import { getFeature } from 'Cloud/Application/FeaturesEs6';

interface ThemeOutsourceSettings {
    applyThemeTimeout?: number;
    availableBgImagesThemes?: string[];
    availableColorThemes?: string[];
    darkDefaultModeThemes?: string[];
    darkModeThemes?: string[];
    lightDefaultModeThemes?: string[];
    supportWidgetThemes?: string[];
    useCustomThemePreviewName?: boolean;
    whitePortalTheme?: string;
    themeOutsourceCdnUrl?: string;
    themeOutsourceVersion?: string;
    defaultThemeId?: string;
    unsavedChangesForegroundTimeout?: number;
}

const themeOutsourceSettings: ThemeOutsourceSettings = getFeature('theme-outsource-settings') || {};

// Список темных тем для https://gitlab.corp.mail.ru/mail/theme-tool
export const DARK_MODE_THEMES: string[] = themeOutsourceSettings.darkModeThemes || ['t5000', 't5001'];

// Таймаут на применение темы для https://gitlab.corp.mail.ru/mail/theme-tool
export const APPLY_THEME_TIMEOUT: number = themeOutsourceSettings.applyThemeTimeout || 3000;

// Дефолтные темные темы для https://gitlab.corp.mail.ru/mail/theme-tool
export const DARK_DEFAULT_MODE_THEMES: string[] = themeOutsourceSettings.darkDefaultModeThemes || ['t5000'];

// Дефолтные светлые темы для https://gitlab.corp.mail.ru/mail/theme-tool
export const LIGHT_DEFAULT_MODE_THEMES: string[] = themeOutsourceSettings.lightDefaultModeThemes || ['t6000', 'default'];

// Доступные цвета для theme chooser https://gitlab.corp.mail.ru/mail/theme-tool
export const AVAILABLE_COLOR_THEMES: string[] = themeOutsourceSettings.availableColorThemes || [
    't4001',
    't4002',
    't4003',
    't4004',
    't4005',
    't4006',
    't4007',
    't4008',
    't4009',
    't4010',
    't4011',
    't4012',
    't4013',
    't4014',
    't4015',
    't4000',
];

// Доступные темы с картинками в бэкграунде для https://gitlab.corp.mail.ru/mail/theme-tool
export const AVAILABLE_BG_IMAGES_THEMES: string[] = themeOutsourceSettings.availableBgImagesThemes || [
    'default',
    't5000',
    't6000',
    't2104',
    't2091',
    't2092',
    't2093',
    't5017',
    't2055',
    't5009',
    't2048',
    't2049',
    't1164',
    't1155',
    't2014',
    't2020',
    't2025',
];

// Список тем с виджетами https://gitlab.corp.mail.ru/mail/theme-tool
export const SUPPORT_WIDGET_THEME_IDS: string[] = themeOutsourceSettings.supportWidgetThemes || [
    't1154',
    't1155',
    't1189',
    't1190',
    't1168',
    't3002',
    't3015',
    't5001',
    't1169',
    't5003',
    't5004',
    't5002',
    't5005',
    't5007',
];

// Название белой темы https://gitlab.corp.mail.ru/mail/theme-tool/
export const WHITE_PORTAL_THEME: string = themeOutsourceSettings.whitePortalTheme || 't6000';

// Использовать ли название картинки превьюхи из конфига темы https://gitlab.corp.mail.ru/mail/theme-tool
export const USE_CUSTOM_THEME_PREVIEW_NAME = themeOutsourceSettings.useCustomThemePreviewName || false;

// CDN где лежат конфиги к theme-outsource
export const cdnPackagesUrl = themeOutsourceSettings.themeOutsourceCdnUrl || 'https://imgs2.imgsmail.ru';

export const minCompatibleVersion = '3.7.1';

// Максимально доступная версия theme.outsource
export const themeOutsourceVersion: string = themeOutsourceSettings.themeOutsourceVersion || minCompatibleVersion;

export const useLocalThemeOutsource: boolean = getFeature('use-local-theme-outsource') || false;

// Дефолтная тема в Theme Chooser
export const defaultThemeId: string = themeOutsourceSettings.defaultThemeId || 't2091';

export const unsavedChangesForegroundTimeout = themeOutsourceSettings.unsavedChangesForegroundTimeout || 1;
