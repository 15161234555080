import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, type ReactElement, memo } from 'react';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { Hint } from 'reactApp/ui/Hint/Hint';
import type { ISpaceLabel, Props } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress.types';
import { useSpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useSpace';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './SingleSpaceProgress.css';

const SpaceLabel: FC<ISpaceLabel & { clickable: boolean }> = ({
    text,
    id,
    hintText,
    isOverquota,
    clickable,
    onClick = noopVoid,
    rebranding = false,
}): ReactElement => {
    const inlineStyles =
        isDarkTheme && rebranding && id === 'cloud'
            ? { background: '#B8FC75' }
            : rebranding && id === 'cloud'
            ? { background: '#97E946' }
            : {};
    return (
        <div className={styles.infoBlock}>
            <Hint text={hintText}>
                <div
                    className={classNames(styles.info, {
                        [styles.info_clickable]: clickable,
                    })}
                    onClick={onClick}
                >
                    <div
                        className={classNames(styles.infoLine, styles[`line_${id}`], {
                            [styles.lineFilled_overquota]: isOverquota,
                        })}
                        style={inlineStyles}
                    />
                    <div className={styles.label}>{text}</div>
                </div>
            </Hint>
        </div>
    );
};

export const SingleSpaceProgress = memo(
    ({
        isMobile = false,
        hideLabels = false,
        redWhenOverquota = false,
        quotaLink = false,
        legendAlign,
        legendSize,
        useSpaceHook = useSpace,
        isWrap,
        rebranding = false,
        isSubscriprionRebranding = false,
    }: Props): ReactElement => {
        const { isOverquota, spaceList, freeSpaceText, usedPercents } = useSpaceHook();
        let zeroSpace = false;
        return (
            <>
                <div
                    className={classNames(styles.line, {
                        [styles.line_overquota]: isOverquota,
                        [styles.line_twoOverquota]: isOverquota && redWhenOverquota,
                        [styles.rebranding]: rebranding,
                        [styles.line_subscriprion_rebranding]: isSubscriprionRebranding,
                    })}
                >
                    <div style={{ width: `${usedPercents}%` }}>
                        {spaceList.map(({ id, percents, isOverquota, hintText, onClick = noopVoid }, key) => {
                            if (percents <= 1) {
                                zeroSpace = true;
                            }
                            if (usedPercents > 100 && !zeroSpace) {
                                percents = (percents * 100) / usedPercents;
                            }
                            return (
                                <Hint text={hintText} key={id}>
                                    <div
                                        className={classNames(styles.lineFilled, styles[`line_${id}`], {
                                            [styles.lineFilled_overquota]: isOverquota,
                                        })}
                                        style={{
                                            minWidth: key !== 0 ? '14px' : '',
                                            width: usedPercents === percents && key !== 0 ? `calc(${percents}% + 6px)` : `${percents}%`,
                                        }}
                                        onClick={onClick}
                                    />
                                </Hint>
                            );
                        })}
                    </div>
                    <Hint text={freeSpaceText}>
                        <div className={styles.lineEmpty} />
                    </Hint>
                </div>

                {!hideLabels && (
                    <>
                        <Spacing size={12} />
                        <div className={styles.legend}>
                            <div
                                className={classNames(styles.quotaInfo, {
                                    [styles.quotaInfo_mobile]: isMobile,
                                    [styles[`quotaInfo_wrap`]]: isWrap,
                                    [styles[`quotaInfo_${legendAlign}`]]: Boolean(legendAlign),
                                    [styles[`quotaInfo_${legendSize}`]]: Boolean(legendSize),
                                })}
                            >
                                {spaceList.map((item) => (
                                    <SpaceLabel
                                        id={item.id}
                                        key={item.id}
                                        isOverquota={item.isOverquota && !redWhenOverquota}
                                        text={item.text}
                                        hintText={item.hintText}
                                        onClick={item?.onClick}
                                        clickable={Boolean(item?.onClick)}
                                        rebranding={rebranding}
                                    />
                                ))}
                            </div>
                            {quotaLink && (
                                <ButtonLink
                                    primary
                                    className={styles.link}
                                    href={getQuotaPromoUrl({ hash: 'tariffs', query: PaymentUTM.spaceProgressFamily })}
                                    target="_blank"
                                    rel="noopener"
                                    hoverUnderline={false}
                                >
                                    Увеличить место
                                </ButtonLink>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    }
);

SingleSpaceProgress.displayName = 'SingleSpaceProgress';
