import { Icon24UsersOutline } from '@vkontakte/icons';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CLOUD_NEW_FEATURES_CONFIG, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { isFamilyMobileMenuItemEnabled, isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { CLOUD_NEW_FEATURES_TOOLTIP_ID_MOBILE } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { FeaturesButton } from 'reactApp/ui/CloudNewFeatures/FeaturesButton/FeaturesButton';
import { FeaturesTooltip } from 'reactApp/ui/CloudNewFeatures/FeaturesTooltip/FeaturesTooltip';
import { renderMobileCloudNewFeaturesModal } from 'reactApp/ui/Mobile/CloudNewFeaturesModal/CloudNewFeaturesModal.helpers';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { Logo } from 'reactApp/ui/Mobile/Logo/Logo';
import { UserBlock } from 'reactApp/ui/Mobile/UserBlock/UserBlock';
import { Space } from 'reactApp/ui/Space/Space';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeItemsFlatten } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import type { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './LeftMenu.css';
import { MenuItem } from './MenuItem/MenuItem';

interface Props {
    onClose: () => void;
    animated?: boolean;
    isRebranding?: boolean;
}

export const LeftMenu = memo(({ onClose, animated = true, isRebranding = false }: Props): ReactElement => {
    const newFeaturesTooltipShown = storeHelper.getValue(CLOUD_NEW_FEATURES_TOOLTIP_ID_MOBILE);

    const storage = useSelector(getCurrentStorage);
    const { isAttaches, isPublicOrStock, isAlbums } = getStorage(storage);

    const scrollRef = useRef(null);
    const featuresRef = useRef(null);

    const [animationFinished, setAnimationFinished] = useState(false);
    const [showTooltip, setShowTooltip] = useState(!newFeaturesTooltipShown);

    const showNewFeaturesIcon = !isAttaches && !isPublicOrStock && !isAlbums;

    useEffect(() => {
        const el = scrollRef?.current;
        scrollLock(el, { reserveScrollBarGap: false });

        return () => {
            scrollUnlock(el);
        };
    }, []);

    const handleClickFeatures = useCallback(() => {
        setShowTooltip(false);
        renderMobileCloudNewFeaturesModal();
    }, [setShowTooltip]);

    const handleFinishAnimation = useCallback(() => {
        setAnimationFinished(true);
    }, [setAnimationFinished]);

    const menuItems = useMemo(() => {
        const items: TreeNodeData[] = Object.values(treeItemsFlatten);

        // TODO: CLOUDWEB-16043 - вынести в treeItemsFlatten после эксперимента.
        if (isFamilyMobileMenuItemEnabled && isFamilySubsAvailable) {
            items.unshift({
                id: ETreeRootIds.family,
                gaId: 'family',
                title: 'Общая подписка',
                storage: EStorageType.family,
                folderCounter: 0,
                itemsCounter: 0,
                icon: <Icon24UsersOutline />,
                isMobile: true,
                href: `/family`,
            });
        }

        return items
            .filter((item) => item?.isMobile)
            .map(({ id, title, icon, storage, href }) => (
                <MenuItem key={id} id={id} title={title} icon={icon} storage={storage} onClose={onClose} href={href} />
            ));
    }, [treeItemsFlatten, onClose]);

    return (
        <LeftDialog onClose={onClose} animated={animated} onFinishAnimation={handleFinishAnimation}>
            <div className={styles.root}>
                <div className={styles.logo}>
                    <Logo dark={isRebranding} />
                </div>
                {showNewFeaturesIcon && Boolean(CLOUD_NEW_FEATURES_CONFIG.length) && (
                    <div className={styles.newFeatures} ref={featuresRef}>
                        <FeaturesButton onClick={handleClickFeatures} />
                        {animationFinished && showTooltip && <FeaturesTooltip ref={featuresRef} />}
                    </div>
                )}
                <div className={styles.contentWrapper} ref={scrollRef}>
                    <div className={styles.content}>
                        <Space isMobile onClose={onClose} />
                    </div>

                    <div className={styles.menu_items}>{menuItems}</div>
                    {!IS_MY_TEAM && (
                        <div className={styles.user}>
                            <UserBlock isLeftMenu reloginAction />
                        </div>
                    )}
                </div>
            </div>
        </LeftDialog>
    );
});

LeftMenu.displayName = 'LeftMenu';
