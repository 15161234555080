import type { EditorID } from 'Cloud/Application/Editor/types';
import type { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import type { SimpleIconRecord } from 'reactApp/modules/editor/editor.types';

import type { IOfficeOnline } from '../../types/context/IOfficeOnline';

export enum EditorMode {
    EDIT = 'edit',
    VIEW = 'view',
    CREATE = 'create',
}

export type EditorConfig = {
    title: string;
    id: EditorID;
    default?: boolean | string;
    description: string;
    icon?: SimpleIconRecord;
    'x-docs-root'?: string;
    get_view_url?: (
        item: { stockId?: string; weblink?: string; home?: string; id?: string; isStock?: boolean },
        storage: string,
        wopiParams: any
    ) => Promise<string>;
    view_url?: string;
    edit_uri?: string;
    create_uri?: string;
    edit_storage?: string;
    edit_data?: (isEdit: boolean, id: string) => Partial<IOfficeOnline> | undefined;
    documentsRoot: string;
    wopi?: boolean;
    ['x-feedback-url']?: string;
};

export interface ViewerList {
    view_extensions: string[];
    DOCUMENTS_ROOT: string;
}

export interface ServerEditor extends EditorConfig {
    icon_big?: SimpleIconRecord;
    edit_extensions?: string[];
    create_extensions?: string[];
    convert_extensions?: Record<string, string>;
    view_extensions?: string[];
    can_view_attach?: boolean | Array<EAttachTypes | 'stock'>;
    force?: boolean;
    tablet?: boolean;
    order?: number;
    attachOrder?: number;
    pages?: string[];
}

export interface Editors {
    viewers: ServerEditor[];
    editors: ServerEditor[];
    /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
     *  по умолчанию на аттачах
     */
    myofficeAttachViewer?: ServerEditor;
    /**
     * В старом коде используется как choosedEditor
     */
    chosenEditor?: IChosenEditor;
}

export interface IChosenEditor {
    ext: string;
    id: EditorID;
    mode: EditorMode;
    file: string;
    data?: Partial<IOfficeOnline>;
}

export interface IEditorSettings {
    create?: string[];
    edit?: string[];
    view?: string[];
    convert?: Record<string, string>;
    tablet?: boolean;
    default?: boolean;
    attach?: boolean;
}
