import React, { type VFC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestFacesListLoadMoreRequest, selectFace } from 'reactApp/modules/faces/faces.module';
import {
    getFacesListRequestState,
    getFilteredFacesList,
    getFilteredFacesListOnFile,
    getSelectedFaceId,
    hasMoreFacesToLoad,
} from 'reactApp/modules/faces/faces.selectors';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { FaceList } from 'reactApp/ui/FaceFilter/FaceList/FaceList';
import { usePublicFaceRadars } from 'reactApp/ui/FaceFilter/hooks/publicFaceRadars';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './FilterFacesModal.css';

interface Props {
    onClose: () => void;
    fileId?: string;
}

export const FilterFacesModal: VFC<Props> = ({ onClose, fileId = '' }) => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const faces = useSelector(getFilteredFacesList);
    const facesOnFile = useSelector((state: RootState) => getFilteredFacesListOnFile(state, fileId));
    const selectedFaceId = useSelector(getSelectedFaceId);
    const hasMoreToLoadState = useSelector(hasMoreFacesToLoad);
    const { isLoading } = useSelector(getFacesListRequestState);
    const isViewerActive = useSelector(ViewerSelectors.isViewerActive);

    const facesList = useMemo(() => (isViewerActive ? facesOnFile : faces), [isViewerActive, facesOnFile, faces]);
    const hasMoreToLoad = useMemo(() => (isViewerActive ? false : hasMoreToLoadState), [isViewerActive, hasMoreToLoadState]);

    const { sendFacesHelper } = usePublicFaceRadars({ countFaces: facesList?.length });

    const loadMore = useCallback(() => {
        dispatch(requestFacesListLoadMoreRequest());
    }, [dispatch]);

    const onSelect = useCallback(
        (id: string | null) => {
            dispatch(selectFace({ id }));
            if (!selectedFaceId) {
                sendFacesHelper('face_filter_select');
            }
            if (isViewerActive) {
                dispatch(closeViewer());
            }
            onClose();
        },
        [dispatch, onClose, sendFacesHelper, selectedFaceId, isViewerActive]
    );

    useEffect(() => {
        sendFacesHelper('toolbar_all_faces');
    }, []);

    const title = useMemo(() => <div className={styles.title}>Люди на фотографиях</div>, []);

    return (
        <MobileDialog id="mobile-filter-faces" onClose={onClose} closeOnDimmerClick mod="confirm" topmost scrollRef={ref} title={title}>
            <div className={styles.root} ref={ref}>
                <FaceList
                    faces={facesList}
                    selectedFaceId={selectedFaceId}
                    onSelect={onSelect}
                    loadMore={loadMore}
                    hasMoreToLoad={hasMoreToLoad}
                    isLoading={isLoading}
                    isMobile
                />
            </div>
        </MobileDialog>
    );
};
