import type { EditorID } from 'Cloud/Application/Editor/types';

export enum AstraMetaProcessorType {
    viewer = 'v',
    editor = 'e',
    default = 'o',
}

export type AstraMetaProcessorIDsMapType = { [key in EditorID]?: string } & { default: string };

export type AstraMetaDataType = {
    processorIDsMap: AstraMetaProcessorIDsMapType;
    processorType: AstraMetaProcessorType;
    fileExt: string;
    editorID?: EditorID;
    isWOPI?: boolean;
};
