import classNames from 'clsx';
import { ReactComponent as ArrowAside } from 'img/editor/llm/hover_element_arrow.svg';
import MascotBiteIcon from 'img/icons/editor/llm/mascot_bite_llm_button.png';
import debounce from 'lodash.debounce';
import React, { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { initLlmDialog, toggleOpenLlmDialog } from 'reactApp/modules/editor/editor.module';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { PROMO_ID } from 'reactApp/modules/promo/sagas/editorLLMButton.saga';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './EditorLLMButton.css';

const ButtonIcon = () => <div className={styles.buttonIcon} />;

export const EditorLLMButton = memo(({ isWithText = true }: { isWithText: boolean }) => {
    const editorLLMButtonPromo = useSelector(PromoSelectors.pullPromo(EPromoType.editorLLMButton));

    const closeTooltip = () => {
        editorLLMButtonPromo?.onClose();
    };

    const isEditorPageHasError = useSelector(selectStatusPage) === EStatus.SOMETHING_WRONG;
    const ref = useRef(null);
    const { isOpen } = useSelector(getLlmDialogState);
    const isTooltipAlreadyShown = Boolean(storeHelper.getValue(PROMO_ID));
    const sendAnalytics = useGetLLMAnalytics();
    const dispatch = useDispatch();

    const handleOpenDialog = () => {
        dispatch(toggleOpenLlmDialog(true));

        if (!isTooltipAlreadyShown) {
            closeTooltip();
        }
    };

    const onButtonClick = () => {
        handleOpenDialog();
        sendAnalytics({ action: LLMAnalyticsType.BOTTOM_CLICK });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.BOTTOM_CLICK });
    };

    const onTooltipClick = () => {
        handleOpenDialog();
        sendAnalytics({ action: LLMAnalyticsType.BANNER_CLICK });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.BANNER_CLICK });
    };

    const onTooltipShow = () => {
        editorLLMButtonPromo?.onShow();
        sendAnalytics({ action: LLMAnalyticsType.BANNER_SHOW });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.BANNER_SHOW });
    };

    const onTooltipClose = () => {
        closeTooltip();
        sendAnalytics({ action: LLMAnalyticsType.BANNER_CLOSE });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.BANNER_CLOSE });
    };

    const onButtonMouseLeave = debounce(() => {
        sendAnalytics({ action: LLMAnalyticsType.BOTTOM_HOVER });
    }, 1000);

    useEffect(() => {
        const hoverType = isWithText ? 'withText' : 'withHint';

        sendAnalytics({ action: LLMAnalyticsType.BOTTOM_SHOW, dwh: { type: hoverType } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.BOTTOM_SHOW, info: hoverType });
    }, [isWithText, sendAnalytics]);

    useEffect(() => {
        dispatch(initLlmDialog());
    }, [dispatch]);

    // Если ошибка при загрузке редактора, то не отображаем кнопку.
    return !isEditorPageHasError && !isOpen ? (
        <div className={styles.button_container} data-qa-id="LLMButton">
            <div ref={ref}>
                {isRebranding ? (
                    <div className={styles.button_content}>
                        <img
                            src={MascotBiteIcon}
                            alt="mascot"
                            className={styles.mascot}
                            onClick={onButtonClick}
                            onMouseLeave={onButtonMouseLeave}
                        />
                        <div className={styles.button_hover_component}>
                            <span className={styles.text}>{llmFeature.buttonTitle}</span>
                            <div className={styles.arrow}>
                                <ArrowAside />
                            </div>
                        </div>
                    </div>
                ) : isWithText ? (
                    <div
                        className={classNames(styles.button_content, styles.button_content_withText)}
                        onClick={onButtonClick}
                        onMouseLeave={onButtonMouseLeave}
                    >
                        <div className={styles.inner_container}>
                            <ButtonIcon />
                            <span className={styles.text}>{llmFeature.buttonTitle}</span>
                        </div>
                    </div>
                ) : (
                    <Hint text={llmFeature.buttonTitle}>
                        <div className={styles.button_content} onClick={handleOpenDialog} onMouseLeave={onButtonMouseLeave}>
                            <ButtonIcon />
                        </div>
                    </Hint>
                )}
            </div>
            {!isTooltipAlreadyShown && editorLLMButtonPromo && (
                <FloatingTooltip
                    target={ref}
                    placement={ETooltipPlacement.topEnd}
                    buttonText={llmFeature.tooltipButtonText}
                    title={llmFeature.tooltipTitle}
                    text={llmFeature.tooltipMessage}
                    onClick={onTooltipClick}
                    onClose={onTooltipClose}
                    onShow={onTooltipShow}
                    closable
                    closeOnOutsideClick={false}
                    className={styles.tooltip}
                    isAlternativeButton
                    qaId={PROMO_ID}
                />
            )}
        </div>
    ) : null;
});

EditorLLMButton.displayName = 'EditorLLMButton';
