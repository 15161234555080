import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { createAlbumRequest, loadAlbumsListRequest } from 'reactApp/modules/albums/albums.actions';
import { getAlbumsItemById, getAlbumsList } from 'reactApp/modules/albums/albums.selector';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { disableDropArea, enableDropArea } from 'reactApp/modules/uploading/uploading.module';
import type { RootState } from 'reactApp/store';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { Empty } from 'reactApp/ui/Empty/Empty';
import { ErrorRetry } from 'reactApp/ui/ErrorRetry/ErrorRetry';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { Album } from './Album/Album';
import { AlbumsCollapser } from './AlbumsCollapser/AlbumsCollapser';
import { AlbumsEmpty } from './AlbumsEmpty/AlbumsEmpty';
import styles from './AlbumsPage.css';
import { CreateAlbum } from './CreateAlbum/CreateAlbum';

const HOT_KEY_SCOPE_NAME = 'albums_page';

export const AlbumsPage = memo(function AlbumsPage() {
    const dispatch = useDispatch();
    const { personal, weblink } = useSelector(getAlbumsList);
    const isEmptyAlbumsList = !personal.length && !weblink.length;
    const album = useSelector((state: RootState) => getAlbumsItemById(state, ROOT_FOLDER_ID));
    const isLoading = isAlbum(album) ? !album.isLoaded && album.isLoading : true;
    const error = isAlbum(album) ? album.error : undefined;

    const createAlbumHandler = useCallback(() => {
        dispatch(createAlbumRequest());
    }, []);

    useHotKeyScope(HOT_KEY_SCOPE_NAME);

    useEffect(() => {
        const handler = function (e) {
            e.preventDefault();

            createAlbumHandler();
        };

        hotkeys(`shift+a`, HOT_KEY_SCOPE_NAME, handler);

        return () => {
            hotkeys.unbind(`shift+a`, HOT_KEY_SCOPE_NAME, handler);
        };
    }, [createAlbumHandler]);

    useEffect(() => {
        dispatch(routeChangeSuccess({ id: '/', storage: EStorageType.albums, params: {}, __isFolder: true, url: '', __parent: '' }));
        dispatch(loadAlbumsListRequest());
    }, []);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'albums' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'albums' });
    }, []);

    useEffect(() => {
        dispatch(disableDropArea());

        return () => {
            dispatch(enableDropArea());
        };
    }, []);

    const content = useMemo(() => {
        if (error) {
            return <ErrorRetry centered />;
        }

        if (isLoading) {
            return <Spinner />;
        }

        if (isEmptyAlbumsList) {
            return (
                <div className={styles.emptyContent} data-qa-id="albums-empty">
                    {/* TODO: CLOUDWEB-14876 - заменить на новый компонент empty-state'а после аб. */}
                    {IS_B2B_BIZ_USER ? <Empty.New storage={EStorageType.albums} categoryType={EStorageType.albums} /> : <AlbumsEmpty />}
                </div>
            );
        }

        return (
            <>
                <AlbumsCollapser title="Мои альбомы" initialyOpen={!weblink.length}>
                    <div className={styles.album}>
                        <CreateAlbum onClick={createAlbumHandler} />
                    </div>
                    {personal.map((album) => (
                        <div className={styles.album} key={album.id}>
                            <Album {...album} />
                        </div>
                    ))}
                </AlbumsCollapser>

                {Boolean(weblink.length) && (
                    <AlbumsCollapser title="Общие альбомы" initialyOpen={!personal.length}>
                        {weblink.map((album) => (
                            <div className={styles.album} key={album.id}>
                                <Album {...album} />
                            </div>
                        ))}
                    </AlbumsCollapser>
                )}
            </>
        );
    }, [error, isEmptyAlbumsList, isLoading, personal, weblink]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <BreadcrumbsContainer />
                </div>

                {content}

                <Footer storage={EStorageType.albums} isAbsolutePos={true} />
            </div>
        </div>
    );
});
