/* tempexp_15344-full-file */
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import type { TemplateVariantString } from 'reactApp/modules/features/features.types';
import { getPublicItemById, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { sendGaHelper } from 'reactApp/modules/requiredAuthorization/sendGaHelper';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import type { RootState } from 'reactApp/store';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { RequiredSignUpTouchSource, SUCCESS_AUTH_WHEN_DOWNLOAD } from './constants';

let touchSource: RequiredSignUpTouchSource | null = null;
let touchSourceFileId: string | undefined;
let showDownloadTip = false;

const setRequiredSignUpTouchSource = (source: RequiredSignUpTouchSource, fileId?: string) => {
    touchSource = source;

    showDownloadTip = false;

    touchSourceFileId = fileId;
};
const setShowDownloadTip = (setShow = true) => {
    showDownloadTip = setShow;
};
const resetRequiredSignUpTouchSource = () => {
    touchSource = null;
    touchSourceFileId = undefined;
};
const getSuccessPage = () => {
    const url = new URL(window.location.href);
    url.searchParams.append(EQueryParams.action, SUCCESS_AUTH_WHEN_DOWNLOAD);

    if (touchSource) {
        url.searchParams.append(EQueryParams.requiredSignUpTouchSource, touchSource);
    }

    if (touchSourceFileId) {
        url.searchParams.append(EQueryParams.id, touchSourceFileId);
    }

    return url.toString();
};

const isSuccessAuthWhenDownload = (state: RootState) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const queryAction = queryParams.action;

    const isPublicOwner = isOwnPublic(state);

    return (
        queryAction === SUCCESS_AUTH_WHEN_DOWNLOAD && !isPublicOwner /* Отсекаем от флоу юзеров, которые авторизовались в свой же паблик */
    );
};

const isTouchIdFolder = (state: RootState) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const id = queryParams[EQueryParams.id] || touchSourceFileId;

    return getPublicItemById(state, id)?.isFolder || !id;
};

const isRequiredSingUpTouchSource = (state: RootState, source: RequiredSignUpTouchSource, id?: string) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const sourceFromQuery = queryParams[EQueryParams.requiredSignUpTouchSource];
    const idFromQuery = queryParams[EQueryParams.id];

    const fromUrl =
        isSuccessAuthWhenDownload(state) &&
        sourceFromQuery === source &&
        (sourceFromQuery === RequiredSignUpTouchSource.PUBLIC_TOOLBAR || id === idFromQuery);
    const fromState =
        showDownloadTip && touchSource === source && (touchSource === RequiredSignUpTouchSource.PUBLIC_TOOLBAR || id === touchSourceFileId);

    return fromUrl || fromState;
};

const isSuccessAuthVariant = (variant: TemplateVariantString) => (state: RootState) => {
    const requiredSignUpVariant = getFeatureRequiredSignUpWhenDownloading(state);

    return isSuccessAuthWhenDownload(state) && requiredSignUpVariant === variant;
};

export const requiredAuthorizationHelpers = {
    getSuccessPage,
    isSuccessAuthWhenDownload,
    isSuccessAuthVariant1: isSuccessAuthVariant('variant1'),
    sendGa: sendGaHelper,
    isRequiredSingUpTouchSource,
    setRequiredSignUpTouchSource,
    resetRequiredSignUpTouchSource,
    setShowDownloadTip,
    isTouchIdFolder,
};
