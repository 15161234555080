import { bytesToNDigits } from '@mail/cross-sizes-utils';
import features from 'Cloud/Application/Features';
import classNames from 'clsx';
import format from 'date-fns/format';
import { ReactComponent as PromoIcon } from 'img/promocode.svg';
import { filter, join, path, pathOr, reduce } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PromocodeAPICall } from 'reactApp/api/promo/PromocodeAPICall';
import { updateReceivedGiftsAction } from 'reactApp/modules/giftReceived/giftReceived.actions';
import { productsController } from 'reactApp/modules/products/products.controller';
import { TRIAL_REGEX } from 'reactApp/modules/products/products.helpers';
import { updatePromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { updatePurchasedGiftsAction } from 'reactApp/modules/purchasedGifts/purchasedGifts.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { ESubscriptionsNotifications } from 'reactApp/modules/subscriptions/subscriptions.types';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { Button } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { EPromocodeApiErrorCodes, errorDescriptions, errorTitles, featuesText } from 'reactApp/ui/Promocode/promocode.constants';
import { TariffIcon } from 'reactApp/ui/TariffIcon/TariffIcon';
import withValidationMessageComponent from 'reactApp/ui/WithValidationMessage';
import { noop } from 'reactApp/utils/helpers';
import { getPeriodName } from 'reactApp/utils/Period';
import { Radar } from 'reactApp/utils/Radar';
import { Form } from 'semantic-ui-react';

import styles from './Promocode.css';

// @ts-ignore
const radar = new Radar({
    logFile: 'app_promocodes',
    groupName: 'app_promocodes',
});

const mapDispatchToProps = (dispatch) => ({
    updateUser: () => dispatch(updateUser()),
    updateProducts: (productId?: string) => productsController.updateProducts(productId),
    updatePromo: (promoId?: string) =>
        new Promise<void>((resolve, reject) => dispatch(updatePromoTariffs({ onSuccess: resolve, onError: reject, promoId }))),
    showNotify: (options) => dispatch(showSnackbarAction(options)),
    updateReceivedGifts: () => dispatch(updateReceivedGiftsAction()),
    updatePurchasedGifts: () => dispatch(updatePurchasedGiftsAction({ count: 5 })),
});

const InputWithValidationMessage = withValidationMessageComponent(Form.Input);

interface IProps {
    updatePromo?(promoId?: string): Promise<void>;
    showNotify?(options): void;
    updateUser?(): void;
    onClose?(): void;
    updateProducts?(productId?: string): Promise<void>;
    updateReceivedGifts?(): Promise<void>;
    openFastCheckout?: boolean;
    updatePurchasedGifts?(): void;
    code: string;
    onSuccess?(productId, isTrial): void;
    noOkButton?: boolean;
    isRebranding?: boolean;
}

interface IState {
    error: string;
    success: boolean;
    isOpen: boolean;
    value: string;
    successBuy: boolean;
    successConfig: { isDiscount?: boolean; isGift?: boolean; features?: any };
    showModal: boolean;
    isLoading: boolean;
}

class PromoCodeDialogComponent extends PureComponent<IProps, IState> {
    state = {
        error: '',
        success: false,
        isOpen: true,
        isLoading: false,
        value: this.props.code,
        successBuy: false,
        showModal: false,
        successConfig: { isDiscount: false, isGift: false, features: null, id: '' },
    };

    input: any = null;

    static defaultProps = {
        code: '',
        onSuccess: noop,
        isRebranding: false,
    };

    componentDidMount() {
        this.focus();
    }

    focus = () => {
        if (this.input) {
            this.input.focus();
        }
    };

    handleOnButtonClick = () => {
        radar.addCounter('show', 1);
        radar.send();
        this.setState({ showModal: true });
    };

    handleOnChange = (e, { value }) => {
        this.setState({
            value: (value as string).trim(),
            error: '',
        });
    };

    handleCloseModal = () => {
        radar.addCounter('close', 1);
        radar.send();
        this.props.onClose?.();
    };

    update = (productId) => {
        const { updateUser, updateProducts, updatePromo, showNotify, updateReceivedGifts, updatePurchasedGifts } = this.props;
        const { successConfig } = this.state;

        const p = new Promise<void>((resolve, reject) => {
            if (successConfig.isGift && !successConfig.isDiscount) {
                updatePurchasedGifts?.();
                return updateReceivedGifts?.().then(resolve);
            }

            if (successConfig.isDiscount) {
                return updateProducts?.(productId)
                    .then(() => {
                        if (successConfig.isGift) {
                            return updateReceivedGifts?.();
                        }
                        return Promise.resolve();
                    })
                    .then(resolve)
                    .catch(reject);
            }

            return resolve();
        });
        updateUser?.();

        return p.then(() =>
            updatePromo?.(productId)
                .then(() => {
                    showNotify?.({
                        text: 'Список подписок обновлен',
                        type: SnackbarTypes.success,
                        id: ESubscriptionsNotifications.updateSuccess,
                        closable: true,
                    });
                })
                .catch(() =>
                    showNotify?.({
                        text: 'Не удалось обновить список подписок',
                        type: SnackbarTypes.failure,
                        id: ESubscriptionsNotifications.updateFailure,
                        closable: true,
                    })
                )
        );
    };

    handleSubmit = () => {
        this.setState({ isLoading: true });
        radar.addCounter('request_make', 1);

        if (!this.state.value) {
            radar.addCounter('empty_field', 1);
            radar.send();
            this.focus();

            return this.setState({
                isLoading: false,
                error: 'required',
            });
        }

        new PromocodeAPICall()
            // @ts-ignore
            .makeRequest({
                promocode: this.state.value,
            })
            .then(({ data: promo }) => {
                radar.addCounter('request_success', 1);

                // @ts-ignore
                const IS_FILE_HISTORY_ENABLED = features.fileHistory.isEnabled();

                let successConfig;
                const paidFeatures = path(['services', 'flags', 'UFLAG_PAID_ACCOUNT'], promo);
                const paidUpload = path(['services', 'flags', 'UFLAG_PAID_UPLOAD'], promo);
                const unlocked = path(['services', 'unlocked', 'web'], promo);
                const productId = path(['product_id'], unlocked) as string;

                if (promo) {
                    const size = promo.quota;
                    const period = pathOr(promo.period, ['period'], unlocked);
                    const isTrial = promo?.is_trial;

                    successConfig = {
                        isDiscount: promo?.is_discount,
                        isGift: promo.is_gift,
                        isTrial,
                        unlocked,
                        expires: format(promo.expires, 'dd.MM.yy'),
                        period: getPeriodName(period),
                    };

                    if (size) {
                        successConfig.size = bytesToNDigits(size, 3).value;
                    }

                    if (!successConfig.unlocked && !successConfig.isGift) {
                        successConfig.features = {
                            paid_upload: !!paidUpload,
                            file_history: IS_FILE_HISTORY_ENABLED && !!paidFeatures,
                        };
                    }
                }

                this.setState(
                    {
                        success: true,
                        isLoading: false,
                        successConfig,
                    },
                    () => {
                        this.update(productId).then(() => this.props.onSuccess?.(productId, productId && TRIAL_REGEX.test(productId)));
                    }
                );

                radar.addCounter('get_promo_info', 1);
                radar.send();
            })
            .catch((error) => {
                let errorType = pathOr(path(['promocode', 'error'], error), ['error'], error) as string;

                if (!errorType || !errorDescriptions[errorType]) {
                    errorType = 'system';
                }

                this.setState({
                    error: errorType,
                    isLoading: false,
                });

                this.focus();
                radar.addCounter('request_error', 1);
                radar.send();
            });
    };

    renderSuccessText = (successConfig) => {
        if (successConfig.isGift && !successConfig.isDiscount) {
            return 'Ваше Облако стало больше';
        }

        let textStart = 'Ваш подарок ';
        let activateText = ' будет активирован';
        let freeText = '';
        if (successConfig.isDiscount) {
            textStart = successConfig.isTrial ? '' : 'Ваша скидка на ';
            activateText = successConfig.isTrial ? ' будут активированы' : ' будет активирована';
            freeText = successConfig.isTrial ? ' бесплатно' : '';
        }

        return (
            <>
                {textStart}
                <strong>
                    {successConfig.size && `+${successConfig.size}`}
                    {freeText}
                    {successConfig.period ? <span>&nbsp;на&nbsp;{successConfig.period}</span> : ''}
                </strong>
                {activateText} в&nbsp;ближайшее время.
            </>
        );
    };

    renderSuccess = () => {
        const { successConfig } = this.state;
        const { isRebranding } = this.props;
        const featuresList =
            successConfig.features &&
            join(
                ', ',
                reduce(
                    // @ts-ignore
                    (list, feature) => [...list, feature.text],
                    [],
                    filter((feature) => Boolean(successConfig.features?.[feature.id]), featuesText)
                )
            );

        return (
            <div className={styles.success}>
                {!isRebranding && <div className={styles.successIcon} />}
                <div className={styles.title}>Промокод активирован!</div>
                {successConfig && (
                    <div className={styles.successOptions}>
                        <div className={styles.successText}>{this.renderSuccessText(successConfig)}</div>
                        {featuresList && (
                            <div className={styles.features}>
                                <b>Доступные опции: </b>
                                {featuresList}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    getErrorText = (error: string) => {
        if (!error) {
            return '';
        }

        const description = [EPromocodeApiErrorCodes.exists.toString(), EPromocodeApiErrorCodes.finished.toString()].includes(error)
            ? ''
            : ` ${errorDescriptions[this.state.error]}`;

        return `${errorTitles[this.state.error]}.${description}`;
    };

    renderForm = () => (
        <>
            {!this.props.isRebranding && (
                <div className={styles.iconContainer}>
                    <PromoIcon className={styles.icon} />
                    <TariffIcon size={74} />
                </div>
            )}
            <div className={styles.title}>Применить промокод</div>
            <Form onSubmit={this.handleSubmit}>
                <div className={styles.input}>
                    <InputWithValidationMessage
                        error={!!this.state.error}
                        onChange={this.handleOnChange}
                        placeholder="Введите промокод"
                        fluid
                        focus
                        validationErrorMessage={this.getErrorText(this.state.error)}
                        getReference={(node) => {
                            this.input = node;
                        }}
                        value={this.state.value}
                        autoFocus
                    />
                </div>
            </Form>
        </>
    );

    renderFooter = () => {
        if (this.state.success && !this.state.successConfig.isDiscount && !this.state.successConfig.isGift) {
            return null;
        }

        const okButton = this.props.noOkButton ? null : (
            <Button size="big" primary theme="octavius" onClick={this.props.onClose} key="returnButton">
                {this.state.successConfig.isDiscount ? 'Вернуться к тарифам' : 'Вернуться на Облако'}
            </Button>
        );

        return (
            <div className={styles.footer}>
                <div className={styles.button}>
                    {this.state.successConfig.isDiscount || this.state.successConfig.isGift ? (
                        okButton
                    ) : (
                        <Button
                            size="big"
                            theme="octavius"
                            onClick={this.handleSubmit}
                            loading={this.state.isLoading}
                            primary
                            key="submitButton"
                        >
                            Применить код
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    render() {
        return (
            <Dialog size="small" onCancel={this.handleCloseModal} footer={this.renderFooter()} open>
                <div
                    className={classNames(styles.modal, {
                        [styles.modal_rebranding]: this.props.isRebranding,
                    })}
                >
                    {this.state.success ? this.renderSuccess() : this.renderForm()}
                </div>
            </Dialog>
        );
    }
}

export const PromoCodeDialog = connect(null, mapDispatchToProps)(PromoCodeDialogComponent);
