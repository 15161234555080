import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { BuyContent } from 'reactApp/components/BuyModal/BuyContent';
import { BuyModalTrial } from 'reactApp/components/BuyModalTrial/BuyModalTrial';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { Product } from 'reactApp/types/Billing';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { sendTmrGoal } from 'reactApp/utils/tmr';

import type { IMapState, IOwnProps, IState, Props } from './BuyModal.types';

const mapStateToProps = (state: IState, props: IOwnProps): IMapState => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const subscription = ProductsSelectors.getProductById(state, props.id) as Product;
    const { total: userTotalSpace } = UserSelectors.getCloudSpace(state);

    return {
        subscription,
        userTotalSpace,
    };
};

export class BuyModalComponent extends PureComponent<Props, IState> {
    subscriptionSpace = this.props.subscription && this.props.subscription.space;
    userSpace = this.props.userTotalSpace;

    state = {
        isSuccess: false,
    };

    componentDidMount(): void {
        this.sendPayment({ label: 'view' });
    }

    sendPayment = ({ label }) => {
        const { subscription, id, paySource } = this.props;

        if (subscription.hasTrial) {
            return;
        }

        sendPaymentGa({ action: EPaymentGa.payment, label, tariff: id, paySource });
    };

    renderHeader = (): JSX.Element | null => {
        const { renew, subscription, id } = this.props;

        const newSpace = bytesToNDigits(this.userSpace.original + this.subscriptionSpace.original, 3).value;

        if (this.state.isSuccess) {
            if (renew) {
                // tempexp_16598-start
                if (Object.values(cheapestProductIDs).includes(id)) {
                    return <div>Вы продлили тариф Без рекламы</div>;
                }
                // tempexp_16598-end

                return <div>Вы продлили тариф на {this.subscriptionSpace.value}</div>;
            }
            return <div>Вы увеличили квоту до {newSpace}</div>;
        }

        if (renew) {
            // tempexp_16598-start
            if (Object.values(cheapestProductIDs).includes(id)) {
                return <div>Продление периода тарифа Без рекламы</div>;
            }
            // tempexp_16598-end

            return <div>Продление периода тарифа на {this.subscriptionSpace.value}</div>;
        }

        if (subscription.hasTrial) {
            return <div>Введите данные карты</div>;
        }

        return <div>Увеличение объёма до {newSpace}</div>;
    };

    onBuyClick = () => {
        this.sendPayment({ label: 'click' });
    };

    onSuccess = (): void => {
        const { subscription, onSuccess } = this.props;

        sendTmrGoal({ goal: subscription.hasTrial ? 'trial-success' : 'pay-success' });

        this.setState({ isSuccess: true });

        onSuccess();
    };

    onClose = (): void => {
        const { onClose } = this.props;

        onClose();
    };

    onCancelDialog = (): void => {
        this.onClose();
        this.sendPayment({ label: 'close' });
    };

    render() {
        const {
            id,
            link = null,
            retry = null,
            subscription,
            renew = false,
            onDmrResizeEvent,
            isMobile,
            source = '',
            paySource,
        } = this.props;

        if (subscription.hasTrial && !renew) {
            return (
                <BuyModalTrial
                    id={id}
                    link={link}
                    retry={retry}
                    onClose={this.onClose}
                    onSuccess={this.onSuccess}
                    source={source}
                    paySource={paySource}
                />
            );
        }

        return (
            <Dialog
                id="tariff-buy"
                open
                disableDarkTheme
                size="big"
                header={this.renderHeader()}
                onCancel={this.onCancelDialog}
                mod={isMobile ? 'mobile' : 'frame_vkpay'}
                dimmer={!isMobile}
            >
                <BuyContent
                    id={id}
                    link={link}
                    retry={retry}
                    renew={renew}
                    source={source}
                    onClose={this.onClose}
                    onSuccess={this.onSuccess}
                    onBuyClick={this.onBuyClick}
                    onDmrResizeEvent={onDmrResizeEvent}
                    paySource={paySource}
                />
            </Dialog>
        );
    }
}

export const BuyModal = connect(mapStateToProps)(BuyModalComponent);
