import 'reactApp/swiper.scss';

import sha1 from 'js-sha1';
import { ReactComponent as EditIcon } from 'mrg-icons/src/mailru/actions/compose.svg';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { IS_B2B_BIZ_USER, IS_BLOCKED, IS_MY_TEAM, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { usePrevious } from 'reactApp/hooks/usePrevious';
import { getEditorsFor } from 'reactApp/modules/editor/editor.selectors';
import { getFacesFileId } from 'reactApp/modules/faces/faces.helpers';
import { getFacesIdxOnFile, getFacesList } from 'reactApp/modules/faces/faces.selectors';
import { getMyOfficeTouchFeature } from 'reactApp/modules/features/features.selectors';
import { getWeblinkFromPublicId, isArchive, isDocument, isPreviewable } from 'reactApp/modules/file/utils';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchCount, getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import type { SearchFile } from 'reactApp/modules/search/search.types';
import { changeStorageHistory, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { selectItemIndexInArray } from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { animationClasses, GA_CATEGORY, SWIPER_SPEED, virtualOptions } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer.constants';
import { MobileViewerItem } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerItem/MobileViewerItem';
import { ViewerToolbar } from 'reactApp/ui/Mobile/MobileViewer/ViewerToolbar/ViewerToolbar';
import { OptionsModalSource } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.constants';
import { renderMobileOptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.helpers';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { ViewerButton } from 'reactApp/ui/ReactViewer/ViewerButton/ViewerButton';
import { SwipingDownComponent } from 'reactApp/ui/SwipingDownComponent/SwipingDownComponent';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { isIosDevice, scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';
import { Virtual, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './MobileViewer.css';
import type { SwiperCoreExt } from './MobileViewer.types';

const sendViewerGa = createGaSender(GA_CATEGORY);

interface Props {
    onClose: () => void;
}

export const MobileViewer = memo<Props>(
    // eslint-disable-next-line max-lines-per-function, complexity
    ({ onClose = noop }) => {
        const dispatch = useDispatch();

        const itemId = useSelector(ViewerSelectors.getViewerItemId);
        const prevItemId = usePrevious(itemId);
        let storage = useSelector(getCurrentStorage);
        const { isHome, isPublic, isStock, isGallery, isAttaches, isSearch, isQuotaCleaner } = getStorage(storage);
        if (isAttaches) {
            storage = EStorageType.viewerAttaches;
        }
        const itemsIdsList = useSelector(ViewerSelectors.getViewerItemIds) || [];
        const itemIndex = useSelector(ViewerSelectors.getViewerItemIndexInArray) || 0;
        const totalCount = useSelector(ViewerSelectors.getViewerItemCount);
        const totalCountSearch = useSelector(getSearchCount);
        const currentItem = useSelector(ViewerSelectors.getViewerItem) as CloudFile;
        const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

        const parentId = folder?.id || currentItem?.parent;

        const myOfficeTouchFeature = useSelector(getMyOfficeTouchFeature);
        const isEditable = !!useSelector((state: RootState) => getEditorsFor(state, currentItem))?.length;

        const hasMyOfficeFeature = myOfficeTouchFeature && isDocument(currentItem);
        const showMyOfficeEditButton = isEditable && hasMyOfficeFeature && myOfficeTouchFeature.editablePages.includes(storage);

        const [swiper, setSwiper] = useState<SwiperCoreExt | null>(null);
        const [showCustomItem, setShowCustomItem] = useState(false);
        const [showToolbar, setShowToolbar] = useState<boolean>(!showCustomItem && !isViewContentOnly);
        const [isZoom, setIsZoom] = useState(false);
        const [swipeCount, setSwiperCount] = useState<number>(0);
        const [slideTransition, setSlideTransition] = useState<boolean>(false);
        const [forceArchiveViewerState, setForceArchiveViewerState] = useState<null | boolean>(null);

        const timerId = useRef<number | undefined>(undefined);
        const currentRoute = useSelector(getCurrentRouteId);

        const rootRef = useRef<null | any>(null);

        const extOriginal = (!currentItem?.isFolder && currentItem?.ext) || '';
        const ext = extOriginal.toLowerCase();
        const nameWithoutExt = (!currentItem?.isFolder && currentItem?.nameWithoutExt) || '';
        const noReturn = (isAttaches && !IS_MY_TEAM) || isQuotaCleaner;

        const fileId = getFacesFileId(itemId || '');
        const facesIdx = useSelector((state: RootState) => getFacesIdxOnFile(state, fileId));
        const faces = useSelector((state: RootState) => getFacesList(state));
        const { query, xPageId, xReqId } = useSelector(getSearchRequestParams);

        // При листании от не-архива к архиву показываем превью с кнопокй для архива.
        // Если кликнули и открыли сразу архив - то тогда сразу распаковываем, без превью с кнопкой
        // Dependency пустое, а не currentItem, так как нам надо запомнить только какой был первый элемент.
        const firstOpenedItem = useMemo(() => {
            return currentItem;
        }, []);
        // Если использовать только forceArchiveViewerState, то изменение увидим только на след рендер, а нужно прям щас
        // Если с элемента ушли - то сбрасываем, и более уже не ставим при всех навигациях
        const forceArchiveViewer = useMemo(() => {
            if (forceArchiveViewerState && firstOpenedItem?.id !== currentItem?.id) {
                setForceArchiveViewerState(false);
                return false;
            }
            if (forceArchiveViewerState !== null) {
                return forceArchiveViewerState;
            }

            const isItemArchive = firstOpenedItem && isArchive(firstOpenedItem);
            const isPreviewableItem = firstOpenedItem && isPreviewable(firstOpenedItem, storage);
            setForceArchiveViewerState(isItemArchive && isPreviewableItem);
            return isItemArchive && isPreviewableItem;
        }, [firstOpenedItem, currentItem, forceArchiveViewerState]);

        const isLockSwipingDown = useMemo(() => {
            return isViewContentOnly || isZoom || showCustomItem || currentItem?.kind === 'pdf,document' || isAttaches;
        }, [isZoom, showCustomItem, currentItem, isAttaches, isViewContentOnly]);

        // Запрещаем переключение слайдов на документах через мой офис
        const allowSlideNavigation = !hasMyOfficeFeature && !isViewContentOnly;

        const sendDwh = useCallback(
            ({ action, source, ...rest }) => {
                const touchSource = IS_PUBLIC_ALBUM ? 'album' : source || storage;

                const isPreviewableItem = isPreviewable(currentItem);

                sendViewerDwh({
                    action,
                    id_media: sha1(currentItem?.id || ''),
                    type_content: currentItem?.kind,
                    extension: ext,
                    source: `touch-${touchSource}`,
                    is_stories: false,
                    id_public: isPublic && currentItem?.id ? getWeblinkFromPublicId(currentItem?.id) : 'None',
                    is_archive: false,
                    size_files: currentItem?.size,
                    is_full_render: isPreviewableItem,
                    ...rest,
                });

                if (storage === EStorageType.search) {
                    const item = currentItem as SearchFile;
                    let dwhData = { eventCategory: ECategoryGa.viewer_search, action };
                    let dwhItem: any = {
                        file_name: item?.nameWithoutExt,
                        type: item?.kind,
                        pos: item?.pos,
                        file_id: item?.id,
                        placement: item?.srchSrc,
                    };
                    const searchParams = { search_phrase: query, page_id: xPageId, req_id: xReqId };
                    if (action === 'delete') {
                        dwhItem = {
                            ...dwhItem,
                            extension: item?.isFolder ? 'folder' : item?.ext,
                        };
                    }
                    newSendSearchRadarAnalytics({
                        ...dwhData,
                        searchParams,
                        items: [dwhItem],
                    });

                    if (action === 'open') {
                        dwhData = {
                            eventCategory: ECategoryGa.entered,
                            action,
                        };

                        dwhItem = {
                            ...dwhItem,
                            mtime: item?.mtime,
                            size: item?.size || 0,
                            extension: item?.isFolder ? 'folder' : item?.ext,
                            placement: item?.srchSrc,
                        };

                        newSendSearchRadarAnalytics({
                            ...dwhData,
                            searchParams,
                            items: [dwhItem],
                        });
                    }
                }
            },
            [storage, currentItem, ext, isPublic, faces?.length, isEditable, query, xPageId, xReqId]
        );

        const closeViewer = useCallback(
            (noHistoryChange = false) => {
                onClose();
                if (!isGallery && !isQuotaCleaner && !noHistoryChange) {
                    changeStorageHistory({ id: folder?.id, storage });
                }
            },
            [parentId, onClose, storage]
        );

        /**
         * После обратноно маунта тулбара после анмаунта, руками показываем тулбар,
         * иначе он отобразиться только если потапать на экран
         */
        useEffect(() => {
            if (!showCustomItem && !isViewContentOnly) {
                setShowToolbar(true);
            }
        }, [showCustomItem, isViewContentOnly]);

        useEffect(() => {
            sendViewerGa('open', '', { count: itemsIdsList.length });

            sendDwh({ action: 'open' });

            const el = rootRef?.current;

            if (!(isQuotaCleaner && isIosDevice())) {
                scrollLock(el, { reserveScrollBarGap: false, allowTouchMove: true });
            }

            return () => {
                sendDwh({ action: 'close' });
                if (!(isQuotaCleaner && isIosDevice())) {
                    scrollUnlock(el);
                }
            };
        }, []);

        useEffect(() => {
            if (IS_B2B_BIZ_USER) {
                sendViewerGa(`b2b-${storage}`, IS_BLOCKED ? 'blocked' : 'nonbl');
            }
        }, [storage]);

        /**
         *  Когда открыли вьювер на 500+ фотографии и перезагрузили страницу.
         *  Скролимся до нужного элемента.
         */
        useEffect(() => {
            if (itemsIdsList.length === 0) {
                closeViewer();
                return;
            }

            if (swiper?.activeIndex !== itemIndex) {
                swiper?.virtual?.update(true);
                swiper?.slideTo(itemIndex, SWIPER_SPEED);
                setShowCustomItem(false);
            }
        }, [itemsIdsList.length, closeViewer, swiper, itemIndex]);

        // менять слайд, если нажали браузерную кнопку назад
        useEffect(() => {
            /**
             * TODO: убрать после починки роутера. на хомяки и стоках в currentRoute приходит не полный путь:
             * Вместо /папка/файл.jpg приходить только /папка.
             * Из-за этого вся логика ниже работает некорректно.
             */
            if (isSearch || isHome || isStock || isViewContentOnly || isPublic) {
                return;
            }

            // У элементов галерее нет своего пути.
            if (isGallery) {
                return;
            }

            if (isQuotaCleaner) {
                return;
            }

            if (itemId === currentRoute) {
                return;
            }

            const routeIndex = itemsIdsList.indexOf(currentRoute);

            if (routeIndex !== -1 && swiper) {
                swiper.slideTo(routeIndex, SWIPER_SPEED);
                setShowCustomItem(false);
            } else {
                closeViewer();
            }
        }, [closeViewer, currentRoute, isViewContentOnly]);

        useEffect(() => {
            // Сменился сторадж - мы куда-то перешли и надо закрыть просмотрщик
            if (storage !== currentItem?.storage) {
                closeViewer(true);
            }
        }, [storage, currentItem, closeViewer]);

        useEffect(() => {
            if (currentItem && swiper) {
                if (currentItem.kind !== 'image') {
                    swiper.zoom.disable();
                } else {
                    swiper.zoom.enable();
                }
            }
        }, [currentItem]);

        useEffect(() => {
            if (isViewContentOnly) {
                sendViewerGa('cnt-only', 'ext', { [ext]: 1 });
                sendViewerGa('cnt-only', 'stor', { [storage.replace(EStorageType.viewerAttaches, EStorageType.attaches)]: 1 });
            }
        }, [isViewContentOnly, ext, storage]);

        const handleClose = useCallback(
            (label: string) => {
                if (isViewContentOnly) {
                    return;
                }

                if (isAttaches && IS_MY_TEAM) {
                    window.location.href = '/home/';
                    return;
                }

                sendViewerGa('close', label);
                closeViewer();
            },
            [closeViewer, sendDwh, isViewContentOnly]
        );

        const handleSlideChange = useCallback(
            (swiper: SwiperCoreExt) => {
                const id = itemsIdsList[swiper.activeIndex];

                // если открыть просмотрщик не на первой фотке swiperJs вызывает slideChange, в этом случае отправлять радар не надо
                if (swipeCount !== 0 || itemId !== id) {
                    sendViewerGa('swipe', '', { swipeCount: swipeCount + 1 });
                    setSwiperCount((prevCount) => prevCount + 1);

                    sendDwh({
                        action: 'skip-photo',
                        type_click:
                            // Событие точно есть, но SwiperCore, описывает не все типы
                            (swiper as SwiperCoreExt & { swipeDirection: 'next' | 'prev' }).swipeDirection === 'next' ? 'next' : 'previous',
                    });
                }

                dispatch(selectItemIndexInArray({ itemIndex: swiper.activeIndex }));
            },
            [dispatch, itemId, sendDwh]
        );

        const handleSlideTouchStart = useCallback(() => {
            setSlideTransition(true);
        }, []);

        const handleSlideTouchEnd = useCallback(() => {
            setSlideTransition(false);
        }, []);

        const handleSlideChangeEnd = useCallback(
            (swiper) => {
                const id = itemsIdsList[swiper.activeIndex];
                if (!isGallery && !isQuotaCleaner && !isAttaches && id) {
                    if (itemId !== id) {
                        changeStorageHistory({ id, storage });
                    }

                    setIsZoom(false);
                }
            },
            [storage, itemId]
        );

        const handleZoom = useCallback((_: SwiperCoreExt, scale: number): void => {
            sendViewerGa('zoom');
            setShowToolbar(false);

            if (scale === 1) {
                setIsZoom(false);
            } else {
                setIsZoom(true);
            }
        }, []);

        const toggleToolbar = useCallback(() => {
            if (!timerId.current) {
                const id = window.setTimeout(() => {
                    setShowToolbar((prevValue) => !prevValue);
                    timerId.current = undefined;
                }, 500);
                timerId.current = id;
            }
        }, [timerId, setShowToolbar]);

        const handleOnDoubleClick = useCallback(() => {
            window.clearTimeout(timerId.current);
            timerId.current = undefined;
        }, [timerId]);

        const onInitialSwiper = useCallback((swiper: SwiperCoreExt) => {
            setSwiper(swiper);
        }, []);

        const closeOnSwipe = useCallback(() => {
            handleClose('swipe');
        }, [handleClose]);

        const openToolbarOptions = useCallback(() => {
            renderMobileOptionsModal({
                id: itemId,
                storage,
                onlyActions: true,
                sendAnalytics: sendDwh,
                skipRename: true,
                source: OptionsModalSource.MOBILE_VIEWER,
            });
        }, [itemId, sendDwh, storage]);

        const handleToggleCustomItem = useCallback((show: boolean) => {
            setShowCustomItem(show);
        }, []);

        if (showCustomItem && !itemId && prevItemId) {
            // Когда закрываем просмотр архива, чтобы не мелькала заглушка внутри MobileViewerItem
            // Она мелькает, если нажимаем кнопку назад браузера - тогда закрытие мы отрабатываем снаружи MobileViewerItem,
            // а он при этом может успеть перенедерится
            return null;
        }

        return (
            <div className={styles.root} ref={rootRef}>
                <SwipingDownComponent onClose={closeOnSwipe} lock={isLockSwipingDown}>
                    <div className={styles.content}>
                        {!showCustomItem && (
                            <CSSTransition in={showToolbar} timeout={SWIPER_SPEED} unmountOnExit exit enter classNames={animationClasses}>
                                <ViewerToolbar
                                    onBack={handleClose}
                                    ext={extOriginal}
                                    nameWithoutExt={nameWithoutExt}
                                    noReturn={noReturn}
                                    onClickOption={openToolbarOptions}
                                    subTitle={`${itemIndex + 1} из ${isSearch ? totalCountSearch : totalCount}`}
                                    itemId={itemId}
                                    faces={facesIdx}
                                />
                            </CSSTransition>
                        )}
                        <div className={styles.swiperWrapper}>
                            <Swiper
                                onSwiper={onInitialSwiper}
                                initialSlide={itemIndex}
                                spaceBetween={20}
                                onSlideChange={isViewContentOnly ? noop : handleSlideChange}
                                zoom
                                allowSlideNext={allowSlideNavigation}
                                allowSlidePrev={allowSlideNavigation}
                                onZoomChange={handleZoom}
                                onDoubleClick={isViewContentOnly ? noop : handleOnDoubleClick}
                                onClick={isViewContentOnly ? noop : toggleToolbar}
                                simulateTouch={IS_MY_TEAM}
                                onTouchStart={handleSlideTouchStart}
                                onTouchEnd={handleSlideTouchEnd}
                                onSlideChangeTransitionEnd={handleSlideChangeEnd}
                                slidesPerView={1}
                                virtual={virtualOptions}
                                resizeObserver={false}
                                modules={[Zoom, Virtual]}
                                speed={150}
                            >
                                {itemsIdsList.map((item, index) => {
                                    return (
                                        <SwiperSlide key={item} zoom virtualIndex={index}>
                                            <MobileViewerItem
                                                openToolbarOptions={openToolbarOptions}
                                                onToggleCustomItem={handleToggleCustomItem}
                                                selected={itemId === item}
                                                slideTransition={slideTransition}
                                                itemId={item}
                                                storage={storage}
                                                sendAnalytics={sendDwh}
                                                forceArchiveViewer={forceArchiveViewer}
                                                isViewContentOnly={isViewContentOnly}
                                                isPublic={isPublic}
                                                onClose={handleClose}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                        {showMyOfficeEditButton && (
                            <div className={styles.controls}>
                                <ViewerButton
                                    title="Редактировать документ"
                                    onClick={() => openEditor(currentItem)}
                                    icon={<EditIcon />}
                                    qaName="edit"
                                />
                            </div>
                        )}
                    </div>
                </SwipingDownComponent>
            </div>
        );
    }
);

MobileViewer.displayName = 'MobileViewer';
