import { createAction, createReducer } from '@reduxjs/toolkit';
import type { State } from 'reactApp/modules/socialUser/socialUser.types';

const initialState: State = {
    email: '',
    status: '',
    error: '',
    isLoaded: false,
    isLoading: false,
};

export const loadSocialUserRequest = createAction('@socialUser/loadSocialUserRequest');
export const loadSocialUserSuccess = createAction('@socialUser/loadSocialUserSuccess');
export const loadSocialUserFailure = createAction('@socialUser/loadSocialUserFailure');
export const checkEmailRequest = createAction('@socialUser/checkEmailRequest');
export const checkEmailSuccess = createAction<{ email; state }>('@socialUser/checkEmailSuccess');
export const checkEmailFailure = createAction<string>('@socialUser/checkEmailFailure');
export const addEmailRequest = createAction('@socialUser/addEmailRequest');
export const addEmailSuccess = createAction('@socialUser/addEmailSuccess');
export const addEmailFailure = createAction<string>('@socialUser/addEmailFailure');
export const confirmEmailRequest = createAction('@socialUser/confirmEmailRequest');
export const confirmEmailSuccess = createAction('@socialUser/confirmEmailSuccess');
export const confirmEmailFailure = createAction('@socialUser/confirmEmailFailure');
export const clearError = createAction('@socialUser/clearError');

export const socialUserReducer = createReducer(initialState, {
    [loadSocialUserRequest.type]: (state): void => {
        state.isLoading = true;
    },
    [loadSocialUserSuccess.type]: (state) => {
        state.isLoading = false;
        state.isLoaded = true;
    },
    [loadSocialUserFailure.type]: () => {
        return {
            ...initialState,
        };
    },
    [checkEmailSuccess.type]: (state, action: ReturnType<typeof checkEmailSuccess>) => {
        const { email, state: status } = action.payload;

        state.email = email;
        state.status = status;
    },
    [addEmailFailure.type]: (state, action: ReturnType<typeof addEmailFailure>) => {
        state.error = action.payload;
    },
    [clearError.type]: (state) => {
        state.error = '';
    },
});
