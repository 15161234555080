import type { IExtInfo } from 'lib/extInfo';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { calculateMaxEditableOrPreviewableSizeForEditor } from 'server/helpers/editors/calculateMaxEditableOrPreviewableSizeForEditor';
import { type ServerEditor, EditorMode } from 'server/helpers/editors/types';
import { getFeatureByName } from 'server/helpers/features/getFeatureByName';
import type { IFeatures } from 'server/helpers/features/getFeatures';
import { EEditorUserType } from 'server/types/context/IOnlineConf';

import { type IFolder, isPublicAlbum, isV2Folders } from '../../types/context/IPublicFolder';
import type { IPublicInfo } from '../public/getPublicInfo';
import { getAvailableEditorsFor } from './getEditors';

// Если в названии файла есть %, то decodeURIComponent упадет
const getId = (id: string): string => {
    try {
        return decodeURIComponent(id);
    } catch (_) {}

    return id;
};

export const getChosenEditor = ({
    userGroup,
    publicInfo,
    folder,
    viewers,
    editors,
    features,
}: {
    userGroup: EEditorUserType;
    features: IFeatures;
    publicInfo: IPublicInfo | undefined;
    folder: IFolder | undefined;
    viewers: ServerEditor[];
    editors: ServerEditor[];
}) => {
    const item = folder && isV2Folders(folder) ? folder?.folder : folder;

    if (!item || isPublicAlbum(item) || ('home' in item && typeof item.home === 'object' && item.home.error === 'not_exists')) {
        return;
    }

    const isEdit = Boolean(publicInfo?.isEditPublic);

    const availableEditors = getAvailableEditorsFor({ item, editors: isEdit ? editors : viewers, isEdit });

    const chosenEditor = availableEditors[0];

    const ext = getExtension(item).toLowerCase();

    // Запрещаем редактирование если размер файла больше разрешенного
    const overrideExtInfoFeature = getFeatureByName<IExtInfo>(features, 'override-ext-info');
    const overrideExtInfo = overrideExtInfoFeature?.[ext];
    const maxEditableSize =
        userGroup !== EEditorUserType.BIZ_USER && chosenEditor && overrideExtInfo && isEdit
            ? calculateMaxEditableOrPreviewableSizeForEditor(chosenEditor.id, overrideExtInfo?.maxEditableSize)
            : undefined;
    const isEditAvailable = typeof maxEditableSize === 'number' ? item.size <= maxEditableSize : true;

    const id = ('weblink' in item && item.weblink) || item.name;

    return (
        chosenEditor &&
        isEditAvailable && {
            ext,
            id: chosenEditor.id,
            mode: isEdit ? EditorMode.EDIT : EditorMode.VIEW,
            file: item.name,
            data: chosenEditor.edit_data?.(isEdit, getId(id)),
        }
    );
};
