import { type PayloadAction, createReducer } from '@reduxjs/toolkit';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import {
    addToFavoritesSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    unPublishWeblink,
} from 'reactApp/modules/modifying/modifying.actions';
import type {
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    RemoveFileSuccessAction,
    UnpublishWeblinkAction,
    UpdateWeblinkAction,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import { getExtension } from '../file/utils';
import {
    addFilesToAlbumSuccess,
    createAlbumSuccess,
    loadAlbumFail,
    loadAlbumsListSuccess,
    loadAlbumSuccess,
    resetAllSelectedItems,
    selectAllItems,
    setUploadAlbumId,
    toggleItem,
} from './albums.actions';
import { isAlbumAllowedExtension, normalizeAlbum, normalizeAlbumItem } from './albums.helpers';
import {
    type IAddItems,
    type IAlbum,
    type IAlbumId,
    type IAlbumsState,
    type ICreateAlbumSuccess,
    type ILoadAlbumsListSuccess,
    type ILoadAlbumSuccess,
    type SelectAllItems,
    type ToogleItem,
    isAlbum,
} from './albums.types';

const initialState: IAlbumsState = {
    list: {
        [ROOT_FOLDER_ID]: {
            childs: [],
            isLoaded: false,
            isLoading: true,
            type: 'album',
            id: ROOT_FOLDER_ID,
            name: '',
            parent: '',
            storage: EStorageType.albums,
            isFolder: true,
            kind: 'album',
        },
    },
    selectedItems: {},
};

export const albumReducer = createReducer(initialState, {
    [createAlbumSuccess.type]: (state, action: PayloadAction<ICreateAlbumSuccess>) => {
        const { id, name } = action.payload;
        const parent = state.list[ROOT_FOLDER_ID];

        if (!isAlbum(parent)) {
            return;
        }

        state.list[id] = {
            id,
            storage: EStorageType.albums,
            name,
            flags: { isMedia: true },
            parent: ROOT_FOLDER_ID,
            isLoaded: false,
            isLoading: true,
            childs: [],
            isFolder: true,
            type: 'album',
            kind: 'album',
        };

        parent.childs.unshift(id);
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>) => {
        const { id, weblink } = action.payload;
        const item = state.list[id];

        if (!item || !isAlbum(item)) {
            return;
        }

        item.weblink = weblink;
    },
    [loadAlbumFail.type]: (state, action: PayloadAction<IAlbumId>) => {
        const { albumId } = action.payload;
        const item = state.list[albumId];

        if (!item || !isAlbum(item)) {
            state.list[albumId] = {
                id: albumId,
                storage: EStorageType.albums,
                error: true,
                flags: { isMedia: true },
                parent: ROOT_FOLDER_ID,
                isLoaded: false,
                isLoading: true,
                childs: [],
                isFolder: true,
                type: 'album',
                name: '',
                kind: 'album',
            };

            return;
        }

        item.error = true;
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || !isAlbum(item)) {
                return;
            }

            item.weblink = '';
        });
    },
    [loadAlbumsListSuccess.type]: (state, action: PayloadAction<ILoadAlbumsListSuccess>) => {
        const { list, cursor } = action.payload;
        const childs: string[] = [];

        list.forEach((item) => {
            state.list[item.id] = normalizeAlbum(item);
            childs.push(item.id);
        });

        state.list[ROOT_FOLDER_ID] = {
            cursor,
            childs,
            isLoaded: true,
            isLoading: false,
            type: 'album',
            id: ROOT_FOLDER_ID,
            storage: EStorageType.albums,
            name: '',
            isFolder: true,
            parent: '',
            kind: 'album',
        };
    },
    [loadAlbumSuccess.type]: (state, action: PayloadAction<ILoadAlbumSuccess>) => {
        const { list, cursor, albumId, title } = action.payload;
        const parent = state.list[albumId] as IAlbum;
        const childs: string[] = [...(parent?.childs || [])];

        list.forEach((item) => {
            const album = normalizeAlbumItem(item, albumId);

            state.list[album.id] = album;
            childs.push(album.id);
        });

        state.list[albumId] = {
            ...parent,
            id: albumId,
            cursor,
            childs,
            isLoaded: true,
            isLoading: false,
            hasMoreToLoad: Boolean(cursor),
            storage: EStorageType.albums,
            parent: ROOT_FOLDER_ID,
            type: 'album',
            name: title,
            kind: 'album',
            flags: { isMedia: true },
        };
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || isAlbum(item)) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || isAlbum(item)) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const {
            newItem: { name },
            oldId: id,
        } = action.payload;

        const item = state.list[id];

        if (!item) {
            return;
        }

        state.list[id] = {
            ...item,
            name,
        };
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];
            const parent = state.list[item?.parent || ''];

            if (!item || !parent || !isAlbum(parent)) {
                return;
            }

            parent.childs = parent.childs.filter((child) => child !== id);
            delete state.list[id];
        });
    },
    [addFilesToAlbumSuccess.type]: (state, action: PayloadAction<IAddItems>) => {
        const { albumId, items } = action.payload;
        const album = state.list[albumId];

        if (!isAlbum(album)) {
            return;
        }

        const childs = [...album.childs];

        items.forEach((item) => {
            const ext = getExtension(item);

            if (item.storage !== EStorageType.albums || !isAlbumAllowedExtension(ext)) {
                return;
            }

            state.list[item.id] = item;
            childs.push(item.id);
        });

        album.childs = childs;
    },
    [setUploadAlbumId.type]: (state, action: PayloadAction<IAlbumId>) => {
        state.currentUploadAlbum = action.payload.albumId;
    },
    [toggleItem.type]: (state, action: PayloadAction<ToogleItem>) => {
        const { selectedItem } = action.payload;
        const selectedItems = { ...state.selectedItems };

        if (selectedItems[selectedItem.id]) {
            delete selectedItems[selectedItem.id];
        } else {
            selectedItems[selectedItem.id] = selectedItem;
        }

        return {
            ...state,
            selectedItems,
        };
    },
    [selectAllItems.type]: (state, action: PayloadAction<SelectAllItems>) => {
        const { allItems } = action.payload;
        const selectAll = Object.keys(state.selectedItems).length === Object.keys(allItems).length;

        return {
            ...state,
            selectedItems: selectAll ? {} : allItems,
        };
    },
    [resetAllSelectedItems.type]: (state) => {
        return {
            ...state,
            selectedItems: {},
        };
    },
});
