import { Rubles } from 'Cloud/Application/Rubles';
import { type ReactElement, useCallback, useEffect, useRef } from 'react';

interface IProps {
    adsUpdateTimerMs: number;
    adsFastUpdateTimerMs: number;
    renderItem: () => ReactElement | null;
    updateAds: () => void;
    suspendTimer?: boolean;
}

export function useShowAttachBannerTimer({ adsUpdateTimerMs, adsFastUpdateTimerMs, renderItem, updateAds, suspendTimer = false }: IProps) {
    const adsTimer = useRef<number | null>(null);
    const updateReason = useRef<string>('start');

    const setAdsTimer = useCallback(
        ({
            initialRender = false,
            shouldRender,
            isFastUpdate,
        }: {
            initialRender?: boolean;
            shouldRender: boolean;
            isFastUpdate: boolean;
        }) => {
            if (adsTimer.current) {
                window.clearTimeout(adsTimer.current);
                adsTimer.current = null;
            }

            if (suspendTimer) {
                return;
            }

            if (document.visibilityState !== 'visible') {
                if (initialRender) {
                    Rubles.setUpdateReason('start');
                    updateAds();
                }
                return;
            }

            if (shouldRender) {
                Rubles.setUpdateReason(updateReason.current);
                updateAds();
            }

            const timeout = isFastUpdate ? adsFastUpdateTimerMs : adsUpdateTimerMs;
            updateReason.current = isFastUpdate ? 'fastUpdate' : 'default';
            adsTimer.current = window.setTimeout(() => setAdsTimer({ shouldRender: true, isFastUpdate: false }), timeout);
        },
        [suspendTimer, adsUpdateTimerMs, adsFastUpdateTimerMs, updateAds]
    );

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setAdsTimer({ shouldRender: false, isFastUpdate: true });
            }
        };

        setTimeout(() => {
            setAdsTimer({ initialRender: true, shouldRender: true, isFastUpdate: false });
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }, 0);

        return () => {
            if (adsTimer.current) {
                window.clearTimeout(adsTimer.current);
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [setAdsTimer]);

    return renderItem();
}
