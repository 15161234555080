import type { Method } from 'lib/axios';
import { APICallV3Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateV3ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'favorites/remove';

class FavoritesDeleteAPIV3Call extends APICallV3Post {
    _method = method;
}

class FavoritesDeleteAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const getFavoritesDeleteAPICall = (): APICallV3Post | APICallV4 => {
    if (apiMigrateV3ToV4[method]) {
        return new FavoritesDeleteAPIV4Call();
    }

    return new FavoritesDeleteAPIV3Call();
};
