import { Checkbox } from '@vkontakte/vkui';
import React, { type FC, useState } from 'react';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { ImageEditorSurveyFooter } from './components/Footer/ImageEditorSurveyFooter';
import styles from './ImageEditSurvey.css';

interface Props {
    onClose: () => void;
    extension: string;
    size_file: number;
    source: string;
    id: string;
}

export const IMAGE_EDITOR_ID = 'image-editor';

export const ImageEditorSurvey: FC<Props> = ({ onClose, extension, id, source, size_file }) => {
    const [choices, setChoices] = useState<string[]>([]);
    const dwh = { extension, id_media: id, source, size_file };

    const handleSubmitClick = () => {
        sendDwh({
            eventCategory: ECategoryGa.photoEditor,
            action: 'send',
            dwhData: {
                choice: choices.length ? 'yes' : 'no',
                choices,
                ...dwh,
            },
        });

        storeHelper.markAsShown(IMAGE_EDITOR_ID);

        snackbarController.showSnackbar({
            type: SnackbarTypes.success,
            text: 'Мы записали ваш ответ',
            title: 'Спасибо',
            id: 'imageEditorSurvey',
            closable: true,
        });

        onClose();
    };

    const handleCloseClick = () => {
        sendDwh({
            eventCategory: ECategoryGa.photoEditor,
            action: 'close',
            dwhData: dwh,
        });

        storeHelper.markAsShown(IMAGE_EDITOR_ID);

        onClose();
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        const result = choices.includes(name) ? choices.filter((item) => item !== name) : [...choices, name];

        setChoices(result);
    };

    return (
        <Dialog
            closeOnDimmerClick={false}
            onCancel={handleCloseClick}
            footer={<ImageEditorSurveyFooter handleCloseClick={handleCloseClick} handleSubmitClick={handleSubmitClick} />}
            header="Редактирование фото вот-вот появится"
            open
            size="average"
            theme="dark"
        >
            <div className={styles.content}>
                <p>Расскажите, какие функции пригодились бы вам больше всего:</p>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="rotate"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}> Обрезка и поворот </span>
                </Checkbox>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="auto_upgrade"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}>Автоулучшение </span>
                </Checkbox>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="filters"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}>Фильтры </span>
                </Checkbox>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="stickers_and_text"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}> Стикеры и текст </span>
                </Checkbox>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="other"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}> Что-то другое </span>
                </Checkbox>
                <Checkbox
                    hasActive={false}
                    hoverMode={styles.hoverable}
                    name="no_need"
                    onChange={handleCheckboxChange}
                    className={styles.checkbox}
                >
                    <span className={styles.description}> Не буду редактировать фото </span>
                </Checkbox>
            </div>
        </Dialog>
    );
};
