import type { PayloadAction } from '@reduxjs/toolkit';
import { checkApiResponseDataEquals } from 'reactApp/api/apiHelpers';
import { GiftReceivedAPICall } from 'reactApp/api/billing/gift/GiftReceivedAPICall';
import { PromoListAPICall } from 'reactApp/api/promo/PromoListAPICall';
import { receivedGiftsSuccess } from 'reactApp/modules/giftReceived/actions/load.action';
import { getRawGiftsList } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { initProducts } from 'reactApp/modules/products/products.module';
import {
    initPromoTariffs as initPromoTariffsAction,
    promoTariffsFailure,
    promoTariffsStart,
    promoTariffsSuccess,
    promoTariffsUpdateFailure,
    promoTariffsUpdateStart,
    promoTariffsUpdateSuccess,
    updatePromoTariffs,
} from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { getDiscountByProductId, getRawList } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import type { TInitPromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { callSagaFromAction } from 'reactApp/utils/callSagaFromAction';
import ping from 'reactApp/utils/ping';
import { call, put, select, takeLatest } from 'redux-saga/effects';

// @ts-ignore
const promoApiCall = () => new PromoListAPICall().makeRequest();
// @ts-ignore
const giftReceivedAPICall = () => new GiftReceivedAPICall().makeRequest();

export function* initPromoTariffs(data: TInitPromoTariffs) {
    const { onSuccess, onError } = data || {};
    const promoList = yield select(getRawList);
    const giftsList = yield select(getRawGiftsList);

    try {
        if (promoList.length || giftsList.length) {
            onSuccess?.();
            return;
        }

        yield put(promoTariffsStart());
        const { data } = yield call(promoApiCall);
        yield put(promoTariffsSuccess({ list: data }));

        const { data: gifts } = yield call(giftReceivedAPICall);
        yield put(receivedGiftsSuccess(gifts));

        if (gifts?.length || data?.length) {
            yield put(initProducts());
        }

        onSuccess?.();
    } catch (error) {
        onError?.();
        yield loggerSaga({ error, action: promoTariffsFailure(error as string) });
    }
}

const handleInitPromoTariffs = (action: PayloadAction<TInitPromoTariffs>) =>
    callSagaFromAction<TInitPromoTariffs>(initPromoTariffs, action);

export function* handleUpdatePromoTariffs(action) {
    const { onSuccess, onError, promoId } = action.payload || {};
    try {
        if (promoId) {
            // @ts-ignore
            const product = yield select(getDiscountByProductId, promoId);
            if (product) {
                onSuccess?.();
                return;
            }
        }

        yield put(promoTariffsUpdateStart());

        const initialState = yield select(getRawList);

        const { data } = yield call(ping, { request: promoApiCall, check: checkApiResponseDataEquals(initialState) });

        yield put(promoTariffsUpdateSuccess({ list: data }));

        onSuccess?.();
    } catch (error) {
        onError?.();
        if (error instanceof Error) {
            yield loggerSaga({ error, action: promoTariffsUpdateFailure(error.toString()) });
        }
    }
}

export function* watchPromoTariffsRoot() {
    yield takeLatest(initPromoTariffsAction.toString(), handleInitPromoTariffs);
    yield takeLatest(updatePromoTariffs.toString(), handleUpdatePromoTariffs);
}
