import type { ChangeEvent, KeyboardEvent, ReactElement, RefObject } from 'react';
import type { ListItem } from 'reactApp/ui/DropdownList/DropdownList';

export enum EInputSuggestTheme {
    vk = 'vk',
}

export interface IProps<T = any> {
    value: string;
    placeholder?: string;
    hasError?: boolean;
    focus: boolean;
    disabled: boolean;
    theme?: EInputSuggestTheme;
    action?: ReactElement;
    onChange: (event: ChangeEvent<HTMLInputElement> | null, { value }: { value: string }) => void;
    suggestions: T[] | null;
    getSuggestionItemId: (item: T) => string;
    renderSuggestionItem: (item: T, width: number) => ReactElement | null;
    onRequestSuggestions: (value: string) => void;
    className?: string;
    inputClassName?: string;
}

export type TUseCallbacksProps = Pick<
    IProps,
    'suggestions' | 'onChange' | 'getSuggestionItemId' | 'renderSuggestionItem' | 'onRequestSuggestions' | 'disabled'
> & { inputRef: RefObject<HTMLInputElement>; rootRef: RefObject<HTMLDivElement> };

export type TUseCallbacks = (props: TUseCallbacksProps) => {
    handleInputKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    closeDropdown: () => void;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    calcDropdownPosition: () => { posX: number; posY: number };
    calcDropdownSize: () => void;
    renderItem: (item: ListItem) => ReactElement | null;
    showDropdown: boolean;
    list: ListItem[];
};
