import { Icon20Cancel } from '@vkontakte/icons';
import { IconButton } from '@vkontakte/vkui';
import MascotBiteIcon from 'img/icons/editor/llm/mascot_bite_llm.png';
import React from 'react';
import { useDispatch } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { toggleOpenLlmDialog } from 'reactApp/modules/editor/editor.module';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';

import styles from './EditorLLMDialogHeader.css';

export const EditorLLMDialogHeader = () => {
    const dispatch = useDispatch();
    const sendAnalytics = useGetLLMAnalytics();

    const handleClose = () => {
        dispatch(toggleOpenLlmDialog(false));
        sendAnalytics({ action: LLMAnalyticsType.CHAT_CLOSE });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.CHAT_CLOSE });
    };

    return (
        <div className={styles.header} data-qa-id="LLMDialog_header">
            {isRebranding && <img src={MascotBiteIcon} alt="mascot" className={styles.mascot} />}
            <span className={styles.header_title} data-qa-id="LLMDialog_header_title">
                {llmFeature.dialogTitle}
            </span>
            <IconButton onClick={handleClose} className={styles.closeButton} data-qa-id="LLMDialog_header_closeButton">
                <Icon20Cancel />
            </IconButton>
        </div>
    );
};
