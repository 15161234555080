import { Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as IconArrow } from 'img/icons/mobile/arrow.svg';
import React, { type MouseEvent, type ReactElement, useCallback } from 'react';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';

import styles from './SharingDropdown.css';
import { type IProps, EDropdownColors } from './SharingDropdown.types';

export const SharingDropdown = React.memo(function SharingNewDropdown(props: IProps): ReactElement {
    const { onClick, color = EDropdownColors, isActive, disabled, isPhone, isUserDropdown, inInput } = props;

    const handleClick = useCallback(
        (e: MouseEvent): void => {
            e.stopPropagation();
            onClick?.(e);
        },
        [onClick]
    );

    const isWindowSmall = useIsWindowIntegrationSmall();

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_color_${color}`]]: color,
                [styles.root_opened]: isActive,
                [styles.root_disabled]: disabled,
                [styles.root_in_input]: inInput,
                [styles.root_phone]: isPhone,
                [styles.root_in_user]: isUserDropdown,
                [styles.root_tutoria]: isWindowSmall,
            })}
            onClick={handleClick}
            data-qa-value={props.qaValue}
        >
            <div className={styles.container}>
                <div className={styles.text}>{props.value}</div>
                {isPhone ? <IconArrow className={styles.icon} /> : <Icon20Dropdown className={styles.icon} />}
            </div>
        </div>
    );
});
