import { toNDigits } from '@mail/cross-sizes-utils';
import { Rubles } from 'Cloud/Application/Rubles';
import sha1 from 'js-sha1';
import { pathOr } from 'ramda';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { HIDE_ADS, IS_PUBLIC_FOLDER, IS_PUBLIC_OF_OVERQUOTA, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { mailSpaceTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { publicOverquota } from 'reactApp/appHelpers/featuresHelpers/features/publicOverquota';
import { getQueryParams, isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { isWeblinkDownloadable } from 'reactApp/modules/public/public.selectors';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { hideSnackbarAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import type { StockFolder } from 'reactApp/modules/stock/stock.type';
import { getCurrentItem, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { removeViewerPlaceholder } from 'reactApp/modules/viewer/viewer.helpers';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { EmptyPublicOverquota } from 'reactApp/ui/Empty/new/EmptyPublicOverquota';
import { MailspacePublicTrialBanner } from 'reactApp/ui/MailspacePublicTrialBanner/MailspacePublicTrialBanner';
import { MobileViewerItem } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerItem/MobileViewerItem';
import { useCallbacks } from 'reactApp/ui/Mobile/OptionsModal/hooks/useCallbacks';
import { OptionsModalSource } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.constants';
import { renderMobileOptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.helpers';
import { ViewerToolbarMobileNew } from 'reactApp/ui/Mobile/ViewerToolbarMobileNew/ViewerToolbarMobileNew';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { RollContentAds } from 'reactApp/ui/RollContent/RollContentAds';
import { handleOnMobileApp } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.helpers';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { sendXray } from 'reactApp/utils/ga';
import { sendCurrTimeRadar, sendPerfMetrics } from 'reactApp/utils/helpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './PublicFile.css';

// 'public-file' - общая категория событий для белого и черного одиночного паблика
const EVENT_CATEGORY = 'public-file';

// высота тулбара
const HEADER_HEIGHT = 66 + 50;

// высота баннера рекламы
const DEFAULT_TOP_HEIGHT = 114;

export const PublicFileConnected = memo(({ storage }: { storage: EStorageType }) => {
    const [isVisible, setIsVisible] = useState(false);

    const isAuthorized = !useSelector(UserSelectors.isAnonymous);
    const isBiz = useSelector(UserSelectors.isBizUser);
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const isDownloadable = useSelector(isWeblinkDownloadable);
    const withMailSpacePromo = mailSpaceTrialPromo && mailSpaceTrialPromo !== 'a';

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];

    const isSuperAppWebView = isWebview && fromDeeplink;

    const showDisabledForOverquotaOwner = IS_PUBLIC_OF_OVERQUOTA && publicOverquota;

    const fileOrFolder = useSelector(getCurrentItem) as CloudFile;
    let routeId = useSelector(getCurrentRouteId);
    if (!IS_PUBLIC_FOLDER && storage === EStorageType.stock) {
        routeId = (fileOrFolder as unknown as StockFolder)?.childs[0];
    }
    const file = useSelector((state: RootState) => getStorageItemById(state, storage, routeId)) as CloudFile;

    const params = getQueryParams();
    const viewerOnly = params?.viewerOnly;

    const dispatch = useDispatch();

    const item: IFile = {
        id: file?.id,
        name: file?.isFolder ? file?.name : file?.nameWithoutExt ?? '',
        size: toNDigits({ bytes: file?.size, maxDigitsCount: 3 })?.value,
        extension: file?.ext,
        date: pathOr(0, ['mtime'], file),
        expires: pathOr(0, ['weblinkExpires'], file),
        subKind: pathOr('', ['subKind'], file),
    };

    const sendDwh = useCallback(
        (data) => {
            sendViewerDwh({
                id_media: sha1(file?.id || ''),
                source: `touch-${storage}`,
                is_stories: false,
                id_public: getWeblinkFromPublicId(file?.id) ?? 'None',
                ...data,
            });
        },
        [file, storage]
    );

    useEffect(() => {
        sendPerfMetrics(`mpubfile`);
        sendCurrTimeRadar(`mpubfile_mnt`);

        Rubles.runPhoneTopBannerUpdater();

        if (!isBiz && !isWebview) {
            Rubles.updateMobSplash();
        }
    }, []);

    useEffect(() => {
        if (!file) {
            return;
        }

        if (!isWebview && !viewerOnly) {
            dispatch(
                showSnackbarAction({
                    id: `download-app`,
                    type: SnackbarTypes.mobileAppDownload,
                    text: 'Удобнее смотреть файлы в Облаке',
                    iconSize: 'flex',
                    closable: true,
                    disableCloseTimeout: true,
                    buttonText: 'Скачать приложение',
                    onButtonClick: handleOnMobileApp,
                })
            );

            const hideSnackbar = () => dispatch(hideSnackbarAction('download-app'));
            document.addEventListener('click', hideSnackbar);
            return () => document.removeEventListener('click', hideSnackbar);
        }

        removeViewerPlaceholder();
    }, [file]);

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${storage}/`, ''),
        search: location.search,
    });

    const onIntersecting = () => {
        setIsVisible(true);
    };
    const onIntersectingOut = () => {
        setIsVisible(false);
    };

    const refIntersection = useIntersectionObserver({
        onIntersecting,
        onIntersectingOut,
        rootMargin: '30px',
    });

    const handleOnClone = useCallback(() => {
        const eventAction = isAuthorized ? 'clone-click' : 'auth-click';

        sendGA(eventAction);

        sendXray([EVENT_CATEGORY, eventAction]);

        if (isAuthorized) {
            toolbarActions.clone({
                id: item.id,
                destination: '',
                source: 'public-files',
            });
        } else {
            dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: `${window.location.href}${window.location.search}`,
                })
            );
        }
    }, [isAuthorized, item.id]);

    const sendGA = (action) => {
        sendXray(['publ-viewic-file', action, isAuthorized ? 'user' : 'anon']);
    };

    const { onDownload: onDownloadHook } = useCallbacks({
        storage,
        id: item.id,
        onClose: handleOnClone,
        sendAnalytics: sendGA,
        isAppViewer: params && Boolean(params.is_app_viewer),
        source: OptionsModalSource.PUBLIC_FILE,
    });

    const openToolbarOptions = useCallback(() => {
        renderMobileOptionsModal({
            id: item.id,
            storage,
            onlyActions: true,
            sendAnalytics: sendDwh,
            skipRename: true,
            skipDelete: true,
            source: OptionsModalSource.PUBLIC_FILE,
        });
    }, [item.id, sendDwh, storage]);

    const handleOnDownload = () => {
        const eventAction = 'download-click';
        sendGA(eventAction);

        sendXray([EVENT_CATEGORY, eventAction]);

        onDownloadHook();
    };

    const content = useMemo(() => {
        if (showDisabledForOverquotaOwner) {
            return (
                <div className={styles.wrapperQuota}>
                    <EmptyPublicOverquota forceDark={!PUBLIC_SHOVE} />
                </div>
            );
        }

        if (!file) {
            return null;
        }

        return (
            <MobileViewerItem
                isPublic
                selected
                forceArchiveViewer
                itemId={file?.id}
                isSingle={true}
                handleDownload={() => downloadItem({ itemOrId: file, fromViewer: true })}
                storage={storage}
                isViewContentOnly={isViewContentOnly}
                sendAnalytics={sendDwh}
                openToolbarOptions={openToolbarOptions}
                showToolbarInArchive={isViewContentOnly}
                isDownloadable={isDownloadable}
            />
        );
    }, [file, showDisabledForOverquotaOwner]);

    const minHeight = `max(calc(var(--dvh, --vh)*100 - ${window.scrollY + HEADER_HEIGHT + (HIDE_ADS ? 0 : DEFAULT_TOP_HEIGHT)}px), 50vh)`;

    const ref = useRef(null);

    if (!file) {
        return null;
    }

    return (
        <div className={styles.root}>
            {!viewerOnly && <ViewerToolbarMobileNew file={item} openToolbarOptions={openToolbarOptions} />}

            <div className={styles.content} style={{ minHeight, maxHeight: minHeight }} ref={ref}>
                {content}
            </div>

            {withMailSpacePromo && <MailspacePublicTrialBanner />}

            {isDownloadable && (
                <div className={styles.buttonWrapper}>
                    <Button className={styles.downloadButton} onClick={handleOnDownload} size="big" theme="base" middle secondary>
                        Скачать {item.size}
                    </Button>
                </div>
            )}

            {!HIDE_ADS && !isViewContentOnly && !isSuperAppWebView && (
                <div className={styles.adsWrapper} ref={refIntersection}>
                    {isVisible && <RollContentAds darkMode showElementIsInViewport />}
                </div>
            )}
        </div>
    );
});

PublicFileConnected.displayName = 'PublicFileConnected';
