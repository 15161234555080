import { xray } from 'lib/xray';
import React, { type MouseEvent, type ReactElement, type RefObject, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSendSpaceMenuAnalytics } from 'reactApp/hooks/UseSendSpaceMenuAnalytics';
import { getHideCleanerInSpaceFeature } from 'reactApp/modules/features/features.selectors';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { type ListItem, DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import opener from 'reactApp/utils/opener';

import styles from './SingleSpaceMenu.css';
import { type IMenuItem, EMenuItemId, getSpaceMenuList } from './SingleSpaceMenu.data';

interface IProps {
    isSubscriptionsPage?: boolean;
    isMobile: boolean;
    isMySubscription?: boolean;
    onClose: (event?: MouseEvent) => void;
    parentRef?: RefObject<HTMLElement>;
}

export const SingleSpaceMenu = memo(
    ({ isSubscriptionsPage = false, isMobile, onClose, parentRef, isMySubscription = false }: IProps): ReactElement | null => {
        const sendAnalytics = useSendSpaceMenuAnalytics(isMobile);

        const hideCleaner = useSelector(getHideCleanerInSpaceFeature);

        const title = 'Единое хранилище';

        const onItemClick = useCallback(
            (item: IMenuItem) => () => {
                if (item.id === EMenuItemId.cleaner) {
                    xray.send(`quota_cleaner_space_open${isMobile ? '_mob' : ''}`);
                }
                sendAnalytics(item.id);
                opener(item.url);
            },
            [sendAnalytics]
        );

        const list = useMemo(
            () =>
                getSpaceMenuList({
                    isSubscriptionsPage,
                    hideCleaner: isMobile && hideCleaner,
                    isMySubscription,
                }).map((item) => ({ ...item, onClick: onItemClick(item) })),
            [hideCleaner, isMobile, isMySubscription, isSubscriptionsPage, onItemClick]
        );

        const findDropdownPosition = (): { posX: number; posY: number } => {
            let posX = 0;
            let posY = 0;

            if (parentRef?.current) {
                const elRect = parentRef.current.getBoundingClientRect();

                posY = elRect.height + window.scrollY + elRect.top + 2;
                posX = elRect.left + window.scrollX;
            }

            return { posX, posY };
        };

        const renderDropdownItem = (item: ListItem): ReactElement => {
            return <DropdownItemAction text={item.text} icon={item.icon} mod={DropdownTheme.space} />;
        };

        useEffect(() => {
            sendAnalytics('menu');
        }, []);

        if (!list.length) {
            return null;
        }

        if (isMobile) {
            const mobileTitle = <div className={styles.title}>{title}</div>;

            return (
                <MobileDialog title={mobileTitle} id="mobile-space-menu" onClose={onClose} closeOnDimmerClick mod="base" topmost>
                    <div className={styles.menu}>
                        {list.map((item) => (
                            <div className={styles.menuItem} key={item.id} onClick={item.onClick}>
                                <div className={styles.icon}>{item.iconMobile}</div>
                                <div className={styles.text}>{item.text}</div>
                            </div>
                        ))}
                    </div>
                </MobileDialog>
            );
        }

        return (
            <DropdownList
                gaId="datalist-control"
                list={list}
                close={onClose}
                closeOnScroll
                closeOnMouseLeave
                parentRef={parentRef}
                title={title}
                calcPosition={findDropdownPosition}
                renderItem={renderDropdownItem}
                font={DropdownFont.VKSansDisplay}
                theme={DropdownTheme.space}
            />
        );
    }
);

SingleSpaceMenu.displayName = 'SingleSpaceMenu';
