import type { EditorID } from 'Cloud/Application/Editor/types';
import type { DocumentEditorsAndViewersExtraConfig } from 'lib/extInfo';
/**
 * Подсчитывает максимальный размер файла доступный на просмотр или редактирование файла
 *
 * @param editorId - Имя просмотрщика или редактора документов
 * @param maxSize - Максимальный размер файла
 */
export const calculateMaxEditableOrPreviewableSizeForEditor = (
    editorId: EditorID | 'default' = 'default',
    maxSize?: DocumentEditorsAndViewersExtraConfig | number | null
): number => {
    if (!maxSize || !['object', 'number'].includes(typeof maxSize)) {
        return 0;
    }

    if (typeof maxSize === 'object') {
        return maxSize[editorId || 'default'] || maxSize.default || 0;
    }

    return maxSize;
};
