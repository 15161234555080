import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { splitEvery } from 'ramda';
import { isCancellable, isFolder } from 'reactApp/modules/uploadList/uploadList.getters';
import { removeFields } from 'reactApp/modules/uploadList/uploadList.helpers';
import {
    type IChangeItem,
    type IInputFile,
    type INormalizedInputFile,
    type ISelectedError,
    type IUpdateInputFile,
    EFileError,
    EFileStatus,
} from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { getGroupedFiles } from 'reactApp/modules/uploadList/uploadList.selectors';
import { store } from 'reactApp/store';
import { ActionsBatcher } from 'reactApp/utils/reduxHelpers';
import { call, put, select } from 'redux-saga/effects';

const updateUploadFilesActionBatcher = new ActionsBatcher((items) => {
    const chunks = splitEvery<IUpdateInputFile>(100, items);

    chunks?.forEach((chunk) => {
        store.dispatch(updateUploadFilesAction(chunk));
    });
}, 500);

export function* removeUploadFiles(action: PayloadAction<IChangeItem[]>) {
    const { payload } = action;

    // TODO: нужно группировать, иначе будет много перерендеров?
    for (const element of payload) {
        const deleteFileWrap = (file) => deleteFile(file, element);
        yield call(doActionOnFile, deleteFileWrap);
    }
}

export function* cancelAllUploadFile() {
    try {
        const result = yield call(doActionOnFile, cancelFile);
        if (result?.length > 0) {
            const chunks = splitEvery<IUpdateInputFile>(100, result);

            for (const chunk of chunks) {
                yield put(updateUploadFilesAction(chunk));
            }
        }
    } catch (error) {
        logger.error(error);
    }
}

export function* hideErrorFiles(action: PayloadAction<ISelectedError>) {
    const { payload } = action;

    if (!payload?.userClick) {
        return;
    }

    const hideErrorFileWrap = (file) => hideErrorFile(file, payload.file);
    yield call(doActionOnFile, hideErrorFileWrap);
}

function* deleteFile(file: IInputFile, { id, isFolder: isFolderItem }: IChangeItem) {
    let editedFile;

    // удаляем элементы внутри папки, саму папку удалим в updateFileInFolder
    // или удаляем отдельный файл в папке куда заливали изначально или внутри загруженной папки
    if (
        (isFolderItem && (file.cloudPath.startsWith(id) || file.folderCloudPath?.startsWith(id))) ||
        file.cloudPath === id ||
        file.descriptorId === id
    ) {
        editedFile = { ...file };
    }

    if (editedFile) {
        editedFile.status = EFileStatus.DELETING;
        yield put(updateUploadFilesAction(editedFile));
    }
}
/* eslint-disable require-yield */
function* cancelFile(file: IInputFile) {
    if (!isCancellable(file)) {
        return;
    }
    const editedFile: IInputFile = { ...file };
    editedFile.status = EFileStatus.CANCEL;
    editedFile.error = EFileError.CANCELLED_FILE;

    return editedFile;
}

function* hideErrorFile(file: IInputFile, payload?: IInputFile) {
    if (isFolder(file) || !payload) {
        return;
    }

    const editedFile: IInputFile = removeFields(file);

    if (isFolder(payload)) {
        // если этот файл принадлежит выбранной папке, то показываем ошибку, иначе скрываем
        const hideError = !(payload.files && payload.files[file.descriptorId]);

        updateUploadFilesActionBatcher.push({ ...editedFile, hideError });
    } else if (file.error && file.hideError) {
        // если выбранный элемент не папка, то показываем все ошибки
        yield put(updateUploadFilesAction({ ...editedFile, hideError: false }));
    }
}

function* doActionOnFile(callback: (file: IInputFile) => void) {
    const groupedFiles: INormalizedInputFile = yield select(getGroupedFiles);

    const result: IInputFile[] = [];

    for (const groupedFile in groupedFiles) {
        const files = groupedFiles[groupedFile].files;

        if (files) {
            for (const key in files) {
                const res = yield call(callback, files[key]);
                if (res) {
                    result.push(res);
                }
            }
        }

        const res = yield call(callback, groupedFiles[groupedFile]);
        if (res) {
            result.push(res);
        }
    }

    return result;
}
