import classNames from 'clsx';
import appStoreImage from 'img/quota-landing/appStore.png';
import appStoreImageRebranding from 'img/quota-landing/appStore_rebranding.png';
import googlePlayImage from 'img/quota-landing/googlePlay.png';
import googlePlayImageRebranding from 'img/quota-landing/googlePlay_rebranding.png';
import React, { type ReactElement, memo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './PayInBrowser.css';
import { sendGa } from './PayInBrowser.helpers';

const MOBILE_URL = 'https://cloud.mail.ru/mobile';
const QUOTA_URL = 'https://cloud.mail.ru/promo/quota';

export interface IProps {
    id: string;
    onOpen(id: string): void;
    onCopy(): void;
    onClose(): void;
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    isRebrandingQuotaLanding?: boolean;
}

export const PayInBrowser = memo(function PayInBrowser(props: IProps): ReactElement | null {
    const { onClose: handleClose, onOpen, onCopy: onCopyUrl, id, isIOSPromo, isAndroidPromo, isRebrandingQuotaLanding } = props;

    const currentStorage = useSelector(getCurrentStorage);
    const { isQuotaCleaner } = getStorage(currentStorage);

    const currentURL = isIOSPromo || isAndroidPromo ? QUOTA_URL : MOBILE_URL;
    const title = (
        <>
            {isIOSPromo ? 'App Store' : 'Google Play'} ограничил
            <br />
            продажу тарифов в России
        </>
    );

    const onCopy = useCallback(() => {
        onCopyUrl();
        toolbarActions.shareLink({ url: currentURL, useNativeShare: false });
        sendGa('copy');
    }, [onCopyUrl]);

    const onClose = useCallback(() => {
        handleClose();
        sendGa('close');
    }, [handleClose]);

    useEffect(() => {
        onOpen(id);
        sendGa('open');
    }, []);

    const ref = useRef<HTMLDivElement>(null);

    return (
        <MobileDialog
            id="pay-in-browser"
            open
            dimmer
            onClose={onClose}
            disableScrolling={!isQuotaCleaner}
            closable
            closeOnDimmerClick
            mod="base"
            topmost
            scrollRef={ref}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        >
            <div className={classNames(styles.root, { [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding })} ref={ref}>
                <img
                    src={
                        isIOSPromo
                            ? isRebrandingQuotaLanding
                                ? appStoreImageRebranding
                                : appStoreImage
                            : isRebrandingQuotaLanding
                            ? googlePlayImageRebranding
                            : googlePlayImage
                    }
                    className={styles.image}
                />
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>
                    Для оплаты тарифа воспользуйтесь <span>веб-версией</span> Облака
                </div>

                <div className={styles.copyUrl}>
                    <div className={styles.url} onClick={onCopy}>
                        {currentURL}
                    </div>
                </div>
            </div>
        </MobileDialog>
    );
});
