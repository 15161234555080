import type { Space } from '@mail/cross-sizes-utils';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type VFC } from 'react';
import { EStatus } from 'reactApp/modules/subscriptions/subscriptions.types';

import styles from './CommonInfo.css';
import { getNumberOfShot, getStatus } from './CommonInfo.helpers';

type Props = {
    space: Space;
    isBase?: boolean;
    status?: EStatus;
    customTitle?: string;
    isRebrandingQuotaLanding?: boolean;
};

export const CommonInfo: VFC<Props> = ({ space, status, isBase, customTitle, isRebrandingQuotaLanding }) => {
    const numberOfShot = getNumberOfShot(space.original);

    return (
        <div className={classNames(styles.root, { [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}>
            {customTitle ? (
                <>
                    <Spacing size={8} />
                    <Text className={styles.customTitle}>{customTitle}</Text>
                    <Spacing size={12} />
                </>
            ) : (
                <div className={styles.availableSpace}>
                    <span className={styles.space}>{space.space}</span>
                    <span className={styles.units}>{space.units}</span>
                </div>
            )}
            <span className={styles.numberOfShot}>{numberOfShot}</span>
            <span
                className={classNames(styles.status, {
                    [styles.status_active]: isBase || status === EStatus.ACTIVE,
                    [styles.status_warning]: status && [EStatus.SUSPENDED, EStatus.FINISHED].includes(status),
                })}
            >
                {getStatus(status)}
            </span>
        </div>
    );
};
