import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharingNewAddUser } from 'reactApp/components/SharingNewBiz/AddUser/SharingNewAddUser';
import { SharingNewSection } from 'reactApp/components/SharingNewBiz/Section/SharingNewSection';
import { EAccessRights, EFrom } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { User } from 'reactApp/components/SharingNewBiz/User/User';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import {
    accessControlListLoadStart,
    accessControlListReset,
    deleteUser,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { isAccessControlListLoading } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import type { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { type ITabMenuItem, ETabMenuAlign, ETabMenuColor } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';

import stylesWeblink from '../Weblink/SharingNewWeblink.css';
import styles from './SharingNewSharing.css';
import type { IProps } from './SharingNewSharing.types';
import SharingNewSharingToggler from './SharingNewSharingToggler';

type ActiveTab = EAccessRights | 'all';

const TABS: ITabMenuItem[] = [
    {
        name: 'Все',
        value: 'all',
    },
    {
        name: 'Просмотр',
        value: EAccessRights.READ_ONLY,
    },
    {
        name: 'Редактирование',
        value: EAccessRights.READ_WRITE,
    },
];

// eslint-disable-next-line max-lines-per-function
export const SharingNewSharing = React.memo(function SharingNewSharing(props: IProps): ReactElement | null {
    const dispatch = useDispatch();

    const {
        item,
        onClose,
        isMounted: mounted,
        isPhone,
        from,
        showUsersScreen,
        setShowUsersScreen,
        acList,
        isCloudUsersOpened,
        setCloudUsersOpened,
        owner,
        currentUserEmail,
        isAlbum,
    } = props;
    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);

    const isLoading = useSelector(isAccessControlListLoading);
    const domain = useSelector(UserSelectors.getDomain);
    const tabCountMap = useMemo(
        () => ({
            all: acList.length,
            [EAccessRights.READ_ONLY]: acList.filter((user) => user.accessRights === EAccessRights.READ_ONLY).length,
            [EAccessRights.READ_WRITE]: acList.filter((user) => user.accessRights === EAccessRights.READ_WRITE).length,
        }),
        [acList]
    );
    const storage = useSelector(getCurrentStorage);
    const { isSharedIncoming } = getStorage(storage);

    // Блок раскрыт если есть приглашенные пользователи и если пользователь пришел из блока "Настроить доступ"
    const isFromSharing = from === EFrom.SHARING;
    const isReadOnlyItem = item && 'isReadOnly' in item ? item.isReadOnly : false;

    const [filteredActList, setfFilteredActList] = useState<IACListItem[]>([]);
    const [activeTab, setActiveTab] = useState<ActiveTab>('all');

    const isUserLoaded = isLoaded && !isUserError;

    // FIXME: CLOUDWEB-13574. Костыль на случай если у монтированной папки не флага "mounted"
    const isMounted = mounted || currentUserEmail !== owner?.email;

    useEffect(() => {
        const newActList: IACListItem[] = acList;
        if (activeTab === 'all') {
            setfFilteredActList(acList);
        } else {
            setfFilteredActList(newActList.filter((item) => item.accessRights === activeTab));
        }
    }, [activeTab, acList]);

    const cleanupExternalUsers = useCallback(() => {
        if (item !== undefined) {
            filteredActList
                .filter((user) => !user.email.includes(domain || ''))
                .forEach((user) => dispatch(deleteUser({ email: user.email, item })));
        }
    }, [dispatch, domain, filteredActList, item]);

    const renderTabMenu = useMemo(() => {
        return (
            <div className={styles.tabs}>
                <TabMenu bottomLine align={ETabMenuAlign.left}>
                    {TABS.map(({ name, value }) => {
                        const count = tabCountMap[value];

                        return (
                            <TabMenuItem
                                key={name}
                                name={`${name}${count > 0 ? ` ${count}` : ''}`}
                                onClick={(value) => setActiveTab(value)}
                                value={value}
                                active={activeTab === value}
                                color={ETabMenuColor.secondary}
                                disabled={count === 0}
                            />
                        );
                    })}
                </TabMenu>
            </div>
        );
    }, [tabCountMap, activeTab]);

    const renderItem = useCallback(
        (user: IACListItem) => {
            return (
                <div className={styles.user} key={user.email}>
                    <User
                        user={user}
                        item={item}
                        isMounted={isMounted}
                        currentUserEmail={currentUserEmail}
                        onClose={onClose}
                        publicId={item?.weblink}
                        isPhone={isPhone}
                    />
                </div>
            );
        },
        [currentUserEmail, isMounted, item, onClose, isPhone]
    );

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        // пробрасывается сторадж, чтобы корректно доставать итем, при шаринге новых папок на вкладке - "Вы поделились"
        dispatch(accessControlListLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, item?.storage]);

    useEffect(() => {
        if (!isUserLoaded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(loadUser).then(() => loadAllData());
        } else {
            loadAllData();
        }

        return function cleanup() {
            dispatch(accessControlListReset());
        };
    }, [dispatch, isUserLoaded, loadAllData]);

    useEffect(() => {
        if (isFromSharing) {
            return;
        }

        // Если в списке есть юзеры, а он скрыт - надо открыть. Для первого открытия со списком.
        if (!isCloudUsersOpened && !!acList.length && !isLoading) {
            setCloudUsersOpened(true);
        }
    }, [acList.length, isCloudUsersOpened, isFromSharing, isLoading]);

    const userList = useMemo(() => {
        const name = owner?.name || '';
        const email = name ? ` (${owner?.email})` : owner?.email;

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_phone]: isPhone,
                    [styles.mounted]: isSharedIncoming,
                })}
            >
                {isMounted && owner && (
                    <div className={styles.mountedRights}>
                        <span className={styles.mountedRightsText}>
                            {name}
                            {email}
                        </span>{' '}
                        предоставил вам доступ на
                        {isReadOnlyItem ? ' чтение' : ' редактирование'}
                    </div>
                )}
                <div className={classNames(styles.usersList, { [styles.usersList_scroll]: !isMounted && acList.length > 2 })}>
                    {isLoading ? (
                        <div className={styles.loading}>
                            <Loader centered isModal />
                        </div>
                    ) : (
                        <>
                            {renderTabMenu}
                            {Boolean(owner) && (
                                <div className={styles.user}>
                                    <User user={owner as IACListItem} isPhone={isPhone} isOwner />
                                </div>
                            )}
                            {filteredActList.map((user) => renderItem(user))}
                        </>
                    )}
                </div>
            </div>
        );
    }, [acList, filteredActList, isLoading, isMounted, isSharedIncoming, owner, renderItem, renderTabMenu, isReadOnlyItem, isPhone]);

    if (isMounted) {
        return userList;
    }

    return (
        <SharingNewSection isOpened name="cloud-users" className={styles.section} isPhone={isPhone}>
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={classNames(stylesWeblink.innerContent, { [stylesWeblink.short]: !showUsersScreen })}>
                    {!showUsersScreen && (
                        <SharingNewSharingToggler isCloudUsersOpened={isCloudUsersOpened} setShowUsersScreen={setShowUsersScreen} />
                    )}

                    {showUsersScreen && (
                        <>
                            <SharingNewAddUser
                                folderId={item?.id}
                                isMounted={isMounted}
                                publicId={item?.weblink}
                                isPhone={isPhone}
                                item={item}
                                isAlbum={isAlbum}
                                domain={domain}
                                onCleanupExternalUsers={cleanupExternalUsers}
                            />
                            {userList}
                        </>
                    )}
                </div>
            </div>
        </SharingNewSection>
    );
});
