import type { Method } from 'lib/axios';

import { APICallV4 } from '../APICall';

export class WeblinksSetFlagsV4ApiCall extends APICallV4 {
    _method = 'weblinks/id/set_flags';
    _type: Method = 'post';
}

export const weblinksSetFlagsV4ApiCall = ({ weblink, flags }) => new WeblinksSetFlagsV4ApiCall().makeRequest({ weblink, flags });
