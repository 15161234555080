import React, { type ReactNode, useCallback } from 'react';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';

interface IDialogContent {
    renderDialogHeader: () => ReactNode;
    renderDialogContent: () => ReactNode;
}

export const useConfirmDialogContent = ({ name, expires }): IDialogContent => {
    const renderDialogHeader = useCallback(() => <>Вы уверены, что файлы нужно удалить?</>, []);

    const renderDialogContent = useCallback(
        () => (
            <div className={styles.dialogContent}>
                <span>{formatAutoDeleteExpires(expires)}</span> ваши файлы в папке «{name}» будут автоматически удалены из вашего Облака. До
                удаления файлов эту настройку можно отключить
            </div>
        ),
        [name, expires]
    );

    return {
        renderDialogHeader,
        renderDialogContent,
    };
};
