import { Icon20FullscreenExitOutline, Icon20FullscreenOutline } from '@vkontakte/icons';
import React from 'react';
import { EActions } from 'reactApp/modules/storage/storage.types';
import type { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { fullScreenProcessor } from 'reactApp/utils/fullScreenProcessor';

fullScreenProcessor.init();

export const getFullScreenItem = (): TToolbarItem => {
    return {
        id: EActions.fullscreen,
        icon: fullScreenProcessor.isFullScreen() ? (
            <Icon20FullscreenExitOutline width={20} height={20} />
        ) : (
            <Icon20FullscreenOutline width={20} height={20} />
        ),
        onClick: fullScreenProcessor.switchFullscreen,
        hint: 'Полный экран',
        text: 'Полный экран',
        collapse: true,
        disabled: fullScreenProcessor.isFullScreenBrowserOnly(),
    };
};
