import videojs from 'video.js';

import type { ConcreteButton } from './ConcreteButton';
import type { HlsQualitySelectorPlugin, VideoPlayerWithHlsQualitySelectorPlugin } from './plugin';

// Concrete classes
const VideoJsMenuItemClass = videojs.getComponent('MenuItem');

interface IItem {
    label: string;
    value: number | string;
    selected?: boolean;
}
export class ConcreteMenuItem extends VideoJsMenuItemClass {
    item: IItem;
    qualityButton: ConcreteButton;
    plugin: HlsQualitySelectorPlugin;

    constructor(
        player: VideoPlayerWithHlsQualitySelectorPlugin,
        item: IItem,
        qualityButton: ConcreteButton,
        plugin: HlsQualitySelectorPlugin
    ) {
        super(player, {
            label: item.label,
            selectable: true,
            selected: item.selected || false,
        });
        this.item = item;
        this.qualityButton = qualityButton;
        this.plugin = plugin;
    }

    /**
     * Click event for menu item.
     */
    handleClick() {
        // Reset other menu items selected status.
        for (let i = 0; i < this.qualityButton.items.length; ++i) {
            this.qualityButton.items[i].selected(false);
        }

        // Set this menu item to selected, and set quality.
        this.plugin.setQuality(this.item.value);
        this.selected(true);
    }
}
