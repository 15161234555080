import { createAction } from '@reduxjs/toolkit';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import type {
    QuotaCleanerGroup,
    QuotaCleanerItem,
    QuotaCleanerYearConfig,
    QuotaCleanerYearGroupConfig,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import type { UserQuotaCleanerGroup, UserQuotaCleanerListParams, UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';

export const loadUserQuotaCleanerList = createAction('userQuotaCleaner/loadList');
export const loadUserQuotaCleanerStart = createAction('userQuotaCleaner/loadListStart');
export const loadUserQuotaCleanerListSuccess = createAction<UserQuotaCleanerGroup[]>('userQuotaCleaner/loadListSuccess');
export const loadUserQuotaCleanerListError = createAction('userQuotaCleaner/loadListError');

export const prepareUserQuotaCleanerParams =
    createAction<Partial<Record<UserQuotaGroupId, UserQuotaCleanerListParams>>>('userQuotaCleaner/prepareParams');

export const loadUserQuotaGroup = createAction<{
    groupId: UserQuotaGroupId;
    offset?: number;
    isGroupReset?: boolean;
    onSuccess?: () => void;
}>('userQuotaCleaner/loadGroup');
export const loadUserQuotaGroupMore = createAction<{ offset: number }>('userQuotaCleaner/loadGroupMore');
export const loadUserQuotaGroupSuccess = createAction<{
    data: UserQuotaCleanerGroup;
    offset: number;
    limit: number;
    maxOffset: number;
    isGroupReset?: boolean;
}>('userQuotaCleaner/loadGroupSuccess');
export const loadUserQuotaGroupError = createAction<{ groupId: UserQuotaGroupId; groupType: UserQuotaGroupType }>(
    'userQuotaCleaner/loadGroupError'
);
export const loadUserQuotaGroupFromList = createAction<{ groupId: UserQuotaGroupId; onSuccess?: () => void }>(
    'userQuotaCleaner/loadGroupFromList'
);

export const startLoadingGroup = createAction<{ groupId: UserQuotaGroupId; groupType: UserQuotaGroupType; hasYearChanged: boolean }>(
    'userQuotaCleaner/startLoadingGroup'
);
export const getUserQuotaGroup = createAction<{ groupId: UserQuotaGroupId; year?: string; onSuccess?: () => void }>(
    'userQuotaCleaner/getGroup'
);
export const goToCleanerAndShowQuotaCleaner = createAction<{ groupId: UserQuotaGroupId; size?: number; from?: string }>(
    'userQuotaCleaner/goToCleanerAndShowQuotaCleaner'
);

export const deleteUserQuotaCleanerItems = createAction<{
    ids: string[];
    itemIds: string[];
    items?: QuotaCleanerItem[] | CloudItem[];
    groupId: UserQuotaGroupId;
    groupType: UserQuotaGroupType;
    size: number;
    onDeleteSuccess: () => void;
}>('userQuotaCleaner/deleteItems');

export const deleteUserQuotaCleanerItemsSuccess = createAction<{
    count: number;
    groupType: UserQuotaGroupType;
    groupId: UserQuotaGroupId;
    ids: string[];
    itemIds: string[];
    items?: QuotaCleanerItem[] | CloudItem[];
    size: number;
    isMsgRemove?: boolean;
    onDeleteSuccess?: () => void;
}>('userQuotaCleaner/deleteItemsSuccess');

export const deleteUserQuotaCleanerItemsFromStore = createAction<{ deleteIds: string[] }>('userQuotaCleaner/deleteItemsFromStore');
export const resetUserQuotaCurrentGroup = createAction('userQuotaCleaner/resetCurrentGroup');
export const openUserQuotaItemView = createAction<{ id: string; groupId?: UserQuotaGroupId; isMobile?: boolean }>(
    'userQuotaCleaner/openItemView'
);
export const setMessagesRemove = createAction<{ value: boolean }>('userQuotaCleaner/setMessagesRemove');
export const deleteLettersFromQuota = createAction<{
    ids: string[];
    itemIds: string[];
    groupId: UserQuotaGroupId;
    groupType: UserQuotaGroupType;
    size: number;
    onDeleteSuccess: () => void;
}>('userQuotaCleaner/deleteLetters');

export const reloadGroupAfterDelete = createAction<{
    ids: string[];
    itemIds: string[];
    groupId: UserQuotaGroupId;
    size: number;
    isGroupReset?: boolean;
}>('userQuotaCleaner/reloadGroupAfterDelete');
export const setNeedsGroupReload = createAction<{ value: boolean }>('userQuotaCleaner/setNeedsGroupReload');
export const setCurrentGroup = createAction<{ value: QuotaCleanerGroup | null }>('userQuotaCleaner/setCurrentGroup');
export const setYearGroupsConfig = createAction<{ value: QuotaCleanerYearConfig }>('userQuotaCleaner/setYearGroupsConfig');
export const setYearGroupConfig = createAction<{ groupId: UserQuotaGroupId; value: QuotaCleanerYearGroupConfig }>(
    'userQuotaCleaner/setYearGroupConfig'
);

export const toggleYearFilterOpen = createAction<{ value?: boolean }>('userQuotaCleaner/toggleYearFilterOpen');
export const setIsItemsDeleteProcess = createAction<{ value: boolean }>('userQuotaCleaner/setIsItemsDeleteProcess');
export const setIsItemsDeleteError = createAction<{ value: boolean }>('userQuotaCleaner/setIsItemsDeleteError');
