import { Headline, Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { BREAKPOINT_XS } from 'reactApp/constants/breakpoints';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { familyLoadRequest } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './FamilyPromo.css';
import { featuresList } from './FamilyPromo.data';
import type { FamilyFeatureProps, FamilyPromoProps } from './FamilyPromo.types';

const FamilyFeature: FC<FamilyFeatureProps> = ({ text, title, color, icon, isCompact, compactColor, compactIcon }) => (
    <div
        className={classNames(styles.feature, {
            [styles.feature_compact]: isCompact,
        })}
    >
        <div className={classNames(styles.featureLabel, styles[`featureLabel_${isCompact ? compactColor : color}`])}>
            {isCompact ? compactIcon : icon}
        </div>
        <Spacing size={isCompact ? 0 : 24} />
        <div className={styles.featureContent}>
            <Headline weight="2" className={styles.featureTitle}>
                {title}
            </Headline>
            <Spacing size={isCompact ? 4 : 8} />
            <Text className={styles.featureText}>{text}</Text>
        </div>
    </div>
);

export const FamilyPromo = memo(({ hidePromoButton = false }: FamilyPromoProps) => {
    const dispatch = useDispatch();

    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const overquota = useSelector(UserQuotaSelectors.getOverQuota);

    const [smBreakpointHit] = useMinWidthBreakpoint(BREAKPOINT_XS);
    const isMini = !smBreakpointHit;

    const updateFamilyData = useCallback(() => {
        dispatch(familyLoadRequest({ withQuota: true }));
    }, []);

    const onClick = useCallback(() => {
        dispatch(
            loadProductsAndOpenFastCheckout({
                productId: isFull ? '' : PROMO_TARIFFS?.familyPromo,
                quota: overquota.original,
                onSuccess: updateFamilyData,
            })
        );
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.clickButtonTry,
        });
    }, [dispatch, isFull, overquota.original, updateFamilyData]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            <Text className={styles.descriptions}>
                Оформите подписку и пригласите{isMini ? <br /> : ' '}близких: у вас будет возможность{isMini ? <br /> : ' '}добавить
                {isPhone ? ' ' : <br />}
                до 3 аккаунтов
            </Text>
            {!hidePromoButton && (
                <>
                    <Spacing size={20} />
                    <div>
                        <Button theme="vk" className={styles.button} onClick={onClick}>
                            Попробовать
                        </Button>
                    </div>
                </>
            )}

            <Spacing size={40} />
            <div
                className={classNames(styles.features, {
                    [styles.features_compact]: isPhone,
                })}
            >
                {featuresList.map((item) => (
                    <FamilyFeature {...item} key={item.id} isCompact={isPhone} />
                ))}
            </div>
        </div>
    );
});

FamilyPromo.displayName = 'FamilyPromo';
