import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { ThemeOutsourceSelectors } from 'reactApp/modules/theme.outsource/theme.outsource.selectors';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import type { State } from './themePublic.types';

const getPublicThemeIdState = (state: RootState): State => state.themePublic as State;

const calcPublicThemeId = (state: State, publicId: string): string | undefined => state.publicThemes[publicId];

const getPublicThemeId = createSelector([getPublicThemeIdState, (state: RootState, publicId: string) => publicId], calcPublicThemeId);

const getPublicId = createSelector([getCurrentRouteId], (weblinkId) => getWeblinkFromPublicId(weblinkId) ?? '');

const isNewPublicThemeSelected = createSelector(
    [getPublicThemeIdState, getPublicId, ThemeOutsourceSelectors.getChoosedTheme],
    (state: State, publicId, selectedTheme): boolean => {
        const publicThemeId = calcPublicThemeId(state, publicId);
        return publicThemeId !== selectedTheme;
    }
);

export const ThemePublicSelectors = { isNewPublicThemeSelected, getPublicThemeId } as const;
