import { getFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import type { ICloudNewFeaturesItem } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.types';
import type { ServerEditor } from 'server/helpers/editors/types';
import type { UserBitmaskParams } from 'server/helpers/getUserBillingBitmaskParams';
import type { IShoveBanner } from 'server/helpers/public/public.types';
import type { IUser as IContextUser } from 'server/types/context/IUser';
import type { IPromoTariffs } from 'server/types/IPromoTariffs';

export const IS_MOBILE_BROWSER = config.get('MOBILE_BROWSER');
export const IS_IOS_MOBILE = config.get('MOBILE_BROWSER') === 'IOS' || config.get('IS_IOS');
export const IOS_BUILD_VERSION = config.get('IOS_BUILD');
export const IS_DEV_BUILD = config.get('IS_DEV_BUILD');
export const IS_MAIL_APP_IOS = config.get('IS_MAIL_APP_IOS');
export const IS_ANDROID_MOBILE = config.get('MOBILE_BROWSER') === 'ANDROID' || config.get('IS_ANDROID');
export const IS_PHONE_BROWSER = config.get('PHONE_BROWSER');
export const IS_TABLET_BROWSER = config.get('TABLET_BROWSER');
export const IS_MOBILE_OR_TABLET_BROWSER = IS_MOBILE_BROWSER || IS_TABLET_BROWSER;
export const IS_REACT_PROMO_NEW_YEAR = config.get('REACT_PROMO_NEW_YEAR');
// IS_STOCK также уже есть внутри IS_PUBLIC
export const IS_PUBLIC = config.get('PUBLIC');
export const IS_THEMED_PUBLIC = config.get('IS_THEMED_PUBLIC');
export const IS_STOCK = config.get('STOCK');
export const IS_ATTACHES = config.get('ATTACHES');
export const X_PAGE_ID = config.get('x-page-id') || '';
export const IS_ONPREMISE = config.get('ON_PREMISE');
export const IS_B2B_BIZ_USER = config.get('B2B_BIZ_USER');
export const IS_BIZ_USER = config.get('BIZ_USER');
export const IS_ACTION_USER = config.get('IS_ACTION_USER');
export const IS_EXTERNAL_USER = config.get('EXTERNAL_USER');
export const IS_SOCIAL_USER = config.get('SOCIAL_USER');
export const IS_FAMILY_USER = config.get('IS_FAMILY_USER');
// у external, бизов и соц пользователей нет mail.ru почты
export const IS_USER_WITH_ONLY_CLOUD = IS_BIZ_USER || IS_EXTERNAL_USER || IS_SOCIAL_USER;
export const IS_MY_TEAM = config.get('IS_MY_TEAM');
export const ENABLE_FULL_RESPONSIVE = config.get('ENABLE_FULL_RESPONSIVE');
export const IS_VKT_WEBVIEW = config.get('IS_VKT_WEBVIEW');
export const PUBLIC_SHOVE = config.get('PUBLIC_SHOVE') as IShoveBanner | undefined;
export const PUBLIC_SHOVE_TIMEOUT = config.get('PUBLIC_SHOVE_TIMEOUT') as number;
export const DOM_ID_WORM = config.get('DOM_ID_WORM');
export const IS_FROZEN_USER = config.get('FROZEN_USER');
export const IS_PUBLIC_FOLDER = Boolean(config.get('PUBLIC_FOLDER'));
export const LOGIN_URL = config.get('LOGIN_URL');
export const HELP_URL = config.get('HELP_URL');
export const IS_CHOSEN_PUBLIC_FILE = IS_PUBLIC && !IS_PUBLIC_FOLDER;
export const IS_REACT_PROMO_QUOTA_PAGE = config.get('REACT_PROMO_QUOTA');
export const IS_REACT_PROMO_QUOTA_IOS_PAGE = config.get('REACT_PROMO_QUOTA_IOS');
export const IS_REACT_PROMO_QUOTA_ANDROID_PAGE = config.get('REACT_PROMO_QUOTA_ANDROID');
export const IS_REACT_PROMO_QUOTA_PARTNER_PAGE = config.get('REACT_PROMO_QUOTA_PARTNER');
export const IS_REACT_PROMO_QUOTA_TARIFFS_PAGE = config.get('REACT_PROMO_QUOTA_TARIFFS');
export const IS_REACT_PROMO_QUOTA_LANDING = config.get('REACT_PROMO_QUOTA_LANDING');
export const IS_REACT_PROMO_QUOTA_PRO_LANDING = config.get('REACT_PROMO_QUOTA_PRO');
export const IS_DEV_SERVER = config.get('DEV_SERVER');
export const IS_TEST_SERVER = config.get('TEST_SERVER');
export const IS_DEV = IS_TEST_SERVER || IS_DEV_SERVER;
export const IS_FROZEN = config.get('SHOW_FROZEN');
export const IS_BLOCKED = config.get('SHOW_BLOCKED');
export const HIDE_ADS = config.get('HIDE_ADS');
export const BACK_URL = config.get('BACK_URL');
export const DOWNLOAD_MODE = config.get('DOWNLOAD_MODE');
export const IS_WEBVIEW = config.get('IS_WEBVIEW');
export const IS_PUBLIC_ALBUM = config.get('IS_PUBLIC_ALBUM');
export const IS_OWN_PUBLIC = config.get('IS_OWN_PUBLIC');
export const EMERGENCY_SWA = config.get('EMERGENCY_SWA');
export const IS_AUTO_TEST_HIDE = config.get('IS_AUTO_TEST_HIDE');
export const IS_AUTO_TEST_PROMO = config.get('IS_AUTO_TEST_PROMO');
export const USER_EMAIL = config.get('user.email');
export const OAUTH_HOST = config.get('OAUTH_HOST');
export const LOGO_SRC = config.get('LOGO_SRC');
export const EDITORS_CONFIG = config.get('EDITORS') || [];
export const CHOSEN_EDITOR = config.get('EDITOR');
export const VIEWERS_CONFIG: ServerEditor[] = config.get('VIEWERS') || [];
/** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
 *  по умолчанию на аттачах
 */
export const MYOFFICE_ATTACHES_VIEWER: ServerEditor = config.get('MYOFFICE_ATTACHES_VIEWER') || [];
export const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');
export const IS_CLOUD_LOCKED = config.get('CLOUD_LOCKED');
export const IS_CLOUD_CREATED = config.get('CLOUD_CREATED');
export const IS_CLOUD_BANNED = config.get('BANNED_USER');
export const BILLING_ENABLED = config.get('user.cloud.billing.enabled');
export const ALLOWED_SORTS = config.get('ALLOWED_SORTS');
export const REACT_START = config.get('REACT_START');
export const IS_MAIL_POPUP = config.get('REACT_UPLOADER');
export const IS_REACT_BUY = config.get('REACT_BUY');
export const IS_REACT_CHOICE = config.get('REACT_CHOICE');
export const IS_FRAME_PAGES = IS_MAIL_POPUP || IS_REACT_BUY || IS_REACT_CHOICE;
export const SHOW_CASE = config.get('SHOW_CASE');
export const PUBLIC_NOT_EXISTS = Boolean(config.get('PUBLIC_NOT_EXISTS'));
export const PUBLIC_NOT_AVAILABLE = Boolean(config.get('PUBLIC_NOT_AVAILABLE'));
export const IS_BAD_BROWSER = Boolean(config.get('IS_BAD_BROWSER'));
export const IS_REACT_LANDINGS = config.get('REACT_LANDINGS');
export const IS_PROMO_LANDOS = config.get('IS_PROMO_LANDOS');
export const PUBLIC_ERROR_PAGE_STATUS = config.get('PUBLIC_ERROR_PAGE_STATUS');
export const ERROR_PAGE_STATUS = config.get('ERROR_PAGE_STATUS');
export const USER = config.get('user') as IContextUser & { email?: string };
export const SUSPICIOUS_EXTENSIONS = config.get('SUSPICIOUS_EXTENSIONS');
export const BUILD_VERSION = config.get('BUILD');
export const BUILD_URLS: undefined | { js: string; css: string; staticPath: '/'; apiMocks: string; img: string } = config.get('BUILD_URLS');
export const IS_CORP_USER = config.get('CORP_USER');
export const ACTION_PROMO: ActionPromoType = IS_ACTION_USER && getFeature('summer-promotion') ? undefined : config.get('ACTION_PROMO');
export const { list: TARIFFS_LIST } = config.get('TARIFFS') || { list: [] };
export const ITEM_NAME_INVALID_CHARACTERS = config.get('ITEM_NAME_INVALID_CHARACTERS') || '';
export const ANONYM_USER = config.get('ANONYM_USER');
// tempexp_16521-next-line
export const BASEQUOTA =
    ANONYM_USER && getFeature('ab-descrease-base-quota') ? SPACE_LIST.gb_4 : config.get('user.cloud.billing.basequota') || SPACE_LIST.gb_8;
// CLOUDWEB-14632: данные из рб слота. в рб настроено, что в recommendItems всегда 3 элемента максимум. Делаем slice на всякий случае если что-то в РБ сломается.
export const CLOUD_NEW_FEATURES_CONFIG: ICloudNewFeaturesItem[] = config.get('CLOUD_NEW_FEATURES')?.recommendItems?.slice(0, 3) || [];
export const EMBEDDED = config.get('EMBEDDED');
export const PROMO_TARIFFS: IPromoTariffs = config.get('PROMO_TARIFFS');
export const DOWNLOAD_DISK_O_PROMO_CONFIG = config.get('DOWNLOAD_DISK_O_PROMO');
export const platform = IS_PHONE_BROWSER ? 'touch' : 'desktop';
export const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');
export const INVALID_NAME_CHARACTERS = config.get('ITEM_NAME_INVALID_CHARACTERS');
export const INVALID_PATH_CHARACTERS = config.get('ITEM_PATH_INVALID_CHARACTERS');
export const DOMAIN_HOST = config.get('DOMAIN_HOST');
export const DOM_ID_PHONE_PUBLIC_HEADER = config.get('DOM_ID_PHONE_PUBLIC_HEADER');
export const YA_ID = config.get('YA_ID');
export const MAIL_HOST = config.get('MAIL_HOST');
export const ALLOW_ANONYM_DOWNLOAD_PUBLIC = config.get('ALLOW_ANONYM_DOWNLOAD_PUBLIC');
export const IS_PAID_USER = config.get('PAID_USER');
export const GA_ID = config.get('GA_ID');
export const IS_PAID_B2B_TARIFF = config.get('IS_PAID_B2B_TARIFF');
export const IS_FREE_BIZ_SAAS_USER = IS_B2B_BIZ_USER && !IS_ONPREMISE && !IS_PAID_B2B_TARIFF;
export const IS_DOCUMENTS_DOMAIN = config.get('IS_DOCUMENTS_DOMAIN');

export const IS_TARM = config.get('IS_TARM');
export const APP_TITLE = config.get('APP_TITLE');
export const APP_WHITE_LOGO = config.get('APP_WHITE_LOGO');
export const APP_LOGO = config.get('APP_LOGO');
export const FOOTER = config.get('FOOTER');
export const THEME = config.get('THEME');
export const IS_PUBLIC_OF_OVERQUOTA = config.get('IS_PUBLIC_OF_OVERQUOTA');
export const API_V4 = config.get('API_V4');

export const NEW_PORTAL_HEADER = !IS_MOBILE_BROWSER && !IS_BIZ_USER;
export const IS_PROJECT_B2B_QUOTA = config.get('IS_PROJECT_B2B_QUOTA');
export const PLATFORM = (() => {
    switch (true) {
        case Boolean(IS_WEBVIEW):
            return 'webview';
        case Boolean(IS_PHONE_BROWSER):
            return 'touch';
        case Boolean(IS_MOBILE_BROWSER):
            return 'tablet';
        default:
            return 'desktop';
    }
})();

export const IS_REACT_PROMO_QUOTA_PRO = config.get('REACT_PROMO_QUOTA_PRO');
export const USER_BILLING_PARAMS: UserBitmaskParams = config.get('USER_BILLING_PARAMS');
export const IS_MBIZUSER = USER_BILLING_PARAMS?.IS_MBIZ_USER;

type ActionPromoType = string | undefined;
