import type { Xray } from '@mail/xray';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import React, { type ComponentType, memo, useCallback, useMemo } from 'react';
import { createVKCSATLoader } from 'reactApp/modules/embedded/helpers/createVKCSATLoader';
import type { IItemHandlerData } from 'reactApp/ui/ReactViewer/ReactViewer.types';

export interface Props {
    onItemOpen?: (data?: IItemHandlerData) => void;
    xray?: Xray;
}

const timerInterval = 5 * 1000;

export function withVKCSATLoader<T extends Props = Props>(WrappedComponent: ComponentType<{ onItemOpen: () => void }>) {
    const surveyEmbedded = getFeature('csat-survey-embedded');
    const surveyEmbeddedProject = getFeature('csat-survey-embedded-project');

    const ComponentWithFeedbackLoader = memo((props: T) => {
        const { onItemOpen, xray } = props;

        const vkFeedbackLoader = useMemo(() => {
            if (!surveyEmbedded) {
                return undefined;
            }

            return createVKCSATLoader({
                xray,
                project: surveyEmbeddedProject,
                feedbacks: [].concat(surveyEmbedded),
                filterFeature: () => true,
            });
        }, [xray]);

        const handleItemOpen = useCallback(
            async (data?: IItemHandlerData) => {
                vkFeedbackLoader?.observe(timerInterval);
                onItemOpen?.(data);
            },
            [onItemOpen, vkFeedbackLoader]
        );

        return <WrappedComponent {...props} onItemOpen={handleItemOpen} />;
    });

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithFeedbackLoader.displayName = `withVKFeedbackLoader(${displayName})`;

    return ComponentWithFeedbackLoader;
}
