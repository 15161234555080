import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

export class DocumentImagesApiCall extends APICallV4 {
    _method = 'doc/images';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class DocumentsListApiCall extends APICallV4 {
    _method = 'doc/list';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class DocumentsFileApiCall extends APICallV4 {
    _method = 'doc/list/file';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class EnableDocumentsRecognitionApiCall extends APICallV4 {
    _method = 'doc/enable';
    _type: Method = 'post';
}

export class DisableDocumentsRecognitionApiCall extends APICallV4 {
    _method = 'doc/disable';
    _type: Method = 'post';
}

export class DocumentsRecognitionStatusApiCall extends APICallV4 {
    _method = 'doc/status';
    _type: Method = 'get';
}

export class LinkToDocument extends APICallV4 {
    _method = 'doc/link';
    _type: Method = 'post';
}

export class UnlinkDocument extends APICallV4 {
    _method = 'doc/unlink';
    _type: Method = 'post';
}
