import type { FeedApiResponse, FeedItemBase } from 'reactApp/modules/feed/feed.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';

export interface GalleryItem extends FeedItemBase {
    storage: EStorageType.gallery;
}

export enum GalleryCategoryType {
    all = 'all',
    desktop = 'desktop',
    mobile = 'mobile',
    images = 'images',
    videos = 'videos',
}

export interface GalleryCategory {
    name: string;
    id: GalleryCategoryType;
    value: GalleryCategoryType;
    childs: string[];
    cursor: string;
    isLoaded: boolean;
    isLoading: boolean;
    hasMoreToLoad: boolean;
}

export interface GalleryState {
    categories: GalleryCategory[];
    currentCategory: GalleryCategoryType;
    list: Record<string, GalleryItem>;
    currentFolder?: string;
}

export interface GalleryLoadSuccessAction {
    category: GalleryCategoryType;
    data: FeedApiResponse;
    folder?: string;
    isExactFolder?: boolean;
}

export interface ChangeGalleryCategoryAction {
    category: GalleryCategoryType;
}

export interface GalleryLoadRequestAction {
    category: GalleryCategoryType;
}

export interface GalleryLoadMoreSuccessAction {
    data: FeedApiResponse;
    category?: GalleryCategoryType;
    folder?: string;
    isExactFolder?: boolean;
}

export interface ChangeGalleryFolderAction {
    category?: GalleryCategoryType;
    folder: string;
}
