import { useSelector } from 'react-redux';
import { isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { mapFolderAuthorsWithContacts } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';

export interface IAuthor {
    name: string;
    email: string;
    nick?: string;
}

export const useItemAuthor = (item): IAuthor => {
    const isPublicOwner = useSelector(isOwnPublic);
    const isPublicUpload = useSelector(isPublicUploadEnabled);
    const userEmail = useSelector(UserSelectors.getEmail);
    let authorEmail = item?.author;

    if (!authorEmail && isPublicOwner && isPublicUpload && !item?.isFolder && userEmail) {
        authorEmail = userEmail;
    }

    if (authorEmail === userEmail && [EStorageType.home, EStorageType.favorites].includes(item?.storage)) {
        authorEmail = '';
    }

    return useSelector((state: RootState) => mapFolderAuthorsWithContacts(state, authorEmail ? [authorEmail] : []))[0];
};
