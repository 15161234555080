import classNames from 'clsx';
import { ReactComponent as UploadIcon } from 'img/icons/mobile/upload.svg';
import { ReactComponent as UploadIconRebranding } from 'img/icons/mobile/upload_rebranding.svg';
import React, { type ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { isUploadEnabled } from 'reactApp/modules/storage/storage.selectors';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { getContent } from 'reactApp/ui/Mobile/Datalist/EmptyFolder/EmptyFolder.helpers';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';

import styles from './EmptyFolder.css';

export const EmptyFolder = memo(function EmptyFolder(): ReactElement {
    const storage = useSelector(getCurrentStorage);
    const uploadEnabled = useSelector(isUploadEnabled);
    const isDarkTheme = useSelector(isCurrentDarkTheme);
    const { isHome, isGallery } = getStorage(storage);

    const { title, description } = getContent(storage);

    if (uploadEnabled) {
        return (
            <div className={styles.root}>
                <div className={styles.provocation}>
                    <div className={styles.upload}>
                        <UploadInput inputName="public-carousel" />
                    </div>
                    {isRebranding ? <UploadIconRebranding className={styles.icon} /> : <UploadIcon className={styles.icon} />}
                    <span>
                        Нажмите, чтобы
                        <br />
                        добавить файлы
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(styles.root, styles.disableUpload)}>
            <div
                className={classNames(styles.folder, {
                    [styles.folderDark]: isDarkTheme,
                    [styles.home]: isHome && !isDarkTheme,
                    [styles.gallery]: isGallery && !isDarkTheme,
                    [styles.homeDark]: isHome && isDarkTheme,
                    [styles.galleryDark]: isGallery && isDarkTheme,
                })}
            />
            <div className={styles.title}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
        </div>
    );
});
