import axios from 'lib/axios';
import { logger } from 'lib/logger';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isUseO2Token, O2AuthClient } from 'reactApp/api/O2AuthClient';
import { useO2AuthClient } from 'reactApp/hooks/useO2AuthClient';
import { PostMessagesNames } from 'reactApp/modules/integration/communicator';

export const INLINE_INTEGRATION_IFRAME_SRC = 'inline-integration';
export const POPUP_INTEGRATION_IFRAME_SRC = 'integration';
export const DEFAULT_TUTORIA_FOLDER = '/Tutoria/';

const UNKNOWN_COULD_IFRAME_LOADING_ERROR = 'unknown could iframe loading error';
const AUTH_COULD_IFRAME_LOADING_ERROR = 'auth could iframe loading error';
const POPUP_INTEGRATION_TRANSFER_PARAMS = [
    'parent_origin',
    'integration_client',
    'integration_p',
    'integration_uid',
    'auth_origin',
    'cloud_documents_viewer_enabled',
    'cloud_my_office_viewer_enabled',
    'cloud_quote_pay_enabled',
    'auto_sharing_disabled',
    'new_widget_icons_enabled',
];
const IFRAME_CHECK_INTERVAL = 250;
const AUTH_CLOUD_STATIC_SUBSTR = '"ANONYM_USER":false';
const RETRY_LIMIT = 5;
const REFRESH_TOKEN_DEFFER_INITIAL = 1000;
const REFRESH_TOKEN_DEFFER_STEP = 2000;
const TIMEOUT_AFTER_RETRY_INITIAL = 500;
const TIMEOUT_AFTER_RETRY_STEP = 500;

const transferParams = (target: URLSearchParams, source: URLSearchParams, paramNames: string[]) => {
    paramNames.forEach((name) => {
        const value = source.get(name);
        if (value) {
            target.set(name, value);
        }
    });
};
const prepareIframeSrc = () => {
    // eslint-disable-next-line compat/compat
    const currentParams = new URLSearchParams(window.location.search);
    const popupMode = currentParams.has('popupMode');
    const src = new URL(`${window.location.origin}/${popupMode ? POPUP_INTEGRATION_IFRAME_SRC : INLINE_INTEGRATION_IFRAME_SRC}`);
    transferParams(src.searchParams, currentParams, POPUP_INTEGRATION_TRANSFER_PARAMS);
    if (isUseO2Token()) {
        src.searchParams.set('auth', 'o2');
    }
    return src.toString();
};

const isAuthHtml = (html: string) => html.includes(AUTH_CLOUD_STATIC_SUBSTR);

export const useCloudInterfaceLoader = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { token, refreshToken } = useO2AuthClient();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const refreshTokenCount = useRef<number>(0);

    const refreshTokenDeferred = useCallback(
        () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(refreshToken());
                }, refreshTokenCount.current * REFRESH_TOKEN_DEFFER_STEP + REFRESH_TOKEN_DEFFER_INITIAL);
            }),
        [refreshTokenCount]
    );

    const loadIframeContent = useCallback(() => {
        const contentWindow = iframeRef.current?.contentWindow;
        if (!contentWindow) {
            return;
        }

        setLoading(true);

        contentWindow.location = 'about:blank';

        const src = prepareIframeSrc();

        axios
            .get(src, {
                headers: O2AuthClient.prepareAuthHeader(token),
            })
            .then((response) => {
                const html = response.data;

                if (!isAuthHtml(html)) {
                    logger.error('auth error', '[InlineIntegration::auth]');
                    if (refreshTokenCount.current >= RETRY_LIMIT) {
                        logger.error('auth error retry limit', '[InlineIntegration::auth]');
                        setError(AUTH_COULD_IFRAME_LOADING_ERROR);
                        return;
                    }
                    refreshTokenCount.current++;
                    return refreshTokenDeferred();
                }

                refreshTokenCount.current = 0;

                const iframeDocument = contentWindow?.document;
                const iframeWindow = contentWindow?.window;
                if (!contentWindow || !iframeDocument || !iframeWindow) {
                    return;
                }
                iframeDocument.open();
                iframeDocument.write(html);
                iframeDocument.close();
            })
            .catch((error) => {
                logger.error(error, '[InlineIntegration::loading]');
                setError(error?.response?.body || error?.response?.error || UNKNOWN_COULD_IFRAME_LOADING_ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [token]);

    useEffect(() => {
        if (!token) {
            return;
        }

        setTimeout(loadIframeContent, refreshTokenCount.current * TIMEOUT_AFTER_RETRY_STEP + TIMEOUT_AFTER_RETRY_INITIAL);
    }, [loadIframeContent]);

    /**
     * После длительного простоя в неактивной вкладке браузер может
     * попытаться перезагрузить iframe по текущему локейшину, что приведет
     * к загрузке не авторизованой статики iframe или блокировке отображения
     * (если текущий домен не находится в списке разрешенных).
     * Для исправления ситуации перезагружаем статику.
     */
    useEffect(() => {
        if (!token) {
            return;
        }

        const interval = window.setInterval(() => {
            const contentWindow = iframeRef.current?.contentWindow;
            if (!contentWindow) {
                return;
            }
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                contentWindow.location.href;
            } catch {
                loadIframeContent();
            }
        }, IFRAME_CHECK_INTERVAL);

        return () => {
            window.clearInterval(interval);
        };
    }, [loadIframeContent, token]);

    const handleMessage = ({ data, origin }: MessageEvent) => {
        // eslint-disable-next-line compat/compat
        const currentParams = new URLSearchParams(window.location.search);
        if (currentParams.get('parent_origin') !== origin) {
            return;
        }

        if (!data.__tutoriaMessage) {
            return;
        }

        if (data.name === PostMessagesNames.expandResponse && data.expand === false) {
            iframeRef.current?.contentWindow?.postMessage(
                { __cloudIntegrationMessage: true, name: PostMessagesNames.closeViewer },
                window.location.origin
            );
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return {
        iframeRef,
        loading,
        error,
    };
};
