import darkCoverImage from 'img/aside-promo-modal/cloud-dark.jpg';
import lightCoverImage from 'img/aside-promo-modal/cloud-light.jpg';
import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { getCurrentPublicItem } from 'reactApp/modules/public/public.selectors';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { sendGaHelper } from 'reactApp/modules/requiredAuthorization/sendGaHelper';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { EAsidePromoModalTheme } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.types';

export const showAsideModalReqAuth = () => {
    const state = reduxStore.getState();
    const requiredSignUpVariant = getFeatureRequiredSignUpWhenDownloading(state);

    if (requiredSignUpVariant === 'variant1' && !reDownloadController.isEmpty()) {
        const item = getCurrentPublicItem(state);

        const theme = !item?.isFolder ? EAsidePromoModalTheme.dark : undefined;
        const imageUrl = !item?.isFolder ? darkCoverImage : lightCoverImage;

        openAsidePromoModal({
            title: 'Храните в Облаке',
            text: 'Файл не будет занимать место на устройстве, а бесплатных гигабайтов хватит на сотни фото и видео',
            buttonText: 'Сохранить в Облако',
            imageUrl,
            theme,
            bottom: theme === EAsidePromoModalTheme.dark && !HIDE_ADS ? 110 : undefined,
            onClick: () => {
                if (item) {
                    toolbarActions.clone({
                        id: item.id,
                        destination: '',
                        source: 'toolbar',
                        fromReDownload: true,
                    });
                }

                sendGaHelper('clck-aside-mdl', 'variant1');
            },
            onShow: () => {
                // Показываем только один раз за сессию
                reDownloadController.resetParams();
                sendGaHelper('shw-aside-mdl', 'variant1');
            },
            onClose: () => sendGaHelper('cls-aside-mdl', 'variant1'),
        });
    }
};
