import React, { type ReactElement, memo, useMemo } from 'react';
import styles from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileMenu/SassMobileMenu.css';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { MenuItem } from 'reactApp/ui/Mobile/LeftMenu/MenuItem/MenuItem';
import { UserBlock } from 'reactApp/ui/Mobile/UserBlock/UserBlock';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeItemsFlatten } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import type { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';

interface Props {
    onClose: () => void;
}

export const SassMobileMenu = memo(({ onClose }: Props): ReactElement => {
    const menuItems = useMemo(() => {
        const items: TreeNodeData[] = [
            treeItemsFlatten[ETreeRootIds.gallery],
            treeItemsFlatten[ETreeRootIds.home],
            treeItemsFlatten[ETreeRootIds.trashbin],
        ];
        return items.map(({ id, title, icon, storage, href }) => (
            <MenuItem key={id} id={id} title={title} icon={icon} storage={storage} onClose={onClose} href={href} />
        ));
    }, [treeItemsFlatten, onClose]);

    return (
        <LeftDialog onClose={onClose} mode={'white'}>
            <div className={styles.root}>
                <div className={styles.contentWrapper}>
                    <div className={styles.menu_items}>{menuItems}</div>
                    <div className={styles.user}>
                        <UserBlock isLeftMenu reloginAction />
                    </div>
                </div>
            </div>
        </LeftDialog>
    );
});

SassMobileMenu.displayName = 'SassMobileMenu';
