import classNames from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { IS_ONPREMISE, IS_TARM } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { getEditorItem, getEditorState } from 'reactApp/modules/editor/editor.selectors';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import styles from 'reactApp/ui/EditorHeader/BizInlineEditorToolbar/BizInlineEditorToolbar.css';
import { Button } from 'reactApp/ui/EditorHeader/BizInlineEditorToolbar/components/Button/Button';
import { updateWeblinkInUrlAction } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/EditorHeader';
import { createGaSender, sendGa } from 'reactApp/utils/ga';
import { EditorMode } from 'server/helpers/editors/types';

type InlineEditorToolbarProps = {
    file: EditorItem | null;
    isPublic: boolean;
    isReady: boolean;
    mode: EditorMode;
    updateWeblinkInUrl: (id?: string, weblink?: string) => void;
    backLink: string;
    gaCategory: string;
};

const InlineEditorToolbar: React.FC<InlineEditorToolbarProps> = ({
    file,
    gaCategory,
    isPublic,
    mode,
    isReady,
    updateWeblinkInUrl,
    backLink,
}) => {
    const gaSender = useMemo(() => createGaSender(`${gaCategory}-${isPublic ? 'public' : 'home'}`), [gaCategory, isPublic]);

    const handlePublish = useCallback(
        (e) => {
            e.stopPropagation();

            gaSender('publish', 'click', { type: 'button' });

            publishHelper({ item: file, gaSuffix: '_header' });
        },
        [file, gaSender]
    );

    const handleBack = useCallback(() => {
        window.location.href = backLink;
        window.opener = null;
    }, [backLink]);

    useEffect(() => {
        gaSender('show');

        if (isPublic) {
            sendGa('public-edit', 'file-type', 'document');
            const ext = file?.ext;
            if (ext) {
                sendGa('public-edit', 'file-extention', ext);
            }
        } else {
            updateWeblinkInUrl(file?.id, file?.weblink);
        }
    }, []);

    useEffect(() => {
        updateWeblinkInUrl(file?.id, file?.weblink);
    }, [file?.id, file?.weblink, updateWeblinkInUrl]);

    return (
        <div className={styles.root}>
            <div className={styles.leftGroup}>
                {IS_ONPREMISE && (
                    <div
                        className={classNames({
                            [styles.logo]: true,
                            [styles.logo__tarm]: IS_TARM,
                            [styles.logo__workdisk]: !IS_TARM,
                        })}
                        onClick={handleBack}
                        data-ext={String(file?.ext).toLowerCase()}
                    />
                )}
            </div>
            <div
                className={classNames({
                    [styles.rightGroup]: true,
                    [styles.modeView]: mode === EditorMode.VIEW,
                    [styles.modeEdit]: mode === EditorMode.EDIT,
                })}
            >
                {isReady && <Button testId="share-button" onClick={handlePublish} text="Поделиться" />}
            </div>
        </div>
    );
};

InlineEditorToolbar.displayName = 'BizInlineEditorToolbar';

const mapStateToProps = (state, props) => {
    const { isPublic, itemSelector = getEditorItem } = props;

    const { isReady } = getEditorState(state);
    const file = itemSelector(state);

    return {
        backLink: isPublic ? '/home' : `/home${file?.parent}`,
        updateWeblinkInUrl: updateWeblinkInUrlAction,
        isReady,
        file,
    };
};

export const BizInlineEditorToolbar = connect(mapStateToProps)(InlineEditorToolbar);
