import type { PayloadAction } from '@reduxjs/toolkit';
import api from 'Cloud/Application/api';
import {
    changeFeedCategory,
    feedLoadMoreRequest,
    feedLoadMoreSuccess,
    feedLoadRequest,
    feedLoadStart,
    feedLoadSuccess,
} from 'reactApp/modules/feed/feed.module';
import { getFeedCategory, getFeedCursorByCategory } from 'reactApp/modules/feed/feed.selectors';
import { type FeedItemApiResponse, type FeedLoadRequestAction, FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { all, call, cancel, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getExtensionByCategory } from './feed.helpers';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.feed);

const apiCall = ({
    limit = LOAD_LIMIT,
    cursor,
    extensions,
}: {
    limit?: number;
    cursor?: string;
    extensions?: string;
}): Promise<FeedItemApiResponse> =>
    Promise.resolve(
        api.feed({
            exts: extensions,
            cursor,
            limit,
        })
    );

function* fetchFeed({ category = null, resetCursor = false }: { category?: Nullable<FeedCategoryType>; resetCursor?: boolean }) {
    try {
        const currentCategory: FeedCategoryType = !category ? yield select(getFeedCategory) : category;
        const cursor = resetCursor ? '' : yield select(getFeedCursorByCategory, currentCategory);
        const extensions = getExtensionByCategory(currentCategory);

        const [sharedIncomingResponse, data] = yield all([
            Promise.resolve(api.folder.shared.incoming()),
            call(apiCall, { extensions, cursor }),
        ]);

        const { list: sharedIncoming = [] } = sharedIncomingResponse;
        const mountedFoldersIds = sharedIncoming.map((item) => (item.home ? `${item.home}/` : null)).filter((home) => !!home);

        return { category: currentCategory, data, mountedFoldersIds };
    } catch (error) {
        yield cancel();
    }
}

function* loadFeedByRequest(action) {
    const category = action?.payload?.category || FeedCategoryType.all;
    yield put(feedLoadStart({ category }));

    const res = yield fetchFeed({ category, resetCursor: true });

    yield put(feedLoadSuccess(res));
}

function* loadFeedCategory(action) {
    const category = action.payload.category;

    const res = yield fetchFeed({ category });

    yield put(feedLoadSuccess(res));
}

function* loadMoreFeed(action?: PayloadAction<FeedLoadRequestAction>) {
    const category = action?.payload?.category;

    const res = yield fetchFeed({ category });

    yield put(feedLoadMoreSuccess(res));
}

export function* watchFeed() {
    yield takeEvery(feedLoadRequest.toString(), loadFeedByRequest);
    yield takeEvery(changeFeedCategory.toString(), loadFeedCategory);
    yield takeLatest(feedLoadMoreRequest.toString(), loadMoreFeed);
}
