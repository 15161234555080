import classNames from 'clsx';
import React, { type PropsWithChildren, forwardRef, useCallback } from 'react';
import type { EViewMode } from 'reactApp/modules/settings/settings.types';
import { createGaSender } from 'reactApp/utils/ga';

import styles from './DataListControl.css';

const sendGa = createGaSender('datalist-control');

export type DataListControlType = 'download' | 'publish' | 'check' | 'restore' | 'remove' | 'downloadAsJpeg' | 'share' | 'favorite';

interface DataListControlProps extends React.HTMLAttributes<HTMLDivElement> {
    viewType: EViewMode;
    type: DataListControlType;
}

const handleDoubleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
};

export const DataListControl = forwardRef<HTMLDivElement, PropsWithChildren<DataListControlProps>>(function DataListControl(
    { children, viewType, type, onClick, ...props },
    ref
) {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e): void => {
            if (onClick) {
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
                sendGa('click', type, viewType);

                onClick(e);
            }
        },
        [onClick, type, viewType]
    );

    return (
        <div
            onDoubleClick={handleDoubleClick}
            {...props}
            className={classNames(props.className, styles.root)}
            onClick={handleClick}
            ref={ref}
        >
            {children}
        </div>
    );
});
