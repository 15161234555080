import classNames from 'clsx';
import { ReactComponent as DropdownIcon } from 'mrg-icons/src/mailru/navigation/drop_down.svg';
import React, { type ReactElement, type ReactNode, type Ref, createRef, PureComponent } from 'react';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { noop } from 'reactApp/utils/helpers';

import styles from './EditorHeader.css';

export interface Props {
    title: string;
    theme?: 'myOffice';
    link?: string | null;
    size?: 110 | 181;
    icon: ReactNode;
    onClick?(e?: React.MouseEvent<HTMLDivElement>): void | null;
    textRef?: Ref<HTMLDivElement>;
    dropdownList?: any[];
    onDropdownClick?(): void;
    isDropdownOpen?: boolean;
    isWopiFeatureEnabled?: boolean;
    sendEditorRadar?: (i: string) => void;
}

export class HeaderButton extends PureComponent<Props> {
    handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        const { link, onClick = noop, sendEditorRadar } = this.props;
        if (link) {
            if (sendEditorRadar) {
                sendEditorRadar('back');
            }
            window.location.href = link;
            window.opener = null;
        } else {
            onClick(e);
        }
    };

    dropdownRef = createRef<HTMLDivElement>();

    findDropdownPosition = (dropdownWidth: number): { posX: number; posY: number } => {
        const el = this.dropdownRef.current;
        let posX = 0;
        let posY = 0;

        if (el) {
            const elRect = el.getBoundingClientRect();
            posY = elRect.top + elRect.height + window.scrollY;
            posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
        }

        return { posX, posY };
    };

    handleClickDropdown = (e?: React.MouseEvent<HTMLDivElement>) => {
        const { onDropdownClick = noop } = this.props;

        e?.stopPropagation();

        onDropdownClick();
    };

    renderDropdown() {
        const { dropdownList, isDropdownOpen } = this.props;

        if (!dropdownList?.length) {
            return null;
        }

        return (
            <div className={styles.dropdown} ref={this.dropdownRef}>
                <div className={styles.dropdownIconContainer} onClick={this.handleClickDropdown}>
                    <DropdownIcon className={classNames(styles.dropdownIcon, { [styles.dropdownIconUp]: isDropdownOpen })} />
                </div>
                {isDropdownOpen ? (
                    <DropdownList
                        posX={0}
                        posY={0}
                        list={dropdownList}
                        gaId={`editor-header-dnld`}
                        title="Скачать в"
                        close={this.handleClickDropdown}
                        renderItem={(item) => <DropdownItemAction text={item.text || item.id} />}
                        calcPosition={this.findDropdownPosition}
                        parentRef={this.dropdownRef}
                        theme={[DropdownTheme.jpeg]}
                        closeOnResize
                        closeOnScroll
                    />
                ) : null}
            </div>
        );
    }

    render(): ReactElement {
        const { icon, title, textRef, size, isWopiFeatureEnabled, theme } = this.props;

        return (
            <div className={styles.buttonBlock} onClick={this.handleClick}>
                <div
                    className={classNames({
                        [styles.button]: true,
                        [styles.button_size]: true,
                        [styles[`button_theme-${theme}`]]: !!theme,
                        [styles[`button_size_${size}`]]: !!size,
                    })}
                    ref={textRef}
                >
                    {icon && (
                        <div className={styles.icon} title={title}>
                            {icon}
                        </div>
                    )}
                    <div className={styles.buttonTitle}>{title}</div>
                    {!isWopiFeatureEnabled && this.renderDropdown()}
                </div>
            </div>
        );
    }
}
