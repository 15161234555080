import { bytesToNDigits } from '@mail/cross-sizes-utils';
import slide1 from 'img/family/onboarding/family-onboarding-1.jpg';
import slide1Dark from 'img/family/onboarding/family-onboarding-1-dark.jpg';
import slide1DarkRebranding from 'img/family/onboarding/family-onboarding-1-dark_rebranding.png';
import slide1TouchRebranding from 'img/family/onboarding/family-onboarding-1-rebranding-touch.png';
import slide1Touch from 'img/family/onboarding/family-onboarding-1-touch.jpg';
import slide1TouchDark from 'img/family/onboarding/family-onboarding-1-touch-dark.jpg';
import slide1TouchDarkRebranding from 'img/family/onboarding/family-onboarding-1-touch-dark_rebranding.png';
import slide2 from 'img/family/onboarding/family-onboarding-2.jpg';
import slide2Dark from 'img/family/onboarding/family-onboarding-2-dark.jpg';
import slide2DarkRebranding from 'img/family/onboarding/family-onboarding-2-dark_rebranding.png';
import slide2TouchRebranding from 'img/family/onboarding/family-onboarding-2-rebranding-touch.png';
import slide2Touch from 'img/family/onboarding/family-onboarding-2-touch.jpg';
import slide2TouchDark from 'img/family/onboarding/family-onboarding-2-touch-dark.jpg';
import slide2TouchDarkRebranding from 'img/family/onboarding/family-onboarding-2-touch-dark_rebranding.png';
import slide3 from 'img/family/onboarding/family-onboarding-3.jpg';
import slide3Dark from 'img/family/onboarding/family-onboarding-3-dark.jpg';
import slide3DarkRebranding from 'img/family/onboarding/family-onboarding-3-dark_rebranding.png';
import slide3TouchRebranding from 'img/family/onboarding/family-onboarding-3-rebranding-touch.png';
import slide3Touch from 'img/family/onboarding/family-onboarding-3-touch.jpg';
import slide3TouchDark from 'img/family/onboarding/family-onboarding-3-touch-dark.jpg';
import slide3TouchDarkRebranding from 'img/family/onboarding/family-onboarding-3-touch-dark_rebranding.png';
import slide1Rebranding from 'img/family/onboarding/family-onboarding-rebranding-1.png';
import slide2Rebranding from 'img/family/onboarding/family-onboarding-rebranding-2.png';
import slide3Rebranding from 'img/family/onboarding/family-onboarding-rebranding-3.png';
import type { SliderModalSlide } from 'reactApp/ui/SliderModal/SliderModal.types';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

const familyOnboardingSlides: SliderModalSlide[] = [
    {
        id: 'slide1',
        title: 'Пользуйтесь подпиской Mail Space вместе',
        text: 'Это выгодно: ваши близкие получат преимущества платной подписки, при этом её стоимость для вас не изменится',
        src: slide1,
        srcMobile: slide1Touch,
    },
    {
        id: 'slide2',
        title: 'У каждого своё Облако',
        text: 'Каждый участник видит только свой аккаунт и свои файлы в\u00A0Облаке. Личное остаётся личным.',
        src: slide2,
        srcMobile: slide2Touch,
    },
    {
        id: 'slide3',
        title: 'У вас 3 свободных места',
        text: 'Ваши близкие получат доступ к вашим гигабайтам и\u00A0другим возможностям подписки Mail\u00A0Space бесплатно',
        src: slide3,
        srcMobile: slide3Touch,
    },
];

export const getFamilyOnboardingSlides = (isPaid: boolean, quota: number, isRebrandingQuotaLanding?: boolean): SliderModalSlide[] => {
    const isDarkMode = isDarkThemeModeEnabled();

    if (isDarkMode) {
        familyOnboardingSlides[0].src = isRebrandingQuotaLanding ? slide1DarkRebranding : slide1Dark;
        familyOnboardingSlides[1].src = isRebrandingQuotaLanding ? slide2DarkRebranding : slide2Dark;
        familyOnboardingSlides[2].src = isRebrandingQuotaLanding ? slide3DarkRebranding : slide3Dark;
        familyOnboardingSlides[0].srcMobile = isRebrandingQuotaLanding ? slide1TouchDarkRebranding : slide1TouchDark;
        familyOnboardingSlides[1].srcMobile = isRebrandingQuotaLanding ? slide2TouchDarkRebranding : slide2TouchDark;
        familyOnboardingSlides[2].srcMobile = isRebrandingQuotaLanding ? slide3TouchDarkRebranding : slide3TouchDark;
    } else {
        familyOnboardingSlides[0].src = isRebrandingQuotaLanding ? slide1Rebranding : slide1;
        familyOnboardingSlides[1].src = isRebrandingQuotaLanding ? slide2Rebranding : slide2;
        familyOnboardingSlides[2].src = isRebrandingQuotaLanding ? slide3Rebranding : slide3;
        familyOnboardingSlides[0].srcMobile = isRebrandingQuotaLanding ? slide1TouchRebranding : slide1Touch;
        familyOnboardingSlides[1].srcMobile = isRebrandingQuotaLanding ? slide2TouchRebranding : slide2Touch;
        familyOnboardingSlides[2].srcMobile = isRebrandingQuotaLanding ? slide3TouchRebranding : slide3Touch;
    }

    if (isPaid) {
        const size = bytesToNDigits(quota, 3);

        return [
            familyOnboardingSlides[0],
            familyOnboardingSlides[1],
            {
                ...familyOnboardingSlides[2],
                text: `Ваши близкие получат доступ к ${size.value} и\u00A0другим возможностям подписки Mail\u00A0Space бесплатно`,
            },
        ];
    }
    return familyOnboardingSlides;
};
