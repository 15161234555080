import { ACTIVE_SESSION_INTERVAL } from 'Cloud/Application/Editor/MyOffice/myOffice.constants';
import type { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { xray } from 'lib/xray';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';

interface DWHParams {
    count_autosafe?: number;
    id_session?: string;
    id_public?: string;
}

interface Params {
    item: EditorItem;
    i: string;
    version: MYOFFICE_VARIANTS;
    dwh?: DWHParams;
    isAttach?: boolean;
    storage: EStorageType;
    back?: string;
}

export const sendEditRadar = ({ item, i, version, dwh, isAttach, storage, back }: Params) => {
    const { subKind, size, id, ext } = item;

    xray.send('editor', {
        i,
        dwh: {
            type_content: subKind,
            size_file: size,
            id_media: id,
            extension: ext,
            attach: isAttach,
            source: storage,
            version,
            return: back,
            ...dwh,
        },
    });
};

interface SessionParams {
    item: EditorItem;
    sessionId: string;
    version: MYOFFICE_VARIANTS;
    isAttach?: boolean;
    storage: EStorageType;
}

export const startSendSessionRadars = ({ item, sessionId, version, isAttach, storage }: SessionParams) => {
    let sessionIntervalId;
    const enableSessionInterval = () => {
        sessionIntervalId = setInterval(() => {
            if (window.navigator.onLine) {
                sendEditRadar({
                    item,
                    version,
                    isAttach,
                    storage,
                    i: 'session',
                    dwh: {
                        id_session: sessionId,
                    },
                });
            }
        }, ACTIVE_SESSION_INTERVAL);
    };

    const sendSessionState = () => {
        if (window.document.hidden) {
            clearInterval(sessionIntervalId);
            return;
        }

        if (sessionIntervalId) {
            clearInterval(sessionIntervalId);
        }

        enableSessionInterval();
    };

    window.document.addEventListener('visibilitychange', sendSessionState);

    if (!window.document.hidden) {
        enableSessionInterval();
    }
};
