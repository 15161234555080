import { Headline } from '@vkontakte/vkui';
import { requestPayment } from 'Cloud/Application/Payment';
import React, { type FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_PROMO, IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { abEternalPromoYearTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureMarketingPromoButton } from 'reactApp/modules/features/features.selectors';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { ESplashScreenAction, useSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';

import styles from './MarketingPromoButton.css';

const PROMO_ID = 'attaches-marketing-promo-bf23';

interface Props {
    onVisibilityChanged: (isVisible: boolean) => void;
}

export const MarketingPromoButton: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const { onVisibilityChanged } = props;

    const featureData = useSelector(getFeatureMarketingPromoButton);
    const showCount = useSelector((state: RootState) => UserStorageSelectors.get(state, PROMO_ID));

    // tempexp_16215-start
    const regExp = new RegExp(/^W128GB_1y_.*$/);
    const eternalProduct = abEternalPromoYearTariffs.find((item) => regExp.test(item));
    const productId = eternalProduct || featureData?.productId;
    // tempexp_16215-end

    const maxShowCount = featureData?.showCount || 5;

    const [visible, setVisible] = useState(
        () => IS_ACTION_USER && Boolean(ACTION_PROMO) && Boolean(featureData) && (!showCount || showCount < maxShowCount)
    );
    useEffect(() => {
        onVisibilityChanged(visible);
    }, [onVisibilityChanged, visible]);

    const sendSplashScreenAnalytics = useSplashScreenAnalytics({ name: 'attaches-marketing-btn', button_name: featureData?.text || '' });

    useEffect(() => {
        if (visible) {
            sendSplashScreenAnalytics(ESplashScreenAction.show);
            dispatch(UserStorageActions.set(PROMO_ID, showCount ? showCount + 1 : 1));
        }
    }, []);

    const onClick = useCallback(() => {
        sendSplashScreenAnalytics(ESplashScreenAction.click);
        dispatch(UserStorageActions.set(PROMO_ID, maxShowCount));
        if (productId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                tariff: productId,
                forceFastCheckout: true,
                onClose: () => setVisible(false),
                paySource: 'viewer_btn_+128gb',
            });
        }
    }, [dispatch, productId, maxShowCount]);

    return visible ? (
        <div className={styles.button} onClick={onClick}>
            <Headline level="2" weight="1">
                {featureData?.text}
            </Headline>
        </div>
    ) : null;
};
