import { Icon20ErrorCircleOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type FC } from 'react';
import { ActionsBlock } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Messages/components/Message/components/ActionsBlock/ActionsBlock';
import { DEFAULT_MESSAGES } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import type { Message } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';

import styles from './EditLLMDialogMessage.css';

interface Props {
    message: Message['message'];
    isOwn: Message['isOwn'];
    hasError: Message['hasError'];
    dataQaId: string;
}

export const EditLLMDialogMessage: FC<Props> = ({ message, isOwn, hasError, dataQaId }) => {
    const isAnswer = DEFAULT_MESSAGES[0].message !== message && !isOwn && !hasError;

    return isAnswer ? (
        <div data-qa-id={dataQaId}>
            <p className={styles.message}>{message}</p>
            <ActionsBlock message={message} dataQaId={dataQaId} />
        </div>
    ) : (
        <p className={classNames(styles.message, { [styles.message_own]: isOwn, [styles.message_error]: hasError })} data-qa-id={dataQaId}>
            {hasError && <Icon20ErrorCircleOutline className={styles.error_icon} />}
            {message}
        </p>
    );
};
