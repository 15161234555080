import { quotaCleanerYearFilterPromoId } from 'reactApp/components/QuotaCleanerYearFilterPromo/quotaCleanerYearFilterPromo.toolkit';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useYearFilter } from 'reactApp/ui/QuotaCleanerPage/hooks/useYearFilter';
import type { ToolbarItem, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { YearFilter } from 'reactApp/ui/Toolbar/YearFilter/YearFilter';

export const getYearFilterItem = (storage: EStorageType): TToolbarItem => {
    let hook;
    if (storage === EStorageType.quotaCleaner) {
        hook = useYearFilter;
    }

    return {
        Item: hook ? (YearFilter as ToolbarItem) : null,
        itemProps: { id: quotaCleanerYearFilterPromoId, hook },
    };
};
