import { Icon20Search } from '@vkontakte/icons';
import { type InputProps, Input } from '@vkontakte/vkui';
import React from 'react';

import styles from './SearchInput.css';

export const SearchInput: React.FC<InputProps> = (props) => (
    <Input mode="plain" className={styles.input} before={<Icon20Search className={styles.searchIcon} />} {...props} />
);

SearchInput.displayName = 'SearchInput';
