/* eslint-disable max-lines-per-function */

export enum ButtonType {
    Download = 'download',
    Edit = 'edit',
    EditImage = 'edit_image',
    Publish = 'publish',
    Clone = 'clone',
    Print = 'print',
    Separator = 'separator',
    Increase = 'increase',
    Decrease = 'decrease',
}

export interface ButtonState {
    /** id чисто для отладки */
    id: ButtonType;
    /** Чем выше приоритет, тем позже скроется кнопка */
    priority: number;
    /** Если кнопку не видно, то её ширина и gap не учитывается в расчётах */
    isVisible: boolean;
    /** Хотим ли мы контролировать сворчаивание кнопки этим алгоритмом */
    isConfigurable: boolean;
    /** Нужно ли свернуть кнопку */
    isCollapsed: boolean;
    /** Минимальная ширина кнопки в свёрнутом состоянии */
    minWidth: number;
    /** Максимальная ширина кнопки в развёрнутом состоянии */
    maxWidth: number;
}

interface Options {
    headerWidth: number;
    isPromoButtonVisible: boolean;
    isRightGroupVisible: boolean;
    isEditButtonVisible: boolean;
    isCloneButtonVisible: boolean;
    isShareButtonVisible: boolean;
    isEditImageButtonVisible: boolean;
    isPrintButtonVisible: boolean;
    isOvidius: boolean;
    isPdf: boolean;
}

/**
 * Рассчитывает, какие кнопки стоит свернуть, а какие развернуть на основе приоритета и доступной ширины
 * @param options - параметры, на основе которых будет проводиться расчёт
 * @returns состояния кнопок просмотршика аттачей
 */
export const calculateControlsState = (options: Options) => {
    const {
        headerWidth,
        isEditButtonVisible,
        isCloneButtonVisible,
        isShareButtonVisible,
        isEditImageButtonVisible,
        isPrintButtonVisible,
        isOvidius,
        isPdf,
        isPromoButtonVisible,
        isRightGroupVisible,
    } = options;

    /*
        На момент создания функции, были найдены такие концигурации тулбара:
        1) Standart: Publish - Clone - Download
        2) PDF: Publish - Clone (s) - Download (s)
        3) Ovidius (docx):  Edit - Publish - Clone (s) - Download (s) - Print (s) - Separator - Zoom In (s) - Zoom out (s)
        4) xlsx: Edit - Publish - Clone - Download

        * (s) - small (collapsed по умолчанию и не разворачивается даже натшироких экранах)

        Здесь поддержана такая же конфигруация
    */

    const buttonsConfig: Record<ButtonType, ButtonState> = {
        [ButtonType.Edit]: {
            id: ButtonType.Edit,
            priority: 5,
            isVisible: isEditButtonVisible,
            isConfigurable: true,
            isCollapsed: false,
            minWidth: 36,
            maxWidth: 218,
        },
        [ButtonType.Publish]: {
            id: ButtonType.Publish,
            priority: 4,
            isVisible: isShareButtonVisible,
            isConfigurable: true,
            isCollapsed: false,
            minWidth: 36,
            maxWidth: 203,
        },
        [ButtonType.Clone]: {
            id: ButtonType.Clone,
            priority: 1,
            isVisible: isCloneButtonVisible,
            isConfigurable: true,
            isCollapsed: false,
            minWidth: 36,
            maxWidth: 198,
        },
        [ButtonType.EditImage]: {
            id: ButtonType.EditImage,
            priority: 2,
            isVisible: isEditImageButtonVisible,
            isConfigurable: true,
            isCollapsed: false,
            minWidth: 36,
            maxWidth: 158,
        },
        [ButtonType.Download]: {
            id: ButtonType.Download,
            priority: 3,
            isVisible: true,
            isConfigurable: true,
            isCollapsed: false,
            minWidth: 36,
            maxWidth: 174,
        },

        [ButtonType.Print]: {
            id: ButtonType.Print,
            priority: 0,
            isVisible: isPrintButtonVisible,
            isConfigurable: false,
            isCollapsed: true,
            minWidth: 36,
            maxWidth: 36,
        },
        [ButtonType.Separator]: {
            id: ButtonType.Separator,
            priority: 0,
            isVisible: isOvidius,
            isConfigurable: false,
            isCollapsed: true,
            minWidth: 28,
            maxWidth: 28,
        },
        [ButtonType.Increase]: {
            id: ButtonType.Increase,
            priority: 0,
            isVisible: isOvidius,
            isConfigurable: false,
            isCollapsed: true,
            minWidth: 36,
            maxWidth: 36,
        },
        [ButtonType.Decrease]: {
            id: ButtonType.Decrease,
            priority: 0,
            isVisible: isOvidius,
            isConfigurable: false,
            isCollapsed: true,
            minWidth: 36,
            maxWidth: 36,
        },
    };

    // ======= 1. Докручиваем конфиги для корнер кейсов =======
    // для PDF и Ovidius эти кнопки свёрнуты и не разворачиваются
    if (isPdf || isOvidius) {
        buttonsConfig[ButtonType.Clone].isCollapsed = true;
        buttonsConfig[ButtonType.Clone].isConfigurable = false;

        buttonsConfig[ButtonType.Download].isCollapsed = true;
        buttonsConfig[ButtonType.Download].isConfigurable = false;
    }

    // ======= 2. Нормализуем данные в конфигах =======
    Object.values(buttonsConfig).forEach((el) => {
        // Все конфигурируемые кнопки разворачиваются из свёрнутого состояния
        if (el.isConfigurable) {
            el.isCollapsed = true;
        }

        // Незачем конфигурировать кнопку, которую не видно
        if (!el.isVisible) {
            el.isConfigurable = false;
        }
    });

    // ======= 3. Расчёт доступной для кнопок ширины =======
    // Паддинги у контейнера хедера (24 слева + 16 справа)
    const headerPaddings = 40;

    // На глаз, offsetWidth юзать не выйдет, левая и правая группы сами растягиваются.
    const leftGroupWidth = 500;
    const marketingButtonWidth = isPromoButtonVisible ? 150 : 0;
    const minRightGroupWidth = (isRightGroupVisible ? 100 : 0) + marketingButtonWidth;

    const maxControlsWidth = headerWidth - headerPaddings - leftGroupWidth - minRightGroupWidth;

    const buttonsGap = 6;
    const visibleButtons = Object.values(buttonsConfig).filter((el) => el.isVisible);
    const minControlsWidth =
        visibleButtons.reduce((acc, config) => {
            const initialWidth = config.isCollapsed ? config.minWidth : config.maxWidth;
            return acc + initialWidth;
        }, 0) +
        (visibleButtons.length - 1) * buttonsGap;

    let availableWidth = maxControlsWidth - minControlsWidth;

    // ======= 4. Разворачиваем кнопки от большего приоритета к меньшему =======
    const sortedConfigurableButtons = Object.values(buttonsConfig)
        .filter((el) => el.isVisible && el.isConfigurable)
        .sort((a, b) => b.priority - a.priority);

    let isExpansionDone = false;
    for (const config of sortedConfigurableButtons) {
        const widthIncrease = config.maxWidth - config.minWidth;

        if (!isExpansionDone && availableWidth > widthIncrease) {
            availableWidth -= widthIncrease;
            config.isCollapsed = false;
        } else {
            isExpansionDone = true;
            config.isCollapsed = true;
        }
    }

    return buttonsConfig;
};
