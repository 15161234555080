import settings from 'Cloud/settings';
import { parse } from 'qs';
import { path, pathOr } from 'ramda';
import { patchPaysourceWithAdmitad } from 'reactApp/utils/admitadHelper';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

/* Эти параметры урла прилетают с SRR и остаются неизменными до перезагрузки страницы. Если просто брать параметры из урла, то там они могут могут меняться без перезагрузки страницы */
export const getQueryParams: any = () => {
    return pathOr({}, ['request', 'query_params'], settings);
};

const { utm_source = '', utm_campaign = '', utm_medium = '', mode = '', viewerOnly = false } = getQueryParams();

export const getUtmObject = () => {
    // Иногда в query_string подставляется только uri
    const serverQuery =
        settings?.request?.query_string && settings?.request?.query_string !== settings?.request?.uri
            ? settings?.request?.query_string
            : '';
    // Пробуем брать query из клиента и если там нет - из серверсайда.
    const { utm_source, utm_campaign, utm_term, utm_medium } =
        parse(window.location.search || serverQuery, { ignoreQueryPrefix: true }) || {};
    return { utm_source, utm_campaign, utm_term, utm_medium };
};

export const getUtmSourceString = () => {
    const utms = getUtmObject();

    // если есть хоть одна utm, создаем строку из utm
    if (!Object.keys(utms).some((key) => Boolean(utms[key]))) {
        return '';
    }

    return getTrimmedText(Object.values(utms).join(';'), 100);
};

export const getSourceString = (source, isPhone, paySource) => {
    const utm = getUtmSourceString();
    const patchedPaySource = patchPaysourceWithAdmitad(paySource);
    return (
        (patchedPaySource?.length ? `${patchedPaySource}_` : '') +
        (utm?.length ? `${utm}_` : '') +
        (path(['params', 'IS_WEBVIEW'], settings) ? 'webview' : isPhone ? 'touch' : 'web') +
        (source?.length ? `_${source}` : '')
    );
};

export const getInitialRequestId = (): string | undefined => {
    return path(['request', 'id'], settings);
};

export const getIsPwaMode = () => mode === 'standalone';

export const isViewContentOnly = viewerOnly;

export const isHiDPI = typeof window !== 'undefined' && window.devicePixelRatio >= 2;

export const isPromoQuotaInAndroid = utm_source === 'cloud_app' && utm_campaign === 'promo_quota' && utm_medium === 'android';
