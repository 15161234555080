import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';

import { EWaitDelay, REWIND_TIME } from './constants';

export const stimulateNav = (player: VideoPlayer) => {
    player.reportUserActivity(true);
};

export const hideNav = (player: VideoPlayer) => {
    player.userActive(false);
    player.userActivity_ = false;
};

export const toggleNav = (player: VideoPlayer) => {
    if (player.userActive()) {
        hideNav(player);
    } else {
        stimulateNav(player);
    }
};

export const goBackward = (player: VideoPlayer): boolean => {
    const currentTime = player.currentTime();
    if (currentTime > EWaitDelay.BEFORE_FORGET / 1000) {
        player.trigger('rewindbackward');
        player.currentTime(currentTime - REWIND_TIME);
        return true;
    }
    return false;
};

export const goForward = (player: VideoPlayer): boolean => {
    const currentTime = player.currentTime();
    const remainingTime = player.remainingTime();
    if (remainingTime > EWaitDelay.BEFORE_FORGET / 1000) {
        player.trigger('rewindforward');
        player.currentTime(currentTime + REWIND_TIME);
        return true;
    }
    return false;
};

export const togglePlay = (player: VideoPlayer) => {
    if (player.paused()) {
        player.play();
    } else {
        player.pause();
    }
};
