import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { noopVoid } from 'reactApp/utils/helpers';

export const renderReactTooltip =
    (tooltipName) =>
    ({ onClose = noopVoid, onAction = noopVoid, ...rest }) => {
        const close = () => {
            closePopupHelper(tooltipName);
        };

        close();
        openPopupHelper({
            popupName: tooltipName,
            onClose: (...params) => {
                close();
                onClose(...params);
            },
            data: {
                ...rest,
                onAction: (...params) => {
                    close();
                    onAction(...params);
                },
            },
        });
        return close;
    };
