import {
    Icon20CloudOutline,
    Icon20SmileAddOutline,
    Icon20UsersOutline,
    Icon24CloudOutline,
    Icon24SmileAddOutline,
    Icon24UsersOutline,
} from '@vkontakte/icons';
import React from 'react';

import { type FamilyFeatureProps, FeaturesColor } from './FamilyPromo.types';

export const featuresList: FamilyFeatureProps[] = [
    {
        color: FeaturesColor.blue,
        compactColor: FeaturesColor.orange,
        icon: <Icon24SmileAddOutline />,
        compactIcon: <Icon20SmileAddOutline />,
        title: '+3 места',
        text: 'Приглашайте близких в Mail Space в любое время. Это бесплатно',
        id: 'place',
    },
    {
        color: FeaturesColor.green,
        compactColor: FeaturesColor.darkGreen,
        icon: <Icon24CloudOutline />,
        compactIcon: <Icon20CloudOutline />,
        title: 'Платите столько же',
        text: 'Все участники пользуются подпиской, при этом её стоимость не меняется',
        id: 'price',
    },
    {
        color: FeaturesColor.purple,
        compactColor: FeaturesColor.violet,
        icon: <Icon24UsersOutline />,
        compactIcon: <Icon20UsersOutline />,
        title: 'У каждого свой аккаунт',
        text: 'А выгода общая: никакой рекламы и больше места для файлов',
        id: 'accounts',
    },
];
