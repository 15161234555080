/* eslint-disable max-lines-per-function */
import { Icon24Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, type RefObject, createRef, PureComponent } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { SwipingDownComponent } from 'reactApp/ui/SwipingDownComponent/SwipingDownComponent';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './MobileDialog.css';

interface Props {
    closable?: boolean;
    open?: boolean;
    dimmer?: boolean;
    isTablet?: boolean;
    fullHeight?: boolean;
    children?: ReactNode;
    icon?: ReactElement;
    title?: string | ReactNode;
    footer?: null | ReactNode;
    text?: string | ReactElement;
    actions?: {
        text: string;
        onClick: (e: React.MouseEvent) => void;
        primary?: boolean;
        id: string;
    }[];
    onClose: (e: React.MouseEvent) => void;

    onShow?(): void;

    closeOnDimmerClick?: boolean;
    closableStateInside?: boolean;
    mod?: null | 'mobileV1' | 'mobileV2' | 'base' | 'confirm' | 'mobileV3' | 'mobileV4';
    disableScrolling?: boolean;
    id?: string;
    contextPadding?: 'zero' | 24 | 8 | undefined;
    topmost?: boolean;
    // Сюда нужно передавать ноду, которую нужно скроллить внутри диалога. Критично для iOS
    scrollRef?: RefObject<HTMLDivElement>;
    className?: string;
    innerClassNames?: {
        title?: string;
        footer?: string;
    };
    closeMode?: 'round';
    isRebrandingQuotaLanding?: boolean;
    isRebranding?: boolean;
    content16PaddingOnly?: boolean;
}

interface State {
    show: boolean;
    visible: boolean;
}

const TRANSITION_TIMEOUT = 250;

export class MobileDialog extends PureComponent<Props, State> {
    public static defaultProps = {
        open: true,
        dimmer: true,
        closable: true,
        closeOnDimmerClick: false,
        closableStateInside: false,
        fullHeight: false,
        mod: null,
        disableScrolling: true,
        id: 'MobileDialog',
        topmost: false,
        isRebranding: false,
    };

    public state = {
        show: !!this.props.open,
        visible: true,
    };

    public rootRef: RefObject<HTMLDivElement> = createRef();

    scrollableRef: RefObject<HTMLDivElement> = this.props.scrollRef || this.rootRef;

    public componentDidMount(): void {
        if (this.props.open && this.props.disableScrolling) {
            scrollLock(this.scrollableRef?.current, { reserveScrollBarGap: false });
        }

        this.props.onShow?.();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.open !== this.props.open && this.props.disableScrolling) {
            if (this.props.open) {
                scrollLock(this.scrollableRef?.current, { reserveScrollBarGap: false });
            } else {
                scrollUnlock(this.scrollableRef?.current);
            }
        }
    }

    public componentWillUnmount(): void {
        if (!this.props.disableScrolling) {
            return;
        }

        scrollUnlock(this.scrollableRef?.current);
    }

    private handleOnDimmerClick = (e): void => {
        const { onClose = noop, closableStateInside, closeOnDimmerClick } = this.props;
        const { visible } = this.state;

        if (!closeOnDimmerClick || !visible) {
            return;
        }

        this.setState({ visible: false });

        if (closableStateInside) {
            this.setState({ show: false });
        }

        setTimeout(() => onClose(e), TRANSITION_TIMEOUT);
    };

    private handleOnClose = (e): void => {
        const { onClose = noop, closableStateInside } = this.props;
        const { visible } = this.state;

        if (!visible) {
            return;
        }

        this.setState({ visible: false });

        if (closableStateInside) {
            this.setState({ show: false });
            scrollUnlock(this.scrollableRef?.current);
        }

        setTimeout(() => onClose(e), TRANSITION_TIMEOUT);
    };

    private renderContent = (): ReactNode => {
        const { title, icon, text, actions, mod, footer, children, innerClassNames } = this.props;

        if (children) {
            return (
                <>
                    {title && <div className={classNames(styles.title, innerClassNames?.title)}>{title}</div>}
                    {children}
                    {footer && <div className={classNames(styles.footer, innerClassNames?.footer)}>{footer}</div>}
                </>
            );
        }

        return (
            <>
                {icon && <div className={styles.icon}>{icon}</div>}
                {title && (
                    <div
                        className={classNames({
                            [styles.title]: true,
                            [styles[`title_${mod}`]]: !!mod,
                        })}
                    >
                        {title}
                    </div>
                )}
                {text && (
                    <div
                        className={classNames({
                            [styles.text]: true,
                            [styles[`text_${mod}`]]: !!mod,
                        })}
                    >
                        {text}
                    </div>
                )}
                {actions && (
                    <div className={styles.actions}>
                        {actions.map(
                            (action): ReactElement => (
                                <div className={styles.action} key={action.id}>
                                    <Button
                                        qaId={action.id}
                                        onClick={action.onClick}
                                        primary={action.primary}
                                        fluid
                                        sizeMode={mod === 'mobileV3' ? ButtonSizeMode.extraBig : ButtonSizeMode.big}
                                        theme={mod === 'mobileV3' ? 'vk' : 'octavius'}
                                        className={mod === 'mobileV2' ? styles.button : ''}
                                    >
                                        {action.text}
                                    </Button>
                                </div>
                            )
                        )}
                    </div>
                )}
                {footer && <div className={styles.footer}>{footer}</div>}
            </>
        );
    };

    public render(): ReactElement | null {
        const {
            dimmer,
            closable,
            open,
            closableStateInside,
            mod,
            isTablet,
            id,
            contextPadding,
            fullHeight,
            className = '',
            topmost = false,
            closeMode,
            isRebrandingQuotaLanding = false,
            isRebranding,
            content16PaddingOnly = false,
        } = this.props;

        if (!open || (closableStateInside && !this.state.show)) {
            return null;
        }

        return (
            <Portal>
                <div className={classNames(styles.modal, { [styles.modal_topmost]: topmost })}>
                    {dimmer && (
                        <CSSTransition
                            in={this.state.visible}
                            timeout={TRANSITION_TIMEOUT}
                            appear
                            key={`dimmer-${id}`}
                            classNames={{
                                exit: styles.overlay_exit,
                                exitActive: styles.overlay_exit_active,
                                appear: styles.overlay_appear,
                                appearActive: styles.overlay_appear_active,
                            }}
                        >
                            <div data-testid="dimmer" className={styles.dimmer} onClick={this.handleOnDimmerClick}>
                                <SwipingDownComponent onClose={this.handleOnClose} lock={!closable} swipeSizePercent={20}>
                                    <></>
                                </SwipingDownComponent>
                            </div>
                        </CSSTransition>
                    )}
                    <CSSTransition
                        in={this.state.visible}
                        timeout={TRANSITION_TIMEOUT}
                        appear
                        key={`root-${id}`}
                        classNames={{
                            exit: styles.animation_exit,
                            exitActive: styles.animation_exit_active,
                            enter: styles.animation_appear,
                            enterActive: styles.animation_appear_active,
                            appear: styles.animation_appear,
                            appearActive: styles.animation_appear_active,
                        }}
                    >
                        <div
                            ref={this.rootRef}
                            data-qa-modal={id}
                            className={classNames({
                                [styles.root]: true,
                                [styles[`root_${mod}`]]: !!mod,
                                [styles.root_tablet]: isTablet,
                                [styles.root_maxWidth]: IS_MY_TEAM,
                                [styles.root_fullHeight]: fullHeight,
                                [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                                [styles.root_rebranding]: isRebranding,
                                [styles.root_content16PaddingOnly]: content16PaddingOnly,
                            })}
                        >
                            <SwipingDownComponent
                                onClose={this.handleOnClose}
                                lock={!closable}
                                ignoreOpacity={true}
                                swipeSizePercent={20}
                                wrapClass={styles.swipingDownWrapper}
                            >
                                <div
                                    className={classNames({
                                        [styles.content]: true,
                                        [styles[`content_${mod}`]]: !!mod,
                                        [styles[`content_pad_${contextPadding}`]]: !!contextPadding,
                                        [className]: className,
                                    })}
                                >
                                    {closable && (
                                        <div
                                            data-testid="close"
                                            className={classNames({
                                                [styles.close]: true,
                                                [styles[`close_${mod}`]]: !!mod,
                                                [styles[`close_${closeMode}`]]: !!closeMode,
                                            })}
                                            onClick={this.handleOnClose}
                                        >
                                            <Icon24Cancel fill="currentColor" />
                                        </div>
                                    )}
                                    {this.renderContent()}
                                </div>
                            </SwipingDownComponent>
                        </div>
                    </CSSTransition>
                </div>
            </Portal>
        );
    }
}
