import type { State } from 'reactApp/modules/paidInfo/paidInfo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getPaidInfoState = (state: RootState) => state.paidInfo as State;

const getLoadingState = createSelector(
    getPaidInfoState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const isUserWithoutPayment = createSelector(
    getPaidInfoState,
    getLoadingState,
    (state: State, loading: LoadingState): boolean => loading.isLoaded && state.paidExpires === 0 && state.trialExpires === 0
);

const isFreeUserWithoutTrials = createSelector(
    getPaidInfoState,
    getLoadingState,
    UserSelectors.isPaidUser,
    (state: State, loading: LoadingState, isPaidUser: boolean): boolean => loading.isLoaded && state.trialExpires === 0 && !isPaidUser
);

export const PaidInfoSelectors = {
    getLoadingState,
    isUserWithoutPayment,
    isFreeUserWithoutTrials,
};
