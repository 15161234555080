import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16Done } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import coverImageMobileRebranding from 'img/family/coverImageMobileRebranding.png';
import coverImageMobileRebrandingDark from 'img/family/coverImageMobileRebrandingDark.png';
import coverImage from 'img/family/familyAcceptInviteCover.jpg';
import coverImageMobile from 'img/family/familyAcceptInviteCoverMobile.jpg';
import coverImageRebranding from 'img/family/familyAcceptInviteCoverRebranding.png';
import coverImageRebrandingDark from 'img/family/familyAcceptInviteCoverRebrandingDark.png';
import { ReactComponent as DoneIcon } from 'mrg-icons/src/mailru/status/done.svg';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme, isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { familyInviteAccept } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsOnboardingActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './FamilyAcceptInviteDialog.css';
import type { FamilyAcceptInviteDialogProps } from './FamilyAcceptInviteDialog.types';

export const FamilyAcceptInviteDialog = memo<FamilyAcceptInviteDialogProps>(({ onClose, link, owner, ownerQuota }) => {
    const dispatch = useDispatch();
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const total = useSelector(UserQuotaSelectors.getTotal);
    const userQuota = total?.original ? total.original - BASEQUOTA : BASEQUOTA;
    const isMobile = EnvironmentSelectors.isPhone();
    const scrollRef = useRef<HTMLDivElement>(null);

    let image = coverImage;
    let mobileImage = coverImageMobile;

    if (isRebranding) {
        image = isDarkTheme ? coverImageRebrandingDark : coverImageRebranding;
        mobileImage = isDarkTheme ? coverImageMobileRebrandingDark : coverImageMobileRebranding;
    }

    const doneIcon = isRebranding ? <Icon16Done /> : <DoneIcon />;

    const handleAcceptInvite = useCallback(() => {
        dispatch(familyInviteAccept(link));
        onClose?.();
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.onboarding,
            action: FamilyAnalyticsOnboardingActions.try,
            creator: false,
            creator_to_tariff: false,
            guest: true,
            step: 1,
        });
    }, [dispatch, link, onClose]);

    const handleClose = useCallback(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.onboarding,
            action: FamilyAnalyticsOnboardingActions.close,
            creator: false,
            creator_to_tariff: false,
            guest: true,
            step: 1,
        });
        dispatch(historyPush({ id: getIdByStorage(EStorageType.family) }));
        onClose?.();
    }, [onClose, dispatch]);

    useEffect(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.onboarding,
            action: FamilyAnalyticsOnboardingActions.show,
            creator: false,
            creator_to_tariff: false,
            guest: true,
            step: 1,
        });
    }, []);

    const content = useMemo(() => {
        return (
            <div className={styles.content}>
                <Spacing size={12} />
                {isPaidUser ? (
                    <>
                        <Text className={styles.text}>
                            <span>{owner}</span> приглашает вас пользоваться подпиской Mail Space вместе:
                        </Text>
                        <Spacing size={12} />
                        <ul className={styles.list}>
                            <li className={styles.listItem}>
                                {doneIcon}+{bytesToNDigits(ownerQuota, 3).value} в Облаке
                            </li>
                            <li className={styles.listItem}>
                                {doneIcon}
                                Без рекламы в Почте и Облаке
                            </li>
                            <li className={styles.listItem}>
                                {doneIcon}
                                Ваши файлы и {bytesToNDigits(userQuota, 3).value} в Облаке доступны только вам
                            </li>
                        </ul>
                    </>
                ) : (
                    <>
                        <Text className={styles.text}>
                            <span>{owner}</span> приглашает вас пользоваться подпиской Mail Space вместе:
                        </Text>
                        <Spacing size={12} />
                        <ul className={styles.list}>
                            <li className={styles.listItem}>
                                {doneIcon}+{bytesToNDigits(ownerQuota, 3).value} в Облаке
                            </li>
                            <li className={styles.listItem}>
                                {doneIcon}
                                Без рекламы в Почте и Облаке
                            </li>
                            <li className={styles.listItem}>
                                {doneIcon}
                                Ваши файлы доступны только вам
                            </li>
                        </ul>
                    </>
                )}
                <Spacing size={32} />
            </div>
        );
    }, [isPaidUser, owner, ownerQuota, userQuota]);

    if (isMobile) {
        return (
            <MobileDialog
                id="family-accept-invite-mobile"
                onClose={handleClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="base"
                contextPadding="zero"
            >
                <div ref={scrollRef} className={styles.rootMobile}>
                    <img className={styles.image} src={mobileImage} alt="Вас пригласили в подписку Mail Space" />
                    <Spacing size={24} />
                    <Text className={styles.title}>
                        {isPaidUser ? 'Вас пригласили в подписку Mail Space' : 'Приглашение в Mail\u00A0Space'}
                    </Text>
                    {content}
                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary fluid onClick={handleAcceptInvite}>
                            Принять приглашение
                        </Button>
                        <Spacing size={12} />
                        <Button theme="vk" fluid onClick={handleClose}>
                            Закрыть
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            title={isPaidUser ? 'Вас пригласили в подписку Mail Space' : 'Приглашение в Mail Space'}
            content={content}
            imageUrl={image}
            primaryButtonText="Принять приглашение"
            onClick={handleAcceptInvite}
            secondaryButtonText="Закрыть"
            onSecondary={handleClose}
            onClose={handleClose}
            qaId="family-accept-invite"
            imageBgColor="transparent"
        />
    );
});

FamilyAcceptInviteDialog.displayName = 'FamilyAcceptInviteDialog';
