import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import coverImage from 'img/quota-landing/litresSuccessPromo.png';
import coverImageMobile from 'img/quota-landing/litresSuccessPromoMobile.png';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IS_WEBVIEW, MAIL_HOST } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import opener from 'reactApp/utils/opener';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import styles from './LitresSuccessDialog.css';
import type { LitresSuccessDialogProps } from './LitresSuccessDialog.types';

export const LitresSuccessDialog = memo<LitresSuccessDialogProps>(({ onClose }) => {
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);
    const scrollRef = useRef<HTMLDivElement>(null);
    const currentUserEmail = useSelector(UserSelectors.getEmail);
    const action = 'modal-got-litres';
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_SUCCESS_SHOW);
        sendQuotaGa({ action, label: 'show' }); // Дополнительно отправляем, так интересуют utm
        if (IS_WEBVIEW) {
            sendPostMessage({
                key: 'lirtes-succes-promo-showed',
            });
        }
    }, []);

    const handleClick = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_CHECK);
        sendQuotaGa({ action, label: 'click' });
        opener(`https://${MAIL_HOST}/inbox/`);
        onClose?.();
    }, [onClose]);

    const handleClose = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_SUCCESS_CLOSE);
        sendQuotaGa({ action, label: 'close' });
        onClose?.();
    }, [onClose]);

    const handleSecondaryClick = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_LATER);
        sendQuotaGa({ action, label: 'cancel' });
        onClose?.();
    }, [onClose]);

    const content = useMemo(() => {
        return (
            <div className={styles.content}>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Отправили код на адрес <span className={styles.email}>{currentUserEmail}</span> — скопируйте и&nbsp;активируйте его на
                    сайте сервиса
                </Text>
                <Spacing size={24} />
            </div>
        );
    }, []);

    if (isMobile) {
        return (
            <MobileDialog
                id="autoupload-10tb-payment-success-mobile"
                onClose={handleClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="base"
                contextPadding="zero"
                isRebrandingQuotaLanding
            >
                <div
                    ref={scrollRef}
                    className={classNames(styles.rootMobile, {
                        [styles.rootMobile_rebranding]: isRebrandingQuotaLanding,
                    })}
                >
                    <div className={styles.imageContainer}>
                        <img src={coverImageMobile} className={styles.image} alt="Промокод на Литрес уже у вас в почте" />
                    </div>
                    <Spacing size={48} />
                    <Title level="1" className={styles.title}>
                        Промокод на Литрес
                        <br />
                        уже у вас в почте
                    </Title>
                    <Spacing size={12} />
                    <Text className={styles.text}>
                        Отправили код на адрес
                        <br />
                        <span className={styles.email}>{currentUserEmail}</span> — скопируйте
                        <br />и активируйте его на сайте сервиса
                    </Text>
                    <Spacing size={24} />
                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary onClick={handleClick} sizeMode={ButtonSizeMode.big}>
                            Проверить почту
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            imageUrl={coverImage}
            imageClassName={styles.image}
            className={styles.wscontent}
            title="Промокод на Литрес уже у вас в почте"
            content={content}
            primaryButtonText="Проверить почту"
            secondaryButtonText="Активирую позже"
            onSecondary={handleSecondaryClick}
            onClick={handleClick}
            onClose={handleClose}
            imageBgColor="var(--vkui--color_background_primary)"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="litres-payment-success"
            isRebranding
            isRebrandingQuotaLanding
        />
    );
});

LitresSuccessDialog.displayName = 'LitresSuccessDialog';
