import { themeOutsourceVersion, useLocalThemeOutsource } from './default-values';
import { ThemeManager } from './themeManager';
import { getThemeOutsourcePath } from './utils';

const themeManager = new ThemeManager(getThemeOutsourcePath(themeOutsourceVersion, useLocalThemeOutsource));

async function getAvailableThemes() {
    return await themeManager.getAvailableThemes();
}

async function applyTheme(themeId: string) {
    return await themeManager.applyTheme(themeId);
}

async function applyBodyTheme() {
    return await themeManager.applyBodyTheme();
}

function getBodyTheme() {
    return themeManager.getThemeIdFromBody();
}

export const ThemeOutsourceHelpers = {
    getAvailableThemes,
    applyTheme,
    applyBodyTheme,
    getBodyTheme,
} as const;
