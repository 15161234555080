import React from 'react';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { filesPluralGenitive, foldersPluralGenitive } from 'reactApp/utils/pluralHelpers';

import type { DialogComponentRecord, GetMainTitleProps, SelectedItemData } from './SelectFolderDialog.types';

const getFilesAndFoldersCount = (selectedItems: SelectedItemData[]) => {
    const folders = selectedItems.filter((item) => item.isFolder).length;
    const files = selectedItems.length - folders;

    return { files, folders };
};

export const getMainTitle = ({ title: originalTitle, selectedItems, waiterText, fullTitle, isInAction, isPhone }: GetMainTitleProps) => {
    if (fullTitle) {
        return fullTitle;
    }

    if (isInAction) {
        return waiterText;
    }

    if (selectedItems.length === 1 && selectedItems[0].id === ROOT_FOLDER_ID) {
        return originalTitle;
    }

    const { files, folders } = getFilesAndFoldersCount(selectedItems);

    let title = '';

    if (files) {
        title += `${files} ${filesPluralGenitive(files)}`;
    }

    if (files && folders) {
        title += ' и ';
    }

    if (folders) {
        title += `${folders} ${foldersPluralGenitive(folders)}`;
    }

    return isPhone ? `${originalTitle}:` : `${originalTitle} ${title}`;
};

export const getSubjectTitle = (selectedItems: SelectedItemData[]) => {
    const { files, folders } = getFilesAndFoldersCount(selectedItems);

    let alertSubjectTitle = '';

    if (files) {
        alertSubjectTitle = files > 1 ? 'файлы' : 'файл';
    }

    if (files && folders) {
        alertSubjectTitle += ' и ';
    }

    if (folders) {
        alertSubjectTitle += folders > 1 ? 'папки' : 'папку';
    }

    return alertSubjectTitle;
};

export const DIALOG_COMPONENTS_TYPES: DialogComponentRecord = {
    mobile: ({ children, ...rest }) => (
        <MobileDialog topmost mod="confirm" {...rest}>
            {children}
        </MobileDialog>
    ),
    desktop: ({ children, onClose, title, ...rest }) => (
        <Dialog closeOnEscape header={title} mod="selectFolders" onCancel={onClose} disableScrollOnBody={false} {...rest}>
            {children}
        </Dialog>
    ),
};
