import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type { ApiPaidInfoResponse, State } from 'reactApp/modules/paidInfo/paidInfo.types';

const initialState: State = {
    isLoading: false,
    isLoaded: false,
    paidInfo: {
        android: { trial: 0, paid: 0 },
        ios: { trial: 0, paid: 0 },
        web: { trial: 0, paid: 0 },
        huawei: { trial: 0, paid: 0 },
    },
    trialExpires: 0,
    paidExpires: 0,
};

export const loadPaidInfoRequest = createAction('paidInfo/loadPaidInfoRequest');
export const loadPaidInfoSuccess = createAction<ApiPaidInfoResponse>('paidInfo/loadPaidInfoSuccess');
export const sendPaymentLetter = createAction<{ id: string }>('paidInfo/sendPaymentLetter');

export const paidInfoReducer = createReducer(initialState, {
    [loadPaidInfoRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadPaidInfoSuccess.type]: (state, action: PayloadAction<ApiPaidInfoResponse>) => {
        state.isLoading = false;
        state.isLoaded = true;

        const { paid_info: paidInfo } = action.payload.user;

        if (paidInfo) {
            Object.keys(state.paidInfo).forEach((device) => {
                const trial = paidInfo[device].trial * 1000;
                const paid = paidInfo[device].paid * 1000;

                state.paidInfo[device] = { trial, paid };

                state.paidExpires = paid > state.paidExpires ? paid : state.paidExpires;
                state.trialExpires = trial > state.trialExpires ? trial : state.trialExpires;
            });
        }
    },
});
