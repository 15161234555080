import type { Vhs } from './VideoPlayer.types';

/**
 * Конфиг алгоритма выбора качества
 * TODO: Вынести константы в омикрон или запрашивать их с бэка
 */
export const defaultConfig = {
    /**
     * Нижная граница буфера плеера, начиная с которой применяется алгоритм BBA
     * Равен ~ сумме двух первых легких сегментов видео по 2,56 секунд
     */
    lowerBoundary: 5, // Два первых легких сегмента

    /**
     * Константа наклона прямой соотношения буфера и качества видео
     * @link https://www.desmos.com/calculator/hkoqyolakh
     */
    a: 0.0000004,

    /**
     * Размен сегмента видео, определяется на бэке
     */
    chunkSize: 6.4,

    /**
     * Bandwidth плейлиста с наихудшим качеством
     */
    minBandwidth: 650000,

    /**
     * Bandwidth плейлиста с наилучшим качеством
     */
    maxBandwidth: 7900000,
};

export type ConfigType = typeof defaultConfig;

/**
 * Функция рассчета верхней границы буфера, после которой мы всегда берем наивысшее качество, в зависимости от пропускной способности сети
 * @param bandwidth - Пропускная способность сети
 * @param config - Конфиг алгоритма
 * @returns Верхняя граница буфера в секундах
 */
export function calcUpperBoundary(bandwidth: number, { a, lowerBoundary, chunkSize, maxBandwidth, minBandwidth }: ConfigType) {
    const bandwidthDiff = maxBandwidth - minBandwidth;

    return (bandwidthDiff * (1 + (a * bandwidth) / chunkSize)) / (bandwidth / chunkSize - a) + lowerBoundary;
}

/**
 * Функция получения bandwidth'a плейлиста на основе длительности буфера видеоплеера
 * @param buffered - Размер буфера в секундах
 * @param upperBoundary - Верхняя граница буфера в секундах
 * @param config - Конфиг алгоритма
 * @returns Bandwidth плейлиста, который мы можем загрузить
 */
export function mapBufferToBandwidth(
    buffered: number,
    upperBoundary: number,
    { maxBandwidth, minBandwidth, lowerBoundary }: Omit<ConfigType, 'a'>
) {
    return ((maxBandwidth - minBandwidth) / (upperBoundary - lowerBoundary)) * (buffered - lowerBoundary) + minBandwidth;
}

/**
 * КОПИПАСТ ИЗ VIDEO.JS/HTTP-STREAMING
 *
 * A utility function to add up the amount of time in a timeRange
 * after a specified startTime.
 * ie:[[0, 10], [20, 40], [50, 60]] with a startTime 0
 *     would return 40 as there are 40s seconds after 0 in the timeRange
 *
 * @param range - The range to check against
 * @param startTime - The time in the time range that you should start counting from
 *
 * @return The number of seconds in the buffer passed the specified time.
 */
export function timeAheadOf(range: TimeRanges, startTime: number): number {
    let time = 0;

    if (!range || !range.length) {
        return time;
    }

    for (let i = 0; i < range.length; i++) {
        const start = range.start(i);
        const end = range.end(i);

        // startTime is after this range entirely
        if (startTime > end) {
            continue;
        }

        // startTime is within this range
        if (startTime > start && startTime <= end) {
            time += end - startTime;
            continue;
        }

        // startTime is before this range.
        time += end - start;
    }

    return time;
}

export function bufferBasedSelectPlaylist(bandwidthPlaylistSelector?: () => void, config: ConfigType = defaultConfig) {
    return function (this: Vhs) {
        // Оборачиваем плейлисты в удобное представление и сортируем по bandwidth'у
        // TODO: Оптимизировать - сортировать список плейлистов только если он обновился
        const playlists = this.playlists.master.playlists
            .map((p) => ({ playlist: p, bandwidth: p.attributes.BANDWIDTH }))
            .sort((left, right) => left.bandwidth - right.bandwidth);

        if (playlists.length <= 0) {
            return null;
        }

        const currentPlaylist = this.playlists.media();
        if (!currentPlaylist) {
            return bandwidthPlaylistSelector?.call(this) || playlists[0].playlist;
        }

        const currentPlaylistBandwidth = currentPlaylist.attributes.BANDWIDTH;

        // Кол-во секунд в буфере плеера
        const buffered = timeAheadOf(this.tech_.buffered(), this.tech_.currentTime());

        const { lowerBoundary } = config;
        const upperBoundary = calcUpperBoundary(this.systemBandwidth, config);

        // Плейлист с bandwidth, следующей за bandwidth текущего плейлиста
        const nextOrderBandwidthPlaylist = playlists.find((p) => p.bandwidth > currentPlaylistBandwidth) || playlists.at(-1)!;
        // Плейлист с bandwidth, предшествующей bandwidth текущего плейлиста
        const previousOrderBandwidthPlaylist = playlists.filter((p) => p.bandwidth < currentPlaylistBandwidth).at(-1) || playlists[0];

        if (buffered <= lowerBoundary) {
            return bandwidthPlaylistSelector?.call(this) || playlists[0].playlist;
        } else if (buffered >= upperBoundary) {
            return playlists.at(-1)!.playlist;
        }

        // Рекомендуемая bandwidth при текущем уровне буфера
        const recommendedBandwidth = mapBufferToBandwidth(buffered, upperBoundary, config);

        if (recommendedBandwidth >= nextOrderBandwidthPlaylist.bandwidth) {
            return playlists.filter((p) => p.bandwidth < recommendedBandwidth).at(-1)!.playlist;
        } else if (recommendedBandwidth <= previousOrderBandwidthPlaylist.bandwidth) {
            return playlists.find((p) => p.bandwidth > recommendedBandwidth)!.playlist;
        }

        return currentPlaylist;
    };
}
