import 'rbadman';

import config from 'Cloud/config';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { addCentralNavigation } from 'reactApp/ui/ReactViewer/VideoPlayer/CustomNavigation/components/centralNavigation';
import {
    addControlBarShadow,
    addControlBarSpacer,
    addControlBarWrapper,
} from 'reactApp/ui/ReactViewer/VideoPlayer/CustomNavigation/components/nativeNavigation/nativeNavigation';
import { stimulateNav } from 'reactApp/ui/ReactViewer/VideoPlayer/CustomNavigation/helpers';
import type { GaData } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayerGa';
import { sendKaktamLog } from 'reactApp/utils/ga';
import videojs from 'video.js';

import type { VideoPlayerWithPlugins } from '../VideoPlayer.types';
import styles from './styles.css';

const Component = videojs.getComponent('Component');

declare global {
    const AdmanHTML: any;
}

interface AdProps {
    player: VideoPlayerWithPlugins;
    controlBarSettings?: videojs.ControlBarOptions;
    isPhone: boolean;
    isVideo: boolean;
    gaData: GaData;
}

const addAdCustomNavigation = (adPlayer: VideoPlayerWithPlugins, isPhone: boolean, adm) => {
    adPlayer.addClass(styles.adm);
    addControlBarWrapper(adPlayer);
    addControlBarShadow(adPlayer);
    if (isPhone) {
        addControlBarSpacer(adPlayer);
    }

    const { showPlayButton, showPauseButton } = addCentralNavigation({
        player: adPlayer,
        className: styles.admNavigation,
        withSeeking: false,
        onPlay: () => {
            stimulateNav(adPlayer);
            adm.resume();
        },
        onPause: () => {
            stimulateNav(adPlayer);
            adm.pause();
        },
    });

    adPlayer.on('pause', showPlayButton);
    adPlayer.on('play', showPauseButton);
};

export const adInit = ({ player, controlBarSettings, isPhone, gaData }: AdProps) => {
    if (!AdmanHTML) {
        sendKaktamLog(
            {
                action: 'ad-error',
                error_message: 'AdmanHTML not exist',
            },
            'cloud_video-player'
        );
        return {};
    }

    // add video tag to player
    const adVideoEl = document.createElement('video');
    adVideoEl.className = 'vjs-tech';
    const adVideo = new Component(player, { el: adVideoEl });
    player.addChild(adVideo);

    // init videojs for new video tag
    const adPlayer = videojs(adVideoEl, {
        language: 'ru',
        bigPlayButton: false,
        controls: true,
        fill: true,
        controlBar: {
            ...controlBarSettings,
        },
    }) as VideoPlayerWithPlugins;

    // disable SwipingComponent logic for controlBar
    adPlayer.controlBar.el().addEventListener('pointerdown', (event) => {
        event.stopPropagation();
    });

    // disable ProgressControl (block seeking)
    (adPlayer.controlBar.getChild('ProgressControl') as videojs.ProgressControl).disable?.();

    // redirect Fullscreen button
    const fullScreenToggle = adPlayer.controlBar.getChild('FullscreenToggle') as videojs.FullscreenToggle;

    if (fullScreenToggle) {
        fullScreenToggle.handleClick = (e) => {
            (player.controlBar.getChild('FullscreenToggle') as videojs.FullscreenToggle).handleClick(e);
        };
    }

    player.on('fullscreenchange', (e) => {
        (adPlayer.controlBar.getChild('FullscreenToggle') as videojs.FullscreenToggle).handleFullscreenChange(e);
    });

    // init Adman
    // https://confluence.vk.team/display/RB/AdmanHTML.js#AdmanHTML.js-%D0%98%D0%BD%D0%B8%D1%86%D0%B8%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8FAdmanHTML
    const adm = new AdmanHTML();

    const playerHeight = player.currentHeight();
    const playerWidth = player.currentWidth();
    const preview = config.get('RB_SLOT_PARAM')?.split('=')[1];
    adm.init({
        slot: 984764,
        params: {
            app: 'embedded',
            sitename: location.hostname,
            width: playerWidth,
            height: playerHeight,
            ...(preview && { preview }),
            ...(document.referrer && { dl: encodeURIComponent(document.referrer) }),
            duration: Math.floor(player.duration()),
        },
        wrapper: adPlayer.el(),
        videoEl: adPlayer.$('video'),
        audioEl: adPlayer.$('video'),
        playerEl: player.$('video'),
        viewabilityEl: player.$('video'),
        viewabilityValue: 0.6,
        videoQuality: playerHeight,
        browser: {
            adBlock: false,
            mobile: false,
        },
    });

    // styles
    addAdCustomNavigation(adPlayer, isPhone, adm);

    // Preroll
    adm.onReady(() => {
        adm.start();
    });

    // Midroll
    const updatePosition = () => {
        adm.setPosition(player.currentTime(), player.duration());
    };
    let interval = setInterval(updatePosition, 1000);
    player.on('play', () => {
        window.clearInterval(interval);
        updatePosition();
        interval = setInterval(updatePosition, 1000);
    });
    player.on('pause', () => {
        window.clearInterval(interval);
        updatePosition();
    });
    adm.adMidrollPoint(() => {
        adm.start('midroll');
    });

    // Pauseroll
    player.on('pause', () => {
        const currentTime = player.currentTime();
        const seeking = player.seeking();
        if (currentTime > 1 && !seeking) {
            adm.start('pauseroll');
        }
    });

    // Postroll
    player.on('ended', () => {
        adm.start('postroll');
    });

    // some fixes
    let continuedAfterAd = false;
    adm.onStarted((type, roll) => {
        if (!player.paused()) {
            player.pause();
            continuedAfterAd = true;
        }

        player.addClass(styles.admShow);
        player.controlBar.hide();

        if (roll?.allowClose) {
            // show skip button after roll?.allowCloseDelay
        }
        sendViewerDwh({ ...gaData, action: 'ad-start' });

        adm.onPlayed(() => {
            sendViewerDwh({ ...gaData, action: 'ad-continue' });
        });
    });
    // вызывается не только в конце ролика, но и при повторных выховах adm.start(...),
    // когда рекламу уже не показывает (adm.onStarted не стреляет в отличает от этого метода)
    adm.onCompleted(() => {
        if (player.hasClass(styles.admShow)) {
            player.removeClass(styles.admShow);
            player.controlBar.show();
            sendViewerDwh({ ...gaData, action: 'ad-end' });
        }
        if (continuedAfterAd) {
            player.play();
            continuedAfterAd = false;
        }

        adm.onPlayed(null);
    });

    adm.onPaused(() => {
        sendViewerDwh({ ...gaData, action: 'ad-stop' });
    });
    adm.onClosed(() => sendViewerDwh({ ...gaData, action: 'ad-close' }));
    // TODO проверить
    // adm.onSkipped(() => sendViewerDwh({ ...gaData, action: 'ad-close' }));

    adm.onError((error) => {
        if (player.hasClass(styles.admShow)) {
            player.removeClass(styles.admShow);
            player.controlBar.show();
        }

        sendViewerDwh({
            ...gaData,
            action: 'ad-error',
            type_error: error?.code?.toString(),
            error_message: error?.message,
        });

        sendKaktamLog(
            {
                ...gaData,
                action: 'ad-error',
                type_error: error?.code?.toString(),
                error_message: error?.message,
            },
            'cloud_video-player-ad'
        );
    });

    player.on('dispose', () => {
        adPlayer.dispose();
    });

    return { adm };
};
