import { useDispatch, useSelector } from 'react-redux';
import { cancelRenewTrialPeriod } from 'reactApp/appHelpers/featuresHelpers';
import { abPauseSubscription } from 'reactApp/appHelpers/featuresHelpers/features/pauseSubscription';
import {
    closePauseSubscriptionDialog,
    renderPauseSubscriptionDialog,
} from 'reactApp/components/PauseSubscriptionDialog/PauseSubscriptionDialog.helpers';
import { BuySubscriptionActions, BuySubscriptionSelectors } from 'reactApp/modules/buySubscription/buySubscription.module';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import type { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import type { HardlyUsedThunkDispatch } from 'reactApp/types/app';
import { renderCancelAutoRenew10TbModal } from 'reactApp/ui/CancelAutoRenew10TbModal/CancelAutoRenew10TbModal.helpers';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';

import type { DwhData } from '../CancelAutoRenewalModal.types';

export const useHandlers = (dwhData: DwhData, onClose: () => void, subscription: ISubscription) => {
    const dispatch = useDispatch<HardlyUsedThunkDispatch>();

    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);

    // tempexp
    const canPauseSubscription = useSelector(BuySubscriptionSelectors.getCanPauseSubscription) && abPauseSubscription;

    const sendAnalytics = (action: ESubscriptionModalAction) => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.CANCEL_AUTOPAY_RESTORE,
            action: `my-tariff-${action}`,
            dwhData,
        });
    };

    const handleCloseSubscription = (id: string, isRebrandingQuotaLanding?: boolean, isRebranding?: boolean) => {
        onClose();

        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_STEP2_CANCEL,
            dwhData,
        });

        const runCancelSubscriptionFlowAfterPausing = () => {
            // tempexp_16460-start
            if (AUTOUPLOAD_10TB_REGEX.test(subscription.productId)) {
                renderCancelAutoRenew10TbModal({ subscription });
                return;
            }
            // tempexp_16460-end

            // tempexp_13984-start
            if (abWithCaptcha === 'b') {
                renderCancelSubscriptionWithCaptchaModal({ subscriptionId: id, isPhone: true, isRebrandingQuotaLanding, isRebranding });
                return;
            }
            // tempexp_13984-end

            // tempexp_16480-next-line
            if (cancelRenewTrialPeriod) {
                dispatch(
                    BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial({
                        subscriptionId: id,
                        sendAnalytics,
                        period: cancelRenewTrialPeriod,
                    })
                );
            } else {
                dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: id, sendAnalytics }));
            }
        };

        if (canPauseSubscription) {
            renderPauseSubscriptionDialog({
                subscription,
                onClose: () => {
                    closePauseSubscriptionDialog();
                },
                onSuccess: () => {
                    dispatch(
                        BuySubscriptionActions.pauseSubscription({
                            subscriptionId: id,
                            period: abPauseSubscription,
                        })
                    );
                },
                onAction: () => {
                    closePauseSubscriptionDialog();
                    runCancelSubscriptionFlowAfterPausing();
                },
            });
        } else {
            runCancelSubscriptionFlowAfterPausing();
        }
    };

    const handleGoToAllSubscriptions = (isRebrandingQuotaLanding?: boolean, isRebranding?: boolean) => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_STEP2_SAFE_SUBS,
            dwhData,
        });

        onClose();
        renderSubscriptionsModal({ isRebrandingQuotaLanding, isRebranding });
    };

    return {
        handleCloseSubscription,
        handleGoToAllSubscriptions,
    };
};
