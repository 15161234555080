import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import type { ApiQuotaResponse, State } from 'reactApp/modules/userQuota/userQuota.types';

const initialState: State = {
    isLoading: false,
    isLoaded: false,
    isSingleQuotaEnabled: false,
    isMailBinDisabled: false,
    quota: null,
    used: {
        total: null,
        cloud: null,
        mail: null,
    },
};

export const loadUserQuotaRequest = createAction('userQuota/loadUserQuotaRequest');
export const loadUserQuotaSuccess = createAction<ApiQuotaResponse>('userQuota/loadUserQuotaSuccess');

export const userQuotaReducer = createReducer(initialState, {
    [loadUserQuotaRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadUserQuotaSuccess.type]: (state, action: PayloadAction<ApiQuotaResponse>) => {
        const { quota, used, single_quota_enabled: isSingleQuotaEnabled, disable_mail_bin: isMailBinDisabled, isSilent } = action.payload;

        state.isLoading = isSilent;
        state.isLoaded = !isSilent;

        state.isSingleQuotaEnabled = !!isSingleQuotaEnabled;
        state.isMailBinDisabled = !!isMailBinDisabled;

        state.quota = bytesToNDigits(quota, 3);
        state.used = {
            total: bytesToNDigits(IS_USER_WITH_ONLY_CLOUD ? used?.cloud : used?.total, 3),
            cloud: bytesToNDigits(used?.cloud, 3),
            mail: IS_USER_WITH_ONLY_CLOUD ? null : bytesToNDigits(used?.mail, 3),
        };
    },
});
