import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import type { IState } from './modifying.types';

const getModifyingStore = (state: RootState) => state.modifying as IState;

export const isModifyingInProgress = createSelector(getModifyingStore, (state) => state.isModifyingInProgress);

export const isInlineEditInProgress = createSelector(getModifyingStore, (state) => state.isInlineEditInProgress);

// tempexp_14373-start
export const getInlineEditState = createSelector(getModifyingStore, (state) => ({
    isInlineEditInProgress: state.isInlineEditInProgress,
    inlineEditItemId: state.inlineEditItemId,
}));
// tempexp_14373-end
