import type { WopiParams } from 'Cloud/Application/Editor/types';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';

export enum EMyOfficeMode {
    DEFAULT = 'default',
    READONLY = 'readonly',
    REVIEW = 'review',
}

export enum MYOFFICE_VARIANTS {
    wopi = 'wopi',
    amr = 'amr',
}

export enum MYOFFICE_VARIANT_NAMES {
    ces = 'ces', // wopi
    sek = 'sek', // amr
}

export const myOfficeVariantsMap = {
    [MYOFFICE_VARIANTS.wopi]: MYOFFICE_VARIANT_NAMES.ces,
    [MYOFFICE_VARIANTS.amr]: MYOFFICE_VARIANT_NAMES.sek,
};

export interface IInitMyoffice {
    container?: HTMLElement;
    iframeAmr?: HTMLIFrameElement | null;
    iframeWopi?: HTMLIFrameElement | null;
    isMyOfficeForMail?: boolean;
    isEmbedded?: boolean;
    sharingAttach?: boolean;
    wopiParams?: WopiParams;
    amrParams?: {
        item: EditorItem;
        initShowAmrIframe: () => void;
    };
    myOfficeVariant?: MYOFFICE_VARIANTS;
    listeners: {
        /**
         * Готовность документа к работе
         */
        onReady?: (editorType: MYOFFICE_VARIANTS, item?: EditorItem) => void;
        /**
         * Загрузка iframe (!== загрузка документа)
         */
        onLoad?: () => void;
        onError: () => void;
    };
    documentMode?: string;
    type?: string;
    isEditAttaches?: boolean;
    item?: EditorItem;
    metrics?: { size?: string; ext?: string };
    forceAmrInit?: boolean;
}

export enum EMyOfficeEvent {
    ready = 'ready',
    init = 'init',
    error = 'error',
}

export enum EPostMessageType {
    myOfficeMessage = 'APPLY',
    customErrorMessage = 'error',
    customServiceErrorMessage = 'service_error',
}

export enum ER7Event {
    App_LoadingStatus = 'App_LoadingStatus',
}

interface MyOfficeRawArgument<T = any> {
    type: 'RAW';
    value: T;
}

/**
 * Кастомное событие ошибки, которые может быть отправлено postMessage моего офиса, такие мы тоже должны обрабатывать
 * Такие события отправляет не сам МОйОфис, а можем и мы
 */
interface ICustomErrorPostMessage {
    type: EPostMessageType.customErrorMessage;
    error: { message: string };
}

/**
 * Кастомное событие ошибки отправляемое при редиректе с iframe wopi
 */
interface ICustomServiceErrorPostMessage {
    type: EPostMessageType.customServiceErrorMessage;
}

/**
 * События, которые отправляет МойОфис 2.2
 */
interface IMyOfficeMessageEvent<T extends [type: MyOfficeRawArgument<EMyOfficeEvent>, data?: MyOfficeRawArgument]> {
    type: EPostMessageType.myOfficeMessage;
    argumentList: T;
    id: string;
    /**
     * @todo Поправить тайпинг, когда станет известно что еще может быть
     * @deprecated Пока всегда приходит пустой, не рекомендуется использовать
     */
    path: [];
}

export interface IMyOfficeReady
    extends IMyOfficeMessageEvent<
        [
            MyOfficeRawArgument<EMyOfficeEvent.ready>,
            MyOfficeRawArgument<{
                readonly: boolean;
                isError: boolean;
            }>
        ]
    > {}

export interface IMyOfficeInit extends IMyOfficeMessageEvent<[MyOfficeRawArgument<EMyOfficeEvent.init>]> {}

export interface IMyOfficeError
    extends IMyOfficeMessageEvent<
        [
            MyOfficeRawArgument<EMyOfficeEvent.error>,
            MyOfficeRawArgument<{
                filename: string;
                message: string;
                stack: string;
            }>
        ]
    > {}

export type MyOfficeEventType = IMyOfficeReady | IMyOfficeInit | IMyOfficeError;

/**
 * Мы не можем гарантировать что кто-то не отправит postMessage с чем-то другим, по этому any добавил
 * чтобы это учитывать в коде
 */
export type PostMessageType = MyOfficeEventType | ICustomErrorPostMessage | ICustomServiceErrorPostMessage | undefined;

export const isMyOfficeEvent = <T extends MyOfficeEventType>(message: PostMessageType, type?: EMyOfficeEvent): message is T => {
    return (
        message?.type === EPostMessageType.myOfficeMessage &&
        message.argumentList?.[0]?.type === 'RAW' &&
        (!type ? Object.values(EMyOfficeEvent).includes(message.argumentList[0].value) : type === message.argumentList[0].value)
    );
};

/**
 * @see https://support.r7-office.ru/document_server/api-document_server/settings-wopi/wopi-rest-api/postmessage
 */
export const isR7Event = (message: string | any, type?: ER7Event): boolean => {
    if (typeof message !== 'string') {
        return false;
    }

    try {
        const data = JSON.parse(message);
        return data.hasOwnProperty('MessageId') && (!type ? true : type === data.MessageId);
    } catch (err) {
        return false;
    }
};

export const isCustomErrorMessage = (message: PostMessageType): message is ICustomErrorPostMessage =>
    message?.type === EPostMessageType.customErrorMessage && !!message?.error?.message;

export const isCustomServiceErrorMessage = (message: PostMessageType): message is ICustomServiceErrorPostMessage =>
    message?.type === EPostMessageType.customServiceErrorMessage;

export enum EWopiExtraEventRadarType {
    error = 'error',
}

/**
 * Тип для конфигурации таймаутов фолбека.
 * Содержит дефолтное значение таймаута для всех расширений файлов (default)
 * и возможность указать оверрайд значения таймаутов для конкретных расширений файлов (override).
 */
export type MyOfficeFallbackTimeoutConfig = {
    /** Дефолтное значение таймаута в секундах. */
    default: number;
    /** Объект, где ключи - расширения файлов, значения - таймауты для этих расширений. */
    override: Record<string, number>;
};
