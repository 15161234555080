import type { Space } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import React, { type ReactElement, type RefObject, createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { noop } from 'reactApp/utils/helpers';

import styles from './SpaceProgress.css';

interface MapState {
    isOverQuota: boolean;
    used: Space | null;
    userTotalSpace: Space | null;
    remaining: Space;
    usedPercent: number;
}

interface Props extends MapState {
    mod?: string;
    handleRef?: (ref: RefObject<HTMLElement>) => void;
    showSpaceText?: boolean;
}

const mapStateToProps = (state): MapState => UserQuotaSelectors.getUserQuotaState(state);

export class SpaceProgressComponent extends PureComponent<Props> {
    static defaultProps = {
        showSpaceText: false,
        handleRef: noop,
    };

    private progressRef = createRef<HTMLDivElement>();

    componentDidMount(): void {
        if (this.props.handleRef) {
            this.props.handleRef(this.progressRef);
        }
    }

    render(): ReactElement {
        const { isOverQuota, remaining, used, userTotalSpace, usedPercent, mod, showSpaceText } = this.props;
        return (
            <div className={classNames({ [styles[`root_${mod}`]]: !!mod })}>
                <div className={styles.progress} ref={this.progressRef}>
                    <div
                        className={classNames({
                            [styles.progressBar]: true,
                            [styles.progressBar_orange]: usedPercent >= 60 && usedPercent < 90,
                            [styles.progressBar_red]: isOverQuota || usedPercent >= 90,
                        })}
                        style={{ width: isOverQuota ? '100%' : `${usedPercent}%` }}
                    />
                </div>
                {showSpaceText && (
                    <div
                        className={classNames({
                            [styles.text]: true,
                            [styles.text_red]: isOverQuota,
                        })}
                    >
                        {isOverQuota
                            ? `Занято ${used && used.value}`
                            : `Свободно ${remaining.value} из ${userTotalSpace && userTotalSpace.value}`}
                    </div>
                )}
            </div>
        );
    }
}

export const SpaceProgress = connect(mapStateToProps)(SpaceProgressComponent);
