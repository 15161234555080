export interface IntegrationState {
    parentOrigin: string; // origin страницы, открывшей окно облака (параметр валидируется на webapi и в бивере и нужен для отправки postMessage)
    client: string; // идентификатор клиента, открывшего окно облака (нужен для аналитики)
    channel: string; // идентификатор канала, открывшего окно облака (нужен для аналитики)
    p: string; // p, открывшего окно облака (нужен для аналитики)
    userId: string; // идентификатор пользователя внешнего сервиса (нужен для аналитики)
    authType: string; // тип авторизации
    authOrigin: string; // ориджин провайдера
    isCloudDocumentsViewerEnabled: boolean; // включен ли просмотр документов
    isCloudMyOfficeViewerEnabled: boolean; // включен ли просмотр my office
    isAutoSharingDisabled: boolean; // Выключен ли автошаринг файлов
    isCloudQuotePayEnabled: boolean; // Включена ли оплата квоты облака в виджете
    isNewWidgetIconsEnabled: boolean; // включены ли новые иконки ребрендинга
}

export type File = {
    name: string;
    link?: string;
    size: number;
    shareID?: string;
    thumb?: string;
    path?: string;
};

export type CommunicatorPackedMessage = {
    name: string;
    payload?: string;
    __cloudMessage: true;
};

export enum EIntegrationClients {
    tutoria = 'tutoria',
}

export interface IIntegrationClient {
    isTutoria: boolean;
}
