import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { Button } from 'reactApp/ui/Button/Button';
import { openerDelayed } from 'reactApp/utils/opener';
import { EQueryParams } from 'server/helpers/getRequestParams';

import styles from './ErrorPageButton.css';
import type { IProps } from './ErrorPageButton.types';

export const ErrorPageButton = memo<IProps>(function ErrorPageButton({ link, text, onClick }) {
    const handleClick = useCallback(() => {
        onClick?.();
        openerDelayed(link, 200, true);
    }, [link, onClick]);

    // При рендере статической страницы нужно рендерить ссылку
    if (process.env.SSR) {
        return (
            <a href={link} className={classNames(styles.root, 'js-error-page-button', 'errorPageDisableElement')} id="error-page__button">
                {text}
            </a>
        );
    }

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];

    return (
        <Button
            theme="vk"
            secondary
            middle
            className={classNames(styles.root, { ['errorPageDisableElement']: IS_WEBVIEW && fromDeeplink })}
            onClick={handleClick}
            id="error-page__button"
        >
            {text}
        </Button>
    );
});
