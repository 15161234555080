import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { askUserForPaymentFiles } from 'reactApp/modules/uploadList/oldUploadListBridge';
import type { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './PopupWarnings.css';

interface IProps {
    file: IInputFile;
    isStrangerOwner: boolean;
}

const OverQuota = ({ file, isStrangerOwner }: IProps) => {
    const dispatch = useDispatch();

    const storage = useSelector(getCurrentStorage);
    const isInlineIntegration = storage === EStorageType.inlineIntegration;

    const handleOverQuota = useCallback(() => {
        askUserForPaymentFiles('payment', file.descriptorId);
    }, []);

    const handleOverQuotaSkip = useCallback(() => {
        askUserForPaymentFiles('skip', file.descriptorId);
    }, []);

    const renderHeader = useCallback(
        () => (
            <div>
                Невозможно загрузить файл <NameComponent name={file.name} truncate />
            </div>
        ),
        [file.name]
    );

    const renderContent = useCallback(() => {
        const content = isStrangerOwner
            ? 'Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов'
            : 'Ваше Облако переполнено. Удалите лишнее или увеличьте его размер.';

        return (
            <div className={styles.content}>
                <div>{content}</div>
            </div>
        );
    }, []);

    useEffect(() => {
        if (!isStrangerOwner && IS_FAMILY_USER && isFamilySubsAvailable) {
            dispatch(updateUser());
        }
    }, []);

    return (
        //  TODO: вернуть, когда сможем открывать лендинг квоты под соцаккаунтом
        <BaseConfirmDialog
            dataQAId="overquota-dialog"
            renderHeader={renderHeader}
            successButtonText="Посмотреть тарифы"
            cancelButtonText="Пропустить файл"
            buttons={isInlineIntegration ? [EButtonType.cancel] : [EButtonType.accept, EButtonType.cancel]}
            onSuccess={handleOverQuota}
            onClose={handleOverQuotaSkip}
            renderContent={renderContent}
            className={styles.root}
        />
    );
};

export const OverQuotaComponent = memo(OverQuota);
