import { Icon24DeleteOutline, Icon24Spinner } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as CloudLogo } from 'img/cloud_blue_new.svg';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { type SnackbarItem, SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { SwipingDownComponent } from 'reactApp/ui/SwipingDownComponent/SwipingDownComponent';
import { CheckCircleOutline, CheckShieldFilled, ClockOutline, ErrorCircleIcon } from 'reactApp/ui/VKUIIcons';

import { useTimer } from '../hooks/useTimer';
import { ESnackbarTheme, Snackbar } from './Snackbar';
import styles from './SnackbarComponent.css';

export const SNACKBAR_ICONS = {
    [SnackbarTypes.success]: <CheckCircleOutline className={styles.succesIcon} />,
    [SnackbarTypes.failure]: <ErrorCircleIcon className={styles.failedIcon} />,
    [SnackbarTypes.time]: <ClockOutline />,
    [SnackbarTypes.warning]: <ErrorCircleIcon />,
    [SnackbarTypes.loading]: <Icon24Spinner className={styles.spinnerIcon} />,
    [SnackbarTypes.protect]: <CheckShieldFilled />,
    [SnackbarTypes.trashbin]: <Icon24DeleteOutline className={styles.trashBin} />,
    [SnackbarTypes.mobileAppDownload]: <CloudLogo className={styles.trashBin} />,
};

export const SnackbarComponent = (props: SnackbarItem) => {
    const { id, icon, closable, closeTimeout, type = '', iconSize, disableCloseTimeout, onClose, onShow } = props;
    const { isVisible, handleClose } = useTimer({ id, closable, closeTimeout, onClose, disableCloseTimeout });

    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    useEffect(() => {
        onShow?.();
    }, []);

    return (
        <SwipingDownComponent onClose={handleClose} lock={!isPhone && closable}>
            <div className={classNames(styles.root, { [styles[`root_stretched`]]: isPhone })}>
                <Snackbar
                    {...props}
                    key={id}
                    onClose={handleClose}
                    icon={icon ? icon : SNACKBAR_ICONS[type]}
                    iconSize={iconSize}
                    closable={!isPhone && closable}
                    stretched={isPhone}
                    visible={isVisible}
                    theme={isPhone ? ESnackbarTheme.REGULAR : ESnackbarTheme.COMPACT}
                />
            </div>
        </SwipingDownComponent>
    );
};
