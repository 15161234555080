import React, { type ReactElement, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { attachesPageStart, attachFoldersRequest } from 'reactApp/modules/attaches/attaches.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';

import styles from './MobileAttachesPage.css';

export const MobileAttachesPage = memo(function MobileAttachesPage(): ReactElement {
    const dispatch = useDispatch();
    const location = useLocation();

    useRouteChangeProcessing({ storage: EStorageType.attaches, path: location.pathname, search: location.search });

    useEffect(() => {
        dispatch(attachesPageStart({ initialId: '' }));
        dispatch(attachFoldersRequest());
    }, []);

    return <div className={styles.root} />;
});
