import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { filesFilterAll, filesFilterOne } from 'reactApp/modules/filesFilter/filesFilter.module';
import { getCurrentAuthorInFilter, isFilterAuthorEmpty } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getFolderAuthors, isFolderOwner, isFolderWithAuthor } from 'reactApp/modules/storage/storage.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { renderFilterAuthorsModal } from 'reactApp/ui/Mobile/FilterAuthorsModal/FIlterAuthorsModal.helpers';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';
import { optionsItems } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.data';
import type { IOptionItem } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';

export const useFilterBlock = ({
    storage,
    onlyActions,
    facesFilter,
}: {
    storage: EStorageType;
    onlyActions: boolean;
    facesFilter: boolean;
}): { filtered: boolean; filterItems: IOptionItem[] } => {
    const dispatch = useDispatch();

    const email = useSelector(UserSelectors.getEmail);
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const { isPublic } = getStorage(storage);
    const isOwn = useSelector(isFolderOwner);

    const authorsInFolder = useSelector(getFolderAuthors);
    const hasAuthorAndIsOwn = useSelector(isFolderWithAuthor) && isOwn;
    const hasAuthorInFilter = !useSelector(isFilterAuthorEmpty);
    const filter = useSelector(getCurrentAuthorInFilter);

    const showedOnlyMine = filter.includes(email || '') && filter.length === 1;
    const hasFilteredAuthor = hasAuthorAndIsOwn && hasAuthorInFilter;

    const selectedFaceId = useSelector(getSelectedFaceId);

    const onFilterMineClick = useCallback(() => {
        if (!showedOnlyMine) {
            dispatch(filesFilterOne(email || ''));
        } else {
            dispatch(filesFilterAll());
        }
    }, [dispatch, showedOnlyMine, email]);

    if (onlyActions) {
        return {
            filtered: false,
            filterItems: [],
        };
    }

    const items: IOptionItem[] = [];

    if (facesFilter) {
        items.push({
            ...optionsItems.faces,
            onClick: renderFilterFacesModal,
            disabled: hasFilteredAuthor || showedOnlyMine,
        });
    }

    if (hasAuthorAndIsOwn) {
        items.push({
            ...optionsItems.authors,
            onClick: renderFilterAuthorsModal,
            disabled: Boolean(selectedFaceId),
        });
    } else if (isUploadEnabled && isPublic && authorsInFolder.length) {
        items.push({
            ...optionsItems.onlyMine,
            onClick: onFilterMineClick,
            active: showedOnlyMine,
            disabled: Boolean(selectedFaceId),
        });
    }

    return {
        filtered: Boolean(selectedFaceId) || hasFilteredAuthor || showedOnlyMine,
        filterItems: items,
    };
};
