import classNames from 'clsx';
import { ReactComponent as IconClose } from 'mrg-icons/src/mailru/actions/close.svg';
import React, { type ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { getPublicOwner, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './UploadInfo.css';

export const UploadInfo = memo(({ closable, onClose = noopVoid }: { closable?: boolean; onClose?: () => void }): ReactElement | null => {
    const storage = useSelector(getCurrentStorage);
    const isRoot = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const owner = useSelector(getPublicOwner);

    const { firstName, lastName, email, nick } = owner || {};

    const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim() || nick || email;

    if (!isRoot || !isUploadEnabled) {
        return null;
    }

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_closable]: closable,
            })}
        >
            <div className={styles.avatar}>
                <Avatar size={32} email={email} />
            </div>
            <div className={styles.info}>
                <span className={styles.name}>{name}</span> сделал эту папку доступной для загрузки файлов.
            </div>
            {closable && (
                <div className={styles.close} onClick={onClose}>
                    <IconClose fill="currentColor" height={20} width={20} />
                </div>
            )}
        </div>
    );
});

UploadInfo.displayName = 'UploadInfo';
