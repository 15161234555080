import type { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import type { WeblinkDownloadable, WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';
import type { AccessRights, Kind, Url } from 'reactApp/types/Tree';

export interface IDocumentSection {
    category: ECategory.DOCUMENT;
    avatar?: string;
    id: string;
    name: string;
    hasMoreToLoad?: boolean;
    cursor?: string;
    childs: string[];
    status?: EDocumentStatus;
    isLoaded: boolean;
    isLoading: boolean;
    isFolder: boolean;
    count: {
        all: number;
        folders: number;
        files: number;
        loaded: number;
    };
}

export enum ECategory {
    DOCUMENT = 'document',
    FILE = 'file',
}

export interface IState {
    isLoading: boolean;
    isLoaded: boolean;
    isError: boolean;
    recognitionStatus: ERecognitionStatus;
    list: Record<string, IDocumentSection | IFileNormalized>;
}

export interface IApiPersonalDocumentsResponse {
    list: IPersonalDocumentsItem[];
    user_docs_count: number;
}

export interface IPersonalDocumentsItem {
    id: number;
    title: string;
    count: number;
    avatar?: IFile;
}

interface IFilePublic extends WeblinkDownloadsCounts {
    ctime: number;
    id: string;
    name: string;
    path: string;
    expires?: number;
    flags?: {
        WRITABLE: boolean;
        DOMESTIC: boolean;
        DOWNLOADABLE: boolean;
    };
    count_downloads_left?: number;
    count_downloads_total?: number;
}

export interface IFile {
    hash: string;
    kind: Kind;
    mtime: number;
    name: string;
    path: string;
    size: number;
    type: string;
    weblink?: string;
    public?: IFilePublic;
    flags?: {
        favorite: boolean;
        mounted: boolean;
    };
}

export type TDocumentType = {
    documentType: EDocumentType;
};

export type TRecognitionStatus = {
    recognitionStatus: ERecognitionStatus;
};

export enum ERecognitionStatus {
    PROCESSING = 'processing',
    DISABLED = 'disabled',
    PROCESSED = 'processed',
}

export interface ApiDocumentResponse {
    list: IFile[];
    truncated?: boolean;
    cursor?: string;
    documentType: EDocumentType;
}

export interface IFileNormalized extends IFile, WeblinkDownloadable {
    id: string;
    category: ECategory.FILE;
    thumbnails?: Record<string, string>;
    nameWithoutExt: string;
    home: string;
    weblink?: string;
    isInFavorites: boolean;
    ext: string;
    weblinkDomestic?: boolean;
    weblinkExpires?: number;
    isMounted: boolean;
    weblinkAccessRights?: AccessRights;
    parent: string;
    storage: EStorageType.documents;
    documentId: EDocumentType;
    url: Url;
    weblinkDomainAccess?: DomainAccess;
}

export enum EDocumentStatus {
    NOT_FOUND = 'notFound',
}

export enum ERecognitionAction {
    ENABLE = 'enable',
    DISABLE = 'disable',
}

export interface DocumentsItem extends IFileNormalized {
    isFolder: false;
}

export interface IStartUploadDocuments {
    data: any;
    documentType: EDocumentType;
}
