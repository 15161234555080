import classNames from 'clsx';
import { format, isValid, parseISO } from 'date-fns';
import React, { type VFC } from 'react';
import { Link } from 'react-router-dom';
import { summerPromotion, summerPromotionEndDate } from 'reactApp/appHelpers/featuresHelpers';
import { useHandlers } from 'reactApp/sections/MobileSubscriptionsPage/hooks/useHandlers';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { useTariffBuyDialog } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { TariffCard } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard';
import { MobileTariffCardTheme } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard.types';
import { type ACTIONS_DEFS, ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';

import styles from './Tariff.css';

type Props = {
    marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo'];
    tariffs: any[];
    isRebranding?: boolean;
};

export const Tariffs: VFC<Props> = ({ marketingPromo, tariffs, isRebranding = false }) => {
    const { handleFAQClick, handleAllTariffsClick } = useHandlers();
    const { openTariffBuy } = useTariffBuyDialog();

    const summerPromoEndDateIso = parseISO(summerPromotionEndDate);
    const summerPromoEndDateString = isValid(summerPromoEndDateIso) ? format(summerPromoEndDateIso, 'dd.MM.yy') : '';

    return (
        <>
            <div className={styles.wrapper}>
                {tariffs.map((tariff, index) => (
                    <div className={classNames(styles.tariff, { [styles.tariff_action]: marketingPromo })} key={tariff.products?.[0].id}>
                        <TariffCard
                            {...tariff}
                            onClick={openTariffBuy}
                            theme={summerPromotion ? MobileTariffCardTheme.white : undefined}
                            // tempexp_13600-next-line
                            openAbCard={index === 0}
                            showPriceByMonth={false}
                            isRebrandingSubscription={isRebranding}
                        />
                    </div>
                ))}
            </div>

            {marketingPromo && (
                <div className={styles.actionLinks}>
                    <Link
                        to={TABS_MAP[ETabsName.usual]}
                        className={classNames(styles.buttonLink, { [styles.summerPromo]: summerPromotion })}
                        onClick={handleAllTariffsClick}
                    >
                        Все тарифы
                    </Link>
                    <a
                        className={classNames(styles.buttonLink, { [styles.summerPromo]: summerPromotion })}
                        href={marketingPromo.conditions}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Полные условия акции
                    </a>
                    {summerPromotion && !!summerPromotionEndDate && (
                        <div className={styles.actionDatesBlock}>
                            <span className={styles.actionDates}>Срок акции с 24.06.2024 по {summerPromoEndDateString}</span>
                        </div>
                    )}
                </div>
            )}

            {tariffs.length === 0 && <div className={styles.noTariff}>Нет доступных тарифов</div>}

            <div
                className={classNames(styles.commonLinks, {
                    [styles.commonLinks_actionPromo]: !!marketingPromo && !summerPromotion,
                    [styles.commonLinks_summerPromo]: summerPromotion,
                })}
            >
                <a
                    className={styles.link}
                    href="https://help.mail.ru/cloud_web/size/increase"
                    onClick={handleFAQClick}
                    target="_blank"
                    rel="noreferrer"
                    data-name="cancel-subscription"
                >
                    Как отменить подписку?
                </a>
            </div>
        </>
    );
};
