import { createSelector } from '@reduxjs/toolkit';
import { desktopTariffMinSize } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { SPACE_SIZES } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Tariff } from 'reactApp/types/Billing';
import type { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { getTariffProductsByPeriods } from 'reactApp/utils/Period';

export const getTariffsByTab = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForSubscriptionsPage(state),
        (state, _, actionName: string, mergeWithUsualTariffs?: boolean) =>
            ProductsSelectors.getPromoTariffs(state, actionName, mergeWithUsualTariffs),
        (state, activeTab: ETabsName) => activeTab,
    ],
    (nonSpecialTariffs, promoTariffs, activeTab): ITariffsByTab[] => {
        const tariffs: Tariff[] = activeTab === ETabsName.special ? promoTariffs : nonSpecialTariffs;

        const isProTabActive = activeTab === ETabsName.pro;
        const isUsualTabActive = activeTab === ETabsName.usual;
        const isYearTabActive = activeTab === ETabsName.year;

        return tariffs.reduce((acc: ITariffsByTab[], tariffItem) => {
            if (
                /**
                 * Пояснения на ифы:
                 * isProTabActive - для про табы
                 * isUsualTabActive || isYearTabActive - для остальных
                 *
                 * Логика в том, что раньше мы определяли про тариф или не про по наличию флага isProfessional
                 * Что по бизнесу означало что тариф поддерживает загрузку 100 гб на файл.
                 *
                 * В ЕДИНОЙ квоте, все тарифы имеют этот флаг ispro, и чтобы понять в какую вкладку закинуть тариф,
                 * мы считаем теперь что если есть Диск-О, тогда это улетает во вкладку ПРО.
                 */
                (isProTabActive && !tariffItem.products.some((p) => p.isDisko)) ||
                ((isUsualTabActive || isYearTabActive) && tariffItem.products.some((p) => p.isDisko))
            ) {
                return acc;
            }

            const products = getTariffProductsByPeriods(tariffItem.products);
            if (products.yearProduct) {
                acc.push({
                    space: tariffItem.space,
                    tariffId: tariffItem.id,
                    products,
                });
            }

            return acc;
        }, []);
    }
);

export const getTariffsByTabAboveCertainSize = createSelector(getTariffsByTab, (tariffs) => {
    // tempexp_16349-start
    const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
    return !EnvironmentSelectors.isPhone() ? tariffs : tariffs.filter((product) => product.space.original >= minSizeDesktop);
    // tempexp_16349-end
});
