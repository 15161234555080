import CloudDark from 'img/portal-header/cloud_dark.svg?url';
import Cloud from 'img/portal-header/cloud_light.svg?url';
import { APP_LOGO, APP_TITLE } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';

export const getLogoSrc = ({ logoSrc, isBizUser, bizLogo, dark }): string => {
    if (logoSrc) {
        return logoSrc;
    }

    if (isBizUser) {
        return bizLogo ?? APP_LOGO;
    }

    if (isDarkTheme || dark) {
        return CloudDark;
    }

    return Cloud;
};

export const getAlt = (isBizUser?: boolean): string => {
    return isBizUser ? APP_TITLE : 'Облако Mail';
};
