import React, { type PropsWithChildren } from 'react';
import { useElementSize } from 'reactApp/hooks/useElementSize';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';

import styles from './BreadcrumbsWrapper.css';

interface BreadcrumbsWrapper {}

export function BreadcrumbsWrapper({ children }: PropsWithChildren<BreadcrumbsWrapper>) {
    const [setBreadcrumbContainerRef, _, breadcrumbSize] = useElementSize<HTMLImageElement>();
    return (
        <>
            <div ref={setBreadcrumbContainerRef} className={styles.breadcrumbsWrapper}>
                <BreadcrumbsContainer className={styles.breadcrumbs} asPageTitle />
            </div>
            <div style={{ height: `calc(100% - ${breadcrumbSize.height}px)` }}>{children}</div>
        </>
    );
}
