import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';

export const PRODUCT_ID = 'W128G1m_pro_promo_mail';

export enum EFeatures {
    space = 'space',
    ad = 'ad',
    mail = 'mail',
    support = 'support',
    upload = 'upload',
    safety = 'safety',
    interest = 'interest',
    document = 'document',
    gift = 'gift',
    settings = 'settings',
    quota = 'quota',
    singleQuota = 'singleQuota',
    control = 'control',
}

export enum ETabs {
    quota = 'quota',
    features = 'features',
    tariffs = 'tariffs',
    questions = 'questions',
    apps = 'apps',
    feedback = 'feedback',
    help = 'help',
    cleaner = 'cleaner',
    family = 'family',
    // tempexp_17509-start
    litres = 'litres',
    // tempexp_17509-end
}

export const QuotaLandingHashTabs = {
    '#price': ETabs.tariffs,
    '#start': ETabs.quota,
    '#cleaner': ETabs.cleaner,
    '#faq': ETabs.questions,
    '#benefits': ETabs.features,
};

export const QUOTA_LANDING_SIMPLE_TABS = [ETabs.features, ETabs.questions];

export const QUOTA_BILLING_SOURCE = 'union_quota_landing';

export const QUOTA_SUBS_NAME = 'Mail Space';

type GetQuotaPromoUrl = (params: { query?: string; hash?: 'cleaner' | 'start' | 'tariffs' | '' }) => string;

// tempexp_16800-next-line
export const QUOTA_SUBS_NAMES_LIST = ['256 ГБ', '1 ТБ', '2 ТБ'];

export const getQuotaPromoUrl: GetQuotaPromoUrl = ({ query = '', hash = '' } = {}) => {
    const cloudOrigin = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;

    const url = `/promo/quota${query ? `?${query}` : ''}${hash ? `#${hash}` : ''}`;

    return `${cloudOrigin}${url}`;
};

export const QUOTA_URL = IS_DOCUMENTS_DOMAIN
    ? `${changeDocumentDomainToCloud(window.location.origin)}/promo/quota`
    : `${window.location.origin}/promo/quota`;
