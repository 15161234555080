import React, { memo, useEffect } from 'react';
import type { Props } from 'reactApp/ui/BannerOffer/BannerOffer.types';
import { useBannerOfferCallback } from 'reactApp/ui/BannerOffer/hooks/useBannerOfferCallback';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import styles from './BannerOffer.css';

export const BannerOfferRedesign = memo(function BannerOfferRedesign(props: Props): JSX.Element | null {
    const { product, onClose: handleClose, onClick: handleClick, onShow: handleShow, config } = props;

    const { title, icon, button, id, features } = config;

    const { onClick, onClose, onShow } = useBannerOfferCallback({
        id,
        handleClose,
        handleClick,
        handleShow,
        product,
        groupAb: 'redesign',
    });

    useEffect(() => onShow(), []);

    return (
        <Dialog open={true} onCancel={onClose} closeOnEscape closeOnDimmerClick className={styles.modal}>
            <section className={styles.root}>
                <div className={styles.imageWrap}>
                    <img src={icon} className={styles.image} />
                </div>
                <div className={styles.title}>{title}</div>
                <ol className={styles.features}>
                    {features.map((feature, index) => (
                        <li className={styles.feature} key={index}>
                            {feature}
                        </li>
                    ))}
                </ol>
                <Button primary theme="octavius" sizeMode={ButtonSizeMode.small} onClick={onClick}>
                    {button}
                </Button>
            </section>
        </Dialog>
    );
});
