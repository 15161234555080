import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { CLOUD_SOURCE } from 'reactApp/constants/paymentUTM';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import { Button } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { EPromocodeApiErrorCodes, errorButtons, errorDescriptions, errorTitles } from 'reactApp/ui/Promocode/promocode.constants';
import opener from 'reactApp/utils/opener';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getUtmString } from 'reactApp/utils/urlHelper';

import styles from './PromocodeResultDialog.css';

export interface IProps {
    success: boolean;
    isTrial?: boolean;
    isTinkoffPromo: boolean;
    errorCode?: EPromocodeApiErrorCodes;
    onClose(): void;
}

export const PromocodeResultDialog = memo(function SharingNew(props: IProps): ReactElement {
    const { success, errorCode = EPromocodeApiErrorCodes.internal, onClose, isTrial, isTinkoffPromo } = props;

    const errorTitle = errorTitles[errorCode] || errorTitles[EPromocodeApiErrorCodes.internal];
    const errorMessage = errorDescriptions[errorCode] || errorDescriptions[EPromocodeApiErrorCodes.internal];
    const errorButton = success ? 'Понятно' : errorButtons[errorCode] || errorButtons[EPromocodeApiErrorCodes.internal];

    const details_popup = success ? 'ok' : errorCode;

    const handleCloseDialog = useCallback(() => {
        sendPaymentGa({
            eventCategory: 'pop-up',
            action: 'close',
            name_popup: PromocodeResultDialog.displayName,
            details_popup,
        });

        onClose();
    }, [onClose]);

    const handleButtonClick = useCallback(
        (event) => {
            sendPaymentGa({
                eventCategory: 'pop-up',
                action: 'click',
                name_popup: PromocodeResultDialog.displayName,
                details_popup,
            });

            if (errorCode === EPromocodeApiErrorCodes.exists || errorCode === EPromocodeApiErrorCodes.finished) {
                event.preventDefault();
                opener(
                    getSubscriptionsUrl(
                        getUtmString({
                            utm_source: CLOUD_SOURCE,
                            utm_medium: 'web_subscriptions',
                            utm_campaign: `promocode_error_${errorCode}`,
                        })
                    )
                );
                return;
            }

            onClose();
        },
        [errorCode, onClose]
    );

    useEffect(() => {
        sendPaymentGa({
            eventCategory: 'pop-up',
            action: 'open',
            name_popup: PromocodeResultDialog.displayName,
            details_popup,
        });
    }, []);

    const footer =
        success || errorCode ? (
            <Button primary theme="octavius" className={styles.dialogButton} onClick={handleButtonClick} key="cancelButton">
                {errorButton}
            </Button>
        ) : null;

    const header = useMemo(() => {
        if (isTinkoffPromo) {
            return 'Промокод от Тинькофф активирован!';
        }

        if (isTrial) {
            return 'Ваше Облако увеличилось!';
        }

        return 'Промокод активирован!';
    }, [isTinkoffPromo, isTrial]);

    return (
        <Dialog
            closeOnDimmerClick={false}
            className={classNames({
                [styles.dialog]: true,
                [styles.dialog_gift]: !(success || errorCode),
                [styles.dialog_success]: success,
                [styles.dialog_error]: Boolean(!success && errorCode),
                [styles.dialog_centerButton]: Boolean(success || errorCode),
            })}
            onCancel={handleCloseDialog}
            footer={footer}
            open
        >
            <div className={styles.dialogContent}>
                {!!success && (
                    <div>
                        <div className={styles.dialogHeader}>{header}</div>
                        <div className={styles.dialogText}>
                            {isTrial
                                ? 'Теперь у вас больше свободного места для хранения файлов.'
                                : 'Теперь у вас больше свободного места для хранения файлов в Облаке.'}
                        </div>
                    </div>
                )}
                {Boolean(!success && errorMessage) && (
                    <div>
                        <div className={styles.dialogHeader}>{errorTitle}</div>
                        <div className={styles.dialogText}>{errorMessage}</div>
                    </div>
                )}
            </div>
        </Dialog>
    );
});

PromocodeResultDialog.displayName = 'PromocodeResultDialog';
