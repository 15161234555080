import { isNodeIn } from '@cloud/react-selectable';
import { equals } from 'ramda';
import { resetSelect, selectOne, selectSome, selectTo, toggle } from 'reactApp/modules/selections/selections.actions';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { closeContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { sendGa } from 'reactApp/utils/ga';

export const onSelecting = ({ setActiveIndex, idxs, selectingIdxs, setSelectingIdxs }) => {
    setActiveIndex(null);

    if (!equals(idxs, selectingIdxs)) {
        setSelectingIdxs(idxs);
    }
};

export const onSelectBoxSelection = ({
    event,
    setActiveIndex,
    idxs,
    selectedIdxs,
    selectingIdxs,
    storage,
    setSelectingIdxs,
    dispatch,
    prevSelectHandler,
}) => {
    setActiveIndex(null);

    sendGa('datalist', 'selection', `${idxs.length}`);
    const currentSelectedIdxs = event.shiftKey ? [...selectedIdxs, ...idxs] : idxs;
    dispatch(selectSome({ selectedIdxs: currentSelectedIdxs, storage: storage as EStorageType, selectionRange: null }));
    if (selectingIdxs.length) {
        setSelectingIdxs([]);
    }

    document.onselectstart = prevSelectHandler.current;
};

export const onSelect = ({ event, setActiveIndex, items, id, isCheckbox, storage, dispatch }) => {
    setActiveIndex(null);

    closeContextMenu();
    if (event.shiftKey) {
        dispatch(selectTo({ allIdxs: items, currentId: id, storage: storage as EStorageType }));
    } else if (event.metaKey || event.ctrlKey || isCheckbox) {
        dispatch(toggle({ selectedIdx: id, storage: storage as EStorageType }));
    } else {
        dispatch(selectOne({ selectedIdx: id, storage: storage as EStorageType }));
    }
};

export const onBeginSelection = ({ event, dispatch, prevSelectHandler, itemCreateNewRef, isInlineEditing }) => {
    if (itemCreateNewRef.current?.contains(event.target)) {
        return;
    }

    if (isNodeIn(event.target, (node) => node?.dataset?.selectbox === 'false')) {
        return false;
    }

    closeContextMenu();
    if (!isInlineEditing) {
        dispatch(resetSelect());
    }

    prevSelectHandler.current = document.onselectstart;
    document.onselectstart = () => {
        return false;
    };
};
