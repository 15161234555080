import React, { type ReactElement, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { formatSubscriptionData } from 'reactApp/utils/formatDate';

import styles from './CancelAutorenewDialog.css';
import { getFeatureList } from './CancelAutorenewDialog.data';

type Props = { subscription: ISubscription; onSuccess: () => void; onClose: () => void };

export const CancelAutorenewDialog = memo<Props>(({ subscription, onSuccess, onClose }) => {
    const { total: userTotalSpace } = useSelector(UserSelectors.getCloudSpace);

    const header = useCallback((): ReactElement => <div>Отключить автопродление?</div>, []);

    const content = useCallback(
        (): ReactElement => (
            <div className={styles.content}>
                <div className={styles.expires}>
                    При отключении с <b>{formatSubscriptionData(subscription.expires)}</b> вы потеряете:
                </div>
                <div className={styles.features}>
                    {getFeatureList(subscription, userTotalSpace).map((feature) => (
                        <div key={feature.id} className={styles.feature}>
                            <div>{feature.icon}</div>
                            <div className={styles.featureText}>{feature.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        ),
        []
    );

    return (
        <BaseConfirmDialog
            onSuccess={onClose}
            onClose={onClose}
            onAction={onSuccess}
            buttons={[EButtonType.accept, EButtonType.action]}
            successButtonText="Не отключать"
            actionButtonText="Отключить автопродление"
            renderHeader={header}
            renderContent={content}
            className={styles.root}
        />
    );
});

CancelAutorenewDialog.displayName = 'CancelAutorenewDialog';
