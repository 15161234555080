import type { ThemeModelsType } from 'reactApp/appHelpers/themeToolHelpers/themeManager';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

import type { State } from './theme.outsource.types';

const getThemeOutsourceState = (state: RootState): State => state.themeOutsource as State;

const getLoadingState = createSelector(
    getThemeOutsourceState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        hasError: state.hasError,
    })
);

const getAvailableThemes = createSelector(getThemeOutsourceState, (state: State): ThemeModelsType => state.availableThemes);

// Текущая тема
const getAppliedTheme = createSelector(getThemeOutsourceState, (state: State): string | undefined => state.appliedTheme);

// Тема, выбранная в ThemeChooser, но не сохраненная
const getChoosedTheme = createSelector(getThemeOutsourceState, (state: State): string | undefined => state.choosedTheme);

export const ThemeOutsourceSelectors = {
    getLoadingState,
    getAvailableThemes,
    getAppliedTheme,
    getChoosedTheme,
};
