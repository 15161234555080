import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { openPublishDialog, unPublishRequest } from 'reactApp/modules/modifying/modifying.actions';
import type { IOpenPublishDialog, IUnPublishRequest } from 'reactApp/modules/modifying/modifying.types';
import { weblinkDeleteAllEmailAccess } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { store } from 'reactApp/store';

export const publishHelper = (params: IOpenPublishDialog) => store.dispatch(openPublishDialog({ ...params, from: EFrom.WEBLINK }));

export const unPublishHelper = (params: IUnPublishRequest) => {
    store.dispatch(weblinkDeleteAllEmailAccess());
    store.dispatch(unPublishRequest(params));
};
