import './plugin.css';

import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';

import { ConcreteButton } from './ConcreteButton';
import { ConcreteMenuItem } from './ConcreteMenuItem';

export interface HlsQualitySelectorPluginOptions {
    displayCurrentQuality?: boolean;
    placementIndex?: number;
    vjsIconClass?: string;
}

export interface VideoPlayerWithHlsQualitySelectorPlugin extends VideoPlayer {
    _hlsQualitySelector: HlsQualitySelectorPlugin;
    hlsQualitySelector: (options?: HlsQualitySelectorPluginOptions) => void;
}

export class HlsQualitySelectorPlugin {
    player: VideoPlayerWithHlsQualitySelectorPlugin;
    config: HlsQualitySelectorPluginOptions;
    _currentQuality: string | number = 'auto';
    _qualityButton: ConcreteButton;

    constructor(player: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) {
        this.player = player;
        this.config = options || {};
        this._qualityButton = new ConcreteButton(player, { name: 'QualityButton' });

        // Create the quality button.
        this.createQualityButton();
        this.bindPlayerEvents();
    }

    bindPlayerEvents() {
        this.player.qualityLevels().on('addqualitylevel', this.onAddQualityLevel.bind(this));
    }

    createQualityButton() {
        const player = this.player;

        const placementIndex = player.controlBar.children().length - 2;
        const concreteButtonInstance = player.controlBar.addChild(
            this._qualityButton,
            { componentClass: 'qualitySelector' },
            this.config.placementIndex || placementIndex
        );

        concreteButtonInstance.addClass('vjs-quality-selector');
        if (!this.config.displayCurrentQuality) {
            const icon = ` ${this.config.vjsIconClass || 'vjs-icon-hd'}`;

            concreteButtonInstance.menuButton_.$('.vjs-icon-placeholder').className += icon;
        } else {
            this.setButtonInnerText(this.player.localize('auto'));
        }
        concreteButtonInstance.removeClass('vjs-hidden');
    }

    setButtonInnerText(text: string) {
        if (this._qualityButton) {
            this._qualityButton.menuButton_.$('.vjs-icon-placeholder').textContent = text;
        }
    }

    getQualityMenuItem(item: { label: string; value: number | string; selected?: boolean }): ConcreteMenuItem {
        const player = this.player;

        return new ConcreteMenuItem(player, item, this._qualityButton, this);
    }

    onAddQualityLevel() {
        const qualityList = this.player.qualityLevels();
        const levelItems: ConcreteMenuItem[] = [];

        for (let i = 0; i < qualityList.length; ++i) {
            const { width, height } = qualityList[i];

            if (!width || !height) {
                return;
            }

            const pixels = width > height ? height : width;
            if (
                !levelItems.filter((_existingItem) => {
                    return _existingItem.item && _existingItem.item.value === pixels;
                }).length
            ) {
                const levelItem = this.getQualityMenuItem({
                    label: `${pixels}p`,
                    value: pixels,
                });

                levelItems.push(levelItem);
            }
        }

        levelItems.sort((current, next) => {
            if (typeof current !== 'object' || typeof next !== 'object') {
                return -1;
            }
            if (current.item.value < next.item.value) {
                return -1;
            }
            if (current.item.value > next.item.value) {
                return 1;
            }
            return 0;
        });

        levelItems.push(
            this.getQualityMenuItem({
                label: this.player.localize('Auto'),
                value: 'auto',
                selected: true,
            })
        );

        if (this._qualityButton) {
            this._qualityButton.setItems(levelItems);
            this._qualityButton.update();
        }
    }

    setQuality(quality: string | number) {
        const qualityList = this.player.qualityLevels();

        this.player.trigger('changequality');

        // Set quality on plugin
        this._currentQuality = quality;

        if (this.config.displayCurrentQuality) {
            this.setButtonInnerText(quality === 'auto' ? this.player.localize('auto') : `${quality}p`);
        }

        for (let i = 0; i < qualityList.length; ++i) {
            const { width = 0, height = 0 } = qualityList[i];
            const pixels = width > height ? height : width;

            qualityList[i].enabled = pixels === quality || quality === 'auto';
        }
        this._qualityButton?.unpressButton();
    }

    getCurrentQuality(): string | number {
        return this._currentQuality || 'auto';
    }
}

const onPlayerReady = (player: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) => {
    player.addClass('vjs-hls-quality-selector');
    player._hlsQualitySelector = new HlsQualitySelectorPlugin(player, options);
};

const hlsQualitySelector = function (this: VideoPlayerWithHlsQualitySelectorPlugin, options?: HlsQualitySelectorPluginOptions) {
    this.ready(() => {
        if (!this.qualityLevels || !this.tech({ IWillNotUseThisInPlugins: true })?.vhs) {
            return;
        }
        onPlayerReady(this, options);
    });
};

export default hlsQualitySelector;
