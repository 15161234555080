import { ReactComponent as CheckIcon } from '@vkontakte/icons/src/svg/20/check_20.svg';
import classNames from 'clsx';
import React, { type FC } from 'react';
import type { QuotaCleanerYears } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { type ListItem, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { EDropdownPosition } from 'reactApp/ui/Toolbar/Toolbar.types';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';

import styles from './YearFilter.css';

interface YearItem {
    id: string;
    year: string;
    yearString: string;
    size: number;
    sizeString: string;
    isCurrent: boolean;
}

interface YearFilterProps {
    id: string;
    hook: () => {
        list: YearItem[];
        setCurrentYear: (year: string | QuotaCleanerYears) => void;
        isDropdownListOpen?: boolean;
        toggleIsDropdownListOpen?: (value?: boolean) => void;
        promoId?: string;
    };
}

export const YearFilter: FC<YearFilterProps> = ({ id, hook }) => {
    const { list, setCurrentYear, isDropdownListOpen, toggleIsDropdownListOpen, promoId } = hook();

    const renderDropdownListItem = (item: ListItem): React.ReactElement => {
        const { year, yearString, sizeString, isCurrent } = item as YearItem;
        const iconClassName = classNames(styles.checkIcon, {
            [styles.hidden]: !isCurrent,
        });

        return (
            <div className={styles.item} onClick={() => setCurrentYear(year)}>
                <div className={styles.yearField}>
                    <CheckIcon className={iconClassName} />
                    <span className={styles.year}>{yearString}</span>
                </div>
                <div className={styles.size}>{sizeString}</div>
            </div>
        );
    };

    return (
        <ToolbarItem
            id={id}
            promoId={promoId}
            text="Фильтр по дате"
            dropdownPosition={EDropdownPosition.right}
            list={list}
            maxDropdownListHeight={300}
            renderDropdownListItem={renderDropdownListItem}
            isDropdownListOpen={isDropdownListOpen}
            setIsDropdownListOpen={toggleIsDropdownListOpen}
            dropdownTheme={DropdownTheme.smallFluid}
        />
    );
};
