import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { AddAlbumItemsAPICall } from 'reactApp/api/albums/AddAlbumItemsAPICall';
import { CreateAlbumAPICall } from 'reactApp/api/albums/CreateAlbumAPICall';
import { GetAlbumAPICall } from 'reactApp/api/albums/GetAlbumAPICall';
import { GetAlbumListAPICall } from 'reactApp/api/albums/GetAlbumListAPICall';
import { IS_ONPREMISE, MAX_NAME_LENGTH } from 'reactApp/appHelpers/configHelpers';
import { renderNewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal.helpers';
import { renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getExtension, isVirusItem } from 'reactApp/modules/file/utils';
import { changeGalleryCategory, changeGalleryFolder, galleryLoadSuccess } from 'reactApp/modules/gallery/gallery.module';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { addFilesSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { AddFileSuccessAction, AddItem, PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { publishAlbum } from 'reactApp/modules/modifying/sagas/publish.saga';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { getOpenPopups } from 'reactApp/modules/popup/popup.selectors';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { changeHistory, routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelected } from 'reactApp/modules/selections/selection.saga';
import type { IToggle } from 'reactApp/modules/selections/selection.types';
import { toggle } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { ESortOder } from 'reactApp/modules/sort/sort.types';
import { getItemsCount, getSelectedItemsByStorage, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getInputFile } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { openSelectAlbumPopup } from 'reactApp/sections/AlbumsPage/SelectAlbumPopup/SelectAlbumPopup.helpers';
import { openSelectFromCloudPopup } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/SelectFromCloudPopup.helpers';
import { EAction } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/SelectFromCloudPopup.types';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { getAlbumName } from 'reactApp/utils/formatDate';
import { channel } from 'redux-saga';
import { all, call, debounce, put, select, take, takeEvery } from 'redux-saga/effects';
import { select as typedSelect } from 'typed-redux-saga';

import {
    addFilesToAlbumSuccess,
    addItemsFromCloudChangeFolder,
    addItemsFromCloudToAlbumRequest,
    albumsLoadMoreRequest,
    createAlbumRequest,
    createAlbumSuccess,
    createAndPublishAlbumRequest,
    handleUpdateAlbumList,
    loadAlbumFail,
    loadAlbumRequest,
    loadAlbumsListRequest,
    loadAlbumsListSuccess,
    loadAlbumSuccess,
    selectAlbumToAddItemsRequest,
    setUploadAlbumId,
    toggleItem,
} from './albums.actions';
import { EAlbumAction, EPlaceUpload, ETypeCreated, sendAlbumAnalytics, sendAlbumItemsAnalytics } from './albums.analytics';
import { albumErrors } from './albums.constants';
import { getAlbumsPath, getNewAlbumItem, normalizeAlbumItem } from './albums.helpers';
import {
    getAlbumAllowedExtensions,
    getAlbumCursor,
    getCurrentAlbum,
    isRootAlbumStorage,
    selectFromCloudGetSelected,
} from './albums.selector';
import type { IAlbumId, ICreateAlbumRequest } from './albums.types';

const createAlbumAPICall = ({ title }) => new CreateAlbumAPICall().makeRequest({ title });
const addAlbumItemsAPICall = ({ id, ids }) => new AddAlbumItemsAPICall().makeRequest({ album_id: id, paths: ids });
const getAlbumsListCall = () => new GetAlbumListAPICall().makeRequest({ order: ESortOder.desc });
const getAlbumCall = ({ albumId, cursor = '' }) => new GetAlbumAPICall().makeRequest({ album_id: albumId, cursor, order: ESortOder.desc });

function* openAlbum(id) {
    yield put(changeHistory(getAlbumsPath(id)));
}

function* showAlbumSnackBar({ text, id }) {
    const openAlbumChanell = channel();
    const isPhone = EnvironmentSelectors.isPhone();

    // На таче пока что нет раздела с альбомами
    const button = isPhone
        ? {}
        : {
              buttonText: 'Посмотреть',
              onButtonClick: () => openAlbumChanell.put(true),
          };

    yield put(
        showSnackbarAction({
            closable: true,
            id: 'create-album-items-success',
            type: SnackbarTypes.success,
            text,
            ...button,
        })
    );

    if (isPhone) {
        return;
    }

    const shouldOpenAlbum = yield take(openAlbumChanell);
    openAlbumChanell.close();

    if (shouldOpenAlbum) {
        yield openAlbum(id);
    }
}

function* waitForAlbumName() {
    const createAlbumChannel = channel();
    const folderName = getAlbumName();

    renderNewFolderModal({
        onSuccess: ({ folderName }) => createAlbumChannel.put(folderName),
        onClose: () => createAlbumChannel.close(),
        showShareButton: false,
        showCreateButton: true,
        title: 'Создать альбом',
        placeholder: 'Введите название альбома',
        folderName,
        createFolder: async (name) => {
            const albumName = name.trim();

            if (!albumName.length) {
                return Promise.reject(albumErrors.getMessage('required'));
            }

            if (albumName.length > MAX_NAME_LENGTH) {
                return Promise.reject(albumErrors.getMessage('name_too_long'));
            }

            try {
                const {
                    data: { id, title },
                } = await createAlbumAPICall({ title: albumName });

                createAlbumChannel.put({ id, title });
                closePopupHelper(popupNames.CREATE_FOLDER);
            } catch ({ error }) {
                return Promise.reject(albumErrors.getMessage(error));
            }
        },
    });

    return yield take(createAlbumChannel);
}

function* createAlbum({ typeCreated, albumName }: { typeCreated: ETypeCreated; albumName?: string }) {
    const { isFull: isOverquota } = yield select(UserSelectors.getCloudSpace);
    const isB2B = yield select(UserSelectors.isB2BUser) || IS_ONPREMISE;

    if (isOverquota && !isB2B) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.createAlbum });
        return;
    }

    try {
        sendAlbumAnalytics({ action: EAlbumAction.CREATED, type_created: typeCreated });

        if (albumName) {
            const {
                data: { id, title },
            } = yield call(createAlbumAPICall, { title: albumName });
            yield put(createAlbumSuccess({ id, name: title }));
            return id;
        }

        const { id, title } = yield call(waitForAlbumName);
        yield put(createAlbumSuccess({ id, name: title }));
        return id;
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                id: 'error',
                text: albumErrors.getMessage('create_error'),
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

function* createAndPublishAlbum(action: PayloadAction<ICreateAlbumRequest>) {
    const { items } = action.payload;

    if (!items?.length) {
        return;
    }

    const name = getAlbumName();
    const id = yield createAlbum({ typeCreated: ETypeCreated.FROM_PUBLISH, albumName: name });

    if (!id) {
        return;
    }

    try {
        yield publishAlbum({ item: { id } as PublishItem });
        yield call(addAlbumItemsAPICall, { ids: items.map((item) => item.id), id });
        sendAlbumItemsAnalytics({ action: EAlbumAction.UPLOAD, items, place_upload: EPlaceUpload.FROM_PUBLISH });

        yield call(renderSharingNew, {
            id,
            itemStorage: EStorageType.albums,
            from: EFrom.ALBUMS,
        });

        yield showAlbumSnackBar({ text: 'Создан публичный альбом', id });
    } catch (error) {
        logger.error(error);
    }
}

function* handleLoadAlbumsList() {
    try {
        const { data } = yield getAlbumsListCall();
        yield put(loadAlbumsListSuccess(data));
    } catch (error) {
        yield put(loadAlbumFail({ albumId: ROOT_FOLDER_ID }));
        logger.error(error);
    }
}

function* handleLoadAlbum(action: PayloadAction<IAlbumId>) {
    const { albumId } = action.payload;

    try {
        const { data } = yield getAlbumCall({ albumId });

        yield put(loadAlbumSuccess({ ...data, albumId }));
    } catch (error: any) {
        logger.error(error);

        if (error.status === 404) {
            yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
            return;
        }

        yield put(loadAlbumFail({ albumId }));
    }
}

function* handleLoadMore() {
    try {
        const currentAlbum = yield select(getCurrentAlbum);

        if (!currentAlbum) {
            return;
        }

        const cursor = yield select(getAlbumCursor);
        const albumId = currentAlbum.id;

        const {
            data: { cursor: newCursor, list, title },
        } = yield getAlbumCall({ albumId, cursor });

        yield put(loadAlbumSuccess({ cursor: newCursor, list, albumId, title }));
    } catch (error) {
        logger.error(error);
    }
}

function* updateAlbumList(action: PayloadAction<IAlbumId>) {
    const isRootAlbum = yield select(isRootAlbumStorage);

    if (isRootAlbum) {
        yield put(loadAlbumsListRequest());
        return;
    }

    yield put(loadAlbumRequest({ albumId: action.payload.albumId }));
}

function* handleSelectItemsFromCloud(action?: PayloadAction<{ id: string }>) {
    const { id } = action?.payload || {};
    const currentAlbum = yield select(getCurrentAlbum);
    const albumId = id || currentAlbum?.id;
    const datalistChannel = channel();

    yield put(setUploadAlbumId({ albumId }));

    yield resetSelected();

    yield put(changeGalleryCategory({ category: GalleryCategoryType.all }));
    yield take(galleryLoadSuccess);
    const count = yield select(getItemsCount, EStorageType.gallery);

    openSelectFromCloudPopup({
        onClose: () => datalistChannel.close(),
        onSuccess: () => datalistChannel.put(true),
        isEmpty: !count,
    });

    yield take(datalistChannel);
    datalistChannel.close();

    const ids = yield select(SelectionsSelectors.getSelectedIdxs);
    const items = yield select(getSelectedItemsByStorage, EStorageType.gallery);

    sendAlbumItemsAnalytics({ action: EAlbumAction.UPLOAD, place_upload: EPlaceUpload.FROM_CLOUD, items });

    try {
        if (ids.length) {
            yield call(addAlbumItemsAPICall, { ids, id: albumId });
        }

        yield put(handleUpdateAlbumList({ albumId }));

        yield put(
            showSnackbarAction({
                id: 'album-success',
                text: `${ids.length > 1 ? 'Файлы добавлены' : 'Файл добавлен'} в альбом`,
                type: SnackbarTypes.success,
                closable: true,
            })
        );
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                id: 'album-error',
                text: albumErrors.getMessage('add_error'),
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }

    yield resetSelected();
}

function* handleCreateAlbum(action: PayloadAction<ICreateAlbumRequest | undefined>) {
    const { items, albumName } = action.payload || {};
    const albumId = yield createAlbum({ typeCreated: ETypeCreated.FROM_CREATE_BUTTON, albumName });

    if (!albumId) {
        return;
    }

    if (items?.length) {
        yield call(addAlbumItemsAPICall, { ids: items.map((item) => item.id), id: albumId });
        yield showAlbumSnackBar({ text: 'Создан альбом', id: albumId });

        return;
    }

    yield openAlbum(albumId);
    yield put(addItemsFromCloudToAlbumRequest({ id: albumId }));
}

function* addItems(files: AddItem[]) {
    const ids = files.map((item) => item.home).filter(Boolean);

    if (!ids.length) {
        return;
    }

    const albumItems = yield all(ids.map((id) => select((state) => getInputFile(state, { cloudPath: id })))) || [];

    const albumsMap = albumItems.reduce((albumIdMap, file) => {
        if (!file?.albumId) {
            return albumIdMap;
        }

        const albumList = albumIdMap[file.albumId] || [];

        albumList.push(file);

        albumIdMap[file.albumId] = albumList;

        return albumIdMap;
    }, {});

    for (const albumId of Object.keys(albumsMap)) {
        const itemIds = albumsMap[albumId].map((item) => item.cloudPath).filter(Boolean);

        const { data: addedIds } = yield call(addAlbumItemsAPICall, { ids: itemIds, id: albumId });
        const items = albumsMap[albumId].map((item, index) => getNewAlbumItem({ elementId: addedIds[index], albumId, item }));

        sendAlbumItemsAnalytics({ action: EAlbumAction.UPLOAD, place_upload: EPlaceUpload.UPLOAD, items });

        yield put(
            addFilesToAlbumSuccess({
                items: items.map((item) => normalizeAlbumItem(item, albumId)),
                albumId,
            })
        );

        yield put(handleUpdateAlbumList({ albumId }));
    }
}

function* addItemToAlbum(action: PayloadAction<AddFileSuccessAction>) {
    const { items: list } = action.payload;
    const storage = yield select(getCurrentStorage);
    const allowedExtensions = getAlbumAllowedExtensions();

    const items = list.filter((item) => {
        const ext = getExtension(item);

        return allowedExtensions.includes(ext?.toLowerCase());
    });

    if (!items?.length || storage !== EStorageType.albums) {
        return;
    }

    try {
        yield addItems(items);
    } catch (error) {
        logger.error(error);

        yield put(
            showSnackbarAction({
                id: 'add-album',
                closable: true,
                text: albumErrors.getMessage('add_error'),
                type: SnackbarTypes.failure,
            })
        );
    }
}

function* selectAlbumAndAddItems(action: PayloadAction<ICreateAlbumRequest>) {
    const { items } = action.payload;

    if (!items?.length) {
        return;
    }

    const { isFull: isOverquota } = yield select(UserSelectors.getCloudSpace);
    const isB2B = yield select(UserSelectors.isB2BUser) || IS_ONPREMISE;

    if (items.length && items.some(isVirusItem)) {
        yield put(showVirusDlg({ items, actionName: ActionName.publish, doNotChangeRoute: true }));
        return;
    }

    if (isOverquota && !isB2B) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.publish });
        return;
    }

    const selectAlbumChanel = channel();

    openSelectAlbumPopup({
        onClose: () => selectAlbumChanel.close(),
        onSuccess: (albumId: string) => selectAlbumChanel.put(albumId),
        onCreateAlbum: () => selectAlbumChanel.put(EAction.CREATE),
        items,
    });

    let albumId = yield take(selectAlbumChanel);
    closePopupHelper(popupNames.SELECT_ALBUM);
    selectAlbumChanel.close();

    if (albumId === EAction.CREATE) {
        albumId = yield createAlbum({ typeCreated: ETypeCreated.FROM_EMPTY_POPUP });
    }

    try {
        yield call(addAlbumItemsAPICall, { ids: items.map((item) => item.id), id: albumId });
        yield showAlbumSnackBar({ text: 'Успешно добавлено в альбом', id: albumId });
        sendAlbumItemsAnalytics({ action: EAlbumAction.UPLOAD, place_upload: EPlaceUpload.FROM_CLOUD, items });
    } catch (error) {
        yield put(
            showSnackbarAction({
                id: 'error',
                text: albumErrors.getMessage('create_error'),
                type: SnackbarTypes.failure,
                closable: true,
            })
        );

        logger.error(error);
    }

    yield resetSelected();
}

function* handleSelectItemsFromCloudChangeFolder(action: PayloadAction<{ folder: string }>) {
    const { folder } = action.payload;
    yield put(changeGalleryFolder({ folder }));
}

function* toggleItemForAlbum(action: PayloadAction<IToggle>) {
    const popups = yield* typedSelect(getOpenPopups);
    const isSelectFromCloudPopupOpen = popups.find((item) => item.name === popupNames.SELECT_FROM_CLOUD_DIALOG);

    // Реагируем на toggle только в попапе выбора файлов для альбома.
    if (isSelectFromCloudPopupOpen) {
        const { selectedIdx, storage } = action.payload;
        const storageItem = yield select(getStorageItemById, storage, selectedIdx);
        const selectedItem = yield select(selectFromCloudGetSelected, selectedIdx);
        yield put(toggleItem({ selectedItem: storageItem || selectedItem }));
    }
}

export function* watchAlbums() {
    yield takeEvery(createAndPublishAlbumRequest.toString(), createAndPublishAlbum);
    yield takeEvery(selectAlbumToAddItemsRequest.toString(), selectAlbumAndAddItems);
    yield takeEvery(loadAlbumsListRequest.toString(), handleLoadAlbumsList);
    yield takeEvery(loadAlbumRequest.toString(), handleLoadAlbum);
    yield takeEvery(albumsLoadMoreRequest.toString(), handleLoadMore);
    yield takeEvery(createAlbumRequest.toString(), handleCreateAlbum);
    yield takeEvery(addFilesSuccess.toString(), addItemToAlbum);
    yield takeEvery(addItemsFromCloudToAlbumRequest.toString(), handleSelectItemsFromCloud);
    yield takeEvery(addItemsFromCloudChangeFolder.toString(), handleSelectItemsFromCloudChangeFolder);
    yield debounce(2000, handleUpdateAlbumList.toString(), updateAlbumList);
    yield takeEvery(toggle.toString(), toggleItemForAlbum);
}
