import answerImg from 'img/sass-landing/ai/answer_ii_2x.jpg';
import descriptionImg from 'img/sass-landing/ai/description_ii_2x.png';
import sameImg from 'img/sass-landing/ai/same_ii_2x.png';

export const LANDING_TITLE = 'Вести дела проще с подпиской Mail\u00A0Space\u00A0PRO';

export const SASS_DOCUMENT_IDS = {
    tariffList: 'TARIFF_LIST',
    benefitTable: 'BENIF_TABLE',
};

export const SASS_MAIL_BENEFITS = [
    {
        id: 'description',
        name: 'Описания товаров и услуг от нейросети',
        free: false,
        paid: true,
    },
    {
        id: 'feedback',
        name: 'Ответы на отзывы от нейросети, даже негативные',
        free: false,
        paid: true,
    },
    {
        id: 'seo',
        name: 'Синонимы для SEO от нейросети',
        free: false,
        paid: true,
    },
    {
        id: 'no_advertising',
        name: 'Без рекламы в Почте и Облаке',
        free: false,
        paid: true,
    },
    {
        id: 'widgets',
        name: 'Виджеты Календаря и других сервисов',
        free: false,
        paid: true,
    },
    {
        id: 'speed_upload',
        name: 'Высокая скорость загрузки файлов',
        free: true,
        paid: true,
    },
    {
        id: 'folder_in_notes',
        name: 'Папки в Заметках',
        free: true,
        paid: true,
    },
    {
        id: 'support',
        name: 'Приоритетная поддержка',
        free: false,
        paid: true,
    },
];

export const SASS_PAYMENT_FEATURES = [
    'Нейросеть для бизнеса',
    'Загрузка тяжёлых файлов  до 100 ГБ',
    'Свободное место в телефоне',
    'Виджеты планирования',
];
export const PROJECT_LIST = [
    {
        id: 'mail',
        title: 'Почта',
        link: 'https://e.mail.ru/',
    },
    {
        id: 'cloud',
        title: 'Облако',
        link: 'https://cloud.mail.ru/',
    },
    {
        id: 'calendar',
        title: 'Календарь',
        link: 'https://calendar.mail.ru/',
    },
    {
        id: 'notes',
        title: 'Заметки',
        link: 'https://notes.mail.ru/',
    },
    {
        id: 'feedback',
        title: 'Обратная связь',
        link: 'https://help.mail.ru/cloud_web/size/trouble/quota',
    },
    {
        id: 'help',
        title: 'Помощь',
        link: 'https://help.mail.ru/mail',
    },
];
export const promoTariffSlider = [
    {
        id: 'desc',
        header: `Готовьте описания товаров вместе`,
        img: descriptionImg,
        btnText: 'Придумать описание',
    },
    {
        id: 'same',
        header: `Подбирайте синонимы для SEO`,
        img: sameImg,
        btnText: 'Подобрать синонимы',
    },
    {
        id: 'answer',
        header: `Пишите ответы на\u00A0отзывы`,
        img: answerImg,
        btnText: 'Написать ответы',
    },
];

export const SASS_TARIFF_QUERY = {
    plan: 'plan',
    card: 'tariffCard',
} as const;
