/* eslint-disable complexity */
import settings from 'Cloud/settings';
import { APP_TITLE, IS_B2B_BIZ_USER, IS_PHONE_BROWSER, IS_REACT_PROMO_QUOTA_PAGE } from 'reactApp/appHelpers/configHelpers';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { changeHistory } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { changeSearchHistory } from 'reactApp/modules/search/search.actions';
import { ERROR_STATUS_MAP } from 'reactApp/sections/ErrorPage/ErrorPage.helpers';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store as reduxStore } from 'reactApp/store';
import opener from 'reactApp/utils/opener';
import { getEncodePath } from 'reactApp/utils/urlHelper';

import { type CloudFile, type CloudItem, EStorageType, INTEGRATION_STORAGES } from './storage.types';

// eslint-disable-next-line max-lines-per-function
export const getStorage = (storage: string | null) => {
    const isHome = storage === EStorageType.home;
    const isPublic = storage === EStorageType.public;
    const isSharedAutodelete = storage === EStorageType.sharedAutodelete;
    const isSharedLinks = storage === EStorageType.sharedLinks;
    const isSharedIncoming = storage === EStorageType.sharedIncoming;
    const isTrashBin = storage === EStorageType.trashbin;
    const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;
    const isSearch = storage === EStorageType.search;
    const isFavorites = storage === EStorageType.favorites;
    const isStock = storage === EStorageType.stock;
    const isFeed = storage === EStorageType.feed;
    const isStart = storage === EStorageType.start;
    const isGallery = storage === EStorageType.gallery;
    const isRecommend = storage === EStorageType.recommend;
    const isDocuments = storage === EStorageType.documents;
    const isStory = storage === EStorageType.story;
    const isEditor =
        storage === EStorageType.r7 ||
        storage === EStorageType.myoffice ||
        storage === EStorageType.r7wopi ||
        storage === EStorageType.myofficeAttaches;
    const isAttachesStorage = storage === EStorageType.attaches;
    const isAlbums = storage === EStorageType.albums;
    const isQuotaCleaner = storage === EStorageType.quotaCleaner;
    const isQuotaPartner = storage === EStorageType.quotaPartner;
    const isQuotaTariffs = storage === EStorageType.quotaTariffs;
    const isSubscription = storage === EStorageType.subscriptions;
    const isEmbedded = storage === EStorageType.embedded;
    const isFamilyPage = storage === EStorageType.family;
    const isAllDocuments = storage === EStorageType.alldocuments;
    const isPdfEdit = storage === EStorageType.pdfEdit;
    const isIntegration = storage === EStorageType.integration;
    const isInlineIntegration = storage === EStorageType.inlineIntegration;
    const isTemplates = storage === EStorageType.templates;
    const isSubscriptions = storage === EStorageType.subscriptions;

    return {
        isHome,
        isPublic,
        isSharedAutodelete,
        isSharedLinks,
        isSharedIncoming,
        isTrashBin,
        isAttaches,
        isSearch,
        isFavorites,
        isStock,
        isFeed,
        isStart,
        isGallery,
        isRecommend,
        isDocuments,
        isStory,
        isEditor,
        isAlbums,
        isQuotaCleaner,
        isQuotaTariffs,
        isQuotaPartner,
        isEmbedded,
        isAllDocuments,
        isPdfEdit,
        isFamilyPage,
        isTemplates,
        isPrivatePage:
            isHome ||
            isGallery ||
            isFavorites ||
            isDocuments ||
            isAllDocuments ||
            isSharedAutodelete ||
            isStart ||
            isAlbums ||
            isDocuments ||
            isTrashBin ||
            isFeed ||
            isRecommend ||
            isSharedIncoming ||
            isSharedLinks ||
            isFamilyPage ||
            isAttachesStorage,
        isPublicOrStock: isPublic || isStock,
        isNewQuotaLanding: isQuotaPartner || isQuotaTariffs,
        isSubscription,
        isIntegration,
        isInlineIntegration,
        isSubscriptions,
    };
};

let lastId = '';

// выпилить navigateStorage когда будет нормальный хелпер
export const changeStorageHistory = ({
    id,
    storage,
    navigateToStorage,
    search = '',
}: {
    id?: string;
    search?: string;
    isFolder?: boolean;
    storage?: EStorageType;
    navigateToStorage?: EStorageType;
}) => {
    if ((storage === EStorageType.home || isIntegrationStorage(storage)) && id) {
        const currentId = getCurrentRouteId(reduxStore.getState());

        // Если инлайн интеграция (Тутория), то позволяем перемещаться по папкам даже если lastId === id,
        // потому что существующая логика блокирует переход в папку, из которой только что переместились на уровень выше
        // посредством хлебных крошек, т.к. они не обновляют последний айди.
        // https://jira.vk.team/browse/TUTR-223
        if (storage === EStorageType.inlineIntegration || lastId !== id || currentId !== id) {
            reduxStore.dispatch(changeHomeHistory({ id, search }));
        }
        lastId = id;
        return;
    }

    if (IS_PHONE_BROWSER && storage === EStorageType.search && id) {
        reduxStore.dispatch(changeSearchHistory({ id }));
        return;
    }

    if (IS_PHONE_BROWSER && (storage === EStorageType.public || storage === EStorageType.stock) && id) {
        reduxStore.dispatch(changeHistory(`/${storage}/${getEncodePath(id)}`));
        return;
    }

    if (navigateToStorage) {
        opener(`/${navigateToStorage}${id || ''}`, true);
    }
};

export const getPageSubtitle = () => {
    if (IS_REACT_PROMO_QUOTA_PAGE) {
        return 'Единое хранилище Почты и Облака';
    }
    return IS_B2B_BIZ_USER ? APP_TITLE : 'Облако Mail';
};

export const getPageTitleByStorage = (storage, itemTitle): string => {
    const title = itemTitle ? itemTitle : settings?.storages?.[storage]?.title;
    const subTitle = getPageSubtitle();

    if (title === ERROR_STATUS_MAP[EStatus.BAD_BROWSER].title) {
        return subTitle;
    }

    return title ? `${title} / ${subTitle}` : subTitle;
};

export const DEFAULT_API_LIMIT = 500;

export const getLoadMoreLimit = (storage: EStorageType): number => {
    if ([EStorageType.home, EStorageType.public, EStorageType.sharedLinks, EStorageType.sharedIncoming].includes(storage)) {
        return DEFAULT_API_LIMIT;
    }

    if (storage === EStorageType.favorites) {
        return 1000;
    }

    if (storage === EStorageType.search) {
        return 10000;
    }

    return 100;
};

export const isCloudFile = (item?: CloudItem | null): item is CloudFile => !!item && !item.isFolder;

export const getIdByStorage = (storage: EStorageType) => {
    return `/${storage}`;
};

export const hasStorageDatalist = (storage: EStorageType) =>
    [
        EStorageType.albums,
        EStorageType.alldocuments,
        EStorageType.attaches,
        EStorageType.favorites,
        EStorageType.feed,
        EStorageType.gallery,
        EStorageType.home,
        EStorageType.integration,
        EStorageType.inlineIntegration,
        EStorageType.documents,
        EStorageType.search,
        EStorageType.sharedAutodelete,
        EStorageType.sharedIncoming,
        EStorageType.sharedLinks,
        EStorageType.trashbin,
        EStorageType.public,
        EStorageType.stock,
        EStorageType.start,
    ].includes(storage);

export const isIntegrationStorage = (storage: EStorageType | undefined | null) => Boolean(storage && INTEGRATION_STORAGES[storage]);
