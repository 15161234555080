import React, { type ReactElement } from 'react';
import type { HomeFolder } from 'reactApp/modules/home/home.types';
import type { IAutoDeleteFoldersInfo } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.type';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';
import { foldersPluralGenitive } from 'reactApp/utils/pluralHelpers';

import styles from './AutoDeleteFolderInformer.css';

interface IContent {
    buttonText: string;
    description: ReactElement;
    closable: boolean;
}

export const useContent = ({
    item,
    isRoot,
    foldersInfo,
}: {
    item: HomeFolder;
    isRoot: boolean;
    foldersInfo: IAutoDeleteFoldersInfo;
}): IContent => {
    const { weblinkExpires } = item || {};

    if (!isRoot && weblinkExpires) {
        return {
            closable: false,
            buttonText: 'Настройки',
            description: (
                <>
                    Данная папка со всеми файлами будет удалена{' '}
                    <span className={styles.date}>{formatAutoDeleteExpires(weblinkExpires)}</span>
                </>
            ),
        };
    }

    let deletedText = '';
    const allOne = foldersInfo?.allFolders === 1;
    const allWillDelete = foldersInfo?.allFolders === foldersInfo?.folderCount;

    if (foldersInfo.folderCount === 1) {
        deletedText = `${allOne ? 'она' : 'одна'} будет удалена ${formatAutoDeleteExpires(foldersInfo?.expires || 0)}`;
    } else if (foldersInfo?.isToday) {
        deletedText = `${allWillDelete ? 'они' : foldersInfo.folderCount} будут удалены сегодня`;
    } else {
        deletedText = `${allWillDelete ? 'они' : foldersInfo.folderCount} будут удалены в ближайшие 7 дней`;
    }

    return {
        closable: true,
        buttonText: 'Подробнее',
        description: (
            <>
                У вас настроено автоматическое удаление {foldersInfo?.allFolders} {foldersPluralGenitive(foldersInfo?.allFolders || 0)},{' '}
                {deletedText}
            </>
        ),
    };
};
