import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

export const watchMedia = (breakpoint: number, callback: (hit: boolean) => void): (() => void) => {
    const mediaQueryList = window.matchMedia?.(`(min-width: ${breakpoint}px)`);
    const innerHandler = (evt: MediaQueryListEvent) => callback(evt.matches);

    mediaQueryList?.addEventListener?.('change', innerHandler);
    callback(mediaQueryList?.matches);

    return () => mediaQueryList?.removeEventListener?.('change', innerHandler);
};

/**
 * Allow to watch and control the media qeury min-width breakpoint state
 * @param breakpoint - min-width number at which we toggle the media breakpoint
 * @returns [breakpointHit, breakpoint, setBreakpoint]
 */
export const useMinWidthBreakpoint = (breakpoint: number): [boolean, number, Dispatch<SetStateAction<number>>] => {
    const [currentBreakpoint, setBreakpoint] = useState(breakpoint);
    const [breakpointHit, setBreakpointHit] = useState(false);

    useEffect(() => {
        return watchMedia(currentBreakpoint, setBreakpointHit);
    }, [currentBreakpoint]);

    return [breakpointHit, currentBreakpoint, setBreakpoint];
};
