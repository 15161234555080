import { createReducer } from '@reduxjs/toolkit';
import settings from 'Cloud/settings';
import type { IntegrationState } from 'reactApp/modules/integration/integration.types';

export const initialState: IntegrationState = {
    parentOrigin: settings.request?.query_params?.['parent_origin'],
    p: settings.request?.query_params?.['integration_p'],
    client: settings.request?.query_params?.['integration_client'],
    userId: settings.request?.query_params?.['integration_uid'],
    channel: settings.request?.query_params?.['integration_channel'],
    authOrigin: settings.request?.query_params?.['auth_origin'],
    authType: settings.request?.query_params?.['auth'],
    isCloudDocumentsViewerEnabled: settings.request?.query_params?.['cloud_documents_viewer_enabled'] === 'true',
    isCloudMyOfficeViewerEnabled: settings.request?.query_params?.['cloud_my_office_viewer_enabled'] === 'true',
    isAutoSharingDisabled: settings.request?.query_params?.['auto_sharing_disabled'] === 'true',
    isCloudQuotePayEnabled: settings.request?.query_params?.['cloud_quote_pay_enabled'] === 'true',
    isNewWidgetIconsEnabled: settings.request?.query_params?.['new_widget_icons_enabled'] === 'true',
};

export const integrationReducer = createReducer(initialState, {});
