import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import React, { useCallback } from 'react';
import { DOM_ID_INSIDE_PHONE_DATALIST } from 'reactApp/appHelpers/advertisingConfigHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';

export const AdvBanner = () => {
    const updateAds = useCallback(() => {
        try {
            Rubles?.updateAdvPhonePublicInsideDatalist();
        } catch (error) {
            captureException(error);
        }
    }, []);

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem: () => <div id={DOM_ID_INSIDE_PHONE_DATALIST} />,
    });
};

AdvBanner.displayName = 'AdvBanner';
