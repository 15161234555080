import classNames from 'clsx';
import React, { type ReactElement, PureComponent } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { ShowcaseItem } from 'reactApp/ui/Showcase/ShowcaseItem/ShowcaseItem';
import type { ShowcaseItemData } from 'reactApp/ui/Showcase/ShowcaseItem/ShowcaseItem.types';
import { createGaSender } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './Showcase.css';

export interface Props {
    items?: ShowcaseItemData[];
    itemsPopular?: ShowcaseItemData[];
    isWindows: boolean;
}

const gaSender = createGaSender('mrecomm');

export class Showcase extends PureComponent<Props> {
    public componentDidMount(): void {
        gaSender('show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'mail-recommend' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'mail-recommend' });
    }

    private handleClick = (id: string): void => gaSender('click', id);

    private handleShowItem = (id: string): void => gaSender('showitem', id);

    private renderItem = (item: ShowcaseItemData): ReactElement => {
        const { isWindows } = this.props;
        return <ShowcaseItem key={item.id} {...item} isWindows={isWindows} onClick={this.handleClick} onShow={this.handleShowItem} />;
    };

    public render(): ReactElement | null {
        const { items, itemsPopular } = this.props;

        if (!items || !items.length) {
            return null;
        }

        const popularSection = !!itemsPopular && !!itemsPopular.length && (
            <>
                <div className={styles.popularTitle}>Популярные приложения</div>
                <div className={styles.section}>{itemsPopular.map(this.renderItem)}</div>
            </>
        );

        return (
            <div>
                <div className={classNames(styles.section, { [styles.sectionResponsive]: ENABLE_FULL_RESPONSIVE })}>
                    {items.map(this.renderItem)}
                </div>
                {popularSection}
            </div>
        );
    }
}
