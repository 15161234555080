import { logger } from 'lib/logger';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getUploadAlbumId } from 'reactApp/modules/albums/albums.selector';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { isMatch } from 'reactApp/modules/media/media.module';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getPublicUploadLimit } from 'reactApp/modules/public/public.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getCurrentFolder, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { setButtonTypeClickAction, setNotAllowedExtensionsAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedExtensions, isUploadFolderAllowed, selectButtonTypeClick } from 'reactApp/modules/upload/upload.selectors';
import { createEntryDescriptor, createFileDescriptor, processDescriptorsDir } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import {
    filterDescriptors,
    getWorkingDirectory,
    prepareDescriptors,
    prepareFileDescriptorsForUI,
    sendGaUploaderNew,
} from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { checkCanUpload } from 'reactApp/modules/uploading/sagas/checkCanUpload';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { resetFilesLogCounter, uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { type addToUploadQueue, processUploadQueue, sendDescriptorsToUI } from 'reactApp/modules/uploading/uploading.module';
import { isDropEnabled } from 'reactApp/modules/uploading/uploading.selectors';
import { EUploadSource } from 'reactApp/modules/uploading/uploading.types';
import { EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import {
    addTotalCount,
    setHasInputAction,
    setHasNotAllowedExtensions,
    setProgressStatusAction,
    setShowUploaderAction,
} from 'reactApp/modules/uploadList/uploadList.module';
import { getProgressStatus, getProgressUploadPacket } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendTmrGoal } from 'reactApp/utils/tmr';
import { call, put, select } from 'redux-saga/effects';

const shouldCollapseNavPanel = (): boolean => {
    if (!ENABLE_FULL_RESPONSIVE) {
        return false;
    }
    return isMatch(`(max-width: 850px)`);
};

export function* handleAddToUploadingQueue(action: ReturnType<typeof addToUploadQueue>) {
    const { descriptorOptions = {} } = action.payload;
    let files = action.payload.files;

    const canUpload = yield call(checkCanUpload);

    if (!canUpload) {
        return;
    }

    const processingId = Date.now();

    if (!files?.length) {
        return;
    }

    try {
        const isDroppingEnabled = yield select(isDropEnabled);

        if (descriptorOptions?.isDrop && !isDroppingEnabled) {
            return;
        }

        yield put(setHasInputAction(false));

        if (shouldCollapseNavPanel()) {
            yield put(sidebarToggle(false));
        }

        const bttnType = yield select(selectButtonTypeClick);
        if (!bttnType || bttnType === 'dnd') {
            yield put(setButtonTypeClickAction('click'));
        }

        const uploadingQueue: UploadingDescriptor[] = [];

        resetFilesLogCounter();

        const uploaderUIStatus = yield select(getProgressStatus);

        if (uploaderUIStatus === EProgressStatus.NONE) {
            yield put(setProgressStatusAction({ status: EProgressStatus.STARTING }));
        }

        // Это нужно вычислять в начале обработки пачки, так как потом юзер может куда-то перейти
        const storage: EStorageType = yield select(getCurrentStorage);
        const routeId = yield select(getCurrentRouteId);
        const isFolderAllowed: boolean = yield select(isUploadFolderAllowed);
        const currentFolder: ReturnType<typeof getCurrentFolder> = yield select(getCurrentFolder);
        const currentPacketIdForUI = yield select(getProgressUploadPacket);

        let isSpecificDocument = false;
        if (storage === EStorageType.documents) {
            isSpecificDocument = !!descriptorOptions.documentType || (routeId !== '/documents' && routeId !== ROOT_FOLDER_ID);
        }

        const originalCloudPath = descriptorOptions.originalCloudPath;
        const workingDirectory = getWorkingDirectory(storage, currentFolder, originalCloudPath, isSpecificDocument);

        const uploadingPacketConfig = new UploadingPacketConfig(storage, workingDirectory, processingId, currentPacketIdForUI);

        uploadingService.startProcessing(uploadingPacketConfig);

        uploadingPacketConfig.userFileSizeLimit = (yield select(UserSelectors.getUserData)).uploadLimit;
        uploadingPacketConfig.publicUploadLimit = yield select(getPublicUploadLimit);
        uploadingPacketConfig.ownPublicFolderWeblink = currentFolder && 'weblink' in currentFolder && currentFolder.weblink;
        uploadingPacketConfig.email = yield select(UserSelectors.getEmail);
        uploadingPacketConfig.continueUpload = descriptorOptions.continueUpload;
        uploadingPacketConfig.isDomainFolder = currentFolder ? isDomainFolder(currentFolder) : false;
        uploadingPacketConfig.hasParentDomainFolder = currentFolder
            ? yield select(hasParentsDomainFolder, currentFolder as unknown as CloudItem)
            : false;
        uploadingPacketConfig.isMountedFolder = currentFolder ? isMountedFolder(currentFolder) : false;

        if (isSpecificDocument) {
            uploadingPacketConfig.documentType = descriptorOptions.documentType;
            uploadingPacketConfig.isSpecificDocument = isSpecificDocument;
        }

        uploadingService.conflictResolution = null;

        if (storage === EStorageType.albums && descriptorOptions.source !== EUploadSource.toolbar) {
            const currentAlbumId = yield select(getUploadAlbumId);
            if (currentAlbumId) {
                descriptorOptions.albumId = currentAlbumId;
                uploadingPacketConfig.albumId = currentAlbumId;
            }
        }

        const allowedExtensions = yield select((state) =>
            getAllowedExtensions(
                state,
                // @ts-ignore
                storage === EStorageType.albums && descriptorOptions.source === EUploadSource.toolbar ? EStorageType.home : storage
            )
        );

        let hasNotAllowedExtensions = false;

        const notUploadExt: string[] = [];
        const filterAllowedFiles = (item) => {
            const name = item.name;
            const ext = (getExtension(name) || '').toLowerCase();
            const isAllowed = allowedExtensions ? allowedExtensions.includes(ext) : true;

            if (!isAllowed) {
                hasNotAllowedExtensions = true;
                notUploadExt.push(ext);
            }

            return isAllowed;
        };

        if (storage !== EStorageType.albums) {
            files = files.filter(filterAllowedFiles);
        }

        if (descriptorOptions.checkAllowedExtensions && allowedExtensions && files && hasNotAllowedExtensions) {
            if (storage === EStorageType.alldocuments && hasNotAllowedExtensions && files.length === 0) {
                openPopupHelper({
                    popupName: popupNames.NOT_DOCUMENT_MODAL,
                    data: { notUploadExt },
                });
            } else if (storage === EStorageType.alldocuments && hasNotAllowedExtensions && files.length > 0) {
                yield put(setHasNotAllowedExtensions(true));
            }

            yield put(setNotAllowedExtensionsAction());

            if (files.length === 0 && (uploaderUIStatus === EProgressStatus.NONE || uploaderUIStatus === EProgressStatus.COMPLETE)) {
                yield put(setShowUploaderAction(false));
            }
        }

        let descriptorsToProcessDelayed: any[] = [];

        const tempResultFiles: UploadingDescriptor[] = [];
        const tempResultChildren: UploadingDescriptor[] = [];
        for (const item of files) {
            if (item instanceof File) {
                const descriptor = createFileDescriptor(item, uploadingPacketConfig);

                if (descriptor) {
                    uploadingQueue.push(descriptor);
                }
            } else {
                const descriptor = yield createEntryDescriptor(item, uploadingPacketConfig);

                if (!descriptor) {
                    continue;
                }

                const { resultFiles, resultDirs, resultChildren, descriptorsToProcess } = yield processDescriptorsDir([descriptor]);

                tempResultFiles.push(...resultFiles);
                tempResultChildren.push(...resultChildren);
                descriptorsToProcessDelayed.push(...descriptorsToProcess);
                uploadingQueue.push(...resultDirs);
                uploadingService.addFolders([...resultDirs]);
            }
        }
        uploadingQueue.push(...tempResultFiles);
        uploadingQueue.push(...tempResultChildren);

        while (descriptorsToProcessDelayed.length > 0) {
            const { resultFiles, resultDirs, resultChildren, descriptorsToProcess } = yield processDescriptorsDir(
                descriptorsToProcessDelayed
            );

            sendGaUploaderNew('packet', 'process');

            descriptorsToProcessDelayed = descriptorsToProcess;
            uploadingQueue.push(...resultFiles);
            uploadingQueue.push(...resultChildren);
            uploadingService.addFolders([...resultDirs]);
        }

        uploadingPacketConfig.size = 0;
        uploadingPacketConfig.fileCount = 0;
        uploadingPacketConfig.folderCount = 0;
        for (const item of uploadingQueue) {
            if (item.isDirectory) {
                uploadingPacketConfig.folderCount++;
            } else {
                uploadingPacketConfig.fileCount++;
                uploadingPacketConfig.size += item.size;
            }
        }

        const filtered = filterDescriptors(uploadingQueue, isFolderAllowed);

        prepareDescriptors(filtered, workingDirectory);

        sendTmrGoal({ goal: 'upload' });
        sendTmrGoal({ goal: filtered.length > 1 ? 'upload-few' : 'upload-one' });

        const fileDescriptors = yield call(prepareFileDescriptorsForUI, filtered, descriptorOptions);

        sendGaUploaderNew('file', `packet_count-newUploader`, { count: filtered.length });
        sendGaUploaderNew('file', 'packet');

        yield put(addTotalCount({ totalCount: uploadingPacketConfig.fileCount, totalSize: uploadingPacketConfig.size }));

        yield put(sendDescriptorsToUI({ fileDescriptors, descriptors: filtered, processingId }));

        uploadingService.addToQueue(filtered);

        yield put(processUploadQueue());

        sendGaUploaderNew('enqueue_all', '', filtered.length);
        sendGaUploaderNew('enqueue_folders_all', '', uploadingPacketConfig.folderCount);
        sendGaUploaderNew('enqueue_files_all', '', uploadingPacketConfig.fileCount);
    } catch (error: any) {
        logger.error('handleAddToUploadingQueue EXCEPTION:', { error });

        yield put(showSnackbarAction({ text: 'Произошла ошибка', id: 'uploadError' }));
        uploadingLog.error(error);
    }
}
