import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import { TariffSpace } from 'reactApp/ui/TariffCardNew/components/TariffSpace';
import { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import styles from './DetailedTariffBenefitsHeader.css';
interface DetailedTariffBenefitsHeaderProps {
    space: number;
    units: string;
    isMonth: boolean;
}

export const DetailedTariffBenefitsHeader = memo(function DetailedTariffBenefitsPopup({
    space,
    units,
    isMonth,
}: DetailedTariffBenefitsHeaderProps): ReactElement {
    const is256gb = space === 256;
    return (
        <div className={styles.root}>
            <div
                className={classNames(styles.spaceContainer, {
                    [styles.spaceContainer_256gb]: is256gb,
                })}
            >
                <TariffSpace space={space} units={units} showSpaceWithPlus contentSize={TariffCardContentSize.compact} />
                <div
                    className={classNames(styles.spaceLabel, {
                        [styles.spaceLabel_256gb]: is256gb,
                        [styles.spaceLabel_month]: isMonth,
                    })}
                >
                    {isMonth ? 'на месяц' : 'на год'}
                </div>
            </div>
        </div>
    );
});
