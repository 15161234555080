import { createAction } from '@reduxjs/toolkit';

import type { BusinessTemplateItem, BusinessTemplatesCategory, CreateFromTemplateRejectionReason } from './businessTemplates.types';

export const businessTemplatesLoadingAction = createAction('businessTemplates/loadListPending');
export const businessTemplatesLoadedAction = createAction<BusinessTemplatesCategory[]>('businessTemplates/loadListResolved');
export const businessTemplatesLoadErrorAction = createAction('businessTemplates/loadListRejected');

export const businessTemplatesCreateFileFromTemplateAction = createAction<{
    template: BusinessTemplateItem;
    onSuccess: (resp: string) => void;
    onError: (reason: CreateFromTemplateRejectionReason) => void;
}>('businessTemplates/createFileFromTemplate');
export const businessTemplatesCreateFileFromTemplateDoneAction = createAction('businessTemplates/createFileFromTemplateDoneAction');
export const businessTemplatesCreateFileFromTemplateErrorAction = createAction('businessTemplates/createFileFromTemplateErrorAction');
