import { EActions } from 'reactApp/modules/storage/storage.types';
import type { ToolbarItem, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

import { OptionsButton } from '../OptionsButton/OptionsButton';

export const getOptionsItem = (): TToolbarItem => {
    return {
        Item: OptionsButton as ToolbarItem,
        itemProps: { id: EActions.options },
    };
};
