import { initAndLoadAmr } from 'Cloud/Application/Editor/MyOffice/amr/amr.helpers';
import AmrApi from 'Cloud/Application/Editor/MyOffice/amrApi';
import WopiApi from 'Cloud/Application/Editor/MyOffice/wopi/apiSdk';
import { initAndLoadWopi } from 'Cloud/Application/Editor/MyOffice/wopi/wopi.helpers';
import { createIframe } from 'Cloud/Application/Editor/utils/createIframe';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { myOfficeFallbackTimeoutConfig, wopiFallbackConfig, wopiJsErrorsFallbackList } from 'reactApp/appHelpers/featuresHelpers';
import { readyEditor } from 'reactApp/modules/editor/editor.module';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { getCurrentPublicItem, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getCurrentItem } from 'reactApp/modules/storage/storage.selectors';
import { store as reduxStore } from 'reactApp/store';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { sessionWatcher } from 'reactApp/ui/ReactViewer/SessionWatcher';
import { sendXray as sendXrayFunc } from 'reactApp/utils/ga';
import { generateUuidV4, roundCustom } from 'reactApp/utils/helpers';
import { isEditingStorage } from 'reactApp/utils/isEditingFile';
import { sendEditRadar, startSendSessionRadars } from 'reactApp/utils/sendEditRadar';
import { getSizeRange } from 'reactApp/utils/sizeHelper';

import { MY_OFFICE_FORMAT, MY_OFFICE_FRAME_ID, MY_OFFICE_IFRAME } from './myOffice.constants';
import {
    type IInitMyoffice,
    type IMyOfficeError,
    type IMyOfficeInit,
    type IMyOfficeReady,
    type PostMessageType,
    EMyOfficeEvent,
    ER7Event,
    EWopiExtraEventRadarType,
    isCustomErrorMessage,
    isCustomServiceErrorMessage,
    isMyOfficeEvent,
    isR7Event,
    MYOFFICE_VARIANT_NAMES,
    MYOFFICE_VARIANTS,
    myOfficeVariantsMap,
} from './myOffice.types';

let wopiSdk: WopiApi | null = null;

/**
 * В postMessage от Моего офиса приходит js-ошибка (полностью весь текст)
 * В кодах ошибок лежит часть этого текста, по которому можно идентифицировать ту или иную ошибку
 */
const ErrorsCodes = {
    network: 'Network Error',
    rowIndex: 'rowIndex',
    localStorage: 'localStorage',
    code400: 'code 400',
    code500: 'code 500',
    eUndefined: 'e is undefined',
};

const sendLoadTimeRadar = (ms: number, officeType: MYOFFICE_VARIANTS) => {
    const groupVal = roundCustom(
        Math.round(ms),
        [0, 1000, 5000, 10000, 15000, 20000, 30000, 60000],
        ['lt_1s', 'gt_1s', 'gt_5s', 'gt_10s', 'gt_15s', 'gt_20s', 'gt_30s', 'gt_1m']
    );
    sendXrayFunc(`myoffice_${officeType}_file_load_time`, {
        ms,
        [`hist_${groupVal}`]: 1,
    });
};

const handleSkipBlurDocument = (e: MouseEvent) => {
    if (e.target && !document?.getElementById('editor-container')?.contains(e.target as Node)) {
        wopiSdk?.blurAction();
    }
};

const doAmrFallback = ({
    iframeWopi,
    iframeAmr,
    isMyOfficeForMail,
    amrParams,
    listeners,
    documentMode,
    container,
    type = '',
    isEditAttaches,
    metrics,
    item,
    isEmbedded,
}: IInitMyoffice) => {
    /**
     * Удаляем iframe с wopi, так как в нем произошла ошибка
     */
    iframeWopi?.parentElement?.removeChild(iframeWopi);
    /**
     * Удаляем обработчик скипов onBlur для вопи
     */
    document.removeEventListener('click', handleSkipBlurDocument);
    /**
     * Чистим sdk ссылку
     */
    wopiSdk = null;

    if (!iframeAmr) {
        return;
    }

    const fallbackItem = amrParams?.item ?? item;

    const hasEditableWeblink =
        fallbackItem?.weblink && (fallbackItem?.weblinkAccessRights === 'rw' || fallbackItem?.weblink_access_rights === 'rw');
    const fallbackViewMode = hasEditableWeblink ? 'view' : documentMode;

    if (documentMode === 'edit' && hasEditableWeblink) {
        reduxStore.dispatch(
            showSnackbarAction({
                id: 'myoffice-edit-fallback',
                type: SnackbarTypes.failure,
                text: 'В редакторе что-то сломалось, но совсем скоро все починим!',
                buttonText: 'Обновить страницу',
                closable: true,
                disableCloseTimeout: true,
                onButtonClick: () => window.location.reload(),
            })
        );
    }

    const newIframe = createIframe({
        url: MY_OFFICE_IFRAME,
        id: iframeAmr?.id || '',
        container,
        iframe: iframeAmr,
    });

    initAndLoadAmr({
        iframe: newIframe,
        item: fallbackItem,
        listeners,
        documentMode: fallbackViewMode,
        isMyOfficeForMail,
        isEditAttaches,
        isEmbedded,
        isFallback: true,
        metrics,
    });

    newIframe.src = MY_OFFICE_IFRAME;
    amrParams?.initShowAmrIframe();

    const state = reduxStore.getState();
    const isOwner = IS_PUBLIC ? isOwnPublic(state) : !(fallbackItem?.isMounted || fallbackItem?.isThisOrParentMounted);
    const details = `${documentMode}_${IS_PUBLIC ? 'pub' : 'home'}_${isOwner ? 'owner' : 'share'}`;
    const amrMetric = {
        [type || 'unk']: 1,
        [details]: 1,
    };

    if (hasEditableWeblink) {
        amrMetric['has_ed_webl'] = 1;
    }

    xray.send('amr_fallback', { i: amrMetric });

    sendMyOfficeMetric(MyOfficeBusinessMetric.error, {
        t: [documentMode, MYOFFICE_VARIANT_NAMES.ces, metrics?.ext],
        i: { [type || '']: 1, [metrics?.size || '']: 1 },
    });
};

const needToDoFallback = (
    data: PostMessageType
): {
    doFallback: boolean;
    error?: string;
} => {
    const { enableFallback, enableFallbackServiceError, enableFallbackPostMsgError, hostReg } = wopiFallbackConfig;

    const wopiRegexp = new RegExp(hostReg);
    const isOriginOk = origin === window.location.origin || origin?.match(wopiRegexp);

    if (!isOriginOk || typeof data !== 'object') {
        return { doFallback: false };
    }

    // Фолбек на случай, если сервис Моего офиса лежит
    if (enableFallbackServiceError && isCustomServiceErrorMessage(data)) {
        return { doFallback: true, error: data.type };
    }

    // Фолбек на случай 'error' в post message от Моего офиса
    if (enableFallbackPostMsgError && isMyOfficeEvent<IMyOfficeError>(data, EMyOfficeEvent.error)) {
        xray.send('wopi_pm_error', {
            rlog: 'wopi_pm_error',
            rlog_message: data?.argumentList[1]?.value,
        });
        return { doFallback: true, error: 'wopi_pm_error' };
    }

    // Фолбек на случай js-ошибки Моего офиса
    if (enableFallback && isCustomErrorMessage(data)) {
        const criticalError = wopiJsErrorsFallbackList?.find((errorCode: string) => data.error.message.includes(ErrorsCodes[errorCode]));

        if (criticalError) {
            return { doFallback: true, error: criticalError };
        }
    }

    return { doFallback: false };
};

const listenToWopiMessages = (params: IInitMyoffice): void => {
    let isLoad = false;
    let fallbackWorked = false;
    /**
     * На все клики ВНЕ области документа, вызываем blurAction
     * Чтобы МойОфис не перехватывал фокус
     */
    document.addEventListener?.('click', handleSkipBlurDocument);

    const fileExtension = params?.item?.ext || 'default';
    const wopiCustomTimeout = myOfficeFallbackTimeoutConfig.override[fileExtension] || myOfficeFallbackTimeoutConfig.default;

    setTimeout(() => {
        // Фолбек на МойОфис (SEK), если МойОфис (CES) не загрузился за заданное в конфигурации myOfficeFallbackTimeoutConfig время
        if (wopiFallbackConfig?.enableTimeoutFallback && !isLoad && !fallbackWorked) {
            doAmrFallback({
                ...params,
                type: 'timeout',
            });
            fallbackWorked = true;
        }
    }, wopiCustomTimeout * 1000);

    window.addEventListener?.('message', ({ data, origin }: MessageEvent<PostMessageType>) => {
        if (!origin || !wopiFallbackConfig?.hostReg) {
            return;
        }

        const { doFallback, error } = needToDoFallback(data);

        if (doFallback && !fallbackWorked) {
            doAmrFallback({ type: error, ...params });
            fallbackWorked = true;
        } else if (isMyOfficeEvent(data)) {
            const {
                listeners: { onReady, onError },
            } = params;
            let extraEventType: EWopiExtraEventRadarType | null = null;
            const eventType = data.argumentList[0].value;
            const eventData = data.argumentList[1]?.value;
            const { item, isEditAttaches } = params;

            /**
             * @interface IMyOfficeReady
             * Смотреть интерфейс события ready, там может на ready возникнуть ошибка
             */
            if (isMyOfficeEvent<IMyOfficeReady>(data, EMyOfficeEvent.ready)) {
                const eventArguments = data.argumentList[1]?.value;

                isLoad = true;
                if (params.iframeWopi?.contentWindow) {
                    /**
                     * FIXME origin = *, шлем посты всем окнам. В теории вместо * надо указывать хост Моего Офиса
                     * Но пока в настройке у нас только регулярка хоста а не строка
                     *
                     * Поправить при рефакторинге CLOUDWEB-14111. Там в стейте должен быть адрес
                     * @link https://jira.vk.team/browse/CLOUDWEB-14111
                     */
                    wopiSdk = new WopiApi(params.iframeWopi.contentWindow, '*');
                }

                if (eventArguments.isError) {
                    extraEventType = EWopiExtraEventRadarType.error;
                    onError?.();
                } else {
                    onReady?.(MYOFFICE_VARIANTS.wopi, params.item);
                }
                /**
                 * Если на паблике открывается в режиме редактирования, то значит это совместное редактирование
                 */
                if (!eventArguments.readonly && IS_PUBLIC && item && !item?.isFolder && item?.weblinkAccessRights === 'rw') {
                    xray.send('editor', {
                        i: 'common-edit',
                        dwh: {
                            type_content: item?.subKind,
                            source: 'public',
                            size_file: item?.size,
                            id_media: item?.id,
                            extension: item?.ext,
                            version: 'wopi',
                        },
                    });
                }
            }

            const state = reduxStore.getState();
            const storage = getCurrentStorage(state);
            if (item && isMyOfficeEvent<IMyOfficeInit>(data, EMyOfficeEvent.init) && isEditingStorage(storage)) {
                sendEditRadar({
                    item,
                    storage,
                    i: 'open',
                    version: MYOFFICE_VARIANTS.wopi,
                    isAttach: isEditAttaches,
                });

                const sessionId = generateUuidV4();
                startSendSessionRadars({
                    item,
                    storage,
                    sessionId,
                    version: MYOFFICE_VARIANTS.wopi,
                    isAttach: isEditAttaches,
                });
            }

            xray.send(`wopi_2_2_post_msg`, {
                i: `${eventType}${extraEventType ? `-${extraEventType}` : ''}`,
                rlog: 'cloud_wopi_postmsgs',
                rlog_message: eventData,
            });
        } else if (isR7Event(data, ER7Event.App_LoadingStatus)) {
            const {
                listeners: { onReady },
            } = params;
            onReady?.(MYOFFICE_VARIANTS.wopi, params.item);
        }
    });
};

export const initAndLoadMyOffice = ({
    container,
    iframeAmr,
    iframeWopi,
    isMyOfficeForMail,
    isEmbedded,
    sharingAttach,
    wopiParams,
    amrParams,
    myOfficeVariant,
    listeners,
    documentMode = 'view',
    isEditAttaches,
    item,
    forceAmrInit = false,
}: IInitMyoffice): undefined | (() => Promise<void>) | (() => void) => {
    const isWopiEnabled = myOfficeVariant === MYOFFICE_VARIANTS.wopi && !forceAmrInit;
    const iframeId = (isWopiEnabled && !forceAmrInit ? iframeWopi?.id : iframeAmr?.id) || MY_OFFICE_FRAME_ID;
    const inputFrame = isWopiEnabled ? iframeWopi : iframeAmr;
    // Для пабликов item = undefined, убрать после переноса их в реакт
    const state = reduxStore.getState();
    const file = item ?? ((IS_PUBLIC ? getCurrentPublicItem(state) : getCurrentItem(state)) as EditorItem);

    if (!inputFrame) {
        return;
    }

    const iframe = createIframe({
        url: isWopiEnabled ? '' : MY_OFFICE_IFRAME,
        id: iframeId,
        container,
        iframe: inputFrame,
        extraData: {
            'wopi-enabled': String(isWopiEnabled),
        },
        item: amrParams?.item,
    });

    const metrics = {
        size: item?.size ? getSizeRange(item?.size) : '',
        ext: item?.ext ?? '',
    };

    const initTimeMark = performance.now();
    const enhancedListeners = {
        ...listeners,
        onReady: (officeType: MYOFFICE_VARIANTS, item?: EditorItem, isFallback = false) => {
            const time = performance.now() - initTimeMark;

            sendLoadTimeRadar(time, officeType);
            sendMyOfficeMetric(MyOfficeBusinessMetric.loaded, {
                t: [documentMode, myOfficeVariantsMap[officeType], isFallback ? 'fb' : '', metrics.ext],
                i: { time: Math.ceil(time), [metrics.size]: 1 },
            });
            listeners.onReady?.(officeType);
            reduxStore.dispatch(readyEditor());
        },
    };

    iframe.addEventListener?.('load', () => {
        logger.warn(`МойОфис. ${isWopiEnabled ? 'Серверная версия (WOPI)' : 'Клиентская версия (АМР)'}`);
    });

    const isWopiDataOk = wopiParams?.url && wopiParams?.accessParams?.access_token_ttl && wopiParams?.accessParams?.access_token;

    sendMyOfficeMetric(MyOfficeBusinessMetric.start, {
        t: [documentMode, (!!myOfficeVariant && myOfficeVariantsMap[myOfficeVariant]) || MYOFFICE_VARIANT_NAMES.sek, metrics.ext],
        i: { [metrics.size]: 1 },
    });

    sessionWatcher.startSession({ key: item?.id || '', data: { documentMode, ext: metrics.ext } });

    if (isWopiEnabled && isWopiDataOk && !forceAmrInit) {
        listenToWopiMessages({
            iframeWopi,
            iframeAmr,
            amrParams,
            listeners: enhancedListeners,
            documentMode,
            isMyOfficeForMail,
            container,
            isEditAttaches,
            item: file,
            metrics,
            isEmbedded,
        });

        return initAndLoadWopi({
            container,
            iframeWopi: iframe,
            iframeId,
            listeners: enhancedListeners,
            isMyOfficeForMail,
            wopiParams,
            isEditAttaches,
            metrics,
            documentMode,
            item: file,
        });
    }

    return initAndLoadAmr({
        iframe,
        item: amrParams?.item ?? file,
        listeners: enhancedListeners,
        documentMode,
        isMyOfficeForMail,
        isEditAttaches,
        isEmbedded,
        sharingAttach,
        metrics,
    });
};

export async function actionDownloadDocument({ filename, format, type }: { filename: string; format: string; type: string }) {
    if (!window?.myofficeApi) {
        return;
    }

    logger.info('Сохранение', { format, filename, type });
    const content = await window.myofficeApi.saveDocument(format);
    const blob = new Blob([content]);
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        let name = filename;
        const currentExt = name.slice(name.lastIndexOf('.') + 1);
        const ext = AmrApi.getDocumentExtension(type, format, currentExt);
        const url = URL.createObjectURL(blob);

        if (ext && currentExt !== ext) {
            name = `${name.slice(0, Math.max(0, name.lastIndexOf('.')))}.${ext}`;
        }

        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(url));
    }
}

export const getDownloadDropdownList = (filename: string, ext: string) => {
    const options = AmrApi.getOptions(true).EXTENSION_TO_TYPE?.[ext.toLowerCase()];
    const format = options?.format;
    const type = options?.type;
    const upperCaseExt = ext.toUpperCase();

    const onDropdownDownload = (format: string) => {
        actionDownloadDocument({ filename, format, type });
    };

    const oxmlText = format === MY_OFFICE_FORMAT.oxml ? upperCaseExt : MY_OFFICE_FORMAT.oxml;
    const odfTextMap = {
        document: format === MY_OFFICE_FORMAT.odt ? upperCaseExt : MY_OFFICE_FORMAT.odt,
        spreadsheet: format === MY_OFFICE_FORMAT.ods ? upperCaseExt : MY_OFFICE_FORMAT.ods,
        default: MY_OFFICE_FORMAT.odf,
    } as const;

    return [
        { id: MY_OFFICE_FORMAT.oxml, text: oxmlText, onClick: onDropdownDownload },
        { id: MY_OFFICE_FORMAT.odf, text: odfTextMap[type || 'default'], onClick: onDropdownDownload },
        { id: MY_OFFICE_FORMAT.pdf, text: MY_OFFICE_FORMAT.pdf, onClick: onDropdownDownload },
        { id: MY_OFFICE_FORMAT.pdfa, text: 'PDF/A', onClick: onDropdownDownload },
    ];
};
