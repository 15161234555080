import React, { memo } from 'react';
import type { EStoryType } from 'reactApp/modules/stories/stories.types';
import { StoryType } from 'reactApp/ui/ReactViewer/StoryType/StoryType';

import styles from './BottomToolbar.css';

interface Props {
    type?: EStoryType;
    title?: string;
    subTitle?: string;
    onDetailsClick?();
}

export const BottomToolbar = memo(function Stub({ title, subTitle, type }: Props): JSX.Element | null {
    if (!(type || title || subTitle)) {
        return null;
    }

    return (
        <div className={styles.root}>
            <StoryType type={type} />
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>

            {/* TODO: uncomment when popup stories will be ready <div className={styles.detailsButton} onClick={onDetailsClick}>*/}
            {/*    <div className={styles.detailsIcon}>*/}
            {/*        <MoreIcon width={13} height={5} />*/}
            {/*    </div>*/}
            {/*    <div className={styles.button}>Подробнее</div>*/}
            {/* </div>*/}
        </div>
    );
});
