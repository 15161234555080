import { createAction } from '@reduxjs/toolkit';
import type { ApiFolderResponse, APILoadFolderFail, HomeFile, MediaStatistics } from 'reactApp/modules/home/home.types';
import type { IHistoryPush } from 'reactApp/modules/router/router.types';

export const loadHomeFolderRequest = createAction<{ id: string; isFolder?: boolean; force?: boolean }>('home/loadHomeFolderRequest');
export const loadFolderSuccess = createAction<ApiFolderResponse, 'home/LoadFolderSuccess'>('home/LoadFolderSuccess');
export const loadFolderStart = createAction<{ id: string }>('home/loadFolderStart');
export const loadFolderFail = createAction<APILoadFolderFail, 'home/loadFolderFail'>('home/loadFolderFail');
export const loadMoreHomeRequest = createAction<{ offset: number; id: string }>('home/loadMoreHomeRequest');
export const loadMoreSuccess = createAction<ApiFolderResponse>('home/loadMoreSuccess');
export const homeStatisticsSuccess = createAction<MediaStatistics>('home/homeStatisticsSuccess');
export const cameraUploadsStatisticsSuccess = createAction<MediaStatistics>('home/cameraUploadsStatisticsSuccess');
export const changeHomeHistory = createAction<IHistoryPush>('home/changeHistory');
export const setDomainFoldersFilterActive = createAction<boolean>('home/setDomainFoldersFilterActive');
export const addHomeItemsToStore = createAction<HomeFile[]>('home/addHomeItemsToStore');
