import { xray } from 'lib/xray';
import { CreateFamilyAPICall } from 'reactApp/api/family/CreateFamilyApiCall';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { type createFamily, familyUpdate, loadingInviteLink, loadInviteLinkFailure } from 'reactApp/modules/family/family.actions';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { openFamilyErrorModal } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.helpers';
import { FamilyCreateError, FamilyError } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.types';
import { openFamilyInviteModal } from 'reactApp/ui/Family/FamilyInviteModal/FamilyInviteModal.helpers';
import { put } from 'typed-redux-saga';

const createFamilyAPICall = () => new CreateFamilyAPICall().makeRequest();

export function* createFamilyRequest(action?: ReturnType<typeof createFamily>) {
    const { isRebrandingQuotaLanding = false } = action?.payload || {};

    const isMobile = EnvironmentSelectors.isMobile();

    try {
        yield put(loadingInviteLink());
        yield closePopupHelper(popupNames.SLIDER_MODAL);
        yield openFamilyInviteModal({ isRebrandingQuotaLanding });
        const { data } = yield createFamilyAPICall();
        yield put(familyUpdate(data));
        xray.send('family_create_success');
    } catch (error: any) {
        yield put(loadInviteLinkFailure());

        const { status, response } = error;
        const xrayParts = ['family', 'create', 'failure'];

        if (status === 403 && response?.error === FamilyCreateError.tooManyFamilies) {
            yield closePopupHelper(popupNames.FAMILY_INVITE_MODAL);
            yield openFamilyErrorModal({ errorType: FamilyError.tooManyFamilies, isMobile, isRebrandingQuotaLanding });
            xrayParts.push(FamilyError.tooManyFamilies);
        }

        xray.send(xrayParts);
        loggerSaga({ error });
    }
}
