import classNames from 'clsx';
import React, { type FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SASS_TARIFF_QUERY } from 'reactApp/sections/SassLanding/SassLanding.data';
import { SassTariffCard } from 'reactApp/sections/SassLanding/TariffPlans/SassTariffCard';
import styles from 'reactApp/sections/SassLanding/TariffPlans/SassTariffPlansCard.css';
import { ETariffCardWidth } from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useFeatures } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import { useMonthSaving } from 'reactApp/ui/TariffSectionNew/hooks/useMonthSaving';
import { useYearSaving } from 'reactApp/ui/TariffSectionNew/hooks/useYearSaving';
import type { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import type { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { noopVoid } from 'reactApp/utils/helpers';
import { getPeriodName, getTypeOfPeriodName, isMonthPeriod, periodToDaysPeriod } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

type TariffPlanCardProps = {
    tariff: ITariffsByTab;
    activeTab: ETabsName;
    size?: 'sm' | 'm' | 'lg';
};

export const SassTariffPlansCard: FC<TariffPlanCardProps> = ({ activeTab, tariff, size }) => {
    const { tariffId, products, isCheapest, isRecommended } = tariff;
    const [__, yearSavingDiscount] = useYearSaving(products);
    const [_, monthSavingDiscount] = useMonthSaving(products.monthProduct);

    const product = products[`${activeTab}Product`];
    const { hasTrial, trialPeriod, discountPeriod, discountPrice, period, price, available } = product;
    const monthDiscountPercent = isMonthPeriod(product.period) ? monthSavingDiscount : 0;

    const mainBtnText = `${getPeriodName(periodToDaysPeriod(trialPeriod || discountPeriod || ''))} ${
        hasTrial ? 'бесплатно' : ` за ${formatPrice(discountPrice || price)} ₽`
    }`;
    const finishPrice = hasTrial && discountPrice ? discountPrice : price;
    const buttonAdditionalText = `далее ${formatPrice(finishPrice)} ₽ в ${getTypeOfPeriodName(period)}`;
    const [featureList] = useFeatures(product);

    const { space, units } = isCheapest ? tariff.space : product.space;

    const [___, setSearchParams] = useSearchParams();

    const handleBtnClick = useCallback(() => {
        setSearchParams({
            [SASS_TARIFF_QUERY.plan]: product.id,
            [SASS_TARIFF_QUERY.card]: 'tariffCard',
        });
    }, [product.id]);
    return (
        <div
            className={classNames(styles.tariffCard, {
                [styles.medium]: size === 'm',
            })}
        >
            <SassTariffCard
                onClick={noopVoid}
                tariffId={tariffId}
                width={ETariffCardWidth.fluid}
                focused={isRecommended}
                buttonPrimary={isRecommended}
                buttonDisabled={!available}
                onClickButton={handleBtnClick}
                disable={!available}
                space={{
                    space,
                    units,
                }}
                featureListOptions={featureList}
                {...{
                    buttonText: <div>{mainBtnText}</div>,
                    buttonAdditionalText,
                    savingInButton: isMonthPeriod(product.period) ? monthDiscountPercent : yearSavingDiscount,
                }}
                size={size}
            />
        </div>
    );
};

SassTariffPlansCard.displayName = 'SassTariffPlansCard';
