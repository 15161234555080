import { fixAttachUrl } from 'reactApp/modules/attaches/attaches.helpers';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getThumbnailImageUrl, tempFixForThumbUrls } from 'reactApp/modules/urlBuilder/thumbsUrlBuilder';

export type IThumb = {
    src?: string;
    srcSet?: string;
};

const getmtimeString = (thumbUrl: string, mtime?: string): string | undefined => {
    return mtime ? `${thumbUrl}${thumbUrl.includes('?') ? '&' : '?'}mt=${mtime}` : thumbUrl;
};

export const getThumbUrl = (item, isVirus, view = 'thumbs', thumbType?: string): IThumb | undefined => {
    if (!item || item.isFolder || !item.thumbnails || isVirus) {
        return;
    }

    if (item.storage === EStorageType.attaches && item.thumbnails[item.type || 'image']) {
        const attachmentItem: AttachesItem = item;
        const itemType = attachmentItem.type || 'image';

        const src = fixAttachUrl(
            tempFixForThumbUrls(attachmentItem, getThumbnailImageUrl(attachmentItem.thumbnails[itemType], 206, 206)) ||
                attachmentItem.thumbnails[itemType].default
        );

        return { src: getmtimeString(src, item.mtime) };
    }

    const sizes = {
        list: {
            src: '36x48',
            srcSet: '72x96',
        },
        thumbs: {
            src: '168x152',
            srcSet: '336x304',
        },
        gallerySmall: {
            src: '270x365',
            srcSet: '540x730',
        },
        galleryBig: {
            src: '1200x1200',
            srcSet: '1200x1200',
        },
        cleanerPreview: {
            src: '52x52',
            scrSet: '52x52',
        },
        cleanerTablet: {
            src: '320x240',
            srcSet: '320x240',
        },
        squares: {
            src: '270x365',
            srcSet: '540x730',
        },
        squares180: {
            src: '270x365',
            srcSet: '540x730',
        },
    };

    const thumbSizes = (thumbType && sizes[thumbType]) || sizes[view];

    return Object.keys(thumbSizes).reduce((result, key) => {
        const itemThumb = item.thumbnails[thumbSizes[key]];

        if (itemThumb) {
            result[key] = getmtimeString(itemThumb, item.mtime);
        }

        return result;
    }, {});
};
