import { useSelector } from 'react-redux';
import { getPromoById } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { getBasicSubscription, getSubscriptionsById } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import type { RootState } from 'reactApp/store';

export const useSubscription = ({ id }: { id: string }) => {
    const basicSubscription = useSelector(getBasicSubscription);
    const subscription = useSelector((state: RootState) => getSubscriptionsById(state, id));
    // @ts-ignore
    const promoSubscriptions = useSelector((state: RootState) => getPromoById(state, id));

    if (id === 'base') {
        return basicSubscription;
    }

    return subscription || promoSubscriptions;
};
