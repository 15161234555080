import { useEffect } from 'react';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { sendDwh } from 'reactApp/utils/ga';

import type { Subscription } from '../../SubscriptionsModal/SubscriptionsModal.types';
import { isBaseSubscription } from '../SubscriptionInfoModal.helpers';

export const useSendAnalyticsAfterOpen = (subscription: Subscription) => {
    // TODO: заменить на `useFirstEffect`
    // Возможно, стоит переименовать `useFirstEffect` в `useEffectOnce`
    useEffect(() => {
        const dwhDate = isBaseSubscription(subscription)
            ? { productId: 'default' }
            : {
                  productId: subscription.productId || subscription.id,
                  start_date: subscription.start,
                  expired_date: subscription.expires,
                  partner_name: subscription.partner,
              };

        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_OPEN,
            dwhData: {
                ...dwhDate,
                source: 'touch',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
