import { extInfo } from 'lib/extInfo';
import { type Dispatch, type MutableRefObject, type RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import { ENABLE_FULL_RESPONSIVE, IS_MOBILE_BROWSER, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { isBBAQualitySelectionAlgorithm, isOverrideNativeHLS } from 'reactApp/appHelpers/featuresHelpers';
import { watchMedia } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { getCurrentPublicId } from 'reactApp/modules/faces/faces.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { Kind } from 'reactApp/types/Tree';
import { usePlayerO2Header } from 'reactApp/ui/ReactViewer/VideoPlayer/usePlayerO2Header';
import { sendXray } from 'reactApp/utils/ga';
import videojs from 'video.js';

import { adInit } from './AD';
import { addCustomNavigation } from './CustomNavigation';
import { bufferBasedSelectPlaylist } from './qualitySelector';
import type { VideoPlayerOptions, VideoPlayerWithPlugins } from './VideoPlayer.types';
import { type GaData, VideoPlayerGa } from './VideoPlayerGa';

interface Options {
    streamUrl?: string;
    posterUrl: string;
    kind: Kind;
    autoPlay: boolean;
    nativeUrl: string;
    size: number | undefined;
    isArchive: boolean;
    isAttaches: boolean;
    isVideo: boolean;
    isPhone: boolean;
    onError: ((msg?: string | undefined) => void) | undefined;
    name: string;
    ext: string;
    isMediaQueryHit: boolean;
    setAdm: Dispatch<any>;
    mediaBreakpoint: number;
}

export const errorsMap = {
    'You aborted the media playback': 'Вы прервали воспроизведение мультимедиа',
    'A network error caused the media download to fail part-way.': 'Ошибка сети привела к частичному сбою загрузки мультимедиа.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
        'Воспроизведение мультимедиа было прервано из-за проблемы с повреждением или из-за того, что мультимедиа использовало функции, которые не поддерживал ваш браузер.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        'Не удалось загрузить файл из-за отказа сервера или сети или из-за того, что формат не поддерживается.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Носитель зашифрован, и у нас нет ключей для его расшифровки.',
    'No compatible source was found for this media.': 'Ошибка воспроизведения. Неизвестный кодек видео.',
};

const errorsMapByCode = {
    1: 'Вы прервали воспроизведение мультимедиа',
    2: 'Ошибка сети привела к частичному сбою загрузки мультимедиа.',
    3: 'Воспроизведение мультимедиа было прервано из-за проблемы с повреждением или из-за того, что мультимедиа использовало функции, которые не поддерживал ваш браузер.',
    4: 'Не удалось загрузить файл из-за отказа сервера или сети или из-за того, что формат не поддерживается.',
    5: 'Носитель зашифрован, и у нас нет ключей для его расшифровки.',
    6: 'Ошибка воспроизведения. Неизвестный кодек видео.',
};

const getControlbarOption = (isVideo: boolean, isPhone: boolean): videojs.ControlBarOptions => ({
    currentTimeDisplay: true,
    timeDivider: true,
    // Ломает перемещение трека ProgressControl вслед за курсором при перетасковании
    // прячем через CSS
    // remainingTimeDisplay: false,
    fullscreenToggle: isVideo,
    pictureInPictureToggle: false,
    volumePanel: !isPhone,
    playToggle: !isPhone,
});

const getPlayerOptions = (
    isVideo: boolean,
    autoPlay: boolean,
    controlBarSettings: videojs.ControlBarOptions,
    overrideNative: boolean
): VideoPlayerOptions => ({
    language: 'ru',
    bigPlayButton: false,
    controls: true,
    playbackRates: isVideo ? [0.5, 1, 1.25, 1.5, 2] : undefined,
    autoplay: autoPlay,
    controlBar: controlBarSettings,
    fill: true,
    html5: {
        vhs: {
            overrideNative: isOverrideNativeHLS || overrideNative || !videojs.browser.IS_SAFARI,
            withCredentials: true,
        },
        nativeAudioTracks: !isOverrideNativeHLS && videojs.browser.IS_SAFARI,
        nativeVideoTracks: !isOverrideNativeHLS && videojs.browser.IS_SAFARI,
    },
});

const handleError = (
    player: VideoPlayerWithPlugins,
    onError: ((msg?: string | undefined) => void) | undefined,
    error: { code?: number; message?: string } | null
) => {
    const { code = 0, message = 'Что-то пошло не так' } = error || {};

    if (!player?.error()) {
        onError?.(errorsMap[message] || errorsMapByCode[code] || message);
        setTimeout(() => {
            player?.error(errorsMap[message] || errorsMapByCode[code] || message);
            player?.removeClass('vjs-waiting');
        }, 0);
    }
};

export const updateControlBarButtons = (player: VideoPlayerWithPlugins | undefined, isHit: boolean) => {
    if (!ENABLE_FULL_RESPONSIVE) {
        return;
    }

    player?.ready(() => {
        const controlBar = player?.getChild('ControlBar');
        if (controlBar) {
            ['TimeDivider', 'DurationDisplay', 'LoopButton', 'PlaybackRateMenuButton', 'QualityButton'].forEach((name) => {
                const child = controlBar.getChild(name);
                if (isHit) {
                    child?.removeAttribute('style');
                } else {
                    child?.setAttribute('style', 'display:none;');
                }
            });
        }
    });
};

const initPlayerGA = (storage: EStorageType, publicId: string, player: VideoPlayerWithPlugins, options: Options) => {
    const { kind, isArchive, isAttaches, size, name, ext } = options;

    const gaData: GaData = {
        source: IS_PUBLIC_ALBUM ? 'album' : storage,
        type_content: kind,
        is_stories: false,
        have_face: false,
        id_public: publicId,
        extension: ext?.toLowerCase(),
        is_archive: isArchive,
        is_attache: isAttaches,
        size_files: size,
        name,
        is_touch: IS_MOBILE_BROWSER,
    };

    const ga = new VideoPlayerGa(player, gaData);
    ga.subscribe();

    return { ga, gaData };
};

export const useVideoPlayer = (
    videoRef: RefObject<HTMLVideoElement>,
    playerRef: MutableRefObject<VideoPlayerWithPlugins | undefined>,
    options: Options
) => {
    const storage = useSelector(getCurrentStorage);
    const videoAdEnabled = useSelector((state) => ViewerSelectors.isVideoAdEnabled(state, storage));
    const publicId = useSelector(getCurrentPublicId);

    usePlayerO2Header();

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        const { isVideo, isPhone, autoPlay, ext, streamUrl, posterUrl, nativeUrl, onError, setAdm, mediaBreakpoint } = options;

        const controlBarSettings = getControlbarOption(isVideo, isPhone);
        const player = videojs(
            videoRef.current,
            getPlayerOptions(isVideo, autoPlay, controlBarSettings, isUseO2Token())
        ) as VideoPlayerWithPlugins;

        playerRef.current = player;

        const gaPlayerInstance = initPlayerGA(storage, publicId, player, options);

        // disable SwipingComponent logic for controlBar
        player.controlBar.el().addEventListener('pointerdown', (event) => event.stopPropagation());

        const info = extInfo.get(ext);

        if (streamUrl && streamUrl.includes('.m3u8') && info.isStreamingSupported) {
            player.src({
                src: streamUrl,
                type: extInfo.get('m3u8').mimeType,
            });
        } else if (info.isNativeSupported) {
            player.src({
                src: nativeUrl,
                type: info.mimeType,
            });
        } else {
            sendXray(['video-player', 'error', 'not-supported']);
            handleError(player, onError, { message: 'Данный формат не потдерживается' });
        }

        player.load();
        player.poster(posterUrl);

        if (streamUrl && info.isStreamingSupported && info.isNativeSupported) {
            // fallback logic
            let streamUrlError = false;
            player.on('error', () => {
                const error = player.error();

                if (error && [4].includes(error.code) && !streamUrlError) {
                    streamUrlError = true;
                    player.error(null);
                    player.src({
                        src: nativeUrl,
                        type: info.mimeType,
                    });

                    return;
                }

                // Чистим ошибку чтобы выбросить кастомную
                if (error?.code) {
                    player.error(null);
                }
                handleError(player, onError, error);
            });
        } else {
            player.on('error', () => {
                const error = player.error();

                // Чистим ошибку чтобы выбросить кастомную
                if (error?.code) {
                    player.error(null);
                }
                handleError(player, onError, error);
            });
        }

        player.ready(() => {
            addCustomNavigation({ player, isPhone, isVideo });

            if (isBBAQualitySelectionAlgorithm) {
                const lastBandwidthPlaylistSelector = player.tech(true).vhs.selectPlaylist;
                player.tech(true).vhs.selectPlaylist = bufferBasedSelectPlaylist(lastBandwidthPlaylistSelector);
            }

            player.loopPlugin();

            if (isVideo) {
                player.hlsQualitySelector({
                    displayCurrentQuality: true,
                });

                // отключаем фиксацию меню по клику
                setTimeout(() => {
                    const qualitySelector = playerRef.current?.$('.vjs-quality-selector');
                    qualitySelector?.addEventListener('mouseleave', () => {
                        (player.$('.vjs-quality-selector .vjs-menu-item') as HTMLElement)?.blur();
                    });
                }, 0);
            }

            const stopWatchMedia = watchMedia(mediaBreakpoint, (hit) => updateControlBarButtons(playerRef.current, hit));
            stopWatchMedia();
        });

        if (isVideo && videoAdEnabled) {
            const initAd = () => {
                const { adm } = adInit({
                    player,
                    controlBarSettings,
                    isPhone,
                    isVideo,
                    gaData: gaPlayerInstance.gaData,
                });
                setAdm(adm);
                player?.off('playing', initAd);

                document.addEventListener('visibilitychange', () => {
                    if (document.visibilityState !== 'visible') {
                        adm.pause();
                    } else {
                        adm.play();
                    }
                });
            };

            player?.on('playing', initAd);
        }

        return () => {
            // dispose также удаляет из ДОМ <video, что может быть проблемой при повторном использовании
            // https://github.com/videojs/video.js/issues/4970
            player.dispose();
            gaPlayerInstance.ga.unsubscribe();
        };
    }, []);
};
