import { ReactComponent as IconClose } from 'mrg-icons/src/mailru/actions/close_big.svg';
import React, { type ReactElement, useCallback, useState } from 'react';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { sendGa } from 'reactApp/utils/ga';

import styles from './MobilePublicPromoBanner.css';

interface Props {
    bannerData: {
        banner_id: string;
        id: string;
        background: string;
        url: string;
        closeColor: string;
        close_pixel: string;
        show_pixel: string;
        click_pixel: string;
        closePixelOnClick: boolean;
    };
    onShow(): void;
    onClose(): void;
}

const sendAnalytics = (action, label): void => sendGa('mPublicBanner', action, label);

export const MobilePublicPromoBanner = ({ bannerData, onShow, onClose }: Props): ReactElement | null => {
    const [show, setShow] = useState(true);

    const handleOnShow = useCallback(() => {
        onShow();

        if (bannerData) {
            sendAnalytics('show', bannerData.id);
            new Image().src = bannerData.show_pixel;
        }
    }, []);

    const handleOnClose = useCallback(() => {
        setShow(false);
        onClose();

        if (bannerData) {
            sendAnalytics('close', bannerData.id);
            new Image().src = bannerData.close_pixel;
        }
    }, []);

    const onBannerClick = useCallback(() => {
        setShow(false);

        if (bannerData) {
            sendAnalytics('click', bannerData.id);

            new Image().src = bannerData.click_pixel;

            if (bannerData.closePixelOnClick) {
                new Image().src = bannerData.close_pixel;
            }
        }
    }, []);

    if (!bannerData || !show) {
        return null;
    }

    return (
        <Dialog open className={styles.root} showCloseIcon={false} onShow={handleOnShow} onCancel={handleOnClose}>
            <div className={styles.close} onClick={onClose}>
                <IconClose fill={bannerData.closeColor} />
            </div>
            <a className={styles.link} href={bannerData.url} target="_blank" rel="noopener noreferrer" onClick={onBannerClick}>
                <img src={bannerData.background} className={styles.background} alt="" />
            </a>
        </Dialog>
    );
};
