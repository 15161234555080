import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import { ReactComponent as WebTariffIcon } from 'img/icons/WebCloudPlans.svg';
import { ReactComponent as WebTariffIconNG } from 'img/icons/WebCloudPlansNG.svg';
import { ReactComponent as WebTariffIconSmall } from 'img/icons/WebTariffSmall.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

import styles from './TariffIcon.css';

const isIE = EnvironmentSelectors.isIE();

// Для будущих тарифов/продуктов со спец иконками

export class TariffIcon extends PureComponent {
    static propTypes = {
        size: PropTypes.oneOf([25, 34, 40, 50, 63, 65, 74, 83, 98, 135, 160, 172, 221, 'fullWidth']),
        withSpace: PropTypes.bool,
        space: PropTypes.number,
        modificator: PropTypes.string,
        className: PropTypes.string,
        showGiftsIcons: PropTypes.bool,
    };

    static defaultProps = {
        size: 25,
        withSpace: false,
        space: 128 * 1024 ** 3,
        modificator: '',
        className: '',
        showGiftsIcons: false,
    };

    render() {
        const {
            size,
            withSpace,
            space,
            showGiftsIcons,
            modificator,
        } = this.props;
        const spaceDesc = bytesToNDigits(space, 3);
        const iconSpace = space / 1024 ** 3;

        let sizeMod = size;

        let icon;

        switch (modificator) {
            case 'promo':
                icon = <WebTariffIconNG />;
                break;
            case 'small':
                icon = <WebTariffIconSmall />;
                sizeMod = isIE ? 221 : size;
                break;
            default:
                icon = <WebTariffIcon />;
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_gift]: showGiftsIcons,
                    [styles.root_hdd]: true,
                    [styles[`root_hdd-${iconSpace}`]]: true,
                    [styles[`root_${modificator}`]]: !!modificator,
                    [styles[`root_size_${sizeMod}`]]: !!sizeMod,
                    [this.props.className]: !!this.props.className,
                })}
            >
                {icon}
                {!!withSpace && (
                    <div className={styles.space}>
                        {spaceDesc.space}
                        <div>{spaceDesc.units}</div>
                    </div>
                )}
            </div>
        );
    }
}
