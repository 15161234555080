import { Spacing, Text, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/pause-subscription-success.jpg';
import coverImageMobile from 'img/subscriptions/pause-subscription-success-mobile.jpg';
import coverImageMobileDark from 'img/subscriptions/pause-subscription-success-mobile-dark.jpg';
import coverImageRebranding from 'img/subscriptions/pause-subscription-success-rebranding.jpg';
import React, { type ReactElement, memo, useEffect, useMemo, useRef } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { useSubscription } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/useSubscription';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { noopVoid } from 'reactApp/utils/helpers';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './PauseSubscriptionSuccessDialog.css';
import type { Props } from './PauseSubscriptionSuccessDialog.types';

export const PauseSubscriptionSuccessDialog = memo<Props>(({ subscriptionId, onClose = noopVoid }): ReactElement => {
    const isMobile = EnvironmentSelectors.isPhone();
    const scrollRef = useRef<HTMLDivElement>(null);

    const subscription = useSubscription({ id: subscriptionId });
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showPauseSuccessModal);
    }, [sendAnalytics]);

    const text = useMemo(
        () => (
            <>
                До <span className={styles.dateText}>{formatFileHistoryDate(subscription.renewAt)}</span> деньги списываться не будут
                &mdash; а{'\u00A0'}подписка продолжит действовать: пользуйтесь без ограничений
            </>
        ),
        [subscription.renewAt]
    );

    const content = useMemo(
        (): ReactElement => (
            <div>
                <Spacing size={12} />
                <Text className={styles.text} weight="3">
                    {text}
                </Text>
                <Spacing size={24} />
            </div>
        ),
        [text]
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    if (isMobile) {
        return (
            <MobileDialog onClose={onClose} closeOnDimmerClick scrollRef={scrollRef} mod="base" contextPadding="zero">
                <div ref={scrollRef} className={styles.rootMobile}>
                    <img
                        className={styles.image}
                        src={containsDarkTheme ? coverImageMobileDark : coverImageMobile}
                        alt="Оплата подписки на паузе"
                    />

                    <div className={styles.content}>
                        <Title>Оплата подписки на паузе</Title>
                        <Spacing size={8} />
                        <Text>{text}</Text>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary fluid onClick={onClose}>
                            Хорошо
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            dialogSize="average"
            closeByPrimaryButton
            onClick={onClose}
            onClose={onClose}
            primaryButtonText="Хорошо"
            title="Оплата подписки на паузе"
            content={content}
            imageUrl={isRebranding ? coverImageRebranding : coverImage}
            buttonTheme="vk"
        />
    );
});

PauseSubscriptionSuccessDialog.displayName = 'PauseSubscriptionSuccessDialog';
