import { EditorID } from 'Cloud/Application/Editor/types';
import { extInfo } from 'lib/extInfo';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { isR7WopiCoEdit } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureShareMountEditAccessDisable, getFeatureWopiCoEdit } from 'reactApp/modules/features/features.selectors';
import { isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';
import { calculateMaxEditableOrPreviewableSizeForEditor } from 'server/helpers/editors/calculateMaxEditableOrPreviewableSizeForEditor';
import { sortEditors } from 'server/helpers/editors/sortEditors';

import { getAvailableEditorsForItem, isValidEditableItem } from './editor.helpers';
import type { CreatorsDescRecord, EditorState } from './editor.types';

export const getEditorState = (state: RootState): EditorState => state.editor;

const CO_EDITORS = [EditorID.MYOFFICE, EditorID.R7, EditorID.R7_WOPI];

export const getEditorItemById = createSelector(
    getEditorState,
    (_: RootState, id: string) => id,
    (state, id) => {
        if (state.item && state.item.id === id) {
            return state.item;
        }
    }
);

const getDefaultCoEditorId = createSelector(
    (state: RootState) => state,
    (_, item: any) => item,
    (state: RootState, item: any) => isMountedOrSharedFolder(state, item),
    getEditorState,
    (_, item, folderState, editor): string | undefined | null => {
        if (!item) {
            return null;
        }
        const { sharedExtensions, extensions, availableEditors } = editor;
        const hasSharedEditor = editor.hasSharedEditor && sharedExtensions[item.ext];
        const isSharedFolder = hasSharedEditor && folderState.is;

        const editors: EditorID[] = extensions[(IS_ONPREMISE && item.ext ? item.ext.toLowerCase() : item.ext) as string]?.editors || [];

        const serverEditors = editors.map((editorId) => availableEditors[editorId]);
        const sortedServerEditors = serverEditors.sort(sortEditors);

        const resultEditor = sortedServerEditors.find((editor) => {
            if (!CO_EDITORS.includes(editor.id) || !hasSharedEditor || !isSharedFolder) {
                return false;
            }

            return editor.default !== 'shared-folder';
        });

        if (resultEditor) {
            return resultEditor.id;
        }

        return sortedServerEditors.length ? sortedServerEditors[0]?.id : undefined;
    }
);

export const getIsFileForCoEditing = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item: any) => getDefaultCoEditorId(state, item),
    (state) => getFeatureWopiCoEdit(state),
    (state) => getFeatureShareMountEditAccessDisable(state),
    (state, item: any) => isMountedOrSharedFolder(state, item),
    (_, item, defaultEditor, wopiCoEdit, isShareMountEditAccessDisable, isSharedOrMouted) => {
        if (!item || item.isFolder) {
            return false;
        }

        switch (defaultEditor) {
            case EditorID.R7:
                return true;
            case EditorID.R7_WOPI:
                if (!isR7WopiCoEdit) {
                    return false;
                }
                // запрещаем шарить на редактирование документ, если мы не владельцы
                return isShareMountEditAccessDisable ? !item.isMounted : true;
            case EditorID.MYOFFICE: {
                if (!wopiCoEdit) {
                    return false;
                }

                if (!isSharedOrMouted.isMounted) {
                    return true;
                }

                // запрещаем шарить на редактирование документ, если мы не владельцы
                return isShareMountEditAccessDisable ? !item.isMounted : true;
            }
        }
    }
);

export const getCreateDescriptions = createSelector(getEditorState, ({ extensions }) =>
    Object.values(extensions).reduce(
        (acc, ext) => (ext?.creatorsDesc && ext.editors.length > 0 ? acc.concat(ext.creatorsDesc) : acc),
        [] as CreatorsDescRecord[]
    )
);
export const getEditorItem = createSelector(getEditorState, (state) => state.item);

export const getEditorsFor = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item) => isMountedOrSharedFolder(state, item),
    (state: RootState) => state.editor,
    (state, item, folderState, editor) => {
        if (!isValidEditableItem(item)) {
            return [];
        }

        const editors = getAvailableEditorsForItem(item, editor, folderState);

        const { ext, size } = item;
        const extData = extInfo.get(ext);

        return editors.filter((editor) => {
            const maxSize = calculateMaxEditableOrPreviewableSizeForEditor(editor.id, extData.maxEditableSize);

            return maxSize && size <= maxSize;
        });
    }
);

export const getAvailableConfiguredEditorsFor = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item) => isMountedOrSharedFolder(state, item),
    (state: RootState) => state.editor,
    (_, item, folderState, editor) => {
        return getAvailableEditorsForItem(item, editor, folderState);
    }
);

export const getEditorsByExtension = createSelector(
    getEditorState,
    (_: RootState, ext: string) => ext,
    ({ extensions }, ext) => (extensions[ext] || { editors: [] }).editors
);

/**
 * Проверяет доступность переименования файла
 * **Важно:** Пока доступно только для WOPI-версии Моего Офиса
 */
export const isEnableRenamingInsideEditor = createSelector(
    (state: RootState) => ({ state, editor: getEditorState(state) }),
    ({ state, editor: { editorId, item } }) => {
        if (!editorId || !item) {
            return false;
        }

        if (editorId === EditorID.MYOFFICE) {
            return ViewerSelectors.isWopiEnabled(state, item.ext.toLowerCase());
        }

        return editorId === EditorID.R7;
    }
);

export const isEditorStorage = (storage: EStorageType): boolean =>
    [EStorageType.editor, EStorageType.r7, EStorageType.myoffice, EStorageType.myofficeAttaches, EStorageType.r7wopi].includes(storage);

export const getLlmDialogState = createSelector(getEditorState, (state) => state.llmDialog);
