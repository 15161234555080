import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDescriptorId, isFileWarning } from 'reactApp/modules/uploadList/uploadList.getters';
import { type IUploaderInformerItem, EFileError, EInfoTypes } from 'reactApp/modules/uploadList/uploadList.model';
import {
    hideInformer,
    setHasConnectionError,
    setSelectedErrorAction,
    updateUploadFilesAction,
} from 'reactApp/modules/uploadList/uploadList.module';
import {
    getHighlightSelectedErrorFile,
    getSelectedErrorId,
    getSelectedErrorIsUserClick,
    hasConnectionErrorFiles,
} from 'reactApp/modules/uploadList/uploadList.selectors';
import { Arrows } from 'reactApp/ui/UploadList/Problems/Arrows';
import { CloseIcon } from 'reactApp/ui/UploadList/Problems/CloseIcon';
import { Problem } from 'reactApp/ui/UploadList/Problems/Problem';

import styles from './Problems.css';

interface ProblemsContainerProps {
    informers: IUploaderInformerItem[];
}

const calculateNextIndex = (fileIndex: number, problemFilesLength: number, back: boolean) => {
    const incrementedIdx = fileIndex + 1;
    const decrementedIdx = fileIndex - 1;

    if (back) {
        return decrementedIdx < 0 ? problemFilesLength - 1 : decrementedIdx;
    }

    return incrementedIdx >= problemFilesLength ? 0 : incrementedIdx;
};

const closableInformers = [EInfoTypes.complete, EInfoTypes.lowSpeed];

export const ProblemsContainer = memo(({ informers }: ProblemsContainerProps) => {
    const [maxHeight, setMaxHeight] = useState(0);
    const [prevActive, setPrevActive] = useState(false);
    const [nextActive, setNextActive] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);
    const hasConnectionError = useSelector(hasConnectionErrorFiles);
    const selectedId = useSelector(getSelectedErrorId);
    const isSelectedErrorUserClick = useSelector(getSelectedErrorIsUserClick);
    const highlightSelectedFile = useSelector(getHighlightSelectedErrorFile);
    const ref = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();

    const informer = informers[fileIndex];
    const problemFile = informer?.file;

    const problemFilesLength = informers.length;
    const informersHash = informers.reduce((acc, curr) => acc + curr.id, '');

    const showArrows = !isFileWarning(problemFile) && informers.length > 1;

    const showClose =
        (problemFile && problemFile.error !== EFileError.CONNECTION_ERROR) ||
        informer?.type === EInfoTypes.networkRestored ||
        closableInformers.includes(informer?.type);

    const switchWarning = useCallback(
        ({ back, highlightFile, idx = undefined }: { back: boolean; highlightFile: boolean; idx?: number }) => {
            const nextIndex = idx || calculateNextIndex(fileIndex, problemFilesLength, back);

            const file = informers[nextIndex]?.file;

            dispatch(setSelectedErrorAction({ file, fileId: informers[nextIndex]?.id }));

            if (!file) {
                return;
            }

            const cloudPath = file.cloudPath;
            const descriptorId = file.descriptorId;
            if (highlightFile) {
                dispatch(updateUploadFilesAction({ descriptorId, cloudPath, highlight: true }));
            }
        },
        [problemFilesLength, fileIndex, informersHash]
    );

    const onClose = useCallback(() => {
        if (informer.file) {
            dispatch(
                updateUploadFilesAction({
                    descriptorId: getDescriptorId(informer.file),
                    cloudPath: informer.file.cloudPath,
                    hideError: true,
                })
            );
            switchWarning({ back: true, highlightFile: false });

            return;
        }

        if (informer.type === EInfoTypes.networkRestored) {
            dispatch(setHasConnectionError(false));
        } else if (closableInformers.includes(informer.type)) {
            dispatch(hideInformer(informer.type));
        }
    }, [informer]);

    useEffect(() => {
        if (hasConnectionError) {
            dispatch(setHasConnectionError(true));
        }
    }, [hasConnectionError]);

    useEffect(() => {
        if (!selectedId && problemFilesLength > 0) {
            dispatch(setSelectedErrorAction({ file: informers[0].file, fileId: informers[0].id }));
        }

        const findFileIndex = informers.findIndex((item) => item.id === selectedId);

        if (findFileIndex === -1) {
            switchWarning({ back: false, highlightFile: false, idx: problemFilesLength - 1 });
            return;
        }

        setPrevActive(findFileIndex !== 0);
        setNextActive(findFileIndex !== problemFilesLength - 1);
        setFileIndex(findFileIndex);
    }, [selectedId, problemFilesLength, informersHash]);

    useEffect(() => {
        if (!selectedId) {
            return;
        }

        if (isSelectedErrorUserClick) {
            return;
        }
        switchWarning({ back: false, highlightFile: false, idx: problemFilesLength - 1 });
    }, [problemFilesLength, isSelectedErrorUserClick]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const height = ref.current.getBoundingClientRect().height;

        if (height !== maxHeight) {
            setMaxHeight(height);
        }
    }, [maxHeight, selectedId]);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.highlight]: highlightSelectedFile,
                [styles.root_arrows]: showArrows,
            })}
            style={{ height: `${maxHeight}px` }}
        >
            <div ref={ref} className={styles.items}>
                {informers.map((item) => (
                    <Problem key={item.id} item={item} show={item.id === selectedId} />
                ))}
            </div>
            {showClose && <CloseIcon onClose={onClose} />}
            {showArrows && (
                <Arrows
                    fileIndex={fileIndex}
                    fileLength={informers.length}
                    prevActive={prevActive}
                    nextActive={nextActive}
                    switchWarning={switchWarning}
                />
            )}
        </div>
    );
});

ProblemsContainer.displayName = 'ProblemsContainer';
