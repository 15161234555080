import { type ButtonProps, Button, Headline } from '@vkontakte/vkui';
import React, { type FC } from 'react';

export interface WebResetFiltersButtonProps extends ButtonProps {}

export const WebResetFiltersButton: FC<ButtonProps> = ({ children, ...props }) => (
    <Button hasHover={false} mode="tertiary" {...props}>
        <Headline weight="2">{children}</Headline>
    </Button>
);

WebResetFiltersButton.displayName = 'WebResetFiltersButton';
