import { useMemo } from 'react';
import type { TariffsByTabProductsType } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import {
    DISKO_MONTH_PRICE,
    VK_COMBO_FREE_MONTHS_PERIOD,
    VK_COMBO_MONTH_PRICE,
    VK_COMBO_YEAR_FREE_MONTHS_PERIOD,
} from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getMonthes } from 'reactApp/utils/Period';

// TODO когда логика зафиксируется, написать юниты
export const useYearSaving = (products?: TariffsByTabProductsType) =>
    useMemo(() => {
        const { yearProduct, monthProduct } = products || {};
        let saving = 0;
        let savingDiscount = 0;

        if (!monthProduct && !yearProduct) {
            return [saving, savingDiscount];
        }

        if (!monthProduct && yearProduct && yearProduct.oldPrice) {
            /**
             * Если есть только годовой тариф, то выгода вычисляется по его старой цене (если она есть)
             */
            saving = yearProduct.oldPrice - yearProduct.price;
        } else if (monthProduct && yearProduct) {
            const monthPeriodAsMonths = getMonthes(monthProduct.period);
            /**
             * Для чего мы высчитываем количество месяцев в годовом тарифе? Когда понятно что в году 12 месяцев
             * На случай если вдруг придет что-то такое
             * 12m, 1y, 2y, 24m
             */
            const yearPeriodAsMonth = getMonthes(yearProduct.period);

            /**
             * Когда есть и годовой и месячный формула следующая = (<цена_месячного_тарифа> * <число_месячных_периодов_в_годовом_периоде>) - <текущая_цена_годового_тарифа>
             * P.S. рассчет <число_месячных_периодов_в_годовом_периоде> нужен для кейса с PRO-тарифами когда у нас есть годовой и полугодовой тарифы.
             */
            const monthPeriodsInYearPeriod = yearPeriodAsMonth / monthPeriodAsMonths;
            const currentYearPrice = yearProduct?.discountPrice || yearProduct.price;

            const yearPriceAsMonth = monthProduct.price * monthPeriodsInYearPeriod;
            saving =
                monthProduct.price * monthPeriodsInYearPeriod -
                currentYearPrice +
                /**
                 * ЕСЛИ ЕСТЬ Диск-О, то прибавляем еще и его цену, НО вычтенную из количества месяцев в годовом тарифе
                 * Пример:
                 * Диск-О только на 6 месяцев, значит остальные 6 месяцев мы считаем что пользователь как будто купил сам
                 * И эта сумма участвует в вычислении выгоды
                 */
                ((yearProduct.isDisko ? DISKO_MONTH_PRICE * (yearPeriodAsMonth - monthPeriodAsMonths) : 0) +
                    /**
                     * ЕСЛИ ЕСТЬ VKCombo то прибавляем еще и его цену, НО вычтенную из количества месяцев в годовом тарифе (сейчас это константа 3)
                     * Пример:
                     * VkCombo только на 1 месяцев, значит остальные 11 месяцев мы считаем что пользователь как будто купил сам
                     * И эта сумма участвует в вычислении выгоды
                     */
                    (yearProduct.isVkCombo ? VK_COMBO_MONTH_PRICE * (VK_COMBO_YEAR_FREE_MONTHS_PERIOD - VK_COMBO_FREE_MONTHS_PERIOD) : 0));

            const { discount } = getDiscount({ discountPrice: currentYearPrice, price: yearPriceAsMonth });
            savingDiscount = discount;
        }

        return [Math.round(saving), savingDiscount];
    }, [products]);
