import { createAction } from '@reduxjs/toolkit';

import type {
    IAddItems,
    IAlbumId,
    ICreateAlbumRequest,
    ICreateAlbumSuccess,
    ILoadAlbumsListSuccess,
    ILoadAlbumSuccess,
    SelectAllItems,
    ToogleItem,
} from './albums.types';

export const createAndPublishAlbumRequest = createAction<ICreateAlbumRequest>('albums/createAndPublishAlbumRequest');
export const createAlbumSuccess = createAction<ICreateAlbumSuccess>('albums/createAlbumSuccess');

export const loadAlbumsListRequest = createAction('albums/loadAlbumsListRequest');
export const loadAlbumsListSuccess = createAction<ILoadAlbumsListSuccess>('albums/loadAlbumsListSuccess');

export const loadAlbumRequest = createAction<IAlbumId>('albums/loadAlbumRequest');
export const loadAlbumSuccess = createAction<ILoadAlbumSuccess>('albums/loadAlbumSuccess');
export const loadAlbumFail = createAction<IAlbumId>('albums/loadAlbumFail');

export const albumsLoadMoreRequest = createAction('albums/albumsLoadMoreRequest');

export const createAlbumRequest = createAction<ICreateAlbumRequest | undefined>('albums/createAlbumRequest');

export const addItemsFromCloudToAlbumRequest = createAction<{ id: string } | undefined>('albums/addItemsFromCloudToAlbumRequest');

export const addItemsFromCloudChangeFolder = createAction<{ folder: string }>('albums/addItemsFromCloudChangeFolder');

export const addFilesToAlbumSuccess = createAction<IAddItems>('albums/addFilesToAlbumSuccess');

export const selectAlbumToAddItemsRequest = createAction<ICreateAlbumRequest>('albums/selectAlbumToAddItemsRequest');

export const setUploadAlbumId = createAction<IAlbumId>('albums/setUploadAlbumId');

export const handleUpdateAlbumList = createAction<IAlbumId>('albums/handleUpdateAlbumList');

export const toggleItem = createAction<ToogleItem>('albums/selection/toggleItem');
export const selectAllItems = createAction<SelectAllItems>('albums/selections/selectAllItems');
export const resetAllSelectedItems = createAction('albums/selections/resetAllSelectedItems');
