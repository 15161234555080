import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { advExpId } from 'reactApp/appHelpers/featuresHelpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { updateProducts } from 'reactApp/modules/products/products.module';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useModalCallbacks = ({ onClose, product, type, onSuccess, isFrame, paySource }) => {
    const dispatch = useDispatch();

    const sendModalGa = useCallback(
        (action) => {
            sendGa(`payment-vk-${type}`, action);

            if (type === EPaymentModalType.trial) {
                sendPaymentGa({
                    action: EPaymentGa.trialWindow,
                    label: action,
                    tariff: product?.id,
                    amount: product?.discountPrice,
                    paySource,
                });
                sendXray(ECategoryGa.payment, [EPaymentGa.trialWindow, action, advExpId].join('_'));
            } else if (type === EPaymentModalType.fastcheckout) {
                emitAnalyticEvent(AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW, { product, paySource });
                sendPaymentGa({
                    action: EPaymentGa.fastCheckout,
                    label: action,
                    tariff: product?.id,
                    type_page: product?.hasDiscount ? 'promo' : 'common',
                    frame: isFrame,
                    paySource,
                    // tempexp_17127-next-line
                    trial: paySource === 'modal_noads_tariff' ? product?.hasTrial : undefined,
                });
            } else if (type === EPaymentModalType.attachesTrial) {
                sendPaymentGa({
                    action: EPaymentGa.attachesTrial,
                    label: action,
                    tariff: product?.id,
                    paySource,
                });
            }
        },
        [isFrame, product?.discountPrice, product?.hasDiscount, product?.id, type, paySource]
    );

    const onCloseModal = useCallback(() => {
        sendModalGa('close');
        onClose();
    }, [onClose, sendModalGa]);

    const onShow = useCallback(() => {
        sendModalGa('view');
    }, [sendModalGa]);

    const onCancel = useCallback(() => {
        sendModalGa('cancel');
    }, [sendModalGa]);

    const onDmrFormSend = useCallback(() => {
        if (type === EPaymentModalType.fastcheckout) {
            emitAnalyticEvent(AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY, { product, paySource });
        }
        sendModalGa(type === EPaymentModalType.fastcheckout ? 'click_pay' : 'click');
    }, [sendModalGa, type]);

    const onSuccessCallback = useCallback(() => {
        sendModalGa('success');

        if (onSuccess) {
            onSuccess();
        } else {
            dispatch(updateProducts());
            dispatch(updateUser());
        }
    }, [dispatch, onSuccess, sendModalGa]);

    const onClickTariffs = useCallback(() => {
        sendModalGa('click_more');
    }, [sendModalGa]);

    return {
        onCloseModal,
        onCancel,
        onShow,
        onDmrFormSend,
        onSuccessCallback,
        onClickTariffs,
    };
};
