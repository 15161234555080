import { pathOr } from 'ramda';
import React, { memo, useCallback, useState } from 'react';
import { isVirusItem } from 'reactApp/modules/file/utils';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { useTimer } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer.hooks';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { sendGa } from 'reactApp/utils/ga';

import styles from './MobileViewerTxt.css';

interface IProps {
    item: CloudItem;
}

export const MobileViewerTxt = memo<IProps>(({ item }) => {
    const [isError, setError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const ext = ('ext' in item && item?.ext) || '';
    const kind = item.kind || 'file';
    // FIXME: проблема с типом clouditem. Сложно дойти до item.url.view.
    const viewUrl = pathOr('', ['url', 'view'], item);
    const isVirus = isVirusItem(item);

    const handleError = useCallback(() => {
        sendGa('mobile-viewer', 'load-txt', 'error');
        setError(true);
        setLoaded(true);
    }, [setError, setLoaded]);

    const { clearTimer } = useTimer(viewUrl, handleError);

    const handleLoad = useCallback(() => {
        clearTimer();
        setLoaded(true);
    }, [setLoaded]);

    if (isError) {
        return <Stub ext={ext} kind={kind} isVirus={isVirus} name={item.name} />;
    }

    return (
        <div className={styles.root}>
            <iframe
                src={viewUrl}
                className={styles.content}
                allowFullScreen
                allow="autoplay; fullscreen"
                frameBorder={0}
                onError={handleError}
                onLoad={handleLoad}
            />
            {!isLoaded && <Spinner />}
        </div>
    );
});

MobileViewerTxt.displayName = 'MobileViewerTxt';
