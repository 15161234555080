import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { isStoriesInGallery } from 'reactApp/appHelpers/featuresHelpers';
import { selectFromCloudGetSelected } from 'reactApp/modules/albums/albums.selector';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
import { getFeatureNewContentTable, getFeatureShowStories } from 'reactApp/modules/features/features.selectors';
import { getLastFilesForWidget } from 'reactApp/modules/feed/feed.selectors';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStorySummaryCovers } from 'reactApp/modules/stories/stories.selectors';
import { GalleryPageTabMenu } from 'reactApp/sections/GalleryNewPage/GalleryPageTabMenu';
import type { RootState } from 'reactApp/store';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { isMobile } from 'reactApp/ui/Datalist/DataList.constants';
import { DataListItem } from 'reactApp/ui/DataListItem/DataListItem';
import type { DataListItemType } from 'reactApp/ui/DataListItem/DataListItem.types';
import { DataListItemCreateNew, ID_NEW_ITEM } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew';
import { CreateNewMod } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew.data';
import { DataListItemSafeFakedoor } from 'reactApp/ui/DataListItemSafeFakedoor/DataListItemSafeFakedoor';
import { LastFilesWidget } from 'reactApp/ui/LastFilesWidget/LastFilesWidget';
import { StoriesWidget } from 'reactApp/ui/StoriesWidget/StoriesWidget';
import { StoriesWidgetComponent } from 'reactApp/ui/StoriesWidgetComponent/StoriesWidgetComponent';

import {
    DATA_LIST_BREADCRUMBS_ID,
    DATA_LIST_EXTRABLOCKS_ID,
    DATA_LIST_GALLERY_TAB_MENU_ID,
    DATA_LIST_LAST_FILES_WIDGET_ID,
    DATA_LIST_STORIES_WIDGET_ID,
    SAFE_FAKEDOOR_ID,
} from '../DataList';
import { ExtraBlocks } from '../ExtraBlocks/ExtraBlocks';
import styles from './DataListItem.css';

interface IProps extends Omit<DataListItemType, 'selectableKey' | 'isSelected' | 'isSelecting' | 'pos' | 'item'> {
    optionIndex: number;
    handleClickCreateNew: (e) => void;
    isSharedLinks: boolean;
    selectingIdxs: string[];
    activeIndex: number | null;
    dragOverItemId: string | undefined;
    selectedCount: number;
    rootRef?: React.Ref<HTMLDivElement>;
    isPopup?: boolean;
    currentAllDocsType?: EAllDocumentsType | null;
    itemsAmount: number;
    isSharedAutodelete?: boolean;
    onItemSizeChange?: (size: number) => void;
}

// eslint-disable-next-line max-lines-per-function, complexity
export const WrapDataListItem = ({
    id,
    optionIndex,
    view,
    handleClickCreateNew,
    isSharedLinks,
    selectedIdxs,
    selectingIdxs,
    activeIndex,
    isDragging,
    dragOverItemId,
    onSelect,
    onClick,
    onContextMenu,
    selectedCount,
    storage,
    tipData,
    isFavoritesEnabled,
    onDraggingOver,
    onMouseLeave,
    register,
    unregister,
    onMouseDown,
    rootRef,
    thumbType,
    isPopup,
    isPublicDownloaded,
    currentAllDocsType,
    listOfBannedToFavoriteItems,
    albumShowSelected = false,
    itemsAmount,
    onItemSizeChange,
}: IProps) => {
    const storeStories = useSelector(getStorySummaryCovers);
    const userHasStories = storeStories?.length > 0;
    const isShowStoryEnabled = useSelector(getFeatureShowStories);
    const lastFiles = useSelector(getLastFilesForWidget);

    const storageItem = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as Exclude<CloudItem, EditorItem>;
    const selectedItem = useSelector((state: RootState) => selectFromCloudGetSelected(state, id)) as Exclude<CloudItem, EditorItem>;
    const item = albumShowSelected ? selectedItem : storageItem;

    const handleStoriesWidgetExpand = useCallback(
        (expanded) => {
            onItemSizeChange?.(expanded ? 294 : 50);
        },
        [onItemSizeChange]
    );

    const handleLastFilesWidgetExpand = useCallback(
        (expanded) => {
            onItemSizeChange?.(expanded ? 244 : 50);
        },
        [onItemSizeChange]
    );

    if (id === ID_NEW_ITEM) {
        let mod: CreateNewMod | undefined;
        if (storage === EStorageType.alldocuments) {
            // eslint-disable-next-line sonarjs/no-small-switch
            switch (currentAllDocsType) {
                case EAllDocumentsType.spreadsheet:
                    mod = CreateNewMod.newAllDocumentSpreadsheet;
                    break;
                case EAllDocumentsType.presentation:
                    mod = CreateNewMod.newAllDocumentPresentation;
                    break;
                default:
                    mod = CreateNewMod.newAllDocument;
                    break;
            }
        }

        return (
            /** tempexp_14729-next-line */
            <FeatureSelector
                selector={getFeatureNewContentTable}
                control={
                    <DataListItemCreateNew
                        isThumb={view === 'thumbs'}
                        onClick={handleClickCreateNew}
                        onContextMenu={handleClickCreateNew}
                        isSharedFolderOnly={isSharedLinks}
                        rootRef={rootRef}
                        storage={storage}
                        mod={mod}
                    />
                }
                variant1={
                    <DataListItemCreateNew.New
                        isThumb={view === 'thumbs'}
                        onClick={handleClickCreateNew}
                        onContextMenu={handleClickCreateNew}
                        isSharedFolderOnly={isSharedLinks}
                        rootRef={rootRef}
                        storage={storage}
                        mod={mod}
                    />
                }
            />
        );
    }

    if (id === DATA_LIST_BREADCRUMBS_ID && NEW_PORTAL_HEADER) {
        return <BreadcrumbsContainer className={styles.breadcrumbs_new_portal_header} />;
    }

    if (id === DATA_LIST_EXTRABLOCKS_ID && NEW_PORTAL_HEADER) {
        return <ExtraBlocks itemsCount={itemsAmount} />;
    }

    if (id === DATA_LIST_STORIES_WIDGET_ID && isShowStoryEnabled && userHasStories && NEW_PORTAL_HEADER) {
        return isStoriesInGallery ? (
            <StoriesWidgetComponent stories={storeStories} />
        ) : (
            <StoriesWidget onExpand={handleStoriesWidgetExpand} />
        );
    }

    if (id === DATA_LIST_LAST_FILES_WIDGET_ID && lastFiles.length > 0) {
        return <LastFilesWidget lastFiles={lastFiles} onExpand={handleLastFilesWidgetExpand} />;
    }

    if (id === SAFE_FAKEDOOR_ID) {
        return <DataListItemSafeFakedoor viewMode={view} />;
    }

    if (id === DATA_LIST_GALLERY_TAB_MENU_ID) {
        return <GalleryPageTabMenu isInDataList />;
    }

    const isSelected = selectedIdxs.includes(id);
    const isSelecting = selectingIdxs.includes(id);
    const isActive = activeIndex === optionIndex;

    if (!item) {
        return null;
    }

    return (
        <DataListItem
            id={id}
            item={item}
            isDragging={isDragging}
            isSelected={isSelected}
            isActive={isActive}
            isSelecting={isSelecting}
            selectedIdxs={selectedIdxs}
            isDragOver={isDragging && dragOverItemId === id && !isSelected}
            onSelect={onSelect}
            onClick={onClick}
            onContextMenu={onContextMenu}
            view={view || 'thumbs'}
            showCheckbox={selectedCount > 1 || isSelected}
            storage={storage || EStorageType.home}
            isMobile={isMobile}
            tipData={tipData}
            isFavoritesEnabled={isFavoritesEnabled}
            listOfBannedToFavoriteItems={listOfBannedToFavoriteItems}
            onDraggingOver={onDraggingOver}
            onMouseLeave={onMouseLeave}
            selectableKey={id}
            register={register}
            unregister={unregister}
            onMouseDown={onMouseDown}
            thumbType={thumbType}
            isPopup={isPopup}
            pos={optionIndex}
            isPublicDownloaded={isPublicDownloaded}
            albumShowSelected={albumShowSelected}
        />
    );
};
