import { Spacing, Text, Title } from '@vkontakte/vkui';
import { ReactComponent as CuteCat } from 'img/errors/cute-cat.svg';
import { ReactComponent as DarkThemeQrCode } from 'img/errors/dark/app-qrcode.svg';
import { ReactComponent as DarkCuteCat } from 'img/errors/dark/cute-cat.svg';
import MascotBite from 'img/mascotBiteWithoutMouthBig.png';
import qrCode from 'img/qrAppLink.png';
import { QRCodeCanvas } from 'qrcode.react';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { Icon20SmartphoneOutline } from 'reactApp/ui/VKUIIcons';

import styles from './WelcomeNewPromo.css';

export const PhoneAppBlock = memo(() => {
    const isDarkTheme = useSelector(isCurrentDarkTheme);

    return (
        <div className={styles.block} data-qa-id="phone">
            <div className={styles.icon}>
                <Icon20SmartphoneOutline />
            </div>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <Title className={styles.block_title} level="3">
                Мобильное приложение
            </Title>
            <Spacing size={8} style={{ pointerEvents: 'none' }} />
            <Text className={styles.block_text}>
                {isRebranding
                    ? 'Наведите камеру на QR-код, чтобы установить приложение'
                    : 'С приложением доступ к вашим файлам будет постоянно под рукой'}
            </Text>
            <Spacing size={isRebranding ? 29 : 24} style={{ pointerEvents: 'none' }} />
            {isRebranding ? (
                <>
                    <div className={styles.qrImage}>
                        <QRCodeCanvas value={'https://trk.mail.ru/c/gb7a13'} size={105} bgColor={'transparent'} fgColor={'#2c2d2e'} />
                    </div>
                    <img src={MascotBite} className={styles.mascot} alt={'Mascot image'} />
                </>
            ) : (
                <div className={styles.cat}>
                    {isRebranding && isDarkTheme ? (
                        <DarkCuteCat width={99} height={58} viewBox="0 0 143 85" />
                    ) : (
                        <CuteCat width={99} height={58} viewBox="0 0 143 85" />
                    )}
                    <div className={styles.qrImage}>
                        {isRebranding && isDarkTheme ? <DarkThemeQrCode width={84} height={84} /> : <img src={qrCode} alt="qr code" />}
                    </div>
                </div>
            )}
        </div>
    );
});

PhoneAppBlock.displayName = 'PhoneAppBlock';
