import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type { PromocodeInfo } from 'reactApp/api/promo/PromocodeGetAPICall';
import type { State } from 'reactApp/modules/promocode/promocode.types';

const initialState: State = {
    isLoading: false,
    isLoaded: false,
    promocodes: [],
};

export const loadPromocodes = createAction('promocodes/loadRequest');
export const loadPromocodesSuccess = createAction<PromocodeInfo[]>('promocodes/loadSuccess');

export const promocodesReducer = createReducer(initialState, {
    [loadPromocodes.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadPromocodesSuccess.type]: (state, action: PayloadAction<PromocodeInfo[]>) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.promocodes = action.payload;
    },
});
