import { useCallback, useEffect } from 'react';
import type { BusinessTemplateItem } from 'reactApp/modules/businessTemplates/businessTemplates.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useAnalytics = (templatesCategory: string) => {
    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, section: templatesCategory });
    }, [templatesCategory]);

    const sendFileCreated = useCallback(
        (template: BusinessTemplateItem, ext: string) =>
            sendDwh({
                eventCategory: ECategoryGa.created,
                action: 'template',
                dwhData: {
                    size_file: template.size,
                    extension: ext,
                    id_media: template.filename,
                    template: template.title,
                },
            }),
        []
    );

    return { sendFileCreated };
};
