import { ReactComponent as LeftArrowIcon } from 'img/icons/leftArrow.svg';
import React, { type ReactElement, memo } from 'react';
import type { IFaceWithCoordinates } from 'reactApp/modules/faces/faces.types';
import { FaceItem } from 'reactApp/ui/FaceFilter/FaceList/FaceItem/FaceItem';

import styles from './FaceFilter.css';

export const FaceFilter = memo(
    ({
        faces,
        onHideClick,
        onFaceSelect,
    }: {
        faces?: IFaceWithCoordinates[];
        onHideClick();
        onFaceSelect(id: string);
    }): ReactElement | null => {
        return (
            <div className={styles.root}>
                <div className={styles.arrow} onClick={onHideClick}>
                    <LeftArrowIcon width={17} height={16} />
                </div>
                {faces?.map((face) => (
                    <FaceItem
                        key={face.id}
                        id={face.id}
                        numOfPhotos={face.numOfPhotos}
                        imageUrl={face.imageUrl}
                        onClick={onFaceSelect}
                        small
                    />
                ))}
            </div>
        );
    }
);

FaceFilter.displayName = 'FaceFilter';

FaceFilter.whyDidYouRender = true;
