import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import type { PublicItem } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { StockItem } from 'reactApp/modules/stock/stock.type';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { MobileDropdown, MobileDropdownTheme } from 'reactApp/ui/Mobile/MobileDropdown/MobileDropdown';

import styles from './DownloadDropdown.css';

const GA_CATEGORY = 'touch-folder';

export const DownloadDropdown = memo<{ id: string; onClose: () => void; storage?: EStorageType }>(({ id, onClose, storage }) => {
    const currentStorage = useSelector(getCurrentStorage);
    if (!storage) {
        storage = currentStorage;
    }
    const item = useSelector((state: RootState) => getStorageItemById(state, storage || currentStorage, id)) as PublicItem | StockItem;

    const ext = item.isFolder ? '' : item?.ext;

    const downloadItems = downloadAsJpeg.getDropdownItems({
        itemOrId: item,
        isFormat: true,
        ext,
        gaSuffix: GA_CATEGORY,
    });

    return (
        <div className={styles.root}>
            <MobileDropdown
                onClose={onClose}
                items={downloadItems}
                gaId={GA_CATEGORY}
                header="Скачать"
                theme={MobileDropdownTheme.public}
            />
        </div>
    );
});

DownloadDropdown.displayName = 'DownloadDropdown';
