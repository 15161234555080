import classNames from 'clsx';
import { addDays } from 'date-fns';
import store from 'lib/store';
import { xray } from 'lib/xray';
import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { abOverquotaPopupTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { initProducts } from 'reactApp/modules/products/products.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { useTariffs } from 'reactApp/ui/QuotaPopup/useTariffs';
import { TariffCard } from 'reactApp/ui/TariffCard/TariffCard';
import { createGaSender } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getTariffProductsByPeriods, isYearPeriod } from 'reactApp/utils/Period';

import styles from './QuotaPopup.css';

const sendGa = createGaSender('quota-banner');

interface Props {
    quota: 2 | 4 | 8 | 16;
    onClose(): void;
    onShow(): void;
}

// eslint-disable-next-line max-lines-per-function
export const QuotaPopup = memo(({ quota, onClose, onShow }: Props): ReactElement | null => {
    const dispatch = useDispatch();
    const { total } = useSelector(UserSelectors.getCloudSpace);
    const userEmail = useSelector(UserSelectors.getEmail);
    const { goToSubscriptions } = useGoToSubscriptions();

    const showPro = total.space >= 512 && (quota === 8 || quota === 16);
    const showOneTariff = !showPro && (quota === 8 || quota === 16);

    const tariffs = useTariffs({ isPro: showPro, isOneTariff: showOneTariff }) || [];

    const setStore = useCallback(
        (dayCount: number): void => {
            if (userEmail) {
                const now = new Date();
                const endDate = addDays(now, dayCount).valueOf();
                store.set(`${userEmail}|quota-banner_${quota}`, endDate);
            }
        },
        [quota, userEmail]
    );

    useEffect(() => {
        dispatch(initProducts());

        onShow();

        sendGa('show');
        sendPaymentGa({ action: EPaymentGa.quotaBanner, label: 'view' });
        xray.send(['quota_popup', 'show', abOverquotaPopupTariffs || 'control']);

        if (quota === 2) {
            setStore(1);
        } else {
            setStore(7);
        }
    }, []);

    const getDescriptions = () => {
        const tariff = tariffs[0];

        // tempexp_16790-next-line
        if (showOneTariff && !abOverquotaPopupTariffs) {
            return `Увеличьте его на ${tariff.space.value} со скидкой`;
        }

        return `Совсем скоро вы не сможете загружать новые файлы и делиться старыми. Нужно срочно что-то предпринять — удалите лишнее или увеличьте Облако`;
    };

    const handleOnTariffsClick = useCallback((): void => {
        sendGa('click-all');
        sendPaymentGa({ action: EPaymentGa.quotaBanner, label: 'click_more' });
        xray.send(['quota_popup', 'click-all', abOverquotaPopupTariffs || 'controll']);

        if (quota !== 2) {
            setStore(3);
        }

        goToSubscriptions({ inSameTab: true, query: PaymentUTM.quotaPopup, isNewTariffsPage: true });

        onClose();
    }, [quota, goToSubscriptions, onClose, setStore]);

    const handleOnClose = useCallback((): void => {
        sendGa('close');
        sendPaymentGa({ action: EPaymentGa.quotaBanner, label: 'close' });
        xray.send(['quota_popup', 'close', abOverquotaPopupTariffs || 'control']);
        onClose();
    }, [onClose]);

    const handleOnBuyClick = useCallback(
        (productId): void => {
            if (quota !== 2) {
                setStore(3);
            }

            sendGa('click');
            sendPaymentGa({ action: EPaymentGa.quotaBanner, label: 'click', tariff: productId });
            xray.send(['quota_popup', 'click-tariff', abOverquotaPopupTariffs || 'control']);

            onClose();

            dispatch(
                openTariffBuy({
                    productId,
                    onSuccess: () => {
                        sendGa('success');
                        xray.send(['quota_popup', 'buy', abOverquotaPopupTariffs || 'control']);
                    },
                    paySource: EPaymentGa.quotaBanner,
                })
            );
        },
        [dispatch, onClose, quota, setStore]
    );

    const renderTariffs = () => {
        return tariffs.map((tariff) => {
            if (!tariff) {
                return null;
            }

            const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff.products);

            // tempexp_16790-next-line
            const product = abOverquotaPopupTariffs === 'c' ? monthProduct : yearProduct;

            if (!product) {
                return null;
            }

            const saving = product.discountPrice ? product.price - product.discountPrice || 11 : null;

            return (
                <div key={tariff.id}>
                    <TariffCard
                        id={product.id}
                        tariffId={tariff.id}
                        flags={tariff.flags}
                        space={product.space}
                        price={product.price}
                        discountPrice={product.discountPrice}
                        // tempexp_16790-next-line
                        showSaving={!abOverquotaPopupTariffs && !!saving}
                        saving={saving}
                        onBuyClick={handleOnBuyClick}
                        showOldPrice={false}
                        period={product.period}
                        isPrimary
                        isVisible
                        withoutTariffIcon
                        withoutTariffFeatures
                        isProfessional={false}
                        modificator="quota"
                        topLabel
                        forceShowPeriod
                        isYear={isYearPeriod(product.period)}
                        noShadow
                    />
                </div>
            );
        });
    };

    if (!tariffs.length) {
        return null;
    }

    return (
        <Dialog
            open
            closeOnDimmerClick
            closeOnEscape
            id="quota-popup"
            theme="octavius"
            showCloseIcon
            size="middle"
            onCancel={handleOnClose}
            className={classNames({
                [styles.modal]: !showOneTariff && !showPro,
            })}
        >
            <div className={styles.root}>
                <div className={styles.title}>
                    В вашем Облаке свободно менее <span className={styles.quota}>{quota} ГБ</span>
                </div>
                <div className={styles.descriptions} dangerouslySetInnerHTML={{ __html: getDescriptions() }} />
                <div className={styles.tariffs}>{renderTariffs()}</div>
                <div className={styles.allTariffs} onClick={handleOnTariffsClick}>
                    Посмотреть все тарифы
                </div>
            </div>
        </Dialog>
    );
});

QuotaPopup.displayName = 'QuotaPopup';
