import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

import type { SearchAnalyticsParams, SearchLoadMoreData } from './search.types';

const getSearchState = (state: RootState) => state.search;

export const getSearchStartLocation = createSelector(getSearchState, (state) => state.searchStartLocation);

export const getSearchItemById = createSelector(
    getSearchState,
    (state, id: string) => id,
    (search, id) => search.list[id]
);

export const getCurrentSearchFolder = createSelector(getSearchState, (search) => search[ROOT_FOLDER_ID]);

export const getSearchList = createSelector(getCurrentSearchFolder, (state): string[] => state.childs);

export const getSearchLoadingState = createSelector(
    getSearchState,
    (search): LoadingState => ({
        isLoading: search[ROOT_FOLDER_ID].isLoading,
        isLoaded: search[ROOT_FOLDER_ID].isLoaded,
    })
);

export const getSearchLoadMoreData = createSelector(
    getSearchState,
    (search): SearchLoadMoreData => ({
        offset: search.offset,
        list: search.listFull,
    })
);

export const getSearchRequestParams = createSelector(getSearchState, (search) => {
    return search.requestParams;
});

export const getSearchEmptyFilters = createSelector(getSearchState, (search) => {
    return search.emptyFilters;
});

export const getSearchContentIndexed = createSelector(getSearchState, (search) => {
    return search.contentIndexed;
});

export const getSearchContentStatus = createSelector(getSearchState, (search) => {
    return {
        webSearchContentEnabled: search.webSearchContentEnabled,
        touchSearchContentEnabled: search.touchSearchContentEnabled,
    };
});

export const getSearchAnalyticsParams = (state): SearchAnalyticsParams => {
    const isPhone = EnvironmentSelectors.isPhone();
    const { query, xPageId, xReqId } = getSearchRequestParams(state);
    const storage = getCurrentStorage(state);
    return {
        place: isPhone ? 'touch' : 'web',
        section: storage,
        search_phrase: query,
        page_id: xPageId,
        req_id: xReqId,
    };
};

export const getSearchHistory = createSelector(getSearchState, (search) => {
    return search.searchHistory;
});

export const getSearchCount = createSelector(getSearchState, (search) => Object.keys(search.listFull).length);

export const getListFullOrigin = createSelector(getSearchState, (search) => search.listFullOrigin);

export const getSearchSection = createSelector(getSearchState, (search) => search.searchSection);
