import React, { memo } from 'react';
import { IS_B2B_BIZ_USER, IS_WEBVIEW, LOGO_SRC } from 'reactApp/appHelpers/configHelpers';

import { LogoComponent } from './LogoComponents';

export const Logo = memo<{ dark?: boolean }>(function Logo({ dark }) {
    if (IS_WEBVIEW) {
        return null;
    }

    return <LogoComponent dark={dark} logoSrc={LOGO_SRC} isBizUser={IS_B2B_BIZ_USER} />;
});
