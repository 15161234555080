import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type OnMessage, useIframeChannel } from 'reactApp/hooks/useIframeChannel';
import type { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { NEED_PASSWORD } from 'reactApp/ui/PdfViewer/PdfViewer.constants';
import { fetchDocumentData } from 'reactApp/ui/ReactViewer/MobilePdfViewer/MobilePdfViewer.helpers';
import type { Message } from 'reactApp/ui/ReactViewer/MobilePdfViewer/MobilePdfViewer.types';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import styles from './MobilePdfViewer.css';

type MobilePdfProps = {
    item: CloudItem;
    storage: EStorageType;
    wait?: boolean;
    onShow();
    onClose();
    onError?: (error: string) => void;
};

/**
 * В логи прилетает много ошибок xray_too_long вида:
 * viewer_pdf_error_Error--Error--Request-failed-with-status-code-4
 * поэтому убираем из сообщения часть "Error: Error: ",
 * которая приходит из onMessageHandler и если сообщение
 * длинное - обрезаем его до 64 символов
 */
const normalizeErrorMessage = (message: string) => {
    let msg = message ?? '';
    msg = msg.toString().toLowerCase();

    if (msg.includes('error:')) {
        msg = msg.replace(/error: /gi, '');
    }

    return getTrimmedText(msg, 65, true);
};

export const MobilePdfViewer: FC<MobilePdfProps> = ({ item, storage, wait = false, onShow = noop, onClose = noop, onError = noop }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [key, setKey] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [content, setContent] = useState<ArrayBuffer | null>(null);
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [scale, setScale] = useState(1);

    const resetState = useCallback(() => {
        setContent(null);
        setPassword('');
        setError('');
        setIsFrameLoaded(false);
        setIsReady(false);
        setScale(1);
    }, []);

    const updateIframe = useCallback(() => {
        setError('');
        setIsFrameLoaded(false);
        setIsReady(false);
        setScale(1);
        setKey(Date.now().toString());
    }, []);

    const channelId = useMemo(() => Date.now().toString(), []);

    const iframeUrl = useMemo(
        () =>
            new URL(
                `${config.get('BUILD_URLS')?.pdfViewer}?channelId=${channelId}&hostname=${window?.location?.hostname}`,
                window?.location?.origin
            ),
        [channelId]
    );

    const onMessageHandler: OnMessage<Message> = useCallback((data) => {
        if (data.type === 'start') {
            setIsFrameLoaded(true);
        } else if (data.type === 'ready') {
            setIsReady(true);
        } else if (data.type === 'scale') {
            setScale(data.scale);
        } else if (data.type === 'error') {
            if (data.message === NEED_PASSWORD) {
                const password = prompt('Пароль');
                if (password) {
                    setPassword(password);
                    updateIframe();
                } else {
                    setError(data.message);
                }
            } else {
                setError(data.message);
            }
            sendXray(['viewer', 'pdf', 'error', normalizeErrorMessage(data.message)]);
        } else if (data.type === 'xray') {
            sendXray(data.event);
        }
    }, []);

    const { postMessage } = useIframeChannel<Message>({
        iframeRef: iframeRef.current,
        isFrameLoaded,
        onMessage: onMessageHandler,
        channelId,
        sendToOrigin: iframeUrl.origin,
        isInsideIframe: false,
    });

    useEffect(() => {
        resetState();
    }, [item.id]);

    useEffect(() => {
        let cancel = false;
        if (!item || !storage || wait || content?.byteLength) {
            return;
        }

        fetchDocumentData({ item, storage })
            .then((content) => {
                if (cancel) {
                    return;
                }

                setContent(content);
            })
            .catch((error) => {
                setError(error.message);
            });

        return () => {
            cancel = true;
        };
    }, [content?.byteLength, item.id, storage, wait]);

    useEffect(() => {
        if (wait) {
            setIsFrameLoaded(false);
            setIsReady(false);
        }
    }, [wait]);

    useEffect(() => {
        if (content && isFrameLoaded) {
            postMessage({ type: 'file', content, password });
        }
    }, [content, isFrameLoaded, password]);

    useEffect(() => {
        onShow();

        return () => {
            onClose();
        };
    }, [onShow, onClose]);

    useEffect(() => {
        sendXray(['viewer', 'pdf', 'open']);
    }, []);

    useEffect(() => {
        if (error) {
            sendXray(['viewer', 'pdf', 'error', normalizeErrorMessage(error)]);
            onError(error);
        }
    }, [error]);

    const handleError = useCallback(() => {
        setError('iframe error');
    }, []);

    return (
        <div className={styles.wrapper}>
            {content?.byteLength && !wait && !error && (
                <>
                    {scale === 1 && (
                        <>
                            <div className={classNames(styles.swipeBlock, styles.left)} />
                            <div className={classNames(styles.swipeBlock, styles.right)} />
                        </>
                    )}
                    <iframe
                        key={key}
                        loading="lazy"
                        onError={handleError}
                        className={styles.wrapperIframe}
                        sandbox="allow-same-origin allow-scripts"
                        src={iframeUrl.toString()}
                        ref={iframeRef}
                    />
                </>
            )}
            {error && <div className={styles.text}>{error}</div>}
            {!error && (!content?.byteLength || wait || !isFrameLoaded || !isReady) && <Spinner />}
        </div>
    );
};
