import { EditorID } from 'Cloud/Application/Editor/types';
import browser from 'Cloud/browser';
import { OLD_FORMAT_EXTS } from 'Cloud/ui/Viewer/viewer.constants';
import { extInfo } from 'lib/extInfo';
import { IS_MOBILE_BROWSER, IS_PHONE_BROWSER, IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getExtension } from 'reactApp/modules/file/utils';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { ON_PREMISE } from 'server/constants/environment';
import { calculateMaxEditableOrPreviewableSizeForEditor } from 'server/helpers/editors/calculateMaxEditableOrPreviewableSizeForEditor';
import { sortEditors } from 'server/helpers/editors/sortEditors';
import { type Editors, type ServerEditor, EditorMode } from 'server/helpers/editors/types';
import { getFeatureByName } from 'server/helpers/features/getFeatureByName';
import type { IFeatures } from 'server/helpers/features/getFeatures';
import { ITEM_WITHOUT_HASH_MAX_SIZE } from 'server/helpers/file/isPreviewable';
import type { IBrowserInfo } from 'server/helpers/getBrowserInfo';
import type { IUser } from 'server/helpers/getUser';
import type { IPublicInfo } from 'server/helpers/public/getPublicInfo';
import type { IContext, ICtx } from 'server/types/context/IContext';
import { type IFolder, EPublicType } from 'server/types/context/IPublicFolder';

import type { IBuildUrls } from '../getBuildUrls';
import type { IRequestParams } from '../getRequestParams';
import { getChosenEditor } from './getChosenEditor';
import { getEditorsConfig } from './getEditorsConfig';
import { getEditorSettingsFromOnlineConf } from './getEditorSettingsFromOnlineConf';
import { getOwnerUserGroup, getUserGroup } from './getUserGroup';

const availableEditors = [EditorID.MAILPAD, EditorID.R7, EditorID.MYOFFICE, EditorID.DOCS_MAIL, EditorID.OVIDIUS_V2, EditorID.R7_WOPI];

export const getAvailableEditorsFor = (props: {
    editors: ServerEditor[];
    item: { name: string; attachType?: EAttachTypes; size?: number; weblink?: string };
    isEdit: boolean;
    storage?: string | null;
    isWopiEnabled?: boolean;
}): ServerEditor[] => {
    const { item, editors, isEdit, isWopiEnabled, storage } = props;
    const { isStock = false, isAttaches = false } = storage ? getStorage(storage) : {};

    const ext = getExtension(item).toLowerCase();

    const extInf = extInfo.get(ext);

    const availableEditors: ServerEditor[] = [];

    // eslint-disable-next-line complexity
    editors.forEach((editor) => {
        if (editor.id === EditorID.MYOFFICE && OLD_FORMAT_EXTS.includes(ext?.toLowerCase()) && !isWopiEnabled) {
            return;
        }

        if (!editor.tablet && EnvironmentSelectors.isIpadOs()) {
            return;
        }

        if (
            (isStock || isAttaches || item.attachType) &&
            (!editor.can_view_attach || (typeof editor.can_view_attach !== 'object' && editor.can_view_attach !== true))
        ) {
            return;
        }

        if (
            typeof editor.can_view_attach === 'object' &&
            (item.attachType || isAttaches || isStock) &&
            ((isAttaches && !editor.can_view_attach.includes(item?.attachType || EAttachTypes.attach)) || // на странице атачей проверяем attachType
                (isStock && !editor.can_view_attach.includes('stock')) || // на странице стоков проверяем stock
                (!isAttaches && !isStock && item.attachType && !editor.can_view_attach.includes(item.attachType))) // если нету storage проверяем attachType
        ) {
            return;
        }

        const isAvailable = !editor.pages || !storage || editor.pages.includes(storage);

        if (!isAvailable) {
            return;
        }

        const size =
            (isEdit
                ? calculateMaxEditableOrPreviewableSizeForEditor(editor.id, extInf.maxEditableSize)
                : !extInf.disablePreviewAsEditorOnPublic &&
                  calculateMaxEditableOrPreviewableSizeForEditor(editor.id, extInf.maxPreviewableSize)) || 0;

        const isCalculatedSize = !item?.size || (item?.size >= ITEM_WITHOUT_HASH_MAX_SIZE && item?.size <= size);

        if (!isCalculatedSize) {
            return;
        }

        if (!editor) {
            return;
        }

        const availableExtensions = isEdit ? editor.edit_extensions : editor.view_extensions;

        if (!availableExtensions?.includes(ext)) {
            return;
        }

        // stringifyEscapeScript убивает функции из конфигов, восстанавливаем при выборке
        editor.get_view_url = getEditorsConfig(editor.id)?.get_view_url;

        if ((isStock || isAttaches || item.attachType) && editor.attachOrder) {
            editor.order = editor.attachOrder;
        }

        availableEditors.push(editor);
    });

    return availableEditors.sort(sortEditors);
};

const getEditorByUri = (
    json: IContext,
    publicInfo: IPublicInfo | undefined,
    editors: ServerEditor[],
    isPublic: boolean,
    isStock: boolean
) => {
    const requestUri = json.request?.uri;

    if (!requestUri) {
        return;
    }

    const availableEditors = editors
        .map((editor) => {
            const uri = (isPublic || isStock) && !publicInfo?.isEditPublic ? editor.view_url : editor.edit_uri;

            if (editor.id === EditorID.MYOFFICE) {
                const re = new RegExp(`^${uri}(\\w+)/(\\d{20})(\\S+)$`);
                const match = requestUri.match(re);

                if (match && match.length === 4 && match[1] === 'attaches') {
                    return {
                        ext: 'edit',
                        id: editor.id,
                        mode: EditorMode.EDIT,
                        file: `/${match[1]}/${match[2]}${match[3]}`,
                        data: editor.edit_data && editor.edit_data(true, `${editor.id}`),
                    };
                }

                if (match && match.length === 4 && editor.can_view_attach) {
                    const messageId = match[2] + match[3];

                    return {
                        ext: match[3],
                        id: editor.id,
                        mode: EditorMode.EDIT,
                        file: messageId,
                        data: editor.edit_data && editor.edit_data(true, decodeURIComponent(messageId)),
                    };
                }
            }

            let re = new RegExp(`^${uri}((\\w+)(\\/(.*\\.(\\w{3,10})(\\?.+)?)?))$`);
            let match = requestUri.match(re);

            if (match && match.length === 7) {
                const file = match[3];

                return {
                    ext: match[5],
                    id: editor.id,
                    mode: EditorMode.EDIT,
                    file,
                    data: editor.edit_data && editor.edit_data(true, decodeURIComponent(file)),
                };
            }

            const createUri = editor.create_uri;
            re = new RegExp(`^${createUri}(\\w+)\\/((\\w+)(\\/(.+)?))$`);
            match = requestUri.match(re);

            if (match && match.length === 6) {
                const ext = match[1];

                const _create_extensions = editor.create_extensions;
                if (Array.isArray(_create_extensions) && _create_extensions.includes(ext)) {
                    const file = `/${match[2]}`;

                    return {
                        ext,
                        id: editor.id,
                        mode: EditorMode.CREATE,
                        file,
                        data: editor.edit_data && editor.edit_data(true, decodeURIComponent(file)),
                    };
                }
            }

            return null;
        })
        .filter(Boolean);

    return availableEditors[0];
};

const checkPublicEdit = ({
    features,
    editor,
    publicInfo,
    isAnonymUser,
    isPublicOrStock,
}: {
    features: IFeatures;
    browserInfo: IBrowserInfo;
    editor: ReturnType<typeof getChosenEditor>;
    publicInfo: IPublicInfo | undefined;
    isAnonymUser: boolean;
    isPublicOrStock: boolean;
}) => {
    if (!editor) {
        return false;
    }

    if (!publicInfo) {
        return true;
    }

    const isFile = publicInfo?.publicType === EPublicType.FILE;

    const isWopiForSafari = browser.isSafari() ? !!getFeatureByName(features, 'wopi-safari') : true;
    const isWopiFeature = !!getFeatureByName(features, 'wopi');

    const isWopi = isWopiForSafari && isWopiFeature && !getFeatureByName(features, 'myoffice-ces-co-edit-public-disabled');

    let isEditEnabled = false;

    const isEditAvailable = (ON_PREMISE || !isAnonymUser) && editor.mode === EditorMode.EDIT;

    /**
     * На пабликах редактирование доступно только для R7/R7Wopi и МоегоОфиса НО ТОЛЬКО для вопи версии
     * По этому для choseEditor доступны только эти редакторы
     *
     * ПРИЧЕМ, МОйОфис доступен только в WOPI режиме
     */
    if (isPublicOrStock) {
        if (editor.id === EditorID.R7) {
            isEditEnabled = editor.mode === EditorMode.EDIT || (editor.mode === EditorMode.VIEW && !publicInfo?.isDownloadablePublic);
        }
        // Добавить обработку открытия совместного редактирования
        else if (editor.id === EditorID.R7_WOPI && isEditAvailable) {
            isEditEnabled = true;
        } else if (editor.id === EditorID.MYOFFICE) {
            isEditEnabled = isEditAvailable && isWopi;
        }
    }

    return isPublicOrStock ? isEditEnabled && isFile : true;
};

export const getEditorList = (
    ctx: ICtx,
    json: IContext,
    requestParams: IRequestParams,
    browserInfo: IBrowserInfo,
    buildUrls: IBuildUrls,
    user: IUser,
    publicInfo: IPublicInfo | undefined,
    folder: IFolder | undefined,
    features: IFeatures,
    isPublic: boolean,
    isStock: boolean
): Editors | undefined => {
    const viewers: ServerEditor[] = [];
    const editors: ServerEditor[] = [];
    let myofficeAttachViewer: ServerEditor | undefined;

    const isEditPublic = !!publicInfo?.isEditPublic;
    const ownerUserGroup = folder && 'owner' in folder && folder?.owner ? getOwnerUserGroup(folder.owner) : undefined;

    const userGroup = getUserGroup({
        user,
        ownerUserGroup,
        isEditPublic,
        features,
        isPublic,
    });

    // eslint-disable-next-line complexity
    const selectEditor = (editorId: EditorID) => {
        const settings = getEditorsConfig(editorId, user);

        if (!settings) {
            return;
        }

        let onlineconfSettings: ReturnType<typeof getEditorSettingsFromOnlineConf> | undefined;
        let myofficeSettings: ReturnType<typeof getEditorSettingsFromOnlineConf> | undefined;

        // Логика перетерания конфигов из фичи с таким же именем (myoffice)
        if (browserInfo.isDesktop || browserInfo.isTablet || IS_TABLET_BROWSER) {
            const editorFromOnlineConf = getEditorSettingsFromOnlineConf(ctx, json, userGroup, editorId);

            if (editorId === EditorID.MYOFFICE) {
                myofficeSettings = editorFromOnlineConf;
            }
            onlineconfSettings = editorFromOnlineConf;
            if (onlineconfSettings) {
                onlineconfSettings = getFeatureByName(features, editorId) || onlineconfSettings;
            }
            if ((browserInfo.isTablet || browserInfo.isIpadOs || IS_TABLET_BROWSER) && !onlineconfSettings?.tablet) {
                return;
            }
            // Логика touch просмотрщиков и перетерания конфигов из фичи с таким же именем (myoffice-touch)
        } else if (browserInfo.isMobile || IS_MOBILE_BROWSER || IS_PHONE_BROWSER) {
            onlineconfSettings = getEditorSettingsFromOnlineConf(ctx, json, userGroup, editorId, true);
            if (onlineconfSettings) {
                onlineconfSettings = getFeatureByName(features, `${editorId}-touch`) || onlineconfSettings;
            }
        }

        if (!onlineconfSettings || onlineconfSettings.disabled) {
            return;
        }

        /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
         *  по умолчанию на аттачах
         */
        if (myofficeSettings?.view?.length) {
            const viewExtensions = myofficeSettings.view || [];
            myofficeAttachViewer = {
                ...settings,
                view_extensions: viewExtensions,
                can_view_attach: myofficeSettings.attach,
                default: Boolean(myofficeSettings.default),
                force: Boolean(myofficeSettings.force),
                order: myofficeSettings.order,
                pages: myofficeSettings.viewablePages,
            };
        }

        const isEdit = Boolean(onlineconfSettings.edit?.length || onlineconfSettings.create?.length);
        const isView = onlineconfSettings.view?.length;

        const editExtensions = onlineconfSettings.edit || [];
        const viewExtensions = onlineconfSettings.view || [];

        if (isEdit && editExtensions.length) {
            editors.push({
                ...settings,
                edit_extensions: editExtensions,
                create_extensions: onlineconfSettings.create || [],
                default: Boolean(onlineconfSettings.default),
                force: Boolean(onlineconfSettings.force),
                tablet: Boolean(onlineconfSettings.tablet),
                order: onlineconfSettings.order,
                attachOrder: onlineconfSettings.attachOrder,
                convert_extensions: onlineconfSettings.convert || {},
                pages: onlineconfSettings.editablePages,
            });
        }

        if (isView && viewExtensions.length) {
            viewers.push({
                ...settings,
                view_extensions: viewExtensions,
                can_view_attach: onlineconfSettings.attach,
                default: Boolean(onlineconfSettings.default),
                force: Boolean(onlineconfSettings.force),
                order: onlineconfSettings.order,
                attachOrder: onlineconfSettings.attachOrder,
                pages: onlineconfSettings.viewablePages,
            });
        }
    };

    availableEditors.forEach(selectEditor);

    if (!editors.length && !viewers.length) {
        return;
    }

    const { isPublicOrStock } = getStorage(requestParams.storage);
    const chosenEditor =
        getChosenEditor({ userGroup, features, publicInfo, folder, viewers, editors }) ||
        getEditorByUri(json, publicInfo, editors, isPublic, isStock);
    return {
        viewers,
        editors,
        myofficeAttachViewer,
        chosenEditor:
            chosenEditor &&
            checkPublicEdit({
                features,
                browserInfo,
                editor: chosenEditor,
                publicInfo,
                isAnonymUser: user.isAnonymUser,
                isPublicOrStock,
            })
                ? chosenEditor
                : undefined,
    };
};
