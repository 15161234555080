import classNames from 'clsx';
import React, { type ReactElement } from 'react';

import styles from './ShadeRight.css';

interface Props {
    children: ReactElement | string;
    isInHeader?: boolean;
    noShade?: boolean;
    theme?: 'folder' | 'publish';
}

export const ShadeRight = (props: Props): ReactElement => (
    <div
        className={classNames({
            [styles.root]: true,
            [styles.inHeader]: props.isInHeader,
            [styles.noShade]: props.noShade,
            [styles[`root_${props.theme}`]]: !!props.theme,
        })}
    >
        {props.children}
    </div>
);
