import throttle from 'lodash.throttle';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDomainFolder, moveItemsDialog } from 'reactApp/appHelpers/appHelpers';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { setDragging } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import type { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';

export const useDragAndDrop = (
    dragOverItem: TreeNodeData,
    isSelected: boolean,
    isDragging: boolean,
    itemsToDrop: (CloudItem | undefined)[]
) => {
    const [isDraggingOver, setDraggingOver] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { storage, id } = dragOverItem || {};
    const item = useSelector((state: RootState) => getItemById(state, id));
    const isReadonlyMountedOrDomainFolder = item && ['mounted', 'domain-folder'].includes(item.kind) && Boolean(item['isReadOnly']);

    const isValidDestination =
        !isSelected &&
        id !== '/domain' &&
        !isReadonlyMountedOrDomainFolder &&
        !itemsToDrop.some((el) => el?.id === id || (el?.id && id.startsWith(el?.id)));
    const hasNoMounted = IS_BIZ_USER ? !itemsToDrop.some((item) => item?.kind === 'mounted') : true;
    const isAllowedToDrop =
        [EStorageType.trashbin, EStorageType.home].includes(storage) &&
        isValidDestination &&
        dragOverItem.id !== ETreeRootIds.promocodes &&
        hasNoMounted;

    const onMouseMove = useCallback(() => {
        if (!isDraggingOver && isDragging) {
            setDraggingOver(true);
        }
    }, [isDragging, isDraggingOver]);

    const handleMouseLeave = useCallback(() => setDraggingOver(false), []);

    const handleStopDrag = useCallback(() => {
        if (!isDragging) {
            return;
        }

        dispatch(setDragging(false));
        const isValidItems = !itemsToDrop.some(isDomainFolder);

        if (!isDraggingOver || !isValidItems || !isAllowedToDrop) {
            dispatch(
                showSnackbarAction({
                    text: 'Невозможно переместить',
                    type: SnackbarTypes.failure,
                    id: 'drop-error',
                    closable: true,
                })
            );
            return;
        }

        if (storage === EStorageType.trashbin) {
            toolbarActions.remove();
            return;
        }

        moveItemsDialog(itemsToDrop, item);
    }, [dispatch, itemsToDrop, isDragging, isDraggingOver, isAllowedToDrop, storage, item]);

    const handleMouseMove = useMemo(() => throttle(onMouseMove, 300), [onMouseMove]);

    return { isDraggingOver, handleMouseMove, handleMouseLeave, handleStopDrag, isAllowedToDrop };
};
