import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { litresModalEnabled, litresModalSettings } from 'reactApp/appHelpers/featuresHelpers/features/litresModal';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { closeLitresModal } from 'reactApp/ui/LitresModal/LitresModal.helpers';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { getDays } from 'reactApp/utils/Period';
import { put, select } from 'typed-redux-saga';

const LITRES_PROMO_MODAL_ID = 'litres-promo-modal';
const LITRES_PROMO_MODAL_HITS = 'litres-promo-modal-hits';

export function* initLitresModal() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    const actionEndDate = parse(litresModalSettings.actionEndDate, defaultDateFormat, new Date());
    const isActionFinished = isValid(actionEndDate) ? isAfter(new Date(), addDays(actionEndDate, 1)) : false;

    if (isActionFinished || !isHome || !litresModalEnabled) {
        return;
    }
    const promoShownCount = storeHelper.getValue(LITRES_PROMO_MODAL_HITS) || 0;
    const daysCount = getDays(
        promoShownCount < litresModalSettings.firstTimeHits ? litresModalSettings.firstTimePeriod : litresModalSettings.restTimePeriod
    );
    const periodPassed = storeHelper.getValue(LITRES_PROMO_MODAL_ID) && storeHelper.isPassed(LITRES_PROMO_MODAL_ID, { daysCount });

    if (periodPassed) {
        storeHelper.clearStore(LITRES_PROMO_MODAL_ID);
    }

    if (!storeHelper.getValue(LITRES_PROMO_MODAL_ID)) {
        yield put(
            addPromoToShowQueue({
                type: EPromoType.litresModal,
                promoId: LITRES_PROMO_MODAL_ID,
                onShow() {
                    storeHelper.markAsShown(LITRES_PROMO_MODAL_ID);
                    storeHelper.setValue(LITRES_PROMO_MODAL_HITS, promoShownCount + 1);
                    emitAnalyticEvent(AnalyticEventNames.LITRES_MODAL_CLOUD_SHOW);
                    sendDwh({ eventCategory: 'modal_litres_show' });
                    store.dispatch(promoShown(EPromoType.quota));
                },
                onClick() {
                    emitAnalyticEvent(AnalyticEventNames.LITRES_MODAL_CLOUD_CLICK);
                    sendDwh({ eventCategory: 'modal_litres_click' });
                    opener('/promo/quota?fromLitres=true&utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=modal_litres');
                    store.dispatch(removePromo(EPromoType.quota));
                    closeLitresModal();
                },
                onCancel() {
                    emitAnalyticEvent(AnalyticEventNames.LITRES_MODAL_CLOUD_CANCEL);
                    sendDwh({ eventCategory: 'modal_litres_cancel' });
                    store.dispatch(removePromo(EPromoType.quota));
                    closeLitresModal();
                },
                onClose() {
                    emitAnalyticEvent(AnalyticEventNames.LITRES_MODAL_CLOUD_CLOSE);
                    sendDwh({ eventCategory: 'modal_litres_close' });
                    store.dispatch(removePromo(EPromoType.quota));
                    closeLitresModal();
                },
            })
        );
    }
}

export function* showLitresModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.litresModal));
    if (!promo) {
        return;
    }

    const { onShow, onCancel, onClick, onClose } = promo;

    openPopupHelper({
        popupName: popupNames.LITRES_MODAL,
        data: {
            onShow,
            onCancel,
            onClick,
            onClose,
        },
        onClose,
    });
}
