import { ReactComponent as MarkBlueIcon } from 'img/icons/mark.svg';
import comboIcon from 'img/icons/vk_combo.svg?url';
import React, { type ReactElement, type RefObject, PureComponent } from 'react';
import { FeaturesList } from 'reactApp/ui/FeaturesList/FeaturesList';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { noop } from 'reactApp/utils/helpers';

import styles from './ProTooltip.css';

const BlueMarkIcon = <MarkBlueIcon width={11} height={9} />;

const proTariffFeatures = [
    {
        text: 'История изменений файлов за 60 дней',
        key: 'hist',
        icon: BlueMarkIcon,
    },
    {
        text: 'Доступ ко всем возможностям <a href="https://disk-o.cloud/?utm_source=cloud&utm_medium=left_menu&utm_campaign=tultip" target="_blank" rel="noopener noreferrer" >Диск-О:</a>',
        key: 'disko',
        icon: BlueMarkIcon,
    },
    {
        text: 'Загрузка файлов до 100 ГБ',
        key: 'size',
        icon: BlueMarkIcon,
    },
    {
        text: `Подписка на <img class=${styles.icon} src=${comboIcon} alt="combo" /> на 3 месяца`,
        key: 'combo',
        icon: BlueMarkIcon,
    },
];
const monthOneComboFeature = {
    text: `Подписка на <img class=${styles.icon} src=${comboIcon} alt="combo" /> на 1 месяц`,
    key: 'combo',
    icon: BlueMarkIcon,
};

interface Props {
    id?: string;
    santa?: string;
    target?: RefObject<HTMLElement>;
    onClose();
}

export class ProTooltip extends PureComponent<Props> {
    static defaultProps = {
        onClose: noop,
    };

    private handleClose = (e) => {
        const { onClose } = this.props;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        onClose();
    };

    private handleClick = (e) => {
        if (e) {
            e.stopPropagation();
        }
    };

    public render(): ReactElement | null {
        const { target, santa } = this.props;

        if (!(target && target.current)) {
            return null;
        }

        if (santa !== 'pro_combo_3m') {
            proTariffFeatures[3] = monthOneComboFeature;
        }

        return (
            <FloatingTooltip target={target} placement={ETooltipPlacement.bottomStart} onClose={this.handleClose} qaId="tooltip-pro-tariff">
                <div className={styles.root} onClick={this.handleClick}>
                    <div className={styles.title}>
                        <span>PRO</span>тариф
                    </div>
                    <div className={styles.features}>
                        <FeaturesList showIcon customItems={proTariffFeatures} modificator="pro" />
                    </div>
                    <a className={styles.link} href="/subscriptions#pro" target="_blank">
                        Другие тарифы <span>PRO</span>
                    </a>
                </div>
            </FloatingTooltip>
        );
    }
}
