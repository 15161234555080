import { parse, stringify } from 'qs';
import { type ChangeEvent, type KeyboardEvent, useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewWebSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { useFallbackSearchUrl } from 'reactApp/hooks/useFallbackSearchUrl';
import { historyPush } from 'reactApp/modules/router/router.module';
import { sendSearchInputClickRadar } from 'reactApp/modules/search/search.analytics';
import { getSearchQueryString, parseSearchQuery } from 'reactApp/modules/search/search.helpers';
import { searchLoadHistory, searchReset } from 'reactApp/modules/search/search.module';
import { getSearchContentStatus, getSearchHistory, getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useWebSearchFilters } from 'reactApp/ui/WebSearch/hooks/useWebSearchFilters';
import {
    type IAction,
    type IFilters,
    type ISearchContent,
    type ISearchProps,
    type IState,
    EActions,
    ESearchOptionSource,
    ESearchOptionType,
} from 'reactApp/ui/WebSearch/WebSearch.data';

const getFilters = (state: IState, payload) => {
    const { type, source } = payload;
    const typeIsAllOrDocs = state.type === ESearchOptionType.all || state.type === ESearchOptionType.document;
    const newTypeIsAllOrDocs = type === ESearchOptionType.all || type === ESearchOptionType.document;
    const filters: IFilters = { type: type || state.type };
    if (typeIsAllOrDocs && source) {
        filters.source = source || state.source;
    } else {
        filters.source = newTypeIsAllOrDocs ? ESearchOptionSource.all : ESearchOptionSource.name;
    }
    return filters;
};

const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
        case EActions.close:
            return {
                active: false,
                focus: false,
                open: false,
                value: '',
                type: ESearchOptionType.all,
                source: ESearchOptionSource.all,
                forceSearch: false,
            };
        case EActions.setFocus:
            return {
                ...state,
                open: false,
                focus: Boolean(action.payload),
            };
        case EActions.setActive:
            return {
                ...state,
                active: Boolean(action.payload),
                focus: Boolean(action.payload),
            };
        case EActions.setValue:
            return {
                ...state,
                open: false,
                value: String(action.payload),
            };
        case EActions.setFilters:
            return {
                ...state,
                type: (action.payload as IFilters)?.type || state.type,
                source: (action.payload as IFilters)?.source || state.source,
            };
        case EActions.setOpenFilters:
            return {
                ...state,
                open: Boolean(action.payload),
            };
        case EActions.toggleFilters:
            return {
                ...state,
                focus: false,
                open: !state.open,
            };
        case EActions.searchValue:
            return {
                ...state,
                focus: false,
                open: false,
                value: String(action.payload),
                forceSearch: true,
            };
        case EActions.setPopupFilters:
            return {
                ...state,
                ...getFilters(state, action.payload),
            };
        case EActions.setForceSearch:
            return {
                ...state,
                forceSearch: Boolean(action.payload),
            };
    }
};

const getInputPlaceholderText = (storage: EStorageType) => {
    switch (storage) {
        case EStorageType.attaches:
            return 'Поиск по разделу';
        case EStorageType.alldocuments:
            return 'Поиск по Документам';
        default:
            return 'Поиск по Облаку';
    }
};

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
export const useWebSearchContent = ({ toggleSearch, isExpanded, onlyExpanded, storage }: ISearchProps): ISearchContent => {
    const { isAttaches, isSearch } = getStorage(storage);

    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);
    const historyItems = useSelector(getSearchHistory);
    const { path } = useSelector(getSearchRequestParams);
    const { query = '' } = useSelector(SettingsSelectors.getQueryParams) || {};

    const { setStartLocation } = useFallbackSearchUrl();
    const { type, source } = useWebSearchFilters();

    const [state, dispatch] = useReducer(reducer, {
        active: Boolean(onlyExpanded),
        focus: false,
        open: false,
        value: query,
        type,
        source,
        forceSearch: false,
    });

    const storeDispatch = useDispatch();

    const placeholder = getInputPlaceholderText(storage);

    const showSearchPage = useCallback(() => {
        const {
            query: _query,
            searchType: locationSearchType = ESearchOptionType.all,
            searchSource: _source,
            ...rest
        } = parse(window.location.search, { ignoreQueryPrefix: true });

        const { value, type, source } = state;

        if (!webSearchContentEnabled && !value && locationSearchType === ESearchOptionType.all) {
            return;
        }

        const searchType = webSearchContentEnabled ? type : locationSearchType;
        const searchSource = webSearchContentEnabled ? source : undefined;
        const searchOther = stringify(rest);
        const search = getSearchQueryString(value, webSearchContentEnabled ? { searchType, searchSource } : { searchType });

        dispatch({ type: EActions.setForceSearch, payload: false });

        if (!isSearch) {
            storeDispatch(searchReset());
            setStartLocation();
        }

        if (isAttaches) {
            storeDispatch(historyPush({ id: '/attaches', search: `${search}${searchOther ? `&${searchOther}` : ''}` }));
            return;
        }

        const queryString = stringify({ query: value, searchType, searchSource, ...rest }, { addQueryPrefix: true });
        // setQueryParams вызываем чтобы компонент SearchPage сразу получал query (иначе сначала query=undefined только потом в стор проставляется значение)
        const searchRequestParams = parse(queryString, { ignoreQueryPrefix: true });

        storeDispatch(setQueryParams(searchRequestParams));
        storeDispatch(historyPush({ id: '/search/', search: `${search}${searchOther ? `&${searchOther}` : ''}` }));
    }, [state, isAttaches, isSearch, setStartLocation, storeDispatch]);

    const onInputClick = useCallback(() => {
        if (isExpanded || onlyExpanded) {
            dispatch({ type: EActions.setFocus, payload: true });
        } else {
            toggleSearch(true);
            sendSearchInputClickRadar({ storage, path });
        }
    }, [isExpanded, onlyExpanded, storage, toggleSearch, path]);

    const onSearchClick = useCallback(() => {
        dispatch({ type: EActions.setFocus, payload: false });
        showSearchPage();
    }, [showSearchPage]);

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseSearchQuery(event.target?.value);
        dispatch({ type: EActions.setValue, payload: parsedValue });
    }, []);

    const onInputClear = useCallback(() => {
        dispatch({ type: EActions.setValue, payload: '' });
    }, []);

    const onInputBlur = useCallback(() => {
        if (!state.open && !historyItems.length) {
            dispatch({ type: EActions.setFocus, payload: false });
        }
    }, [state.open, historyItems.length]);

    const onClickAway = useCallback(() => {
        dispatch({ type: EActions.setFocus, payload: false });

        if (!onlyExpanded) {
            toggleSearch(false);
        }
    }, [onlyExpanded, toggleSearch]);

    const setActiveInput = useCallback(() => {
        dispatch({ type: EActions.setActive, payload: true });
    }, [dispatch]);

    const setFiltersClose = useCallback(() => {
        dispatch({ type: EActions.setOpenFilters, payload: false });
    }, []);

    const onKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                dispatch({ type: EActions.setFocus, payload: false });
                showSearchPage();
            }
        },
        [showSearchPage]
    );

    const searchValue = useCallback((payload: string) => {
        dispatch({ type: EActions.searchValue, payload });
    }, []);

    const setFilters = useCallback((payload: IFilters) => {
        dispatch({ type: EActions.setPopupFilters, payload });
    }, []);

    const onToggleFilters = useCallback(() => {
        dispatch({ type: EActions.toggleFilters });
    }, []);

    useEffect(() => {
        if (isNewWebSearchHistoryEnabled && state.forceSearch) {
            showSearchPage();
        }
    }, [state.forceSearch]);

    useEffect(() => {
        if (!isExpanded) {
            dispatch({ type: EActions.close });
        }
    }, [isExpanded]);

    useEffect(() => {
        if (!webSearchContentEnabled) {
            dispatch({ type: EActions.setFilters, payload: { type } });
        }
    }, [webSearchContentEnabled, type]);

    useEffect(() => {
        if (webSearchContentEnabled) {
            dispatch({ type: EActions.setFilters, payload: { type, source } });
        }
    }, [type, source, webSearchContentEnabled]);

    useEffect(() => {
        if (state.focus && isNewWebSearchHistoryEnabled) {
            storeDispatch(searchLoadHistory());
        }
    }, [state.focus, storeDispatch]);

    return {
        isFocus: state.focus,
        isOpen: state.open,
        value: state.value,
        type: state.type,
        source: state.source,
        placeholder,
        onSearchClick,
        onInputClick,
        onInputClear,
        onClickAway,
        onInputChange,
        onInputBlur,
        onKeyUp,
        onToggleFilters,
        setActiveInput,
        searchValue,
        setFilters,
        setFiltersClose,
        historyItems,
    };
};
