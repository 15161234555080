import { Icon20MenuOutline, Icon20MoreVertical } from '@vkontakte/icons';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { renderMobileLeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu.helpers';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { renderMobileUserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu.helpers';
import { NameComponent } from 'reactApp/ui/Name/Name';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { formatLocale } from 'reactApp/utils/formatDate';

import styles from './ViewerToolbarMobileNew.css';

interface ViewerToolbarMobileNew {
    file: IFile;
    openToolbarOptions: () => void;
}

export const ViewerToolbarMobileNew = memo<ViewerToolbarMobileNew>(({ file, openToolbarOptions }) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    const openOptions = useCallback(() => {
        openToolbarOptions();
    }, [openToolbarOptions]);

    const onMenuClick = useCallback(() => {
        if (isAnonymous) {
            renderMobileUserLoginMenu();
        } else {
            renderMobileLeftMenu({ animated: true, isRebranding: false });
        }
    }, [isAnonymous]);

    const date = file.date ? formatLocale(file.date, 'dd MMMM yyyy') : '';

    return (
        <div className={styles.root}>
            <div className={styles.menu} onClick={onMenuClick} data-qa-button={'menu'}>
                <Icon20MenuOutline width={20} height={20} fill="var(--vkui--color_text_contrast)" />
            </div>

            <div className={styles.info}>
                <div className={styles.name}>
                    <NameComponent name={file.name} extension={file.extension} />
                    {isViewerFileVerifiedByKaspersky && <ProtectedTooltip />}
                </div>
                <div className={styles.meta}>
                    {file.size}
                    {date ? ` • ${date}` : ''}
                </div>
            </div>
            <div className={styles.button} onClick={openOptions} data-qa-button={'options'}>
                <Icon20MoreVertical width={20} height={20} fill="#currentColor" />
            </div>
        </div>
    );
});

ViewerToolbarMobileNew.displayName = 'ViewerToolbarMobileNew';
