import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { PublicInfoAPICall } from 'reactApp/api/public/PublicInfoAPICall';
import { PublicThemeSetApiCall } from 'reactApp/api/public/PublicThemeSetApiCall';
import { getThemeId, getThemeOutsourceId } from 'reactApp/appHelpers/themeToolHelpers/utils';
import { applyThemeRequest } from 'reactApp/modules/theme.outsource/theme.outsource.module';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { getPublicThemeRequest, getPublicThemeSucces, setPublicTheme } from './themePublic.module';
import { ThemePublicSelectors } from './themePublic.selectors';
import type { GetPublicInfoParams, GetPublicThemeRequestProps, SetPublicThemeActionParams } from './themePublic.types';

const setPublicThemeApiCall = ({ themeId, publicId }: SetPublicThemeActionParams) =>
    new PublicThemeSetApiCall().makeRequest({ public_id: publicId, theme_id: getThemeId(themeId) });

const getPublicInfoApiCall = ({ public_id }: GetPublicInfoParams) => new PublicInfoAPICall().makeRequest({ public_id });

function* setPublicThemeHandler(action: PayloadAction<SetPublicThemeActionParams>) {
    try {
        const { publicId, themeId } = action.payload;
        const isNewTheme = yield select(ThemePublicSelectors.isNewPublicThemeSelected);
        if (isNewTheme) {
            yield put(applyThemeRequest(themeId));
            yield call(setPublicThemeApiCall, { themeId, publicId });
            yield put(getPublicThemeSucces({ themeId, public_id: publicId }));
        }
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

function* getPublicThemeRequestHandler(action: PayloadAction<GetPublicThemeRequestProps>) {
    try {
        const { public_id } = action.payload;
        const publicInfo: { data: { theme_id?: number } } = yield call(getPublicInfoApiCall, { public_id });
        const themeId = getThemeOutsourceId(publicInfo.data.theme_id);
        if (themeId) {
            yield put(getPublicThemeSucces({ themeId, public_id }));
        }
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* watchThemePublic() {
    yield takeEvery(setPublicTheme.toString(), setPublicThemeHandler);
    yield takeEvery(getPublicThemeRequest.toString(), getPublicThemeRequestHandler);
}
