import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import type { Product } from 'reactApp/types/Billing';
import { sendGa } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const sendBannerGa = (id: string, action: string): void => {
    sendGa('popup_trial_ga', action, id);
    sendGa('popup_trial_ab', action, 'ab');
};

type Handler = () => void;

interface Options {
    id: string;
    product: Product;
    groupAb?: string;
    handleClose: Handler;
    handleClick?: Handler;
    handleShow: Handler;
}

export const useBannerOfferCallback = ({ id, handleClose, handleClick, handleShow, product, groupAb = 'None' }: Options) => {
    const sendPayment = useCallback(
        (label) => {
            sendPaymentGa({ action: EPaymentGa.offerBanner, label, size_offer: product?.space?.original });
        },
        [product?.space?.original]
    );
    const sendPaymentAb = useCallback(
        (action) => {
            sendPaymentGa({
                action,
                label: '',
                size_offer: product.space.original,
                name_popup: 'BannerOffer',
                group: groupAb,
                eventCategory: 'pop-up',
                tariffId: product.id,
            });
        },
        [product.space.original]
    );

    const onClose = useCallback(() => {
        sendBannerGa(id, 'close');
        sendPayment('close');
        sendPaymentAb('close');
        emitAnalyticEvent(AnalyticEventNames.POPUP_BANNER_OFFER_CLOSE);

        handleClose();
    }, [id, handleClose, product]);

    const onClick = useCallback(async () => {
        sendBannerGa(id, 'click');
        sendPayment('click');
        sendPaymentAb('click');
        emitAnalyticEvent(AnalyticEventNames.POPUP_BANNER_OFFER_CLICK);

        handleClick?.();

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                productId: product.id,
                forceFastCheckout: true,
                onSuccess: () => {
                    emitAnalyticEvent(AnalyticEventNames.POPUP_BANNER_OFFER_BUY);
                    sendBannerGa(id, 'success');
                },
                paySource: 'cloud_present_trial',
            });

            handleClose();
        } catch (error) {}
    }, [id, handleClick, handleClose, product]);

    const onShow = useCallback(() => {
        sendBannerGa(id, 'show');
        sendPayment('view');
        sendPaymentAb('open');
        emitAnalyticEvent(AnalyticEventNames.POPUP_BANNER_OFFER_SHOW);

        handleShow();
    }, [id, handleShow, product]);

    return {
        onClick,
        onClose,
        onShow,
    };
};
