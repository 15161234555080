import type { State } from 'reactApp/modules/billing/billing.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getBillingState = (state: RootState): State => state.billing;

const getPaymentLink = createSelector(getBillingState, (state): string => state.link);
const getPaymentRenewLink = createSelector(getBillingState, (state): string => state.renewLink);
const getLoadingState = createSelector(
    getBillingState,
    ({ isLoading, isLoaded, hasError }): LoadingState => ({ isLoading, isLoaded, hasError })
);

export const BillingSelectors = {
    getPaymentLink,
    getPaymentRenewLink,
    getLoadingState,
};
