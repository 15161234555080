import React, { type ReactElement, type ReactEventHandler, memo } from 'react';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import type { Product } from 'reactApp/types/Billing';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { TariffFeatureList } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList';
import { useFeatures } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';

import styles from './DetailedTariffBenefitsContent.css';
import { BUY_BUTTON_TARIFF_BENEFITS_MODAL_ID } from './DetailedTariffBenefitsPopup.constants';

interface DetailedTariffBenefitsContentProps {
    product: Product;
    buttonText: string;
    onButtonClick: ReactEventHandler;
}

export const DetailedTariffBenefitsContent = memo(function DetailedTariffBenefitsContent({
    product,
    onButtonClick,
    buttonText,
}: DetailedTariffBenefitsContentProps): ReactElement {
    const [featureList] = useFeatures(product, true, true, { variant: 'all-active', shortText: false });
    const onClick: ReactEventHandler = (event) => {
        sendQuotaGa({
            action: 'modal-tariff',
            label: 'benefits_click',
            tariff: product.id,
            platform: 'web',
        });
        onButtonClick(event);
    };
    return (
        <div className={styles.root}>
            <div className={styles.featureList_white}>
                <TariffFeatureList accent large showIcon items={featureList} />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    id={BUY_BUTTON_TARIFF_BENEFITS_MODAL_ID}
                    theme="vk"
                    className={styles.button}
                    sizeMode={ButtonSizeMode.small}
                    primary
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
});
