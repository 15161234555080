import classNames from 'clsx';
import { ReactComponent as IconMore } from 'mrg-icons/src/mailru/navigation/more.svg';
import React, { type MouseEvent, memo, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { useDragAndDrop } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.hooks';
import type { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { DropNotify } from 'reactApp/ui/DropNotify/DropNotify';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { Link } from 'reactApp/ui/Link/Link';
import { sendGa } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './BreadcrumbsDropdown.css';

const Item = ({ onClick, item }: { item: BreadcrumbItem; onClick: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void }) => {
    const itemRef = useRef<HTMLDivElement | null>(null);

    const handleGoTo = useCallback(
        (e) => {
            onClick(e, item);
            sendGa('breadcrumbs', 'click');
        },
        [item, onClick]
    );
    const handleClick = useCallback(() => sendGa('breadcrumbs', 'click'), []);

    const { isDraggingOver, handleMouseMove, handleMouseLeave, handleStopDrag, isAllowedToDrop } = useDragAndDrop(item.id);

    return (
        <Link id={item.id} storage={item.storage} handleGoTo={handleGoTo} handleClick={handleClick} noLink={item?.noLink}>
            <div
                className={classNames(styles.item, {
                    [styles.itemDrop]: isDraggingOver,
                })}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleStopDrag}
                ref={itemRef}
            >
                <div className={styles.folderIcon}>
                    <FileIcon size={18} type="folder" />
                </div>
                <Hint text={!isDraggingOver ? item.text : ''}>
                    <div className={styles.text}>{item.text}</div>
                </Hint>
                {isDraggingOver && <DropNotify dragOverName={item.text} isAllowedToDrop={isAllowedToDrop} target={itemRef} />}
            </div>
        </Link>
    );
};

export const BreadcrumbsDropdown = memo(
    ({
        list,
        onClick,
        isInlineIntegration,
    }: {
        list: BreadcrumbItem[];
        onClick: (e: MouseEvent, breadcrumb: BreadcrumbItem) => void;
        isInlineIntegration?: boolean;
    }) => {
        const [isOpen, setOpen] = useState(false);
        const buttonRef = useRef<HTMLButtonElement | null>(null);
        const isDragging = useSelector(SelectionsSelectors.isDragging);

        const calcPosition = useCallback(() => {
            if (buttonRef.current) {
                const elRect = buttonRef.current.getBoundingClientRect();

                const posY = elRect.top + elRect.height + window.scrollY;

                return {
                    posY,
                    posX: elRect.left,
                    maxHeight: `${window.innerHeight - posY - 8}px`,
                };
            }

            return { posY: 0, posX: 0 };
        }, []);

        const close = useCallback(() => setOpen(false), []);

        const handleOnDotsClick = useCallback(() => setOpen((prev) => !prev), [setOpen]);
        const handleOnDotsHover = useCallback(() => setOpen(true), [setOpen]);

        const handleOnClick = useCallback(
            (e: MouseEvent, breadcrumb: BreadcrumbItem) => {
                close();

                onClick(e, breadcrumb);
            },
            [close, onClick]
        );

        const renderItem = useCallback((item) => <Item item={item} onClick={handleOnClick} />, [handleOnClick]);

        return (
            <>
                <button
                    ref={buttonRef}
                    onClick={handleOnDotsClick}
                    onMouseOver={isDragging ? handleOnDotsHover : noop}
                    type="button"
                    className={classNames(styles.more, {
                        [styles.more_active]: isOpen,
                        [styles.more_inlineIntegration]: isInlineIntegration,
                        [styles.more_activeInlineIntegration]: isInlineIntegration && isOpen,
                    })}
                >
                    <IconMore />
                </button>
                {isOpen && (
                    <DropdownList
                        posX={0}
                        posY={0}
                        list={list}
                        closeOnScroll
                        calcPosition={calcPosition}
                        close={close}
                        theme={DropdownTheme.breadcrumbs}
                        renderItem={renderItem}
                        parentRef={buttonRef}
                        gaId="breadcrumbs"
                        doNotGaSendId
                        closeOnMouseLeave
                        isInlineIntegration={isInlineIntegration}
                    />
                )}
            </>
        );
    }
);

BreadcrumbsDropdown.displayName = 'BreadcrumbsDropdown';
