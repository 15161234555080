import { getExtension, getItemNameWithoutExt, getNameById, getParent } from 'reactApp/modules/file/utils';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';

const urlBuilder = new UrlBuilder();

export const getNewItem = ({ item, newId, isMounted = item.isMounted }: { item: any; newId: string; isMounted?: boolean }) => {
    const name = getNameById(newId);
    const parent = getParent(newId);
    const ext = getExtension(name);

    const thumbnails = urlBuilder.getThumb({
        hash: item.hash,
        ext,
        id: newId,
        size: item.size,
        kind: item.kind,
        name,
        path: newId,
        isPublic: false,
        isStock: false,
        dwl_token: null,
        weblink: '',
    });

    const url = urlBuilder.getUrls({
        ext,
        isPublic: false,
        id: newId,
        weblink: '',
        size: item.size,
        kind: item.kind,
        subKind: item.subKind,
        isStock: false,
        name,
        path: newId,
    });

    return {
        ...item,
        url,
        name,
        ext,
        parent,
        ext2: ext,
        id: newId,
        thumbnails,
        home: newId,
        isMounted,
        parentFolder: parent,
        nameWithoutExt: getItemNameWithoutExt(name, ext),
    };
};
