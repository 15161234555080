import { PaymentWindowType } from 'reactApp/modules/payment/payment.helpers';
import type { Tariff } from 'reactApp/types/Billing';

export interface IProps {
    productId: string;
    onClose: () => void;
    onShow?: () => void;
    onSuccess?: () => void;
    onDmrResize?: (height) => void;
    type: EPaymentModalType;
    tariff?: Tariff;
    isMarketingPromo?: boolean;
    isFrame?: boolean;
    withoutSidebar?: boolean;
    paymentDescription?: string;
    paySource?: string;
    featuresList?: string[];
    options?: Options;
    title?: string;
    hideOtherTariffs?: boolean;
    hideSwitchSidebarBlock?: boolean;
    onPayBtnClick?: () => void;
}

export enum EPaymentModalType {
    trial = 'trial',
    fastcheckout = 'fastcheckout',
    attachesTrial = 'attachesTrial',
}

export const PaymentModalEventCategory = {
    [EPaymentModalType.fastcheckout]: 'fastCheckout',
    [EPaymentModalType.trial]: 'sdbar-trial',
    [EPaymentModalType.attachesTrial]: 'sdbar-atchs-trial',
} as const;

export const PaymentModalFrameEventCategory = {
    [EPaymentModalType.fastcheckout]: 'buy-frame',
} as const;

export const PaymentModalToPaymentWindowType = {
    [EPaymentModalType.fastcheckout]: PaymentWindowType.fastcheckout,
    [EPaymentModalType.trial]: PaymentWindowType.promo,
} as const;

export interface Options {
    dmrWithPaddings?: boolean;
    sidebarNewContent?: boolean;
}
