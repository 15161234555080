import type { PayloadAction } from '@reduxjs/toolkit';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { sendSuperAppXray, superAppSubmetrics } from 'reactApp/appHelpers/sendSuperAppAnalytics';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { getFacesList } from 'reactApp/modules/faces/faces.selectors';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { getCurrentPublicItem, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import type { PublicFolder } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { closeUploadPanelAction, openUploadPanelAction } from 'reactApp/modules/upload/upload.module';
import {
    isProvocationShown as isProvocationShownSelector,
    selectButtonTypeClick,
    selectUploadFrom,
} from 'reactApp/modules/upload/upload.selectors';
import { cancelUploading, clearUploadedQueue } from 'reactApp/modules/uploading/uploading.module';
import { askUserForConflictFiles, askUserForPaymentFiles, createFolderIfNotExists } from 'reactApp/modules/uploadList/oldUploadListBridge';
import { cancelAllUploadFile, hideErrorFiles, removeUploadFiles } from 'reactApp/modules/uploadList/sagas/actionsOnFile.saga';
import { checkProgressStatus } from 'reactApp/modules/uploadList/sagas/progress.saga';
import { deferredUpdateUploadFile, updateUploadFiles } from 'reactApp/modules/uploadList/sagas/updateUploadFile.saga';
import { isFileAutoFixError, isFolder } from 'reactApp/modules/uploadList/uploadList.getters';
import { animationDelay, removeFields } from 'reactApp/modules/uploadList/uploadList.helpers';
import { type IInputFile, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { getGroupedByFolderInputFiles, getProblemItemsGroupedByFolder } from 'reactApp/modules/uploadList/uploadList.selectors';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { sendProvocationGa } from 'reactApp/ui/Datalist/UploadProvocation/UploadProvocation.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { filesPluralGenitive } from 'reactApp/utils/pluralHelpers';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { EQueryParams } from 'server/helpers/getRequestParams';

import {
    cancelAllUploadFileAction,
    deferredUpdateUploadFileAction,
    removeUploadFilesAction,
    setClearStateAction,
    setHighlightProblemFilesAction,
    setHighlightSelectedErrorAction,
    setInputFilesAction,
    setProgressStatusAction,
    setSelectedErrorAction,
    setShowUploaderAction,
    updateUploadFilesAction,
    uploadDescriptor,
} from './uploadList.module';

function* setProgressStatus(action) {
    const { payload } = action;

    // eslint-disable-next-line sonarjs/no-small-switch
    switch (payload.status) {
        case EProgressStatus.CANCEL_ALL: {
            if (askUserForPaymentFiles) {
                yield call(askUserForPaymentFiles, 'skip');
            }

            if (askUserForConflictFiles) {
                yield call(askUserForConflictFiles, { option: 'skip', saveForAll: true });
            }

            yield put(cancelAllUploadFileAction());
            yield put(cancelUploading());
            yield put(setProgressStatusAction({ status: EProgressStatus.COMPLETE }));
            break;
        }

        case EProgressStatus.STARTING:
            yield put(setShowUploaderAction(true));
            break;
    }
}

function* setInputFiles(action: PayloadAction<IInputFile[]>) {
    const { payload } = action;
    const autoFixFiles = payload.filter((item) => isFileAutoFixError(item));
    const countAutoFix = autoFixFiles.length;
    const uploadFrom = yield select(selectUploadFrom);
    const buttonType = yield select(selectButtonTypeClick);
    const storage = yield select(getCurrentStorage);
    const files = yield select(getGroupedByFolderInputFiles);
    const outerFolders = files?.filter((file) => isFolder(file));
    const sendCountFolders = buttonType === 'folder' && uploadFrom === 'upload-area';

    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    const item: PublicFolder = yield select(getCurrentPublicItem);
    const isOwn: ReturnType<typeof isOwnPublic> = yield select(isOwnPublic);
    const faces = yield select(getFacesList);

    yield call(sendPaymentGa, {
        eventCategory: ECategoryGa.upload,
        action: 'upload-files',
        countFiles: payload.length,
        source: uploadFrom,
        source_storage: storage,
        typeUpload: buttonType,
        countOuterFolders: sendCountFolders ? outerFolders?.length : null,
    });

    if (isSuperAppWebView) {
        sendSuperAppXray(superAppSubmetrics.uploadFiles, {
            id_public: isOwn && item?.home ? sha1(item.home) : item.id,
            id_folder: item.id,
            type_public: 'folder',
            count_objects: item.count.all,
            owner: isOwn,
            have_faces: Boolean(faces?.length),
            count_faces: faces?.length,
        });
    }

    // Временно для того, чтобы посчитать метрики с провокации
    // @see: CLOUDWEB-12411
    const isProvocationShown = yield select(isProvocationShownSelector);

    if (isProvocationShown) {
        yield call(sendProvocationGa, 'upload', uploadFrom);
    }

    if (uploadFrom === 'provocation') {
        yield call(sendProvocationGa, 'upload-success');
    }

    yield put(setShowUploaderAction(true));

    yield call(checkProgressStatus);

    if (!countAutoFix) {
        return;
    }

    yield put(
        showSnackbarAction({
            type: SnackbarTypes.success,
            text: `Облако автоматически исправило названия ${countAutoFix} ${filesPluralGenitive(countAutoFix)}`,
            closable: true,
            id: 'uploader-info',
        })
    );
}

function* setShowUploader(action: PayloadAction<boolean>) {
    const { payload } = action;

    if (payload) {
        yield put(openUploadPanelAction());
        return;
    }

    yield put(clearUploadedQueue());
    yield put(setClearStateAction());
    yield put(closeUploadPanelAction());
}

function* setHighlightSelectedError(action: PayloadAction<boolean>) {
    const { payload } = action;

    if (payload) {
        yield delay(animationDelay);
        yield put(setHighlightSelectedErrorAction(false));
    }
}

function* highlightProblemFiles() {
    const problemItemsGroupedByFolder: IInputFile[] = yield select(getProblemItemsGroupedByFolder);

    for (const element of problemItemsGroupedByFolder) {
        const editedFile: IInputFile = removeFields(element);
        editedFile.highlight = true;

        yield put(updateUploadFilesAction(editedFile));
    }
}

/**
 * В разделах личные документы и альбомы необходимо предварительно создать соответствующую папку
 */
function* handleUploadDescriptor(action: PayloadAction<{ name: string }>) {
    const { name } = action.payload;

    yield call(handleCreateFolderForUpload, name);
}

export function* handleCreateFolderForUpload(name: string) {
    try {
        const isFolderExists = yield select(getHomeItemById, `/${name}`);

        if (!isFolderExists) {
            try {
                yield addFolderHelper({
                    item: name,
                    parent: '/',
                    storage: EStorageType.home,
                    conflictMode: EConflictMode.STRICT,
                });
            } catch (error) {
                // тут игнорируем, потому что если папка уже существует, то апи ответит 400
            }
        }

        yield createFolderIfNotExists?.();
    } catch (error) {
        logger.error(error);
    }
}

export function* watchUploadListRoot() {
    yield takeEvery(updateUploadFilesAction.toString(), updateUploadFiles);
    yield takeLatest(setProgressStatusAction.toString(), setProgressStatus);
    yield takeEvery(setInputFilesAction.toString(), setInputFiles);
    yield takeLatest(setShowUploaderAction.toString(), setShowUploader);
    yield takeEvery(setHighlightSelectedErrorAction.toString(), setHighlightSelectedError);
    yield takeEvery(deferredUpdateUploadFileAction.toString(), deferredUpdateUploadFile);
    yield takeEvery(removeUploadFilesAction.toString(), removeUploadFiles);
    yield takeLatest(cancelAllUploadFileAction.toString(), cancelAllUploadFile);
    yield takeLatest(setSelectedErrorAction.toString(), hideErrorFiles);
    yield takeEvery(setHighlightProblemFilesAction.toString(), highlightProblemFiles);
    yield takeEvery(uploadDescriptor.toString(), handleUploadDescriptor);
}
