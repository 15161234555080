import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import type { IWeblinkAccessControlState } from './weblinkAccessControl.types';

const getWeblinkAccessControlState = (state: RootState): IWeblinkAccessControlState => state.weblinkAccessControl;

export const isAccessControlListLoading = createSelector(
    getWeblinkAccessControlState,
    (state: IWeblinkAccessControlState) => state.isLoading
);

export const getAccessControlList = createSelector(getWeblinkAccessControlState, (state: IWeblinkAccessControlState) => state.acList);
export const getError = createSelector(getWeblinkAccessControlState, (state: IWeblinkAccessControlState) => state.error);

export const getACL = createSelector(getAccessControlList, contactsSelectors.getContacts, (acList, contacts) => {
    if (!acList?.length || !contacts?.length) {
        return acList;
    }

    const contactsMap = contacts.reduce((result, { email, name }) => {
        result[email] = name;
        return result;
    }, {});

    return acList.map((item) => {
        if ((!item.name || item.name === item.email) && contactsMap[item.email]) {
            return { ...item, name: contactsMap[item.email] };
        }

        return item;
    });
});

export const getUserAccessRights = createSelector(
    getAccessControlList,
    (state, email) => email,
    (acList, userEmail) => (acList.find(({ email }) => email === userEmail) || {}).accessRights
);
