export enum EPageId {
    pdfEdit = 'pdf-edit',
    attaches = 'attaches',
    buy = 'buy',
    choice = 'choice',
    // tempexp_17127-next-line
    buyDisableAds = 'buy-disable-ads',
    discount = 'discount',
    favorites = 'favorites',
    feed = 'feed',
    gallery = 'gallery',
    home = 'home',
    notFound = 'not-found',
    personalDocuments = 'documents-type',
    personalDocumentsMain = 'documents-main',
    promocodes = 'promocodes',
    recommend = 'recommend',
    sharedAutodelete = 'shared-autodelete',
    singleQuota = 'single-quota',
    singleQuotaIOS = 'quota-ios',
    singleQuotaAndroid = 'quota-and',
    singleQuotaPartner = 'quota-partner',
    singleQuotaTariffs = 'quota-tariffs',
    start = 'start',
    subscriptions = 'subscriptions',
    trashbin = 'trashbin',
    uploader = 'uploader',
    albums = 'albums',
    albumsMain = 'albumsMain',
    sharedLinks = 'shared-links',
    sharedIncoming = 'shared-incoming',
    search = 'search',
    embedded = 'embedded',
    sharingAttach = 'sharing-attach',
    alldocuments = 'alldocuments',
    integration = 'integration',
    inlineIntegration = 'inline-integration',
    family = 'family',
    public = 'public',
    editor = 'editor',
    singleQuotaPro = 'single-quota-pro',
    businessTemplatesRoot = 'business-templates-root',
    businessTemplatesCategory = 'business-templates-category',
}
