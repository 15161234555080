import classNames from 'clsx';
import React, { type VFC } from 'react';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { DiscountCard } from 'reactApp/sections/MobileSubscriptionsPage/Discounts/Components/DiscountCard/DiscountCard';
import {
    ACTIVATE_PROMOCODE_CARD,
    buttonTextMap,
} from 'reactApp/sections/MobileSubscriptionsPage/Discounts/Components/DiscountCard/DiscountCard.constant';

import styles from './EmptyDiscounts.css';

const onButtonClick = () => {
    closePopupHelper(popupNames.MOBILE_DISCOUNTS);
};

export const EmptyDiscounts: VFC<{ isCompact?: boolean; isRebrandingQuotaLanding?: boolean }> = ({
    isCompact = false,
    isRebrandingQuotaLanding = false,
}) => {
    return (
        <div
            className={classNames(styles.root, {
                [styles.root_compact]: isCompact,
                [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            })}
        >
            <div className={styles.img} />
            <div className={styles.contentWrapper}>
                <p className={styles.title}>Акций пока нет</p>
                <p className={styles.text}>Скоро мы что-нибудь придумаем!</p>
            </div>
            <DiscountCard
                {...ACTIVATE_PROMOCODE_CARD}
                buttonText={buttonTextMap[ACTIVATE_PROMOCODE_CARD.type]}
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                onClick={onButtonClick}
            />
        </div>
    );
};
