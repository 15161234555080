import { getFolderPath } from 'reactApp/modules/file/utils';
import { changeHomeHistory, loadFolderSuccess } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { getIntegrationClient, getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { isFileInCurrentFolder } from 'reactApp/modules/modifying/modifying.helpers';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { routerStart } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { folderCloudPath } from 'reactApp/modules/uploadList/uploadList.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    resetSelect,
    scrollToHomeItemAction,
    selectOne,
    selectSome,
    selectTo,
    setScrollToItemState,
    startSelecting,
    toggle,
    toggleAll,
} from './selections.actions';
import { SelectionsSelectors } from './selections.selectors';

export function* resetSelected() {
    const selected = yield select(SelectionsSelectors.getSelectedIdxs);

    if (!selected.length) {
        return;
    }

    /* tempexp_15344-start */
    const isSuccessAuthWhenDownload = yield select(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);

    if (isSuccessAuthWhenDownload) {
        return;
    }
    /* tempexp_15344-end */

    yield put(resetSelect());
}

function* scrollToItem({ payload }: ReturnType<typeof scrollToHomeItemAction>) {
    const storage = yield select(getCurrentStorage);
    const { isIntegration } = getStorage(storage);
    const integrationQueryString = yield select(getIntegrationQueryString);

    const { isFolder, itemId = '', parentFolderId } = payload;
    const fileInCurrentFolder = yield call(isFileInCurrentFolder, itemId);
    const folderIdByPath = yield call(folderCloudPath, itemId);
    const folderId = parentFolderId || folderIdByPath;

    let pathToItem = '';

    if (isFolder && itemId) {
        pathToItem = itemId;
    } else if (!fileInCurrentFolder || !itemId) {
        pathToItem = folderId;
    }

    if (pathToItem) {
        yield put(setScrollToItemState({ itemId, needScroll: true }));
        yield put(changeHomeHistory({ id: pathToItem, search: isIntegration ? integrationQueryString : '' }));
    } else {
        yield put(scrollToItemAction(itemId));
    }
}

function* routeChange() {
    const scrollToItemState = yield select(SelectionsSelectors.getScrollToItemState);

    if (scrollToItemState.needScroll) {
        const item = yield select(getHomeItemById, getFolderPath(scrollToItemState.itemId));

        if (!item?.isLoaded) {
            yield take(loadFolderSuccess);
        }

        yield put(scrollToItemAction(scrollToItemState.itemId));
        yield put(setScrollToItemState({ itemId: '', needScroll: false }));
    }
}

const INTEGRATION_WINDOW_SMALL_WIDTH = 430;
const getIsWindowWidthSmall = () => window.innerWidth <= INTEGRATION_WINDOW_SMALL_WIDTH;

let lastSelectionsCount = 0;
function* takeEverySelectionAction() {
    const selected = yield select(SelectionsSelectors.getSelectedIdxs);
    const storage = yield select(getCurrentStorage);
    const isSelecting = yield select(SelectionsSelectors.isSelecting);
    const { isTutoria } = yield select(getIntegrationClient);
    const isWindowWidthSmall = getIsWindowWidthSmall();

    // В сторе integration режим выделения должен быть всегда активен, кроме тутории для узкого экрана
    if (!isSelecting && storage === EStorageType.integration && !(isTutoria && isWindowWidthSmall)) {
        yield put(startSelecting());
    }

    /* Ловим только выделение */
    if (selected.length > lastSelectionsCount) {
        sendPaymentGa({ eventCategory: ECategoryGa.choose, action: 'file', section: storage });
    }

    lastSelectionsCount = selected.length;
}

export function* watchSelections() {
    yield takeLatest(scrollToHomeItemAction.toString(), scrollToItem);
    yield takeEvery(routerStart.toString(), routeChange);

    yield takeEvery(
        [selectOne.toString(), toggle.toString(), selectSome.toString(), selectTo.toString(), toggleAll.toString(), resetSelect.toString()],
        takeEverySelectionAction
    );
}
