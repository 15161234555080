import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { fromUnixTime } from 'date-fns';
import type {
    ActivitiesState,
    RequestActivitiesPaylod,
    RequestActivitiesSuccessPaylod,
} from 'reactApp/modules/activities/activities.types';

const initialState: ActivitiesState = {
    currentItemId: '',
    activities: [],
    loading: false,
    isError: false,
};

export const requestActivities = createAction<RequestActivitiesPaylod>('activities/requestActivities');
export const requestActivitiesSuccess = createAction<RequestActivitiesSuccessPaylod>('activities/requestActivitiesSuccess');
export const requestActivitiesFail = createAction<void>('activities/requestActivitiesFail');
export const resetActivities = createAction<void>('activities/resetActivities');

export const activitiesReducer = createReducer(initialState, {
    [requestActivities.type]: (state, action: PayloadAction<RequestActivitiesPaylod>) => {
        state.isError = false;
        state.loading = true;
        state.activities = state.currentItemId !== action.payload.path ? [] : state.activities;
        state.currentItemId = action.payload.path;
    },
    [requestActivitiesSuccess.type]: (state, action: PayloadAction<RequestActivitiesSuccessPaylod>) => {
        state.loading = false;
        state.activities = action.payload.length
            ? state.activities.concat(
                  action.payload.map((item) => ({
                      ...item,
                      time: fromUnixTime(item.time).getTime(),
                  }))
              )
            : state.activities;
    },
    [resetActivities.type]: (state) => {
        state.activities = [];
        state.currentItemId = '';
        state.loading = false;
        state.isError = false;
    },
    [requestActivitiesFail.type]: (state) => {
        state.activities = [];
        state.currentItemId = '';
        state.loading = false;
        state.isError = true;
    },
});
