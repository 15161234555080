import { logger } from 'lib/logger';
import { type IDocumentsApiArgs, DocumentsApiCall, SharedIncomingApiCall } from 'reactApp/api/DocumentsApiCall';
import { allDocumentsRadars } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import {
    allDocumentsLoadError,
    allDocumentsLoadRequest,
    allDocumentsLoadSuccess,
    allDocumentsMoreRequest,
} from 'reactApp/modules/allDocuments/allDocuments.module';
import {
    getAllDocumentsCurrentType,
    getAllDocumentsCursor,
    getDocumentsState,
    getExtensionsForType,
} from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { AllDocumentsXrayTypes, EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { normalizeBaseFeedItem } from 'reactApp/modules/feed/feed.helpers';
import type { FeedApiResponse, SharedFolderApiResponse } from 'reactApp/modules/feed/feed.types';
import { addHomeItemsToStore } from 'reactApp/modules/home/home.actions';
import type { HomeFile } from 'reactApp/modules/home/home.types';
import { makeNewDocumentError, makeNewDocumentSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.alldocuments);

const getAllDocumentsList = async ({ limit = LOAD_LIMIT, cursor = '', exts = '' }: IDocumentsApiArgs) =>
    (await new DocumentsApiCall().makeRequest({ limit, cursor, exts }))?.data?.body;

const getMountedFolders = async () => (await new SharedIncomingApiCall().makeRequest())?.data?.body;

function* handleLoadAllDocuments(action: ReturnType<typeof allDocumentsLoadRequest>) {
    const currentDocType = yield* select(getAllDocumentsCurrentType);
    const documentType = action.payload || currentDocType || EAllDocumentsType.document;
    const extensions = yield* select(getExtensionsForType, documentType);

    if (extensions.length === 0) {
        return;
    }

    try {
        const data = yield* call(getAllDocumentsList, { exts: extensions.join(',') });
        const folders = yield* call(getMountedFolders);

        yield* call(handleLoadListApiResponse, documentType, data, folders);
    } catch (error: any) {
        logger.error(error);
        yield put(allDocumentsLoadError({ type: documentType, error: error.toString() }));
    }
}

function* handleLoadMoreAllDocuments() {
    const documentType = yield* select(getAllDocumentsCurrentType);
    const extensions = yield* select(getExtensionsForType, documentType);
    const cursor = yield* select(getAllDocumentsCursor);

    if (extensions.length === 0) {
        return;
    }

    try {
        const data = yield* call(getAllDocumentsList, { exts: extensions.join(','), cursor });

        yield* call(handleLoadListApiResponse, documentType || EAllDocumentsType.document, data);
    } catch (error: any) {
        logger.error(error);
        yield put(allDocumentsLoadError({ type: documentType, error: error.toString() }));
    }
}

function* handleLoadListApiResponse(documentType: EAllDocumentsType, data: FeedApiResponse, mountedFolders?: SharedFolderApiResponse) {
    const items: HomeFile[] = [];
    const indexes: string[] = [];
    let docsSize = 0;

    if (data?.objects?.length) {
        data.objects.forEach((apiTem) => {
            const item = {
                ...normalizeBaseFeedItem(apiTem),
                storage: EStorageType.home,
                isMounted: false,
            };
            docsSize += item.size;
            items.push(item as HomeFile);
            indexes.push(item.id);
        });
    }

    if (mountedFolders?.list?.length) {
        mountedFolders?.list?.forEach((apiTem) => {
            const item = {
                ...normalizeBaseFeedItem(apiTem),
                storage: EStorageType.home,
                isMounted: true,
                isFolder: true,
                kind: 'mounted',
                isReadOnly: apiTem.access === 'read_only',
            };
            items.push(item as HomeFile);
        });
    }

    if (items.length) {
        yield put(addHomeItemsToStore(items));
    }

    yield put(allDocumentsLoadSuccess({ type: documentType, data, items: indexes, docsSize }));
}

/**
 * Генератор для отправки технических метрик для раздела документов.
 *
 * @param {string} action Наименование действия.
 * @param {AllDocumentsXrayTypes} xrayType Тип радара.
 * @param {documentType} [documentType] Кастомный тип документа, который можно прокинуть вручную. По умолчанию используется тип в зависимости от текущего раздела документов.
 */
export function* handleSendAllDocumentsXray({
    action,
    xrayType,
    documentType = null,
}: {
    action: string;
    xrayType: AllDocumentsXrayTypes;
    documentType?: EAllDocumentsType | null;
}) {
    const storage = yield select(getCurrentStorage);

    if (storage === EStorageType.alldocuments) {
        const { currentDocType } = yield select(getDocumentsState);

        const radars = allDocumentsRadars({ section: documentType ?? currentDocType, action });

        yield radars[xrayType]();
    }
}

export function* handleSendCreatedDocumentSuccessXray() {
    const { currentDocType } = yield select(getDocumentsState);

    yield handleSendAllDocumentsXray({
        action: 'create_file',
        xrayType: AllDocumentsXrayTypes.onSuccess,
        documentType: currentDocType,
    });
}

export function* handleSendCreatedDocumentErrorXray() {
    const { currentDocType } = yield select(getDocumentsState);

    yield handleSendAllDocumentsXray({
        action: 'create_file',
        xrayType: AllDocumentsXrayTypes.onError,
        documentType: currentDocType,
    });
}

export function* watchAllDocuments() {
    yield takeEvery(allDocumentsLoadRequest.toString(), handleLoadAllDocuments);
    yield takeEvery(allDocumentsMoreRequest.toString(), handleLoadMoreAllDocuments);
    yield takeEvery(makeNewDocumentSuccess.toString(), handleLoadAllDocuments);
    yield takeEvery(makeNewDocumentSuccess.toString(), handleSendCreatedDocumentSuccessXray);
    yield takeEvery(makeNewDocumentError.toString(), handleSendCreatedDocumentErrorXray);
}
