import React from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import type { RenamedFileMeta } from 'reactApp/appHelpers/updateRenamedFileInsideViewer';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { isWhiteEditorHeader } from 'reactApp/modules/features/features.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EditorHeader as BlueEditorHeader } from 'reactApp/ui/EditorHeader/BlueEditorHeader/EditorHeader';
import { EditorHeader as WhiteEditorHeader } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/EditorHeader';

export interface EditorHeaderMapState {
    backLink: string;
    updateWeblinkInUrl(id: EditorItem['id'] | undefined, weblink: EditorItem['weblink']);
    renameItem(file: EditorItem | null, newName: string);
    isAnonymous: boolean;
    enableRenaming: boolean;
    file: EditorItem | null;
    myOfficePromoEditorEnabled?: boolean;
    id?: string;
    isWopiFeatureEnabled?: boolean;
    storage?: EStorageType;
}

export interface EditorHeaderProps extends EditorHeaderMapState {
    showBubble: boolean;
    onBubbleShow();
    toggleFavorite(file: EditorItem);
    loadUser();
    showNotify(options);
    onBackToCloud: (() => void) | null;
    isPublic: boolean;
    isStock: boolean;
    enableFavorites: boolean;
    itemSelector(state);
    returnButtonTitle?: string;
    downloadDropdownList?: any[];
    getRenamedFileIdInsideViewer: () => RenamedFileMeta;
    setRenamedFileIdInsideViewer: (init: RenamedFileMeta) => void;
    itemStorage?: EStorageType | null;
    licenseExpired?: boolean;
    isViewer?: boolean;
    gaCategory?: string;
    editorId?: string;
}

// TODO удалить папку BlueEditorHeader и данный компонент, когда полностью перейдем на белую шапку
//  Нужно будет напрямую использовать WhiteEditorHeader (содержимое соответствующей папки просто перенести в EditorHeader,
//  а саму папку WhiteEditorHeader удалить. https://jira.vk.team/browse/CLOUDWEB-15326)
export const EditorHeaderContainer = (props: Partial<EditorHeaderProps>) =>
    isWhiteEditorHeader && !IS_BIZ_USER ? <WhiteEditorHeader {...props} /> : <BlueEditorHeader {...props} />;
