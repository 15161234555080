import { Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import { flatten } from 'ramda';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useWindowResizer } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import type { IProps } from './AlbumCollapser.types';
import styles from './AlbumsCollapser.css';
import { getAvailableItemsNumber } from './AlbumsCollapser.helpers';

export const AlbumsCollapser = memo(function AlbumsCollapser(props: IProps) {
    const { children, title, initialyOpen = false } = props;
    const content = flatten(children);
    const [isOpen, setOpen] = useState(initialyOpen);

    const [rootRef, containerWidth] = useWindowResizer();

    const onClick = useCallback(() => setOpen(!isOpen), [isOpen]);
    const availableAlbumCount = getAvailableItemsNumber(containerWidth);
    const showCollapser = content.length >= availableAlbumCount;

    const albums = useMemo(() => {
        return isOpen || !showCollapser ? content : content.slice(0, availableAlbumCount);
    }, [showCollapser, isOpen, content, availableAlbumCount]);

    return (
        <section ref={rootRef} className={styles.root} data-qa-id="albums-collapser" data-qa-title={title} data-qa-open={isOpen}>
            <div className={styles.title} data-qa-id="collapser-title">
                {title}
                {showCollapser && (
                    <div className={styles.collapserButton} onClick={onClick} data-qa-id="collapser-button">
                        Посмотреть все
                        <Icon20Dropdown className={classNames(styles.icon, { [styles.icon_opened]: isOpen })} />
                    </div>
                )}
            </div>
            <div className={classNames(styles.container, { [styles.container_opened]: isOpen })}>{albums}</div>
        </section>
    );
});
