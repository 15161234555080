import classNames from 'clsx';
import React, { type MouseEventHandler, type ReactElement, memo } from 'react';
import { ShadeRight } from 'reactApp/ui/ShadeRight/ShadeRight';

import { FileName } from './FileName';
import styles from './HeaderFileName.css';

export interface Props {
    fileName?: string;
    readOnly: boolean;
    icon?: ReactElement | null;
    onEdit?: (value: { value: string }) => Promise<any>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    title?: string;
}

export const HeaderFileName = memo<Props>(({ fileName, readOnly, icon, onClick, onEdit, title }) => {
    return (
        <div className={styles.root}>
            <ShadeRight isInHeader={true} noShade={true}>
                <div
                    className={classNames(styles.filename, {
                        [styles.filenameReadOnly]: readOnly,
                        [styles.filenameRightSpace]: !!icon,
                    })}
                >
                    <FileName fileName={fileName} readOnly={readOnly} onClick={onClick} onEdit={onEdit} />
                    {icon && (
                        <div className={styles.icon} title={title}>
                            {icon}
                        </div>
                    )}
                </div>
            </ShadeRight>
        </div>
    );
});

HeaderFileName.displayName = 'HeaderFileName';
