import classNames from 'clsx';
import React, { type ReactElement, createRef, PureComponent } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { ActionName, ActionTarget } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import { FileIcon } from '../FileIcon/FileIcon';
import styles from './VirusDialog.css';

export interface Props {
    id: string;
    actionName: ActionName;
    actionTarget: ActionTarget;
    isMobile?: boolean;
    isHome?: boolean;
    isPublic?: boolean;
    onClose();
    onDownload(id: string);
    onRemove();
}

const getTitle = ({ actionName, actionTarget }: { actionName: ActionName; actionTarget: ActionTarget }) => {
    let title;

    switch (actionName) {
        case ActionName.publish:
            switch (actionTarget) {
                case ActionTarget.file:
                    title = 'Невозможно создать ссылку на заражённый файл';
                    break;

                case ActionTarget.folder:
                    title = 'Невозможно создать ссылку на папку с заражённым файлом';
                    break;
            }
            break;

        default:
        case ActionName.clone:
        case ActionName.download:
        case ActionName.zip:
            switch (actionTarget) {
                case ActionTarget.file:
                    title = 'Файл заражён вирусом';
                    break;

                case ActionTarget.folder:
                    title = 'Папка содержит заражённый вирусом файл';
                    break;

                case ActionTarget.several:
                    title = 'Среди выбранных объектов есть заражённый вирусом файл';
                    break;
            }
            break;
    }

    return <div className={styles.header}>{title}</div>;
};

export class VirusDialogComponent extends PureComponent<Props> {
    private contentRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        onClose: noop,
        onDownload: noop,
        isVirus: false,
    };

    public componentDidMount() {
        scrollLock(this.contentRef.current);
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.contentRef.current);
    }

    private handleClose = () => {
        this.props.onClose();
    };

    private handleDownload = () => this.props.onDownload(this.props.id);

    private handleDelete = () => this.props.onRemove();

    private renderFooter = () => {
        const { isPublic, actionName } = this.props;

        return (
            <div className={styles.footer}>
                {!isPublic && (actionName === ActionName.download || actionName === ActionName.zip) && (
                    <div className={styles.buttonWrapper}>
                        <Button theme="octavius" primary middle data-name="action" onClick={this.handleDownload}>
                            Скачать
                        </Button>
                    </div>
                )}
                <div className={styles.buttonWrapper}>
                    <Button theme="octavius" middle data-name="close" onClick={this.handleClose}>
                        Закрыть
                    </Button>
                </div>
            </div>
        );
    };

    public render(): ReactElement | null {
        const { actionName, actionTarget, isMobile, isHome } = this.props;

        const iconType = getIconType({ isVirus: true });

        return (
            <Dialog
                open
                header={getTitle({ actionName, actionTarget })}
                onCancel={this.handleClose}
                disableScrollOnBody={false}
                id="virus-dialog"
                footer={this.renderFooter()}
                className={styles.root}
            >
                <Content isModal isLoading={false} hasError={false} scrolling>
                    <div className={classNames({ [styles.content]: true, [styles.content_scrollable]: true })} ref={this.contentRef}>
                        <div className={styles.iconWrapper}>
                            <FileIcon size="l" type={iconType} />
                        </div>
                        <div className={styles.description}>
                            Этот файл может представлять угрозу вашему
                            {isMobile ? ' устройству' : ' компьютеру'}
                            {actionName === ActionName.publish && ' и людям, которые его скачают'}, <br />
                            так как содержит вирусы.
                            {actionTarget === ActionTarget.file && isHome && (
                                <div className={styles.delete} onClick={this.handleDelete}>
                                    Мы рекомендуем удалить его.
                                </div>
                            )}
                        </div>
                    </div>
                </Content>
            </Dialog>
        );
    }
}
