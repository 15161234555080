import type { PayloadAction } from '@reduxjs/toolkit';
import api from 'Cloud/Application/api';
import { extInfo } from 'lib/extInfo';
import { IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { CAMERA_UPLOADS_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import type { FeedItemApiResponse } from 'reactApp/modules/feed/feed.types';
import {
    changeGalleryCategory,
    changeGalleryFolder,
    galleryLoadMoreRequest,
    galleryLoadMoreSuccess,
    galleryLoadRequest,
    galleryLoadStart,
    galleryLoadSuccess,
} from 'reactApp/modules/gallery/gallery.module';
import { routeChangeStart } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { GetStateResponse } from 'reactApp/modules/router/router.types';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { call, cancel, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getGalleryCategory, getGalleryCursorByCategory, getGalleryFolder, isGalleryCategoryLoaded } from './gallery.selectors';
import {
    type ChangeGalleryCategoryAction,
    type ChangeGalleryFolderAction,
    type GalleryLoadRequestAction,
    GalleryCategoryType,
} from './gallery.types';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.gallery);

const apiCall = ({
    limit = LOAD_LIMIT,
    cursor,
    extensions,
    include,
    exclude,
}: {
    limit?: number;
    cursor?: string;
    extensions?: string;
    include?: string[];
    exclude?: string[];
}): Promise<FeedItemApiResponse> =>
    Promise.resolve(
        api.feed({
            exts: extensions,
            cursor,
            limit,
            include,
            exclude,
        })
    );

function* fetchGallery({
    category = null,
    resetCursor = false,
    folder,
    storage,
}: {
    category: GalleryCategoryType | null;
    resetCursor?: boolean;
    folder?: string;
    storage?: EStorageType;
}) {
    try {
        const eitherBizOrOnPremiseUser = IS_BIZ_USER || IS_ONPREMISE;

        if (!category) {
            category = yield select(getGalleryCategory);
        }

        /**
         * storage перелающийся извне - вынужденная мера, так как при переходе из альбомов
         * в галерею galleryLoadRequest пушится раньше, чем обрабатывается установка getCurrentStorage
         * и в итоге внутри галереи мы начинали грузить вместо экстеншенов галереи экстеншены альбома
         * что было не неверно.
         * Экшн galleryLoadRequest диспатчится только из новой галереи, поэтом мы можем четко сказать
         * что здесь сторадж будет задан значение EStorageType.gallery
         */
        const currentStorage = storage || (yield select(getCurrentStorage));

        const cursor = resetCursor ? '' : yield select(getGalleryCursorByCategory, category as GalleryCategoryType);
        const extensions =
            currentStorage === EStorageType.albums
                ? extInfo.getExtsForAlbums()?.join(',')
                : extInfo.getExtsForGallery(eitherBizOrOnPremiseUser && category)?.join(',');

        const data: { extensions: string; cursor: string; include?: string[]; exclude?: string[] } = { extensions, cursor };

        if (!eitherBizOrOnPremiseUser) {
            if (category === GalleryCategoryType.mobile) {
                data.include = [CAMERA_UPLOADS_FOLDER_ID];
            } else if (category === GalleryCategoryType.desktop) {
                data.exclude = [CAMERA_UPLOADS_FOLDER_ID];
            }
        }

        if (folder) {
            data.include = [folder];
        }

        return yield call(apiCall, data);
    } catch (error) {
        yield cancel();
    }
}

function* loadGalleryByRequest(action) {
    const category = action?.payload?.category || GalleryCategoryType.all;
    yield put(galleryLoadStart({ category }));

    const data = yield fetchGallery({ category, resetCursor: true, storage: EStorageType.gallery });
    yield put(galleryLoadSuccess({ category, data }));
}

function* loadGalleryOnStart(action: PayloadAction<GetStateResponse>) {
    const storage = action.payload.storage;

    const isLoaded = yield select(isGalleryCategoryLoaded, GalleryCategoryType.all);

    if (storage !== EStorageType.gallery || (storage === EStorageType.gallery && isLoaded)) {
        return;
    }

    yield loadGalleryByRequest({ category: GalleryCategoryType.all });
}

function* loadGalleryCategory(action: PayloadAction<ChangeGalleryCategoryAction>) {
    const category = action.payload.category;

    yield put(galleryLoadStart({ category }));

    const data = yield fetchGallery({ category, resetCursor: true });
    yield put(galleryLoadSuccess({ category, data }));
}

function* loadMoreGallery(action: PayloadAction<undefined | GalleryLoadRequestAction>) {
    const category = action?.payload?.category;
    const folder = yield select(getGalleryFolder);
    const data = yield fetchGallery({ category: category || null, folder });
    yield put(galleryLoadMoreSuccess({ data, category, folder, isExactFolder: Boolean(folder) }));
}

function* loadGalleryFolder(action: PayloadAction<ChangeGalleryFolderAction>) {
    const { folder, category = GalleryCategoryType.all } = action.payload;
    yield put(galleryLoadStart({ category }));
    const data = yield fetchGallery({ category, folder, resetCursor: true });
    yield put(galleryLoadSuccess({ category, data, folder, isExactFolder: true }));
}

export function* watchGallery() {
    yield takeEvery(routeChangeStart.toString(), loadGalleryOnStart);
    yield takeEvery(galleryLoadRequest.toString(), loadGalleryByRequest);
    yield takeEvery(changeGalleryCategory.toString(), loadGalleryCategory);
    yield takeLatest(galleryLoadMoreRequest.toString(), loadMoreGallery);
    yield takeEvery(changeGalleryFolder.toString(), loadGalleryFolder);
}
