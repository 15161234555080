import type { PayloadAction } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';
import { logger } from 'lib/logger';
import { activitiesV4ApiCall } from 'reactApp/api/activities/ActivitiesApiCall';
import { activitiesFeature } from 'reactApp/appHelpers/featuresHelpers';
import { requestActivities, requestActivitiesFail, requestActivitiesSuccess } from 'reactApp/modules/activities/activities.module';
import { ActivitiesSelectors } from 'reactApp/modules/activities/activities.selector';
import type { RequestActivitiesAPIPaylod, RequestActivitiesPaylod } from 'reactApp/modules/activities/activities.types';
import { call, put, select, takeLatest } from 'redux-saga/effects';

const getActivitiesCall = (args: RequestActivitiesAPIPaylod) => activitiesV4ApiCall(args);

function* handleRequestActivities(action: PayloadAction<RequestActivitiesPaylod>) {
    try {
        const items = yield select(ActivitiesSelectors.activities);
        const period = parseInt(activitiesFeature.period);
        const ts_min = Math.trunc(subDays(Date.now(), period).getTime() / 1000);
        const ts_max = items.length ? Math.trunc(items.slice(-1)[0].time / 1000) - 1 : undefined;
        const { filter, ...payload } = action.payload;
        const { data } = yield call(getActivitiesCall, {
            ...payload,
            ...{ filter: (filter !== 'all' && filter) || undefined },
            ts_max,
            ts_min,
        });
        yield put(requestActivitiesSuccess(data.activities));
    } catch (error) {
        logger.error(error);
        yield put(requestActivitiesFail());
    }
}

export function* watchActivities() {
    yield takeLatest(requestActivities.toString(), handleRequestActivities);
}
