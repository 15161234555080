import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSassThemeEnabled } from 'reactApp/appHelpers/featuresHelpers/features/sassQuotaProLanding';
import { useDarkTheme } from 'reactApp/hooks/useDarkTheme';
import { initProducts, initProductsForAnonymous } from 'reactApp/modules/products/products.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getSassTariffsList } from 'reactApp/sections/SassLanding/SassLanding.selectors';
import styles from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileSubscriptionsPage.css';
import { SassMobileToolbar } from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileToolbar/SassMobileToolbar';
import { TariffPlansList } from 'reactApp/sections/SassLanding/TariffPlans/TariffPlansList';
import type { RootState } from 'reactApp/store';
import { Loader } from 'reactApp/ui/Loader/Loader';

export const SassMobileSubscriptionsPage = memo(() => {
    const dispatch = useDispatch();
    const tariffList = useSelector((state: RootState) => getSassTariffsList(state));
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    useDarkTheme(isSassThemeEnabled);

    useEffect(() => {
        if (isAnonymous) {
            dispatch(initProductsForAnonymous());
            return;
        }
        dispatch(initProducts());
    }, []);

    if (!tariffList.length) {
        return (
            <div className={styles.root}>
                <Loader centered />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <SassMobileToolbar />
            <div className={styles.content}>
                <div className={styles.title}>
                    Mail&nbsp;Space&nbsp;Pro <br />
                    Выберите, что подходит больше
                </div>
                <TariffPlansList />
            </div>
        </div>
    );
});

SassMobileSubscriptionsPage.displayName = 'MobileSubscriptionsPage';
