import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { captureMessage } from '@sentry/browser';
import api from 'Cloud/Application/api';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import throttle from 'lodash.throttle';
import { clone, path } from 'ramda';
import { IS_B2B_BIZ_USER, IS_DOCUMENTS_DOMAIN, IS_PHONE_BROWSER, IS_PUBLIC, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { agreeUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { setUser } from 'reactApp/modules/user/user.actions';
import { store as reduxStore } from 'reactApp/store';
import { loginHide, loginShow } from 'reactApp/utils/auth';
import { noop } from 'reactApp/utils/helpers';
import { sendTmrGoal } from 'reactApp/utils/tmr';

const updateReduxUser = (user) => {
    reduxStore.dispatch(setUser(clone(user)));
};

let userObj = config.get('user') || {};

const _updateSpace = throttle(function () {
    api.user.space().done(function (space) {
        user.updateSpace(space);
    });
}, 500);

function setUserParam(name, value) {
    const parts = name.split('.');
    let hash = userObj;

    for (let i = 0; i < parts.length - 1; i++) {
        if (hash[parts[i]] === undefined) {
            hash[parts[i]] = {};
        }

        hash = hash[parts[i]];
    }

    hash[parts[parts.length - 1]] = value;

    // сохранение настроек на сервере
    // для авторизованных пользователей
    if (user.isAuthorized()) {
        api.user
            .edit({
                ui: userObj.ui,
            })
            .fail(function (errors) {
                captureMessage('USER SET', {
                    arguments: errors,
                });
            });
    }
}

function getUserParam(name, defaultValue) {
    const parts = name.split('.');
    let hash = userObj;

    if (hash) {
        for (const element of parts) {
            if (!hash || hash[element] === undefined) {
                return defaultValue || null;
            }

            hash = hash[element];
        }
    }

    return hash;
}

const user = {
    getDomain() {
        return userObj.domain;
    },

    getEmail() {
        if (userObj.login && userObj.domain) {
            return `${userObj.login}@${userObj.domain}`;
        }
    },

    destroy() {
        userObj = {};
    },

    isAuthorized() {
        return userObj && !!userObj.login && userObj.login.length > 0;
    },

    isCorp() {
        return config.get('CORP_USER');
    },

    isBizUser() {
        return config.get('BIZ_USER');
    },

    isNewbie() {
        return user.isAuthorized() && user.getParam('newbie');
    },

    isFrozen() {
        return config.get('user.cloud.frozen') || config.get('user.cloudflags.FROZEN');
    },

    defrost() {
        return new Promise((resolve, reject) => {
            if (this.isFrozen()) {
                api.user
                    .unfreeze()
                    .done(() => {
                        resolve();
                    })

                    .fail((errors) => {
                        reject(errors);
                    });
            } else {
                resolve();
            }
        }).then(() => {
            // return new Promise((resolve, reject) => {
            //     this.updateUserData().done(resolve).fail(reject);
            // });
            window.location.reload();
        });
    },

    setParam: setUserParam,
    getParam: getUserParam,

    allUIParams() {
        return { ...userObj.ui };
    },

    isOverquota() {
        const userCloud = userObj.cloud;

        if (!userCloud || !userCloud.space) {
            return false; // actually, we don't know
        }

        if ('overquota' in userCloud.space) {
            return userCloud.space.overquota;
        }
        return userCloud.space.used >= userCloud.space.total;
    },

    updateBilling(billing) {
        if (!billing) {
            xray.send('app_update-billing-empty');
            return;
        }

        const costDiff = userObj.cloud.billing.active_cost_id !== billing.active_cost_id;
        const rateDiff = userObj.cloud.billing.active_rate_id !== billing.active_rate_id;
        const expiresDiff = userObj.cloud.billing.expires !== billing.expires;

        userObj.cloud.billing = billing || {};
        billing.subscription = billing.subscription || [];

        const storage = getCurrentStorage(reduxStore.getState());

        if (costDiff) {
            xray.send(`app_update-billing-cost_${storage}`);
        }

        if (rateDiff) {
            xray.send(`app_update-billing-rate_${storage}`);
        }

        if (expiresDiff) {
            xray.send(`app_update-billing-expires_${storage}`);
        }
    },

    updateSpace(space, doUpdate) {
        if (!this.isAuthorized()) {
            return;
        }

        const exists = path(['cloudflags', 'exists'], userObj) || path(['cloudflags', 'EXISTS'], userObj);

        if (exists !== true) {
            return;
        }

        if (!space) {
            _updateSpace();
            return;
        }

        const diffUsed = userObj.cloud.space.bytes_used !== space.bytes_used;
        const diffTotal = userObj.cloud.space.bytes_total !== space.bytes_total;
        const diffOverquota = userObj.cloud.space.overquota !== space.overquota;
        const storage = getCurrentStorage(reduxStore.getState());

        // юзер залил/удалил какие то файлы
        if (diffUsed) {
            xray.send(`app_update-space-used_${storage}`);
            userObj.cloud.space.used = space.used;
        }

        // у юзера скорее всего изменились бонусы
        if (diffTotal) {
            xray.send(`app_update-space-total_${storage}`);
            userObj.cloud.space.total = space.total;
        }

        // облако пользователя переполнилось или вышло из режима переполнения
        if (diffOverquota) {
            xray.send(`app_update-space-overquota_${storage}`);
            userObj.cloud.space.overquota = space.overquota;
        }

        if (doUpdate !== false && (diffUsed || diffTotal || diffOverquota)) {
            this.updateUserData();
        }
    },

    formatSpaceInBytes(space) {
        return bytesToNDigits(space, 3);
    },

    formatSpaceInBytesFloor(space) {
        return bytesToNDigits(space, 3, false);
    },

    updateUserData() {
        xray.sendImmediately(`app_update-user_data`);

        return api
            .user()
            .done((data) => {
                updateReduxUser(data);
                this.updateSpace(data.cloud.space, false);
                this.updateBilling(data.cloud.billing);

                userObj = data;
                userObj.email = user.getEmail();
                config.set('user', userObj);

                // нужно поправить это дело в перляке
                if (IS_B2B_BIZ_USER) {
                    user.setParam('newbie', false);
                }
            })
            .fail(function (errors) {
                captureMessage('UPDATE USER', {
                    arguments: errors,
                });
            });
    },
};

function reloadPage() {
    logger.verbose('AUTH CHANGE');

    xray.sendImmediately('auth_change');

    if (IS_PUBLIC || /^(\/promo)|(\/quota)|(\/quota-pro)\/?$/.test(window.location.href)){
        window.location.reload();
    } else if (/^\/integration/.test(window.location.pathname)) {
        // рефрешим страницу, переходя в хомяк и (!) сохраняя гет-параметры
        window.location.pathname = '/integration/';
    } else {
        window.location.href = IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : '/home/';
    }
}

function authPopup() {
    window.__PH?.loadAccountsList(function (data) {
        const params = {
            formType: data.accounts && data.accounts.length ? 'restore' : 'relogin',
            modal: !(data.accounts && data.accounts.length),
        };

        if (userObj.email && userObj.domain) {
            params.email = userObj.email;
            params.domain = userObj.domain;
        }

        user.destroy();

        loginShow(params);
    });
}

function authPopupHide() {
    user.updateUserData()
        .then(() => {
            xray.sendImmediately('auth_relogin');
            loginHide();
        })
        .fail(() => {
            reloadPage();
        });
}

function sendAgreement(source = '') {
    sendTmrGoal({ goal: 'agree-la' });
    const storage = getCurrentStorage(reduxStore.getState());

    xray.sendImmediately(`app_send_agreement_${source}${storage}`);

    return api.user['agree-la']().done(function () {
        user.setParam('newbie', false);

        reduxStore.dispatch(agreeUpdateLicenseRequest({ source }));

        if (window.cloudSettings.params.REACT_PROMO_HAPPY_BIRTHDAY_CLOUD) {
            window.location.reload();
        }
    });
}

// eslint-disable-next-line max-lines-per-function
export default function () {
    let prevEmail;

    if (window.__PH) {
        // текущий пользователь разлогинился
        window.__PH.on('authLost', function () {
            if (!prevEmail) {
                prevEmail = user.getEmail(false);

                xray.send('user_auth_lost');
                xray.send('auth_lost');
                authPopup();
            }
        });

        // слушаем обновления авторизации
        window.__PH.on('update', function (e, next = noop) {
            xray.send('user_auth_update');

            const newEmail = e?.data?.email || null;

            if (prevEmail && newEmail === prevEmail) {
                // relogin
                prevEmail = undefined;

                authPopupHide();
            }

            next();
        });

        // юзер хочет войти под другим именем
        window.__PH.on('loginRequest', function (e, next = noop) {
            xray.send('auth_login-request');
            xray.send('user_auth_login-request');
            const { protocol, hostname } = window.location;

            loginShow({
                closable: true,
                successPage: `${protocol}//${hostname}/home/`,
            });

            next();
        });

        // юзер авторизовался под другим именем
        window.__PH.on('authChange', function (e, next = noop) {
            const newEmail = e?.data?.email || null;

            xray.send('user_auth_change');

            if (newEmail === user.getEmail()) {
                xray.send('auth_change-same');
                xray.send('user_auth_change-same');
            } else if (newEmail) {
                reloadPage();
            } else {
                xray.send('auth_lost');
                xray.send('user_auth_change-lost');
                authPopup();
            }

            next();
        });
    } else if (!IS_PHONE_BROWSER && !IS_WEBVIEW) {
        // there is no __PH for mobile phone for now
        xray.send('error___PH');
        captureMessage('NO __PH');
    }

    api.on('accessDenied', function (e, from) {
        captureMessage(`ACCESS DENIED FROM ${from.toUpperCase()}`);

        xray.send(`auth_lost-${from}${window.__PH ? '' : '-no_ph'}`);
        authPopup();
    });
}

export { user, sendAgreement };
