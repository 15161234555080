import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

interface DeleteFamilyUserAPICallParams {
    error?: string;
    message?: string;
}

export interface DeleteFamilyUserQueryParams {
    email: string;
    family: string;
}

export class DeleteFamilyUserAPICall extends APICallV4<DeleteFamilyUserAPICallParams> {
    _method = 'family/user/delete';
    _type: Method = 'post';
}
