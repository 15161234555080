import { pathOr } from 'ramda';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { isVirusItem } from 'reactApp/modules/file/utils';
import type { PublicItem } from 'reactApp/modules/public/public.types';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import type { StockItem } from 'reactApp/modules/stock/stock.type';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { getThumbUrl } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic.helpers';
import { ItemInfo } from 'reactApp/ui/Mobile/OptionsModal/ItemInfo/ItemInfo';
import { formatDate } from 'reactApp/utils/formatDate';

interface IItemInfoContainerProps {
    id: string;
    storage: EStorageType;
}

export const ItemInfoContainer = memo<IItemInfoContainerProps>(function ItemInfoContainer({ id, storage }) {
    const item = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as PublicItem | StockItem;

    const isFolder = item.isFolder;
    const ext = isFolder ? undefined : item?.ext;
    const name = isFolder ? item?.name : item?.nameWithoutExt;
    const isVirus = isVirusItem(item);

    const rawDate = pathOr(0, ['mtime'], item);
    const date = rawDate ? formatDate(rawDate) : '';

    const thumbUrl = getThumbUrl({
        viewMode: EViewMode.list,
        item,
        isVirus,
    });

    return (
        <ItemInfo
            thumbUrl={thumbUrl}
            ext={ext}
            isFolder={isFolder}
            isVirus={isVirus}
            kind={item.kind}
            name={name}
            size={'size' in item ? item.size : undefined}
            storage={storage}
            date={date}
        />
    );
});
