import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EButtonAction, sendDisableAdsAnalytics } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton.analytics';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';

export const initDisableAdAnalytics = (storage: EStorageType, showDisableButton: boolean) => {
    const button = document.querySelector('.js-disable-button');

    if (!button) {
        return;
    }

    sendDisableAdsAnalytics({
        action: EButtonAction.SHOW,
        place: 'left-col',
    });

    button.addEventListener('click', () => {
        sendDisableAdsAnalytics({
            action: EButtonAction.CLICK,
            place: 'left-col',
        });

        // tempexp_13536-start
        if (showDisableButton) {
            sendSplashscreenAnalytics({
                action: EPromoAction.CLICK,
                name: 'disable-adv-button',
                source: storage,
                name_button: 'disable-adv',
            });
        }
        // tempexp_13536-end
    });
};
