import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAvatarUrl } from 'reactApp/utils/avatar';

export interface Contact {
    suggest: string;
    name: string;
    email: string;
    emails?: string;
    avatar: string;
}

interface ContactsState {
    list: null | Contact[];
    suggest: null | Contact[];
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
}

const initialState: ContactsState = {
    list: null,
    suggest: null,
    isLoading: false,
    isLoaded: false,
    hasError: false,
};

function normalizeContact(contact, email): Contact {
    const name = contact.display_name || (contact.name ? `${contact.name.first || ''} ${contact.name.last || ''}`.trim() : contact.nick);

    return {
        suggest: email,
        name: name || email,
        email: email.toLowerCase(),
        avatar: createAvatarUrl(email, name, 90),
    };
}

function normalizeContacts(contacts): Contact[] {
    return contacts.reduce((acc, contact) => {
        if (Array.isArray(contact.emails)) {
            return contact.emails
                .filter((email) => Boolean(email))
                .reduce((prevData, email) => [...prevData, normalizeContact(contact, email)], acc);
        }
        if (contact.email) {
            return [...acc, normalizeContact(contact, contact.email)];
        }
        return acc;
    }, []);
}

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        listRequest(state): void {
            state.isLoading = true;
            state.hasError = false;
        },
        listSuccess(state, action): void {
            state.list = normalizeContacts(action.payload);
            state.isLoading = false;
            state.hasError = false;
            state.isLoaded = true;
        },
        listFailure(state): void {
            state.list = null;
            state.isLoading = false;
            state.hasError = true;
        },
        // сигнатура метода нужна для типизации, экшен используется для подписки в саге
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        suggestRequest(state, action: PayloadAction<string>): void {
            //
        },
        suggestSuccess(state, action): void {
            state.suggest = normalizeContacts(action.payload?.contacts);
        },
        suggestReset(state): void {
            state.suggest = null;
        },
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        addRequest(state, action: PayloadAction<{ emails: string[] }[]>): void {
            //
        },
    },
});
