import { removeMessages } from 'reactApp/api/axios.corsapi';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { deleteItemsError } from 'reactApp/modules/userQuotaCleaner/sagas/deleteItemsError';
import {
    type deleteLettersFromQuota,
    deleteUserQuotaCleanerItemsSuccess,
    setIsItemsDeleteProcess,
    setMessagesRemove,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { put, select } from 'redux-saga/effects';

export function* deleteLetters(action: ReturnType<typeof deleteLettersFromQuota>) {
    const {
        payload: { ids, itemIds, groupId, groupType, size, onDeleteSuccess },
    } = action;
    const email = yield select(UserSelectors.getEmail);
    yield put(setIsItemsDeleteProcess({ value: true }));

    try {
        const res = yield removeMessages(email, ids);
        if (res.status !== 200) {
            throw res;
        }

        yield put(
            deleteUserQuotaCleanerItemsSuccess({
                count: ids.length,
                groupType,
                groupId,
                ids,
                itemIds,
                size,
                isMsgRemove: true,
                onDeleteSuccess,
            })
        );
        yield put(setMessagesRemove({ value: false }));
    } catch (error) {
        yield deleteItemsError({ error, groupId, ids, itemIds, size, isMsgRemove: true });
        yield put(setMessagesRemove({ value: false }));
    }
}
