import type { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';

export interface CheckCaptchaParams {
    id: number;
    value: string;
}

export type CheckCaptchaAction = CheckCaptchaParams & { sendAnalytics?: (action: ESubscriptionModalAction) => void };

export interface State {
    error: string;
    isLoading: boolean;
}

export enum ECaptchaErrors {
    empty = 'Укажите код с картинки',
    wrong = 'Неверные символы',
}
