import { createAction } from '@reduxjs/toolkit';

import type {
    IAccessControlListError,
    IAccessControlListLoadStart,
    IDeleteAllUser,
    IDeleteUser,
    ISetAccessRight,
    ISetInvitesSuccess,
} from './folderAccessControlList.types';

export const accessControlListLoadStart = createAction<IAccessControlListLoadStart>('accessControlList/loadStart');
export const setAccessControlListError = createAction<IAccessControlListError>('accessControlList/loadFail');
export const setAccessRight = createAction<ISetAccessRight>('accessControlList/setAccessRight');
export const deleteUser = createAction<IDeleteUser>('accessControlList/deleteUser');
export const deleteAllUsers = createAction<IDeleteAllUser>('accessControlList/deleteAllUsers');
export const accessControlListReset = createAction('accessControlList/accessControlListReset');
export const setInvitesSuccess = createAction<ISetInvitesSuccess>('accessControlList/setInvitesSuccess');
