import type { Method } from 'lib/axios';

import { APICallV4 } from '../APICall';

export class WeblinksEmailAccessDeleteV4ApiCall extends APICallV4 {
    _method = 'weblinks/email/access/delete';
    _type: Method = 'post';
}

export const weblinksEmailAccessDeleteV4ApiCall = ({ weblink, email }) =>
    new WeblinksEmailAccessDeleteV4ApiCall().makeRequest({ id: weblink, email });
