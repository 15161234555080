import React, { type FC } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Destination, SizeType } from 'reactApp/ui/DataListItemCommonComponents/Destination/Destination';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { Restore } from 'reactApp/ui/DataListItemRow/components/Restore/Restore';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import type { TrashbinItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const TrashbinItem: FC<TrashbinItemProps> = (props) => {
    const {
        id,
        isFolder,
        thumbUrl,
        kind,
        isPublic,
        name,
        size,
        ext,
        isSelected,
        onCheckboxClick,
        deletedFrom,
        deletedAt,
        binName,
        onRestore,
    } = props;

    const path = deletedFrom === '/' ? binName : deletedFrom.slice(0, deletedFrom.length - 1);

    const isCompact = useCompactView();

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic isFolder={isFolder} thumbUrl={thumbUrl} isVirus={false} ext={ext || ''} kind={kind} isPublic={isPublic} />
            </TableColumn>

            {isCompact ? (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <div className={styles.nameWrapper}>
                            <ItemName name={name} ext={ext || ''} id={id} />
                            <Destination destination={path} noMargin size={SizeType.s} />
                            <span className={styles.responsiveDateWrapper}>
                                <Size size={size} isInline />
                                {!!deletedAt && <>&nbsp;•&nbsp;</>}
                                <Date date={deletedAt} className={styles.date} />
                            </span>
                        </div>
                    </TableColumn>
                </>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} />
                    </TableColumn>
                    <TableColumn width="100%" overflow="hidden">
                        <Destination destination={path} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Date date={deletedAt} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={128} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Restore onClick={onRestore} />
            </TableColumn>
        </>
    );
};
