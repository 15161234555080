import { path, pipe } from 'ramda';
import type { MediaState } from 'reactApp/modules/media/media.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getMediaState = (state: RootState): MediaState => state.media;

const isPhone = pipe(getMediaState, path(['isPhone']));

const isPhoneSmall = pipe(getMediaState, path(['isPhoneSmall']));

const isMobile = pipe(getMediaState, path(['isMobile']));

const isTablet = pipe(getMediaState, path(['isTablet']));

const isDesktop = pipe(getMediaState, path(['isDesktop']));

const isSmallDesktop = pipe(getMediaState, path(['isDesktopSmall']));

const isMediumDesktop = pipe(getMediaState, path(['isDesktopMedium']));

const getWindowWidth = createSelector(getMediaState, (state) => state.windowWidth);

export const MediaSelectors = {
    getMediaState,
    isPhone,
    isPhoneSmall,
    isMobile,
    isTablet,
    isDesktop,
    isSmallDesktop,
    isMediumDesktop,
    getWindowWidth,
};
