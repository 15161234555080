import { CAMERA_UPLOADS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { type FeedItem, FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { isItemPreviewableImageWithThumbs, isItemPreviewableVideoWithThumbs } from 'reactApp/modules/file/file.helpers';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getFeedState = (state: RootState) => state.feed;

export const getFeedCategory = (state: RootState): FeedCategoryType => getFeedState(state).currentCategory;
export const getFeedList = (state: RootState) => getFeedState(state).list;
export const getFeedCategories = createSelector(getFeedState, (feed) => feed.categories);

export const getFeedCursorByCategory = createSelector(
    getFeedCategories,
    (state: RootState, category: FeedCategoryType) => category,
    (categories, category) => categories.find((item) => item.id === category)?.cursor
);

export const getFeedCategoryById = createSelector(
    getFeedCategories,
    (state, category) => category,
    (categories, category) => categories.find((item) => item.id === category)
);

export const getCurrentFeedCategory = createSelector(
    getFeedCategory,
    (state: RootState) => state,
    (currentCategory, state) => getFeedCategoryById(state, currentCategory)
);

export const getFeedOfCategory = createSelector(
    getFeedCategories,
    (state, category) => category,
    (categories, category) => categories.find((item) => item.id === category)
);

export const getFeedItemById = createSelector(
    getFeedList,
    (state, id) => id,
    (list, id): FeedItem | undefined => list[id]
);

export const isFeedCategoryLoaded = createSelector(
    getFeedCategories,
    (state: RootState, category: FeedCategoryType) => category,
    (categories, category) => !!categories.find((item) => item.id === category)?.isLoaded
);

export const isFeedCategoryLoading = createSelector(
    getFeedCategories,
    (state: RootState, category: FeedCategoryType) => category,
    (categories, category) => !!categories.find((item) => item.id === category)?.isLoading
);

export const hasMoreToLoadFeed = createSelector(
    getFeedCategories,
    (state: RootState, category: FeedCategoryType) => category,
    (categories, category) => categories.find((item) => item.id === category)?.hasMoreToLoad
);

export const getPreviewableFeedMedia = createSelector(
    (state: RootState) => getFeedOfCategory(state, FeedCategoryType.all)?.childs.map((id) => getFeedItemById(state, id)) as FeedItem[],
    (state, number): number => number,
    (list, number): any[] => {
        const withImages = list.filter((item) => isItemPreviewableImageWithThumbs(item) || isItemPreviewableVideoWithThumbs(item));
        if (number === null) {
            return withImages;
        }
        return withImages.slice(0, number);
    }
);

// Выкидываем все корневые файлы и файлы из папки Фото с телефона
export const getLastFilesForWidget = createSelector(
    (state: RootState) => getFeedOfCategory(state, FeedCategoryType.all)?.childs.map((id) => getFeedItemById(state, id)) as FeedItem[],
    (list): any[] =>
        list.filter(
            (item) =>
                item.parent !== ROOT_FOLDER_ID &&
                item.parent !== CAMERA_UPLOADS_FOLDER_ID &&
                item.parent !== CAMERA_UPLOADS_FOLDER_ID.toLowerCase()
        )
);
