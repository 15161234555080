import React, { memo, useCallback, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import type { Message } from 'reactApp/sections/PdfEditPage/PdfEditPage.types';

interface IDeletePageModalProps {
    pageToDelete: number[];
    postMessage: (content: Message) => void;
}

const popupName = popupNames.DELETE_PAGE_MODAL;

export const DeletePageModal = memo(({ pageToDelete, postMessage }: IDeletePageModalProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const snackbarText = Array.isArray(pageToDelete) ? 'Страницы удалены' : 'Страница удалена';
    const isManyPages = Array.isArray(pageToDelete) && pageToDelete.length > 1;

    const handleSuccess = useCallback(() => {
        postMessage({ type: 'deletePageApproved', message: pageToDelete });
        setIsOpen(false);
        closePopupHelper(popupName);
        snackbarController.showSnackbar({
            id: '',
            closable: true,
            text: snackbarText,
            type: SnackbarTypes.success,
        });
    }, [pageToDelete]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        closePopupHelper(popupName);
    }, [isOpen, setIsOpen]);

    const renderHeader = useCallback(() => <div>{`Удаление страниц${isManyPages ? '' : 'ы'}`}</div>, [pageToDelete]);

    const renderContent = useCallback(() => {
        return <div>{`Вы действительно хотите удалить страниц${isManyPages ? 'ы' : 'у'}?`}</div>;
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <BaseConfirmDialog
            dataQAId="delete-page-modal"
            renderHeader={renderHeader}
            successButtonText="Удалить"
            cancelButtonText="Отменить"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onSuccess={handleSuccess}
            renderContent={renderContent}
            onClose={handleClose}
            size={'small'}
        />
    );
});

DeletePageModal.displayName = 'DeletePageModal';
