import { Icon12Cancel } from '@vkontakte/icons';
import video from 'img/OBLAKO.webm';
import * as React from 'react';
import type { IRebrandingPromoModalProps } from 'reactApp/ui/RebrandingPromoModal/RebrandingPromoModal.types';

import s from './RebrandingPromoModal.css';

export const RebrandingPromoModal = ({ onClose, onDone }: IRebrandingPromoModalProps) => {
    return (
        <div className={s.modal}>
            <div className={s.contentWrapper}>
                <button type="button" className={s.close} onClick={onClose}>
                    <Icon12Cancel />
                </button>
                <video className={s.video} autoPlay muted playsInline>
                    <source src={video} type="video/webm" />
                    Your browser does not support the video tag.
                </video>
                <div className={s.mainContent}>
                    <div className={s.textContent}>
                        <div className={s.title}>
                            Новый взгляд
                            <br />
                            на хранение
                        </div>
                        <div className={s.description}>
                            Облако найдёт вас на фото и наведёт порядок в документах — узнайте, что ещё умеют сервисы Mail
                        </div>
                    </div>
                    <a href="https://trk.mail.ru/c/cv7t54" target="_blank" className={s.done} onClick={onDone} rel="noreferrer">
                        Узнать больше
                    </a>
                </div>
            </div>
        </div>
    );
};
