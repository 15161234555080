import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPromoQuotaWithLitress } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';

export function useLitresTooltip(tootipId: string, isRecommended = false) {
    const dispatch = useDispatch();
    const litresTooltipRef = useRef<HTMLDivElement | null>(null);
    const litresTooltipShown = dispatch(UserStorageActions.get(tootipId));
    const [showLitresTooltip, setShowLitresTooltip] = useState(false);
    const isPaid = useSelector(UserSelectors.isPaidUser);
    useEffect(() => {
        if (isPromoQuotaWithLitress && !isPaid && !litresTooltipShown && isRecommended) {
            setTimeout(() => {
                setShowLitresTooltip(true);
            }, 3000);
        }
    }, [litresTooltipShown, isRecommended, isPromoQuotaWithLitress]);
    const initLitresTooltip = showLitresTooltip && isPromoQuotaWithLitress;
    return { litresTooltipRef, initLitresTooltip, setShowLitresTooltip };
}
