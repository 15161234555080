import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { familyInviteRequest, familyLoadRequest } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import {
    getFamilyLoadingState,
    isUserFamilyMember,
    isUserFamilyOwner,
    userCanCreateFamily,
} from 'reactApp/modules/family/family.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FamilyPromo } from 'reactApp/sections/FamilyPage/FamilyPromo/FamilyPromo';
import { FamilySettings } from 'reactApp/sections/FamilyPage/FamilySettings/FamilySettings';
import Content from 'reactApp/ui/Content/Content';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { openFamilyErrorModal } from './FamilyErrorDialog/FamilyErrorDialog.helpers';
import { FamilyError } from './FamilyErrorDialog/FamilyErrorDialog.types';
import styles from './FamilyPage.css';

export const FamilyPage = (): ReactElement => {
    const dispatch = useDispatch();
    const params = useParams();

    const { isLoading } = useSelector(getFamilyLoadingState);
    const canCreateFamily = useSelector(userCanCreateFamily);
    const isFamilyMember = useSelector(isUserFamilyMember);
    const isFamilyOwner = useSelector(isUserFamilyOwner);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);

    const content = useMemo(() => {
        // если есть семья или состоишь в семье
        // если есть подписка (не только партнерская)
        // то показываем настройки семьи
        if (isFamilyMember || isFamilyOwner || canCreateFamily) {
            return <FamilySettings />;
        }

        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.showButtonTry,
        });
        return <FamilyPromo />;
    }, [canCreateFamily, isFamilyMember, isFamilyOwner]);

    useEffect(() => {
        if (isBizUser || isCorpUser) {
            dispatch(historyPush({ id: getIdByStorage(EStorageType.home) }));
            openFamilyErrorModal({ errorType: FamilyError.wrongAccountEnter, storage: EStorageType.home });
        }
    }, []);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: `family${params?.inviteLink ? '-invite' : ''}` });
        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: `family${params?.inviteLink ? '-invite' : ''}`,
        });

        if (params?.inviteLink) {
            dispatch(familyInviteRequest(params?.inviteLink));
        }
        dispatch(checkUpdateLicenseRequest());
        dispatch(familyLoadRequest({ withQuota: true }));
    }, []);

    return (
        <div className={classNames(styles.root)}>
            <Content isLoading={isLoading}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        {isFamilyMember || isFamilyOwner || canCreateFamily ? 'Общее хранилище Mail Space' : 'Mail Space для близких'}
                    </div>
                    <Spacing size={12} />
                    {content}
                    <Spacing size={32} />
                </div>
            </Content>
            <Footer storage={EStorageType.family} isAbsolutePos={true} withMargins />
        </div>
    );
};
