export const getWeblinkAccessError = (error): string => {
    const status = error.status;
    const response = error.response.error || error.response;

    if (status === 404 && response === 'NOT/FOUND') {
        return 'Не удалось получить список пользователей';
    }

    return 'Произошла неизвестная ошибка';
};
