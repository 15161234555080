import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import type { Dispatch } from 'redux';

/**
 * Мок стейта, тип указывать явно не обязательно
 */
type GetState = () => any;

/**
 * Получает значение из хранилищa и localStorage для текущего пользователя
 */
export const getItemFromLocalStorage =
    <Item>(id: string) =>
    (dispatch: Dispatch, getState: GetState): Item | null =>
        UserStorageActions.get(id)(dispatch, getState);

/**
 * Добавляет значение в хранилище и localStorage для текущего пользователя
 */
export const setItemFromLocalStorage =
    <Item>(id: string, init: Item) =>
    (dispatch: Dispatch, getState: GetState) => {
        UserStorageActions.set(id, JSON.stringify(init))(dispatch, getState);
    };

/**
 * Удаляет значение из хранилища и localStorage для текущего пользователя
 */
export const clearItemFromLocalStorage = (id: string) => (dispatch: Dispatch, getState: GetState) => {
    UserStorageActions.remove(id)(dispatch, getState);
};
