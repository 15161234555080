import { captureException } from '@sentry/browser';
import { user } from 'Cloud/Application/User';
import { logger } from 'lib/logger';
import { type SetBaseQuotaQueryParams, SetBaseQuotaAPICall } from 'reactApp/api/billing/SetBaseQuotaApiCall';
import { OverquotaNewAPICall } from 'reactApp/api/overquota/OverquotaNewAPICall';
import { OverquotaUnblockAPICall } from 'reactApp/api/overquota/OverquotaUnblockAPICall';
import { ProfileAgreeLaApiCall } from 'reactApp/api/profile/ProfileAgreeLa';
import { ProfileFullApiCall } from 'reactApp/api/profile/ProfileFullAPICall';
import { ProfileUserLaApiCall } from 'reactApp/api/profile/ProfileUserLa';
import { UserNewDeviceAPICall } from 'reactApp/api/profile/UserNewDeviceAPICall';
import { IS_MOBILE_BROWSER, IS_PUBLIC, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { abDecreaseBaseQuota } from 'reactApp/appHelpers/featuresHelpers';
import { renderUpdateLicenseDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { renderCreateDocumentByQueryActionDialog } from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.helpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { DEVICE_ID_WEB } from 'reactApp/modules/profile/profile.contants';
import {
    agreeUpdateLicenseRequest,
    checkUpdateLicenseCompleted,
    checkUpdateLicenseRequest,
    setClearedUserProcessed,
    setProfileOverquotaState,
    setProfileUblockUser,
} from 'reactApp/modules/profile/profile.module';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { ProfileApiResponses } from 'reactApp/modules/profile/profile.types';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { getBooleanCookie } from 'reactApp/utils/cookie';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { sendDwh, sendGa } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

// tempexp_16521-next-line
const QUOTA_SET_DELAY = 3000;

const sentMeAdsCloudCookieName = 'sent_ads_cloud';
const defaultData = { did: DEVICE_ID_WEB };

const newDeviceApiCall = () =>
    new UserNewDeviceAPICall().makeRequest({
        ...defaultData,
        name: DEVICE_ID_WEB,
        plid: DEVICE_ID_WEB,
    });

const agreeLicenseApiCall = ({ initialize_unsubscribed, first_login = '' }) =>
    new ProfileAgreeLaApiCall().makeRequest({
        ...defaultData,
        initialize_unsubscribed,
        first_login,
    });

const checkLicenseApiCall = () => new ProfileUserLaApiCall().makeRequest(defaultData);

// tempexp_16521-next-line
const setBaseQuotaApiCall = (params: SetBaseQuotaQueryParams) => new SetBaseQuotaAPICall().makeRequest(params);

const debug = (...args) => {
    logger.verbose('[profile.saga]', ...args);
};

let isCheckUpdateDone = false;

function* handleApiError(error, retry, data = undefined) {
    const message = error?.response?.message;
    if (message === ProfileApiResponses.USER_NOT_FOUND || message === ProfileApiResponses.DEVICE_NOT_FOUND) {
        try {
            const result = yield call(newDeviceApiCall);
            debug('newDeviceApiCall:', result);
            yield call(retry, data);
        } catch (error) {
            captureException(error);
            logger.error(error);
        }
    }
}

function* hideAndUpdateNewUserQuotaActions() {
    const isPhone = EnvironmentSelectors.isPhone();

    try {
        yield call(setBaseQuotaApiCall, { quota: 4 });
        sendDwh({
            eventCategory: 'quota',
            action: 'set',
            dwhData: {
                quota: 4,
            },
        });
        if (isPhone) {
            yield delay(QUOTA_SET_DELAY);
        }
        yield put(updateUser());
    } catch (error) {
        logger.error(error);
    }
}

function* agreeUpdateLicense(action) {
    try {
        const sentMeAdsCloudCookieValue = getBooleanCookie(sentMeAdsCloudCookieName, X_PAGE_ID.toLowerCase());
        const result = yield call(agreeLicenseApiCall, {
            initialize_unsubscribed: !sentMeAdsCloudCookieValue,
            first_login: action?.payload?.source,
        });

        // tempexp_16521-start
        // CLOUDWEB-16521: После принятия ЛС пользователем нужно обновить дефолтную квоту юзера под фичей
        if (abDecreaseBaseQuota && !action?.payload?.isUpdate) {
            yield call(hideAndUpdateNewUserQuotaActions);
        }

        sendDwh({
            eventCategory: 'la',
            action: action?.payload?.isUpdate ? 'update' : 'agree',
        });

        // tempexp_16521-end
        debug('agreeLicenseApiCall:', result);
    } catch (error) {
        captureException(error);
        logger.error(error);
        yield call(handleApiError, error, agreeUpdateLicense, action);
    } finally {
        user.setParam('updateLicenseCompleted', true);
        yield put(checkUpdateLicenseCompleted());
    }
}

function* askForUpdateLicense() {
    sendGa('la-update', 'show');

    const chan = channel();

    renderUpdateLicenseDialog({
        onSuccess: () => chan.put(true),
    });

    yield take(chan);
    sendGa('la-update', 'agree');

    yield call(renderCreateDocumentByQueryActionDialog);

    yield call(agreeUpdateLicense, { payload: { source: 'update', isUpdate: true } });
}

function* checkUpdateLicense() {
    const isNewbie = yield select(UserSelectors.isNewbie);
    const isBizUser = yield select(UserSelectors.isBizUser);
    const isViewerActive = yield select(ViewerSelectors.isViewerActive);
    const isViewerOpening = yield select(ViewerSelectors.isViewerOpening);

    if (isCheckUpdateDone || isViewerActive || isViewerOpening || isNewbie || isBizUser || IS_PUBLIC || IS_MOBILE_BROWSER) {
        return;
    }

    try {
        isCheckUpdateDone = true;
        const { data } = yield call(checkLicenseApiCall);

        if (data?.status !== 'NOT_ACCEPTED') {
            yield put(checkUpdateLicenseCompleted());

            yield call(renderCreateDocumentByQueryActionDialog);

            return;
        }

        yield call(askForUpdateLicense);
    } catch (error) {
        yield call(handleApiError, error, checkUpdateLicense);
        yield put(checkUpdateLicenseCompleted());
        captureException(error);
        logger.error(error);
    }
}

export function* handleLoadUserFullProfile() {
    try {
        const { data: profileResponse } = yield new ProfileFullApiCall().makeRequest({ did: DEVICE_ID_WEB });

        if (profileResponse?.code === 'OK') {
            const data = {
                state: profileResponse.profile.properties['overquota.state'],
                blockTime: (profileResponse.profile.properties['overquota.block_time'] || 0) * 1000,
                clearTime: (profileResponse.profile.properties['overquota.clear_time'] || 0) * 1000,
                version: profileResponse.profile.version,
            };

            yield put(setProfileOverquotaState(data));
        }
    } catch (error) {
        captureException(error);
        logger.error(error);
    }
}

function* handleProfileUblockUser() {
    try {
        yield new OverquotaUnblockAPICall().makeRequest();

        yield call(handleLoadUserFullProfile);

        const quotaState = yield select(getUserOverquotaState);

        yield put(
            showSnackbarAction({
                id: 'overquota-unblocked-message',
                type: SnackbarTypes.success,
                text: `Пространство&nbsp;разблокировано до&nbsp;${formatFileHistoryDate(
                    quotaState.clearTime
                )}&nbsp;,&nbsp;после&nbsp;файлы&nbsp;будут&nbsp;удалены`,
                closable: true,
            })
        );
    } catch (error) {
        yield put(
            showSnackbarAction({
                id: 'overquota-unblocked-message-err',
                type: SnackbarTypes.failure,
                text: `Не удалось разблокировать`,
                closable: true,
            })
        );
        captureException(error);
        logger.error(error);
    }
}

function* handleClearedUser() {
    try {
        yield new OverquotaNewAPICall().makeRequest();

        yield call(handleLoadUserFullProfile);
    } catch (error) {
        captureException(error);
        logger.error(error);
    }
}

export function* watchProfile() {
    yield takeEvery(routeChangeSuccess.toString(), checkUpdateLicense);
    yield takeEvery(checkUpdateLicenseRequest.toString(), checkUpdateLicense);
    yield takeLatest(agreeUpdateLicenseRequest.toString(), agreeUpdateLicense);
    yield takeLatest(setProfileUblockUser.toString(), handleProfileUblockUser);
    yield takeLatest(setClearedUserProcessed.toString(), handleClearedUser);
}
