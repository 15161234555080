import cloudRebrandingIcon from 'img/icons/cloud_rebranding.svg?url';
import React, { type VFC, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { sortQuotaPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import {
    getBasicSubscription,
    getSubscriptionsLoadingState,
    sortSubscriptionsBySpace,
} from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { SubscriptionListComponent } from './SubscriptionList/SubscriptionListComponent';
import styles from './SubscriptionsModal.css';
import type { SubscriptionList } from './SubscriptionsModal.types';

export const SubscriptionsModal: VFC<IPropsWithPopup> = ({ onClose, isRebrandingQuotaLanding, isRebranding }) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const { isLoaded } = useSelector(getSubscriptionsLoadingState);

    const basicSubscription = useSelector(getBasicSubscription);
    const subscriptions = useSelector(sortSubscriptionsBySpace);
    const promoSubscriptions = useSelector(sortQuotaPromoBySpace);

    const subscriptionList = useMemo(() => {
        const baseSubs = isRebranding ? { ...basicSubscription, icon: cloudRebrandingIcon } : basicSubscription;

        if (isLoaded) {
            return [baseSubs, ...subscriptions, ...promoSubscriptions].map((item) => {
                const partner = 'partner' in item && item?.partner;

                return isRebranding && item?.icon && !partner ? { ...item, icon: cloudRebrandingIcon } : item;
            }) as SubscriptionList;
        }
        return [baseSubs] as SubscriptionList;
    }, [isRebranding, basicSubscription, isLoaded, subscriptions, promoSubscriptions]);

    return (
        <MobileDialog
            id="mobile-subscriptions-dialog"
            onClose={onClose}
            closeOnDimmerClick
            mod="confirm"
            topmost
            innerClassNames={{ title: isRebrandingQuotaLanding ? styles.root_rebrandingQuotaLanding : styles.root }}
            title="Мои подписки"
            scrollRef={scrollRef}
            className={isRebranding ? styles.dialog__content : ''}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            content16PaddingOnly={isRebrandingQuotaLanding}
        >
            <SubscriptionListComponent
                ref={scrollRef}
                subscriptionList={subscriptionList}
                onClose={onClose}
                isRebranding={isRebranding}
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            />
        </MobileDialog>
    );
};
