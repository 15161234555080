import { ReactComponent as ArrowIcon } from 'img/icons/editor/arrowBack.svg';
import { ReactComponent as CloudIcon } from 'mrg-icons/src/mailru/logotypes/cloud.svg';
import React, { type FC } from 'react';
import { getIsCloudAvailabe } from 'reactApp/appHelpers/appHelpers';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { goToRegisterPage } from 'reactApp/modules/ph/ph.helpers';
import { HeaderButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton';
import { HeaderFileName } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderLeftGroup/components/HeaderFileName/HeaderFileName';
import { noop, noopPromise } from 'reactApp/utils/helpers';

import styles from './HeaderLeftGroup.css';

interface Props {
    backLink: string;
    file: EditorItem | null;
    isAnonymous: boolean;
    onBackToCloud: (() => void) | null;
    isPublic: boolean;
    returnButtonTitle?: string;
    enableFavorites: boolean;
    enableRenaming: boolean;
    toggleFavorite: (file: EditorItem) => void;
    gaSender: (action: string, label: string) => void;
    handleFileNameEdit: ({ value: string }) => Promise<string | void>;
    sendEditorRadar: (i: string) => void;
}

export const HeaderLeftGroup: FC<Props> = (props) => {
    const {
        backLink,
        file,
        isAnonymous,
        isPublic,
        onBackToCloud,
        returnButtonTitle,
        enableRenaming,
        gaSender,
        handleFileNameEdit,
        sendEditorRadar,
    } = props;

    // Скрываем кнопку, т.к. у анонминого пользователя нет возможности завести облако в on-premise
    const isCloudAvailabe = IS_ONPREMISE ? !isAnonymous && getIsCloudAvailabe() : getIsCloudAvailabe();
    // Скрываем кнопку в on-premise, т.к. пользователь не может сам завести себе облако
    const showCreateCloudButton = isAnonymous && !IS_ONPREMISE;

    const handleFileNameClick = () => {
        gaSender('rename', 'click');
    };

    return (
        <div className={styles.leftGroup}>
            {showCreateCloudButton ? (
                <HeaderButton title="Завести Облако" onClick={goToRegisterPage} icon={<CloudIcon />} className={styles.backButton} />
            ) : (
                isCloudAvailabe && (
                    <HeaderButton
                        qaId="back"
                        title={returnButtonTitle}
                        link={onBackToCloud ? null : backLink}
                        onClick={onBackToCloud || noop}
                        icon={<ArrowIcon />}
                        className={styles.backButton}
                        sendEditorRadar={sendEditorRadar}
                    />
                )
            )}
            <HeaderFileName
                qaId="file-name"
                fileName={file?.nameWithoutExt || ''}
                readOnly={!enableRenaming || isPublic || !!file?.readOnly}
                onEdit={enableRenaming ? handleFileNameEdit : noopPromise}
                onClick={enableRenaming ? handleFileNameClick : noop}
            />
        </div>
    );
};
