import type { Method } from 'lib/axios';

import { APICallV4 } from '../APICall';

export class WeblinksEmailAccessListV4ApiCall extends APICallV4 {
    _method = 'weblinks/id/access';
    _type: Method = 'post';
}

export const weblinksEmailAccessListV4ApiCall = ({ weblink }) => new WeblinksEmailAccessListV4ApiCall().makeRequest({ id: weblink });
