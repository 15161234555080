import React, { type ReactElement, memo, useRef } from 'react';
import { PROJECT_LIST } from 'reactApp/sections/SassLanding/SassLanding.data';
import { sendSassGa } from 'reactApp/sections/SassLanding/SassLanding.helpers';
import styles from 'reactApp/sections/SassLanding/SassMobileNavigationPanel/SassMobileNavigation.css';
import { sendSassTmrGoal } from 'reactApp/sections/SassLanding/SassTmr';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { UserBlock } from 'reactApp/ui/Mobile/UserBlock/UserBlock';
import opener from 'reactApp/utils/opener';

interface IProps {
    onClose: () => void;
}

export const SassMobileNavigationPopup = memo(({ onClose }: IProps): ReactElement | null => {
    const scrollRef = useRef(null);

    return (
        <LeftDialog onClose={onClose} mode={'white'}>
            <div className={styles.menu}>
                <div className={styles.dialog} ref={scrollRef}>
                    {PROJECT_LIST.map((tab) => (
                        <div
                            key={tab.id}
                            className={styles.tab}
                            onClick={() => {
                                sendSassGa({
                                    action: 'click_project',
                                    eventCategory: 'click',
                                    project: tab.id,
                                });
                                sendSassTmrGoal({ goal: `click_project_${tab.id}` });
                                onClose();
                                opener(tab.link);
                            }}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
                <div className={styles.userBlock}>
                    <UserBlock isLeftMenu />
                </div>
            </div>
        </LeftDialog>
    );
});

SassMobileNavigationPopup.displayName = 'SassMobileNavigationPopup';
