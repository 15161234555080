import { Icon20Add } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, PureComponent } from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { type CreateNewMod, CREATE_ITEM_DATA } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew.data';
import { generateUuidV4, noop } from 'reactApp/utils/helpers';

import styles from './DataListItemCreateNew.css';
import { DataListItemCreateNew as DataListItemCreateNewNew } from './new/DataListItemCreateNew';

const NEW_ITEM_CAPTION = IS_BIZ_USER ? 'Создать' : 'Создать документ\nили папку';

interface Props {
    isThumb: boolean;
    isSharedFolderOnly?: boolean;
    onClick(e: React.MouseEvent);
    onContextMenu(e: React.MouseEvent);
    mod?: CreateNewMod;
    rootRef?: React.Ref<HTMLDivElement>;
    storage?: EStorageType;
}

export const ID_NEW_ITEM = generateUuidV4();

const Icon = ({ isThumb }) => (
    <div className={classNames(styles.addIcon, { [styles.addIconThumb]: isThumb })}>
        <Icon20Add />
    </div>
);
class DataListItemCreateNewCompoent extends PureComponent<Props> {
    public static defaultProps = {
        isThumb: true,
        onClick: noop,
        onContextMenu: noop,
        isSharedFolderOnly: false,
    };

    private handleOnClick = (e) => {
        const { onClick, mod } = this.props;
        const itemData = mod && CREATE_ITEM_DATA[mod];

        if (itemData?.onClick) {
            itemData.onClick(e);
            return;
        }

        onClick(e);
    };

    private handleOnContextMenu = (e) => {
        const { onContextMenu, mod } = this.props;
        const itemData = mod && CREATE_ITEM_DATA[mod];

        if (itemData?.onContextMenu) {
            itemData.onContextMenu(e);
            return;
        }

        onContextMenu(e);
    };

    private getCaption = () => {
        const { isThumb, isSharedFolderOnly, mod, storage } = this.props;
        const itemData = mod && CREATE_ITEM_DATA[mod];

        if (itemData?.text) {
            return isThumb ? itemData?.text : itemData?.text?.replace('\n', ' ');
        }

        if (isSharedFolderOnly) {
            return 'Создать общую папку';
        }

        if (storage === EStorageType.sharedAutodelete) {
            return IS_BIZ_USER ? 'Создать' : 'Создать папку';
        }

        return isThumb ? NEW_ITEM_CAPTION : NEW_ITEM_CAPTION.replace('\n', ' ');
    };

    public render(): ReactElement | null {
        const { isThumb, mod, rootRef = null } = this.props;

        const itemData = mod && CREATE_ITEM_DATA[mod];
        const caption = this.getCaption();
        const icon = itemData?.icon || <Icon isThumb={isThumb} />;

        return (
            <div
                className={classNames(styles.root, { [styles.rootThumb]: isThumb, [styles[`root_${mod}`]]: !!mod })}
                onClick={this.handleOnClick}
                onContextMenu={this.handleOnContextMenu}
                ref={rootRef}
            >
                <div className={styles.icon}>{icon}</div>
                <div className={styles.caption}>{caption}</div>
            </div>
        );
    }
}

export const DataListItemCreateNew = Object.assign(DataListItemCreateNewCompoent, { New: DataListItemCreateNewNew });
