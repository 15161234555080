import React, { type ReactNode, useCallback } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { noop } from 'reactApp/utils/helpers';

import styles from './UnsavedChangesModal.css';

export interface UnsavedChangesModalProps {
    successButtonText?: string;
    onSuccess?: () => void;
    onShow?: () => void;
    onClose?: () => void;
    onAction?: (data) => void;
    description: ReactNode;
}

const buttons = [EButtonType.accept, EButtonType.action];

export function UnsavedChangesModal({
    onClose = noop,
    onShow = noop,
    onSuccess = noop,
    onAction = noop,
    description,
    successButtonText = 'Выбрать тариф',
}: UnsavedChangesModalProps) {
    const header = useCallback(() => 'Сохранить изменения?', []);
    const content = useCallback(() => <div className={styles.description}>{description}</div>, []);
    return (
        <BaseConfirmDialog
            className={styles.root}
            onSuccess={onSuccess}
            onShow={onShow}
            onClose={onClose}
            onAction={onAction}
            renderHeader={header}
            renderContent={content}
            buttons={buttons}
            size="small"
            successButtonText={successButtonText}
            actionButtonText="Не сохранять"
            closable
            showCloseIcon
            closeOnDimmerClick
        />
    );
}
