import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import classNames from 'clsx';
import React, { type VFC, memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { datalistInlineRenameEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { useRenameInlineDoubleClick } from 'reactApp/hooks/useRenameInlineDoubleClick';
import { EInlineEditPlace } from 'reactApp/modules/modifying/modifying.types';
import { getSearchContentStatus } from 'reactApp/modules/search/search.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { ContentEditable } from 'reactApp/ui/ContentEditable/ContentEditable';
import { DataListCheckbox } from 'reactApp/ui/DataListCheckbox/DataListCheckbox';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { Progress } from 'reactApp/ui/DataListItemRow/components/Progress';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';
import { WebSearchSource } from 'reactApp/ui/WebSearch/WebSearchSource/WebSearchSource';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';
import { noop } from 'reactApp/utils/helpers';

import { ETooltipPlacement, ETooltipSize } from '../FloatingTooltip/FloatingTooltip.types';
import { Favorites } from './components/Favorites';
import { Pic } from './components/Pic';
import { Publish } from './components/Publish';
import styles from './DataListItemThumb.css';
import type { DataListItemThumbProps } from './DataListItemThumb.types';
/** tempexp_14729-next-line */
import { DataListItemThumb as DataListItemThumbNew } from './new/DataListItemThumb';

const getDownloadHint = (ext?: string, size?: number) =>
    ext
        ? `Скачать .${ext.toLowerCase()}${size ? `, ${bytesToNDigits(size, 3).value}` : ''}`
        : `Скачать${size ? ` ${bytesToNDigits(size, 3).value}` : ''}`;

// eslint-disable-next-line max-lines-per-function
export const DataListItemThumbComponent: VFC<DataListItemThumbProps> = (props) => {
    const {
        isSelected,
        isActive,
        isSelecting,
        forceCheckbox,
        onCheckboxClick,
        size,
        isFolder,
        name,
        ext,
        tipData,
        id,
        denyDoAction,
        progress,
        storage,
        isPublicDownloaded,
        isPopup,
        parentItemRef,
    } = props;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const config = STORAGE_CONFIG[props.storage];
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);

    const isRenameAvailable = 'renameAvailable' in props && props.renameAvailable;
    const { isEdit, handleDoubleClick, onRename } = useRenameInlineDoubleClick(name, id, EInlineEditPlace.datalistThumbs, ext);

    const handleDropdown = useCallback(
        (isOpen) => {
            if (denyDoAction) {
                return null;
            }

            setDropdownOpen(isOpen);
        },
        [setDropdownOpen, denyDoAction]
    );

    const renderFooter = () => {
        if (isPopup) {
            return null;
        }

        switch (storage) {
            case EStorageType.home:
            case EStorageType.sharedLinks:
            case EStorageType.sharedAutodelete:
            case EStorageType.favorites:
            case EStorageType.search:
            case EStorageType.feed:
            case EStorageType.documents:
            case EStorageType.gallery:
            case EStorageType.alldocuments:
                if (storage === EStorageType.home && denyDoAction) {
                    return <Progress className={styles.progress} progress={progress} onClick={props.onCancelUpload} />;
                }

                return (
                    <Hint text="Поделиться ссылкой">
                        <div>
                            <Publish isActive={props.isPublic} onClick={props.onPublish} />
                        </div>
                    </Hint>
                );
            case EStorageType.attaches:
            case EStorageType.public:
                return (
                    props.owner && (
                        <Hint text={props.owner.name || props.owner.email}>
                            <div className={styles.owner}>
                                <Avatar email={props.owner.email} name={props.owner.name} size={30} />
                            </div>
                        </Hint>
                    )
                );
        }
    };

    const renderFavorites = () => {
        const isFavorites = storage === EStorageType.favorites;
        const isBannedForFavorite = 'listOfBannedToFavoriteItems' in props && props.listOfBannedToFavoriteItems.includes(id);
        const isFavoritesEnable = config.favorites && 'favoritesAvailable' in props && props.favoritesAvailable && !isBannedForFavorite;

        if (denyDoAction || !isFavoritesEnable || isPopup) {
            return null;
        }

        return (
            <Favorites
                isActive={'isInFavorites' in props && Boolean(props.isInFavorites)}
                onClick={'onFavorites' in props ? props.onFavorites : noop}
                isActiveHidden={isFavorites}
            />
        );
    };

    const renderSearchSource = useMemo(() => {
        const isContentMatch = 'srchSrc' in props && props.srchSrc === ESearchOptionSource.content;

        if (!isContentMatch || !webSearchContentEnabled || storage !== EStorageType.search || !props.query) {
            return null;
        }

        return <WebSearchSource query={props.query} />;
    }, [props, webSearchContentEnabled, storage]);

    const renderPublicExpires = useMemo(() => {
        if (renderSearchSource || denyDoAction || isPopup) {
            return null;
        }

        const expires = 'publicExpires' in props && props.publicExpires;
        const isAutoDelete = 'isAutoDelete' in props && props.isAutoDelete;

        if (!!expires && isAutoDelete) {
            return <div className={styles.expires}>Удалится {formatAutoDeleteExpires(expires, true)}</div>;
        }
    }, [renderSearchSource, props]);

    const renderDownloadButton = () => {
        if (denyDoAction || forceCheckbox || isPopup || (storage === EStorageType.public && isPublicDownloaded)) {
            return null;
        }

        const downloadHint = getDownloadHint(ext, size);

        return ext && downloadAsJpeg.isAvailable({ ext, storage }) ? (
            <DataListControl
                type="downloadAsJpeg"
                onClick={props.onDownload}
                onDropdown={handleDropdown}
                list={downloadAsJpeg.getDropdownItems({
                    itemOrId: id,
                    gaSuffix: 'datalist',
                    ext,
                    textPrefix: 'в',
                    storage: props.storage,
                })}
                hint={downloadHint}
                viewType={EViewMode.thumbs}
            />
        ) : (
            <Hint text={downloadHint}>
                <div>
                    <DataListControl type="download" onClick={props.onDownload} viewType={EViewMode.thumbs} />
                </div>
            </Hint>
        );
    };

    return (
        <div
            ref={parentItemRef}
            className={classNames({
                [styles.root]: true,
                [styles.root_selected]: isSelected || isDropdownOpen,
                [styles.root_active]: isActive,
                [styles.root_selecting]: isSelecting,
            })}
        >
            {!isPopup && (
                <div className={styles.pinLeft}>
                    {!!tipData && (
                        <FloatingTooltip
                            target={parentItemRef}
                            className={styles.tooltip}
                            size={ETooltipSize.large}
                            text={tipData[0].getTipContent?.(isFolder)}
                            placement={ETooltipPlacement.top}
                            closeOnOutsideClick={false}
                            qaId={tipData[0].id}
                            onClose={tipData[0].onHideClick}
                            usePortal
                        />
                    )}
                </div>
            )}
            <div className={styles.pin}>
                {renderDownloadButton()}
                {(forceCheckbox || isPopup) && (
                    <Hint text={isSelected ? 'Снять выделение' : 'Выделить'}>
                        <div onClick={onCheckboxClick}>
                            <DataListCheckbox checked={isSelected} />
                        </div>
                    </Hint>
                )}
            </div>
            <div className={styles.content}>
                <Pic
                    isFolder={props.isFolder}
                    thumbUrl={props.thumbUrl}
                    kind={props.kind}
                    isVirus={'isVirus' in props && Boolean(props.isVirus)}
                    isAutoDelete={'isAutoDelete' in props && props.isAutoDelete}
                    ext={props.ext || ''}
                    isLoading={denyDoAction}
                />
            </div>
            <div className={styles.footer}>
                <div className={styles.controls}>{renderFooter()}</div>
                <div className={styles.meta}>
                    <div className={styles.name}>
                        {renderFavorites()}
                        {isEdit ? (
                            <div className={styles.edit}>
                                <ContentEditable value={name} onEdit={onRename} selectAllOnFocus />
                            </div>
                        ) : (
                            <div className={styles.nameWrapper}>
                                <NameComponent
                                    name={name}
                                    extension={ext}
                                    showTooltip
                                    onDoubleClick={isRenameAvailable && datalistInlineRenameEnabled ? handleDoubleClick : noop}
                                />
                            </div>
                        )}
                    </div>
                    {renderSearchSource}
                    {renderPublicExpires}
                </div>
            </div>
        </div>
    );
};

/** tempexp_14729-next-line */
export const DataListItemThumb = Object.assign(memo(DataListItemThumbComponent), { New: DataListItemThumbNew });
DataListItemThumb.displayName = 'DataListItemThumb';
DataListItemThumb.whyDidYouRender = true;
