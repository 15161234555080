import api from 'Cloud/Application/api';
import { getErrorMsg, logger } from 'lib/logger';
import { QuotaAPICall } from 'reactApp/api/QuotaAPICall';
import { IS_BLOCKED } from 'reactApp/appHelpers/configHelpers';
import { canShowUserQuota } from 'reactApp/modules/family/family.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaWithFamilyRequest } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.module';
import { loadUserQuotaRequest, loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { noop } from 'reactApp/utils/helpers';
import { call, cancel, put, select, takeLatest } from 'redux-saga/effects';

const callApi = () => new QuotaAPICall().makeRequest();

type AnonymousSelector = ReturnType<typeof UserSelectors.isAnonymous>;

type ApiCall = Awaited<ReturnType<typeof callApi>>;

export function* loadQuota(action?: { payload: { isSilent: boolean } }) {
    const isSilent = Boolean(action?.payload?.isSilent);

    try {
        const isAnonymous: AnonymousSelector = yield select(UserSelectors.isAnonymous);
        const shouldLoadFamilyQuota = yield select(canShowUserQuota);

        if (isAnonymous || IS_BLOCKED) {
            return;
        }

        // Дергаем старую ручку квоты - на нее завязана аналитика DAU, потом сделают более лучший вариант.
        // timeout - чтобы не сильно мешать другим запросам и влиять на скорость отображения страницы
        setTimeout(() => api.user.space().done(noop), 2000);

        if (shouldLoadFamilyQuota) {
            yield put(loadUserQuotaWithFamilyRequest());
        }

        const { data }: ApiCall = yield call(callApi);

        yield put(loadUserQuotaSuccess({ ...data, isSilent }));
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield cancel();
    }
}

export function* watchUserQuota() {
    yield takeLatest(loadUserQuotaRequest.toString(), loadQuota);
}
