import { Icon20Cancel, Icon20HistoryBackwardOutline } from '@vkontakte/icons';
import { Button, Cell, Headline, List, unstable_Popper as Popper } from '@vkontakte/vkui';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';
import { searchHistoryClear, searchHistoryRemoveItem } from 'reactApp/modules/search/search.module';
import { type IHistoryItem, EActionSearchHistory } from 'reactApp/modules/search/search.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './SearchSuggests.css';

interface IProps {
    rootRef: React.RefObject<HTMLElement>;
    targetRef: React.RefObject<HTMLDivElement>;
    items: IHistoryItem[];
    onClick: (value: string) => void;
    onClickAway: () => void;
}

export const SearchSuggests: React.FC<IProps> = ({ rootRef, targetRef, items, onClick, onClickAway }) => {
    const ref = useRef<HTMLDivElement>(null);
    useClickOutsideTarget({ rootRef, ref, onClickAway });
    const storeDispatch = useDispatch();

    const onClickHistory = (query: string) => {
        sendDwh({
            eventCategory: ECategoryGa.search_history,
            action: EActionSearchHistory.open,
            dwhData: {
                search_phrase: query,
                history_pos: items.findIndex((item) => query === item.text),
                count_history: items.length,
                place: 'web',
            },
        });
        onClick(query);
    };

    const onDeleteHistory = (query: string) => {
        sendDwh({
            eventCategory: ECategoryGa.search_history,
            action: EActionSearchHistory.delete,
            dwhData: {
                search_phrase: query,
                history_pos: items.findIndex((item) => query === item.text),
                count_history: items.length,
                place: 'web',
            },
        });
        storeDispatch(searchHistoryRemoveItem(query));
    };

    const onClear = () => {
        sendDwh({
            eventCategory: ECategoryGa.search_history,
            action: EActionSearchHistory.clear,
            dwhData: {
                count_history: items.length,
                place: 'web',
            },
        });
        storeDispatch(searchHistoryClear());
    };

    return (
        <Popper sameWidth offsetDistance={10} placement="bottom-start" targetRef={targetRef} forcePortal={false}>
            <div ref={ref} id="dropdownList" data-qa-id="search-suggests">
                <List className={styles.container}>
                    <Cell
                        after={
                            <Button className={styles.resetButton} hasHover={false} mode="tertiary" onClick={onClear}>
                                <Headline>Очистить</Headline>
                            </Button>
                        }
                    >
                        <Headline className={styles.header} level="1" weight="2">
                            История поиска
                        </Headline>
                    </Cell>
                    {items.map(({ text }) => (
                        <Cell
                            hoverMode={styles.hover}
                            key={text}
                            before={<Icon20HistoryBackwardOutline fill="var(--vkui--color_icon_secondary)" />}
                            after={
                                <div
                                    className={styles.clearButton}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onDeleteHistory(text);
                                    }}
                                >
                                    <Icon20Cancel />
                                </div>
                            }
                            onClick={() => onClickHistory(text)}
                        >
                            {text}
                        </Cell>
                    ))}
                </List>
            </div>
        </Popper>
    );
};

SearchSuggests.displayName = 'SearchSuggests';
