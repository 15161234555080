import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { getItemsSize } from 'reactApp/modules/userQuotaCleaner/helpers/getItemsSize';
import {
    deleteLettersFromQuota,
    deleteUserQuotaCleanerItems,
    reloadGroupAfterDelete,
    setIsItemsDeleteError,
    setMessagesRemove,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getCurrentGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import type { QuotaCleanerGroup, QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { UserQuotaGroupId } from 'reactApp/types/QuotaCleaner';
import { renderDeleteConfirmationDialog } from 'reactApp/ui/DeleteConfirmationDialog/deleteConfirmationDialog.toolkit';
import { getSize } from 'reactApp/utils/sizeHelper';

interface Props {
    items: QuotaCleanerItem[] | CloudItem[];
}

/**
 * Удаление элементов квоты в рамках одной группы
 */
export const useDeleteItems = ({ items }: Props) => {
    const dispatch = useDispatch();
    const currentGroup = useSelector(getCurrentGroup);
    const isMailBinDisabled = useSelector(UserQuotaSelectors.getIsMailBinDisabled);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { groupId, groupType, totalCount, size: groupSize } = currentGroup as QuotaCleanerGroup;

    const deleteSize = useMemo(() => {
        const size = getItemsSize(items as QuotaCleanerItem[]);
        return {
            size,
            sizeString: getSize(size),
        };
    }, [items]);

    const onDeleteSuccess = () => {
        sendQuotaCleanerGa({
            action: 'cleaner_cleaned',
            dwh: {
                name_block: groupId,
                size_files: groupSize,
                count_files: totalCount,
                size_files_cleaned: deleteSize.size,
                count_files_cleaned: items.length,
            },
        });
    };

    const ids = useMemo(() => items.map((item) => item.deleteId), [items]);
    const itemIds = useMemo(() => items.map((item) => item.id), [items]);

    const onGroupReload = useCallback(() => {
        dispatch(reloadGroupAfterDelete({ ids, itemIds, groupId, size: deleteSize.size }));
    }, [ids, itemIds, groupId, deleteSize, dispatch]);

    const onDelete = useCallback(() => {
        dispatch(deleteUserQuotaCleanerItems({ ids, itemIds, items, groupId, groupType, size: deleteSize.size, onDeleteSuccess }));
        if (isMobile) {
            dispatch(setShowUploaderAction(false));
            dispatch(startSelecting());
        }
    }, [ids, itemIds, items, deleteSize, currentGroup, onDeleteSuccess, dispatch]);

    const onLettersDelete = useCallback(() => {
        dispatch(deleteLettersFromQuota({ ids, itemIds, groupId, groupType, size: deleteSize.size, onDeleteSuccess }));
    }, [ids, itemIds, groupId, groupType, deleteSize, onDeleteSuccess, dispatch]);

    const callback = useCallback(() => {
        dispatch(setIsItemsDeleteError({ value: false })); // сбрасываем ошибки удаления, если они были

        if (groupId === UserQuotaGroupId.MailBin) {
            dispatch(setMessagesRemove({ value: true }));
        }

        const count = items.length;
        renderDeleteConfirmationDialog({
            count,
            groupType,
            groupId,
            deleteSize: deleteSize.sizeString,
            isTwoStepLettersDelete: !isMailBinDisabled,
            onDelete,
            onLettersDelete,
            onGroupReload,
        });
    }, [items, deleteSize, onDelete, isMailBinDisabled, onLettersDelete, onGroupReload, groupType, groupId, dispatch]);

    return {
        callback,
        deleteSize,
    };
};
