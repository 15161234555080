import React, { type FC } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { EditorLLMDialogInput } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Footer/components/EditorLLMDialogInput/EditorLLMDialogInput';
import { EditorLLMDialogSelect } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Footer/components/EditorLLMDialogSelect/EditorLLMDialogSelect';

import styles from './EditorLLMDialogFooter.css';

export const EditorLLMDialogFooter: FC = () => (
    <div className={styles.footer}>
        {isRebranding ? (
            <div className={styles.select_section}>
                <EditorLLMDialogSelect />
            </div>
        ) : (
            <EditorLLMDialogSelect />
        )}
        <EditorLLMDialogInput />
    </div>
);
