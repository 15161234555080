import { disableFeature, enableFeature, isFeature, resetFeature } from 'Cloud/Application/FeaturesEs6';
import settings from 'Cloud/settings';
import { parse } from 'qs';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureMobileGalleryGrid3 } from 'reactApp/modules/features/features.selectors';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import {
    loadSettingsRequest,
    setAttachesViewMode,
    setFilesViewMode,
    setPhotoViewMode,
    setPhotoViewModeMobile,
    setQuotaCleanerViewMode,
    setViewMode,
} from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode, EViewStoreType } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { isMobileGalleryViewMode } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage.helpers';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { cancel, put, select, takeEvery } from 'redux-saga/effects';

export function initConsoleApi() {
    window['app'] = window['app'] ?? {};
    Object.assign(window['app'], {
        ...window['app'],
        isFeature: (name: string) => isFeature(name),
        /** Включает фичу со значением data, будет включена неделю */
        enableFeature: (name: string, data?: any) => enableFeature(name, data),
        /** Выключает фичу, будет выключена неделю */
        disableFeature: (name: string) => disableFeature(name),
        /** Сбрасывает значение фичи до дефолтной */
        resetFeature: (name: string) => resetFeature(name),
        cloudSettings: settings,
    });
}

export function reportFeatures() {
    const enabledFeatures = settings.params.FEATURES.enabled || [];

    const names = {};
    enabledFeatures.forEach((featureName) => {
        names[featureName] = 1;
    });
    sendXray(['feature-on'], names);
}

function* handleLoadSettings() {
    try {
        const isNewMobileGalleryGridEnabled = yield select(getFeatureMobileGalleryGrid3);

        let photoViewMode = yield put(UserStorageActions.get(EViewStoreType.photo));
        if (!photoViewMode) {
            photoViewMode = EViewMode.gallery;

            yield put(UserStorageActions.set(EViewStoreType.photo, EViewMode.gallery));
        }

        let photoViewModeMobile = yield put(UserStorageActions.get(EViewStoreType.photoMobile));
        if (!photoViewModeMobile) {
            /**
             * TODO: CLOUDWEB-13281. Cетка 'galleryGrid3' плохо перформит.
             * Убрали под фичу.
             */
            photoViewModeMobile = isNewMobileGalleryGridEnabled ? EViewMode.galleryGrid3 : EViewMode.thumbs;

            yield put(UserStorageActions.set(EViewStoreType.photoMobile, photoViewModeMobile));
        }

        let attachesViewMode = yield put(UserStorageActions.get(EViewStoreType.attaches));
        if (!attachesViewMode) {
            attachesViewMode = EViewMode.list;

            yield put(UserStorageActions.set(EViewStoreType.attaches, EViewMode.list));
        }

        let filesViewMode = yield put(UserStorageActions.get(EViewStoreType.files));
        if (!filesViewMode) {
            filesViewMode = EViewMode.thumbs;

            yield put(UserStorageActions.set(EViewStoreType.files, EViewMode.thumbs));
        }

        let quotaCleanerViewMode = yield put(UserStorageActions.get(EViewStoreType.quotaCleaner));
        if (!quotaCleanerViewMode) {
            quotaCleanerViewMode = EViewMode.list;

            yield put(UserStorageActions.set(EViewStoreType.quotaCleaner, EViewMode.list));
        }

        /**
         * TODO: CLOUDWEB-13281. Cетка 'galleryGrid3' плохо перформит.
         * Убрали под фичу.
         */
        if (!isNewMobileGalleryGridEnabled && isMobileGalleryViewMode(photoViewModeMobile)) {
            photoViewModeMobile = EViewMode.thumbs;
        }

        yield put(setFilesViewMode({ viewMode: filesViewMode }));
        yield put(setPhotoViewMode({ viewMode: photoViewMode }));
        yield put(setPhotoViewModeMobile({ viewMode: photoViewModeMobile }));
        yield put(setAttachesViewMode({ viewMode: attachesViewMode }));
        yield put(setQuotaCleanerViewMode({ viewMode: quotaCleanerViewMode }));
    } catch (error) {
        yield cancel();
    }
}

function* handleSetViewMode(action: ReturnType<typeof setViewMode>) {
    const { viewMode, storage, isMediaFolder } = action.payload;

    const { isGallery, isAttaches, isQuotaCleaner } = getStorage(storage);

    const prevViewMode: EViewMode = yield select(SettingsSelectors.getPrevViewMode);
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    sendGa('switch-view', prevViewMode, viewMode);

    if ((isGallery || isMediaFolder) && !isPhone) {
        yield put(UserStorageActions.set(EViewStoreType.photo, viewMode));
        return;
    }

    if (isGallery && isPhone) {
        yield put(UserStorageActions.set(EViewStoreType.photoMobile, viewMode));
        return;
    }

    if (isAttaches) {
        yield put(UserStorageActions.set(EViewStoreType.attaches, viewMode));
        return;
    }

    if (isQuotaCleaner) {
        yield put(UserStorageActions.set(EViewStoreType.quotaCleaner, viewMode));
        return;
    }

    yield put(UserStorageActions.set(EViewStoreType.files, viewMode));
}

export function* setQueryParams() {
    yield settingsController.setQueryParams(parse(window.location.search, { ignoreQueryPrefix: true }));
}

export function* watchSettings() {
    yield takeEvery(loadSettingsRequest.toString(), handleLoadSettings);
    yield takeEvery(setViewMode.toString(), handleSetViewMode);
}
