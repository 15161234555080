import React, { forwardRef } from 'react';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { isBaseSubscription, renderSubscriptionInfoModal } from 'reactApp/ui/Mobile/SubscriptionInfoModal/SubscriptionInfoModal.helpers';

import { useSendAnalyticsAfterOpen } from '../hooks/useSendAnalyticsAfterOpen';
import type { Subscription, SubscriptionList } from '../SubscriptionsModal.types';
import { BaseSubscriptionItemComponent } from './Components/BaseSubscriptionItem/BaseSubscriptionItem';
import { ModernSubscriptionItemComponent } from './Components/ModernSubscriptionItem/ModernSubscriptionListItemComponent';
import styles from './SubscriptionListComponent.css';

type Props = IPropsWithPopup<{ subscriptionList: SubscriptionList; isRebranding?: boolean; isRebrandingQuotaLanding?: boolean }>;

export const SubscriptionListComponent = forwardRef<HTMLDivElement, Props>(
    ({ subscriptionList, onClose, isRebranding = false, isRebrandingQuotaLanding }, ref) => {
        useSendAnalyticsAfterOpen(subscriptionList);

        const handleClick = (subscription: Subscription) => {
            onClose();
            renderSubscriptionInfoModal({ subscription, isRebrandingQuotaLanding, isRebranding });
        };

        return (
            <div className={styles.root} ref={ref}>
                {subscriptionList.map((subscription) =>
                    isBaseSubscription(subscription) ? (
                        <BaseSubscriptionItemComponent
                            key={subscription.id}
                            subscription={subscription}
                            onClick={() => handleClick(subscription)}
                            isRebranding={isRebranding}
                            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        />
                    ) : (
                        <ModernSubscriptionItemComponent
                            key={subscription.id}
                            subscription={subscription}
                            onClick={() => handleClick(subscription)}
                            isRebranding={isRebranding}
                            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        />
                    )
                )}
            </div>
        );
    }
);

SubscriptionListComponent.displayName = 'SubscriptionListComponent';
