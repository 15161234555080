import { Icon16ArrowLeftOutline, Icon16ArrowRightOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStoryListLoadMore, showStory } from 'reactApp/modules/stories/stories.module';
import { hasSummariesLoadMore } from 'reactApp/modules/stories/stories.selectors';
import type { SwiperNavigator } from 'reactApp/types/SwiperNavigator';
import type { StoriesWidgetNewProps } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StoryItem } from './StoriesItem/StoryItem';
import styles from './StoriesWidgetComponent.css';

const swiperConfig = {
    pagination: {
        clickable: true,
    },
    modules: [Mousewheel],
};

export const StoriesWidgetComponent = memo(function StoriesWidgetComponent({ stories }: StoriesWidgetNewProps) {
    const [navState, setNavState] = useState({ isFirst: true, isLast: true });
    const navigator = useRef<SwiperNavigator | null>(null);

    const hasStoriesLoadMore = useSelector(hasSummariesLoadMore);
    const dispatch = useDispatch();

    const handleStoryClick = useCallback(
        ({ id, type }) => {
            dispatch(showStory({ storyId: id, type }));
        },
        [dispatch]
    );

    const handleReachEnd = useCallback(() => {
        if (navigator.current?.isLast() && hasStoriesLoadMore) {
            dispatch(getStoryListLoadMore());
        }
        setNavState({ isFirst: false, isLast: true });
    }, [navigator, dispatch, hasStoriesLoadMore]);

    const handlePrevStory = useCallback(() => {
        navigator.current?.navigateToPrev();
    }, [navigator]);

    const handleNextStory = useCallback(() => {
        navigator.current?.navigateToNext();
        if (navigator.current?.isLast() && hasStoriesLoadMore) {
            dispatch(getStoryListLoadMore());
        }
    }, [dispatch, navigator, hasStoriesLoadMore]);

    const handleSwiper = useCallback(
        (swiper) => {
            navigator.current = {
                navigateToNext: () => swiper?.slideNext(),
                navigateToPrev: () => swiper?.slidePrev(),
                isFirst: () => swiper?.isBeginning,
                isLast: () => swiper?.isEnd,
            };
        },
        [navigator]
    );

    // TODO/FIXME CLOUDWEB-17271 Костыль для того, чтобы не рендерилась кнопка скролла влево в случае,
    // если все истории умещаются на экране. При таких условиях, сразу появлалось событие reachEnd в swiper'е,
    // срабатывал handleReachEnd и рисовалась стрелка

    const updateNavState = useCallback(() => {
        setNavState({ isFirst: Boolean(navigator.current?.isFirst()), isLast: Boolean(navigator.current?.isLast()) });
    }, [navigator, setNavState]);

    useEffect(() => {
        updateNavState();
    }, [updateNavState]);

    // END TODO/FIXME CLOUDWEB-17271

    const handleActiveIndexChange = useCallback(() => {
        updateNavState();
    }, [updateNavState]);

    return (
        <div className={styles.root}>
            <div className={styles.navigation}>
                <div
                    className={classNames(styles.navButton, styles.navButtonLeft, {
                        [styles.navButtonDisabled]: navState.isFirst,
                    })}
                    onClick={handlePrevStory}
                >
                    <Icon16ArrowLeftOutline />
                </div>
                <div
                    className={classNames(styles.navButton, styles.navButtonRight, {
                        [styles.navButtonDisabled]: navState.isLast && !hasStoriesLoadMore,
                    })}
                    onClick={handleNextStory}
                >
                    <Icon16ArrowRightOutline />
                </div>
            </div>
            <Swiper
                slidesPerView="auto"
                spaceBetween={16}
                threshold={20}
                pagination={swiperConfig.pagination}
                onSwiper={handleSwiper}
                onReachEnd={handleReachEnd}
                onActiveIndexChange={handleActiveIndexChange}
                modules={swiperConfig.modules}
            >
                {stories.map((storyItem, idx) => (
                    <SwiperSlide key={storyItem.id} virtualIndex={idx} className={styles.slide}>
                        {() => {
                            return <StoryItem item={storyItem} isFirst={idx === 0} onItemClick={handleStoryClick} />;
                        }}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
});
