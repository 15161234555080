import type { Method } from 'lib/axios';
import { APICallV3Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateV3ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'favorites/list';

class FavoritesListAPIV3Call extends APICallV3Post {
    _method = method;
}

class FavoritesListAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const getFavoritesListAPICall = (): APICallV3Post | APICallV4 => {
    if (apiMigrateV3ToV4[method]) {
        return new FavoritesListAPIV4Call();
    }

    return new FavoritesListAPIV3Call();
};
