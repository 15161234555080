import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.helpers';
import { deleteAllUsers } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import type { IProps } from './sharingNew.hook.types';

export const useSharingNew = (props: IProps) => {
    const { storage, item, acList, isFromSharing, isFromWeblink, isOwner } = props;

    const [isCloudUsersOpened, setCloudUsersOpened] = useState<boolean>(isFromSharing);
    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const dispatch = useDispatch();

    useEffect(() => sendGa('show'), []);

    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item) {
            return;
        }

        unPublishHelper({ items: [item] });
    }, [storage, item]);

    const onCloudUsersActionClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('delete-all');
        dispatch(deleteAllUsers({ item }));
        setCloudUsersOpened(false);
    }, [dispatch, item]);

    const onChangeCloudUsers = useCallback(() => {
        // Если нет приглашенных пользователей - просто закрываем, без предупреждения
        if (!acList.length) {
            setCloudUsersOpened(!isCloudUsersOpened);
            return;
        }

        if (isCloudUsersOpened) {
            return;
        }

        setCloudUsersOpened(true);
    }, [acList.length, isCloudUsersOpened]);

    const onChangeWeblink = useCallback(() => {
        if (!item) {
            return;
        }

        if (isWeblinkOpened) {
            renderUnpublishWeblinkDialog({
                onActionClick: () => {
                    if (isCloudUsersOpened && isOwner && item.isFolder) {
                        onCloudUsersActionClick();
                    }
                    onActionClick();
                },
            });
        } else {
            sendGa('publish');

            dispatch(publishRequest({ item }));
        }
    }, [item, isWeblinkOpened, isCloudUsersOpened, isOwner, onActionClick, onCloudUsersActionClick, dispatch]);

    useEffect(() => {
        if (isWeblinkOpened && isOwner) {
            onChangeCloudUsers();
        }
    }, [isWeblinkOpened]);

    return {
        isCloudUsersOpened,
        isWeblinkOpened,
        setWeblinkOpened,
        setCloudUsersOpened,
        onActionClick,
        onChangeWeblink,
    };
};
