import { Snackbar } from 'reactApp/modules/snackbar/snackbar.constants';
import type { SnackbarActionTypes, SnackbarItem } from 'reactApp/modules/snackbar/snackbar.types';

export const showSnackbarAction = (item: SnackbarItem): SnackbarActionTypes => ({
    type: Snackbar.SHOW,
    payload: item,
});

export const hideSnackbarAction = (id: string): SnackbarActionTypes => ({
    type: Snackbar.HIDE,
    payload: id,
});

/* Стараться использовать сразу useSnackBarBottomMargin вместо этого экшена */
export const setBottomMarginAction = (value: number): SnackbarActionTypes => ({
    type: Snackbar.SET_BOTTOM_MARGIN,
    payload: value,
});
