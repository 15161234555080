import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_ANDROID_MOBILE, IS_MOBILE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

export const cheapTariffOnlyYearAndroid = IS_ANDROID_MOBILE && IS_WEBVIEW && isFeature('cheap-tariff-only-year-android');

export const cheapTariffOnlyYearDesktop = !IS_MOBILE_BROWSER && getFeature('cheap-tariff-only-year-desktop');

if (!IS_MOBILE_BROWSER) {
    registerExperiment('cheap-only-y', cheapTariffOnlyYearDesktop, [AnalyticEventNames.TARIFF_SHOWN], {
        sendXrayArgs: {
            TARIFF_SHOWN: ({ product }, { eventName }) => ({ eventName: `${eventName}-${product.space.space}` }),
        },
    });
}
