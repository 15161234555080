import { Spacing, Text } from '@vkontakte/vkui';
import image from 'img/referralProgramPromo/in-process-desktop.jpg';
import React, { memo, useEffect } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import styles from 'reactApp/ui/ReferralProgramPromoModal/ReferralProgramPromoModal.css';
import type { Props } from 'reactApp/ui/ReferralProgramPromoModal/ReferralProgramPromoModal.types';

export const ReferralProgramThanksModal = memo<Props>(({ onShow, onClick, onClose }) => {
    useEffect(onShow, []);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                Мы пока работаем над этой функцией. Сообщим сразу, как появится
            </Text>
            <Spacing size={24} />
        </>
    );

    return (
        <WhatsNewDialog
            primaryButtonText="Хорошо"
            title="Спасибо за ваш интерес"
            imageUrl={image}
            content={content}
            dialogSize="small"
            buttonTheme="vk"
            onClick={onClick}
            onCloseButtonClick={onClose}
            closeByPrimaryButton
        />
    );
});

ReferralProgramThanksModal.displayName = 'ReferralProgramThanksModal';
