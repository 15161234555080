import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import type { Props } from './ReferralProgramPromoModal.types';

export const renderReferralProgramPromoModal = (data: Props) => {
    openPopupHelper({
        popupName: popupNames.REFERRAL_PROGRAM_PROMO_MODAL,
        data,
        onClose: data.onClose,
    });
};
