import autoupload10TbImage from 'img/promo-block-mobile/autoupload-10-tb.png';
import autoupload10TbImageRebranding from 'img/promo-block-mobile/autoupload-10-tb-rebranding.png';
import summerPromoImage from 'img/promo-block-mobile/summer-promo.png';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';

import { type PromoBlockBase, PromoBlockBgColor, PromoBlockButtonColor, PromoBlockId, PromoBlockTextColor } from './PromoBlock.types';

export const PromoBlockData: Record<PromoBlockId, PromoBlockBase> = {
    [PromoBlockId.AUTOUPLOAD_10TB]: {
        id: PromoBlockId.AUTOUPLOAD_10TB,
        title: 'Добавим 10 ТБ для телефона',
        text: 'Подключите любой тариф с акцией на\u00A0автозагрузку фото и видео',
        textColor: isRebranding ? PromoBlockTextColor.black : PromoBlockTextColor.white,
        buttonText: 'Выбрать тариф',
        buttonColor: PromoBlockButtonColor.black,
        backgroundColor: isRebranding ? PromoBlockBgColor.lightBlue : PromoBlockBgColor.blue,
        image: isRebranding ? autoupload10TbImageRebranding : autoupload10TbImage,
        analyticsName: 'touch_promo_block_10tb',
        imageFullHeight: isRebranding,
    },
    [PromoBlockId.SUMMER_PROMO]: {
        id: PromoBlockId.SUMMER_PROMO,
        title: 'Летняя скидка до 85%',
        text: 'Подключите подписку Mail Space с\u00A0максимальной скидкой',
        textColor: PromoBlockTextColor.black,
        buttonText: 'Выбрать тариф',
        buttonColor: PromoBlockButtonColor.summerButtonColor,
        backgroundColor: PromoBlockBgColor.summerBgColor,
        image: summerPromoImage,
        imageFullHeight: true,
        analyticsName: 'summer_action',
    },
};
