import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';
import { ActionCategory, MessageCategory } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';

export type LLMResponse = {
    message: string;
    id: boolean;
};

const method = 'user/quota/llm';

export class LLMTextByThemeApiCall extends APICallV4<LLMResponse> {
    _method = `${method}/${MessageCategory.TEXT_BY_THEME}`;
    _type: Method = 'post';
}

export class LLMPostApiCall extends APICallV4<LLMResponse> {
    _method = `${method}/${MessageCategory.POST}`;
    _type: Method = 'post';
}

export class LLMBrainstormApiCall extends APICallV4<LLMResponse> {
    _method = `${method}/${MessageCategory.BRAINSTORM}`;
    _type: Method = 'post';
}

export class LLMContinueApiCall extends APICallV4<LLMResponse> {
    _method = `${method}/${ActionCategory.CONTINUE}`;
    _type: Method = 'post';
}
