export enum EPublicAccessRights {
    READ_ONLY = 'read',
    READ_WRITE = 'read_write',
}

export interface IWeblinkACEmailListItem {
    email: string;
    name: string;
    accessRights: EPublicAccessRights;
    avatarUrl: string;
}

export interface IWeblinkAccessControlState {
    isLoading: boolean;
    acList: IWeblinkACEmailListItem[];
    error: string;
}

export interface IWeblinkSetEmailAccessRights {
    accessRight: EPublicAccessRights;
    weblink: string;
    email: string;
}

export interface IWeblinkDeleteEmailAccessRights {
    weblink: string;
    email: string;
}

export interface IWeblinkAccessControlSetError {
    error: string;
}

export interface IWeblinkAccessControlLoadStart {
    id: string;
    storage?: string;
}

export interface IWeblinkSetEmailAccessList {
    share: IWeblinkACEmailListItem[];
}
