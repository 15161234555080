import { createAction } from '@reduxjs/toolkit';

import type {
    IScrollToItemAction,
    ISelectOne,
    ISelectSeveral,
    ISelectSome,
    ISelectTo,
    IToggle,
    IToggleAll,
    ScrollToItemState,
} from './selection.types';

export const selectOne = createAction<ISelectOne>('selections/selectOne');
export const selectSeveral = createAction<ISelectSeveral>('selections/selectSeveral');
export const selectSome = createAction<ISelectSome>('selections/selectSome');
export const toggle = createAction<IToggle>('selections/toggle');
export const toggleAll = createAction<IToggleAll>('selections/toggleAll');
export const selectTo = createAction<ISelectTo>('selections/selectTo');
export const resetSelect = createAction('selections/resetSelect');
export const setDragging = createAction<boolean>('selections/setDragging');
export const startSelecting = createAction('selections/startSelecting');
export const scrollToHomeItemAction = createAction<IScrollToItemAction>('selections/scrollToItemAction');
export const setScrollToItemState = createAction<ScrollToItemState>('selections/setScrollToItemState');
