import { type Middleware, type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type { FC, ReactElement } from 'react';
import type { ActionPanelState } from 'reactApp/modules/actionpanel/actionpanel.types';
import { getBizFilteredItems } from 'reactApp/modules/bizCategories/bizCategories.helpers';
import { getSelectedItems } from 'reactApp/modules/selections/selections.items.selectors';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import type { AnyAction } from 'redux';

const initialState: ActionPanelState = {
    opened: false,
    component: null,
    caption: '',
};

interface ToggleActionPanel {
    open: boolean;
}

interface ToggleActionPanelOpened {
    open: true;
    component: FC;
    caption: string | ReactElement;
}

export const toggleActionPanel = createAction<ToggleActionPanel | ToggleActionPanelOpened>('actionPanel/toggleActionPanel');
export const setActionPanelHeader = createAction<string | ReactElement>('actionPanel/setActionPanelHeader');

export const actionPanelReducer = createReducer(initialState, {
    [toggleActionPanel.type]: (state, action: PayloadAction<ToggleActionPanel & ToggleActionPanelOpened>) => {
        state.opened = action.payload.open ?? !state.opened;
        state.component = action.payload.open ? action.payload.component : null;
    },
    [setActionPanelHeader.type]: (state, action: PayloadAction<string | ReactElement>) => {
        state.caption = action.payload;
    },
});

export const actionPanelMiddleware: Middleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action: AnyAction) => {
        const eligibleModals = ['file-history-copy', 'file-history-rewrite'];
        const closingActions = [
            'home/changeHistory',
            'modifying/createNewItem',
            'modifying/removeRequest',
            'router/changeHistory',
            'showDialog/show',
            'uploader/setUploadFrom',
            'uploaderList/setHasInputAction',
        ];

        let shouldBizFilterClosePanel = false;
        if (action.type === 'bizCategories/setCurrentCategory') {
            const selectedItems = getSelectedItems(getState()).filter(Boolean) as CloudItem[];
            const filteredBizItems = getBizFilteredItems(selectedItems, action.payload);
            shouldBizFilterClosePanel = selectedItems.length === 1 && selectedItems[0] !== filteredBizItems[0];
        }

        const shouldClosePanel =
            shouldBizFilterClosePanel || (closingActions.includes(action.type) && !eligibleModals.includes(action.payload));

        if (shouldClosePanel) {
            dispatch(toggleActionPanel({ open: false }));
        }

        return next(action);
    };
