import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { QRCloud } from 'reactApp/ui/QRCloud/QRCloud';
import { noop } from 'reactApp/utils/helpers';

import styles from './PopupMobileAppQR.css';

interface Props {
    isDark?: boolean;
    url: string;

    onClose?(): void;
}

export const PopupMobileAppQR = memo(({ isDark = false, onClose = noop, url }: Props): ReactElement | null => {
    const handleOnClose = useCallback((): void => {
        onClose();
    }, [onClose]);

    return (
        <Dialog
            open
            closeOnDimmerClick
            closeOnEscape
            id="mobile-app-qr-popup"
            showCloseIcon
            onCancel={handleOnClose}
            className={styles.root}
            theme={isDark ? 'dark' : 'octavius'}
            header={
                <Title level="1" weight="2" className={styles.title}>
                    Наведите камеру на{'\u00A0'}QR-код
                </Title>
            }
        >
            <div>
                <Spacing size={6} />
                <Text>Чтобы скачать Облако на смартфон</Text>
                <div className={classNames(styles.qrWrapper, { [styles.qrWrapper_dark]: isDark })}>
                    <QRCloud url={url} isDark={isDark} size={168} />
                </div>
            </div>
        </Dialog>
    );
});

PopupMobileAppQR.displayName = 'PopupMobileAppQR';
