import { editor } from 'Cloud/Application/Editor';
import config from 'Cloud/config';
import { cloneAttach, getMessage } from 'reactApp/api/axios.corsapi';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import {
    API_V4,
    APP_TITLE,
    EDITORS_CONFIG,
    IS_B2B_BIZ_USER,
    IS_WEBVIEW,
    USER_EMAIL,
    VIEWERS_CONFIG,
} from 'reactApp/appHelpers/configHelpers';
import { isAttachSaveToAllDocuments } from 'reactApp/appHelpers/featuresHelpers';
import { ALL_DOCUMENTS_FOLDER_ID, MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { parseAttachId } from 'reactApp/modules/attaches/attaches.helpers';
import { handleReadyCoEditPublicAlert } from 'reactApp/modules/editor/sagas/coEditPublicAlert';
import { loadHomeFolder } from 'reactApp/modules/home/home.saga';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { loadFolderRequest, loadPublicFileSuccess } from 'reactApp/modules/public/public.actions';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { put, select, takeLatest } from 'redux-saga/effects';
import { sortEditors } from 'server/helpers/editors/sortEditors';
import type { ServerEditor } from 'server/helpers/editors/types';

import { composeAvailableEditors, composeExtensions, composeSharedExtensions } from './editor.helpers';
import { initEditor, readyEditor as readyEditorAction, startEditor, startEditorRequest } from './editor.module';

const serverSideFolder = config.get('serverSideFolders');

export function* setEditor() {
    const editors: ServerEditor[] = (EDITORS_CONFIG.length ? EDITORS_CONFIG : VIEWERS_CONFIG).sort(sortEditors);
    const extensions = composeExtensions(editors);
    const availableEditors = composeAvailableEditors(editors, extensions);
    const hasSharedEditor = editors.some((editor) => 'x-in-shared-folder' in editor);
    const sharedExtensions = composeSharedExtensions(editors, extensions);

    yield put(initEditor({ availableEditors, hasSharedEditor, sharedExtensions, extensions }));
}

function* handleStartEditorRequest(action: ReturnType<typeof startEditorRequest>) {
    // Где был вызван редактор по роутингу - хомяк или паблик
    const storage = action.payload.storage;
    let id = yield select(getCurrentRouteId);
    const storageRoute = yield select(getCurrentStorage);

    if (storage !== EStorageType.public) {
        if (storageRoute === EStorageType.myofficeAttaches) {
            const { messageId, partId } = parseAttachId(id);

            yield getMessage({
                messageId,
                email: USER_EMAIL,
            });

            const res = yield cloneAttach({
                ids: [`${messageId};${partId}`],
                folder: isAttachSaveToAllDocuments ? ALL_DOCUMENTS_FOLDER_ID : MAIL_ATTACHES_FOLDER_ID,
                email: USER_EMAIL,
            });

            id = res[0];
        }

        yield loadHomeFolder({ id, isFolder: false, force: true });
    }

    if (API_V4 && storage === EStorageType.public) {
        const item = itemV4ToV2(serverSideFolder);
        yield put(loadPublicFileSuccess(item));
        yield put(
            startEditor({
                item: {
                    ...item,
                    id,
                    subKind: item.subkind,
                    readOnly: item.readonly,
                    weblink_access_rights: item.weblinkAccessRights,
                    weblink_expires: item.weblinkExpires,
                    weblink_domestic: item.weblinkDomestic,
                },
            })
        );
        yield put(loadFolderRequest({ storage, id: item?.weblink }));
    }

    const item = yield select(getStorageItemById, storage, id);

    if (!item) {
        yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
        return;
    }

    yield editor.start(item);

    yield put(checkUpdateLicenseRequest());

    const titleDelimiter = IS_WEBVIEW ? '' : ' / ';
    const titleSuffix = IS_B2B_BIZ_USER ? APP_TITLE : 'Облако Mail';
    const title = item.name + titleDelimiter;
    document.title = (title || '') + (IS_WEBVIEW ? '' : titleSuffix);
}

export function* watchEditor() {
    yield takeLatest(readyEditorAction, handleReadyCoEditPublicAlert);
    yield takeLatest(startEditorRequest, handleStartEditorRequest);
}
