import classNames from 'clsx';
import React, { type ReactElement, Children, cloneElement, isValidElement, memo } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';

import styles from './TabMenu.css';
import { type ITabMenuProps, ETabMenuAlign } from './TabMenu.types';

export const TabMenu = memo<ITabMenuProps & { className? }>(
    ({ children, align = ETabMenuAlign.center, noVerticalScroll = false, className, ...props }): ReactElement => {
        return (
            <div
                className={classNames(styles.menu, className, {
                    [styles.menuResponsive]: ENABLE_FULL_RESPONSIVE,
                    [styles.menu_bottomLine]: !!props.bottomLine,
                    [styles[`menu_align_${align}`]]: !!align,
                    [styles.menu_noVerticalScroll]: noVerticalScroll,
                    [styles.menu_mobile]: props.isMobile,
                    [styles[`menu_${props.mod}`]]: !!props.mod,
                })}
            >
                <div className={styles.wrapper}>
                    {Children.map(
                        children,
                        (child: JSX.Element | null) =>
                            isValidElement(child) &&
                            cloneElement(child, {
                                ...props,
                            })
                    )}
                </div>
            </div>
        );
    }
);

TabMenu.displayName = 'TabMenu';
