import { xray } from 'lib/xray';
import React, { type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';

import { getContent, getTitle } from './RemoveDialog.helpers';
import type { IProps } from './RemoveDialog.types';

const sendGa = createGaSender(`remove-dialog`);

export const RemoveDialog = React.memo(function RemoveDialog(props: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const { items, hasAlienFiles, onRemove } = props;

    const item = items[0];
    const { isMounted, isDomain } = useSelector((state: RootState) => isMountedOrSharedFolder(state, item));
    const currentStorage = useSelector(getCurrentStorage);

    const onConfirm = useCallback(() => {
        setLoading(true);
        sendGa('confirm');
        xray.send('trash', {
            p: 'cloud-web-analytics',
            i: { move: 1 },
            dwh: { tab: currentStorage },
        });
        onRemove();
    }, [onRemove, currentStorage]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose?.();
    }, [props]);

    const renderHeader = useCallback(() => <>{getTitle({ items, currentStorage })}</>, [currentStorage, items]);

    const renderContent = useCallback(
        () => getContent({ isMounted: isMounted && !isDomain, items, hasAlienFiles, currentStorage }),
        [isMounted, isDomain, items, hasAlienFiles, currentStorage]
    );
    const buttons = useMemo(() => (items.length ? [EButtonType.accept, EButtonType.cancel] : [EButtonType.cancel]), [items.length]);

    useEffect(() => sendGa('show'), []);

    const isAlbumStorage = IS_PUBLIC_ALBUM || currentStorage === EStorageType.albums;

    return (
        <BaseConfirmDialog
            dataQAId="remove-confirmation-dialog"
            renderHeader={renderHeader}
            successButtonText={isAlbumStorage ? 'Удалить' : 'Переместить'}
            cancelButtonText="Отменить"
            buttons={buttons}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            loading={loading}
            closeOnDimmerClick
        />
    );
});
