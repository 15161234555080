import { getExt } from 'Cloud/Application/Editor/helpers';
import { extInfo } from 'lib/extInfo';
import React, { useEffect, useState } from 'react';
import { MYOFFICE_ATTACHES_VIEWER } from 'reactApp/appHelpers/configHelpers';
import { getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { ErrorPageBlock } from 'reactApp/sections/ErrorPage/ErrorPageBlock';
import { useLetterUrl } from 'reactApp/sections/LettersPage/useLetterUrl';
import { ReactViewerLetters } from 'reactApp/ui/ReactViewer/ReactViewerLetters';
import { noopVoid } from 'reactApp/utils/helpers';

const XRAY_CONFIG = {
    defaultParams: { p: 'sharing_attach_viewer' },
};

const getFile = (ext: string, blobUrl: string, fileName: string | null, downloadUrl: string) => {
    const { kind, subKind } = extInfo.get(ext);

    return {
        ext,
        kind,
        subKind,
        isFolder: false,
        id: fileName || '',
        name: fileName || '',
        mtime: '',
        nameWithoutExt: fileName ? getItemNameWithoutExt(fileName, ext) : '',
        parent: '',
        size: 0,
        storage: EStorageType.embedded,
        thumbnails: {
            original: blobUrl || '',
        },
        url: {
            get: blobUrl || '',
            view: blobUrl || '',
            download: downloadUrl,
        },
    };
};

export const SharingAttachPage = () => {
    const url = decodeURIComponent(document.location.search.slice(4));
    const fileName = new URL(url).searchParams.get('file');
    const ext = getExt(fileName);
    const { mimeType } = extInfo.get(ext);
    const { blobUrl, error } = useLetterUrl(url, mimeType);
    const [file, setFile] = useState<any | null>(null);

    useEffect(() => {
        if (blobUrl) {
            setFile(getFile(ext, blobUrl, fileName, url));
        }
    }, [ext, blobUrl, fileName]);

    if (error) {
        return <ErrorPageBlock status={EStatus.NOT_FOUND} />;
    }

    return (
        <ReactViewerLetters
            deleteFile={noopVoid}
            file={file}
            isActionOpen={false}
            isAuthorized={false}
            isMountedFolder={false}
            isPublicCopyEditable={false}
            itemCount={1}
            isNewbie={true}
            onClose={noopVoid}
            publishItemAction={noopVoid}
            selectItemIndexInArray={noopVoid}
            showEditorHeader={false}
            showSnackbar={noopVoid}
            showViewerHeader={true}
            isViewer={true}
            viewers={[MYOFFICE_ATTACHES_VIEWER]}
            toggleFavorite={noopVoid}
            sendSearchRadar={noopVoid}
            xrayConfig={XRAY_CONFIG}
            isViewerFileVerifiedByKaspersky={false}
            gaCategory="sharing_attach"
            itemStorage={EStorageType.attaches}
            sharingAttach
        />
    );
};
