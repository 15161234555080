import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_PROMO, IS_IOS_MOBILE, IS_MOBILE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import {
    abAutoupload10TB,
    isDarkTariffsPages,
    isRebranding,
    isRebrandingQuotaLanding,
    summerPromotion,
} from 'reactApp/appHelpers/featuresHelpers';
import { isPromoQuotaWithLitress, litresEndDate } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useDarkTheme } from 'reactApp/hooks/useDarkTheme';
import { clearRebrandingQuota, setRebrandingQuota } from 'reactApp/hooks/useRebranding';
import { checkMobileWebviewPayAvailable } from 'reactApp/modules/billing/billing.helpers';
import { getReceivedGiftsLoadingState, hasAutoupload10TbGift } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { loadQuotaTariffsLandingRequest, sendShowLandingAnalyticsAction } from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import {
    useAddCardModal,
    useRemoveCardModal,
    useSubscriptionsModal,
} from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { Container } from 'reactApp/sections/QuotaLanding/Container/Container';
import { useProductFromQuery } from 'reactApp/sections/QuotaLanding/hooks/useProductFromQuery';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { TariffsList } from 'reactApp/sections/QuotaLanding/TariffsList/TairiffsList';
import { Button } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import Content from 'reactApp/ui/Content/Content';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { ACTIONS_DEFS } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendIosFavorableTariffsAnalytics, sendProfitableTariffsFirstAnalytics } from 'reactApp/utils/analytics';
import opener from 'reactApp/utils/opener';

import { Header } from './Header/Header';
import styles from './MobileQuotaTariffsLanding.css';
import { sendWebviewAnalytics } from './MobileQuotaTariffsLanding.helpers';

const HELP_LINK = 'https://help.mail.ru/cloud_web/size/increase';

export const MobileQuotaTariffsLanding = memo((): ReactElement => {
    const dispatch = useDispatch();

    const { isLoading, hasError, isLoaded } = useSelector(QuotaLandingSelectors.getState);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isSocialUser = useSelector(UserSelectors.isSocialUser);
    const isUserWithBilling = useSelector(UserSelectors.isUserWithBilling);

    const isMobilePayAvailable = checkMobileWebviewPayAvailable();

    // tempexp_16460-start
    const isCorp = useSelector(UserSelectors.isCorpUser);
    const isBiz = useSelector(UserSelectors.isBizUser);
    const hasAutoupload1Tb = useSelector(hasAutoupload10TbGift);
    const { isLoading: isGiftLoading } = useSelector(getReceivedGiftsLoadingState);
    const queryParams = getQueryParams();
    const total = useSelector(UserQuotaSelectors.getTotal);
    const showAutouploadPromo =
        (Boolean(abAutoupload10TB.length) || (IS_WEBVIEW && queryParams?.forwardApp === 'true')) &&
        ((!hasAutoupload1Tb && !isGiftLoading) || isAnonymous) &&
        !isCorp &&
        !isBiz &&
        (Boolean(total?.original && total.original <= SPACE_LIST.tb_1) || isAnonymous);
    // tempexp_16460-end

    useProductFromQuery({
        forceMobile: true,
        mobileInfo: {
            isMobilePayAvailable,
            isIOSPromo: IS_IOS_MOBILE,
        },
    });
    useRemoveCardModal();
    useAddCardModal();
    useSubscriptionsModal();

    useDarkTheme(isDarkTariffsPages);

    const onSubscriptionsClick = useCallback(() => {
        sendQuotaGa({ action: 'subscriptions', label: 'click' });
        renderSubscriptionsModal({ isRebrandingQuotaLanding, isRebranding });
    }, []);

    const onCancelInfo = useCallback(() => {
        sendQuotaGa({ action: 'cancel-info', label: 'click' });
    }, []);

    useEffect(() => {
        dispatch(loadQuotaTariffsLandingRequest());
        emitAnalyticEvent('MOBILE_LANDING_SHOWN');
        sendIosFavorableTariffsAnalytics(['landing_view']);
        sendProfitableTariffsFirstAnalytics(['landing_view']);
    }, []);

    const retry = useCallback(() => {
        dispatch(loadQuotaTariffsLandingRequest());
    }, [dispatch]);

    useEffect(() => {
        if (isLoaded) {
            sendWebviewAnalytics('open', isMobilePayAvailable);
            dispatch(sendShowLandingAnalyticsAction());
        }
    }, [isLoaded]);

    useEffect(() => {
        const { action = null } = getQueryParams();
        if (action === 'openSubsModal') {
            renderSubscriptionsModal({ isRebrandingQuotaLanding, isRebranding });
        }
    }, []);

    useEffect(() => {
        if (isRebrandingQuotaLanding) {
            setRebrandingQuota();
        }

        return () => clearRebrandingQuota();
    }, [isRebrandingQuotaLanding]);

    // если пользователю недоступен билинг(кроме соц пользователей)
    // если аноним
    // если не тач
    // редирект к хомяк
    if ((!isUserWithBilling && !isSocialUser) || isAnonymous || !IS_MOBILE_BROWSER) {
        xray.send('mobile_quota_tariffs_to_home');
        opener('/', true);
    }

    let fullTermActionDef;
    // tempexp_17509-start
    const isPaid = useSelector(UserSelectors.isPaidUser);
    if (!isPaid && isPromoQuotaWithLitress) {
        fullTermActionDef = 'litresPromo';
    }
    // tempexp_17509-end
    else if (summerPromotion) {
        fullTermActionDef = 'summerPromo';
    } else if (ACTION_PROMO) {
        fullTermActionDef = 'marketingPromo';
    }

    return (
        <Content
            buttonText="Попробовать ещё раз"
            retry={retry}
            isLoading={isLoading}
            hasError={hasError}
            isPage
            pageName="quota_landing_tariffs"
        >
            <div
                className={classNames(styles.root, {
                    [styles.root_dark]: isDarkTariffsPages,
                    [styles.root_action]: Boolean(ACTION_PROMO),
                    [styles.summer]: summerPromotion,
                    [styles.root_rebranding]: isRebrandingQuotaLanding,
                })}
            >
                <Spacing size={isRebrandingQuotaLanding ? 24 : 32} />
                <Header />
                <Spacing size={summerPromotion ? 20 : 32} />
                <TariffsList
                    isIOSPromo={IS_IOS_MOBILE}
                    isMobilePayAvailable={isMobilePayAvailable}
                    isMobilePayFromGetParam={false}
                    isAnonymous={isAnonymous}
                    isTariffsPage
                    isPhone
                    // tempexp_16481-next-line
                    autouploadPromo={showAutouploadPromo}
                />
                <Spacing size={isRebrandingQuotaLanding ? 38 : summerPromotion ? 16 : 20} />
                <Container isNarrow>
                    <Button
                        className={classNames(styles.button, { [styles.summerPromo]: summerPromotion })}
                        theme="vk"
                        onClick={onSubscriptionsClick}
                        qaId="subscriptions"
                    >
                        Мои подписки
                    </Button>
                    {fullTermActionDef && (
                        <>
                            <Spacing size={isRebrandingQuotaLanding ? 10 : 12} />
                            <Button
                                className={classNames(styles.button, { [styles.summerPromo]: summerPromotion })}
                                theme="vk"
                                href={ACTIONS_DEFS[fullTermActionDef].conditions}
                                target="_blank"
                                qaId="action"
                            >
                                Полные условия акции
                            </Button>
                        </>
                    )}
                    {/* tempexp_17509-start */}
                    {isPromoQuotaWithLitress && !!litresEndDate && (
                        <div className={styles.actionDatesBlock}>
                            <span className={styles.actionDates}>Срок акции с 13.09.2024 по {litresEndDate}</span>
                        </div>
                    )}
                    {/* tempexp_17509-end */}
                    <Spacing size={isRebrandingQuotaLanding ? 10 : 20} />
                    <ButtonLink
                        className={styles.link}
                        href={HELP_LINK}
                        target="_blank"
                        rel="noopener"
                        hoverUnderline={false}
                        onClick={onCancelInfo}
                    >
                        Как отменить подписку?
                    </ButtonLink>
                </Container>
                <Spacing size={isRebrandingQuotaLanding ? 32 : 40} />
            </div>
        </Content>
    );
});

MobileQuotaTariffsLanding.displayName = 'MobileQuotaTariffsLanding';
