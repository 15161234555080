/* eslint-disable max-lines-per-function */
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import format from 'date-fns/format';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { trialPrice } from 'reactApp/appHelpers/featuresHelpers';
import { litresUpsaleTariffs } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { BuyContent } from 'reactApp/components/BuyModal/BuyContent';
import { EBuyContentSize, EBuyContentType } from 'reactApp/components/BuyModal/BuyContent.types';
import { PaymentWindowType } from 'reactApp/modules/payment/payment.helpers';
import { AUTOUPLOAD_10TB_REGEX, getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import {
    getPeriodName,
    getPeriodNameAsWord,
    getTrialOrDiscountDateEnd,
    getTypeOfPeriodName,
    isMonthPeriod,
    isYearPeriod,
} from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './BuyModalTrial.css';
import { sendModalGa } from './BuyModalTrial.helpers';
import { type IProps, EBuyModalTheme } from './BuyModalTrial.types';

const INITIAL_HEIGHT = 324; // Начальная высота карточки оплаты

export const BuyModalTrial = memo(function BuyModalTrial(props: IProps) {
    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, props.id)) as Product;
    const { isForceDiscountTrial, space, discountPeriod, discountPrice, trialPeriod, isSingleQuota, price, period } = product;
    const trialPeriodName = getPeriodName(trialPeriod || '1m', false, true);
    const trialExpires = format(getTrialOrDiscountDateEnd(trialPeriod), defaultDateFormat);
    const [height, setHeight] = useState<number>(INITIAL_HEIGHT);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [orderAmount, setOrderAmount] = useState<string | null>(null);
    const [orderAmountVisible, setOrderAmountVisible] = useState(false);
    const onDmrResizeEvent = useCallback((params) => setHeight(Math.max(params?.height || 0, INITIAL_HEIGHT)), []);
    const modalTheme = EBuyModalTheme.LIGHT;
    // tempexp-17509-next-line
    const isLitresUpsale = litresUpsaleTariffs.includes(product.id);

    const isBytePromo = props.id === PROMO_TARIFFS?.bytePromo;
    const isTbPromo = props.id === PROMO_TARIFFS?.tbPromo;

    const onSuccess = useCallback(() => {
        props.onSuccess();
        setSuccess(true);
        sendModalGa(modalTheme, 'success');
        emitAnalyticEvent(AnalyticEventNames.TRIAL_TARIFF_BUY);
    }, []);

    const onClose = useCallback(() => {
        props.onClose();
        sendModalGa(modalTheme, 'close');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'close', tariff: product?.space.original, paySource: props.paySource });
    }, []);

    const onCancel = useCallback(() => {
        props.onClose();
        sendModalGa(modalTheme, 'close');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'cancel', tariff: product?.space.original, paySource: props.paySource });
    }, []);

    const onBuyClick = useCallback(() => {
        sendPaymentGa({
            action: EPaymentGa.trialBuyModal,
            label: 'click_pay',
            tariff: product?.space.original,
            paySource: props.paySource,
        });
    }, []);

    useEffect(() => {
        sendModalGa(modalTheme, 'show');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'view', tariff: product?.space.original, paySource: props.paySource });
    }, []);

    const description = useMemo(() => {
        if (isForceDiscountTrial && discountPeriod && trialPeriod && !isBytePromo) {
            return (
                <p className={styles.text}>
                    <br />
                    {getPeriodName(trialPeriod, true)} бесплатно,
                    <br />
                    затем {formatPrice(discountPrice)}&nbsp;₽&nbsp;{isLitresUpsale ? ' в ' : '/'}&nbsp;
                    {getPeriodName(discountPeriod)} с&nbsp;автопродлением
                </p>
            );
        }

        return (
            <>
                <p className={styles.text}>
                    Привяжите карту
                    <br /> для активации
                    <br /> бесплатного периода
                </p>

                {!isBytePromo && <p className={styles.space}>И получите +{space.value} свободной памяти</p>}
            </>
        );
    }, [isForceDiscountTrial, discountPeriod, trialPeriod, isBytePromo, space.value, discountPrice]);

    const footer = useMemo(() => {
        if (isBytePromo && discountPeriod && discountPrice) {
            return (
                <p className={styles.la}>
                    После окончания бесплатного периода стоимость подписки составит {formatPrice(discountPrice)}&nbsp;₽&nbsp;
                    {getPeriodName(discountPeriod)}. {getPretextPeriodForProduct(discountPeriod)}{' '}
                    {getPeriodNameAsWord(discountPeriod, true)} оплата составит {formatPrice(price)}&nbsp;₽&nbsp;. Подписка продлевается
                    автоматически, её можно отменить в любой момент. Сроки акции с 21.08.2024 по 20.09.2024. Подробные условия:&nbsp;
                    <ButtonLink size="medium" inline href="https://clck.ru/3CGbGi" target="_blank" fontInherit underline={false}>
                        https://clck.ru/3CGbGi
                    </ButtonLink>
                </p>
            );
        }

        if (isForceDiscountTrial) {
            let promoPeriod = period;
            let actionText;

            if ((isMonthPeriod(product.period) || isYearPeriod(product.period)) && product?.discountPeriodCount) {
                promoPeriod = `${product.discountPeriodCount}${isMonthPeriod(product.period) ? 'm' : 'y'}`;
            }

            // tempexp_17405-start
            if (AUTOUPLOAD_10TB_REGEX.test(product.id)) {
                actionText = (
                    <>
                        Акция «+10 ТБ для телефона» ограничена во времени — смотрите{' '}
                        <ButtonLink
                            size="medium"
                            primary
                            inline
                            href="https://promo-rules.hb.ru-msk.vkcs.cloud/10-tb-rules.pdf"
                            target="_blank"
                            fontInherit
                            underline={false}
                        >
                            условия акции
                        </ButtonLink>
                    </>
                );
            }
            // tempexp_17405-end

            return (
                <p className={styles.la}>
                    {getPretextPeriodForProduct(promoPeriod)} {getPeriodNameAsWord(promoPeriod, true)} оплата составит {formatPrice(price)}
                    &nbsp;₽ в&nbsp;{getTypeOfPeriodName(period)}. {actionText}
                </p>
            );
        }

        return (
            <p className={styles.la}>
                Стоимость тарифа {formatPrice(price)}&nbsp;₽/&nbsp;
                {getPeriodName(period)}, начиная с&nbsp;{trialExpires}. Подписка продлевается автоматически, её можно отменить в любой
                момент.
            </p>
        );
    }, [isBytePromo, discountPeriod, discountPrice, isForceDiscountTrial, price, period, trialExpires]);

    return (
        <Dialog
            id="buy-trial"
            open={true}
            disableDarkTheme
            onCancel={onClose}
            className={classNames({
                [styles.root]: true,
                [styles[`root_theme_${modalTheme}`]]: true,
                [styles.root_forceTrial]: isForceDiscountTrial,
            })}
        >
            <h3 className={styles.title}>
                {isForceDiscountTrial && !isBytePromo
                    ? `Подписка Mail Space ${product.space.value}`
                    : `Активировать ${product.space.value} на ${trialPeriodName}${isTbPromo ? '' : ' бесплатно'}`}
            </h3>
            <div
                className={styles.content}
                style={{ maxHeight: `${height + 75}px` }} // 75 - высота заголовка "Введите данные карты"
            >
                <div className={styles.aside}>
                    {description}

                    <ol className={styles.list}>
                        {/* tempexp-17509-next-line */}
                        {isLitresUpsale ? <li>Подписка Литрес на 2 месяца</li> : null}
                        <li>Дополнительное место</li>
                        <li>Загрузка файлов до {isSingleQuota ? '100' : '32'} ГБ</li>
                        <li>Никакой рекламы в Почте и Облаке</li>
                        <li>Возможность делиться местом с близкими</li>
                        {/* tempexp-17509-next-line */}
                        {isLitresUpsale ? <li /> : <li>И другие преимущества</li>}
                    </ol>

                    {footer}
                </div>

                <div
                    className={styles.buyContent}
                    style={{ maxHeight: `${height + 34}px` }} // 34 - высота заголовка "Введите данные карты"
                >
                    {!isSuccess && <h4 className={styles.buyHeader}>Введите данные карты</h4>}
                    {orderAmountVisible && (
                        <Text className={styles.note} data-qa-id="note-check-payment">
                            Для проверки карты мы спишем и сразу же вернём&nbsp;
                            {formatPrice(parseInt(orderAmount || '0') || trialPrice)}&nbsp;₽.
                        </Text>
                    )}
                    <Spacing size={16} />
                    <BuyContent
                        id={props.id}
                        link={props.link}
                        source={props.source}
                        onSuccess={onSuccess}
                        onClose={onClose}
                        onCancel={onCancel}
                        onBuyClick={onBuyClick}
                        size={EBuyContentSize.large}
                        type={EBuyContentType.TRIAL}
                        onDmrResizeEvent={onDmrResizeEvent}
                        setCardCheckPrice={(price) => {
                            setOrderAmount(price);
                            setOrderAmountVisible(true);
                        }}
                        windowType={PaymentWindowType.trial}
                        paySource={props.paySource}
                    />
                </div>
            </div>
        </Dialog>
    );
});
