// tempexp_SRCH-70119-full-file
import MascotBiteIcon from 'img/search/tooltip/mascot_tooltip_search.png';
import React, { type FC, type RefObject } from 'react';
import { Portal } from 'react-portal';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './SearchTooltip.css';
import { type SearchTooltipTypes, searchTooltipData } from './SearchTooltip.data';

interface Props {
    targetRef: RefObject<HTMLElement> | null;
    type: SearchTooltipTypes;
    onClose: () => void;
}

export const SearchTooltip: FC<Props> = ({ targetRef, type, onClose }) => {
    const data = searchTooltipData[type];
    return (
        <Portal>
            <FloatingTooltip
                target={targetRef}
                placement={isRebranding ? ETooltipPlacement.top : ETooltipPlacement.bottomEnd}
                title={data.title}
                text={data.description}
                onClose={onClose}
                closeOnScroll
                qaId="tooltip-search"
                img={isRebranding && MascotBiteIcon}
                className={isRebranding && styles.searchTooltipRebranding}
                smallClose={isRebranding}
            />
        </Portal>
    );
};

SearchTooltip.displayName = 'SearchTooltip';
