import { Text, Title } from '@vkontakte/vkui';
import { getExt } from 'Cloud/Application/Editor/helpers';
import classNames from 'clsx';
import React, { useCallback, useMemo } from 'react';
import type { BusinessTemplateItem } from 'reactApp/modules/businessTemplates/businessTemplates.types';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';

import styles from './BusinessTemplateCard.css';

type BusinessTemplateCardProps = {
    template: BusinessTemplateItem;
    onClick: (template: BusinessTemplateItem, ext: string) => void;
};

const BusinessTemplateCardInner = ({ template, onClick }: BusinessTemplateCardProps) => {
    const ext = useMemo(() => getExt(template.filename), [template.filename]);
    const handleClick = useCallback(() => onClick(template, ext), [ext, onClick, template]);

    return (
        <div className={classNames(styles.root, styles[`root_${ext}`])} data-qa-id={`business-template-card:${template.hash}`}>
            <div className={styles.card} onClick={handleClick}>
                <div className={styles.icon}>
                    <img src={template.icon} />
                </div>
                <Title level="3" className={styles.title}>
                    {template.title}
                </Title>
                <FileIcon className={styles.thumb} size="s" type={ext} mode="dark" />
            </div>
            <Text className={styles.description}>{template.description}</Text>
        </div>
    );
};

export const BusinessTemplateCard = React.memo(BusinessTemplateCardInner);
