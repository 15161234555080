import classNames from 'clsx';
import React, { type ReactElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { showUploaderAction } from 'reactApp/modules/upload/upload.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FinalStep } from 'reactApp/ui/ConfirmEmail/Step/FinalStep';
import { FirstStep } from 'reactApp/ui/ConfirmEmail/Step/FirstStep';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './ConfirmEmail.css';

const sendGa = createGaSender('social-user');

export interface Props {
    isDialog: boolean;
    onClose();
    showUploadDropArea();
    domain: string;
}

export interface State {
    isConfirming: boolean;
}

const mapStateToProps = (state) => {
    return {
        domain: UserSelectors.getDomain(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    showUploadDropArea: (): void => dispatch(showUploaderAction()),
});

export class ConfirmEmailComponent extends PureComponent<Props, State> {
    public static defaultProps: Props = {
        isDialog: false,
        showUploadDropArea: noop,
        onClose: noop,
        domain: '',
    };

    public readonly state = {
        isConfirming: false,
    };

    public componentDidMount(): void {
        if (!this.props.isDialog) {
            sendGa('confirm-email-screen-show', this.props.domain);
        }
    }

    private handleConfirm = (): void => {
        this.setState({ isConfirming: true });
        sendGa('send-email', this.props.domain);
    };

    private handleClose = (): void => {
        const { onClose, isDialog, showUploadDropArea } = this.props;
        onClose();
        if (!isDialog) {
            showUploadDropArea();
        }
    };

    public render(): ReactElement {
        const { isDialog } = this.props;

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.dialog]: isDialog,
                    [styles.finalDialog]: isDialog && this.state.isConfirming,
                })}
            >
                {this.state.isConfirming ? (
                    <FinalStep onButtonClick={this.handleClose} />
                ) : (
                    <FirstStep onButtonClick={this.handleConfirm} />
                )}
                <div className={styles.licenseBlock}>
                    Нажимая «Отправить», вы принимаете условия{' '}
                    <a href="/LA/" target="_blank">
                        Лицензионного соглашения
                    </a>{' '}
                    и{' '}
                    <a href="https://help.mail.ru/legal/terms/cloud/privacy" target="_blank" rel="noreferrer">
                        Политики конфиденциальности
                    </a>
                </div>
            </div>
        );
    }
}

export const ConfirmEmail = connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailComponent);
