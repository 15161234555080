import coverImage from 'img/editor/co-edit-alert-popup.jpg';
import React, { type VFC, useCallback } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';

import styles from './CoEditPublicAlertPopup.css';

export interface CoEditPublicAlertPopupProps {
    onClick: () => void;
    onClose: () => void;
    onShow: () => void;
}

export const CoEditPublicAlertPopup: VFC<CoEditPublicAlertPopupProps> = ({ onClose, onShow, onClick }) => {
    const handleClick = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);

    return (
        <WhatsNewDialog
            contentClassName={styles.content}
            imageWrapperClassName={styles.imageWrapper}
            imageClassName={styles.image}
            textClassName={styles.text}
            closeIconClassName={styles.closeIcon}
            primaryButtonText="Хорошо"
            onShow={onShow}
            onClick={handleClick}
            onClose={onClose}
            title="Документ можно редактировать вместе"
            text="Это могут делать все, у кого есть ссылка"
            imageUrl={coverImage}
            buttonTheme="vk"
        />
    );
};
