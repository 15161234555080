import MascotBiteIcon from 'img/tooltip/public/mascot_bite.png';
import store from 'lib/store';
import React, { type ReactElement, type RefObject, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { ANONYMOUS_USER_NAME } from 'reactApp/constants/magicIdentificators';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './FaceInfoTooltip.css';

const FACE_TIP_ID = 'faceTipViewer';

export const FaceInfoTooltip = memo(({ targetRef, onApply }: { targetRef: RefObject<HTMLDivElement>; onApply?() }): ReactElement | null => {
    const email = useSelector(UserSelectors.getEmail) || ANONYMOUS_USER_NAME;
    const showTip = !store.get(`${email}|${FACE_TIP_ID}`);
    const [show, setShow] = useState(showTip);

    const handleClose = useCallback(() => {
        setShow(false);
        store.set(`${email}|${FACE_TIP_ID}`, new Date().valueOf());
    }, [email]);

    const handleApply = useCallback(() => {
        onApply?.();
        handleClose();
    }, [onApply, handleClose]);

    if (!show || !targetRef?.current) {
        return null;
    }

    const tooltipProps = isRebranding
        ? {
              text: 'Нажмите на иконку — быстро найдёте все кадры с людьми на фото',
              buttonText: '',
              img: MascotBiteIcon,
              className: styles.rebrandingTooltip,
              smallClose: true,
          }
        : {};

    return (
        <FloatingTooltip
            target={targetRef}
            text={'Облако умеет определять\nлюдей на фото'}
            buttonText="Попробовать"
            placement={ETooltipPlacement.bottom}
            appearance={ETooltipAppearance.dark}
            closeOnOutsideClick={false}
            onClick={handleApply}
            onClose={handleClose}
            qaId="tooltip-faces-viewer"
            {...tooltipProps}
        />
    );
});

FaceInfoTooltip.displayName = 'FaceInfoTooltip';
