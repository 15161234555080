import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import type { StockFile, StockFolder } from 'reactApp/modules/stock/stock.type';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getStockState = (state: RootState) => state.stock;
export const getStockList = createSelector(getStockState, (stock) => stock.list);

export const getCurrentStockFolder = createSelector(getCurrentRouteId, getStockList, (routeId, list): StockFolder | undefined => {
    const item = list[routeId];

    if (!item) {
        return;
    }

    if (item.isFolder) {
        return item;
    }

    return list[item.parent || ''] as undefined | StockFolder;
});

export const getCurrentStockIds = createSelector(getCurrentStockFolder, (folder) => folder?.childs || []);

export const getStockItemById = createSelector(
    getStockState,
    (state, id: string) => id,
    (stock, id) => stock.list[id]
);

export const getCurrentStockItem = createSelector(
    getCurrentRouteId,
    (state) => state,
    (routeId, state): StockFolder | StockFile | undefined => {
        const stock = getStockItemById(state, routeId);

        if (!routeId || !stock) {
            return;
        }

        /**
         * Сток-файлы открываются по id бандла
         * В сторе в этот момент лежит бандл и сам файл,
         * поэтому надо отдельно достать файл
         */
        if ('childs' in stock && stock.childs?.length === 1) {
            const id = stock.childs[0];

            return getStockItemById(state, id);
        }

        return stock;
    }
);
