import { Switch } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type ChangeEvent, type MouseEvent, type ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { noop } from 'reactApp/utils/helpers';

import styles from './SharingNewSection.css';
import type { IProps } from './SharingNewSection.types';

const DEFAULT_HEIGHT = 500;

export const SharingNewSection = React.memo(function SharingNewSection({
    isOpened,
    className = '',
    noSwitch = false,
    title,
    onChange = noop,
    name,
    children,
    isPhone,
    titleBig = true,
}: IProps): ReactElement {
    const ref = useRef<HTMLDivElement | null>(null);
    const [maxHeight, setMaxHeight] = useState<number>(isOpened ? DEFAULT_HEIGHT : 0);

    useEffect(() => {
        const height = ref.current?.getBoundingClientRect().height || DEFAULT_HEIGHT;
        setMaxHeight(height);
    }, [children]);

    const isWindowSmall = useIsWindowIntegrationSmall();

    const handleSwitchClick = useCallback((event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.currentTarget.blur();
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_opened]: isOpened,
                [styles.root_phone]: isPhone,
                [className]: Boolean(className),
                [styles.tutoria_opened]: isWindowSmall,
            })}
            data-qa-section={name}
        >
            {!noSwitch && (
                <div className={styles.header} onClick={onChange}>
                    <span
                        className={classNames(styles.title, {
                            [styles.titleBig]: titleBig,
                        })}
                    >
                        {title}
                    </span>
                    <Switch name={name} checked={isOpened} readOnly onClick={handleSwitchClick} />
                </div>
            )}
            <div
                className={classNames(styles.content, { [styles.tutoria_content]: isWindowSmall })}
                style={{ maxHeight: `${isOpened ? maxHeight : 0}px` }}
            >
                <div ref={ref}>{children}</div>
            </div>
        </div>
    );
});
