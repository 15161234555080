import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import {
    attachesNoadsButtonProductId,
    AttachesSidebarAB,
    attachesSidebarVersion,
    isAttachesNoadsButtonEnabled,
    isAttachesNoadsButtonPayment,
} from 'reactApp/appHelpers/featuresHelpers/features/attachSidebarAd';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';

import styles from './ReactViewer.css';

const DOM_ID_VIEWER_SIDEBAR_SMALL = config.get('DOM_ID_VIEWER_SIDEBAR_SMALL');
const DOM_ID_VIEWER_SIDEBAR = config.get('DOM_ID_VIEWER_SIDEBAR');

export const AttachesSidebarBanner = memo((): ReactElement | null => {
    const isSmall = [AttachesSidebarAB.RightSmall, AttachesSidebarAB.LeftSmall].includes(attachesSidebarVersion);
    const isRight = [AttachesSidebarAB.RightSmall, AttachesSidebarAB.RightBig].includes(attachesSidebarVersion);

    const updateAds = useCallback(() => {
        try {
            if (isSmall) {
                Rubles?.updateViewerAttachesSidebarSmall();
            } else {
                Rubles?.updateViewerAttachesSidebar();
            }
        } catch (error) {
            captureException(error);
        }
    }, [isSmall]);

    const renderItem = () => {
        return (
            <div
                className={classNames(styles.attachesSidebar, {
                    [styles.attachesSidebar__small]: isSmall,
                })}
            >
                {isAttachesNoadsButtonEnabled && (
                    <div className={styles.attachesSidebar_header}>
                        {isSmall ? (
                            <DisableButton
                                id={isRight ? 'sidebar_at_r' : 'sidebar_at_l'}
                                className={classNames(styles.attachesSidebar_headerButton, styles.attachesSidebar_headerButton__wide)}
                                forceShowPayment={isAttachesNoadsButtonPayment}
                                productId={attachesNoadsButtonProductId}
                            />
                        ) : (
                            <>
                                <span className={styles.attachesSidebar_headerText}>Реклама</span>
                                <DisableButton
                                    id={isRight ? 'sidebig_at_r' : 'sidebig_at_l'}
                                    text="Отключить"
                                    className={styles.attachesSidebar_headerButton}
                                    forceShowPayment={isAttachesNoadsButtonPayment}
                                    productId={attachesNoadsButtonProductId}
                                />
                            </>
                        )}
                    </div>
                )}

                <div
                    className={classNames(styles.attachesSidebar_content, {
                        [styles.attachesSidebar_content__small]: isSmall,
                    })}
                    id={isSmall ? DOM_ID_VIEWER_SIDEBAR_SMALL : DOM_ID_VIEWER_SIDEBAR}
                />
            </div>
        );
    };

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem,
    });
});

AttachesSidebarBanner.displayName = 'AttachesSidebarBanner';

AttachesSidebarBanner.whyDidYouRender = true;
