import { IOS_BUILD_VERSION, IS_DEV_BUILD, IS_IOS_MOBILE, IS_MAIL_APP_IOS, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import {
    defaultPayMethod,
    defaultPayMethodTouch,
    getFeatureQuotaBuyIOSConfig,
    isHidePayMethodsMenu,
    isHidePayMethodsMenuTouch,
    quotaBuyWebviewIOSConfig,
} from 'reactApp/appHelpers/featuresHelpers';
import { dmrMidasTouchEnabled, dmrMidasUrlWebEnabled } from 'reactApp/appHelpers/featuresHelpers/features/dmrMidas';
import { getQueryParams, getSourceString } from 'reactApp/appHelpers/settingsHelpers';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import type { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import { type BuyAction, type CommonBuyApiParams, BillingBuyTemplates, BillingBuyViewTemplates } from './billing.types';

export const getRequestId = (): string => Date.now().toString();

export const isMidasPaymentAvailable = (isMobile = false): boolean => {
    if (isMobile) {
        return dmrMidasTouchEnabled === 'b';
    }

    return dmrMidasUrlWebEnabled === 'b';
};

export const getMidasPaymentLink = (url: string, isMobile = false) => {
    if (!isMidasPaymentAvailable(isMobile)) {
        return url;
    }

    return url?.replace(/\/\/pw\.money\.mail\.ru\/pw\/[\d-]+\//, '//co.vkpay.io/');
};

export const getPayMethod = (isMobile) => {
    const { pay_method: payMethod } = getQueryParams();

    if (payMethod) {
        return payMethod;
    }

    if (defaultPayMethod && !isMobile) {
        return defaultPayMethod;
    }

    if (defaultPayMethodTouch && isMobile) {
        return defaultPayMethodTouch;
    }
};

export const getBuyApiParams = ({
    isMobile,
    payMethod,
    source,
    paySource,
    isQuick,
    description,
    isMidas,
}: BuyAction): CommonBuyApiParams => {
    const defaultSkin = isMobile || isQuick ? BillingBuyTemplates.CHECKOUT_VKPAY_NOOFFER : BillingBuyTemplates.CHECKOUT_VKPAY;

    return {
        skin: isMidas ? BillingBuyTemplates.VK_WALLET_V2 : defaultSkin,
        view: BillingBuyViewTemplates.checkout,
        source: getSourceString(source, isMobile, paySource),
        pay_method: payMethod,
        partner: getQueryParams()?.partner,
        hide_pay_methods_menu: isMobile ? isHidePayMethodsMenuTouch : isHidePayMethodsMenu,
        hide_login_button: isMidas && isMobile && IS_WEBVIEW,
        hide_close_button: true,
        description,
        fullscreen: isMobile && isMidas,
    };
};

export const getPaymentDescription = (product: Product): string => {
    const { space, discountPrice, discountPeriod, period, price } = product;

    const priceInfo = `+ ${space.value} на ${getPeriodName(period)}`;

    let description = `${priceInfo}\nПодписка продлевается автоматически через ${getPeriodName(
        product.period
    )} на аналогичный период. С привязанной карты будет списываться автоплатеж для всех активных подписок, приобретаемых на сайте. Отменить автоплатёж можно в настройках подписки.`;

    if (discountPrice && discountPeriod) {
        description = `${priceInfo}\n${getPretextPeriodForProduct(discountPeriod)} ${getPeriodNameAsWord(
            discountPeriod,
            true
        )} оплата составит ${formatPrice(
            price
        )} ₽. C привязанной карты будет списываться автоплатеж для всех активных подписок, приобретаемых на сайте. Отменить автоплатёж можно в любое время в настройках подписки.`;
    }

    return description.replace(/\u00A0/g, ' ');
};

export const checkMobileWebviewPayAvailable = (): boolean => {
    // Открыто в приложении почты без build-версии
    if (!IOS_BUILD_VERSION && IS_MAIL_APP_IOS && IS_IOS_MOBILE) {
        return true;
    }

    if ((!IS_WEBVIEW && !IS_MAIL_APP_IOS) || !IS_IOS_MOBILE) {
        return true;
    }

    // для дев сборок всегда включаем оплаты
    if (IS_DEV_BUILD) {
        return true;
    }

    const config = IS_MAIL_APP_IOS ? getFeatureQuotaBuyIOSConfig : quotaBuyWebviewIOSConfig;

    if (!IOS_BUILD_VERSION || !config) {
        return false;
    }

    const { start, end } = config || {};

    // если нет стартовой версии, то не валидно
    if (!start) {
        return false;
    }

    const buildNum = +IOS_BUILD_VERSION;

    if (buildNum < start) {
        return false;
    }

    // если нет конечной версии, то оплата включена, иначе проверяем < конечной
    return end ? buildNum < end : true;
};
