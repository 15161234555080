import classNames from 'clsx';
import React, { type ForwardedRef, type MouseEvent, type ReactElement, forwardRef } from 'react';

import styles from './Label.css';

interface IProps {
    children: ReactElement | string;
    className?: string;
    onClick?(event?: MouseEvent): void;
    qaLabel?: string;
}

export const Label = forwardRef(function Label(
    { children, className = '', onClick, qaLabel = '' }: IProps,
    ref: ForwardedRef<HTMLDivElement>
): ReactElement {
    return (
        <div className={classNames(styles.root, className)} ref={ref} onClick={onClick} data-qa-label={qaLabel}>
            {children}
        </div>
    );
});
