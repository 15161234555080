import Cookies from 'js-cookie';
import { logger } from 'lib/logger';
import { isEmpty } from 'ramda';
import { isAdmitadEnabled } from 'reactApp/appHelpers/featuresHelpers/features/admitad';
import type { Product } from 'reactApp/types/Billing';
import { sendXray } from 'reactApp/utils/ga';
import { isYearPeriod } from 'reactApp/utils/Period';

export const sendAdmitadProduct = (product: Product) => {
    try {
        const { period, price, discountPrice, hasTrial, id } = product;

        const productPrice = hasTrial ? 0 : discountPrice || price;

        if (!isAdmitadEnabled) {
            return;
        }

        ADMITAD = window.ADMITAD || {};

        if (!ADMITAD || isEmpty(ADMITAD)) {
            sendXray('buy-product-no-admitad');
            return;
        }

        ADMITAD.Invoice = ADMITAD.Invoice || {};

        // определение канала для Admitad
        // Покупка месячной подписки - 1
        // Покупка годовой подписки - 2
        ADMITAD.Invoice.category = isYearPeriod(period) ? '2' : '1';

        const orderedItem: any[] = [];

        orderedItem.push({
            Product: {
                productID: id,
                category: '1',
                price: productPrice.toString(),
                priceCurrency: 'RUB',
            },
            orderQuantity: '1',
            additionalType: 'sale',
        });

        ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];

        ADMITAD.Invoice.referencesOrder.push({
            orderNumber: new Date().getTime().toString(),
            orderedItem,
        });

        ADMITAD.Tracking.processPositions();
    } catch (error) {
        sendXray('admitad-fail');
        logger.error(error);
    }
};

export const patchPaysourceWithAdmitad = (paySource?: string) => {
    const admitadPaySource = 'admitadps';
    if (Cookies.get('tagtag_aid')) {
        return paySource ? `${paySource}_${admitadPaySource}` : admitadPaySource;
    }
    return paySource;
};
