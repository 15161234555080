import React, { type FC } from 'react';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import type { BasicProps } from '../../DataListItemRow.types';
import styles from './ItemTip.css';

interface ItemTipProps {
    className?: string;
    isFolder: boolean;
    tipData: BasicProps['tipData'];
    target: React.RefObject<HTMLDivElement>;
}

export const ItemTip: FC<ItemTipProps> = ({ isFolder, tipData, target }) => {
    return !tipData ? null : (
        <FloatingTooltip
            className={styles.tooltip}
            target={target}
            size={ETooltipSize.large}
            text={tipData[0].getTipContent?.(isFolder)}
            placement={ETooltipPlacement.top}
            closeOnOutsideClick={false}
            qaId={tipData[0].id}
            onClose={tipData[0].onHideClick}
            usePortal
        />
    );
};
