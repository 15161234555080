import { Spacing, Text, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/pause-subscription.jpg';
import coverImageMobile from 'img/subscriptions/pause-subscription-mobile.jpg';
import coverImageMobileDark from 'img/subscriptions/pause-subscription-mobile-dark.jpg';
import coverImageRebranding from 'img/subscriptions/pause-subscription-rebranding.jpg';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './PauseSubscriptionDialog.css';
import type { Props } from './PauseSubscriptionDialog.types';

export const PauseSubscriptionDialog = memo<Props>(({ subscription, onClose, onSuccess, onAction }): ReactElement => {
    const isMobile = EnvironmentSelectors.isPhone();
    const scrollRef = useRef<HTMLDivElement>(null);

    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showPauseModal);
    }, [sendAnalytics]);

    const handleClick = useCallback(() => {
        onSuccess();
        sendAnalytics(ESubscriptionModalAction.clickPauseSub);
    }, [onSuccess, sendAnalytics]);

    const handleSecondaryClick = useCallback(() => {
        onAction();
        sendAnalytics(ESubscriptionModalAction.cancelPauseSub);
    }, [onAction, sendAnalytics]);

    const content = useMemo(
        (): ReactElement => (
            <div>
                <Spacing size={12} />
                <Text className={styles.text} weight="3">
                    Очередной платёж не спишется, а все возможности подписки &mdash; сохранятся. Удобно, пока вы в отпуске
                </Text>
                <Spacing size={24} />
            </div>
        ),
        []
    );

    const containsDarkTheme = isDarkThemeModeEnabled();

    if (isMobile) {
        return (
            <MobileDialog onClose={onClose} closeOnDimmerClick scrollRef={scrollRef} mod="base" contextPadding="zero">
                <div ref={scrollRef} className={styles.rootMobile}>
                    <img
                        className={styles.image}
                        src={containsDarkTheme ? coverImageMobileDark : coverImageMobile}
                        alt="Оплату можно поставить на паузу"
                    />
                    <div className={styles.content}>
                        <Title>Оплату можно поставить на паузу</Title>
                        <Spacing size={8} />
                        <Text>Очередной платёж не спишется, а все возможности подписки &mdash; сохранятся. Удобно, пока вы в отпуске</Text>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary fluid onClick={handleClick}>
                            Взять паузу
                        </Button>
                        <Spacing size={12} />
                        <Button theme="vk" fluid onClick={handleSecondaryClick}>
                            Отключить всё равно
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            dialogSize="average"
            onClose={onClose}
            onClick={handleClick}
            onSecondary={handleSecondaryClick}
            primaryButtonText="Взять паузу"
            secondaryButtonText="Отключить всё равно"
            title="Оплату можно поставить на паузу"
            content={content}
            imageUrl={isRebranding ? coverImageRebranding : coverImage}
            buttonTheme="vk"
        />
    );
});

PauseSubscriptionDialog.displayName = 'PauseSubscriptionDialog';
