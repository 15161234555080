/* eslint-disable no-irregular-whitespace */
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import { ReactComponent as WarningIcon } from 'mrg-icons/src/mailru/status/warning_triangle.svg';
import React, { type ReactElement, createRef, PureComponent } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { EActionName, EVirusScan } from 'reactApp/ui/SuspiciousDialog/SuspiciousDialog.types';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './SuspiciousDialog.css';

const sendGa = createGaSender('suspdlg');

export interface Props {
    onClose();
    onSuccess();
    action: EActionName;
    isMobileIos: boolean;
    filename: string;
    virusScan: EVirusScan;
    isPhone?: boolean;
}

interface State {
    isChecked: boolean;
}

const hotKeysScope = 'SuspiciousDialog';

export class SuspiciousDialog extends PureComponent<Props, State> {
    private contentRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        onClose: noop,
        onBuy: noop,
    };

    previousKeyScope = '';
    state = {
        isChecked: false,
    };

    public componentDidMount() {
        this.previousKeyScope = hotkeys.getScope();
        hotkeys.setScope(hotKeysScope);
        hotkeys('enter', hotKeysScope, () => {
            if (this.state.isChecked) {
                this.handleSuccess();
            }
        });
        scrollLock(this.contentRef.current);
        sendGa('show');
    }

    public componentWillUnmount(): void {
        hotkeys.deleteScope(hotKeysScope);
        hotkeys.setScope(this.previousKeyScope);
        scrollUnlock(this.contentRef.current);
    }

    private handleClose = () => {
        sendGa('close-click', null, true);

        this.props.onClose();
    };

    private handleSuccess = () => {
        sendGa('success-click', null, true);

        this.props.onSuccess();
    };

    private renderFooter = (action, isMobileIos, isChecked) => {
        let submitBttnName = '';
        if (action === EActionName.download) {
            submitBttnName = isMobileIos ? 'Открыть' : 'Скачать';
        } else if (action === EActionName.clone) {
            submitBttnName = isMobileIos ? 'в Облако' : 'Сохранить';
        } else {
            submitBttnName = 'Подтвердить';
        }

        return (
            <div className={styles.footer}>
                <div className={styles.buttonWrapper}>
                    <Button theme="octavius" primary middle data-name="action" onClick={this.handleSuccess} disabled={!isChecked}>
                        {submitBttnName}
                    </Button>
                    <Button theme="octavius" middle data-name="cancel" onClick={this.handleClose}>
                        Отменить
                    </Button>
                </div>
            </div>
        );
    };

    private handleAgreement = () => {
        this.setState((state) => ({ isChecked: !state.isChecked }));
    };

    public render(): ReactElement | null {
        const { action, isMobileIos, filename, virusScan, isPhone } = this.props;
        const { isChecked } = this.state;

        const title =
            action === EActionName.download
                ? `Вы собираетесь ${isMobileIos ? 'открыть' : 'скачать'} файл`
                : action === EActionName.clone
                ? 'Вы собираетесь сохранить в Облако файл'
                : 'Вы собираетесь выполнить действие с файлом';

        const virusText =
            virusScan === EVirusScan.pass
                ? 'Файл был проверен с помощью антивируса Kaspersky Lab, но остаётся вероятность, что файл может причинить вред вашему компьютеру.'
                : 'Файл этого типа может причинить вред вашему компьютеру.';
        const note =
            action === EActionName.download
                ? `${isMobileIos ? 'Открывайте' : 'Скачивайте'} его, только если вы доверяете отправителю.`
                : action === EActionName.clone
                ? 'Сохраняйте его, только если вы доверяете отправителю.'
                : 'Подтверждайте действие, только если вы доверяете отправителю.';
        const checkText =
            action === EActionName.download
                ? `Я хочу ${isMobileIos ? 'открыть' : 'скачать'} этот файл`
                : action === EActionName.clone
                ? 'Я хочу сохранить этот файл'
                : 'Я хочу выполнить действие с этим файлом';

        return (
            <Dialog
                open
                onCancel={this.handleClose}
                disableScrollOnBody={false}
                id="suspicious-dialog"
                footer={this.renderFooter(action, isMobileIos, isChecked)}
                className={classNames(styles.root, { [styles.root_mobile]: isPhone })}
            >
                <Content isModal isLoading={false} hasError={false} scrolling>
                    <div className={styles.content} ref={this.contentRef}>
                        <div className={styles.iconWrapper}>
                            <WarningIcon height={96} width={96} fill="var(--color-orange-peel)" data-qa-id="warningIcon" />
                        </div>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.filename}>{filename}</div>
                        <div className={styles.description}>
                            <div>{virusText}</div>
                            <div className={styles.note}>{note}</div>

                            <div className={styles.check} onClick={this.handleAgreement}>
                                <Checkbox selected={isChecked} mod="blue" />
                                <div className={styles.checkText}>{checkText}</div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Dialog>
        );
    }
}
