import { LLM } from 'reactApp/modules/features/features.helpers';
import { type ActionCategory, MessageCategory } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';

type selectOptions<T extends string> = Record<T, string>;

type actionOptions = selectOptions<ActionCategory>;

type scenarioOptions = selectOptions<MessageCategory>;

interface LLMFeature {
    hoverType: 'withText' | 'withHover';
    actionOptions: actionOptions;
    scenarioOptions: scenarioOptions;
    dialogTitle: string;
    buttonTitle: string;
    tooltipTitle: string;
    tooltipMessage: string;
    tooltipButtonText: string;
    welcomeMessage: string;
    inputPlaceholder: string;
}

const DEFAULT_VALUE: LLMFeature = {
    hoverType: 'withText',
    actionOptions: {} as actionOptions,
    scenarioOptions: { [MessageCategory.TEXT_BY_THEME]: 'Сгенерировать текст' } as scenarioOptions,
    dialogTitle: 'Создать текст?',
    buttonTitle: 'Создать текст?',
    tooltipTitle: 'Привет!',
    tooltipMessage: 'Я помогу написать\nтекст и придумать идеи',
    tooltipButtonText: 'Попробовать',
    welcomeMessage:
        'Привет! Я пишу идеи, списки и другие тексты с помощью умных алгоритмов. Результат может не соответствовать ожиданиям, — ответы автоматические, поэтому в них бывают ошибки. Но будьте уверены: это не чьё-то мнение или позиция.\n\nВыбирайте действие — и начнём!',
    inputPlaceholder: 'Предложите тему',
};

export const llmFeature: LLMFeature = { ...DEFAULT_VALUE, ...LLM };
