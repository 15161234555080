import React, { type ReactElement } from 'react';

import styles from './TipSecondClickContent.css';

export const TipSecondClickContent = (isFolder: boolean): ReactElement => (
    <div className={styles.root}>
        <span>Чтобы открыть {isFolder ? 'папку' : 'файл'}, коснитесь дважды.</span>
        <br />
        <span>А чтобы выделить — один раз.</span>
    </div>
);
