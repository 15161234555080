import { ReactComponent as ShareIcon } from 'img/icons/editor/shareIcon.svg';
import React, { type FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import type { EditorItem } from 'reactApp/modules/editor/editor.types';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { HeaderButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton';
import { ShareBubble } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderRightGroup/components/ShareBubble/ShareBubble';
import { ShareButtonCoEditPopup } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/ShareButtonCoEditPopup';

interface Props {
    isPublic: boolean;
    gaSender: (action: string, label: string, data?: any) => void;
    isStock: boolean;
    file: EditorItem | null;
    id?: string;
    isViewer?: boolean;
}

export const ShareSection: FC<Props> = ({ isPublic, gaSender, file, id = '', isViewer, isStock }) => {
    const [isShowBubble, setIsShowBubble] = useState(false);
    const buttonTextRef = useRef<HTMLDivElement>(null);
    const coEditPopupPromo = useSelector(PromoSelectors.pullPromo(EPromoType.coEditPopup));

    const closeBubble = () => {
        setIsShowBubble(false);
    };

    const handlePublish = (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
        e?.stopPropagation();

        gaSender('publish', 'click', { type: 'button' });

        closeBubble();

        publishHelper({ item: file, gaSuffix: '_header' });
    };

    const handleShareDocument = () => {
        gaSender('share', 'click');

        renderSharingNew({ id, from: EFrom.SHARING });
    };

    return isPublic ? (
        <HeaderButton icon={<ShareIcon />} onClick={handleShareDocument} textRef={buttonTextRef} hint="Поделиться ссылкой" qaId="share" />
    ) : (
        <>
            {isShowBubble && <ShareBubble buttonTextRef={buttonTextRef} closeBubble={closeBubble} />}
            {!isPublic && !isViewer && !isStock && coEditPopupPromo ? (
                <ShareButtonCoEditPopup>
                    <div onClick={handlePublish} data-qa-id="share">
                        <HeaderButton
                            hint="Настроить доступ"
                            title="Настроить доступ"
                            icon={<ShareIcon />}
                            onClick={handlePublish}
                            textRef={buttonTextRef}
                        />
                    </div>
                </ShareButtonCoEditPopup>
            ) : (
                <div onClick={handlePublish} data-qa-id="share">
                    <HeaderButton
                        hint="Настроить доступ"
                        title="Настроить доступ"
                        icon={<ShareIcon />}
                        onClick={handlePublish}
                        textRef={buttonTextRef}
                    />
                </div>
            )}
        </>
    );
};
