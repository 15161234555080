import React, { useEffect } from 'react';

// костыль только для аб
/** tempexp_150202-start */
export const PresentLandingReloader = ({ location }) => {
    useEffect(() => {
        window.location.assign(`/promo/present${location.search}`);
    }, []);

    return <></>;
};
/** tempexp_150202-end */
