import React from 'react';
import { APP_TITLE } from 'reactApp/appHelpers/configHelpers';
import { type IBaseConfirmDialogProps, BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { UpdateLicenseDialog } from 'reactApp/components/BaseConfirmDialog/UpdateLicenseDialog/UpdateLicenseDialog';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

export const ComfirmationDialog = ({
    renderContent,
    onSuccess = noop,
    onClose = noop,
    renderHeader = null,
    successButtonText,
    cancelButtonText,
    dataQAId,
    buttons,
    icon,
    closable,
}: IBaseConfirmDialogProps): JSX.Element => (
    <BaseConfirmDialog
        renderContent={renderContent}
        onClose={onClose}
        onSuccess={onSuccess}
        renderHeader={renderHeader}
        successButtonText={successButtonText}
        cancelButtonText={cancelButtonText}
        dataQAId={dataQAId}
        buttons={buttons}
        icon={icon}
        closable={closable}
    />
);

export const renderComfirmationDialog = renderModalDialog(ComfirmationDialog, 'ComfirmationDialog');

export const renderNewComfirmationDialog = (props: IBaseConfirmDialogProps) => {
    const { onSuccess = noop, onAction = noop, ...rest } = props;
    const { closeDialog } =
        openPopupHelper({
            popupName: popupNames.GENERIC_CONFRIMATION_DIALOG,
            data: {
                onSuccess: () => {
                    closeDialog?.();
                    onSuccess();
                },
                onAction: () => {
                    closeDialog?.();
                    onAction();
                },
                ...rest,
            },
        }) || {};
};

export const BizPaidEditorDialog = ({
    dataQAId = 'teambox-editors-promo-dialog',
    renderContent = () =>
        `Редактирование документов доступно только в платной версии ${APP_TITLE}. Свяжитесь с администратором вашего домена для включения этой опции. `,
    renderHeader = () => 'Редактирование документов недоступно',
    buttons = [EButtonType.cancel],
    cancelButtonText = 'Закрыть',
    onClose = noop,
}: Partial<IBaseConfirmDialogProps>) => (
    <BaseConfirmDialog
        dataQAId={dataQAId}
        renderContent={renderContent}
        renderHeader={renderHeader}
        buttons={buttons}
        cancelButtonText={cancelButtonText}
        onClose={onClose}
    />
);

export const renderBizPaidEditorDialog = renderModalDialog(BizPaidEditorDialog, 'BizPaidEditorDialog');

export const MessageDialog = ({ renderContent, onClose = noop, renderHeader = null, dataQAId }: IBaseConfirmDialogProps) => (
    <BaseConfirmDialog
        renderContent={renderContent}
        onClose={onClose}
        onSuccess={noop}
        renderHeader={renderHeader}
        buttons={[EButtonType.ok]}
        dataQAId={dataQAId}
    />
);

export const renderMessageBox = renderModalDialog(MessageDialog, 'MessageDialog');

export const BizMigrationDialog = ({
    dataQAId = 'biz-migration',
    renderContent = () => (
        <>
            Вы используете Облако на устаревшем бизнес-аккаунте. Для изменения тарифа вам необходимо провести миграцию на {APP_TITLE}. У вас
            сохранятся все ваши файлы, останется привычный интерфейс и появятся дополнительные бизнес-функции и возможности. Миграция
            выполняется администратором домена в панели управления {APP_TITLE}.
        </>
    ),
    renderHeader = () => 'Миграция',
    buttons = [EButtonType.accept, EButtonType.action],
    onClose = noop,
}: Partial<IBaseConfirmDialogProps>) => (
    <BaseConfirmDialog
        dataQAId={dataQAId}
        renderContent={renderContent}
        renderHeader={renderHeader}
        buttons={buttons}
        onClose={onClose}
        actionButtonText="Подробнее"
        successButtonText="Миграция"
        onSuccess={() => {
            opener('https://biz.mail.ru/domains/goto/cloud/');
        }}
        onAction={() => {
            opener('https://biz.mail.ru/teambox/');
        }}
    />
);

export const renderBizMigrationDialog = renderModalDialog(BizMigrationDialog, 'BizMigrationDialog');

export const renderUpdateLicenseDialog = renderModalDialog(UpdateLicenseDialog, 'UpdateLicenseDialog');
