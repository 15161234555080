import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'file/copy';

class FileCopyAPIV2Call extends APICallV2Post {
    _method = method;
}

class FileCopyAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const copyFileApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateFileV2ToV4.copy) {
        return new FileCopyAPIV4Call().makeRequest({ path: params.id, folder: params.folder, conflict: params.conflict });
    }

    return new FileCopyAPIV2Call().makeRequest({ home: params.id, folder: params.folder, conflict: params.conflict });
};
