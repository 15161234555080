import React, { type ReactElement, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { sendDwh } from 'reactApp/utils/ga';

import styles from './SearchNotification.css';

const title = 'Находите нужные файлы';
const description = 'Введите слово или фразу — поиск покажет файлы с похожим названием';

const sendRadar = (action) =>
    sendDwh({
        eventCategory: 'search-tooltip',
        action,
        dwhData: {
            place: 'touch',
            text_id: 'mobile-search-notification',
            text: `${title} ${description}`,
        },
    });

export const SearchNotification = memo<IPropsWithPopup>(({ onClose }): ReactElement => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);

    const onCloseCallback = useCallback(() => {
        onClose();
        sendRadar('close');
    }, [dispatch, onClose]);

    const onClickCallback = useCallback(() => {
        dispatch(historyPush({ id: getIdByStorage(EStorageType.search) }));
        onClose();
        sendRadar('click');
    }, [dispatch, onClose]);

    useEffect(() => {
        sendRadar('show');
    }, []);

    return (
        <MobileDialog
            id="mobile-search-notification"
            onClose={onCloseCallback}
            disableScrolling
            closeOnDimmerClick
            topmost
            mod="base"
            scrollRef={ref}
        >
            <div className={styles.root} onClick={onClickCallback} ref={ref}>
                <div className={styles.image} />
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>{description}</div>
                    <Button theme="vk" primary className={styles.button}>
                        Попробовать
                    </Button>
                </div>
            </div>
        </MobileDialog>
    );
});

SearchNotification.displayName = 'SearchNotification';
