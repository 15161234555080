import type { Method } from 'lib/axios';
import { apiMigrateFolderV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

import { APICallV2Post, APICallV4 } from '../APICall';

export class ShareApiCall extends APICallV2Post {
    _method = 'folder/share';
}

export class ShareV4ApiCall extends APICallV4 {
    _method = 'folder/share';
    _type: Method = 'post';
}

export const shareV4ApiCall = ({ path, email, access }) => new ShareV4ApiCall().makeRequest({ path, invite: { email, access } });

export const shareApiCall = ({ id, email, access }) => {
    if (apiMigrateFolderV2ToV4.share) {
        return shareV4ApiCall({ path: id, email, access });
    }
    return new ShareApiCall().makeRequest({ home: id, invite: { email, access } });
};
