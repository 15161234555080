import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type MidasFeatureType = 'a' | 'b' | undefined;

/** CLOUDWEB-16083: Фича включения оплаты через мидас */
export const dmrMidasUrlWebEnabled: MidasFeatureType = getFeature('dmr-midas-web');
/** CLOUDWEB-17111: Фича включения оплаты через мидас на таче*/
export const dmrMidasUrlTouchIosEnabled: MidasFeatureType = getFeature('dmr-midas-ios');
export const dmrMidasUrlToucAndroidhEnabled: MidasFeatureType = getFeature('dmr-midas-and');
export const dmrMidasTouchEnabled: MidasFeatureType = IS_IOS_MOBILE ? dmrMidasUrlTouchIosEnabled : dmrMidasUrlToucAndroidhEnabled;

// tempexp_17111-start
registerExperiment(
    IS_PHONE_BROWSER ? 'midas-touch' : 'midas-web',
    IS_PHONE_BROWSER ? dmrMidasTouchEnabled : dmrMidasUrlWebEnabled,
    [
        AnalyticEventNames.TARIFFS_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ],
    {
        sendXrayArgs: {
            TARIFF_BUY: ({ enabledMidasPayment }) => ({
                cancel: !enabledMidasPayment,
            }),
            FAST_CHECKOUT_SHOWN: ({ enabledMidasPayment }) => ({
                cancel: !enabledMidasPayment,
            }),
        },
    }
);
// tempexp_17111-end
