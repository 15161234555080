import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { litresTariffIdsListCommon } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendWebviewAnalytics } from 'reactApp/sections/MobileQuotaTariffsLanding/MobileQuotaTariffsLanding.helpers';
import { EPostMessageBuy } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { getQuotaBillingSource, sendQuotaGa, sendWVRadar, sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { sendAdmitadProduct } from 'reactApp/utils/admitadHelper';
import { scrollToTop } from 'reactApp/utils/helpers';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import type { IMobilePayProps, TBuyClick } from './TariffPlansCard.types';

export const useBuyClick = ({
    isMobilePayAvailable,
    isMobilePayFromGetParam,
    isIOSPromo = false,
    isAndroidPromo = false,
    enabledMidasPayment = false,
    isRebrandingQuotaLanding = false,
}: IMobilePayProps): TBuyClick => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    const isSocial = useSelector(UserSelectors.isSocialUser);
    const isSocialUserWithoutEmail = useSelector(isEmailAbsent);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { isQuotaTariffs } = getStorage(storage);

    const openBuy = useCallback(
        // tempexp_16481-next-line
        ({ product, paySource, onClick, space }) => {
            const onSuccess = () => {
                sendAdmitadProduct(product);
                sendWebviewAnalytics('payok', isMobilePayAvailable);
                sendWVRadar('quota_land_wv_payok', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });
                emitAnalyticEvent(AnalyticEventNames.TARIFF_BUY, { paySource, product, enabledMidasPayment });
                sendQuotaGa({
                    action: 'tariff',
                    label: 'buy',
                    tariff: product.id,
                    amount: product.price,
                });

                // tempexp_16481-start
                if (AUTOUPLOAD_10TB_REGEX.test(product.id)) {
                    sendXrayWithPlatform(['autoupload-10tb', `tariff-buy-${space}`]);
                }
                // tempexp_16481-end

                if (isMobile) {
                    sendPostMessage({
                        paymentResult: EPostMessageBuy.SUCCESS,
                        productId: product.id,
                        price: product.price,
                    });
                }

                scrollToTop();
            };

            const onError = () => {
                sendWebviewAnalytics('payerror', isMobilePayAvailable);
                sendWVRadar('quota_land_wv_payerror', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });
                sendQuotaGa({ action: 'tariff', label: 'buy-error', tariff: product.id, amount: product.price });

                if (isMobile) {
                    sendPostMessage({
                        paymentResult: EPostMessageBuy.FAILED,
                    });
                }
            };

            // tempexp_16481-next-line
            const subTitle = AUTOUPLOAD_10TB_REGEX.test(product.id) ? '+10 ТБ на автозагрузку фото и видео' : undefined;

            // tempexp_16598-start
            const isCheapest = product.id && Object.values(cheapestProductIDs).includes(product.id);
            let title = isCheapest ? 'Отключить рекламу с подпиской Mail Space' : undefined;
            const paymentDescription = isCheapest ? 'Тариф - Без рекламы' : undefined;
            // tempexp_16598-end

            // tempexp_17509-start
            title = litresTariffIdsListCommon.includes(product.id)
                ? `Подписка Mail Space: Литрес на 2 месяца бесплатно + ${product.space.value}`
                : undefined;
            // tempexp_17509-end

            dispatch(
                openTariffBuy({
                    productId: product.id,
                    source: getQuotaBillingSource(),
                    checkAnonymous: true,
                    onSuccess,
                    isMobile,
                    onError,
                    paySource,
                    onClick,
                    subTitle,
                    enabledMidasPayment,
                    title,
                    paymentDescription,
                })
            );
        },
        [dispatch, enabledMidasPayment, isAndroidPromo, isIOSPromo, isMobile, isMobilePayAvailable, isMobilePayFromGetParam]
    );

    return useCallback(
        // tempexp_16481-next-line
        ({ product, paySource, onClick, space }) => {
            sendWebviewAnalytics('tclick', isMobilePayAvailable);
            sendWVRadar('quota_land_wv_tclick', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });

            if ((isAndroidPromo || isIOSPromo) && !isMobilePayAvailable) {
                revealPlatformPopup(product.id, isIOSPromo, isAndroidPromo, isRebrandingQuotaLanding);
                return;
            }

            // Социальным пользователям показываем окно привязки почты
            if (isQuotaTariffs && isMobile && isSocial && isSocialUserWithoutEmail) {
                // tempexp_16481-next-line
                renderConfirmEmailMobile({ onSuccess: () => openBuy({ product, paySource, space }) });
                return;
            }

            emitAnalyticEvent(AnalyticEventNames.TARIFF_CLICK, { paySource, product });
            sendQuotaGa({
                action: 'tariff',
                label: 'click',
                tariff: product.id,
                amount: product.price,
            });

            // tempexp_16481-next-line
            openBuy({ product, paySource, onClick, space });

            // tempexp_16481-start
            if (AUTOUPLOAD_10TB_REGEX.test(product.id)) {
                sendXrayWithPlatform(['autoupload-10tb', `tariff-click-${space}`]);
            }
            // tempexp_16481-end
        },
        [
            isIOSPromo,
            isAndroidPromo,
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isRebrandingQuotaLanding,
            isQuotaTariffs,
            isMobile,
            isSocialUserWithoutEmail,
            openBuy,
        ]
    );
};
