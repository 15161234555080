import { Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type VFC, useMemo } from 'react';
import { summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { Button } from 'reactApp/ui/Button/Button';
import { DiscountLabel } from 'reactApp/ui/DiscountLabel/DiscountLabel';
import { TariffFeatureList } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList';
import { TariffLabel } from 'reactApp/ui/TariffCardNew/components/TariffLabel';
import { ETariffLabelKind, ETariffLabelRadius } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { TariffSpace } from 'reactApp/ui/TariffCardNew/components/TariffSpace';
import { TariffSwitch } from 'reactApp/ui/TariffCardNew/components/TariffSwitch';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

import styles from './TariffCard.css';
import { type ITariffCardProps, ETariffCardWidth, TariffCardContentSize } from './TariffCard.types';

export const TariffCard: VFC<ITariffCardProps> = ({
    dataQa,
    onClick,
    width = ETariffCardWidth.w360,
    tariffId,
    space,
    showSpaceWithPlus = true,
    giftSpace,
    buttonText,
    buttonSize,
    buttonTextSize,
    headerPrimaryText,
    headerPrimaryTextCrossedOut,
    headerPrimaryTextLarge,
    headerPrimaryTextLight,
    buttonPrimary,
    buttonPrimaryLight,
    buttonDisabled,
    buttonAdditionalText,
    focused,
    label,
    labelKind,
    onClickButton,
    onClickSwitch,
    switchOptions,
    switchChecked,
    featureListOptions,
    footerFeatureListOptions,
    buttonInFooter,
    compact,
    compactFeatureList = false,
    discountLabel,
    infoBlock,
    savingInButton,
    disable,
    action,
    withoutButton,
    accentFeature,
    footerText,
    onLabelClick,
    focusedWithoutShadow = false,
    accentFocused = false,
    buttonInInfoBlock = false,
    stretchInfoBlock = false,
    buttonInFooterGap = 36,
    contentSize = TariffCardContentSize.middle,
    boldSavingLabel = false,
    // tempexp_17086-next-line
    bordered = false,
    isRebrandingQuotaLanding = false,
    featuresSmallSize = false,
    isRebranding = false,
    /* tempexp_17509-next-line */
    isFromLitres = false,
    // tempexp_17371-next-line
    hideButtonInMonthTariff,
}) => {
    const button = useMemo(
        () =>
            !withoutButton && (
                <>
                    <div
                        className={classNames(styles.buttonWrapper, {
                            [styles.buttonWrapper_withMargin]: buttonInInfoBlock,
                            [styles.buttonWrapper_inFooter]: buttonInFooter,
                        })}
                    >
                        {discountLabel && (
                            <div className={styles.discountLabel}>
                                <DiscountLabel discount={discountLabel} />
                            </div>
                        )}
                        <Button
                            data-qa={dataQa && 'button'}
                            data-qa-primary={dataQa && buttonPrimary}
                            data-qa-disabled={dataQa && buttonDisabled}
                            fluid
                            onClick={onClickButton}
                            disabled={buttonDisabled}
                            className={classNames(styles.cardButton, {
                                [styles.cardButton_primary]: buttonPrimary,
                                [styles.cardButton_disabled]: buttonDisabled,
                                [styles.cardButton_primaryLight]: buttonPrimaryLight,
                                [styles[`cardButton_${buttonSize}`]]: Boolean(buttonSize),
                                [styles.cardButton_action]: action && !isRebranding,
                                [styles.cardButton_InInfoBlock]: buttonInInfoBlock,
                                [styles.cardButton_summer]: summerPromotion,
                                [styles.cardButton_rebranding]: isRebranding,
                            })}
                        >
                            <span
                                data-qa={dataQa && 'buttonText'}
                                className={classNames({
                                    [styles[`buttonText_${buttonTextSize}`]]: Boolean(buttonTextSize),
                                })}
                            >
                                {buttonText}
                            </span>
                            {Boolean(buttonAdditionalText) && (
                                <div className={styles.buttonAdditionalText} data-qa={dataQa && 'buttonAdditionalText'}>
                                    {buttonAdditionalText}
                                </div>
                            )}
                        </Button>
                        {Boolean(savingInButton) && (
                            <div
                                className={classNames(styles.saving, {
                                    [styles.boldSavingLabel]: boldSavingLabel,
                                })}
                            >
                                -{savingInButton}%
                            </div>
                        )}
                    </div>
                </>
            ),
        [
            action,
            buttonAdditionalText,
            buttonDisabled,
            buttonPrimary,
            buttonPrimaryLight,
            buttonSize,
            buttonText,
            buttonTextSize,
            dataQa,
            discountLabel,
            onClickButton,
            savingInButton,
            withoutButton,
            buttonInFooter,
        ]
    );
    const darkTheme = isDarkThemeModeEnabled();

    return (
        <div
            data-qa={dataQa}
            data-qa-focused={dataQa && focused}
            data-qa-tariffid={dataQa && tariffId}
            className={classNames(styles.card, {
                [styles[`contentSize_${contentSize}`]]: contentSize,
                [styles.card_bordered]: bordered,
                [styles.card_focused]: focused,
                [styles.focused_without_shadow]: focusedWithoutShadow,
                [styles.accentFocused]: accentFocused,
                [styles.accentFocused_summer]: summerPromotion,
                [styles.card_compact]: compact,
                [styles.card_compact_padding]: compactFeatureList,
                [styles.card_disable]: disable,
                [styles.card_action]: action,
                [styles[`card_${width}`]]: width,
                [styles.darkMode]: darkTheme,
                [styles.card_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                [styles.card_rebranding]: isRebranding,
            })}
            onClick={onClick}
        >
            {label && (
                <div
                    className={classNames(styles.label, {
                        [styles.labelClickable]: Boolean(onLabelClick),
                    })}
                    onClick={() => onLabelClick?.(true)}
                >
                    <TariffLabel
                        dataQa={dataQa && 'label'}
                        contentSize={contentSize}
                        radius={
                            /* tempexp_17509-start */
                            !isFromLitres ||
                            /* tempexp_17509-end */
                            focused
                                ? ETariffLabelRadius.r22
                                : ETariffLabelRadius.r24
                        }
                        kind={
                            /* tempexp_17509-start */
                            !isFromLitres &&
                            /* tempexp_17509-end */
                            focused &&
                            action &&
                            labelKind !== ETariffLabelKind.tariffPromo &&
                            labelKind !== ETariffLabelKind.tarrifRebranding
                                ? ETariffLabelKind.actionBlack
                                : labelKind
                        }
                        isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        enableTopRightBorder={isRebrandingQuotaLanding && focused}
                    >
                        {label}
                    </TariffLabel>
                </div>
            )}
            <div
                className={classNames(styles.cardHeader, {
                    [styles.cardHeader_stretch]: stretchInfoBlock,
                })}
            >
                <TariffSpace
                    dataQa={dataQa && 'space'}
                    units={space.units}
                    space={space.space}
                    giftSpace={giftSpace}
                    giftKind={labelKind}
                    showSpaceWithPlus={showSpaceWithPlus}
                    contentSize={contentSize}
                    isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                    isRebranding={isRebranding}
                />
                {headerPrimaryText && (
                    <div
                        data-qa={dataQa && 'headerPrimaryText'}
                        data-qa-crossedout={dataQa && headerPrimaryTextCrossedOut}
                        data-qa-large={dataQa && headerPrimaryTextLarge}
                        className={classNames(styles.cardHeaderPrimaryText, {
                            [styles.cardHeaderPrimaryText_crossedOut]: headerPrimaryTextCrossedOut,
                            [styles.cardHeaderPrimaryText_large]: headerPrimaryTextLarge,
                            [styles.cardHeaderPrimaryText_light]: headerPrimaryTextLight,
                        })}
                    >
                        {headerPrimaryText}
                    </div>
                )}
                {buttonInInfoBlock ? button : infoBlock}
                {switchOptions && (
                    <div className={styles.switch}>
                        <TariffSwitch
                            dataQa={dataQa && 'tariffSwitch'}
                            checked={switchChecked}
                            name={`tariff_${tariffId}`}
                            onClick={onClickSwitch}
                            options={switchOptions}
                            isRebranding={isRebranding}
                        />
                    </div>
                )}
            </div>
            {!buttonInFooter && !buttonInInfoBlock && button}
            {featureListOptions && (
                <div
                    className={classNames(styles.featureList, {
                        [styles.featureList_white]: compact && !disable,
                        [styles.featureList_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                    })}
                >
                    <TariffFeatureList
                        accent={accentFeature}
                        dataQa={dataQa && 'featureList'}
                        showIcon
                        items={featureListOptions}
                        contentSize={compactFeatureList ? TariffCardContentSize.compact : contentSize}
                        isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        featuresSmallSize={featuresSmallSize}
                        isRebranding={isRebranding}
                    />
                </div>
            )}
            {footerFeatureListOptions && (
                <>
                    <div className={styles.delimiter} data-qa={dataQa && 'featureDelimiter'} />
                    <div className={styles.featureList}>
                        <TariffFeatureList
                            accent={accentFeature}
                            dataQa={dataQa && 'footerFeatureList'}
                            showIcon
                            contentSize={contentSize}
                            items={footerFeatureListOptions}
                            isRebranding={isRebranding}
                        />
                    </div>
                </>
            )}
            {footerText && <div className={styles.footerText}>{footerText}</div>}
            {buttonInFooter && (
                <>
                    <Spacing size={buttonInFooterGap} />
                    {hideButtonInMonthTariff ? (
                        <div
                            className={classNames(styles.onlyYearTariffWrapper, {
                                [styles.onlyYearTariffWrapperRebranding]: isRebrandingQuotaLanding,
                            })}
                        >
                            <Headline weight="2">Доступен в годовом плане</Headline>
                        </div>
                    ) : (
                        button
                    )}
                </>
            )}
            {/* tempexp_16800-end */}
            {buttonInInfoBlock && !buttonInFooter && <Spacing size={26} />}
            {/* tempexp_16800-end */}
        </div>
    );
};
