import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';

export enum SearchTooltipTypes {
    file_content_search = 'file_content_search',
    extension_filter = 'extension_filter',
    file_extension_search = 'file_extension_search',
    extension_setup_filter = 'extension_setup_filter',
}

const contentSearchRebranding = isRebranding
    ? {
          title: '',
          description: 'Находите нужное по словам в названии или в содержимом файла',
      }
    : {};

const contentSearchFilterRebranding = isRebranding
    ? {
          title: '',
          description: 'Используйте фильтр, чтобы найти файлы определённого типа',
      }
    : {};

export const searchTooltipData = {
    [SearchTooltipTypes.file_content_search]: {
        title: 'Поиск стал лучше',
        description: 'Находите нужное по словам, которые могут\nбыть не только в названии, но и внутри файлов',
        flag: 'new_search_by_content',
        ...contentSearchRebranding,
    },
    [SearchTooltipTypes.extension_filter]: {
        title: 'Для более точного поиска',
        description: 'Используйте фильтры, когда ищете файлы\nопределённого типа',
        flag: 'new_search_UI',
        ...contentSearchFilterRebranding,
    },
    [SearchTooltipTypes.file_extension_search]: {
        title: 'Поиск по форматам',
        description: 'Чтобы найти файлы определённого формата,\nнапишите расширение в строке поиска\n(например, docx, png)',
        flag: 'new_search_ifs',
    },
    [SearchTooltipTypes.extension_setup_filter]: {
        title: 'Настройка поиска',
        description: 'Используйте фильтры, чтобы искать нужные\n файлы по названию, содержанию, типу и формату',
        flag: 'new_search_ifs',
    },
} as const;
