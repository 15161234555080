import type { PayloadAction } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import escapeForHtml from 'lib/escapeForHtml';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderNewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal.helpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureABCreateFolderNewFlow } from 'reactApp/modules/features/features.selectors';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { waitForFolderBeingLoaded } from 'reactApp/modules/home/home.saga';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { createFolderSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { type ICreateFolderAction, type ICreateFolderSuccess, EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentFolder, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { call, put, select } from 'redux-saga/effects';

import { createFolderErrors } from '../modifying.constants';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export function createFolderRequest({
    folderName,
    parent,
    skipXhr = undefined,
    conflictMode = undefined,
    storage,
    share,
    gaPrefix,
}): Promise<{ folderName: string }> {
    return new Promise((resolve, reject) => {
        folderName = folderName.trim();

        if (!folderName) {
            return reject(createFolderErrors.getMessage('required'));
        }

        if (folderName.length > MAX_NAME_LENGTH) {
            return reject(createFolderErrors.getMessage('name_too_long'));
        }

        addFolderHelper({
            item: folderName,
            parent,
            storage,
            skipXhr,
            conflictMode,
            reason: EModifyReason.add,
        })
            .then((item: any) => {
                reduxStore.dispatch(
                    createFolderSuccess({
                        storage,
                        share,
                        folderName: item.id,
                        gaPrefix,
                    })
                );
                resolve({ folderName: item.id });
            })
            .catch((error) => {
                reject(
                    createFolderErrors.getMessage((error?.home && error?.home.error) || error, {
                        '{FOLDER_NAME}': escapeForHtml(folderName),
                    })
                );
            });
    });
}

export function* createNewFolder(action: ICreateFolderAction) {
    const { parentFolderId, showCreateButton, showShareButton = true, storage, gaPrefix, onCreateFolder, onClose = noopVoid } = action;

    const isCorpUser = yield select(UserSelectors.isCorpUser);
    const { isFull: isOverQuota } = yield select(UserSelectors.getCloudSpace);

    const currentFolder = yield select(getCurrentFolder);
    const hasParentsDmnFolder = yield select(hasParentsDomainFolder, currentFolder);
    const ignoreUserOverquota = isDomainFolder(currentFolder) || hasParentsDmnFolder || isCorpUser;

    if (isOverQuota && !ignoreUserOverquota) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFolder });
        return;
    }

    yield renderNewFolderModal(
        {
            createFolder: async (folderName: string, share: boolean) => {
                const { folderName: newFolder } = await createFolderRequest({
                    folderName,
                    share,
                    parent: parentFolderId,
                    storage,
                    gaPrefix,
                });

                if (newFolder) {
                    onCreateFolder?.(newFolder);
                }
            },
            showShareButton,
            showCreateButton,
        },
        onClose
    );
}

export function* handleCreateNewFolder(action: PayloadAction<ICreateFolderAction>) {
    yield createNewFolder(action.payload);
}

export function* handleCreateFolderSuccess(action: PayloadAction<ICreateFolderSuccess>) {
    const { folderName, share, storage = '' } = action.payload;
    const currentStorage = yield select(getCurrentStorage);
    const { isHome, isPublic, isStart, isAllDocuments } = getStorage(storage || currentStorage);
    // tempexp-14737-start
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);
    // tempexp-14737-end

    yield closePopupHelper(popupNames.CREATE_FOLDER);

    const folder = yield select(getHomeItemById, folderName);

    if (!folder) {
        return;
    }

    // tempexp-14737-next-line
    // На мобилке или если не включена фича - перекидываем пользователя в созданную папку.
    const destination = isPhone || !isNewFlowEnabled ? folder.id : folder.parent;

    // tempexp-14737-start
    const isExternal = !isPublic && !isStart && !isAllDocuments;
    const shouldChangeHistory = isNewFlowEnabled ? !isHome && isExternal : isExternal;

    if (shouldChangeHistory) {
        yield put(changeHomeHistory({ id: destination }));
        yield call(waitForFolderBeingLoaded, destination);
    }
    // tempexp-14737-end

    if (share && folder && folder.id !== '/') {
        publishHelper({
            item: folder,
            itemStorage: EStorageType.home,
            // tempexp-14737-next-line
            onClose: () => (isHome ? noop : opener(`/home/${encodeURIComponent(destination)}`, true)),
        });
    }
}
