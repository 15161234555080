import api from 'Cloud/Application/api';
import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'file/history';

class HistoryFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const getFileHistoryApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateFileV2ToV4.history) {
        return new HistoryFileAPIV4Call().makeRequest({ path: params.home }).then((response) => response.data);
    }

    return promisifyDeferredCall(api.file.history, params);
};
