import { createAction, createReducer } from '@reduxjs/toolkit';
import { ProductListAPICall } from 'reactApp/api/billing/ProductListAPICall';
import type { IProductsState } from 'reactApp/modules/products/products.types';

const INITIAL_STATE: IProductsState = {
    isLoaded: false,
    isLoading: false,
    hasError: false,
    list: {},
    selectedGroupName: '',
};

export const initProducts = createAction<{ onSuccess?(); onError?() } | undefined>('products/load/init');
export const initProductsForAnonymous = createAction('products/load/initForAnonymous');
export const loadProductsStart = createAction<{ setLoading: boolean }>('products/load/start');
export const loadProductsSuccess = createAction<any>('products/load/success');
export const loadProductsError = createAction('products/load/error');
export const updateProducts = createAction<{ onSuccess?(); onError?(); productId?: string } | undefined>('products/load/update');
export const selectProductsGroup = createAction<string>('products/selectGroup');

export const callProductsListApi = () => new ProductListAPICall().makeRequest();

export const productsReducer = createReducer(INITIAL_STATE, {
    [loadProductsStart.type]: (state, action: ReturnType<typeof loadProductsStart>) => ({
        ...state,
        isLoading: action.payload.setLoading,
    }),
    [loadProductsSuccess.type]: (state, action: ReturnType<typeof loadProductsSuccess>) => ({
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
    }),
    [loadProductsError.type]: (state) => ({
        ...state,
        isLoading: false,
        isLoaded: false,
        hasError: true,
    }),
    [selectProductsGroup.type]: (state, action: ReturnType<typeof selectProductsGroup>) => {
        state.selectedGroupName = action.payload;
    },
});
