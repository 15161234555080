import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { usePublicThemeId } from 'reactApp/ui/PublicThemePreview/hooks/usePublicThemeId';
import { ThemePreview } from 'reactApp/ui/PublicThemePreview/ThemePreview/ThemePreview';
import { sendXray } from 'reactApp/utils/ga';

import styles from './ToolbarPublicThemePreview.css';

interface PublicThemePreviewProps {
    publicId: string;
}

function PublicThemePreview({ publicId }: PublicThemePreviewProps) {
    const themeId = usePublicThemeId(publicId);
    return (
        <div className={styles.previewContainer}>
            {themeId ? (
                <>
                    Изменить оформление
                    <ThemePreview themeId={themeId} className={styles.previewInToolbar} />
                </>
            ) : (
                <>
                    Выбрать оформление
                    <div className={styles.promoNew}>новое</div>
                </>
            )}
        </div>
    );
}

function PublicThemeText({ publicId }: PublicThemePreviewProps) {
    const themeId = usePublicThemeId(publicId);
    return <>{themeId ? 'Изменить оформление' : 'Выбрать оформление'}</>;
}

export function ToolbarPublicThemePreview() {
    const folder = useSelector(getCurrentFolder);
    const publicId = folder && 'weblink' in folder ? folder.weblink : null;
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.TOOLBAR_ITEM_SHOW_BRAND_PAGE);
        sendXray('toolbar_item-show_brand_page');
    }, []);
    return publicId ? <PublicThemePreview publicId={publicId} /> : null;
}

export function ToolbarPublicThemeText() {
    const folder = useSelector(getCurrentFolder);
    const publicId = folder && 'weblink' in folder ? folder.weblink : null;
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.TOOLBAR_BRAND_PAGE_SHOW);
        sendXray('toolbar-brand-page-show');
    }, []);
    return publicId ? <PublicThemeText publicId={publicId} /> : null;
}
