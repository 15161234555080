import { WelcomeDialogAllDocuments } from 'reactApp/ui/WelcomeDialog/AllDocuments/WelcomeDialogAllDocuments';
import { WelcomeDialogAttachesNew } from 'reactApp/ui/WelcomeDialog/AttachesNew/WelcomeDialogAttachesNew';
import { WelcomeDialogAttachesRepeating } from 'reactApp/ui/WelcomeDialog/AttachesRepeating/WelcomeDialogAttachesRepeating';
import { WelcomeDialog } from 'reactApp/ui/WelcomeDialog/WelcomeDialog';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';

export const renderWelcomeDialog = renderModalDialog(WelcomeDialog, 'WelcomeDialog');
export const renderWelcomeRepeatingAttachesDialogHelper = renderModalDialog(WelcomeDialogAttachesRepeating, 'WelcomeAttachesRepeating');
export const renderWelcomeDialogNewAttaches = renderModalDialog(WelcomeDialogAttachesNew, 'WelcomeDialogAttachesNew');
export const renderWelcomeAllDocumentsDialog = renderModalDialog(WelcomeDialogAllDocuments, 'WelcomeAllDocumentsDialog');
