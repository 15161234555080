import { SubscriptionRenewAPICall } from 'reactApp/api/billing/subscription/SubscriptionRenewAPICall';
import { getBuyApiParams, getRequestId } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import type { RenewApiParams } from 'reactApp/modules/billing/billing.types';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { sendXray } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

export const subscriptionRenewAPICall = (params: RenewApiParams) => new SubscriptionRenewAPICall().makeRequest(params);

export function* handleRenewSubscription(action: ReturnType<typeof BillingActions.renewSubscription>) {
    const requestId = getRequestId();

    yield put(BillingActions.startLoading({ requestId }));

    const params = getBuyApiParams(action.payload);

    try {
        const { data } = yield* call(subscriptionRenewAPICall, {
            ...params,
            sub_id: action.payload.id,
        });

        yield put(BillingActions.setRenewLink({ link: data.pw, requestId }));

        sendXray(['blng', 'renew', 'link', 'success']);
    } catch (error) {
        sendXray(['blng', 'renew', 'link', 'error']);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error });
    }
}
