import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import React, { type ReactElement, memo, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './NoWarningDialog.css';
import { useCallbacks } from './NoWarningDialog.hooks';
import type { IProps } from './NoWarningDialog.types';
import { NoWarningDialogContent } from './NoWarningDialogContent';

const HOT_KEY_SCOPE_NAME = 'NoWarningDialog';

export const NoWarningDialog = memo(function NoWarningDialog(props: IProps): ReactElement {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const { isCheckboxSelected, handleAccept, handleChecboxClick, handleClose } = useCallbacks(props);
    const ref = useRef<HTMLDivElement>(null);

    const content = useMemo(
        () => (
            <NoWarningDialogContent
                {...props}
                isCheckboxSelected={isCheckboxSelected}
                handleAccept={handleAccept}
                handleChecboxClick={handleChecboxClick}
                handleClose={handleClose}
                isPhone={isPhone}
            />
        ),
        [handleAccept, handleChecboxClick, handleClose, isCheckboxSelected, isPhone, props]
    );

    useHotKeyScope(HOT_KEY_SCOPE_NAME);
    useEffect(() => {
        hotkeys('enter', HOT_KEY_SCOPE_NAME, handleAccept);
        return () => {
            hotkeys.unbind('enter', HOT_KEY_SCOPE_NAME, handleAccept);
        };
    }, [handleAccept]);

    if (isPhone) {
        return (
            <MobileDialog id={props.id} mod="confirm" topmost onClose={handleClose} scrollRef={ref}>
                <div className={styles.fullContent} ref={ref}>
                    {content}
                </div>
            </MobileDialog>
        );
    }

    return (
        <Dialog
            open
            size="small"
            onCancel={handleClose}
            id={props.id}
            className={classNames(styles.root, {
                [styles.rootResponsive]: ENABLE_FULL_RESPONSIVE,
            })}
        >
            <Content isModal isLoading={false} hasError={false} scrolling>
                {content}
            </Content>
        </Dialog>
    );
});
