import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, memo } from 'react';
import type { Config } from 'reactApp/ui/Empty/new/Empty.types';

import styles from './Empty.css';

export const EmptyComponent = memo(
    ({
        className,
        title,
        isMobile = false,
        description: Description,
        BottomContent,
        IconComponent,
    }: Config & { IconComponent?: FC; isMobile?: boolean; className?: string }) => {
        const description = typeof Description === 'function' && !React.isValidElement(Description) ? <Description /> : Description;

        return (
            <div
                className={classNames(styles.root, className, {
                    [styles.touch]: isMobile,
                })}
            >
                <div className={styles.container}>
                    {IconComponent && <IconComponent />}
                    <Spacing size={isMobile ? 60 : 24} />
                    <Text className={styles.title}>{title}</Text>
                    <Spacing size={12} />
                    <div className={styles.description}>{description}</div>
                    <Spacing size={24} />
                    {BottomContent && <BottomContent isMobile={isMobile} />}
                </div>
            </div>
        );
    }
);

EmptyComponent.displayName = 'EmptyComponent';
