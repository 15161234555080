import type { Config as XrayConfig, Xray } from '@mail/xray';
import { xray } from 'lib/xray';
import { EMBEDDED, GA_ID, IS_REACT_PROMO_QUOTA_PRO } from 'reactApp/appHelpers/configHelpers';
import { normalizeSymbols } from 'reactApp/utils/helpers';

function shorten(data, limit) {
    if (!limit || typeof limit !== 'number') {
        return data;
    }

    switch (true) {
        case !limit:
            return data;
        case typeof data === 'string':
            return data.slice(0, limit);
        case typeof data === 'object':
            return Object.keys(data).reduce(
                (previousValue, currentValue) => ({
                    ...previousValue,
                    [currentValue.slice(0, limit)]: data[currentValue],
                }),
                {}
            );
        default:
            return data;
    }
}

export interface IRadarOptions {
    // Принудительная отсылка очереди радара
    sendImmediately?: boolean;
    // Отсылать только ГА, радары не слать
    sendGaOnly?: boolean;
    sendToGa?: boolean;
}

/**
 *
 * @param eventCategory
 * @param action
 * @param label
 * @param iData: {[key: string]: number}. Example: {'ext':1, 'time': 1000}
 * @param options:boolean|IRadarOptions - принудительная отсылка очереди радара
 */
const sendGa = (
    eventCategory,
    action: string | null = '',
    label: string | null = '',
    iData: any = null,
    options: boolean | IRadarOptions = {}
) => {
    const names = [eventCategory];

    if (action) {
        names.push(action);
    }

    if (label) {
        names.push(label);
    }

    if (typeof options === 'boolean') {
        options = {
            sendImmediately: options,
        };
    } else if (!('sendToGa' in options)) {
        options.sendToGa = Boolean(GA_ID);
    }

    sendXray(names, iData, options);
};

/**
 *
 * @param eventCategory
 * @param action
 * @param label
 * @param dwhData: {[key: string]: number}. Example: {'ext':1, 'time': 1000}
 * @param sendImmediately:boolean - принудительная отсылка очереди радара
 */
const sendDwh = ({
    eventCategory,
    action = '',
    label = '',
    sendImmediately = false,
    dwhData = null,
}: {
    eventCategory?;
    action?;
    label?;
    sendImmediately?;
    dwhData?: any;
}) => {
    const name = shorten(normalizeSymbols(eventCategory), 64);

    const i: string[] = [];

    if (action) {
        i.push(`${normalizeSymbols(action)}`);
    }

    if (label) {
        i.push(`${normalizeSymbols(label)}`);
    }

    const params: any = { p: EMBEDDED ? 'vk_file_viewer' : IS_REACT_PROMO_QUOTA_PRO ? 'landing' : 'cloud-web-analytics' };

    if (i.length) {
        params.i = i.join('_');
    }

    if (dwhData) {
        params.dwh = dwhData;
    }

    if (sendImmediately) {
        xray.sendImmediately(name, params);
    } else {
        xray.send(name, params);
    }
};

function isExist<T>(item: T | undefined): item is T {
    return !!item;
}

/**
 * @param iData: {[key: string]: number}. Example: {'ext':1, 'time': 1000}
 */
const sendXray = (
    names: string | Array<string | undefined>,
    iData: Record<string, number> | string | null = null,
    options: IRadarOptions = {}
) => {
    let name = '';

    if (Array.isArray(names)) {
        name = names
            .filter(isExist)
            .map((item) => normalizeSymbols(item))
            .join('_');
    } else {
        name = normalizeSymbols(names);
    }

    name = shorten(name, 64);

    let params: any;

    if (iData) {
        params = {
            i: shorten(iData, 32),
        };
    }

    if (options?.sendGaOnly && options?.sendToGa) {
        // @ts-ignore
        gtag('event', names[1], {
            event_category: names[0],
            event_label: names[2],
        });

        return;
    }

    // t - длина не должна превышать 64 символа
    // i - длина строки/ключей в объекте не должна превышать 32 символа
    if (options.sendImmediately) {
        // @ts-ignore
        xray.sendImmediately(name, params, options.sendToGa);
    } else {
        // @ts-ignore
        xray.send(name, params, options.sendToGa);
    }
};

/**
 *
 * @param eventCategory
 */
const createGaSender =
    (eventCategory) =>
    (action, label: string | null = '', iData: any = null, options: boolean | IRadarOptions = {}) => {
        sendGa(eventCategory, action, label, iData, options);
    };

const createDwhSender =
    (eventCategory) =>
    ({
        action = '',
        label = '',
        sendImmediately = false,
        dwhData = null,
    }: {
        eventCategory?;
        action?;
        label?;
        sendImmediately?: boolean;
        dwhData?: any;
    }) => {
        sendDwh({ eventCategory, action, label, sendImmediately, dwhData });
    };

const sendKaktamLog = (message: Record<string, any>, fileName = 'cloud_common') => {
    xray.send('log', {
        rlog: fileName,
        rlog_message: message,
    });
};

const createXrayInstance = (config: XrayConfig): Xray => {
    const instance = xray.getInstanceCopy();
    instance.setConfig(config);
    return instance;
};

export { createGaSender, sendGa, sendDwh, createDwhSender, sendXray, sendKaktamLog, createXrayInstance };
