import classNames from 'clsx';
import { ReactComponent as PlayIcon } from 'mrg-icons/src/mailru/actions/play.svg';
import React, { memo, useCallback, useRef, useState } from 'react';
import type { Kind } from 'reactApp/types/Tree';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import type { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from '../DataListGalleryItem.css';

export const PicComponent = ({ ext, thumbUrl, kind }: { ext: string; thumbUrl: IThumb; kind: Kind }) => {
    const iconType = getIconType({ ext: ext.toLowerCase() });

    const startTime = useRef(Date.now());
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const handleThumbLoad = useCallback(() => {
        setLoaded(true);

        logHandler({
            radarName: `gallery_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, []);

    const handleThumbError = useCallback(() => {
        logHandler({
            radarName: `gallery_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, []);

    return (
        <div className={styles.container}>
            {!isLoaded && (
                <div className={styles.icon}>
                    <FileIcon size="l" type={iconType} />
                </div>
            )}
            <div
                className={classNames({
                    [styles.imageContainer]: true,
                    [styles.imageContainer_loaded]: isLoaded,
                })}
            >
                <ImageCancelable
                    className={classNames({
                        [styles.thumb]: true,
                        [styles.thumb_loaded]: isLoaded,
                    })}
                    draggable={false}
                    src={thumbUrl?.src}
                    srcSet={thumbUrl?.srcSet ? `${thumbUrl?.srcSet} 2x` : ''}
                    alt=""
                    onError={handleThumbError}
                    onLoad={handleThumbLoad}
                />
            </div>
            {isLoaded && kind === 'video' && (
                <div className={styles.play}>
                    <PlayIcon height={14} width={14} />
                </div>
            )}
        </div>
    );
};

export const Pic = memo(PicComponent);
