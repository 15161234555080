import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';

/** CLOUDWEB-17491: заголовок окна оплаты через кнопку "Отключить рекламу" */
export const noadsButtonPaymentTitle = getFeature('noads-btn-payment-title');

/** CLOUDWEB-17306: Автообновление страницы после покупки подписки */
export const autoreloadAfterSubscription: boolean = isFeature('autoreload-after-subscription');

/** CLOUDWEB-17306: Минимальное время через которое можно обновлять страницу после покупки */
export const autoreloadAfterSubscriptionTimeout: number = getFeature('autoreload-after-subscription-timeout') ?? 5000;
