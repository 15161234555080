import { type ButtonProps, Button, Headline } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';

import styles from './ReloadButton.css';

interface IProps extends ButtonProps {
    isMobile?: boolean;
}

export const ReloadButton: React.FC<IProps> = ({ isMobile = false, ...props }) => (
    <Button
        className={classNames(styles.button, { [styles.buttonMobile]: isMobile })}
        size="l"
        appearance="neutral"
        onClick={() => window.location.reload()}
        {...props}
    >
        <Headline level="1" weight="2">
            Обновить страницу
        </Headline>
    </Button>
);
