import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateWeblinksV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'weblinks/file/remove';

class RemoveFileWeblinkAPIV2Calll extends APICallV2Post {
    _method = method;
}

class RemoveFileWeblinkAPIV4Calll extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const removeFileWeblinksApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateWeblinksV2ToV4.fileRemove) {
        return new RemoveFileWeblinkAPIV4Calll().makeRequest({ ...params, weblink_id: params.weblink, file_path: params.home });
    }

    return new RemoveFileWeblinkAPIV2Calll().makeRequest(params);
};
