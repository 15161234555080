import { createAction, createReducer } from '@reduxjs/toolkit';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import type {
    EConflictResolution,
    IAddToUploadQueue,
    ICancelUploading,
    ISendDescriptorsToUIData,
} from 'reactApp/modules/uploading/uploading.types';

export const addToUploadQueue = createAction<IAddToUploadQueue>('uploading/addToUploadQueue');
export const processUploadQueue = createAction('uploading/processUploadQueue');
export const sendDescriptorsToUI = createAction<ISendDescriptorsToUIData>('uploading/sendDescriptorsToUI');
export const enableDropArea = createAction('uploading/enableDropArea');
export const disableDropArea = createAction('uploading/disableDropArea');
export const startFileUploading = createAction<UploadingDescriptor>('uploading/startFileUploading');
export const cancelUploading = createAction<ICancelUploading | undefined>('uploading/cancelUploading');
export const clearUploadedQueue = createAction('uploading/clearUploadedQueue');
export const cancelDescriptor = createAction<string>('uploading/cancelDescriptor');
export const saveDocument = createAction<{
    home: string;
    fileContent: Uint8Array;
    onResult(data: any);
    conflictResolution?: EConflictResolution;
}>('uploading/saveDocument');

export interface IUploadingState {
    disableDropArea: boolean;
}

const initialState: IUploadingState = {
    disableDropArea: false,
};

// Uploader модуль уже есть, используется для логики UI компонента аплоадера
// Потому этот называется uploading, используется для логики загрузки файлов
export const uploadingReducer = createReducer(initialState, {
    [addToUploadQueue.type]: (state) => {
        return state;
    },
    [enableDropArea.type]: (state) => {
        state.disableDropArea = false;
    },
    [disableDropArea.type]: (state) => {
        state.disableDropArea = true;
    },
});
