import { Icon16InfoOutline } from '@vkontakte/icons';
import React, { useCallback, useRef, useState } from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { MAIL_HOST } from 'reactApp/modules/attaches/attaches.constants';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './AttachesTooltip.css';

const CLEANER_LINK = getQuotaPromoUrl({ query: PaymentUTM.attachesTooltip, hash: 'cleaner' });

export const AttachesTooltip = () => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((value) => !value);
    };

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleCleanerClick = useCallback(() => {
        sendPaymentGa({ eventCategory: 'attaches-tooltip', action: 'cleaner-link', label: 'click' });
    }, []);

    if (IS_B2B_BIZ_USER) {
        return null;
    }

    return (
        <>
            <div className={styles.info} onClick={handleClick} ref={ref}>
                Быстрый доступ к вложениям из писем
                <Icon16InfoOutline className={styles.infoIcon} />
            </div>
            {isOpen && (
                <FloatingTooltip target={ref} placement={ETooltipPlacement.bottomStart} onClose={handleClose} qaId="tooltip-attaches">
                    <div className={styles.tooltipRoot}>
                        <div className={styles.tooltipContent}>
                            Исходные файлы хранятся в Почте, удалить их&nbsp;можно только вместе с письмом через раздел для{' '}
                            <a
                                className={styles.tooltipLink}
                                target="_blank"
                                href={CLEANER_LINK}
                                rel="noreferrer"
                                onClick={handleCleanerClick}
                            >
                                очистки хранилища
                            </a>{' '}
                            или в&nbsp;
                            <a className={styles.tooltipLink} target="_blank" href={`https://${MAIL_HOST}/inbox/`} rel="noreferrer">
                                Почте Mail.ru
                            </a>
                        </div>
                    </div>
                </FloatingTooltip>
            )}
        </>
    );
};
