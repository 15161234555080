import React, { type ReactElement, type RefObject, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { sendFamilyAnalyticsDropdowItem } from 'reactApp/modules/family/family.analytics';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { type ListItem, DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './FamilySettingsDropdown.css';
import { useDropdownList } from './useDropdownList';

interface Props {
    controlRef: RefObject<HTMLDivElement>;
    gaId: string;
    onDelete?: () => void;
    onLeave?: () => void;
    isMemberSettings?: boolean;
    onClose: () => void;
    clearSpace?: boolean;
}

export const FamilySettingsDropdown = memo(({ controlRef, gaId, onDelete, onLeave, isMemberSettings, clearSpace, onClose }: Props) => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const list = useDropdownList({
        onLeave,
        onDelete,
        isMemberSettings,
        clearSpace,
    });

    const findDropdownPosition = (dropdownWidth): { posX: number; posY: number } => {
        let posX = 0;
        let posY = 0;

        if (controlRef?.current) {
            const elRect = controlRef.current.getBoundingClientRect();

            posY = elRect.height + window.scrollY + elRect.top - 5;

            const canPositionRight = window.innerWidth - elRect.right > dropdownWidth;

            if (canPositionRight) {
                posX = elRect.left + window.scrollX + 10;
            } else {
                posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
            }
        }

        return { posX, posY };
    };

    const renderDropdownItem = (item: ListItem): ReactElement => {
        return <DropdownItemAction text={item.text} icon={item.icon} font={DropdownFont.VKSansDisplay} />;
    };

    const onMobileMenuClick = useCallback(
        (onClick) => () => {
            onClose();
            onClick();
        },
        [onClose]
    );

    useEffect(() => {
        list.forEach((item) => sendFamilyAnalyticsDropdowItem(item.id));
    }, []);

    if (isPhone) {
        const mobileTitle = <div className={styles.title}>Единое хранилище</div>;

        return (
            <MobileDialog title={mobileTitle} id="mobile-family-settings-menu" onClose={onClose} closeOnDimmerClick mod="base" topmost>
                <div className={styles.menu}>
                    {list.map((item) => (
                        <div className={styles.menuItem} key={item.id} onClick={onMobileMenuClick(item.onClick)} data-qa-id={item.id}>
                            <div className={styles.icon}>{item.iconMobile}</div>
                            <div className={styles.text}>{item.text}</div>
                        </div>
                    ))}
                </div>
            </MobileDialog>
        );
    }

    return (
        <DropdownList
            gaId={gaId}
            list={list}
            close={onClose}
            closeOnScroll
            closeOnResize
            closeOnMouseLeave
            parentRef={controlRef}
            renderItem={renderDropdownItem}
            theme={DropdownTheme.medium}
            calcPosition={findDropdownPosition}
            font={DropdownFont.VKSansDisplay}
        />
    );
});

FamilySettingsDropdown.displayName = 'FamilySettingsDropdown';
