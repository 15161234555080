import type { TemplateVariantString } from 'reactApp/modules/features/features.types';
import { sendGa } from 'reactApp/utils/ga';

type AsideModalGaAction = 'shw-aside-mdl' | 'cls-aside-mdl' | 'clck-aside-mdl';
type ReDownloadSnackGaAction = 'shw-redwnld-snack' | 'cls-redwnld-snack' | 'clck-redwnld-snack';
type ReDownloadTooltipGaAction = 'shw-redwnld-tltip' | 'cls-redwnld-tltip' | 'clck-redwnld-tltip';

export const sendGaHelper = (
    action: AsideModalGaAction | ReDownloadSnackGaAction | ReDownloadTooltipGaAction,
    variant?: TemplateVariantString,
    placement?: 'single' | 'folder'
): void => sendGa('rqird-auth-whn-dwnld', action, variant, { placement });
