import type { FC, ReactElement } from 'react';
import type { ActionPanelState } from 'reactApp/modules/actionpanel/actionpanel.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getActionPanelStore = (state: RootState): ActionPanelState => state.actionPanel;

const isOpened = createSelector(getActionPanelStore, (store: ActionPanelState): boolean => store.opened);
const activeComponent = createSelector(getActionPanelStore, (store: ActionPanelState): FC | null => store.component);
const headerText = createSelector(getActionPanelStore, (store: ActionPanelState): ReactElement | string => store.caption);

export const ActionPanelSelectors = {
    isActionPanelOpened: isOpened,
    activeComponent,
    headerText,
};
