import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { type EAccessRights, EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingNewAddUser } from 'reactApp/components/SharingWindow/SharingNew/AddUser/SharingNewAddUser';
import { SharingNewSection } from 'reactApp/components/SharingWindow/SharingNew/Section/SharingNewSection';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import {
    accessControlListLoadStart,
    accessControlListReset,
    deleteAllUsers,
    setAccessRight,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import {
    getACL,
    getError,
    getOwner,
    isAccessControlListLoading,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { UserList } from '../UserList/UserList';
import styles from './SharingNewSharing.css';
import { renderDeleteUsersDialog } from './SharingNewSharing.helpers';
import type { IProps } from './SharingNewSharing.types';

// eslint-disable-next-line max-lines-per-function
export const SharingNewSharing = React.memo(function SharingNewSharing(props: IProps): ReactElement | null {
    const dispatch = useDispatch();

    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);
    const acList = useSelector(getACL);
    const owner = useSelector(getOwner);
    const currentUserEmail = useSelector(UserSelectors.getEmail);
    const isLoading = useSelector(isAccessControlListLoading);
    const error = useSelector(getError);

    const storage = useSelector(getCurrentStorage);
    const { isSharedIncoming } = getStorage(storage);

    // Блок раскрыт если есть приглашенные пользователи и если пользователь пришел из блока "Настроить доступ"
    const isFromSharing = props.from === EFrom.SHARING;

    const [isCloudUsersOpened, setCloudUsersOpened] = useState<boolean>(isFromSharing);

    const isUserLoaded = isLoaded && !isUserError;
    const { item, onClose, isMounted: mounted, isPhone } = props;

    // FIXME: CLOUDWEB-13574. Костыль на случай если у монтированной папки не флага "mounted"
    const isMounted = mounted || currentUserEmail !== owner?.email;

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        dispatch(accessControlListLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, item?.storage]);

    useEffect(() => {
        if (!isUserLoaded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(loadUser).then(() => loadAllData());
        } else {
            loadAllData();
        }

        return function cleanup() {
            dispatch(accessControlListReset());
        };
    }, [dispatch, isUserLoaded, loadAllData]);

    useEffect(() => {
        if (isFromSharing) {
            return;
        }

        const needOpen = Boolean(acList.length);

        // Если в списке есть юзеры, а он скрыт - надо открыть. Для первого открытия со списком.
        if (!isCloudUsersOpened && needOpen && !isLoading) {
            setCloudUsersOpened(true);
        }
    }, [acList.length, isCloudUsersOpened, isFromSharing, isLoading]);

    const onActionClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('delete-all');
        dispatch(deleteAllUsers({ item }));
        setCloudUsersOpened(false);
    }, [dispatch, item]);

    const handleAddUser = useCallback(
        ({ currentEmail, accessRight }: { currentEmail: string; accessRight: EAccessRights }) => {
            dispatch(
                setAccessRight({
                    accessRight,
                    folderId: item?.id || '',
                    email: currentEmail,
                })
            );
            dispatch(contactsSlice.actions.listRequest());

            sendGa('add-user');
            sendPaymentGa({
                eventCategory: item?.weblink ? ECategoryGa.public : ECategoryGa.sharedFolder,
                action: 'created-sharing',
                public_id: item?.weblink,
            });
        },
        [dispatch, item?.id, item?.weblink]
    );

    const onChangeCloudUsers = useCallback(() => {
        // Если нет приглашенных пользователей - просто закрываем, без предупреждения
        if (!acList.length) {
            setCloudUsersOpened(!isCloudUsersOpened);
            return;
        }

        if (isCloudUsersOpened) {
            renderDeleteUsersDialog({ onActionClick });
            return;
        }

        setCloudUsersOpened(true);
    }, [acList.length, isCloudUsersOpened, onActionClick]);

    const List = () => {
        return (
            <UserList
                acList={acList}
                isLoading={isLoading}
                isMounted={isMounted}
                isPhone={isPhone}
                isSharedIncoming={isSharedIncoming}
                onClose={onClose}
                item={item}
                owner={owner}
            />
        );
    };

    if (isMounted) {
        return <List />;
    }

    return (
        <SharingNewSection
            onChange={onChangeCloudUsers}
            isOpened={isCloudUsersOpened}
            title="Пользователям Облака"
            name="cloud-users"
            className={styles.section}
            isPhone={isPhone}
        >
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <SharingNewAddUser
                    error={error}
                    isLoading={isLoading}
                    handleAddUser={handleAddUser}
                    isMounted={isMounted}
                    isPhone={isPhone}
                />
                <List />
            </div>
        </SharingNewSection>
    );
});
