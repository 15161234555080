import settings from 'Cloud/settings';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isRebrandingPromo } from 'reactApp/modules/features/features.helpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { closeRebrandingPromoModal, openRebrandingPromoModal } from 'reactApp/ui/RebrandingPromoModal/RebrandingPromoModal.helpers';
import { put } from 'redux-saga/effects';
import { ON_PREMISE } from 'server/constants/environment';
import { call, select } from 'typed-redux-saga';

const REBRANDING_PROMO_ID = 'rebranding-promo';
export function* initRebrandingPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isHome, isPublic } = getStorage(storage);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isWebview = yield* select(EnvironmentSelectors.isWebview);
    const promoShownCount = storeHelper.getValue(REBRANDING_PROMO_ID) || 0;
    const promoLastShownTime = storeHelper.getLastTime(REBRANDING_PROMO_ID) || 0;

    if (
        !isRebrandingPromo ||
        (!isHome && !isPublic) ||
        isPhone ||
        promoShownCount > 1 ||
        (promoLastShownTime && !isAfter(new Date(), addDays(promoLastShownTime, 1))) ||
        isWebview ||
        settings?.request?.action ||
        IS_AUTO_TEST_HIDE ||
        ON_PREMISE
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.rebranding,
            promoId: REBRANDING_PROMO_ID,
            onShow: () => {
                sendSplashScreenAnalytics({ action: ESplashScreenAction.show, name: 'rebranding', storage });
                store.dispatch(promoShown(EPromoType.rebranding));
            },
            onClose: () => {
                sendSplashScreenAnalytics({ action: ESplashScreenAction.close, name: 'rebranding', storage });
                storeHelper.markAsShown(REBRANDING_PROMO_ID, promoShownCount + 1);
                store.dispatch(removePromo(EPromoType.rebranding));
            },
            onDone: () => {
                sendSplashScreenAnalytics({ action: ESplashScreenAction.click, name: 'rebranding', storage });
                storeHelper.markAsShown(REBRANDING_PROMO_ID, 10);
                store.dispatch(removePromo(EPromoType.rebranding));
                closeRebrandingPromoModal();
            },
        })
    );
}

export function* showRebrandingPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.rebranding));

    if (!promo) {
        return;
    }

    promo.onShow();

    yield call(openRebrandingPromoModal, { onClose: promo.onClose, onDone: promo.onDone });
}
