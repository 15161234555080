import { useSelector } from 'react-redux';
import {
    HIDE_ADS,
    IS_B2B_BIZ_USER,
    IS_CHOSEN_PUBLIC_FILE,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_PHONE_BROWSER,
    PUBLIC_SHOVE,
} from 'reactApp/appHelpers/configHelpers';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';

export const useShowAd = () => {
    const isViewerActive = useSelector(ViewerSelectors.isViewerActive);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const storage = useSelector(getCurrentStorage);

    const isWelcomePromo = storage === EStorageType.home && isDefaultContent;

    const chosenPublicFile = IS_CHOSEN_PUBLIC_FILE && !IS_PHONE_BROWSER;
    return (
        !HIDE_ADS &&
        !PUBLIC_SHOVE &&
        !IS_MOBILE_OR_TABLET_BROWSER &&
        !IS_B2B_BIZ_USER &&
        !chosenPublicFile &&
        !isViewerActive &&
        !isWelcomePromo
    );
};
