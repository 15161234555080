import type { PayloadAction } from '@reduxjs/toolkit';
import Config from 'Cloud/config';
import { logger } from 'lib/logger';
import { parse } from 'qs';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { NewSearchApiCall } from 'reactApp/api/NewSearchApiCall';
import { SearchHistoryClearApiCall, SearchHistoryDeleteApiCall, SearchHistoryLoadApiCall } from 'reactApp/api/SearchHistory';
import { VirusScanAPICall } from 'reactApp/api/VirusScanAPICall';
import { IS_DOCUMENTS_DOMAIN, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isNewWebSearchHistoryEnabled, isTouchSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getSlicedSearch } from 'reactApp/modules/file/utils';
import { isThisOrParentMounted } from 'reactApp/modules/home/home.selectors';
import { getNewItem } from 'reactApp/modules/modifying/getNewItem';
import { renameItemSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { IRenameItemSuccess } from 'reactApp/modules/modifying/modifying.types';
import { clearQueue, initPromoController } from 'reactApp/modules/promo/promo.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import type { IHistoryPush } from 'reactApp/modules/router/router.types';
import {
    newSendSearchRadarAnalytics,
    sendSearchAnalytics,
    sendSearchStatistics,
    sendVirusScanStatistics,
} from 'reactApp/modules/search/search.analytics';
import {
    searchContentIndexed,
    searchFakeLoadStart,
    searchFakeLoadSuccess,
    searchFilterExactFolder,
    searchHistoryClear,
    searchHistoryRemoveItem,
    searchLoadError,
    searchLoadHistory,
    searchLoadHistoryError,
    searchLoadHistorySuccess,
    searchLoadInFolderRequest,
    searchLoadMoreRequest,
    searchLoadMoreSuccess,
    searchLoadRequest,
    searchLoadRequestStart,
    searchLoadStart,
    searchLoadSuccess,
    searchRequestParamsUpdate,
    searchUpdateDomainFolders,
    searchUpdateVirusScan,
} from 'reactApp/modules/search/search.module';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { checkViewerAtIdChange, openMobileViewer as openMobileViewerAction } from 'reactApp/modules/viewer/viewer.module';
import { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { decodeURIComponentHelper, getFilePathFromUrl } from 'reactApp/utils/urlHelper';
import { actionDecorator, closeAllPopups } from 'redux-popup';
import { all, call, cancel, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getDomainFoldersList } from '../domainFolders/domainFolders.saga';
import { SettingsSelectors } from '../settings/settings.selectors';
import { getStorage } from '../storage/storage.helpers';
import { changeSearchHistory } from './search.actions';
import { getAllDocumentsExts, getExtsBySearchType, SEARCH_PER_PAGE_LIMIT } from './search.helpers';
import {
    getListFullOrigin,
    getSearchContentStatus,
    getSearchItemById,
    getSearchList,
    getSearchLoadMoreData,
    getSearchRequestParams,
} from './search.selectors';
import {
    type ApiSearchResponceList,
    type NewSearchApiRequestParams,
    type SearchFile,
    type SearchHistoryGetApiRequestParams,
    type SearchLoadParams,
    type SearchRequestParams,
    EActionSearchHistory,
} from './search.types';

const MAX_NEW_RESULT_COUNT = 2000;
const SEARCH_HISTORY_LENGTH = 4;

const newSearchApiCall = (params: NewSearchApiRequestParams) => new NewSearchApiCall().makeRequest(params);
const searchHistoryGetApiCall = (params: SearchHistoryGetApiRequestParams) => new SearchHistoryLoadApiCall().makeRequest(params);
const searchHistoryDeleteApiCall = (params: SearchHistoryGetApiRequestParams) => new SearchHistoryDeleteApiCall().makeRequest(params);
const searchHistoryClearApiCall = () => new SearchHistoryClearApiCall().makeRequest();
const virusScanApiCall = ({ hash_list }) => new VirusScanAPICall().makeRequest({ hash_list });

/** Загружаем доменные папки, находим их в результатах поиска и помечаем. */
export function* searchModifyingDomainFolders() {
    try {
        const domainFolders = yield call(getDomainFoldersList);
        const domainFoldersIDs = domainFolders?.list?.map((item) => item.home) || [];

        if (domainFoldersIDs.length) {
            yield put(searchUpdateDomainFolders(domainFoldersIDs));
        }
    } catch (error) {
        logger.error(error);
    }
}

/** Проверяем результаты поиска на вирусы и измениям поля 'virus_scan', 'blocked' и 'illegal'. */
export function* searchModifyingVirusScan(results: ApiSearchResponceList, offset: number, total: number) {
    try {
        const hashList = results.reduce<string[]>((acc, cur) => {
            if ('hash' in cur && cur?.hash) {
                acc.push(cur?.hash);
            }
            return acc;
        }, []);

        let apiRequestCallDeltaT = performance.now();

        const { data, status } = yield virusScanApiCall({ hash_list: hashList });
        apiRequestCallDeltaT = performance.now() - apiRequestCallDeltaT;
        sendVirusScanStatistics(status, apiRequestCallDeltaT, offset, total);

        const virusScanResults = data?.body || {};

        if (Object.keys(virusScanResults).length) {
            yield put(searchUpdateVirusScan(virusScanResults));
        }
    } catch (error) {
        logger.error(error);
    }
}

function* loadSearch(action: PayloadAction<SearchLoadParams>) {
    try {
        const { queryParam, type, folder, isAlbumsSearch = false } = action?.payload || {};
        yield put(searchLoadRequest());

        const isPhone = yield select(EnvironmentSelectors.isPhone);
        const queryParams = yield select(SettingsSelectors.getQueryParams);
        const query = queryParam || queryParams?.query || '';
        const searchType = type || queryParams?.searchType || ESearchOptionType.all;
        const searchSource = queryParams.searchSource || ESearchOptionSource.all;
        const exts = IS_DOCUMENTS_DOMAIN ? getAllDocumentsExts(yield select) : getExtsBySearchType(searchType, isAlbumsSearch);
        const searchQuery = query.trim();
        const { path } = yield select(getSearchRequestParams);
        const { webSearchContentEnabled, touchSearchContentEnabled } = yield select(getSearchContentStatus);
        const searchPath = folder || path || ROOT_FOLDER_ID;

        const searchFolder = searchType === ESearchOptionType.folder;
        const searchOnlyName = isPhone ? !touchSearchContentEnabled : !webSearchContentEnabled;

        const params = {
            query: searchQuery,
            path: searchPath,
            new_srch: true,
            srch_src: searchOnlyName ? ESearchOptionSource.name : searchSource,
            limit: MAX_NEW_RESULT_COUNT,
        } as NewSearchApiRequestParams;

        if (exts) {
            params.exts = exts;
        }

        if (searchFolder) {
            params.filesrch_type = 'folder';
        }

        let apiRequestCallDeltaT = performance.now();

        const { status, headers, data } = yield newSearchApiCall(params);
        apiRequestCallDeltaT = performance.now() - apiRequestCallDeltaT;

        const results = data?.list?.map(itemV4ToV2) || [];

        if (!isAlbumsSearch) {
            sendSearchStatistics(status, searchQuery, searchType, apiRequestCallDeltaT, results.length || 0);
        }

        yield put(searchLoadStart(results || []));

        if (touchSearchContentEnabled) {
            const searchSection = parse(location.search).searchSection;
            yield put(searchFakeLoadStart({ searchSection }));
        }

        // В альбомах если поиск идет по папке - нужно показывать файлы из строка заданной папки, не учитывая подпапки.
        if (isAlbumsSearch && searchPath !== ROOT_FOLDER_ID) {
            yield put(searchFilterExactFolder({ id: searchPath }));
        }

        // Сохраняем параметры для дальнейшего использования в других местах приложения.
        const xPageId = Config.get('x-page-id') || 'empty_header';
        const xReqId = headers?.['x-req-id'] || 'empty_header';
        const requestParams: SearchRequestParams = { xPageId, xReqId, query: searchQuery, path: params.path };

        if (searchType !== ESearchOptionType.all) {
            requestParams.type = searchType;
        }

        if (searchSource !== ESearchOptionSource.all) {
            requestParams.source = searchSource;
        }

        yield put(searchRequestParamsUpdate(requestParams));

        const place = IS_PHONE_BROWSER ? 'touch' : 'web';

        if (!isAlbumsSearch) {
            sendSearchAnalytics(params, place, xPageId, xReqId, searchType);
        }

        if (results.length) {
            yield call(searchModifyingDomainFolders);
            // Сначала сканируем первые SEARCH_PER_PAGE_LIMIT файлов.
            const virusScanList = results.slice(0, SEARCH_PER_PAGE_LIMIT);
            yield call(searchModifyingVirusScan, virusScanList, 0, SEARCH_PER_PAGE_LIMIT);
        }
        yield put(searchLoadSuccess());

        const viewerItemId = decodeURIComponentHelper(getFilePathFromUrl(location?.pathname));

        if (viewerItemId && IS_PHONE_BROWSER) {
            yield put(openMobileViewerAction({ id: viewerItemId }));
        }
    } catch (error) {
        logger.error(error);
        yield put(searchLoadError());
    }
}

function* fakeLoadSearch(action: PayloadAction<SearchLoadParams>) {
    const { searchSection } = action?.payload || {};
    yield put(searchLoadRequest());

    const queryParams = yield select(SettingsSelectors.getQueryParams);
    const query = queryParams?.query || '';
    const searchType = queryParams?.searchType || ESearchOptionType.all;
    const extensions = getExtsBySearchType(searchType, false);
    const searchQuery = query.trim();
    const id = searchQuery || ROOT_FOLDER_ID;

    const searchFolder = searchType === ESearchOptionType.folder;

    if (!extensions && !searchFolder && id === ROOT_FOLDER_ID) {
        return yield put(searchLoadSuccess());
    }

    const origin = yield select(getListFullOrigin) || [];
    const filtered = searchSection ? origin[searchSection] : [...origin.text_content, ...origin.filename];

    yield put(searchFakeLoadSuccess({ origin, filtered, searchSection }));

    yield put(searchLoadSuccess());
}

function* loadMoreSearch() {
    try {
        const { offset, list } = yield select(getSearchLoadMoreData);
        const nextResultList = list?.slice(offset, offset + SEARCH_PER_PAGE_LIMIT) || [];
        yield call(searchModifyingVirusScan, nextResultList, offset, list.length);

        yield put(searchLoadMoreSuccess(nextResultList.length));
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

function* updateRenamedItems(id: string, newFolderId: string, oldFolderId: string) {
    const item: CloudItem = yield select((state) => getSearchItemById(state, id));

    if (!item) {
        return;
    }

    const newId = id.replace(oldFolderId, newFolderId);
    const isMounted: boolean = yield select(isThisOrParentMounted, item);
    const newItem: CloudItem = getNewItem({ item, newId, isMounted });

    yield put(renameItemSuccess({ newItem, oldId: item.id }));
}

// test
// -- test.jpg
// При переименовании папки test надо обновить test.jpg
function* handleRenameItems(action: PayloadAction<IRenameItemSuccess>) {
    const { newItem, oldId } = action.payload;

    const item = newItem as SearchFile;

    const storage = yield select(getCurrentStorage);
    const attachesSearchState = yield select(AttachesSelectors.getSearchState);
    const attachesQuery = attachesSearchState?.query;
    const { isSearch, isAttaches } = getStorage(storage);

    if (isSearch || (isAttaches && attachesQuery)) {
        const { xPageId, xReqId, query } = yield select(getSearchRequestParams);
        newSendSearchRadarAnalytics({
            eventCategory: ECategoryGa.search_results,
            action: 'rename',
            searchParams: {
                search_phrase: query,
                page_id: xPageId,
                req_id: xReqId,
            },
            items: [
                {
                    file_name: item.name,
                    file_id: item.id,
                    type: item.kind,
                    pos: item.pos,
                    placement: item.srchSrc,
                },
            ],
        });
    }

    if (!newItem.isFolder) {
        return;
    }

    const searchList: string[] | undefined = yield select(getSearchList);
    const idsToUpdate = searchList?.filter((item) => item?.indexOf(`${oldId}/`) === 0);

    if (!idsToUpdate?.length) {
        return;
    }

    yield all(idsToUpdate.map((id: string) => updateRenamedItems(id, newItem.id, oldId)));
}

function* changeSearchHistorySaga(action: PayloadAction<IHistoryPush>) {
    if (!IS_PHONE_BROWSER) {
        return;
    }

    const { id } = action.payload;

    let idFixed;
    try {
        idFixed = decodeURIComponent(getSlicedSearch(id));
    } catch (error) {
        idFixed = getSlicedSearch(id);
        logger.error(error);
    }
    const item = yield select(getSearchItemById, idFixed);

    yield put(checkViewerAtIdChange({ id: item?.id, storage: EStorageType.search }));
}

function* removeSearchHistoryItem({ payload }: PayloadAction<string>) {
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (isPhone ? !isTouchSearchHistoryEnabled : !isNewWebSearchHistoryEnabled) {
        return;
    }

    try {
        yield searchHistoryDeleteApiCall({ query: payload });
    } catch (error) {
        logger.error(error);
        yield put(searchLoadHistoryError());
    }
}

function* clearSearchHistory() {
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    if (isPhone ? !isTouchSearchHistoryEnabled : !isNewWebSearchHistoryEnabled) {
        return;
    }

    try {
        yield searchHistoryClearApiCall();
    } catch (error) {
        logger.error(error);
        yield put(searchLoadHistoryError());
    }
}

function* loadSearchHistory() {
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (isPhone ? !isTouchSearchHistoryEnabled : !isNewWebSearchHistoryEnabled) {
        return;
    }
    try {
        yield put(initPromoController({ disable: true }));
        yield put(actionDecorator()(closeAllPopups()));
        yield put(clearQueue());
        const {
            data: { history = [] },
            config: {
                headers: { ['X-Page-Id']: page_id, ['X-Req-Id']: req_id },
            },
        } = yield searchHistoryGetApiCall({ query: '' });
        const historyCount = history.slice(0, SEARCH_HISTORY_LENGTH);
        sendDwh({
            eventCategory: ECategoryGa.search_history,
            action: EActionSearchHistory.show,
            dwhData: {
                count_history: historyCount.length,
                page_id,
                req_id,
                place: isPhone ? 'touch' : 'web',
                history_results: historyCount.map((item) => item.text),
            },
        });

        yield put(searchLoadHistorySuccess({ history: historyCount, searchHistoryParams: { xPageId: page_id, xReqId: req_id } }));
    } catch (error) {
        logger.error(error);
        yield put(searchLoadHistoryError());
    }
}

function* loadSearchInFolder(action: PayloadAction<{ id: string }>) {
    const { id } = action.payload;
    const { query, type } = yield select(getSearchRequestParams);

    yield put(searchLoadRequestStart({ folder: id, queryParam: query, type, isAlbumsSearch: true }));
}

export function* initContentSearch() {
    const params = {
        // специальный айди который добавляется каждому пользователю прошедшему индексацию для поиска по содержанию
        query: 'rRyGrsrtCfgctAGFqGvAwiNNHZiltLdj',
        path: ROOT_FOLDER_ID,
        new_srch: true,
        srch_src: ESearchOptionSource.content,
        limit: MAX_NEW_RESULT_COUNT,
    } as NewSearchApiRequestParams;

    const { data } = yield newSearchApiCall(params);

    const results = data?.list?.map(itemV4ToV2) || [];

    yield put(searchContentIndexed(results.length > 0));
}

export function* watchSearch() {
    yield takeEvery(searchLoadRequestStart.toString(), loadSearch);
    yield takeEvery(searchFakeLoadStart.toString(), fakeLoadSearch);
    yield takeEvery(searchLoadMoreRequest.toString(), loadMoreSearch);
    yield takeEvery(renameItemSuccess.toString(), handleRenameItems);
    yield takeEvery(searchLoadHistory.toString(), loadSearchHistory);
    yield takeEvery(searchHistoryRemoveItem.toString(), removeSearchHistoryItem);
    yield takeEvery(searchHistoryClear.toString(), clearSearchHistory);
    yield takeLatest(changeSearchHistory.toString(), changeSearchHistorySaga);
    yield takeEvery(searchLoadInFolderRequest.toString(), loadSearchInFolder);
}
