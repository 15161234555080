import { keys } from 'ramda';
import type { IRange, Selected, State } from 'reactApp/modules/selections/selection.types';
import { createSelector } from 'reselect';

const getSelectionsStore = (state: any): State => state.selections;
const getSelected = createSelector(getSelectionsStore, (store): Selected => store.selected);
const getSelectedIdxs = createSelector(getSelected, keys);

const getLastSelected = (state: any): string => getSelectionsStore(state).lastSelectedIdx;
const getStorageSelected = (state: any) => getSelectionsStore(state).storage;
const isSelected = createSelector(
    getSelected,
    (state, id): string => id,
    (selected, id): boolean => Boolean(selected[id])
);

const getSelectedRange = createSelector(getSelectionsStore, (store): IRange | null => store.selectionRange);

const isDragging = createSelector(getSelectionsStore, (store): boolean => Boolean(store.isDragging));

const isSelecting = createSelector(getSelectionsStore, (state): boolean => Boolean(state.isSelecting));

const isAnyIndexSelected = createSelector(getSelectedIdxs, (ids) => Boolean(ids.length));

export const getScrollToItemState = createSelector(getSelectionsStore, (state) => state.scrollToItemState);

export const SelectionsSelectors = {
    getSelected,
    getSelectedIdxs,
    getStorageSelected,
    isSelected,
    getLastSelected,
    getSelectedRange,
    isDragging,
    isSelecting,
    isAnyIndexSelected,
    getScrollToItemState,
};
