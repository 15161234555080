import { bytesToNDigits, ONE_TB_SIZE_LIMIT, Spaces } from '@mail/cross-sizes-utils';
import { isNil, path, pathOr, propEq } from 'ramda';
import { IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { uploadFileSizeLimitMbAb } from 'reactApp/appHelpers/featuresHelpers';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getUserState = (state: RootState) => state.user;

export const getUserData = createSelector(getUserState, (state) => state.data);

export const getUserIsLoaded = createSelector(getUserState, (state) => state.isLoaded);
export const getUserIsLoading = createSelector(getUserState, (state) => state.isLoading);
export const getHasError = createSelector(getUserState, (state) => state.hasError);
export const getIsNewUser = createSelector(getUserState, (state) => state.isNewUser);

const getLogin = (state) => getUserData(state)?.login;
const isAnonymous = (state) => !getLogin(state);
const getDomain = (state) => getUserData(state)?.domain;
const isVerifiedAccount = (state) => pathOr(false, ['account_verified'], getUserData(state));
const isFrozen = (state) =>
    pathOr(false, ['cloud', 'frozen'], getUserData(state)) || pathOr(false, ['cloudflags', 'FROZEN'], getUserData(state));
const getBasicSpace = (state) => Number(pathOr(0, ['cloud', 'billing', 'basequota'], getUserData(state)));
const getTotalSpace = (state) => Number(pathOr(0, ['cloud', 'space', 'bytes_total'], getUserData(state)));
const getUsedSpace = (state) => Number(pathOr(0, ['cloud', 'space', 'bytes_used'], getUserData(state)));

// tempexp_17072-start
/** CLOUDWEB-17072 Отдельный селектор для размера аттачей, который не будет участвовать в экспе из CLOUDWEB-17018 */
const getAttachSizeLimit = (state) => Number(pathOr(0, ['cloud', 'file_size_limit'], getUserData(state)));
// tempexp_17072-end

const getUploadLimit = (state) => {
    const realLimit = Number(pathOr(0, ['cloud', 'file_size_limit'], getUserData(state)));

    if (uploadFileSizeLimitMbAb && !(isCorpUser(state) || isPaidUser(state) || isBizUser(state) || isProUser(state) || IS_FAMILY_USER)) {
        const fakeLimit = uploadFileSizeLimitMbAb * Spaces.ONE_MB;
        // Чтобы случайно не увеличить реальный лимит, берём минимальный
        return Math.min(realLimit, fakeLimit);
    }
    return realLimit;
};
const getOverquota = (state) => path<boolean>(['cloud', 'space', 'overquota'], getUserData(state));
const isOverQuota = ({ used, total, overquota }: { used: number; total: number; overquota?: boolean }) => {
    if (!used && !total) {
        return false;
    }

    if (!isNil(overquota)) {
        return overquota;
    }

    return used >= total;
};
const isOverQuotaUser = createSelector(getTotalSpace, getUsedSpace, getOverquota, (total, used, overquota) =>
    isOverQuota({ used, total, overquota })
);
const isBizUser = (state) => propEq('account_type', 'pdd', getUserData(state) || {}) && !isAnonymous(state);
const isOldBizUser = (state) => isBizUser(state) && propEq('account_version', 1, getUserData(state));
const isNewbie = (state) => propEq('newbie', true, getUserData(state)) && !isAnonymous(state);
const isPaidUser = (state: RootState) =>
    Boolean(path(['cloudflags', 'paid_account'], getUserData(state))) || Boolean(path(['cloudflags', 'PAID_ACCOUNT'], getUserData(state)));

const isB2BUser = (state) => isBizUser(state) && propEq('account_version', 2, getUserData(state));
const isCorpUser = (state) => !!(!isAnonymous(state) && path(['cloudflags', 'CORP'], getUserData(state)));

const isProUser = (state) => !!(!isAnonymous(state) && path(['cloudflags', 'PRO'], getUserData(state)));
const isUserFreeTb = (state) => !isNewbie(state) && !isCorpUser(state) && !isPaidUser(state) && getTotalSpace(state) >= ONE_TB_SIZE_LIMIT;

const isUserWithBilling = (state) => !!(path(['cloud', 'billing', 'enabled'], getUserData(state)) && !isFrozen(state));

const getCloudSpace = createSelector(getTotalSpace, getUsedSpace, getOverquota, (total, used, overquota) => {
    const remaining = bytesToNDigits(total - used, 3);
    const isFull = isOverQuota({ used, total, overquota });
    const free = Math.max(0, total - used);
    const over = Math.max(0, used - total);
    const usedPercent = Math.max(1, Math.round((used / total) * 100));
    const freePercent = 100 - usedPercent;

    return {
        total: bytesToNDigits(total, 3),
        used: bytesToNDigits(used, 3),
        free: bytesToNDigits(free, 3),
        over: bytesToNDigits(over, 3),
        freePercent,
        isFull,
        remaining,
        usedPercent,
        usedBytes: used,
    };
});

const isSocialUser = (state) => propEq('account_type', 'social', getUserData(state));

const getBasicSubscription = createSelector(getBasicSpace, (space) => bytesToNDigits(space, 3));

const getEmail = createSelector(getLogin, getDomain, (login, domain) => (login && domain ? `${login}@${domain}` : null));

const isHideAdInMail = (state) => pathOr(false, ['flags', 'hide_ad_in_mail_web'], getUserData(state));

const getBillingBitmask = (state) => getUserData(state)?.billing_bitmask;

export const UserSelectors = {
    getCloudSpace,

    isSocialUser,

    getUserData: (state) => ({
        ...getUserData(state),
        space: getCloudSpace(state),
        uploadLimit: getUploadLimit(state),
        // tempexp_17072-start
        attachSizeLimit: getAttachSizeLimit(state),
        // tempexp_17072-end
    }),
    getBasicSubscription,
    getEmail,

    getLifeCycleState: (state) => {
        return {
            isLoaded: getUserIsLoaded(state),
            isLoading: getUserIsLoading(state),
            hasError: getHasError(state),
        };
    },

    isNewUser: (state) => getIsNewUser(state),
    getLogin,
    getDomain,
    isBizUser,
    isVerifiedAccount,
    isFrozen,
    isNewbie,
    isPaidUser,
    isProUser,
    isB2BUser,
    isCorpUser,
    isOldBizUser,
    isUserFreeTb,
    isAnonymous,
    isOverQuotaUser,
    isUserWithBilling,
    isHideAdInMail,
    getBasicSpace,
    getBillingBitmask,
};
