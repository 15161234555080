import { xray } from 'lib/xray';
import { type DeleteFamilyQueryParams, DeleteFamilyAPICall } from 'reactApp/api/family/DeleteFamilyAPICall';
import { type DeleteFamilyUserQueryParams, DeleteFamilyUserAPICall } from 'reactApp/api/family/DeleteFamilyUserAPICall';
import { type removeFamilyMember, familyDelete, setLoading } from 'reactApp/modules/family/family.actions';
import { getCurrentFamily, getFamilyId, getFamilyMember } from 'reactApp/modules/family/family.selectors';
import { getFamilyData, getFamilyPlan } from 'reactApp/modules/family/sagas/initFamilyData.saga';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { openFamilyDeleteModal } from 'reactApp/sections/FamilyPage/FamilySettings/FamilySettings.helpers';
import { channel } from 'redux-saga';
import { cancel, put, select, take } from 'typed-redux-saga';

const deleteFamilyUserAPICall = (params: DeleteFamilyUserQueryParams) => new DeleteFamilyUserAPICall().makeRequest(params);
const deleteFamilyAPICall = (params: DeleteFamilyQueryParams) => new DeleteFamilyAPICall().makeRequest(params);

export function* removeFamilyMemberRequest(action: ReturnType<typeof removeFamilyMember>) {
    const { user, deleteAll } = action.payload;

    const deleteUserChannel = channel();

    openFamilyDeleteModal({
        user,
        deleteAll,
        onClose: () => deleteUserChannel.close(),
        onAction: () => deleteUserChannel.put(true),
    });

    yield take(deleteUserChannel);

    try {
        const currentFamilyId = yield* select(getFamilyId);
        const currentFamily = yield* select(getCurrentFamily);

        if (!currentFamily) {
            xray.send(`family_remove${deleteAll ? '' : '_user'}_failure_no-family`);
            yield loggerSaga({ error: 'remove-user-no-family' });
        }

        yield put(setLoading());

        if (deleteAll) {
            yield deleteFamilyAPICall({ family: currentFamilyId, remove_users: true });
            xray.send('family_remove_success');

            if (currentFamily.used !== currentFamily.user_used) {
                yield waitForUserUpdate();
            }

            yield getFamilyPlan();
            yield put(familyDelete());
            yield cancel();
        }

        const familyUser = yield* select(getFamilyMember, user);

        yield deleteFamilyUserAPICall({ email: user || '', family: currentFamilyId });
        xray.send('family_remove_user_success');

        // если пользователь в семье занимать 0 кб, то обновлять всю квоту не надо
        if (familyUser?.used) {
            yield waitForUserUpdate();
        }

        yield getFamilyData({ withQuota: true });
    } catch (error) {
        xray.send(`family_remove${deleteAll ? '' : '_user'}_failure`);
        yield put(
            showSnackbarAction({
                text: `Не удалось удалить ${deleteAll ? 'семью' : 'участника из семьи'}`,
                type: SnackbarTypes.failure,
                id: `remove-family${deleteAll ? '' : '-user'}-error`,
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }
}
