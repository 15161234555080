import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EConflictResolution, ERetryErrorFileOptions } from 'reactApp/modules/uploading/uploading.types';
import { askUserForConflict } from 'reactApp/modules/uploadList/oldUploadListBridge';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { channel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';

let channelAsk;

export function* handleFileExistsError(descriptor: UploadingDescriptor) {
    let retryResult: ERetryErrorFileOptions = ERetryErrorFileOptions.cancel;

    sendGaUploaderNew('file', 'file_exist');

    let option, saveForAll;
    if (descriptor.uploadingPacketConfig.storage === EStorageType.albums) {
        option = EConflictResolution.rename;
    } else if (
        descriptor.uploadingPacketConfig.conflictResolution !== EConflictResolution.skip &&
        descriptor.conflictResolution !== EConflictResolution.skip
    ) {
        if (!channelAsk) {
            channelAsk = channel();
        } else {
            return ERetryErrorFileOptions.shouldRetry;
        }
        askUserForConflict().then((result) => channelAsk.put(result));

        const { hasInvalidCharAutoFix, hasNameTooLongAutoFix } = descriptor;
        let errorFile = { status: EFileStatus.WARNING, error: EFileError.FILE_EXIST };

        if (hasInvalidCharAutoFix) {
            errorFile = { status: EFileStatus.INFO, error: EFileError.INVALID_CHARACTERS_AUTO_FIX };
        } else if (hasNameTooLongAutoFix) {
            errorFile = { status: EFileStatus.INFO, error: EFileError.NAME_TOO_LONG_AUTO_FIX };
        }

        yield put(
            updateUploadFilesAction({
                descriptorId: descriptor.id,
                cloudPath: descriptor.cloudPath,
                ...errorFile,
                hideError: false,
            })
        );

        const result = yield take(channelAsk);
        channelAsk.close();
        channelAsk = null;

        option = result.option as keyof typeof EConflictResolution;
        saveForAll = result.saveForAll;
    } else {
        option = EConflictResolution.skip;
    }

    if (saveForAll) {
        descriptor.uploadingPacketConfig.setConflictResolution(option);
    }

    if (option === EConflictResolution.rewrite || option === EConflictResolution.rename) {
        descriptor.setConflictResolution(option);

        sendGaUploaderNew('file', `${option}_file_exist`);

        retryResult = ERetryErrorFileOptions.shouldRetryImmediately;
    } else if (option === EConflictResolution.skip) {
        descriptor.setConflictResolution(option);
        sendGaUploaderNew('file', 'skip_file_exist');
        yield put(
            updateUploadFilesAction({
                descriptorId: descriptor.id,
                cloudPath: descriptor.cloudPath,
                status: EFileStatus.CANCEL,
                currentUpload: false,
                error: EFileError.FILE_EXIST,
                hideError: true,
            })
        );
    }

    return retryResult;
}
