/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import {
    Icon20ArrowLeftOutline,
    Icon20Cancel,
    Icon20Chain,
    Icon20CloudArrowUpOutline,
    Icon20CopyOutline,
    Icon20DeleteOutline,
    Icon20DocumentOutline,
    Icon20DownloadOutline,
    Icon20FolderSimpleArrowRightOutlune,
    Icon20FullscreenExitOutline,
    Icon20FullscreenOutline,
    Icon20Like,
    Icon20LikeOutline,
    Icon20MagnifierMinusOutline,
    Icon20MagnifierPlusOutline,
    Icon20MailOutline,
    Icon20More,
    Icon20PrinterOutline,
    Icon20Search,
    Icon20WriteOutline,
    Icon24ExternalLinkOutline,
} from '@vkontakte/icons';
import { throttle } from '@vkontakte/vkjs';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import browser from 'Cloud/browser';
import classNames from 'clsx';
import { ReactComponent as LogoCloud } from 'img/icons/logo_cloud.svg';
import { ReactComponent as ShapeIcon } from 'img/icons/shape.svg';
import { xray } from 'lib/xray';
import React, { type ReactElement, Component, createRef } from 'react';
import { connect } from 'react-redux';
import { downloadByUrl } from 'reactApp/appHelpers/appDownload';
import { getIsCloudAvailabe } from 'reactApp/appHelpers/appHelpers';
import {
    ENABLE_FULL_RESPONSIVE,
    IS_BIZ_USER,
    IS_ONPREMISE,
    IS_PUBLIC_FOLDER,
    IS_TABLET_BROWSER,
    IS_TARM,
} from 'reactApp/appHelpers/configHelpers';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    AttachesCloseButtonAB,
    attachesCloseButtonVersion,
    getViewerReturnToAllDocuments,
    isAttachSaveToAllDocuments,
} from 'reactApp/appHelpers/featuresHelpers';
import { attachesViewerHeaderJsAdaptivity } from 'reactApp/appHelpers/featuresHelpers/features/attachSidebarAd';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import {
    isFileAvailableForCreateEditableCopy,
    openCreateCopyOfNoneditableFileModal,
} from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { sendTechAnalytics, TechAnalyticsType } from 'reactApp/components/CreateCopyOfNoneditableFileModal/sendTechAnalytics';
import type { CreateCopyOfNonEditableFileModalProps } from 'reactApp/components/CreateCopyOfNoneditableFileModal/types';
import { watchMedia } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { type AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { isEditPublicCopy, isImageEditor } from 'reactApp/modules/features/features.helpers';
import { isImage, isPreviewable } from 'reactApp/modules/file/utils';
import type { HomeFile } from 'reactApp/modules/home/home.types';
import { editPdfCopy, editPublicCopy } from 'reactApp/modules/modifying/modifying.actions';
import { editAttachCopyNotification } from 'reactApp/modules/modifying/modifying.helpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { redirectFromViewerToFolder } from 'reactApp/modules/public/public.actions';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { renderWelcomeHelper } from 'reactApp/modules/welcome/utils';
import { store } from 'reactApp/store';
import { renderAuthDialog } from 'reactApp/ui/AuthDialog/authDialog.toolkit';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { type ListItem, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { Logo } from 'reactApp/ui/Header/Logo/Logo';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import { AttachInfo } from 'reactApp/ui/ReactViewer/AttachInfo/AttachInfo';
import { CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { type IViewerDwhData, openImageEditor } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { DocumentSearch } from 'reactApp/ui/ReactViewer/ViewerHeader/DocumentSearch/DocumentSearch';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';
import { FaceInfoTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/FaceInfoTooltip/FaceInfoTooltip';
import { MarketingPromoButton } from 'reactApp/ui/ReactViewer/ViewerHeader/MarketingPromoButton/MarketingPromoButton';
import { EViewerActions } from 'reactApp/ui/ReactViewer/ViewerHeader/ViewerHeader.analytics';
import { ViewerHeaderImageEditButton } from 'reactApp/ui/ReactViewer/ViewerHeader/ViewerHeaderImageEditButton';
import { IMAGE_EDITOR_ID } from 'reactApp/ui/ReactViewer/ViewerImage/components/ImageEditSurvey/ImageEditorSurvey';
import { RegisterCloudTooltip } from 'reactApp/ui/RegisterCloudTooltip/RegisterCloudTooltip';
import { showAuth } from 'reactApp/utils/auth';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { changeCloudToDocumentDomain } from 'reactApp/utils/documentsDomain';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { printPdfParts } from 'reactApp/utils/mergePdfParts';
import opener from 'reactApp/utils/opener';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { getSize } from 'reactApp/utils/sizeHelper';

import { ButtonType, calculateControlsState } from './adaptivity/attachesHeader';
import styles from './ViewerHeader.css';
import { ViewerHeaderButton } from './ViewerHeaderButton';
import buttonStyles from './ViewerHeaderButton.css';

interface Props {
    enableFavorites: boolean;
    isPublic: boolean;
    isStock: boolean;
    isAttaches?: boolean;
    isNewbie: boolean;
    isAuthorized: boolean;
    isPublicCopyEditable: boolean;
    isArchiveItem?: boolean;
    id?: string;
    item: CloudFile | AttachesItem | IStoryFileItem | null;
    itemStorage?: EStorageType | null;

    toggleFavorite();

    onClose();

    onDownload(unknown);

    onToggleFavorites(item: CloudFile | AttachesItem | IStoryFileItem | null);

    onPublish(unknown);

    onDelete(item: CloudFile | AttachesItem | IStoryFileItem | null);

    onPdfEdit(item: CloudFile | AttachesItem | IStoryFileItem | null);

    onCopy();

    onMove();

    onMail();

    onOpenInMyCloud();

    onClone();

    onOpenParent();

    onActive({ isActive }: { isActive: boolean });

    onFileClick(): void;

    sendViewerDwh?(data: IViewerDwhData): void;

    isFullScreen: boolean;
    showLogo?: boolean;
    embedded?: boolean;
    sharingAttach?: boolean;
    onFullscreen: () => void;
    isB2BBizUser?: boolean;
    isAttachesEnabled?: boolean;

    renderTopChildren?(isActive: boolean);

    topTitle?: string;
    topSubTitle?: string;
    isUnpublished?: boolean;
    facesCount?: number;

    onShowFaceFilter?();

    showFacesFilter?: boolean;
    disableClose?: boolean;
    showPdfEditButton?: boolean;
    /** Поле необходимое для показа кнопки редактирования на Ovidius V2 */
    showEditButton?: boolean;
    /** Поле необходимое для показа кнопок редактирования, увеличения и уменьшения зума */
    normalizedOvidiusEnabled?: boolean;
    /** Включен ли продукт в OnPrem продукт R7 */
    onpremR7ProductEnabled?: boolean;
    /** Колбэк для увеличения зума при просмотре Ovidius V2 */
    normalizedOvidiusIncreaseScaleClick?: () => void;
    /** Колбэк для уменьшения зума при просмотре Ovidius V2 */
    normalizedOvidiusDecreaseScaleClick?: () => void;
    contentForPrint?: ArrayBuffer[] | null;
    allowPrinting?: boolean;
    setSearchText: (text: string) => void;
    enableSearch: boolean;
    isViewerFileVerifiedByKaspersky: boolean;
    sendAuthDwh(action: EAuthDwhAction, source?: string);
    editingNoneEditableFormats?: CreateCopyOfNonEditableFileModalProps['editingNoneEditableFormats'];
}

interface State {
    isMoreOpen: boolean;
    isDownloadOpen: boolean;
    isPdfEditOpen: boolean;
    isButtonTextHidden: boolean;
    wrapToolbarButtonsOnSmallScreen: boolean;
    onPrintClick: boolean;
    expandSearch: boolean;
    isPromoButtonVisible: boolean;
    headerWidth: number;
}

const getLinkText = 'Получить ссылку';
const unpublishLinkText = 'Отключить ссылку';
const shareLink = 'Поделиться ссылкой';
const deleteFromFavorites = 'Убрать из избранного';
const addToFavorites = 'Добавить в избранное';
const SEND_BY_EMAIL = 'Отправить по почте';
const SAVE_INTO_CLOUD = 'Сохранить в Облако';
const DELETE_FILE = 'Удалить файл';
const PRINT_FILE = 'Распечатать';
const EDIT_FILE = 'Редактировать';
const EDIT_COPY = 'Редактировать копию';

const FULL_TOOLBAR_BR = 1090;
const UNWRAP_TOOLBAR_BR = 538;

const mapDispatchToProps = (dispatch) => ({
    sendAuthDwh: (action: EAuthDwhAction, source?: string): void => dispatch(sendAuthDwhRequest({ action, source })),
});

export class ViewerHeaderComponent extends Component<Props, State> {
    static defaultProps = {
        enableFavorites: false,
        isPublic: false,
        isStock: false,
        isAttaches: false,
        isAuthorized: false,
        isNewbie: true,
        isArchiveItem: false,
        embedded: false,
        sharingAttach: false,
        showLogo: false,
        item: null,
        isAttachesEnabled: true,
        id: '',
        onClose: noop,
        toggleFavorite: noop,
        onDownload: noop,
        onToggleFavorites: noop,
        onPublish: noop,
        onDelete: noop,
        onPdfEdit: noop,
        onActive: noop,
        onFullscreen: noop,
        onFileClick: noop,
        onOpenInMyCloud: noop,
        renderTopChildren: noop,
        onShowFaceFilter: noop,
        isFullScreen: false,
        facesCount: 0,
        showFacesFilter: false,
        disableClose: false,
        showPdfEditButton: false,
        contentForPrint: null,
        setSearchText: noop,
        enableSearch: false,
        isViewerFileVerifiedByKaspersky: false,
        sendAuthDwh: noop,
    };

    state = {
        isMoreOpen: false,
        isDownloadOpen: false,
        isPdfEditOpen: false,
        isButtonTextHidden: false,
        wrapToolbarButtonsOnSmallScreen: false,
        onPrintClick: false,
        expandSearch: false,
        isPromoButtonVisible: false,
        headerWidth: 0,
    };

    private containerEl: HTMLDivElement | null = null;
    private rightGroupRef = createRef<HTMLDivElement>();

    private buttonTextRef = createRef<HTMLDivElement>();
    private moreRef = createRef<HTMLDivElement>();
    private downloadRef = createRef<HTMLDivElement>();
    private pdfEditRef = createRef<HTMLDivElement>();
    private faceCounterRef = createRef<HTMLDivElement>();
    private cloneRef = createRef<HTMLDivElement>();

    private unsubscribeMediaWatchers: (() => void)[] = [];

    private headerSizeListener = throttle(this.updateHeaderWidth.bind(this), 500);

    componentDidMount(): void {
        const { item, showPdfEditButton } = this.props;

        this.unsubscribeMediaWatchers.push(watchMedia(FULL_TOOLBAR_BR, (hit) => this.handleMediaBreakpointHit(hit, FULL_TOOLBAR_BR)));
        this.unsubscribeMediaWatchers.push(watchMedia(UNWRAP_TOOLBAR_BR, (hit) => this.handleMediaBreakpointHit(hit, UNWRAP_TOOLBAR_BR)));
        window.addEventListener('resize', this.headerSizeListener);

        if (isEditPublicCopy) {
            sendDwh({
                eventCategory: ECategoryGa.viewer,
                action: EViewerActions.SHOW_EDIT,
                dwhData: {
                    size_file: item?.size,
                    extension: item?.ext,
                    id_media: item?.id,
                },
            });
        }

        if (showPdfEditButton) {
            sendDwh({
                eventCategory: ECategoryGa.pdfEditor,
                action: 'edit-view',
                dwhData: {
                    size_file: item?.size,
                    source: item?.storage,
                    id_media: item?.id,
                },
            });
        }
    }

    componentDidUpdate() {
        const { onPrintClick } = this.state;
        const { contentForPrint } = this.props;

        if (onPrintClick && contentForPrint) {
            this.setState({ onPrintClick: false });
            printPdfParts(contentForPrint);
        }

        /* Воткнул обновление сюда, так как при повторном открытии просмотрщика рефы есть, но
        они не находятся в HTML DOM (то есть если подниматься по parentNode вверх, то упрёшься в null).
        Они вне DOM и в ref коллбеках, и на componentDidMount. А раз они вне DOM, то нельзя взять offsetWidth
        Отследил проблему до usePortal('ReactViewer', ...) (в первый рендер
        портал вставляется в DOM ДО появления рефов, в последующие - ПОСЛЕ).
        Из-за этого при повторных рендерах не выйдет расчитать ширину хедера через коллбеки и componentDidMount.
        Грешу на мемоизацию ViewerRender, но копать глубже уже не в состоянии. The spice must flow */
        this.updateHeaderWidth();
    }

    componentWillUnmount(): void {
        this.unsubscribeMediaWatchers.forEach((unsubscribe) => unsubscribe());
        window.removeEventListener('resize', this.headerSizeListener);
    }

    private updateHeaderWidth() {
        // Просто на всякий случай, чтобы лишние ререндеры не вызывать
        if (!attachesViewerHeaderJsAdaptivity) {
            return;
        }

        if (!this.containerEl) {
            return;
        }

        const newWidth = this.containerEl.offsetWidth;
        if (this.state.headerWidth !== newWidth) {
            this.setState({
                headerWidth: newWidth,
            });
        }
    }

    private handleMediaBreakpointHit(hit: boolean, breakpoint: number) {
        switch (breakpoint) {
            case FULL_TOOLBAR_BR:
                this.setState({ isButtonTextHidden: !hit && ENABLE_FULL_RESPONSIVE });
                break;
            case UNWRAP_TOOLBAR_BR:
                this.setState({ wrapToolbarButtonsOnSmallScreen: !hit && ENABLE_FULL_RESPONSIVE });
                break;
        }
    }

    private compensateHorizontalOverflow(posX: number, dropdownWidth: number | undefined) {
        const bodyWidth = document.body.offsetWidth;
        if (dropdownWidth && posX + dropdownWidth > bodyWidth) {
            posX -= posX + dropdownWidth - bodyWidth;
        }
        return Math.max(0, posX);
    }

    private handleMarketingPromoButtonVisible = (isVisible: boolean) => {
        if (this.state.isPromoButtonVisible !== isVisible) {
            this.setState({ isPromoButtonVisible: isVisible });
        }
    };

    private onSearchClick = () => {
        xray.send('viewer_head_search_click');
        this.setState({ expandSearch: true });
    };

    private onSearchClose = () => {
        xray.send('viewer_head_search_close');
        this.setState({ expandSearch: false });
    };

    private calcDropdownPosition =
        (ref, xDisplacement = 0) =>
        (dropdownWidth?: number) => {
            let posX = 0;
            let posY = 0;
            let maxHeight;

            if (ref.current) {
                const elRect = ref.current.getBoundingClientRect();

                posY = elRect.top + elRect.height + window.scrollY + 4;
                posX = elRect.left + xDisplacement;
                posX = this.compensateHorizontalOverflow(posX, dropdownWidth);
                maxHeight = `${window.innerHeight - (posY - window.scrollY) - 8}px`;
            }

            return { posY, posX, maxHeight };
        };

    private handlePublish = (e?): void => {
        const { item, onPublish } = this.props;

        onPublish({ item, textRef: this.buttonTextRef, event: e });
    };

    private handleEditPdf = () => {
        const { item, onPdfEdit } = this.props;

        sendDwh({
            eventCategory: ECategoryGa.pdfEditor,
            action: 'edit-open',
            dwhData: {
                file_change: 'original',
                size_file: item?.size,
                id_media: item?.id,
                source: item?.storage,
            },
        });

        onPdfEdit(item);
    };

    private handleEditImage = () => {
        const { item: file, itemStorage } = this.props;

        openImageEditor({ file, itemStorage, place: 'toolbar' });
    };

    private handleDownload = (): void => {
        const { item, onDownload } = this.props;

        onDownload(item);
    };

    private handleAttachEdit = () => {
        const { item, itemStorage, isAuthorized, editingNoneEditableFormats, isAttaches, isNewbie, sendAuthDwh } = this.props;

        if (!isAuthorized) {
            sendAuthDwh(EAuthDwhAction.regAuth, 'edit-attace-copy');
            renderAuthDialog();
            return;
        }

        if (isNewbie && isAttaches) {
            renderWelcomeHelper({ storage: itemStorage as EStorageType, newDialog: true, source: 'edit-attace-copy' });
            return;
        }

        sendDwh({
            eventCategory: ECategoryGa.viewer,
            action: EViewerActions.CLICK_EDIT,
            dwhData: {
                size_file: item?.size,
                extension: item?.ext,
                id_media: item?.id,
            },
        });

        if (item && itemStorage && editingNoneEditableFormats) {
            sendTechAnalytics(item.ext, itemStorage)(TechAnalyticsType.clickOnEditButton);
            return openCreateCopyOfNoneditableFileModal({
                file: item as HomeFile,
                editingNoneEditableFormats,
            });
        }

        editAttachCopyNotification.setText(
            `Копия сохранена ${isAttachSaveToAllDocuments ? 'у вас в Документах' : 'в папку "Почтовые\u00A0вложения"'}`
        );
        openEditor(item as CloudFile);
    };

    private handleClone = (): void => {
        const { onClone, isAuthorized } = this.props;

        if (IS_ONPREMISE && !isAuthorized) {
            showAuth({});
            return;
        }
        onClone();
    };

    private handleEditPublicCopy = () => {
        const { isAuthorized, itemStorage, item: file, editingNoneEditableFormats } = this.props;
        const { ext = '', id = '', size = 0 } = file || {};
        const isNewbie = UserSelectors.isNewbie(store.getState());

        sendDwh({
            eventCategory: ECategoryGa.viewer,
            action: EViewerActions.CLICK_EDIT,
            dwhData: {
                size_file: size,
                extension: ext,
                id_media: id,
            },
        });

        if (!isAuthorized) {
            showAuth({});
            return;
        }

        if (isNewbie) {
            renderWelcomeHelper({
                storage: itemStorage as EStorageType,
                newDialog: true,
                source: 'edit-public-copy',
            });
            return;
        }

        if (file && itemStorage) {
            if (isFileAvailableForCreateEditableCopy(file) && editingNoneEditableFormats) {
                return openCreateCopyOfNoneditableFileModal({
                    file,
                    editingNoneEditableFormats,
                });
            }

            store.dispatch(
                editPublicCopy({
                    item: file as CloudFile,
                    storage: itemStorage,
                    source: 'edit-public-copy',
                })
            );
        }
    };

    private handleEdit = () => {
        const { item, itemStorage, isAuthorized, editingNoneEditableFormats } = this.props;
        if (!isAuthorized) {
            renderAuthDialog();
            return;
        }

        if (item && itemStorage && editingNoneEditableFormats) {
            sendTechAnalytics(item.ext, itemStorage)(TechAnalyticsType.clickOnEditButton);

            sendDwh({
                eventCategory: ECategoryGa.viewer,
                action: 'click-edit',
                dwhData: {
                    extension: item.ext,
                    size_file: item.size,
                    id_media: item.id,
                },
            });
            snackbarController.hideSnackbar(CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID);

            return openCreateCopyOfNoneditableFileModal({
                file: item as HomeFile,
                editingNoneEditableFormats,
            });
        }

        openEditor(item as CloudFile);
    };

    private handleEditPdfCopy = () => {
        const { isAuthorized, itemStorage, item: file, onPdfEdit } = this.props;
        const isNewbie = UserSelectors.isNewbie(store.getState());

        if (!isAuthorized) {
            showAuth({});
            return;
        }

        if (isNewbie) {
            renderWelcomeHelper({ storage: itemStorage as EStorageType, newDialog: true, source: 'edit-pdf-copy' });
            return;
        }

        if (file && itemStorage) {
            sendDwh({
                eventCategory: ECategoryGa.pdfEditor,
                action: 'edit-open',
                dwhData: {
                    file_change: 'copy',
                    size_file: file?.size,
                    id_media: file?.id,
                    source: file?.storage,
                },
            });

            store.dispatch(editPdfCopy({ item: file as CloudFile, onPdfEdit }));
        }
    };

    private handleOnToggleFavorites = (): void => {
        const { item, onToggleFavorites } = this.props;

        onToggleFavorites(item);
    };

    private handleDelete = () => {
        const { item, onDelete } = this.props;

        onDelete(item);
    };

    private handlePrint = () => {
        const { contentForPrint, sendViewerDwh } = this.props;

        sendViewerDwh?.({
            eventCategory: ECategoryGa.viewer,
            action: 'print',
            forceSend: true,
        });

        xray.send('viewer_head_print_click');

        if (!contentForPrint) {
            snackbarController.showSnackbar({
                type: SnackbarTypes.loading,
                text: 'Подготовка к печати...',
                id: 'printPreparation',
                closable: true,
            });
            this.setState({ onPrintClick: true });
            return;
        }

        printPdfParts(contentForPrint);
    };

    private handleClose = () => {
        const { onClose, item, isArchiveItem, isAttaches, sharingAttach, sendViewerDwh } = this.props;
        const queryParams = getQueryParams();

        if (sharingAttach && sendViewerDwh) {
            sendViewerDwh({ action: 'close', forceSend: true });
        }

        emitAnalyticEvent(AnalyticEventNames.VIEWER_CLOSE);

        /**
         * Вероятно я следующее поколение, ниже для документов аттачей, под фичей мы ведем на раздел документов
         * Сделано это именно здесь, что бы из-за принудительного return в ниже вызывающейся onClose ничего не сломать
         */
        if (isAttaches && item?.ext && getViewerReturnToAllDocuments.includes(item.ext) && !queryParams?.fromCloud && !IS_BIZ_USER) {
            const path = changeCloudToDocumentDomain(window.location.origin);
            return opener(`${path}?from=mail `, true);
        }

        /**
         * Здесь требуется пояснение для следющего поколения. Мы можем закрывать просмотрщик из двух позиций
         * 1. Файл открыть через [публичную] папку
         * 2. Файл открыт напрямую по ссылке
         * Во втором случае у айтема будет отсуствовать родитель, и это признак что нужно уйти в облако
         * Также, в случае если мы просматриваем архив, и выбираем там файл - то родителя тоже не будет,
         * но будет задан isArchiveItem, что символизирует, что нам не нужно уходить в рут. При этом если
         * мы будем смотреть все тоже самое по прямой ссылке, сработает обычный первый случай
         */
        if (!item?.parent && !isArchiveItem) {
            return open('/', '_self');
        }

        /**
         * CLOUDWEB-17185 - вызываем action, что бы при открытии папки, контент не грузился заново,
         * и позиционирование не сбрасывалось
         */
        if (item?.storage === EStorageType.public) {
            store.dispatch(redirectFromViewerToFolder({ redirectFromViewer: true }));
        }

        onClose();
    };

    private handleCloseMore = () => {
        this.props.onActive({ isActive: false });
        this.setState({ isMoreOpen: false });
    };

    private handleMoreClick = () => {
        this.setState((state) => {
            const newValue = !state.isMoreOpen;
            this.props.onActive({ isActive: newValue });
            return { isMoreOpen: newValue };
        });
    };

    private handleDownloadDropdownClick = () => {
        this.setState((state) => {
            const newValue = !state.isDownloadOpen;
            this.props.onActive({ isActive: newValue });
            return { isDownloadOpen: newValue };
        });
        this.props.onActive({ isActive: true });
    };

    private handleCloseDownload = () => {
        this.setState({ isDownloadOpen: false });
        this.props.onActive({ isActive: false });
    };

    private handlePdfEditDropdownClick = () => {
        const { isPdfEditOpen } = this.state;
        this.setState({ isPdfEditOpen: !isPdfEditOpen });
    };

    private renderDropdownItem = (item) => {
        return <DropdownItemAction active={false} text={item.text} icon={item.icon} />;
    };

    private isDownloadAvailable = (item): boolean => {
        return IS_ONPREMISE ? item.weblinkDownloadable || item.weblinkAccessRights !== 'r' : true;
    };

    private renderDownload = (item, isCollapsed?: boolean) => {
        const allowDownloadAsJpeg = downloadAsJpeg.isAvailable({
            ext: item.ext,
            itemType: item.type,
            storage: item.storage,
        });
        const { isDownloadOpen, isButtonTextHidden } = this.state;
        const {
            isPublic,
            isAuthorized,
            itemStorage,
            isAttaches,
            isArchiveItem,
            isUnpublished,
            isPublicCopyEditable,
            onpremR7ProductEnabled,
        } = this.props;

        const isEditable = item?.weblinkAccessRights === 'rw';
        const showEditPublicCopyBtn =
            (isEditPublicCopy || isEditable) &&
            isPublicCopyEditable &&
            Boolean(canCloneWeblink(isAuthorized, itemStorage, true)) &&
            !IS_BIZ_USER &&
            !(IS_TABLET_BROWSER || browser.isIpadOs());

        const editBtnText = isEditable ? EDIT_FILE : EDIT_COPY;

        const markButtonAsPrimary = !onpremR7ProductEnabled;

        if ((isArchiveItem && (item.isFolder || item.isEncrypted)) || isUnpublished) {
            return null;
        }

        const downloadBtnText = item.isFolder ? 'Скачать' : 'Скачать файл';

        return (
            <>
                <DownloadTooltip btnText={downloadBtnText} downloadRef={this.downloadRef?.current} />
                {showEditPublicCopyBtn && (
                    <ViewerHeaderButton
                        title={editBtnText}
                        hint={editBtnText}
                        icon={<Icon20WriteOutline />}
                        onClick={this.handleEditPublicCopy}
                        iconOnly={isButtonTextHidden}
                        primary={markButtonAsPrimary}
                        id="edit_public_copy"
                    />
                )}
                <div ref={this.downloadRef}>
                    <ViewerHeaderButton
                        title={downloadBtnText}
                        hint={downloadBtnText}
                        primary={!showEditPublicCopyBtn && isPublic && !isAttaches && !item.weblinkDownloadable && markButtonAsPrimary}
                        icon={<Icon20DownloadOutline />}
                        iconOnly={isCollapsed ?? isButtonTextHidden}
                        onClick={this.handleDownload}
                        onClickDropdown={this.handleDownloadDropdownClick}
                        isDropdownOpen={isDownloadOpen}
                        showDropDown={allowDownloadAsJpeg}
                        id="download"
                    />

                    {isDownloadOpen && (
                        <DropdownList
                            list={downloadAsJpeg.getDropdownItems({
                                itemOrId: item,
                                gaSuffix: 'viewer',
                                ext: item.ext,
                            })}
                            renderItem={this.renderDropdownItem}
                            close={this.handleCloseDownload}
                            posX={0}
                            posY={0}
                            theme={[DropdownTheme.jpeg, DropdownTheme.dark]}
                            parentRef={this.downloadRef}
                            calcPosition={this.calcDropdownPosition(this.downloadRef, 58)}
                            closeOnResize
                            closeOnScroll
                            title="Скачать в"
                            gaId="viewer-dnld"
                        />
                    )}
                </div>
            </>
        );
    };

    private getMoreItems = (isStory = false) => {
        const { isAttachesEnabled, itemStorage } = this.props;

        const showInFolder = {
            id: 'openinparent',
            text: 'Показать в папке',
            onClick: this.props.onOpenParent,
            icon: <Icon24ExternalLinkOutline />,
        };

        const copy = {
            id: 'copy',
            text: 'Копировать',
            onClick: this.props.onCopy,
            icon: <Icon20CopyOutline />,
        };

        const move = {
            id: 'move',
            text: 'Переместить в папку',
            onClick: this.props.onMove,
            icon: <Icon20FolderSimpleArrowRightOutlune />,
        };

        const download = {
            id: 'download',
            text: 'Скачать',
            onClick: this.handleDownload,
            icon: <Icon20DownloadOutline />,
        };

        const deleteItem = {
            id: 'delete',
            text: 'Удалить',
            onClick: this.handleDelete,
            icon: <Icon20DeleteOutline />,
        };

        const mail = {
            id: 'mail',
            text: SEND_BY_EMAIL,
            onClick: this.props.onMail,
            icon: <Icon20MailOutline />,
        };

        let moreItems: { id: string; text: string; onClick: () => any; icon: any }[] = [];

        if (!isIntegrationStorage(itemStorage)) {
            moreItems = [showInFolder];
        }

        moreItems.push(copy, move);

        if (isStory) {
            moreItems = [download, ...moreItems, deleteItem];
        }

        if (isAttachesEnabled && !isIntegrationStorage(itemStorage)) {
            moreItems.push(mail);
        }

        return moreItems;
    };

    private filloutPrivateToolbarMenu(
        menuItems: ListItem[],
        options: {
            enableFavorites: boolean;
            isCanDownload: boolean;
            isInFavorites: boolean;
            isPublished: boolean;
            isCanPrint: boolean;
        }
    ) {
        const { enableFavorites, isCanDownload, isInFavorites, isPublished, isCanPrint } = options;

        if (isCanDownload) {
            menuItems.push({
                icon: <Icon20Chain />,
                id: 'publish',
                onClick: () => this.handlePublish(),
                text: isPublished ? shareLink : getLinkText,
            });
        }
        if (enableFavorites) {
            menuItems.push({
                icon: isInFavorites ? (
                    <Icon20Like color="var(--vkui--color_icon_contrast)" />
                ) : (
                    <Icon20LikeOutline color="var(--vkui--color_icon_contrast)" />
                ),
                id: 'favorite',
                onClick: () => this.handleOnToggleFavorites(),
                text: isInFavorites ? deleteFromFavorites : addToFavorites,
            });
        }
        if (isCanPrint) {
            menuItems.push({
                icon: <Icon20PrinterOutline />,
                id: 'print',
                onClick: () => this.handlePrint(),
                text: PRINT_FILE,
            });
        }
        menuItems.push({
            icon: <Icon20DeleteOutline />,
            id: 'delete',
            onClick: () => this.handleDelete(),
            text: DELETE_FILE,
        });
    }

    private renderMainButtons = () => {
        const { item, showPdfEditButton, normalizedOvidiusEnabled, showEditButton } = this.props;
        const { isButtonTextHidden, wrapToolbarButtonsOnSmallScreen, isPdfEditOpen } = this.state;

        const isPublished = Boolean(item && 'weblink' in item && item.weblink);
        const isCanDownload = this.isDownloadAvailable(item);

        const btnText = item?.isFolder ? 'Скачать' : 'Скачать файл';
        const isEditButtonVisible = normalizedOvidiusEnabled ? showEditButton && normalizedOvidiusEnabled : showEditButton;

        const pdfEditButtons = [
            {
                text: 'Редактировать оригинал',
                id: 'editOriginal',
                onClick: (): void => {
                    this.handleEditPdf();
                },
                icon: <Icon20DocumentOutline />,
            },
            {
                text: 'Редактировать копию',
                id: 'editCopy',
                onClick: (): void => {
                    this.handleEditPdfCopy();
                },
                icon: <Icon20CopyOutline />,
            },
        ];

        return isEditButtonVisible ? (
            <>
                <ViewerHeaderButton
                    primary
                    title="Редактировать"
                    icon={<Icon20WriteOutline />}
                    iconOnly={isButtonTextHidden}
                    onClick={this.handleEdit}
                    id="edit"
                />
                {(!wrapToolbarButtonsOnSmallScreen || !isCanDownload) && (
                    <ViewerHeaderButton
                        title={isPublished ? shareLink : getLinkText}
                        forceText
                        icon={<Icon20Chain />}
                        iconOnly={isButtonTextHidden}
                        onClick={this.handlePublish}
                        textRef={this.buttonTextRef}
                        id="publish"
                    />
                )}
            </>
        ) : (
            <>
                <DownloadTooltip
                    btnText={btnText}
                    downloadRef={this.downloadRef?.current}
                    isSinglePublic
                    onShow={() => requiredAuthorizationHelpers.sendGa('shw-redwnld-tltip', 'variant1', 'single')}
                    onClick={() => requiredAuthorizationHelpers.sendGa('clck-redwnld-tltip', 'variant1', 'single')}
                    onClose={() => requiredAuthorizationHelpers.sendGa('cls-redwnld-tltip', 'variant1', 'single')}
                />
                {(!wrapToolbarButtonsOnSmallScreen || !isCanDownload) && (
                    <ViewerHeaderButton
                        title={isPublished ? shareLink : getLinkText}
                        icon={<Icon20Chain />}
                        iconOnly={isButtonTextHidden}
                        onClick={this.handlePublish}
                        textRef={this.buttonTextRef}
                        id="publish"
                        primary
                    />
                )}
                {showPdfEditButton && (
                    <div ref={this.pdfEditRef}>
                        <ViewerHeaderButton
                            title={'Редактировать'}
                            forceText={true}
                            hint={'Редактировать PDF'}
                            icon={<Icon20WriteOutline />}
                            iconOnly={isButtonTextHidden}
                            onClick={this.handlePdfEditDropdownClick}
                            isDropdownOpen={isPdfEditOpen}
                            id="edit"
                            isPdf
                        />
                        {isPdfEditOpen && (
                            <DropdownList
                                list={pdfEditButtons}
                                renderItem={this.renderDropdownItem}
                                close={this.handlePdfEditDropdownClick}
                                posX={0}
                                posY={0}
                                theme={DropdownTheme.dark}
                                parentRef={this.pdfEditRef}
                                calcPosition={this.calcDropdownPosition(this.pdfEditRef)}
                                closeOnResize
                                closeOnScroll
                                gaId="viewer-pdf-edit"
                            />
                        )}
                    </div>
                )}
            </>
        );
    };

    private renderPrivateToolbarItems = () => {
        const { item, isArchiveItem, enableFavorites, allowPrinting, normalizedOvidiusEnabled, itemStorage } = this.props;
        const { isMoreOpen, isButtonTextHidden, wrapToolbarButtonsOnSmallScreen } = this.state;

        const isInFavorites = Boolean(item && 'isInFavorites' in item && item.isInFavorites);
        const isPublished = Boolean(item && 'weblink' in item && item.weblink);

        const isCanDownload = this.isDownloadAvailable(item);
        const downloadBttn = this.renderDownload(item);
        const isCanPrint = allowPrinting || false;
        const isImageEditorEnabled = isImageEditor && isImage(item) && isPreviewable(item) && !storeHelper.getValue(IMAGE_EDITOR_ID);

        if (isArchiveItem) {
            return downloadBttn;
        }

        const menuItems: ListItem[] = [];

        if (wrapToolbarButtonsOnSmallScreen) {
            this.filloutPrivateToolbarMenu(menuItems, {
                enableFavorites,
                isCanDownload,
                isInFavorites,
                isPublished,
                isCanPrint,
            });
        }

        menuItems.push(...this.getMoreItems());

        return (
            <>
                {this.renderMainButtons()}
                {downloadBttn}
                {!wrapToolbarButtonsOnSmallScreen && enableFavorites && (
                    <ViewerHeaderButton
                        title={<div className={styles.button_fav}>{isInFavorites ? deleteFromFavorites : addToFavorites}</div>}
                        hint={isInFavorites ? deleteFromFavorites : addToFavorites}
                        icon={
                            isInFavorites ? (
                                <Icon20Like color="var(--vkui--color_icon_contrast)" />
                            ) : (
                                <Icon20LikeOutline color="var(--vkui--color_icon_contrast)" />
                            )
                        }
                        iconOnly={isButtonTextHidden}
                        onClick={this.handleOnToggleFavorites}
                        id="favorite"
                    />
                )}
                <div className={styles.iconsGroup}>
                    {!wrapToolbarButtonsOnSmallScreen && isImageEditorEnabled && (
                        <ViewerHeaderImageEditButton onClick={this.handleEditImage} item={item} itemStorage={itemStorage} />
                    )}
                    {!wrapToolbarButtonsOnSmallScreen && isCanPrint && (
                        <ViewerHeaderButton
                            title=""
                            icon={<Icon20PrinterOutline />}
                            onClick={this.handlePrint}
                            hint={PRINT_FILE}
                            isFilled={false}
                            id="print"
                        />
                    )}
                    {!wrapToolbarButtonsOnSmallScreen && (
                        <ViewerHeaderButton
                            title=""
                            icon={<Icon20DeleteOutline />}
                            onClick={this.handleDelete}
                            hint={DELETE_FILE}
                            isFilled={false}
                            id="delete"
                        />
                    )}
                    <div ref={this.moreRef}>
                        <ViewerHeaderButton
                            title=""
                            icon={<Icon20More />}
                            onClick={this.handleMoreClick}
                            hint="Дополнительные действия"
                            id="more"
                        />
                        {isMoreOpen && (
                            <DropdownList
                                list={menuItems}
                                renderItem={this.renderDropdownItem}
                                close={this.handleCloseMore}
                                theme={DropdownTheme.dark}
                                parentRef={this.moreRef}
                                calcPosition={this.calcDropdownPosition(this.moreRef, 2)}
                                closeOnResize
                                gaId="viewer-more"
                            />
                        )}
                    </div>
                    {normalizedOvidiusEnabled && (
                        <>
                            <div className={buttonStyles.separator} />
                            <ViewerHeaderButton
                                title="Уменьшить"
                                icon={<Icon20MagnifierMinusOutline />}
                                onClick={this.props.normalizedOvidiusDecreaseScaleClick}
                                hint="Уменьшить"
                                id="decrease"
                            />
                            <ViewerHeaderButton
                                title="Увеличить"
                                icon={<Icon20MagnifierPlusOutline />}
                                onClick={this.props.normalizedOvidiusIncreaseScaleClick}
                                hint="Увеличить"
                                id="increase"
                            />
                        </>
                    )}
                </div>
            </>
        );
    };

    private renderStoryToolbarItems = () => {
        const { item, enableFavorites } = this.props;
        const { isMoreOpen, isButtonTextHidden } = this.state;

        const isInFavorites = Boolean(item && 'isInFavorites' in item && item.isInFavorites);
        const isPublished = Boolean(item && 'weblink' in item && item.weblink);

        return (
            <>
                <ViewerHeaderButton
                    title={isPublished ? shareLink : getLinkText}
                    hint={isPublished ? shareLink : getLinkText}
                    icon={<Icon20Chain />}
                    iconOnly={isButtonTextHidden}
                    onClick={this.handlePublish}
                    textRef={this.buttonTextRef}
                    id="publish"
                    primary
                />
                {enableFavorites && (
                    <ViewerHeaderButton
                        title={<div className={styles.button_fav}>{isInFavorites ? deleteFromFavorites : addToFavorites}</div>}
                        hint={isInFavorites ? deleteFromFavorites : addToFavorites}
                        icon={
                            isInFavorites ? (
                                <Icon20Like color="var(--vkui--color_icon_contrast)" />
                            ) : (
                                <Icon20LikeOutline color="var(--vkui--color_icon_contrast)" />
                            )
                        }
                        iconOnly={isButtonTextHidden}
                        onClick={this.handleOnToggleFavorites}
                        id="favorite"
                    />
                )}
                <div className={styles.iconsGroup}>
                    <div ref={this.moreRef}>
                        <ViewerHeaderButton
                            title=""
                            icon={<Icon20More />}
                            onClick={this.handleMoreClick}
                            hint="Дополнительные действия"
                            id="more"
                        />
                        {isMoreOpen && (
                            <DropdownList
                                list={this.getMoreItems(true)}
                                renderItem={this.renderDropdownItem}
                                close={this.handleCloseMore}
                                posX={0}
                                posY={0}
                                theme={DropdownTheme.dark}
                                parentRef={this.moreRef}
                                calcPosition={this.calcDropdownPosition(this.moreRef, 2)}
                                closeOnResize
                                gaId="viewer-more"
                            />
                        )}
                    </div>
                </div>
            </>
        );
    };

    private renderStockToolbarItems = () => {
        const { item, isAuthorized, isArchiveItem, itemStorage } = this.props;
        const { isButtonTextHidden } = this.state;
        const showCloneExcludeArchive = Boolean(canCloneWeblink(isAuthorized, itemStorage, true)) && !isArchiveItem;

        return (
            <>
                <>
                    {!isArchiveItem && (
                        <ViewerHeaderButton
                            title={shareLink}
                            hint={shareLink}
                            icon={<Icon20Chain />}
                            iconOnly={isButtonTextHidden}
                            onClick={this.handlePublish}
                            textRef={this.buttonTextRef}
                            id="publish"
                            primary
                        />
                    )}
                    {showCloneExcludeArchive && (
                        <ViewerHeaderButton
                            title={SAVE_INTO_CLOUD}
                            hint={SAVE_INTO_CLOUD}
                            icon={<Icon20CloudArrowUpOutline />}
                            iconOnly={isButtonTextHidden}
                            onClick={this.handleClone}
                            id="clone"
                            collapsableText
                        />
                    )}
                </>
                {this.renderDownload(item)}
            </>
        );
    };

    private renderPublicToolbarItems = (showSendViaMail: boolean, showLink: boolean) => {
        const {
            item,
            isAuthorized,
            isArchiveItem,
            embedded,
            isFullScreen,
            onFullscreen,
            isAttachesEnabled,
            isUnpublished,
            facesCount = 0,
            onShowFaceFilter,
            itemStorage,
            isStock,
            disableClose,
            normalizedOvidiusEnabled,
        } = this.props;
        let isTempAttachType = false;
        if (item && 'attachType' in item) {
            isTempAttachType = item.attachType === EAttachTypes.temporary;
        }
        const showClone = !item?.weblinkDownloadable && Boolean(canCloneWeblink(isAuthorized, itemStorage, true)) && !isTempAttachType;
        const showOpenInCloud = !showClone && !item?.weblinkDownloadable && embedded && getIsCloudAvailabe();
        const showCopyLink = canCloneWeblink(isAuthorized, itemStorage);
        const isImageEditorEnabled = isImageEditor && isImage(item) && isPreviewable(item) && !storeHelper.getValue(IMAGE_EDITOR_ID);
        const isNotAuthTarm = IS_TARM && !isAuthorized;
        const showCloneExcludeArchive = showClone && !isArchiveItem && !isNotAuthTarm;
        const { isButtonTextHidden } = this.state;

        return (
            <>
                {this.renderDownload(item)}
                {showCloneExcludeArchive && (
                    <>
                        <ViewerHeaderButton
                            title={SAVE_INTO_CLOUD}
                            hint={SAVE_INTO_CLOUD}
                            icon={<Icon20CloudArrowUpOutline />}
                            iconOnly={isButtonTextHidden}
                            onClick={this.handleClone}
                            id="clone"
                            collapsableText
                            textRef={this.cloneRef}
                        />
                        {/* Временно выключил на сток-файлах. Включить после перевода стоков на новый SSR - CLOUDWEB-14426 */}
                        {!isStock && <RegisterCloudTooltip targetRef={this.cloneRef} closeOnScroll={true} />}
                    </>
                )}
                {showOpenInCloud && (
                    <ViewerHeaderButton
                        title={'Открыть в моём Облаке'}
                        hint={'Открыть в моём Облаке'}
                        icon={<Icon24ExternalLinkOutline />}
                        iconOnly={isButtonTextHidden}
                        onClick={this.props.onOpenInMyCloud}
                        id="openinmy"
                        collapsableText
                    />
                )}
                {isAttachesEnabled && showSendViaMail && !isArchiveItem && (
                    <ViewerHeaderButton
                        title={SEND_BY_EMAIL}
                        hint={SEND_BY_EMAIL}
                        icon={<Icon20MailOutline data-qa-id="mail" />}
                        iconOnly={isButtonTextHidden}
                        onClick={this.props.onMail}
                        id="mail"
                        collapsableText
                    />
                )}
                {showLink && !item?.weblinkDownloadable && !isArchiveItem && !isUnpublished && (
                    <ViewerHeaderButton
                        title={showCopyLink ? getLinkText : unpublishLinkText}
                        hint={showCopyLink ? getLinkText : unpublishLinkText}
                        icon={<Icon20Chain />}
                        iconOnly={isButtonTextHidden}
                        onClick={this.handlePublish}
                        textRef={this.buttonTextRef}
                        id="publish"
                        collapsableText
                    />
                )}
                {isImageEditorEnabled && (
                    <ViewerHeaderImageEditButton onClick={this.handleEditImage} item={item} itemStorage={itemStorage} />
                )}
                {normalizedOvidiusEnabled && (
                    <>
                        <div className={buttonStyles.separator} />
                        <ViewerHeaderButton
                            title="Уменьшить"
                            icon={<Icon20MagnifierMinusOutline />}
                            onClick={this.props.normalizedOvidiusDecreaseScaleClick}
                            hint="Уменьшить"
                            id="decrease"
                        />
                        <ViewerHeaderButton
                            title="Увеличить"
                            icon={<Icon20MagnifierPlusOutline />}
                            onClick={this.props.normalizedOvidiusIncreaseScaleClick}
                            hint="Увеличить"
                            id="increase"
                        />
                    </>
                )}
                {facesCount > 0 && (
                    <>
                        <ViewerHeaderButton
                            title=""
                            hint="Показать фильтр по лицам"
                            textOnIcon={facesCount?.toString()}
                            icon={<ShapeIcon />}
                            onClick={onShowFaceFilter}
                            textRef={this.faceCounterRef}
                            id="showFaces"
                            iconOnly
                        />
                        {Boolean(this.faceCounterRef?.current) && (
                            <FaceInfoTooltip onApply={onShowFaceFilter} targetRef={this.faceCounterRef} />
                        )}
                    </>
                )}
                {embedded && (
                    <ViewerHeaderButton
                        title=""
                        icon={
                            isFullScreen ? (
                                <Icon20FullscreenExitOutline width={16} height={16} />
                            ) : (
                                <Icon20FullscreenOutline width={16} height={16} />
                            )
                        }
                        onClick={onFullscreen}
                        hint={isFullScreen ? 'Выйти из полноэкранного режима' : 'Полноэкранный режим'}
                        id="fullscreen"
                    />
                )}
                {!disableClose && embedded && (
                    <Hint text="Закрыть" theme="dark" showDelay>
                        <div className={styles.closeIcon} onClick={this.handleClose}>
                            <Icon20Cancel />
                        </div>
                    </Hint>
                )}
            </>
        );
    };

    private renderAttachToolbarItems = () => {
        const {
            item,
            isAuthorized,
            isArchiveItem,
            itemStorage,
            allowPrinting,
            showPdfEditButton,
            normalizedOvidiusEnabled,
            showEditButton,
        } = this.props;
        const { isButtonTextHidden, isPromoButtonVisible, headerWidth } = this.state;
        const showClone = Boolean(canCloneWeblink(isAuthorized, itemStorage));
        const attachType = (item as AttachesItem)?.attachType;
        const isTemporary = attachType === EAttachTypes.temporary;
        const isCloudAttach = attachType === EAttachTypes.cloud;
        const showAttachEditButton = showEditButton && !isTemporary && !isCloudAttach && !IS_BIZ_USER;
        const isImageEditorEnabled = isImageEditor && isImage(item) && isPreviewable(item) && !storeHelper.getValue(IMAGE_EDITOR_ID);
        const isShowCloneAndShare = showClone && !isArchiveItem && !isTemporary && !isCloudAttach;

        const buttonsConfig = calculateControlsState({
            headerWidth,
            isPromoButtonVisible,
            isRightGroupVisible: !!this.rightGroupRef.current,

            isEditButtonVisible: !!showAttachEditButton,
            isCloneButtonVisible: isShowCloneAndShare,
            isShareButtonVisible: isShowCloneAndShare,
            isEditImageButtonVisible: isImageEditorEnabled,
            isPrintButtonVisible: !!allowPrinting,
            isOvidius: !!normalizedOvidiusEnabled,
            isPdf: !!showPdfEditButton,
        });

        // eslint-disable-next-line max-lines
        return (
            <>
                {showAttachEditButton && (
                    <>
                        <ViewerHeaderButton
                            primary
                            title={EDIT_COPY}
                            icon={<Icon20WriteOutline />}
                            iconOnly={attachesViewerHeaderJsAdaptivity ? buttonsConfig[ButtonType.Edit].isCollapsed : isButtonTextHidden}
                            onClick={this.handleAttachEdit}
                            id="edit"
                        />
                    </>
                )}
                {isShowCloneAndShare && (
                    <>
                        <ViewerHeaderButton
                            title={shareLink}
                            hint={shareLink}
                            icon={<Icon20Chain />}
                            iconOnly={attachesViewerHeaderJsAdaptivity ? buttonsConfig[ButtonType.Publish].isCollapsed : isButtonTextHidden}
                            onClick={this.handlePublish}
                            textRef={this.buttonTextRef}
                            id="publish"
                            forceText={attachesViewerHeaderJsAdaptivity ? false : showEditButton && normalizedOvidiusEnabled}
                            primary={!showAttachEditButton}
                        />
                        <ViewerHeaderButton
                            title={SAVE_INTO_CLOUD}
                            hint={SAVE_INTO_CLOUD}
                            icon={<Icon20CloudArrowUpOutline />}
                            iconOnly={attachesViewerHeaderJsAdaptivity ? buttonsConfig[ButtonType.Clone].isCollapsed : isButtonTextHidden}
                            onClick={this.handleClone}
                            id="clone"
                            collapsableText={!attachesViewerHeaderJsAdaptivity}
                        />
                        {isImageEditorEnabled && (
                            <ViewerHeaderImageEditButton
                                onClick={this.handleEditImage}
                                item={item}
                                itemStorage={itemStorage}
                                isJsAdaptivity={attachesViewerHeaderJsAdaptivity}
                                isCollapsed={buttonsConfig[ButtonType.EditImage].isCollapsed}
                            />
                        )}
                    </>
                )}
                {this.renderDownload(item, attachesViewerHeaderJsAdaptivity ? buttonsConfig[ButtonType.Download].isCollapsed : undefined)}
                {allowPrinting && (
                    <ViewerHeaderButton
                        title=""
                        icon={<Icon20PrinterOutline />}
                        onClick={this.handlePrint}
                        hint={PRINT_FILE}
                        isFilled={false}
                        id="print"
                    />
                )}
                {normalizedOvidiusEnabled && (
                    <>
                        <div className={buttonStyles.separator} />
                        <ViewerHeaderButton
                            title="Уменьшить"
                            icon={<Icon20MagnifierMinusOutline />}
                            onClick={this.props.normalizedOvidiusDecreaseScaleClick}
                            iconOnly={attachesViewerHeaderJsAdaptivity}
                            hint="Уменьшить"
                            id="decrease"
                        />
                        <ViewerHeaderButton
                            title="Увеличить"
                            icon={<Icon20MagnifierPlusOutline />}
                            onClick={this.props.normalizedOvidiusIncreaseScaleClick}
                            iconOnly={attachesViewerHeaderJsAdaptivity}
                            hint="Увеличить"
                            id="increase"
                        />
                    </>
                )}
            </>
        );
    };

    private renderAlbumsToolbarItems = () => {
        const { item, enableFavorites } = this.props;
        const isInFavorites = Boolean(item && 'isInFavorites' in item && item.isInFavorites);

        const downloadBttn = this.renderDownload(item);

        return (
            <>
                {downloadBttn}
                {enableFavorites && (
                    <ViewerHeaderButton
                        title={<div className={styles.button_fav}>{isInFavorites ? deleteFromFavorites : addToFavorites}</div>}
                        icon={
                            isInFavorites ? (
                                <Icon20Like color="var(--vkui--color_icon_contrast)" />
                            ) : (
                                <Icon20LikeOutline color="var(--vkui--color_icon_contrast)" />
                            )
                        }
                        hint={isInFavorites ? deleteFromFavorites : addToFavorites}
                        onClick={this.handleOnToggleFavorites}
                        id="favorite"
                        iconOnly
                    />
                )}
                <div className={styles.iconsGroup}>
                    <ViewerHeaderButton
                        title="Удалить"
                        icon={<Icon20DeleteOutline />}
                        onClick={this.handleDelete}
                        hint={DELETE_FILE}
                        isFilled={false}
                        id="delete"
                        iconOnly
                    />
                </div>
            </>
        );
    };

    private renderIntegrationToolbarItems = () => {
        const { item, isArchiveItem, enableFavorites, allowPrinting, normalizedOvidiusEnabled, itemStorage } = this.props;
        const { wrapToolbarButtonsOnSmallScreen } = this.state;

        const isInFavorites = Boolean(item && 'isInFavorites' in item && item.isInFavorites);
        const isPublished = Boolean(item && 'weblink' in item && item.weblink);

        const isCanDownload = this.isDownloadAvailable(item);
        const downloadBttn = this.renderDownload(item);
        const isCanPrint = allowPrinting || false;
        const isImageEditorEnabled = isImageEditor && isImage(item) && isPreviewable(item) && !storeHelper.getValue(IMAGE_EDITOR_ID);

        if (isArchiveItem) {
            return downloadBttn;
        }

        const menuItems: ListItem[] = [];

        if (wrapToolbarButtonsOnSmallScreen) {
            this.filloutPrivateToolbarMenu(menuItems, {
                enableFavorites,
                isCanDownload,
                isInFavorites,
                isPublished,
                isCanPrint,
            });
        }

        menuItems.push(...this.getMoreItems());

        return (
            <>
                {this.renderMainButtons()}
                {downloadBttn}
                <div className={styles.iconsGroup}>
                    {!wrapToolbarButtonsOnSmallScreen && isImageEditorEnabled && (
                        <ViewerHeaderImageEditButton onClick={this.handleEditImage} item={item} itemStorage={itemStorage} />
                    )}
                    {!wrapToolbarButtonsOnSmallScreen && isCanPrint && (
                        <ViewerHeaderButton
                            title=""
                            icon={<Icon20PrinterOutline />}
                            onClick={this.handlePrint}
                            hint={PRINT_FILE}
                            isFilled={false}
                            id="print"
                        />
                    )}
                    {normalizedOvidiusEnabled && (
                        <>
                            <div className={buttonStyles.separator} />
                            <ViewerHeaderButton
                                title="Уменьшить"
                                icon={<Icon20MagnifierMinusOutline />}
                                onClick={this.props.normalizedOvidiusDecreaseScaleClick}
                                hint="Уменьшить"
                                id="decrease"
                            />
                            <ViewerHeaderButton
                                title="Увеличить"
                                icon={<Icon20MagnifierPlusOutline />}
                                onClick={this.props.normalizedOvidiusIncreaseScaleClick}
                                hint="Увеличить"
                                id="increase"
                            />
                        </>
                    )}
                </div>
            </>
        );
    };

    private renderSharingAttachToolbarItems = () => {
        const { item, sendViewerDwh } = this.props;

        return (
            <>
                <ViewerHeaderButton
                    title="Скачать"
                    hint="Скачать"
                    primary={false}
                    icon={<Icon20DownloadOutline />}
                    onClick={() => {
                        if (sendViewerDwh) {
                            sendViewerDwh({ action: 'download_content', forceSend: true });
                        }
                        if (item?.url?.download) {
                            return downloadByUrl(item.url.download, item.name, item.storage);
                        }
                    }}
                    id="download"
                />
            </>
        );
    };

    private renderToolbar = () => {
        const { isPublic, isStock, isAttaches, embedded, item, itemStorage, sharingAttach } = this.props;

        if (sharingAttach) {
            return this.renderSharingAttachToolbarItems();
        }

        if (item?.storage === EStorageType.albums) {
            return this.renderAlbumsToolbarItems();
        }

        if (isAttaches) {
            return this.renderAttachToolbarItems();
        }

        if (isStock || itemStorage === EStorageType.stock) {
            return this.renderStockToolbarItems();
        }

        if (isPublic || itemStorage === EStorageType.public) {
            return this.renderPublicToolbarItems(!embedded, !!embedded);
        }

        if (item?.storage === EStorageType.story) {
            return this.renderStoryToolbarItems();
        }

        if (isIntegrationStorage(itemStorage)) {
            return this.renderIntegrationToolbarItems();
        }

        return this.renderPrivateToolbarItems();
    };

    render(): ReactElement | null {
        const {
            item,
            isFullScreen,
            onFullscreen,
            onFileClick,
            embedded,
            sharingAttach,
            showLogo,
            renderTopChildren,
            topTitle,
            topSubTitle,
            showFacesFilter,
            disableClose,
            isAttaches,
            showPdfEditButton,
            setSearchText,
            enableSearch,
            isViewerFileVerifiedByKaspersky,
            normalizedOvidiusEnabled,
            itemStorage,
            onpremR7ProductEnabled,
        } = this.props;
        const { isDownloadOpen, isMoreOpen, expandSearch } = this.state;

        if (!item) {
            return null;
        }

        let topTitleBlock;
        if (sharingAttach) {
            topTitleBlock = (
                <div className={classNames(styles.fileInfo, styles.fileInfo_letters)}>
                    <div className={styles.iconLetters}>
                        <LogoCloud className={styles.iconLettersIcon} />
                    </div>
                    <div className={styles.fileName}>
                        <NameComponent name={item.name ?? ''} extension="" truncate showTooltip fullWidth />
                    </div>
                </div>
            );
        } else if (item.storage === EStorageType.story) {
            topTitleBlock = (
                <div className={classNames(styles.fileInfo, styles.fileInfo_story)}>
                    <div className={styles.fileName}>
                        <NameComponent name={topTitle ?? ''} extension="" truncate showTooltip fullWidth />
                    </div>
                    <div className={styles.fileSize}>{topSubTitle}</div>
                </div>
            );
        } else {
            const name = item.isFolder ? item.name : item?.nameWithoutExt || '';

            topTitleBlock = (
                <div className={styles.fileInfo}>
                    <div className={styles.fileName}>
                        <NameComponent
                            name={name}
                            extension={item?.ext}
                            truncate
                            delayTruncate={embedded}
                            showTooltip
                            showUnknownExtension
                        />
                        {[EStorageType.public, EStorageType.stock].includes(item.storage) &&
                            !IS_PUBLIC_FOLDER &&
                            isViewerFileVerifiedByKaspersky && <ProtectedHint text="Файл проверен антивирусом Касперского" />}
                    </div>
                    {typeof item.size === 'number' ? <div className={styles.fileSize}>{getSize(item.size)}</div> : null}
                </div>
            );
        }

        let closeButton = (
            <Hint text="Закрыть" theme="dark" showDelay>
                <div className={styles.closeIcon} onMouseDown={this.handleClose} data-qa-id={'close'}>
                    <Icon20Cancel />
                </div>
            </Hint>
        );

        if (
            isAttaches &&
            [AttachesCloseButtonAB.HighlightIcon, AttachesCloseButtonAB.HighlightText].includes(
                attachesCloseButtonVersion as AttachesCloseButtonAB
            )
        ) {
            const hasIcon = attachesCloseButtonVersion === AttachesCloseButtonAB.HighlightIcon;
            const hasText = attachesCloseButtonVersion === AttachesCloseButtonAB.HighlightText;

            closeButton = (
                <ViewerHeaderButton
                    iconOnly={hasIcon}
                    icon={hasIcon ? <Icon20Cancel /> : undefined}
                    title={hasText ? 'Закрыть' : ''}
                    forceText={hasText}
                    hint="Закрыть"
                    secondary
                    onClick={this.handleClose}
                    id="close"
                />
            );
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_attaches]: item?.storage === EStorageType.attaches || item?.storage === EStorageType.viewerAttaches,
                    [styles.root_embedded]: embedded,
                    [styles.root_story]: item?.storage === EStorageType.story,
                    [styles.root_with_r7]: onpremR7ProductEnabled,
                    [buttonStyles.hide_text]:
                        isAttaches && attachesViewerHeaderJsAdaptivity ? false : showPdfEditButton || normalizedOvidiusEnabled,
                })}
                data-ext={String(item?.ext).toLowerCase()}
            >
                {renderTopChildren && renderTopChildren(isDownloadOpen || isMoreOpen)}
                {!expandSearch && (
                    <div
                        ref={(el) => {
                            if (el && this.containerEl !== el) {
                                this.containerEl = el;
                                this.updateHeaderWidth();
                            }
                        }}
                        className={styles.controlsWrapper}
                    >
                        <div
                            className={classNames(styles.group, styles.leftGroup, {
                                [styles.leftGroup_small]: Boolean(showFacesFilter),
                            })}
                        >
                            {item?.storage === EStorageType.attaches || item?.storage === EStorageType.viewerAttaches ? (
                                <AttachInfo
                                    name={item?.subject}
                                    author={item?.authorName}
                                    timestamp={item?.mtime}
                                    fileName={item?.nameWithoutExt}
                                    fileExt={item?.ext}
                                    size={getSize(item?.size || 0)}
                                    isTemporary={item?.attachType === EAttachTypes.temporary}
                                    onClick={onFileClick}
                                />
                            ) : (
                                <>
                                    {showLogo && (
                                        <div
                                            className={classNames(styles.logo, {
                                                [styles.logoResponsive]: ENABLE_FULL_RESPONSIVE,
                                            })}
                                        >
                                            <Logo white />
                                        </div>
                                    )}
                                    {!disableClose && isIntegrationStorage(itemStorage) && (
                                        <Hint text="Вернуться" theme="dark" showDelay>
                                            <div
                                                className={classNames(styles.closeIcon, styles.closeIconLeft)}
                                                onMouseDown={this.handleClose}
                                            >
                                                <Icon20ArrowLeftOutline />
                                            </div>
                                        </Hint>
                                    )}
                                    {topTitleBlock}
                                </>
                            )}
                        </div>
                        <div className={classNames(styles.group, styles.middleGroup)}>{this.renderToolbar()}</div>
                        {!embedded && (
                            <div ref={this.rightGroupRef} className={classNames(styles.group, styles.rightGroup)}>
                                {enableSearch && (
                                    <ViewerHeaderButton
                                        title=""
                                        icon={<Icon20Search />}
                                        onClick={this.onSearchClick}
                                        hint={'Поиск по документу'}
                                        id='document-search"'
                                    />
                                )}
                                {isAttaches && (
                                    <div className={styles.marketingPromoButton}>
                                        <MarketingPromoButton onVisibilityChanged={this.handleMarketingPromoButtonVisible} />
                                    </div>
                                )}
                                <ViewerHeaderButton
                                    title=""
                                    icon={
                                        isFullScreen ? (
                                            <Icon20FullscreenExitOutline width={16} height={16} />
                                        ) : (
                                            <Icon20FullscreenOutline width={16} height={16} />
                                        )
                                    }
                                    onClick={onFullscreen}
                                    hint={isFullScreen ? 'Выйти из полноэкранного режима' : 'Полноэкранный режим'}
                                    id="fullscreen"
                                />
                                {!disableClose && !isIntegrationStorage(itemStorage) && closeButton}
                            </div>
                        )}
                    </div>
                )}

                {expandSearch && <DocumentSearch onSearchClose={this.onSearchClose} setSearchText={setSearchText} />}
            </div>
        );
    }
}

export const ViewerHeader = connect(mapDispatchToProps)(ViewerHeaderComponent);
