import api from 'Cloud/Application/api';
import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateWeblinksV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'weblinks/file/add';

class AddFileWeblinksAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const addFileWeblinksApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateWeblinksV2ToV4.fileAdd) {
        return new AddFileWeblinksAPIV4Call()
            .makeRequest({ ...params, weblink_id: params.weblink, file_path: params.home })
            .catch((error) => {
                throw [error.response, error.status, error, error.responseText];
            });
    }

    return promisifyDeferredCall(api.weblinks.file.add, params);
};
