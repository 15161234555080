import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicThemeRequest } from 'reactApp/modules/themePublic/themePublic.module';
import { ThemePublicSelectors } from 'reactApp/modules/themePublic/themePublic.selectors';
import type { RootState } from 'reactApp/store';

export function usePublicThemeId(public_id: string) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPublicThemeRequest({ public_id }));
    }, []);
    return useSelector((state) => ThemePublicSelectors.getPublicThemeId(state as RootState, public_id));
}
