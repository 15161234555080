import { Icon20ChevronLeftOutline } from '@vkontakte/icons';
import React, { type FC } from 'react';
import { SearchIcon } from 'reactApp/ui/VKUIIcons';
import { Input } from 'semantic-ui-react';

import styles from './DocumentSearch.css';

interface Props {
    onSearchClose: () => void;
    setSearchText: (text: string) => void;
}

export const DocumentSearch: FC<Props> = ({ onSearchClose, setSearchText }) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchText(event.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.resetSearchBlock} onClick={onSearchClose}>
                <Icon20ChevronLeftOutline />
                <p className={styles.resetSearchText}>Сбросить поиск</p>
            </div>

            <div className={styles.inputBlock}>
                <div className={styles.searchIcon}>
                    <SearchIcon color={'#B0B1B6'} />
                </div>
                <Input className={styles.input} placeholder={'Поиск по документу'} onChange={onChange} />
            </div>
        </div>
    );
};

DocumentSearch.displayName = 'DocumentSearch';
