/** CLOUDWEB-17532 Модальное окно про акцию с Литрес */
// tempexp_17532-start

import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_PAID_USER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type LitresModalAB = 'a' | 'b' | false;

interface LitresModalSettings {
    firstTimePeriod: string;
    restTimePeriod: string;
    firstTimeHits: number;
    actionEndDate: string;
}

const IS_BIZ_PAID_SOC_USER = IS_PAID_USER || IS_SOCIAL_USER || IS_BIZ_USER;

const litresModalABDesktop: LitresModalAB = !IS_BIZ_PAID_SOC_USER && getFeature('litres-modal-ab');

registerExperiment(
    'litres-modal',
    litresModalABDesktop,
    [
        AnalyticEventNames.LITRES_MODAL_CLOUD_SHOW,
        AnalyticEventNames.LITRES_MODAL_CLOUD_CLICK,
        AnalyticEventNames.LITRES_MODAL_CLOUD_CANCEL,
        AnalyticEventNames.LITRES_MODAL_CLOUD_CLOSE,
    ],
    {
        mapEventNames: {
            LITRES_MODAL_CLOUD_SHOW: 'cloud-show',
            LITRES_MODAL_CLOUD_CLICK: 'cloud-click',
            LITRES_MODAL_CLOUD_CANCEL: 'cloud-cancel',
            LITRES_MODAL_CLOUD_CLOSE: 'cloud-close',
        },
    }
);

export const litresModalEnabled = litresModalABDesktop === 'b';

export const litresModalSettings: LitresModalSettings = getFeature('litres-modal-settings');

// tempexp_17532-end
