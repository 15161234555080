import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getPhState = (state: RootState) => state.ph;

export const getPrevEmail = createSelector(
    getPhState,
    (state) => state,
    (state) => state.prevEmail
);
export const getPhListner = createSelector(
    getPhState,
    (state) => state,
    (state) => state.phListner
);
