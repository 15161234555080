import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactApp/components/BaseConfirmDialog/Input/Input';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { addEmailAction } from 'reactApp/modules/socialUser/socialUser.actions';
import { getError } from 'reactApp/modules/socialUser/socialUser.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { isIosDevice } from 'reactApp/utils/scrollLock';

import styles from './ConfirmEmailMobile.css';

const IS_IOS = isIosDevice();

interface ConfirmEmailMobileProps {
    onClose: () => void;
    onSuccess: () => void;
    title?: string;
    text?: string;
    showLA?: boolean;
    buttonText?: string;
    placeholder?: string;
    closable?: boolean;
}

export const ConfirmEmailMobile = memo(
    ({
        onClose,
        onSuccess,
        title,
        text,
        showLA = true,
        buttonText,
        placeholder,
        closable = false,
    }: ConfirmEmailMobileProps): ReactElement => {
        const dispatch = useDispatch();

        const error = useSelector(getError);
        const storage = useSelector(getCurrentStorage);

        const [email, setEmail] = useState<string>('');

        const inputRef = useRef<HTMLInputElement | null>(null);

        const afterAddEmail = useCallback(() => {
            onSuccess();
            onClose();
        }, [onClose, onSuccess]);

        const handleButtonClick = useCallback(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(addEmailAction(email, storage)).then(afterAddEmail);
        }, [dispatch, email, afterAddEmail]);

        const handleInputChange = useCallback(
            (event) => {
                const { value } = event.target || {};
                setEmail(value);
            },
            [email, setEmail]
        );

        const getReference = (ref) => {
            inputRef.current = ref;
        };

        useEffect(() => {
            if (!IS_IOS) {
                // eslint-disable-next-line no-unused-expressions
                inputRef?.current?.focus?.();
            }
        }, []);

        return (
            <MobileDialog
                id="mobile-confirm-email"
                open
                dimmer
                onClose={onClose}
                disableScrolling
                mod="confirm"
                topmost
                title={!title ? 'Подтверждение email' : undefined}
                closable={closable}
                closeOnDimmerClick={closable}
            >
                <div
                    className={classNames(styles.root, {
                        [styles.rootNoLA]: !showLA,
                    })}
                >
                    {title && <div className={styles.title}>{title}</div>}
                    <div
                        className={classNames(styles.text, {
                            [styles.textCustom]: Boolean(text),
                        })}
                    >
                        {text || 'Чтобы оплатить тариф, укажите свой email, и мы пришлём на указанный вами адрес ссылку для подтверждения'}
                    </div>

                    {showLA && (
                        <div className={styles.licenseBlock}>
                            Нажимая «Отправить», вы принимаете условия{' '}
                            <a href="/LA/" target="_blank">
                                Лицензионного соглашения
                            </a>
                        </div>
                    )}

                    <Input
                        error={!!error}
                        validationErrorMessage={error}
                        onChange={handleInputChange}
                        value={email}
                        fluid
                        focus={!IS_IOS}
                        autoFocus={!IS_IOS}
                        getReference={getReference}
                        className={styles.input}
                        placeholder={placeholder || 'Укажите ваш e-mail'}
                    />
                    <div className={styles.button}>
                        <Button
                            data-name="confirm"
                            theme="vk"
                            className={styles.button}
                            onClick={handleButtonClick}
                            fluid
                            primary
                            sizeMode={ButtonSizeMode.big}
                        >
                            {buttonText || 'Отправить'}
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }
);

ConfirmEmailMobile.displayName = 'ConfirmEmailMobile';
