import { addDays, differenceInDays } from 'date-fns';
import type { Profile } from 'reactApp/modules/profile/profile.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

export const getProfile = (state: RootState): Profile => state.profile;

export const getCountDaysBeforeClear = createSelector(getProfile, (profile) => {
    const clearTime = profile?.overquota?.clearTime;
    const now = Date.now();

    return clearTime && now < clearTime ? differenceInDays(clearTime, now) : 0;
});

export const needToShowClearOverquotaNotification = createSelector(getProfile, (profile) => {
    const clearStarted = profile?.overquota?.clearStarted || 0;
    const now = Date.now();
    const finishNotificationsDate = addDays(clearStarted, 10).valueOf();

    return !!clearStarted && now > clearStarted && now < finishNotificationsDate;
});

export const getClearTime = createSelector(getProfile, (profile) => profile?.overquota?.clearTime);

export const getIsCheckUpdateLicenseCompleted = (state: RootState) => getProfile(state).checkUpdateLicenseCompleted;
export const getProfileVersion = (state: RootState) => getProfile(state).version;

export const getUserOverquotaState = createSelector(getProfile, (profile) => ({
    ...profile.overquota,
}));
