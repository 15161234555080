import type { quotaStates } from 'server/types/context/IUser';

export interface ProfileOverquota {
    state?: quotaStates;
    clearTime?: number;
    blockTime?: number;
    clearStarted?: number;
    trashSize?: number;
}

export interface Profile {
    email: string;
    overquota?: ProfileOverquota;
    checkUpdateLicenseCompleted: boolean;
    version?: string;
}

export interface ApiProfile {
    email: string;
    properties: { 'overquota.clear_time'?: number; 'overquota.clear_started'?: number };
}

export enum ProfileApiResponses {
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
}
