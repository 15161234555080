import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import type { AxiosResponse } from 'lib/axios';
import { logger } from 'lib/logger';
import { type LLMResponse, LLMBrainstormApiCall, LLMContinueApiCall, LLMPostApiCall, LLMTextByThemeApiCall } from 'reactApp/api/LLMApiCall';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import {
    continueMessage,
    getMessageFromLLM,
    getMessageFromLLMError,
    getMessageFromLLMSuccess,
    initLlmDialog,
    removeErrorMessages,
    rewriteMessage,
    sendMessage,
    toggleOpenLlmDialog,
} from 'reactApp/modules/editor/editor.module';
import { getEditorItem, getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { LLMAnalyticsType, sendTechLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { MessageCategory } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { removeLeadingNewlines } from 'reactApp/ui/EditorLLM/EditorLLMDialog/utils/EditorLlmDialog.utils';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { call, put, select, takeEvery } from 'redux-saga/effects';

type LLMRequestParams = {
    text: string;
    streaming: boolean;
};

const llmTextByThemeApiCall = (params: LLMRequestParams) => new LLMTextByThemeApiCall().makeRequest(params);
const llmPostApiCall = (params: LLMRequestParams) => new LLMPostApiCall().makeRequest(params);
const llmBrainstormApiCall = (params: LLMRequestParams) => new LLMBrainstormApiCall().makeRequest(params);
const llmContinueApiCall = (params: LLMRequestParams) => new LLMContinueApiCall().makeRequest(params);

function* initDialog() {
    const { isFromExternal } = yield select(SettingsSelectors.getQueryParams);

    if (isFromExternal) {
        yield put(toggleOpenLlmDialog(true));
    }
}

function* sendLLMApiAnalytics(isSuccess: boolean) {
    const item = yield select(getEditorItem);
    const { selectType } = yield select(getLlmDialogState);
    const { size = 0, id = '', storage = '', ext = '' } = item || {};

    sendDwh({
        eventCategory: ECategoryGa.gpt,
        action: isSuccess ? LLMAnalyticsType.ANSWER_SHOW : LLMAnalyticsType.ERROR_SHOW,
        dwhData: {
            size_file: size,
            id_media: id,
            source: storage,
            version: isWopiEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
            extension: ext,
            scenario: selectType.value,
        },
    });
    sendTechLLMAnalytics({ action: LLMAnalyticsType.ANSWER_SHOW, result: isSuccess ? 'success' : 'error', info: selectType.value });
}

function* getHandler() {
    const {
        selectType: { value },
    } = yield select(getLlmDialogState);

    switch (value) {
        case MessageCategory.TEXT_BY_THEME:
            return llmTextByThemeApiCall;
        case MessageCategory.POST:
            return llmPostApiCall;
        case MessageCategory.BRAINSTORM:
            return llmBrainstormApiCall;
    }
}

function* handleApiResponse(message: string, handleFn?: (params: LLMRequestParams) => Promise<AxiosResponse<LLMResponse>>) {
    const handler = yield handleFn || getHandler();

    try {
        yield put(removeErrorMessages());
        const { data } = yield call(handler, { text: message, streaming: false });
        const { message: responseMessage } = data as LLMResponse;

        yield sendLLMApiAnalytics(true);
        yield put(getMessageFromLLMSuccess(removeLeadingNewlines(responseMessage)));
    } catch (error: any) {
        yield sendLLMApiAnalytics(false);
        logger.error(error);
        yield put(getMessageFromLLMError(error?.status));
    }
}

function* handleGetMessageFromLLM(action: ReturnType<typeof getMessageFromLLM>) {
    const { message, hasError, isOwn } = action.payload;

    yield put(sendMessage({ message, hasError, isOwn }));

    yield call(handleApiResponse, message);
}

function* handleContinueMessage(action: ReturnType<typeof continueMessage>) {
    const message = action.payload;

    yield call(handleApiResponse, message, llmContinueApiCall);
}

function* handleRewriteMessage() {
    const { inputMessage } = yield select(getLlmDialogState);

    yield call(handleApiResponse, inputMessage);
}

export function* watchLlmDialog() {
    yield takeEvery(initLlmDialog.toString(), initDialog);
    yield takeEvery(getMessageFromLLM.toString(), handleGetMessageFromLLM);
    yield takeEvery(continueMessage.toString(), handleContinueMessage);
    yield takeEvery(rewriteMessage.toString(), handleRewriteMessage);
}
