import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'reactApp/store';

import type { BusinessTemplatesState } from './businessTemplates.types';

const getBusinessTemplatesState = (state: RootState): BusinessTemplatesState => state.businessTemplates;

export const selectAllBusinessTemplates = createSelector(getBusinessTemplatesState, (state) =>
    state.categoriesList.map(({ templates }) => templates).flat()
);

export const selectBusinessTemplatesByCategoryId = createSelector(
    getBusinessTemplatesState,
    (_state: RootState, categoryId: string | undefined) => categoryId,
    (templates, categoryId) => (categoryId ? templates.categoriesById[categoryId] : undefined)
);

export const selectBusinessTemplatesCategories = createSelector(getBusinessTemplatesState, (state) => state.categoriesList);

export const selectBusinessTemplatesListLoadingState = createSelector(getBusinessTemplatesState, (state) => state.listLoadingState);

export const selectBusinessTemplatesCopyLoadingState = createSelector(getBusinessTemplatesState, (state) => state.copyLoadingState);
