import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type ChangeEvent, type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRenewTrialPeriod, subscriptionCaptchaConfig } from 'reactApp/appHelpers/featuresHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { Input } from 'reactApp/components/BaseConfirmDialog/Input/Input';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { getCaptchaError, isCheckingCaptcha, resetCaptchaState } from 'reactApp/modules/captcha/captcha.module';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { getSubscriptionsById } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import type { RootState } from 'reactApp/store';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';

import styles from './CancelSubscriptionWithCaptchaModal.css';

export interface IProps {
    subscriptionId: string;
    isPhone?: boolean;
    isRebrandingQuotaLanding?: boolean;
    isRebranding?: boolean;
}

const getMtimeString = (): string => String(Date.now()).slice(-6);

export const CancelSubscriptionWithCaptchaModal = memo(
    ({ onClose, subscriptionId, isPhone, isRebrandingQuotaLanding, isRebranding = false }: IPropsWithPopup<IProps>): ReactElement => {
        const dispatch = useDispatch();

        const { url, captchaId } = subscriptionCaptchaConfig;

        const subscription = useSelector((state: RootState) => getSubscriptionsById(state, subscriptionId)) as ISubscription;
        const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

        const error = useSelector(getCaptchaError);
        const isLoading = useSelector(isCheckingCaptcha);

        const [inputValue, setInputValue] = useState<string>('');
        const [mtimeString, setMtimeString] = useState<string>(getMtimeString());

        const inputRef = useRef<HTMLInputElement | null>(null);

        const buttons = useMemo(() => [EButtonType.accept, EButtonType.action], []);

        const changeCaptcha = useCallback(() => {
            setMtimeString(getMtimeString());
        }, []);

        const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target?.value);
            dispatch(resetCaptchaState());
        };

        useEffect(() => {
            if (subscription) {
                sendAnalytics(ESubscriptionModalAction.captchaView);
            }
            dispatch(resetCaptchaState());
        }, [subscription?.id]);

        const getReference = (ref) => {
            inputRef.current = ref;
        };

        const content = useCallback(() => {
            return (
                <div
                    className={classNames(styles.content, {
                        [styles.content_phone]: isPhone,
                        [styles.content_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                        [styles.content_rebranding]: isRebranding,
                    })}
                >
                    <Title level="1" weight="2">
                        Подтвердите отключение
                    </Title>
                    <Spacing size={12} />
                    <Text>Для подтверждения введите код&nbsp;с&nbsp;картинки</Text>
                    <Spacing size={32} />
                    <div className={styles.captchaBlock}>
                        <img src={`${url}${captchaId}?${mtimeString}`} alt="" className={styles.captcha} />
                    </div>
                    <Spacing size={12} />
                    <ButtonLink className={styles.link} onClick={changeCaptcha}>
                        Показать другой код
                    </ButtonLink>
                    <Spacing size={32} />
                    <Input
                        onChange={onInputChange}
                        value={inputValue}
                        placeholder="Ввести код"
                        autoFocus
                        getReference={getReference}
                        className={styles.input}
                        error={Boolean(error)}
                        validationErrorMessage={error}
                    />
                </div>
            );
        }, [captchaId, changeCaptcha, error, inputValue, isPhone, isRebranding, isRebrandingQuotaLanding, mtimeString, onInputChange, url]);

        const onSuccess = useCallback(() => {
            sendAnalytics(ESubscriptionModalAction.captchaSaveSubs);
            onClose();
        }, [onClose, sendAnalytics]);

        const onAction = useCallback(() => {
            const props = {
                captchaId,
                captchaValue: inputValue,
                subscriptionId,
                sendAnalytics,
                isRebrandingQuotaLanding,
            };

            // tempexp_16480-next-line
            if (cancelRenewTrialPeriod) {
                sendAnalytics(ESubscriptionModalAction.trialCancelSubs);
                dispatch(BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial({ ...props, period: cancelRenewTrialPeriod }));
                return;
            }

            sendAnalytics(ESubscriptionModalAction.captchaCancelSubs);
            dispatch(BuySubscriptionActions.cancelAutorenewSubscription(props));
        }, [dispatch, inputValue, sendAnalytics, subscriptionId]);

        return (
            <BaseConfirmDialog
                onSuccess={onSuccess}
                onAction={onAction}
                onClose={onClose}
                buttons={buttons}
                successButtonText="Сохранить подписку"
                actionButtonText="Отключить"
                renderContent={content}
                className={styles.dialog}
                loading={isLoading}
                buttonTheme="vk"
                size="tiny"
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                isRebranding={isRebranding}
            />
        );
    }
);

CancelSubscriptionWithCaptchaModal.displayName = 'CancelSubscriptionWithCaptchaModal';
