import { Icon16CheckOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Checkbox.css';

interface CheckboxProps {
    className?: string;
    onCheckboxClick: React.MouseEventHandler<HTMLDivElement>;
    isSelected: boolean;
    isDisabled?: boolean;
    viewType: EViewMode;
}

export const Checkbox = memo<CheckboxProps>(function Checkbox({ className, isSelected, isDisabled, onCheckboxClick, viewType }) {
    const textIsSelected = isSelected ? 'Снять выделение' : 'Выделить';
    const hint = !isDisabled ? textIsSelected : '';

    return (
        <Hint text={hint}>
            <DataListControl.New
                id="checkbox"
                type="check"
                viewType={viewType}
                className={classNames(styles.root, className, {
                    [styles.selected]: isSelected && !isDisabled,
                    [styles.disabled]: isDisabled,
                    [styles.list]: viewType === EViewMode.list,
                })}
                onClick={onCheckboxClick}
                aria-selected={isSelected}
                aria-disabled={isDisabled}
            >
                <Icon16CheckOutline />
            </DataListControl.New>
        </Hint>
    );
});
