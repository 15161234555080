import React, { type ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { EDropdownColors } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown.types';
import { TOOLTIP_OFFSET } from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { type IAccessRightsOptions, EAccessRights } from 'reactApp/components/SharingWindow/Sharing.types';
import { deleteUser, setAccessRight } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { unmountRequest } from 'reactApp/modules/modifying/modifying.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { weblinkDeleteEmailAccess, weblinkSetEmailAccess } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { EPublicAccessRights } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.types';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { ACCESS_RIGHTS_OPTIONS, DELETE_USER, PUBLIC_ACCESS_RIGHTS_OPTIONS, UNMOUNT_USER } from './User.constants';
import styles from './User.css';
import { renderDeletePublicAccessDialog, renderDeleteUserDialog } from './User.helpers';

export const useAccessRights = ({ user, item, isMounted, onClose, isPhone, isOwner, publicId }): ReactElement | null | string => {
    const dispatch = useDispatch();
    const userRight = ACCESS_RIGHTS_OPTIONS[user.accessRights];
    const storage = useSelector(getCurrentStorage);
    const { xPageId, xReqId, query } = useSelector(getSearchRequestParams);

    const onActionClick = useCallback(() => {
        if (!user || !item) {
            return;
        }
        sendGa('delete-user');

        sendPaymentGa({
            eventCategory: publicId ? ECategoryGa.public : ECategoryGa.sharedFolder,
            action: 'shutdown-sharing',
            public_id: publicId,
        });

        if (storage === EStorageType.search) {
            sendPaymentGa({
                eventCategory: ECategoryGa.publicSearch,
                action: 'shutdown-sharing',
                public_id: publicId,
                section: storage,
                search_phrase: query,
                name_files: item.name,
                type_content: 'folder',
                page_id: xPageId,
                req_id: xReqId,
                pos: item.pos,
            });
        }

        dispatch(deleteUser({ email: user.email, item }));
    }, [user, item, publicId, storage, dispatch, query, xPageId, xReqId]);

    const handleAccessRightsUpdate = useCallback(
        (accessRight): void => {
            if (!user) {
                return;
            }

            if (accessRight === DELETE_USER.key) {
                renderDeleteUserDialog({ user, onActionClick });
                return;
            }

            sendGa('user-rights-change');
            dispatch(setAccessRight({ email: user.email, accessRight, folderId: item?.id }));
        },
        [dispatch, item?.id, onActionClick, user]
    );

    const onUnmount = useCallback(() => {
        sendGa('unmount');
        onClose();
    }, [onClose]);

    const handleOpenUnmountDialog = useCallback(() => {
        dispatch(unmountRequest({ item, onUnmount }));
    }, [dispatch, item, onUnmount]);

    const renderAccessRightsDropdown = useCallback(
        (onClick): ReactElement | null => {
            const values = {
                ...ACCESS_RIGHTS_OPTIONS,
                [EAccessRights.DELETE_USER]: DELETE_USER,
            };

            const list = Object.values(values).map((item: IAccessRightsOptions) => ({
                id: item.key,
                text: item.text,
                check: item.key === user?.accessRights,
                qaValue: item.key,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [user]
    );

    if (isOwner) {
        return <div className={styles.owner}>Владелец папки</div>;
    }

    if (isMounted) {
        return (
            <div className={styles.unmount} onClick={handleOpenUnmountDialog} data-qa-value={UNMOUNT_USER.key}>
                <div className={styles.unmountText}>{UNMOUNT_USER.text}</div>
            </div>
        );
    }

    return (
        <div className={styles.dropdown}>
            <Dropdown
                withinDialog
                theme="octavius"
                value={userRight?.text}
                qaValue={userRight?.key}
                as={SharingDropdown}
                color={isPhone ? EDropdownColors.BLUE : EDropdownColors.GRAY}
                placement={tooltipPlacements.BOTTOM_LEFT}
                content={renderAccessRightsDropdown}
                onClickItem={handleAccessRightsUpdate}
                tooltipOffset={TOOLTIP_OFFSET}
                animatedTooltip
                isPhone={isPhone}
                isUserDropdown
                tooltipClassName={isPhone ? styles.tooltip : undefined}
                noPageOffset
            />
        </div>
    );
};

export const useWeblinkAccessRights = ({ user, item, isPhone, isOwner, isReadOnly }): ReactElement | null | string => {
    const dispatch = useDispatch();
    const userRight = PUBLIC_ACCESS_RIGHTS_OPTIONS[user.accessRights];

    const onActionClick = useCallback(() => {
        if (!user || !item) {
            return;
        }
        sendGa('public-delete-user');

        dispatch(weblinkDeleteEmailAccess({ email: user.email, weblink: item?.weblink }));
    }, [dispatch, item, user]);

    const handleAccessRightsUpdate = useCallback(
        (accessRight): void => {
            if (!user) {
                return;
            }

            if (accessRight === DELETE_USER.key) {
                renderDeletePublicAccessDialog({ user, onActionClick });
                return;
            }

            sendGa('user-rights-change');
            dispatch(weblinkSetEmailAccess({ email: user.email, accessRight, weblink: item?.weblink }));
        },
        [dispatch, item?.weblink, onActionClick, user]
    );

    const renderAccessRightsDropdown = useCallback(
        (onClick): ReactElement | null => {
            const values = isReadOnly
                ? {
                      [EPublicAccessRights.READ_ONLY]: PUBLIC_ACCESS_RIGHTS_OPTIONS[EPublicAccessRights.READ_ONLY],
                      [EAccessRights.DELETE_USER]: DELETE_USER,
                  }
                : {
                      ...PUBLIC_ACCESS_RIGHTS_OPTIONS,
                      [EAccessRights.DELETE_USER]: DELETE_USER,
                  };

            const list = Object.values(values).map((item) => ({
                id: item.key,
                text: item.text,
                check: item.key === user?.accessRights,
                qaValue: item.key,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [isReadOnly, user?.accessRights]
    );

    if (isOwner) {
        return <div className={styles.owner}>Владелец</div>;
    }

    return (
        <div className={styles.dropdown}>
            <Dropdown
                withinDialog
                theme="octavius"
                value={userRight?.text}
                qaValue={userRight?.key}
                as={SharingDropdown}
                color={isPhone ? EDropdownColors.BLUE : EDropdownColors.GRAY}
                placement={tooltipPlacements.BOTTOM_LEFT}
                content={renderAccessRightsDropdown}
                onClickItem={handleAccessRightsUpdate}
                tooltipOffset={TOOLTIP_OFFSET}
                animatedTooltip
                isPhone={isPhone}
                isUserDropdown
                tooltipClassName={isPhone ? styles.tooltip : undefined}
            />
        </div>
    );
};
