import { attachSelected, cancel, expandRequest, publishSelected, showPayLanding } from 'reactApp/modules/integration/integration.actions';
import { handleAttachSelected } from 'reactApp/modules/integration/sagas/attachSelected.saga';
import { handleCancel } from 'reactApp/modules/integration/sagas/cancel.saga';
import { handleExpandRequest } from 'reactApp/modules/integration/sagas/expandRequest.saga';
import { handlePublishSelected } from 'reactApp/modules/integration/sagas/publishSelected.saga';
import { handleShowPayLanding } from 'reactApp/modules/integration/sagas/showPayLanding.saga';
import { takeEvery } from 'redux-saga/effects';

export function* watchIntegration() {
    yield takeEvery(publishSelected.toString(), handlePublishSelected);
    yield takeEvery(attachSelected.toString(), handleAttachSelected);
    yield takeEvery(cancel.toString(), handleCancel);
    yield takeEvery(expandRequest.toString(), handleExpandRequest);
    yield takeEvery(showPayLanding.toString(), handleShowPayLanding);
}
