import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { Uflr } from 'lib/uflr';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import { normalizeStockItem } from './stock.helpers';
import type { ApiStockFolderResponse, ApiStockFolderResponseFile, ApiStockLoadMore, StockFolder, StockState } from './stock.type';

const initialState: StockState = {
    list: {},
};

const filterUflrFiles = (items: (ApiStockFolderResponseFile | ApiStockFolderResponse)[]) => Uflr.exclude(items);

export const loadStockFolder = createAction<ApiStockFolderResponse>('stock/load/folder');
export const loadStockMoreRequest = createAction<ApiStockLoadMore>('stock/load/more');
export const loadStockMoreSuccess = createAction<ApiStockFolderResponse>('stock/load/moreSuccess');

export const stockReducer = createReducer(initialState, {
    [loadStockFolder.type]: (state, action: PayloadAction<ApiStockFolderResponse>) => {
        const { payload } = action;
        const count = {
            folders: payload.count.folders,
            files: payload.count.files,
            all: payload.count.folders + payload.count.files,
            loaded: payload.list.length,
        };

        const stock: StockFolder = {
            count,
            isLoading: false,
            storage: EStorageType.stock,
            isLoaded: true,
            bndExp: payload.bnd_exp,
            bundle: payload.bundle,
            id: payload.bundle,
            kind: 'folder',
            dwlExp: payload.dwl_exp,
            childs: [],
            name: 'Файлы',
            hasMoreToLoad: count.all - count.loaded > 0,
            isFolder: true,
            __reduxTree: true,
            parent: '',
        };

        const list = filterUflrFiles(payload.list) || [];

        list.forEach((child) => {
            const item = normalizeStockItem(child, stock.bundle);
            stock.childs.push(item.id);
            state.list[item.id] = item;
        });

        state.list[stock.bundle] = stock;
    },
    [loadStockMoreSuccess.type]: (state, action: PayloadAction<ApiStockFolderResponse>) => {
        const { bundle, list: apiList } = action.payload;
        const stock = state.list[bundle];

        if (!apiList?.length || !bundle || !stock?.isFolder) {
            return;
        }

        const list = filterUflrFiles(apiList) || [];

        list.forEach((child) => {
            const item = normalizeStockItem(child, bundle);
            stock.childs.push(item.id);
            state.list[item.id] = item;
        });

        const { count } = stock;
        const loaded = count.loaded + apiList.length;
        stock.count.loaded = loaded;
        stock.hasMoreToLoad = count.all - loaded > 0;
    },
});
