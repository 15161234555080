import { classNames } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import type { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import { WeblinkUser } from '../User/WeblinkUser';
import styles from './UserList.css';
import type { IProps } from './WeblinkUserList.types';

export const WeblinkUserList = React.memo(function WeblinkUserList({ acList, isLoading, isPhone, item, isReadOnly }: IProps) {
    const email = useSelector(UserSelectors.getEmail);
    const owner = email
        ? {
              name: 'Вы',
              email,
              avatarUrl: createAvatarUrl(email),
          }
        : null;

    const renderItem = useCallback(
        (user) => (
            <div className={styles.user} key={user.email}>
                <WeblinkUser user={user} item={item} publicId={item?.weblink} isPhone={isPhone} isReadOnly={isReadOnly} />
            </div>
        ),
        [item, isPhone, isReadOnly]
    );

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_phone]: isPhone,
            })}
        >
            <div className={classNames(styles.usersList, { [styles.usersList_scroll]: acList.length > 2 })}>
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader centered isModal />
                    </div>
                ) : (
                    <>
                        {Boolean(owner) && (
                            <div className={styles.user}>
                                <WeblinkUser user={owner as IACListItem} isPhone={isPhone} isOwner isReadOnly={isReadOnly} />
                            </div>
                        )}
                        {acList.map((user) => renderItem(user))}
                    </>
                )}
            </div>
        </div>
    );
});
