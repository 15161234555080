import type { PayloadAction } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/browser';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { Uflr } from 'lib/uflr';
import { FolderV2APICall } from 'reactApp/api/FolderV2APICall';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { addFilesSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { getIdParams } from 'reactApp/modules/modifying/modifying.helpers';
import { EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { sendPublicAnalytics } from 'reactApp/modules/public/public.saga';
import { routeChangeSuccess, routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { defaultSort, getSortById } from 'reactApp/modules/sort/sort.selectors';
import { loadStockFolder, loadStockMoreRequest, loadStockMoreSuccess } from 'reactApp/modules/stock/stock.module';
import { getStockItemById } from 'reactApp/modules/stock/stock.selectors';
import type { ApiStockFolderResponse, ApiStockLoadMore } from 'reactApp/modules/stock/stock.type';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { checkViewerAtIdChange, openMobileViewer as openMobileViewerAction } from 'reactApp/modules/viewer/viewer.module';
import { handleUpdateViewerData } from 'reactApp/modules/viewer/viewer.saga';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { sendXray } from 'reactApp/utils/ga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

function* openMobileViewer(action?) {
    if (!IS_PHONE_BROWSER) {
        return;
    }

    const currentStorage = yield select(getCurrentStorage);

    const storage = action?.payload?.storage || currentStorage;

    if (storage !== EStorageType.stock) {
        return;
    }

    const id = yield select(getCurrentRouteId);

    yield put(openMobileViewerAction({ id }));
}

function* loadStock(action) {
    const storage = action.payload.storage;

    if (storage !== EStorageType.stock) {
        return;
    }

    const serverSideStock = config.get('serverSideFolders')?.folder;
    let folder: ApiStockFolderResponse;
    let isBlocked = false;
    let showInViewer = false;
    let itemId;

    if (serverSideStock?.kind !== 'storage') {
        if (serverSideStock?.list && serverSideStock?.kind === 'folder') {
            folder = serverSideStock;
        } else {
            folder = yield loadStockFolderRequest(action.payload.id);
        }

        if (folder?.list?.length === 1) {
            showInViewer = true;
            itemId = folder?.list?.[0]?.stock;
            isBlocked = folder?.list.some((item) => Uflr.is(item));
        }

        yield put(loadStockFolder(folder));

        yield openMobileViewer();

        yield sendPublicAnalytics(action.payload.id);
    } else {
        const item = serverSideStock?.list[0];

        isBlocked = Uflr.is(item);

        showInViewer = true;

        itemId = item?.id;

        yield put(
            addFilesSuccess({
                items: [
                    {
                        ...item,
                        name: item.name || item.id.split('/').pop(),
                        home: item.home,
                    },
                ],
                parent: '',
                storage: EStorageType.stock,
                reason: EModifyReason.add,
            })
        );
    }

    if (isBlocked) {
        // CLOUDWEB-7677: Не показываем на публичных страницах illegal и blocked файлы.
        yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
    }

    const routeId = yield select(getCurrentRouteId);
    const routeItem = yield select(getStockItemById, routeId);

    if (showInViewer && itemId) {
        yield call(handleUpdateViewerData, { payload: { itemId, itemStorage: storage } });
    } else if (routeItem?.isFolder === false) {
        yield put(checkViewerAtIdChange({ id: routeItem?.id, storage: EStorageType.stock }));
    }
}

function* stockLoadMore(action: PayloadAction<ApiStockLoadMore>) {
    const { offset, id } = action.payload;

    try {
        const folder = yield loadStockFolderRequest(id, offset);

        yield put(loadStockMoreSuccess(folder));
    } catch (error) {
        logger.error(error);
    }
}

function* loadStockFolderRequest(id, offset = 0) {
    try {
        const sort = yield select(getSortById, id);
        const { type, order } = sort || defaultSort;
        const idParams = {
            ...getIdParams(EStorageType.stock, id),
            sort: type,
            order,
            offset: offset || 0,
            limit: getLoadMoreLimit(EStorageType.stock),
            version: 4,
        };

        const { data } = yield new FolderV2APICall().makeRequest(idParams);

        sendXray(`app_load-folder_stock`);

        return data?.body;
    } catch (error) {
        captureMessage('LOAD FOLDER', {
            extra: { error },
        });

        yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
    }
}

export function* watchStock() {
    yield takeEvery(routeChangeSuccess.toString(), openMobileViewer);
    yield takeEvery(loadStockMoreRequest.toString(), stockLoadMore);
    yield takeEvery(routeChangeSuccess.toString(), loadStock);
}
