import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Spacing, Text, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/cancel-autorenew-noads.jpg';
import coverImageDark from 'img/subscriptions/cancel-autorenew-noads-dark.jpg';
import coverImageMobile from 'img/subscriptions/cancel-autorenew-noads-mobile.jpg';
import coverImageMobileDark from 'img/subscriptions/cancel-autorenew-noads-mobile-dark.jpg';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { cancelRenewTrialPeriod } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './CancelAutoRenewNoAds.css';
import type { CancelAutoRenewNoAdsProps } from './CancelAutoRenewNoAds.types';

export const CancelAutoRenewNoAds = memo<CancelAutoRenewNoAdsProps>(({ onClose, subscription }): ReactElement => {
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);
    const dispatch = useDispatch();
    const isMobile = EnvironmentSelectors.isMobile();
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDarkMode = document.body.classList.contains(VK_UI_DARK_THEME);
    const image = isDarkMode ? coverImageDark : coverImage;
    const mobileImage = isDarkMode ? coverImageMobileDark : coverImageMobile;

    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showNoAds);
    }, []);

    const content = useMemo(
        (): ReactElement => (
            <div className={styles.content}>
                <Spacing size={16} />
                <Text className={styles.text}>В Почте и Облаке вновь появится реклама</Text>
                <Spacing size={32} />
            </div>
        ),
        []
    );

    const handleKeepAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.notCancelNoAds);
        onClose?.();
    }, [onClose, sendAnalytics]);

    const handleCancelAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.cancelNoAds);

        // tempexp_13984-start
        if (abWithCaptcha === 'b') {
            onClose?.();
            renderCancelSubscriptionWithCaptchaModal({ subscriptionId: subscription.id });
            return;
        }
        // tempexp_13984-end

        // tempexp_16480-next-line
        if (cancelRenewTrialPeriod) {
            dispatch(
                BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial({
                    subscriptionId: subscription.id,
                    sendAnalytics,
                    period: cancelRenewTrialPeriod,
                })
            );
        } else {
            dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id, sendAnalytics }));
        }

        onClose?.();
    }, [onClose, dispatch, abWithCaptcha, sendAnalytics, subscription]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    // Не стал выносить в отдельный компонент мобильную шторку тк пришлось бы копипастить много логики колбеков.
    if (isMobile) {
        return (
            <MobileDialog
                id="cancel-autorenew-no-ads-mobile"
                onClose={handleClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="base"
                contextPadding="zero"
            >
                <div ref={scrollRef} className={styles.rootMobile}>
                    <img src={mobileImage} alt="Тариф: Без рекламы" className={styles.image} />
                    <Spacing size={24} />
                    <Title level="1" className={styles.title}>
                        Вы отключаете тариф {bytesToNDigits(BASEQUOTA, 3).value} без рекламы
                    </Title>
                    <Spacing size={8} />
                    <Text className={styles.text}>В Почте и Облаке вновь появится реклама</Text>
                    <Spacing size={24} />
                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary onClick={handleKeepAutoRenew} sizeMode={ButtonSizeMode.big}>
                            Сохранить подписку
                        </Button>
                        <Spacing size={12} />
                        <Button theme="vk" onClick={handleCancelAutoRenew} sizeMode={ButtonSizeMode.big}>
                            Отключить
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            onClose={handleClose}
            onClick={handleKeepAutoRenew}
            onSecondary={handleCancelAutoRenew}
            primaryButtonText="Сохранить подписку"
            secondaryButtonText="Отключить"
            title={`Вы отключаете тариф ${bytesToNDigits(BASEQUOTA, 3).value} без рекламы`}
            content={content}
            imageUrl={image}
            imageBgColor="var(--vkui--color_background)"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="cancel-autorenew-no-ads"
        />
    );
});

CancelAutoRenewNoAds.displayName = 'CancelAutoRenewNoAds';
