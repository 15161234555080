import { plural } from 'lib/plural';
import React from 'react';
import { IS_CLOUD_BANNED, IS_CLOUD_CREATED, IS_CLOUD_LOCKED } from 'reactApp/appHelpers/configHelpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { createNewAutoDeleteFolder, moveRequest, openPublishDialog, removeRequest } from 'reactApp/modules/modifying/modifying.actions';
import { isDomainFolder as isDomainFolderStorage } from 'reactApp/modules/storage/folder.helpers';
import { isMountedOrSharedFolder, isReadOnlyMountedOrShared } from 'reactApp/modules/storage/storage.selectors';
import { store as reduxStore } from 'reactApp/store';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import { getSize, getSizeInteger } from 'reactApp/utils/sizeHelper';

const { renderComfirmationDialog } = process.env.SSR
    ? ({} as any)
    : require('reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers');

export const isDomainFolder = (item) => item && item.isFolder && isDomainFolderStorage(item);

export const isReadOnly = (item) => isReadOnlyMountedOrShared(reduxStore.getState(), item);

export const isSharedOrMountedStatus = (item) => {
    const isSharedOrMounted = isMountedOrSharedFolder(reduxStore.getState(), item);
    return isSharedOrMounted && (isSharedOrMounted.isMounted || isSharedOrMounted.isShared);
};

export const getDownloadItemSize = (items) => {
    if (!items.length) {
        return '';
    }

    if (!items.every((item) => typeof item?.size === 'number')) {
        return;
    }

    if (items.length === 1) {
        return getSize(getSizeInteger(items[0].size));
    }

    return getSize(items.reduce((acc, cur) => acc + getSizeInteger(cur.size), 0));
};

export const hideDownloadItemSize = (items) => {
    if (!items.length || !items.every((item) => typeof item?.size === 'number')) {
        return true;
    }
    return items.reduce((acc, cur) => acc + getSizeInteger(cur.size), 0) === 0;
};

export const isEmptyFolder = (currentFolder) => {
    if (!currentFolder) {
        return true;
    }

    return currentFolder.hasOwnProperty('size') && currentFolder.size === 0;
};

export const moveItemsDialog = (items, destination) => {
    if (!items || !destination) {
        return;
    }
    const isSharedOrMounted = isSharedOrMountedStatus(destination);
    const count = {
        folders: items.filter((item) => item.isFolder).length,
        files: 0,
    };
    count.files = items.length - count.folders;

    let text = '';
    if (items.length === 1) {
        text = `${count.folders ? 'папку' : 'файл'} «${items[0].name}»`;
    } else {
        let foldersText = '';
        if (count.folders) {
            foldersText = `${count.folders} ${['выбранную', 'выбранные', 'выбранных'][plural(count.folders)]} ${
                ['папку', 'папки', 'папок'][plural(count.folders)]
            }`;
        }
        const and = count.files && count.folders ? ' и ' : '';
        let filesText = '';
        if (count.files) {
            filesText = `${count.files} ${count.folders ? '' : ['выбранный', 'выбранных', 'выбранных'][plural(count.folders)]} ${
                ['файл', 'файла', 'файлов'][plural(count.files)]
            }`;
        }
        text = `${foldersText}${and}${filesText}`;
    }

    renderComfirmationDialog({
        dataQAId: 'move-files-confirmation-dialog',
        renderHeader: () => 'Перемещение',
        successButtonText: 'Переместить',
        renderContent: () => (
            <>
                <div style={{ overflowWrap: 'break-word' }}>{`Переместить ${text} в папку «${destination.name}»?`}</div>
                {isSharedOrMounted && (
                    <div style={{ overflowWrap: 'break-word', marginTop: '16px', fontWeight: 'normal' }} data-name="alert-text">
                        После переноса файлы будут доступны всем, у кого есть доступ к этой папке.
                    </div>
                )}
            </>
        ),
        onSuccess: () => reduxStore.dispatch(moveRequest({ items, destination: destination.id })),
        onClose: noop,
    });
};

export const removeItemsWithoutDialog = ({ items }) =>
    reduxStore.dispatch(
        removeRequest({
            items,
            withRemoveDialog: false,
        })
    );

// Удаление файлов с окном подтверждения
export const removeItems = ({ items, onSuccess = noopVoid }) => {
    reduxStore.dispatch(removeRequest({ items, withRemoveDialog: true, onSuccess }));
};

export const openShareDialog = ({ item }) =>
    reduxStore.dispatch(
        openPublishDialog({
            item,
            from: EFrom.SHARING,
        })
    );

export const createAutoDeleteFolder = () => reduxStore.dispatch(createNewAutoDeleteFolder());

export const getIsCloudAvailabe = () => IS_CLOUD_CREATED && !IS_CLOUD_BANNED && !IS_CLOUD_LOCKED;
