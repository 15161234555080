import { shallowEqual, useSelector } from 'react-redux';
import { allDocumentsRadars } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { getDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import type { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

/**
 * Хук для отправки технических метрик для страницы документов.
 *
 * @param action {string} Экшен для текущего действия.
 */
export const useGetAllDocumentsXray = (action: string) => {
    const currentStorage = useSelector(getCurrentStorage, shallowEqual);
    const { currentDocType } = useSelector(getDocumentsState, shallowEqual);

    return (xrayType: AllDocumentsXrayTypes) => {
        if (currentStorage === EStorageType.alldocuments) {
            return allDocumentsRadars({ section: currentDocType, action })[xrayType]();
        }
    };
};
