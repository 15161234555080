import { Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { leaveFamilyRequest, removeFamilyMember } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { getFamilyQuotaInfo, isUserWithFamily } from 'reactApp/modules/family/family.selectors';
import { FamilySettingsDropdown } from 'reactApp/sections/FamilyPage/FamilySettingsDropdown/FamilySettingsDropdown';
import { MenuButton } from 'reactApp/ui/MenuButton/MenuButton';
import { useMenuButton } from 'reactApp/ui/MenuButton/useMenuButton';
import { SingleSpaceProgress } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress';
import { ESpaceLegendAlign, ESpaceLegendSize } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress.types';
import { useFamilySpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useFamilySpace';

import styles from './FamilyQuota.css';

export const FamilyQuota = memo(() => {
    const dispatch = useDispatch();
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const withFamily = useSelector(isUserWithFamily);
    const { used, total } = useSelector(getFamilyQuotaInfo);

    const menuProps = useMenuButton();

    const onDelete = useCallback(() => {
        dispatch(removeFamilyMember({ deleteAll: true }));
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.clickButtonDeleteFamily,
        });
    }, [dispatch]);

    const onLeave = useCallback(() => {
        dispatch(leaveFamilyRequest());
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageGuest,
            action: FamilyAnalyticsPageActions.clickButtonLeaveFamily,
        });
    }, [dispatch]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            <div className={styles.titleBlock}>
                <Headline level="2" weight="2" className={styles.title}>
                    Занято {used.value} из {total.value}
                </Headline>
                {withFamily && <MenuButton {...menuProps} marginRight={isPhone ? 12 : 17} />}
            </div>
            <Spacing size={12} />
            <SingleSpaceProgress
                useSpaceHook={useFamilySpace}
                quotaLink={!isPhone}
                legendAlign={ESpaceLegendAlign.left}
                legendSize={ESpaceLegendSize.big}
                rebranding={isRebranding}
            />

            {menuProps.isShowMenu && (
                <FamilySettingsDropdown
                    gaId="family-quota"
                    onDelete={onDelete}
                    onLeave={onLeave}
                    controlRef={menuProps.menuRef}
                    onClose={menuProps.closeMenu}
                    isMemberSettings
                    clearSpace={isPhone}
                />
            )}
        </div>
    );
});

FamilyQuota.displayName = 'FamilyQuota';
