import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

type OfferBannerParams = {
    title?: string;
    text?: string;
    button?: string;
    features?: string[];
    tariffId?: string;
};

const offerBannerAbGroupName: string | undefined = getFeature('ab-offer-banner-group-name');

registerExperiment('banner-offer-redesign', offerBannerAbGroupName, [
    AnalyticEventNames.POPUP_BANNER_OFFER_SHOW,
    AnalyticEventNames.POPUP_BANNER_OFFER_CLICK,
    AnalyticEventNames.POPUP_BANNER_OFFER_CLOSE,
    AnalyticEventNames.POPUP_BANNER_OFFER_BUY,
]);

const offerBannerParamsJson: string = getFeature('ab-offer-banner-params') || '';
let offerBannerParamsParsed: OfferBannerParams = {};

try {
    offerBannerParamsParsed = JSON.parse(offerBannerParamsJson);
} catch {}

export const offerBannerParams: OfferBannerParams = offerBannerParamsParsed;
export const offerBannerTimeout: number = getFeature('ab-offer-banner-timeout-days') || 7;
export const isOfferBannerRedesign: boolean = isFeature('ab-offer-banner-redesign') || false;
