import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import settings from 'Cloud/settings';
import { CHOSEN_EDITOR, IS_DOCUMENTS_DOMAIN, IS_PUBLIC, REACT_START } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import type { GetStateResponse, IHistoryPush, IStatusPage, RouterState } from 'reactApp/modules/router/router.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { decodeURIComponentHelper } from 'reactApp/utils/urlHelper';

const getInitialId = () => {
    if (CHOSEN_EDITOR && !IS_PUBLIC) {
        const url = (settings?.request?.id || decodeURIComponentHelper(settings?.request?.uri))?.split('?')?.shift();

        // В редакторах ИД в request всегда начинается с myoffice/edit/home, потому вырезаем его
        return (
            url
                // На домене доков почему-то не приходит home в урле в request.id, хотя в урле браузера оно есть. На домене клауд все ок.
                ?.replace(
                    IS_DOCUMENTS_DOMAIN && !url.includes(settings?.request?.storage)
                        ? `/${settings?.request?.storage}`.replace('/home', '')
                        : `/${settings?.request?.storage}`,
                    ''
                )
        );
    }

    return settings?.request?.id?.split('?')?.shift() ?? ROOT_FOLDER_ID;
};

const initialState: RouterState = {
    id: getInitialId(),
    params: {},
    storage: settings?.request?.storage || (REACT_START ? EStorageType.start : null),
    parent: '',
    status: null,
    historyReplace: false,
    historyPath: '',
};

export const routeChangeStart = createAction<GetStateResponse>('router/change/start');
export const routeChangeSuccess = createAction<GetStateResponse>('router/change/success');
export const routerStart = createAction<GetStateResponse>('router/start');
export const applicationStart = createAction('router/applicationStart');
export const routeStatusPage = createAction<IStatusPage>('router/statusPage');
export const historyPush = createAction<IHistoryPush>('router/historyPush');
export const changeHistory = createAction<string>('router/changeHistory');
export const setHistoryReplace = createAction<boolean>('router/setHistoryReplace');
export const fixRouteId = createAction<string>('router/fixRouteId');

const processRouteChange = (action: PayloadAction<GetStateResponse>, stateStorage, prevStorage) => {
    const { __parent, storage, params, id } = action.payload;

    return {
        id,
        prevStorage: storage === stateStorage ? prevStorage : stateStorage,
        parent: __parent,
        storage,
        params,
    };
};

export const routerReducer = createReducer<RouterState>(initialState, {
    [routerStart.type]: (state, action: PayloadAction<GetStateResponse>) => ({
        ...state,
        ...processRouteChange(action, state.storage, state.prevStorage),
    }),
    [routeChangeStart.type]: (state, action: PayloadAction<GetStateResponse>) => ({
        ...state,
        ...processRouteChange(action, state.storage, state.prevStorage),
    }),
    [routeChangeSuccess.type]: (state, action: PayloadAction<GetStateResponse>) => ({
        ...state,
        ...processRouteChange(action, state.storage, state.prevStorage),
    }),
    [routeStatusPage.type]: (state, action: PayloadAction<IStatusPage>) => ({
        ...state,
        status: action.payload.status,
    }),
    [setHistoryReplace.type]: (state, action: PayloadAction<boolean>) => ({
        ...state,
        historyReplace: action.payload,
    }),
    [changeHistory.type]: (state, action: PayloadAction<string>) => ({
        ...state,
        historyPath: action.payload,
    }),
    [fixRouteId.type]: (state, action: PayloadAction<string>) => ({
        ...state,
        id: action.payload,
    }),
});
