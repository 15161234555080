import { IS_STATIC_ERROR } from 'server/constants/environment';
import type { ICtx } from 'server/types/context/IContext';

export const getContext = (ctx: ICtx, id: string, param?: string): string | undefined => {
    /**
     * При генерации статических ошибок
     * в контексте есть только переменные, добавленные в процессе сборки
     */
    if (IS_STATIC_ERROR) {
        // @ts-expect-error
        return ctx[id];
    }

    return __get(ctx, id, param);
};
