import { Spacing, Text } from '@vkontakte/vkui';
import img from 'img/user/cloud.png';
import React, { type ReactElement, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { Button } from 'reactApp/ui/Button/Button';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { Logo } from 'reactApp/ui/Mobile/Logo/Logo';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './UserLoginMenu.css';

const DOWNLOAD_APP_LINK = `https://trk.mail.ru/c/kj3vg2?mt_deeplink=${window.location.href}`;

export const UserLoginMenu = memo<IPropsWithPopup>(({ onClose }): ReactElement => {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    useEffect(() => {
        const el = scrollRef?.current;
        scrollLock(el, { reserveScrollBarGap: false });

        return () => {
            scrollUnlock(el);
        };
    }, []);

    const handleAuthClick = useCallback(() => {
        dispatch(
            authPopup({
                closable: false,
                loginRequest: true,
                successPage: `${window.location.href}${window.location.search ? '&' : '?'}action=show-left-menu`,
            })
        );
    }, [dispatch]);

    return (
        <LeftDialog onClose={onClose}>
            <div className={styles.root}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.contentWrapper} ref={scrollRef}>
                    <div className={styles.content}>
                        <img src={img} width={150} />
                        <Spacing size={32} />
                        <Text weight="3" className={styles.title}>
                            Войти в аккаунт
                        </Text>
                        <Spacing size={20} />
                        <Text weight="3" className={styles.text}>
                            Для сохранения и редактирования файлов нужно авторизоваться или скачать приложение Облако
                        </Text>
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <Spacing size={20} />
                        <Button theme="vk" primary onClick={handleAuthClick}>
                            Войти
                        </Button>
                        <Spacing size={8} />
                        <Button theme="vk" href={DOWNLOAD_APP_LINK}>
                            Скачать приложение
                        </Button>
                    </div>
                </div>
            </div>
        </LeftDialog>
    );
});

UserLoginMenu.displayName = 'UserLoginMenu';
