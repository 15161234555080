import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { plural } from 'lib/plural';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useButtons } from 'reactApp/components/UploadBigFileDialog/hooks/useButtons';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getPublicOwner, getPublicUploadLimit } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { NameComponent } from 'reactApp/ui/Name/Name';

import { type Content, type IBigFileModalContent, FILES_PLURAL } from '../UploadBigFileDialog.types';

export const useContent = ({ tariffInfo, numberOfFiles, file, files, callbacks }: IBigFileModalContent): Content => {
    const { uploadLimit } = useSelector(UserSelectors.getUserData);
    const publicUploadLimit = useSelector(getPublicUploadLimit);

    const currentStorage = useSelector(getCurrentStorage);
    const { isPublic } = getStorage(currentStorage);

    const publicOwner = useSelector(getPublicOwner);
    const acOwner = useSelector(getOwner);
    const owner = isPublic ? publicOwner : acOwner;

    const currentUserEmail = useSelector(UserSelectors.getEmail);

    const isStrangerOwner = !!currentUserEmail && !!owner?.email && currentUserEmail !== owner?.email;

    const annotation = useMemo(
        () =>
            isStrangerOwner && isPublic && publicUploadLimit ? (
                <>
                    Файл слишком тяжёлый. Чтобы вы могли загрузить файлы больше {bytesToNDigits(publicUploadLimit, 3).value}, владелец папки
                    должен подключить один из платных тарифов.
                </>
            ) : (
                <>
                    Файл слишком тяжёлый. Чтобы загружать файлы больше {bytesToNDigits(uploadLimit, 3).value}, подключите один из платных
                    тарифов.
                </>
            ),
        [isPublic, isStrangerOwner, publicUploadLimit, uploadLimit]
    );

    const description = useMemo(() => {
        if (files) {
            return <>{FILES_PLURAL[plural(files.number)].replace('{count}', `${files.number}`)}</>;
        }

        return (
            <>
                <span>
                    <NameComponent name={file?.name || ''} truncate />{' '}
                </span>
                не может быть загружен
            </>
        );
    }, [file?.name, files]);

    const header = useMemo(() => {
        if (files) {
            return 'Невозможно загрузить файлы';
        }

        return (
            <>
                Невозможно загрузить файл <NameComponent name={file?.name || ''} truncate />
            </>
        );
    }, [file?.name, files]);

    const buttonActions = useButtons({ tariffInfo, numberOfFiles, files, callbacks });

    return {
        header,
        annotation,
        actions: buttonActions,
        description,
    };
};
