import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './SidebarSubscriptionItem.css';

export enum ELabelTheme {
    pro = 'pro',
    discount = 'discount',
}

interface IProps {
    icon: ReactElement;
    title: ReactElement;
    description: ReactElement;
    label?: string;
    labelTheme?: ELabelTheme;
    onClick?: () => void;
    isDisable?: boolean;
    isRebranding?: boolean;
}

export const SidebarSubscriptionItem = memo<IProps>(
    ({
        icon,
        title,
        description,
        label,
        labelTheme = ELabelTheme.discount,
        onClick = noopVoid,
        isDisable,
        isRebranding = false,
    }): ReactElement => (
        <div
            className={classNames(styles.root, {
                [styles.root_disable]: isDisable,
                [styles.root_rebranding]: isRebranding,
            })}
            onClick={onClick}
        >
            <div
                className={classNames(styles.icon, {
                    [styles.icon_rebranding]: isRebranding,
                })}
            >
                {icon}
            </div>
            <div className={styles.content}>
                {label && (
                    <div
                        className={classNames({
                            [styles.label]: true,
                            [styles[`label_${labelTheme}`]]: !!labelTheme,
                        })}
                    >
                        {label}
                    </div>
                )}
                <div
                    className={classNames(styles.title, {
                        [styles.title_rebranding]: isRebranding,
                    })}
                >
                    {title}
                </div>
                <div className={styles.description}>{description}</div>
            </div>
        </div>
    )
);

SidebarSubscriptionItem.displayName = 'SidebarSubscriptionItem';
