import classNames from 'clsx';
import React, { type FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { initBizCategories, setCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.actions';
import { getBizCategoriesList, getBizCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.selectors';
import { BizCategoryType } from 'reactApp/modules/bizCategories/bizCategories.types';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';

import styles from './BizPageTabMenu.css';

interface Props {
    noMarginBottom?: boolean;
}

const BizPageTabMenu: FC<Props> = ({ noMarginBottom }) => {
    const dispatch = useDispatch();
    const current = useSelector(getBizCurrentCategory);
    const categories = useSelector(getBizCategoriesList);
    const routeId = useSelector(getCurrentRouteId);
    const storage = useSelector(getCurrentStorage);

    const handleOnTypeSelect = useCallback(
        (type: BizCategoryType) => {
            dispatch(setCurrentCategory(type));
        },
        [dispatch]
    );

    const tabs = useMemo(() => categories.filter((c) => c.id !== BizCategoryType.all), [categories]);

    useEffect(() => {
        if (IS_BIZ_USER) {
            dispatch(initBizCategories(storage));
        }
    }, [dispatch, storage]);

    useEffect(() => {
        if (IS_BIZ_USER) {
            dispatch(setCurrentCategory(BizCategoryType.all));

            return () => {
                dispatch(setCurrentCategory(undefined));
            };
        }
    }, [dispatch]);

    useEffect(() => {
        if (IS_BIZ_USER && storage !== EStorageType.attaches) {
            dispatch(setCurrentCategory(BizCategoryType.all));
        }
    }, [dispatch, routeId, storage]);

    if (!IS_BIZ_USER || !current) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootNoMarginBottom]: noMarginBottom,
            })}
            data-qa-id="bizPageTabMenu"
        >
            <PageTabMenu tabs={tabs} current={current} onClick={handleOnTypeSelect} allowClickOnActive noVerticalScroll />
        </div>
    );
};

export default BizPageTabMenu;
