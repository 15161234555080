/* eslint-disable max-lines-per-function */
import { ReactComponent as DownloadIcon } from '@vkontakte/icons/src/svg/16/download_outline_16.svg';
import classNames from 'clsx';
import React, { type FormEvent, type ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import { DOWNLOAD_FILE_OPTIONS, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { DownloadAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { useOutsideClick } from 'reactApp/hooks/useOutsideClick';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { updateWeblinkCountDownloadsRequest } from 'reactApp/modules/modifying/modifying.actions';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

const DEFAULT_LIMITED_DOWNLOADS_COUNT = 10;

interface Props {
    item?: PublishItem;
    isDownloadable?: boolean;
}

export const DownloadsCountDropdown: React.FC<Props> = ({ item, isDownloadable }) => {
    const countRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isInputActive, setIsInputActive] = useState(false);
    const [downloadCountLeft, setDownloadCountLeft] = useState<undefined | number>(undefined);
    const [downloadCountTotal, setDownloadCountTotal] = useState<undefined | number>(undefined);
    const dispatch = useDispatch();
    const isPhone = EnvironmentSelectors.isPhone();
    const checked = downloadCountLeft !== undefined && downloadCountTotal !== undefined ? DownloadAccess.Limited : DownloadAccess.Unlimited;

    const updateCountDownloads = useCallback(
        (count_downloads: number) => {
            if (item && !isNaN(count_downloads)) {
                dispatch(updateWeblinkCountDownloadsRequest({ item, count_downloads }));
            }
        },
        [dispatch, item]
    );

    const handleBlur = useCallback(() => {
        if (inputRef.current?.value === '') {
            updateCountDownloads(0);
            setDownloadCountLeft(0);
        }
        setIsInputActive(false);
    }, [updateCountDownloads]);

    const handleCountWrapperClick = () => setIsInputActive(true);

    const onClickItem = useCallback(
        (id: DownloadAccess) => {
            if (id === DownloadAccess.Limited) {
                return updateCountDownloads(DEFAULT_LIMITED_DOWNLOADS_COUNT);
            }

            updateCountDownloads(0);
        },
        [updateCountDownloads]
    );

    const onDownloadCountTotalChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            updateCountDownloads(parseInt(e.target.value));
        },
        [updateCountDownloads]
    );

    const onDownloadCountSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        inputRef.current?.blur();
    }, []);

    const renderDownloadCountDropdown = useCallback(
        (onClick: void): ReactElement => {
            const list = Object.values(DOWNLOAD_FILE_OPTIONS).map((item) => ({
                id: item.id,
                text: item.text,
                check: item.id === checked,
                qaValue: item.id,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [checked]
    );

    useOutsideClick(countRef, handleBlur);

    useEffect(() => {
        setDownloadCountLeft(item?.count_downloads_left);
    }, [item?.count_downloads_left]);

    useEffect(() => {
        setDownloadCountTotal(item?.count_downloads_total);
    }, [item?.count_downloads_total]);

    const renderDownloadsCount = useCallback((): ReactElement => {
        const item = DOWNLOAD_FILE_OPTIONS[checked];

        return (
            <div className={classNames(styles.rightsItem, { [styles.rightsItemNoMargin]: true })} data-qa-item={QA_VALUE.downloadItem}>
                <div className={styles.titleWrapper}>
                    <span className={styles.icon}>
                        <DownloadIcon />
                    </span>
                    <span className={styles.rightsTitle}>Количество скачиваний</span>
                </div>
                {isDownloadable && (
                    <div className={classNames(styles.dropdown, styles.downloadDropdown)}>
                        <Dropdown
                            withinDialog
                            theme="octavius"
                            qaValue={item?.id}
                            as={SharingNewDropdown}
                            value={item.text}
                            content={renderDownloadCountDropdown}
                            onClickItem={onClickItem}
                            placement={tooltipPlacements.BOTTOM_LEFT}
                            qaValueContent={QA_VALUE.downloadDropdownContent}
                            tooltipOffset={TOOLTIP_OFFSET}
                            animatedTooltip
                            description="Количество скачиваний"
                            tooltipClassName={isPhone ? styles.tooltip : undefined}
                            isPhone={isPhone}
                            noPageOffset={isPhone}
                        />
                    </div>
                )}

                {!isDownloadable && <span className={styles.notDownloadable}>Без ограничений</span>}

                {isDownloadable && checked === DownloadAccess.Limited && (
                    <div ref={countRef} className={styles.downloadInputWrapper}>
                        {isInputActive ? (
                            <form onSubmit={onDownloadCountSubmit}>
                                <input
                                    ref={inputRef}
                                    className={styles.inputCount}
                                    type="number"
                                    defaultValue={downloadCountTotal}
                                    onChange={onDownloadCountTotalChange}
                                    onBlur={handleBlur}
                                    autoFocus
                                />
                            </form>
                        ) : (
                            <div className={styles.countWrapper} onClick={handleCountWrapperClick}>
                                {downloadCountLeft} из {downloadCountTotal}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }, [
        checked,
        isPhone,
        handleBlur,
        onClickItem,
        isInputActive,
        isDownloadable,
        downloadCountLeft,
        downloadCountTotal,
        onDownloadCountSubmit,
        onDownloadCountTotalChange,
        renderDownloadCountDropdown,
    ]);

    return renderDownloadsCount();
};
