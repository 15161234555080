import type { Space } from '@mail/cross-sizes-utils';
import { useSelector } from 'react-redux';
import { UserAndFamilyQuotaSelectors } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';

type UseQuotaInfoHook = (withFamily: boolean) => {
    isFull: boolean;
    total: Space | null;
    usedTotal: Space | null;
    isQuotaLoaded: boolean;
};

export const useQuotaInfo: UseQuotaInfoHook = (withFamily) => {
    const quotaSelectors = withFamily ? UserAndFamilyQuotaSelectors : UserQuotaSelectors;

    const isFull = useSelector(quotaSelectors.isFull);
    const total = useSelector(quotaSelectors.getTotal);
    const usedTotal = useSelector(quotaSelectors.getTotalUsed);
    const { isLoaded: isQuotaLoaded } = useSelector(quotaSelectors.getLoadingState);

    return {
        isFull,
        total,
        usedTotal,
        isQuotaLoaded,
    };
};
