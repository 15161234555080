import type { AvailableEditorsRecord, ExtensionRecord, IconsRecord, SimpleIconRecord } from 'reactApp/modules/editor/editor.types';
import type { ServerEditor } from 'server/helpers/editors/types';

import { getIconClass } from './get-icon-class';

type Options = {
    editExtensions: string[];
    iconObj?: SimpleIconRecord;
};

type ComposeIconsFn = (editor: string, options: Options) => IconsRecord;

const composeIcons: ComposeIconsFn = (editorId, { editExtensions, iconObj }) =>
    editExtensions.reduce(
        (acc, ext) => {
            const iconAll = iconObj || { '16x16': '', '32x32': '' };
            const icon = getIconClass(editorId, 16, ext);
            const iconBig = `ico_${getIconClass(editorId, 32, ext)}`;

            return {
                icon: { ...acc.icon, [ext]: icon },
                iconAll: { ...acc.iconAll, [ext]: iconAll },
                icon_big: { ...acc.icon_big, [ext]: iconBig },
            };
        },
        { icon: {}, iconAll: {}, icon_big: {} } as IconsRecord
    );

type ComposeAvailableEditorsFn = (editors: ServerEditor[], extensions: ExtensionRecord) => AvailableEditorsRecord;

export const composeAvailableEditors: ComposeAvailableEditorsFn = (editors, extensions) =>
    editors.reduce((acc, editor) => {
        const hasDefaultEditor = Object.values(extensions).every((extension) => extension?.editors?.length === 1) && !editor.default;

        const icons = composeIcons(editor.id, { editExtensions: editor.edit_extensions || [], iconObj: editor.icon });
        const availableEditor = { ...editor, ...icons, default: hasDefaultEditor ? 'auto' : editor.default };

        return { ...acc, [editor.id]: availableEditor };
    }, {} as AvailableEditorsRecord);
