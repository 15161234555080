import { type PayloadAction, createReducer } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { acceptWelcomeLA } from 'reactApp/modules/welcome/welcome.module';

import { destroyUser, setIsLoading, setNewUser, setUser, setUserData, setUserState } from './user.actions';
import type { IState, SetIsLoadingAction } from './user.types';

const userConfig = config.get('user');

const initialState: IState = {
    isLoaded: !!userConfig,
    isLoading: false,
    hasError: false,
    data: userConfig,
    isNewUser: false,
};

export const userReducer = createReducer(initialState, {
    [setUser.type]: (state, action: PayloadAction<any>) => {
        if (IS_B2B_BIZ_USER) {
            state.data.newbie = false;
        }

        state.data = { ...action.payload };
    },
    [destroyUser.type]: () => initialState,
    [setNewUser.type]: (state) => {
        state.isNewUser = true;
    },
    [setUserData.type]: (state, action: PayloadAction<any>) => {
        state.data = { ...action.payload };

        if (IS_B2B_BIZ_USER) {
            state.data.newbie = false;
        }

        state.isLoaded = true;
    },
    [setIsLoading.type]: (state, action: PayloadAction<SetIsLoadingAction>) => {
        state.isLoading = action.payload.value;
    },
    [setUserState.type]: (state, action: PayloadAction<IState>) => {
        if (IS_B2B_BIZ_USER) {
            state.data.newbie = false;
        }

        return action.payload;
    },
    [acceptWelcomeLA.type]: (state) => {
        state.data.newbie = false;
    },
});
