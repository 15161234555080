import settings from 'Cloud/settings';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isFeatureAlbumFavorites } from 'reactApp/appHelpers/featuresHelpers';
import { type IEnabledActions, EStorageType } from 'reactApp/modules/storage/storage.types';

export const disabledAll: IEnabledActions = {
    copy: false,
    move: false,
    remove: false,
    rename: false,
    history: false,
    favorites: false,
    publish: false,
    share: false,
    openParentFolder: false,
    download: false,
    add: false,
    view: false,
    sort: false,
    facesFilter: false,
    selectAll: false,
    mount: false,
    sendViaMail: false,
    publishAlbum: false,
    addToAlbum: false,
    createAlbum: false,
    // tempexp_17340-start
    changeDesign: false,
    changeDesignMore: false,
    // tempexp_17340-end
};

export const STORAGE_CONFIG = {
    [EStorageType.home]: {
        ...disabledAll,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        rename: true,
        share: true,
        remove: true,
        history: true,
        download: true,
        add: true,
        sort: true,
        view: true,
        selectAll: true,
        createFolder: true,
        back: true,
        // tempexp_17340-start
        changeDesign: true,
        changeDesignMore: true,
        // tempexp_17340-end
    },
    [EStorageType.search]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        rename: true,
        share: true,
        remove: true,
        history: true,
        download: true,
        view: true,
        selectAll: true,
        add: true,
        back: true,
    },
    [EStorageType.sharedIncoming]: {
        ...disabledAll,
        share: true,
        remove: true,
        download: true,
        selectAll: true,
        mount: true,
        add: true,
        sort: IS_BIZ_USER,
        back: true,
    },
    [EStorageType.sharedLinks]: {
        ...disabledAll,
        publish: true,
        share: true,
        download: true,
        sort: true,
        view: true,
        selectAll: true,
        add: true,
        back: true,
    },
    [EStorageType.sharedAutodelete]: {
        ...disabledAll,
        publish: true,
        share: true,
        view: true,
        favorites: false, // не приходит флаг из апи v2
        openParentFolder: false, // путь до папки не приходит в нужном регистре
        remove: true,
        rename: true,
        move: true,
        selectAll: true,
        add: true,
        back: true,
    },
    [EStorageType.public]: {
        ...disabledAll,
        remove: true,
        view: true,
        sort: true,
        download: true,
        clone: true,
        add: true,
        shareLink: true,
        selectAll: true,
        facesFilter: true,
        back: true,
    },
    [EStorageType.favorites]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        share: true,
        remove: true,
        view: true,
        selectAll: true,
        add: true,
        sort: IS_BIZ_USER,
        download: true,
        back: true,
    },
    [EStorageType.feed]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        move: true,
        share: true,
        remove: true,
        view: true,
        download: true,
        selectAll: true,
        add: true,
        back: true,
    },
    [EStorageType.gallery]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        rename: true,
        share: true,
        remove: true,
        history: true,
        download: true,
        view: true,
        selectAll: true,
        add: true,
        sort: IS_BIZ_USER,
        back: true,
    },
    [EStorageType.documents]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        rename: true,
        share: true,
        remove: true,
        history: true,
        download: true,
        add: true,
    },
    [EStorageType.start]: {
        ...disabledAll,
        publish: true,
        copy: true,
        favorites: true,
        move: true,
        rename: false,
        share: true,
        remove: true,
        history: true,
        add: true,
        sort: IS_BIZ_USER,
    },
    [EStorageType.trashbin]: {
        ...disabledAll,
        restore: true,
        restoreAll: true,
        clear: true,
        selectAll: true,
        add: true,
        sort: IS_BIZ_USER,
        back: true,
    },
    [EStorageType.attaches]: {
        ...disabledAll,
        back: true,
        selectAll: true,
        download: true,
        view: true,
        sendViaMail: true,
        clone: true,
        publish: true,
        add: true,
        sort: IS_BIZ_USER,
    },
    [EStorageType.viewerAttaches]: {
        ...disabledAll,
        download: true,
        view: true,
        sendViaMail: true,
        clone: true,
        publish: true,
    },
    [EStorageType.stock]: {
        ...disabledAll,
        view: true,
        sort: true,
        download: true,
        clone: true,
        selectAll: true,
        back: true,
    },
    [EStorageType.documents]: {
        ...disabledAll,
        openParentFolder: true,
        remove: true,
        move: true,
        copy: true,
        publish: true,
        favorites: true,
        selectAll: true,
        download: true,
        add: true,
        back: true,
    },
    [EStorageType.templates]: {
        ...disabledAll,
        selectAll: false,
        add: true,
    },
    [EStorageType.quotaCleaner]: {
        ...disabledAll,
        selectAll: true,
        back: true,
        view: true,
        download: true,
        quotaCleanerDelete: true,
    },
    [EStorageType.albums]: {
        ...disabledAll,
        selectAll: true,
        remove: true,
        favorites: isFeatureAlbumFavorites,
        publish: true,
        view: true,
        download: true,
        rename: true,
        add: true,
        back: true,
        createAlbum: true,
    },
    [EStorageType.recommend]: {
        ...disabledAll,
        add: true,
    },
    [EStorageType.alldocuments]: {
        ...disabledAll,
        openParentFolder: true,
        publish: true,
        copy: true,
        move: true,
        share: true,
        remove: true,
        view: true,
        download: true,
        selectAll: true,
        createFolder: false,
        add: true,
        history: true,
        rename: true,
        back: true,
    },
    [EStorageType.pdfEdit]: {
        ...disabledAll,
        publish: true,
        favorites: true,
        download: true,
    },
    [EStorageType.integration]: {
        ...disabledAll,
        add: true,
        publish: true,
    },
    [EStorageType.inlineIntegration]: {
        ...disabledAll,
        add: true,
        publish: true,
    },
    [EStorageType.sharingAttach]: {
        ...disabledAll,
    },
};

export const getStorageConfig = (storage: EStorageType): IEnabledActions => {
    const config = STORAGE_CONFIG[storage] || {};

    const oldSettings = settings?.storages?.[storage]?.settings ?? {};

    return { ...oldSettings, ...config };
};
