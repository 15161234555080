import { Icon28CancelOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as Logo } from 'img/logo.svg';
import { ReactComponent as LogoRebranding } from 'img/logo-rebranding.svg';
import React, { type ReactElement, type ReactNode, createRef, PureComponent } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './Screen.css';

export interface Props {
    closable: boolean;
    children: ReactNode;
    withLogo: boolean;
    marginForHeader?: boolean;
    backSecondary?: boolean;

    onClose();
    aboveAll?: boolean;
}

export class Screen extends PureComponent<Props> {
    private rootRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        closable: true,
        withLogo: true,
        onClose: noop,
        aboveAll: true,
        withHeader: false,
        backSecondary: false,
    };

    public componentDidMount(): void {
        scrollLock(this.rootRef.current, { allowTouchMove: true });
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.rootRef.current);
    }

    private handleClose = (): void => {
        this.props.onClose();
    };

    public render(): ReactElement {
        const { closable, children, withLogo, marginForHeader, backSecondary, aboveAll } = this.props;

        return (
            <div
                className={classNames(styles.root, {
                    [styles.rootAboveAll]: aboveAll,
                    [styles.root_marginForHeader]: marginForHeader,
                    [styles.root_backSecondary]: backSecondary,
                    [styles.rebranding]: isRebranding && !backSecondary,
                })}
                ref={this.rootRef}
            >
                {closable && (
                    <div className={styles.closeIcon} onClick={this.handleClose}>
                        <Icon28CancelOutline />
                    </div>
                )}
                {withLogo && <div className={styles.logo}>{isRebranding ? <LogoRebranding /> : <Logo />}</div>}
                <div className={styles.content}>{children}</div>
            </div>
        );
    }
}
