import React, { type FC, type PropsWithChildren, createContext, useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { generateUuidV4, noop } from 'reactApp/utils/helpers';

import { type IProps as TooltipProps, Tooltip } from './Tooltip';
import type { ID } from './Tooltip.types';

interface TooltipState {
    /** Тултип показывается */
    isOpen: boolean;
    /** Пропсы компонента */
    props: TooltipProps;
    /** Идентификатор конкретного вида тултипов(если задан, то одновременно будет отображаться один тултип с данным ID) */
    controlledId?: ID;
}

interface TooltipContextProps {
    tooltips: Map<ID, TooltipState>;
    open: (id: ID, props: TooltipProps, controlledId?: ID) => void;
    close: (id?: ID) => void;
}

export const TooltipContext = createContext<TooltipContextProps>({
    tooltips: new Map<ID, TooltipState>(),
    open: noop,
    close: noop,
});

const changeOpeningState = (tooltips: Map<ID, TooltipState>, id: ID, controlledId?: ID) => {
    return new Map(
        Array.from(tooltips.entries()).map(([itemId, tooltipState]) => {
            if (id === itemId) {
                /** Если тот же id, то открываем */
                return [itemId, { ...tooltipState, isOpen: true }];
            } else if (!!controlledId && controlledId === tooltipState.controlledId) {
                /** Закрываем все, что имеют тот же controlledId */
                return [itemId, { ...tooltipState, isOpen: false }];
            }

            return [itemId, tooltipState];
        })
    );
};

export const TooltipProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [tooltips, setTooltips] = useState(new Map<ID, TooltipState>());

    const handleOpen = useCallback(
        (id: ID = generateUuidV4(), props: TooltipProps, controlledId?: ID) => {
            const temp = new Map(tooltips);
            const tooltip = temp.get(id);

            if (!tooltip) {
                temp.set(id, { props, controlledId, isOpen: true });
            }

            return setTooltips(changeOpeningState(temp, id, controlledId));
        },
        [tooltips]
    );

    const handleClose = useCallback((id: ID = generateUuidV4()) => {
        setTooltips((prev) => {
            const temp = new Map(prev);
            temp.delete(id);

            return temp;
        });
    }, []);

    return (
        <TooltipContext.Provider
            value={{
                tooltips,
                open: handleOpen,
                close: handleClose,
            }}
        >
            {children}
            {Array.from(tooltips.entries())
                .filter(([_, { isOpen }]) => isOpen)
                .map(([id, { props, controlledId }]) => {
                    return (
                        <Portal key={`${id}-${controlledId}`}>
                            <Tooltip {...props} />
                        </Portal>
                    );
                })}
        </TooltipContext.Provider>
    );
};
