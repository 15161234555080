import type { PayloadAction } from '@reduxjs/toolkit';
import { IS_REACT_PROMO_QUOTA_ANDROID_PAGE, IS_REACT_PROMO_QUOTA_IOS_PAGE, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { isRebranding, isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { createFamily, familyUpdateRequest, initFamilyInviteModal } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsOnboardingActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { getFamilyOnboardingSlides } from 'reactApp/modules/family/family.data';
import { buildInviteLink } from 'reactApp/modules/family/family.helpers';
import {
    getFamilyInviteLink,
    getFamilyOverquota,
    getFamilyQuotaPlan,
    isUserFamilyOwner,
    isUserWithFamily,
} from 'reactApp/modules/family/family.selectors';
import type { InitFamilyOnboardingProps } from 'reactApp/modules/family/family.types';
import { openFastCheckout } from 'reactApp/modules/payment/payment.module';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { initProducts, loadProductsSuccess } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { checkMobilePayAvailable } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { store } from 'reactApp/store';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { openFamilyInviteModal } from 'reactApp/ui/Family/FamilyInviteModal/FamilyInviteModal.helpers';
import { openSliderModal } from 'reactApp/ui/SliderModal/SliderModal.helpers';
import { channel } from 'redux-saga';
import { call, cancel, put, select, take } from 'typed-redux-saga';

function* checkPlatformPay(id: string) {
    const { isMobilePayAvailable } = yield* call(checkMobilePayAvailable, IS_REACT_PROMO_QUOTA_IOS_PAGE, IS_REACT_PROMO_QUOTA_ANDROID_PAGE);

    if ((IS_REACT_PROMO_QUOTA_ANDROID_PAGE || IS_REACT_PROMO_QUOTA_IOS_PAGE) && !isMobilePayAvailable) {
        revealPlatformPopup(id, IS_REACT_PROMO_QUOTA_IOS_PAGE, IS_REACT_PROMO_QUOTA_ANDROID_PAGE, isRebrandingQuotaLanding);
        yield cancel();
    }
}

function* redirectToFamilyPage() {
    closePopupHelper(popupNames.SLIDER_MODAL);
    yield put(historyPush({ id: getIdByStorage(EStorageType.family) }));
    yield cancel();
}

export function* onboardingOverQuota(redirect?: boolean, isRebrandingQuotaLanding?: boolean) {
    const afterUpdateChannel = channel();
    const closeChannel = channel();
    const { isLoaded } = yield* select(ProductsSelectors.getLifeCycleState);

    if (!isLoaded) {
        yield put(initProducts());
        yield take(loadProductsSuccess);
    }

    const over = yield* select(getFamilyOverquota);
    const { product } = yield* select(ProductsSelectors.getProductAndTariffByQuota, over.original, EProductPeriod.year, false);
    const productId = product?.id || PROMO_TARIFFS.familyOnboarding;

    const storage = yield select(getCurrentStorage);
    const { isFamilyPage } = getStorage(storage);

    yield call(checkPlatformPay, productId);

    yield put(
        openFastCheckout({
            productId,
            onSuccess: () => {
                // обновляем данные на странице семьи после покупки
                if (isFamilyPage) {
                    store.dispatch(familyUpdateRequest());
                }
            },
            afterUpdate: () => {
                afterUpdateChannel.put(true);
            },
            onClose: () => {
                closePopupHelper(popupNames.SLIDER_MODAL);
                closeChannel.put(true);
            },
        })
    );

    yield take(afterUpdateChannel);
    yield take(closeChannel);

    if (redirect) {
        afterUpdateChannel.close();
        closeChannel.close();
        yield call(redirectToFamilyPage);
    }

    const withFamily = yield* select(isUserWithFamily);

    if (withFamily) {
        yield put(initFamilyInviteModal({ isRebrandingQuotaLanding }));
    } else {
        yield put(createFamily({ isRebrandingQuotaLanding }));
    }

    afterUpdateChannel.close();
    closeChannel.close();
}

function* onboardingPaidUser(redirect?: boolean, isRebrandingQuotaLanding?: boolean) {
    if (redirect) {
        yield call(redirectToFamilyPage);
    }

    const isFamilyOwner = yield* select(isUserFamilyOwner);
    if (isFamilyOwner) {
        const { link } = yield* select(getFamilyInviteLink);
        yield openFamilyInviteModal({ link: buildInviteLink(link), isRebrandingQuotaLanding });
    } else {
        yield put(createFamily({ isRebrandingQuotaLanding }));
    }
}

function* onboardingFreeUser(redirect?: boolean, isRebrandingQuotaLanding?: boolean) {
    const productId = PROMO_TARIFFS.familyOnboarding;
    const afterUpdateChannel = channel();
    const closeChannel = channel();
    const { isLoaded } = yield* select(ProductsSelectors.getLifeCycleState);

    if (!isLoaded) {
        yield put(initProducts());
        yield take(loadProductsSuccess);
    }

    yield call(checkPlatformPay, productId);

    yield put(
        openFastCheckout({
            productId,
            afterUpdate: () => {
                afterUpdateChannel.put(true);
            },
            onClose: () => {
                closePopupHelper(popupNames.SLIDER_MODAL);
                closeChannel.put(true);
            },
        })
    );

    yield take(afterUpdateChannel);
    yield take(closeChannel);

    if (redirect) {
        afterUpdateChannel.close();
        closeChannel.close();
        yield call(redirectToFamilyPage);
    }

    // @ts-ignore
    yield put(updateUser());
    yield put(createFamily({ isRebrandingQuotaLanding }));

    afterUpdateChannel.close();
    closeChannel.close();
}

export function* familyOnboarding(action: PayloadAction<InitFamilyOnboardingProps>) {
    const { redirect = false, isRebrandingQuotaLanding = false } = action?.payload || {};

    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const isSocial = yield* select(UserSelectors.isSocialUser);
    const isSocialUserWithoutEmail = yield* select(isEmailAbsent);

    const isMobile = EnvironmentSelectors.isMobile();

    const familyChannel = channel();

    const { isLoaded: isUserQuotaLoaded } = yield* select(UserQuotaSelectors.getLoadingState);
    if (!isUserQuotaLoaded) {
        yield take(loadUserQuotaSuccess);
    }

    const isAlmostFull = yield* select(UserQuotaSelectors.isAlmostFull);
    const isFull = yield* select(UserQuotaSelectors.isFull);
    const isOverQuota = isAlmostFull || isFull;

    const subscriptionsQuota = yield* select(getFamilyQuotaPlan);
    const familyOnboardingSlides = getFamilyOnboardingSlides(isPaidUser, subscriptionsQuota, isRebrandingQuotaLanding || isRebranding);

    yield openSliderModal({
        slides: familyOnboardingSlides,
        primaryButtonText: isPaidUser && !isOverQuota ? 'Поделиться местом' : 'Оформить подписку',
        qaId: isMobile ? 'family-onboarding-mobile' : 'family-onboarding',
        primaryButtonOnClick: (step) => {
            familyChannel.put(true);
            sendFamilyAnalytics({
                eventCategory: FamilyAnalyticsCategory.onboarding,
                action: FamilyAnalyticsOnboardingActions.try,
                guest: false,
                creator: isPaidUser && !isOverQuota,
                creator_to_tariff: isOverQuota || !isPaidUser,
                step,
            });
        },
        onShow: (step) => {
            sendFamilyAnalytics({
                eventCategory: FamilyAnalyticsCategory.onboarding,
                action: FamilyAnalyticsOnboardingActions.show,
                guest: false,
                creator: isPaidUser && !isOverQuota,
                creator_to_tariff: isOverQuota || !isPaidUser,
                step,
            });
        },
        onCancel: (step) => {
            sendFamilyAnalytics({
                eventCategory: FamilyAnalyticsCategory.onboarding,
                action: FamilyAnalyticsOnboardingActions.close,
                guest: false,
                creator: isPaidUser && !isOverQuota,
                creator_to_tariff: isOverQuota || !isPaidUser,
                step,
            });
        },
        isRebrandingQuotaLanding,
    });

    yield take(familyChannel);

    if (isSocial && isMobile && isSocialUserWithoutEmail) {
        closePopupHelper(popupNames.SLIDER_MODAL);

        const socialChannel = channel();

        yield* call(renderConfirmEmailMobile, {
            title: 'Почта для связи',
            text: 'Будем писать только по делу: пришлём чеки об оплате, предупредим, если место заканчивается',
            showLA: false,
            buttonText: 'Добавить',
            placeholder: 'Почтовый адрес',
            closable: true,
            onSuccess: () => socialChannel.put(true),
        });

        yield take(socialChannel);
    }

    if (isOverQuota) {
        // Пользователю с оверквотой сначала предлагаем купить тариф для выхода из оверквоты.
        yield* call(onboardingOverQuota, redirect, isRebrandingQuotaLanding);
    } else if (isPaidUser) {
        // Создаем семью / показываем окно с инвайт-ссылкой.
        yield* call(onboardingPaidUser, redirect, isRebrandingQuotaLanding);
    } else {
        // Бесплатнику сначала предлагаем купить тариф.
        yield* call(onboardingFreeUser, redirect, isRebrandingQuotaLanding);
    }

    familyChannel.close();
}
