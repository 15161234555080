import { Icon16InfoOutline } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { TOP_TOOLBAR_ID } from 'reactApp/constants/magicIdentificators';
import { getFilesWithSelectedFace } from 'reactApp/modules/faces/faces.selectors';
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getSelectedItems, groupedIds, isFolderWithAuthor } from 'reactApp/modules/storage/storage.selectors';
import { type EStorageType, EActions } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { useCustomActions } from 'reactApp/ui/Toolbar/customActions/useCustomActions.new';
import { shouldCollapse } from 'reactApp/ui/Toolbar/Toolbar.helpers';
import { type ToolbarProps, type TToolbarItem, ToolbarGapMode } from 'reactApp/ui/Toolbar/Toolbar.types';
import { DelimiterItem } from 'reactApp/ui/Toolbar/ToolbarItem/DelimiterItem';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { useActions } from 'reactApp/ui/Toolbar/useActions.new';
import { useFilterActions } from 'reactApp/ui/Toolbar/useFilterActions';
import { Icon20MenuOutline } from 'reactApp/ui/VKUIIcons';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { FloatingTooltip } from '../FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from '../FloatingTooltip/FloatingTooltip.types';
import styles from './Toolbar.new.css';

const MAX_NUM_OF_BUTTON_POSITION_TRIES = 10;

// eslint-disable-next-line max-lines-per-function
export const ToolbarNew = memo(
    // eslint-disable-next-line max-lines-per-function
    ({ gapMode, toolbarType, isEbookReader, color, onBookmark, onDownload, isResponsiveEnable }: ToolbarProps): ReactElement => {
        const rootRef = useRef<HTMLDivElement | null>(null);
        const leftRef = useRef<HTMLDivElement | null>(null);
        const rightRef = useRef<HTMLDivElement | null>(null);
        const counterRef = useRef(0);
        const timerRef = useRef<number | null>(null);

        const downloadButtonRef = useRef<HTMLDivElement>(null);

        const dispatch = useDispatch();
        const storage = useSelector(getCurrentStorage) as EStorageType;
        const { isPublic, isIntegration } = getStorage(storage);
        const isSuccessAuthWhenDownload = useSelector(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);
        const items = useSelector((state: RootState) => groupedIds(state, storage as EStorageType, false)) as string[];

        let filters = useFilterActions({ storage, toolbarType });

        const currentRouteId = useSelector(getCurrentRouteId);
        if (currentRouteId === '/') {
            filters = filters.filter((item) => item.id !== 'author');
        }

        const actions = useActions({ storage, toolbarType, isEbookReader, downloadButtonRef, onDownload });
        const { left: leftCustomActions, right: rightCustomActions } = useCustomActions({ storage, isEbookReader, onBookmark });
        const filesWithFaces = useSelector(getFilesWithSelectedFace);
        const isPublicUpload = useSelector(isPublicUploadEnabled);
        const folderWithAuthors = useSelector(isFolderWithAuthor);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const facesIsOff = !filesWithFaces.length;
        const selectedItems = useSelector(getSelectedItems);

        const onSidebarTogglerClick = useCallback(() => {
            dispatch(sidebarToggle());
        }, [dispatch]);

        const showAuthorsFilter = (folderWithAuthors || (isPublic && isPublicUpload)) && facesIsOff && !isAnonymous;

        const [priority, setPriority] = useState(0);

        let leftActions = [...leftCustomActions, ...actions];
        let rightActions = [...filters, ...rightCustomActions];

        if (items.length === 0 && !showAuthorsFilter) {
            leftActions = [...actions];
            rightActions = [];
        }

        if (isEbookReader) {
            rightActions = [...leftCustomActions, ...actions, ...rightCustomActions];
            leftActions = [];
        }

        const setResetStackCounter = () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = window.setTimeout(() => {
                counterRef.current = 0;
            }, 1000);
        };

        const findButtonPriority = useCallback((): void => {
            if (leftActions.length && leftRef?.current && rootRef?.current) {
                const leftWidth = leftRef.current.getBoundingClientRect()?.width;
                const rightWidth = rightRef?.current ? rightRef?.current.getBoundingClientRect()?.width : 0;
                const rootWidth = rootRef.current.getBoundingClientRect()?.width;

                const emptyPlace = rootWidth - leftWidth - rightWidth;

                if (emptyPlace <= 10 && priority < 20) {
                    if (counterRef.current > MAX_NUM_OF_BUTTON_POSITION_TRIES) {
                        // useState для этого каунтера использовать нельзя, так как его изменение вызывает перерендер и сбрасывая каунтер, мы опять попадаем в начало этого цикла
                        setResetStackCounter();
                        return;
                    }
                    setPriority(priority + 1);
                    counterRef.current++;
                } else if (emptyPlace > 150 && priority > 0) {
                    setPriority(priority - 1);
                    counterRef.current++;
                }

                // Если будет зацикливание в рендере тулбара, то в вызове только в этом месте поднять таймаут с 1000
                setResetStackCounter();
            }
        }, [leftActions.length, priority]);

        const renderItem = useCallback(
            ({ Item, itemProps, ...data }: TToolbarItem, index) => {
                if (Item) {
                    return <Item key={itemProps?.id || index} color={color} parentRef={rootRef} {...itemProps} />;
                }

                if (data.id === EActions.delimiter) {
                    return <DelimiterItem color={color} />;
                }

                return (
                    <ToolbarItem
                        key={data.id}
                        ref={data.ref}
                        id={data.id}
                        text={data.text}
                        onClick={data.onClick}
                        hint={data.hint}
                        icon={data.icon}
                        color={color}
                        collapse={
                            isEbookReader && data.id !== EActions.publish
                                ? true
                                : shouldCollapse(data, priority, Boolean(isResponsiveEnable))
                        }
                        list={data.list}
                        parentRef={rootRef}
                        dropdownTheme={data.dropdownTheme}
                        dropdownPosition={data.dropdownPosition}
                        withoutDropdownIcon={data.withoutDropdownIcon}
                        openDropdownByArrow={data.openDropdownByArrow}
                        tooltipId={data?.tooltipId}
                        disabled={data.disabled}
                    />
                );
            },
            [priority, isResponsiveEnable, color]
        );

        const handleOnResize = useMemo(() => throttle(findButtonPriority, 500), [findButtonPriority]);

        useEffect(handleOnResize, [priority, leftActions.length, rightActions.length]);

        useResizeObserver({ onResize: handleOnResize, ref: rootRef });

        return (
            <div className={styles.root} ref={rootRef} id={TOP_TOOLBAR_ID} data-qa-id="toolbar">
                {/* tempexp_15344-next-line */}
                <FeatureSelector
                    selector={getFeatureRequiredSignUpWhenDownloading}
                    control={<></>}
                    variant1={
                        IS_PUBLIC &&
                        !reDownloadController.isDownloadHappened() && (
                            <FloatingTooltip
                                target={downloadButtonRef.current}
                                title=""
                                text="Теперь файл можно скачать"
                                placement={ETooltipPlacement.bottom}
                                closeOnOutsideClick={false}
                                qaId="required-auth-when-download"
                                onShow={() => requiredAuthorizationHelpers.sendGa('shw-redwnld-tltip', 'variant1', 'folder')}
                                onClick={() => requiredAuthorizationHelpers.sendGa('clck-redwnld-tltip', 'variant1', 'folder')}
                                onClose={() => requiredAuthorizationHelpers.sendGa('cls-redwnld-tltip', 'variant1', 'folder')}
                            />
                        )
                    }
                    variant2={<></>}
                    options={{ skipCondition: { variant1: () => !isSuccessAuthWhenDownload } }}
                />
                {isIntegration && !!selectedItems?.length && (
                    <div className={styles.disclaimer}>
                        <div>
                            <Icon16InfoOutline width={20} height={21} fill="var(--vkui--color_icon_accent)" />
                        </div>
                        <Text>Просматривать смогут все по ссылке</Text>
                    </div>
                )}

                {isResponsiveEnable && <ToolbarItem id="sidebarToggler" icon={<Icon20MenuOutline />} onClick={onSidebarTogglerClick} />}

                {Boolean(leftActions?.length) && (
                    <div
                        className={classNames(styles.left, {
                            [styles.wideGap]: gapMode === ToolbarGapMode.wide,
                        })}
                        ref={leftRef}
                    >
                        {leftActions.map(renderItem)}
                    </div>
                )}

                {Boolean(rightActions?.length) && (
                    <div
                        className={classNames(styles.right, {
                            [styles.wideGap]: gapMode === ToolbarGapMode.wide,
                        })}
                        ref={rightRef}
                    >
                        {rightActions.map(renderItem)}
                    </div>
                )}
            </div>
        );
    }
);

ToolbarNew.displayName = 'Toolbar';
