import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

export interface PublicThemeSetApiCallType {
    public_id: string;
    theme_id: number;
}

export class PublicThemeSetApiCall extends APICallV4<PublicThemeSetApiCallType> {
    _method = 'public/theme/set';
    _type: Method = 'post';
    _retriesCount = 0;
}
