import type { ICloudFlags } from 'server/types/ICloudFlags';

export enum EAccountType {
    REGULAR = 'regular',
    PDD = 'pdd',
    SOCIAL = 'social',
}

export type quotaStates = 'new' | 'started' | 'blocked' | 'unblocked' | 'cleared';

export interface IOverquotaState {
    state?: quotaStates;
    // Дата временной блокировки оверквотера в облаке
    block_time?: number;
    // Дата очистки файлов в облаке
    clear_time?: number;
}

interface IUserCloud {
    file_size_limit: number;
    frozen?: boolean;
    billing?: {
        enabled: boolean;
        basequota: number;
    };
    space: {
        overquota: boolean;
        bytes_total: number;
        bytes_used: number;
        basequota: number;
    };
    tariff?: {
        is_paid?: boolean;
        end_time?: number;
    };
    profile?: {
        overquota?: IOverquotaState;
    };
    domain_quota?: {
        type?: number;
    };
}

interface IUserFlags {
    domain_logo: boolean;
    hide_ad_in_mail_web: boolean;
    short_headline: boolean;
}

export interface IUser {
    newbie: boolean;
    // Пустая строка у анонима
    login?: string;
    domain?: string;
    locked: boolean;
    banned: boolean;
    cloudflags?: ICloudFlags;
    account_type?: EAccountType;
    account_verified: boolean;
    cloud?: IUserCloud;
    account_version?: number;
    flags: IUserFlags;
    theme_main_page?: 'dark' | 'light' | 'system';
    billing_bitmask?: number;
}
