import { bytesToNDigits } from '@mail/cross-sizes-utils';
import config from 'Cloud/config';
import { CLOUD_SOURCE } from 'reactApp/constants/paymentUTM';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import type { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { getUtmString } from 'reactApp/utils/urlHelper';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

export const openDisabledFeaturePopupHelper = ({ disabledFeature }: { disabledFeature: DisabledFeature }) => {
    const { bytes_used, bytes_total } = config.get('user.cloud.space') || {};
    const isBizUser = Boolean(config.get('BIZ_USER'));
    const over = bytesToNDigits(bytes_used >= bytes_total ? bytes_used - bytes_total : 0, 3);

    const link = getQuotaPromoUrl({
        query: getUtmString({
            utm_source: CLOUD_SOURCE,
            utm_medium: 'web_union_quota_landing',
            utm_campaign: `disable_feature_${disabledFeature}`,
        }),
    });

    openPopupHelper({
        popupName: popupNames.DISABLED_FEATURE,
        onClose: noop,
        data: {
            onBuy: () => {
                if (isDocumentsDomain(window.location.hostname)) {
                    opener(link);
                } else {
                    reduxStore.dispatch(
                        loadProductsAndOpenFastCheckout({
                            quota: over.original,
                            paySource: `disable_feature_${disabledFeature}`,
                            onSuccess: () => closePopupHelper(popupNames.DISABLED_FEATURE),
                        })
                    );
                }
            },
            disabledFeature,
            overquotaSize: `${over.space} ${over.units}`,
            isBizUser,
        },
    });
};
