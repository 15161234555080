import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { isRebrandingQuotaLanding, summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Button } from 'reactApp/ui/Button/Button';
import { renderMobileDiscountsModal } from 'reactApp/ui/Mobile/DiscountsModal/DiscountsModal.helpers';

import styles from './Header.css';

export const Header = memo(() => {
    const onActionClick = useCallback(() => {
        sendQuotaGa({ action: 'action', label: 'click' });
        renderMobileDiscountsModal({ isRebrandingQuotaLanding });
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_action]: Boolean(ACTION_PROMO),
                [styles.summerPromo]: summerPromotion,
                [styles.root_rebranding]: isRebrandingQuotaLanding,
            })}
        >
            <div className={styles.title}>
                Тарифы <br /> Mail Space
            </div>
            <Button
                primary={!!summerPromotion}
                theme={summerPromotion ? 'vk' : undefined}
                className={styles.button}
                onClick={onActionClick}
            >
                Акции
            </Button>
        </div>
    );
});

Header.displayName = 'Header';
