import React, { type FC } from 'react';
import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';

import styles from './LicenseBlock.css';

interface LicenseBlockProps {
    buttonText?: string;
}

export const LicenseBlock: FC<LicenseBlockProps> = ({ buttonText = 'Продолжить' }) => {
    return (
        <div className={styles.root}>
            Нажимая «{buttonText}», вы соглашаетесь с тем, что Облако использует{' '}
            <a href="https://help.mail.ru/legal/terms/cloud/recommendation">рекомендательные технологии</a>, и принимаете условия{' '}
            <a href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`} target="_blank" rel="noreferrer">
                Лицензионного соглашения
            </a>{' '}
            и{' '}
            <a href="https://help.mail.ru/legal/terms/cloud/privacy" target="_blank" rel="noreferrer">
                Политики конфиденциальности
            </a>
        </div>
    );
};
