import React, { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { addItemsFromCloudChangeFolder } from 'reactApp/modules/albums/albums.actions';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getRootFolderList } from 'reactApp/modules/home/home.selectors';
import { searchLoadInFolderRequest, searchParamsUpdatePath } from 'reactApp/modules/search/search.module';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { SelectFromCloudBreadcrumbs } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudBreadcrumbs/SelectFromCloudBreadcrumbs';
import type { RootState } from 'reactApp/store';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { TreeComponent } from 'reactApp/ui/TreeComponent/TreeComponent';
import { ETreeNodeMode, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeRootConfig } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import { getFoldersTree, loadFolder } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { noop } from 'reactApp/utils/helpers';

import styles from './SelectFromCloudTree.css';

interface SelectFromCloudTreeProps {
    mod: SelectFromCloudContentMod;
    selectedId: string;
    setSelectedId: Dispatch<SetStateAction<string>>;
}

export const SelectFromCloudTree: FC<SelectFromCloudTreeProps> = ({ mod, selectedId, setSelectedId }) => {
    const dispatch = useDispatch();
    const datalistRef = useRef<HTMLDivElement>(null);

    const treeItems = useSelector((state: RootState) =>
        getFoldersTree(state, [treeRootConfig.find((item) => item.id === ETreeRootIds.home) || treeRootConfig[0]], true, false)
    );

    const rootFolderList = useSelector(getRootFolderList);

    const handleClick = useCallback(
        ({ id }) => {
            setSelectedId(id);

            dispatch(searchParamsUpdatePath({ id }));

            if (mod === SelectFromCloudContentMod.tree) {
                dispatch(addItemsFromCloudChangeFolder({ folder: id }));
            }

            if (mod === SelectFromCloudContentMod.search) {
                dispatch(searchLoadInFolderRequest({ id }));
            }
        },
        [setSelectedId, dispatch, mod]
    );

    const handleExpand = useCallback(
        (data: { id: string; storage: EStorageType; isOpen: boolean; selectedStorage: EStorageType }) => {
            loadFolder({ ...data, dispatch });
        },
        [dispatch]
    );

    useEffect(() => {
        if (!rootFolderList) {
            dispatch(loadHomeFolderRequest({ id: ROOT_FOLDER_ID, isFolder: true }));
        }
    }, []);

    useEffect(() => {
        // При выходе из поиска загружаем данные из последней выбранной папки.
        if (mod === SelectFromCloudContentMod.tree) {
            dispatch(addItemsFromCloudChangeFolder({ folder: selectedId }));
        }
    }, [mod, dispatch, selectedId]);

    return (
        <div className={styles.content}>
            <div className={styles.leftCol}>
                <TreeComponent
                    items={treeItems}
                    selectedId={EStorageType.home + selectedId}
                    treeNodeMod={ETreeNodeMode.albumsDlg}
                    onClick={handleClick}
                    onExpand={handleExpand}
                    gaCategory="selectFromCloudAlbum"
                />
            </div>
            <div className={styles.righCol}>
                <SelectFromCloudBreadcrumbs id={selectedId} onClick={handleClick} mod={mod} />
                <div className={styles.datalist} ref={datalistRef}>
                    <DataList
                        storage={mod === SelectFromCloudContentMod.search ? EStorageType.search : EStorageType.gallery}
                        goTo={noop}
                        viewMode={EViewMode.squares}
                        isPopup
                        customScrollElement={datalistRef.current}
                        withinDialog
                    />
                </div>
            </div>
        </div>
    );
};
