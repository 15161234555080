import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import type { SearchItem } from 'reactApp/modules/search/search.types';
import { getSelectedItems } from 'reactApp/modules/selections/selections.items.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const useSendSearchActionBarAnalytics = (storage: EStorageType) => {
    const { query, xPageId, xReqId } = useSelector(getSearchRequestParams);
    const selectedItems = useSelector(getSelectedItems);

    return useCallback(
        (action: string) => {
            const { isSearch } = getStorage(storage);

            if (!isSearch) {
                return;
            }

            const storeItems = selectedItems as SearchItem[];
            newSendSearchRadarAnalytics({
                eventCategory: ECategoryGa.toolbar_search,
                action,
                count_files: storeItems.length,
                searchParams: {
                    search_phrase: query,
                    page_id: xPageId,
                    req_id: xReqId,
                },
                items: storeItems.map((item) => ({
                    file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                    file_id: item.id,
                    type: item.kind,
                    pos: item.pos,
                    placement: 'srchSrc' in item && item.srchSrc,
                })),
            });
        },
        [query, selectedItems, storage, xPageId, xReqId]
    );
};
