import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeChangeStart } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { ActionsBar, EActionsMode } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { UploadDropArea } from 'reactApp/ui/UploadDropArea/UploadDropArea';
import { UploadListMobileContainer } from 'reactApp/ui/UploadList/UploadListMobile';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './MobileGalleryPage.css';

export const MobileGalleryPage = memo(function MobileHomePage(): ReactElement {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const showUploader = useSelector(isUploaderVisible);
    const showActionsBar = selectedIds.length > 0;

    const showUploadArea = !showUploader && !showActionsBar;

    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-gallery' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'touch-gallery' });
        dispatch(routeChangeStart({ id: '/gallery', storage: EStorageType.gallery, params: {}, __isFolder: true, url: '', __parent: '' }));
    }, []);

    return (
        <div className={classNames(styles.root, { [styles.root_bottomPadding]: showUploadArea })}>
            <Toolbar showMenu />
            <div className={styles.breacrumbs}>
                <Breadcrumbs />
            </div>
            <div className={classNames(styles.content, styles[`content_${viewMode}`])}>
                <Datalist />
            </div>
            {showActionsBar && <ActionsBar mode={EActionsMode.PRIMARY} />}
            {showUploader && <UploadListMobileContainer />}
            {showUploadArea && (
                <UploadDropArea subscribeToInput={subscribeToInput} unsubscribeOfInput={unsubscribeOfInput} gaCategory={'mobile'} />
            )}
        </div>
    );
});
