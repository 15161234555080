import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

/** CLOUDWEB-17235: Максимальный размер рекламаного сайдбара */
export enum HomeSidebarAB {
    /** Сайдбар 160 справа */
    Small = 1,
    /** Сайдбар 240 справа */
    Big = 2,
}

/** CLOUDWEB-17235: максимальный размер сайдбара на домашней */
export const homeSidebarVersion = getFeature('ab-home-sidebar') as HomeSidebarAB;

/** CLOUDWEB-16889, CLOUDWEB-17235: АБ с заменой рекламной строки в хомяке на сайдбар */
export const isHomeSidebar = isFeature('ab-home-sidebar') && Object.values(HomeSidebarAB).includes(homeSidebarVersion);

/** CLOUDWEB-17234: ширина при которой показывается широкая колонка */
export const homeSidebarBigBreakpoint = (getFeature('ab-home-sidebar-big-breakpoint') as number) || 1440;

/** CLOUDWEB-17234: ширина при которой показывается узкая колонка */
export const homeSidebarSmallBreakpoint = (getFeature('ab-home-sidebar-small-breakpoint') as number) || 1270;

/** CLOUDWEB-17234: название группы экспа */
const homeSidebarGroup = getFeature('ab-home-sidebar-group') as string | undefined;

registerExperiment(
    'home-sidebar',
    homeSidebarGroup,
    [
        AnalyticEventNames.DISABLE_BUTTON_SHOW,
        AnalyticEventNames.DISABLE_BUTTON_CLICK,
        AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT,
        AnalyticEventNames.DISABLE_BUTTON_PAYMENT,
    ],
    {
        sendXrayArgs: {
            [AnalyticEventNames.DISABLE_BUTTON_SHOW]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_CLICK]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_PAYMENT]: ({ id }) => ({
                iData: id,
            }),
        },
    }
);
