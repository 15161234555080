import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getIsYearFilter, getStorageSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, type EStorageType, EActions } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { getBackItem } from 'reactApp/ui/Toolbar/customActions/getBackItem';
import { getBookmarkItem } from 'reactApp/ui/Toolbar/customActions/getBookmarkItem';
import { getDeleteItem } from 'reactApp/ui/Toolbar/customActions/getDeleteItem';
import { getFullScreenItem } from 'reactApp/ui/Toolbar/customActions/getFullScreenItem';
import { getOptionsItem } from 'reactApp/ui/Toolbar/customActions/getOptionsItem';
import { TOOLBAR_CONFIG } from 'reactApp/ui/Toolbar/Toolbar.config';
import type { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

import { getYearFilterItem } from './getYearFilterItem';

export const useCustomActions = ({
    storage,
    isEbookReader,
    onBookmark,
}: {
    storage: EStorageType;
    isEbookReader?: boolean;
    onBookmark?(isActiveBookmark: boolean): void;
}) => {
    const left: TToolbarItem[] = [];
    const right: TToolbarItem[] = [];

    const currentStorage = useSelector(getCurrentStorage);

    const { isQuotaCleaner } = getStorage(currentStorage);

    const selectedItems: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const isYearFilter = useSelector(getIsYearFilter);

    const settings = TOOLBAR_CONFIG[storage];
    if (!settings) {
        return { left, right };
    }

    if (settings.back && isQuotaCleaner) {
        left.push(getBackItem(storage));
    }

    if (settings[EActions.yearFilter] && isYearFilter) {
        right.push(getYearFilterItem(storage));
    }

    if (settings.delete) {
        right.push(getDeleteItem(storage, selectedItems));
    }

    if (isEbookReader) {
        right.push(getFullScreenItem());

        left.push(getBookmarkItem(onBookmark));
        left.push(getOptionsItem());
        left.push({ id: EActions.delimiter });
    }

    return { left, right };
};
