import { Button } from '@vkontakte/vkui';
import React, { type FC } from 'react';

import styles from './ImageEditorSurveyFooter.css';

interface Props {
    handleCloseClick: () => void;
    handleSubmitClick: () => void;
}

export const ImageEditorSurveyFooter: FC<Props> = ({ handleCloseClick, handleSubmitClick }) => {
    return (
        <div className={styles.footer}>
            <Button onClick={handleSubmitClick} size="m" appearance="overlay" className={styles.footer_button}>
                Готово
            </Button>
            <Button onClick={handleCloseClick} size="m" appearance="overlay" mode="secondary" className={styles.footer_button}>
                Закрыть
            </Button>
        </div>
    );
};
