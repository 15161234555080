import type { IUnpackOnClientWorker, UnpackOptions } from 'Cloud/webWorkers/types';
import { wrap } from 'comlink';
import { logger } from 'lib/logger';
import { BUILD_URLS } from 'reactApp/appHelpers/configHelpers';
import { sendXray } from 'reactApp/utils/ga';

const path = BUILD_URLS?.js;
const pathToWorker = `${path}unpackWorker.js`;
const pathToWasm = path?.startsWith('https://') ? path : window.location.origin + path;

class UnpackOnClientController {
    private ableWorker = false;
    private unpackOnClientWorker;
    private isInited;

    static WorkerCalculatorWrapped;

    public async init() {
        if (this.isInited) {
            return;
        }

        try {
            if (!UnpackOnClientController.WorkerCalculatorWrapped) {
                await new Promise<void>((resolve, reject) => {
                    const oReq = new XMLHttpRequest();
                    oReq.addEventListener('error', function (error) {
                        logger.error('Worker load error:', error);
                        sendXray('worker-error-load');
                        reject();
                    });
                    oReq.addEventListener('load', function () {
                        try {
                            sendXray('worker-load');
                            if (!window.URL?.createObjectURL) {
                                sendXray('worker-error-blob');
                            }

                            const blob = window.URL.createObjectURL(new Blob([this.responseText]));
                            const worker = new Worker(blob);

                            UnpackOnClientController.WorkerCalculatorWrapped = wrap<{ new (): Promise<IUnpackOnClientWorker> }>(worker);
                            resolve();
                        } catch (err) {
                            sendXray('worker-error-crt');
                            reject();
                        }
                    });
                    oReq.open('get', pathToWorker, true);
                    oReq.send();
                });
            }

            this.unpackOnClientWorker = await new UnpackOnClientController.WorkerCalculatorWrapped();

            this.ableWorker = true;

            await this.unpackOnClientWorker.init(pathToWasm);

            this.isInited = true;

            return 'ok';
        } catch (error) {
            logger.error('Worker error:', error);
            sendXray('worker-error-exc');
        }
    }

    public async unpack(options: UnpackOptions) {
        if (!this.isInited) {
            return;
        }
        return this.unpackOnClientWorker.unpack(options);
    }

    public clearCache() {
        if (!this.isInited) {
            return;
        }
        this.unpackOnClientWorker.clearCache();
    }

    public async getFileContentBlobUrl(path: string): Promise<string> {
        if (!this.isInited) {
            return '';
        }
        return this.unpackOnClientWorker.getFileContentBlobUrl(path);
    }
}

export const unpackOnClientController = new UnpackOnClientController();
