import React, { type ReactEventHandler, useCallback } from 'react';
import { TariffSwitch } from 'reactApp/ui/TariffCardNew/components/TariffSwitch';
import type { SwitchOption } from 'reactApp/ui/TariffCardNew/components/TariffSwitch.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';

import styles from './TariffPeriodSwitch.css';

const switchOptions: [SwitchOption, SwitchOption] = [
    {
        value: ETabsName.month,
        text: 'На месяц',
    },
    {
        value: ETabsName.year,
        text: 'На год',
    },
];

interface TariffPeriodSwitchProps {
    checkedOption: ETabsName;
    onClickSwitch: (tab: ETabsName) => void;
}

export const TariffPeriodSwitch = ({ checkedOption, onClickSwitch }: TariffPeriodSwitchProps) => {
    const onTariffSwitchClick: ReactEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            onClickSwitch(checkedOption === ETabsName.year ? ETabsName.month : ETabsName.year);
        },
        [checkedOption, onClickSwitch]
    );

    return (
        <div className={styles.root}>
            <div className={styles.discountLabel} />
            <TariffSwitch
                gray
                sizeExtraSmall
                checked={checkedOption === ETabsName.year}
                onClick={onTariffSwitchClick}
                options={switchOptions}
            />
        </div>
    );
};
