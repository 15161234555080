import type { Method } from 'lib/axios';

import { APICallV4 } from '../APICall';

export class WeblinksEmailAccessChangeV4ApiCall extends APICallV4 {
    _method = '/weblinks/email/access/change';
    _type: Method = 'post';
}

export const weblinksEmailAccessChangeV4ApiCall = ({ weblink, email, access }) =>
    new WeblinksEmailAccessChangeV4ApiCall().makeRequest({
        id: weblink,
        email,
        access,
    });
