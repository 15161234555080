import classNames from 'clsx';
import React, { type ReactNode, forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';

import styles from './TransitionWrapper.css';

interface IProps {
    children: ReactNode;
    onEntered?: () => void;
    onEnter?: () => void;
    onExited?: () => void;
    onExit?: () => void;
    isExpanded?: boolean;
}

export const TransitionWrapper = forwardRef<HTMLDivElement, IProps>(({ children, isExpanded, ...rest }, ref) => (
    <CSSTransition
        in={isExpanded}
        timeout={200}
        classNames={{
            exit: styles.animation_exit,
            exitActive: styles.animation_exit_active,
            enter: styles.animation_appear,
            enterActive: styles.animation_appear_active,
        }}
        {...rest}
    >
        <div
            className={classNames(styles.root, { [styles.responsive]: ENABLE_FULL_RESPONSIVE, [styles.root_expand]: isExpanded })}
            ref={ref}
        >
            {children}
        </div>
    </CSSTransition>
));

TransitionWrapper.displayName = 'TransitionWrapper';
