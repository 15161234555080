import { ReactComponent as CoEditIcon } from 'img/icons/coedit.svg';
import React, { type FC } from 'react';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './ShareBubble.css';

interface Props {
    buttonTextRef: React.RefObject<HTMLDivElement>;
    closeBubble: () => void;
}

export const ShareBubble: FC<Props> = ({ buttonTextRef, closeBubble }) => (
    <FloatingTooltip target={buttonTextRef} placement={ETooltipPlacement.bottomEnd} onClose={closeBubble} hideArrow qaId="tooltip-co-edit">
        <div className={styles.bubble}>
            <div className={styles.bubbleIcon}>
                <CoEditIcon width={145} height={100} />
            </div>
            <div>
                <div className={styles.bubbleTitle}>Совместное редактирование документов</div>
                <div className={styles.bubbleText}>
                    Работайте совместно. Вы можете открывать доступ к вашим документам по секретной ссылке.
                </div>
            </div>
        </div>
    </FloatingTooltip>
);
