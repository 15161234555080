import React, { type MouseEvent, useCallback } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { usePublicThemeId } from 'reactApp/ui/PublicThemePreview/hooks/usePublicThemeId';
import { type ThemePreviewProps, ThemePreview } from 'reactApp/ui/PublicThemePreview/ThemePreview/ThemePreview';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import styles from './SharingPublicThemePreview.css';

interface SharingPublicThemePreviewProps extends ThemePreviewProps {
    item: PublishItem;
    publicId: string;
}

export function SharingPublicThemePreview({ publicId, item, className }: SharingPublicThemePreviewProps) {
    const themeId = usePublicThemeId(publicId);
    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            emitAnalyticEvent(AnalyticEventNames.SHARING_BRAND_PAGE_CLICK);
            sendDwh({ eventCategory: 'sharing-brand-page', action: 'click' });
            const url = new URL(getPublicUrl(item));
            url.searchParams.append('themeChooser', 'true');
            url.searchParams.append('source', 'sharing');
            opener(url.toString());
            e.currentTarget.blur();
        },
        [item]
    );
    return (
        <div className={styles.previewContainer}>
            {themeId ? <ThemePreview themeId={themeId} className={className} /> : null}
            <Button theme="vk" sizeMode={ButtonSizeMode.extraSmall} onClick={handleClick} className={styles.button}>
                {themeId ? 'Изменить' : 'Выбрать'}
            </Button>
        </div>
    );
}
