import onboardingPhoneImage from 'img/ask-phone-popup/onboarding-phone.png';
import onboardingPhoneDarkImage from 'img/ask-phone-popup/onboarding-phone-dark.png';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { MobileDialog } from 'reactApp/ui/AskPhoneDialog/components/MobileDialog';
import { sendDwh } from 'reactApp/utils/ga';
import { noop, parseURL } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { ASK_PHONE_DIALOG, MUST_ATTACH_PHONE_CONTENT } from './AskPhoneDialog.constants';
import styles from './MustAttachPhoneDialog.css';

const sendAskPhoneDwh = ({ action, ...data }): void => {
    sendDwh({
        eventCategory: ECategoryGa.addPhone,
        action,
        dwhData: { ...data, place: IS_PHONE_BROWSER ? 'touch' : 'desktop' },
        sendImmediately: true,
    });
};

interface Props {
    text: string;
    closable?: boolean;
    onClose?(): void;
}

export const MustAttachPhoneDialog = ({ text = MUST_ATTACH_PHONE_CONTENT.contentText, closable = false, onClose = noop }: Props) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(EnvironmentSelectors.isMobile);
    const storage = useSelector(getCurrentStorage);
    const recoveryUrl = useMemo(() => {
        const currentUrl = parseURL(window.location.href);
        // передаю в backUrl текущий урл без get параметров
        const backUrl = `${currentUrl.origin}${currentUrl.pathname}?action=${ASK_PHONE_DIALOG}`;

        return decodeURIComponent(`${MUST_ATTACH_PHONE_CONTENT.recoveryUrl}?back_url=${backUrl}`);
    }, []);
    const coverImage = useMemo(() => (isDarkTheme ? onboardingPhoneDarkImage : onboardingPhoneImage), []);

    const handleShow = useCallback(() => {
        dispatch(UserStorageActions.set(ASK_PHONE_DIALOG, Date.now()));

        sendAskPhoneDwh({ action: 'show', source: storage });
    }, [storage]);

    const handleClick = useCallback(() => {
        sendAskPhoneDwh({ action: 'click', name: 'click' });

        opener(recoveryUrl, true);
    }, [recoveryUrl]);

    const handleClose = useCallback(() => {
        sendAskPhoneDwh({ action: 'click', name: 'close' });

        onClose();
    }, [onClose]);

    return isMobile ? (
        <MobileDialog
            onShow={handleShow}
            onClose={handleClose}
            onClickButton={handleClick}
            closable={closable}
            title={MUST_ATTACH_PHONE_CONTENT.title}
            buttonText={MUST_ATTACH_PHONE_CONTENT.primaryButtonText}
            imgProps={{
                height: 247,
                src: coverImage,
                alt: MUST_ATTACH_PHONE_CONTENT.title,
            }}
        >
            {text}
        </MobileDialog>
    ) : (
        <WhatsNewDialog
            contentClassName={styles.content}
            imageWrapperClassName={styles.imageWrapper}
            imageClassName={styles.image}
            textClassName={styles.text}
            dialogSize="average"
            onShow={handleShow}
            onClose={handleClose}
            onClick={handleClick}
            onSecondary={handleClose}
            closable={closable}
            imageFluid={false}
            primaryButtonText={MUST_ATTACH_PHONE_CONTENT.primaryButtonText}
            secondaryButtonText={MUST_ATTACH_PHONE_CONTENT.secondaryButtonText}
            title={MUST_ATTACH_PHONE_CONTENT.title}
            text={text}
            imageUrl={coverImage}
            imageBgColor="var(--vkui--color_background_modal)"
            buttonTheme="vk"
        />
    );
};
