import logoMyOffice from 'img/icons/editor/MyOffice_Icon.svg?url';
import React, { type FC, useEffect } from 'react';
import { sendXray } from 'reactApp/utils/ga';
import { type PopupProps, Popup } from 'semantic-ui-react';

import styles from './MyOfficePopup.css';

interface IMyOfficePopupProps extends Omit<PopupProps, 'style' | 'content' | 'trigger'> {
    radarName: string;
    mouseEnterDelay?: number;
    mouseLeaveDelay?: number;
}

/**
 * onOpen задваивает вызов на hover, по этому отправляем через didMount
 */
const CustomMountRadarSend = ({ radarName }: { radarName: string }) => {
    useEffect(() => {
        sendXray(radarName, { 'popup-show': 1 });
    }, [radarName]);

    return null;
};

export const MyOfficePopup: FC<IMyOfficePopupProps> = ({
    children,
    mouseEnterDelay = 200,
    mouseLeaveDelay = 800,
    radarName,
    ...popupProps
}) => (
    <Popup
        flowing
        className={styles.componentStyles}
        closeOnDocumentClick
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        {...popupProps}
        content={
            <div className={styles.wrapper}>
                <CustomMountRadarSend radarName={radarName} />
                <div>
                    <img src={logoMyOffice} width={72} alt="Мой Офис" />
                </div>
                <div className={styles.content}>
                    <div className={styles.contentHeader}>МойОфис</div>
                    <div className={styles.contentText}>Используйте продукты МойОфис для ежедневной работы с{'\u00A0'}документами</div>
                </div>
            </div>
        }
        trigger={children}
    />
);
