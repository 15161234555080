import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

import styles from './styles.css';

const Component = videojs.getComponent('Component');

function divWrapper(el: Element, className: string) {
    const wrap = document.createElement('div');
    wrap.className = className;
    wrap.appendChild(el);
    return wrap;
}

// обёртка для правильного позиционирования
export function addControlBarWrapper(player: VideoPlayer) {
    const el = player.controlBar.contentEl();
    const wrap = divWrapper(el, styles.controlBarWrapper);
    player.contentEl().appendChild(wrap);
}

// Тень(градиент) нижнего интерфейса
export function addControlBarShadow(player: VideoPlayer) {
    const elShadow = document.createElement('div');
    elShadow.className = styles.controlBarShadow;
    const shadow = new Component(player, { el: elShadow });
    player.addChild(shadow);
}

// Расширитель для мобильной версс
export function addControlBarSpacer(player: VideoPlayer) {
    const elSpacer = document.createElement('div');
    elSpacer.className = styles.spacer;
    const spacer = new Component(player, { el: elSpacer });
    player.controlBar.addChild(spacer);
}
