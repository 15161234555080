import classnames from 'clsx';
import React, { type VFC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { abPauseSubscription } from 'reactApp/appHelpers/featuresHelpers/features/pauseSubscription';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { renderSubscriptionsModal } from '../SubscriptionsModal/SubscriptionsModal.helpers';
import type { Subscription } from '../SubscriptionsModal/SubscriptionsModal.types';
import { BaseSubscription } from './Components/BaseSubscription/BaseSubscription';
import { ModernSubscription } from './Components/ModernSubscription/ModernSubscription';
import { useSendAnalyticsAfterOpen } from './hooks/useSendAnalyticsAfterOpen';
import styles from './SubscriptionInfoModal.css';
import { isBaseSubscription } from './SubscriptionInfoModal.helpers';

type Props = IPropsWithPopup<{ subscription: Subscription; isRebrandingQuotaLanding?: boolean; isRebranding?: boolean }>;

export const SubscriptionInfoModal: VFC<Props> = ({ subscription, onClose, isRebrandingQuotaLanding, isRebranding = false }) => {
    const dispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);

    useSendAnalyticsAfterOpen(subscription);

    useEffect(() => {
        if (abPauseSubscription && subscription.id !== 'base') {
            dispatch(BuySubscriptionActions.checkPauseSubscription({ subscriptionId: subscription.id }));
        }
    }, [dispatch, subscription.id]);

    const handleCloseDialog = () => {
        onClose();
        renderSubscriptionsModal({ isRebrandingQuotaLanding, isRebranding });
    };

    return (
        <MobileDialog
            id="mobile-subscription-info-modal"
            onClose={handleCloseDialog}
            closeOnDimmerClick
            mod="base"
            topmost
            scrollRef={scrollRef}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            isRebranding={isRebranding}
        >
            <div ref={scrollRef} className={classnames(styles.root, { [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}>
                {isBaseSubscription(subscription) ? (
                    <BaseSubscription subscription={subscription} isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
                ) : (
                    <ModernSubscription
                        subscription={subscription}
                        onClose={onClose}
                        isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        isRebranding={isRebranding}
                    />
                )}
            </div>
        </MobileDialog>
    );
};
