import type { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';

/**
 * Получаем данные об удаленнх элементах в разбивке по годам
 * @param itemIds - массив id удаленных
 * @param deletedIds - массив удаленных delete_id элементов
 * @returns
 */
export const getYearDeleteConfig = (
    items: Record<string, QuotaCleanerItem>,
    deleteIds: string[]
): Record<string, { size: number; count: number }> =>
    deleteIds.reduce((acc, id) => {
        const { size, date } = items[id];
        const year = new Date(date).getFullYear();

        acc[year] ??= {
            size: 0,
            count: 0,
        };

        acc[year].size += size;
        acc[year].count++;

        return acc;
    }, {});
