import classNames from 'clsx';
import { ReactComponent as CloudIcon } from 'img/icons/cloud_colorized_2.svg';
import { ReactComponent as BlockedCloud } from 'img/icons/cloud-blocked.svg';
import { ReactComponent as BlockedCloudDark } from 'img/icons/cloud-blockedDark.svg';
import { ReactComponent as ExclamationIcon } from 'img/icons/exclamation.svg';
import overquotaClearedImage from 'img/overquota/maskot.png';
import overquotaClearedDarkImage from 'img/overquota/maskotDark.png';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { setClearedUserProcessed, setProfileUblockUser } from 'reactApp/modules/profile/profile.module';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { OverquotaBannerCtrl, sendDwhOverquota } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import { Screen } from 'reactApp/ui/Screen/Screen';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { sendXray } from 'reactApp/utils/ga';

import styles from './OverquotaSplash.css';

interface Props {
    onClose: () => void;
    mode: 'blocked' | 'cleared';
}

export const OverquotaSplash = memo(({ mode, onClose }: Props): ReactElement => {
    const dispatch = useDispatch();
    const { clearTime, state }: ReturnType<typeof getUserOverquotaState> = useSelector(getUserOverquotaState);
    const storage = useSelector(getCurrentStorage);
    const isDark = useSelector(isCurrentDarkTheme);

    const place = useMemo(() => (mode === 'cleared' ? 'overquota_cleared' : 'overquota_blocked'), [mode]);

    const onCloseScreen = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        sendXray(['overquota', 'splashnew', 'show']);
        sendDwhOverquota({ action: 'show', storage, place });
    }, []);

    useEffect(() => {
        if (mode === 'blocked' && state !== 'blocked') {
            onClose?.();
        }
    }, [mode, state, onClose]);

    const title = useMemo(() => {
        if (mode === 'cleared') {
            return 'Ваши файлы были удалены';
        }

        return 'Доступ к файлам заблокирован';
    }, [mode]);

    const content = useMemo(() => {
        if (mode === 'cleared') {
            return (
                <>
                    Единое пространство было переполнено больше трёх месяцев —
                    <br />и автоматически очистилось
                </>
            );
        }

        return (
            <>
                Для&nbsp;них&nbsp;не&nbsp;хватает места —{' '}
                <span className={styles.date}>{!!clearTime && formatFileHistoryDate(clearTime)}</span> они&nbsp;будут удалены.
                <br />
                Увеличьте место, чтобы&nbsp;их&nbsp;сохранить, или&nbsp;разблокируйте доступ.
            </>
        );
    }, [mode]);

    const onGoToClearHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.add_space });
        dispatch(historyPush({ id: '/promo/quota', search: `?${PaymentUTM.overquotaSplashNewBlocked}` }));
        onClose?.();
    }, [onClose, place]);

    const onGoToCloudHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.go_cloud });
        dispatch(setClearedUserProcessed());
        onClose?.();
    }, [onClose, place]);

    const onUnblockHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.unblocked });
        dispatch(setProfileUblockUser());
    }, [place]);

    const onWhyWhyWhy = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, place, control: OverquotaBannerCtrl.whyWhyWhy });
    }, [place]);

    const blockedComponet = isDark ? <BlockedCloudDark /> : <BlockedCloud />;

    return (
        <Screen onClose={onCloseScreen} withLogo={false} closable={false} marginForHeader backSecondary aboveAll={false}>
            <div className={styles.root}>
                {state === 'cleared' ? (
                    <img src={isDark ? overquotaClearedDarkImage : overquotaClearedImage} alt="Ваши файлы были удалены" width="360" />
                ) : (
                    <div
                        className={classNames(styles.icon, {
                            [styles.iconRenranding]: isRebranding,
                        })}
                    >
                        {isRebranding ? (
                            blockedComponet
                        ) : (
                            <>
                                <CloudIcon />
                                <div className={styles.iconExcl}>
                                    <ExclamationIcon width={6} height={24} />
                                </div>
                            </>
                        )}
                    </div>
                )}
                <div className={classNames(styles.title, { [styles.title_withMargin]: state !== 'cleared' })}>{title}</div>
                <div className={styles.description}>{content}</div>
                {mode === 'cleared' ? (
                    <div className={classNames(styles.buttons, styles.buttons_column)}>
                        <Button className={styles.button} data-name="freeze-renewal" theme="vk" onClick={onGoToCloudHandler} primary>
                            Перейти в Облако
                        </Button>
                        <a
                            className={styles.link}
                            href={`https://help.mail.ru/cloud_web/faq/#overquota?${PaymentUTM.overquotaSplashNewCleared}`}
                            onClick={onWhyWhyWhy}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Почему это произошло?
                        </a>
                    </div>
                ) : (
                    <div className={styles.buttons}>
                        <Button
                            className={classNames(styles.button, {
                                [styles.buttonRebranding]: isRebranding,
                            })}
                            data-name="freeze-renewal"
                            theme="vk"
                            onClick={onGoToClearHandler}
                            error={!isRebranding}
                        >
                            Увеличить место
                        </Button>
                        <Button className={styles.button} onClick={onUnblockHandler} theme="white" data-name="secondary">
                            Разблокировать
                        </Button>
                    </div>
                )}
            </div>
        </Screen>
    );
});

OverquotaSplash.displayName = 'OverquotaSplash';
