import classNames from 'clsx';
import { ReactComponent as IconArrow } from 'img/icons/dropArrow.svg';
import React, { type ReactElement, memo } from 'react';
import { useViewContext } from 'reactApp/hocs/withViewContext';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Breadcrumb } from 'reactApp/ui/Breadcrumbs/Breadcrumb';
import { noop } from 'reactApp/utils/helpers';

import styles from './Breadcrumbs.css';
import type { BreadcrumbItem } from './Breadcrumbs.types';
import { BreadcrumbsDropdown } from './BreadcrumbsDropdown';

const BREADCRUMBS_WITHOUT_DROPDOWN = 3;

const isDropdownListType = (item: BreadcrumbItem | DropdownList): item is DropdownList => 'list' in item;

interface DropdownList {
    list: BreadcrumbItem[];
}
interface Props {
    breadcrumbs: BreadcrumbItem[];
    onClick?: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
    onShareClick?: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
    onPublishClick?: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
    mode?: 'viewer' | '';
    storage?: EStorageType;
    // Количество крошек, которые не будут скрываться в дропдаун
    breadcrumbsCount?: number;
    isFollowToSharedFolders?: boolean;
    goToSharedFolders?: () => void;
    asPageTitle?: boolean;
}

const createList = (breadcrumbs: BreadcrumbItem[], breadcrumbsCount: number): (BreadcrumbItem | DropdownList)[] => {
    const showDropdown = breadcrumbs.length > breadcrumbsCount;

    if (!showDropdown) {
        return breadcrumbs;
    }

    const breadCrumbsList = [...breadcrumbs];
    const list = breadCrumbsList.splice(1, breadcrumbs.length - breadcrumbsCount);
    const [firstBreadCrumb, ...restBreadcrumbs] = breadCrumbsList;
    return [firstBreadCrumb, { list }, ...restBreadcrumbs];
};

export const BreadCrumbs = memo(
    ({
        breadcrumbs,
        onClick = noop,
        onShareClick = noop,
        onPublishClick = noop,
        mode = '',
        storage,
        breadcrumbsCount = BREADCRUMBS_WITHOUT_DROPDOWN,
        isFollowToSharedFolders,
        goToSharedFolders,
        asPageTitle = false,
    }: Props): ReactElement => {
        const list = createList(breadcrumbs, breadcrumbsCount);
        const viewContext = useViewContext();

        const { isInlineIntegration } = getStorage(storage as EStorageType);

        return (
            <div
                data-selectbox="false"
                className={classNames({
                    [styles.root]: true,
                    [styles.root_public]: storage === EStorageType.public,
                    [styles.root_vk_embedded]: viewContext.isVKEmbedded,
                })}
            >
                {list.map((item, index) => {
                    const isActive = index === list.length - 1;
                    const breadCrumbMode = mode === 'viewer' ? (index === 0 ? 'light' : 'bold') : '';

                    return (
                        <div
                            key={isDropdownListType(item) ? 'list' : item.id}
                            className={classNames(styles.item, {
                                [styles.item_vk_embedded]: viewContext.isVKEmbedded,
                                [styles.item_active]: isActive,
                                [styles.item_more]: isDropdownListType(item),
                                [styles.item_only]: list.length === 1,
                                [styles[`item_${breadCrumbMode}`]]: breadCrumbMode,
                                [styles.item_inlineIntegration]: isInlineIntegration,
                            })}
                        >
                            {isDropdownListType(item) ? (
                                <BreadcrumbsDropdown onClick={onClick} list={item.list} isInlineIntegration={isInlineIntegration} />
                            ) : (
                                <Breadcrumb
                                    breadcrumb={item}
                                    storage={storage}
                                    isActive={isActive}
                                    onClick={onClick}
                                    onPublishClick={onPublishClick}
                                    onShareClick={onShareClick}
                                    data-qa-name={item.text}
                                    isFollowToSharedFolders={isFollowToSharedFolders}
                                    goToSharedFolders={goToSharedFolders}
                                    isVKEmbedded={viewContext.isVKEmbedded}
                                    isInlineIntegration={isInlineIntegration}
                                    asPageTitle={asPageTitle}
                                />
                            )}
                            {!isActive && (
                                <div className={styles.arrow}>
                                    <IconArrow />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
);
BreadCrumbs.displayName = 'Breadcrumbs';
