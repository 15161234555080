import { useSelector } from 'react-redux';
import { isPromoQuotaWithLitress } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';

import type { Props } from './UpsaleWithTrialDialog.types';

type UseYearProductType = Pick<Props, 'yearProduct' | 'monthProduct'>;

export const useYearProduct = ({ yearProduct, monthProduct }: UseYearProductType): Product => {
    const yearCommonProduct = useSelector((state: RootState) =>
        ProductsSelectors.getUpsaleForceTrialYearProductBySpace(state, monthProduct.space.space)
    );

    // tempexp-17509-start
    const isPaid = useSelector(UserSelectors.isPaidUser);
    const yearLitresProduct = useSelector((state: RootState) =>
        ProductsSelectors.getLitresUpsaleForceTrialYearProductBySpace(state, monthProduct.space.space)
    );

    const yearTrial = isPromoQuotaWithLitress && !isPaid ? yearLitresProduct : yearCommonProduct;
    // tempexp-17509-end

    if (yearTrial?.available) {
        return yearTrial;
    }

    return yearProduct;
};
