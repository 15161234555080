import browser from 'Cloud/browser';
import config from 'Cloud/config';
import { IS_ANDROID_MOBILE, IS_IOS_MOBILE, IS_MOBILE_BROWSER, IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';

const mobileType = IS_MOBILE_BROWSER;
const isPhone = !!IS_PHONE_BROWSER;
const isWebview = !!IS_WEBVIEW;
const isHuawei = config.get('EMORB_VENDOR') === 'Huawei';

const getMobileType = () => (IS_ANDROID_MOBILE ? 'ANDROID' : IS_IOS_MOBILE ? 'IOS' : mobileType);
const isMobile = () => !!getMobileType();

export const EnvironmentSelectors = {
    isMobile,
    getMobileType,
    isHuawei: () => isHuawei,
    isPhone: () => isPhone,
    isWebview: () => isWebview,
    isIE: () => browser.isIE(),
    isIpadOs: () => browser.isIpadOs(),
    isMac: () => browser.isMac(),
    getIOsVersion: () => browser.getIOsVersion(),
    isIFrame: () => {
        try {
            return window.self !== window.top;
        } catch {
            return true;
        }
    },
};
