import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, memo } from 'react';
import { useSelector } from 'react-redux';
import { isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';

import styles from './Container.css';

export const Container = memo(
    ({ children, isNarrow, className = ' ' }: { children: ReactNode; isNarrow?: boolean; className?: string }): ReactElement => {
        const isMobile = useSelector(QuotaLandingSelectors.isMobile);
        const isIFrame = useSelector(QuotaLandingSelectors.isIFrame);

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_mobile]: isMobile,
                    [styles.root_narrow]: isNarrow,
                    [styles.root_integration]: isIFrame,
                    [className]: !!className,
                    [styles.root_rebranding]: isRebrandingQuotaLanding,
                })}
            >
                {children}
            </div>
        );
    }
);

Container.displayName = 'Container';
