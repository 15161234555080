import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { type ESources, useDownloadEbook } from 'reactApp/hooks/useDownloadEbook';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureViewerEbookExtraTags } from 'reactApp/modules/features/features.selectors';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { EEpubPostMessageType, mapPostMessageTypeToRadar } from 'reactApp/ui/ViewerEbook/postMessage.types';
import { useRenderTimeout } from 'reactApp/ui/ViewerEbook/useRenderTimeout';
import { withRenderIsStock } from 'reactApp/ui/ViewerEbook/withRenderIsStock';
import { withRenderIsVisible } from 'reactApp/ui/ViewerEbook/withRenderIsVisible';
import { sendXray } from 'reactApp/utils/ga';
import { generateUuidV4 } from 'reactApp/utils/helpers';

import styles from './ViewerEbook.css';
import { getIframeSource } from './ViewerEbook.helpers';

export interface ViewerEbookProps {
    file: CloudFile;
    onReady?: () => void;
    renderStub: () => ReactElement;
    source: ESources;
    itemStorage?: EStorageType | null;
}

const radarNameMap = {
    epub: {
        RADAR_NAME: 'viewer-ebook',
        RADAR_NAME_IFRAME: 'viewer-ebook-iframe',
    },
    fb2: {
        RADAR_NAME: 'viewer-fb2-book',
        RADAR_NAME_IFRAME: 'viewer-fb2-book-iframe',
    },
    djvu: {
        RADAR_NAME: 'viewer-djvu-book',
        RADAR_NAME_IFRAME: 'viewer-djvu-book-iframe',
    },
};

const ViewerEbookComponent = memo<ViewerEbookProps>(({ onReady, file, renderStub, source, itemStorage }): ReactElement | null => {
    const postChanelId = useMemo(() => `viewer-ebook-${generateUuidV4()}`, []);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const isMobile = useSelector(EnvironmentSelectors.isMobile);
    const extraAllowedTags: ReturnType<typeof getFeatureViewerEbookExtraTags> = useSelector(getFeatureViewerEbookExtraTags);
    const [idRenderBookError, setIsRenderBookError] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const { content, isError: isDownloadEbookError } = useDownloadEbook({ file, source, itemStorage });
    const iframeSrc = getIframeSource(postChanelId, file.ext, itemStorage);

    const { RADAR_NAME, RADAR_NAME_IFRAME } = radarNameMap[file.ext];

    const isLoading = !isFrameLoaded || content?.byteLength === 0 || !isReady;
    const showStub = isDownloadEbookError || idRenderBookError || !iframeSrc;

    const clearRenderTimeout = useRenderTimeout(source, showStub, setIsRenderBookError);

    useEffect(() => {
        if (!isFrameLoaded || !iframeSrc) {
            return;
        }
        const iframeUrl = new URL(iframeSrc);

        iframeRef?.current?.contentWindow?.postMessage(
            {
                type: EEpubPostMessageType.sender,
                chanelId: postChanelId,
                props: {
                    content,
                    isMobile,
                    extraAllowedTags,
                },
            },
            process.env.NODE_ENV === 'production' ? iframeUrl.origin : '*'
        );
    }, [
        content,
        isFrameLoaded,
        postChanelId,
        /** iframeSrc должен быть статичным */
        /** extraAllowedTags должен быть статичным */
        /** isMobile должен быть статичным */
    ]);

    useEffect(() => {
        const onMessageHandler = ({ data, origin }) => {
            // /**
            //  * FIXME Брать origin из настроек, пока статично
            //  */
            if (
                data?.chanelId !== postChanelId ||
                !(
                    ['https://static.mail.corpcloud.devmail.ru', 'https://cloud.imgsmail.ru'].includes(origin) ||
                    /cloud\d*\.(devmail|mini-mail)\.ru/.test(origin)
                )
            ) {
                return;
            }

            switch (data.type) {
                case EEpubPostMessageType.globalError:
                    setIsRenderBookError(true);
                    break;
                case EEpubPostMessageType.error:
                    setIsRenderBookError(true);
                    break;
                case EEpubPostMessageType.ready:
                    clearRenderTimeout();
                    onReady?.();
                    setIsReady(true);
                    break;
                case EEpubPostMessageType.init:
                    setIsFrameLoaded(true);
                    break;
                case EEpubPostMessageType.xray:
                    if (data.xrayData) {
                        sendXray(RADAR_NAME_IFRAME, data.xrayData);
                    }
                    break;
            }

            if (mapPostMessageTypeToRadar[data.type]) {
                sendXray(RADAR_NAME, {
                    [isMobile ? 'mobile' : 'desktop']: 1,
                    [mapPostMessageTypeToRadar[data.type]]: 1,
                });
            }
        };

        window.addEventListener('message', onMessageHandler);
        return () => window.removeEventListener('message', onMessageHandler);
    }, [isMobile, postChanelId, clearRenderTimeout, onReady]);

    if (showStub) {
        return renderStub();
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_mobile]: isMobile,
            })}
        >
            {isLoading && <Spinner />}
            <iframe
                loading="lazy"
                onError={() => setIsRenderBookError(true)}
                className={styles.wrapperIframe}
                /** ITSEC_REVIEW Для iframe устанавливаем возможные действия через белый список sandbox */
                sandbox="allow-same-origin allow-scripts"
                src={iframeSrc}
                ref={iframeRef}
            />
        </div>
    );
});

ViewerEbookComponent.displayName = 'ViewerEbookComponent';

export const ViewerEbook = withRenderIsStock(withRenderIsVisible(ViewerEbookComponent));
