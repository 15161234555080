import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type { FeedApiResponse } from 'reactApp/modules/feed/feed.types';
import { getExtension, isFolder } from 'reactApp/modules/file/utils';
import { addFilesSuccess, removeFileSuccess, renameItemSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { IRenameItemSuccess } from 'reactApp/modules/modifying/modifying.types';

import type { AllDocumentsItem, AllDocumentsSections, EAllDocumentsType, IAllDocumentsState } from './allDocuments.types';

export const allDocumentsLoadRequest = createAction<EAllDocumentsType>('allDocuments/loadRequest');
export const allDocumentsAdditionalLoadRequest = createAction<AllDocumentsSections>('allDocuments/additionalLoadRequest');
export const allDocumentsMoreRequest = createAction('allDocuments/loadMoreRequest');
export const allDocumentsLoadSuccess = createAction<{
    type: EAllDocumentsType | undefined | null;
    data?: FeedApiResponse;
    items: string[];
    docsSize: number;
}>('allDocuments/loadSuccess');
export const allDocumentsLoadError = createAction<{ type: EAllDocumentsType | undefined | null; error: string }>('allDocuments/loadError');
export const resetCurrentAllDocumentType = createAction('allDocuments/resetCurrentAllDocumentType');

const initialState: IAllDocumentsState = {
    currentDocType: null,
    isLoaded: false,
    isLoading: false,
    hasMoreToLoad: false,
    cursor: '',
    idxs: [],
    docsSize: 0,
    currentSection: null,
    prevSection: null,
};

export const allDocumentsReducer = createReducer(initialState, {
    [allDocumentsLoadRequest.type]: (state, action: ReturnType<typeof allDocumentsLoadRequest>) => {
        state.hasMoreToLoad = false;
        state.cursor = '';
        state.idxs = [];
        state.docsSize = 0;
        state.isLoaded = false;
        state.isLoading = true;
        state.prevSection = state.currentSection;
        state.currentSection = action.payload;
        state.currentDocType = action.payload;
        state.error = '';
        state.idxs = [];
    },
    [allDocumentsAdditionalLoadRequest.type]: (state, action: ReturnType<typeof allDocumentsLoadRequest>) => {
        state.hasMoreToLoad = false;
        state.cursor = '';
        state.idxs = [];
        state.docsSize = 0;
        state.isLoaded = true;
        state.isLoading = false;
        state.prevSection = state.currentSection;
        state.currentSection = action.payload;
        state.currentDocType = null;
        state.error = '';
        state.idxs = [];
    },
    [allDocumentsLoadSuccess.type]: (state, action: ReturnType<typeof allDocumentsLoadSuccess>) => {
        const { type, data, items, docsSize } = action.payload;

        if (type === state.currentDocType) {
            state.isLoaded = true;
            state.isLoading = false;

            state.cursor = data?.cursor || '';
            state.hasMoreToLoad = !!data?.cursor;
            state.idxs = [...new Set([...state.idxs, ...items])];
            state.docsSize = docsSize;
        }
    },
    [allDocumentsLoadError.type]: (state, action: ReturnType<typeof allDocumentsLoadError>) => {
        if (action.payload.type === state.currentDocType) {
            state.isLoaded = true;
            state.isLoading = false;
            state.error = action.payload.error;
        }
    },
    [resetCurrentAllDocumentType.type]: () => initialState,
    [removeFileSuccess.type]: (state, action: ReturnType<typeof removeFileSuccess>) => {
        const { ids } = action.payload;
        state.idxs = state.idxs.filter((id) => !ids.includes(id));
    },
    [addFilesSuccess.type]: (state, action: ReturnType<typeof addFilesSuccess>) => {
        const { items, allowedExt } = action.payload;
        items.forEach((item) => {
            const ext = getExtension(item.name);
            if (!isFolder(item) && allowedExt?.includes(ext)) {
                state.idxs.unshift(item.id);
            }
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file = newItem as AllDocumentsItem;
        const index = state.idxs.indexOf(oldId);

        if (!file || !oldId) {
            return;
        }

        state.idxs[index] = file.id;
    },
});
