import downloadAppModal from 'img/downloadAppModal/1.png';
import biteImg from 'img/downloadAppModal/bite.png';
import { ReactComponent as CloudLogo } from 'img/downloadAppModal/cloud_logo.svg';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { sendDwh } from 'reactApp/utils/ga';

import { MobileDialog } from '../MobileDialog/MobileDialog';
import styles from './DownloadAppModal.css';
import type { DownloadAppModalData } from './DownloadAppModal.types';

const BUTTON_HREF = 'https://trk.mail.ru/c/emn3i6';

export const DownloadAppModal = memo<IPropsWithPopup<DownloadAppModalData>>(
    ({ onClose, description, title, buttonText, buttonLink, imgUrl, noMtParameter }) => {
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'view',
            });
        }, []);

        const handleClick = useCallback(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'click',
            });
            onClose();
        }, [onClose]);

        const handleClose = useCallback(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'close',
            });
            onClose();
        }, [onClose]);

        const href = `${buttonLink || BUTTON_HREF}${noMtParameter ? '' : `?mt_deeplink=${window.location.href}`}`;

        return (
            <MobileDialog id="download-app-modal" onClose={handleClose} mod="base" topmost closeOnDimmerClick scrollRef={ref}>
                <div className={styles.root} ref={ref}>
                    <div className={styles.imageWrapper}>
                        {isRebranding ? <CloudLogo /> : <img className={styles.image} src={imgUrl || downloadAppModal} />}
                    </div>
                    {isRebranding && <img className={styles.bite} src={biteImg} />}
                    <div className={styles.content}>
                        <div className={styles.title}>{isRebranding ? 'Установите приложение' : title}</div>
                        <div className={styles.text}>
                            {isRebranding
                                ? 'С ним удобнее открывать и сохранять файлы — заодно они не будут забивать память телефона'
                                : description}
                        </div>
                        <a href={href} onClick={handleClick} className={styles.button}>
                            {buttonText}
                        </a>
                    </div>
                </div>
            </MobileDialog>
        );
    }
);

DownloadAppModal.displayName = 'DownloadAppModal';
