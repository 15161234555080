import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import {
    type ChangeFeedCategoryAction,
    type FeedItem,
    type FeedLoadMoreSuccessAction,
    type FeedLoadRequestAction,
    type FeedLoadSuccessAction,
    type FeedState,
    FeedCategoryType,
} from 'reactApp/modules/feed/feed.types';
import {
    addFilesSuccess,
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import type {
    AddFile,
    AddFileSuccessAction,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    IWeblinkSetDomainAccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AccessRights } from 'reactApp/types/Tree';

import { filterOutDefaultContentAdnFoldersItems, normalizeBaseFeedItem } from './feed.helpers';

const initialState: FeedState = {
    categories: [
        {
            name: 'Все',
            id: FeedCategoryType.all,
            value: FeedCategoryType.all,
            childs: [],
            cursor: '',
            isLoaded: false,
            isLoading: false,
            hasMoreToLoad: false,
        },
        {
            name: 'Изображения',
            id: FeedCategoryType.image,
            value: FeedCategoryType.image,
            childs: [],
            cursor: '',
            isLoaded: false,
            isLoading: false,
            hasMoreToLoad: false,
        },
        {
            name: 'Документы',
            id: FeedCategoryType.document,
            value: FeedCategoryType.document,
            childs: [],
            cursor: '',
            isLoaded: false,
            isLoading: false,
            hasMoreToLoad: false,
        },
        {
            name: 'Музыка',
            id: FeedCategoryType.audio,
            value: FeedCategoryType.audio,
            childs: [],
            cursor: '',
            isLoaded: false,
            isLoading: false,
            hasMoreToLoad: false,
        },
        {
            name: 'Видео',
            id: FeedCategoryType.video,
            value: FeedCategoryType.video,
            childs: [],
            cursor: '',
            isLoaded: false,
            isLoading: false,
            hasMoreToLoad: false,
        },
    ],
    list: {},
    currentCategory: FeedCategoryType.all,
};

export const feedLoadRequest = createAction<undefined | FeedLoadRequestAction>('feed/feedLoadRequest');
export const feedLoadStart = createAction<FeedLoadRequestAction>('feed/feedLoadStart');
export const feedLoadSuccess = createAction<FeedLoadSuccessAction>('feed/loadSuccess');
export const changeFeedCategory = createAction<ChangeFeedCategoryAction>('feed/changeCategory');
export const feedLoadMoreSuccess = createAction<FeedLoadMoreSuccessAction>('feed/loadMoreSuccess');
export const feedLoadMoreRequest = createAction<undefined | FeedLoadRequestAction>('feed/loadMoreRequest');

export const feedReducer = createReducer(initialState, {
    [feedLoadStart.type]: (state, action: PayloadAction<FeedLoadRequestAction>) => {
        state.currentCategory = action.payload.category;

        const category = state.categories.find((category) => category.id === action.payload.category);

        if (category) {
            category.isLoaded = false;
            category.isLoading = true;
        }
    },
    [feedLoadSuccess.type]: (state, action: PayloadAction<FeedLoadSuccessAction>) => {
        const category = state.categories.find((category) => category.id === action.payload.category);

        if (!category) {
            return;
        }

        const {
            payload: { data, mountedFoldersIds },
        } = action;

        category.cursor = data.cursor || '';
        category.hasMoreToLoad = !!data.cursor;
        category.childs = [];
        if (data.objects) {
            data.objects.filter(filterOutDefaultContentAdnFoldersItems).forEach((item) => {
                state.list[item.home] = { ...normalizeBaseFeedItem(item, mountedFoldersIds), storage: EStorageType.feed };
                category.childs.push(item.home);
            });
        }
        category.isLoaded = true;
        category.isLoading = false;
    },
    [changeFeedCategory.type]: (state, action: PayloadAction<ChangeFeedCategoryAction>) => {
        state.currentCategory = action.payload.category;

        const category = state.categories.find((category) => category.id === action.payload.category);

        if (category) {
            category.cursor = '';
            category.isLoaded = false;
            category.isLoading = true;
        }
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDomestic = domestic;
        }
    },
    [weblinkSetDomainAccess.type]: (state, action: PayloadAction<IWeblinkSetDomainAccess>): void => {
        const { domain, itemId } = action.payload;

        if (state.list[itemId]) {
            state.list[itemId].weblinkDomainAccess = domain;
        }
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkExpires = expires;
        }
    },
    [publishWeblink.type]: (state, action: PayloadAction<{ weblink: string; id: string }>) => {
        const { id, weblink } = action.payload;

        if (state.list[id]) {
            state.list[id].weblink = weblink;
        }
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        if (state.list[id]) {
            state.list[id].weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;
        if (state.list[id]) {
            state.list[id].weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id];
        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;
        if (state.list[id]) {
            state.list[id].count_downloads_total = undefined;
            state.list[id].count_downloads_left = undefined;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }
        });
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;
        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (category) {
            category.childs = category.childs.filter((child) => !ids.includes(child));
        }
        ids.forEach((id) => delete state.list[id]);
    },
    [addFilesSuccess.type]: (state, action: PayloadAction<AddFileSuccessAction>) => {
        const { items, storage } = action.payload;
        const files = items as AddFile[];

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (!category || storage !== EStorageType.feed) {
            return;
        }

        files.forEach((file) => {
            if (!file.home || file.kind === 'folder') {
                return;
            }

            state.list[file.home] = { ...normalizeBaseFeedItem(file), storage: EStorageType.feed };
            category.childs = [file.home, ...category.childs];
        });
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds } = action.payload;

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (!category) {
            return;
        }

        newItems.forEach((newItem, index) => {
            const file = newItem as FeedItem;
            const oldItemId = oldIds[index];
            const oldItem = state.list[oldItemId];

            if (!file.home || file.kind === 'folder' || !oldItem) {
                return;
            }

            state.list[file.home] = file;
            delete state.list[oldItemId];

            category.childs = category.childs.filter((id) => id !== oldItemId);
            category.childs = [file.home, ...category.childs];
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file = newItem as FeedItem;

        if (!file.home || file.kind === 'folder' || !oldId) {
            return;
        }

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (!category) {
            return;
        }

        state.list[file.home] = file;
        delete state.list[oldId];

        category.childs = category.childs.filter((id) => id !== oldId);
        category.childs = [file.home, ...category.childs];
    },
    [feedLoadMoreRequest.type]: (state, action: PayloadAction<undefined | FeedLoadRequestAction>) => {
        const categoryId = action?.payload?.category || state.currentCategory;
        const category = state.categories.find((category) => category.id === categoryId);

        if (category) {
            category.isLoading = true;
            category.isLoaded = false;
        }
    },
    [feedLoadMoreSuccess.type]: (state, action: PayloadAction<FeedLoadMoreSuccessAction>) => {
        const category = state.categories.find((category) => category.id === (action.payload.category || state.currentCategory));

        if (!category) {
            return;
        }

        const {
            payload: { data, mountedFoldersIds },
        } = action;

        category.isLoading = false;
        category.isLoaded = true;

        category.cursor = data.cursor || '';
        category.hasMoreToLoad = !!data.cursor;
        if (data.objects) {
            data.objects.filter(filterOutDefaultContentAdnFoldersItems).forEach((item) => {
                state.list[item.home] = { ...normalizeBaseFeedItem(item, mountedFoldersIds), storage: EStorageType.feed };
                category.childs.push(item.home);
            });
        }
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = false;
        });
    },
});
