import type { AstraMetaDataType } from './astraMeta.types';
import { getAstraMetaProcessing } from './getAstraMetaProcessing';

/** Расширению Astra необходимы данные об открытом просмотрщике и/или редакторе. Задача CLOUDWEB-16700.
 * Делаем следующим образом: добавляем в body новый пустой div id="astra-meta", ему задаём составной атрибут data-file-processing,
 * который формируется по правилу: "{processorType}_{processorId}_{fileExt}"
 *
 * Правила для processorType:
 * Если открыт предпросмотр файла, processorType = "v";
 * Если открыто редактирование файла, processorType = "e"
 * Если по какой-то причине просмотрщик открыт, но нет ни просмотра, ни редактирования, processorType = "o"
 *
 * Правила для processorID:
 * получаем данные через омикрон, название фичи: "astra-meta", структура: Record<EditorID, string> & { default: string } */

export const createAstraMetaDiv = (metaData?: AstraMetaDataType) => {
    const astraMetaDiv = document.createElement('div');
    astraMetaDiv.id = 'astra-meta';
    astraMetaDiv.style.display = 'none';

    if (metaData?.editorID) {
        astraMetaDiv.setAttribute('data-file-processing', getAstraMetaProcessing(metaData));
    } else {
        astraMetaDiv.setAttribute('data-file-processing', 'null');
    }

    document.body.appendChild(astraMetaDiv);
};
