import { Spacing } from '@vkontakte/vkui';
import React, { memo, useCallback, useRef } from 'react';
import { VkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import type { Product } from 'reactApp/types/Billing';
import Content from 'reactApp/ui/Content/Content';
import { DisableAdsPostMessageType, sendFrameMessage } from 'reactApp/ui/IframePages/BuyDisableAds/utils';
import { Icon24ArrowLeftOutine } from 'reactApp/ui/VKUIIcons';

import styles from './EmbeddedBuyScreen.css';

interface EmbeddedBuyScreenProps {
    product: Product;
    onClose: () => void;
}

export const EmbeddedBuyScreen = memo(({ product, onClose }: EmbeddedBuyScreenProps) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const title = product.hasTrial ? 'Отключение рекламы за 1 ₽' : 'Отключение рекламы с подпиской Mail Space';

    const topTitleSpace = 18;
    const bottomTitleSpace = 6;

    const onDmrResizeEvent = useCallback((height) => {
        // Высота заголовка и spacing'ов вокруг него
        const titleHeight = titleRef.current ? titleRef.current.offsetHeight + topTitleSpace + bottomTitleSpace : 0;
        sendFrameMessage({ type: DisableAdsPostMessageType.size, height: height + titleHeight });
    }, []);

    const onSuccess = useCallback(() => {
        sendFrameMessage({ type: DisableAdsPostMessageType.success });
    }, []);

    const onCloseSidebar = useCallback(() => {
        sendFrameMessage({ type: DisableAdsPostMessageType.close });
    }, []);

    return (
        <>
            <Content wrapClass={styles.content}>
                <Spacing size={topTitleSpace} className={styles.spacing} />
                <div className={styles.header} ref={titleRef}>
                    <div className={styles.backButton} onClick={onClose} title="Назад к тарифам">
                        <Icon24ArrowLeftOutine />
                    </div>
                    <div className={styles.title}>{title} </div>
                </div>
                <Spacing size={bottomTitleSpace} className={styles.spacing} />
                <VkBuyModalWithSidebar
                    productId={product?.id}
                    isFrame
                    paySource="modal_noads_tariff"
                    onClose={onCloseSidebar}
                    onSuccess={onSuccess}
                    type={EPaymentModalType.fastcheckout}
                    onDmrResize={onDmrResizeEvent}
                    withoutSidebar={true}
                />
            </Content>
        </>
    );
});

EmbeddedBuyScreen.displayName = 'EmbeddedBuy';
