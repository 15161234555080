import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { GetFamilyAPICall } from 'reactApp/api/family/GetFamilyAPICall';
import { GetFamilyPlanAPICall } from 'reactApp/api/family/GetFamilyPlanAPICall';
import { IS_FAMILY_USER, IS_FRAME_PAGES } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import {
    type familyLoadRequest,
    familyInitDataSuccess,
    familyLoadFailure,
    familyLoadSuccess,
    setFamilyPlan,
} from 'reactApp/modules/family/family.actions';
import { isUserWithFamily } from 'reactApp/modules/family/family.selectors';
import type { InitFamilyProps } from 'reactApp/modules/family/family.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { call, put, select } from 'typed-redux-saga';

const getFamilyAPICall = (withQuota?: boolean) => new GetFamilyAPICall().makeRequest(null, { url: withQuota ? '?add_avatar=1' : '' });
const getFamilyPlanAPICall = () => new GetFamilyPlanAPICall().makeRequest();

export function* getFamilyData(payload: InitFamilyProps = {}) {
    const { withQuota } = payload;

    try {
        const { data } = yield* call(getFamilyAPICall, withQuota);
        yield put(familyLoadSuccess(data));
        yield put(familyInitDataSuccess());
        xray.send('family_load_success');
    } catch (error) {
        yield put(familyLoadFailure());
        xray.send('family_load_failure');
        logger.error(error);
    }
}

export function* getFamilyPlan() {
    try {
        const { data } = yield* call(getFamilyPlanAPICall);
        yield put(setFamilyPlan(data));
        yield put(familyInitDataSuccess());
        xray.send('family_plan_load_success');
    } catch (error) {
        xray.send('family_plan_load_failure');
        logger.error(error);
    }
}

export function* initFamilyData(action: ReturnType<typeof familyLoadRequest>) {
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const isBizUser = yield* select(UserSelectors.isBizUser);

    if (!isFamilySubsAvailable || isAnonymous || isBizUser || IS_FRAME_PAGES) {
        return;
    }

    const withFamily = yield* select(isUserWithFamily);

    if (IS_FAMILY_USER || withFamily) {
        yield getFamilyData(action.payload);
        return;
    }

    yield getFamilyPlan();
}
