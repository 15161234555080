import type { Space } from '@mail/cross-sizes-utils';
import type { normalizeData } from 'reactApp/modules/products/products.helpers';
import type { Product } from 'reactApp/types/Billing';

export enum EProductPeriod {
    year = '1y',
    month = '1m',
}

export type IProductList = ReturnType<typeof normalizeData>;

export interface IProductsState {
    isLoading: boolean;
    selectedGroupName: string;
    hasError: boolean;
    list: IProductList;
    isLoaded: boolean;
}

export interface IProduct {
    id: string;
    turbo: boolean;
    price: number;
    period: string;
    available: boolean;
    isPromo: boolean;
    hasTrial: boolean;
    trialPeriod?: string;
    isProfessional: boolean;
    isDisko: boolean;
    isPrepaid: boolean;
    hasDiscount: boolean;
    discountPeriod?: string;
    discountPrice?: number;
    discountPeriodCount?: number;
    space: Space;
    isForceDiscountTrial?: boolean;
    gift?: {
        space: Space;
        period: string;
        id: string;
    };
}

export interface YearAndMonthProductIds {
    year: string[];
    month: string[];
}

export interface NormalizedTariffByProductIds {
    tariffId: string;
    space: Space;
    products: { yearProduct: Product; monthProduct?: Product };
}
