import { Icon16ChevronOutline } from '@vkontakte/icons';
import { Headline } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as CloudLogo } from 'img/icons/cloud_icon_inline_integration.svg';
import { ReactComponent as NewCloudLogo } from 'img/icons/new_cloud_icon_inline_integration.svg';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { showPayLanding } from 'reactApp/modules/integration/integration.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { type RootState, store as reduxStore } from 'reactApp/store';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { Tooltip } from 'reactApp/ui/Tooltip/Tooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import styles from './InlineIntegrationQuotaHeader.css';

export const InlineIntegrationQuotaHeader = () => {
    const [isHovered, setIsHovered] = useState(false);
    const isUserWithBilling = useSelector(UserSelectors.isUserWithBilling);
    const ref = useRef<HTMLDivElement>(null);
    const isCloudQuotePayEnabled = useSelector((state: RootState) => state.integration.isCloudQuotePayEnabled);

    const { total, usedTotal } = useQuotaInfo(false);
    const { usedPercent } = useSelector(UserQuotaSelectors.getUserQuotaState);
    const isNewWidgetIconsEnabled = useSelector((state: RootState) => state.integration.isNewWidgetIconsEnabled);

    const getTooltipText = () => {
        if (usedPercent >= 80 && usedPercent < 100) {
            return 'Облако почти заполнено,';
        }
        if (usedPercent === 100) {
            return 'Облако заполнено,';
        }
        if (usedPercent > 100) {
            return 'Облако переполнено,';
        }
    };

    return (
        <div className={styles.header}>
            {isNewWidgetIconsEnabled ? <NewCloudLogo className={styles.logo} /> : <CloudLogo className={styles.logo} />}
            <Headline level="2" weight="2" className={styles.text}>
                Облако
            </Headline>
            <div
                ref={ref}
                className={classNames(styles.loadStatus, {
                    [styles.isFull]: usedPercent >= 80,
                    [styles.isClickable]: isCloudQuotePayEnabled,
                })}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={
                    isCloudQuotePayEnabled
                        ? () => {
                              if (isUserWithBilling) {
                                  reduxStore.dispatch(showPayLanding());
                              } else {
                                  renderConfirmEmailMobile({
                                      onSuccess: () => {
                                          reduxStore.dispatch(showPayLanding());
                                      },
                                      closable: true,
                                  });
                              }
                          }
                        : undefined
                }
            >
                {usedTotal && (
                    <Headline level="2">
                        Занято {usedTotal?.value} из {total?.value}
                    </Headline>
                )}
                {isCloudQuotePayEnabled && <Icon16ChevronOutline />}
            </div>

            {usedPercent >= 80 && isHovered && (
                <Tooltip
                    theme="inlineIntegration"
                    target={ref}
                    minWidth={false}
                    placement={tooltipPlacements.BOTTOM}
                    smartPlacement={false}
                    noArrow={true}
                    offset={{ y: 4 }}
                >
                    <div className={styles.tooltip}>
                        <div>{getTooltipText()}</div>
                        <div>увеличьте объём памяти</div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
