import { Icon20ArrowLeftOutline } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { CLOUD_NEW_FEATURES_CONFIG } from 'reactApp/appHelpers/configHelpers';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { Button } from 'reactApp/ui/Button/Button';
import { ECloudNewFeaturesActions, sendCloudNewFeaturesAnalytics } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.analytics';
import { CLOUD_NEW_FEATURES_LANDING_HREF } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { FeaturesListItem } from 'reactApp/ui/CloudNewFeatures/FeaturesListItem/FeaturesListItem';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './CloudNewFeaturesModal.css';

export const CloudNewFeaturesModal = memo<IPropsWithPopup>(({ onClose }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        const el = scrollRef?.current;
        scrollLock(el, { reserveScrollBarGap: false });
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.showTool });

        return () => {
            scrollUnlock(el);
        };
    }, []);

    const handleClickAll = useCallback(() => {
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.clickViewAll });
    }, []);

    return (
        <LeftDialog onClose={onClose} closeIcon={<Icon20ArrowLeftOutline />}>
            <div className={styles.root}>
                <div className={styles.contentWrapper} ref={scrollRef}>
                    <div className={styles.content}>
                        <Text className={styles.title}>Полезные обновления</Text>
                        <Spacing size={24} />
                        {CLOUD_NEW_FEATURES_CONFIG.map(({ bannerid, title, text, link, image }) => {
                            return (
                                <FeaturesListItem bannerid={bannerid} title={title} text={text} image={image} link={link} key={bannerid} />
                            );
                        })}
                    </div>
                    {CLOUD_NEW_FEATURES_CONFIG.length > 2 && (
                        <div className={styles.buttonWrapper}>
                            <Spacing size={20} />
                            <Button theme="vk" secondary href={CLOUD_NEW_FEATURES_LANDING_HREF} target="_blank" onClick={handleClickAll}>
                                Посмотреть все
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </LeftDialog>
    );
});

CloudNewFeaturesModal.displayName = 'CloudNewFeaturesModal';
