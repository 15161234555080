import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'file/rename';
class RenameFileAPIV2Call extends APICallV2Post {
    _method = method;
}

class RenameFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const renameFileApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateFileV2ToV4.rename) {
        return new RenameFileAPIV4Call().makeRequest({ path: params.id, name: params.name, conflict: params.conflict });
    }

    return new RenameFileAPIV2Call().makeRequest({ home: params.id, name: params.name, conflict: params.conflict });
};
