import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import type { StorySummaryItem } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { noop } from 'reactApp/utils/helpers';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import styles from './StoryItem.css';

interface StoryItemProps {
    item: StorySummaryItem;
    isFirst?: boolean;
    onItemClick?({ id: string, type: EStoryType }): void;
}

export const StoryItem = memo(function StoryItem({ item, isFirst = false, onItemClick = noop }: StoryItemProps): JSX.Element | null {
    const handleClick = useCallback(() => {
        onItemClick({ id: item.id, type: item.type });
    }, [onItemClick, item]);

    return (
        <div
            className={classNames({ [styles.root]: true, [styles.root_notFirst]: !isFirst, [styles.root_viewed]: item.viewed })}
            onClick={handleClick}
        >
            <div className={styles.description}>
                <div className={styles.title}>{getTrimmedText(item.title, isFirst ? 65 : 45)}</div>
                {isFirst && <div className={styles.subtitle}>{getTrimmedText(item.subtitle, 80)}</div>}
            </div>
            <img className={styles.image} draggable={false} src={item.thumb} alt={item.title} />
        </div>
    );
});
