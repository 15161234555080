import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import classNames from 'clsx';
import { ReactComponent as DoneIcon } from 'mrg-icons/src/mailru/status/done.svg';
import React, { type VFC } from 'react';
import styles from 'reactApp/sections/SassLanding/TariffPlans/SassTariffFeatureList.css';
import type { ITariffFeatureListProps } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';

export type SassTariffFeatureListProps = ITariffFeatureListProps & {
    size?: 'sm' | 'm' | 'lg';
};

export const SassTariffFeatureList: VFC<SassTariffFeatureListProps> = ({ items, size }) => (
    <ul className={styles.featureList}>
        {items.map((item) => (
            <li
                key={item.key}
                className={classNames(styles.featureListItem, {
                    [styles.medium]: size === 'm',
                })}
                onClick={item.onClick}
            >
                <div className={styles.featureListItemIcon}>{item.icon || <DoneIcon fill={theme.colorIconAccent.normal.value} />}</div>
                <div>{item.text}</div>
            </li>
        ))}
    </ul>
);

SassTariffFeatureList.displayName = 'SassTariffFeatureList';
