import { Icon20ChevronRightOutline } from '@vkontakte/icons';
import React, { type VFC } from 'react';

import styles from './FileDirection.css';

type Props = {
    direction: string[];
};

export const FileDirection: VFC<Props> = ({ direction }) => (
    <div className={styles.root}>
        <span>Облако</span>
        {direction.length > 0 && (
            <>
                {direction.length > 1 && (
                    <>
                        <Icon20ChevronRightOutline />
                        <span>{'\u2026'}</span>
                    </>
                )}
                <Icon20ChevronRightOutline width={16} height={16} />
                <span className={styles.folder}>{direction.slice(-1)[0]}</span>
            </>
        )}
    </div>
);
