import React, { type FC, useMemo } from 'react';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';

interface DeleteButtonProps {
    items: CloudItem[];
    hook: ({ items }) => {
        callback: () => void;
        deleteSize: {
            size: number;
            sizeString: string;
        };
    };
}

/**
 * Кнопка тулбара - Удаление чего-либо
 * @param hook - хук, который вернет коллбек (действие удаления), удаляемый размер
 * @param items - массив удаляемых элементов
 */
export const DeleteButton: FC<DeleteButtonProps> = ({ hook, items }) => {
    const { callback, deleteSize } = hook({ items });
    const { size, sizeString } = deleteSize;

    const sizeText = useMemo(() => {
        if (!size) {
            return '';
        }

        return `\xA0${sizeString}`;
    }, [size, sizeString]);

    return (
        <Button theme="octavius" primary sizeMode={ButtonSizeMode.small} disabled={!size} onClick={callback} qaId="delete-btn">
            Удалить{sizeText}
        </Button>
    );
};
