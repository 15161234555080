import iconChrome from 'img/icons/chrome.png';
import iconFirefox from 'img/icons/firefox.png';
import React, { type ReactElement } from 'react';
import { Screen } from 'reactApp/ui/Screen/Screen';

import styles from './WebPushScreen.css';

interface IProps {
    onClose(): void;
    isCloudBlocked: boolean;
}

export const WebPushScreen = ({ onClose, isCloudBlocked }: IProps): ReactElement => {
    return (
        <Screen onClose={onClose}>
            <h1 className={styles.header}>
                {isCloudBlocked
                    ? 'Как удалить сайт cloud.mail.ru из списка заблокированных для получения уведомлений'
                    : 'Как разблокировать браузерные уведомления'}
            </h1>
            <h2 className={styles.subheader}>
                <img className={styles.icon} src={iconChrome} />
                Google Chrome
            </h2>
            <ul className={styles.list}>
                <li className={styles.listItem}>Откройте настройки Google Chrome</li>
                <li className={styles.listItem}>В блоке «Конфиденциальность и безопасность» выберите «Настройки сайтов»</li>
                {isCloudBlocked ? (
                    <>
                        <li className={styles.listItem}>
                            Перейдите в «Уведомления». Вы увидите список сайтов, отправка уведомлений от которых заблокирована
                        </li>
                        <li className={styles.listItem}>Выберите https://cloud.mail.ru и удалите из списка</li>
                    </>
                ) : (
                    <li className={styles.listItem}>Перейдите в «Уведомления» и включите возможность отправлять уведомления</li>
                )}
            </ul>
            <h2 className={styles.subheader}>
                <img className={styles.icon} src={iconFirefox} />
                Firefox
            </h2>
            <ul className={styles.list}>
                <li className={styles.listItem}>Откройте настройки Firefox</li>
                <li className={styles.listItem}>Выберите «Приватность и защита» и прокрутите до раздела «Разрешения»</li>
                {isCloudBlocked ? (
                    <li className={styles.listItem}>
                        Нажмите на кнопку «Параметры» напротив уведомлений и выберите статус «Разрешить» для https://cloud.mail.ru
                    </li>
                ) : (
                    <li className={styles.listItem}>
                        Нажмите на кнопку «Параметры» напротив уведомлений и уберите галку у пункта «Блокировать новые запросы на отправку
                        вам уведомлений»
                    </li>
                )}
            </ul>
        </Screen>
    );
};
