import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import autosaveCoverImage from 'img/aside-promo-modal/autosave-cover.jpg';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { readyEditor } from 'reactApp/modules/editor/editor.module';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import { isAutosavePromo } from 'reactApp/modules/features/features.helpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore, store } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { isEditingAttach } from 'reactApp/utils/isEditingFile';
import { sendEditRadar } from 'reactApp/utils/sendEditRadar';
import { put, take } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const AUTOSAVE_PROMO_ID = 'autosave';

const sendAutosavePromoRadar = (i: string) => {
    const state = reduxStore.getState();
    const currentStorage = getCurrentStorage(state);
    const { item } = getEditorState(state);

    if (item) {
        sendEditRadar({
            item,
            storage: currentStorage,
            i,
            isAttach: isEditingAttach(currentStorage),
            version: isWopiEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
        });
    }
};

export function* initAutosaveAsidePromoModal() {
    const isFeatureEnabled = yield isAutosavePromo;

    if (!isFeatureEnabled) {
        return;
    }

    const storage = yield select(getCurrentStorage);
    const isAlreadyShown = Boolean(storeHelper.getValue(AUTOSAVE_PROMO_ID));

    if ([EStorageType.editor, EStorageType.myoffice].includes(storage) && !isAlreadyShown) {
        yield put(
            addPromoToShowQueue({
                type: EPromoType.autosave,
                promoId: AUTOSAVE_PROMO_ID,
                onShow: () => {
                    sendAutosavePromoRadar('autosave-banner-show');
                    store.dispatch(promoShown(EPromoType.autosave));
                },
                onClose: () => {
                    sendAutosavePromoRadar('autosave-banner-close');
                    storeHelper.markAsShown(AUTOSAVE_PROMO_ID);
                    store.dispatch(removePromo(EPromoType.autosave));
                },
            })
        );
    }
}

export function* showAutosaveAsidePromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.autosave));
    const { isReady } = yield* select(getEditorState);

    if (!promo) {
        return;
    }

    if (!isReady) {
        yield take(readyEditor);
    }

    const { onShow, onClose } = promo;

    yield openAsidePromoModal({
        title: 'Автосохранение',
        text: 'Все изменения в документе автоматически сохраняются в Облаке',
        imageUrl: autosaveCoverImage,
        qaId: 'autosave',
        onShow,
        onClose,
    });
}
