import type { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { renderComponentInExternalDom } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';

export class BReactComponent {
    ReactComponent: ReactNode;
    deleteRootElement: boolean;
    props: any;
    el: Element | null = null;

    constructor({ ReactComponent, deleteRootElement = false }: { ReactComponent: ReactNode; deleteRootElement?: boolean }) {
        this.ReactComponent = ReactComponent;
        this.deleteRootElement = deleteRootElement;

        this.el = null;
        this.props = null;
    }

    _renderReactComponent = (data: { props: any } = { props: {} }) => {
        if (!document.body.contains(this.el)) {
            throw new Error(`BReactComponent: this.el must be mounted to the DOM tree!`);
        }

        const props = { ...this.props, ...data.props };

        this.props = props;

        ReactDOM.render(renderComponentInExternalDom(this.ReactComponent, props), this.el);
    };

    renderTo = (el, data) => {
        this.el = el;
        this.render(data);
    };

    render = (data) => this._renderReactComponent(data);

    init = () => {
        if (!this.ReactComponent) {
            throw new TypeError(`BReactComponent: the "ReactComponent" property must be defined!`);
        }

        if (this.el) {
            this._renderReactComponent();
        }
    };

    destroy = () => {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el);
        }

        const el = this.el;
        if (this.deleteRootElement && el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    };
}
