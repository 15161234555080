import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/icons/close_s__2.svg';
import { path } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    isHidePayMethodsMenuTouch,
    tariffBuyCustomDescription,
    tariffBuyCustomTitle,
    trialPrice,
} from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { isMidasPaymentAvailable } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { getPayMethod } from 'reactApp/modules/buySubscription/buySubscription.helpers';
import { PaymentWindowType } from 'reactApp/modules/payment/payment.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { Product } from 'reactApp/types/Billing';
import type { SpecialTariffProps } from 'reactApp/types/SpecialTariffProps';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { DMR } from 'reactApp/ui/DMRFrame/DMR';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';
import { noop } from 'reactApp/utils/helpers';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';
import { sendTmrGoal } from 'reactApp/utils/tmr';
import { getParameterFromUrl } from 'reactApp/utils/urlHelper';
import type { AnyAction } from 'redux';

import styles from './TariffBuy.css';

interface Props extends SpecialTariffProps {
    link: string | null;
    isLoading: boolean;
    hasError: boolean;
    isMobile: boolean;
    hasTrial: boolean;
    reset();
    buy(...args);
    id: Product['id'];
    product?: Product;
    onSuccess();
    onClose();
    onError();
    userTotalSpace: Product['space'];
    source?: string;
    isQuotaCleaner?: boolean;
    closable?: boolean;
    title?: string;
    payMethod?: string;
    isMidas?: boolean;
    isBytePromo?: boolean;
    paymentDescription?: string;
    eventCategory?: string;
    paySource?: string;
    onClick();
    subTitle?: string;
    enabledMidasPayment?: boolean;
    onPayBtnClick?: () => void;
}

interface State {
    isOpen: boolean;
    isSuccessPayment: boolean;
    frameHeight: number;
    orderAmount: string | null;
}

const mapStateToProps = (state, props): any => {
    const { isMobile, enabledMidasPayment } = props;

    const product = props.product || ProductsSelectors.getProductById(state, props.id) || {};
    const { space, period, price, hasTrial } = product;

    const { total: userTotalSpace } = UserSelectors.getCloudSpace(state);

    const { isLoading, hasError } = BillingSelectors.getLoadingState(state);
    const buyLink = BillingSelectors.getPaymentLink(state);

    const isQuickPayment = (isMobile && isHidePayMethodsMenuTouch) || hasTrial;

    return {
        product,
        link: buyLink,
        space,
        period,
        isLoading,
        hasError,
        price,
        userTotalSpace,
        hasTrial,
        payMethod: isQuickPayment ? undefined : getPayMethod(isMobile),
        isMidas: enabledMidasPayment && isMidasPaymentAvailable(isMobile) && !hasTrial,
        isBytePromo: product.id === PROMO_TARIFFS?.bytePromo,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    buy: (id: string, source, isMidas, payMethod): AnyAction => {
        const { isMobile, paymentDescription, paySource } = props;

        const { action = null, description = null } = getQueryParams();
        const descriptionFromQuery =
            action === 'request-payment' && tariffBuyCustomDescription?.hasOwnProperty(description)
                ? tariffBuyCustomDescription[description]
                : undefined;

        return dispatch(
            BillingActions.buySubscription({
                id,
                isMobile,
                payMethod,
                source,
                xraySource: 'tariff-modal',
                description: descriptionFromQuery || paymentDescription,
                isMidas,
                paySource,
            })
        );
    },
    reset: (): AnyAction => dispatch(BillingActions.resetLink()),
});

class TariffBuy extends PureComponent<Props, State> {
    public state = {
        isOpen: true,
        isSuccessPayment: false,
        frameHeight: 0,
        orderAmount: null,
    };

    public static defaultProps = {
        onSuccess: noop,
        onClose: noop,
        onError: noop,
        closable: true,
        onClick: noop,
    };

    public componentDidMount(): void {
        const { id, buy, source, isMidas, payMethod, paySource, enabledMidasPayment, product } = this.props;
        buy(id, source || paySource, isMidas, payMethod);
        emitAnalyticEvent(AnalyticEventNames.FAST_CHECKOUT_SHOWN, { paySource, product, productId: id, enabledMidasPayment });
        this.sendPayment('view');
    }

    public componentDidUpdate(prevProps: Props): void {
        const { hasError, onError, link } = this.props;

        if (!prevProps.hasError && hasError) {
            onError();
        }

        if (link !== prevProps.link && link) {
            this.setState({ orderAmount: getParameterFromUrl(link, 'order_amount') });
        }
    }

    sendPayment = (label, payMethod = undefined) => {
        const { id, paySource } = this.props;

        sendPaymentGa({ action: EPaymentGa.payment, label: `${label}_v2`, tariff: id, paySource, paymethod: payMethod });
    };

    private handleOnClose = (): void => {
        const { onClose } = this.props;

        onClose();

        this.setState({
            isOpen: false,
        });
    };

    private handleOnCancelDialog = (): void => {
        this.handleOnClose();
        this.sendPayment('close');
    };

    private handleDmrFormSend = (): void => {
        const { onClick, isMidas, onPayBtnClick } = this.props;

        if (isMidas) {
            return;
        }

        onPayBtnClick?.();
        this.sendPayment('click');
        onClick();
    };

    private handlePayClick = ({ method, isSwitchPaymentMethod }): void => {
        const { onClick, isMidas } = this.props;

        if (!isMidas) {
            return;
        }

        if (!isSwitchPaymentMethod) {
            this.sendPayment('click', method);
        }

        onClick();
    };

    private handleOnSuccess = (): void => {
        const { onSuccess, hasTrial } = this.props;

        sendTmrGoal({ goal: hasTrial ? 'trial-success' : 'pay-success' });

        this.setState({ isSuccessPayment: true }, () => {
            onSuccess();
        });
    };

    private handleError = (): void => {
        this.props.onError();
    };

    private handleResize = (data): void => {
        const newHeight = path(['height'], data) as number;
        const { frameHeight } = this.state;
        if (newHeight && frameHeight !== newHeight) {
            this.setState({ frameHeight: newHeight });
        }
    };

    private getHeader() {
        const { isSuccessPayment } = this.state;

        const { space, period, userTotalSpace, isMobile, hasTrial, price, product, title, subTitle, isBytePromo } = this.props;
        const { hasDiscount, discountPrice, discountPeriod, trialPeriod, isForceDiscountTrial, isMbUser } = product || {};

        const { title: titleFromQuery = null, action = null } = getQueryParams();

        if (isBytePromo && space && trialPeriod) {
            return `${space.value} на ${getPeriodName(trialPeriod, true)} бесплатно`;
        }

        if (action === 'request-payment' && tariffBuyCustomTitle?.hasOwnProperty(titleFromQuery)) {
            return tariffBuyCustomTitle[titleFromQuery];
        }

        if (title) {
            return title;
        }

        if (isSuccessPayment) {
            return 'Оплата прошла успешно';
        }

        if (isMobile && space) {
            if (isForceDiscountTrial) {
                return <>Подписка Mail&nbsp;Space {space.value}</>;
            }

            const priceToDisplay = hasDiscount ? discountPrice : price;
            const periodToDisplay = hasDiscount && discountPeriod ? discountPeriod : period;

            const priceText = priceToDisplay ? ` за ${formatPrice(priceToDisplay)} ₽` : '';

            if (isMbUser && hasTrial) {
                return `Введите данные карты, чтобы попробовать тариф ${space.value}`;
            }

            return hasTrial ? (
                `Введите данные карты для получения ${space.value}${trialPeriod ? ` на ${getPeriodName(trialPeriod, false, true)}` : ''}`
            ) : (
                <>
                    Оплата тарифа
                    <br />
                    {space.value} на {getPeriodName(periodToDisplay, false, true)}
                    {priceText}
                    {Boolean(subTitle) && <span className={styles.subtitle}>{subTitle}</span>}
                </>
            );
        }

        if (userTotalSpace && space) {
            const newSpace = bytesToNDigits(userTotalSpace.original + space.original, 3).value;
            return (
                <>
                    Увеличение объёма до {newSpace}
                    {Boolean(subTitle) && <span className={styles.subtitle}>{subTitle}</span>}
                </>
            );
        }

        return 'Подписка на Облако';
    }

    private getDescription() {
        const { product, isBytePromo } = this.props;

        if (isBytePromo && product?.discountPeriod) {
            return (
                <div className={styles.description}>
                    Затем {formatPrice(product.discountPrice)}&nbsp;₽ за&nbsp;
                    {getPeriodName(product.discountPeriod)}
                </div>
            );
        }

        if (product?.isForceDiscountTrial && product?.trialPeriod && product?.discountPeriod) {
            return (
                <div className={styles.description}>
                    {getPeriodName(product.trialPeriod, true)} бесплатно, затем {formatPrice(product.discountPrice)}&nbsp;₽ за&nbsp;
                    {getPeriodName(product.discountPeriod)}
                </div>
            );
        }

        return null;
    }

    public render() {
        const { isSuccessPayment, isOpen, frameHeight, orderAmount } = this.state;

        if (!isOpen) {
            return null;
        }

        const {
            isLoading,
            reset,
            product,
            hasError,
            link,
            isMobile,
            id,
            hasTrial,
            buy,
            source,
            isQuotaCleaner,
            closable,
            payMethod,
            isMidas,
            eventCategory,
            paySource,
        } = this.props;

        const defaultEventCategory = isMidas ? 'midasBuyModal' : 'newBuyModal';
        const isMobileMidas = isMobile && isMidas;

        return (
            <Dialog
                id="tariff-buy"
                open
                size="larger"
                header={isMobileMidas ? '' : this.getHeader()}
                onCancel={this.handleOnCancelDialog}
                mod={isMobile ? 'mobile' : 'frame'}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                dimmer={!isMobile}
                showCloseIcon={!isMobileMidas}
                disableScrollOnBody={!isQuotaCleaner}
                closable={closable}
                disableDarkTheme
                className={classNames({
                    [styles.midasModal]: isMidas,
                    [styles.mobileModal]: isMobile,
                })}
            >
                {this.getDescription()}
                <div
                    className={classNames(styles.root, {
                        [styles.root_mobile]: isMobile,
                        [styles.root_trial]: hasTrial,
                        [styles.root_midasMobile]: isMobileMidas,
                    })}
                >
                    {hasTrial && !!link && (
                        <Text className={styles.note} data-qa-id="note-check-payment">
                            Для проверки карты мы спишем и сразу же вернём&nbsp;{formatPrice(parseInt(orderAmount || '0') || trialPrice)}
                            &nbsp;₽.
                        </Text>
                    )}
                    {isMobileMidas && (
                        <div className={styles.headerBlock}>
                            <div className={styles.header}>{this.getHeader()}</div>
                            <div className={styles.closeIcon} onClick={this.handleOnClose}>
                                <IconClose />
                            </div>
                        </div>
                    )}
                    <div style={frameHeight && !isMidas ? { height: frameHeight } : {}} className={classNames(styles.dmrContainer)}>
                        <DMR
                            buySubscription={(productId) => buy(productId, source, isMidas, payMethod)}
                            resetSubscription={reset}
                            subscription={product}
                            onCancel={this.handleOnClose}
                            onSuccess={this.handleOnSuccess}
                            onFormSend={this.handleDmrFormSend}
                            onPayClick={this.handlePayClick}
                            isLoading={isLoading}
                            hasError={hasError}
                            onError={this.handleError}
                            onResize={this.handleResize}
                            checkoutLink={link}
                            className={styles.dmr}
                            disableLoaderCentering
                            eventCategory={eventCategory || defaultEventCategory}
                            size={isMobile ? 'medium' : 'larger'}
                            skin="vkpay"
                            payMethod={payMethod}
                            isMidas={isMidas}
                            paySource={paySource}
                            windowType={PaymentWindowType.default}
                        />
                    </div>
                    <div
                        className={classNames(styles.footer, {
                            [styles.footer_success]: isMobile && isSuccessPayment,
                        })}
                    >
                        <PaymentFooter productId={id} isMobile={isMobile} product={this.props.product} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default TariffBuy;

export const TariffBuyConnected = connect(mapStateToProps, mapDispatchToProps)(TariffBuy);
