import type { AstraMetaDataType } from './astraMeta.types';
import { getAstraMetaProcessing } from './getAstraMetaProcessing';

export const updateAstraMetaData = (metaData: AstraMetaDataType) => {
    const astraMetaDiv = document?.querySelector('#astra-meta');

    if (!astraMetaDiv) {
        return;
    }

    astraMetaDiv.setAttribute('data-file-processing', getAstraMetaProcessing(metaData));
};
