import classNames from 'clsx';
import { ReactComponent as MarketingPromoLabel } from 'img/marketingPromo/blackFridayLabel2023.svg';
import React, { type ReactElement, memo, useCallback, useMemo } from 'react';
import { isFamilySubsInTariff, tariffBuyCustomFeatures, welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { PROMO_TARIFFS_REGEX } from 'reactApp/modules/products/products.helpers';
import type { Product, Tariff } from 'reactApp/types/Billing';
import { Switch } from 'reactApp/ui/Switch/Switch';
import { getDiscount } from 'reactApp/utils/getDiscount';
import {
    getPeriodName,
    getTariffProductsByPeriods,
    isMonth6Period,
    isMonthPeriod,
    isYearPeriod,
    periodToDaysPeriod,
} from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

import styles from './FastCheckoutSidebar.css';

interface FastCheckoutSidebarQueryParams {
    cancelSending?: 'true';
    freeSpace?: 'true';
}

export const FastCheckoutSidebar = memo(
    ({
        tariff,
        product,
        onProductChange,
        onClickTariffs,
        isMarketingPromo,
        featuresList: customFeaturesList,
        isBuy,
        hideOtherTariffs,
        hideSwitchSidebarBlock,
    }: {
        tariff: Tariff;
        product: Product;
        onProductChange: (id: string) => void;
        onClickTariffs: () => void;
        isMarketingPromo?: boolean;
        featuresList?: string[];
        isBuy?: boolean;
        hideOtherTariffs?: boolean;
        hideSwitchSidebarBlock?: boolean;
    }): ReactElement => {
        const { goToSubscriptions } = useGoToSubscriptions();

        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);

        const { space, discountPeriod, period, discountPrice, price, hasDiscount, hasTrial, trialPeriod, isMbUser } = product;

        const { discount } = getDiscount({ discountPrice: discountPrice || 0, price });

        const isYear = isYearPeriod(period);

        const { cancelSending: cancelSendingFromQuery }: FastCheckoutSidebarQueryParams = getQueryParams();

        const cancelSending = cancelSendingFromQuery === 'true';

        const { freeSpace: freeSpaceFromQuery }: FastCheckoutSidebarQueryParams = getQueryParams();
        const freeSpace = freeSpaceFromQuery === 'true';

        const isActionProduct = isMarketingPromo && PROMO_TARIFFS_REGEX.marketingPromo.test(product?.id);

        const switchChange = useCallback(() => {
            if (monthProduct && yearProduct) {
                onProductChange(isYear ? monthProduct.id : yearProduct.id);
            }
        }, [isYear, monthProduct, onProductChange, yearProduct]);

        const isSwitchBlockVisible = !hideSwitchSidebarBlock;

        let queryFeatures: string[] = [];
        const { features: featuresFromQuery } = getQueryParams();
        if (featuresFromQuery && tariffBuyCustomFeatures[featuresFromQuery]) {
            queryFeatures = tariffBuyCustomFeatures[featuresFromQuery];
        }

        const clickTariffs = useCallback(() => {
            onClickTariffs();

            goToSubscriptions({
                inSameTab: false,
                isNewTariffsPage: true,
                query: isBuy ? PaymentUTM.fastcheckoutBuy : PaymentUTM.fastcheckout,
            });
        }, [onClickTariffs, goToSubscriptions, isBuy]);

        const priceBlock = useMemo(() => {
            if (isMbUser && hasTrial && trialPeriod) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.price}>Бесплатно</div>
                        <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                            {getPeriodName(periodToDaysPeriod(trialPeriod))} бесплатно, далее {formatPrice(price)} ₽ в{' '}
                            {getPeriodName(period)}
                        </div>
                    </div>
                );
            }

            if (product?.isForceDiscountTrial && product?.trialPeriod) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.price}>Бесплатно</div>
                        <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                            {getPeriodName(product.trialPeriod)} за 0 ₽, далее {formatPrice(discountPrice)} ₽ в {getPeriodName(period)}
                        </div>
                    </div>
                );
            }

            if (!isActionProduct && hasDiscount && discountPrice && discountPeriod) {
                return (
                    <div className={styles.discountBlock}>
                        <div className={styles.oldPrice}>
                            {formatPrice(price)} ₽/{getPeriodName(period)}
                        </div>
                        <div className={styles.price}>
                            {formatPrice(discountPrice)} ₽/{getPeriodName(discountPeriod)}
                        </div>
                    </div>
                );
            }

            if (isYearPeriod(period) || isMonth6Period(period)) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.priceInfo}>
                            Подписка на {getPeriodName(period)} по цене {formatPrice(discountPrice || price)}&nbsp;₽
                        </div>
                        <div className={styles.price}>{formatPrice(getMonthProductPrice(product))} ₽/месяц</div>
                    </div>
                );
            }

            if (hasTrial && trialPeriod) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.price}>Бесплатно</div>
                        <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                            {getPeriodName(periodToDaysPeriod(trialPeriod))} за 0 ₽, далее {formatPrice(price)} ₽ в {getPeriodName(period)}
                        </div>
                    </div>
                );
            }

            if (isMonthPeriod(period)) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.priceInfo}>Подписка на месяц по цене</div>
                        <div className={styles.price}>{formatPrice(price)} ₽/месяц</div>
                    </div>
                );
            }
        }, [discountPeriod, discountPrice, hasDiscount, period, price, product]);

        const yearSwitch = useCallback(
            (isRight: boolean) => (
                <div
                    className={classNames({
                        [styles.switchLabel]: true,
                        [styles.switchLabel_right]: isRight,
                        [styles.switchLabel_active]: isYear,
                        [styles.switchLabelAction_active]: isYear && isActionProduct,
                    })}
                >
                    На год
                </div>
            ),
            [isActionProduct, isYear]
        );

        const monthSwitch = useCallback(
            (isRight: boolean) => (
                <div
                    className={classNames({
                        [styles.switchLabel]: true,
                        [styles.switchLabel_right]: isRight,
                        [styles.switchLabel_active]: !isYear,
                    })}
                >
                    На {getPeriodName(monthProduct?.period)}
                </div>
            ),
            [isYear, monthProduct?.period]
        );

        const switchBlock = useMemo(
            () =>
                tariff?.products?.length > 1 &&
                monthProduct && (
                    <div className={styles.switch} onClick={switchChange}>
                        {isMarketingPromo ? (
                            <>
                                {monthSwitch(false)}
                                <Switch
                                    isAlwaysWhite
                                    name={`tariff_${product.id}`}
                                    checked={isYear}
                                    theme="big"
                                    isAction={isActionProduct}
                                />
                                {yearSwitch(true)}
                                <div className={styles.actionDiscountLabel}>
                                    <MarketingPromoLabel />
                                </div>
                            </>
                        ) : (
                            <>
                                {yearSwitch(false)}
                                <Switch isAlwaysWhite name={`tariff_${product.id}`} checked={!isYear} theme="big" />
                                {monthSwitch(true)}
                            </>
                        )}
                    </div>
                ),
            [
                isActionProduct,
                isMarketingPromo,
                isYear,
                monthProduct,
                monthSwitch,
                product.id,
                switchChange,
                tariff?.products?.length,
                yearSwitch,
            ]
        );

        const featuresList = useMemo(() => {
            if (customFeaturesList?.length) {
                return customFeaturesList;
            }
            if (queryFeatures?.length) {
                return queryFeatures;
            }
            const autodeleteText = 'Автоудаление папок';
            const cancelSendingText = 'Отмена отправки писем';
            const additionalFeatures = cancelSending ? [cancelSendingText] : [];

            if (tariff.isSingleQuota) {
                const base = [
                    'Без рекламы в\u00A0Почте\u00A0и\u00A0Облаке',
                    'Загрузка файлов до 100 ГБ в\u00A0Облако',
                    'Отправка файлов до 100 ГБ в\u00A0письмах',
                ];

                // tempexp_17086-start
                if (freeSpace || welcomeDefaultTariff?.tariff === tariff.id) {
                    return [...base, 'Делитесь местом с близкими', 'Свободная память в телефоне c автозагрузкой'];
                }
                // tempexp_17086-end

                return isFamilySubsInTariff
                    ? [...base, 'Делитесь местом с близкими', ...additionalFeatures, autodeleteText]
                    : [...base, ...additionalFeatures, autodeleteText, 'Приоритетная поддержка'];
            }

            return ['Никакой рекламы', 'Загрузка файлов до 32 ГБ', autodeleteText, 'Приоритетная поддержка'];
        }, [tariff.isSingleQuota, tariff.id]);

        return (
            <div className={styles.root}>
                <div
                    className={classNames(styles.tariffInfo, {
                        [styles.tariffInfo_action]: isActionProduct,
                    })}
                >
                    {!isActionProduct && hasDiscount && !!discount && <div className={styles.label}>Скидка {discount}%</div>}
                    <div className={styles.header}>
                        <div className={styles.quota}>+{space.space}</div>
                        <div className={styles.units}>{space.units}</div>
                    </div>

                    <>
                        {isSwitchBlockVisible && switchBlock}
                        {priceBlock}
                    </>

                    <ol
                        className={classNames(styles.features, {
                            [styles.features_action]: isActionProduct,
                        })}
                    >
                        {featuresList.map((text, index) => (
                            <li key={index}>{text}</li>
                        ))}
                    </ol>
                </div>
                {!hideOtherTariffs && (
                    <div className={styles.tariffsLink} onClick={clickTariffs}>
                        Другие тарифы
                    </div>
                )}
            </div>
        );
    }
);

FastCheckoutSidebar.displayName = 'FastCheckoutSidebar';
