import classNames from 'clsx';
import React, { memo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import styles from 'reactApp/ui/PollPopup/PollPopup.css';

export const FooterButton = memo(
    ({
        onClick,
        enableSubmit,
        isPhone,
        isRebrandingQuotaLanding,
    }: {
        onClick(): void;
        enableSubmit: boolean;
        isPhone: boolean;
        isRebrandingQuotaLanding?: boolean;
    }) => (
        <div
            className={classNames(styles.buttonsWrapper, {
                [styles.buttonsWrapper_mobile]: isPhone,
                [styles.buttonsWrapper_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            })}
        >
            <Button
                className={styles.button}
                data-name="confirm"
                onClick={onClick}
                theme={isPhone ? 'vk' : 'octavius'}
                primary
                disabled={!enableSubmit}
            >
                Отправить
            </Button>
        </div>
    )
);

FooterButton.displayName = 'FooterButton';
