import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import image from 'img/blockViewer.png';
import React, { type FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './BlockViewer.css';

const ACTION_STOP_FILES_OPEN = 'stop-files-open';

export const BlockViewer: FC = () => {
    const dispatch = useDispatch();
    const overquota = useSelector(UserQuotaSelectors.getOverQuota);

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_FILES_VIEW);
        sendPaymentGa({
            action: ACTION_STOP_FILES_OPEN,
            label: 'view',
            place: 'viewer',
        });
    }, []);

    const handlePrimaryClick = useCallback(() => {
        dispatch(closeViewer());
        emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_FILES_CLICK);
        sendPaymentGa({
            action: ACTION_STOP_FILES_OPEN,
            label: 'click',
            place: 'viewer',
        });
        dispatch(
            historyPush({
                id: getIdByStorage(EStorageType.quotaCleaner),
                search: `?${PaymentUTM.overquotaSlashViewerSpace}`,
            })
        );
    }, [dispatch]);

    const handleSecondaryClick = useCallback(() => {
        dispatch(closeViewer());
        sendPaymentGa({
            action: ACTION_STOP_FILES_OPEN,
            label: 'cleaner',
            place: 'viewer',
        });
        dispatch(
            historyPush({
                id: getIdByStorage(EStorageType.quotaCleaner),
                search: `?${PaymentUTM.overquotaSlashViewerCleaner}`,
                hash: 'cleaner',
            })
        );
    }, [dispatch]);

    return (
        <div className={styles.root}>
            <img src={image} alt="Посмотреть файлы пока нельзя" className={styles.image} />
            <Spacing size={40} />
            <Text className={styles.title}>Посмотреть файлы пока нельзя</Text>
            <Spacing size={8} />
            <Text className={styles.text}>
                Облако переполнено на {overquota.value}. Чтобы оно работало без&nbsp;ограничений — увеличьте или очистите место
            </Text>
            <Spacing size={20} />
            <div
                className={classNames(styles.buttonsWrapper, {
                    ['vkui--cloud--dark']: true,
                })}
            >
                <Button theme="vk" sizeMode={ButtonSizeMode.extraSmall} className={styles.button} onClick={handlePrimaryClick}>
                    Увеличить место
                </Button>
                <Button theme="vk" sizeMode={ButtonSizeMode.extraSmall} className={styles.button} onClick={handleSecondaryClick}>
                    Удалить лишнее
                </Button>
            </div>
        </div>
    );
};
