import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

const Component = videojs.getComponent('Component');
const Button = videojs.getComponent('Button');

export class LoopButton extends Button {
    constructor(player: VideoPlayer, options?: videojs.ComponentOptions & { name?: string }) {
        super(player, options);
        this.controlText(player.localize('Loop'));
    }

    buildCSSClass() {
        return `vjs-loop-control ${super.buildCSSClass()}`;
    }

    handleClick() {
        if (!this.player_.loop()) {
            const ended = this.player_.ended();

            this.player_.trigger('loopchange');
            this.player_.loop(true);
            this.controlText(this.player_.localize('Non-Loop'));

            if (ended) {
                this.player_.play();
            }

            this.toggleClass('vjs-loop-enabled', true);
        } else {
            this.player_.trigger('loopchange');
            this.player_.loop(false);
            this.controlText(this.player_.localize('Loop'));
            this.toggleClass('vjs-loop-enabled', false);
        }
    }
}

Component.registerComponent('LoopButton', LoopButton);
