import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { ECloudNewFeaturesActions, sendCloudNewFeaturesAnalytics } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.analytics';
import { getViewedCloudFeatures, markCloudFeatureAsViewed } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import type { ICloudNewFeaturesItem } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.types';

import styles from './FeaturesListItem.css';

export const FeaturesListItem = memo<ICloudNewFeaturesItem>(({ bannerid, title, text, link, image }) => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const viewedFeatures = getViewedCloudFeatures();
    const isViewed = viewedFeatures.includes(bannerid);

    const [showIndicator, setShowIndicator] = useState(!isViewed);

    const handleClick = useCallback(() => {
        if (!isViewed) {
            setShowIndicator(false);
            markCloudFeatureAsViewed(bannerid);
        }
        sendCloudNewFeaturesAnalytics({
            action: ECloudNewFeaturesActions.clickTool,
            place: title,
        });
    }, [setShowIndicator, bannerid, isViewed, title]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            <a className={styles.link} href={link} title={title} onClick={handleClick} target="_blank" rel="noreferrer">
                <div className={styles.content}>
                    <Text className={styles.title}>{title}</Text>
                    <Spacing size={4} />
                    <Text className={styles.text}>{text}</Text>
                </div>
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={image} alt={title} />
                </div>
            </a>
            {showIndicator && <div className={styles.informer} />}
        </div>
    );
});

FeaturesListItem.displayName = 'FeaturesListItem';
