import React, { type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';

import styles from './UpdateLicenseDialog.css';

interface WelcomeDialogProps {
    onSuccess: () => void;
}

export const UpdateLicenseDialog: FC<WelcomeDialogProps> = ({ onSuccess }) => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);

    return (
        <BaseConfirmDialog
            dataQAId="update-license-dialog"
            className={styles.dialog}
            onSuccess={() => {
                onSuccess();
                dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source: 'update-la',
                        place: storage,
                    })
                );
            }}
            onShow={() => {
                dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source: 'update-la',
                        place: storage,
                    })
                );
            }}
            onClose={() => {
                onSuccess();
                dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source: 'update-la',
                        place: storage,
                    })
                );
            }}
            renderHeader={() => 'Обновление'}
            renderContent={() => (
                <div className={styles.content}>
                    Внесли новые описания и уточнения в{' '}
                    <a
                        href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        лицензионное соглашение
                    </a>
                </div>
            )}
            size="average"
            buttonTheme="vk"
            buttons={[EButtonType.accept]}
            successButtonText="Понятно"
        />
    );
};
