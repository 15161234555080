import React from 'react';
import { useSelector } from 'react-redux';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { ViewerEbook, ViewerEbookProps } from 'reactApp/ui/ViewerEbook/ViewerEbook';

export const withRenderIsStock = (Component: typeof ViewerEbook) =>
    function WithRenderIsStock(props: ViewerEbookProps) {
        const { itemStorage } = props;
        const attachType: EAttachTypes | false = useSelector(
            (state: RootState) =>
                itemStorage === EStorageType.viewerAttaches && AttachesSelectors.getViewerAttachById(state, props.file.id)?.attachType
        );

        if (
            props.itemStorage === EStorageType.stock ||
            (itemStorage &&
                [EStorageType.attaches, EStorageType.viewerAttaches].includes(itemStorage) &&
                attachType !== EAttachTypes.attach)
        ) {
            return props.renderStub();
        }

        return <Component {...props} />;
    };
