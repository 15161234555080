import { Icon20Check } from '@vkontakte/icons';
import { Button, Headline, List, SimpleCell, unstable_Popper as Popper } from '@vkontakte/vkui';
import React, { useRef } from 'react';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';
import { searchFileTypeOptionsList } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudSearch/SelectFromCloudSearch.data';
import {
    type SearchFileTypeOption,
    SearchOptionType,
} from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudSearch/SelectFromCloudSearch.types';

import styles from './CategoryFilters.css';

interface CategoryFiltersProps {
    searchOption?: SearchFileTypeOption;
    onSelect: (type: string) => void;
    onReset: () => void;
    rootRef: React.RefObject<HTMLDivElement>;
    targetRef: React.RefObject<HTMLElement>;
    onClickAway: () => void;
}

interface OptionsList extends SearchFileTypeOption {
    onClick: () => void;
}

export const CategoryFilters: React.FC<CategoryFiltersProps> = ({ searchOption, onSelect, onReset, targetRef, onClickAway }) => {
    const ref = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    useClickOutsideTarget({ ref: contentRef, rootRef: targetRef, onClickAway });

    const optionsList: OptionsList[] = searchFileTypeOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            onSelect(item.id);
        },
    }));

    return (
        <Popper placement="bottom-end" targetRef={targetRef} forcePortal={false}>
            <div ref={contentRef}>
                <div ref={ref} id="dropdownList" data-qa-id="search-options">
                    <List className={styles.container}>
                        <SimpleCell
                            hasHover={false}
                            hasActive={false}
                            after={
                                searchOption?.id !== SearchOptionType.media && (
                                    <Button
                                        className={styles.resetButton}
                                        hasHover={false}
                                        mode="tertiary"
                                        onClick={onReset}
                                        data-qa-id="search-options-reset"
                                    >
                                        <Headline>Сбросить</Headline>
                                    </Button>
                                )
                            }
                        >
                            <Headline level="1" weight="2" data-qa-id="search-options-title">
                                Искать
                            </Headline>
                        </SimpleCell>
                        {optionsList.map(({ id, icon, text, onClick }) => (
                            <SimpleCell
                                key={id}
                                before={icon}
                                onClick={onClick}
                                after={searchOption?.id === id && <Icon20Check />}
                                data-qa-id={`search-option-${id}`}
                            >
                                {text}
                            </SimpleCell>
                        ))}
                    </List>
                </div>
            </div>
        </Popper>
    );
};

CategoryFilters.displayName = 'CategoryFilters';
