import { type PayloadAction, createReducer } from '@reduxjs/toolkit';
import { initBizCategories, setCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.actions';
import { type BizCategoryState, BizCategoryType } from 'reactApp/modules/bizCategories/bizCategories.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

const INITIAL_CATEGORIES = [
    {
        name: 'Все',
        id: BizCategoryType.all,
        value: BizCategoryType.all,
    },
    {
        name: 'Папки',
        id: BizCategoryType.folder,
        value: BizCategoryType.folder,
    },
    {
        name: 'Документы',
        id: BizCategoryType.document,
        value: BizCategoryType.document,
    },
    {
        name: 'Таблицы',
        id: BizCategoryType.spreadsheet,
        value: BizCategoryType.spreadsheet,
    },
    {
        name: 'Pdf-документы',
        id: BizCategoryType.pdf,
        value: BizCategoryType.pdf,
    },
    {
        name: 'Презентации',
        id: BizCategoryType.presentation,
        value: BizCategoryType.presentation,
    },
    {
        name: 'Архивы',
        id: BizCategoryType.archive,
        value: BizCategoryType.archive,
    },
    {
        name: 'Изображения',
        id: BizCategoryType.image,
        value: BizCategoryType.image,
    },
    {
        name: 'Музыка',
        id: BizCategoryType.audio,
        value: BizCategoryType.audio,
    },
    {
        name: 'Видео',
        id: BizCategoryType.video,
        value: BizCategoryType.video,
    },
    {
        name: 'Другие',
        id: BizCategoryType.other,
        value: BizCategoryType.other,
    },
];

const initialState: BizCategoryState = {
    categories: [],
};

export const bizCategoryReducer = createReducer(initialState, {
    [initBizCategories.type]: (state, action: PayloadAction<EStorageType>) => {
        const { payload } = action;

        if (payload === EStorageType.attaches) {
            state.categories = INITIAL_CATEGORIES.filter((item) => item.id !== BizCategoryType.folder);
            return;
        }

        state.categories = INITIAL_CATEGORIES;
    },
    [setCurrentCategory.type]: (state, action: PayloadAction<BizCategoryType>) => {
        const { payload } = action;

        if (payload === state.currentCategory) {
            state.currentCategory = BizCategoryType.all;
            return;
        }

        state.currentCategory = payload;
    },
});
