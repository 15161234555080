import { createAction } from '@reduxjs/toolkit';
import type {
    LoadProductsAndOpenFastCheckoutAction,
    OpenBuyCheckoutAction,
    OpenBuyDisableAdsAction,
    OpenFastCheckoutAction,
    OpenPromocodeGiftModalAfterBuyAction,
    OpenTariffBuyAction,
} from 'reactApp/modules/payment/payment.types';

export const openFastCheckout = createAction<OpenFastCheckoutAction>('payment/openFastCheckout');
export const openTariffBuy = createAction<OpenTariffBuyAction>('payment/openTariffBuy');
export const openPromocodeGiftModalAfterBuy = createAction<OpenPromocodeGiftModalAfterBuyAction>('payment/openPromocodeGiftModalAfterBuy');
export const loadDataForSubscriptions = createAction('payment/loadDataForSubscriptions');
export const loadProductsAndOpenFastCheckout = createAction<LoadProductsAndOpenFastCheckoutAction>(
    'payment/loadProductsAndOpenFastCheckout'
);
export const openBuyCheckout = createAction<OpenBuyCheckoutAction>('payment/openBuyCheckout');
export const openBuyDisableAds = createAction<OpenBuyDisableAdsAction>('payment/openBuyDisableAds');
