import { DOCUMENT_ID } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

import { type IState, ERecognitionStatus } from './personalDocuments.types';

export const loadingState = {
    isLoading: true,
    isLoaded: false,
    isError: false,
};

export const successState = {
    isLoading: false,
    isLoaded: true,
    isError: false,
};

export const failureState = {
    isLoading: false,
    isLoaded: true,
    isError: true,
};

export const initialState: IState = {
    isLoading: false,
    isLoaded: false,
    isError: false,
    recognitionStatus: ERecognitionStatus.DISABLED,
    list: {},
};

export const PERSONAL_DOCUMENTS_FOLDER_NAME = 'Копии документов';

export const MAP_ROUTE_TO_ID = Object.entries(DOCUMENT_ID).reduce((res, [key, value]) => {
    res[`/${key}`] = value;
    return res;
}, {});
