import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type {
    BinClearRequestAction,
    BinClearSuccessAction,
    BinDeleteFilesRequestAction,
    BinRestoreRequestAction,
    LoadMoreTrashbinRequestAction,
    LoadMoreTrashbinSuccessAction,
    LoadTrashbinSizeSuccessAction,
    LoadTrashbinSuccessAction,
    TrashbinApiResponseItem,
    TrashbinItem,
    TrashbinState,
    UpdateTrashbinSucessAction,
} from 'reactApp/modules/trashbin/trashbin.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime, renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

import { getExtension, getItemNameWithoutExt } from '../file/utils';

const initialState: TrashbinState = {
    opened: '/',
    bins: [],
    files: {},
    removing: false,
    size: 0,
};

export const trashbinLoad = createAction('trashbin/load/request');
export const trashbinRestoreRequest = createAction<BinRestoreRequestAction>('trashbin/restore/request');
export const trashbinLoadSuccess = createAction<LoadTrashbinSuccessAction>('trashbin/load/success');
export const toggleBin = createAction<{ id: string }>('trashbin/toggle');
export const updateBinByIdSuccess = createAction<{ id: string; bin: LoadTrashbinSuccessAction }>('trashbin/update/success');
export const deleteFilesFromBinRequest = createAction<BinDeleteFilesRequestAction>('trashbin/deleteFiles/request');
export const binClearRequest = createAction<BinClearRequestAction>('trashbin/clear/request');
export const binClearSuccess = createAction<BinClearSuccessAction>('trashbin/clear/success');
export const trashbinLoadMoreRequest = createAction<LoadMoreTrashbinRequestAction>('trashbin/loadMore/request');
export const trashbinLoadMoreSuccess = createAction<LoadMoreTrashbinSuccessAction>('trashbin/loadMore/success');
export const trashbinRestoreAllRequest = createAction('trashbin/restoreAll/request');
export const trashbinRestoreAllStart = createAction('trashbin/restoreAll/start');
export const trashbinRestoreAllUpdate = createAction<{ status: boolean }>('trashbin/restoreAll/update');
export const trashbinUpdateSizeSuccess = createAction<LoadTrashbinSizeSuccessAction>('trashbin/updateSize/success');

const urlBuilder = new UrlBuilder();

const normalizeItem = (item: TrashbinApiResponseItem, parentId, parentName): TrashbinItem => {
    const homePath = `${item.deleted_from}${item.name}`;

    const baseProps = {
        id: `${item.rev}:${homePath}`,
        home: homePath,
        name: item.name,
        deletedFrom: item.deleted_from,
        deletedAt: normalizeMtime(item.deleted_at),
        deletedBy: normalizeMtime(item.deleted_by),
        rev: item.rev,
        size: item.size,
        parent: parentId,
        binName: parentName,
    };

    if (item.type === 'file') {
        const ext = getExtension(item);
        const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
        const { kind, subKind } = extInfo.get(ext);

        return {
            ...baseProps,
            isFolder: false,
            __reduxTree: true,
            storage: EStorageType.trashbin,
            hash: item.hash,
            ext,
            subKind,
            kind,
            nameWithoutExt,
            thumbnails: urlBuilder.getThumb({
                hash: item.hash,
                ext,
                id: homePath,
                size: item.size,
                kind,
                name: item.name,
                isPublic: false,
                isStock: false,
                dwl_token: null,
                weblink: '',
            }),
        };
    }

    return {
        ...baseProps,
        name: renameCameraUploadFolder(item),
        isFolder: true,
        kind: item.kind,
        __reduxTree: true,
        storage: EStorageType.trashbin,
        count: {
            files: item.count.files,
            folders: item.count.folders,
            all: item.count.files + item.count.folders,
            loaded: 0,
        },
    };
};

export const trashbinReducer = createReducer(initialState, {
    [trashbinLoadSuccess.type]: (state, action: PayloadAction<LoadTrashbinSuccessAction>) => {
        state.bins = [];
        state.files = {};
        action.payload.forEach((bin) => {
            const childs: string[] = [];
            const count = {
                folders: 0,
                files: 0,
            };

            bin.list.forEach((item) => {
                const normalizedItem = normalizeItem(item, bin.id, bin.name);
                state.files[normalizedItem.id] = normalizedItem;
                childs.push(normalizedItem.id);
                count[normalizedItem.isFolder ? 'folders' : 'files'] += 1;
            });

            state.bins.push({
                id: bin.id,
                name: bin.name,
                childs,
                isLoaded: true,
                isLoading: false,
                hasMoreToLoad: !!bin.next_chunk_rev,
                nextChunkRev: bin.next_chunk_rev,
                tree: bin.tree,
                isDomain: bin.isDomain,
                count: {
                    ...count,
                    all: childs.length,
                    loaded: childs.length,
                },
            });
        });
    },
    [trashbinLoadMoreRequest.type]: (state, action: PayloadAction<LoadMoreTrashbinRequestAction>) => {
        const bin = state.bins.find((bin) => bin.id === action.payload.id);

        if (bin) {
            bin.isLoading = true;
        }
    },
    [trashbinLoadMoreSuccess.type]: (state, action: PayloadAction<LoadMoreTrashbinSuccessAction>) => {
        const bin = state.bins.find((bin) => bin.id === action.payload.id);

        if (bin) {
            const count = {
                folders: bin.count.folders,
                files: bin.count.files,
            };

            bin.isLoading = false;
            action.payload.bin.list.forEach((item) => {
                const normalizedItem = normalizeItem(item, bin.id, bin.name);
                state.files[normalizedItem.id] = normalizedItem;
                bin.childs.push(normalizedItem.id);
                count[normalizedItem.isFolder ? 'folders' : 'files'] += 1;
            });
            const nextChunkRev = action.payload.bin.next_chunk_rev;
            bin.nextChunkRev = nextChunkRev;
            bin.hasMoreToLoad = !!nextChunkRev;
            bin.count = {
                ...count,
                all: bin.childs.length,
                loaded: bin.childs.length,
            };
        }
    },
    [toggleBin.type]: (state, action: PayloadAction<{ id: string }>) => {
        state.opened = action.payload.id;
    },
    [updateBinByIdSuccess.type]: (state, action: PayloadAction<UpdateTrashbinSucessAction>) => {
        const bin = state.bins.find((bin) => bin.id === action.payload.id);

        if (bin) {
            const childs: string[] = [];
            const count = {
                folders: 0,
                files: 0,
            };

            // eslint-disable-next-line sonarjs/no-identical-functions
            action.payload.bin.list.forEach((item) => {
                const normalizedItem = normalizeItem(item, bin.id, bin.name);
                state.files[normalizedItem.id] = normalizedItem;
                childs.push(normalizedItem.id);
                count[normalizedItem.isFolder ? 'folders' : 'files'] += 1;
            });
            bin.childs = childs;
            const nextChunkRev = action.payload.bin.next_chunk_rev;
            bin.nextChunkRev = nextChunkRev;
            bin.hasMoreToLoad = !!nextChunkRev;
            bin.count = {
                ...count,
                all: childs.length,
                loaded: childs.length,
            };
        }
    },
    [binClearSuccess.type]: (state, action: PayloadAction<BinClearSuccessAction>) => {
        const bin = state.bins.find((bin) => bin.id === action.payload.id);

        if (bin) {
            bin.childs.forEach((id) => {
                delete state.files[id];
            });
            bin.nextChunkRev = 0;
            bin.childs = [];
            bin.count = {
                folders: 0,
                files: 0,
                all: 0,
                loaded: 0,
            };
        }

        state.size = 0;
    },
    [trashbinRestoreAllStart.type]: (state) => {
        state.removing = true;
    },
    [trashbinRestoreAllUpdate.type]: (state, action: PayloadAction<{ status: boolean }>) => {
        state.removing = action.payload.status;
        if (!state.removing) {
            state.bins.forEach((bin) => {
                bin.childs = [];
            });

            state.files = {};
            state.size = 0;
        }
    },
    [trashbinUpdateSizeSuccess.type]: (state, action: PayloadAction<LoadTrashbinSizeSuccessAction>) => {
        state.size = action.payload.size;
    },
});
