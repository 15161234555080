/* eslint-disable max-lines-per-function */
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16ChainOutline, Icon16Done } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_DOCUMENTS_DOMAIN, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { historyPush } from 'reactApp/modules/router/router.module';
import { scrollToHomeItemAction } from 'reactApp/modules/selections/selections.actions';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { cancelDescriptor } from 'reactApp/modules/uploading/uploading.module';
import {
    getFolderCloudPath,
    hasProblemFile,
    isFileDone,
    isFolder,
    showErrorIcon,
    showInfoIcon,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { sendGaUploader } from 'reactApp/modules/uploadList/uploadList.helpers';
import { type IInputFile, EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { DOCUMENT_ID, ITEMS_META } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';
import FilePicture from 'reactApp/ui/FilePicture/FilePicture';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { LoaderIcon } from 'reactApp/ui/UploadList/Item/LoaderIcon';
import { MessageIcon } from 'reactApp/ui/UploadList/Item/MessageIcon';
import { RemainTime } from 'reactApp/ui/UploadList/Item/RemainTime';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import opener from 'reactApp/utils/opener';
import { getPublicParentUrl } from 'reactApp/utils/urlHelper';

import styles from './Item.css';

interface IProps {
    file: IInputFile;
    show: boolean;
    updateUploadFiles: (data) => void;
    showError: (file: IInputFile) => void;
    isMobile?: boolean;
    storage: EStorageType;
    uploadSpeed?: number;
    narrow?: boolean;
}

export const Item = memo(
    ({ file, show, updateUploadFiles, showError, isMobile = false, storage, uploadSpeed = 0, narrow = false }: IProps) => {
        const [iconHover, setIconHover] = useState(false);
        const [fileTitle, setFileTitle] = useState('');
        const itemRef = useRef<HTMLDivElement>(null);
        const publishRef = useRef<HTMLDivElement>(null);
        const fileDone = isFileDone(file);
        const fileProblems = hasProblemFile(file);
        const isInfoIcon = showInfoIcon(file);
        const isErrorIcon = showErrorIcon(file);
        const dispatch = useDispatch();
        const { isPublic, isHome, isAlbums, isIntegration } = getStorage(storage);
        const iconName = file?.extension?.toLowerCase();
        const showInfoIconOnDesktop = isInfoIcon && (!isMobile || !fileDone);

        useEffect(() => {
            if (show) {
                itemRef?.current?.scrollIntoView();
            }
        }, [show]);

        useEffect(() => {
            if (fileDone) {
                const itemType = isFolder(file) ? 'папке' : 'файлу';
                setFileTitle(`Перейти к ${itemType}`);
            }
        }, [fileDone]);

        const publishHandler = useCallback(
            (event) => {
                event.stopPropagation();

                sendGaUploader('file', 'publish');
                let id = file.cloudPath;

                if (isFolder(file)) {
                    id = getFolderCloudPath(file);
                }

                if (id) {
                    publishHelper({ item: { id, storage: isAlbums ? EStorageType.home : undefined }, publishFrom: 'uploader' });
                    updateUploadFiles({ descriptorId: file.descriptorId, cloudPath: file.cloudPath, publish: true });
                }
            },
            [file]
        );

        const cancelHandler = useCallback(() => {
            if (fileProblems) {
                return;
            }

            if (file?.files?.length || file?.extension === 'folder') {
                dispatch(cancelDescriptor(file.localPath));
            }

            updateUploadFiles({
                cloudPath: file.cloudPath,
                descriptorId: file.descriptorId,
                status: EFileStatus.CANCEL,
                error: EFileError.CANCELLED_FILE,
                hideError: true,
            });
        }, [file]);

        const onCloseMouseEnter = useCallback(() => {
            setIconHover(true);
        }, []);

        const onCloseMouseLeave = useCallback(() => {
            setIconHover(false);
        }, []);

        const showMessageHandler = useCallback(
            (e) => {
                e.stopPropagation();

                showError(file);
            },
            [file, showError]
        );

        const showItemHandler = useCallback(
            (event) => {
                const publishElem = publishRef?.current;
                const isPublishClick = publishElem === event?.target || event?.target?.parentElement === publishElem;

                if (!fileDone || isPublishClick) {
                    if (isMobile && (isInfoIcon || isErrorIcon)) {
                        showError(file);
                    }
                    return;
                }

                if (file.documentType) {
                    const path = ITEMS_META[DOCUMENT_ID[file.documentType]]?.title;
                    if (!path) {
                        return;
                    }

                    dispatch(historyPush({ id: `/documents/${path}` }));
                    return;
                }

                const isItemFolder = isFolder(file);
                let id = file.cloudPath;

                if (IS_DOCUMENTS_DOMAIN) {
                    const url = `${changeDocumentDomainToCloud(window.location.origin)}/home${id}`;

                    return opener(url);
                }

                if (isItemFolder) {
                    id = getFolderCloudPath(file);

                    if (isPublic) {
                        id = `${getPublicParentUrl()}/${file.cloudPath}`;
                    }
                }

                dispatch(scrollToHomeItemAction({ itemId: id, isFolder: isItemFolder }));
            },
            [file, showError]
        );

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.cursor]: fileDone && isHome,
                    [styles.highlight]: file.highlight,
                    [styles.root_mobile]: isMobile,
                    [styles.narrow]: narrow,
                })}
                ref={itemRef}
                onClick={showItemHandler}
                data-qa-filename={file.extension ? `${file.name}.${file.extension}` : file.name}
                title={fileTitle}
            >
                <div className={classNames(styles.elements, { [styles.responsive]: ENABLE_FULL_RESPONSIVE, [styles.narrow]: narrow })}>
                    <div
                        className={classNames({
                            [styles.wrapperText]: true,
                            [styles.wrapperText_progress]: !fileDone,
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.narrow]: narrow,
                        })}
                    >
                        <div className={styles.type}>
                            <FilePicture
                                icon={iconName}
                                file={file}
                                thumbUrl={isFolder(file) ? '' : file.thumb}
                                borderRadius={true}
                                removeLoader={() => true}
                                loadThumb={() => true}
                            />
                        </div>
                        <div className={styles.name}>
                            <NameComponent
                                name={file.name}
                                extension={!isFolder(file) ? file?.extension : undefined}
                                truncate
                                showTooltip
                            />
                            {!isMobile && (
                                <div className={styles.size}>
                                    {typeof file.size == 'number' && file.size >= 0 && bytesToNDigits(file.size, 3).value}
                                </div>
                            )}
                        </div>
                        <MessageIcon
                            isErrorIcon={isErrorIcon}
                            showInfoIcon={showInfoIconOnDesktop}
                            showMessageHandler={showMessageHandler}
                        />
                    </div>
                    {fileDone && !isPublic && !isIntegration && (
                        <div
                            className={classNames({
                                [styles.publish]: true,
                                [styles.small]: file.publish,
                                [styles.mobile]: IS_MY_TEAM && isMobile,
                            })}
                            onClick={publishHandler}
                            ref={publishRef}
                            title=""
                        >
                            {file.publish ? (
                                <Hint text="Открыть доступ к файлу">
                                    <Icon16ChainOutline />
                                </Hint>
                            ) : (
                                <>
                                    <Icon16ChainOutline />
                                    {!IS_MY_TEAM && <span className={styles.publishText}>Поделиться</span>}
                                </>
                            )}
                        </div>
                    )}
                    {!fileDone && !isMobile && !(isErrorIcon || showInfoIconOnDesktop) && !fileProblems && (
                        <RemainTime
                            sizeToUpload={file.size && file.uploadedSize ? file.size - file.uploadedSize : undefined}
                            uploadSpeed={isFolder(file) ? uploadSpeed : 0}
                            timeRemain={file.timeRemain}
                            showTooltip
                            narrow={narrow}
                        />
                    )}
                    {!fileDone && (
                        <div
                            className={styles.LoadingIconWrap}
                            onClick={cancelHandler}
                            onMouseEnter={onCloseMouseEnter}
                            onMouseLeave={onCloseMouseLeave}
                        >
                            {!fileProblems && <LoaderIcon progress={file.progress} loading={file.currentUpload} iconHover={iconHover} />}
                        </div>
                    )}
                    {fileDone && isMobile && (
                        <div className={styles.icon}>
                            <Icon16Done width={16} height={16} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

Item.displayName = 'UploadListItem';
