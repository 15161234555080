import { EStorageType } from 'reactApp/modules/storage/storage.types';

export enum ESiteZone {
    shoveFile = 1,
    shoveFolder = 2,
    allPrivate = 6,
    folderPublic = 7,
    indiPublic = 8,
    viewerPublic = 9,
}

export enum ESiteZoneErrorPage {
    INTERNAL_SERVER_ERROR = 20,
    NOT_FOUND = 21,
    TOO_MANY_REQUESTS = 22,
    NOT_AVAILABLE = 23,
    GATEWAY_TIMEOUT = 24,
    BAD_BROWSER = 25,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    BAD_BROWSER_ATOM = 25,
}

export const ADS_STORAGE = {
    [EStorageType.home]: 'home',
    [EStorageType.public]: 'public',
    [EStorageType.favorites]: 'favorites',
    [EStorageType.sharedIncoming]: 'shared_incoming',
    [EStorageType.sharedLinks]: 'shared_links',
    [EStorageType.sharedAutodelete]: 'shared_autodelete',
    [EStorageType.viewerAttaches]: 'viewer_attaches',
    [EStorageType.attaches]: 'attaches',
    [EStorageType.stock]: 'stock',
    [EStorageType.trashbin]: 'trashbin',
    [EStorageType.editor]: 'editor',
    [EStorageType.recommend]: 'recommend',
    [EStorageType.feed]: 'feed',
    [EStorageType.start]: 'start',
    [EStorageType.gallery]: 'gallery',
    [EStorageType.documents]: 'documents',
    [EStorageType.templates]: 'templates',
    [EStorageType.archive]: 'archive',
    [EStorageType.story]: 'story',
    [EStorageType.albums]: 'albums',
    [EStorageType.alldocuments]: 'alldocuments',
    [EStorageType.pdfEdit]: 'pdf_edit',
    [EStorageType.family]: 'family',
    [EStorageType.safe]: 'safe',
    [EStorageType.attachesViewerDocuments]: 'attaches_vdoc',
    [EStorageType.attachesViewerError]: 'attaches_verr',
};
