import { Icon20WriteOutline } from '@vkontakte/icons';
import React, { type FC, useEffect } from 'react';
import type { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import { ViewerHeaderButton } from 'reactApp/ui/ReactViewer/ViewerHeader/ViewerHeaderButton';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

interface Props {
    onClick: () => void;
    item: CloudFile | AttachesItem | IStoryFileItem | null;
    itemStorage?: EStorageType | null;
    isJsAdaptivity?: boolean;
    isCollapsed?: boolean;
}

export const ViewerHeaderImageEditButton: FC<Props> = ({ onClick, item, itemStorage, isJsAdaptivity, isCollapsed }) => {
    useEffect(() => {
        sendDwh({
            eventCategory: ECategoryGa.photoEditor,
            action: 'show-editor',
            dwhData: {
                size_file: item?.size ?? 0,
                extension: item?.ext ?? '',
                id: item?.id ?? '',
                source: itemStorage,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ViewerHeaderButton
            title="Редактировать"
            icon={<Icon20WriteOutline />}
            onClick={onClick}
            hint="Редактировать"
            isFilled={false}
            id="edit_image"
            iconOnly={isJsAdaptivity && isCollapsed}
            collapsableText={!isJsAdaptivity}
        />
    );
};
