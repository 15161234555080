import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { equals } from 'ramda';
import { SubscriptionListAPICall } from 'reactApp/api/billing/subscription/SubscriptionListAPICall';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import type { QueryParams } from 'reactApp/modules//settings/settings.types';
import { EStorageType } from 'reactApp/modules//storage/storage.types';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import {
    getSubscriptionsRequest,
    getSubscriptionsSuccess,
    setActiveSubscription,
    startSubscriptionsPageRequest,
    updateSubscriptionsRequest,
    updateSubscriptionsSuccess,
} from 'reactApp/modules/subscriptions/subscriptions.module';
import { getSubscriptionsList, getSubscriptionsRaw } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { ESubscriptionsNotifications } from 'reactApp/modules/subscriptions/subscriptions.types';
import ping from 'reactApp/utils/ping';
import { call, cancel, put, select, take, takeEvery } from 'redux-saga/effects';
const DISKO_KEY_SIZE = 4;

const callApi = () => new SubscriptionListAPICall().makeRequest();

function* handleSubscriptionsPageStart() {
    try {
        yield put(
            routeChangeSuccess({
                id: ROOT_FOLDER_ID,
                storage: EStorageType.subscriptions,
                params: {},
                __isFolder: true,
                url: '',
                __parent: '',
            })
        );

        const { action, diskoKey }: QueryParams = yield select(SettingsSelectors.getQueryParams);

        if (action === 'show-subscription' && diskoKey && diskoKey.length >= DISKO_KEY_SIZE) {
            yield put(getSubscriptionsRequest());

            yield take(getSubscriptionsSuccess);

            const list = yield select(getSubscriptionsList);

            const subscription = list.find((item) => item.diskoKey?.endsWith(diskoKey));
            if (subscription) {
                yield put(setActiveSubscription(subscription.id));
            }
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

export function* handleSubscriptionsRequest() {
    try {
        const { data } = yield callApi();
        if (!data) {
            logger.error('no subscriptions data from api');
            captureException('no subscriptions data from api');
            yield cancel();
        }

        yield put(getSubscriptionsSuccess({ list: data }));
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

const checkSubscriptionEquals =
    (oldData) =>
    ({ data }) => {
        const { android, ios, web } = data || {};
        const newData = {
            android,
            ios,
            web,
        };

        return !equals(newData, oldData);
    };

function* handleUpdateSubscriptionsRequest(action: ReturnType<typeof updateSubscriptionsRequest>) {
    const { showSnackbar = false } = action.payload || {};

    try {
        const { android, ios, web } = yield select(getSubscriptionsRaw);

        const initial = {
            android,
            ios,
            web,
        };

        const res = yield call(ping, { request: callApi, check: checkSubscriptionEquals(initial) });

        yield put(updateSubscriptionsSuccess({ list: res.data }));

        if (showSnackbar) {
            yield put(
                showSnackbarAction({
                    text: 'Список подписок обновлён',
                    type: SnackbarTypes.success,
                    id: ESubscriptionsNotifications.updateSuccess,
                    closable: true,
                })
            );
        }
    } catch (error) {
        logger.error(error);
        captureException(error);

        showSnackbarAction({
            text: 'Не удалось обновить список подписок',
            type: SnackbarTypes.failure,
            id: ESubscriptionsNotifications.updateFailure,
            closable: true,
        });

        yield cancel();
    }
}

export function* watchSubscriptionsRoot() {
    yield takeEvery(startSubscriptionsPageRequest.toString(), handleSubscriptionsPageStart);
    yield takeEvery(getSubscriptionsRequest.toString(), handleSubscriptionsRequest);
    yield takeEvery(updateSubscriptionsRequest.toString(), handleUpdateSubscriptionsRequest);
}
