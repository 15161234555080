import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

const paidUserPricesValue: 'a' | 'b' = getFeature('paid-user-prices');

if (!IS_MOBILE_BROWSER) {
    registerExperiment('paid-usr-prices', paidUserPricesValue, [
        AnalyticEventNames.QUOTA_LANDING_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ]);
}

export const isPaidUserPrices = !IS_MOBILE_BROWSER && paidUserPricesValue === 'b';
