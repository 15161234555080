import { useSelector } from 'react-redux';
import { getCurrentAuthorInFilter, isAllFiltered } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import { isFolderOwner, mapFolderAuthorsWithContacts } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';

import { useAuthorEmails } from './useAuthorEmails';

const AUTHORS_COUNT = 2;

export const useAuthors = () => {
    const isOwn = useSelector(isFolderOwner);
    const authorsEmails = useAuthorEmails(isOwn);
    const filter = useSelector(getCurrentAuthorInFilter);
    const authors = useSelector((state: RootState) => mapFolderAuthorsWithContacts(state, authorsEmails));
    const isAll = useSelector(isAllFiltered);
    const authorsList = isAll ? authors.map(({ email }) => email) : filter;
    const renderedAuthors = authorsList.slice(0, AUTHORS_COUNT);
    const counter = authorsList.length - renderedAuthors.length;

    return { counter, renderedAuthors, isOwn };
};
