import React, { type ReactElement, type ReactNode, memo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { noop } from 'reactApp/utils/helpers';

import styles from './SwipingComponent.css';

interface Props {
    children: ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSwipe?: Function;
    disable?: boolean;
    enableForward?: boolean;
    enableBack?: boolean;
    id: string;
}

export const SwipingComponent = memo(
    ({ children, onSwipe = noop, disable = false, id, enableForward, enableBack }: Props): ReactElement => {
        const rootRef = useRef<null | any>(null);
        const [prevId, setPrevId] = useState(id);

        const [{ x, opacity }, set] = useSpring(() => ({
            x: 0,
            opacity: 1,
            config: { mass: 1, tension: 190, friction: 26 },
        }));

        const bind = useDrag(
            (state) => {
                const {
                    down,
                    movement: [mx],
                    swipe: [swipeX],
                    touches,
                    memo,
                    buttons,
                    canceled,
                    cancel,
                    first,
                } = state;

                if (disable || canceled) {
                    return;
                }

                if (first && buttons === 0) {
                    // Такое прилетает при клике в ПКМ
                    cancel();
                    return;
                }

                // если свайп более чем одним пальцем, то не учитываем свайп
                if (!rootRef.current || touches > 1) {
                    return;
                }

                let wasHidden = false;

                const { width } = rootRef.current.getBoundingClientRect();

                const mxAbs = Math.abs(mx);
                const toRight = Math.sign(mx) === 1;

                if ((toRight && !enableBack) || (!toRight && !enableForward)) {
                    return;
                }

                // если сделан длинный свайп, то скрываем компонент
                if (swipeX === 1 || mxAbs > width / 3) {
                    const newX = toRight ? width : -width;

                    if (memo?.wasHidden) {
                        // хватит одного раза
                        return;
                    }
                    set({
                        x: newX,
                        opacity: 0,
                    });
                    wasHidden = true;

                    setTimeout(() => {
                        onSwipe(toRight);
                    }, 300);
                } else {
                    set({
                        x: down ? mx : 0,
                        opacity: down ? 1 - mxAbs / width : 1,
                    });
                }

                return { x, opacity, wasHidden };
            },
            { axis: 'x', swipeDistance: 50 }
        );

        if (id !== prevId) {
            setPrevId(id);
            set({
                x: 0,
                opacity: 1,
                from: { x: 0, opacity: 0 },
                immediate: true,
            });
        }

        return (
            <animated.div
                className={styles.root}
                ref={rootRef}
                {...bind()}
                style={
                    disable
                        ? {}
                        : {
                              transform: x.interpolate((x) => `translateX(${x}px)`),
                              opacity,
                              touchAction: 'none',
                          }
                }
            >
                {children}
            </animated.div>
        );
    }
);

SwipingComponent.displayName = 'SwipingComponent';
