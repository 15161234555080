import classNames from 'clsx';
import React, { type VFC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { loadIncomingStartRequest } from 'reactApp/modules/incoming/incoming.module';
import { getIncomingLoadingState } from 'reactApp/modules/incoming/incoming.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedIncomingPage.css';

export const SharedIncomingPage: VFC = () => {
    const dispatch = useDispatch();
    const { isLoaded } = useSelector(getIncomingLoadingState);
    const showSharedTabs = !IS_BIZ_USER;

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'shared-incoming' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'shared-incoming' });
        dispatch(loadIncomingStartRequest());
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                {isLoaded ? <BreadcrumbsContainer /> : <div className={styles.breadPlaceholder} />}
                {showSharedTabs && <SharedTabs />}
            </div>
            <DataList goTo={noop} storage={EStorageType.sharedIncoming} selectableClassName={styles.datalistSelectable} />
        </div>
    );
};
