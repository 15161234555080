import { requestPayment } from 'Cloud/Application/Payment';
import blockedCloud from 'img/icons/cloud-blocked.svg?url';
import overquotaImg from 'img/overquotaMobile.png';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import { type UseContentHook, ESplashScreenMod } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';

export const useOverquotaSplashScreen: UseContentHook = ({ hookParams = {} }) => {
    const { over } = useSelector(UserSelectors.getCloudSpace);
    const product = useSelector((state: RootState) => getProductById(state, hookParams?.productId));

    const description = isRebranding
        ? 'Хранилище переполнено и вы больше не можете загружать фото, делиться файлами и создавать папки. Для снятия ограничения увеличьте Облако.'
        : `Ваше хранилище переполнено на ${over?.value}. Вы больше не можете загружать фото, делиться файлами, создавать папки. Для снятия ограничений увеличьте Облако.`;

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id });
    }, [product?.id]);

    return {
        id: 'mobile-overquota',
        title: 'Работа вашего Облака остановлена',
        description,
        buttonText: `Увеличить на ${product?.space?.value}`,
        onClick,
        img: isRebranding ? blockedCloud : overquotaImg,
        mod: isRebranding ? ESplashScreenMod.rebranding : ESplashScreenMod.red,
    };
};
