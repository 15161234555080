import { createReducer } from '@reduxjs/toolkit';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import {
    weblinkAccessControlLoadStart,
    weblinkAccessControlReset,
    weblinkAccessControlSetError,
    weblinkDeleteAllEmailAccess,
    weblinkSetEmailAccessList,
} from './weblinkAccessControl.actions';
import type { IWeblinkAccessControlState, IWeblinkACEmailListItem } from './weblinkAccessControl.types';

const initialState: IWeblinkAccessControlState = {
    isLoading: true,
    acList: [],
    error: '',
};

const convertListToACL = (invited): IWeblinkACEmailListItem[] => {
    if (!invited?.length) {
        return [];
    }

    return invited.map((item) => ({
        email: item.email,
        name: item.name || item.email,
        accessRights: item.access,
        avatarUrl: createAvatarUrl(item.email, item.name),
    }));
};

export const weblinkAccessControlReducer = createReducer(initialState, {
    [weblinkAccessControlLoadStart.type]: (state) => {
        state.isLoading = true;
    },
    [weblinkAccessControlSetError.type]: (state, action) => {
        const { error } = action.payload;
        state.error = error;
        state.isLoading = false;
    },
    [weblinkAccessControlReset.type]: () => {
        return { ...initialState };
    },
    [weblinkSetEmailAccessList.type]: (state, action) => {
        const { share } = action.payload;

        state.acList = convertListToACL(share);
        state.isLoading = false;
        state.error = '';
    },
    [weblinkDeleteAllEmailAccess.type]: (state) => {
        state.isLoading = true;
    },
});
