import classNames from 'clsx';
import React, { memo } from 'react';
import type { Tariff } from 'reactApp/types/Billing';
import { useYearSaving } from 'reactApp/ui/TariffSectionNew/hooks/useYearSaving';
import { getTariffProductsByPeriods } from 'reactApp/utils/Period';

import styles from './DiscountLabel.css';

export const DiscountLabel = memo(
    ({
        tariff,
        discount,
        isAction,
        isRebrandingQuotaLanding,
        isFromLitres = false,
    }: {
        tariff?: Tariff;
        discount?: string | number;
        isAction?: boolean;
        isRebrandingQuotaLanding?: boolean;
        /* tempexp_17509-next-line */
        isFromLitres?: boolean;
    }) => {
        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products || []);

        const [_, yearSavingDiscount] = useYearSaving({ yearProduct, monthProduct });

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_action]: isAction,
                    [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                    /* tempexp_17509-next-line */
                    [styles.root_fromLitres]: isFromLitres,
                })}
            >
                - {yearSavingDiscount || discount}%
            </div>
        );
    }
);

DiscountLabel.displayName = 'DiscountLabel';
