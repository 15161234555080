import { Text } from '@vkontakte/vkui';
import { isThisYear, isToday, isYesterday } from 'date-fns';
import nodata from 'img/no-data.png';
import nodatadark from 'img/no-data_dark.png';
import { groupBy } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { activitiesFeature } from 'reactApp/appHelpers/featuresHelpers';
import type { Activity, RequestActivitiesSuccessPaylod } from 'reactApp/modules/activities/activities.types';
import {
    extractLocalPart,
    getActionElement,
    truncateTime,
} from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesList/format.utilities';
import styles from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesLog.css';
import { createAvatarUrl } from 'reactApp/utils/avatar';
import { formatLocale } from 'reactApp/utils/formatDate';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

type Props = {
    items: RequestActivitiesSuccessPaylod;
    currentUserEmail: string;
    isLoading: boolean;
};

export const FileFolderChangesList = ({ items, currentUserEmail, isLoading = false }: Props) => {
    const [imageSrc, setImageSrc] = useState(nodata);

    const renderActivity = (activity: Activity, currentUserEmail, key) => {
        const { actor, time } = activity;
        return (
            <div key={key} className={styles.list_item}>
                <div className={styles.icon}>
                    <img loading="lazy" src={createAvatarUrl(actor.email, actor.nick, 32)} />
                </div>
                <Text className={styles.name}>{actor.nick || extractLocalPart(actor.email)}</Text>
                <Text className={styles.date}>{formatLocale(time, 'HH:mm')}</Text>
                <Text className={styles.email}>{actor.email}</Text>
                <Text className={styles.action}>{getActionElement(activity, currentUserEmail)}</Text>
            </div>
        );
    };

    const renderGroupHeader = (date) => {
        const dateTime = new Date(+date);
        return (
            <div key={date} className={styles.head_date}>
                {formatLocale(dateTime, isThisYear(dateTime) ? 'dd MMMM' : 'dd MMMM yyyy')}{' '}
                <span>{isToday(dateTime) ? 'Сегодня' : (isYesterday(dateTime) && 'Вчера') || null}</span>
            </div>
        );
    };

    const elements = useMemo(() => {
        const groupedByDate = groupBy<RequestActivitiesSuccessPaylod[0]>((entry) => truncateTime(entry.time).toString(10), items);

        return Object.keys(groupedByDate)
            .sort((a, b) => +b - +a)
            .reduce<JSX.Element[]>((collection, date) => {
                const head = renderGroupHeader(date);
                const items = groupedByDate[date].map((entry: Activity, index) =>
                    renderActivity(entry, currentUserEmail, `itm_${date}_${index}`)
                );
                return collection.concat(head, ...items);
            }, []);
    }, [items, currentUserEmail]);

    useEffect(() => {
        if (isDarkThemeModeEnabled()) {
            setImageSrc(nodatadark);
        }
    }, []);

    const showItems = elements.length || isLoading;
    const showEmpty = !showItems;

    return (
        <>
            {showItems && elements}
            {showEmpty && (
                <div className={styles.empty_stub}>
                    <img src={imageSrc} />
                    <span>Нет данных за&nbsp;последние&nbsp;{activitiesFeature.period}&nbsp;дней</span>
                </div>
            )}
        </>
    );
};
