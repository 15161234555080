import classNames from 'clsx';
import React, { type VFC, useMemo } from 'react';
import styles from 'reactApp/sections/SassLanding/TariffPlans/SassTariffCard.css';
import { SassTariffFeatureList } from 'reactApp/sections/SassLanding/TariffPlans/SassTariffFeatureList';
import { Button } from 'reactApp/ui/Button/Button';
import type { ITariffCardProps } from 'reactApp/ui/TariffCardNew/TariffCard.types';

export type SassTariffCardProps = ITariffCardProps & {
    size?: 'sm' | 'm' | 'lg';
};

export const SassTariffCard: VFC<SassTariffCardProps> = ({
    onClick,
    space,
    buttonText,
    buttonPrimary,
    buttonAdditionalText,
    focused,
    onClickButton,
    featureListOptions,
    discountLabel,
    savingInButton,
    boldSavingLabel = false,
    buttonDisabled,
    disable,
    size,
}) => {
    const button = useMemo(
        () => (
            <>
                <div className={classNames(styles.buttonWrapper)}>
                    <Button
                        fluid
                        onClick={onClickButton}
                        disabled={buttonDisabled}
                        className={classNames(styles.cardButton, {
                            [styles.cardButton_primary]: buttonPrimary,
                            [styles.cardButton_disabled]: buttonDisabled,
                        })}
                    >
                        <span className={styles.buttonMainText}>{buttonText}</span>
                        {Boolean(buttonAdditionalText) && <div className={styles.buttonAdditionalText}>{buttonAdditionalText}</div>}
                    </Button>
                    {Boolean(savingInButton && !buttonDisabled) && (
                        <div
                            className={classNames(styles.saving, {
                                [styles.boldSavingLabel]: boldSavingLabel,
                            })}
                        >
                            -{savingInButton}%
                        </div>
                    )}
                </div>
            </>
        ),
        [buttonAdditionalText, buttonPrimary, buttonText, discountLabel, onClickButton, savingInButton]
    );

    return (
        <div
            className={classNames(styles.card, {
                [styles.card_focused]: focused && !disable,
                [styles.card_disable]: disable,
            })}
            onClick={onClick}
        >
            <div className={classNames(styles.cardTitle)}>
                <span className={classNames(styles.cardTitleSpace)}>{space.space}</span>{' '}
                <span className={classNames(styles.cardTitleUnit)}>{space.units}</span>
            </div>
            {featureListOptions && (
                <div className={classNames(styles.featureList)}>
                    <SassTariffFeatureList items={featureListOptions} size={size} />
                </div>
            )}
            <>{button}</>
        </div>
    );
};

SassTariffCard.displayName = 'SassTariffCard';
