import { createReducer } from '@reduxjs/toolkit';

import { loadFeaturesAction } from './features.actions';
import type { IState } from './features.types';

export const initialState: IState = {
    list: [],
    isLoading: false,
    hasError: false,
};

export const featuresReducer = createReducer(initialState, {
    [loadFeaturesAction.type]: (state) => {
        return {
            ...state,
            isLoaded: true,
            list: [],
        };
    },
});
