import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { isWelcomePromoShow } from 'reactApp/modules/welcome/welcome.selectors';
import Countdown from 'reactApp/ui/Countdown/Countdown';
import { createGaSender } from 'reactApp/utils/ga';

import styles from './SpaceCountdown.css';
import type { ICountdownPromo } from './SpaceCountdown.types';

const sendGaCountdown = createGaSender('ab-space-countdown');

export const SpaceCountdown = memo(({ button, onClick }: ICountdownPromo): ReactElement | null => {
    const countdownPromo = useSelector(PromoSelectors.getPromo(EPromoType.space));

    const { data, endDate } = countdownPromo || {};

    useEffect(() => {
        if (countdownPromo) {
            sendGaCountdown('show', data?.id);
            countdownPromo.onShow();
        }
    }, [countdownPromo]);

    const handleClick = useCallback(
        (event) => {
            sendGaCountdown('click', data?.id);
            onClick(event);
        },
        [onClick]
    );

    const isWelcomePromoShown = useSelector(isWelcomePromoShow);

    if (!countdownPromo || isWelcomePromoShown) {
        return null;
    }

    return (
        <div className={styles.root} onClick={handleClick}>
            <div className={styles.info}>
                <div className={styles.description}>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: data?.text || '' }} />
                    <div className={styles.timer}>
                        <Countdown showIcon showAsPlainText theme="dark" endDate={endDate} />
                    </div>
                </div>
                <div>{data?.img}</div>
            </div>
            {button}
        </div>
    );
});

SpaceCountdown.displayName = 'SpaceCountdown';
