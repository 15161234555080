import { createAction, createReducer } from '@reduxjs/toolkit';
import type { State } from 'reactApp/modules/welcome/welcome.types';

const initialState: State = {
    isShow: false,
};

export const showWelcomePromo = createAction('welcome/showPromo');
export const hideWelcomePromo = createAction('welcome/hidePromo');
export const showWelcome = createAction('welcome/showWelcome');
export const acceptWelcomeLA = createAction('welcome/acceptWelcomeLA');

export const welcomeReducer = createReducer(initialState, {
    [showWelcomePromo.type]: (state) => {
        state.isShow = true;
    },
    [hideWelcomePromo.type]: (state) => {
        state.isShow = false;
    },
});
