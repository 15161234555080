import { useEffect, useState } from 'react';
import { useSpringRef, useTransition } from 'react-spring';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { useElementSize } from 'reactApp/hooks/useElementSize';
import { sendDwh } from 'reactApp/utils/ga';

export function useExpandTransition(source?: string) {
    const [setAnimatedContainerRef, _, { height }] = useElementSize();
    const [isExpanded, setExpanded] = useState(true);

    const transRef = useSpringRef();
    const transform = (collapsed: boolean) => (collapsed ? `translate3d(0,${height - 56}px,0)` : 'translate3d(0,0px,0)');

    const transitions = useTransition(isExpanded, {
        ref: transRef,
        keys: null,
        from: { transform: transform(isExpanded) },
        enter: { transform: transform(!isExpanded) },
    });

    useEffect(() => {
        transRef.start();
    }, [isExpanded]);

    return {
        transitions,
        toggleExpand: () => {
            emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_HIDE);
            sendDwh({
                eventCategory: 'brand_page',
                action: 'hide',
                dwhData: {
                    source,
                },
            });
            setExpanded((val) => !val);
        },
        isExpanded,
        ref: setAnimatedContainerRef,
    };
}
