import config from 'Cloud/config';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';

export interface Account {
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
}

const { PH_RegisterBackURL } = config.get('PHLinks') || {};

export const getSuccessPage = (location: typeof window.location) => {
    if (
        /^((\/subscriptions)|(\/promocodes)|(\/discount)|(\/promo\/newyear)|(\/promo\/quota)|(\/promo\/quota\/partner)|(\/promo\/quota-pro)|(\/public)|(\/promo\/present)|(\/promo\/mail))\/?$/.test(
            location.pathname
        )
    ) {
        return location.href;
    }

    if (/^\/integration/.test(location.pathname)) {
        return `${location.protocol}//${location.hostname}/integration/${location.search}`;
    }

    const path = IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : '/home';

    return `${location.protocol}//${location.hostname}${path}`;
};

export const reloadPage = () => {
    // @ts-ignore
    RADAR.beaconImmediate('auth_change').after(function () {
        const href = getSuccessPage(window.location);

        if (href === window.location.href) {
            window.location.reload();
        } else {
            window.location.href = href;
        }
    });
};

const registerLink = `https://r.mail.ru/cls22587493/r.mail.ru/clb1126011/e.mail.ru/signup?from=navi&lang=ru_RU&back=${PH_RegisterBackURL}`;

export const goToRegisterPage = () => {
    window.open(registerLink, '_blank');
};

export const setPortalMenuTheme = (theme: 'default' | 'dark' | 'grey' | 'system') => {
    try {
        window.__PH?.setColorTheme?.(theme);
    } catch (_) {
        /* Порталка может бросить эксепшен на вызове */
    }
};

export const getAccounts = (): Promise<Account[]> => {
    return new Promise((resolve) => {
        if (window.__PH) {
            window.__PH.loadAccountsList(({ accounts }) => resolve(accounts));
        } else {
            resolve([]);
        }
    });
};
