import classNames from 'clsx';
import React, { memo } from 'react';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';

import styles from './Logo.css';
import { getAlt, getLogoSrc } from './Logo.helpers';
import type { IProps } from './Logo.types';

export const LogoComponent = memo<IProps>(function LogoComponent({ isBizUser, logoSrc: src, bizLogo, dark, errorPage = false }) {
    const logoSrc = getLogoSrc({ isBizUser, logoSrc: src, bizLogo, dark });
    const alt = getAlt(isBizUser);
    if (isBizUser && IS_MY_TEAM) {
        return null;
    }

    return (
        <a
            href="/"
            title={alt}
            className={classNames(styles.root, {
                ['errorPageDisableCursorEvents']: errorPage,
            })}
            data-qa-button="logo"
            id="mobile__logo-component"
        >
            <img height={24} src={logoSrc} alt={alt} />
        </a>
    );
});
