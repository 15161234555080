import { Icon20MenuOutline } from '@vkontakte/icons';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { renderSassMobileMenu } from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileSubscriptions.helpers';
import styles from 'reactApp/sections/SassLanding/SassMobileSubscriptionsPage/SassMobileToolbar/SassMobileToolbar.css';
import { Button } from 'reactApp/ui/Button/Button';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { renderMobileUserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu.helpers';

export const SassMobileToolbar = () => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const dispatch = useDispatch();

    const onMenuClick = useCallback(() => {
        if (isAnonymous) {
            renderMobileUserLoginMenu();
        } else {
            renderSassMobileMenu();
        }
    }, [isAnonymous]);

    const onSubscriptionClick = useCallback(() => {
        renderSubscriptionsModal();
    }, []);

    useEffect(() => {
        dispatch(getSubscriptionsRequest());
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.menu} onClick={onMenuClick} data-qa-button={'menu'}>
                <Icon20MenuOutline width={16} height={16} fill={`var(--vkui--color_icon_primary)`} />
            </div>

            <Button className={styles.button} onClick={onSubscriptionClick}>
                Мои подписки
            </Button>
        </div>
    );
};

SassMobileToolbar.displayName = 'SassMobileToolbar';
