import { getCoordinatesId } from 'reactApp/modules/faces/faces.helpers';
import type { IFace, IFacesStore, IFaceWithCoordinates, IRequestState } from 'reactApp/modules/faces/faces.types';
import type { PublicFile } from 'reactApp/modules/public/public.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getFacesStore = (state: RootState) => state.faces as IFacesStore;

export const getFaces = (state: RootState) => getFacesStore(state).faces;
export const getFacesIdxs = createSelector(getFaces, (faces) => {
    return Object.values(faces)
        .sort((a, b) => b.numOfPhotos - a.numOfPhotos)
        .map((item) => item.id);
});
export const getCurrentFaceFolderId = (state: RootState) => getFacesStore(state).currentId;
export const getFacesState = (state: RootState) => getFacesStore(state).facesState;
export const getFacesListRequestState = (state: RootState) => getFacesStore(state).requestFacesListState;
export const getFiles = (state: RootState) => getFacesStore(state).files;
export const getFacesIdxOnFile = (state: RootState, fileId: string) => getFacesStore(state).facesIdxByFile[fileId] ?? [];
export const getFacesList = createSelector(getFaces, getFacesIdxs, (faces, idxs): IFace[] => idxs.map((id) => faces[id]));
export const hasFolderFileWithFace = createSelector(
    getFacesIdxs,
    (state, folderId) => folderId,
    getCurrentFaceFolderId,
    (idxs, folderId, currentFaceFolderId): boolean => currentFaceFolderId === folderId && idxs && idxs.length > 0
);
export const getSelectedFaceId = (state: RootState) => getFacesStore(state)?.selectedFaceId ?? '';
export const getSelectedFaceItem = createSelector(getSelectedFaceId, getFaces, (id, faces) => id && faces[id]);
export const getFilteredFacesList = createSelector(getFaces, getFacesIdxs, getSelectedFaceId, (faces, ids, selectedId): IFace[] => {
    const idsWithoutSelected = ids.filter((id) => id !== selectedId);
    const idsList = selectedId ? [selectedId, ...idsWithoutSelected] : ids;

    return idsList.map((id) => faces[id]);
});
export const getItemInSelectedFace = createSelector(
    getSelectedFaceId,
    getFiles,
    (state, itemId) => itemId,
    (selectedId, files, itemId) => selectedId && files?.[itemId]
);
export const getCursorByFaceId = (state: RootState, faceId: string) => getFacesStore(state).facesState[faceId]?.cursor ?? '';
export const getFilesWithFaceIdxs = (state: RootState, faceId: string): string[] => getFacesStore(state).facesState[faceId]?.filesIdx ?? [];
export const getFileWithFaceById = (state: RootState, fileId: string): PublicFile | undefined => getFiles(state)?.[fileId];
export const getFileWithFaceLoadingState = (state: RootState, faceId: string): IRequestState | undefined =>
    getFacesState(state)?.[faceId]?.requestState;
export const hasMoreToLoad = createSelector(
    (state: RootState, faceId?: string) => faceId || getSelectedFaceId(state),
    (state: RootState) => state,
    (faceId, state: RootState) => Boolean(getCursorByFaceId(state, faceId))
);
export const getCurrentPublicId = (state: RootState) => getFacesStore(state).currentId ?? '';
export const getCoordinates = (state: RootState) => getFacesStore(state).coordinates;
export const getFilesWithSelectedFace = createSelector(
    (state: RootState) => state,
    getSelectedFaceId,
    (state: RootState, faceId) => {
        if (!faceId) {
            return [] as PublicFile[];
        }

        return getFilesWithFaceIdxs(state, faceId)
            .map((id) => getFileWithFaceById(state, id))
            .filter((_) => !!_) as PublicFile[];
    }
);
export const getFacesListCursor = (state: RootState) => getFacesStore(state).requestFacesListCursor;
export const hasMoreFacesToLoad = (state: RootState) => Boolean(getFacesListCursor(state));

export const getFacesOnFile = createSelector(
    getFaces,
    getCoordinates,
    (state, fileId) => getFacesIdxOnFile(state, fileId),
    (state, fileId) => fileId,
    (faces, coordinates, idxs, fileId): IFaceWithCoordinates[] => {
        return idxs.map<IFaceWithCoordinates>((id) => {
            const coordId = getCoordinatesId(fileId, id);
            return {
                ...faces[id],
                id,
                coordinates: coordinates?.[coordId] ?? [],
            };
        });
    }
);

export const getFilteredFacesListOnFile = createSelector(
    getFaces,
    (state, fileId) => getFacesIdxOnFile(state, fileId),
    getSelectedFaceId,
    (faces, ids, selectedId): IFace[] => {
        const withoutSelected = ids.filter((id) => id !== selectedId);
        const list = selectedId ? [selectedId, ...withoutSelected] : ids;
        return list.map((id) => faces[id]);
    }
);

export const hasFacesOnFile = createSelector(
    (state, fileId) => getFacesIdxOnFile(state, fileId),
    (idx): boolean => Boolean(idx.length)
);

export const sortFacesByCount = createSelector(getFacesList, getSelectedFaceId, (faces, selectedFaceId) =>
    faces.sort((a, b) => {
        if (b.id === selectedFaceId) {
            return 1;
        } else if (a.id === selectedFaceId) {
            return -1;
        }

        return b.numOfPhotos - a.numOfPhotos;
    })
);
