import React, { type ComponentType, createContext, memo, useContext, useMemo } from 'react';

export interface Props {}

export interface IViewContext {
    isVKEmbedded: boolean;
}

export const ViewContext = createContext<IViewContext>({
    isVKEmbedded: false,
});

export const useViewContext = () => {
    return useContext(ViewContext);
};

export function withViewContext<T extends Props = Props>(WrappedComponent: ComponentType<T>) {
    const ComponentWithViewContext = memo((props: T) => {
        const contextValue: IViewContext = useMemo(
            () => ({
                isVKEmbedded: true,
            }),
            []
        );

        return (
            <ViewContext.Provider value={contextValue}>
                <WrappedComponent {...props} />
            </ViewContext.Provider>
        );
    });
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithViewContext.displayName = `withViewContext(${displayName})`;

    return ComponentWithViewContext;
}
