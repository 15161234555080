import { IS_IOS_MOBILE, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getUtmString } from 'reactApp/utils/urlHelper';

export const CLOUD_SOURCE = IS_MOBILE_BROWSER ? `cloud_touch_${IS_IOS_MOBILE ? 'ios' : 'and'}` : 'cloud';

export const PaymentUTM = {
    spacePaid: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'space_paid' }),
    spaceFree: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'space_free' }),
    spacePaidOverquota: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'overquota_space_paid',
    }),
    spaceFreeOverquota: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'overquota_space_free',
    }),
    spaceMobile: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'touch_union_quota_landing', utm_campaign: 'add_space' }),
    spaceAction: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'add_space_summer_action' }),
    attachesTooltip: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'attaches_tooltip_cleaner',
    }),
    spaceMenuCleaner: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'space_menu_cleaner' }),
    spaceMenuUnionQuota: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'space_menu_more' }),
    spaceMenuSubs: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_subscriptions', utm_campaign: 'space_menu_subs' }),
    spaceMenuBuy: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'space_buy' }),
    spaceProgressFamily: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'family_space_progress',
    }),
    mailUploadPopup: getUtmString({ utm_source: 'mail', utm_medium: 'web_union_quota_landing', utm_campaign: '2gb_upload' }),
    mailUploadCleaner: getUtmString({ utm_source: 'mail', utm_medium: 'web_union_quota_landing', utm_campaign: '2gb_upload_cleaner' }),
    welcomeOverquota: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'welcome_overquota_cleaner',
    }),
    welcomeOverquotaGroup: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'welcome_overquota_cleaner',
    }),
    welcomeOverquotaTariffs: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'welcome_overquota',
    }),
    welcomeAttachesRepeating: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'welcome_attaches_cleaner',
    }),
    familyBuy: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'family_space_dropdown_buy' }),
    familySubs: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_subscriptions', utm_campaign: 'family_space_dropdown_subs' }),
    familyMore: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'family_space_dropdown_more',
    }),
    familyClear: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'family_space_dropdown_clear',
    }),
    fastcheckout: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'fastcheckout_other_tariffs',
    }),
    fastcheckoutBuy: getUtmString({
        utm_source: 'mail',
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'fastcheckout_other_tariffs',
    }),
    trashbinInformer: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'trashbin_informer' }),
    fileHistory: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'file_history' }),
    welcomePromo: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'welcome_promo' }),
    featuresTariffsCard: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'subscriptions_tariff_no_ads',
    }),
    emptyAttaches: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'emptystate' }),
    quotaPopup: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'quota_popup_all_tariffs' }),
    autoDeletePromo: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'autodelete_more' }),
    advDisableLink: getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'web_union_quota_landing', utm_campaign: 'disable_ads_branded' }),
    advDisableLinkAnonym: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'disable_ads_branded_anonym',
    }),
    advDisableLineLinkAnonym: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'disable_ads_line_anonym',
    }),
    promoAutodeleteTooltip: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'autodelete_promo_tooltip',
    }),
    singleQuotaTooltip: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'single_quota_subs_tooltip',
    }),
    advRightBanner: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'public-ads-left',
    }),
    advRightBannerAnonym: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'public-ads-left',
    }),
    advErrorPage: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_subscriptions',
        utm_campaign: 'error_page',
    }),
    overquotaSplashNew: getUtmString({
        utm_source: 'cloud_web',
        utm_medium: 'overquota_started',
        utm_campaign: 'overquota_splash_new',
    }),
    overquotaSplashNewBlocked: getUtmString({
        utm_source: 'cloud_web',
        utm_medium: 'overquota_blocked',
        utm_campaign: 'overquota_splash_new',
    }),
    overquotaSplashNewCleared: getUtmString({
        utm_source: 'cloud_web',
        utm_medium: 'overquota_cleared',
        utm_campaign: 'overquota_splash_new',
    }),
    // tempexp_17125-start
    overquotaSlashEnterSpace: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'not_enough_space',
    }),
    overquotaSlashEnterCleaner: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'not_enough_space_cleaner',
    }),
    overquotaSlashViewerSpace: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'stop_files_open',
    }),
    overquotaSlashViewerCleaner: getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: 'stop_files_open_cleaner',
    }),
    // tempexp_17125-end
};
