import { hideSnackbarAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import type { SnackbarActionTypes, SnackbarItem } from 'reactApp/modules/snackbar/snackbar.types';
import { store } from 'reactApp/store';

class SnackbarController {
    public showSnackbar = (item: SnackbarItem): SnackbarActionTypes => store.dispatch(showSnackbarAction(item));

    public hideSnackbar = (id: string): SnackbarActionTypes => store.dispatch(hideSnackbarAction(id));
}

const snackbarController = new SnackbarController();

export { snackbarController };
