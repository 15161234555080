/* eslint-disable complexity */
import { Icon20Check } from '@vkontakte/icons';
import classNames from 'clsx';
import { isNil } from 'ramda';
import React, { type ReactNode, type VFC } from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { type DropdownTheme, DropdownFont } from 'reactApp/ui/DropdownList/DropdownList';
import { HotKeyTip } from 'reactApp/ui/HotKeyTip/HotKeyTip';
import { Icon20SortOutline, Icon20SortReverseOutline } from 'reactApp/ui/VKUIIcons';

import styles from './DropdownItemAction.css';

interface Props {
    mod?: string;
    asc?: boolean;
    withStatus?: boolean;
    icon?: ReactNode;
    active?: boolean;
    text: ReactNode;
    hotkey?: string;
    id?: string;
    theme?: DropdownTheme;
    isSort?: boolean;
    font?: DropdownFont;
}

const renderStatus = ({ active, asc }: { active?: boolean; asc?: boolean }) => {
    if (!active) {
        return <div className={styles.status} />;
    }

    if (isNil(asc)) {
        return <Icon20Check className={styles.checkIcon} />;
    }

    return (
        <div className={styles.status}>
            {asc ? (
                <Icon20SortOutline data-qa-id={asc ? 'sortAsc' : 'sortDesc'} />
            ) : (
                <Icon20SortReverseOutline data-qa-id={asc ? 'sortAsc' : 'sortDesc'} />
            )}
        </div>
    );
};

export const DropdownItemAction: VFC<Props> = ({ mod, asc, icon, text, active, withStatus, hotkey, id, isSort, font }) => {
    if (isSort) {
        return (
            <div
                className={classNames(styles.root, {
                    [styles[`root_${mod}`]]: Boolean(mod),
                    [styles[`root_${id}`]]: id,
                    [styles.root_mail_sans]: IS_BIZ_USER || font === DropdownFont.VKSansDisplay,
                })}
            >
                {!!icon && IS_BIZ_USER && isSort && (
                    <div className={classNames(styles.icon, styles.icon_blue)}>{active && <Icon20Check />}</div>
                )}
                {!!icon && !IS_BIZ_USER && <div className={styles.icon}>{icon}</div>}
                <div className={styles.text}>{text}</div>
                {(IS_BIZ_USER ? isSort : true) && withStatus && renderStatus({ active, asc })}
                {hotkey && (
                    <div className={styles.hotkeyWrapper}>
                        {' '}
                        <HotKeyTip text={hotkey} />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles[`root_${mod}`]]: Boolean(mod),
                [styles[`root_${id}`]]: id,
                [styles.root_mail_sans]: font === DropdownFont.VKSansDisplay,
            })}
        >
            {!!icon && <div className={styles.icon}>{icon}</div>}
            <div className={styles.text}>{text}</div>
            {withStatus && renderStatus({ active, asc })}
            {hotkey && (
                <div className={styles.hotkeyWrapper}>
                    {' '}
                    <HotKeyTip text={hotkey} />
                </div>
            )}
        </div>
    );
};
