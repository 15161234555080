import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';

/**
 * Allow to watch the status of the responsive mode
 */
export const useResponsiveEnabled = (): boolean => {
    const { windowWidth } = useWindowSize();

    const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
    const actionPanelWidth = isActionPanelOpened ? 380 : 0;

    return ENABLE_FULL_RESPONSIVE && windowWidth - actionPanelWidth <= BREAKPOINT_MD;
};
