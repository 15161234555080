import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

import styles from './styles.css';

const Component = videojs.getComponent('Component');

interface NavProps {
    player: VideoPlayer;
    togglePlay?: (e: MouseEvent) => void;
    onPlay?: (e: MouseEvent) => void;
    onPause?: (e: MouseEvent) => void;
    onReplay?: (e: MouseEvent) => void;
    className?: string;
    withSeeking?: boolean;
    seekingBackward?: (e: MouseEvent) => void;
    seekingForward?: (e: MouseEvent) => void;
}

// Навигация в центре экрана
export function addCentralNavigation({
    player,
    withSeeking = false,
    className,
    togglePlay,
    onPlay,
    onPause,
    onReplay,
    seekingBackward,
    seekingForward,
}: NavProps) {
    const wrapperEl = document.createElement('div');
    wrapperEl.className = `${styles.centredNavigation} ${className}`;

    if (withSeeking && seekingBackward) {
        const leftButton = document.createElement('div');
        leftButton.className = `leftButton ${styles.leftButton}`;
        leftButton.onclick = seekingBackward;
        wrapperEl.appendChild(leftButton);
    }

    if (withSeeking && seekingForward) {
        const rightButton = document.createElement('div');
        rightButton.className = `rightButton ${styles.rightButton}`;
        rightButton.onclick = seekingForward;
        wrapperEl.appendChild(rightButton);
    }

    const playButton = document.createElement('div');
    playButton.className = `centredButton centredButtonPlay ${styles.centredButton} ${styles.centredButtonPlay}`;
    playButton.onclick = onPlay || togglePlay || null;
    playButton.ontouchstart = (e) => e.stopImmediatePropagation();
    wrapperEl.appendChild(playButton);

    const pauseButton = document.createElement('div');
    pauseButton.className = `centredButton centredButtonPause ${styles.centredButton} ${styles.centredButtonPause}`;
    pauseButton.onclick = onPause || togglePlay || null;
    pauseButton.ontouchstart = (e) => e.stopImmediatePropagation();
    wrapperEl.appendChild(pauseButton);

    const replayButton = document.createElement('div');
    replayButton.className = `centredButton centredButtonReplay ${styles.centredButton} ${styles.centredButtonReplay}`;
    replayButton.onclick = onReplay || togglePlay || null;
    replayButton.ontouchstart = (e) => e.stopImmediatePropagation();
    wrapperEl.appendChild(replayButton);

    const wrapper = new Component(player, { el: wrapperEl });

    player.addChild(wrapper);

    const centredButtons = [playButton, pauseButton, replayButton];

    playButton.classList.add(styles.show);
    return {
        showPlayButton: () => {
            centredButtons.forEach((item) => item.classList.remove(styles.show));
            playButton.classList.add(styles.show);
        },
        showPauseButton: () => {
            centredButtons.forEach((item) => item.classList.remove(styles.show));
            pauseButton.classList.add(styles.show);
        },
        showReplayButton: () => {
            centredButtons.forEach((item) => item.classList.remove(styles.show));
            replayButton.classList.add(styles.show);
        },
    };
}
