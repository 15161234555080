import { ThemeModel } from '@mail/theme-tool';
import { logger } from 'lib/logger';
import type { ThemeConfig } from 'themesConfig';

import {
    APPLY_THEME_TIMEOUT,
    AVAILABLE_BG_IMAGES_THEMES,
    AVAILABLE_COLOR_THEMES,
    DARK_DEFAULT_MODE_THEMES,
    DARK_MODE_THEMES,
    LIGHT_DEFAULT_MODE_THEMES,
    SUPPORT_WIDGET_THEME_IDS,
    USE_CUSTOM_THEME_PREVIEW_NAME,
    WHITE_PORTAL_THEME,
} from './default-values';

const themesConfigPromise = import('themesConfig');

const themeClassPrefix = 'theme-';

const themeOutsourceClassName = 'theme-outsource';

export type ThemeModelsType = Record<string, ThemeModel>;

export class ThemeManager {
    private project = 'cloud';
    private themeConfigs: Record<string, ThemeConfig> | null = null;
    private themeOutsourcePath: string;
    private availableThemeModelsPromise: Promise<ThemeModelsType>;
    public lastAppliedTheme: string | null = null;

    private async initAvailableThemeModels() {
        const themeModels = await Promise.all(
            [...AVAILABLE_COLOR_THEMES, ...AVAILABLE_BG_IMAGES_THEMES].map((theme) => this.createThemeModel(theme))
        );
        return themeModels.reduce((accumulator, themeModel) => {
            if (themeModel) {
                accumulator[themeModel.id] = themeModel;
            }
            return accumulator;
        }, {});
    }

    private async createThemeModel(themeId: string) {
        try {
            const themeConfig = await this.getThemeConfig(themeId);
            return new ThemeModel(themeId, this.project, themeConfig, this.themeOutsourcePath);
        } catch (error) {
            logger.error('theme_init_error', themeId, this.project, error);
        }
    }

    private async getAllThemeConfigs() {
        this.themeConfigs ??= (await themesConfigPromise).themes;
        return this.themeConfigs;
    }

    private async getThemeConfig(themeId: string) {
        const themeConfigs = await this.getAllThemeConfigs();
        return themeConfigs[themeId];
    }

    public getThemeIdFromBody() {
        const itThemeOutsource = document.body.classList.contains(themeOutsourceClassName);
        if (!itThemeOutsource) {
            return;
        }
        const themeClassName = Array.from(document.body.classList).find((className) => className.startsWith(themeClassPrefix));
        if (themeClassName) {
            return themeClassName.replace(themeClassPrefix, '');
        }
        return;
    }

    constructor(themeOutsourcePath: string) {
        this.themeOutsourcePath = themeOutsourcePath;
        try {
            ThemeModel.setupSettings({
                DARK_MODE_THEMES,
                APPLY_THEME_TIMEOUT,
                DARK_DEFAULT_MODE_THEMES,
                LIGHT_DEFAULT_MODE_THEMES,
                SUPPORT_WIDGET_THEME_IDS,
                WHITE_PORTAL_THEME,
                USE_CUSTOM_THEME_PREVIEW_NAME,
            });
        } catch (error) {
            logger.error('theme_setup_error', error);
        }
        this.availableThemeModelsPromise ??= this.initAvailableThemeModels();
    }

    public async applyTheme(themeId: string) {
        if (themeId !== this.lastAppliedTheme) {
            const themeModels = await this.availableThemeModelsPromise;
            const themeModel = themeModels[themeId];
            if (themeModel) {
                // Удаляем старые классы темы, они начинаются с
                Array.from(document.body.classList).forEach((className) => {
                    if (className.startsWith(themeClassPrefix)) {
                        document.body.classList.remove(className);
                    }
                });

                // Добавляем класс активной темы
                document.body.classList.add(`${themeClassPrefix}${themeId}`);
                // Указываем что тема установлена
                document.body.classList.add(themeOutsourceClassName);
                themeModel.apply();
                this.lastAppliedTheme = themeId;
            } else {
                logger.warn('theme_apply_no_theme_with_id', themeId, this.project);
            }
        }
    }

    public async applyBodyTheme() {
        const themeId = this.getThemeIdFromBody();
        if (themeId) {
            return await this.applyTheme(themeId);
        }
    }

    public async getAvailableThemes() {
        return await this.availableThemeModelsPromise;
    }
}
