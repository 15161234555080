import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { disableDocumentsRecognition } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getDocumentsRecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';

import { sendDocumentsGa } from '../PersonalDocuments.helpers';
import styles from './RecognitionPopup.css';
import { getDescription, getTitle } from './RecognitionPopup.helpers';
import type { IProps } from './RecognitionPopup.types';

export const RecognitionPopup = memo(function RecognitionPopup({ onClose }: IProps) {
    const dispatch = useDispatch();
    const documentsRecognitionStatus = useSelector(getDocumentsRecognitionStatus);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

    useEffect(() => sendDocumentsGa('popup-show'));

    const onPopupClose = useCallback(() => onClose(), [onClose]);
    const onAction = useCallback(() => setShowConfirmationDialog(true), []);
    const renderHeader = useCallback(() => <>{getTitle(documentsRecognitionStatus)}</>, [documentsRecognitionStatus]);
    const renderContent = useCallback(() => <>{getDescription(documentsRecognitionStatus)}</>, [documentsRecognitionStatus]);
    const buttons = useMemo(() => [EButtonType.action], []);
    const confirmPopupButtons = useMemo(() => [EButtonType.accept, EButtonType.action], []);
    const renderConfirmHeader = useCallback(() => <>Вы уверены?</>, []);
    const renderConfirmContent = useCallback(
        () => (
            <>
                В случае отключения этой функции все автоматически распознанные документы будут удалены из раздела (но останутся в Облаке),
                кроме добавленных вручную.
            </>
        ),
        []
    );

    const onCancel = useCallback(() => {
        dispatch(disableDocumentsRecognition());
        sendDocumentsGa('recognition', 'disable');
        onPopupClose();
    }, [dispatch, onPopupClose]);

    return (
        <>
            <BaseConfirmDialog
                className={styles.root}
                dataQAId="recognition-popup"
                renderHeader={renderHeader}
                actionButtonText="Отключить"
                buttons={documentsRecognitionStatus === ERecognitionStatus.PROCESSING ? [] : buttons}
                onAction={onAction}
                onClose={onPopupClose}
                renderContent={renderContent}
            />
            {showConfirmationDialog && (
                <BaseConfirmDialog
                    className={styles.root}
                    dataQAId="confirm-popup"
                    renderHeader={renderConfirmHeader}
                    successButtonText="Отменить"
                    actionButtonText="Отключить"
                    buttons={confirmPopupButtons}
                    onAction={onCancel}
                    onSuccess={onPopupClose}
                    onClose={onPopupClose}
                    renderContent={renderConfirmContent}
                />
            )}
        </>
    );
});
