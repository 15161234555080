import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsWindowWidthSmall } from 'reactApp/hooks/useNormalizedOvidius/useIsWindowWidthSmall';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { IntegrationFooter } from 'reactApp/ui/IntegrationFooter/IntegrationFooter';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';

import styles from './IntegrationHomePage.css';

export const IntegrationHomePage: React.FC = () => {
    const dispatch = useDispatch();
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, EStorageType.integration));
    const [updateLicenseCheck, setUpdateLicenseCheck] = React.useState<boolean>(false);
    const isLoaded = folder?.isLoaded;

    const { isTutoria } = useSelector(getIntegrationClient);
    const isWindowWidthSmall = useIsWindowWidthSmall();

    // десктопный DataList проверяет апдейты лицензионного соглашения сам, но мобильный так не умеет
    // поэтому проверим тут
    React.useEffect(() => {
        if (isLoaded && !updateLicenseCheck) {
            setUpdateLicenseCheck(true);
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isLoaded, updateLicenseCheck, dispatch]);

    return (
        <div className={styles.root}>
            {/* На узких экранах добавляем кнопку для переключения режимов, чтобы можно было заходить в папки*/}
            {isWindowWidthSmall && isTutoria && <Toolbar hideLogo={true} showOptions={false} showSearch={false} />}
            <div className={styles.breadcrumbsWrapper}>
                <BreadcrumbsContainer />
            </div>
            <Datalist />
            <IntegrationFooter />
        </div>
    );
};
