import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { abPauseSubscription } from 'reactApp/appHelpers/featuresHelpers/features/pauseSubscription';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { getPausedSubscriptionStorageKey, isPausedSubscription } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import type { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';

import { SubscriptionItem } from '../SubscriptionListItem/SubscriptionListItem';
import { composeContent } from './ModernSubscriptionItem.helpers';

const sendGa = createGaSender('subs-sidebar');

type Props = {
    subscription: ISubscription;
    onClick: () => void;
    isRebranding?: boolean;
    isRebrandingQuotaLanding?: boolean;
};

export const ModernSubscriptionItemComponent = memo<Props>(({ subscription, onClick, isRebranding = false, isRebrandingQuotaLanding }) => {
    const { productId, type } = subscription;
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId));
    const pausedSubscription: boolean =
        useSelector((state: RootState) => UserStorageSelectors.get(state, getPausedSubscriptionStorageKey(subscription.id))) &&
        isPausedSubscription(abPauseSubscription, subscription.renewAt);
    const { title, description, icon, partner, showWaiting } = composeContent({ subscription, product, pausedSubscription });

    const handleOnClick = () => {
        // нельзя слать полный id продукта, потому что тогда не укладываемся в лимит на длину метрики
        sendGa('click', 'subs-item', `${type}${productId ? `_${productId.slice(0, 16)}` : ''}`);

        onClick();
    };

    return (
        <SubscriptionItem
            type={partner}
            showWaiting={showWaiting}
            icon={icon}
            title={title}
            description={description}
            onClick={handleOnClick}
            isRebranding={isRebranding}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        />
    );
});

ModernSubscriptionItemComponent.displayName = 'ModernSubscriptionItemComponent';
