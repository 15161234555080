import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import { ReactComponent as CalendarIcon } from 'img/icons/subscriptions/calendar-icon.svg';
import { ReactComponent as CardIcon } from 'img/icons/subscriptions/card-icon.svg';
import { ReactComponent as InfoIcon } from 'img/icons/subscriptions/info-icon.svg';
import React, { type VFC } from 'react';
import { useSelector } from 'react-redux';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { abPauseSubscription } from 'reactApp/appHelpers/featuresHelpers/features/pauseSubscription';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { cheapestProductIDs } from 'reactApp/modules/products/products.const';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EPromoSubscriptionType } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { PARTNER_TITLE } from 'reactApp/modules/subscriptions/subscriptions.data';
import { getPausedSubscriptionStorageKey, isPausedSubscription } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import { type ISubscription, EStatus, ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';

import { CommonInfo } from '../CommonInfo/CommonInfo';
import { SubscriptionInfoItem } from '../SubscriptionInfoItem/SubscriptionInfoItem';
import { useHandlers } from './hooks/useHandlers';
import styles from './ModernSubscription.css';
import { getCardNumber, getExpiresTitle, getNextPayment, getTariffConditionsText } from './ModernSubscription.helpers';

type Props = IPropsWithPopup<{ subscription: ISubscription; isRebrandingQuotaLanding?: boolean; isRebranding?: boolean }>;

export const ModernSubscription: VFC<Props> = ({ subscription, onClose, isRebrandingQuotaLanding, isRebranding = false }) => {
    const status = subscription.status as EStatus;
    const { expires, type, renewAt, productId, autorenewal, partner, space, accountLink } = subscription;
    const { card } = useSelector(CardSelectors.getCard);
    const cardNumber = getCardNumber(card);
    const paymentMethod = card?.isSbp ? 'Система быстрых платежей' : cardNumber || '';
    const pausedSubscription: boolean =
        useSelector((state: RootState) => UserStorageSelectors.get(state, getPausedSubscriptionStorageKey(subscription.id))) &&
        isPausedSubscription(abPauseSubscription, subscription.renewAt);
    const nextPayment = getNextPayment(renewAt, expires, pausedSubscription);
    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId)) as Product;
    const conditions = getTariffConditionsText(subscription, product);

    const isMobileOsType = [ESubscriptionsTypes.ios, ESubscriptionsTypes.android].includes(type);
    const showCard = !isMobileOsType;
    const showMainInfo = isMobileOsType || type === ESubscriptionsTypes.web;
    const showNextPayment = typeof autorenewal === 'boolean' && showCard;
    const showExpires = expires && (partner || status === EStatus.SUSPENDED);

    const { handleUpdateCard, handleCancelNextPayment, handleGoToSubscription, handleRenewNextPayment, handlePartnerClick } = useHandlers(
        subscription,
        onClose
    );

    const subscriptionType = subscription.type as ESubscriptionsTypes | EPromoSubscriptionType;

    // tempexp_16598-start
    const isCheapest = productId && Object.values(cheapestProductIDs).includes(productId);
    const tariffSpace = isCheapest ? bytesToNDigits(BASEQUOTA, 3) : space;
    const customTitle = isCheapest ? `${bytesToNDigits(BASEQUOTA, 3).value} без рекламы` : undefined;
    // tempexp_16598-end

    return (
        <>
            <CommonInfo
                space={tariffSpace}
                status={status as EStatus}
                customTitle={customTitle}
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            />
            {showExpires && (
                <span className={classNames(styles.expires, { [styles.expires_warning]: status === 'SUSPENDED' })}>
                    {getExpiresTitle(expires, status)}
                </span>
            )}
            {subscriptionType === EPromoSubscriptionType.quota && (
                <span
                    className={classNames(styles.tariffManagement, {
                        [styles.tariffManagement_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                    })}
                >
                    Это подарочная подписка — она не может быть изменена или отменена
                </span>
            )}
            {partner &&
                (accountLink ? (
                    <div
                        className={classNames(styles.tariffManagement, {
                            [styles.tariffManagement_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                        })}
                    >
                        Управление тарифом доступно в личном кабинете&nbsp;
                        <a className={styles.link} onClick={handlePartnerClick} target="_blank" href={accountLink} rel="noreferrer">
                            {PARTNER_TITLE[partner]}
                        </a>
                    </div>
                ) : (
                    <span className={styles.tariffManagement}>Данная подписка не может быть изменена или отменена</span>
                ))}
            {showMainInfo && (
                <div className={styles.mainInfo}>
                    <SubscriptionInfoItem
                        title="Условия подписки"
                        subtitle={conditions}
                        buttonText="Добавить место"
                        Icon={InfoIcon}
                        onClick={handleGoToSubscription}
                        isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                    />
                    {showNextPayment && (
                        <SubscriptionInfoItem
                            title={autorenewal ? 'Следующее списание' : 'Дата окончания'}
                            subtitle={nextPayment}
                            buttonText={autorenewal ? 'Отключить' : 'Восстановить'}
                            Icon={CalendarIcon}
                            onClick={
                                autorenewal ? () => handleCancelNextPayment(isRebrandingQuotaLanding, isRebranding) : handleRenewNextPayment
                            }
                            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        />
                    )}
                    {showCard && (
                        <SubscriptionInfoItem
                            title="Способ оплаты"
                            subtitle={cardNumber !== null ? paymentMethod : 'Карта не привязана'}
                            buttonText={cardNumber !== null ? `Изменить${card?.isSbp ? '' : ' карту'}` : 'Привязать карту'}
                            Icon={CardIcon}
                            onClick={handleUpdateCard}
                            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                        />
                    )}
                </div>
            )}
        </>
    );
};
