import AmrApi from 'Cloud/Application/Editor/MyOffice/amrApi';
import { downloadDocumentMail } from 'Cloud/Application/Editor/MyOffice/helpers/downloadDocument';
import { getModeByRateLimit, rateLimitUpdate } from 'Cloud/Application/Editor/MyOffice/helpers/rateLimit';
import { sendMyofficeGa } from 'Cloud/Application/Editor/MyOffice/helpers/sendMyofficeGa';
import { MY_OFFICE_IFRAME, RATELIMIT_UPDATE_INTERVAL, SAVE_INTERVAL } from 'Cloud/Application/Editor/MyOffice/myOffice.constants';
import { MYOFFICE_VARIANT_NAMES, MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { IS_STOCK } from 'reactApp/appHelpers/configHelpers';
import { setMyOfficeType } from 'reactApp/modules/editor/editor.module';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { saveDocument } from 'reactApp/modules/uploading/uploading.module';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store as reduxStore } from 'reactApp/store';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { downloadDocument, downloadDocumentEmbedded } from 'reactApp/utils/downloadDocumentHelpers';
import { sendKaktamLog } from 'reactApp/utils/ga';
import { generateUuidV4 } from 'reactApp/utils/helpers';
import { isEditingStorage } from 'reactApp/utils/isEditingFile';
import { sendEditRadar, startSendSessionRadars } from 'reactApp/utils/sendEditRadar';

const handleSkipBlurDocument = async (event: MouseEvent, editorApi?: AmrApi) => {
    if (event.target && !document?.getElementById('editor-container')?.contains(event.target as Node)) {
        await editorApi?.blur();
    }
};

const onError = (editorApi?: AmrApi, shouldRedirect = true) => {
    document.removeEventListener('click', (event) => handleSkipBlurDocument(event, editorApi));

    if (shouldRedirect) {
        reduxStore.dispatch(routeStatusPage({ status: EStatus.SOMETHING_WRONG }));
    }
};

let editorApi: AmrApi;
let fileInfo: Awaited<ReturnType<AmrApi['openDocument']>>;

export const initAndLoadAmr = ({
    iframe,
    item,
    listeners,
    documentMode,
    isMyOfficeForMail,
    isEditAttaches,
    isEmbedded = false,
    sharingAttach = false,
    isFallback = false,
    metrics,
}): undefined | (() => Promise<void>) => {
    if (!item) {
        return;
    }

    reduxStore.dispatch(setMyOfficeType(MYOFFICE_VARIANTS.amr));
    const { name, url, editors } = item;
    const { onLoad, onReady } = listeners;

    let documentHasChanges = false;

    let autoSaveCount = 0;
    let sessionId = '';

    const onLoadIframe = async () => {
        AmrApi.setExperimentalFeaturesEnabled(true);

        editorApi = new AmrApi(iframe, MY_OFFICE_IFRAME);

        document.addEventListener('click', (event) => handleSkipBlurDocument(event, editorApi));

        const myOfficeUrl = editors?.myoffice?.url;

        let officeDocument: { content?: any; error?: unknown };
        switch (true) {
            case Boolean(isMyOfficeForMail && myOfficeUrl):
                officeDocument = await downloadDocumentMail(myOfficeUrl);
                break;
            case isEmbedded:
            case sharingAttach:
                officeDocument = await downloadDocumentEmbedded(url.get);
                break;
            default:
                officeDocument = await downloadDocument(url.get, IS_STOCK, {
                    onError: () => {
                        sendMyofficeGa('download-error');
                    },
                    onSuccess: () => {
                        sendMyofficeGa('download-success');
                    },
                });
        }
        const { content, error } = officeDocument;

        const state = reduxStore.getState();
        const storage = getCurrentStorage(state);
        const { isInlineIntegration } = getStorage(storage);
        const isEditingFileUrl = isEditingStorage(storage);

        if (!content) {
            logger.error('Не смогли скачать файл: ', error);

            xray.send('amr_down_file_err', {
                rlog: 'cloud_amr_file_err',
                rlog_message: {
                    type: 'download',
                    url,
                    error,
                },
            });

            onError(editorApi, !isInlineIntegration);
            return;
        }

        const mode = await getModeByRateLimit(documentMode);

        const myOfficeParams = {
            lang: 'ru-RU',
            experimentalFeaturesEnabled: true,
            filename: name,
            mode,
            content,
            usePerfHelper: false,
        };

        editorApi.onError(({ errorInfo } = { errorInfo: 'unk' }) => {
            sendMyofficeGa('amr-error');
            logger.error('AMR error: ', errorInfo);

            const errorType = 'on-error';
            xray.send('amr_on_err', {
                rlog: 'cloud_amr_file_err',
                rlog_message: {
                    type: errorType,
                    error: errorInfo,
                },
            });

            sendMyOfficeMetric(MyOfficeBusinessMetric.error, {
                t: [documentMode, MYOFFICE_VARIANT_NAMES.sek, metrics?.ext],
                i: { [errorType]: 1, [metrics?.size || '']: 1 },
            });
        });

        editorApi.onPageReloadRequested(() => {
            sendMyofficeGa('amr-reload');
            window.location.reload();
        });

        editorApi.onChange(({ hasChanges }) => {
            if (hasChanges) {
                sendMyofficeGa('amr-change');

                sendEditRadar({
                    item,
                    storage,
                    i: 'edit',
                    version: MYOFFICE_VARIANTS.amr,
                    isAttach: isEditAttaches,
                });
            }

            documentHasChanges = hasChanges;
        });

        window.myofficeApi = editorApi;

        sendMyOfficeMetric(MyOfficeBusinessMetric.request, {
            t: [documentMode, MYOFFICE_VARIANT_NAMES.sek, metrics?.ext],
            i: { [metrics?.size]: 1 },
        });

        try {
            fileInfo = await editorApi.openDocument(myOfficeParams);
        } catch (error) {
            logger.error('Ошибка при инициализации редактора', error);

            const suffix = isEditAttaches ? '-att-editor' : '';
            sendMyofficeGa(`init-error${suffix}`);
            sendKaktamLog({ error }, `myoffice-init-error${suffix}`);

            xray.send('amr_file_init_err', {
                rlog: 'cloud_amr_file_err',
                rlog_message: {
                    type: 'init',
                    mode,
                    error,
                },
            });

            sendMyOfficeMetric(MyOfficeBusinessMetric.error, {
                t: [documentMode, MYOFFICE_VARIANT_NAMES.sek, metrics?.ext],
                i: { init: 1, [metrics?.size || '']: 1 },
            });

            onError(editorApi, !isInlineIntegration);
            return;
        }

        logger.info('Документ готов к редактированию');
        onReady?.(MYOFFICE_VARIANTS.amr, item, isFallback);

        const suffix = isEditAttaches ? '-att-editor' : '';
        sendMyofficeGa(`init-success${suffix}`);

        if (isEditingFileUrl) {
            sendEditRadar({
                item,
                storage,
                i: 'open',
                version: MYOFFICE_VARIANTS.amr,
                isAttach: isEditAttaches,
            });

            sessionId = generateUuidV4();
        }

        onLoad?.();

        const saveDocumentProcess = async () => {
            if (!documentHasChanges) {
                return;
            }

            try {
                const fileContent = await editorApi.saveDocument(fileInfo.format);
                const { error } = await new Promise((resolve) => {
                    reduxStore.dispatch(saveDocument({ home: item.home, fileContent, onResult: resolve }));
                });

                if (error) {
                    throw error;
                } else {
                    sendEditRadar({
                        item,
                        storage,
                        i: 'autosave',
                        version: MYOFFICE_VARIANTS.amr,
                        isAttach: isEditAttaches,
                        dwh: {
                            count_autosafe: autoSaveCount,
                        },
                    });

                    autoSaveCount++;
                }
            } catch (error) {
                logger.error('Не смогли сохранить документ');
                sendMyOfficeMetric(MyOfficeBusinessMetric.error, {
                    t: [documentMode, MYOFFICE_VARIANT_NAMES.sek, metrics?.ext],
                    i: { 'save-doc': 1, [metrics?.size || '']: 1 },
                });
            }
        };

        setInterval(saveDocumentProcess, SAVE_INTERVAL);

        if (isEditingFileUrl) {
            startSendSessionRadars({
                item,
                sessionId,
                version: MYOFFICE_VARIANTS.amr,
                storage,
                isAttach: isEditAttaches,
            });
        }

        // Раз в 2 минуты ходим в /ratelim
        // @see https://confluence.mail.ru/pages/viewpage.action?pageId=701933833
        setInterval(() => rateLimitUpdate(mode), RATELIMIT_UPDATE_INTERVAL);
    };

    iframe.addEventListener?.('load', onLoadIframe);

    return onLoadIframe;
};
