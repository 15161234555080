import type { MiddlewareState } from '@floating-ui/react';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

const ARROW_NON_CENTER_HORIZONTAL = [
    ETooltipPlacement.bottomStart,
    ETooltipPlacement.bottomEnd,
    ETooltipPlacement.topStart,
    ETooltipPlacement.topEnd,
];
const ARROW_NON_CENTER_VERTICAL = [
    ETooltipPlacement.leftStart,
    ETooltipPlacement.leftEnd,
    ETooltipPlacement.rightStart,
    ETooltipPlacement.rightEnd,
];

const TOP_PLACEMENTS = [ETooltipPlacement.top, ETooltipPlacement.topStart, ETooltipPlacement.topEnd];
const BOTTOM_PLACEMENTS = [ETooltipPlacement.bottom, ETooltipPlacement.bottomStart, ETooltipPlacement.bottomEnd];
const LEFT_PLACEMENTS = [ETooltipPlacement.left, ETooltipPlacement.leftStart, ETooltipPlacement.leftEnd];
const RIGHT_PLACEMENTS = [ETooltipPlacement.right, ETooltipPlacement.rightStart, ETooltipPlacement.rightEnd];

const TOOTIP_OFFSET_MAIN = 18; // Размер стрелки + 8px (by design)

/**
 * Callback для offset middlware.
 * see: https://floating-ui.com/docs/offset
 */
export const calculateOffset = (params: MiddlewareState) => {
    let alignmentAxis = 0;

    if (ARROW_NON_CENTER_HORIZONTAL.includes(params.placement as ETooltipPlacement)) {
        alignmentAxis = params.rects.reference.width / 2;
    }

    if (ARROW_NON_CENTER_VERTICAL.includes(params.placement as ETooltipPlacement)) {
        alignmentAxis = params.rects.reference.height / 2;
    }

    return {
        mainAxis: TOOTIP_OFFSET_MAIN,
        alignmentAxis,
    };
};

// Если тултип не помещается в области экрана - сначала пробуем его по основной оси и только потом - противоположную.
export const getFlipFallbackPlacements = (placement: ETooltipPlacement) => {
    if (TOP_PLACEMENTS.includes(placement)) {
        return [...TOP_PLACEMENTS, ...BOTTOM_PLACEMENTS];
    }

    if (BOTTOM_PLACEMENTS.includes(placement)) {
        return [...BOTTOM_PLACEMENTS, ...TOP_PLACEMENTS];
    }

    if (LEFT_PLACEMENTS.includes(placement)) {
        return [...LEFT_PLACEMENTS, ...RIGHT_PLACEMENTS];
    }

    if (RIGHT_PLACEMENTS.includes(placement)) {
        return [...RIGHT_PLACEMENTS, ...LEFT_PLACEMENTS];
    }
};
