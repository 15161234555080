import { type unstable_PopperProps as PopperProps, unstable_Popper as Popper } from '@vkontakte/vkui';
import React, { useRef } from 'react';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';

interface IProps extends PopperProps {
    targetRef: React.RefObject<HTMLElement>;
    onClickAway: () => void;
}

export const Popup: React.FC<IProps> = ({ targetRef, onClickAway, children, ...rest }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    useClickOutsideTarget({ ref: contentRef, rootRef: targetRef, onClickAway });

    return (
        <Popper targetRef={targetRef} forcePortal={false} {...rest}>
            <div ref={contentRef}>{children}</div>
        </Popper>
    );
};

Popup.displayName = 'Popup';
