import { Icon20ChevronRight } from '@vkontakte/icons';
import { Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import tooltipImg from 'img/stories-widget-new-location-tooltip.png';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import styles from './LastFilesWidget.css';

export const LAST_FILES_WIDGET_LOCAL_STORAGE_KEY = 'lastfilescollapsed';
export interface LastFilesWidgetProps {
    lastFiles: any[];
    onExpand?: (expanded: boolean) => void;
}

export const LastFilesWidget = memo(function LastFilesWidget({ lastFiles, onExpand = noop }: LastFilesWidgetProps) {
    const dispatch = useDispatch();

    const localstoreIsClosed = useSelector((state: RootState) =>
        Boolean(UserStorageSelectors.get(state, LAST_FILES_WIDGET_LOCAL_STORAGE_KEY))
    );
    const [isClosed, setClosed] = useState<boolean>(localstoreIsClosed);

    const titleRef = useRef<HTMLDivElement | null>(null);

    const handleExpand = useCallback(() => {
        const newValue = !isClosed;
        dispatch(UserStorageActions.set(LAST_FILES_WIDGET_LOCAL_STORAGE_KEY, newValue));
        setClosed(newValue);
    }, [isClosed, dispatch, setClosed]);

    useEffect(() => {
        onExpand(!localstoreIsClosed);
    }, [localstoreIsClosed, onExpand]);

    const handleFeedSeeAll = useCallback(() => {
        opener('/feed', true);
    }, []);

    const handleTooltipButtonClick = useCallback(() => {
        opener('/gallery', true);
    }, []);

    const storiesInGalleryPromo = useSelector(PromoSelectors.getPromo(EPromoType.storiesInGalleryPromo));

    useEffect(() => {
        storiesInGalleryPromo?.onShow();
    }, [storiesInGalleryPromo]);

    const handleCloseTip = useCallback(() => {
        storiesInGalleryPromo?.onClose();
    }, [storiesInGalleryPromo]);

    const tooltipText = useMemo(() => {
        return (
            <span className={styles.tooltipText}>
                Подборки воспоминаний стали
                <br />
                ближе к фотографиям
            </span>
        );
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.collapser} onClick={handleExpand}>
                    <Icon20ChevronRight
                        className={classNames(styles.expandIcon, {
                            [styles.expandIconExpanded]: !localstoreIsClosed,
                        })}
                    />
                    <div ref={titleRef}>
                        <Title className={styles.title} level="2">
                            Последние файлы
                        </Title>
                    </div>
                </div>
                {storiesInGalleryPromo && (
                    <FloatingTooltip
                        target={titleRef}
                        title="Истории переехали в Галерею"
                        text={tooltipText}
                        buttonText="К историям"
                        size={ETooltipSize.fit}
                        placement={ETooltipPlacement.rightStart}
                        img={tooltipImg}
                        onClick={handleTooltipButtonClick}
                        onClose={handleCloseTip}
                        usePortal
                    />
                )}
                <div className={styles.headerContent}>
                    {!localstoreIsClosed && (
                        <div className={styles.leftControls}>
                            <div className={styles.feedAll} onClick={handleFeedSeeAll}>
                                Посмотреть все
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={classNames(styles.body, { [styles.bodyClosed]: localstoreIsClosed })}>
                <div className={styles.lastFiles}>
                    <DataList
                        goTo={noop}
                        storage={EStorageType.feed}
                        viewMode={EViewMode.thumbs}
                        gaSuffix="wjfeed"
                        disableCenteredLoader
                        limitItemsNumber={10}
                        disableLoadOnScroll
                        disableTips
                        itemsList={lastFiles?.map((item) => item.id)}
                        storiesWidget
                        selectBoxEnabled={false}
                    />
                </div>
            </div>
        </div>
    );
});
