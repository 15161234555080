import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, memo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import type { ButtonProps } from 'semantic-ui-react/src/elements/Button/Button';

import styles from './ButtonLink.css';

const ButtonLinkComponent = ({
    children,
    inline,
    underline,
    hoverUnderline,
    className = ' ',
    fontInherit,
    ...rest
}: Props): ReactElement => (
    <Button
        as="a"
        basic
        {...rest}
        className={classNames({
            [styles.root]: true,
            [styles.root_inline]: inline,
            [styles.root_underline]: underline,
            [styles.hoverUnderline]: hoverUnderline,
            [styles.fontInherit]: fontInherit,
            [className]: !!className,
        })}
    >
        <span className={styles.text}>{children}</span>
    </Button>
);

interface Props extends ButtonProps {
    children: ReactNode;
    inline?: boolean;
    underline?: boolean;
    hoverUnderline?: boolean;
    className?: string;
    fontInherit?: boolean;
}

ButtonLinkComponent.defaultProps = {
    inline: true,
    primary: false,
    underline: false,
    hoverUnderline: true,
    fontInherit: false,
};

export const ButtonLink = memo(ButtonLinkComponent);
