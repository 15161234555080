import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isCheapestProduct } from 'reactApp/modules/products/products.helpers';

/** CLOUDWEB-16598 Создание дешевого тарифа с преимуществами Почты*/
const cheapestTariffValue: 'a' | 'b' | 'c' = getFeature('cheapest-tariff-web');

// tempexp_16598-start
registerExperiment(
    'cheapest-tariff',
    cheapestTariffValue,
    [
        AnalyticEventNames.TARIFF_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
    ],
    {
        sendXrayArgs: {
            TARIFF_BUY: ({ product }) => ({ cancel: !product.isCheapest }),
            TARIFF_SHOWN: ({ product }) => ({ cancel: !product.isCheapest }),
            TARIFF_CLICK: ({ product }) => ({ cancel: !product.isCheapest }),
            FAST_CHECKOUT_SHOWN: ({ productId }) => ({ cancel: !isCheapestProduct(productId) }),
        },
    }
);

export const cheapestTariff: 'a' | 'b' | 'c' = !IS_PAID_USER ? cheapestTariffValue || 'a' : 'a';
// tempexp_16598-end
