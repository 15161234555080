import { CaptchaCheckAPICall } from 'reactApp/api/CaptchaCheckAPICall';
import { checkCaptchaFailure, checkCaptchaSuccess } from 'reactApp/modules/captcha/captcha.module';
import { type CheckCaptchaAction, type CheckCaptchaParams, ECaptchaErrors } from 'reactApp/modules/captcha/captcha.types';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { call, cancel, cancelled, put } from 'redux-saga/effects';

const checkCaptchaApiCall = ({ id, value }: CheckCaptchaParams) => new CaptchaCheckAPICall().makeRequest({ id, value });

export function* checkCaptcha(payload: CheckCaptchaAction) {
    const { id, value, sendAnalytics } = payload;

    try {
        if (!value) {
            yield cancel();
        }

        const { data } = yield call(checkCaptchaApiCall, { id, value });

        if (!data?.result) {
            yield cancel();
            return;
        }

        yield put(checkCaptchaSuccess());
        sendAnalytics?.(ESubscriptionModalAction.captchaCancelSubsSuccess);
    } catch (error) {
        yield loggerSaga({ error });
    } finally {
        if (yield cancelled()) {
            sendAnalytics?.(ESubscriptionModalAction.captchaCancelSubsFail);
            yield put(checkCaptchaFailure({ error: !value ? ECaptchaErrors.empty : ECaptchaErrors.wrong }));
        }
    }
}
