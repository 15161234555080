import type { Space } from '@mail/cross-sizes-utils';
import type { FeatureCrossedFullPriceInTariffCard } from 'reactApp/appHelpers/featuresHelpers/features.types';
import type { MobileBuyClick } from 'reactApp/sections/QuotaLanding/TariffsList/TariffsList.types';
import type { Product } from 'reactApp/types/Billing';

export interface ITariffCardProduct extends Product {
    saving?: number;
    primary?: boolean;
}

export enum MobileTariffCardTheme {
    white = 'white',
    grey = 'grey',
}

export interface IProps {
    space: Space;
    giftSpace?: Space;
    products: ITariffCardProduct[];
    fullPrice?: number;
    oldPrice?: number;
    fullPricePeriod?: string;
    onClick: MobileBuyClick;
    economy?: number;
    // tempexp_13600-start
    countSnapshotsBySpace?: number;
    openAbCard?: boolean;
    // tempexp_13600-end
    tariffTypeLabel?: string;
    savingLable?: string;
    hasOldPriceYear?: boolean;
    isOldCard?: boolean;
    infoText?: string;
    theme?: MobileTariffCardTheme;
    isSingleQuota?: boolean;
    alwaysExpand?: boolean;
    showPriceByMonth?: boolean;
    skipBenefitsFeature?: boolean;
    setCarouselLoaded?: (state: boolean) => void;
    headerHeight?: number;
    crossFullPrice?: FeatureCrossedFullPriceInTariffCard;
    // tempexp_17020-start
    showPriceByYear?: boolean;
    // tempexp_17020-end
    isCardActive?: boolean;
    horizontalMobile?: boolean;
    skipDiscountPrice?: boolean;
    isRebrandingQuotaLanding?: boolean;
    isRebrandingSubscription?: boolean;
    /* tempexp_17509-start */
    isTopCard?: boolean;
    isFromLitres?: boolean;
    /* tempexp_17509-end */
    // tempexp_17406-next-line
    autouploadTooltip?: boolean;
}
