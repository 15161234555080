import type { UflrType } from 'lib/uflr';
import type { IFolderFlags } from 'reactApp/modules/home/home.types';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { Correspondents } from 'reactApp/types/QuotaCleaner';
import type { Count, Kind, Sort, SubKind, Url, VirusScan } from 'reactApp/types/Tree';
import type { IPublicFolder } from 'server/types/context/IPublicFolder';

export interface WeblinkDownloadsCounts {
    count_downloads_total?: number;
    count_downloads_left?: number;
}

export interface WeblinkDownloadable extends WeblinkDownloadsCounts {
    weblinkDownloadable?: boolean;
}

export interface PublicFolder extends WeblinkDownloadable {
    type?: string;
    id: string;
    weblink: string;
    home?: string;
    name: string;
    size?: number;
    sizeWithoutRestricted?: number;
    kind: Kind;
    parent: string;
    isFolder: true;
    rev?: number;
    count: Count;
    isLoading: boolean;
    isLoaded: boolean;
    hasMoreToLoad: boolean;
    childs: string[];
    authors?: Record<string, number>;
    storage: EStorageType.public;
    sort?: Sort;
    flags?: IFolderFlags;
    __reduxTree?: true;
    error?: string;
    cursor?: string;
    correspondents?: Correspondents;
}

export interface PublicFile {
    id: string;
    weblink: string;
    home?: string;
    name: string;
    ext: string;
    author?: string;
    nameWithoutExt: string;
    size: number;
    kind: Kind;
    subKind: SubKind;
    parent: string;
    isFolder: false;
    weblinkAccessRights?: string;
    weblinkDownloadable?: boolean;
    count_downloads_total?: number;
    count_downloads_left?: number;
    virusScan: VirusScan;
    mtime: number;
    hash?: string;
    uflr?: UflrType;
    storage: EStorageType.public;
    thumbnails?: Record<string, string>;
    url?: Url;
    __reduxTree?: true;
    correspondents?: Correspondents;
}

export type PublicItem = PublicFolder | PublicFile;

export interface IRootWeblink extends WeblinkDownloadsCounts {
    weblink: string;
    name: string;
    isOwn: boolean;
    isUploadEnabled: boolean;
    uploadLimit?: number;
    weblinkExpires?: number;
    albumId?: string;
    isAutodelete?: boolean;
    weblinkDownloadable?: boolean;
}

export interface PublicState {
    rootWeblink: IRootWeblink;
    owner?: {
        email: string;
        nick?: string;
        lastName: string;
        firstName: string;
    };
    forceShowDownloadButton: boolean;
    list: Record<string, PublicItem>;
    isOpenDwhRadarSent?: boolean;
    redirectFromViewer?: boolean;
}

export interface ILoadMoreRequestData {
    id: string;
    offset: number;
}

export const isPublicApiResponseType = (item: any): item is IPublicFolder => item.list;
