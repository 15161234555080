import { extInfo } from 'lib/extInfo';
import { getExtension, getItemNameWithoutExt, getParent, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import { type IFile, type IFileNormalized, ECategory, ERecognitionAction } from './personalDocuments.types';

const urlBuilder = new UrlBuilder();

export const normalizeItem = (item: IFile, documentId): IFileNormalized => {
    const ext = getExtension(item);
    const { kind, subKind } = extInfo.get(ext);
    const publicInfo = normalizePublicApiInfo(item);

    return {
        hash: item.hash,
        kind,
        name: item.name,
        path: item.path,
        size: item.size,
        type: item.type,
        id: item.path,
        home: item.path,
        mtime: normalizeMtime(item.mtime),
        nameWithoutExt: getItemNameWithoutExt(item.name, ext),
        ext,
        parent: getParent(item.path),
        isInFavorites: Boolean(item.flags?.favorite),
        isMounted: Boolean(item.flags?.mounted),
        documentId,
        storage: EStorageType.documents,
        category: ECategory.FILE,
        ...publicInfo,
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: item.path,
            weblink: publicInfo.weblink,
            size: item.size,
            subKind,
            kind,
            isStock: false,
            name: item.name,
            path: item.path,
        }),
        thumbnails: urlBuilder.getThumb({
            hash: item.hash,
            ext,
            weblink: publicInfo.weblink,
            id: item.path,
            size: item.size,
            kind,
            name: item.name,
            path: item.path,
            isPublic: false,
            isStock: false,
            dwl_token: null,
        }),
    };
};

export const getRecognitionError = (type: ERecognitionAction) => {
    return {
        title: `Не удалось ${type === ERecognitionAction.DISABLE ? 'выключить' : 'включить'} распознование`,
        text: 'Попробуйте через несколько минут. Мы уже знаем о проблеме и решаем её',
    };
};

export const getDocumentAllowedExtensions = (): string[] => ['jpg', 'jpeg', 'heif', 'heic', 'png', 'pdf', 'doc', 'docx'];

export const getDocumentId = (type: EDocumentType): string => `/${type}`;
