import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useCloseCleanerPage } from 'reactApp/ui/QuotaCleanerPage/hooks/useCloseCleanerPage';
import { BackButton } from 'reactApp/ui/Toolbar/BackButton/BackButton';
import type { ToolbarItem, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const getBackItem = (storage: EStorageType): TToolbarItem => {
    let hook;
    if (storage === EStorageType.quotaCleaner) {
        hook = useCloseCleanerPage;
    }

    return {
        Item: hook ? (BackButton as ToolbarItem) : null,
        itemProps: { id: 'back', hook },
    };
};
