import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
    DEFAULT_TUTORIA_FOLDER,
    INLINE_INTEGRATION_IFRAME_SRC,
    POPUP_INTEGRATION_IFRAME_SRC,
} from '../../../../public/inline-integration-iframe/components/CloudInterfaceWrapper/hooks/useCloudInterfaceLoader';

export const IntegrationRedirect = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const currentParams = parse(search);
        const popupMode = Boolean(currentParams?.popupMode);
        const folder = currentParams?.folder || (popupMode ? '' : DEFAULT_TUTORIA_FOLDER);
        const initPath = currentParams?.initPath;
        navigate({
            pathname: `/${initPath || (popupMode ? POPUP_INTEGRATION_IFRAME_SRC : INLINE_INTEGRATION_IFRAME_SRC)}${folder}`,
            search,
        });
    }, []);

    return null;
};
