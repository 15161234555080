import { Spacing, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as MarkBlueIcon } from 'img/icons/mark.svg';
import attachesWelcomeNew from 'img/welcome/attachesWelcomeNew.jpg';
import React, { type ReactElement, memo, useEffect, useMemo } from 'react';
import { abDecreaseBaseQuota } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';

import styles from './WelcomeDialogAttachesNew.css';

type WelcomeDialogBaseProps = {
    onSuccess: () => void;
    onShow: () => void;
    title: string;
    text?: string;
    coverImage: string;
    content?: ReactElement;
    footer?: ReactElement;
};

const BlueMarkIcon = <MarkBlueIcon width={11} height={9} />;
const benefitsText = [
    'Просмотр любых почтовых вложений: документов, фото и видео',
    'Бесплатные 8 ГБ для надёжного хранения любых ваших файлов',
    'Совместный доступ к фотографиям, видео и другим файлам по ссылке',
];

export const WelcomeDialogAttachesNew = memo<WelcomeDialogBaseProps>(({ onSuccess, onShow }) => {
    useEffect(() => {
        onShow();
    }, []);

    // tempexp_16521-next-line
    if (abDecreaseBaseQuota) {
        benefitsText[1] = 'Бесплатные 4 ГБ для надёжного хранения любых ваших файлов';
    }

    const dialogContent = useMemo(() => {
        return (
            <div className={styles.content}>
                <Title level="3" className={styles.title} weight="2" data-qa-id="welcomeDialogAttaches-title">
                    Облако для ваших файлов
                </Title>
                <Spacing size={12} />
                <div>
                    {benefitsText.map((benefit, index) => (
                        <>
                            <div className={styles.row} data-qa-id={`welcomeDialogAttaches-textRow-${index}`} key={benefit}>
                                {BlueMarkIcon}
                                <div>{benefit}</div>
                            </div>
                            {benefitsText.length - 1 !== index && <Spacing size={10} />}
                        </>
                    ))}
                </div>
                <Spacing size={32} />
            </div>
        );
    }, []);

    const footer = useMemo(() => {
        return (
            <div className={classNames(styles.footerText, styles.footerText_small)}>
                Нажимая «Закрыть», вы соглашаетесь с тем, что Облако использует{' '}
                <a
                    href="https://help.mail.ru/legal/terms/cloud/recommendation"
                    target="_blank"
                    rel="noreferrer"
                    data-qa-id="welcomeDialogAttaches-recommendation-link"
                >
                    рекомендательные технологии
                </a>
                , и принимаете условия{' '}
                <a
                    href="https://help.mail.ru/legal/terms/cloud/LA"
                    target="_blank"
                    rel="noreferrer"
                    data-qa-id="welcomeDialogAttaches-LA-link"
                >
                    Лицензионного соглашения
                </a>{' '}
                и{' '}
                <a
                    href="https://help.mail.ru/legal/terms/cloud/privacy"
                    target="_blank"
                    rel="noreferrer"
                    data-qa-id="welcomeDialogAttaches-privacy-link"
                >
                    Политики конфиденциальности
                </a>
            </div>
        );
    }, []);

    return (
        <WhatsNewDialog
            closable={false}
            onClick={onSuccess}
            primaryButtonText="Закрыть"
            title=""
            content={dialogContent}
            imageUrl={attachesWelcomeNew}
            imageBgColor="var(--vkui--color_background)"
            footerText={footer}
        />
    );
});

WelcomeDialogAttachesNew.displayName = 'WelcomeDialogAttachesNew';
