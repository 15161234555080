import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { ThemeContext } from 'reactApp/modules/theme/theme.context';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { sendGa } from 'reactApp/utils/ga';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './DatalistBanner.css';

const DOM_ID_AFTER_DATALIST = config.get('DOM_ID_AFTER_DATALIST');
const MIN_ITEMS_COUNT_PUBLIC = 1;
const MIN_ITEMS_COUNT_OTHERS = 12;

interface IProps {
    itemsCount: number;

    onShow(): void;
}

let minHeight = 0;

export const DatalistBanner = ({ itemsCount, onShow }: IProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const storage = useSelector(getCurrentStorage);
    const { isDocuments, isPublic } = getStorage(storage);
    let minElements = itemsCount >= MIN_ITEMS_COUNT_OTHERS;
    if (isPublic) {
        minElements = itemsCount >= MIN_ITEMS_COUNT_PUBLIC;
    }
    const closedPages = !isDocuments;
    const showAdv = !HIDE_ADS && minElements && closedPages;

    const isDark = useContext(ThemeContext).isDark;

    const { ref, height } = useResizeObserver<HTMLDivElement>();
    const hasAdvFromRb = !!height;

    if (!minHeight && height) {
        minHeight = height + 58;
    }

    const onIntersecting = () => {
        setIsVisible(true);
    };
    const onIntersectingOut = () => {
        setIsVisible(false);
    };
    const refIntersection = useIntersectionObserver({ onIntersecting, onIntersectingOut, rootMargin: '30px' });

    const updateAds = useCallback(() => {
        try {
            // @ts-ignore
            Rubles?.updateAdvAfterDatalist(refIntersection?.current?.offsetWidth ?? null, undefined, isDark);
        } catch (error) {
            captureException(error);
        }
    }, []);

    const banner = useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        suspendTimer: !(isVisible && showAdv),
        renderItem: () => <div ref={ref} id={DOM_ID_AFTER_DATALIST} />,
    });

    const sendGaClick = useCallback(() => {
        sendGa('adv', 'after-datalist', 'click', null, true);
    }, []);

    useEffect(() => {
        if (showAdv) {
            onShow();
        }
    }, [showAdv, onShow]);

    if (!showAdv) {
        return null;
    }

    return (
        <div
            ref={refIntersection}
            className={classNames({
                [styles.root]: true,
                [styles.hide]: !hasAdvFromRb,
                [styles.root_public]: isPublic,
            })}
            style={{ minHeight }}
        >
            {!isPublic && (
                <div className={styles.textWrap}>
                    <div className={styles.text}>Реклама</div>
                    <DisableButton text="Отключить" className={styles.btn} onClick={sendGaClick} id="datalist-ad" />
                </div>
            )}
            {banner}
        </div>
    );
};

DatalistBanner.displayName = 'DatalistBanner';
