import type { Config as XrayConfig, Xray } from '@mail/xray';
import React, { type ComponentType, useCallback, useMemo } from 'react';
import type { IItemHandlerData } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { createXrayInstance } from 'reactApp/utils/ga';

export interface Props {
    onItemError?: (data?: IItemHandlerData) => void;
    onItemOpen?: (data?: IItemHandlerData) => void;
    xrayConfig: XrayConfig;
    xray?: Xray;
}

export function withXray<T extends Props = Props>(WrappedComponent: ComponentType<T>) {
    const ComponentWithXray = ({ onItemError, onItemOpen, xrayConfig, ...props }: T) => {
        const xray = useMemo(() => {
            return createXrayInstance(xrayConfig);
        }, [xrayConfig]);

        const handleError = useCallback(
            ({ xrayParams }: IItemHandlerData) => {
                const { dwh = {}, i } = xrayParams || {};
                xray.send('viewer', { dwh, i });
                if (typeof onItemError === 'function') {
                    onItemError();
                }
            },
            [onItemError, xray]
        );

        const handleOpen = useCallback(
            ({ xrayParams }: IItemHandlerData) => {
                const { dwh = {}, i } = xrayParams || {};
                xray.send('viewer', { dwh, i });
                if (typeof onItemOpen === 'function') {
                    onItemOpen();
                }
            },
            [onItemOpen, xray]
        );

        return <WrappedComponent {...(props as T)} xray={xray} onItemError={handleError} onItemOpen={handleOpen} />;
    };
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithXray.displayName = `withXray(${displayName})`;
    return ComponentWithXray;
}
