import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'file/remove';

class RemoveFileAPIV2Call extends APICallV2Post {
    _method = method;
}

class RemoveFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const removeFileApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateFileV2ToV4.remove) {
        return new RemoveFileAPIV4Call().makeRequest({ path: params.home });
    }

    return new RemoveFileAPIV2Call().makeRequest(params);
};
