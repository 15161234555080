import classNames from 'clsx';
import React, { type FC } from 'react';
import { FileType } from 'reactApp/types/FileType';

import styles from './FileIcon.css';
import { type Size, isFolder as _isFolder } from './FileIcon.helpers';

interface FileIconProps {
    type?: FileType | string;
    className?: string;
    size?: Size | number;
    mode?: 'light' | 'dark';
}

export const FileIcon: FC<FileIconProps> = ({ className, type = FileType.UNKNOWN, size = 's', mode = 'light' }) => {
    const isFolder = _isFolder(type as FileType);
    const actualSize = typeof size !== 'number' && size;
    const style = typeof size === 'number' ? { width: size, height: size } : undefined;

    return (
        <div
            style={style}
            className={classNames(styles.icon, className, {
                [styles[`size_${actualSize}`]]: !!actualSize,
                [styles.mode]: true,
                [styles[`folder_type_${type}`]]: isFolder,
                [styles[`file_type_${type}`]]: !isFolder,
                [styles['file_type']]: !isFolder,
                [styles.root_dark]: mode === 'dark',
            })}
        />
    );
};
