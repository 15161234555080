import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDomainAccessOptions } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getIntraDomainShare } from 'reactApp/modules/home/home.selectors';

import { DomesticDropdownTemplate } from '../DomesticDropdownTemplate/DomesticDropdownTemplate';
import type { DomesticProps } from './DomesticDropdown.types';
import { useDomestic } from './hooks/useDomestic';

const getModifiedOptionsForIntraDomainShareFeature = (options: ReturnType<typeof getDomainAccessOptions>) => {
    const { [DomainAccess.EXTERNAL]: external, ...rest } = options;
    return {
        [DomainAccess.EXTERNAL]: {
            ...external,
            disabled: true,
        },
        ...rest,
    };
};

export const DomesticDropdown: React.FC<DomesticProps> = ({ item, isWeblinkOpened, onActionClick, domain }) => {
    const isPhone = EnvironmentSelectors.isPhone();
    const isFolder = item?.isFolder;
    const isSupportIntraDomainShare = useSelector(getIntraDomainShare);
    const domainAccess = item?.weblinkDomestic || isSupportIntraDomainShare ? DomainAccess.DOMESTIC : DomainAccess.EXTERNAL;
    const checked = !isWeblinkOpened ? DomainAccess.OFF : domainAccess;

    const domainAccessOptions = useMemo(() => {
        const options = getDomainAccessOptions(domain || '');
        if (isSupportIntraDomainShare) {
            return getModifiedOptionsForIntraDomainShareFeature(options);
        }
        return options;
    }, [domain, isSupportIntraDomainShare]);

    const { renderDropdownMenu, handleUpdateDomestic } = useDomestic({
        checked,
        item,
        isWeblinkOpened,
        onActionClick,
        domainAccessOptions,
    });

    return (
        <DomesticDropdownTemplate
            checked={checked}
            accessText={isFolder ? 'Папка доступна по ссылке' : 'Файл доступен по ссылке'}
            isPhone={isPhone}
            renderDomesticDropdown={renderDropdownMenu}
            handleUpdateDomestic={handleUpdateDomestic}
            domain={domain}
        />
    );
};
