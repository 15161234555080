import React, { type ReactElement, memo, useCallback } from 'react';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';

import styles from './FilterAuthorsModal.css';

export const AuthorItem = memo(
    ({
        email,
        name,
        checked,
        onClick,
    }: {
        email: string;
        name: string;
        checked: boolean;
        onClick: (email, checked) => void;
    }): ReactElement => {
        const onAuthorClick = useCallback(() => {
            onClick(email, checked);
        }, [checked, email, onClick]);

        return (
            <div className={styles.item} onClick={onAuthorClick} data-qa-email={email}>
                <div className={styles.avatar}>
                    <Avatar size={32} email={email} />
                </div>
                <div className={styles.name}>{name}</div>
                <Checkbox selected={checked} mod="new" />
            </div>
        );
    }
);

AuthorItem.displayName = 'AuthorItem';
