import emergencySwaImage from 'img/emergencySwaImage.jpg';
import promocodeImage from 'img/promocodePopup.jpg';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';
import { noopVoid } from 'reactApp/utils/helpers';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const renderWhatsNewDialog = renderModalDialog(WhatsNewDialog, 'WhatsNewDialog');

export const renderPromocodePopup = ({ onSuccess: handleSuccess = noopVoid, onClose: handleClose = noopVoid }) => {
    const additionalParams = {
        details_popup: 'ok',
        name_popup: 'promocode-success',
        eventCategory: EPaymentGa.popup,
    };

    const onShow = () => sendPaymentGa({ label: 'open', ...additionalParams });

    const onClick = () => {
        sendPaymentGa({ label: 'click', ...additionalParams });
        handleSuccess();
    };

    const onClose = () => {
        sendPaymentGa({ label: 'close', ...additionalParams });
        handleClose();
    };

    openPopupHelper({
        popupName: popupNames.WHATS_NEW_DIALOG,
        onClose,
        data: {
            title: 'Промокод успешно активирован',
            text: 'Дополнительные гигабайты будут начислены в ближайшее время',
            primaryButtonText: 'Спасибо',
            imageUrl: promocodeImage,
            onClick,
            onShow,
        },
    });
};

export const renderEmergencySwaPopup = ({ onSuccess: handleSuccess = noopVoid, onClose: handleClose = noopVoid, link }) => {
    const additionalParams = {
        details_popup: 'ok',
        name_popup: 'emergency-swa',
        eventCategory: EPaymentGa.popup,
    };

    const onShow = () => sendPaymentGa({ label: 'open', ...additionalParams });

    const onClick = () => {
        sendPaymentGa({ label: 'click', ...additionalParams });
        handleSuccess();
    };

    const onClose = () => {
        sendPaymentGa({ label: 'close', ...additionalParams });
        handleClose();
    };

    openPopupHelper({
        popupName: popupNames.WHATS_NEW_DIALOG,
        onClose,
        data: {
            link,
            title: 'Проблемы с авторизацией',
            text: 'Могут возникнуть проблемы авторизации. Для доступа к Облаку\nскопируйте себе ссылку и используйте для входа.',
            imageUrl: emergencySwaImage,
            onClick,
            onShow,
        },
    });
};
