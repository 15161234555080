import React, { memo, useMemo } from 'react';
import { Portal } from 'react-portal';
import { FixedSizeGrid } from 'react-window';
import type { TFaceListProps, TFaceListTooltipProps } from 'reactApp/ui/FaceFilter/FaceFilter.types';
import { FaceListItem } from 'reactApp/ui/FaceFilter/FaceList/FaceItem/FaceListItem';
import { getGridSizes } from 'reactApp/ui/FaceFilter/FaceList/FaceList.helpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { useWindowResizer } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from './FaceList.css';

export const FaceList = memo(function FaceList(props: TFaceListProps) {
    const [rootRef, containerWidth] = useWindowResizer();

    const style = useMemo(() => (props.isMobile ? ({ overflowX: 'hidden' } as const) : ({ overflowX: 'auto' } as const)), [props.isMobile]);

    const { columnCount, columnWidth, rowCount, height, rowHeight, width } = getGridSizes({
        faces: props.faces?.length || 0,
        hasMoreToLoad: props.hasMoreToLoad,
        isMobile: props.isMobile,
        width: containerWidth,
    });

    if (!props.faces?.length) {
        return null;
    }

    return (
        <div className={styles.root} ref={rootRef}>
            <FixedSizeGrid
                columnCount={columnCount}
                columnWidth={columnWidth}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={rowHeight}
                itemData={{ ...props, columnCount }}
                style={style}
            >
                {FaceListItem}
            </FixedSizeGrid>
        </div>
    );
});

export const FaceListTooltip = memo(function FaceListTooltip({ onTooltipClose, targetRef, ...props }: TFaceListTooltipProps) {
    if (!props.faces?.length) {
        return null;
    }

    return (
        <Portal>
            <FloatingTooltip
                target={targetRef}
                placement={ETooltipPlacement.bottom}
                onClose={onTooltipClose}
                closable={false}
                hideArrow
                qaId="tooltip-faces-list"
            >
                <div className={styles.tooltip}>
                    <FaceList {...props} />
                </div>
            </FloatingTooltip>
        </Portal>
    );
});
