import { EditorID } from 'Cloud/Application/Editor/types';
import React, { type FC } from 'react';
import { useSelector } from 'react-redux';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import { getFeatureMyOfficeEditorPromo, getFeatureMyOfficePromoRating } from 'reactApp/modules/features/features.selectors';
import type { RootState } from 'reactApp/store';
import { EditorHeaderButton } from 'reactApp/ui/MyOfficePromo/EditorHeaderButton/EditorHeaderButton';
import { MyOfficePopup } from 'reactApp/ui/MyOfficePromo/Popup/MyOfficePopup';
import opener from 'reactApp/utils/opener';

const RADAR_NAME = 'myoffice-editor-promo';

interface EditorHeaderButtonPromoContainerProps {
    fileExt?: string;
}

export const EditorHeaderButtonPromoContainer: FC<EditorHeaderButtonPromoContainerProps> = ({ fileExt = '' }) => {
    const featureData = useSelector(getFeatureMyOfficeEditorPromo);
    const showPromoRating = useSelector(getFeatureMyOfficePromoRating);
    const editorId = useSelector((state: RootState) => getEditorState(state).editorId);
    const isMyOffice = editorId === EditorID.MYOFFICE;

    return isMyOffice && !!featureData ? (
        <MyOfficePopup
            mouseLeaveDelay={featureData?.mouseLeaveDelay}
            mouseEnterDelay={featureData?.mouseEnterDelay}
            radarName={RADAR_NAME}
            offset={[-20, 5]}
        >
            <EditorHeaderButton
                showPromoRating={showPromoRating}
                onClickAd={() => {
                    opener(featureData.adLink);
                }}
                radarName={RADAR_NAME}
                fileExt={fileExt}
                onClick={() => {
                    opener(featureData.link);
                }}
            />
        </MyOfficePopup>
    ) : null;
};
