import classNames from 'clsx';
import React, { type ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharingNewAddUser } from 'reactApp/components/SharingWindow/SharingNew/AddUser/SharingNewAddUser';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import {
    weblinkAccessControlLoadStart,
    weblinkAccessControlReset,
    weblinkAccessControlSetError,
    weblinkSetEmailAccess,
} from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.actions';
import { getACL, getError, isAccessControlListLoading } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.selectors';
import { EPublicAccessRights } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.types';
import { sendGa } from 'reactApp/utils/ga';

import { EAccessRights } from '../../../Sharing.types';
import { WeblinkUserList } from '../../UserList/WeblinkUserList';
import styles from './SharingNewWeblinkList.css';
import type { IProps } from './SharingNewWeblinkList.types';

export const SharingNewWeblinkList = React.memo(function SharingNewWeblinkList({ item, isPhone, isReadOnly }: IProps): ReactElement | null {
    const dispatch = useDispatch();

    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);
    const acList = useSelector(getACL);
    const isLoading = useSelector(isAccessControlListLoading);
    const error = useSelector(getError);

    const isUserLoaded = isLoaded && !isUserError;

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        dispatch(weblinkAccessControlLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, item?.storage]);

    useEffect(() => {
        if (!isUserLoaded) {
            (async () => {
                await dispatch(loadUser);
                await loadAllData();
            })();
        } else {
            loadAllData();
        }

        return function cleanup() {
            dispatch(weblinkAccessControlReset());
        };
    }, [dispatch, isUserLoaded, loadAllData]);

    const handleAddUser = useCallback(
        ({ currentEmail, accessRight }: { currentEmail: string; accessRight: EAccessRights }) => {
            const mappedAccess = accessRight === EAccessRights.READ_WRITE ? EPublicAccessRights.READ_WRITE : EPublicAccessRights.READ_ONLY;

            dispatch(
                weblinkSetEmailAccess({
                    accessRight: mappedAccess,
                    weblink: item?.weblink || '',
                    email: currentEmail,
                })
            );
            dispatch(contactsSlice.actions.listRequest());

            sendGa('public-add-user');
        },
        [dispatch, item?.weblink]
    );

    const clearError = useCallback(() => {
        dispatch(weblinkAccessControlSetError({ error: '' }));
    }, [dispatch]);

    return (
        <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
            <SharingNewAddUser
                handleAddUser={handleAddUser}
                isPhone={isPhone}
                isLoading={isLoading}
                error={error}
                clearError={clearError}
                isReadOnly={isReadOnly}
            />
            <WeblinkUserList acList={acList} isLoading={isLoading} isPhone={isPhone} item={item} isReadOnly={isReadOnly} />
        </div>
    );
});
