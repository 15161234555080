import { type Space, bytesToNDigits } from '@mail/cross-sizes-utils';
import { equals, filter, path, sortBy, values } from 'ramda';
import { summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import type { Product, Tariff } from 'reactApp/types/Billing';
import { parsePeriod, simplifyPeriod } from 'reactApp/utils/Period';
import { pretextSPlural } from 'reactApp/utils/pluralHelpers';

import { cheapestProductIDs } from './products.const';

export const SINGLE_QUOTA_REGEX = /.*_single_quota*/;

export const SINGLE_QUOTA_DISKO_REGEX = /.*_single_quota_disko_cloud*/;

export const DEFAULT_PRIMARY_PRODUCT = 137438953472; // 128GB

function isExist<T>(item: T | null | undefined): item is T {
    return Boolean(item);
}

export const sortBySpace = <T extends { space: Space }>(products: Record<string, T | undefined>) =>
    // @ts-ignore
    sortBy(path(['space', 'original']), values(products).filter(isExist));

export const normalizeData = (tariffs) => {
    const list: Record<string, Tariff | undefined> = {};

    Object.keys(tariffs).forEach((id) => {
        const group = tariffs[id];

        const { services, products } = group;
        const space = bytesToNDigits(services.quota, 3);
        const gift = services.gift && {
            space: bytesToNDigits(services.gift.gift_quota, 3),
            period: services.gift.gift_interval,
            id: services.gift.giftible_id,
        };

        list[id] = {
            id,
            turbo: /^pro?\d/.test(products[0].product_id),
            isSingleQuota: Boolean(services.mailru),
            isPromoCode: products.some((product) => product.is_promo),
            available: products.every((product) => product.available),
            space,
            flags: {
                upload: !!services?.flags?.UFLAG_PAID_UPLOAD,
                paidFeatures: !!services?.flags?.UFLAG_PAID_ACCOUNT,
            },
            gift,
            isProTariff: !!services.disko && !!services.pro,
            products: products.map((product) => ({
                price: product.price,
                id: product.product_id,
                period: product.period,
                available: product.available,
                isPromo: product.is_promo,
                hasTrial: product.has_trial_period,
                trialPeriod: product.trial_period,
                isProfessional: !!services.pro,
                isDisko: !!services.disko,
                hasDiskO: !!services.disko && !!services.pro,
                hasDiskOAutoRenewal: !!services.disko,
                // Пока считаем что наличие санты это наличие vkCombo
                isVkCombo: !!services.santa,
                santa: services.santa,
                // Триалы у которых есть еще скидочный период
                isForceDiscountTrial: product.has_trial_period && product.has_discount_period,
                // "предоплаченный" триал, не отнимает квоту при отвязке карты / отключеннии автопродления
                isPrepaid: product.is_prepaid,
                hasDiscount: product.has_discount_period,
                discountPeriod: product.discount_period,
                ...(product?.discount_period_count && { discountPeriodCount: product.discount_period_count }),
                discountPrice: product.discount_price,
                space,
                turbo: /^pro?\d/.test(product.product_id),
                isSingleQuota: Boolean(services.mailru),
                gift,
                isMbUser: services.common?.mb_user,
            })),
        };
    });

    return list;
};

export const checkProductListEquals =
    (oldData) =>
    ({ data }) =>
        !equals(normalizeData(data), oldData);

// tempexp-17081-start
export const PRICE_INCREASE_10_PERCEN_1M_WEB_REGEX = /.*_1m_increase_10_discount_3m_single_quota_cloud$/;
export const PRICE_INCREASE_10_PERCEN_1M_TOUCH_REGEX = /.*_1m_increase_10_single_quota_cloud$/;
export const PRICE_INCREASE_10_PERCEN_1Y_REGEX = /.*_1y_increase_10_discount_single_quota_cloud$/;
export const increasePrice10PercentInTariffs = (
    tariffs: Tariff[],
    { monthTariffs, yearTariffs }: { monthTariffs: Tariff[]; yearTariffs: Tariff[] }
) => replaceProductInTariff(replaceProductInTariff(tariffs, yearTariffs), monthTariffs);
// tempexp-17081-end

export const PROMO_TARIFFS_REGEX = {
    promoTariffs: /.*_PT$/,
    marketingPromo: /.*_blackfriday_2023_discount_single_quota_cloud$/,
};

export const SUMMER_PROMO_TARIFFS_REGEX = /.*_summer_discount_25_single_quota_cloud$/;

export const isSummerPromoProductsAvailable = (productId: string) => summerPromotion && SUMMER_PROMO_TARIFFS_REGEX.test(productId);

export const summerTariff4TBId = 'W4TB_summer_discount_25_single_quota_cloud';

export const upsertSummerPromoTariffs = (originalTariffs: Tariff[], summerPromoTarrifs: Tariff[] = [], isMobile = false) => {
    const summerTariffs4TB = getTariffsByQuotaHelper(summerPromoTarrifs, SPACE_LIST.tb_4) as Tariff[];
    const [monthTariff, yearTariff] = summerTariffs4TB;
    if (!(monthTariff && yearTariff)) {
        return originalTariffs;
    }

    const has4TBTariff = originalTariffs.some((t) => t.space.original === SPACE_LIST.tb_4);

    const replacedTariffs = replaceProductInTariff(originalTariffs, summerPromoTarrifs);
    if (!has4TBTariff) {
        const tariff4TB = { ...yearTariff, products: [...monthTariff.products, ...yearTariff.products], id: summerTariff4TBId };
        if (isMobile) {
            replacedTariffs.push(tariff4TB);
        } else {
            replacedTariffs.unshift(tariff4TB);
        }
        return replacedTariffs;
    }
    const replaced4TbYearTariffs = replaceProductInTariff(replacedTariffs, [yearTariff]);
    return replaceProductInTariff(replaced4TbYearTariffs, [monthTariff]);
};

// tempexp_16481-start
export const AUTOUPLOAD_10TB_REGEX = /^W.*_(autoupload_action(_discount)?|autoupload_v2_discount|upsale_10tgift_discount).*$/;
// @TODO: пришлось захардкодить массив тк нужно было синхронизировать вебвью мобилки между экспами. Переделать если эксп взлетит.
export const AUTOUPLOAD_10TB_TARIFFS = [
    'W64GB_1y_autoupload_action_discount',
    'W128GB_1y_autoupload_action_discount',
    'W256GB_1y_autoupload_action_discount',
    'W512GB_1y_autoupload_action_discount',
    'W1TB_1y_autoupload_action_discount_single_quota_cloud',
    'W2TB_1y_autoupload_action_discount_single_quota_cloud',
    'W4TB_1y_autoupload_action_discount_single_quota_cloud',
    'W64GB_1m_autoupload_action',
    'W128GB_1m_autoupload_action',
    'W256GB_1m_autoupload_action',
    'W512GB_1m_autoupload_action',
    'W1TB_1m_autoupload_action_single_quota_cloud',
    'W2TB_1m_autoupload_action_single_quota_cloud',
    'W4TB_1m_autoupload_action_single_quota_cloud',
    'W256GB_1m_autoupload_v2_discount',
    'W1TB_1m_autoupload_v2_discount',
    'W2TB_1m_autoupload_v2_discount',
];
// tempexp_16481-end

export const TRIAL_REGEX = /.*(prepaid|trial)+/;

export const isTrial = (productId?: string) => productId?.includes('prepaid') || productId?.includes('trial');

export const YEAR_COST_DECREASE_REGEX = /W\d{3,4}G1y_2021/;

export const YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX = /W\d{1,4}[TG]1y_.*(single_quota_discount50_cloud)/;

export const isNotSpecialProduct = (product) =>
    !product.products.some((product) => product.hasTrial) &&
    !product.products.some((product) => product.isPromo) &&
    !values(PROMO_TARIFFS_REGEX).some((promoRegex) => promoRegex.test(product.id));

export const SPACE_SIZES = {
    _32: SPACE_LIST.gb_32,
    _64: SPACE_LIST.gb_64,
    _128: SPACE_LIST.gb_128,
    _256: SPACE_LIST.gb_256,
    _512: SPACE_LIST.gb_512,
    _1024: SPACE_LIST.tb_1,
    _2048: SPACE_LIST.tb_2,
    _4096: SPACE_LIST.tb_4,
};

export const getPretextPeriodForProduct = (period: string) => {
    const matchedPeriod = parsePeriod(period);
    if (!matchedPeriod) {
        return '';
    }
    const [count] = simplifyPeriod(Number(matchedPeriod[1]), matchedPeriod[2]);

    return pretextSPlural(count + 1);
};

export const filterTariffsByQuotaAndPeriod = (tariff, quota, period) => {
    if (tariff.space.original !== quota) {
        return false;
    }

    return Boolean(tariff.products.find((product) => product.period === period));
};

export const isTariffForExclude = (tariff: Tariff, quota: number, excludePro?: boolean): boolean => {
    if (tariff.isProTariff && !excludePro) {
        return false;
    }

    return tariff?.space?.original === quota;
};

export const getTariffsByQuotaHelper = (tariffs: Tariff[], quota: number) =>
    filter((tariff) => path(['space', 'original'], tariff) === +quota, tariffs);

// tempexp_16338-start
export const replaceProductInTariff = (tariffs: Tariff[], tariffsWithProductToReplace: Tariff[]) => {
    return tariffs.map((tariff) => {
        const tariffWithIncreasedPrice = getTariffsByQuotaHelper(tariffsWithProductToReplace, tariff.space.original)[0] as Tariff;

        if (!tariffWithIncreasedPrice) {
            return tariff;
        }

        const index = tariff.products.findIndex((product) => product.period === tariffWithIncreasedPrice?.products[0].period);

        if (index === -1) {
            return tariff;
        }

        const products = [...tariff.products];
        products[index] = tariffWithIncreasedPrice.products[0];

        return {
            ...tariff,
            products,
        };
    });
};
// tempexp_16338-end

/**
 * tempexp_16510
 * Фильтрует и сортирует массив тарифов на основании массива размеров.
 *
 * @param tariffs - массив тарифов
 * @param quotasInGigabytes - массив размеров в Гигабайтах в нужном порядке
 *
 * @returns
 */
export const filterAndSortTariffsByQuota = (tariffs: Tariff[], quotasInGigabytes: number[]) => {
    const filtered = tariffs.filter((item) => {
        const itemSpaceInGigabytes = item?.space?.original / SPACE_LIST.gb_1;
        return quotasInGigabytes.includes(itemSpaceInGigabytes);
    });
    filtered.sort((a, b) => {
        const aSpaceInGigabytes = a?.space?.original / SPACE_LIST.gb_1;
        const bSpaceInGigabytes = b?.space?.original / SPACE_LIST.gb_1;
        return quotasInGigabytes.indexOf(aSpaceInGigabytes) - quotasInGigabytes.indexOf(bSpaceInGigabytes);
    });

    return filtered;
};

/**
 * Сортирует массив по id на основании порядка в параметре list
 *
 * @param list
 */
export const getComparatorByIdFromList = (list: string[]) => (a, b) => list.indexOf(a.id) - list.indexOf(b.id);

/**
 * Возвращает продукт из списка на основании квоты и периода
 *
 * @param tariffs - массив тарифов
 * @param quota - размер квоты продукта
 * @param period - период действия продукта
 */
export const getProductFromTariffListByQuotaAndPeriod = (tariffs: Tariff[], quota, period): Product | undefined => {
    const products = tariffs.find((tariff) => tariff.space.original === quota)?.products;
    return products?.find((product) => product.period === period);
};

/* tempexp_16598-start */
export const isCheapestProduct = (productId: string) => Object.values(cheapestProductIDs).includes(productId);
/* tempexp_16598-end */
