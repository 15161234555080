/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContent } from 'reactApp/components/VkBuyModalWithSidebar/hooks/useContent';
import { useModalCallbacks } from 'reactApp/components/VkBuyModalWithSidebar/hooks/useModalCallbacks';
import {
    type IProps,
    EPaymentModalType,
    PaymentModalEventCategory,
    PaymentModalFrameEventCategory,
    PaymentModalToPaymentWindowType,
} from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { getPayMethod } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { PaymentWindowType } from 'reactApp/modules/payment/payment.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Product, Tariff } from 'reactApp/types/Billing';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { DMR } from 'reactApp/ui/DMRFrame/DMR';

import styles from './VkBuyModalWithSidebar.css';

const TRIAL_MIN_HEIGH = 280;

export const VkBuyModalWithSidebar = memo(
    ({
        productId,
        onShow,
        onClose,
        type,
        onSuccess,
        tariff,
        isMarketingPromo,
        isFrame,
        onDmrResize,
        withoutSidebar,
        paymentDescription,
        paySource,
        featuresList,
        options,
        title,
        hideOtherTariffs = false,
        hideSwitchSidebarBlock = false,
        onPayBtnClick,
    }: IProps): ReactElement => {
        const INITIAL_HEIGHT = type === EPaymentModalType.fastcheckout ? 580 : 490; // Начальная высота окна оплаты

        const dispatch = useDispatch();

        const [height, setHeight] = useState<number>(INITIAL_HEIGHT);

        const { isLoading, hasError } = useSelector(BillingSelectors.getLoadingState);
        const link = useSelector(BillingSelectors.getPaymentLink);

        const [currentProductId, setProductId] = useState<string>(productId);

        // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
        const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, currentProductId)) as Product;
        const storeTariff = useSelector((state: RootState) => ProductsSelectors.getTariffByProductId(state, product.id)) as Tariff;

        const payMethod = type === EPaymentModalType.fastcheckout && !product.hasTrial ? getPayMethod(false) : undefined;

        const buySubscription = useCallback(
            (id: string) => {
                dispatch(
                    BillingActions.buySubscription({
                        id,
                        isQuick: type === EPaymentModalType.trial,
                        xraySource: `buy-sidebar-${type}`,
                        payMethod,
                        description: paymentDescription,
                        paySource,
                    })
                );
            },
            [dispatch, payMethod, paymentDescription, type]
        );

        const resetLink = useCallback(() => {
            dispatch(BillingActions.resetLink());
        }, [dispatch]);

        const {
            onSuccessCallback,
            onCloseModal,
            onCancel,
            onDmrFormSend,
            onShow: handleShow,
            onClickTariffs,
        } = useModalCallbacks({
            onClose,
            onSuccess,
            product,
            type,
            isFrame,
            paySource,
        });

        const isFastCheckout = type === EPaymentModalType.fastcheckout;

        const changeProduct = useCallback((id: string) => {
            setProductId(id);
        }, []);

        const handleDmrResize = useCallback(
            (params) => {
                // Размеры маргинов из VkBuyModalWithSidebar.css
                const marginsHeight = isFastCheckout ? 16 + 24 : 0;
                const newHeight = (params?.height > INITIAL_HEIGHT && params.height + (isFastCheckout ? 70 : 0)) || INITIAL_HEIGHT;

                setHeight(newHeight);
                onDmrResize?.(newHeight + marginsHeight);
            },
            [isFastCheckout, INITIAL_HEIGHT, onDmrResize]
        );

        const handlePayBtnClick = useCallback(() => {
            onPayBtnClick?.();
            onDmrFormSend();
        }, []);

        const { sidebar, footer, header } = useContent({
            type,
            product,
            tariff: tariff || storeTariff,
            changeProduct,
            onClickTariffs,
            isMarketingPromo,
            featuresList,
            isFrame,
            options,
            title,
            hideOtherTariffs,
            hideSwitchSidebarBlock,
        });

        const dmrFrame = useMemo(() => {
            const { dmrWithPaddings } = options ?? {};
            const isFastcheckoutTrial = type === EPaymentModalType.fastcheckout && product.hasTrial;
            const usePaddings = type === EPaymentModalType.trial && product.hasTrial && dmrWithPaddings;
            const isFastCheckoutMbiz = type === EPaymentModalType.fastcheckout && product.isMbUser;
            const dmr = (
                <DMR
                    buySubscription={buySubscription}
                    resetSubscription={resetLink}
                    subscription={product}
                    isLoading={isLoading}
                    hasError={hasError}
                    checkoutLink={link}
                    onFormSend={handlePayBtnClick}
                    onCancel={onCancel}
                    onSuccess={onSuccessCallback}
                    onResize={handleDmrResize}
                    qaId={`payment-vk-${type}`}
                    minHeight={isFastcheckoutTrial ? TRIAL_MIN_HEIGH : INITIAL_HEIGHT}
                    className={classNames(styles.dmr, {
                        [styles.dmr_padding]: usePaddings,
                        [styles.dmr_border]: isFastCheckoutMbiz,
                    })}
                    size={type === EPaymentModalType.fastcheckout && !product.hasTrial ? 'larger' : null}
                    payMethod={payMethod}
                    eventCategory={isFrame ? PaymentModalFrameEventCategory[type] : PaymentModalEventCategory[type]}
                    paySource={paySource}
                    windowType={isFrame ? PaymentWindowType.frame : PaymentModalToPaymentWindowType[type]}
                />
            );

            if (isFastcheckoutTrial) {
                return (
                    <div className={styles.trialBlock}>
                        <div className={styles.dmrBlock}>
                            <div className={styles.trialHeader}>Введите данные карты</div>
                            {dmr}
                        </div>
                    </div>
                );
            }

            return dmr;
        }, [
            INITIAL_HEIGHT,
            buySubscription,
            handleDmrResize,
            hasError,
            isLoading,
            link,
            onCancel,
            onDmrFormSend,
            onSuccessCallback,
            product,
            resetLink,
            type,
            payMethod,
        ]);

        const content = useMemo(
            () => (
                <div className={styles.content} style={{ height: `${height}px` }}>
                    {!withoutSidebar && (
                        <div className={styles.sidebar} style={{ minHeight: `${INITIAL_HEIGHT}px`, height: `${height}px` }}>
                            {sidebar}
                        </div>
                    )}
                    <div className={styles.payment} style={{ height: `${height}px` }}>
                        {dmrFrame}
                        {footer}
                    </div>
                </div>
            ),
            [INITIAL_HEIGHT, dmrFrame, footer, height, sidebar]
        );

        useEffect(() => {
            handleShow();
            onShow?.();
        }, []);

        return isFrame ? (
            <div
                className={classNames({
                    [styles[`root_${type}`]]: !!type,
                })}
            >
                {content}
            </div>
        ) : (
            <Dialog
                id="vk-buy-with-sidebar"
                open
                disableDarkTheme
                mod="frame"
                className={classNames({
                    [styles[`root_${type}`]]: !!type,
                })}
                onCancel={onCloseModal}
                closeOnDimmerClick={false}
                header={header}
            >
                {content}
            </Dialog>
        );
    }
);

VkBuyModalWithSidebar.displayName = 'VkBuyModalWithSidebar';
