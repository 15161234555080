import { TariffPromoLabelType } from './TariffsSection.types';

// ширина карточки + место для между ними
export const fixedItemWidth = 380;
export const rebrandingFixedItemWidth = 320;
// чтобы стрелочки переключения карусели не заходили на карточки
export const carouselPadding = 160;
export const rebrandingCarouselPadding = 220;

export const carouselBreakpoints = [
    {
        maxWidth: 800,
        showCount: 1,
    },
    {
        maxWidth: 1080,
        showCount: 2,
    },
    {
        maxWidth: 1200,
        showCount: 3,
    },
];

export const rebrandingQuotaCarouselBreakpoints = [
    {
        maxWidth: 801,
        showCount: 1,
    },
    {
        maxWidth: 1101,
        showCount: 2,
    },
    {
        maxWidth: 1300,
        showCount: 3,
    },
];

export const carouselBreakpointsForOneCard = [
    {
        maxWidth: 800,
        showCount: 1,
    },
];

export const tariffPromoLabelText: Record<TariffPromoLabelType, string> = {
    // tempexp_16481-next-line
    [TariffPromoLabelType.autoupload10TB]: '+10 ТБ для телефона',
};
