import { Icon16MoreHorizontal } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsTutoriaIntegration } from 'reactApp/hooks/useIsTutoriaIntegration';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { LinkIcon } from 'reactApp/ui/VKUIIcons';
import { noop } from 'reactApp/utils/helpers';

import styles from './DatalistThumbItem.css';
import type { IProps } from './DatalistThumbItem.types';

export const DatalistThumbItem = memo(
    ({
        isFolder,
        kind,
        thumbUrl,
        ext,
        isVirus,
        isSelected,
        isSelecting,
        name,
        weblink,
        onClick,
        onDoubleClick,
        onOptions,
        onShare,
        id,
        author,
        onQuotaCleanerSelect,
        moreBtnRef,
    }: IProps): ReactElement => {
        const [isLoaded, setLoaded] = useState<boolean>(false);

        const onLoaded = useCallback(() => setLoaded(true), []);

        const storage = useSelector(getCurrentStorage);
        const { isTutoria } = useSelector(getIntegrationClient);
        const { isPublic, isQuotaCleaner, isIntegration } = getStorage(storage);

        const isTutoriaIntegration = useIsTutoriaIntegration();

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_loaded]: isLoaded || isFolder || !thumbUrl?.src,
                    [styles.root_selecting]: isSelecting,
                    [styles.root_selected]: isSelected,
                    [styles.root_inlineIntegration]: isTutoriaIntegration,
                    [styles.root_inlineIntegration_selected]: isSelected && isTutoriaIntegration,
                })}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                data-qa-id={id}
                data-qa-type={isFolder ? 'folder' : 'file'}
                data-qa-name={ext ? `${name}.${ext}` : name}
            >
                <div className={styles.content}>
                    <div className={styles.check} onClick={onQuotaCleanerSelect}>
                        <DataListControl type="check" active={isSelected} size={28} viewType={EViewMode.thumbs} />
                    </div>
                    <div className={styles.pic}>
                        <DatalistPic
                            isFolder={isFolder}
                            ext={ext || ''}
                            kind={kind}
                            thumbUrl={thumbUrl}
                            isVirus={isVirus}
                            viewMode={EViewMode.thumbs}
                            onLoaded={onLoaded}
                            author={author}
                        />
                    </div>
                    <div className={styles.name}>
                        <NameComponent name={name} extension={ext} truncate />
                    </div>

                    {!(isTutoria && isIntegration) && (
                        <div className={styles.buttons}>
                            {!isPublic && !!weblink && (
                                <div className={styles.button_publish} onClick={isSelecting ? noop : onShare}>
                                    <LinkIcon className={styles.icon} width={14} height={14} />
                                </div>
                            )}
                            {!isQuotaCleaner && (
                                <div ref={moreBtnRef} className={styles.button_more} onClick={isSelecting ? noop : onOptions}>
                                    <Icon16MoreHorizontal className={styles.icon} width={20} height={20} fill="currentColor" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

DatalistThumbItem.displayName = 'DatalistThumbItem';
