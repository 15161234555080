import { Cell } from '@vkontakte/vkui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isVirusItem } from 'reactApp/modules/file/utils';
import type { ApiSearchFolderResponseFile, ApiSearchResponseFolder } from 'reactApp/modules/search/search.types';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { changeStorageHistory } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { openMobileViewer } from 'reactApp/modules/viewer/viewer.module';
import type { RootState } from 'reactApp/store';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';
import { getThumbUrl } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic.helpers';

import styles from './SearchSectionItem.css';

export interface IProps {
    item: ApiSearchResponseFolder | ApiSearchFolderResponseFile;
}

export const SearchSectionItem: React.FC<IProps> = ({ item }) => {
    const dispatch = useDispatch();
    const itemFullInfo = useSelector((state: RootState) => getStorageItemById(state, EStorageType.search, item.home));
    const isVirus = isVirusItem(item);

    const thumbUrl = getThumbUrl({
        item: itemFullInfo,
        isVirus,
        viewMode: EViewMode.list,
    });

    const onItemClick = useCallback(
        (id: string, isFolder: boolean) => {
            if (isFolder) {
                changeStorageHistory({
                    id,
                    storage: undefined,
                    navigateToStorage: EStorageType.home,
                });
                return;
            }
            dispatch(openMobileViewer({ id }));
        },
        [dispatch]
    );

    return (
        <Cell
            before={
                <div className={styles.pic}>
                    <DatalistPic
                        isFolder={item.kind === 'folder'}
                        ext=""
                        kind={item.kind}
                        thumbUrl={thumbUrl}
                        isVirus={isVirus}
                        viewMode={EViewMode.list}
                    />
                </div>
            }
            hasActive={false}
            key={item.home}
            onClick={() => onItemClick(item.home, item.kind === 'folder')}
        >
            {item.name}
        </Cell>
    );
};

SearchSectionItem.displayName = 'SearchSectionItem';
