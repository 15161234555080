import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';

import type { AvailableThemesLoadSuccessAction, State } from './theme.outsource.types';

const initialState: State = {
    isLoaded: false,
    isLoading: false,
    hasError: false,
    availableThemes: {},
};

export const availableThemesLoadRequest = createAction('themeOutsource/availableThemesLoadRequest');
export const availableThemesLoadSuccess = createAction<AvailableThemesLoadSuccessAction>('themeOutsource/availableThemesLoadSuccess');
export const availableThemesFailure = createAction('themeOutsource/availableThemesLoadFailure');
export const applyThemeRequest = createAction<string>('themeOutsource/applyThemeRequest');
export const chooseThemeRequest = createAction<string>('themeOutsource/chooseThemeRequest');

export const themeOutsourceReducer = createReducer(initialState, {
    [availableThemesLoadRequest.type]: (state): void => {
        state.isLoaded = false;
        state.isLoading = true;
        state.hasError = false;
        state.availableThemes = {};
    },
    [availableThemesFailure.type]: (state): void => {
        state.isLoaded = true;
        state.isLoading = false;
        state.hasError = true;
    },
    [availableThemesLoadSuccess.type]: (state, action: PayloadAction<AvailableThemesLoadSuccessAction>): void => {
        state.isLoading = false;
        state.isLoaded = true;
        state.hasError = false;
        state.availableThemes = action.payload.themes;
    },
    [applyThemeRequest.type]: (state, action: PayloadAction<string>): void => {
        state.appliedTheme = action.payload;
    },
    [chooseThemeRequest.type]: (state, action: PayloadAction<string>): void => {
        state.choosedTheme = action.payload;
    },
});
