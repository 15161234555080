import { isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import type { EStoreType } from 'reactApp/ui/StoreButton/StoreButton';

export interface IAppCard {
    id: string;
    title: string;
    text: string;
    appStoreLink: string;
    googlePlayLink: string;
    ruStoreLink?: string;
    appGalleryLink?: string;
    mobileType?: EStoreType;
}

interface ILink {
    href: string;
    title: string;
}

export const helpUrl = 'https://help.mail.ru/mail';
const feedbackUrl = 'https://help.mail.ru/cloud_web/size/trouble/quota';

export const appCardsList: IAppCard[] = [
    {
        id: 'mail',
        title: 'Почта',
        text: isRebrandingQuotaLanding
            ? 'Письма, файлы и календарь всегда будут под рукой, а уведомления помогут не забыть о важном'
            : 'Отправляйте письма, читайте новости, делитесь файлами в новом суперприложении',
        appStoreLink: 'https://trk.mail.ru/c/u4t8i3',
        googlePlayLink: 'https://trk.mail.ru/c/kybd67',
        appGalleryLink: 'https://trk.mail.ru/c/rb1vs5',
        ruStoreLink: 'https://trk.mail.ru/c/sg33397',
    },
    {
        id: 'cloud',
        title: 'Облако',
        text: isRebrandingQuotaLanding
            ? 'Облако безопасно сохранит тысячи ваших файлов, упростит обмен и сэкономит память в телефоне'
            : 'Персональное хранилище, где ваши файлы находятся в безопасности',
        appStoreLink: 'https://trk.mail.ru/c/uuuqg3',
        googlePlayLink: 'https://trk.mail.ru/c/v24lm4',
        ruStoreLink: 'https://trk.mail.ru/c/it7po8',
        appGalleryLink: 'https://trk.mail.ru/c/io99c7',
    },
];

export const linkList: ILink[] = [
    {
        href: feedbackUrl,
        title: 'Обратная связь',
    },
    {
        href: helpUrl,
        title: 'Помощь',
    },
];
