import classNames from 'clsx';
import React, { type MouseEvent, type ReactElement, memo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { noop } from 'reactApp/utils/helpers';

import type { ITabMenuItem } from './TabMenu.types';
import styles from './TabMenuItem.css';

export const TabMenuItem = memo<ITabMenuItem>(
    ({
        name,
        badge,
        active,
        value,
        bottomLine,
        mod,
        color,
        isMobile,
        changeHash = true,
        onClick = noop,
        disabled,
        badgeHint = '',
        allowClickOnActive,
    }): ReactElement => {
        const location = useLocation();

        const handleClick = useCallback(
            (event: MouseEvent) => {
                if (!changeHash) {
                    event.preventDefault();
                }

                if (allowClickOnActive || !active) {
                    onClick(value);
                }
            },
            [active, changeHash, onClick, value, allowClickOnActive]
        );

        return (
            <a
                data-qa-id={name}
                href={`#${value}`}
                onClick={handleClick}
                className={classNames(styles.item, {
                    [styles.item_bottomLine]: bottomLine,
                    [styles.item_mobile]: isMobile,
                    [styles[`item_${mod}`]]: !!mod,
                    [styles[`item_color_${color}`]]: !!color,
                    [styles.active]: active,
                    [styles.disabled]: disabled,
                    [styles.action]: Boolean(ACTION_PROMO) && (!location.hash || location.hash === TABS_MAP.special),
                })}
            >
                <div className={styles.name}>{name}</div>
                {badge && (
                    <Hint text={badgeHint}>
                        <span className={styles.badge}>{badge}</span>
                    </Hint>
                )}
            </a>
        );
    }
);

TabMenuItem.displayName = 'TabMenuItem';
