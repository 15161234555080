import { isThemedSharedToobarItem, isThemedSharedToobarItemMore } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { put, select } from 'typed-redux-saga';

const CHANGE_PUBLIC_DESIGN_PROMO_TOOLBAR_ID = 'changePublicDesignPromoToolbar';

export function* initChangePublicDesignPromoToolbarInit() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    if (
        !isHome ||
        !(isThemedSharedToobarItem || isThemedSharedToobarItemMore) ||
        storeHelper.getValue(CHANGE_PUBLIC_DESIGN_PROMO_TOOLBAR_ID)
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.changePublicDesign,
            promoId: CHANGE_PUBLIC_DESIGN_PROMO_TOOLBAR_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.changePublicDesign));
                storeHelper.markAsShown(CHANGE_PUBLIC_DESIGN_PROMO_TOOLBAR_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.changePublicDesign));
            },
        })
    );
}
