import errorListFactory from 'Cloud/Application/ErrorList';
import config from 'Cloud/config';
import escapeForHtml from 'lib/escapeForHtml';
import { renderNewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal.helpers';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';

const errors = errorListFactory.createErrorList({
    exists: 'Папка с таким названием уже существует. Попробуйте другое название',
    invalid:
        '«{FOLDER_NAME}» это неправильное название папки. В названии папок нельзя использовать символы «{INVALID_CHARACTERS}». Также название не может состоять только из точки «.» или из двух точек «..»',
    name_too_long: 'Ошибка: Превышена длина имени папки. {HELP_LINK_NAME_CONFINES}',
    read_only: 'Невозможно создать. Доступ только для просмотра',
    required: 'Название папки не может быть пустым',
    unknown: 'Ошибка на сервере',
});

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export const openNewFolderDialogAndCreate = ({
    parentFolder,
    storageName,
    folderCreateApi,
    noDimmer = false,
    showShareButton = true,
    showCreateButton = true,
}): Promise<{ result: boolean; data?: any }> => {
    const state = store.getState();
    const { isFull: isOverQuota } = UserSelectors.getCloudSpace(state);

    if (isOverQuota && !UserSelectors.isCorpUser(state)) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFolder });
        return Promise.resolve({ result: false });
    }

    return new Promise((resolve) => {
        const onSuccess = (data) => {
            resolve({ result: true, data });
        };

        const onClose = () => {
            resolve({ result: false });
        };

        const createFolder = (folderName) => {
            folderName = folderName.trim();

            if (!folderName) {
                // @ts-ignore
                return Promise.reject(errors.required);
            }

            if (folderName.length > MAX_NAME_LENGTH) {
                // @ts-ignore
                return Promise.reject(errors.name_too_long);
            }

            // eslint-disable-next-line promise/param-names
            return new Promise((_, reject) =>
                folderCreateApi(folderName, parentFolder, undefined, undefined, storageName)
                    .then((newFolderName) => {
                        closePopupHelper(popupNames.CREATE_FOLDER);
                        resolve({ result: true, data: { folderName: newFolderName } });
                    })
                    .catch(function (error) {
                        reject(
                            errors.getMessage(error, {
                                '{FOLDER_NAME}': escapeForHtml(folderName),
                            })
                        );
                    })
            );
        };

        renderNewFolderModal({
            onSuccess,
            onClose,
            createFolder,
            noDimmer,
            showShareButton,
            showCreateButton,
        });
    });
};
