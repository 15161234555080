import type { EDocumentType } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

export enum EFileStatus {
    'NONE' = 'none',
    'PROGRESS' = 'progress',
    'DONE' = 'done',
    'CANCEL' = 'cancel',
    'DELETING' = 'deleting',
    'SKIP' = 'skip',
    'ERROR' = 'error',
    'WARNING' = 'warning',
    'INFO' = 'info',
    'PAUSED' = 'paused',
    'MOVED' = 'moved',
}

export enum EFileError {
    'FILE_EXIST' = 'file_exist',
    'OVER_QUOTA_CLOUD' = 'over_quota_cloud',
    'OVER_QUOTA_CLOUD_B2B' = 'over_quota_cloud_b2b',
    'OVER_QUOTA_CLOUD_AT_OWNER' = 'over_quota_cloud_at_owner',
    'OVER_QUOTA_LIMIT_DOWNLOAD' = 'over_quota_limit_download',
    'OVER_QUOTA' = 'over_quota',
    'IGNORED_FILE' = 'ignored_file',
    'SKIPPED_FILE' = 'skipped_file',
    'CANCELLED_FILE' = 'cancelled_file',
    'UNKNOWN' = 'unknown',
    'NAME_TOO_LONG' = 'name_too_long',
    'LOCAL_FILE_NOT_FOUND' = 'local_file_not_found',
    'LOCAL_FILE_READ_ERROR' = 'local_file_read_error',
    'CONNECTION_ERROR' = 'connection_error',
    'GATEWAY_ERROR' = 'gateway_error',
    'READ_ONLY_DIRECTORY' = 'read_only_directory',
    'INVALID_CHARACTERS' = 'invalid_characters',
    'USER_FILE_SIZE_LIMIT' = 'user_file_size_limit',
    'USER_FILE_SIZE_LIMIT_OVER_100_GB' = 'user_file_size_limit_over_100_gb',
    'FILE_TOO_BIG' = 'file_too_big',

    'NAME_TOO_LONG_AUTO_FIX' = 'name_too_long_auto_fix',
    'INVALID_CHARACTERS_AUTO_FIX' = 'invalid_characters_auto_fix',

    'ERROR_IN_FOLDER' = 'error_in_folder',
    'INFO_IN_FOLDER' = 'info_in_folder',

    'VIOLATED_FILENAME' = 'violated_file_name',
}

export enum EProgressStatus {
    NONE = 'none',
    PAUSE = 'pause',
    CANCEL_ALL = 'cancel_all',
    PROGRESS = 'progress',
    COMPLETE = 'complete',
    STARTING = 'starting',
}
export interface IChangeItem {
    isFolder: boolean;
    id: string;
}

export interface INormalizedInputFile {
    [id: string]: IInputFile;
}

interface ICommonProgress {
    progress: {
        percentLoaded: number;
        uploadPacket: number;
        status: EProgressStatus;
        uploadTime?: number;
        startTime?: number | null;
    };
}

export interface INeedScroll {
    cloudPath: string;
    needScroll: boolean;
}

export interface ISelectedError {
    fileId: string;
    file?: IInputFile;
    userClick?: boolean;
}

export interface IGetInputFile {
    cloudPath?: string;
    descriptorId?: string;
}

export interface IUpdateInputFile {
    cloudPath?: string;
    descriptorId?: string;
    folderCloudPath?: string;
    size?: number;
    uploadedSize?: number;
    progress?: number; // прогресс в процентах
    loaded?: number; // прогресс в байтах
    currentUpload?: boolean;
    publish?: boolean;
    status?: EFileStatus;
    error?: EFileError | '';
    hideError?: boolean;
    highlight?: boolean;
    hasAutoFix?: {
        hasInvalidCharAutoFix?: boolean;
        hasNameTooLongAutoFix?: boolean;
    };
    documentType?: EDocumentType;
    thumb?: string;
    timeRemain?: number;
}

export interface IUpdateInputEmptyFolder extends IUpdateInputFile {
    name: string;
    localPath: string;
    extension: string;
}

export interface IInputFile extends IUpdateInputFile {
    cloudPath: string;
    name: string;
    localPath: string;
    thumb: string;
    extension: string;
    descriptorId: string;
    uploadPacket: number; // указываем, к какой пачке загруженных файлов относится файл
    files?: INormalizedInputFile;
}

export interface IUploadListState extends ICommonProgress {
    showUploader: boolean;
    hasInput: boolean;
    groupedFiles: INormalizedInputFile;
    groupedFilesById: INormalizedInputFile;
    errors: {
        selectedFileId: string;
        highlightSelectedFile: boolean;
        userFileSizeLimit: number;
        userClick?: boolean;
    };
    totalCount: number;
    totalSize: number;
    speedMbSec: number;
    smartUploadedBytes: number;
    hasConnectionError: boolean;
    hideInformers: EInfoTypes[];
    hasNotAllowedExtensions: boolean;
}

export interface ICountStatistic {
    successCount: number;
    commonCount: number;
    autoFixCount: number;
    errorCount: number;
    ignoredCount: number;
    fileExistCount: number;
    leftCount: number;
    totalCount: number;
}

export interface ITotalProgress {
    speedMbSec: number;
}

export enum EInfoTypes {
    problemFile = 'problemFile',
    networkRestored = 'networkRestored',
    complete = 'complete',
    lowSpeed = 'lowSpeed',
}

export interface IUploaderInformerItem {
    id: string;
    type: EInfoTypes;
    file?: IInputFile;
}
