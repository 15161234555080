import React, { memo, useCallback, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { type TAskUserConflictOptions, askUserForConflictFiles } from 'reactApp/modules/uploadList/oldUploadListBridge';
import type { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import styles from './PopupWarnings.css';

interface IProps {
    file: IInputFile;
    isPhone?: boolean;
}

const nameLength = 50;

export const OverWriteComponent = memo(({ file, isPhone = false }: IProps) => {
    const [checked, setChecked] = useState(false);

    const toggleApply = useCallback(() => {
        setChecked(!checked);
    }, [checked]);

    const askUserForConflict = useCallback(
        (option: TAskUserConflictOptions) => {
            askUserForConflictFiles({ option, saveForAll: checked, id: file.descriptorId });
        },
        [checked]
    );

    const handleReplaceFile = () => {
        askUserForConflict('rewrite');
    };

    const handleSkipFile = () => {
        askUserForConflict('skip');
    };

    const handleRenameFile = () => {
        askUserForConflict('rename');
    };

    const renderHeader = useCallback(() => <div>Ошибка при загрузке файла</div>, []);

    const renderContent = useCallback(() => {
        const fileName = `${getTrimmedText(file.name, nameLength)}.${file.extension}`;

        return (
            <div className={styles.content}>
                <div>
                    Файл с названием{' '}
                    <span className={styles.bold} title={`${file.name}.${file.extension}`}>
                        {fileName}
                    </span>{' '}
                    уже существует. Заменить его?
                </div>
                <div className={styles.applyToAll} onClick={toggleApply}>
                    <Checkbox selected={checked} mod="blue" />
                    <div className={styles.text}>Применить ко всем загружаемым файлам</div>
                </div>
            </div>
        );
    }, [file.name, file.extension, toggleApply]);

    if (isPhone) {
        return (
            <MobileDialog
                mod="mobileV2"
                id="name-conflict-dialog"
                contextPadding={24}
                closable
                dimmer
                open
                topmost
                title={renderHeader()}
                text={renderContent()}
                onClose={handleSkipFile}
                actions={[
                    {
                        text: 'Заменить',
                        id: 'replace',
                        primary: true,
                        onClick: handleReplaceFile,
                    },
                    {
                        text: 'Переименовать',
                        id: 'rename',
                        onClick: handleRenameFile,
                    },
                    {
                        text: 'Пропустить',
                        id: 'skip',
                        onClick: handleSkipFile,
                    },
                ]}
            />
        );
    }

    return (
        <BaseConfirmDialog
            dataQAId="name-conflict-dialog"
            renderHeader={renderHeader}
            successButtonText="Заменить"
            actionButtonText="Переименовать"
            cancelButtonText="Пропустить"
            buttons={[EButtonType.accept, EButtonType.action, EButtonType.cancel]}
            onSuccess={handleReplaceFile}
            onAction={handleRenameFile}
            onClose={handleSkipFile}
            renderContent={renderContent}
            className={styles.root}
        />
    );
});

OverWriteComponent.displayName = 'OverWriteComponent';
