import { Spacing, Text, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/cancel-autorenew-10tb.jpg';
import coverImageMobile from 'img/subscriptions/cancel-autorenew-10tb-mobile.jpg';
import coverImageMobileDark from 'img/subscriptions/cancel-autorenew-10tb-mobile-dark.jpg';
import coverImageMobileRebranding from 'img/subscriptions/cancel-autorenew-10tb-mobile-rebranding.jpg';
import coverImageMobileRebrandingDark from 'img/subscriptions/cancel-autorenew-10tb-mobile-rebranding-dark.jpg';
import coverImageRebranding from 'img/subscriptions/cancel-autorenew-10tb-rebranding.jpg';
import coverImageRebrandingDark from 'img/subscriptions/cancel-autorenew-10tb-rebranding-dark.jpg';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRenewTrialPeriod, isRebranding, isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './CancelAutoRenew10TbModal.css';
import type { CancelAutoRenew10TbModalProps } from './CancelAutoRenew10TbModal.types';

export const CancelAutoRenew10TbModal = memo<CancelAutoRenew10TbModalProps>(({ onClose, subscription }): ReactElement => {
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);
    const dispatch = useDispatch();
    const isMobile = EnvironmentSelectors.isMobile();
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDarkMode = document.body.classList.contains(VK_UI_DARK_THEME);
    const storage = useSelector(getCurrentStorage);
    const isRebrandingQuota = isRebrandingQuotaLanding && [EStorageType.quotaTariffs, EStorageType.quotaPartner].includes(storage);

    let mobileImage = isDarkMode ? coverImageMobileDark : coverImageMobile;

    let image = coverImage;

    if (isRebranding) {
        image = isDarkMode ? coverImageRebrandingDark : coverImageRebranding;
        mobileImage = isDarkMode ? coverImageMobileRebrandingDark : coverImageMobileRebranding;
    }

    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showCancel10Tb);
    }, []);

    const content = useMemo(
        (): ReactElement => (
            <div className={styles.content}>
                <Spacing size={16} />
                <Text className={styles.text}>
                    Без подписки Mail Space дополнительное место на фото и видео, которое вы получили во время акции, пропадёт
                </Text>
                <Spacing size={24} />
            </div>
        ),
        []
    );

    const handleKeepAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.notCancel10Tb);
        onClose?.();
    }, [onClose, sendAnalytics]);

    const handleCancelAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.cancel10tbCancel);

        // tempexp_13984-start
        if (abWithCaptcha === 'b') {
            onClose?.();
            renderCancelSubscriptionWithCaptchaModal({ subscriptionId: subscription.id, isRebrandingQuotaLanding: isRebrandingQuota });
            return;
        }
        // tempexp_13984-end

        // tempexp_16480-next-line
        if (cancelRenewTrialPeriod) {
            dispatch(
                BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial({
                    subscriptionId: subscription.id,
                    sendAnalytics,
                    period: cancelRenewTrialPeriod,
                })
            );
        } else {
            dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id, sendAnalytics }));
        }

        onClose?.();
    }, [onClose, dispatch, abWithCaptcha, sendAnalytics, subscription, isRebrandingQuota]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    // Не стал выносить в отдельный компонент мобильную шторку тк пришлось бы копипастить много логики колбеков.
    if (isMobile) {
        return (
            <MobileDialog
                id="cancel-autorenew-10tb-modal-mobile"
                onClose={handleClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="base"
                contextPadding="zero"
                isRebrandingQuotaLanding={isRebrandingQuota}
            >
                <div ref={scrollRef} className={styles.rootMobile}>
                    <img src={mobileImage} alt="10 ТБ для телефона — только в подписке" className={styles.image} />
                    <Spacing size={24} />
                    <Title level="1" className={styles.title}>
                        10 ТБ для телефона —
                        <br />
                        только в подписке
                    </Title>
                    <Spacing size={12} />
                    <Text className={styles.text}>
                        Без подписки Mail Space дополнительное место на фото и видео, которое вы получили во время акции, пропадёт
                    </Text>
                    <Spacing size={24} />
                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary onClick={handleKeepAutoRenew} sizeMode={ButtonSizeMode.big}>
                            Сохранить подписку
                        </Button>
                        <Spacing size={12} />
                        <Button theme="vk" onClick={handleCancelAutoRenew} sizeMode={ButtonSizeMode.big}>
                            Отключить
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            onClose={handleClose}
            onClick={handleKeepAutoRenew}
            onSecondary={handleCancelAutoRenew}
            primaryButtonText="Сохранить подписку"
            secondaryButtonText="Отключить"
            title="10 ТБ для телефона — только в подписке"
            content={content}
            imageUrl={image}
            imageBgColor="var(--vkui--color_background)"
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="cancel-autorenew-10tb-modal"
        />
    );
});

CancelAutoRenew10TbModal.displayName = 'CancelAutoRenew10TbModal';
