import logoMyOffice from 'img/icons/editor/MyOffice_Logo-T_W.svg?url';
import { ReactComponent as CloseIcon } from 'mrg-icons/src/mailru/actions/close_big.svg';
import React, { type HTMLProps, type ReactEventHandler, type VFC, useEffect } from 'react';
import { ReactComponent as AdIcon } from 'reactApp/ui/MyOfficePromo/Ad.svg';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { sendXray } from 'reactApp/utils/ga';

import styles from './ViewerSnackbar.css';

interface IViewerSnackbarProps extends Omit<HTMLProps<HTMLDivElement>, 'className' | 'onClick'> {
    onClickClose: ReactEventHandler<HTMLButtonElement>;
    onClickAd: ReactEventHandler<HTMLDivElement>;
    onClick: ReactEventHandler<HTMLDivElement>;
    showPromoRating?: boolean;
    radarName: string;
    fileExt?: string;
}

export const ViewerSnackbar: VFC<IViewerSnackbarProps> = ({
    radarName,
    onClickClose,
    onClickAd,
    onClick,
    showPromoRating,
    fileExt = '',
    ...htmlDivProps
}) => {
    useEffect(() => {
        sendXray(radarName, { show: 1 });
        sendMyOfficeMetric(MyOfficeBusinessMetric.mention, { i: { [fileExt]: 1 } });
    }, [radarName]);

    const onClickWithRadar: ReactEventHandler<HTMLDivElement> = (e) => {
        sendXray(radarName, { click: 1 });
        sendMyOfficeMetric(MyOfficeBusinessMetric.openPartner, { i: { [fileExt]: 1 } });
        onClick?.(e);
    };

    return (
        <div {...htmlDivProps} className={styles.snackbar}>
            <div className={styles.snackbarLogo}>
                <img src={logoMyOffice} height={28} alt="Мой Офис" />
            </div>
            <div className={styles.snackbarText} onClick={onClickWithRadar}>
                Установить редакторы
            </div>
            <div className={styles.snackbarTextSmallScreen} onClick={onClickWithRadar}>
                Установить
            </div>
            <button
                onClick={(e) => {
                    onClickClose(e);
                    sendXray(radarName, { close: 1 });
                    e.stopPropagation();
                }}
                className={styles.snackbarButton}
            >
                <CloseIcon fill="var(--vkui--color_background)" />
            </button>
            {showPromoRating && (
                <div
                    className={styles.adIcon}
                    onClick={(e) => {
                        onClickAd(e);
                        sendXray(radarName, { 'click-ad': 1 });
                        e.stopPropagation();
                    }}
                >
                    <AdIcon />
                </div>
            )}
        </div>
    );
};
