import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';

import type { InitSortAction, ISortState, SetSortAction } from './sort.types';

const initialState: ISortState = {};

export const initSort = createAction('sort/initSort');
export const initSortSuccess = createAction<InitSortAction>('sort/initSortSuccess');
export const setSort = createAction<SetSortAction>('sort/setSort');

export const sortReducer = createReducer(initialState, {
    [initSortSuccess.type]: (state, action: PayloadAction<InitSortAction>) => {
        return action.payload;
    },
    [setSort.type]: (state, action: PayloadAction<SetSortAction>) => {
        state[action.payload.id] = action.payload.sort;
    },
});
