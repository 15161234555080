import { createAction, createReducer } from '@reduxjs/toolkit';
import { USER } from 'reactApp/appHelpers/configHelpers';
import type { ApiProfile, Profile, ProfileOverquota } from 'reactApp/modules/profile/profile.types';

const initialState: Profile = {
    email: '',
    checkUpdateLicenseCompleted: false,
    overquota: {
        state: USER?.cloud?.profile?.overquota?.state,
        blockTime: (USER?.cloud?.profile?.overquota?.block_time || 0) * 1000,
        clearTime: (USER?.cloud?.profile?.overquota?.clear_time || 0) * 1000,
    },
    version: '',
};

export const loadProfileRequest = createAction('@profile/clearError');
export const loadProfileSuccess = createAction<{ profile: ApiProfile }>('@profile/loadProfileSuccess');
export const loadProfileFailure = createAction('@profile/loadProfileFailure');
export const checkUpdateLicenseRequest = createAction('@profile/user-la/request');
export const agreeUpdateLicenseRequest = createAction<{ source: string; isUpdate?: boolean }>('@profile/agree-la/request');
export const checkUpdateLicenseCompleted = createAction('profile/checkUpdateLicenseCompleted');
export const setProfileOverquotaState = createAction<ProfileOverquota & { version?: string }>('profile/setOverquotaState');
export const setProfileUblockUser = createAction('profile/setProfileUblockUser');
export const setClearedUserProcessed = createAction('profile/setClearedUserProcessed');

export const profileReducer = createReducer(initialState, {
    [loadProfileSuccess.type]: (state, action: ReturnType<typeof loadProfileSuccess>): void => {
        const { profile } = action.payload;

        const { properties, email } = profile;

        state.email = email;
        state.overquota = {
            clearTime: (properties['overquota.clear_time'] || 0) * 1000,
            clearStarted: (properties['overquota.clear_started'] || 0) * 1000,
        };
    },
    [checkUpdateLicenseCompleted.type]: (state): void => {
        state.checkUpdateLicenseCompleted = true;
    },
    [setProfileOverquotaState.type]: (state, action: ReturnType<typeof setProfileOverquotaState>) => {
        state.overquota = {
            ...state.overquota,
            ...action.payload,
        };
        if (action.payload.version) {
            state.version = action.payload.version;
        }
    },
});
