import config from 'Cloud/config';
import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTrashbinSelectiveActive } from 'reactApp/appHelpers/featuresHelpers/features/tashbinSelectiveDeletion';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { groupedIds } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { binClearRequest, deleteFilesFromBinRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { getOpenedBin } from 'reactApp/modules/trashbin/trashbin.selectors';
import type { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';
import { declOfNum, isFile } from 'reactApp/utils/helpers';

import type { IProps } from './EmptyTrashBin.types';

const isBizUser = config.get('BIZ_USER');

const sendGa = createGaSender(`trashbin-empty${isBizUser ? '_biz' : ''}`);

export const EmptyTrashBin = React.memo(function EmptyTrashBin(props: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const bin = useSelector(getOpenedBin);

    const selected = useSelector(SelectionsSelectors.getSelectedIdxs);

    const items = useSelector((state: RootState) => groupedIds(state, EStorageType.trashbin)) as string[];

    const dispatch = useDispatch();

    const onConfirm = useCallback(() => {
        setLoading(true);

        if (isTrashbinSelectiveActive && selected.length && selected.length !== items.length) {
            dispatch(deleteFilesFromBinRequest({ selected }));
        } else {
            dispatch(binClearRequest({ id: bin?.id || '', tree: bin?.tree || '' }));
        }

        sendGa('confirm');
        props.onClose();
    }, [bin?.id, bin?.tree, dispatch, props]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose();
    }, [props]);

    const renderHeader = useCallback(
        function () {
            if (isTrashbinSelectiveActive && selected.length && selected.length !== items.length) {
                const files = selected.filter((item) => isFile(item));
                const foldersCount = selected.length - files.length;

                let res = 'Удалить ';

                if (files.length > 0) {
                    res += `${files.length} ${declOfNum(files.length, ['файл', 'файла', 'файлов'])}`;
                }

                if (files.length > 0 && foldersCount > 0) {
                    res += ' и ';
                }

                if (foldersCount > 0) {
                    res += `${foldersCount} ${declOfNum(foldersCount, ['папку', 'папки', 'папок'])}`;
                }

                res += ' из корзины?';

                return <>{res}</>;
            }

            return <>Удалить все файлы в корзине?</>;
        },
        [selected]
    );

    const renderContent = useCallback(() => {
        if (isTrashbinSelectiveActive && selected.length && selected.length !== items.length) {
            const files = selected.filter((item) => isFile(item));
            const foldersCount = selected.length - files.length;

            let res = '';
            if (files.length > 0 && foldersCount > 0) {
                res = 'их';
            } else if (files.length > 0) {
                res = declOfNum(selected.length, ['его', 'их', 'их']);
            } else if (foldersCount > 0) {
                res = declOfNum(selected.length, ['её', 'их', 'их']);
            }

            return <>После этого {res} нельзя будет восстановить</>;
        }

        return <div>После этого их нельзя будет восстановить</div>;
    }, []);

    useEffect(() => sendGa('show'), []);

    return (
        <BaseConfirmDialog
            dataQAId="empty-trashbin-dialog"
            renderHeader={renderHeader}
            isNegative
            actionButtonText="Удалить навсегда"
            cancelButtonText="Не удалять"
            buttons={[EButtonType.action, EButtonType.cancel]}
            onClose={onClose}
            onAction={onConfirm}
            renderContent={renderContent}
            loading={loading}
            closeOnDimmerClick
        />
    );
});
