import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import styles from './BindingSpherum.css';

interface IProps {
    primaryButtonText: ReactNode;
    primaryButtonHref?: string;
    primaryButtonColor?: 'black';
    closeByPrimaryButton?: boolean;
    onShow?(): void;
    onClose?(): void;
    onClick?(): void;
    actionButtonText?: string;
    onActionClick?(): void;
    onCloseButtonClick?(): void;
    title: string;
    text?: string | ReactElement;
    content?: ReactElement;
    imageUrl: string;
    imageBgColor?: string;
    imageFluid?: boolean;
    closable?: boolean;
    footerText?: ReactElement;
}

export const BindingSpherum = memo(function BindingSpherum({
    primaryButtonText,
    primaryButtonHref,
    onClick = noop,
    onClose = noop,
    onShow = noop,
    title,
    text,
    content,
    closable = true,
    imageUrl,
    imageBgColor,
    imageFluid = true,
    closeByPrimaryButton = false,
    actionButtonText,
    onActionClick = noop,
    footerText,
    primaryButtonColor,
    onCloseButtonClick = noop,
}: IProps): ReactElement {
    const [open, setOpen] = useState(true);
    useEffect(() => onShow(), []);

    const onPrimaryHandler = useCallback(() => {
        onClick();
        if (closeByPrimaryButton) {
            setOpen(false);
        }
        if (primaryButtonHref) {
            opener(primaryButtonHref, true);
        }
    }, [onClick, primaryButtonHref, closeByPrimaryButton]);

    const onCloseHandler = useCallback(() => {
        onCloseButtonClick?.();
        onClose?.();
    }, [onClose, onCloseButtonClick]);

    useEffect(() => {
        if (!open) {
            onClose?.();
        }
    }, [
        /** onClose */
        open,
    ]);

    return (
        <Dialog
            open={open}
            header={''}
            closeOnDimmerClick={false}
            id="bind-spherum-dlg"
            onCancel={onCloseHandler}
            size="large"
            bigCloseIcon
            closable={closable}
            className={styles.root}
        >
            <Content isModal>
                <div className={styles.content}>
                    <div
                        className={styles.imageWrapper}
                        style={{
                            backgroundColor: imageBgColor,
                        }}
                    >
                        <img
                            className={classNames(styles.image, {
                                [styles.image_fluid]: imageFluid,
                            })}
                            src={imageUrl}
                            alt="Cover image"
                        />
                    </div>
                    <div className={styles.title}>{title}</div>
                    {text && <div className={styles.text}>{typeof text === 'string' ? text.replace(/\\n/g, '\n') : text}</div>}
                    {Boolean(content) && content}

                    <div className={styles.footerWrapper}>
                        {footerText}
                        <div className={styles.buttonWrapper}>
                            {actionButtonText && (
                                <Button data-name="freeze-renewal" theme="octavius" primary middle onClick={onActionClick}>
                                    {actionButtonText}
                                </Button>
                            )}
                            {primaryButtonText && (
                                <Button
                                    onClick={onPrimaryHandler}
                                    data-name="success"
                                    theme="octavius"
                                    primary={!actionButtonText}
                                    secondary={Boolean(actionButtonText)}
                                    middle
                                    className={classNames({ [styles[`button_${primaryButtonColor}`]]: !!primaryButtonColor })}
                                >
                                    {primaryButtonText}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Dialog>
    );
});
