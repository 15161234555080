import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import type { WelcomeTrialParams } from 'reactApp/appHelpers/featuresHelpers/features.types';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from '../WelcomeNewPromo.css';
import { useContent } from './useContent';

interface TrialBlockProps {
    data: WelcomeTrialParams;
    forQuota?: boolean;
}

export const TrialBlock = memo(({ data, forQuota }: TrialBlockProps) => {
    const { productId, bannerName, infoOldStyle = false, useMonthPrice = false } = data;

    const onClick = useCallback(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'click', name_banner: bannerName });
        emitAnalyticEvent(AnalyticEventNames.WELCOME_TRIAL_BANNER_CLICK);
        openVkBuyModalWithSidebar({
            productId,
            type: EPaymentModalType.trial,
            options: {
                dmrWithPaddings: !infoOldStyle,
                sidebarNewContent: !infoOldStyle,
            },
            paySource: 'welcome-trial',
            onSuccess: () => {
                emitAnalyticEvent(AnalyticEventNames.WELCOME_TRIAL_BANNER_BUY);
            },
        });
    }, [productId, bannerName]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'view', name_banner: bannerName });
        emitAnalyticEvent(AnalyticEventNames.WELCOME_TRIAL_BANNER_SHOW);
    }, []);

    const content = useContent({ productId, infoOldStyle, useMonthPrice, forQuota: Boolean(forQuota) });
    if (!content) {
        return null;
    }
    const { space, units, ctaText, info } = content;

    return (
        <div className={classNames(styles.block, { [styles.block_quota]: forQuota })} data-qa-id="trial">
            <Title className={styles.tariff_text} level="2">
                Увеличьте хранилище
            </Title>
            {isRebranding ? (
                <>
                    <Spacing size={22} />
                    <div className={styles.tariff_block}>
                        <div className={styles.tariff}>
                            {`+${space}`}
                            <span>{units}</span>
                        </div>
                        <Spacing size={36} className={classNames({ [styles.fillFreeSpace]: !infoOldStyle && !forQuota })} />
                        <div>
                            <Button className={styles.tariff_button} data-name="welcome-trial" theme="vk" primary onClick={onClick} fluid>
                                <Text>{ctaText}</Text>
                            </Button>
                        </div>
                    </div>
                    <Spacing size={23} />
                </>
            ) : (
                <>
                    <Spacing size={forQuota ? 54 : 38} />
                    <div className={styles.tariff}>
                        {space}
                        <span>{units}</span>
                    </div>
                    <Text className={styles.tariff_text}>{forQuota ? 'Чтобы места хватило на все' : 'Места хватит на всё'}</Text>
                    <Spacing size={forQuota ? 24 : 38} className={classNames({ [styles.fillFreeSpace]: !infoOldStyle && !forQuota })} />
                    <div>
                        <Button data-name="welcome-trial" theme="vk" primary onClick={onClick} fluid>
                            <Text className={styles.tariff_button}>{ctaText}</Text>
                        </Button>
                    </div>
                    <Spacing size={forQuota ? 16 : 12} />
                </>
            )}
            <Text className={infoOldStyle ? styles.tariff_price : styles.tariff_price_new}>{info}</Text>
        </div>
    );
});

TrialBlock.displayName = 'TrialBlock';
