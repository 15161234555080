/* eslint-disable max-lines */
import { type PayloadAction, createReducer } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { isNil, path } from 'ramda';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { getExtension, getParent, isFolder, isMedia } from 'reactApp/modules/file/utils';
import { isApiFolderType, normalizeFile, normalizeFolder, normalizeItem } from 'reactApp/modules/home/home.helpers';
import type {
    ApiFolderResponse,
    ApiFolderResponseFile,
    FilesState,
    HomeFolder,
    HomeItem,
    MediaStatistics,
    NormalizeFunc,
} from 'reactApp/modules/home/home.types';
import { mountFolderSuccess } from 'reactApp/modules/incoming/incoming.module';
import {
    addFilesSuccess,
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    shareFolderSuccess,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    unshareFolderSuccess,
    updateFolder,
    updateItem,
    updateWeblinkAutoDelete,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import type {
    AddFileSuccessAction,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    IShareFolderSuccess,
    IUnshareFolderSuccess,
    IWeblinkSetDomainAccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateItemAction,
    UpdateWeblinkAction,
    UpdateWeblinkAutoDelete,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setTreeAction } from 'reactApp/modules/tree/tree.actions';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { AccessRights } from 'reactApp/types/Tree';
import { showcaseController } from 'reactApp/ui/Showcase/Showcase.controller';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import {
    addHomeItemsToStore,
    cameraUploadsStatisticsSuccess,
    homeStatisticsSuccess,
    loadFolderFail,
    loadFolderStart,
    loadFolderSuccess,
    loadMoreHomeRequest,
    loadMoreSuccess,
    setDomainFoldersFilterActive,
} from './home.actions';

const initialState: FilesState = {
    list: {},
    homeStatistics: null,
    cameraUploadsStatistics: null,
};

const urlBuilder = new UrlBuilder();

const normalize: NormalizeFunc = ({ state, item, loaded, parent, isLoading, keepPreviousChilds }) => {
    const normalizedItem = normalizeItem(item, parent, state.intraDomainShare);

    const { id, parent: parentId } = normalizedItem;

    if (isApiFolderType(item)) {
        const folder = normalizeFolder({ normalizedItem, item, isLoaded: !!loaded });

        if (showcaseController.isFolderForShowcase(folder)) {
            return;
        }

        folder.isLoading = !!isLoading;

        const oldParentFolder = state.list[id];
        if (oldParentFolder?.isFolder) {
            oldParentFolder?.childs.forEach((childId) => {
                const child = state.list[childId];
                // Продолжаем загрузку в случае наличия дескриптора и флага, что загрузка завершена
                // После перезагрузки страницы и его надо сохранить после запроса в апи
                if ((child?.descriptorId && !folder.childs.find((id) => id === childId)) || keepPreviousChilds) {
                    folder.childs.push(childId);
                }
            });
        }

        state.list[id] = folder;

        (item.list || []).forEach((item) => {
            if (showcaseController.isFolderForShowcase(item)) {
                return;
            }

            const parentFolder = state.list[id];
            const childId = item.home;

            // includes проверяем на случай продолжения загрузки папки
            if (parentFolder?.isFolder && !parentFolder.childs?.includes(childId)) {
                parentFolder.childs.push(childId);
            }

            const cachedItem = state.list[childId];

            if (!cachedItem || !cachedItem.isFolder || !cachedItem.isLoaded) {
                normalize({ state, item, parent: id, keepPreviousChilds: true });
            }

            state.intraDomainShare ||= !!item.intra_domain_share;
        });
    } else {
        const parent = state.list[parentId] as HomeFolder;
        const file = normalizeFile({ normalizedItem, item });

        if (file.author && parent) {
            if (!parent.authors) {
                parent.authors = {};
            }

            parent.authors[file.author] = 1 + (parent.authors?.[file.author] || 0);
        }

        state.list[id] = file;
    }
};

const loadStartHandler = (state, action: PayloadAction<{ id: string }>): void => {
    const { id } = action.payload;

    const folder = state.list[id];
    if (folder?.isFolder) {
        folder.isLoading = true;
        folder.isLoaded = false;
    }
};

export const homeReducer = createReducer(initialState, {
    [setTreeAction.type]: (state, action: ReturnType<typeof setTreeAction>) => {
        const { tree } = action.payload;

        if (!tree.length || !tree[0].home) {
            return state;
        }

        tree.forEach((item) => {
            const itemInStore = state.list[item.home];
            if (!itemInStore || (itemInStore.isFolder && itemInStore.childs?.length !== item.list?.length && item.list?.length > 0)) {
                normalize({
                    state,
                    item,
                    loaded: false, // в серверном дереве нет всех полей, поэтому идем дополнительно в v4
                });
            }
        });
    },
    [loadFolderStart.type]: loadStartHandler,
    [loadFolderFail.type]: (state, action: PayloadAction<{ id: string; error: string }>): void => {
        const folder = state.list[action.payload.id];
        if (folder?.isFolder) {
            folder.isLoading = false;
            folder.error = action.payload.error ?? 'error';
        }
    },
    [loadFolderSuccess.type]: (state, action: PayloadAction<ApiFolderResponse>): void => {
        normalize({ state, item: action.payload, loaded: true, parent: '' });
    },
    // eslint-disable-next-line sonarjs/no-identical-functions
    [loadMoreHomeRequest.type]: loadStartHandler,
    [loadMoreSuccess.type]: (state, action: PayloadAction<ApiFolderResponse>): void => {
        const { payload } = action;
        const id = payload.home;
        const folder = state.list[id];

        if (folder && isApiFolderType(payload) && folder.isFolder && payload.list) {
            folder.count = {
                files: payload.count.files,
                folders: payload.count.folders,
                all: payload.count.files + payload.count.folders,
                loaded: (folder?.count?.loaded || 0) + payload.list.length,
            };
            folder.isLoading = false;
            folder.isLoaded = true;

            folder.hasMoreToLoad = folder.count.all > folder.count.loaded;

            const childIdxs = {};
            folder.childs?.forEach((child) => {
                childIdxs[child] = true;
            });

            payload.list.forEach((item) => {
                if (!childIdxs[item.home]) {
                    folder.childs.push(item.home);
                }
                normalize({ state, item, parent: id });
            });
        }
    },
    [homeStatisticsSuccess.type]: (state, action: PayloadAction<MediaStatistics>) => {
        return {
            ...state,
            homeStatistics: {
                size: action?.payload?.size || null,
                videos: action?.payload?.videos || null,
                photos: action?.payload?.photos || null,
            },
        };
    },
    [cameraUploadsStatisticsSuccess.type]: (state, action: PayloadAction<MediaStatistics>) => {
        return {
            ...state,
            cameraUploadsStatistics: {
                size: action?.payload?.size || null,
                videos: action?.payload?.videos || null,
                photos: action?.payload?.photos || null,
            },
        };
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>): void => {
        const { weblink, id } = action.payload;

        if (state.list[id]) {
            state.list[id].weblink = weblink;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }
        });
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id];
        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;

        if (state.list[id]) {
            state.list[id].count_downloads_total = undefined;
            state.list[id].count_downloads_left = undefined;
        }
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkExpires = expires;
            state.list[id].expiresTimeSize = action.payload.expiresTimeSize;
        }
    },
    [updateWeblinkAutoDelete.type]: (state, action: PayloadAction<UpdateWeblinkAutoDelete>): void => {
        const { id, autoDelete } = action.payload;
        const item = state.list[id];

        if (item && item.isFolder) {
            item.weblinkAutoDelete = autoDelete;
        }
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDomestic = domestic;
        }
    },
    [weblinkSetDomainAccess.type]: (state, action: PayloadAction<IWeblinkSetDomainAccess>): void => {
        const { domain, itemId } = action.payload;

        if (state.list[itemId]) {
            state.list[itemId].weblinkDomainAccess = domain;
        }
    },
    [updateItem.type]: (state, action: PayloadAction<UpdateItemAction>) => {
        const { payload } = action;

        const item = state.list[payload.home];

        if (item) {
            item.kind = payload.kind;

            if (!isNil(payload.size)) {
                item.size = payload.size;
            }
            if (!item.isFolder) {
                const { kind } = extInfo.get(item.ext);
                item.kind = kind;
                item.mtime = normalizeMtime(payload.mtime);
                item.hash = payload.hash || item.hash;
                item.thumbnails = urlBuilder.getThumb({
                    hash: item.hash,
                    ext: item.ext,
                    id: item.id,
                    size: payload.size,
                    kind,
                    name: item.name,
                    path: item.home,
                    isPublic: false,
                    isStock: false,
                    dwl_token: null,
                    weblink: '',
                });
            }
        }
    },
    [updateFolder.type]: (state, action: PayloadAction<ApiFolderResponse>) => {
        const { payload } = action;
        const id = payload.home;

        if (!state.list[id]) {
            return;
        }

        normalize({ state, item: payload, loaded: true });
    },
    [mountFolderSuccess.type]: (state, action: PayloadAction<ApiFolderResponse>) => {
        const item = action.payload;

        const parentId = getParent(item.home);
        if (state.list[parentId] && state.list[parentId].isFolder) {
            const folder = state.list[parentId] as HomeFolder;
            if (!folder.childs?.includes(item.home)) {
                folder?.childs?.push(item.home);
            }
        }

        normalize({ state, item, loaded: true });
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds, newParent, storage } = action.payload;
        const isAllDocuments = IS_DOCUMENTS_DOMAIN || storage === EStorageType.alldocuments;

        const newParentFolder = state.list[newParent] as HomeFolder | undefined;

        if (!newParentFolder) {
            return;
        }

        newItems.forEach((newItem, index) => {
            const file = newItem as HomeItem;
            const oldItemId = oldIds[index];
            const oldItem = state.list[oldItemId];
            const oldParentFolder = state.list[oldItem?.parent || ''] as HomeFolder | undefined;
            const isFile = !newItem.isFolder;

            if (!file?.home || !oldItem || newParentFolder.childs.includes(file?.home) || !oldParentFolder) {
                return;
            }

            if (isAllDocuments) {
                state.list[file.home] = file;
                delete state.list[oldItemId];

                oldParentFolder.childs = oldParentFolder.childs.filter((id) => id !== oldItemId);
                newParentFolder.childs = [file.home, ...newParentFolder.childs];
            } else {
                // Удаляем элемент из старого родительского элемента
                oldParentFolder.childs = oldParentFolder.childs.filter((child): boolean => child !== oldItemId);
                oldParentFolder.size -= oldItem.size;
                oldParentFolder.count.all -= 1;
                oldParentFolder.count.loaded -= 1;
                oldParentFolder.count.folders -= Number(!isFile);
                oldParentFolder.count.files -= Number(isFile);

                // Добавляем в новый родительский элемент
                newParentFolder.size += file.size;
                newParentFolder.count.all += 1;
                newParentFolder.count.loaded += 1;
                newParentFolder.count.folders += Number(!isFile);
                newParentFolder.count.files += Number(isFile);
                newParentFolder.childs.push(file.home);

                delete state.list[oldItemId];

                // @ts-ignore
                normalize({ item: newItem, parent: newParent, state });
            }
        });
    },
    [addFilesSuccess.type]: (state, action: PayloadAction<AddFileSuccessAction>) => {
        const { parent: parentId, items } = action.payload;
        const parent = state.list[parentId] as HomeFolder | undefined;
        const isMounted = isMountedFolder(parent);

        if (!parent) {
            // Мы в другом разделе и хомяк (его дерево папок) не загружен,
            // но мы загрузили и он нам может быть нужен
            // Тогда кладем не в дерево, а хотя бы просто в список по ИД
            items.forEach((item) => {
                const file = item as ApiFolderResponse | ApiFolderResponseFile | HomeItem;

                if (file.home && !state.list[file.home]) {
                    // @ts-ignore
                    normalize({ item: file, parent: parentId, state });
                }
            });
            return;
        }

        let all = parent.count.all;
        let loaded = parent.count.loaded;
        let folders = parent.count.folders;
        let files = parent.count.files;

        const childs = [...parent.childs];

        items.forEach((item) => {
            const file = item as ApiFolderResponse | ApiFolderResponseFile | HomeItem;

            if (!file.home || parent.childs.includes(file.home)) {
                return;
            }

            const isFile = !isFolder(item);
            childs.push(file.home);

            let kind = item.kind;
            if (path(['descriptorId'], item) || item.kind === 'file') {
                const ext = getExtension(item);
                kind = ext ? extInfo.get(ext?.toLowerCase())?.kind : null ?? kind;
            }
            if (!isFile || !isMedia({ kind })) {
                parent.flags = {
                    ...(parent.flags || {}),
                    isMedia: false,
                };
            }

            if ('__reduxTree' in file && file.__reduxTree) {
                state.list[file.id] = file;
            } else {
                const flags = 'flags' in file ? file.flags : {};
                const item = { ...file, flags: { mounted: isMounted, ...flags } };
                // @ts-ignore
                normalize({ item, parent: parentId, state });
            }

            all += 1;
            loaded += 1;
            folders += Number(!isFile);
            files += Number(isFile);
        });

        parent.count = { all, folders, files, loaded };
        parent.childs = childs;
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        ids.forEach((id): void => {
            const item = state.list[id];
            if (item) {
                const parent = state.list[item.parent || ''] as HomeFolder | undefined;
                if (parent) {
                    parent.childs = parent.childs.filter((child): boolean => child !== id);
                    parent.count.all -= 1;
                    parent.count.loaded -= 1;
                    delete state.list[id];

                    if (item.isFolder) {
                        parent.count.folders -= 1;
                    } else {
                        parent.count.files -= 1;
                    }
                }
            }
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file = newItem as HomeItem;

        if (!file || !oldId) {
            return;
        }

        const parent = state.list[file.parent || ''] as HomeFolder | undefined;

        if (!parent?.id) {
            return;
        }

        if (Array.isArray(parent.childs)) {
            parent.childs = parent.childs.filter((child): boolean => child !== oldId);
            parent.childs.push(file.id);
        }

        delete state.list[oldId];

        state.list[file.id] = file;
    },
    [unshareFolderSuccess.type]: (state, action: PayloadAction<IUnshareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id];

        if (!item || !isFolder(item)) {
            return;
        }

        item.kind = 'folder';
    },
    [shareFolderSuccess.type]: (state, action: PayloadAction<IShareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id];

        if (!item || !isFolder(item)) {
            return;
        }

        item.kind = 'shared';
    },
    [setDomainFoldersFilterActive.type]: (state, action: PayloadAction<boolean>) => {
        state.domainFoldersFilterActive = action.payload;
    },
    [addHomeItemsToStore.type]: (state, action: ReturnType<typeof addHomeItemsToStore>) => {
        const files = action.payload;

        files.forEach((file) => {
            const item = state.list[file.id];

            if (item || !file.id) {
                return;
            }

            state.list[file.id] = file;
        });
    },
});
