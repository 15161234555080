import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Footnote, Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { removeFamilyMember } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import type { FamilyUser } from 'reactApp/modules/family/family.types';
import { FamilySettingsDropdown } from 'reactApp/sections/FamilyPage/FamilySettingsDropdown/FamilySettingsDropdown';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { MenuButton } from 'reactApp/ui/MenuButton/MenuButton';
import { useMenuButton } from 'reactApp/ui/MenuButton/useMenuButton';

import styles from './MemberCard.css';

type MemberCardProps = FamilyUser & { isOwner?: boolean; showDeleteOnClick?: boolean };

export const MemberCard = memo(({ user, used, isOwner = false, name, showDeleteOnClick = false }: MemberCardProps) => {
    const dispatch = useDispatch();

    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const onDelete = useCallback(() => {
        dispatch(removeFamilyMember({ user }));
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.clickButtonDeleteUser,
        });
    }, [dispatch, user]);

    const onDeleteClick = useCallback(
        (event) => {
            if (!showDeleteOnClick) {
                return;
            }

            event.stopPropagation();
            event.preventDefault();
            onDelete();
        },
        [onDelete, showDeleteOnClick]
    );

    const menuProps = useMenuButton();

    return (
        <div className={styles.item} data-qa-id={user}>
            <div className={styles.avatar}>
                <Avatar sourceSize={90} size={48} email={user} name={name} />
            </div>
            <div className={styles.info}>
                <Text weight="2">{name}</Text>
                <div className={styles.emailBlock}>
                    {user}
                    {isOwner && (
                        <>
                            <Footnote className={styles.dot}>•</Footnote>
                            администратор
                        </>
                    )}
                </div>
                <Spacing size={8} />
                <div
                    className={classNames(styles.used, {
                        [styles.used_grey]: isOwner,
                    })}
                >
                    Занимает {bytesToNDigits(used, 3).value}
                </div>
            </div>
            {!isOwner && (
                <div onClickCapture={onDeleteClick}>
                    <MenuButton {...menuProps} marginRight={isPhone ? 9 : 13} />
                </div>
            )}

            {menuProps.isShowMenu && (
                <FamilySettingsDropdown
                    gaId="family-member-card"
                    onClose={menuProps.closeMenu}
                    onDelete={onDelete}
                    controlRef={menuProps.menuRef}
                />
            )}
        </div>
    );
});

MemberCard.displayName = 'MemberCard';
