import { type ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { chooseThemeRequest } from 'reactApp/modules/theme.outsource/theme.outsource.module';
import { setPublicTheme } from 'reactApp/modules/themePublic/themePublic.module';
import { renderSharingPublicThemeTariffsModal } from 'reactApp/ui/SharingPublicThemeTariffsModal/SharingPublicThemeTariffsModal.helpers';
import { closeUnsavedChangesModal, renderUnsavedChangesModal } from 'reactApp/ui/UnsavedChangesModal/UnsavedChangesModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';

interface UseHandlersParams {
    isPaid?: boolean;
    source?: string;
    choosedTheme?: string;
    unsavedChangesDescription: ReactNode;
    unsavedChangesDescriptionForPaid: ReactNode;
    setUnsavedChangesModalShowed: (v: boolean) => void;
}

export function useHandlers({
    choosedTheme,
    source,
    isPaid,
    unsavedChangesDescription,
    unsavedChangesDescriptionForPaid,
    setUnsavedChangesModalShowed,
}: UseHandlersParams) {
    const dispatch = useDispatch();
    const isAnyDialogVisible = useSelector(isDialogVisible);
    const weblinkId = useSelector(getCurrentRouteId);
    const publicId = getWeblinkFromPublicId(weblinkId) ?? '';

    const savePublicTheme = useCallback(() => {
        if (choosedTheme) {
            dispatch(setPublicTheme({ publicId, themeId: choosedTheme }));
            snackbarController.showSnackbar({
                id: 'public-theme-success',
                closable: true,
                text: 'Оформление для папки установлено,его увидят получатели ссылки',
                type: SnackbarTypes.success,
            });
        }
    }, [publicId, choosedTheme]);

    const openBuyModal = useCallback(() => {
        renderSharingPublicThemeTariffsModal({
            onShow: closeUnsavedChangesModal,
            onBuySuccess: savePublicTheme,
            source,
        });
    }, [savePublicTheme]);

    const onSaveClick = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_SAVE);
        sendDwh({
            eventCategory: 'brand_page',
            action: 'save',
            dwhData: {
                source,
            },
        });
        if (!isPaid) {
            openBuyModal();
        } else {
            savePublicTheme();
        }
    }, [isPaid, openBuyModal, savePublicTheme]);

    const showUnsavedChangesModal = useCallback(() => {
        if (!isAnyDialogVisible) {
            renderUnsavedChangesModal({
                successButtonText: isPaid ? 'Сохранить' : 'Выбрать тариф',
                description: isPaid ? unsavedChangesDescriptionForPaid : unsavedChangesDescription,
                onAction: () => {
                    emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_EXIT_TRUE);
                    sendDwh({
                        eventCategory: 'brand_page',
                        action: 'exit_true',
                        dwhData: {
                            source,
                        },
                    });
                    window.close();
                    closeUnsavedChangesModal();
                },
                onSuccess: () => {
                    if (!isPaid) {
                        emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_EXIT_CHOOSE);
                        sendDwh({
                            eventCategory: 'brand_page',
                            action: 'exit_choose',
                            dwhData: {
                                source,
                            },
                        });
                        openBuyModal();
                    } else {
                        onSaveClick();
                        closeUnsavedChangesModal();
                    }
                },
                onShow: () => {
                    emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_EXIT_SHOW);
                    sendDwh({
                        eventCategory: 'brand_page',
                        action: 'exit_show',
                        dwhData: {
                            source,
                        },
                    });
                    setUnsavedChangesModalShowed(true);
                },
                onClose: () => {
                    setUnsavedChangesModalShowed(false);
                },
            });
        }
    }, [isAnyDialogVisible, isPaid, onSaveClick, openBuyModal, unsavedChangesDescriptionForPaid, unsavedChangesDescription]);

    const chooseItem = useCallback((themeId: string) => {
        dispatch(chooseThemeRequest(themeId));
    }, []);

    return { onSaveClick, showUnsavedChangesModal, chooseItem };
}
