/* eslint-disable @typescript-eslint/no-unused-expressions */
import { partition } from 'ramda';
import { downloadItem, downloadItemsZip } from 'reactApp/appHelpers/appDownload';
import { renderRemoveDialog } from 'reactApp/components/RemoveDialog/RemoveDialog.helpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isVirusItem } from 'reactApp/modules/file/utils';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { ActionName, ActionTarget } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { noopVoid } from 'reactApp/utils/helpers';

export const openVirusPopupHelper = ({ items, isMobile, isPublic, isHome, actionName, onClose = noopVoid, name = '', onRemoveSuccess }) => {
    const [infected, safe] = partition((item: CloudItem) => isVirusItem(item), items);
    const isPhone = EnvironmentSelectors.isPhone();

    let actionTarget = ActionTarget.file;

    if (infected?.length === 1) {
        actionTarget = infected[0].isFolder ? ActionTarget.folder : ActionTarget.file;
    } else {
        actionTarget = ActionTarget.several;
    }

    const dlg = openPopupHelper({
        popupName: isPhone ? popupNames.MOBILE_VIRUS_DIALOG : popupNames.VIRUS_DLG,
        onClose,
        data: {
            onDownload: () => {
                const forceDownload: any = true;
                actionName === ActionName.zip
                    ? downloadItemsZip({ items, name, forceDownload })
                    : downloadItem({ itemOrId: infected[0], forceDownload });
                dlg?.closeDialog();
            },
            onDownloadOnlySafe: () => {
                const forceDownload: any = true;
                actionName === ActionName.zip
                    ? downloadItemsZip({ items: safe, name, forceDownload })
                    : downloadItem({ itemOrId: safe[0], forceDownload });

                dlg?.closeDialog();
            },
            onRemove: () => {
                renderRemoveDialog({
                    items: [infected[0]],
                    onRemove: () => {
                        onRemoveSuccess?.({ items: [infected[0]] });
                        dlg?.closeDialog();
                    },
                });
            },
            id: infected[0]?.id,
            isMobile,
            actionName,
            actionTarget,
            isHome,
            isPublic,
            hasSafeFiles: !!safe.length,
        },
    });
};
