import { type IAccessRightsOptions, type IPublicAccessRightsOptions, EAccessRights } from 'reactApp/components/SharingWindow/Sharing.types';
import { EPublicAccessRights } from 'reactApp/modules/weblinkAccessControl/weblinkAccessControl.types';

export const DELETE_USER: IAccessRightsOptions = {
    key: EAccessRights.DELETE_USER,
    text: 'Закрыть доступ',
};

export const UNMOUNT_USER: IAccessRightsOptions = {
    key: EAccessRights.UNMOUNT_USER,
    text: 'Отключить доступ ',
};

export const ACCESS_RIGHTS_OPTIONS: Record<string, IAccessRightsOptions> = {
    [EAccessRights.READ_WRITE]: {
        key: EAccessRights.READ_WRITE,
        text: 'Редактор',
    },
    [EAccessRights.READ_ONLY]: {
        key: EAccessRights.READ_ONLY,
        text: 'Читатель',
    },
};

export const PUBLIC_ACCESS_RIGHTS_OPTIONS: Record<string, IPublicAccessRightsOptions> = {
    [EPublicAccessRights.READ_WRITE]: {
        key: EPublicAccessRights.READ_WRITE,
        text: 'Редактор',
    },
    [EPublicAccessRights.READ_ONLY]: {
        key: EPublicAccessRights.READ_ONLY,
        text: 'Читатель',
    },
};
