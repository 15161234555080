import classNames from 'clsx';
import React, { type VFC, useCallback, useEffect, useRef, useState } from 'react';
import type { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import Content from 'reactApp/ui/Content/Content';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { FooterButton } from 'reactApp/ui/PollPopup/FooterButton';
import { PollContent } from 'reactApp/ui/PollPopup/PollContent';
import { MAX_COMMENT_LENGTH, OTHER_ID, POLL_DLG_ID } from 'reactApp/ui/PollPopup/PollPopup.constants';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import { Dialog } from '../Dialog/Dialog';
import styles from './PollPopup.css';

export const PollPopup: VFC<IPropsWithPopup<{ isPhone?: boolean; isRebrandingQuotaLanding?: boolean }>> = ({
    onClose,
    isPhone = false,
    isRebrandingQuotaLanding = false,
}) => {
    const source = isPhone ? 'touch' : 'desktop';
    const [choiceId, setChoiceId] = useState('');
    const [comment, setComment] = useState('');
    const handleOnClose = useCallback(() => onClose?.(), [onClose]);
    const ref = useRef<HTMLDivElement>(null);

    const enableSubmit = Boolean(choiceId === OTHER_ID ? comment : choiceId);

    useEffect(() => {
        sendDwh({ eventCategory: ECategoryGa.payment, action: 'poll-open', dwhData: { source } });
    }, []);

    const handleSubmit = useCallback(() => {
        if (!enableSubmit) {
            return;
        }

        const result = `${choiceId === OTHER_ID ? getTrimmedText(comment, MAX_COMMENT_LENGTH) : choiceId}`;

        sendDwh({ eventCategory: ECategoryGa.payment, action: 'poll-send', dwhData: { result, source } });

        handleOnClose();
    }, [handleOnClose, choiceId, comment, enableSubmit]);

    const footer = (
        <FooterButton
            onClick={handleSubmit}
            enableSubmit={enableSubmit}
            isPhone={isPhone}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        />
    );

    const choiceClick = useCallback(
        (id: string) => {
            setChoiceId(id);
            if (id !== OTHER_ID) {
                setComment('');
            }
        },
        [setChoiceId, setComment]
    );

    const handleOnComment = useCallback(
        (comment: string) => {
            setComment(comment);
        },
        [setComment]
    );

    const content = (
        <PollContent onComment={handleOnComment} onChoice={choiceClick} isPhone={isPhone} choiceId={choiceId} comment={comment} />
    );

    if (isPhone) {
        return (
            <MobileDialog
                id={POLL_DLG_ID}
                open
                dimmer
                onClose={handleOnClose}
                closable
                closeOnDimmerClick
                mod="confirm"
                title={<></>}
                footer={footer}
                topmost
                scrollRef={ref}
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            >
                <div
                    ref={ref}
                    className={classNames(styles.contentWrapper, {
                        [styles.contentWrapper_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
                    })}
                >
                    {content}
                </div>
            </MobileDialog>
        );
    }

    return (
        <Dialog id={POLL_DLG_ID} open dimmer className={styles.root} header={<></>} onCancel={handleOnClose} footer={footer} closable>
            <Content isModal>
                <div className={styles.contentWrapper}>{content}</div>
            </Content>
        </Dialog>
    );
};

PollPopup.displayName = 'PollPopup';
