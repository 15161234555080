// стили для сетки и синей шапки
import 'semantic-ui-css/semantic.min.css'; // ставим первым, чтобы в main.scss перезаписать базовые свойства
import './legacy.scss';
import './fonts.css';
import './theme.css';
import 'intersection-observer';
import 'fullscreen-api-polyfill';
import '@vkontakte/vkui/dist/components.css';
import './arrayBufferPolyfill';

import config from 'Cloud/config';
import { sendAppReady } from 'lib/app-ready';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { APIConfig } from 'reactApp/api/APIConfig';
import { registerABExperiments } from 'reactApp/appHelpers/experiments';
import { initializeCloudAppCode } from 'reactApp/modules/start/CloudAppAdapterForReactPages';
import { initSaga, store } from 'reactApp/store';
import { App } from 'reactApp/ui/App/App';
import { initializeFirebase } from 'reactApp/utils/firebase';
import { rootSaga } from 'rootSaga';

try {
    initializeCloudAppCode();
    initSaga(rootSaga);
    initializeFirebase();
    registerABExperiments();

    APIConfig.set('user', config.get('user.email'));
    APIConfig.set('csrf-token', config.get('tokens.csrf'));
    APIConfig.set('x-page-id', config.get('x-page-id'));
    APIConfig.set('auth-domain', config.get('AUTH_DOMAIN'));

    window.__store = store;

    document.body.classList.add('g-default-font');

    if (/macintosh/i.test(navigator.userAgent)) {
        document.body.classList.add('g-macintosh-font');
    }

    render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.querySelector('#reactApp')
    );
} catch (error) {
    sendAppReady(error);
}
