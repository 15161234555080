import { OLD_FORMAT_EXTS } from 'Cloud/ui/Viewer/viewer.constants';
import { getFeatureAdOnVideo, getFeatureWopi, getFeatureWopiOld } from 'reactApp/modules/features/features.selectors';
import { sortItems } from 'reactApp/modules/sort/sort.helpers';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { type CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import type { IArchiveItem, IViewerArchiveState, ViewerState } from 'reactApp/modules/viewer/viewer.types';
import type { RootState } from 'reactApp/store';
import type { Count, Kind } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

const getViewerStore = (state: RootState) => state.viewer as ViewerState;
export const getViewerItemId = (state: RootState): string => getViewerStore(state).itemId;
const getViewerItemIndexInArray = (state: RootState): number | null => getViewerStore(state).itemIdxInArray;
const getViewerItemStorage = (state: RootState): EStorageType | null => getViewerStore(state).itemStorage;
export const getViewerItem = (state: RootState): CloudItem | IStoryFileItem | null => getViewerStore(state).item;
const getViewerItemIds = (state: RootState): string[] | null => getViewerStore(state).itemIds;
const getViewerItemCount = createSelector(getViewerStore, (viewer) => viewer.totalCount || (viewer.item ? 1 : 0));
const getViewerGa = (state: RootState): string => getViewerStore(state).gaSuffix || '';
const isViewerActive = (state: RootState): boolean => getViewerStore(state).isActive;
const isViewerOpening = (state: RootState): boolean => getViewerStore(state).isOpening;
const isViewerActionOpen = (state: RootState): boolean => getViewerStore(state).isOpenAction;
const hasMoreToLoad = (state: RootState): boolean => Boolean(getViewerStore(state).hasMoreToLoad);
const getArchiveInfo = (state: RootState): IViewerArchiveState | null => getViewerStore(state).archive;
const getOvidiusZoom = (state: RootState, id: string | undefined): number => (id && getViewerStore(state).ovidiusZoom[id]) || 1;
const getArchiveCurrentFolder = createSelector(getArchiveInfo, (archive): string => archive?.currentFolderId ?? '');
const getArchiveItems = createSelector(
    getArchiveInfo,
    (state, path: string | null): string => path ?? '',
    (archive, path): IArchiveItem[] => {
        const items = archive?.archiveItems || {};
        const idxs = (!path ? archive?.list : items[path]?.list) ?? [];
        return sortItems(
            idxs.map((id) => items[id]),
            { order: ESortOder.asc, type: ESortTypes.name }
        ) as IArchiveItem[];
    }
);
const getCurrentFolderArchiveItems = createSelector(
    (state: RootState) => state,
    getArchiveCurrentFolder,
    (state, currentFolderId): IArchiveItem[] => getArchiveItems(state, currentFolderId)
);
const getArchiveCount = createSelector(
    getArchiveInfo,
    getArchiveCurrentFolder,
    (archive, activeArchiveFolderId): Count | undefined | null => {
        if (activeArchiveFolderId) {
            return archive?.archiveItems[activeArchiveFolderId]?.count;
        }
        return archive?.count;
    }
);
const getArchiveError = createSelector(getArchiveInfo, (archive) => archive?.error);
const getArchiveIsLoaded = createSelector(getArchiveInfo, (archive): boolean => archive?.isLoaded ?? false);
const getArchiveProlong = createSelector(getArchiveInfo, (archive) => ({
    prolongLink: archive?.prolongLink,
    ttl: archive?.prolongTtl,
}));
export const getArchiveItemById = createSelector(
    getArchiveInfo,
    (state, id) => id,
    (archive, id): IArchiveItem | null | undefined => {
        if (!id) {
            return null;
        }

        return archive?.archiveItems[id];
    }
);
const getArchiveActiveItem = createSelector(
    (state) => state,
    getArchiveInfo,
    (state, info): IArchiveItem | null | undefined => getArchiveItemById(state, info?.currentActiveId)
);
const getArchiveBreadcrumbs = createSelector(
    getArchiveInfo,
    (state, zipName) => zipName,
    getArchiveCurrentFolder,
    (archive, zipName, path) => {
        const res = [
            {
                text: zipName,
                id: '',
                kind: 'folder' as Kind,
                showWeblink: false,
                showShare: false,
                storage: EStorageType.archive,
            },
        ];

        if (!path) {
            return res;
        }

        let prevId = '';
        path.split('/')
            .filter((id) => Boolean(id))
            .forEach((id) => {
                const newId = `${prevId}/${id}`;
                prevId = newId;
                res.push({
                    text: id,
                    id: newId,
                    kind: 'folder' as Kind,
                    showWeblink: false,
                    showShare: false,
                    storage: EStorageType.archive,
                });
            });

        return res;
    }
);
const isWopiForOldFormat = createSelector(
    (state) => state,
    (state, ext: string) => ext,
    getFeatureWopiOld,
    (state, ext, isFeatureWopiOldEnabled) => isFeatureWopiOldEnabled && OLD_FORMAT_EXTS.includes(ext)
);
const isVideoAdEnabled = createSelector(
    (state) => state,
    (state, storage: EStorageType) => storage,
    getFeatureAdOnVideo,
    (state, storage, isFeatureAdOnVideo) => isFeatureAdOnVideo && storage === EStorageType.public
);

const isWopiEnabled = createSelector(
    (state: RootState) => getFeatureWopi(state),
    (state: RootState, ext: string) => isWopiForOldFormat(state, ext || ''),
    (isWopiFeature, isWopiOld) => isWopiFeature || isWopiOld
);

const getIsFirstClosed = createSelector(getViewerStore, (state) => state.isFirstClosed);

export const ViewerSelectors = {
    getViewerItemId,
    getViewerItemStorage,
    getViewerItem,
    getViewerItemIndexInArray,
    getViewerItemIds,
    getViewerGa,
    isViewerActive,
    isViewerOpening,
    isViewerActionOpen,
    getViewerItemCount,
    hasMoreToLoad,
    getArchiveInfo,
    getArchiveItems,
    getCurrentFolderArchiveItems,
    getArchiveCount,
    getArchiveError,
    getArchiveIsLoaded,
    getArchiveCurrentFolder,
    getArchiveBreadcrumbs,
    getArchiveActiveItem,
    getArchiveProlong,
    getArchiveItemById,
    isWopiForOldFormat,
    isVideoAdEnabled,
    isWopiEnabled,
    getIsFirstClosed,
    getOvidiusZoom,
};
