import { type PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import type {
    IGetSubscriptionsRequestData,
    ISubscriptionsState,
    IUpdateSubscriptionsAction,
} from 'reactApp/modules/subscriptions/subscriptions.types';

import { normalizeSubscriptionsList } from './subscriptions.helpers';

export const startSubscriptionsPageRequest = createAction('subscriptions/startSubscriptionsPageRequest');
export const startSubscriptionsPageSuccess = createAction('subscriptions/startSubscriptionsPageSuccess');
export const getSubscriptionsRequest = createAction('subscriptions/getSubscriptionsRequest');
export const getSubscriptionsSuccess = createAction<IGetSubscriptionsRequestData>('subscriptions/getSubscriptionsSuccess');
export const updateSubscriptionsRequest = createAction<IUpdateSubscriptionsAction | undefined>('subscriptions/updateSubscriptionsRequest');
export const updateSubscriptionsSuccess = createAction<IGetSubscriptionsRequestData>('subscriptions/updateSubscriptionsSuccess');
export const setActiveSubscription = createAction<string>('subscriptions/setActiveSubscription');

const initialState: ISubscriptionsState = {
    activeSubscriptionId: '',
    list: {},
    rawList: {},
    isLoading: false,
    isLoaded: false,
};

const handleSubscriptionSuccessAction = (
    state: any,
    { payload: { list } }: PayloadAction<IGetSubscriptionsRequestData>
): ISubscriptionsState => ({
    ...state,
    rawList: list,
    list: normalizeSubscriptionsList(list),
    isLoading: false,
    isLoaded: true,
});

export const subscriptionsReducer = createReducer(initialState, {
    [getSubscriptionsSuccess.type]: handleSubscriptionSuccessAction,
    [updateSubscriptionsSuccess.type]: handleSubscriptionSuccessAction,
    [setActiveSubscription.type]: (state, action: PayloadAction<string>) => {
        state.activeSubscriptionId = action.payload;
    },
    [getSubscriptionsRequest.type]: (state) => {
        state.isLoading = true;
    },
    [updateSubscriptionsRequest.type]: (state) => {
        state.isLoading = true;
    },
});
