import classNames from 'clsx';
import React from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';

import styles from './Loader.css';

interface LoaderProps {
    centered?: boolean;
    isModal?: boolean;
    className?: string;
}

export const Loader = ({ centered, isModal, className }: LoaderProps) => (
    <div
        className={classNames({
            [styles.root]: true,
            [styles.centered]: centered,
            [styles.modal]: Boolean(isModal),
            className,
        })}
    >
        <SemanticLoader active />
    </div>
);
