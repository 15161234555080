/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { EFavoritesFetch, EFavoritesFetchCount } from 'reactApp/modules/favorites/favorites.constants';
import type { FavoriteItem, State } from 'reactApp/modules/favorites/favorites.types';
import { isItemPreviewableImageWithThumbs, isItemPreviewableVideoWithThumbs } from 'reactApp/modules/file/file.helpers';
import { FileBuilder } from 'reactApp/modules/file/FileBuilder';
import { isFolder, normalizeId, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import {
    addFilesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    resetWeblinkCountDownloads,
    shareFolderSuccess,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    unshareFolderSuccess,
    updateWeblinkAutoDelete,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
    weblinkSetDomainAccess,
} from 'reactApp/modules/modifying/modifying.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getThumbnailImageUrl } from 'reactApp/modules/urlBuilder/thumbsUrlBuilder';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';
import type { AnyAction, Reducer } from 'redux';

const INITIAL_STATE: State = {
    childs: [],
    list: {},
    id: IS_BIZ_USER ? 'favorites' : '',
    offset: 0,
    hasMoreToLoad: false,
    isLoaded: false,
    isLoading: false,
    name: 'Избранные',
    count: {
        files: 0,
        folders: 0,
        all: 0,
        loaded: 0,
        photos: 0,
        videos: 0,
    },
};

const oneDaySeconds = 86400;
const timeComparerFactory =
    (today): any =>
    (item1, item2): number => {
        const milestone = Math.round(today / 1000) + oneDaySeconds;

        if (item1.time > milestone) {
            return 1;
        }
        if (item2.time > milestone) {
            return -1;
        }

        return item2.time - item1.time;
    };

const urlBuilder = new UrlBuilder();
const fileBuilder = new FileBuilder();

const normalizeItem = (item: any, domainFolderIDs: string[] = []): FavoriteItem => {
    item.id = item.home; // TEMP!
    const res = fileBuilder.prepareFile(item);

    const weblink = item.public ? item.public.id : '';
    const parentFolder = normalizeId(res.home.replace(res.name, ''));

    const thumbnails = urlBuilder.getThumb({
        ext: res.ext,
        weblink,
        isPublic: false,
        kind: res.kind,
        path: res.id,
        name: res.name,
        size: res.size,
    });

    const thumbUrl = getThumbnailImageUrl(thumbnails, 320, 240);

    const thumbs = {};
    if (thumbnails) {
        for (const key in thumbnails) {
            if (Object.prototype.hasOwnProperty.call(thumbnails, key)) {
                thumbs[key] = thumbnails[key];
            }
        }
    }

    const url = {};
    const urls = urlBuilder.getUrls({
        ext: res.ext,
        weblink,
        isPublic: false,
        kind: res.kind,
        subKind: res.subKind,
        id: res.id,
        name: res.name,
        size: res.size,
    });

    if (urls) {
        for (const key in urls) {
            if (Object.prototype.hasOwnProperty.call(urls, key)) {
                url[key] = urls[key];
            }
        }
    }

    let kind = res.isFolder ? item.kind : res.kind;

    if (domainFolderIDs.length && res.isFolder && domainFolderIDs.includes(item.id)) {
        kind = 'domain-folder';
    }

    return {
        ...res,
        kind,
        parent: parentFolder,
        ext2: res.ext,
        storage: EStorageType.favorites,
        isFolder: res.isFolder,
        author: item.actor,
        thumbUrl,
        thumbnails: thumbs,
        isInFavorites: true,
        favoritesEnabled: true,
        publishEnabled: true,
        mtime: normalizeMtime(item.mtime),
        url,
        ...normalizePublicApiInfo(item),
    };
};

// eslint-disable-next-line max-lines-per-function
// eslint-disable-next-line complexity
export const favoritesReducer: Reducer = (state: State = INITIAL_STATE, action: AnyAction): State => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (action.type) {
        case EFavoritesFetch.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case EFavoritesFetch.SUCCESS: {
            // TODO:  если будем тут делать догрузку через offset, то нужно доп-но обработать предыдущий список

            const { sharedIncoming, favorites, domainFolders } = action.payload;

            const mountedFoldersIds = sharedIncoming.map((item) => (item.home ? `${item.home}/` : null)).filter((home) => !!home);
            let { objects = [] } = favorites;

            if (mountedFoldersIds.length) {
                objects = objects.filter((obj) => !mountedFoldersIds.some((mountedFolderId) => obj.home.includes(mountedFolderId)));
            }

            const domainFolderIDs = domainFolders?.map((item) => item.home) || [];

            const files = objects.filter((object) => object.kind === 'file');
            const items = objects.map((item) => normalizeItem(item, domainFolderIDs)) || [];

            const list = {
                ...items.reduce(
                    (acc, item): Record<string, FavoriteItem> => ({
                        ...acc,
                        [item.id]: item,
                    }),
                    {}
                ),
            };
            const childs = Array.from(new Set([...items.sort(timeComparerFactory(new Date().valueOf())).map((item): string => item.id)]));

            return {
                ...state,
                list,
                childs,
                offset: items.length,
                isLoaded: true,
                isLoading: false,
                hasMoreToLoad: false, // доделаем когда будет offset в апи. state.childs.length < childs.length,
                count: {
                    ...state.count,
                    all: childs.length,
                    loaded: childs.length,
                    files: files.length,
                    folders: childs.length - files.length,
                },
            };
        }
        case EFavoritesFetchCount.SUCCESS: {
            const count = action.payload.count;

            return {
                ...state,
                count: {
                    ...state.count,
                    ...count,
                },
            };
        }
        case removeFromFavoritesSuccess.type: {
            const { ids } = action.payload;
            let all = 0;
            let loaded = 0;
            let files = 0;
            let photos = 0;
            let videos = 0;
            let folders = 0;

            ids.forEach((id) => {
                const item = state.list[id];

                if (!item) {
                    return;
                }

                delete state.list[id];

                const isItemFolder = isFolder(item);
                const isItemPhoto = isItemPreviewableImageWithThumbs(item);
                const isItemVideo = isItemPreviewableVideoWithThumbs(item);

                all++;
                loaded++;
                files += Number(!isItemFolder);
                photos += Number(isItemPhoto);
                videos += Number(isItemVideo);
                folders += Number(isItemFolder);
            });

            return {
                ...state,
                childs: state.childs.filter((item) => !ids.includes(item)),
                count: {
                    ...state.count,
                    all: state.count?.all - all,
                    loaded: state.count?.loaded - loaded,
                    files: state.count?.files - files,
                    photos: Number(state.count?.photos) - photos,
                    videos: Number(state.count?.videos) - videos,
                    folders: state.count?.folders - folders,
                },
            };
        }
        case publishWeblink.type: {
            const list = state.list;
            const item = list[action.payload.id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [action.payload.id]: {
                            ...item,
                            weblink: action.payload.weblink,
                        },
                    },
                };
            }
            return state;
        }
        case unPublishWeblink.type: {
            const { ids } = action.payload;
            let list = state.list;

            ids.forEach((id) => {
                const item = list[id];

                if (!item) {
                    return;
                }

                list = {
                    ...list,
                    [id]: {
                        ...item,
                        weblink: undefined,
                        weblinkDomestic: false,
                        weblinkExpires: 0,
                        weblinkAutoDelete: false,
                    },
                };
            });

            return {
                ...state,
                list,
            };
        }
        case toggleWeblinkAccessRights.type: {
            const { id, type } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            weblinkAccessRights: type,
                        },
                    },
                };
            }
            return state;
        }
        case toggleWeblinkDownloadable.type: {
            const { id, downloadable } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            weblinkDownloadable: downloadable,
                        },
                    },
                };
            }
            return state;
        }
        case updateWeblinkCountDownloads.type: {
            const { id, count_downloads } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            count_downloads_left: count_downloads,
                            count_downloads_total: count_downloads,
                        },
                    },
                };
            }
            return state;
        }
        case resetWeblinkCountDownloads.type: {
            const { id } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            count_downloads_total: undefined,
                            count_downloads_left: undefined,
                        },
                    },
                };
            }
            return state;
        }
        case updateWeblinkExpires.type: {
            const { id, expires } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            weblinkExpires: expires,
                        },
                    },
                };
            }
            return state;
        }
        case updateWeblinkAutoDelete.type: {
            const { id, autoDelete } = action.payload;
            const item = state.list[id];

            if (item && item.isFolder) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            weblinkAutoDelete: autoDelete,
                        },
                    },
                };
            }

            return state;
        }
        case toggleWeblinkDomestic.type: {
            const { id, domestic } = action.payload;
            const item = state.list[id];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [id]: {
                            ...item,
                            weblinkDomestic: domestic,
                        },
                    },
                };
            }
            return state;
        }
        case weblinkSetDomainAccess.type: {
            const { domain, itemId } = action.payload;

            const item = state.list[itemId];
            if (item) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [itemId]: {
                            ...item,
                            weblinkDomainAccess: domain,
                        },
                    },
                };
            }
            return state;
        }
        case removeFileSuccess.type: {
            const { ids } = action.payload;
            const items = ids.filter((id) => !!state.list[id]);

            if (!items.length) {
                return state;
            }

            const childs = state.childs.filter((child) => !items.includes(child));

            let photos = state.count?.photos || 0;
            let videos = state.count?.videos || 0;
            let folders = state.count?.folders || 0;
            let files = state.count?.files || 0;

            items.forEach((id) => {
                const item = state.list[id];
                if (photos && isItemPreviewableImageWithThumbs(item)) {
                    photos--;
                }
                if (videos && isItemPreviewableVideoWithThumbs(item)) {
                    videos--;
                }
                if (folders && isFolder(item)) {
                    folders--;
                } else {
                    files--;
                }
            });

            return {
                ...state,
                childs,
                count: {
                    ...state.count,
                    all: state.count?.all - items.length,
                    loaded: state.count?.loaded - items.length,
                    files,
                    folders,
                    photos,
                    videos,
                },
            };
        }
        case moveItemsSuccess.type: {
            const { newItems, oldIds } = action.payload;

            const list = { ...state.list };
            let childs = [...state.childs];

            newItems.forEach((item, index) => {
                const oldId = oldIds[index];
                const oldItem = state.list[oldId];

                if (!item.isInFavorites || !oldItem || !item.home || state.childs.includes(item.home)) {
                    return;
                }

                list[item.id] = normalizeItem(item);

                childs = childs.filter((child) => child !== oldId);
                childs.push(item.id);

                delete list[oldId];
            });

            return {
                ...state,
                childs,
                list,
            };
        }
        case unshareFolderSuccess.type: {
            const { id } = action.payload;
            const item = state.list[id];

            if (item && isFolder(item)) {
                item.kind = 'folder';
            }

            return state;
        }
        case shareFolderSuccess.type: {
            const { id } = action.payload;
            const item = state.list[id];

            if (item && isFolder(item)) {
                item.kind = 'shared';
            }

            return state;
        }
        case addFilesSuccess.type: {
            const { items } = action.payload;

            let all = state.count?.all || 0;
            let loaded = state.count?.loaded || 0;
            let files = state.count?.files || 0;
            let folders = state.count?.folders || 0;
            let photos = state.count?.photos || 0;
            let videos = state.count?.videos || 0;

            items.forEach((item) => {
                if (!item.isInFavorites || !item.home || state.childs.includes(item.home)) {
                    return state;
                }

                const isItemFolder = isFolder(item);
                const isItemPhoto = isItemPreviewableImageWithThumbs(item);
                const isItemVideo = isItemPreviewableVideoWithThumbs(item);

                state.childs.push(item.id);
                state.list[item.id] = item;

                all++;
                loaded++;
                files += Number(!isItemFolder);
                folders += Number(isItemFolder);
                photos += Number(isItemPhoto);
                videos += Number(isItemVideo);
            });

            state.count = { all, loaded, files, folders, photos, videos };

            return state;
        }
        default:
            return state;
    }
};
