import type { RouterState } from 'reactApp/modules/router/router.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { createSelector } from 'reselect';
import { PROMO_REG_EXP, REACT_LANDINGS_REG_EXP } from 'server/constants/landings';

const getRouterState = (state): RouterState => state.router;

export const getCurrentRouteId = createSelector(getRouterState, (state): string => state.id);

/** TODO: getCurrentStorage - поменьше это юзать! Лучше прокидывать сторадж как свойство! */
export const getCurrentStorage = createSelector(getRouterState, (state): EStorageType => state.storage as EStorageType);
export const getPreviousStorage = createSelector(getRouterState, (state): EStorageType => state.prevStorage as EStorageType);

export const isPublic = createSelector(getCurrentStorage, (state): boolean => state === EStorageType.public);

export const getParams = createSelector(getRouterState, (state) => state.params);

export const isSubscriptionPage = () => window.location?.pathname?.indexOf('/subscriptions') === 0;

export const isReactLandingPage = () => Boolean(window.location.pathname.match(REACT_LANDINGS_REG_EXP));
export const isPromoLandingPage = () => Boolean(window.location.pathname.match(PROMO_REG_EXP));

export const getQuotaLandingStorage = (): EStorageType | null => {
    if (window.location.pathname.startsWith('/promo/quota/tariffs')) {
        return EStorageType.quotaTariffs;
    }

    if (window.location.pathname.startsWith('/promo/quota/partner')) {
        return EStorageType.quotaPartner;
    }

    if (window.location.pathname.startsWith('/promo/quota')) {
        return EStorageType.quotaCleaner;
    }

    return null;
};

export const selectStatusPage = (state) => getRouterState(state).status;

export const selectHistoryReplace = (state) => getRouterState(state).historyReplace;

export const selectHistoryPath = (state) => getRouterState(state).historyPath;
