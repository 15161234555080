import browser from 'Cloud/browser';
import { setMany } from 'idb-keyval';
import { extInfo } from 'lib/extInfo';
import { HttpErrorCodes } from 'reactApp/api/HttpErrorCodes';
import { IS_MY_TEAM, IS_OWN_PUBLIC, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { ALBUMS_FOLDER_NAME } from 'reactApp/modules/albums/albums.constants';
import { getNameById } from 'reactApp/modules/file/utils';
import { PERSONAL_DOCUMENTS_FOLDER_NAME } from 'reactApp/modules/personalDocuments/personalDocuments.constants';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { InvalidCharactesFail } from 'reactApp/modules/uploading/fails/InvalidCharactesFail';
import { NameTooLongFail } from 'reactApp/modules/uploading/fails/NameTooLongFail';
import { PathTooLongFail } from 'reactApp/modules/uploading/fails/PathTooLongFail';
import { getVisibleNameParts, getVisiblePath, joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { MB } from 'reactApp/modules/uploading/serviceClasses/helpers';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import type { UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { type IDescriptorOptions, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { type IInputFile, EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { sendXray } from 'reactApp/utils/ga';
import { escapeForRegExp } from 'reactApp/utils/helpers';
import { getFileExtLimit } from 'reactApp/utils/textHelpers';

import { removeInvalidNameCharacters, removeInvalidPathCharacters, validatePaths } from './cloudFs/cloudFs.helpers';

const urlBuilder = new UrlBuilder();

const MAX_FILES_TO_STORE_IN_INDEX_DB = 1000;

const retryableHttpStatuses = [
    HttpErrorCodes.REQUEST_TIMEOUT,
    HttpErrorCodes.INTERNAL_SERVER_ERROR,
    HttpErrorCodes.BAD_GATEWAY,
    HttpErrorCodes.SERVICE_UNAVAILABLE,
    HttpErrorCodes.GATEWAY_TIMEOUT,
];

export const isRetryableHttpStatus = (status: number) => retryableHttpStatuses.includes(status);

export const sendGaUploaderNew = (action: string, label = '', iData: Record<string, any> | null | number = null) =>
    sendXray(['uploader', action, label], typeof iData === 'number' ? { i: iData } : iData);

const uploadSpeedMap = [
    { range: [0, 0.1], label: '0--0.1' },
    { range: [0.1, 0.5], label: '0.1--0.5' },
    { range: [0.5, 1], label: '0.5--1' },
    { range: [1, 5], label: '1-5' },
    { range: [5, 10], label: '5-10' },
    { range: [10, 25], label: '10-25' },
    { range: [25, 50], label: '25-50' },
    { range: [50, 75], label: '50-75' },
    { range: [75, 100], label: '75-100' },
    { range: [100, 200], label: '100-200' },
    { range: [200, Infinity], label: '200-more' },
];

const uploadSizeMap = [
    { range: [0, 1], label: '0-1' },
    { range: [1, 5], label: '1-5' },
    { range: [5, 10], label: '5-10' },
    { range: [10, 25], label: '10-25' },
    { range: [25, 50], label: '25-50' },
    { range: [50, 100], label: '50-100' },
    { range: [100, 250], label: '100-250' },
    { range: [250, 500], label: '250-500' },
    { range: [500, 1000], label: '500-1000' },
    { range: [1000, Infinity], label: '1000-more' },
];

export const sendUploadSpeedMetric = (fileSize: number, uploadTime: number) => {
    const fileSizeInMB = Math.round(fileSize / MB);
    const mbps = fileSizeInMB / (uploadTime / 1000);

    const uploadSpeedInfo = uploadSpeedMap.find(({ range: [from, to] }) => mbps >= from && mbps < to);
    const uploadSizeInfo = uploadSizeMap.find(({ range: [from, to] }) => fileSizeInMB >= from && fileSizeInMB < to);

    sendXray(['uploader', 'speed', uploadSpeedInfo?.label, 'size', uploadSizeInfo?.label], {
        exactSpeed: mbps,
        exactSize: fileSizeInMB,
    });
    sendXray(['uploader', 'speed', 'any', 'size', uploadSizeInfo?.label], {
        exactSpeed: mbps,
        exactSize: fileSizeInMB,
    });
    sendXray(['uploader', 'speed', uploadSpeedInfo?.label, 'size', 'any']);
};

export function filterDescriptors(descriptors: UploadingDescriptor[], isFolderAllowed: boolean) {
    return descriptors.filter((descriptor) => {
        const isDirectory = descriptor.isDirectory;
        const hasNotChildren = !isDirectory || !descriptor.children.length;

        if (isDirectory && !isFolderAllowed) {
            return false;
        }

        // Оставляем только пустые папки и файлы.
        // Остальные папки будут автоматически
        // созданы нашим API при добавлении файлов.
        return hasNotChildren;
    });
}

export function getWorkingDirectory(
    storage: EStorageType,
    currentFolder,
    originalCloudPath: string | null = null,
    isSpecificDocument = false
) {
    // при продолжении загрузки используем изначальную директорию
    if (originalCloudPath) {
        return originalCloudPath;
    }
    const { isDocuments, isHome, isIntegration, isPublic, isAllDocuments, isInlineIntegration } = getStorage(storage);

    if (isDocuments && isSpecificDocument) {
        return `/${PERSONAL_DOCUMENTS_FOLDER_NAME}`;
    }

    if (storage === EStorageType.albums) {
        return `/${ALBUMS_FOLDER_NAME}`;
    }

    if (isAllDocuments) {
        return `/${ALL_DOCUMENTS_FOLDER_ID}`;
    }

    if (isPublic && currentFolder?.home) {
        return currentFolder.home;
    }

    if (!isPublic) {
        if (isHome || isIntegration || isInlineIntegration) {
            return currentFolder.id;
        }

        return ROOT_FOLDER_ID;
    }

    return currentFolder && currentFolder.id;
}

const fixEndSpacesInLocalPath = (workingDir: string, path: string) => {
    const regexp = new RegExp(`^${escapeForRegExp(workingDir)}/`);
    const temp = path.replace(regexp, '');

    const newPath = temp.replace(/\s+\//g, '/'); /* Избавляемся от кейса, когда в конце названия папки есть пробел */

    return temp.length === path.length ? newPath : `${workingDir}/${newPath}`;
};

export function prepareDescriptors(descriptors: UploadingDescriptor[], workingDirectory: string): void {
    for (const descriptor of descriptors) {
        const localName = descriptor.localName;
        let localPath = descriptor.localPath;
        let cloudPath = joinPath(workingDirectory, localPath);
        let cloudName = getNameById(cloudPath);
        let error = descriptor.error;

        error = validatePaths({ error, path: localName });
        error = validatePaths({ error, path: cloudName });
        error = validatePaths({ error, path: cloudPath, onlyPath: true });

        const { fixError, fixCloudName, fixLocalPath, fixCloudPath, hasInvalidCharAutoFix, hasNameTooLongAutoFix } = autoFixError({
            error,
            cloudName,
            cloudPath,
            localPath,
        });
        error = fixError;
        cloudName = fixCloudName;
        cloudPath = fixCloudPath;
        localPath = fixLocalPath;

        if (error) {
            descriptor.error = error;
            if (!(error instanceof ConnectionFail)) {
                descriptor.state = EUploadingState.STATE_FAIL;
            }
        }

        descriptor.hasInvalidCharAutoFix = hasInvalidCharAutoFix;
        descriptor.hasNameTooLongAutoFix = hasNameTooLongAutoFix;

        cloudPath = fixEndSpacesInLocalPath(workingDirectory, cloudPath);
        localPath = localPath?.replace(/\s+\//g, '/'); /* Избавляемся от кейса, когда в конце названия папки есть пробел */

        descriptor.cloudName = cloudName;
        descriptor.cloudPath = cloudPath;
        descriptor.initialCloudPath = cloudPath;
        descriptor.localPath = localPath;
        descriptor.visiblePath = getVisiblePath(cloudPath);
        descriptor.visibleName = getVisiblePath(cloudName);
        descriptor.nameParts = getVisibleNameParts(cloudName);
        descriptor.uploadingPacketConfig.isPublicUpload = descriptor.uploadingPacketConfig.storage === EStorageType.public;
        descriptor.uploadingPacketConfig.isOwnPublic = IS_OWN_PUBLIC;
        descriptor.uploadingPacketConfig.workingDirectory = workingDirectory;
    }
}

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export async function prepareFileDescriptorsForUI(descriptors: UploadingDescriptor[], descriptorOptions: IDescriptorOptions) {
    const fileDescriptors: IInputFile[] = [];

    const entries: [string, any][] = [];

    for (const descriptor of descriptors) {
        const {
            cloudPath,
            initialCloudPath,
            size,
            nameParts,
            id,
            localPath,
            error,
            isDirectory,
            hasInvalidCharAutoFix,
            hasNameTooLongAutoFix,
        } = descriptor;
        const directoryFolder = isDirectory ? 'folder' : '';
        const extension = nameParts?.extension || directoryFolder;

        if (isDirectory && descriptor.cloudPath) {
            continue;
        }

        switch (true) {
            case error instanceof NameTooLongFail:
            case error instanceof PathTooLongFail: {
                descriptor.errorFile = { status: EFileStatus.ERROR, error: EFileError.NAME_TOO_LONG };
                break;
            }
            case error instanceof InvalidCharactesFail: {
                descriptor.errorFile = { status: EFileStatus.ERROR, error: EFileError.INVALID_CHARACTERS };
                break;
            }
        }

        if (!descriptor.errorFile) {
            if (hasInvalidCharAutoFix) {
                descriptor.errorFile = { error: EFileError.INVALID_CHARACTERS_AUTO_FIX };
            } else if (hasNameTooLongAutoFix) {
                descriptor.errorFile = { error: EFileError.NAME_TOO_LONG_AUTO_FIX };
            }
        }

        if (
            !descriptorOptions.continueUpload &&
            !descriptor.uploadingPacketConfig.isPublicUpload &&
            entries.length < MAX_FILES_TO_STORE_IN_INDEX_DB &&
            !descriptor.isDirectory
        ) {
            const file = await descriptor.getFile();
            if (file) {
                entries.push([initialCloudPath, file]);
            }
        }

        fileDescriptors.push({
            cloudPath: fixEndSpacesInLocalPath(descriptor.uploadingPacketConfig.workingDirectory, cloudPath),
            size,
            name: nameParts?.name || '',
            extension,
            descriptorId: id,
            localPath: localPath?.replace(/\s+\//g, '/') /* Избавляемся от кейса, когда в конце названия папки есть пробел */,
            hasAutoFix: {
                hasInvalidCharAutoFix,
                hasNameTooLongAutoFix,
            },
            status: EFileStatus.PROGRESS,
            ...(descriptor.errorFile ? descriptor.errorFile : {}),
            ...descriptorOptions,
            thumb: '',
            uploadPacket: descriptor.uploadingPacketConfig.currentPacketIdForUI,
        });

        if (descriptor.errorFile) {
            sendGaUploaderNew('file', descriptor.errorFile.error);
            sendGaUploaderNew('testerror', getFileExtLimit(extension));
        }
    }

    if (entries.length) {
        setTimeout(async () => {
            // TODO: писать в стор паралельно пачками
            try {
                await setMany(entries);
            } catch (error) {
                sendXray(['uploader', 'idb-keyval', 'setindex', 'error']);
            }
        }, 100);
    }

    return fileDescriptors;
}

function autoFixError({
    error,
    cloudName,
    cloudPath,
    localPath,
}: {
    error: UploadingReason | null;
    cloudName: string;
    cloudPath: string;
    localPath: string;
}) {
    const maxNameLength = 50;
    let fixCloudName = cloudName;
    let fixCloudPath = cloudPath;
    let fixLocalPath = localPath;
    let fixError = error;
    let hasInvalidCharAutoFix = false;
    let hasNameTooLongAutoFix = false;

    if (error instanceof InvalidCharactesFail) {
        fixCloudName = removeInvalidNameCharacters(cloudName);
        fixCloudPath = removeInvalidPathCharacters(cloudPath);
        fixLocalPath = removeInvalidPathCharacters(localPath);
        fixError = null;
        hasInvalidCharAutoFix = true;

        sendGaUploaderNew('auto_fix', 'invalid_char');
    } else if (error instanceof NameTooLongFail) {
        fixCloudName = `${fixCloudName.slice(0, maxNameLength)}...${fixCloudName.slice(-maxNameLength)}`;
        fixCloudPath = `${fixCloudPath.slice(0, maxNameLength)}...${fixCloudName.slice(-maxNameLength)}`;
        fixLocalPath = `${fixLocalPath.slice(0, maxNameLength)}...${fixLocalPath.slice(-maxNameLength)}`;
        fixError = null;
        hasNameTooLongAutoFix = true;
        sendGaUploaderNew('auto_fix', 'name_too_long');
    }

    return { fixCloudName, fixCloudPath, fixLocalPath, fixError, hasInvalidCharAutoFix, hasNameTooLongAutoFix };
}

export const getThumbnail = (descriptor: UploadingDescriptor) => {
    const extension = descriptor.nameParts?.extension;

    if (!extension) {
        return '';
    }

    const extensionData = extInfo.get(extension);
    const kind = extensionData.kind;
    const hash = descriptor.cloudHash;
    const size = descriptor.size;

    const id = `/fake.${extension}`;
    const isPublic = descriptor.uploadingPacketConfig.isPublicUpload;
    const thumbnails = urlBuilder.getThumb({ ext: extension.toLowerCase(), ext2: extension, kind, hash, size, isPublic, id });

    return thumbnails?.pic ?? '';
};

export const isWasmSupported = (() => {
    // Не поддерживаем вызов WA для ВКТ WebView, т.к там используется старая ветсия в которыей происходит crash
    // @see B2BCLOUD-1493
    if (IS_MY_TEAM && browser.isVKTWebViewVersion()) {
        return false;
    }

    try {
        if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module) {
                return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
            }
        }
    } catch (error) {}
    return false;
})();

export const getConnectionTypeString = () => {
    try {
        if ('connection' in navigator) {
            // @ts-ignore
            const effType = 'effectiveType' in navigator.connection && navigator.connection.effectiveType;
            const type = 'type' in navigator.connection && navigator.connection.type;
            return `connection:${type},${effType};`;
        }
        return `connection:${IS_PHONE_BROWSER ? 'cellular' : 'non-cell'},unk;`;
    } catch (_) {}
    return '';
};

/**
 * @desc Это усреднение, новые значения могут сильно меняться, потому усредняем с предыдущим и у предыдущего экспериментально подобран вес больше,
 * чтобы среднее неизменялось слишком сильно
 */
export const getWeightedMovingAverage = (prevValue: number, value: number) => 0.6 * prevValue + 0.4 * value;
