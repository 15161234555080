import React, { type ReactElement, useCallback, useEffect, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { createGaSender } from 'reactApp/utils/ga';

import type { IProps } from './OpenDocument.types';

const sendGa = createGaSender(`open-document`);

export const OpenDocument = React.memo(function OpenDocument(props: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const { hasSharedEditor } = props;

    const onConfirm = useCallback(() => {
        setLoading(true);
        sendGa('confirm');
        props.onSuccess();
    }, [props]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose();
    }, [props]);

    const renderHeader = useCallback(() => <>Внимание!</>, []);

    const renderContent = useCallback(() => {
        return hasSharedEditor ? (
            <div>
                Вы пытаетесь отредактировать файл в общей папке редактором, который не поддерживает совместное редактирование.
                <br />
                Другие участники могут перезаписать ваши изменения.
            </div>
        ) : (
            <div>
                Вы пытаетесь отредактировать файл в общей папке.
                <br />
                Другие участники могут перезаписать ваши изменения.
            </div>
        );
    }, [hasSharedEditor]);

    useEffect(() => sendGa('show'), []);

    return (
        <BaseConfirmDialog
            dataQAId="open-document-dialog"
            renderHeader={renderHeader}
            successButtonText="Продолжить"
            cancelButtonText="Отмена"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            loading={loading}
        />
    );
});
