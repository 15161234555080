import type { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { BillingInfoAPICall } from 'reactApp/api/billing/BillingInfoAPICall';
import { SendPaymentLetterAPICall } from 'reactApp/api/billing/SendPaymentLetterAPICall';
import {
    loadPaidInfoRequest,
    loadPaidInfoSuccess,
    sendPaymentLetter as sendPaymentLetterAction,
} from 'reactApp/modules/paidInfo/paidInfo.module';
import type { ApiPaidInfoResponse } from 'reactApp/modules/paidInfo/paidInfo.types';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

const sendPaymentLetterApiCall = ({ id, name, space }) =>
    new SendPaymentLetterAPICall().makeRequest({ product_id: id, product_name: name, product_space: space });

function* loadPaidInfo() {
    try {
        const { data } = yield call(new BillingInfoAPICall().makeRequest);
        yield put(loadPaidInfoSuccess(data as ApiPaidInfoResponse));
    } catch (error) {
        yield cancel();
    }
}

function* sendPaymentLetter(action: PayloadAction<{ id: string }>) {
    try {
        const { id } = action.payload;
        const product = yield select(getProductById, id);

        if (!product) {
            return;
        }

        yield sendPaymentLetterApiCall({ id: product.id, space: product.space.original, name: product.space.value });
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* watchPaidInfo() {
    yield takeEvery(loadPaidInfoRequest.toString(), loadPaidInfo);
    yield takeEvery(sendPaymentLetterAction.toString(), sendPaymentLetter);
}
