import { Icon24FolderSimpleOutline, Icon28CloudOutline, Icon28DocumentOutline } from '@vkontakte/icons';
import { ReactComponent as CalendarIcon } from 'img/icons/mobile/calendar.svg';
import { ReactComponent as EmailIcon } from 'img/icons/mobile/email.svg';
import { ReactComponent as FolderIcon } from 'img/icons/mobile/folder.svg';
import { ReactComponent as FolderMoveInIcon } from 'img/icons/mobile/folderMoveIn.svg';
import { ReactComponent as CloseLinkAccessIcon } from 'img/icons/mobile/linkAccess.svg';

import type { FileInfoRecord } from './useItems.types';

export const fileInfoItems: FileInfoRecord = {
    filename: {
        id: 'filename',
        section: 'Название',
        Icon: Icon28DocumentOutline,
    },
    letterTitle: {
        id: 'letterTitle',
        section: 'Тема письма',
        Icon: EmailIcon,
    },
    author: {
        id: 'author',
        section: 'От кого',
        Icon: Icon28DocumentOutline,
    },
    uploadDate: {
        id: 'uploadDate',
        section: 'Добавлено',
        Icon: CalendarIcon,
    },
    removeDate: {
        id: 'removeDate',
        section: 'Удалено',
        Icon: CalendarIcon,
    },
    size: {
        id: 'size',
        section: 'Размер',
        Icon: Icon28CloudOutline,
    },
    share: {
        id: 'share',
        section: 'Настроить',
        Icon: CloseLinkAccessIcon,
        rightIcon: 'right',
    },
    direction: {
        id: 'direction',
        section: 'Расположение',
        Icon: Icon24FolderSimpleOutline,
        rightIcon: 'right',
    },
    originalPlace: {
        id: 'originalPlace',
        section: 'Исходное расположение',
        Icon: FolderMoveInIcon,
        rightIcon: 'right',
    },
    letter: {
        id: 'letter',
        section: 'Расположение',
        Icon: FolderIcon,
        rightIcon: 'right',
    },
};
