import { Button } from '@vkontakte/vkui';
import AlbumIcon from 'img/empty-state/album.png';
import AttachesIcon from 'img/empty-state/attaches.png';
import AlbumIconDark from 'img/empty-state/dark/album.png';
import AttachesIconDark from 'img/empty-state/dark/attaches.png';
import FavoritesIconDark from 'img/empty-state/dark/favorites.png';
import FeedAllIconDark from 'img/empty-state/dark/feed_all.png';
import FeedRestIconDark from 'img/empty-state/dark/feed_rest.png';
import GalleryAllIconDark from 'img/empty-state/dark/gallery_all.png';
import GalleryDesktopIconDark from 'img/empty-state/dark/gallery_desktop.png';
import GalleryMobileIconDark from 'img/empty-state/dark/gallery_mobile.png';
import SharedAutodeleteIconDark from 'img/empty-state/dark/shared_autodelete.png';
import SharedIncomingIconDark from 'img/empty-state/dark/shared_incoming.png';
import SharedLinkIconDark from 'img/empty-state/dark/shared_links.png';
import TrashIconDark from 'img/empty-state/dark/trash.png';
import FavoritesIcon from 'img/empty-state/favorites.png';
import FeedAllIcon from 'img/empty-state/feed_all.png';
import FeedRestIcon from 'img/empty-state/feed_rest.png';
import GalleryAllIcon from 'img/empty-state/gallery_all.png';
import GalleryDesktopIcon from 'img/empty-state/gallery_desktop.png';
import GalleryMobileIcon from 'img/empty-state/gallery_mobile.png';
import SharedAutodeleteIcon from 'img/empty-state/shared_autodelete.png';
import SharedIncomingIcon from 'img/empty-state/shared_incoming.png';
import SharedLinkIcon from 'img/empty-state/shared_links.png';
import TrashIcon from 'img/empty-state/trash.png';
import AlbumIconB2b from 'img/empty-state-b2b/album.png';
import AttachesIconB2b from 'img/empty-state-b2b/attaches.png';
import AlbumIconB2bDark from 'img/empty-state-b2b/dark/album.png';
import AttachesIconB2bDark from 'img/empty-state-b2b/dark/attaches.png';
import EmptyB2BIconDark from 'img/empty-state-b2b/dark/empty.png';
import FavoritesIconB2bDark from 'img/empty-state-b2b/dark/favorites.png';
import FeedAllIconB2bDark from 'img/empty-state-b2b/dark/feed_all.png';
import FeedDocumentIconB2bDark from 'img/empty-state-b2b/dark/feed_document.png';
import FeedImageIconB2bDark from 'img/empty-state-b2b/dark/feed_image.png';
import FeedMusicIconB2bDark from 'img/empty-state-b2b/dark/feed_music.png';
import FeedVideoIconB2bDark from 'img/empty-state-b2b/dark/feed_video.png';
import GalleryAllIconB2bDark from 'img/empty-state-b2b/dark/gallery_all.png';
import SharedIncomingIconB2bDark from 'img/empty-state-b2b/dark/shared-incoming.png';
import SharedLinkIconB2bDark from 'img/empty-state-b2b/dark/shared-links.png';
import TrashIconB2bDark from 'img/empty-state-b2b/dark/trash.png';
import EmptyB2BIcon from 'img/empty-state-b2b/empty.png';
import FavoritesIconB2b from 'img/empty-state-b2b/favorites.png';
import FeedAllIconB2b from 'img/empty-state-b2b/feed_all.png';
import FeedDocumentIconB2b from 'img/empty-state-b2b/feed_document.png';
import FeedImageIconB2b from 'img/empty-state-b2b/feed_image.png';
import FeedMusicIconB2b from 'img/empty-state-b2b/feed_music.png';
import FeedVideoIconB2b from 'img/empty-state-b2b/feed_video.png';
import GalleryAllIconB2b from 'img/empty-state-b2b/gallery_all.png';
import SharedIncomingIconB2b from 'img/empty-state-b2b/shared-incoming.png';
import SharedLinkIconB2b from 'img/empty-state-b2b/shared-links.png';
import TrashIconB2b from 'img/empty-state-b2b/trash.png';
import React from 'react';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { onClickCreateNew } from 'reactApp/ui/Datalist/UserActions/onClickAction';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import { Albums } from './components/Albums';
import { SharedAutoDelete } from './components/SharedAutoDelete';
import styles from './Empty.css';
import type { CategoryType, Config } from './Empty.types';

const getIcon = (b2cIcon: any, b2cIconDark: any, b2bIcon: any, b2bIconDark: any) => ({
    icon: IS_B2B_BIZ_USER ? b2bIcon : b2cIcon,
    iconDark: IS_B2B_BIZ_USER ? b2bIconDark : b2cIconDark,
});

const icons = {
    sharedLink: getIcon(SharedLinkIcon, SharedLinkIconDark, SharedLinkIconB2b, SharedLinkIconB2bDark),
    sharedIncoming: getIcon(SharedIncomingIcon, SharedIncomingIconDark, SharedIncomingIconB2b, SharedIncomingIconB2bDark),
    gallery: getIcon(GalleryAllIcon, GalleryAllIconDark, GalleryAllIconB2b, GalleryAllIconB2bDark),
    favorites: getIcon(FavoritesIcon, FavoritesIconDark, FavoritesIconB2b, FavoritesIconB2bDark),
    feedAll: getIcon(FeedAllIcon, FeedAllIconDark, FeedAllIconB2b, FeedAllIconB2bDark),
    feedDocument: getIcon(FeedRestIcon, FeedRestIconDark, FeedDocumentIconB2b, FeedDocumentIconB2bDark),
    feedImage: getIcon(FeedRestIcon, FeedRestIconDark, FeedImageIconB2b, FeedImageIconB2bDark),
    feedMusic: getIcon(FeedRestIcon, FeedRestIconDark, FeedMusicIconB2b, FeedMusicIconB2bDark),
    feedVideo: getIcon(FeedRestIcon, FeedRestIconDark, FeedVideoIconB2b, FeedVideoIconB2bDark),
    attaches: getIcon(AttachesIcon, AttachesIconDark, AttachesIconB2b, AttachesIconB2bDark),
    trashbin: getIcon(TrashIcon, TrashIconDark, TrashIconB2b, TrashIconB2bDark),
    album: getIcon(AlbumIcon, AlbumIconDark, AlbumIconB2b, AlbumIconB2bDark),
};

const configByStorage = new Map<EStorageType, Config | Map<CategoryType | undefined, Config>>()
    .set(EStorageType.sharedLinks, {
        ...icons.sharedLink,
        title: IS_B2B_BIZ_USER ? 'Делитесь' : 'Вы пока ничем\nне поделились',
        description: 'Создавайте общие папки и отправляйте ссылки\nна файлы — всё появится здесь',
        BottomContent: () => (
            <Button
                mode="secondary"
                appearance="neutral"
                size="m"
                className={styles.button}
                onClick={(e) => {
                    sendEmptyStatesAnalitics({ action: 'shared-links' });

                    onClickCreateNew(e, { gaSuffix: '', storage: EStorageType.sharedLinks });
                }}
            >
                Создать общую папку
            </Button>
        ),
    })
    .set(EStorageType.sharedIncoming, {
        ...icons.sharedIncoming,
        title: 'Пока нет доступных файлов',
        description: (
            <>
                Когда с вами поделятся ссылкой, файлы будут{'\n'}здесь — проверены антивирусом Касперского.{'\n'}
                {!IS_B2B_BIZ_USER && (
                    <a
                        data-qa-id="empty-link"
                        href="https://whatsnew.mail.ru/promo/security?utm_source=cloud&utm_medium=emptystate&utm_campaign=sharedwithyou"
                        onClick={() => {
                            sendEmptyStatesAnalitics({ action: 'shared-incoming' });
                        }}
                    >
                        Подробнее о безопасности
                    </a>
                )}
            </>
        ),
    })
    .set(
        EStorageType.feed,
        new Map<CategoryType | undefined, Config>()
            .set(undefined, {
                ...icons.feedAll,
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: 'Пока нет файлов',
                description: 'Когда загрузите, всё будет здесь —\nотсортировано по дате добавления',
            })
            .set(FeedCategoryType.all, {
                ...icons.feedAll,
                title: 'Пока нет файлов',
                description: 'Когда загрузите, всё будет здесь —\nотсортировано по дате добавления',
            })
            .set(FeedCategoryType.image, {
                ...icons.feedImage,
                title: 'Пока нет изображений',
                description: 'Фотографии, картинки, скриншоты — всё будет\nздесь и быстро найдётся по дате добавления',
            })
            .set(FeedCategoryType.document, {
                ...icons.feedDocument,
                title: 'Пока нет документов',
                description: 'Архивы, книги, сканы — загружайте\nи открывайте сразу в PDF, ZIP, EPUB и других\nформатах',
            })
            .set(FeedCategoryType.audio, {
                ...icons.feedMusic,
                title: 'Пока нет звуковых файлов',
                description: 'Музыка, подкасты, аудиокниги — загружайте\nи слушайте прямо в Облаке',
            })
            .set(FeedCategoryType.video, {
                ...icons.feedVideo,
                title: 'Пока нет видео',
                description: 'Загружайте и смотрите прямо в Облаке,\nуправляйте скоростью и качеством картинки',
            })
    )
    .set(EStorageType.favorites, {
        ...icons.favorites,
        title: 'Пока нет избранных файлов',
        description: 'Ставьте сердечко на важных и любимых\nфайлах — всё появится здесь',
        BottomContent: () => (
            <Button
                mode="secondary"
                appearance="neutral"
                size="m"
                className={styles.button}
                Component="a"
                href="/home"
                onClick={() => {
                    sendEmptyStatesAnalitics({ action: 'favorites-click' });
                }}
            >
                Ко всем файлам
            </Button>
        ),
    })
    .set(EStorageType.albums, {
        ...icons.album,
        title: 'Пока нет альбомов',
        description: 'Соберите тематическую коллекцию фото или видео — всё будет здесь',
        BottomContent: Albums.BottomContent,
    })
    .set(EStorageType.attaches, {
        ...icons.attaches,
        title: 'Пока нет файлов из Почты',
        description: (
            <>
                Здесь будут все вложения из писем{'\n'}
                {!IS_B2B_BIZ_USER && (
                    <a data-qa-id="empty-link" href={getQuotaPromoUrl({ query: PaymentUTM.emptyAttaches })}>
                        Подробнее
                    </a>
                )}
            </>
        ),
    })
    .set(EStorageType.trashbin, {
        ...icons.trashbin,
        title: 'В корзине пусто',
        description: 'Здесь файлы хранятся 14 дней, после этого удаляются навсегда',
    })
    .set(EStorageType.sharedAutodelete, {
        icon: SharedAutodeleteIcon,
        title: 'Пока нет автоудаляемых\nпапок',
        iconDark: SharedAutodeleteIconDark,
        description: SharedAutoDelete.Description,
        BottomContent: SharedAutoDelete.BottomContent,
    });

export const useConfigByStorage = ({ isMobile }: { isMobile?: boolean }) => {
    if (isMobile) {
        configByStorage
            .set(EStorageType.gallery, {
                icon: GalleryAllIcon,
                iconDark: GalleryAllIconDark,
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: 'Галерея ваших фото\nи видео',
                description: 'Загружайте файлы с телефона, чтобы освободить память для новых снимков',
            })
            .set(EStorageType.home, {
                icon: FeedAllIcon,
                iconDark: FeedAllIconDark,
                title: 'Загрузите первый файл',
                description: 'Чтобы быстро находить нужное, пользуйтесь сортировкой и поиском',
            });

        return configByStorage;
    }
    const galleryTitle = 'Пока ничего нет';
    const galleryDescription = IS_B2B_BIZ_USER ? '' : 'Сохраняйте фото и видео с разных устройств и смотрите истории с лучшими моментами';
    const galleryConfig: Config = {
        ...icons.gallery,
        title: galleryTitle,
        description: galleryDescription,
    };
    const galleryDesktopConfig: Config = {
        icon: GalleryDesktopIcon,
        iconDark: GalleryDesktopIconDark,
        title: 'Пока нет фото и видео',
        description: 'Загрузите файлы — сможете смотреть\nих на любых устройствах',
    };

    const galleryMobileConfig: Config = {
        icon: GalleryMobileIcon,
        iconDark: GalleryMobileIconDark,
        title: <>Пока нет фото и видео</>,
        description: 'Установите Облако на телефон и включите автозагрузку галереи — освободите память для новых кадров',
    };

    const emptySharedFolders: Config = {
        icon: EmptyB2BIcon,
        iconDark: EmptyB2BIconDark,
        title: 'Пока нет общих папок',
        description: '',
    };
    configByStorage
        .set(
            EStorageType.gallery,
            new Map<GalleryCategoryType | undefined, Config>()
                .set(GalleryCategoryType.all, galleryConfig)
                .set(GalleryCategoryType.images, galleryConfig)
                .set(GalleryCategoryType.videos, galleryConfig)
                .set(GalleryCategoryType.desktop, galleryDesktopConfig)
                .set(GalleryCategoryType.mobile, galleryMobileConfig)
        )
        .set(EStorageType.documents, {
            icon: GalleryMobileIcon,
            iconDark: GalleryMobileIconDark,
            title: 'Установите Облако на смартфон',
            description: 'Включите автозагрузку фото и видео, и в смартфоне появится больше свободного места',
        })
        .set(EStorageType.home, emptySharedFolders);

    if (IS_B2B_BIZ_USER) {
        configByStorage.delete(EStorageType.documents);
    }
    return configByStorage;
};
