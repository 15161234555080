import type { FC } from 'react';

export enum SearchActions {
    close = 'close',
    removeType = 'removeType',
    setFocus = 'setFocus',
    setType = 'setType',
    setActive = 'setActive',
    setValue = 'setValue',
    setOpenFilters = 'setOpenFilters',
    toggleFilters = 'toggleFilters',
    searchValue = 'searchValue',
}

export enum SearchOptionType {
    image = 'image',
    video = 'video',
    media = 'media',
}

export interface SearchState {
    active: boolean;
    focus: boolean;
    open: boolean;
    value: string;
    type: SearchOptionType;
}

export interface SearchAction {
    type: SearchActions;
    payload?: boolean | string | SearchOptionType;
}

export interface SearchFileTypeOption {
    id: string;
    text: string;
    icon: JSX.Element | FC;
}
