import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React from 'react';
import { renderNewComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type { ITotalQuota } from 'reactApp/modules/userQuota/userQuota.types';

import styles from './FamilySettings.css';

export const openFamilyDeleteModal = ({
    deleteAll,
    user,
    onClose,
    onAction,
}: {
    deleteAll?: boolean;
    user?: string;
    onAction: () => void;
    onClose: () => void;
}) => {
    const header = deleteAll ? 'Удалить всех из подписки?' : 'Удалить участника из подписки?';
    const text = (
        <div className={styles.deleteText}>
            {deleteAll ? (
                'Ваши близкие потеряют дополнительное место в Облаке.'
            ) : (
                <>
                    Участник <b>{user}</b> потеряет дополнительное место в Облаке.
                </>
            )}
        </div>
    );

    renderNewComfirmationDialog({
        renderHeader: () => header,
        renderContent: () => text,
        buttons: [EButtonType.action, EButtonType.cancel],
        actionButtonText: deleteAll ? 'Удалить всех' : 'Удалить',
        closeOnDimmerClick: true,
        onClose,
        onAction,
        size: 'tiny',
        isNegative: true,
        dataQAId: `family-delete-${deleteAll ? 'all' : 'user'}${EnvironmentSelectors.isPhone() ? '-mobile' : ''}`,
    });
};

export const openFamilyOverquotaModal = ({
    quota,
    onClose,
    onSuccess,
}: {
    quota: ITotalQuota;
    onSuccess: () => void;
    onClose: () => void;
}) => {
    const { totalSize = 0, usedSize = 0 } = quota;

    const text = (
        <>
            Ваше хранилище переполнено: занято {bytesToNDigits(usedSize, 3).value} из {bytesToNDigits(totalSize, 3).value}.
            <br /> Купите дополнительное место, чтобы пользоваться Облаком без ограничений и сохранить доступ к файлам.
        </>
    );

    renderNewComfirmationDialog({
        renderHeader: () => 'У вас закончилось свободное место',
        renderContent: () => text,
        buttons: [EButtonType.accept, EButtonType.cancel],
        successButtonText: 'Увеличить место',
        closeOnDimmerClick: true,
        onClose,
        onSuccess,
        size: 'tiny',
        dataQAId: `family-overquota${EnvironmentSelectors.isPhone() ? '-mobile' : ''}`,
    });
};

export const openFamilyLeaveModal = ({ onClose, onAction }: { onAction: () => void; onClose: () => void }) => {
    renderNewComfirmationDialog({
        renderHeader: () => 'Покинуть подписку?',
        renderContent: () => 'Вы потеряете доступ к подписке Mail Space и дополнительное место в Облаке',
        buttons: [EButtonType.action, EButtonType.cancel],
        actionButtonText: 'Покинуть подписку',
        closeOnDimmerClick: true,
        onClose,
        onAction,
        size: 'tiny',
        isNegative: true,
        dataQAId: `family-leave${EnvironmentSelectors.isPhone() ? '-mobile' : ''}`,
    });
};
