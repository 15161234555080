import api from 'Cloud/Application/api';
import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateWeblinksV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'weblinks/autodelete';

class WeblinksAutodeleteOnAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const weblinksAutodeletecOnApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateWeblinksV2ToV4.autodelete) {
        return new WeblinksAutodeleteOnAPIV4Call().makeRequest({
            weblink_id: params.weblink,
            enable: true,
        });
    }

    return promisifyDeferredCall(api.weblinks.autodelete.on, params);
};
