import type { ThemeModel } from '@mail/theme-tool';
import { Icon28Done } from '@vkontakte/icons';
import { Headline } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useMemo } from 'react';

import styles from './ThemeChooserItem.css';

interface ThemeChooserItemProps {
    themeModel: ThemeModel & {
        preview?: string;
        title?: string;
    };
    isSelected?: boolean;
    onClick: () => void;
}

const customTitle = {
    default: 'Светлая',
    t5000: 'Темная',
};

export function ThemeChooserItem({ isSelected, themeModel, onClick }: ThemeChooserItemProps) {
    const { colorized, title, preview, id } = themeModel;

    const inlineStyle = useMemo<React.CSSProperties>(
        () => (colorized ? { backgroundColor: `${colorized}` } : { backgroundImage: `url(${preview})` }),
        [colorized, preview]
    );
    const isDefaultTheme = id === 'default';
    return (
        <div
            className={classNames(styles.container, {
                [styles.colorized]: colorized,
            })}
        >
            <div
                className={classNames(styles.item, {
                    [styles.isSelected]: isSelected,
                    [styles.bordered]: isDefaultTheme,
                })}
                onClick={onClick}
                style={inlineStyle}
            >
                <div className={styles.overlay}>
                    {isSelected && (
                        <div className={styles.iconWrapper}>
                            <Icon28Done />
                        </div>
                    )}
                </div>
                <Headline level="1" weight="2" className={styles.title}>
                    {customTitle[id] || title}
                </Headline>
            </div>
        </div>
    );
}
