import { Spacing, Text, Title } from '@vkontakte/vkui';
import img from 'img/tooltip/change-public-design-sharing.png';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { QA_VALUE } from 'reactApp/components/SharingWindow/Sharing.constants';
import stylesSharing from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { SharingPublicThemePreview } from 'reactApp/ui/PublicThemePreview/SharingPublicThemePreview/SharingPublicThemePreview';
import { sendDwh } from 'reactApp/utils/ga';

import styles from './ChangeDesign.css';
type ChangeDesignProps = {
    item?: PublishItem;
};

const TOOLTIP_ID = 'changePublicDesignSharing';

export const ChangeDesign = memo<ChangeDesignProps>(({ item }) => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement | null>(null);
    const isTooltipShown = Boolean(dispatch(UserStorageActions.get(TOOLTIP_ID)));

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_BRAND_PAGE_SHOW);
        sendDwh({ eventCategory: 'sharing-brand-page', action: 'show' });
        dispatch(UserStorageActions.set(TOOLTIP_ID, new Date().valueOf()));
    }, []);

    const onTooltipShow = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_SHOW);
        sendDwh({ eventCategory: 'sharing-tooltip-brand-page', action: 'show' });
    }, []);
    const onTooltipClose = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_CLOSE);
        sendDwh({ eventCategory: 'sharing-tooltip-brand-page', action: 'close' });
    }, []);

    return (
        <div className={stylesSharing.rightItem} data-qa-item={QA_VALUE.changeDesign}>
            <span className={stylesSharing.rightTitle}>Оформление для папки</span>
            <div className={stylesSharing.dropdown} ref={ref}>
                {item?.weblink && <SharingPublicThemePreview item={item} publicId={item?.weblink} />}
            </div>
            {!isTooltipShown && (
                <FloatingTooltip
                    target={ref}
                    placement={ETooltipPlacement.right}
                    qaId="change-public-design-sharing-tooltip"
                    withinModal
                    closable
                    closeOnOutsideClick
                    usePortal
                    onShow={onTooltipShow}
                    onClose={onTooltipClose}
                >
                    <div className={styles.tooltip}>
                        <Title className={styles.tooltipTitle}>
                            Оформление с подпиской
                            <br />
                            Mail Space
                        </Title>
                        <Spacing size={4} />
                        <Text className={styles.tooltipText}>
                            Светлый, тёмный или с картинками —
                            <br />
                            задавайте свой цвет настроения
                            <br />в папках, которыми делитесь
                        </Text>
                        <img src={img} alt="Оформление с подпиской Mail Space" className={styles.image} />
                    </div>
                </FloatingTooltip>
            )}
        </div>
    );
});

ChangeDesign.displayName = 'ChangeDesign';
