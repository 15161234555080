import { parse, stringify } from 'qs';
import { convertFB2toEPUB } from 'reactApp/appHelpers/featuresHelpers';
import { openConvertFb2ToEpubDialog } from 'reactApp/components/ConvertFb2ToEpubDialog/helpers';
import type { NavItem } from 'reactApp/modules/ebook/ebook.types';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const findChapterByHref = (chapters?: NavItem[], href?: string) => {
    if (!chapters?.length) {
        return;
    }

    for (const item of chapters) {
        if (item.href.split('#')[0] === href) {
            return item;
        }

        const chapter = findChapterByHref(item.subitems, href);
        if (chapter) {
            return chapter;
        }
    }
};

export const openEbookReader = (storage: EStorageType | null | undefined, path: string, ext?: string, from = '') => {
    const search = parse(window.location.search, { ignoreQueryPrefix: true }) || {};

    search['dialog'] = popupNames.EBOOK_READER;
    if (from) {
        search['from'] = from;
    }

    if (convertFB2toEPUB && ext?.toLowerCase() === 'fb2') {
        openConvertFb2ToEpubDialog({ fileId: path });
        return;
    }

    const fixedStorage = storage === EStorageType.viewerAttaches ? EStorageType.attaches : storage;
    const fixedPath = path?.[0] === '/' ? path : `/${path}`;

    const url = `${location.origin}/${fixedStorage}${fixedPath}${stringify(search, {
        addQueryPrefix: true,
        skipNulls: true,
    })}`;

    open(url, '_blank');
};
