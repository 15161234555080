import { differenceInMinutes } from 'date-fns';
import { type MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { unsavedChangesForegroundTimeout } from 'reactApp/appHelpers/themeToolHelpers/default-values';

/** Показываем сообщение о покупке тарифа после того, как кастомер переключился на другую вкладку и вернулся
 * - Таймаут 1 минута
 * - Не показываем для платника (передаем в showAlert)
 * - Не показываем, если уже показывали, но обновление страницы запускает механизм показа заново (цель спровоцировать купить тариф)
 * - показываем, даже если пользователь ничего не выбрал (цель спровоцировать купить тариф)
 */

const action = (ref: MutableRefObject<number>, action: () => void, setDisplayed: (v: boolean) => void, showAlert: boolean) => {
    if (!showAlert) {
        return;
    }
    const now = Date.now();
    if (document.visibilityState === 'visible') {
        if (differenceInMinutes(now, ref.current) >= unsavedChangesForegroundTimeout) {
            action();
            setDisplayed(true);
            document.removeEventListener('visibilitychange', action);
        }
    } else {
        ref.current = now;
    }
};

export function useForegroundTabAlert(showUnsavedChangesModal: () => void, showAlert: boolean) {
    const ref = useRef(Date.now());
    const [isDisplayed, setDisplayed] = useState(false);
    const cb = useCallback(() => action(ref, showUnsavedChangesModal, setDisplayed, showAlert), [showUnsavedChangesModal, showAlert]);

    useEffect(() => {
        if (!isDisplayed) {
            document.addEventListener('visibilitychange', cb);
        }
        return () => document.removeEventListener('visibilitychange', cb);
    }, [isDisplayed, cb]);
}
