import { type PayloadAction, createReducer } from '@reduxjs/toolkit';
import { removeFileSuccess } from 'reactApp/modules/modifying/modifying.actions';
import type { RemoveFileSuccessAction } from 'reactApp/modules/modifying/modifying.types';
import type { Reducer } from 'redux';

import type {
    IRange,
    ISelectOne,
    ISelectSeveral,
    ISelectSome,
    ISelectTo,
    IToggle,
    IToggleAll,
    ScrollToItemState,
    State,
} from './selection.types';
import {
    resetSelect,
    selectOne,
    selectSeveral,
    selectSome,
    selectTo,
    setDragging,
    setScrollToItemState,
    startSelecting,
    toggle,
    toggleAll,
} from './selections.actions';

const initialState: State = {
    selected: {},
    isDragging: false,
    lastSelectedIdx: '',
    isSelecting: false,
    scrollToItemState: { itemId: '', needScroll: false },
    selectionRange: {
        offset: 0,
        rangeStartId: '',
    },
};

export const selectionsReducer: Reducer = createReducer(initialState, {
    [selectOne.type]: (state, action: PayloadAction<ISelectOne>) => {
        const { selectedIdx, storage } = action.payload;

        return {
            ...state,
            storage,
            selected: {
                [selectedIdx]: true,
            },
            lastSelectedIdx: selectedIdx,
            selectionRange: {
                rangeStartId: selectedIdx,
                offset: 0,
            },
        };
    },
    [selectSeveral.type]: (state, action: PayloadAction<ISelectSeveral>) => {
        const { selectedIdxs, storage } = action.payload;

        return {
            ...state,
            storage,
            selected: selectedIdxs.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
            lastSelectedIdx: selectedIdxs[selectedIdxs.length - 1],
        };
    },
    [resetSelect.type]: () => {
        return initialState;
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const selected = { ...state.selected };
        action.payload.ids.forEach((id) => {
            delete selected[id];
        });

        return {
            ...state,
            selected,
            selectionRange: {
                rangeStartId: '',
                offset: 0,
            },
        };
    },
    [selectSome.type]: (state, action: PayloadAction<ISelectSome>) => {
        const { rangeStartId = state.selectionRange?.rangeStartId || '', offset = 0 } = action.payload.selectionRange || {};

        return {
            ...state,
            lastSelectedIdx: '',
            storage: action.payload.storage,
            selected: action.payload.selectedIdxs.reduce(
                (result, item) => ({
                    ...result,
                    [item]: true,
                }),
                {}
            ),
            selectionRange: {
                rangeStartId,
                offset,
            },
        };
    },
    [toggle.type]: (state, action: PayloadAction<IToggle>) => {
        const selected = { ...state.selected };
        let lastSelectedIdx = state.lastSelectedIdx;
        let selectionRange: IRange | null = null;
        const { selectedIdx, storage } = action.payload;

        if (selected[selectedIdx]) {
            delete selected[selectedIdx];
        } else {
            selected[selectedIdx] = true;
            lastSelectedIdx = selectedIdx;
            selectionRange = {
                rangeStartId: selectedIdx,
                offset: 0,
            };
        }

        return {
            ...state,
            selected,
            lastSelectedIdx,
            storage,
            selectionRange,
        };
    },
    [toggleAll.type]: (state, action: PayloadAction<IToggleAll>) => {
        const selectAll = Object.keys(state.selected).length === action.payload.allIdxs.length;
        const lastSelectedIdx = selectAll ? action.payload.allIdxs[action.payload.allIdxs.length - 1] : '';

        return {
            ...state,
            lastSelectedIdx,
            storage: action.payload.storage,
            selectionRange: {
                rangeStartId: lastSelectedIdx,
                offset: 0,
            },
            selected: selectAll
                ? {}
                : action.payload.allIdxs.reduce(
                      (result, item) => ({
                          ...result,
                          [item]: true,
                      }),
                      {}
                  ),
        };
    },
    [selectTo.type]: (state, action: PayloadAction<ISelectTo>) => {
        const lastSelectedIndex = action.payload.allIdxs.indexOf(state.lastSelectedIdx);
        const currentIndex = action.payload.allIdxs.indexOf(action.payload.currentId);
        const alreadySelected = state.selected[action.payload.currentId];
        let ids: string[] = [];

        if (alreadySelected) {
            return state;
        }

        if (lastSelectedIndex !== -1 && !alreadySelected) {
            ids = action.payload.allIdxs.slice(
                lastSelectedIndex < currentIndex ? lastSelectedIndex : currentIndex,
                (lastSelectedIndex > currentIndex ? lastSelectedIndex : currentIndex) + 1
            );
        } else {
            ids = action.payload.allIdxs.slice(0, currentIndex + 1);
        }

        return {
            ...state,
            storage: action.payload.storage,
            lastSelectedIdx: action.payload.currentId,
            selectionRange: {
                rangeStartId: action.payload.currentId,
                offset: 0,
            },
            selected: {
                ...state.selected,
                ...ids.reduce(
                    (result, item) => ({
                        ...result,
                        [item]: true,
                    }),
                    {}
                ),
            },
        };
    },
    [setDragging.type]: (state, action: PayloadAction<boolean>) => {
        state.isDragging = action.payload;
    },
    [startSelecting.type]: (state) => {
        state.isSelecting = true;
    },
    [setScrollToItemState.type]: (state, action: PayloadAction<ScrollToItemState>) => {
        const { payload } = action;
        state.scrollToItemState = payload;
    },
});
