import { connect } from 'react-redux';
import { getFeatureAbAutoDelete } from 'reactApp/modules/features/features.selectors';
import { getDiscountGiftByProductId } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { getDiscountByProductId } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import type { ITariffCardMapStateToProps } from 'reactApp/ui/TariffCard/TariffCard.types';
import { TariffCardComponent } from 'reactApp/ui/TariffCard/TariffCardComponent';

const mapStateToProps = (state, props): ITariffCardMapStateToProps => {
    const promocode = (getDiscountByProductId as any)(state, props.id) || (getDiscountGiftByProductId as any)(state, props.id);
    const autodeleteAb = getFeatureAbAutoDelete(state);
    const isAutoDeleteForPaid = autodeleteAb?.data === 'paid';

    return {
        promocode,
        isAutoDeleteForPaid,
    };
};

export const TariffCard = connect(mapStateToProps)(TariffCardComponent);
