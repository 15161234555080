import classNames from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './Checkbox.css';

export default function Checkbox({ id, label, selected, onClick, mod }) {
    const _id = `check_${  id}`;
    return (
        <div className={styles.root}>
            <input
                key={_id}
                className={classNames({
                    [styles.checkbox]: true,
                    [styles[mod]]: !!mod,
                })}
                type="checkbox"
                checked={!!selected}
                onChange={onClick}
            />
            <label onClick={onClick}>{label}</label>
        </div>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    mod: PropTypes.oneOf(['blue', 'new', 'round']),
};

Checkbox.defaultProps = {
    onClick: noop,
    label: '',
    selected: false,
    mod: '',
    id: '',
};
