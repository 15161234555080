import React, { type ReactElement, memo, useEffect, useRef, useState } from 'react';
import { PUBLIC_SHOVE, PUBLIC_SHOVE_TIMEOUT } from 'reactApp/appHelpers/configHelpers';

import styles from './ShoveFrame.css';

interface IProps {
    onLoad(): void;
    onTimeOut(): void;
    onError(): void;
}

const PixelAnalytics = () => {
    return (
        <>
            {PUBLIC_SHOVE?.show_pixels?.map((pixel) => (
                <img key={pixel} className={styles.pixel} width="0" height="0" alt="" src={pixel} />
            ))}
        </>
    );
};

export const ShoveFrame = memo(function ShoveFrame({ onLoad, onTimeOut, onError }: IProps): ReactElement {
    const frameRef = useRef<HTMLIFrameElement>(null);
    const timer = useRef<number>();
    const [isShown, setShown] = useState<boolean>(false);

    useEffect(() => {
        timer.current = window.setTimeout(onTimeOut, PUBLIC_SHOVE_TIMEOUT);
    }, []);

    const onIframeLoad = () => {
        window.clearTimeout(timer.current);
        onLoad();
        // Отправляем пиксели только после того, как загрузится брендирование
        setShown(true);
    };

    return (
        <>
            <iframe className={styles.frame} src={PUBLIC_SHOVE?.frame} ref={frameRef} onError={onError} onLoad={onIframeLoad} />
            {isShown && <PixelAnalytics />}
        </>
    );
});
