import { createAction } from '@reduxjs/toolkit';
import type { AttachesSearchParams } from 'reactApp/modules/attaches/attaches.types';

// Не в файле attaches.reducer, так как где-то есть циклическая зависимость
export const attachesFetchRequest = createAction<AttachesSearchParams>('attaches/fetch/request');
export const attachesFetchSuccess = createAction<{ noOffset?: boolean; after; attaches; hasMoreToLoad: boolean }>('attaches/fetch/success');
export const attachesFetchFailure = createAction<any>('attaches/fetch/failure');
export const resetAttachPage = createAction('attaches/resetPage');
export const attachesViewerSuccess = createAction<{ attaches; messageId: string }>('attaches/viewer/save');
export const attachesLoadMoreRequest = createAction<AttachesSearchParams>('attaches/loadMore');
export const attachesPageStart = createAction<{ initialId: string; messageId?: string }>('attaches/pageStart');
export const attachesViewerLoadMore = createAction<any>('attaches/viewerLoadMore');
export const attachesOpenViewer = createAction<{ id: string; attachType?: string; folderId?: string }>('attaches/openViewer');
export const attachesChangePath = createAction<{ path: string }>('attaches/changePath');
export const attachFoldersRequest = createAction('attaches/attachFoldersRequest');
export const requestFoldersStart = createAction('attaches/requestFoldersStart');
export const successFolders = createAction<any>('attaches/successFolders');
export const failureFolders = createAction<string>('attaches/failureFolders');
export const setIsFromAttaches = createAction<boolean>('attaches/setIsFromAttaches');
export const setAttachesId = createAction<string | null>('attaches/setAttachesId');
