import React, { type ReactElement, memo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './SelectAlbumPopupEmpty.css';

export const SelectAlbumPopupEmpty = memo(function SelectAlbumPopupEmpty({ onCreateAlbum }: { onCreateAlbum(): void }): ReactElement {
    return (
        <div className={styles.empty} data-qa-id="select-album-empty">
            <div className={styles.folder} data-qa-id="folder" />
            <span className={styles.title} data-qa-id="title">
                У вас пока нет альбомов
            </span>
            <span className={styles.text} data-qa-id="text">
                Создайте свой первый альбом и добавляйте в него фото
            </span>

            <Button theme="vk" primary className={styles.button} onClick={onCreateAlbum}>
                Создать альбом
            </Button>
        </div>
    );
});
