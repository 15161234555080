import { Icon28BlockOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as ErrorIcon } from 'img/overquota-share/error.svg';
import React, { type ReactElement, createRef, PureComponent } from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import { MobileDialog } from '../Mobile/MobileDialog/MobileDialog';
import styles from './DisabledFeatureDialog.css';

const sendGa = createGaSender('overqouta');

const getTitle = (disabledFeature) => {
    if (disabledFeature === DisabledFeature.publish) {
        return isRebranding ? 'публичного доступа' : 'открытия публичного доступа';
    }

    if (disabledFeature === DisabledFeature.newFile) {
        return 'создания новых файлов';
    }

    if (disabledFeature === DisabledFeature.createAlbum) {
        return 'создания альбома';
    }

    return 'создания новых папок';
};

export interface Props {
    disabledFeature: DisabledFeature;
    overquotaSize: string;
    onClose();
    onBuy();
    isBizUser: boolean;
}

export class DisabledFeatureDialog extends PureComponent<Props> {
    private contentRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        onClose: noop,
        onBuy: noop,
    };

    public componentDidMount() {
        const { disabledFeature } = this.props;

        scrollLock(this.contentRef.current);

        sendGa(disabledFeature, 'show');
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.contentRef.current);
    }

    private handleClose = () => {
        const { disabledFeature } = this.props;

        sendGa(disabledFeature, 'close-click', null, true);

        this.props.onClose();
    };

    private handleBuy = () => {
        const { disabledFeature } = this.props;

        sendGa(disabledFeature, 'buy-click', null, true);

        this.props.onBuy();
    };

    private renderFooter = () => {
        let buttonSize = isRebranding ? ButtonSizeMode.extraBig : ButtonSizeMode.big;

        if (!IS_PHONE_BROWSER) {
            buttonSize = isRebranding ? ButtonSizeMode.small : ButtonSizeMode.big;
        }

        return (
            <div className={styles.footer}>
                <div className={styles.buttonWrapper}>
                    <Button
                        className={styles.button}
                        theme="vk"
                        primary={!isRebranding}
                        error={isRebranding}
                        middle
                        data-name="action"
                        onClick={this.handleBuy}
                        sizeMode={buttonSize}
                    >
                        Купить дополнительное место
                    </Button>
                </div>
            </div>
        );
    };

    private renderDescription = () => {
        const { overquotaSize } = this.props;

        if (isRebranding) {
            return (
                <>
                    Объем вашего Облака превышен на&nbsp;<strong>{overquotaSize}</strong>. Доступ к&nbsp;вашим файлам ограничен, но
                    скачивание и&nbsp;удаление остаются доступными.
                </>
            );
        }

        return (
            <>
                Объем вашего Облака превышен на&nbsp;<strong>{overquotaSize}.</strong>
                <br />
                В&nbsp;связи с&nbsp;этим доступ к&nbsp;вашим файлам ограничен.
                <br />
                Остается доступным скачивание и&nbsp;удаление.
            </>
        );
    };

    private renderRecommend = () => {
        const { isBizUser } = this.props;

        if (isBizUser) {
            return 'Обратитесь к администратору домена или удалите лишние файлы, освободив место в\u00A0Облаке.';
        }

        if (isRebranding) {
            return 'Обновите тариф или удалите лишние файлы в\u00A0Облаке.';
        }

        return 'Рекомендуем вам выбрать подходящий тариф или удалить лишние файлы, освободив место в\u00A0Облаке.';
    };

    private renderContent = () => {
        const { disabledFeature, isBizUser } = this.props;

        return (
            <>
                <div className={classNames({ [styles.content]: true, [styles.content_scrollable]: true })} ref={this.contentRef}>
                    <div className={styles.iconWrapper}>
                        {isRebranding ? <Icon28BlockOutline /> : <ErrorIcon width={96} height={96} data-qa-id="errorIcon" />}
                    </div>
                    <div className={styles.title}>Функция {getTitle(disabledFeature)} отключена</div>
                    <div className={styles.description}>{this.renderDescription()}</div>
                    <div className={styles.recommend}>{this.renderRecommend()}</div>
                </div>
                {!isBizUser && this.renderFooter()}
            </>
        );
    };

    public render(): ReactElement | null {
        if (IS_PHONE_BROWSER) {
            return (
                <MobileDialog id="disabledFeature-dialog" onClose={this.handleClose} closeOnDimmerClick={true} mod="confirm" topmost>
                    <div
                        className={classNames(styles.root_phone, {
                            [styles.rebranding]: isRebranding,
                        })}
                    >
                        {this.renderContent()}
                    </div>
                </MobileDialog>
            );
        }

        return (
            <Dialog
                open
                onCancel={this.handleClose}
                disableScrollOnBody={false}
                id="disabledFeature-dialog"
                className={classNames(styles.root, {
                    [styles.rebranding]: isRebranding,
                })}
                size={isRebranding ? 'tiny' : 'small'}
            >
                <Content isModal isLoading={false} hasError={false} scrolling>
                    {this.renderContent()}
                </Content>
            </Dialog>
        );
    }
}
