import { type RefObject, useCallback, useEffect } from 'react';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { getEntries } from 'reactApp/modules/uploading/helpers/dataTransfer.helpers';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import type { EUploadSource, IDescriptorOptions } from 'reactApp/modules/uploading/uploading.types';
import { store } from 'reactApp/store';

interface IProps {
    input?: RefObject<HTMLInputElement> | null;
    disableDrop?: boolean;
    source?: EUploadSource;
}

export const useUploadInputHandlers = ({ input = null, disableDrop = false, source }: IProps = {}) => {
    const onDrop = useCallback(
        (event, data, descriptorOptions: IDescriptorOptions = {}) => {
            const entries = getEntries(data);

            if (entries?.length) {
                store.dispatch(addToUploadQueue({ files: entries, descriptorOptions: { ...descriptorOptions, source } }));
            } else {
                const files = Array.from(data.files) as File[];

                if (files) {
                    store.dispatch(addToUploadQueue({ files, descriptorOptions: { ...descriptorOptions, source } }));
                }
            }
        },
        [source]
    );

    const onUpload = useCallback(
        (files: (File | FileSystemEntry)[], descriptorOptions: IDescriptorOptions = {}) => {
            try {
                const descriptors: any[] = [];

                store.dispatch(addToUploadQueue({ files, descriptorOptions: { ...descriptorOptions, source } }));

                uploadingLog.info({
                    event: 'input files',
                    details: files.length,
                    radarName: 'input-files',
                    radarValue: files.length,
                });

                uploadingLog.info({
                    event: 'enqueue',
                    details: descriptors.length,
                });
            } catch (error: any) {
                snackbarController.showSnackbar({ id: 'uploaderror', text: 'Файлы не найдены' });
                uploadingLog.error(error);
            }
        },
        [source]
    );

    const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | File[], descriptorOptions: IDescriptorOptions = {}) => {
            if (descriptorOptions.continueUpload && Array.isArray(event)) {
                onUpload(event, descriptorOptions);
                return;
            }

            const files = (event as React.ChangeEvent<HTMLInputElement>).target.files;

            if (!files?.length) {
                return;
            }

            onUpload(Array.from(files), descriptorOptions);
        },
        [onUpload]
    );

    const handleEvent = useCallback(
        (
            event: Event | React.ChangeEvent<HTMLInputElement>,
            data: null | Record<string, any>,
            descriptorOptions: IDescriptorOptions = {}
        ) => {
            switch (event.type) {
                case 'drop':
                    onDrop(event, data, { ...descriptorOptions, isDrop: true });

                    break;

                case 'change':
                    onInputChange(event as React.ChangeEvent<HTMLInputElement>, descriptorOptions);

                    break;
            }
        },
        [onDrop, onInputChange]
    );

    const handlerInputChange = useCallback(
        (event: Event | React.ChangeEvent<HTMLInputElement>) => {
            if (disableDrop && event.type === 'drop') {
                return;
            }

            handleEvent(event, null);
        },
        [handleEvent]
    );

    useEffect(() => {
        input?.current?.addEventListener('change', handlerInputChange);

        return () => {
            input?.current?.removeEventListener('change', handlerInputChange);
        };
    }, [input?.current !== null, handlerInputChange]);

    const subscribeToInput = useCallback(
        (input: HTMLInputElement | null) => {
            input?.addEventListener('change', handlerInputChange);
        },
        [handlerInputChange]
    );

    const unsubscribeOfInput = useCallback(
        (input: HTMLInputElement | null) => {
            input?.removeEventListener('change', handlerInputChange);
        },
        [handlerInputChange]
    );

    const processDrop = useCallback(
        (data: DataTransfer) => {
            onDrop(null, data, { checkAllowedExtensions: true, isDrop: true });
        },
        [onDrop]
    );

    return { subscribeToInput, unsubscribeOfInput, processDrop };
};
