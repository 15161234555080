import {
    HomeSidebarAB,
    homeSidebarBigBreakpoint,
    homeSidebarSmallBreakpoint,
    homeSidebarVersion,
} from 'reactApp/appHelpers/featuresHelpers/features/homeSidebarAd';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';

export enum SidebarAdSize {
    None,
    Small,
    Big,
}

export const useSidebarAdSize = (): SidebarAdSize => {
    const { windowWidth } = useWindowSize();

    const canShowBig = homeSidebarVersion === HomeSidebarAB.Big;
    const canShowSmall = canShowBig || homeSidebarVersion === HomeSidebarAB.Small;

    if (canShowBig && windowWidth > homeSidebarBigBreakpoint) {
        return SidebarAdSize.Big;
    }

    if (canShowSmall && windowWidth > homeSidebarSmallBreakpoint) {
        return SidebarAdSize.Small;
    }

    return SidebarAdSize.None;
};
