import axios from 'lib/axios';
import { useEffect, useState } from 'react';
import { sendXray } from 'reactApp/utils/ga';

export const useLetterUrl = (url: string, mimeType: string) => {
    const [blobUrl, setBlobUrl] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        // В урле должен быть autogen_token для того что бы мы могли получить файл
        if (url && url.includes('autogen_token')) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(url, {
                        withCredentials: false,
                        responseType: 'blob',
                        params: { ajax_call: 1 },
                    });
                    const blobUrl = URL.createObjectURL(new Blob([response.data], { type: mimeType }));
                    sendXray('sharing_attach_get_url_success');
                    setBlobUrl(blobUrl);
                } catch (error) {
                    sendXray('sharing_attach_get_url_error');
                    setError(true);
                }
            };

            fetchData();
        }

        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [url]);

    return { blobUrl, error };
};
