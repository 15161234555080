import React, { useCallback, useRef, useState } from 'react';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import type { ViewerEbook, ViewerEbookProps } from 'reactApp/ui/ViewerEbook/ViewerEbook';

import styles from './ViewerEbook.css';

export const withRenderIsVisible = (Component: typeof ViewerEbook) =>
    function WithRenderIsVisible(props: ViewerEbookProps) {
        const readyRef = useRef(false);
        const [isVisible, setIsVisible] = useState(false);

        const onReady = useCallback(() => {
            readyRef.current = true;
            setIsVisible(true);
        }, []);

        const onIntersectingOut = useCallback(() => !readyRef.current && setIsVisible(false), []);
        const onIntersecting = useCallback(() => !readyRef.current && setIsVisible(true), []);

        const intersectingRef = useIntersectionObserver({
            onIntersectingOut,
            onIntersecting,
        });

        return (
            <div ref={intersectingRef} className={styles.visibilityHoc}>
                {isVisible && <Component {...props} onReady={onReady} />}
            </div>
        );
    };
