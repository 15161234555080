import { userKeeper } from '@mail-core/dashboard';
import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import React, { type ReactElement, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IS_B2B_BIZ_USER,
    IS_MY_TEAM,
    IS_PUBLIC_FOLDER,
    IS_PUBLIC_OF_OVERQUOTA,
    NEW_PORTAL_HEADER,
} from 'reactApp/appHelpers/configHelpers';
import { publicOverquota } from 'reactApp/appHelpers/featuresHelpers/features/publicOverquota';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { isOwnPublic, isWeblinkDownloadable } from 'reactApp/modules/public/public.selectors';
import { loadPublicAlbumPageRequest } from 'reactApp/modules/public/publicAlbum.actions';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { File } from 'reactApp/ui/Public/Components/File/File';
import { Folder } from 'reactApp/ui/Public/Components/Folder/Folder';
import { initDisableAdAnalytics } from 'reactApp/ui/Public/Public.helpers';
import { ShovePublic } from 'reactApp/ui/Public/ShovePublic/ShovePublic';
import ReactViewerConnected from 'reactApp/ui/ReactViewer/ReactViewer';
import { filterFiles, startAdsUpdateTime } from 'reactApp/utils/helpers';

import { BreadcrumbsWrapper } from './Components/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { ThemeChooser } from './Components/ThemeChooser/ThemeChooser';
import styles from './Public.css';

interface Props {
    storage: EStorageType;
    noAds: boolean;
    isFolder: boolean;
    isShove: boolean;
    isAlbum: boolean;
    supportThemedPage?: boolean;
    showThemeChooser?: boolean;
}

export const Public = memo(function Public({
    storage,
    noAds,
    isFolder,
    isShove,
    isAlbum,
    supportThemedPage,
    showThemeChooser,
}: Props): ReactElement {
    const showDisableButton = useSelector(getFeatureABDisableAdsSnackbar) !== 'b';
    const dispatch = useDispatch();
    const statusPage = useSelector(selectStatusPage);
    const isDownloadable = useSelector(isWeblinkDownloadable);
    const isOwn = useSelector(isOwnPublic);
    isFolder = isFolder && !isDownloadable;
    const isBizDownlodableFolder = IS_B2B_BIZ_USER && IS_PUBLIC_FOLDER && isDownloadable;
    const showDisabledForOverquotaOwner = IS_PUBLIC_OF_OVERQUOTA && publicOverquota;

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${isAlbum ? 'album' : storage}/`, ''),
        search: location.search,
    });

    useEffect(() => {
        userKeeper.timeEnd('cloud-public-ready');

        initDisableAdAnalytics(storage, showDisableButton);

        dispatch(loadPublicAlbumPageRequest());

        if (isShove || noAds) {
            return;
        }

        const stopAdsIpdate = startAdsUpdateTime(() => Rubles.updateRightCol());

        return () => {
            stopAdsIpdate?.();
        };
    }, [isShove, noAds, showDisableButton]);

    useEffect(() => {
        const uploadFilesFromClipboard = async (e: ClipboardEvent) => {
            if (!e.clipboardData?.files.length) {
                return;
            }

            const files = filterFiles(Array.from(e.clipboardData.files));

            if (!files.length) {
                return;
            }

            e.preventDefault();

            dispatch(
                addToUploadQueue({
                    files,
                })
            );
        };

        document.addEventListener('paste', uploadFilesFromClipboard);
        return () => document.removeEventListener('paste', uploadFilesFromClipboard);
    }, []);

    if (statusPage) {
        return <ErrorPage status={statusPage} />;
    }

    if (isShove) {
        return <ShovePublic storage={storage} />;
    }
    return (
        <div className={classNames(styles.root, { [styles.root_signlePublic]: !isFolder })}>
            <div className={styles.content}>
                {isFolder ? (
                    <div
                        className={classNames(styles.folderContent, {
                            [styles.folderContent_shove]: isShove,
                            [styles.folderContent_right]: !noAds,
                            [styles.folderContent_newHeader]: NEW_PORTAL_HEADER,
                            [styles.folderContent_supportThemedPage]: supportThemedPage,
                        })}
                    >
                        {supportThemedPage ? (
                            <BreadcrumbsWrapper>
                                <Folder noTopBanner={noAds} supportThemedPage showUploadBlock={!showThemeChooser} />
                            </BreadcrumbsWrapper>
                        ) : (
                            <Folder noTopBanner={noAds} />
                        )}
                    </div>
                ) : (
                    <div
                        className={classNames(styles.fileContent, {
                            [styles.fileContent_shove]: isShove,
                        })}
                    >
                        {isShove || showDisabledForOverquotaOwner ? (
                            <File isShove={isShove} />
                        ) : (
                            <ReactViewerConnected
                                embedded={!isBizDownlodableFolder}
                                isViewer
                                disableClose={!IS_MY_TEAM}
                                showLogo={!IS_MY_TEAM}
                                gaCategory="indpnewv"
                            />
                        )}
                    </div>
                )}
            </div>
            {showThemeChooser && isOwn && <ThemeChooser />}
        </div>
    );
});
