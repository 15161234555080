import type { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import type { Count, Kind, SubKind, Url } from 'reactApp/types/Tree';

export interface ViewerItem {
    id: string;
    mimeType: string;
    type: Kind;
    kind: Kind;
    subKind: SubKind;
    ext: string;
    ext2: string;
    icon: string;
    size: number;
    isInFavorites: boolean;
    weblink?: string;
    thumbnails?: {
        [key: string]: string;
    };
    filename: {
        name: string;
        extension: string;
    };
    url: Url;
    isR7: boolean;
    lightweightLoadCheck: boolean;
    viewType: string;
    downloadUrl: string;
    viewUrl: string;
    posterUrl: string;
    mediaUrl: string;
    getThumbnailImageUrl?();
}

export enum EArchiveType {
    zip = 'zip',
    rar = 'rar',
    _7z = '7z',
}

export interface IViewerArchiveState {
    count?: Count;
    error?: string;
    archiveId?: string;
    isLoaded?: boolean;
    type: EArchiveType;
    list: string[];
    archiveItems: Record<string, IArchiveItem>;
    currentFolderId: string | null;
    currentActiveId: string | null;
    prolongLink?: string;
    prolongTtl?: number | null;
}

export interface ViewerState {
    isActive: boolean;
    isOpening: boolean;
    itemId: string;
    itemIdxInArray: number | null;
    item: CloudItem | IStoryFileItem | null;
    itemStorage: EStorageType | null;
    itemIds: string[];
    gaSuffix: string;
    isOpenAction: boolean;
    hasMoreToLoad?: boolean;
    totalCount: number;
    archive: IViewerArchiveState | null;
    ovidiusZoom: Record<string, number>;
    fromCloud?: boolean;
    isFirstClosed?: boolean; // хотя бы раз сработал closeViewer
}

export interface SetViewerItemIdAction {
    id: string;
}

export type SelectItemIndexInArrayAction = { itemIndex: number | null };

export interface AddViewerItemAction {
    item: ViewerItem;
}

export interface ViewerActionData {
    itemId: string;
    itemStorage: EStorageType | null;
    itemIds: string[];
    gaSuffix: string;
    totalCount?: number;
    fromCloud?: boolean;
    keepPreviousData?: boolean;
}

export interface SetSelectedItemData {
    item: CloudItem | null;
    itemIdxInArray: number | null;
    itemId: string;
    isOpenAction: boolean;
}

export interface SetViewerStorage {
    itemStorage: EStorageType | null;
}

export interface SetViewerItemsData {
    itemIdxs: string[];
    filesCount: number;
    hasMoreToLoad?: boolean;
}

export interface IArchiveInfoRequestData {
    storage: EStorageType;
    id: string;
    ext: string;
}

export interface IArchiveInfoApiResponseItem {
    path: string;
    name: string;
    kind: string;
    size?: number;
    list?: IArchiveInfoApiResponseItem[];
    is_encrypted?: boolean;

    previewUrl?: string;
    viewersUrl?: string;
    downloadUrl?: string;
}

export interface IArchiveInfoApiResponse {
    archiveId: string;
    error?: string;
    list?: IArchiveInfoApiResponseItem[];
}

export interface IArchiveItemInfoRequestData {
    storage: EStorageType;
    archiveId: string;
    itemId: string;
}

export interface IArchiveItemInfoData {
    itemId: string;
    previewUrl?: string | null;
    downloadUrl?: string;
}

export interface IArchiveItem {
    path: string;
    name: string;
    isFolder: boolean;
    size?: number;
    list?: string[];
    isEncrypted?: boolean;
    count?: Count | null;

    kind: Kind;
    subKind: SubKind;
    ext: string;
    previewUrl?: string | null;
    downloadUrl?: string;
    nameWithoutExt?: string;
}

export interface IZipViewProcessResponse {
    hash: string;
    prolong_link: string;
    info_link: string;
    ttl: number;
}
