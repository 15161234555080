import { extInfo } from 'lib/extInfo';
import { getExtension, getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';

import type { StockItem } from './stock.type';

const urlBuilder = new UrlBuilder();

export const normalizeStockItem = (child, parent: string): StockItem => {
    const childId = child.stock || child.bundle;
    const ext = getExtension(child);
    const { kind, subKind } = extInfo.get(ext);
    const nameWithoutExt = getItemNameWithoutExt(child.name, ext);

    return {
        id: childId,
        bundle: child.bundle,
        stock: child.stock,
        dwlToken: child.dwl_token,
        kind,
        subKind,
        stockId: childId,
        ext,
        nameWithoutExt,
        name: child.name,
        storage: EStorageType.stock,
        size: child.size,
        virusScan: child.virus_scan,
        __reduxTree: true,
        isFolder: false,
        parent,
        thumbnails: urlBuilder.getThumb({
            hash: '',
            ext,
            id: childId,
            size: child.size,
            kind,
            name: child.name,
            path: child.stock,
            isPublic: false,
            isStock: true,
            dwl_token: child.dwl_token,
            weblink: '',
        }),
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: childId,
            weblink: '',
            size: child.size,
            kind,
            subKind,
            isStock: true,
            name: child.name,
            path: child.stock,
            dwl_token: child.dwl_token,
        }),
    };
};
