import { useEffect } from 'react';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { sendDwh } from 'reactApp/utils/ga';

import type { DwhData } from '../CancelAutoRenewalModal.types';

export const useSendAnalyticAfterOpen = (dwhData: DwhData) => {
    // TODO: заменить на `useFirstEffect`
    // Возможно, стоит переименовать `useFirstEffect` в `useEffectOnce`
    useEffect(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_STEP0_OPEN,
            dwhData,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
