/* tempexp_15344-full-file */
import store from 'lib/store';
import { ANONYM_USER } from 'reactApp/appHelpers/configHelpers';
import type { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';

export enum ReDownloadEntry {
    ToolbarClone = 'toolbar.clone',
    Toolbar = 'toolbar.download',
    AppHelpers = 'appHelpers.downloadItem',
}

const ROOT_STORE_KEY = 'should-re-download';
const DOWNLOAD_ACTION_STORE_KEY = 'should-re-download:download-happened';

interface ToolbarParams {
    useDownloadUrl?: boolean;
    id?: string;
    storage?: EStorageType;
    selected: CloudItem[];
}

interface AppHelpersParams {
    itemOrId: CloudItem | string;
    useDownloadUrl?: boolean;
    storage?: EStorageType;
    forceDownload?: unknown;
    fromViewer?: boolean;
}

interface StoredData<Entry extends ReDownloadEntry> {
    type: Entry;
    params: Params<Entry>;
}

type Params<Entry extends ReDownloadEntry> = Entry extends ReDownloadEntry.Toolbar ? ToolbarParams : AppHelpersParams;

class ReDownloadController {
    setFutureParams<Entry extends ReDownloadEntry>(entry: Entry, params: Params<Entry>) {
        if (!ANONYM_USER) {
            return;
        }

        store.set(ROOT_STORE_KEY, {
            type: entry,
            params,
        } as StoredData<Entry>);
    }

    getParams<Entry extends ReDownloadEntry>(entry: Entry): Params<Entry> | undefined {
        const data = store.get(ROOT_STORE_KEY) as StoredData<Entry>;

        if (data?.type === entry) {
            return data?.params;
        }
    }

    setDownloadHappened() {
        store.set(DOWNLOAD_ACTION_STORE_KEY, true);
    }

    isDownloadHappened() {
        return store.get(DOWNLOAD_ACTION_STORE_KEY);
    }

    resetParams() {
        store.remove(ROOT_STORE_KEY);
    }

    resetDownloadHappened() {
        store.remove(DOWNLOAD_ACTION_STORE_KEY);
    }

    isEmpty() {
        return !store.get(ROOT_STORE_KEY);
    }
}

export const reDownloadController = new ReDownloadController();
