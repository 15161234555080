import { find, findLast, sortBy } from 'ramda';
import type { Tariff } from 'reactApp/types/Billing';

const findLowerTariff = ({ tariffs, quota }: { tariffs: Tariff[]; quota: number }) => {
    const sortedTariffs = sortBy((tariff) => tariff.space.original, tariffs);
    return findLast((tariff) => tariff.space.original < quota, sortedTariffs);
};

const findHigherTariff = ({ tariffs, quota, period }: { tariffs: Tariff[]; quota: number; period: string | null }) => {
    const sortedTariffs = sortBy((tariff) => tariff.space.original, tariffs);

    if (period) {
        return find((tariff) => {
            return tariff.space.original > quota && Boolean(tariff.products.find((product) => product.period === period));
        }, sortedTariffs);
    }

    return find((tariff) => tariff.space.original > quota, sortedTariffs);
};

export { findHigherTariff, findLowerTariff };
