import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { getFeatureKasperskyVerifiedExts } from 'reactApp/modules/features/features.selectors';
import { getCurrentPublicFolder, getCurrentPublicItem } from 'reactApp/modules/public/public.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import { getExtension } from './helpers/getExtention';

const verifyExt = (ext: string, verifiedExtsMap: Record<string, string[]>) => {
    if (ext) {
        const verifiedExts = Object.values(verifiedExtsMap || [])
            .flat()
            .map((o) => o.toLowerCase());

        return verifiedExts.includes(ext.toLowerCase());
    }

    return false;
};

export const getIsItemVerifiedByKaspersky = createSelector(
    (state: RootState) => state,
    (state, item) => item,
    getFeatureKasperskyVerifiedExts,
    (state, item, verifiedExtsMap) => {
        const ext = getExtension(item);

        return item && verifyExt(ext, verifiedExtsMap);
    }
);

export const getIsViewerFileVerifiedByKaspersky = createSelector(
    (state) => state,
    getFeatureKasperskyVerifiedExts,
    ViewerSelectors.getViewerItem,
    (state, verifiedExtsMap, currentViewerItem) => {
        const currentPublicItem = getCurrentPublicItem(state);
        const item = IS_PUBLIC ? currentPublicItem : currentViewerItem;

        const ext = getExtension(item);

        return item && verifyExt(ext, verifiedExtsMap);
    }
);

export const getFolderVerifiedByKaspersky = createSelector(
    (state: RootState) => state,
    (state: RootState, storage: EStorageType) => storage,
    getFeatureKasperskyVerifiedExts,
    (state, storage, verifiedExtsMap) => {
        if (storage !== EStorageType.public) {
            return false;
        }

        const currentFolder = getCurrentPublicFolder(state);

        const { childs, count, hasMoreToLoad } = currentFolder || {};

        const verifiedExts = Object.values(verifiedExtsMap || [])
            .flat()
            .map((o) => o.toLowerCase());

        // если в папке есть хотябы один файлы формата не из этого списка
        const haveUnpredictableFiles = childs?.some((child) => !verifiedExts.includes(getExtension(child)));
        // если папка пустая
        const folderIsEmpty = !count || count?.all === 0;
        // если есть вложенные папки
        const folderHasAnotherFolders = count && count.folders > 0;
        // если папка содержит более 500 элементов и мы не можем уверенно сказать что все файлы в ней проверены
        const loadedFilesLessThanAll = (count && count.loaded !== count.all) || hasMoreToLoad;

        return !haveUnpredictableFiles && !folderIsEmpty && !folderHasAnotherFolders && !loadedFilesLessThanAll;
    }
);
