import { createReducer } from '@reduxjs/toolkit';
import { equals } from 'ramda';
import { AttachFileBuilder } from 'reactApp/modules/file/AttachFileBuilder';
import { cloneToCloudSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import {
    attachesFetchFailure,
    attachesFetchRequest,
    attachesFetchSuccess,
    attachesViewerSuccess,
    resetAttachPage,
    setAttachesId,
    setIsFromAttaches,
    successFolders,
} from './attaches.actions';
import type { AttachesFolderItem, State } from './attaches.types';

const INITIAL_STATE: State = {
    childs: [],
    attaches: {},
    viewerAttaches: {},
    loadedMessagesIds: [],
    folders: {},
    after: '',
    hasMoreToLoad: false,
    isLoaded: false,
    isLoading: false,
    error: '',
    search: null,
    openedFoldersIdxs: {},
    id: 'attaches',
    allMessagesTotalCount: 0, // число писем по всех папках юзера, включая 'spam', 'drafts', 'templates', 'trash'
    isFromAttaches: false,
};

const attachFileBuilder = new AttachFileBuilder();

export const attachesReducer = createReducer(INITIAL_STATE, {
    [attachesFetchRequest.type]: (state, action: ReturnType<typeof attachesFetchRequest>) => {
        const { query, type, folderId } = action.payload;
        const search = query || type || folderId ? { query, type, folderId } : null;
        const isNewSearch = !equals(state.search, search);

        state.search = search;
        state.after = isNewSearch ? '' : state.after;
        state.childs = isNewSearch ? [] : state.childs;
        state.isLoaded = false;
        state.isLoading = true;
        state.error = '';
    },
    [attachesFetchSuccess.type]: (state, action: ReturnType<typeof attachesFetchSuccess>) => {
        const { noOffset, after, attaches, hasMoreToLoad } = action.payload;

        const newAttaches = {
            ...state.attaches,
            ...attaches,
        };
        Object.keys(attaches).forEach((key) => {
            if (state.attaches[key]?.url) {
                newAttaches[key].url = state.attaches[key].url;
            }

            if (state.attaches[key]?.editors) {
                newAttaches[key].editors = state.attaches[key].editors;
            }

            if (state.attaches[key]?.attachType) {
                newAttaches[key].attachType = state.attaches[key].attachType;
            }

            if (state.attaches[key]?.thumbnails) {
                newAttaches[key].thumbnails = state.attaches[key].thumbnails;
            }
        });

        state.childs = Array.from(new Set([...state.childs, ...Object.keys(attaches)]));
        state.attaches = newAttaches;
        state.isLoading = false;
        state.isLoaded = true;
        state.error = '';
        state.after = noOffset ? state.after : after;
        state.hasMoreToLoad = hasMoreToLoad ?? state.hasMoreToLoad;
    },
    [attachesFetchFailure.type]: (state, action: ReturnType<typeof attachesFetchFailure>) => {
        const { error } = action.payload;

        state.isLoading = false;
        state.isLoaded = true;
        state.error = error;
    },
    [resetAttachPage.type]: (state) => {
        state.after = '';
        state.openedFoldersIdxs = {};
        state.hasMoreToLoad = false;
    },
    [attachesViewerSuccess.type]: (state, action: ReturnType<typeof attachesViewerSuccess>) => {
        const { attaches, messageId } = action.payload;

        state.viewerAttaches = { ...state.viewerAttaches, ...attaches };
        state.loadedMessagesIds = [...state.loadedMessagesIds, messageId];
    },
    [cloneToCloudSuccess.type]: (state, action: ReturnType<typeof cloneToCloudSuccess>) => {
        const { idx, home, storageFrom } = action.payload;

        if (storageFrom !== EStorageType.viewerAttaches && storageFrom !== EStorageType.attaches) {
            return state;
        }

        const newViewerAttaches = {};
        idx.forEach((id, i) => {
            const homeId = home[i];
            if (homeId && state.viewerAttaches[id]) {
                newViewerAttaches[id] = {
                    ...state.viewerAttaches[id],
                    home: homeId,
                };
            }
        });

        state.viewerAttaches = {
            ...state.viewerAttaches,
            ...newViewerAttaches,
        };
    },
    [successFolders.type]: (state, action: ReturnType<typeof successFolders>) => {
        const allMessagesTotalCount =
            action.payload?.reduce((count, folder: AttachesFolderItem) => count + folder.messages_total, 0) ?? state.allMessagesTotalCount;

        const newItems = action.payload
            ? action.payload
                  .filter((folder: AttachesFolderItem): any => !['spam', 'drafts', 'templates', 'trash'].includes(folder.type))
                  .reduce(
                      (acc, item): Record<string, AttachesFolderItem> => ({
                          ...acc,
                          [item.id]: attachFileBuilder.buildFolderItem(item),
                      }),
                      {}
                  )
            : {};
        const folders = {
            ...state.folders,
            ...newItems,
        };

        state.folders = folders;
        state.allMessagesTotalCount = allMessagesTotalCount;
    },
    [setIsFromAttaches.type]: (state, action: ReturnType<typeof setIsFromAttaches>) => {
        state.isFromAttaches = action.payload;
    },
    [setAttachesId.type]: (state, action: ReturnType<typeof setAttachesId>) => {
        state.attachesIdViewed = action.payload;
    },
});
