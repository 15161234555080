import { xray } from 'lib/xray';

export const enum superAppSubmetrics {
    openPublic = 'open-public',
    uploadFiles = 'upload-files',
    sharePublic = 'share-public',
    saveInCloud = 'save-in-cloud',
    authorizationOrInstallation = 'authorization-or-installation',
    necessarilyDownloadApp = 'necessarily-download-app',
    necessarilyAuthorization = 'necessarily-authorization',
}

export interface superAppWebViewDWH {
    id_public: string;
    id_file?: string;
    id_folder?: string;
    type_public: 'folder' | 'file' | 'album';
    count_objects: number;
    extension?: string;
    owner: boolean;
    have_faces: boolean;
    count_faces: number;
}

export const sendSuperAppXray = (i: superAppSubmetrics, dwh: superAppWebViewDWH) => {
    xray.send('public', {
        p: 'cloud-integration',
        i,
        dwh: {
            type_access: 'url',
            app: 'Mail App',
            place: 'Mail App',
            shove: false,
            isPhone: true,
            isEditor: false,
            isStock: false,
            ...dwh,
        },
    });
};
