import { Spacing, Text } from '@vkontakte/vkui';
import React, { type RefObject, forwardRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { litresTermsLink } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './LitresTooltip.css';
import type { LitresTooltipProps } from './LitresTooltip.types';

export const LITRES_TOOLTIP_ID = 'litres-tooltip';
export const LITRES_TOOLTIP_ID_MOBILE = 'litres-tooltip-mobile';

const textNode = (
    <>
        2 месяца подписки на сервис
        <br />
        электронных книг Литрес за 0 ₽ —
        <br />в тарифах Mail Space
    </>
);

export const LitresTooltip = forwardRef<HTMLDivElement, LitresTooltipProps>(({ onClose }, ref) => {
    const dispatch = useDispatch();
    const isMobile = EnvironmentSelectors.isMobile();

    const handleOnShow = useCallback(() => {
        dispatch(UserStorageActions.set(isMobile ? LITRES_TOOLTIP_ID_MOBILE : LITRES_TOOLTIP_ID, true));
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_TOOLTIP_SHOW);
        sendQuotaGa({ action: 'tooltip_litres', label: 'view' }); // Дополнительно отправляем, так интересуют utm
    }, [dispatch, isMobile]);

    const handleClose = useCallback(() => {
        onClose?.(false);
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLOSE);
        sendQuotaGa({ action: 'tooltip_litres', label: 'close' });
    }, [onClose]);

    const handleClickLink = useCallback(() => {
        emitAnalyticEvent(AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLICK);
        sendQuotaGa({ action: 'tooltip_litres', label: 'click' });
    }, []);

    const linkNode = useMemo(
        () => (
            <a target="_blank" href={litresTermsLink} className={styles.link} onClick={handleClickLink} rel="noreferrer">
                Подробнее
            </a>
        ),
        [handleClickLink]
    );

    return (
        <FloatingTooltip
            target={ref as RefObject<HTMLDivElement>}
            placement={ETooltipPlacement.top}
            appearance={ETooltipAppearance.dark}
            size={ETooltipSize.medium}
            onShow={handleOnShow}
            onClose={handleClose}
            usePortal={isMobile}
            allowFlip={false}
            belowSticky={true}
            className={styles.tooltipRoot}
            arrowClassName={styles.tooltipArrow}
        >
            <div className={styles.root}>
                {IS_WEBVIEW ? (
                    <Text className={styles.text}>
                        {textNode}. {linkNode}
                    </Text>
                ) : (
                    <>
                        <Text className={styles.text}>{textNode}</Text>
                        <Spacing size={6} />
                        {linkNode}
                    </>
                )}
            </div>
        </FloatingTooltip>
    );
});

LitresTooltip.displayName = 'LitresTooltip';
