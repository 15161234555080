import { useSelector } from 'react-redux';
import { getFamilyQuotaInfo, isUserWithFamily } from 'reactApp/modules/family/family.selectors';
import type { TSpaceInfo, TUseSpaceHook } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress.types';

export const useFamilySpace: TUseSpaceHook = () => {
    const { usedCurrentUser, usedFamily, remaining, familyUsedPercents, currentUserUsedPercents, isFull, isAlmostFull } =
        useSelector(getFamilyQuotaInfo);

    const withFamily = useSelector(isUserWithFamily);

    const isOverquota = isAlmostFull || isFull;
    const isFamilyOverquota = isOverquota && (usedFamily?.original || 0) >= (usedCurrentUser?.original || 0);

    const spaceList: TSpaceInfo[] = [
        {
            id: 'files',
            text: `Мои файлы ${usedCurrentUser?.value}`,
            hintText: 'Письма и файлы из Облака',
            percents: currentUserUsedPercents,
            isOverquota: isOverquota && !isFamilyOverquota,
        },
    ];

    if (withFamily) {
        spaceList.push({
            id: 'family',
            text: `Общее ${usedFamily?.value}`,
            hintText: 'Участники подписки',
            percents: Math.min(familyUsedPercents + 1, 100),
            isOverquota: isFamilyOverquota,
        });
    }

    return {
        spaceList,
        isOverquota: false,
        freeSpaceText: `Свободно ${remaining?.value}`,
        usedPercents: familyUsedPercents,
    };
};
