import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DOCUMENTS_DOMAIN, platform } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getAllDocumentsCurrentType, getDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { getFeatureAllDocumentsUploadText } from 'reactApp/modules/features/features.selectors';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { emitUploadStart } from 'reactApp/ui/UploadInput/UploadInput';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

interface INotDocumentModalProps {
    notUploadExt: string[];
}

const linkText = 'Облако Mail';
const linkHref = IS_DOCUMENTS_DOMAIN ? `${changeDocumentDomainToCloud(window.location.origin)}/home/` : '/home/';
const additionalText = 'Другие файлы сохраните в ';
const mainTextTrashbin = 'Для загрузки переключитесь на\u00A0соответствующий\u00A0раздел в меню слева. ';

const headerText = {
    trashbin: 'Нельзя загружать в корзину',
    common: 'Загружайте документы',
};

const popupName = popupNames.NOT_DOCUMENT_MODAL;

export const NotDocumentModal = memo(({ notUploadExt }: INotDocumentModalProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const currentAllDocsType = useSelector(getAllDocumentsCurrentType);
    const { isLoaded, count } = useSelector(getDocumentsState);
    const mainText = useSelector(getFeatureAllDocumentsUploadText).modal;
    /**
     *  CLOUDWEB-14993
     *  Если пытаемся загрузить в корзину в разделе документов, то показываем попап, поправить когда переедем на отдельный домен
     */
    const isTrashbinPage =
        (window.location.pathname.includes('alldocuments/trashbin') ||
            (IS_DOCUMENTS_DOMAIN && window.location.pathname.includes('trashbin'))) &&
        !currentAllDocsType;

    const dwhData = useMemo(
        () =>
            isLoaded
                ? {
                      platform,
                      extension: notUploadExt,
                      cnt_file: notUploadExt.length,
                  }
                : null,
        [isLoaded, platform, count]
    );

    useEffect(() => {
        sendDwh({
            eventCategory: ECategoryGa.docs,
            action: 'show-pop-up_wrong_format',
            dwhData: { ...dwhData, place: currentAllDocsType },
        });
    }, []);

    const handleUploadFile = useCallback(() => {
        if (isTrashbinPage) {
            dispatch(historyPush({ id: IS_DOCUMENTS_DOMAIN ? '/document' : '/alldocuments/document' }));
            setIsOpen(false);
            closePopupHelper(popupName);
            return;
        }
        emitUploadStart({ type: 'file', from: undefined });
        setIsOpen(false);
        closePopupHelper(popupName);
    }, [isTrashbinPage]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        closePopupHelper(popupName);
    }, [isOpen, setIsOpen]);

    const renderHeader = useCallback(() => <div>{isTrashbinPage ? headerText.trashbin : headerText.common}</div>, []);

    const renderContent = useCallback(() => {
        return (
            <div>
                {!isTrashbinPage && mainText && <div>{mainText}</div>}
                {isTrashbinPage && <div>{mainTextTrashbin}</div>}
                <div>
                    {additionalText}
                    <a href={linkHref} target="_blank" rel="noopener noreferrer" title={linkText}>
                        {linkText}
                    </a>
                </div>
            </div>
        );
    }, [mainText, additionalText]);

    if (!isOpen) {
        return null;
    }

    return (
        <BaseConfirmDialog
            dataQAId="not-document-modal"
            renderHeader={renderHeader}
            successButtonText={isTrashbinPage ? 'Перейти к документам' : 'Загрузить'}
            cancelButtonText="Отменить"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onSuccess={handleUploadFile}
            renderContent={renderContent}
            onClose={handleClose}
            size={'small'}
        />
    );
});

NotDocumentModal.displayName = 'NotDocumentModal';
