import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { type IBaseSubscription, ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import type { Subscription } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.types';

export const renderSubscriptionInfoModal = (data: {
    subscription: Subscription;
    isRebrandingQuotaLanding?: boolean;
    isRebranding?: boolean;
}) => openPopupHelper({ popupName: popupNames.SUBSCRIPTION_INFO_MOBILE, data });

export const isBaseSubscription = (subscription: Subscription): subscription is IBaseSubscription => {
    return subscription.type === ESubscriptionsTypes.base;
};
