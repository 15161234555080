import type { Product } from 'reactApp/types/Billing';
import { getPeriodName } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

interface Props {
    product: Product;
    periodDivider?: string;
}

export const usePrice = ({ product, periodDivider = ' / ' }: Props) => {
    const { price, discountPrice, discountPeriod, period } = product;
    const defaultPeriod = '1y';

    const priceAsText = price ? `${formatPrice(price)} ₽ ${periodDivider} ${getPeriodName(period || defaultPeriod)}` : '';
    const discountPriceAsText = discountPrice
        ? `${formatPrice(discountPrice)} ₽ ${periodDivider} ${getPeriodName(discountPeriod || defaultPeriod)}`
        : '';

    const productMonthPriceAsText = `${formatPrice(getMonthProductPrice(product))} ₽ ${periodDivider} месяц`;
    const defaultMonthPrice = `${formatPrice(getMonthProductPrice(product, false))} ₽`;

    return [priceAsText, discountPrice, discountPriceAsText, productMonthPriceAsText, defaultMonthPrice] as const;
};
