import {
    type IStory,
    type IStoryContentElementFile,
    type IStoryFileItem,
    type IStoryState,
    EStoryHeaderType,
    IEnumStoryContentType,
} from 'reactApp/modules/stories/stories.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getStoryStore = (state: RootState) => state.stories as IStoryState;

export const getStorySummaries = (state: RootState) => getStoryStore(state).summaries;
export const getStorySummariesList = (state: RootState): string[] => Object.keys(getStorySummaries(state) ?? {});
export const getStoryFiles = (state: RootState) => getStoryStore(state).files;
export const getFileItemById = createSelector(
    getStoryFiles,
    (state, id) => id,
    (files, id): undefined | IStoryFileItem => files[id]
);
export const getCurrentStory = (state: RootState): IStory | null => getStoryStore(state).story;
export const getCurrentCursor = (state: RootState) => getStoryStore(state).cursor;
export const hasSummariesLoadMore = (state: RootState): boolean => Boolean(getCurrentCursor(state));
export const getNewStoriesCount = (state: RootState): number => getStoryStore(state).unviewedCount;

export const getStorySummaryById = createSelector(
    getStoryStore,
    (state, id) => id,
    (stories, id) => {
        return stories?.summaries?.[id];
    }
);
export const getSummariesStatus = createSelector(getStoryStore, (state): { isLoading: boolean; isLoaded: boolean; error?: string } => {
    return {
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    };
});
export const getStorySummaryCovers = createSelector(
    (state) => state,
    (state: RootState) => getStorySummariesList(state).map((id) => getStorySummaryById(state, id)),
    (state, stories) => {
        return stories
            .map((story) => {
                const file = getFileItemById(state, (story.cover as IStoryContentElementFile).file);
                return {
                    id: story.id,
                    title: story.title,
                    subtitle: story.subtitle,
                    type: story.type,
                    viewed: story.viewed,
                    thumb: file?.thumb?.xms3,
                };
            })
            .filter((item) => item.thumb);
    }
);

export const getCurrentStoryStatus = createSelector(getCurrentStory, (state): { isLoading: boolean; isLoaded: boolean; error?: string } => {
    return {
        isLoading: state?.isLoading ?? false,
        isLoaded: state?.isLoaded ?? false,
        error: state?.error ?? '',
    };
});
export const getStoryForViewer = createSelector(
    getCurrentStory,
    (state, id: string | null | undefined) => id,
    (story, id) => {
        if (!story || !story.header) {
            return null;
        }

        let bottomTitle;
        let bottomSubTitle;
        let topTitle;
        let topSubTitle;
        const viewed = story.viewed;

        switch (story.header.type) {
            case EStoryHeaderType.history:
                bottomTitle = story.header.top_title;
                bottomSubTitle = story.header.top_subtitle;
                break;
            case EStoryHeaderType.selection:
            case EStoryHeaderType.flashback:
                bottomTitle = story.header.bottom_title;
                bottomSubTitle = story.header.bottom_subtitle;
                break;
        }

        let contentItem;

        if (id) {
            contentItem = (story.header?.content as any[]).filter((content) => content?.file === id)[0];
        }

        if (!contentItem) {
            contentItem = story.header?.content?.[0];
        }

        if (!contentItem) {
            return null;
        }

        switch (contentItem.type) {
            case IEnumStoryContentType.elementWithPathAndTitle:
                topTitle = contentItem.title;
                topSubTitle = contentItem.subtitle;
                break;
            case IEnumStoryContentType.elementWithPathAndTopTitle:
                topTitle = contentItem.top_title;
                topSubTitle = contentItem.top_subtitle;
                break;
        }

        return {
            content: story.header?.content,
            type: story.type,
            bottomSubTitle,
            bottomTitle,
            topTitle,
            topSubTitle,
            id: story.id,
            viewed,
        };
    }
);
export const getStoryGallery = createSelector(getCurrentStory, (story) => {
    return story?.blocks;
});
export const getAdjacentStoryIds = createSelector(
    getCurrentStory,
    getStorySummariesList,
    (
        currentStory,
        storyIdxs
    ): {
        prevStoryId?: string;
        nextStoryId?: string;
        currentStoryId?: string;
    } => {
        if (!currentStory?.id) {
            return {};
        }

        const currentStoryIdx = storyIdxs?.indexOf(currentStory?.id);

        const nextStoryId = currentStoryIdx < storyIdxs.length - 1 ? storyIdxs[currentStoryIdx + 1] : '';
        const prevStoryId = currentStoryIdx > 0 ? storyIdxs[currentStoryIdx - 1] : '';

        return { prevStoryId, nextStoryId, currentStoryId: currentStory?.id };
    }
);

export const getStoryItemById = getFileItemById;
export const getCurrentStoryFiles = createSelector(getCurrentStory, (story): undefined | string[] =>
    story?.header?.content?.map((content) => content?.file)?.filter((item) => Boolean(item))
);
