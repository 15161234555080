import type { State } from 'reactApp/modules/start/start.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';

const getStartStore = (state: RootState) => state.start as State;
const getLoadingState = (state: RootState): LoadingState => ({
    isLoading: getStartStore(state).isLoading,
    isLoaded: getStartStore(state).isLoaded,
});

export const StartSelectors = {
    getLoadingState,
};
