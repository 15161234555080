import { xray } from 'lib/xray';
import { type LeaveFamilyQueryParams, LeaveFamilyAPICall } from 'reactApp/api/family/LeaveFamilyAPICall';
import { leaveFamily, setLoading } from 'reactApp/modules/family/family.actions';
import { getFamilyList } from 'reactApp/modules/family/family.selectors';
import { getFamilyPlan } from 'reactApp/modules/family/sagas/initFamilyData.saga';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { openFamilyLeaveModal } from 'reactApp/sections/FamilyPage/FamilySettings/FamilySettings.helpers';
import { channel } from 'redux-saga';
import { put, select, take } from 'typed-redux-saga';

const leaveFamilyAPICall = (params: LeaveFamilyQueryParams) => new LeaveFamilyAPICall().makeRequest(params);

export function* handleLeaveFamilyRequest() {
    const leaveChannel = channel();

    openFamilyLeaveModal({
        onClose: () => leaveChannel.close(),
        onAction: () => leaveChannel.put(true),
    });

    yield take(leaveChannel);

    try {
        yield put(setLoading());
        const families = yield* select(getFamilyList);
        const currentFamily = Object.values(families)[0];

        if (!currentFamily) {
            xray.send('family_leave_error_no-family');
            yield loggerSaga({ error: 'leave-family-no-family' });
        }

        yield leaveFamilyAPICall({ family: currentFamily.family });
        xray.send('family_leave_success');
        yield put(leaveFamily());
        yield waitForUserUpdate();
        yield getFamilyPlan();
    } catch (error) {
        xray.send('family_leave_failure');
        yield put(
            showSnackbarAction({
                text: 'Не удалось покинуть подписку',
                type: SnackbarTypes.failure,
                id: 'leave-family-error',
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }
}
