import config from 'Cloud/config';
import { extInfo } from 'lib/extInfo';
import { stringify } from 'qs';
import type { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getFeatureAllDocuments, getFeatureAllDocumentsHideSections } from 'reactApp/modules/features/features.selectors';
import { SearchOptionType } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudSearch/SelectFromCloudSearch.types';
import type { RootState } from 'reactApp/store';
import type { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';

export const SEARCH_PER_PAGE_LIMIT = 500;

export const getExtsBySearchType = (searchType: string, isAlbumsSearch: boolean) => {
    if (searchType === SearchOptionType.media && isAlbumsSearch) {
        return extInfo.getExtsForAlbums().join(',');
    }

    if (isAlbumsSearch) {
        return extInfo.getExtsForAlbumsByKind(searchType).join(',');
    }

    return extInfo.getExtsByKind(searchType).join(',');
};

export const getAllDocumentsExts = (state: RootState) => {
    const sections = getFeatureAllDocuments(state);
    const hiddenSections = getFeatureAllDocumentsHideSections(state);
    const availableExtensions: string[] = [];

    for (const section in sections) {
        if (!hiddenSections.includes(section as EAllDocumentsType)) {
            availableExtensions.push(...sections[section]);
        }
    }

    return availableExtensions.join(',');
};

export const getSearchQueryString = (
    query: string,
    { searchType, searchSource }: { searchType?: ESearchOptionType; searchSource?: ESearchOptionSource }
) => stringify({ query, searchType, searchSource }, { addQueryPrefix: true });

export const getSearchQueryStringMobile = (
    query: string,
    { searchType, searchSection }: { searchType?: ESearchOptionType; searchSection?: string }
) => stringify({ query, searchType, searchSection }, { addQueryPrefix: true });

export const getUserSearchParams = () => {
    const isPaidUser = config.get('PAID_USER');
    const isProUser = config.get('IS_PRO_USER');
    return { isPaidUser, isProUser };
};

export const parseSearchQuery = (query: string) => {
    return query.replace(/[*|/|"|:|<|>|?|\\||]/g, '').slice(0, 100);
};
