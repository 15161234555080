import { extInfo } from 'lib/extInfo';
import { partition } from 'ramda';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getFeatureAlbumsPage, getFeatureCreatePublicAlbum } from 'reactApp/modules/features/features.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { BreadcrumbsListItem } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { createSelector } from 'reselect';

import { isAlbumAllowedExtension } from './albums.helpers';
import { type AlbumItem, type IAlbum, type IAlbumsState, isAlbum } from './albums.types';

const getAlbumsState = (state: RootState): IAlbumsState => state.albums;

export const getAlbumList = (state: RootState) => getAlbumsState(state).list;

export const getAlbumsItemById = createSelector(
    getAlbumList,
    (state, id) => id,
    (list, id): AlbumItem | undefined => list[id]
);

export const getAlbumsIds = createSelector(getAlbumList, (list): string[] => {
    const item = list[ROOT_FOLDER_ID];

    return isAlbum(item) ? item?.childs || [] : [];
});

export const isCreatePublicAlbumsEnabled = (state, items): boolean => {
    const currentStorage = getCurrentStorage(state);
    // В разделе альбомов нужно показывать кнопку поделиться, но только с включенной фичей
    if (items[0]?.type === 'album' && currentStorage === EStorageType.albums) {
        return Boolean(getFeatureCreatePublicAlbum(state));
    }

    if (items?.length < 2) {
        return false;
    }

    if (!getFeatureCreatePublicAlbum(state)) {
        return false;
    }

    return items.every((item) => isAlbumAllowedExtension(item?.ext));
};

export const getCurrentAlbum = createSelector(getCurrentRouteId, getAlbumList, getCurrentStorage, (routeId, list, storage) => {
    if (storage !== EStorageType.albums) {
        return undefined;
    }

    return list[routeId] as IAlbum;
});

export const isAddToAlbumsEnabled = (state, items): boolean => {
    if (!getFeatureAlbumsPage(state)) {
        return false;
    }

    const currentAlbum = getCurrentAlbum(state);

    if (currentAlbum && items?.length && items?.every((item) => !isAlbum(item))) {
        return false;
    }

    const currentStorage = getCurrentStorage(state);
    if (currentStorage === EStorageType.albums) {
        return true;
    }

    if (!items?.length) {
        return false;
    }

    return items.every((item) => isAlbumAllowedExtension(item?.ext));
};

export const getAlbumsList = createSelector(
    (state) => state,
    getAlbumList,
    getAlbumsIds,
    (state, list, ids) => {
        const isPublicAlbumEnabled = getFeatureCreatePublicAlbum(state);
        const items = ids.map((id) => list[id]).filter(Boolean) || [];

        // Если не включена фича про публичные альбомы, нужно не показывать публичные альбомы
        if (!isPublicAlbumEnabled) {
            return {
                personal: items,
                weblink: [],
            };
        }

        const [weblink, personal] = partition((item) => Boolean(isAlbum(item) && item.weblink), items) as IAlbum[][];

        return {
            personal,
            weblink,
        };
    }
);

export const getFlattenAlbumsList = createSelector(
    getAlbumList,
    getAlbumsIds,
    (list, ids) => ids.map((id) => list[id]).filter(Boolean) || []
);

export const getAlbumCursor = createSelector(getCurrentAlbum, (album): string | undefined => (isAlbum(album) ? album.cursor : undefined));

export const getCurrentAlbumList = createSelector(getCurrentAlbum, (album) => (isAlbum(album) ? album?.childs || [] : []));

export const isAlbumsLoading = createSelector(getCurrentAlbum, (album): boolean =>
    album ? Boolean(isAlbum(album) && !album?.isLoaded && album?.isLoading) : true
);

export const isRootAlbumStorage = createSelector(getCurrentAlbum, (album): boolean =>
    isAlbum(album) ? album?.id === ROOT_FOLDER_ID : false
);

export const getAlbumsBreadcrumbs = createSelector(getCurrentAlbum, (album) => {
    const result: BreadcrumbsListItem[] = [
        {
            name: 'Альбомы',
            id: '/albums',
            kind: 'storage' as const,
            storage: EStorageType.albums,
        },
    ];

    if (album && album.id !== ROOT_FOLDER_ID) {
        result.push({
            name: album.name,
            id: album.id,
            storage: EStorageType.albums,
            kind: 'folder',
        });
    }

    return result;
});

export const getAlbumAllowedExtensions = (): string[] => extInfo.getExtsForAlbums();

export const getAlbumIdsParams = createSelector(
    (state) => state,
    getCurrentAlbum,
    (state, idList) => idList,
    (state, currentAlbum, idList: string[]) => {
        if (!idList?.length) {
            return;
        }

        const albumId = idList[0];
        const album = getAlbumsItemById(state, albumId);

        if (idList.length === 1 && isAlbum(album)) {
            return { id: albumId };
        }

        return currentAlbum ? { id: currentAlbum.id, album_list: idList } : undefined;
    }
);

export const getUploadAlbumId = createSelector(getAlbumsState, getCurrentAlbum, (state, currentAlbum) => {
    if (!currentAlbum) {
        return;
    }

    if (currentAlbum.id === ROOT_FOLDER_ID && isAlbum(currentAlbum)) {
        return state.currentUploadAlbum;
    }

    return currentAlbum.id;
});

export const selectFromCloudGetSelected = createSelector(
    getAlbumsState,
    (state, id): string => id,
    (state, id) => state.selectedItems?.[id]
);
