import { createAction } from '@reduxjs/toolkit';

import type {
    IWeblinkAccessControlLoadStart,
    IWeblinkAccessControlSetError,
    IWeblinkDeleteEmailAccessRights,
    IWeblinkSetEmailAccessList,
    IWeblinkSetEmailAccessRights,
} from './weblinkAccessControl.types';

export const weblinkAccessControlLoadStart = createAction<IWeblinkAccessControlLoadStart>('weblinkAccessControl/loadStart');
export const weblinkAccessControlSetError = createAction<IWeblinkAccessControlSetError>('weblinkAccessControl/loadFail');
export const weblinkAccessControlReset = createAction('weblinkAccessControl/reset');

export const weblinkSetEmailAccessList = createAction<IWeblinkSetEmailAccessList>('weblinkAccessControl/setEmailList');
export const weblinkDeleteAllEmailAccess = createAction('weblinkAccessControl/deleteAllEmail');

export const weblinkSetEmailAccess = createAction<IWeblinkSetEmailAccessRights>('weblinkAccessControl/setEmailAccess');
export const weblinkDeleteEmailAccess = createAction<IWeblinkDeleteEmailAccessRights>('weblinkAccessControl/deleteEmailAccess');
