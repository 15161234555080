import { isFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getThemeState = (state: RootState) => state.theme;

export const isCurrentDarkTheme = createSelector(getThemeState, (theme): boolean => {
    if (isDarkTheme) {
        return true;
    }

    if (IS_PHONE_BROWSER || !isFeature('theme-switcher')) {
        return false;
    }

    return theme.currentTheme === 'dark';
});
