import { IS_PHONE_BROWSER, IS_PUBLIC_OF_OVERQUOTA, IS_WEBVIEW, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { sendSuperAppXray, superAppSubmetrics } from 'reactApp/appHelpers/sendSuperAppAnalytics';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

interface IOpenPublicAnalytics {
    eventCategory?: ECategoryGa;
    type_public: 'folder' | 'file';
    extension: string;
    id_public: string;
    place?: string;
    id_file?: string;
    id_folder?: string;
    count_objects?: number;
    owner?: boolean;
    isStock?: boolean;
    isEditor?: boolean;
    source: string;
    have_faces?: boolean;
    count_faces?: number;
    name_files?: string;
    type_content?: string;
    hash?: string;
    size?: number;
}

/**
 * https://metida.mail.ru/cloud/events/2029
 */
export const sendOpenPublicAnalytics = ({ source, eventCategory, ...data }: IOpenPublicAnalytics) => {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const fromDeeplink = getQueryParams()[EQueryParams.fromDeeplink];
    const isSuperAppWebView = Boolean(IS_WEBVIEW) && fromDeeplink;

    sendPaymentGa({
        eventCategory: eventCategory || ECategoryGa.public,
        action: EPaymentGa.openPublic,
        ownerIsOverquota: Boolean(IS_PUBLIC_OF_OVERQUOTA),
        shove: PUBLIC_SHOVE,
        isPhone,
        type_access: 'url',
        source,
        ...data,
    });

    if (isSuperAppWebView) {
        sendSuperAppXray(superAppSubmetrics.openPublic, {
            id_public: data.id_public,
            id_file: data.id_file,
            id_folder: data.id_folder,
            type_public: data.type_public,
            count_objects: data.count_objects || 0,
            extension: data.extension,
            owner: data.owner || false,
            have_faces: data.have_faces || false,
            count_faces: data.count_faces || 0,
        });
    }
};
