import { getSegmentByQuota, getUsableSpaceRatio, Segments } from '@mail/cross-quota-utils';
import { type Space, bytesToNDigits } from '@mail/cross-sizes-utils';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

import type { State } from './userAndFamilyQuota.types';

const getQuotaWithFamilyState = (state: RootState) => state.userQuotaWithFamily as State;
const getTotal = createSelector(getQuotaWithFamilyState, (state: State): Space | null => state.quota);
const getUsed = createSelector(getQuotaWithFamilyState, (state: State) => state.used);
const getTotalUsed = createSelector(getQuotaWithFamilyState, (state: State): Space | null => state.used?.total);
const getRemaining = createSelector(getTotal, getUsed, (total, used) =>
    bytesToNDigits((total?.original || 0) - (used?.total?.original || 0), 3)
);

const getFilledPercents = createSelector(getQuotaWithFamilyState, (state: State): { used: number; family: number; user: number } => {
    const { quota, used } = state;

    if (!quota || !used) {
        return {
            used: 0,
            family: 0,
            user: 0,
        };
    }

    const {
        cloud: userPercents,
        mail: familyPercents,
        total: usedTotalPercents,
    } = getUsableSpaceRatio({
        quota: quota.original,
        used: {
            total: used.total?.original || 0,
            mail: used.family?.original,
            cloud: used.user?.original,
        },
    });

    return {
        used: usedTotalPercents === 0 ? 1 : usedTotalPercents,
        family: familyPercents === 0 ? 1 : familyPercents,
        user: userPercents === 0 ? 1 : userPercents,
    };
});

const isFull = createSelector(getQuotaWithFamilyState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const segment = getSegmentByQuota(quota.original, used.total.original);

    return [Segments.full, Segments.overflow].includes(segment);
});

const isAlmostFull = createSelector(getQuotaWithFamilyState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const segment = getSegmentByQuota(quota.original, used.total.original);

    return segment === Segments.almostFull;
});

const getLoadingState = createSelector(
    getQuotaWithFamilyState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

export const UserAndFamilyQuotaSelectors = {
    getTotal,
    getTotalUsed,
    getUsed,
    getRemaining,
    isFull,
    isAlmostFull,
    getFilledPercents,
    getLoadingState,
};
