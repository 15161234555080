import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as FolderIcon } from 'img/safe-fakedoor/folder.svg';
import debounce from 'lodash.debounce';
import React, { type FC, useCallback, useEffect } from 'react';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import styles from './DataListItemSafeFakedoor.css';

interface DataListItemSafeFakedoorProps {
    className?: string;
    viewMode: EViewMode;
}

const sendDebouncedGa = debounce(() => {
    safeFakedoorAnalytics(ESafeFakedoorAnalytics.SHOW, { entity: 'folder' });
}, 500);

export const DataListItemSafeFakedoor: FC<DataListItemSafeFakedoorProps> = ({ className, viewMode }) => {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        e.stopPropagation();

        safeFakedoorAnalytics(ESafeFakedoorAnalytics.CLICK, { entity: 'folder' });

        openPopupHelper({
            popupName: popupNames.SAFE_FAKEDOOR,
        });
    }, []);

    useEffect(() => {
        /** Пришлось использовать debounce, т.к. компонет монтируется дважды, когда отображается viewMode='thumb'.
         * Это связано с тем, что дефолтное значение viewMode='list', поэтому сначала рендерится list и сразу потом thumb
         */
        sendDebouncedGa();
    }, []);

    if (viewMode === EViewMode.list) {
        return (
            <div className={classNames(styles.root, className, styles.list)} onClick={handleClick}>
                <Checkbox className={styles.listCheckbox} isSelected={false} onCheckboxClick={handleClick} viewType={EViewMode.list} />
                <div className={styles.iconContainer} />
                <div className={styles.details}>
                    <div className={styles.titleContainer}>
                        <Text className={styles.title}>Сейф</Text>
                        <span className={styles.tag}>новое</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(styles.root, className)} onClick={handleClick}>
            <div className={styles.iconContainer}>
                <FolderIcon className={styles.icon} />
            </div>
            <div className={styles.details}>
                <div className={styles.titleContainer}>
                    <Text className={styles.title}>Сейф</Text>
                    <span className={styles.tag}>новое</span>
                </div>
                <Text className={styles.description}>Папка с PIN-кодом</Text>
            </div>
        </div>
    );
};
