import { getTrialOfferConfig } from 'reactApp/modules/home/home.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const storagesNeededHomeStore = [
    EStorageType.home,
    EStorageType.feed,
    EStorageType.gallery,
    EStorageType.favorites,
    EStorageType.trashbin,
    EStorageType.recommend,
    EStorageType.alldocuments,
    EStorageType.sharedLinks,
    EStorageType.sharedIncoming,
    EStorageType.sharedAutodelete,
    EStorageType.integration,
    EStorageType.inlineIntegration,
];

export const TRIAL_OFFER_CONFIG = getTrialOfferConfig();
