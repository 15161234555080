import classNames from 'clsx';
import React, { type ButtonHTMLAttributes, type FC, type ReactNode } from 'react';

import styles from './Button.css';

type BaseButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonPropsType = BaseButtonProps & {
    icon?: ReactNode;
    onClick: () => void;
};

export const Button: FC<ButtonPropsType> = ({ className, icon, onClick, children, ...restButtonProps }) => {
    return (
        <button {...restButtonProps} className={classNames(styles.root, className)} onClick={onClick}>
            {icon}
            {children}
        </button>
    );
};

Button.displayName = 'Button';
