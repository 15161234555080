import React, { type VFC, useCallback, useRef, useState } from 'react';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { FeaturesButton } from 'reactApp/ui/CloudNewFeatures/FeaturesButton/FeaturesButton';
import { FeaturesDropdown } from 'reactApp/ui/CloudNewFeatures/FeaturesDropdown/FeaturesDropdown';
import { FeaturesTooltip } from 'reactApp/ui/CloudNewFeatures/FeaturesTooltip/FeaturesTooltip';
import { Hint } from 'reactApp/ui/Hint/Hint';

export const CloudNewFeatures: VFC = () => {
    const ref = useRef<HTMLDivElement>(null);

    const tooltipShown = storeHelper.getValue(CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showTooltip, setShowTooltip] = useState(!tooltipShown);

    const handleClickButton = useCallback(() => {
        setShowDropdown((prev) => !prev);
        setShowTooltip(false);
    }, [setShowDropdown, setShowTooltip]);

    const handleCloseDropdown = useCallback(() => {
        setShowDropdown(false);
    }, [setShowDropdown]);

    return (
        <>
            <Hint text="Полезные обновления Облака">
                <div ref={ref}>
                    <FeaturesButton onClick={handleClickButton} />
                </div>
            </Hint>
            {showTooltip && <FeaturesTooltip ref={ref} />}
            {showDropdown && <FeaturesDropdown ref={ref} onClose={handleCloseDropdown} />}
        </>
    );
};
