import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import coverImage from 'img/quota-landing/autoupload-cover.jpg';
import coverImageMobile from 'img/quota-landing/autoupload-cover-mobile.jpg';
import coverImageMobileRebranding from 'img/quota-landing/autoupload-cover-mobile-rebranding.jpg';
import coverRebranding from 'img/quota-landing/autoupload-cover-rebranding.jpg';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isRebrandingQuotaLanding } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { sendQuotaGa, sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './AutouploadSuccessDialog.css';
import type { AutouploadSuccessDialogProps } from './AutouploadSuccessDialog.types';

export const AutouploadSuccessDialog = memo<AutouploadSuccessDialogProps>(({ onClose }) => {
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        sendQuotaGa({ action: 'modal-got-10tb-for-phone', label: 'show' });
        sendXrayWithPlatform(['autoupload-10tb', 'buy-success']);
    }, []);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const content = useMemo(() => {
        return (
            <div
                className={classNames(styles.content, {
                    [styles.content_rebranding]: isRebrandingQuotaLanding,
                })}
            >
                <Spacing size={12} />
                <Text className={styles.text}>Вы получили дополнительное место на автозагрузку фото и видео в мобильном приложении</Text>
                <Spacing size={24} />
            </div>
        );
    }, []);

    if (isMobile) {
        return (
            <MobileDialog
                id="autoupload-10tb-payment-success-mobile"
                onClose={handleClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="base"
                contextPadding="zero"
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            >
                <div
                    ref={scrollRef}
                    className={classNames(styles.rootMobile, {
                        [styles.rootMobile_rebranding]: isRebrandingQuotaLanding,
                    })}
                >
                    <img
                        src={isRebrandingQuotaLanding ? coverImageMobileRebranding : coverImageMobile}
                        className={styles.image}
                        alt="+10 ТБ для телефона уже в вашем Облаке"
                    />
                    <Spacing size={24} />
                    <Title level="1" className={styles.title}>
                        +10 ТБ для телефона
                        <br />
                        уже в вашем Облаке
                    </Title>
                    <Spacing size={12} />
                    <Text className={styles.text}>
                        Вы получили дополнительное место на автозагрузку фото и видео в мобильном приложении
                    </Text>
                    <Spacing size={24} />
                    <div className={styles.buttonWrapper}>
                        <Button theme="vk" primary onClick={handleClose} sizeMode={ButtonSizeMode.big}>
                            Хорошо
                        </Button>
                    </div>
                </div>
            </MobileDialog>
        );
    }

    return (
        <WhatsNewDialog
            imageUrl={isRebrandingQuotaLanding ? coverRebranding : coverImage}
            imageBgColor="var(--vkui--color_background)"
            title="+10 ТБ для телефона уже в вашем Облаке"
            content={content}
            primaryButtonText="Хорошо"
            onClick={onClose}
            onClose={onClose}
            buttonTheme="vk"
            dialogSize="tiny"
            qaId="autoupload-10tb-payment-success"
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        />
    );
});

AutouploadSuccessDialog.displayName = 'AutouploadSuccessDialog';
