import config from 'Cloud/config';

export const MUST_ATTACH_PHONE_CONTENT = {
    title: 'Добавьте номер телефона',
    contentText:
        'Мы\u00A0останавливаем поддержку Фейсбука, пожалуйста, привяжите номер телефона к\u00A0своему аккаунту для сохранения доступа',
    primaryButtonText: 'Добавить',
    secondaryButtonText: 'Не добавлять',
    recoveryUrl: `https://account.${config.get('DOMAIN_HOST')}/security/recovery/add-phone`,
};

export const SUCCESS_ATTACH_PHONE_CONTENT = {
    title: 'Номер успешно добавлен',
    contentText: 'Теперь ваш способ авторизации\u00A0— номер телефона',
    buttonText: 'Понятно',
};

export const ASK_PHONE_DIALOG = 'ask-phone-dialog';
