import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import type { Product } from 'reactApp/types/Billing';
import { sendDwh } from 'reactApp/utils/ga';
import { scrollToTop } from 'reactApp/utils/helpers';

interface UseBuyClickProps {
    source?: string;
    paySource: string;
    onSuccess: () => void;
    onError?: () => void;
}

export const useBuyClick = ({ paySource, source, ...props }: UseBuyClickProps) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const openBuy = useCallback(
        ({ productId, paySource, onClick }) => {
            const onSuccess = () => {
                emitAnalyticEvent(AnalyticEventNames.TARIFF_BUY, { paySource, product: productId });
                props.onSuccess();
                scrollToTop();
            };

            const onError = () => {
                props.onError?.();
            };

            dispatch(
                openTariffBuy({
                    productId,
                    source: paySource,
                    checkAnonymous: true,
                    onSuccess,
                    isMobile,
                    onError,
                    paySource,
                    onClick,
                })
            );
        },
        [dispatch, paySource, isMobile]
    );

    return useCallback(
        ({ id, price, space }: Product) => {
            emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_TARIFF_CLICK);
            sendDwh({
                eventCategory: 'brand_page',
                action: 'tariff_click',
                dwhData: {
                    source,
                },
            });
            openBuy({ productId: id, price, paySource, space });
        },
        [isMobile, openBuy]
    );
};
