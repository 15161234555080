import classNames from 'clsx';
import React, { type ReactElement, type ReactNode, PureComponent } from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './ViewerButton.css';

export interface Props {
    title: string | ReactNode;
    icon?: ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
    qaName?: string;
}

export class ViewerButton extends PureComponent<Props> {
    static defaultProps = {
        onClick: noop,
    };

    handleClick = (e): void => {
        const { onClick } = this.props;
        onClick(e);
    };

    render(): ReactElement {
        const { icon, title, qaName = 'bttn' } = this.props;

        return (
            <div className={classNames({ [styles.root]: true })} onClick={this.handleClick} data-qa-name={qaName}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.title}>{title}</div>
            </div>
        );
    }
}
