import { Spaces } from '@mail/cross-sizes-utils';
import { getUnixTime, lastDayOfYear, subYears } from 'date-fns';
import type { Method } from 'lib/axios';
import { extInfo } from 'lib/extInfo';
import { APICallV4 } from 'reactApp/api/APICall';
import { letterGroups, yearFilterGroups } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import {
    type UserQuotaCleanerDelete,
    type UserQuotaCleanerGroup,
    type UserQuotaCleanerListParams,
    Folders,
    Order,
    Sort,
    UserQuotaGroupId,
    UserQuotaGroupType,
} from 'reactApp/types/QuotaCleaner';

const getTimeYearShifted = (date: Date, yearsBefore: number): number => getUnixTime(subYears(date, yearsBefore));
export const getGroupType = (groupId: UserQuotaGroupId) =>
    letterGroups.includes(groupId) ? UserQuotaGroupType.Letter : UserQuotaGroupType.Cloud;
const isYearView = (groupId: UserQuotaGroupId) => yearFilterGroups.includes(groupId);

// Список актуальных метатредов лежит в репе JSSDK
const getUserMetathreads = (folders) =>
    [Folders.News, Folders.NewsLetters, Folders.Social, Folders.ToMySelf].filter((folder) => folders.includes(folder));

export const getSortParams = (isYearAllView: boolean): { sort: Sort; order: Order } => {
    return {
        sort: isYearAllView ? Sort.mtime : Sort.size,
        order: isYearAllView ? Order.asc : Order.desc,
    };
};

export const prepareParamsQuotaCleaner = (params: {
    folders: string[];
    userFolders: string[];
    limit: number;
    isYearFilter: boolean;
    oldYearGroupShift: number;
    excludedGroupIds?: UserQuotaGroupId[];
}): Partial<Record<UserQuotaGroupId, UserQuotaCleanerListParams>> => {
    const { folders, userFolders, limit, isYearFilter, oldYearGroupShift, excludedGroupIds = [] } = params;
    const now = new Date();
    const lasteDayOfThisYear = lastDayOfYear(now);
    const metathreads = getUserMetathreads(folders);

    const config: Record<UserQuotaGroupId, Partial<UserQuotaCleanerListParams>> = {
        [UserQuotaGroupId.Mounted]: {
            mounted: true,
        },
        [UserQuotaGroupId.Backup]: {
            folders: [Folders.Backups],
        },
        [UserQuotaGroupId.CloudLarge]: {
            min_size: 100 * Spaces.ONE_MB,
        },
        [UserQuotaGroupId.Video]: {
            extensions: extInfo.getExtsByKind('video'),
        },
        [UserQuotaGroupId.CloudOld]: {
            older_than: getTimeYearShifted(lasteDayOfThisYear, oldYearGroupShift),
        },
        [UserQuotaGroupId.CameraUploads]: {
            folders: [Folders.CameraUploads],
        },
        [UserQuotaGroupId.MailBin]: {
            folders: [Folders.LetterTrashbin],
        },
        [UserQuotaGroupId.MailSpam]: {
            folders: [Folders.LetterSpam],
        },
        [UserQuotaGroupId.Newsletters]: {
            newsletter: true,
            folders: [Folders.LetterInbox, Folders.NewsLetters, ...metathreads, ...userFolders],
        },
        [UserQuotaGroupId.LargeSent]: {
            send_by: true,
            min_size: 25 * Spaces.ONE_MB,
            folders: [Folders.LetterSent, ...metathreads, ...userFolders],
        },
        [UserQuotaGroupId.LargeInbox]: {
            send_by: false,
            min_size: 25 * Spaces.ONE_MB,
            folders: [Folders.LetterInbox, ...metathreads, ...userFolders],
        },
        [UserQuotaGroupId.OldSent]: {
            send_by: true,
            older_than: getTimeYearShifted(lasteDayOfThisYear, oldYearGroupShift),
            folders: [Folders.LetterSent, ...metathreads, ...userFolders],
        },
        [UserQuotaGroupId.Read]: {
            mark_read: true,
            mark_flagged: false,
            folders: [Folders.LetterInbox, ...metathreads, ...userFolders],
        },
        [UserQuotaGroupId.WithAttaches]: {
            with_attach: true,
            older_than: getTimeYearShifted(now, 1),
            folders: [...folders.filter((folder) => folder !== Folders.LetterTrashbin && folder !== Folders.Official)],
        },
        [UserQuotaGroupId.OldInbox]: {
            older_than: getTimeYearShifted(lasteDayOfThisYear, oldYearGroupShift),
            folders: [Folders.LetterInbox, ...metathreads, ...userFolders],
        },
    };

    return (Object.keys(config) as UserQuotaGroupId[]).reduce((acc, groupId) => {
        if (!excludedGroupIds.includes(groupId)) {
            const yearView = isYearFilter && isYearView(groupId);

            acc[groupId] = {
                ...config[groupId],
                group_id: groupId,
                group_type: getGroupType(groupId),
                ...(yearView && { year_view: yearView }),
                limit,
                ...getSortParams(yearView),
            };
        }

        return acc;
    }, {} as Partial<Record<UserQuotaGroupId, UserQuotaCleanerListParams>>);
};

export class QuotaCleanerListAPICall extends APICallV4<{ body?: UserQuotaCleanerGroup[] }> {
    _method = 'user/quota/list';
    _type: Method = 'post';
}

export class QuotaCleanerGroupAPICall extends APICallV4<{ body?: UserQuotaCleanerGroup }> {
    _method = 'user/quota/group';
    _type: Method = 'post';
}

export class QuotaCleanerDeleteAPICall extends APICallV4<{ body?: UserQuotaCleanerDelete }> {
    _method = 'user/quota/delete';
    _type: Method = 'post';
}
