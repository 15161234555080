import React, { type ReactElement, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { EDropdownColors } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown.types';
import { TOOLTIP_OFFSET } from 'reactApp/components/SharingWindow/Sharing.constants';
import { type IAccessRightsOptions, EAccessRights } from 'reactApp/components/SharingWindow/Sharing.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import { ACCESS_RIGHTS_OPTIONS } from '../User/User.constants';
import styles from './SharingNewAddUser.css';

export const useAccessRights = (isInputDisabled: boolean, isReadOnly: boolean = false): [EAccessRights, () => ReactElement | null] => {
    const [accessRight, setAccessRight] = useState(isReadOnly ? EAccessRights.READ_ONLY : EAccessRights.READ_WRITE);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const renderAccessRightsDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(ACCESS_RIGHTS_OPTIONS).map((item: IAccessRightsOptions) => ({
                id: item.key,
                text: item.text,
                check: item.key === accessRight,
                qaValue: item.key,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [accessRight]
    );

    const handleAccessRightsUpdate = useCallback((id): void => setAccessRight(id), []);

    const renderDropdown = useCallback((): ReactElement | null => {
        const item = ACCESS_RIGHTS_OPTIONS[accessRight];

        return (
            <div className={styles.rightsItem}>
                <Dropdown
                    withinDialog
                    theme="octavius"
                    qaValue={item.key}
                    as={SharingDropdown}
                    value={item.text}
                    color={EDropdownColors.GRAY}
                    placement={tooltipPlacements.BOTTOM_LEFT}
                    content={renderAccessRightsDropdown}
                    onClickItem={handleAccessRightsUpdate}
                    inInput
                    disabled={isInputDisabled}
                    tooltipOffset={TOOLTIP_OFFSET}
                    animatedTooltip
                    isPhone={isPhone}
                    noPageOffset
                />
            </div>
        );
    }, [accessRight, handleAccessRightsUpdate, isInputDisabled, isPhone, renderAccessRightsDropdown]);

    return [accessRight, renderDropdown];
};
