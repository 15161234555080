import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { USER_BILLING_PARAMS } from 'reactApp/appHelpers/configHelpers';

const businessTemplates: boolean = getFeature('business-templates');

/**
 * PDSM-2692: Микробизнесы. Разделы в облаке "Селлерам" и "Сфера красоты"
 *
 * Раздел шаблонов показывается если включена фича `business-templates` и в биллинг маске есть 8ой бит
 */
export const BUSINESS_TEMPLATES_ENABLED = businessTemplates && USER_BILLING_PARAMS.HAS_MBIZ_TEMPLATES;
