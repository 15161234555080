import type { AxiosResponse, Method } from 'lib/axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'file/publish';
class PublishFileAPIV2Call extends APICallV2Post {
    _method = method;
}

class PublishFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const publishFileApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateFileV2ToV4.publish) {
        return new PublishFileAPIV4Call().makeRequest(params);
    }

    return new PublishFileAPIV2Call().makeRequest(params);
};
