import { Spacing } from '@vkontakte/vkui';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isPromoTariffsLoaded } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { Discounts } from 'reactApp/sections/MobileSubscriptionsPage/Discounts/Discounts';
import { useDiscounts } from 'reactApp/sections/MobileSubscriptionsPage/hooks/useDiscounts';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './DiscountsModal.css';

export const DiscountsModal = memo(({ onClose, isRebrandingQuotaLanding }: { onClose: () => void; isRebrandingQuotaLanding?: boolean }) => {
    const ref = useRef<HTMLDivElement>(null);

    const discounts = useDiscounts();
    const isLoaded = useSelector(isPromoTariffsLoaded);

    return (
        <MobileDialog
            id="mobile-discounts-modal"
            title={<span className={styles.title}>Акции</span>}
            onClose={onClose}
            closeOnDimmerClick
            mod="confirm"
            topmost
            scrollRef={ref}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        >
            <Spacing size={8} />
            {isLoaded ? (
                <div className={styles.content} ref={ref}>
                    <Discounts discounts={discounts} isCompact isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
                </div>
            ) : (
                <>
                    <div className={styles.loadingBlock}>
                        <Placeholder />
                    </div>
                    <div className={styles.loadingBlock}>
                        <Placeholder />
                    </div>
                </>
            )}
        </MobileDialog>
    );
});

DiscountsModal.displayName = 'DiscountsModal';
