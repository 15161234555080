import { Spacing, Text } from '@vkontakte/vkui';
import imageD from 'img/referralProgramPromo/50-percent-sale-desktop.jpg';
import imageE from 'img/referralProgramPromo/1000-points-desktop.jpg';
import imageB from 'img/referralProgramPromo/free-10gb-desktop.jpg';
import imageC from 'img/referralProgramPromo/free-200gb-desktop.jpg';
import React, { memo, useEffect } from 'react';
import { abReferralProgram } from 'reactApp/appHelpers/featuresHelpers/features/referralProgram';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';

import styles from './ReferralProgramPromoModal.css';
import type { Props } from './ReferralProgramPromoModal.types';

const imageMap = {
    b: imageB,
    c: imageC,
    d: imageD,
    e: imageE,
};

const primaryButtonTextMap = {
    b: 'Рекомендовать',
    c: 'Рекомендовать',
    d: 'Скопировать ссылку',
    e: 'Скопировать ссылку',
};

const titleMap = {
    b: 'Порекомендуйте подписку Mail Space \u2014\u00A0получите 10 ГБ',
    c: 'Порекомендуйте подписку Mail Space \u2014\u00A0получите 200 ГБ',
    d: 'Советуйте Mail Space другим \u2014\u00A0копите скидки для себя',
    e: 'Советуйте подписку Mail Space и получайте 1\u00A0000\u00A0баллов',
};

const textMap = {
    b: 'Добавим к вашему пространству 10 ГБ, если кто-то оплатит подписку по вашей ссылке',
    c: 'Добавим к вашему пространству 200 ГБ, если кто-то оплатит подписку по вашей ссылке',
    d: 'Дадим скидку на\u00A0любой тариф за каждого, кто оплатит подписку по вашей ссылке: 50% за первого, 10% за следующих',
    e: '1 балл равен 1₽ \u2014\u00A0сможете потратить на любой тариф, когда кто-то оплатит подписку по вашей ссылке',
};

export const ReferralProgramPromoModal = memo<Props>(({ onShow, onClick, onClose }) => {
    useEffect(onShow, []);

    const content = (
        <>
            <Spacing size={12} />
            <Text className={styles.text} weight="3">
                {textMap[abReferralProgram]}
            </Text>
            <Spacing size={24} />
        </>
    );

    return (
        <WhatsNewDialog
            primaryButtonText={primaryButtonTextMap[abReferralProgram]}
            title={titleMap[abReferralProgram]}
            imageUrl={imageMap[abReferralProgram]}
            content={content}
            dialogSize="small"
            buttonTheme="vk"
            onClick={onClick}
            onCloseButtonClick={onClose}
            closeByPrimaryButton
        />
    );
});

ReferralProgramPromoModal.displayName = 'ReferralProgramPromoModal';
