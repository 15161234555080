import classNames from 'clsx';
import React, { type VFC } from 'react';
import { summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { Switch as SwitchUI } from 'reactApp/ui/Switch/Switch';

import styles from './TariffSwitch.css';
import type { ISwitchProps } from './TariffSwitch.types';

export const TariffSwitch: VFC<ISwitchProps> = ({
    dataQa,
    options,
    name = '',
    checked = false,
    onClick,
    sizeSmall,
    /* tempexp_17127-start */
    sizeExtraSmall,
    gray,
    /* tempexp_17127-end */
    dark,
    lightBg = false,
    isRebrandingQuotaLanding = false,
    isRebranding = false,
    /* tempexp_17509-next-line */
    isFromLitres = false,
}) => {
    const [firstOption, secondOption] = options;

    return (
        <div
            className={classNames(styles.switch, {
                [styles.switch_sizeSmall]: sizeSmall,
                [styles.switch_sizeExtraSmall]: sizeExtraSmall,
                [styles.switch_dark]: dark,
                [styles.switch_summer]: summerPromotion,
                [styles.switch_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            })}
            onClick={onClick}
            data-qa={dataQa}
        >
            <div
                className={classNames(styles.switchLabel, {
                    [styles.switchLabel_active]: !checked,
                    [styles.switchLabel_rebranding]: isRebranding,
                })}
                data-qa={dataQa && 'firstOption'}
                data-qa-value={dataQa && firstOption.value}
                data-qa-checked={dataQa && !checked}
            >
                <div data-qa={dataQa && 'text'}>{firstOption.text}</div>
                {firstOption.note && (
                    <div
                        data-qa={dataQa && 'note'}
                        className={classNames(styles.switchLabelNote, { [styles.switchLabelNote_rebranding]: isRebranding })}
                    >
                        {firstOption.note}
                    </div>
                )}
            </div>
            <div className={classNames(styles.switchBlock, { [styles.summerPromo]: summerPromotion && lightBg })}>
                <SwitchUI
                    dark={dark}
                    gray={gray}
                    name={name}
                    checked={checked}
                    isRebrandingQuotaLanding={isRebrandingQuotaLanding}
                    theme="big"
                    isRebranding={isRebranding}
                    /* tempexp_17509-next-line */
                    isFromLitres={isFromLitres}
                />
            </div>
            <div
                className={classNames(styles.switchLabel, styles.switchLabel_right, {
                    [styles.switchLabel_active]: checked,
                    [styles.switchLabel_rebranding]: isRebranding,
                })}
                data-qa={dataQa && 'secondOption'}
                data-qa-value={dataQa && secondOption.value}
                data-qa-checked={dataQa && checked}
            >
                <div data-qa={dataQa && 'text'}>{secondOption.text}</div>
                {secondOption.note && (
                    <div
                        data-qa={dataQa && 'note'}
                        className={classNames(styles.switchLabelNote, { [styles.switchLabelNote_rebranding]: isRebranding })}
                    >
                        {secondOption.note}
                    </div>
                )}
            </div>
        </div>
    );
};
