import { ReactComponent as RecentIcon } from '@vkontakte/icons/src/svg/16/recent_16.svg';
import React, { type ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import { EXPIRES_OPTIONS, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { ExpiryPeriod } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { deleteWeblinkExpiresRequest, updateWeblinkExpiresRequest } from 'reactApp/modules/modifying/modifying.actions';
import type { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { formatExpiresLeftDropdown } from 'reactApp/utils/formatDate';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

// требуется поправить формат текста
const setExpiresText = (expires: number): string => {
    return `Осталось ${formatExpiresLeftDropdown(expires)}`;
};
interface Props {
    item?: PublishItem;
}

export const ExpiriesDwopdown: React.FC<Props> = ({ item }) => {
    const dispatch = useDispatch();
    const isPhone = EnvironmentSelectors.isPhone();
    const expires = item?.weblinkExpires;
    const ctime = item?.ctime ? item.ctime * 1000 : 0;
    const expiresTimeSize = item?.expiresTimeSize;

    const renderExpiresDropdown = useCallback(
        (onClick: () => void): ReactElement => {
            const list = Object.values(EXPIRES_OPTIONS).map((item) => {
                if (expires && (item?.timeSize === expires - ctime || expiresTimeSize === item?.timeSize)) {
                    return {
                        ...item,
                        text: setExpiresText(expires),
                        id: ExpiryPeriod.Expiry,
                        check: true,
                        qaValue: ExpiryPeriod.Expiry,
                        expiresTimeSize: item.timeSize,
                    };
                }
                return {
                    id: item.id,
                    text: item.text,
                    check: !expires && item.id === ExpiryPeriod.Unlimited,
                    qaValue: item.id,
                    expiresTimeSize: item.timeSize,
                };
            });

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [expires, ctime, expiresTimeSize]
    );

    const handleUpdateExpires = useCallback(
        (id: ExpiryPeriod): void => {
            const expires = EXPIRES_OPTIONS[id];

            if (id === ExpiryPeriod.Expiry) {
                return;
            }

            if (item) {
                sendGa('expires');
                sendPaymentGa({
                    eventCategory: ECategoryGa.public,
                    action: 'edit-ttl',
                    time_ttl: id,
                    public_id: item?.weblink,
                });

                if (expires.id === ExpiryPeriod.Unlimited) {
                    dispatch(deleteWeblinkExpiresRequest({ item }));

                    return;
                }

                dispatch(updateWeblinkExpiresRequest({ item, expires: expires.getValue(), expiresTimeSize: expires.timeSize }));
            }
        },
        [dispatch, item]
    );
    const renderExpiries = useCallback((): ReactElement => {
        const item = EXPIRES_OPTIONS[ExpiryPeriod.Unlimited];
        const text = expires ? setExpiresText(expires) : item?.text;

        return (
            <div className={styles.rightsItem} data-qa-item={QA_VALUE.expiresItem}>
                <div className={styles.titleWrapper}>
                    <span className={styles.icon}>
                        <RecentIcon />
                    </span>
                    <span className={styles.rightsTitle}>Срок действия доступа</span>
                </div>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item.id}
                        as={SharingNewDropdown}
                        value={text}
                        content={renderExpiresDropdown}
                        onClickItem={handleUpdateExpires}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.expiresDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        description="Срок действия доступа"
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                        noPageOffset={isPhone}
                    />
                </div>
            </div>
        );
    }, [expires, handleUpdateExpires, isPhone, renderExpiresDropdown]);

    return renderExpiries();
};
