import classNames from 'clsx';
import format from 'date-fns/format';
import { ReactComponent as LogoCloud } from 'img/icons/logo_cloud.svg';
import React, { type ReactElement, memo, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { APP_TITLE, APP_WHITE_LOGO, ENABLE_FULL_RESPONSIVE, IS_B2B_BIZ_USER, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import { MobileAppPromoTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/MobileAppPromoTooltip';
import opener from 'reactApp/utils/opener';

import styles from './AttachInfo.css';

interface Props {
    name: string;
    author: string;
    fileName: string;
    fileExt: string;
    size: string;
    timestamp: number;
    isTemporary: boolean;
    onClick();
}

const handleCloudLogoClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    opener('/home', true);
};

export const AttachInfo = memo(({ name, author, timestamp, onClick, fileName, fileExt, size, isTemporary }: Props): ReactElement | null => {
    const fileTitle = `${fileName}.${fileExt}`;
    const hintText = IS_B2B_BIZ_USER ? APP_TITLE : 'Облако Mail';
    const logoRef = useRef<HTMLDivElement>(null);

    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    const myTeamAttachInfo = useMemo(() => {
        return [name, fileTitle, size].filter(Boolean).join(', ');
    }, [name, fileTitle, size]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootClickable]: !isTemporary && !IS_MY_TEAM,
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
            })}
            onClick={IS_MY_TEAM ? undefined : onClick}
        >
            <MobileAppPromoTooltip elementRef={logoRef} />
            {!IS_MY_TEAM && (
                <>
                    <Hint theme="dark" text={hintText}>
                        {IS_B2B_BIZ_USER ? (
                            <img className={styles.logoB2B} src={APP_WHITE_LOGO} />
                        ) : (
                            <div className={styles.iconWrapper} onClick={handleCloudLogoClick} ref={logoRef}>
                                <LogoCloud className={styles.newLogo} />
                            </div>
                        )}
                    </Hint>
                    <div className={styles.divider} />
                </>
            )}
            <div className={styles.attachBlock}>
                {!IS_MY_TEAM ? (
                    <div className={styles.attachTopBlock}>
                        <div className={styles.author}>{Boolean(author) && author}</div>
                        <div className={styles.attachTime}>{Boolean(timestamp) && format(timestamp, 'dd.MM.yy, HH:mm')}</div>
                    </div>
                ) : (
                    <div className={styles.attachTeamTopBlock}>
                        {Boolean(author) && author}
                        <span className={styles.attachTime}>{Boolean(timestamp) && format(timestamp, 'dd.MM.yy, HH:mm')}</span>
                    </div>
                )}
                {!IS_MY_TEAM ? (
                    <div className={styles.attachBottomBlock}>
                        {name && (
                            <Hint text={name} theme="dark" showDelay>
                                <div className={styles.attachName}>{name}</div>
                            </Hint>
                        )}
                        {fileTitle && (
                            <Hint text={fileTitle} theme="dark" showDelay>
                                <div className={classNames({ [styles.attachItemPrefix]: name })}>
                                    <NameComponent name={fileName} extension={fileExt} truncate />
                                </div>
                            </Hint>
                        )}
                        {size && (
                            <div data-qa-id="attach-info-size" className={classNames({ [styles.attachItemPrefix]: fileTitle })}>
                                {size}
                            </div>
                        )}
                        {isViewerFileVerifiedByKaspersky && <ProtectedHint text="Файл проверен антивирусом Касперского" />}
                    </div>
                ) : (
                    <div className={styles.attachTeamBottomBlock}>
                        <Hint text={myTeamAttachInfo} theme="dark">
                            <>{myTeamAttachInfo}</>
                        </Hint>
                    </div>
                )}
            </div>
        </div>
    );
});

AttachInfo.displayName = 'AttachInfo';

AttachInfo.whyDidYouRender = true;
