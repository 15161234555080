import classNames from 'clsx';
import React, { type ReactElement } from 'react';
import styles from 'reactApp/ui/UploadDropArea/UploadButtons/UploadButtons.css';

import { useUploadButtons } from './UploadButtons.hooks';

interface IProps {
    onFolderClick: (e: React.MouseEvent) => void;
    onFileClick: (e: React.MouseEvent) => void;
    onSelectFromCloud: (e: React.MouseEvent) => void;
}

export const UploadButtons = (props: IProps): ReactElement => {
    const buttons = useUploadButtons(props);

    return (
        <div className={styles.root}>
            {buttons.map(({ icon, onClick, text, id, isWide = false }) => (
                <div className={classNames(styles.uploadItem, { [styles.wide]: isWide })} onClick={onClick} key={id}>
                    {icon}
                    <div className={styles.text}>{text}</div>
                </div>
            ))}
        </div>
    );
};
