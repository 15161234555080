import classNames from 'clsx';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Checkbox } from 'reactApp/ui/DatalistGalleryItem/components/Checkbox';
import { Download } from 'reactApp/ui/DatalistGalleryItem/components/Download';
import { Favorites } from 'reactApp/ui/DatalistGalleryItem/components/Favorites';
import { Pic } from 'reactApp/ui/DatalistGalleryItem/components/Pic';
import { Publish } from 'reactApp/ui/DatalistGalleryItem/components/Publish';
import type { DataListGalleryItemProps } from 'reactApp/ui/DatalistGalleryItem/DataListGalleryItem.types';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './DataListGalleryItem.css';

const sendGa = createGaSender('gallery-view');

export const DataListGalleryItem = memo((props: DataListGalleryItemProps) => {
    const {
        name,
        kind,
        onCheckboxClick,
        isSelected,
        isActive,
        isSelecting,
        thumbUrl,
        onDownload,
        id,
        ext,
        isPublic,
        onPublish,
        isInFavorites,
        onFavorites,
        storage,
        size,
        favoritesAvailable,
        isPopup,
        isPublicDownloaded,
    } = props;

    const itemRef = useRef<HTMLDivElement>(null);
    const controlsSettings = STORAGE_CONFIG[storage];
    const showPublish = storage !== EStorageType.albums;

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdown = useCallback(
        (isOpen) => {
            sendGa('download-dropdown');
            setDropdownOpen(isOpen);
        },
        [setDropdownOpen]
    );

    const handlePublish = useCallback(
        (e) => {
            sendGa('publish');
            onPublish(e);
        },
        [onPublish]
    );

    const handleDownload = useCallback(() => {
        sendGa('download');
        onDownload();
    }, [onDownload]);

    const handleFavorites = useCallback(() => {
        sendGa('favorites');
        onFavorites();
    }, [onFavorites]);

    const showDoanloadButton = !(storage === EStorageType.public && isPublicDownloaded);

    const controls = useMemo(
        () => ({
            favorites: (favoritesAvailable || storage === EStorageType.gallery) && (
                <Favorites key={1} isActive={!!isInFavorites} onClick={handleFavorites} />
            ),
            download: showDoanloadButton && (
                <Download
                    key={2}
                    ext={ext}
                    onClick={handleDownload}
                    onDropdown={handleDropdown}
                    storage={storage}
                    size={size}
                    id={id}
                    name={name}
                />
            ),
            publish: showPublish ? <Publish key={3} isActive={isPublic} onClick={handlePublish} /> : null,
        }),
        [ext, id, isInFavorites, isPublic, size, favoritesAvailable, name, showDoanloadButton]
    );

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_selected]: isSelected || isDropdownOpen,
                [styles.root_active]: isActive,
                [styles.root_selecting]: isSelecting,
                [styles.root_dropdownOpen]: isDropdownOpen,
                [styles.root_small]: itemRef?.current && itemRef?.current?.getBoundingClientRect().width < 170,
            })}
            onClick={isPopup ? onCheckboxClick : noop}
            ref={itemRef}
        >
            <div className={styles.content}>
                <Pic thumbUrl={thumbUrl} ext={ext} kind={kind} />
            </div>
            <div className={styles.footer}>
                <Checkbox isActive={isSelected} onClick={onCheckboxClick} />
                {!isPopup && (
                    <div className={styles.rightControls}>
                        {Object.keys(controls).map((control) => controlsSettings[control] && controls[control])}
                    </div>
                )}
            </div>
        </div>
    );
});

DataListGalleryItem.displayName = 'DataListGalleryItem';
