import classNames from 'clsx';
import React, { type ReactElement, type ReactNode } from 'react';
import { Button as ButtonToolkit } from 'semantic-ui-react';
import type { ButtonProps } from 'semantic-ui-react/src/elements/Button/Button';

import styles from './Button.css';

export enum ButtonSizeMode {
    extraSmall = 'extra_small',
    small = 'small',
    middle = 'middle',
    big = 'big',
    extraBig = 'extra_big',
}

export enum ButtonVariant {
    contrast = 'contrast',
    flat = 'flat',
}

interface Props extends ButtonProps {
    theme?: 'base' | 'octavius' | 'white' | 'vk' | '';
    orange?: boolean;
    middle?: boolean;
    sizeMode?: ButtonSizeMode;
    variant?: ButtonVariant;
    error?: boolean;
    children: ReactNode;
    qaId?: string;
}

export const Button = ({
    children,
    theme = 'base',
    orange = false,
    middle = false,
    sizeMode,
    className = '',
    qaId,
    error,
    variant,
    ...rest
}: Props): ReactElement => (
    <ButtonToolkit
        {...rest}
        data-qa-id={qaId}
        className={classNames({
            [styles.btnOrange]: orange,
            [styles.btnError]: error,
            [styles.btnSmall]: sizeMode === ButtonSizeMode.small,
            [styles.btnMiddle]: middle || sizeMode === ButtonSizeMode.middle,
            [styles.btnBig]: sizeMode === ButtonSizeMode.big,
            [styles.btnExtraSmall]: sizeMode === ButtonSizeMode.extraSmall,
            [styles.btnExtraBig]: sizeMode === ButtonSizeMode.extraBig,
            [styles.btnContrast]: variant === ButtonVariant.contrast,
            [styles.btnFlat]: variant === ButtonVariant.flat,
            [styles[`btn_${theme}`]]: !!theme,
            [className]: !!className,
        })}
    >
        {children}
    </ButtonToolkit>
);
