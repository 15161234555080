import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { withViewContext } from 'reactApp/hocs/withViewContext';
import type { Props as WithXrayProps } from 'reactApp/hocs/withXray';
import type { RootState } from 'reactApp/store';
import type { IProps } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { ReactViewerComponent } from 'reactApp/ui/ReactViewer/ReactViewerComponent';
import { compose } from 'redux';

interface Props extends IProps, WithXrayProps {}

const mapStateToProps = (state: RootState, props: IProps) => ({
    ...props,
    requestId: undefined,
    userId: undefined,
    isViewerFileVerifiedByKaspersky: true,
});

export const ReactViewerLetters = compose<FunctionComponent<Props>>(withViewContext, connect(mapStateToProps))(ReactViewerComponent);
