import { Title } from '@vkontakte/vkui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { animated } from 'react-spring';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { AVAILABLE_BG_IMAGES_THEMES, AVAILABLE_COLOR_THEMES } from 'reactApp/appHelpers/themeToolHelpers/default-values';
import type { QueryParams } from 'reactApp/modules/settings/settings.types';
import { ThemeOutsourceSelectors } from 'reactApp/modules/theme.outsource/theme.outsource.selectors';
import { ThemePublicSelectors } from 'reactApp/modules/themePublic/themePublic.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { sendDwh } from 'reactApp/utils/ga';

import { useExpandTransition } from './hooks/useExpandTransition';
import { useForegroundTabAlert } from './hooks/useForegroundTabAlert';
import { useHandlers } from './hooks/useHandlers';
import { useShowUnsavedChanges } from './hooks/useShowUnsavedChanges';
import styles from './ThemeChooser.css';
import { ThemeChooserItem } from './ThemeChooserItem';

const unsavedChangesDescription = (
    <>
        Оформление для папок можно сохранить с подпиской
        <br /> Mail Space. А ещё — отключится реклама и можно
        <br /> будет загружать файлы до 100 ГБ.
    </>
);

const unsavedChangesDescriptionForPaid = (
    <>
        Вы добавили оформление для папки, его увидят
        <br />
        получатели ссылки
    </>
);

const queryParams: QueryParams = getQueryParams();
const { source } = queryParams;

export function ThemeChooser() {
    const isNewThemeSelected = useSelector(ThemePublicSelectors.isNewPublicThemeSelected);
    const isPaid = useSelector(UserSelectors.isPaidUser);
    const themeModels = useSelector(ThemeOutsourceSelectors.getAvailableThemes);
    const choosedTheme = useSelector(ThemeOutsourceSelectors.getChoosedTheme);
    const [isUnsavedChangesModalShowed, setUnsavedChangesModalShowed] = useState(false);
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.BRAND_PAGE_VIEW);
        sendDwh({
            eventCategory: 'brand_page',
            action: 'view',
            dwhData: {
                source,
            },
        });
    }, []);

    const { toggleExpand, isExpanded, transitions, ref } = useExpandTransition(source);
    const { onSaveClick, showUnsavedChangesModal, chooseItem } = useHandlers({
        isPaid,
        setUnsavedChangesModalShowed,
        unsavedChangesDescription,
        unsavedChangesDescriptionForPaid,
        choosedTheme,
        source,
    });

    useShowUnsavedChanges(showUnsavedChangesModal, !isUnsavedChangesModalShowed && isNewThemeSelected);
    useForegroundTabAlert(showUnsavedChangesModal, !isPaid);

    const renderItem = useCallback(
        (themeId) =>
            themeModels[themeId] && (
                <ThemeChooserItem
                    key={themeId}
                    themeModel={themeModels[themeId]}
                    isSelected={themeId === choosedTheme}
                    onClick={() => {
                        chooseItem(themeId);
                    }}
                />
            ),
        [choosedTheme, themeModels]
    );

    return transitions((style) => {
        return (
            <animated.div style={style} className={styles.root} ref={ref}>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <Button theme="vk" sizeMode={ButtonSizeMode.middle} className={styles.button} onClick={toggleExpand}>
                            {isExpanded ? 'Свернуть' : 'Развернуть'}
                        </Button>
                        <Title level="2">Оформление для папки</Title>
                        <Button theme="vk" sizeMode={ButtonSizeMode.middle} primary className={styles.button} onClick={onSaveClick}>
                            Сохранить
                        </Button>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.themeContainer}>
                            <div className={styles.themeLine}>{AVAILABLE_COLOR_THEMES.map(renderItem)}</div>
                            <div className={styles.themeLine}>{AVAILABLE_BG_IMAGES_THEMES.map(renderItem)}</div>
                        </div>
                    </div>
                </div>
            </animated.div>
        );
    });
}
