import { getSegmentByQuota, getUsableSpaceRatio, Segments } from '@mail/cross-quota-utils';
import { type Space, bytesToNDigits } from '@mail/cross-sizes-utils';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { ITotalQuota, IUserQuotaState, State } from 'reactApp/modules/userQuota/userQuota.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getQuotaState = (state: RootState) => state.userQuota as State;

const getLoadingState = createSelector(
    getQuotaState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const getTotal = createSelector(getQuotaState, (state: State): Space | null => state.quota);
const getUsed = createSelector(getQuotaState, (state: State) => state.used);
const getTotalUsed = createSelector(getQuotaState, (state: State): Space | null => state.used?.total);
const getRemaining = createSelector(getTotal, getUsed, (total, used) =>
    bytesToNDigits((total?.original || 0) - (used?.total?.original || 0), 3)
);

const isSingleQuotaEnabled = createSelector(getQuotaState, (state: State): boolean => state.isSingleQuotaEnabled);

const isFull = createSelector(getQuotaState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const segment = getSegmentByQuota(quota.original, used.total.original);

    return [Segments.full, Segments.overflow].includes(segment);
});

const isOverquota = createSelector(getQuotaState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const total = quota.original;
    const usedAll = used.total.original;

    return usedAll > total;
});

const isAlmostFull = createSelector(getQuotaState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const segment = getSegmentByQuota(quota.original, used.total.original);

    return segment === Segments.almostFull;
});

const is100PercentFull = createSelector(getQuotaState, (state: State): boolean => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return false;
    }

    const segment = getSegmentByQuota(quota.original, used.total.original);

    return segment === Segments.full;
});

const getFilledPercents = createSelector(getQuotaState, (state: State): { used: number; cloud: number; mail: number } => {
    const { quota, used } = state;

    if (!quota || !used) {
        return {
            used: 0,
            mail: 0,
            cloud: 0,
        };
    }

    const {
        cloud: cloudPercents,
        mail: mailPercents,
        total: usedTotalPercents,
    } = getUsableSpaceRatio({
        quota: quota.original,
        used: {
            total: used.total?.original || 0,
            mail: used.mail?.original,
            cloud: used.cloud?.original,
        },
    });

    return {
        used: usedTotalPercents === 0 ? 1 : usedTotalPercents,
        mail: mailPercents === 0 ? 1 : mailPercents,
        cloud: cloudPercents === 0 ? 1 : cloudPercents,
    };
});

export const getOverQuota = createSelector(getQuotaState, (state: State): Space => {
    const { quota, used } = state;

    if (!quota || !used || !used?.total) {
        return bytesToNDigits(0, 3);
    }

    const total = quota.original;
    const usedAll = used.total.original;

    const over = Math.max(usedAll - total, 0);

    return bytesToNDigits(over, 3);
});

export const getUserQuotaState = createSelector(
    (state) => state,
    getTotal,
    isOverquota,
    getUsed,
    getFilledPercents,
    getRemaining,
    (state) => UserSelectors.getCloudSpace(state),
    (state, total, isOverQuota, used, usedPercent, remaining, onlyCloudSpace): IUserQuotaState => {
        const isBizUser = UserSelectors.isBizUser(state);

        if (isBizUser) {
            return {
                isOverQuota: onlyCloudSpace.isFull,
                used: onlyCloudSpace.used,
                usedPercent: onlyCloudSpace.usedPercent,
                userTotalSpace: onlyCloudSpace.total,
                remaining: onlyCloudSpace.remaining,
            };
        }

        return {
            userTotalSpace: total,
            isOverQuota,
            used: used?.total,
            usedPercent: usedPercent?.used,
            remaining,
        };
    }
);

const getIsMailBinDisabled = createSelector(getQuotaState, (state: State) => state.isMailBinDisabled);

const getSpace = createSelector(getTotal, getUsed, (total, used): ITotalQuota => {
    const totalSize = total?.original;
    const usedSize = used?.total?.original;
    const freeSize = totalSize && totalSize - Number(usedSize);

    return {
        totalSize,
        usedSize,
        freeSize,
    };
});

const getOwnerUsedQuotaInFamily = createSelector(
    getSpace,
    (state, familyQuotaPlan) => familyQuotaPlan,
    (space, familyQuotaPlan) => {
        const { totalSize = 0, usedSize = 0 } = space;

        const notFamilyQuota = totalSize - familyQuotaPlan;

        return usedSize <= notFamilyQuota ? 0 : usedSize - notFamilyQuota;
    }
);

const getTotalOrBase = createSelector(getTotal, UserSelectors.getBasicSpace, (total, basic) => total?.original || basic || BASEQUOTA);

export const UserQuotaSelectors = {
    getLoadingState,
    getTotal,
    getRemaining,
    getUsed,
    isFull,
    isAlmostFull,
    getFilledPercents,
    isSingleQuotaEnabled,
    getUserQuotaState,
    isOverquota,
    getIsMailBinDisabled,
    is100PercentFull,
    getSpace,
    getOverQuota,
    getOwnerUsedQuotaInFamily,
    getTotalUsed,
    getTotalOrBase,
};
