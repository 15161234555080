/**
 * @see https://www.figma.com/file/ho7TMiQlwfBDojkjxAZ8vj/Icons
 */
export { ReactComponent as TrashbinIcon } from 'img/icons/vkui/delete_outline_20.svg';
export { ReactComponent as DownloadIcon } from 'img/icons/vkui/download_outline_20.svg';
export { ReactComponent as AddCircle } from 'img/icons/vkui/add_circle_outline_20.svg';
export { ReactComponent as MoreHorizontalIcon } from 'img/icons/vkui/more_20.svg';
export { ReactComponent as ShareIcon } from 'img/icons/vkui/users_outline_20.svg';
export { ReactComponent as ArrowRightIcon } from 'img/icons/vkui/arrow_right_outline_20.svg';
export { ReactComponent as ArrowLeftIcon } from 'img/icons/vkui/arrow_left_outline_20.svg';
export { ReactComponent as CloudArrowUpIcon } from 'img/icons/vkui/cloud_arrow_up_outline_20.svg';
export { ReactComponent as ChevronDownIcon } from 'img/icons/vkui/chevron_down_medium_20.svg';
export { ReactComponent as ChevronLeftOutlineIcon } from 'img/icons/vkui/chevron_left_outline_20.svg';
export { ReactComponent as DropdownOutlineIcon } from 'img/icons/vkui/dropdown_outline_20.svg';
export { ReactComponent as FavoritesIcon } from 'img/icons/vkui/like_outline_20.svg';
export { ReactComponent as FilledFavoritesIcon } from 'img/icons/vkui/like_20.svg';
export { ReactComponent as UploadIcon } from 'img/icons/vkui/upload_cloud_outline_20.svg';
export { Icon28ChainOutline as LinkIcon } from '@vkontakte/icons';
export { ReactComponent as ExternalLinkIcon } from 'img/icons/vkui/external_link_outline_20.svg';
export { ReactComponent as Icon16LinkOutline } from 'img/icons/vkui/link_outline_16.svg';
export { ReactComponent as CheckCircleOutlineIcon } from 'img/icons/vkui/check_circle_outline_20.svg';
export { ReactComponent as CheckCircleIcon } from 'img/icons/vkui/check_circle_20.svg';
export { ReactComponent as CloudIcon } from 'img/icons/vkui/cloud_outline_20.svg';
export { ReactComponent as FolderIcon } from 'img/icons/vkui/folder_outline_20_old.svg';
export { ReactComponent as CopyIcon } from 'img/icons/vkui/copy_outline_20.svg';
export { ReactComponent as RenameIcon } from 'img/icons/vkui/write_square_outline_20.svg';
export { ReactComponent as MoveIcon } from 'img/icons/vkui/folder_move_outline_20.svg';
export { ReactComponent as MailIcon } from 'img/icons/vkui/mail_outline_20.svg';
export { ReactComponent as Icon20ThumbsDownOutline } from 'img/icons/vkui/thumbs_down_outline_20.svg';
export { ReactComponent as HistoryIcon } from 'img/icons/vkui/history_backward_outline_20.svg';
export { ReactComponent as ViewCardsIcon } from 'img/icons/vkui/view_cards_outline_20.svg';
export { ReactComponent as ViewListIcon } from 'img/icons/vkui/view_list_outline_20.svg';
export { ReactComponent as ViewGalleryIcon } from 'img/icons/vkui/view_pinterest_outline_20.svg';
export { ReactComponent as SortIcon } from 'img/icons/vkui/sort_outline_20.svg';
export { ReactComponent as UsersIcon } from 'img/icons/vkui/users_2_outline_20.svg';
export { ReactComponent as UserIcon } from 'img/icons/vkui/user_outline_20.svg';
export { ReactComponent as AddIcon } from 'img/icons/vkui/add_20.svg';
export { ReactComponent as Icon20BookmarkFill } from 'img/icons/vkui/bookmark_fill_20.svg';
export { ReactComponent as CalendarIcon } from 'img/icons/vkui/calendar_outline_20.svg';
export { ReactComponent as Icon20CalendarOutline } from 'img/icons/vkui/calendar_outline_20_new.svg';
export { ReactComponent as Icon20CalendarOutlineDarkTheme } from 'img/icons/vkui/dark/calendar_outline_20_new.svg';
export { ReactComponent as TextIconDarkTheme } from 'img/icons/vkui/dark/text_outline_20.svg';
export { ReactComponent as TextIcon } from 'img/icons/vkui/text_outline_20.svg';
export { ReactComponent as CompareIcon } from 'img/icons/vkui/compare_20.svg';
export { ReactComponent as RestoreIcon } from 'img/icons/vkui/arrow_uturn_left_outline_20.svg';
export { ReactComponent as VerifiedIcon } from 'img/icons/vkui/verified_20.svg';
export { ReactComponent as FileAddIcon } from 'img/icons/vkui/file_add_outline_20.svg';
export { ReactComponent as FileSizeIcon } from 'img/icons/vkui/file_size_outline_20.svg';
export { ReactComponent as FileSizeIconDarkTheme } from 'img/icons/vkui/dark/file_size_outline_20.svg';
export { ReactComponent as FolderAddIcon } from 'img/icons/vkui/folder_add_20.svg';
export { ReactComponent as SearchIcon } from 'img/icons/vkui/search_20.svg';
export { ReactComponent as VideoIcon } from 'img/icons/vkui/videocam_outline_20.svg';
export { ReactComponent as DocumentIcon } from 'img/icons/vkui/document_outline_20.svg';
export { ReactComponent as CancelIcon } from 'img/icons/vkui/cancel_small_20.svg';
export { ReactComponent as ShareAlbumIcon } from 'img/icons/vkui/album_outline_20.svg';
export { ReactComponent as AddToAlbum } from 'img/icons/vkui/picture_add_outline_20.svg';
export { ReactComponent as AlbumIcon } from 'img/icons/vkui/album_20.svg';
export { ReactComponent as CheckCircleOutline } from 'img/icons/vkui/check_circle_outline_24.svg';
export { ReactComponent as ClockOutline } from 'img/icons/vkui/clock_outline_24.svg';
export { ReactComponent as ErrorCircleIcon } from 'img/icons/vkui/error_circle_outline_24.svg';
export { ReactComponent as CancelIconOutline } from 'img/icons/vkui/cancel_outline_24.svg';
export { ReactComponent as CheckShieldFilled } from 'img/icons/vkui/check_shield_filled_24.svg';
export { ReactComponent as Icon28FileBrokenOutline } from 'img/icons/vkui/file_broken_outline_24.svg';
export { ReactComponent as Icon20DeleteClockOutline } from 'img/icons/vkui/delete_clock_outline_20.svg';
export { ReactComponent as Icon20FolderOutline } from 'img/icons/vkui/folder_outline_20.svg';
export { ReactComponent as Icon20MenuOutline } from 'img/icons/vkui/menu_outline_20.svg';
export { ReactComponent as Icon16DownloadCircleIcon } from 'img/icons/vkui/download_outline_16.svg';
export { ReactComponent as Icon20SortReverseOutline } from 'img/icons/vkui/sort_outline_reverse.svg';
export { ReactComponent as Icon20SortOutline } from 'img/icons/vkui/sort_outline.svg';
export { ReactComponent as Icon24ListBookmarkFill } from 'img/icons/vkui/list_bookmark_fill_24.svg';
export { ReactComponent as Icon20SmartphoneOutline } from 'img/icons/vkui/smartphone_outline_20.svg';
export { ReactComponent as Icon24ArrowLeftOutine } from 'img/icons/vkui/arrow_left_outline_24.svg';
