import { normalize, schema } from 'normalizr';
import { getExtension, getItemNameWithoutExt, getParent } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { IStory, IStoryFileItem, IStorySummary } from 'reactApp/modules/stories/stories.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';

const urlBuilder = new UrlBuilder();

const fileItemSchema = new schema.Entity<IStoryFileItem>('files', undefined, {
    idAttribute: 'path',
    processStrategy: (value) => {
        return normalizeFileItem(value);
    },
});

const summariesSchema = new schema.Entity<IStorySummary>('summaries', {
    cover: {
        file: fileItemSchema,
    },
});

const summariesListSchema = new schema.Array(summariesSchema);

const storiesSchema = new schema.Entity<IStory>('stories', {
    cover: {
        file: fileItemSchema,
    },
    header: {
        content: [
            {
                file: fileItemSchema,
            },
        ],
    },
    blocks: [
        {
            content: [
                {
                    file: fileItemSchema,
                },
            ],
        },
    ],
});

const normalizeFileItem = (file: IStoryFileItem): IStoryFileItem => {
    const { path, ...rest } = file;

    const ext = getExtension(file);
    const nameWithoutExt = getItemNameWithoutExt(file.name, ext);

    const id = path;
    const parent = getParent(id);

    const isInFavorites = Boolean(rest['favorited']);
    delete rest['favorited'];
    delete rest['duration_ms'];
    return {
        ...rest,
        path,
        nameWithoutExt,
        ext,
        id,
        storage: EStorageType.story,
        isInFavorites,
        parent,
        weblink: undefined, // Для автопаблиша
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id,
            weblink: '',
            size: rest.size,
            kind: rest.kind,
            isStock: false,
            name: rest.name,
            path,
        }),
    };
};

export const normalizeListData = (list) => {
    const normalizedData = normalize(list, summariesListSchema);

    return { summaries: normalizedData.entities.summaries, files: normalizedData.entities.files, summaryIdxs: normalizedData.result };
};

export const normalizeStoryInfoData = (data) => {
    const normalizedData = normalize(data, storiesSchema);

    if (normalizedData.entities.stories && normalizedData.entities.files) {
        return {
            story: normalizedData.entities.stories[normalizedData.result],
            files: normalizedData.entities.files as unknown as Record<string, IStoryFileItem>,
        };
    }
};

export const deleteFilesFromStory = (state, idxs) => {
    const story = state.story;
    if (story) {
        const contentFilter = (content) => !content?.file || !idxs.includes(content?.file);
        if (story.header) {
            story.header = {
                ...story.header,
                content: (story.header.content as any[]).filter(contentFilter),
            };
        }
        if (story.blocks) {
            story.blocks = story.blocks.map((block) => {
                if (!block) {
                    return block;
                }
                block.content = (block.content as any[]).filter(contentFilter);
                return block;
            });
        }
        state.story = { ...story };
    }
    idxs.forEach((id) => delete state.files[id]);
};
