import sha1 from 'js-sha1';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isPreviewable } from 'reactApp/modules/file/utils';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import type { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import type { EStoryType } from 'reactApp/modules/stories/stories.types';
import type { Kind } from 'reactApp/types/Tree';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const renderMobileViewer = ({ onClose }) => openPopupHelper({ popupName: popupNames.MOBILE_VIEWER, onClose });

export interface IViewerDwhData {
    eventCategory?: ECategoryGa;
    item?: CloudFile;
    storage?: EStorageType;
    action: string;
    label?: string;
    source?: string;
    place?: string;
    type_content?: Kind;
    type_story?: EStoryType;
    id_story?: string;
    id_media?: string;
    is_stories?: boolean;
    have_face?: boolean;
    quality?: string;
    id_public?: string;
    extension?: string;
    size_files?: number;
    is_archive?: boolean;
    // true если превьювабл, false если заглушка
    is_full_render?: boolean;
    is_edit?: boolean;
    iz_biz?: boolean;
}

export const sendViewerDwh = ({ action, eventCategory = ECategoryGa.viewer, item, storage, label, ...data }: IViewerDwhData): void => {
    if (item) {
        data = data || {};

        const isPreviewableItem = isPreviewable(item);

        data.type_content = item?.kind;
        data.extension = item?.ext;
        data.is_full_render = isPreviewableItem;
        data.size_files = item && 'size' in item ? item?.size : 0;
        data.source = 'touch';
        data.place = storage;
        data.id_media = sha1(item?.id || '');
        data.iz_biz = IS_B2B_BIZ_USER;
    }

    sendDwh({ eventCategory, action, label, dwhData: data });
};
