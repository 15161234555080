import { Spacing, Text } from '@vkontakte/vkui';
import React, { type RefObject, forwardRef, useCallback, useEffect } from 'react';
import { Portal } from 'react-portal';
import { CLOUD_NEW_FEATURES_CONFIG } from 'reactApp/appHelpers/configHelpers';
import { ECloudNewFeaturesActions, sendCloudNewFeaturesAnalytics } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.analytics';
import { CLOUD_NEW_FEATURES_LANDING_HREF } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { FeaturesListItem } from 'reactApp/ui/CloudNewFeatures/FeaturesListItem/FeaturesListItem';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './FeaturesDropdown.css';

interface IProps {
    onClose: () => void;
}

export const FeaturesDropdown = forwardRef<HTMLDivElement, IProps>(({ onClose }, ref) => {
    useEffect(() => {
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.showTool });
    }, []);

    const handleClickAll = useCallback(() => {
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.clickViewAll });
    }, []);

    return (
        <Portal>
            <FloatingTooltip
                target={ref as RefObject<HTMLDivElement>}
                placement={ETooltipPlacement.bottom}
                onClose={onClose}
                closable={false}
                hideArrow
                closeOnScroll
                qaId="tooltip-features-dropdown"
            >
                <div className={styles.root}>
                    <Text className={styles.title}>Полезные обновления Облака</Text>
                    <Spacing size={16} />
                    {CLOUD_NEW_FEATURES_CONFIG.map(({ bannerid, title, text, link, image }) => {
                        return <FeaturesListItem bannerid={bannerid} title={title} text={text} image={image} link={link} key={bannerid} />;
                    })}
                    {CLOUD_NEW_FEATURES_CONFIG.length > 2 && (
                        <>
                            <Spacing size={22} />
                            <a
                                className={styles.link}
                                href={CLOUD_NEW_FEATURES_LANDING_HREF}
                                target="_blank"
                                rel="noreferrer"
                                onClick={handleClickAll}
                            >
                                Посмотреть все
                            </a>
                        </>
                    )}
                </div>
            </FloatingTooltip>
        </Portal>
    );
});

FeaturesDropdown.displayName = 'FeaturesDropdown';
