import type { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer.types';
import videojs from 'video.js';

import { ESides, EWaitDelay } from '../../constants';
import { stimulateNav } from '../../helpers';
import styles from './styles.css';

const Component = videojs.getComponent('Component');

let lastClickPlace;
let doubleClickCounter = 0;
let lastClickTime = Date.now();
let waitDelay = EWaitDelay.TO_SECOND_CLICK;

const showNavOnMove = (e: MouseEvent, player: VideoPlayer) => {
    // клик на тач версии провоцирует mousemove
    if (!e.movementX && !e.movementY) {
        return;
    }

    stimulateNav(player);
};

const clearCounter = () => {
    doubleClickCounter = 0;
};

const onScreenClick = (e, click?: () => void, doubleClick?: (side: ESides, count: number, clearCounter: () => void) => void) => {
    const { side } = e.target.dataset;
    const timeFromLastClick = Date.now() - lastClickTime;
    lastClickTime = Date.now();

    if (timeFromLastClick < waitDelay && lastClickPlace === side) {
        // второй, третий и последующие быстрые клики
        if (timeFromLastClick < EWaitDelay.TO_SECOND_CLICK) {
            doubleClickCounter++;
            doubleClick?.(side, doubleClickCounter, clearCounter);
        } else {
            doubleClickCounter = 0;
        }
        waitDelay = EWaitDelay.BEFORE_FORGET;
        lastClickPlace = side;
    } else {
        doubleClickCounter = 0;
        waitDelay = EWaitDelay.TO_SECOND_CLICK;
        lastClickPlace = side;
        click?.();
    }
};

// Кнопки промотки
export function addRewindScreens(
    player: VideoPlayer,
    click?: () => void,
    doubleClick?: (side: ESides, count: number, clearCounter: () => void) => void
) {
    const el = document.createElement('div');
    el.className = styles.shadowNav;
    el.onmousemove = (e) => showNavOnMove(e, player);
    el.onclick = (e) => onScreenClick(e, click, doubleClick);

    // фикс для дублирование события нажатия на экран. VideoJS отслеживат кучу ивентов своих компонентов
    // это приводит к дублированию логики по нажатию на экран
    const eventRedirect = (event) => {
        event.stopImmediatePropagation();
        const eventClone = new event.constructor(event.type, event);
        const playerElement = player.el() as HTMLElement | null;
        if (playerElement) {
            playerElement.parentElement?.dispatchEvent(eventClone);
        }
    };
    el.onmousedown = eventRedirect;
    el.onmouseup = eventRedirect;
    el.ontouchstart = eventRedirect;
    el.ontouchmove = eventRedirect;
    el.ontouchcancel = eventRedirect;
    el.ontouchend = eventRedirect;

    const elBackward = document.createElement('div');
    elBackward.className = styles.leftScreen;
    elBackward.setAttribute('data-side', ESides.LEFT);
    el.appendChild(elBackward);

    const elForward = document.createElement('div');
    elForward.className = styles.rightScreen;
    elForward.setAttribute('data-side', ESides.RIGHT);
    el.appendChild(elForward);

    const elCenter = document.createElement('div');
    elCenter.className = styles.centredScreen;
    elCenter.setAttribute('data-side', ESides.CENTER);
    el.appendChild(elCenter);

    const shadowNav = new Component(player, { el });
    player.addChild(shadowNav);
}
