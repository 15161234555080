/** CLOUDWEB-17509 Интеграция с Литрес */
// tempexp_17509-start
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_IOS_MOBILE, IS_PAID_USER, IS_SOCIAL_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import type { QueryParams } from 'reactApp/modules/settings/settings.types';

type LitresAB = 'a' | 'b' | false;

interface LitresTariffIds {
    year: string[];
    month: string[];
    upsaleForceTrial: string[];
    monthDiscount3m: string[];
}

const queryParams: QueryParams = getQueryParams();

const IS_BIZ_PAID_SOC_USER = IS_PAID_USER || IS_SOCIAL_USER || IS_BIZ_USER;

const litresABDesktop: LitresAB = getFeature('litres-ab');

const litresABIos: LitresAB = getFeature('litres-ab-ios');

const litresABAndroid: LitresAB = getFeature('litres-ab-and');

const litresABTouch: LitresAB = IS_IOS_MOBILE ? litresABIos : litresABAndroid;

const litresABValue: LitresAB = IS_WEBVIEW ? litresABTouch : litresABDesktop;

registerExperiment(
    'litres',
    !IS_BIZ_PAID_SOC_USER && (queryParams.fromLitres ? 'link' : litresABValue),
    [
        AnalyticEventNames.QUOTA_LANDING_SHOWN,
        AnalyticEventNames.MOBILE_LANDING_SHOWN,
        AnalyticEventNames.TARIFF_CLICK,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.TARIFF_BUY,
        AnalyticEventNames.LITRES_PROMO_SHOW,
        AnalyticEventNames.LITRES_PROMO_TOOLTIP_SHOW,
        AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLICK,
        AnalyticEventNames.LITRES_PROMO_TOOLTIP_CLOSE,
        AnalyticEventNames.LITRES_PROMO_SUCCESS_SHOW,
        AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_CHECK,
        AnalyticEventNames.LITRES_PROMO_SUCCESS_CLICK_LATER,
        AnalyticEventNames.LITRES_PROMO_SUCCESS_CLOSE,
    ],
    {
        mapEventNames: {
            LITRES_PROMO_SHOW: 'promo-show',
            LITRES_PROMO_TOOLTIP_SHOW: 'promo-tooltip-show',
            LITRES_PROMO_TOOLTIP_CLICK: 'promo-tooltip-click',
            LITRES_PROMO_TOOLTIP_CLOSE: 'promo-tooltip-close',
            LITRES_PROMO_SUCCESS_SHOW: 'promo-success-show',
            LITRES_PROMO_SUCCESS_CLICK_CHECK: 'promo-success-check',
            LITRES_PROMO_SUCCESS_CLICK_LATER: 'promo-success-later',
            LITRES_PROMO_SUCCESS_CLOSE: 'promo-success-close',
        },
        sendXrayArgs: {
            TARIFF_CLICK: ({ paySource }, { eventName }) => ({
                eventName: paySource === BLOCK_LITRES_PAYSOURCE ? `${eventName}-block` : eventName,
            }),
            TARIFF_BUY: ({ paySource }, { eventName }) => ({
                eventName: paySource === BLOCK_LITRES_PAYSOURCE ? `${eventName}-block` : eventName,
            }),
            FAST_CHECKOUT_SHOWN: ({ paySource }, { eventName }) => ({
                eventName: paySource === BLOCK_LITRES_PAYSOURCE ? `${eventName}-block` : eventName,
            }),
        },
    }
);

export const BLOCK_LITRES_PAYSOURCE = 'block_litres';

export const isPromoQuotaWithLitress = !IS_BIZ_PAID_SOC_USER && (queryParams.fromLitres || litresABValue === 'b');

const fromLitresDwhParams = queryParams.fromLitres ? { fromLitres: true } : {};
export const litresDwhParams = isPromoQuotaWithLitress ? { name_action: 'litres', ...fromLitresDwhParams } : {};

const litresTariffIds: LitresTariffIds = getFeature('litres-tariff-ids') || {
    month: [],
    year: [],
    monthDiscount3m: [],
    upsaleForceTrial: [],
};

export const litresTariffIdsListCommon: string[] = [
    ...litresTariffIds.month,
    ...litresTariffIds.year,
    ...litresTariffIds.monthDiscount3m,
    ...litresTariffIds.upsaleForceTrial,
];

export const litresTariffIdsList: string[] = [...litresTariffIds.month, ...litresTariffIds.year];

export const litresTariffIds3mDiscountList: string[] = [...litresTariffIds.monthDiscount3m, ...litresTariffIds.year];

export const litresUpsaleTariffs: string[] = litresTariffIds.upsaleForceTrial;

export const litresTermsLink: string = getFeature('litres-terms-link');

// дата окончания акции
export const litresEndDate: string = (getFeature('litres-modal-settings') || {}).actionEndDate;

// tempexp_17509-end
