import debounce from 'lodash.debounce';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import type { Product } from 'reactApp/types/Billing';
import { useDisableAdsTariffs } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTariffs';
import { useDisableAdsTitle } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTitle';
import { DisableAdsPostMessageType, sendFrameMessage } from 'reactApp/ui/IframePages/BuyDisableAds/utils';
import { TariffsModalContent } from 'reactApp/ui/TariffsModal/TariffsModalContent';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendDwh } from 'reactApp/utils/ga';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './DisableAdsScreen.css';

interface DisableAdsScreenProps {
    titleFromQuery?: string;
    tariffsIdsFromQuery?: string;
    activeTabFromQuery?: string;
    onBuyClick: (product: Product) => void;
}

export const DisableAdsScreen = memo(({ activeTabFromQuery, tariffsIdsFromQuery, titleFromQuery, onBuyClick }: DisableAdsScreenProps) => {
    const title = useDisableAdsTitle(titleFromQuery);
    const { tariffs, tariffIds } = useDisableAdsTariffs(tariffsIdsFromQuery);
    const [activeTab, setActiveTab] = useState<ETabsName>(activeTabFromQuery === 'm' ? ETabsName.month : ETabsName.year);

    const onResize = useCallback(
        debounce(({ height = 0 }: { height: number }) => {
            sendFrameMessage({ type: DisableAdsPostMessageType.size, height, width: BREAKPOINT_SM });
        }),
        []
    );
    const { ref } = useResizeObserver<HTMLDivElement>({ onResize });

    const isFirstEffectCalled = useRef(false);
    useEffect(() => {
        if (!isFirstEffectCalled.current) {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN, { selectedPeriod: activeTab });
            sendDwh({ eventCategory: 'tariffs-quota', action: 'modal-noads-show', dwhData: { selectedPeriod: activeTab } });
            isFirstEffectCalled.current = true;
        } else {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE, { selectedPeriod: activeTab });
            sendDwh({ eventCategory: 'tariffs-quota', action: 'modal-noads-switch', dwhData: { selectedPeriod: activeTab } });
        }
    }, [activeTab]);

    return (
        <div ref={ref} className={styles.contentRefWrapper}>
            <TariffsModalContent
                tariffs={tariffs}
                yearTariffsCount={tariffIds.year.length}
                monthTariffsCount={tariffIds.month.length}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onBuyClick={onBuyClick}
                title={title}
            />
        </div>
    );
});

DisableAdsScreen.displayName = 'DisableAdsScreen';
