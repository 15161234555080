import config from 'Cloud/config';
import { xray } from 'lib/xray';
import { pickBy } from 'ramda';
import {
    IOS_BUILD_VERSION,
    IS_DEV,
    IS_IOS_MOBILE,
    IS_MAIL_APP_IOS,
    IS_MOBILE_BROWSER,
    IS_PHONE_BROWSER,
    IS_REACT_PROMO_QUOTA_PARTNER_PAGE,
    IS_WEBVIEW,
} from 'reactApp/appHelpers/configHelpers';
import { getFeatureQuotaBuyAndroidConfig, getFeatureQuotaBuyIOSConfig } from 'reactApp/appHelpers/featuresHelpers';
import { litresDwhParams } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { getQueryParams, getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { summerPromotionDwh } from 'reactApp/constants/dwh';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { getCurrentGroup, getYearGroupConfig } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { store as reduxStore } from 'reactApp/store';
import { type IRadarOptions, sendDwh, sendXray } from 'reactApp/utils/ga';
import { getPlatformInfo } from 'reactApp/utils/gaHelpers';
import { noop } from 'reactApp/utils/helpers';

let isVkWidgetInited = false;
const VK_WIDGET_CONTAINER_ID = 'vk_community_messages';
const VK_SDK_URL = 'https://vk.com/js/api/openapi.js?169';

let clientId;

window.gtag?.('get', config.get('GA_ID'), 'client_id', (clientID) => {
    clientId = clientID;
});

export const initVkWidget = (params): (() => void) => {
    let widget: { destroy: () => void } | null = null;

    if (isVkWidgetInited || !params || IS_DEV || EnvironmentSelectors.isMobile()) {
        return noop;
    }

    const { groupId = 209690185, ...options } = params;

    const container = document.createElement('div');
    container.id = VK_WIDGET_CONTAINER_ID;
    document.body.appendChild(container);

    const sdk = document.createElement('script');
    sdk.onload = () => {
        isVkWidgetInited = true;

        // https://dev.vk.com/ru/widgets/community-messages
        // eslint-disable-next-line new-cap
        widget = (window as any).VK.Widgets.CommunityMessages(VK_WIDGET_CONTAINER_ID, groupId, options);
    };
    sdk.src = VK_SDK_URL;
    document.body.appendChild(sdk);

    return () => {
        widget?.destroy();
    };
};

export const openVkWidget = () => {
    (window as any)?.CommunityWidget?.expand();
};

export const sendQuotaGa = ({ action, label = '', group = 'None', ...restData }) => {
    const utms = getUtmObject();
    const { utm_source } = utms;

    const state = reduxStore.getState();

    const isMobile = QuotaLandingSelectors.isMobile(state);
    const { usedSize, freeSize } = UserQuotaSelectors.getSpace(state);

    const data = pickBy((val) => val !== undefined, restData) as Record<string, any>;

    const dwhData = {
        size_quota: usedSize,
        free_quota: freeSize,
        group,
        client_id: clientId,
        source: isMobile ? 'mobile' : 'desktop',
        platform: IS_PHONE_BROWSER ? String(EnvironmentSelectors.getMobileType()).toLowerCase() : 'web',
        webview: IS_WEBVIEW,
        ...summerPromotionDwh,
        ...litresDwhParams,
        ...(IS_REACT_PROMO_QUOTA_PARTNER_PAGE && utm_source ? { partner: utm_source } : {}),
        ...data,
        ...utms,
    };

    let eventCategory = 'union-quota';

    if (IS_REACT_PROMO_QUOTA_PARTNER_PAGE) {
        eventCategory = 'partner';
    }

    sendDwh({ eventCategory, action, label, dwhData });
};

export const sendQuotaCleanerGa = ({ action, dwh = {} }: { action: string; dwh?: Record<string, any> }) => {
    const state = reduxStore.getState();

    const isMobile = EnvironmentSelectors.isMobile();
    const isPaidUser = UserSelectors.isPaidUser(state);
    const isProUser = UserSelectors.isProUser(state);

    const { groupId, totalCount, size } = getCurrentGroup(state) ?? {};
    const { currentYear } = getYearGroupConfig(state, groupId) ?? {};

    const cleanerGroupDwh = groupId
        ? {
              name_block: groupId,
              count_block: totalCount,
              size_block: size,
          }
        : {};

    const fullDwh = {
        free_user: !isPaidUser,
        paid_user: isPaidUser,
        pro_user: isProUser,
        source: isMobile ? 'mobile' : 'desktop',
        ...(currentYear && Number.isInteger(+currentYear) && { year: currentYear }),
        ...cleanerGroupDwh,
        ...dwh,
    };

    sendQuotaGa({ action, ...fullDwh });
};

export const sendWVRadar = (
    t: string,
    config: {
        isIOSPromo: boolean;
        isAndroidPromo: boolean;
        isMobilePayFromGetParam: boolean;
        isMobilePayAvailable: boolean;
    }
): void => {
    const { isIOSPromo, isAndroidPromo, isMobilePayFromGetParam, isMobilePayAvailable } = config;
    const { super_app_version, mp, ver, appbuild: appBuildFromQuery } = getQueryParams() || {};

    const appbuild = IS_IOS_MOBILE ? IOS_BUILD_VERSION : appBuildFromQuery;

    // шлем радары только из webview
    if (!IS_MOBILE_BROWSER || (!mp && !IS_MAIL_APP_IOS)) {
        return;
    }

    const version = super_app_version || ver;

    const payManageSource = isMobilePayFromGetParam ? '_f' : '';
    const safeParameter = (isIOSPromo || isAndroidPromo) && !isMobilePayAvailable ? '_safe' : '_unsafe';
    const deviceParameter = !mp && IS_MAIL_APP_IOS ? '_ios' : `_${mp.toLowerCase()}`;
    const appbuildParameter = appbuild ? `_${appbuild}` : '';

    xray.send(`${t}${deviceParameter}${payManageSource}${safeParameter}${appbuildParameter}`, {
        dwh: {
            version,
            appbuild,
        },
    });
};

export const checkMobilePayAvailable = (
    isIOSPromo: boolean,
    isAndroidPromo: boolean
): {
    isMobilePayFromGetParam: boolean;
    isMobilePayAvailable: boolean;
} => {
    const { appbuild: appBuildFromQuery } = getQueryParams() || {};

    const appbuild = IS_IOS_MOBILE ? IOS_BUILD_VERSION : appBuildFromQuery;

    const iosConfig = getFeatureQuotaBuyIOSConfig;
    const androidConfig = getFeatureQuotaBuyAndroidConfig;

    // если не на /ios /android в любом кейсе оплата вкл
    if (!isIOSPromo && !isAndroidPromo) {
        return { isMobilePayFromGetParam: false, isMobilePayAvailable: true };
    }

    const config = isIOSPromo ? iosConfig : androidConfig;
    const isMobilePayFromGetParam = !!appbuild && !!config;

    if (!isMobilePayFromGetParam) {
        return { isMobilePayFromGetParam, isMobilePayAvailable: false };
    }

    const appBuildNum = +appbuild;

    // если версия есть в списке на включение
    if (config.list?.includes(appBuildNum)) {
        return { isMobilePayFromGetParam, isMobilePayAvailable: true };
    }

    const { start, end } = config;
    // если версии нет или стартовой версии нет, то не валидно
    if (!appBuildNum || !start) {
        return { isMobilePayFromGetParam, isMobilePayAvailable: false };
    }

    // если текущая версия меньше стартовой
    if (appBuildNum < start) {
        return { isMobilePayFromGetParam, isMobilePayAvailable: false };
    }

    // если нет конечной версии, то мы включены, иначе проверяем < конечной
    return { isMobilePayFromGetParam, isMobilePayAvailable: end ? appBuildNum < end : true };
};

export const getQuotaBillingSource = (): string => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);

    const { isQuotaPartner, isQuotaTariffs } = getStorage(storage);

    if (isQuotaTariffs) {
        return 'tariffs_quota_landing';
    }

    if (isQuotaPartner) {
        return 'partner_quota_landing';
    }

    return 'union_quota_landing';
};

export const sendQuotaBlockViewDWH = (name: string, action = 'view') => {
    sendQuotaGa({ action: `block-${action}`, block_name: name });
};

/**
 * Хелпер. Добавляет платформу (web, ios, and, wv) в параметры sendXray.
 *
 * @param {string[]} names - массив параметров для функции sendXray.
 * @param iData: {[key: string]: number}. Example: {'ext':1, 'time': 1000}
 */
export const sendXrayWithPlatform = (
    names: string[],
    iData: Record<string, number> | string | null = null,
    options: IRadarOptions = {}
) => {
    sendXray([...names, ...getPlatformInfo()], iData, options);
};
