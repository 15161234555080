import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import type { cancelUploading } from 'reactApp/modules/uploading/uploading.module';
import { clearContinueUploadFilesAction } from 'reactApp/modules/uploadList/continueUpload/continueUpload.module';
import { sortFilesByError } from 'reactApp/modules/uploadList/sagas/updateUploadFile.saga';
import { call, put } from 'redux-saga/effects';

export function* handleCancelUploading(action: ReturnType<typeof cancelUploading>) {
    if (action.payload?.fileId) {
        uploadingService.cancel(action.payload.fileId);
        return;
    }

    const cancelInfo = uploadingService.cancelAll();

    yield call(sortFilesByError);

    yield put(clearContinueUploadFilesAction());

    uploadingLog.info({
        event: 'cancel all',
        details: cancelInfo.canceled,
        radarName: 'cancel_web-client_all',
        radarValue: cancelInfo.canceled,
    });
}
