import { EViewMode } from 'reactApp/modules/settings/settings.types';
import type { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';

export const getThumbUrl = ({ item, isVirus, viewMode }): IThumb | undefined => {
    if (!item || item.isFolder || !item.thumbnails || isVirus) {
        return;
    }

    const thumbSizeMap = {
        [EViewMode.list]: '52x52',
        [EViewMode.thumbs]: '168x152',
        [EViewMode.galleryGrid3]: '336x304',
        default: '52x52',
    };

    return {
        src: item.thumbnails[thumbSizeMap[viewMode || 'default']],
    };
};
