import classNames from 'clsx';
import React, { type FC, useCallback } from 'react';
import { useDisclosure } from 'reactApp/hooks/useDisclosure';
import type { EmptyComponentProps } from 'reactApp/ui/Empty/new/Empty.types';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { createGaSender } from 'reactApp/utils/ga';

import { DetailsDialog } from '../DetailsDialog/DetailsDialog';
import { useCreateAutoDeleteFolderHandler } from '../hooks';

interface DescriptionProps extends EmptyComponentProps {
    className?: string;
}

const sendGa = createGaSender('autodelete-empty');

export const Description: FC<DescriptionProps> = ({ className }) => {
    const detailsDialogDisclosure = useDisclosure();

    const { createFolder } = useCreateAutoDeleteFolderHandler({ sendGa });

    const handleCreateFolder = useCallback(() => {
        sendEmptyStatesAnalitics({ action: 'autodelete-create' });

        createFolder();
    }, [createFolder]);

    return (
        <div className={classNames(className)}>
            Создайте папку, установите таймер на файлы —{'\n'}они удалятся сами и не будут занимать место{' '}
            {detailsDialogDisclosure.isOpen && (
                <DetailsDialog onClose={detailsDialogDisclosure.onClose} onFolderCreate={handleCreateFolder} />
            )}
        </div>
    );
};
