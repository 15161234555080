import { requestPayment } from 'Cloud/Application/Payment';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import type { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { ERetryErrorFileOptions } from 'reactApp/modules/uploading/uploading.types';
import { askUserForPayment } from 'reactApp/modules/uploadList/oldUploadListBridge';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { type Channel, channel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';

let isSkipDlgShown = false;

export function* handleOverQuotaError(descriptor: UploadingDescriptor, noOwnSpace = false) {
    sendGaUploaderNew('file', 'over_quota_cloud');

    if (isSkipDlgShown) {
        return ERetryErrorFileOptions.shouldRetry;
    }

    const payChannel = channel<object>();

    const handleAskPayment = (option, id) => {
        payChannel?.put({ option, id });
    };

    isSkipDlgShown = true;

    askUserForPayment(handleAskPayment);

    const isUserOverquota = yield select(UserSelectors.isOverQuotaUser);

    yield put(
        updateUploadFilesAction({
            descriptorId: descriptor.id,
            cloudPath: descriptor.cloudPath,
            status: EFileStatus.WARNING,
            error:
                descriptor.uploadingPacketConfig.isMountedFolder && !(isUserOverquota || noOwnSpace)
                    ? EFileError.OVER_QUOTA_CLOUD_AT_OWNER
                    : EFileError.OVER_QUOTA_CLOUD,
        })
    );

    const res = yield processPayDlg(descriptor, payChannel);

    payChannel.close();
    isSkipDlgShown = false;

    return res ?? ERetryErrorFileOptions.cancel;
}

function* processPayDlg(descriptor: UploadingDescriptor, payChannel: Channel<object>) {
    const { option } = yield take(payChannel, '*');

    const isB2bOption = option === 'skip_b2b';

    if (option === 'skip' || isB2bOption) {
        sendGaUploaderNew('file', 'skip_over_quota_cloud');

        if (!isB2bOption) {
            yield put(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    cloudPath: descriptor.cloudPath,
                    status: EFileStatus.CANCEL,
                    error: EFileError.SKIPPED_FILE,
                    hideError: false,
                })
            );
        }
    } else {
        // @ts-ignore
        const success = yield call(requestPayment, {
            forceFastCheckout: true,
        });

        if (success) {
            sendGaUploaderNew('file', 'add_over_quota_cloud');

            isSkipDlgShown = false;

            return ERetryErrorFileOptions.shouldRetryImmediately;
        }

        return yield processPayDlg(descriptor, payChannel);
    }
}
