import { Icon16Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useMemo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { type IProps, EInformerColor } from 'reactApp/ui/Informer/Informer.types';

import styles from './Informer.css';

export const Informer = memo(
    ({
        children,
        color,
        icon,
        className,
        closable = false,
        onClose,
        buttonText,
        onButtonClick,
        centered,
        title,
        button,
        dataQaId,
    }: IProps): ReactElement => {
        const handleClose = useCallback(() => {
            if (onClose) {
                onClose();
            }
        }, [onClose]);

        const buttonTheme = useMemo(() => {
            if (color === EInformerColor.grey) {
                return 'octavius';
            }

            return 'white';
        }, [color]);

        const isPrimaryButton = useMemo(() => color === EInformerColor.grey, [color]);

        return (
            <div
                data-qa-id={dataQaId}
                className={classNames(className, {
                    [styles.root]: true,
                    [styles[`root_color_${color}`]]: color,
                    [styles.root_centered]: centered,
                })}
            >
                {!!icon && (
                    <div data-qa-id="informer-icon" className={styles.icon}>
                        {icon}
                    </div>
                )}
                <div className={styles.text}>
                    {title && (
                        <div data-qa-id="informer-title" className={styles.title}>
                            {title}
                        </div>
                    )}
                    <div data-qa-id="informer-text">{children}</div>
                </div>
                <div className={styles.rightBlock}>
                    {button
                        ? button
                        : Boolean(buttonText) && (
                              <Button theme={buttonTheme} onClick={onButtonClick} primary={isPrimaryButton}>
                                  <span className={styles.buttonText}>{buttonText}</span>
                              </Button>
                          )}
                    {}
                    {closable && (
                        <div className={styles.close} onClick={handleClose}>
                            <Icon16Cancel width={20} height={20} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

Informer.displayName = 'Informer';
