import type { PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { ThemeOutsourceHelpers } from 'reactApp/appHelpers/themeToolHelpers';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    applyThemeRequest,
    availableThemesFailure,
    availableThemesLoadRequest,
    availableThemesLoadSuccess,
    chooseThemeRequest,
} from './theme.outsource.module';

function* loadAvailableThemes() {
    try {
        const themes = yield call(ThemeOutsourceHelpers.getAvailableThemes);
        yield put(availableThemesLoadSuccess({ themes }));
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(availableThemesFailure());
    }
}

function* handleChangeTheme(action: PayloadAction<string>) {
    yield call((id) => ThemeOutsourceHelpers.applyTheme(id), action.payload);
}

export function* watchThemeOutsource() {
    yield takeEvery(availableThemesLoadRequest.toString(), loadAvailableThemes);
    yield takeLatest(applyThemeRequest.toString(), handleChangeTheme);
    yield takeLatest(chooseThemeRequest.toString(), handleChangeTheme);
}

export function* initThemeOutsource() {
    yield put(availableThemesLoadRequest());
    const themeId = ThemeOutsourceHelpers.getBodyTheme();
    if (themeId) {
        yield put(applyThemeRequest(themeId));
        yield put(chooseThemeRequest(themeId));
    }
}
