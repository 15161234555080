import React, { type ReactElement } from 'react';

interface Content {
    title: ReactElement;
    description: ReactElement;
    buttonText: string;
}

export const useContent = ({ isVerified, noPhone }: { isVerified: boolean; noPhone: boolean }): Content => {
    let title = <>Ваше Облако заморожено</>;

    let description = (
        <>
            <p>
                Вы давно не пользовались Облаком, мы пытались с вами <br />
                связаться и отправили вам несколько предупреждающих писем.
            </p>
            <p>
                По правилам{' '}
                <a target="__blank" href="/LA/">
                    лицензионного соглашения
                </a>{' '}
                мы вправе заморозить <br />
                ваш аккаунт, если вы долго не пользуетесь Облаком.
            </p>
            <p>
                С заморозкой вашего аккаунта <b>объём бесплатного места</b> <br />в Облаке был сброшен <b>до минимального</b>, но все файлы{' '}
                <br />
                остались <b>в сохранности и безопасности.</b>
            </p>
            {!isVerified && <p>Для разморозки Облака, пожалуйста, подтвердите ваш телефон.</p>}
        </>
    );

    let buttonText = isVerified ? 'Разморозить Облако' : 'Подтвердить номер телефона';

    if (noPhone && !isVerified) {
        title = <>Облако не может быть разморожено без подтвержденного номера телефона</>;

        description = (
            <>
                <p>
                    Обращаем ваше внимание, что мы вправе очистить ваше <br />
                    Облако, если вы не подтвердите свой номер телефона.
                </p>
                <p>
                    Воспользуйтесь нашим{' '}
                    <a target="__blank" href="https://disk-o.cloud/?utm_source=freeze_phone_verification">
                        приложением для компьютера
                    </a>
                    , чтобы <br />
                    скачать и сохранить свои файлы.
                    <br />
                    <br />
                </p>
                <p>
                    <a target="__blank" href="https://disk-o.cloud/?utm_source=freeze_phone_verification">
                        Скачать приложение для компьютера
                    </a>
                </p>
            </>
        );

        buttonText = 'Подтвердить номер телефона';
    }

    return {
        title,
        buttonText,
        description,
    };
};
