import React, { type FC, useRef } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Favorite } from 'reactApp/ui/DataListItemCommonComponents/Favorite/Favorite';
import { Progress } from 'reactApp/ui/DataListItemCommonComponents/Progress/Progress';
import { Publish } from 'reactApp/ui/DataListItemCommonComponents/Publish/Publish';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { Expires } from 'reactApp/ui/DataListItemRow/components/Expires/Expires';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { ItemTip } from 'reactApp/ui/DataListItemRow/components/ItemTip/ItemTip';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import type { HomeItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

// eslint-disable-next-line max-lines-per-function
export const HomeItem: FC<HomeItemProps> = (props) => {
    const {
        id,
        storage,
        denyDoAction: isProgress,
        tipData,
        isFolder,
        progress,
        renameAvailable,
        thumbUrl,
        isVirus,
        kind,
        isPublic,
        name,
        size,
        mtime,
        author,
        ext,
        favoritesAvailable,
        isAutoDelete,
        publicExpires,
        isSelected,
        isInFavorites,
        onCheckboxClick,
        onCancelUpload,
        onFavorites,
        onDownload,
        onPublish,
    } = props;

    const isCompact = useCompactView();

    const tipTargetRef = useRef(null);

    return (
        <>
            <ItemTip target={tipTargetRef} tipData={tipData} isFolder={isFolder ?? false} />
            <TableColumn>
                <Checkbox
                    viewType={EViewMode.list}
                    className={styles.check}
                    onCheckboxClick={onCheckboxClick}
                    isSelected={isSelected}
                    isDisabled={isProgress}
                />
                <FilePic
                    ref={tipTargetRef}
                    isFolder={isFolder}
                    thumbUrl={thumbUrl}
                    kind={kind}
                    isVirus={isVirus}
                    ext={ext || ''}
                    author={author}
                    isAutoDelete={isAutoDelete}
                    publicExpires={publicExpires}
                    isPublic={isPublic}
                />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} ext={ext || ''} loading={isProgress} id={id} />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                            {!!mtime && <>&nbsp;•&nbsp;</>}
                            <Date date={mtime} className={styles.date} />
                        </span>
                        <Expires expires={publicExpires} isAutoDelete={isAutoDelete} isShared={!!publicExpires} isInline />
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} loading={isProgress} id={id} isRenameAvailable={renameAvailable} />
                    </TableColumn>
                    {isFolder && Boolean(publicExpires) && (
                        <TableColumn flexBasis={214} flexShrink={0} justifyContent="flex-start">
                            <Expires expires={publicExpires} isAutoDelete={isAutoDelete} />
                        </TableColumn>
                    )}
                    {!isFolder && (
                        <TableColumn flexBasis={140} flexShrink={0} justifyContent="flex-end">
                            <Date date={mtime} />
                        </TableColumn>
                    )}
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            {!isProgress && (
                <TableColumn flexBasis={100} flexShrink={0} justifyContent="flex-end" gap={20}>
                    <Favorite
                        className={styles.favoriteAction}
                        isAvailable={favoritesAvailable}
                        isFavorite={isInFavorites}
                        onClick={onFavorites}
                        viewType={EViewMode.list}
                    />
                    <Publish viewType={EViewMode.list} onClick={onPublish} isShared={isPublic} />
                    <Download
                        onClick={onDownload}
                        ext={isFolder ? 'zip' : ext}
                        size={size}
                        id={id}
                        storage={storage}
                        viewType={EViewMode.list}
                    />
                </TableColumn>
            )}
            {isProgress && typeof progress === 'number' && (
                <TableColumn flexBasis={100} flexShrink={0} justifyContent="flex-end" gap={20}>
                    <Progress onClick={onCancelUpload} progress={progress} />
                </TableColumn>
            )}
        </>
    );
};
