import api from 'Cloud/Application/api';
import { logger } from 'lib/logger';
import { loadSharedLinksRequest, loadSharedLinksStartRequest, loadSharedLinksSuccess } from 'reactApp/modules/shared/shared.module';
import { put, takeEvery } from 'redux-saga/effects';

function* loadSharedLinks() {
    try {
        yield put(loadSharedLinksRequest());

        const { list } = yield Promise.resolve(api.folder.shared.links());
        yield put(
            loadSharedLinksSuccess({
                list: [...list],
            })
        );
    } catch (error) {
        logger.error(error);
    }
}

export function* watchShared() {
    yield takeEvery(loadSharedLinksStartRequest.toString(), loadSharedLinks);
}
