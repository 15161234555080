/* eslint-disable sonarjs/no-identical-functions */
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { SubscriptionNoRenewAPICall } from 'reactApp/api/billing/subscription/SubscriptionNoRenewAPICall';
import { SubscriptionAutoRenewAPICall } from 'reactApp/api/billing/subscription/SubscriptionRenewByBindIdAPICall';
import type {
    CheckPauseSubscription,
    IAddAutoRenewAction,
    ICancelAutorenewAddTrial,
    ICancelAutorenewSubscription,
    IOpenCancelMailSubsDialogAction,
    PauseSubscription,
} from 'reactApp/modules/buySubscription/buySubscription.types';
import type { AppDispatch, RootState } from 'reactApp/store';

const INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    link: '',
    renewLink: '',
    requestId: '',
    canPauseSubscription: false,
};

const startLoading = createAction<{ requestId: string } | undefined>('@buySubscription/startLoading');
const hasError = createAction<{ requestId: string } | undefined>('@buySubscription/hasError');
const isLoaded = createAction('@buySubscription/isLoaded');
const resetLink = createAction('@buySubscription/resetLink');
const setRenewLink = createAction<{ renewLink: string }>('@buySubscription/setRenewLink');
const setLink = createAction<{ link: string; requestId?: string }>('@buySubscription/setLink');
const setCanPauseSubscription = createAction<{ canPauseSubscription: boolean }>('@buySubscription/setCanPauseSubscription');
const openCancelAutorenewMailSubsDialog = createAction<IOpenCancelMailSubsDialogAction>(
    '@buySubscription/openCancelAutorenewMailSubsDialog'
);
const addAutorenewToSubscription = createAction<IAddAutoRenewAction>('@buySubscription/addAutorenewToSubscription');
const cancelAutorenewSubscription = createAction<ICancelAutorenewSubscription>('@buySubscription/cancelAutorenewSubscription');
// tempexp_16480-start
const cancelAutorenewCheckCaptchaAndTrial = createAction<ICancelAutorenewSubscription>(
    '@buySubscription/cancelAutorenewCheckCaptchaAndTrial'
);
const cancelAutorenewAddTrial = createAction<ICancelAutorenewAddTrial>('@buySubscription/cancelAutorenewAddTrial');
// tempexp_16480-end
const pauseSubscription = createAction<PauseSubscription>('@buySubscription/pauseSubscription');
const checkPauseSubscription = createAction<CheckPauseSubscription>('@buySubscription/checkPauseSubscription');

const buySubscriptionReducer = createReducer(INITIAL_STATE, {
    [resetLink.type]: () => INITIAL_STATE,
    [startLoading.type]: (_, action) => ({
        ...INITIAL_STATE,
        isLoading: true,
        requestId: action.payload?.requestId,
    }),
    [hasError.type]: (state, action) => {
        if (action.payload?.requestId && action.payload.requestId !== state.requestId) {
            return state;
        }
        return {
            ...INITIAL_STATE,
            hasError: true,
        };
    },
    [isLoaded.type]: () => ({
        ...INITIAL_STATE,
        isLoaded: true,
    }),
    [setRenewLink.type]: (_, action) => ({
        ...INITIAL_STATE,
        isLoaded: true,
        renewLink: action.payload.renewLink,
    }),
    [setLink.type]: (state, action) => {
        if (action.payload?.requestId && action.payload.requestId !== state.requestId) {
            return state;
        }

        return {
            ...INITIAL_STATE,
            isLoaded: true,
            link: action.payload.link,
        };
    },
    [setCanPauseSubscription.type]: (state, action) => ({
        ...INITIAL_STATE,
        canPauseSubscription: action.payload.canPauseSubscription,
    }),
});

const cancelAutorenew = (id: string) => (dispatch: AppDispatch) => {
    const api = new SubscriptionNoRenewAPICall();

    dispatch(startLoading());

    return new Promise((resolve, reject) => {
        api.makeRequest({
            sub_id: id,
        })
            .then(() => resolve(dispatch(isLoaded())))
            .catch((error) => {
                dispatch(hasError());

                return reject(error);
            });
    });
};

const addAutorenew = (id: string) => (dispatch: AppDispatch) => {
    const api = new SubscriptionAutoRenewAPICall();

    dispatch(startLoading());

    return new Promise((resolve, reject) => {
        api.makeRequest({
            sub_id: id,
        })
            .then(() => resolve(dispatch(isLoaded())))
            .catch((error) => {
                dispatch(hasError());

                reject(error);
            });
    });
};

const getLink = (state: RootState) => state.buySubscription;
const getCanPauseSubscription = createSelector(getLink, (state) => state.canPauseSubscription);

export const BuySubscriptionActions = {
    startLoading,
    hasError,
    cancelAutorenew,
    addAutorenew,
    setLink,
    resetLink,
    setCanPauseSubscription,
    openCancelAutorenewMailSubsDialog,
    addAutorenewToSubscription,
    cancelAutorenewSubscription,
    cancelAutorenewCheckCaptchaAndTrial,
    cancelAutorenewAddTrial,
    pauseSubscription,
    checkPauseSubscription,
};

export const BuySubscriptionSelectors = {
    getLink,
    getCanPauseSubscription,
};

export { buySubscriptionReducer };
