import { useMemo } from 'react';
import type { Product } from 'reactApp/types/Billing';
import { getDiscount } from 'reactApp/utils/getDiscount';

export const useMonthSaving = (monthProduct?: Product) =>
    useMemo(() => {
        if (!monthProduct) {
            return [0, 0];
        }

        const saving = monthProduct.price - (monthProduct?.discountPrice || monthProduct.price);
        const { discount } = getDiscount({ discountPrice: monthProduct?.discountPrice || monthProduct.price, price: monthProduct.price });

        return [saving, discount];
    }, [monthProduct]);
