import { Button } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import type { EmptyComponentProps } from 'reactApp/ui/Empty/new/Empty.types';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import styles from './BottomContent.css';

interface BottomContentProps extends EmptyComponentProps {
    className?: string;
}

export const BottomContent: FC<BottomContentProps> = ({ className }) => {
    const dispatch = useDispatch();

    const handleAlbumCreate = useCallback(() => {
        sendEmptyStatesAnalitics({ action: 'album-click' });

        dispatch(createAlbumRequest());
    }, [dispatch]);

    return (
        <Button className={classNames(styles.root, className)} mode="secondary" appearance="neutral" size="m" onClick={handleAlbumCreate}>
            Создать альбом
        </Button>
    );
};
