import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { type VFC } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { Kind } from 'reactApp/types/Tree';
import type { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './ItemInfo.css';

interface IItemInfoProps {
    isFolder: boolean;
    ext?: string;
    name: string;
    size?: number;
    kind: Kind;
    thumbUrl?: IThumb;
    storage: EStorageType;
    isVirus: boolean;
    date?: string;
}

export const ItemInfo: VFC<IItemInfoProps> = ({ size, name, isFolder, ext = '', kind, thumbUrl, isVirus, storage, date }) => {
    const sizeText = (size && bytesToNDigits(size, 3).value) || '';

    return (
        <div className={styles.root}>
            <div className={styles.pic}>
                <DatalistPic
                    isFolder={isFolder}
                    ext={ext}
                    kind={kind}
                    thumbUrl={thumbUrl}
                    isVirus={isVirus}
                    viewMode={EViewMode.list}
                    ignoreFolderType={storage === EStorageType.public}
                />
            </div>
            <div className={styles.info}>
                <div className={styles.name}>
                    <NameComponent name={name} extension={ext} truncate />
                </div>
                {sizeText && (
                    <div className={styles.size}>
                        {sizeText}
                        {date ? ` • ${date}` : ''}
                    </div>
                )}
            </div>
        </div>
    );
};
