import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { sortQuotaPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { getBasicSubscription, sortSubscriptionsBySpace } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import type { SubscriptionList } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.types';
import { sendDwh } from 'reactApp/utils/ga';

import { SUBSCRIPTION_ANALYTICS } from '../MobileSubscription.analytics';
import { useSendGa } from '../MobileSubscriptionsPage.hooks';

export const useHandlers = () => {
    const basicSubscription = useSelector(getBasicSubscription);
    const subscriptions = useSelector(sortSubscriptionsBySpace);
    const promoSubscriptions = useSelector(sortQuotaPromoBySpace);
    const subscriptionList = [basicSubscription, ...subscriptions, ...promoSubscriptions] as SubscriptionList;
    const sendGa = useSendGa();

    const handleAllTariffsClick = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        sendGa('all-tariffs');
    }, [sendGa]);

    const handleFAQClick = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.FAQ_CANCEL_SUBS_OPEN,
            dwhData: {
                count_subs: subscriptionList.length,
                source: 'touch',
            },
        });
    }, [subscriptionList.length]);

    return {
        handleAllTariffsClick,
        handleFAQClick,
    };
};
