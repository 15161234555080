import { createAction, createReducer } from '@reduxjs/toolkit';
import type { State } from 'reactApp/modules/start/start.types';

const initialState: State = {
    isLoading: false,
    isLoaded: false,
    isError: false,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const loadStartRequest = createAction<{ dispatch: Function }>('start/loadStartRequest');
export const loadStartSuccess = createAction('start/loadStartSuccess');
export const loadStartFail = createAction('start/loadStartFail');
export const digestRequest = createAction('start/digestRequest');

export const startReducer = createReducer(initialState, {
    [loadStartRequest.type]: (state) => {
        return {
            ...state,
            isLoading: true,
            isLoaded: false,
            isError: false,
        };
    },
    [loadStartSuccess.type]: (state) => {
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
            isError: false,
        };
    },
    [loadStartFail.type]: (state) => {
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
            isError: true,
        };
    },
});
