import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';

export const getContent = (storage: EStorageType) => {
    const { isTrashBin, isGallery } = getStorage(storage);

    if (isTrashBin) {
        return {
            title: 'В корзине пусто',
            description: 'Файлы хранятся в корзине 14 дней, а затем\nудаляются',
        };
    }

    if (isGallery) {
        return {
            title: 'Галерея ваших фото\nи видео',
            description: 'Загружайте файлы с телефона, чтобы освободить память для новых снимков',
        };
    }

    return {
        title: 'В папке пока ничего нет',
        description: 'Как только владелец загрузит файлы,\nвы увидите их здесь',
    };
};
