import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { isStoriesInGallery } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureShowStories } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStoryListSuccess } from 'reactApp/modules/stories/stories.module';
import { getStorySummariesList } from 'reactApp/modules/stories/stories.selectors';
import { store } from 'reactApp/store';
import { delay } from 'redux-saga/effects';
import { put, race, select, take } from 'typed-redux-saga';

import { storeHelper } from '../promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from '../promo.module';
import { EPromoType } from '../promo.types';

export const STORIES_IN_GALLERY_PROMO_ID = 'stories-in-gallery-promo-id';

export function* initStoriesInGalleyPromo() {
    const isStoriesEnabled = yield* select(getFeatureShowStories);
    const currentStorage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(currentStorage);

    yield race({
        done: take(getStoryListSuccess.type),
        timeout: delay(2000),
    });
    const stories = yield* select(getStorySummariesList);

    if (
        !isHome ||
        !isStoriesInGallery ||
        !isStoriesEnabled ||
        stories.length === 0 ||
        storeHelper.getValue(STORIES_IN_GALLERY_PROMO_ID) ||
        IS_AUTO_TEST_HIDE
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.storiesInGalleryPromo,
            promoId: STORIES_IN_GALLERY_PROMO_ID,
            onShow() {
                store.dispatch(promoShown(EPromoType.storiesInGalleryPromo));
                storeHelper.markAsShown(STORIES_IN_GALLERY_PROMO_ID);
            },
            onClose() {
                store.dispatch(removePromo(EPromoType.storiesInGalleryPromo));
            },
        })
    );
}
