import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    filesFilterAddToFilter,
    filesFilterAll,
    filesFilterOne,
    filesFilterRemoveAll,
    filesFilterRemoveFromFilter,
} from 'reactApp/modules/filesFilter/filesFilter.module';
import { getCurrentAuthorInFilter, isAllFiltered } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import { getFolderAuthors, isFolderOwner, mapFolderAuthorsWithContacts } from 'reactApp/modules/storage/storage.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';

export const useList = () => {
    const dispatch = useDispatch();

    const userEmail = useSelector(UserSelectors.getEmail);
    const filter = useSelector(getCurrentAuthorInFilter);
    const isOwn = useSelector(isFolderOwner);
    const authorsEmails = useSelector(getFolderAuthors);

    if (userEmail && isOwn && !authorsEmails.includes(userEmail)) {
        authorsEmails.push(userEmail);
    }

    const authors = useSelector((state: RootState) => mapFolderAuthorsWithContacts(state, authorsEmails));

    const isAll = useSelector(isAllFiltered);

    const onAllClick = useCallback(() => {
        if (isAll) {
            dispatch(filesFilterRemoveAll());
        } else {
            dispatch(filesFilterAll());
        }
    }, [dispatch, isAll]);

    const onItemClick = useCallback(() => {
        if (isAll) {
            dispatch(filesFilterOne(userEmail || ''));
        } else {
            dispatch(filesFilterAll());
        }
    }, [dispatch, isAll, userEmail]);

    const onAuthorClick = useCallback(
        (author: string) => () => {
            if (isAll || filter.includes(author)) {
                dispatch(filesFilterRemoveFromFilter({ author, folderAuthors: authorsEmails }));
            } else {
                dispatch(filesFilterAddToFilter({ author, folderAuthors: authorsEmails }));
            }
        },
        [authorsEmails, dispatch, filter, isAll]
    );

    return useMemo(() => {
        if (!isOwn) {
            return [
                {
                    id: 'all',
                    text: 'Файлы от всех авторов',
                    onClick: onItemClick,
                    active: isAll,
                },
                {
                    id: 'mine',
                    text: 'Только мои файлы',
                    onClick: onItemClick,
                    active: !isAll,
                },
            ];
        }

        return [
            {
                id: 'all',
                text: 'Файлы от всех авторов',
                onClick: onAllClick,
                active: isAll,
            },
            ...authors.map(({ email, nick, name }) => ({
                id: email,
                text: name || nick,
                onClick: onAuthorClick(email),
                active: isAll || filter.includes(email),
            })),
        ];
    }, [authors, filter, isAll, isOwn, onAllClick, onAuthorClick, onItemClick]);
};
