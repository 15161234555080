import { logger } from 'lib/logger';
import { O2AuthClient, o2AuthClient } from 'reactApp/api/O2AuthClient';
import streamSaver from 'streamsaver';

interface AuthedSourceLoaderFlags {
    withRedirect: boolean;
    withToken: boolean;
}

export interface File {
    name: string;
    url: {
        get: string;
    };
}

export class O2AuthedSourceLoader {
    private NO_REDIRECT_FLAG = 'no_redirect=true';
    private initialAuthedSourceLoaderFlags: AuthedSourceLoaderFlags = {
        withRedirect: false,
        withToken: true,
    };

    private async getToken(): Promise<string> {
        const token = await o2AuthClient.getToken();
        if (token === null) {
            const error = new Error('Token is not available');
            logger.error(error, '[O2AuthedSourceLoader::getToken]');
            throw error;
        }

        return token;
    }

    private async getAuthedResponse(src: string, flags?: Partial<AuthedSourceLoaderFlags>) {
        const token = await this.getToken();
        const { withRedirect, withToken } = { ...this.initialAuthedSourceLoaderFlags, ...flags };

        const headers = withToken ? O2AuthClient.prepareAuthHeader(token) : {};
        const fetchSrc = withRedirect ? `${src}?${this.NO_REDIRECT_FLAG}` : src;
        return fetch(fetchSrc, { headers });
    }

    private async downloadFileStream(response: Response, fileName: string) {
        const fileStream = streamSaver.createWriteStream(fileName);
        return response.body?.pipeTo(fileStream);
    }

    async getAuthedSource(src: string) {
        const response = await this.getAuthedResponse(src);
        return response.blob();
    }

    // Получаем ссылку от ручки, которая делает редирект
    async getAuthedLink(src: string): Promise<string> {
        const response = await this.getAuthedResponse(src, { withRedirect: true });
        const { redirect } = await response.json();
        return redirect;
    }

    async getAuthedSourceFromRedirect(src: string) {
        const redirect = await this.getAuthedLink(src);
        const response = await this.getAuthedResponse(redirect, { withToken: false });
        return response.blob();
    }

    getObjectUrlFromBlob(blob: Blob): string {
        return URL.createObjectURL(blob);
    }

    revokeObjectUrl(url: string) {
        URL.revokeObjectURL(url);
    }

    async getBase64FromBlob(blob: Blob): Promise<string> {
        const reader = new FileReader();

        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

        // readAsDataURL returns base64 string
        return reader.result as string;
    }

    async saveFile<FileToSave extends File>(file: FileToSave): Promise<void> {
        const redirectUrl = await this.getAuthedLink(file.url.get);
        const response = await this.getAuthedResponse(redirectUrl, { withToken: false });
        return this.downloadFileStream(response, file.name);
    }
}

export const o2AuthedSourceLoader = new O2AuthedSourceLoader();
