import { stringify } from 'qs';
import { type IIntegrationClient, type IntegrationState, EIntegrationClients } from 'reactApp/modules/integration/integration.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

export const getIntegrationState = (state: RootState): IntegrationState => state.integration;

export const getIntegrationClient = createSelector(getIntegrationState, ({ client }): IIntegrationClient => {
    const isTutoria = client === EIntegrationClients.tutoria;

    return {
        isTutoria,
    };
});

export const getIntegrationQueryString = createSelector(getIntegrationState, ({ parentOrigin, client, userId, authOrigin, authType, p }) =>
    stringify(
        {
            parent_origin: parentOrigin,
            auth_origin: authOrigin,
            integration_client: client,
            integration_uid: userId,
            integration_p: p,
            auth: authType,
        },
        { addQueryPrefix: true }
    )
);
