import type { FeatureOptions, FeatureVariants, TemplateVariantString } from './types';

export const isToggleFeature = <T extends boolean | TemplateVariantString, V>(
    variants: FeatureVariants<T, V>,
    result: T
): variants is FeatureVariants<boolean, V> => typeof result === 'boolean';

export const isMultiVariantFeature = <T extends boolean | TemplateVariantString, V>(
    variants: FeatureVariants<T, V>,
    result: T
): variants is FeatureVariants<TemplateVariantString, V> => typeof result !== 'boolean';

export const isMultiVariantFeatureResult = (result: boolean | TemplateVariantString): result is TemplateVariantString =>
    typeof result !== 'boolean';

export const chooseVariant = <T extends boolean | TemplateVariantString, V>(
    selector: () => T,
    variants: FeatureVariants<T, V> & { control: V },
    options: FeatureOptions = {}
): V => {
    const result = selector();

    const { control, ...restVariants } = variants;
    const { skipCondition } = options;
    /**
     * TS не справляется с деструктуризацией(...restVariants) и не хочет поверить, что
     * Omit<FeatureVariants<T, V> & { control: V; }, "control"> это тоже самое, что FeatureVariants<T, V>
     */
    const _variants = restVariants as unknown as FeatureVariants<T, V>;

    if (isToggleFeature(_variants, result)) {
        if (result && !(skipCondition?.variant1 || skipCondition?.forAll)) {
            return _variants.variant1 as V; // variant1 может быть любым типом, как и V
        }
        return control;
    }

    if (isMultiVariantFeature(_variants, result) && isMultiVariantFeatureResult(result)) {
        const selected = _variants[result];
        if (skipCondition?.[result] || skipCondition?.forAll) {
            return control;
        }
        return selected ?? control;
    }

    return control;
};
