import { EditorID } from 'Cloud/Application/Editor/types';
import { CHOSEN_EDITOR, IS_DOCUMENTS_DOMAIN, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isAllDocumentsReturn } from 'reactApp/appHelpers/featuresHelpers';
import { getAvailableSectionInAllDocuments } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { inlineHeader, isWhiteEditorHeader } from 'reactApp/modules/features/features.helpers';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { changeCloudToDocumentDomain, changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';

export const getBackLink = (file: CloudFile) => {
    const currentEditor = CHOSEN_EDITOR;
    const section = getAvailableSectionInAllDocuments(file?.ext);
    const isWhiteHeaderReturn = Boolean(isWhiteEditorHeader && isAllDocumentsReturn && currentEditor?.id === EditorID.MYOFFICE && section);
    const isR7HeaderReturn = Boolean(inlineHeader?.enable && currentEditor?.id === EditorID.R7_WOPI && section);
    const queryString = currentEditor?.id ? `?fromPage=${currentEditor?.id}` : '';

    if (isR7HeaderReturn) {
        return IS_DOCUMENTS_DOMAIN ? `/${section}${queryString}` : `/home${file?.parent || ''}`;
    }

    if (isWhiteHeaderReturn) {
        return IS_DOCUMENTS_DOMAIN
            ? `/${section}${queryString}`
            : `${changeCloudToDocumentDomain(window.location.origin)}/${section}${queryString}`;
    }

    if (IS_PUBLIC) {
        return '/home';
    }

    return IS_DOCUMENTS_DOMAIN ? `${changeDocumentDomainToCloud(window.location.origin)}/home${file?.parent}` : `/home${file?.parent}`;
};
