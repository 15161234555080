import classNames from 'clsx';
import myOfficeIcon from 'img/icons/editor/MyOffice_Logo-T.svg?url';
import React, { type HTMLProps, type ReactEventHandler, type VFC, useEffect, useRef } from 'react';
import buttonStyles from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton.css';
import styles from 'reactApp/ui/EditorHeader/WhiteEditorHeader/EditorHeader.css';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { sendXray } from 'reactApp/utils/ga';

import { ReactComponent as AdIcon } from '../Ad.svg';

interface EditorHeaderButtonProps extends Omit<HTMLProps<HTMLDivElement>, 'className' | 'onClick'> {
    radarName: string;
    onClickAd: ReactEventHandler<HTMLDivElement>;
    onClick: ReactEventHandler<HTMLDivElement>;
    showPromoRating?: boolean;
    fileExt?: string;
}

export const EditorHeaderButton: VFC<EditorHeaderButtonProps> = ({
    radarName,
    onClick,
    onClickAd,
    showPromoRating,
    fileExt = '',
    ...htmlDivProps
}) => {
    const buttonTitleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        sendXray(radarName, { show: 1 });
        sendMyOfficeMetric(MyOfficeBusinessMetric.mention, { i: { [fileExt]: 1 } });
    }, [fileExt, radarName]);

    return (
        <div
            className={classNames(buttonStyles.buttonBlock, buttonStyles['buttonBlock_theme-myOffice'])}
            {...htmlDivProps}
            onClick={(e) => {
                /**
                 * На экранах больше 1260 по требованию дизайна клик должен засчитываться только по buttonTitle
                 * На экранах меньше 1260 клик засчитывается по всей области
                 * Это связано с тем, что на экранах 1260 кнопка сворачивается в иконку, а у нас очень кастомная кнопка под промо
                 */
                if (window.innerWidth > 1260 && e.target !== buttonTitleRef.current) {
                    return;
                }

                sendXray(radarName, { click: 1 });
                sendMyOfficeMetric(MyOfficeBusinessMetric.openPartner, { i: { [fileExt]: 1 } });
                onClick?.(e);
            }}
        >
            <div className={classNames(buttonStyles.button, buttonStyles.button_size, buttonStyles['button_theme-myOffice'])}>
                <div className={buttonStyles.icon} title="Установить редактор">
                    <img height={24} src={myOfficeIcon} alt="Мой Офис" />
                </div>
                <div ref={buttonTitleRef} className={buttonStyles.buttonTitle}>
                    Установить редактор
                </div>
            </div>
            {showPromoRating && (
                <div
                    className={styles.adIcon}
                    onClick={(e) => {
                        onClickAd(e);
                        sendXray(radarName, { 'click-ad': 1 });
                        e.stopPropagation();
                    }}
                >
                    <AdIcon />
                </div>
            )}
        </div>
    );
};
