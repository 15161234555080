import { createAction, createReducer } from '@reduxjs/toolkit';
import type { State } from 'reactApp/modules/quotaLanding/quotaLanding.types';

const initialState: State = {
    isLoading: true,
    isLoaded: false,
    hasError: false,
};

export const loadQuotaLandingRequest = createAction('quotaLanding/loadQuotaLandingRequest');
export const loadQuotaTariffsLandingRequest = createAction('quotaLanding/loadQuotaTariffsLandingRequest');
export const loadQuotaLandingSuccess = createAction('quotaLanding/loadUserQuotaSuccess');
export const loadQuotaLandingFailure = createAction('quotaLanding/loadQuotaLandingFailure');
export const showLandingTariffsAction = createAction('quotaLanding/showLandingTariffs');
export const sendShowLandingAnalyticsAction = createAction('quotaLanding/sendShowLandingtAnalytics');

export const quotaLandingReducer = createReducer(initialState, {
    [loadQuotaLandingRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadQuotaLandingSuccess.type]: (state) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.hasError = false;
    },
    [loadQuotaLandingFailure.type]: (state) => {
        state.isLoading = false;
        state.isLoaded = false;
        state.hasError = true;
    },
});
