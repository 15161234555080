import FeedbackLoader from '@mail/feedback-loader';
import config from 'Cloud/config';
import { xray } from 'lib/xray';
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureCSATServey } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { sendGa } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { getStorageFromUri } from 'server/helpers/getRequestParams';
import { select } from 'typed-redux-saga';

import { addPromoToShowQueue, promoShown, removePromo } from '../promo.module';
import { PromoSelectors } from '../promo.selectors';
import { EPromoType } from '../promo.types';

const PROMO_ID = 'csat-servey';
const sendCSATGa = (action: string): void => sendGa('csat-servey', action);

let loader: FeedbackLoader | null = null;

const radar = (name, data) => {
    const params: any = { p: 'cloud-web-analytics' };

    if (data) {
        params.dwh = data;
    }

    xray.send(name, params);
};

const CORSAPI_HOST = config.get('CORS_API_HOST');

const generateProject = (storage: EStorageType) => {
    return `cloud_web_${storage}`;
};

interface Survey {
    /**
     * Задержка показа опроса в миллисекундах с момента получения информации о том, что опрос активен
     * */
    delay: number;
    /**
     * Название фичи, при включении которой опрос будет считаться активным
     * */
    feature: string;
    /**
     * Доля пользователей, для которой активен опрос. Диапазон значений: [0, 1000]
     * */
    permille: number;
    /**
     * Задержка между показами в днях
     * */
    repeatAfterDays?: number;
    /**
     * Идентификатор опроса
     * */
    surveyId: string;
}

interface RawDetails {
    points: EStorageType[];
    config: {
        feedbacks: Survey[];
    };
}

const mapDetails = (raw: RawDetails[], storage: EStorageType): { feedbacks: Survey[] } => {
    if (Array.isArray(raw)) {
        return raw.reduce(
            (acc, item) => {
                if (item.points.includes(storage)) {
                    acc.feedbacks.push(...(item.config.feedbacks || []));
                }

                return acc;
            },
            { feedbacks: [] } as { feedbacks: Survey[] }
        );
    }

    return { feedbacks: [] };
};

/**
 * Конфиг в облаке
 * https://omicron.mail.ru/projects/41/applications/83/parameters/9560/edit
 */
export function* initCSATServey() {
    const currentStorage = yield* select(getCurrentStorage);
    const storageFromUrl = getStorageFromUri(window.location.pathname) as EStorageType | undefined;
    const storage = currentStorage === EStorageType.editor && storageFromUrl ? storageFromUrl : currentStorage;
    const isPhone = EnvironmentSelectors.isPhone();
    const surveyDetails = yield* select(getFeatureCSATServey);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);

    if (IS_AUTO_TEST_HIDE || !surveyDetails || isPhone || isAnonymous) {
        return;
    }

    const { feedbacks } = mapDetails(surveyDetails, storage);

    if (!feedbacks.length) {
        return;
    }

    const email = yield* select(UserSelectors.getEmail);

    loader = new FeedbackLoader(feedbacks || [], {
        apiBaseUrl: `https://${CORSAPI_HOST}/api/v1/`,
        surveyLoaderOptions: {
            email: email ?? undefined,
            radar,
            /* Отключить опрос можно из омикрона, просто убрав его из списка или permille: 0 */
            checkFeature: () => true,
            frameLoaderOptions: {
                styles: {
                    /* Приподнять над футером */
                    bottom: '37px',
                    zIndex: '1206',
                },
                /* Для разделения аналитики */
                project: generateProject(storage),
            },
        },
    });

    yield put(
        addPromoToShowQueue({
            type: EPromoType.csatServey,
            promoId: PROMO_ID,
            onShow: () => {
                sendCSATGa('show');
                store.dispatch(promoShown(EPromoType.csatServey));
            },
            onClose: () => {},
        })
    );
}

export function* showCSATServey() {
    const csatServey = yield* select(PromoSelectors.getPromo(EPromoType.csatServey));

    const onMessage = (e: MessageEvent<any>) => {
        const { action, type } = e?.data || {};

        if (type === 'feedback-evt' && action === 'close') {
            store.dispatch(removePromo(EPromoType.csatServey));
            sendCSATGa('close');
            window.removeEventListener('message', onMessage);
        }
    };

    loader
        ?.update()
        .then(() => {
            csatServey?.onShow();
            window.addEventListener('message', onMessage);
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.debug('[csat-feedback]', error);

            store.dispatch(removePromo(EPromoType.csatServey));
            sendCSATGa('skip');
        });

    loader?.setDarkTheme(isDarkTheme);
}

export function closeCSATServey() {
    loader?.close();
}
