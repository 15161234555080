import { type IHashCalculator, EHashLibType } from 'Cloud/webWorkers/types';
import { transfer, wrap } from 'comlink';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import { BUILD_URLS } from 'reactApp/appHelpers/configHelpers';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { promiseTimeout } from 'reactApp/utils/helpers';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

const pathToWorker = BUILD_URLS ? `${BUILD_URLS.js}/calculatorWorker.js` : '';

export class HashCalculator {
    private ableWorker = false;
    private hashCalcWorker;
    private hash;

    public fileSize = 0;

    static WorkerCalculatorWrapped;
    static useTransferable: boolean | null = null;

    public async init(type: EHashLibType = EHashLibType.js) {
        if (!pathToWorker) {
            return;
        }

        try {
            if (!HashCalculator.WorkerCalculatorWrapped) {
                await new Promise<void>((resolve, reject) => {
                    const oReq = new XMLHttpRequest();
                    oReq.addEventListener('error', function (error) {
                        logger.error('Worker load error:', error);
                        sendXray('worker-error-load');
                        reject();
                    });
                    oReq.addEventListener('load', function () {
                        try {
                            sendXray('worker-load');
                            if (!window.URL?.createObjectURL) {
                                sendXray('worker-error-blob');
                            }

                            const blob = window.URL.createObjectURL(new Blob([this.responseText]));
                            const worker = new Worker(blob);
                            worker.addEventListener('error', reject, false);

                            HashCalculator.WorkerCalculatorWrapped = wrap<{ new (): Promise<IHashCalculator> }>(worker);
                            resolve();
                        } catch (err) {
                            sendXray('worker-error-crt');
                            reject();
                        }
                    });
                    oReq.open('get', pathToWorker, true);
                    oReq.send();
                });
            }

            this.hashCalcWorker = await new HashCalculator.WorkerCalculatorWrapped();

            this.ableWorker = true;

            return this.hashCalcWorker.addData('mrCloud', type);
        } catch (error) {
            logger.error('Worker error:', error);
            sendXray('worker-error-exc');

            this.hash = sha1.create();

            this.hash.update('mrCloud');
        }
    }

    public async addData(dataBlock: ArrayBuffer | string, type: EHashLibType = EHashLibType.js): Promise<void> {
        if (!this.ableWorker && this.hash) {
            this.hash.update(dataBlock);
            return;
        }

        let error = '';

        if (typeof dataBlock !== 'string' && dataBlock.constructor === ArrayBuffer) {
            if (HashCalculator.useTransferable === null) {
                const testArray = new ArrayBuffer(10);
                HashCalculator.useTransferable = await Promise.race([
                    this.hashCalcWorker.canUseTransferable(transfer(testArray, [testArray])),
                    promiseTimeout(2000),
                ]);
            }

            let newArray: Uint8Array | null = new Uint8Array(dataBlock);
            const res = await this.hashCalcWorker.addData(
                HashCalculator.useTransferable ? transfer(newArray, [newArray.buffer]) : newArray,
                type
            );
            error = res.error;
            res.buffer = null;
            newArray = null;
        } else {
            const res = await this.hashCalcWorker.addData(dataBlock, type);
            error = res.error;
        }

        if (error) {
            sendXray('worker-error-hash-calc-exc');
            if (error.toLowerCase?.().includes('memory')) {
                sendXray('upld-err-hash-memory');
            }
            if (error.toLowerCase?.().includes('update(')) {
                sendXray('upld-err-hash-updinit');
            }
            if (error.toLowerCase?.().includes('webassembly(')) {
                sendXray('upld-err-hash-wasmnotspprt');
            }
            sendXray('upld-err-hash', getTrimmedText(error, 20));
            sendKaktamLog({
                text: `hash calc exception:${error}`,
                error,
                // @ts-ignore
                memoryAvailableGb: navigator?.deviceMemory,
                // @ts-ignore
                heapSize: performance?.memory?.jsHeapSizeLimit,
                fileSize: this.fileSize,
            });
        }
    }

    public async getHash(): Promise<string> {
        if (!this.ableWorker) {
            return Promise.resolve(this.hash?.hex().toUpperCase());
        }

        const hash = await this.hashCalcWorker.getHash();
        delete this.hashCalcWorker;
        return hash;
    }
}
