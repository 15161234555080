import { useBeforeUnload } from 'reactApp/hooks/useBeforeUnload';

export function useShowUnsavedChanges(action: () => void, showAlert: boolean) {
    useBeforeUnload(
        (e) => {
            if (showAlert) {
                e.preventDefault();
                // Included for legacy support, e.g. Chrome/Edge < 119
                (e || window.event).returnValue = 'false';
                // будет выполнен после закрытия браузерного модального окна о несохраненных изменениях
                setTimeout(() => {
                    // планируем показ нашего модальника через 1сек, примерно столько хватит, чтобы не промаргивать, если пользователь решил закрыть окно
                    setTimeout(action, 1000);
                });
            }
        },
        [showAlert, action]
    );
}
