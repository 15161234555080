import React, { type FC } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Publish } from 'reactApp/ui/DataListItemCommonComponents/Publish/Publish';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { Expires } from 'reactApp/ui/DataListItemRow/components/Expires/Expires';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import type { SharedItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const SharedItem: FC<SharedItemProps> = (props) => {
    const {
        id,
        isFolder,
        storage,
        publicExpires,
        ext,
        thumbUrl,
        renameAvailable,
        isAutoDelete,
        mtime,
        kind,
        isVirus,
        isPublic,
        name,
        size,
        isSelected,
        onCheckboxClick,
        onPublish,
        onDownload,
    } = props;

    const isCompact = useCompactView();
    const actionsFlexBasis = ENABLE_FULL_RESPONSIVE ? 40 : 100;

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic
                    isFolder={isFolder}
                    thumbUrl={thumbUrl}
                    kind={kind}
                    isVirus={isVirus}
                    ext={ext || ''}
                    isAutoDelete={isAutoDelete}
                    publicExpires={publicExpires}
                    isPublic={isPublic}
                />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} ext={ext || ''} id={id} isRenameAvailable={renameAvailable} />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                            {!!mtime && <>&nbsp;•&nbsp;</>}
                            <Date date={mtime} className={styles.date} />
                        </span>
                        <Expires expires={publicExpires} isAutoDelete={isAutoDelete} isShared isInline />
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} isRenameAvailable={renameAvailable} />
                    </TableColumn>
                    <TableColumn flexBasis={214} flexShrink={0} justifyContent="flex-start">
                        <Expires expires={publicExpires} isAutoDelete={isAutoDelete} isShared />
                    </TableColumn>
                    <TableColumn flexBasis={140} flexShrink={0} justifyContent="flex-end">
                        <Date date={mtime} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={actionsFlexBasis} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Publish onClick={onPublish} isShared={isPublic} viewType={EViewMode.list} />
                <Download
                    onClick={onDownload}
                    ext={isFolder ? 'zip' : ext}
                    size={size}
                    id={id}
                    storage={storage}
                    viewType={EViewMode.list}
                />
            </TableColumn>
        </>
    );
};
