import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_IOS_MOBILE, IS_MOBILE_BROWSER, IS_PAID_USER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { getUtmObject } from 'reactApp/appHelpers/settingsHelpers';

type UpsaleWithTrial = {
    activePeriod?: 'm' | 'y';
    tariffs: string[];
};

const { utm_source } = getUtmObject();
const isAdmitad = utm_source === 'admitad';

const upsaleWithTrialWebview: UpsaleWithTrial = IS_IOS_MOBILE ? getFeature('upsale-with-trial-ios') : getFeature('upsale-with-trial-and');

export const upsaleWithTrial: UpsaleWithTrial =
    !isAdmitad && !IS_PAID_USER && (IS_WEBVIEW ? upsaleWithTrialWebview : !IS_MOBILE_BROWSER && getFeature('upsale-with-trial'));

if (!IS_MOBILE_BROWSER || IS_WEBVIEW) {
    registerExperiment(
        'ups-force-tr',
        upsaleWithTrial.activePeriod || 'a',
        [
            AnalyticEventNames.MODAL_CHOOSE_TARIFF_SHOW,
            AnalyticEventNames.MODAL_CHOOSE_TARIFF_CONTINUE,
            AnalyticEventNames.TARIFF_BUY,
            AnalyticEventNames.TRIAL_TARIFF_BUY,
        ],
        {
            mapEventNames: {
                MODAL_CHOOSE_TARIFF_SHOW: 'show',
                MODAL_CHOOSE_TARIFF_CONTINUE: 'continue',
            },
        }
    );
}
