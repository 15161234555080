interface IEditor {
    myoffice?: string;
    R7?: string;
    Owa?: string;
}

export enum EEditorUserType {
    BIZ_USER = 'bizUser',
    CORP_USER = 'corpUser',
    DEV_USER = 'devUser',
    EXTERNAL_USER = 'externalUser',
    MAIL_USER = 'mailUser',
    PAID_BIZ_USER = 'paidBizUser',
    R7_USER = 'r7User',
    TEST_USER = 'testUser',
}

type TEditorSettings = Record<EEditorUserType, IEditor>;

export interface IOnlineConf {
    features: Record<string, any>;
    config?: {
        editorsSettings?: TEditorSettings;
        testUsers?: string;
        'on-premise': boolean;
        'is-tarm': boolean;
        'filin-host': string;
        'whitelist-domains': string;
    };
    tariffs?: string;
}
