import { Icon16CheckOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useState } from 'react';
import type { EViewMode } from 'reactApp/modules/settings/settings.types';
import type { Kind } from 'reactApp/types/Tree';
import type { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';

import styles from './DataListItemSquares.css';

interface DataListItemSquaresProps {
    isFolder: boolean;
    kind: Kind;
    ext?: string;
    isVirus: boolean;
    thumbUrl?: IThumb;
    isSelected: boolean;
    id: string;
    onCheckboxClick: (e: React.MouseEvent) => void;
    viewMode: EViewMode;
}

export const DataListItemSquares = memo<DataListItemSquaresProps>(
    ({ isFolder, kind, thumbUrl, ext, isVirus, isSelected, id, onCheckboxClick, viewMode }) => {
        const [isLoaded, setLoaded] = useState<boolean>(false);

        const onLoaded = useCallback(() => setLoaded(true), []);

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_loaded]: isLoaded || isFolder || !thumbUrl?.src,
                    [styles[`root_${viewMode}`]]: viewMode,
                })}
                data-qa-id={id}
                onClick={onCheckboxClick}
            >
                <div className={styles.content}>
                    <div className={styles.pic}>
                        <DatalistPic
                            isFolder={isFolder}
                            ext={ext || ''}
                            kind={kind}
                            thumbUrl={thumbUrl}
                            isVirus={isVirus}
                            viewMode={viewMode}
                            onLoaded={onLoaded}
                        />
                    </div>
                </div>
                <div className={classNames(styles.check, { [styles.active]: isSelected })} onClick={onCheckboxClick}>
                    {isSelected && <Icon16CheckOutline />}
                </div>
            </div>
        );
    }
);

DataListItemSquares.displayName = 'DataListItemSquares';
