import { ReactComponent as DocumentIcon } from 'img/icons/newDocument.svg';
import { ReactComponent as FolderIcon } from 'img/icons/newFolder.svg';
import React, { type ReactElement } from 'react';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { createDocumentMenu, createFolderMenu } from 'reactApp/ui/ContextMenu/createContextMenuList';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { sendGa } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';

export enum CreateNewMod {
    firstFolder = 'firstFolder',
    newDocument = 'newDocument',
    folder = 'folder',
    autodeletePromo = 'autodeletePromo',
    newAllDocument = 'newAllDocument',
    newAllDocumentSpreadsheet = 'newAllDocumentSpreadsheet',
    newAllDocumentPresentation = 'newAllDocumentPresentation',
}

export interface ItemDataInterface {
    icon?: ReactElement;
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onContextMenu?: React.MouseEventHandler<HTMLButtonElement>;
}

const onClickItem = ({ getItems, gaSuffix }): React.MouseEventHandler<HTMLButtonElement> => {
    return (e) => {
        e.preventDefault();
        e.stopPropagation();

        sendGa('create-new', 'click', gaSuffix);

        renderContextMenu({
            posX: e.pageX,
            posY: e.pageY,
            menuItems: getItems(),
            theme: DropdownTheme.medium,
            gaSuffix,
        });
    };
};

export const CREATE_ITEM_DATA: { [key: string]: ItemDataInterface } = {
    [CreateNewMod.firstFolder]: {
        icon: <FolderIcon width={48} height={48} />,
        text: 'Создайте свою\nпервую папку',
        onClick: () => toolbarActions.create('folder', 'welcome'),
        onContextMenu: onClickItem({ getItems: () => createFolderMenu('welcome'), gaSuffix: 'wlcm_folder' }),
    },
    [CreateNewMod.newDocument]: {
        icon: <DocumentIcon width={42} height={49} />,
        text: 'Создайте новый\nдокумент',
        onClick: onClickItem({ getItems: () => createDocumentMenu('welcome'), gaSuffix: 'wlcm_document' }),
        onContextMenu: onClickItem({ getItems: () => createDocumentMenu('welcome'), gaSuffix: 'wlcm_document' }),
    },
    [CreateNewMod.autodeletePromo]: {
        icon: <FolderIcon />,
        text: 'Создать общую папку',
    },
    [CreateNewMod.newAllDocument]: {
        text: 'Создать\nдокумент',
        onContextMenu: noopVoid,
    },
    [CreateNewMod.newAllDocumentSpreadsheet]: {
        text: 'Создать\nтаблицу',
        onContextMenu: noopVoid,
    },
    [CreateNewMod.newAllDocumentPresentation]: {
        text: 'Создать\nпрезентацию',
        onContextMenu: noopVoid,
    },
};
