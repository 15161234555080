import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

type TCaptchaCheck = {
    result: boolean;
};

export class CaptchaCheckAPICall extends APICallV4<TCaptchaCheck> {
    _method = 'captcha/check';
    _type: Method = 'get';
    _retriesCount = 0;
}
