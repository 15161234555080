import classNames from 'clsx';
import { ReactComponent as RemoveIcon } from 'img/icons/mobile/remove.svg';
import React, { type FC } from 'react';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import styles from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.css';

export interface QuotadeleteButtonProps {
    items: CloudItem[];
    hook: ({ items }) => {
        callback: () => void;
    };
}

/**
 * Кнопка тулбара - Удаление чего-либо в чистилке единой квоты
 * @param hook - хук, который вернет коллбек (действие удаления)
 * @param items - массив удаляемых элементов
 */
export const QuotaDeleteButton: FC<QuotadeleteButtonProps> = ({ hook, items }) => {
    const { callback } = hook({ items });

    return (
        <div
            className={classNames({
                [styles.barItem]: true,
            })}
            onClick={callback}
            data-qa-action="quota-delete"
        >
            <RemoveIcon className={styles.icon} />
            Удалить
        </div>
    );
};
