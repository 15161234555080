import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { type IBaseConfirmDialogProps, BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';
import { isPromise, noop } from 'reactApp/utils/helpers';

import styles from './BaseInputDialog.css';
import { Input } from './Input/Input';

interface IBaseInputDialogProps extends IBaseConfirmDialogProps {
    maxLength?: number;
    value?: string;
    selectionRange?: number;
    placeholder?: string;
}

export const BaseInputDialog = memo<IBaseInputDialogProps>(
    ({
        renderContent = noop,
        onSuccess = noop,
        onAction = noop,
        onClose = noop,
        renderHeader = null,
        successButtonText,
        maxLength,
        value = '',
        waitText = '',
        dataQAId = 'input-dialog',
        selectionRange,
        actionButtonText,
        buttons,
        placeholder,
    }) => {
        const [newValue, setNewValue] = useState<string>(value);
        const [error, setError] = useState('');
        const [isWaitingAction, setWaitingAction] = useState(false);
        const inputRef = useRef<HTMLInputElement | null>(null);
        const isPhone = useSelector(EnvironmentSelectors.isPhone);

        const handleInputChange = useCallback(
            (event) => {
                const { value } = event.target || {};
                setNewValue(value);
            },
            [newValue, setNewValue]
        );

        const getReference = (ref) => {
            inputRef.current = ref;
        };

        const handleActionClick = useCallback(() => {
            const res = onSuccess(newValue);
            if (res && isPromise(res)) {
                setWaitingAction(true);
                return res
                    .catch((error) => {
                        setError(error);
                    })
                    .finally(() => setWaitingAction(false));
            }

            return res;
        }, [newValue, onSuccess, setError, error, setWaitingAction]);

        const handleActionActionClick = useCallback(() => {
            const res = onAction(newValue);
            if (res && isPromise(res)) {
                setWaitingAction(true);
                return res
                    .catch((error) => {
                        setError(error);
                    })
                    .finally(() => setWaitingAction(false));
            }

            return res;
        }, [onAction, newValue]);

        useEffect(() => {
            // eslint-disable-next-line no-unused-expressions
            if (!isPhone) {
                inputRef?.current?.focus?.();
            }
        }, [inputRef?.current, isPhone]);

        return (
            <BaseConfirmDialog
                dataQAId={dataQAId}
                renderContent={renderContent}
                onClose={onClose}
                onSuccess={handleActionClick}
                renderHeader={renderHeader}
                successButtonText={successButtonText}
                loading={isWaitingAction}
                actionButtonText={actionButtonText}
                buttons={buttons}
                onAction={handleActionActionClick}
            >
                <div className={styles.content}>
                    {renderContent?.()}
                    <Input
                        error={!!error}
                        validationErrorMessage={error}
                        onChange={handleInputChange}
                        value={newValue}
                        maxLength={maxLength}
                        fluid
                        focus={!isPhone}
                        autoFocus={!isPhone}
                        getReference={getReference}
                        className={styles.input}
                        selectionRange={selectionRange}
                        placeholder={placeholder}
                    />
                    {isWaitingAction && waitText && <div className={styles.waitText}>{waitText}</div>}
                </div>
            </BaseConfirmDialog>
        );
    }
);

BaseInputDialog.displayName = 'BaseInputDialog';

export const renderInputDialog = renderModalDialog(BaseInputDialog, 'BaseInputDialog');
