import type { UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

export class UploaderData {
    error: null | UploadingReason;
    responseText: string | null;
    url: string;
    hash: string;
    size: number;
    speed: number;
    progress: number;
    loaded: number;
    status: number;

    public startBytes?: number;
    /**
     * @constructor
     * @param {Object} params
     * @param {string} url – адрес сервера для загрузки файла.
     * @param {string} hash – облачный hash файла (SHA1 + salt).
     * @param {number} size – размер файла (в байтах).
     * @param {number} speed – скорость загрузки (килобайт в секунду).
     * @param {number} progress – прогресс загрузки файла (в процентах).
     * @param {number} loaded – прогресс загрузки файла (в байтах).
     * @param {number} status – xhr.status
     * @param {string} responseText – xhr.responseText
     * @param {UploadReason|Error|null} error
     */
    constructor(params) {
        this.url = params.url || '';
        this.hash = params.hash || '';
        this.size = params.size || 0;
        this.speed = params.speed || 0;
        this.progress = params.progress || 0;
        this.loaded = params.loaded || 0;
        this.status = params.status || 0;
        this.responseText = params.responseText || '';
        this.error = params.error || null;
    }
}
