import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { equals } from 'ramda';
import type { AbstractAPICallInterface } from 'reactApp/api/AbstractAPICall';

export const makeRequestHelper = async (apiObject: AbstractAPICallInterface, ...args): Promise<{ data?: any; error?: any }> =>
    apiObject
        .makeRequest(...args)
        .then((response) => ({ ...response }))
        .catch((error) => {
            logger.error(error);
            captureException(error);
            return { error };
        });

export const checkApiResponseDataEquals =
    (oldData) =>
    ({ data }) =>
        !equals(data, oldData);
