import { Button, ButtonGroup } from '@vkontakte/vkui';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsTutoriaIntegration } from 'reactApp/hooks/useIsTutoriaIntegration';
import { attachSelected, cancel as cancelPublishing, publishSelected } from 'reactApp/modules/integration/integration.actions';
import { getSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import type { RootState } from 'reactApp/store';

import styles from './IntegrationFooter.css';

export const IntegrationFooter = React.memo(() => {
    const dispatch = useDispatch();
    const selectedItems = useSelector(getSelectedItems);
    const isAutoSharingDisabled = useSelector((state: RootState) => state.integration.isAutoSharingDisabled);
    const isTutoria = useIsTutoriaIntegration();

    const onAcceptClick = () => {
        xray.send('attach_cloud_add');

        if (isAutoSharingDisabled) {
            dispatch(attachSelected());
        } else {
            dispatch(publishSelected());
        }
    };

    const onCancelClick = () => {
        xray.send('attach_cloud_closed');
        dispatch(cancelPublishing());
    };

    return (
        <div className={classNames(styles.root, { [styles.root_integration]: isTutoria })} data-qa-id="integration-footer">
            <ButtonGroup gap={isTutoria ? 'none' : 's'} className={classNames({ [styles.buttonGroup_integration]: isTutoria })}>
                <Button
                    appearance="accent"
                    mode="primary"
                    size="m"
                    className={styles.button}
                    disabled={!selectedItems?.length}
                    onClick={onAcceptClick}
                    data-qa-id="attach-button"
                >
                    Прикрепить
                </Button>
                <Button
                    appearance="neutral"
                    mode="secondary"
                    size="m"
                    className={styles.button}
                    onClick={onCancelClick}
                    data-qa-id="cancel-button"
                >
                    Отменить
                </Button>
            </ButtonGroup>
        </div>
    );
});

IntegrationFooter.displayName = 'IntegrationFooter';
