import { APICallV3Get, APICallV3Post } from 'reactApp/api/APICall';

type Params = {
    has_sessions: boolean;
};

export class WopiEditorConsistencySupportAPICall extends APICallV3Get<Params> {
    _method = 'wopi-go/active';
}

export class R7EditorConsistencySupportAPICall extends APICallV3Post<Params> {
    _method = 'r7/active';
}
