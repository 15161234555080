import type { Method } from 'lib/axios';
import { APICallV1 } from 'reactApp/api/APICall';

export type TProfileGetFull = {
    did: string;
};

export class ProfileFullApiCall extends APICallV1<TProfileGetFull> {
    _method = 'profile/full_profile';
    _type: Method = 'post';
    defaultData = this._defaultPayload;
}
