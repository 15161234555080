import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';

import styles from './FilterAuthorsDropdown.css';

interface Props {
    text: string;
    withAvatar: boolean;
    email?: string;
    checked: boolean;
    id: string;
}

export const AuthorItem = memo(
    ({ text, withAvatar, checked, email, id }: Props): ReactElement => (
        <div className={classNames(styles.item, { [styles.item_all]: id === 'all' })}>
            <div className={styles.checkbox}>
                <Checkbox selected={checked} mod="new" />
            </div>
            <div className={styles.text}>{text}</div>
            {withAvatar && <Avatar size={24} email={email} />}
        </div>
    )
);

AuthorItem.displayName = 'AuthorItem';
