import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { withViewContext } from 'reactApp/hocs/withViewContext';
import { type Props as WithXrayProps, withXray } from 'reactApp/hocs/withXray';
import { getViewerFile, getViewerRequestId, getViewerUserId } from 'reactApp/modules/embedded/embedded.selectors';
import { withFileStateVKNotifier } from 'reactApp/modules/embedded/hocs/withFileStateVKNotifier';
import { withVKCSATLoader } from 'reactApp/modules/embedded/hocs/withVKCSATLoader';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import type { RootState } from 'reactApp/store';
import type { IProps } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { ReactViewerComponent } from 'reactApp/ui/ReactViewer/ReactViewerComponent';
import { compose } from 'redux';

interface Props extends IProps, WithXrayProps {}

const mapStateToProps = (state: RootState, props: IProps) => ({
    ...props,
    file: getViewerFile(state),
    itemStorage: ViewerSelectors.getViewerItemStorage(state),
    requestId: getViewerRequestId(state),
    userId: getViewerUserId(state),
    isViewerFileVerifiedByKaspersky: getIsViewerFileVerifiedByKaspersky(state),
});

export const ReactViewerEmbedded = compose<FunctionComponent<Props>>(
    withXray,
    withVKCSATLoader,
    withFileStateVKNotifier,
    withViewContext,
    connect(mapStateToProps)
)(ReactViewerComponent);
