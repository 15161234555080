import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewAutoDeleteFolder } from 'reactApp/modules/modifying/modifying.actions';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { createGaSender } from 'reactApp/utils/ga';

interface UseCreateAutoDeleteFolderHandlerParams {
    sendGa: ReturnType<typeof createGaSender>;
}
export const useCreateAutoDeleteFolderHandler = ({ sendGa }: UseCreateAutoDeleteFolderHandlerParams) => {
    const dispatch = useDispatch();

    const isAutodeleteFeatureEnabled = !!useSelector(isAutoDeleteEnabled);
    const isProUser = useSelector(UserSelectors.isProUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);

    const handleFolderCreate = useCallback(() => {
        sendGa('create-click');

        if (isPaidUser) {
            sendGa('create-click', isProUser ? 'pro' : 'paid');
        }

        if (isAutodeleteFeatureEnabled) {
            dispatch(createNewAutoDeleteFolder());
        }
    }, [dispatch, isAutodeleteFeatureEnabled, isPaidUser, isProUser, sendGa]);

    return {
        createFolder: handleFolderCreate,
    };
};
