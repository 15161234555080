import type { Method } from 'lib/axios';
import { APICallV0 } from 'reactApp/api/APICall';
import { APIConfig } from 'reactApp/api/APIConfig';

export class EmergencySwaAPICall extends APICallV0 {
    _baseUrl = '/';
    _method = 'emergencyswa';
    _type: Method = 'get';
    defaultParams = {
        login: APIConfig.get('user') || 'anonym',
    };
}
