import { Button } from '@vkontakte/vkui';
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import MascotBiteIcon from 'img/icons/uploader/mascot_bite.png';
import React, { type ReactElement, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureNewEmptyStatesTouch } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import styles from 'reactApp/ui/UploadDropArea/Upload/Upload.css';
import { UploadButtons } from 'reactApp/ui/UploadDropArea/UploadButtons/UploadButtons';
import { sendGa } from 'reactApp/utils/ga';

interface Props {
    isOpen: boolean;
    isPhone?: boolean;
    // tempexp-17235-start
    isCentered?: boolean;
    // tempexp-17235-end
    text: string;
    title?: string;
    onMouseEnter: (e: any) => void;
    onMouseLeave: (e: any) => void;
    onClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onFolderClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onFileClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onSelectFromCloud: (e: React.MouseEvent | KeyboardEvent) => void;
}

export const UploadButton = ({
    isOpen,
    text,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onFolderClick,
    onFileClick,
    onSelectFromCloud,
    isPhone = false,
    // tempexp-17235-start
    isCentered = false,
    // tempexp-17235-end
    title = 'Загрузить',
}: Props): ReactElement | null => {
    const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
    const storage = useSelector(getCurrentStorage);

    const onMouseEnterHandler = useCallback(
        (event) => {
            onMouseEnter(event);
        },
        [onMouseEnter]
    );

    const onMouseLeaveHandler = useCallback(
        (event) => {
            onMouseLeave(event);
        },
        [onMouseLeave]
    );

    useEffect(() => {
        const hotkey = 'shift+u,shift+i';
        hotkeys(hotkey, (event) => {
            sendGa('hotkey', `shift_${event.key}`);
            if (event.key.toLowerCase() === 'i') {
                onClick(event);
            } else {
                onFolderClick(event);
            }
        });

        return () => {
            hotkeys.unbind(hotkey);
        };
    }, [onClick, onFolderClick]);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                // tempexp-17235-start
                [styles.root_centered]: isCentered,
                // tempexp-17235-end
                [styles.open]: isOpen,
                [styles.root_phone]: isPhone,
                [styles.shifted_by_panel]: isActionPanelOpened,
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                /* tempexp_14812-next-line */
                [styles.new_empty_states]: chooseVariant((state) => getFeatureNewEmptyStatesTouch(state) && isPhone, {
                    control: false,
                    variant1: true,
                })(),
                [styles.root_album]: storage === EStorageType.albums,
            })}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onClick}
        >
            {/* tempexp_14812-next-line */}
            <FeatureSelector
                selector={(state) => getFeatureNewEmptyStatesTouch(state) && isPhone}
                control={
                    <div className={styles.button}>
                        <div className={styles.buttonText}>{title}</div>
                    </div>
                }
                variant1={
                    <Button size="l" className={styles['touchButton']}>
                        Загрузить
                    </Button>
                }
            />
            <div className={styles.circle}>
                <div className={classNames(styles.circleContent, { [styles.circleContent_wide]: storage === EStorageType.albums })}>
                    {isRebranding && <img src={MascotBiteIcon} className={styles.mascot} alt="mascot" />}
                    <div className={styles.circleText} dangerouslySetInnerHTML={{ __html: text }} />
                    <UploadButtons onFolderClick={onFolderClick} onFileClick={onFileClick} onSelectFromCloud={onSelectFromCloud} />
                </div>
            </div>
        </div>
    );
};
