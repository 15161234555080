import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { isMobileGalleryViewMode } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage.helpers';

import {
    GALLERY_BLOCK_HEIGHT_RATIO,
    GALLERY_TYPES_COUNT,
    GRID_ITEM_HEIGHT,
    GRID_ITEM_WIDTH,
    ITEM_MIN_PADDING,
    ITEM_MIN_PADDING_PHONE,
    ITEM_VERTICAL_PADDING,
    LARGE_PHONE_MIN_WIDTH,
    ROW_HEIGHT,
    SCROLL_GAP,
    SQUARES_ITEM_MIN_PADDING,
    SQUARES_ITEM_SIZE,
    SQUARES_ITEM_SIZE_180,
    TOOLBAR_HEIGHT,
} from './VirtualList.constants';
import type { VirtualIndex, VirtualListDivider, VirtualListItem } from './VirtualList.types';

interface Sizes {
    columnCount: number;
    lineHeight: number;
    height: number;
    width: number;
    margin: number;
}

interface ListSizeParams {
    containerWidth: number;
    viewMode?: EViewMode;
    itemHeight?: number;
    itemWidth?: number;
    rowHeight: number;
    isPhone: boolean;
}

export interface ActiveIndexParams {
    index: number;
    indexes: VirtualIndex[];
    viewMode?: EViewMode;
    columnCount: number;
}

const getSquaresViewModSizes = (containerWidth: number, padding: number, size: number) => {
    const columnCount = Math.floor((containerWidth + padding) / (size + padding));
    const margin = (containerWidth - columnCount * size) / (columnCount - 1);
    const lineHeight = size + padding;

    return {
        columnCount,
        lineHeight,
        width: size,
        height: size,
        margin,
    };
};

export const getListSizes = ({
    containerWidth,
    viewMode,
    itemHeight = GRID_ITEM_HEIGHT,
    itemWidth = GRID_ITEM_WIDTH,
    rowHeight = ROW_HEIGHT,
    isPhone = false,
}: ListSizeParams): Sizes => {
    if (isPhone && viewMode === EViewMode.thumbs) {
        const columnCount = containerWidth < LARGE_PHONE_MIN_WIDTH ? 2 : 3;
        const width = (containerWidth - (columnCount - 1) * ITEM_MIN_PADDING_PHONE) / columnCount;
        const margin = (containerWidth - columnCount * width) / (columnCount - 1);

        return {
            columnCount,
            lineHeight: width + ITEM_MIN_PADDING_PHONE,
            width,
            // На таче плиточки квадратные
            height: width,
            margin,
        };
    }

    if (isPhone && viewMode === EViewMode.galleryGrid3) {
        const columnCount = 3;
        const width = containerWidth / columnCount;
        const columnsMargin = 2;

        return {
            columnCount,
            lineHeight: width + columnsMargin * 2,
            width,
            height: width,
            margin: columnsMargin,
        };
    }

    if (viewMode === EViewMode.squares) {
        return getSquaresViewModSizes(containerWidth, SQUARES_ITEM_MIN_PADDING, SQUARES_ITEM_SIZE);
    }

    if (viewMode === EViewMode.squares180) {
        return getSquaresViewModSizes(containerWidth, SQUARES_ITEM_MIN_PADDING, SQUARES_ITEM_SIZE_180);
    }

    const columnCount =
        viewMode === EViewMode.thumbs ? Math.floor((containerWidth + ITEM_MIN_PADDING) / (itemWidth + ITEM_MIN_PADDING)) : 1;
    const margin = (containerWidth - columnCount * itemWidth) / (columnCount - 1);

    let lineHeight = rowHeight;

    if (viewMode === EViewMode.thumbs) {
        lineHeight = itemHeight + ITEM_VERTICAL_PADDING;
    } else if (viewMode === EViewMode.gallery) {
        lineHeight = containerWidth * GALLERY_BLOCK_HEIGHT_RATIO + ITEM_MIN_PADDING;
    }

    return {
        columnCount,
        lineHeight,
        width: itemWidth,
        height: itemHeight,
        margin,
    };
};

export const scrollIntoView = (item: HTMLDivElement | null): void => {
    const rect = item?.getBoundingClientRect();
    const top = rect?.top || 0;
    const bottom = rect?.bottom || 0;
    const yOffset = window.pageYOffset;
    const windowHeight = window.innerHeight;

    if (top + yOffset < yOffset + TOOLBAR_HEIGHT) {
        window.scrollTo(0, top + yOffset - TOOLBAR_HEIGHT - SCROLL_GAP);
        return;
    }

    if (bottom + yOffset > windowHeight + yOffset) {
        window.scrollTo(0, bottom + yOffset - windowHeight + SCROLL_GAP);
    }
};

const getGalleryBlockCount = (row: number): number => (row % GALLERY_TYPES_COUNT === 0 ? 5 : 9);

const getGalleryIndexes = (list: VirtualListItem[]): VirtualIndex[] => {
    const indexes: VirtualIndex[] = [];
    let startIndex = 0;
    let currentRow = 0;
    let columnCount = getGalleryBlockCount(currentRow);
    let lastIndex = Math.min(startIndex + columnCount - 1, list.length - 1);

    while (startIndex < list.length) {
        indexes.push({ start: startIndex, end: lastIndex });
        startIndex = lastIndex + 1;

        currentRow++;
        columnCount = getGalleryBlockCount(currentRow);

        lastIndex = Math.min(startIndex + columnCount - 1, list.length - 1);
    }

    return indexes;
};

export const getIndexes = (list: VirtualListItem[], columnCount: number, view: EViewMode | undefined): VirtualIndex[] => {
    if (list.length === 1 || columnCount === 0) {
        return [{ start: 0, end: 0 }];
    }

    if (view === EViewMode.gallery) {
        return getGalleryIndexes(list);
    }

    const indexes: VirtualIndex[] = [];
    let startIndex = 0;
    let lastIndex = Math.min(startIndex + columnCount - 1, list.length - 1);

    while (startIndex < list.length - 1) {
        let dividerIndex = -1;

        for (let i = startIndex; i <= lastIndex; i++) {
            if ((list[i] as VirtualListDivider).divider) {
                dividerIndex = i;
                break;
            }
        }

        if (dividerIndex === 0 || dividerIndex === startIndex) {
            indexes.push({ start: dividerIndex, end: dividerIndex });
            startIndex = dividerIndex + 1;
        } else if (dividerIndex !== -1) {
            indexes.push({ start: startIndex, end: dividerIndex - 1 }, { start: dividerIndex, end: dividerIndex });
            startIndex = dividerIndex + 1;
        } else {
            indexes.push({ start: startIndex, end: lastIndex });
            startIndex = lastIndex + 1;
        }

        if (startIndex === list.length - 1) {
            indexes.push({ start: startIndex, end: startIndex });
        }

        lastIndex = Math.min(startIndex + columnCount - 1, list.length - 1);
    }

    return indexes;
};

export const getActiveRowIndex = ({ index, indexes, viewMode, columnCount }: ActiveIndexParams): number => {
    if (viewMode === EViewMode.list) {
        return index;
    }

    if (
        viewMode === EViewMode.thumbs ||
        isMobileGalleryViewMode(viewMode) ||
        viewMode === EViewMode.squares ||
        viewMode === EViewMode.squares180
    ) {
        return Math.floor(index / columnCount);
    }

    if (viewMode === EViewMode.gallery) {
        return indexes?.findIndex((row) => row?.start <= index && index <= row?.end);
    }

    return -1;
};

export const getThumbTypeForGallery = (grid: number, item: number) => {
    switch (grid) {
        case 0:
            return item === 0 ? 'galleryBig' : 'gallerySmall';
        case 1:
            return [2, 5, 8].includes(item) ? 'galleryBig' : 'gallerySmall';
        case 2:
            return item === 2 ? 'galleryBig' : 'gallerySmall';
    }
};
