/* CLOUDWEB-17241 [WEB][Desktop] - Брендирование страницы шаринга файлов (шаринг по ссылке) под свои нужды */

import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { IS_BIZ_USER, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

// tempexp_17241-start
const abThemedSharedPublic: 'a' | 'b' | 'c' = getFeature('themed-shared-public');
export interface SharingPublicThemeTariffIds {
    year: string[];
    month: string[];
}
export const sharingPublicThemeTariffIds: SharingPublicThemeTariffIds = getFeature('sharing-public-theme-tariffs') || {};

// tempexp_17241-end

// tempexp_17340-start
const abThemedSharedToobarItem: 'a' | 'b' | 'c' = getFeature('themed-shared-toolbar-item');
const abThemedSharedToobarItemMore: 'a' | 'b' | 'c' = getFeature('themed-shared-toolbar-item-more');
const abThemedSharedModalItem: 'a' | 'b' | 'c' = getFeature('themed-shared-modal-item');
/** CLOUDWEB-17340: Кнопка 'Выбрать оформление' в тулбаре */
export const isThemedSharedToobarItem = !IS_BIZ_USER && !IS_SOCIAL_USER && abThemedSharedToobarItem === 'c';
/** CLOUDWEB-17340: Кнопка 'Выбрать оформление' в тулбаре в трёх точках */
export const isThemedSharedToobarItemMore = !IS_BIZ_USER && !IS_SOCIAL_USER && abThemedSharedToobarItemMore === 'b';
// tempexp_17340-end
// tempexp_17341-start
/** CLOUDWEB-17341: Пункт "Оформление для папки" в окне шэринга */
export const isThemedSharedModalItem =
    !IS_BIZ_USER && !IS_SOCIAL_USER && (abThemedSharedModalItem === 'b' || abThemedSharedModalItem === 'c');
// tempexp_17341-end

registerExperiment(
    'themed-publics',
    abThemedSharedPublic,
    [
        AnalyticEventNames.TOOLTIP_BRAND_PAGE_SHOW,
        AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLICK,
        AnalyticEventNames.TOOLTIP_BRAND_PAGE_CLOSE,
        AnalyticEventNames.BREADCRUMBS_SHOW,
        AnalyticEventNames.TOOLBAR_ITEM_SHOW_BRAND_PAGE,
        AnalyticEventNames.TOOLBAR_ITEM_CLICK_BRAND_PAGE,
        AnalyticEventNames.BREADCRUMBS_PUBLISH_CLICK,
        AnalyticEventNames.SHARING_BRAND_PAGE_SHOW,
        AnalyticEventNames.SHARING_BRAND_PAGE_CLICK,
        AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_SHOW,
        AnalyticEventNames.SHARING_TOOLTIP_BRAND_PAGE_CLOSE,
        AnalyticEventNames.TOOLBAR_BRAND_PAGE_CLICK,
        AnalyticEventNames.TOOLBAR_BRAND_PAGE_SHOW,
        AnalyticEventNames.BRAND_PAGE_VIEW,
        AnalyticEventNames.BRAND_PAGE_SAVE,
        AnalyticEventNames.BRAND_PAGE_TARIFF_PLAN_CHOOSE,
        AnalyticEventNames.BRAND_PAGE_TARIFF_CLICK,
        AnalyticEventNames.TARIFF_BUY,
        AnalyticEventNames.FAST_CHECKOUT_SHOWN,
        AnalyticEventNames.BRAND_PAGE_HIDE,
        AnalyticEventNames.BRAND_PAGE_EXIT_SHOW,
        AnalyticEventNames.BRAND_PAGE_EXIT_CHOOSE,
        AnalyticEventNames.BRAND_PAGE_EXIT_TRUE,
    ],
    {
        sendXrayArgs: {
            TARIFF_BUY: ({ paySource }, { eventName }) => ({
                eventName: paySource?.startsWith('brand_page') ? 'bp_buy' : eventName,
            }),
            FAST_CHECKOUT_SHOWN: ({ paySource }, { eventName }) => ({
                eventName: paySource?.startsWith('brand_page') ? 'bp_checkout' : eventName,
            }),
        },
    }
);
