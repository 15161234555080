import React, { memo, useEffect, useRef, useState } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import type { Product } from 'reactApp/types/Billing';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { useDisableAdsTariffs } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTariffs';
import { TariffsModalContent } from 'reactApp/ui/TariffsModal/TariffsModalContent';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendDwh } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';

interface DisableAdsScreenProps {
    title: string;
    tariffsNumber: string;
    activeTab: string;
    isFrame?: boolean;
    onClose?: () => void;
    onBuyClick: (product: Product) => void;
}

const eventCategory = 'tariffs-quota';

export const BuyDisableAds = memo(({ activeTab, tariffsNumber, title, onClose = noopVoid, onBuyClick }: DisableAdsScreenProps) => {
    const [tabActive, setTabActive] = useState<ETabsName>(activeTab === 'm' ? ETabsName.month : ETabsName.year);
    const { tariffs, tariffIds } = useDisableAdsTariffs(tariffsNumber);

    const isFirstEffectCalled = useRef(false);
    useEffect(() => {
        if (!isFirstEffectCalled.current) {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN, { selectedPeriod: activeTab });
            sendDwh({ eventCategory, action: 'modal-noads-show', dwhData: { selectedPeriod: activeTab } });
            isFirstEffectCalled.current = true;
        } else {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE, { selectedPeriod: activeTab });
            sendDwh({ eventCategory, action: 'modal-noads-switch', dwhData: { selectedPeriod: activeTab } });
        }
    }, [activeTab]);

    const onBuyWithRadar = (product: Product) => {
        sendDwh({ eventCategory, action: 'modal-noads-click', dwhData: { tariff: product.id } });
        onBuyClick(product);
    };

    const content = (
        <TariffsModalContent
            tariffs={tariffs}
            yearTariffsCount={tariffIds.year.length}
            monthTariffsCount={tariffIds.month.length}
            activeTab={tabActive}
            setActiveTab={setTabActive}
            onBuyClick={onBuyWithRadar}
            title={title}
        />
    );

    return (
        <Dialog id="disable-ads-screen" open disableDarkTheme mod="frame" closeOnDimmerClick={false} onCancel={onClose}>
            {content}
        </Dialog>
    );
});

export const openDisableAdsScreen = ({ ...data }: DisableAdsScreenProps) =>
    openPopupHelper({
        popupName: popupNames.BUY_DISABLE_ADS,
        data,
    });

BuyDisableAds.displayName = 'BuyDisableAds';
