import { mergeDeepLeft, mergeDeepWithKey } from 'ramda';
import type { ExtensionRecord } from 'reactApp/modules/editor/editor.types';
import type { ServerEditor } from 'server/helpers/editors/types';

import { getIconClass } from './get-icon-class';

type ComposeExtensionsFn = (editors: ServerEditor[]) => ExtensionRecord;

const extText = {
    folder: 'Папку',
    pptx: 'Презентацию',
    xlsx: 'Таблицу',
    docx: 'Документ',
};

export const composeExtensions: ComposeExtensionsFn = (editors: ServerEditor[]) =>
    editors.reduce((acc, editor) => {
        const editExtensions = (editor.edit_extensions || []).reduce(
            (editExtHash, ext) => ({ ...editExtHash, [ext]: { editors: [editor.id] } }),
            {}
        );

        const createExtensions = (editor.create_extensions || []).reduce((createExtHash, ext) => {
            const creatorsDesc = acc?.[ext]?.creatorsDesc || {
                name: ext,
                icon: getIconClass(ext, 16, ''),
                text: extText[ext],
            };

            return { ...createExtHash, [ext]: { creatorsDesc, editors: [] } };
        }, {});

        return mergeDeepWithKey((k, l, r) => (k === 'editors' ? l.concat(r) : r), acc, mergeDeepLeft(editExtensions, createExtensions));
    }, {} as ExtensionRecord);
