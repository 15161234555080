import { stringify } from 'qs';
import React, { type ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getSearchSection } from 'reactApp/modules/search/search.selectors';
import { type ApiSearchFolderResponseFile, type ApiSearchResponseFolder, SearchSourceTitle } from 'reactApp/modules/search/search.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsContainer } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import type { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';
import { scrollToTop } from 'reactApp/utils/helpers';

import styles from './MobileSearchDatalist.css';
import { SearchSection } from './SearchSection/SearchSection';

export interface IProps {
    sections: {
        title: SearchSourceTitle;
        srch_src: ESearchOptionSource;
        items: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
        items_in_section: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
    }[];
    byOneSource: SearchSourceTitle | null | undefined;
    noResults: boolean;
}

export const MobileSearchDatalist: React.FC<IProps> = ({ sections, byOneSource, noResults }): ReactElement => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { query, searchType } = useSelector(SettingsSelectors.getQueryParams);
    const searchSection = useSelector(getSearchSection);
    const backLocation = stringify({ query, searchType });
    const showDatalist = Boolean(searchSection || byOneSource || noResults);

    const onClickBack = () => {
        scrollToTop();
        dispatch(resetSelect());
        dispatch(historyPush({ id: `${location.pathname}?${backLocation}` }));
    };

    return (
        <>
            {searchSection && (
                <Breadcrumbs showBackButton={!!searchSection} onClickBackButton={onClickBack} name={SearchSourceTitle[searchSection]} />
            )}
            {byOneSource && <BreadcrumbsContainer />}
            {showDatalist ? (
                <Datalist />
            ) : (
                <div className={styles.sectionsContainer}>
                    {sections.map(({ title, items, srch_src, items_in_section }) => (
                        <SearchSection key={title} title={title} items={items} section={srch_src} sectionItems={items_in_section} />
                    ))}
                </div>
            )}
        </>
    );
};
