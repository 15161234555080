import { Icon20Cancel } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { type FC, type PropsWithChildren, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { QRCloud } from 'reactApp/ui/QRCloud/QRCloud';
import { sendXray } from 'reactApp/utils/ga';

import styles from './AsidePromoModal.css';
import { type IAsidePromoModalProps, EAsidePromoModalTheme } from './AsidePromoModal.types';

interface ContainerProps {
    isMobile: boolean;
    onClose: () => void;
    qaId: string;
    darkClose?: boolean;
    textCompact?: boolean;
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({ isMobile, children, onClose, qaId }) => {
    if (isMobile) {
        return (
            <MobileDialog id={qaId} onClose={onClose} mod="base" topmost closeOnDimmerClick contextPadding="zero">
                {children}
            </MobileDialog>
        );
    }

    return <>{children}</>;
};

export const AsidePromoModal = memo<IAsidePromoModalProps>(
    ({
        theme,
        title,
        text,
        imageUrl,
        buttonText,
        href,
        onShow,
        onClick,
        onClose,
        fixed = true,
        bottom,
        qrUrl,
        qaId = 'aside-promo-modal',
        showAboveViewer,
        darkClose,
        textCompact,
        closeOnNavigation,
    }) => {
        const dispatch = useDispatch();
        const storage = useSelector(getCurrentStorage);
        const firstStorage = useMemo(() => storage, []);

        const sendRadar = useCallback((action: string) => sendXray(['aside-promo', action, qaId]), [qaId]);

        useEffect(() => {
            onShow?.();
            sendRadar('show');
        }, [sendRadar]);

        const handleClick = useCallback(() => {
            onClick?.();
            sendRadar('click');
            // Просто вызывать onClose?.() нельзя, так как в начале вызова в onClose прокидываются отсыл радаров клика по крестику
            dispatch(closePopupHelper(popupNames.ASIDE_PROMO_MODAL));
        }, [onClick, sendRadar]);

        const handleClose = useCallback(() => {
            onClose?.();
            sendRadar('close');
        }, [onClose, sendRadar]);

        useEffect(() => {
            if (!closeOnNavigation) {
                return;
            }

            if (firstStorage !== storage) {
                handleClose();
            }
        }, [closeOnNavigation, firstStorage, storage, handleClose]);

        const isMobile = EnvironmentSelectors.isPhone();

        return (
            <Container isMobile={isMobile} onClose={handleClose} qaId={qaId}>
                <div
                    className={classNames({
                        [styles.root]: !isMobile,
                        [styles.root_fixed]: !isMobile && fixed,
                        [styles.root_mobile]: isMobile,
                        [styles[`root_${theme}`]]: Boolean(theme),
                        [styles.root_showAboveViewer]: showAboveViewer,
                    })}
                    data-qa-modal={qaId}
                    style={bottom ? { bottom: `${bottom}px` } : undefined}
                >
                    {Boolean(imageUrl) && (
                        <div className={styles.imageWrapper}>
                            <img className={styles.image} src={imageUrl} alt="Cover image" />
                        </div>
                    )}
                    {Boolean(qrUrl) && (
                        <div className={styles.qrWrapper}>
                            <QRCloud url={qrUrl ?? ''} size={160} isDark={theme === EAsidePromoModalTheme.dark} />
                        </div>
                    )}
                    <div
                        className={classNames(styles.content, {
                            [styles.content_mobile]: isMobile,
                        })}
                    >
                        <Text
                            className={classNames(styles.title, {
                                [styles.title_mobile]: isMobile,
                            })}
                        >
                            {title}
                        </Text>
                        <Spacing size={isMobile ? 12 : 8} />
                        <Text
                            className={classNames(styles.text, {
                                [styles.text_mobile]: isMobile,
                                [styles.text_compact]: textCompact,
                            })}
                        >
                            {text}
                        </Text>
                        {buttonText && (
                            <>
                                <Spacing size={isMobile ? 24 : 20} />
                                <Button
                                    className={classNames(styles.button, {
                                        [styles.button_mobile]: isMobile,
                                    })}
                                    href={href}
                                    theme="vk"
                                    primary
                                    target="_blank"
                                    onClick={handleClick}
                                    sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                                >
                                    {buttonText}
                                </Button>
                            </>
                        )}
                    </div>
                    {!isMobile && (
                        <div className={classNames(styles.close, { [styles.darkClose]: darkClose })} onClick={handleClose}>
                            <Icon20Cancel width={18} height={18} />
                        </div>
                    )}
                </div>
            </Container>
        );
    }
);

AsidePromoModal.displayName = 'AsidePromoModal';
