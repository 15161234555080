import React, { type MouseEvent, type ReactElement, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { createAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { getAlbumsItemById, getFlattenAlbumsList } from 'reactApp/modules/albums/albums.selector';
import { type IAlbum, isAlbum } from 'reactApp/modules/albums/albums.types';
import { Album } from 'reactApp/sections/AlbumsPage/Album/Album';
import { CreateAlbum } from 'reactApp/sections/AlbumsPage/CreateAlbum/CreateAlbum';
import type { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { ErrorRetry } from 'reactApp/ui/ErrorRetry/ErrorRetry';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';

import type { IProps } from '../../SelectAlbumPopup.types';
import { SelectAlbumPopupEmpty } from '../EmptyContent/SelectAlbumPopupEmpty';
import styles from './SelectAlbumPopupContent.css';

export const SelectAlbumPopupContent = memo(function SelectAlbumPopupContent(props: IProps): ReactElement {
    const { onSuccess, onClose, onCreateAlbum, items: itemsToAdd } = props;
    const items = useSelector(getFlattenAlbumsList) as IAlbum[];
    const isEmptyAlbumsList = !items.length;
    const album = useSelector((state: RootState) => getAlbumsItemById(state, ROOT_FOLDER_ID));
    const error = isAlbum(album) ? album.error : undefined;
    const isLoading = isAlbum(album) ? !album.isLoaded && album.isLoading : true;
    const [selectedAlbum, setSelected] = useState<string>('');
    const dispatch = useDispatch();

    const handleSuccess = useCallback(
        (e: MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault();
            e.stopPropagation();
            onSuccess(selectedAlbum);
        },
        [onSuccess, selectedAlbum]
    );

    const createAlbumHandler = useCallback(() => {
        dispatch(createAlbumRequest({ items: itemsToAdd }));
        onClose();
    }, []);

    if (error) {
        return <ErrorRetry centered />;
    }

    if (isLoading) {
        return <Spinner />;
    }

    if (isEmptyAlbumsList) {
        return <SelectAlbumPopupEmpty onCreateAlbum={onCreateAlbum} />;
    }

    return (
        <>
            <div className={styles.datalist}>
                <div className={styles.album}>
                    <CreateAlbum onClick={createAlbumHandler} />
                </div>
                {items.map((album) => (
                    <div className={styles.album} key={album.id}>
                        <Album
                            {...album}
                            onClick={() => setSelected(album.id)}
                            isLink={false}
                            isSelectable
                            isSelected={selectedAlbum === album.id}
                        />
                    </div>
                ))}
            </div>

            <div className={styles.buttonGroup} data-qa-id="button-group">
                <Button theme="vk" primary onClick={handleSuccess} className={styles.button} disabled={!selectedAlbum}>
                    Добавить в альбом
                </Button>

                <Button theme="vk" className={styles.button} onClick={onClose}>
                    Отменить
                </Button>
            </div>
        </>
    );
});
