import { cdnPackagesUrl, minCompatibleVersion } from './default-values';

const themeOutsourcePart = '/themes.outsource';

export const getThemeOutsourcePath = (version: string, useLocal: boolean) => {
    const resolvedVersion = minCompatibleVersion > version ? minCompatibleVersion : version;
    return useLocal ? themeOutsourcePart : `${cdnPackagesUrl}${themeOutsourcePart}/${resolvedVersion}`;
};

export const getThemeOutsourceId = (id?: number | null) => {
    if (id === 1) {
        return 'default';
    }
    if (id) {
        return `t${id}`;
    }
    return;
};

export const getThemeId = (id?: string) => {
    if (id === 'default') {
        return 1;
    }
    if (id?.startsWith('t')) {
        return parseInt(id.slice(1));
    }
    return;
};
