import config from 'Cloud/config';
import { addDays, fromUnixTime, isValid as isValidDate } from 'date-fns';
import coverImage from 'img/welcome/attachesWelcomeCover.png';
import React, { type VFC, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { getFeatureShowRepeatingAttaches } from 'reactApp/modules/features/features.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';

export interface IWelcomeAttachesRepeatingProps {
    onClose?: () => void;
    onShow?: () => void;
    onAccept?: () => void;
}

const LOCAL_STORAGE_KEY = 'last_welcome_attaches_repeating_show';
const MAIL_HOST = config.get('MAIL_HOST');

export const WelcomeDialogAttachesRepeating: VFC<IWelcomeAttachesRepeatingProps> = ({ onClose, onShow, onAccept }) => {
    const isShown = useRef(false);
    const isAnyDialogVisible = useSelector(isDialogVisible);
    const featureEnabled = useSelector(getFeatureShowRepeatingAttaches);
    const dispatch = useDispatch();

    const needToShow: boolean = useMemo(() => {
        if (isShown.current) {
            return true;
        }
        if (!featureEnabled || isAnyDialogVisible) {
            return false;
        }

        const localStorageValue = dispatch(UserStorageActions.get(LOCAL_STORAGE_KEY)) as unknown as null | string;
        const lastShowTime = localStorageValue ? fromUnixTime(parseInt(localStorageValue) / 1000) : null;

        if (!lastShowTime) {
            return true;
        }

        return !isValidDate(lastShowTime) || Date.now() >= addDays(lastShowTime, featureEnabled?.days || 90).getTime();
    }, [dispatch, featureEnabled, isAnyDialogVisible]);

    return needToShow ? (
        <WhatsNewDialog
            onShow={() => {
                isShown.current = true;
                onShow?.();
                dispatch(UserStorageActions.set(LOCAL_STORAGE_KEY, Date.now()));
            }}
            onClose={onClose}
            onClick={onAccept}
            closable
            primaryButtonText="Понятно"
            title="Файлы из почты"
            qaId="attachFromMail"
            text={
                <>
                    В этом разделе вложения из писем можно только просматривать и{'\u00A0'}скачивать. Удалить их можно только вместе с
                    письмом через раздел для{'\u00A0'}
                    <a
                        target="_blank"
                        href={getQuotaPromoUrl({ query: PaymentUTM.welcomeAttachesRepeating, hash: 'cleaner' })}
                        data-qa-id="cleanerLink"
                        rel="noreferrer"
                    >
                        очистки хранилища
                    </a>
                    {'\u00A0'}или в{'\u00A0'}
                    <a href={`https://${MAIL_HOST}/inbox/`} target="_blank" rel="noreferrer" data-qa-id="mailLink">
                        Почте Mail.ru
                    </a>
                </>
            }
            imageUrl={coverImage}
        />
    ) : null;
};
