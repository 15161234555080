import config from 'Cloud/config';
import { useSelector } from 'react-redux';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import type { TSpaceInfo, TUseSpaceHook } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress.types';
import opener from 'reactApp/utils/opener';

const MAIL_HOST = config.get('MAIL_HOST');

export const useSpace: TUseSpaceHook = () => {
    const { mail: mailUsed, cloud: cloudUsed } = useSelector(UserQuotaSelectors.getUsed);
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const remaining = useSelector(UserQuotaSelectors.getRemaining);
    const { used: usedPercents, mail: mailPercents } = useSelector(UserQuotaSelectors.getFilledPercents);

    const isOverquota = isAlmostFull || isFull;
    const isCloudOverquota = isOverquota && (cloudUsed?.original || 0) >= (mailUsed?.original || 0);

    const spaceList: TSpaceInfo[] = [
        ...(!IS_USER_WITH_ONLY_CLOUD
            ? [
                  {
                      id: 'mail',
                      text: `Почта ${mailUsed?.value}`,
                      hintText: 'Письма из Почты',
                      percents: mailPercents,
                      isOverquota: isOverquota && !isCloudOverquota,
                      onClick: (event) => {
                          event.stopPropagation();
                          opener(`https://${MAIL_HOST}/inbox`);
                      },
                  },
              ]
            : []),
        {
            id: 'cloud',
            text: `Облако ${cloudUsed?.value}`,
            hintText: 'Файлы из Облака',
            percents: usedPercents,
            isOverquota: isCloudOverquota,
        },
    ];

    return {
        spaceList,
        isOverquota,
        freeSpaceText: `Свободно ${remaining?.value}`,
        usedPercents,
    };
};
