import classNames from 'clsx';
import { ReactComponent as CheckIcon } from 'mrg-icons/src/mailru/status/done_big.svg';
import React, { type ReactElement, memo, useCallback } from 'react';
import type { IOptionItem } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './OptionsBlock.css';

interface IProps {
    header?: string;
    id: string;
    items: IOptionItem[];
    onClose?: () => void;
    headerComponent?: ReactElement;
    rightItem?: ReactElement;
}

const NO_CLOSE_ACTIONS_IDS = ['upload', 'more'];

export const OptionsBlock = memo<IProps>(({ id, header, items, headerComponent, onClose = noopVoid }): ReactElement => {
    const onItemClick = useCallback(
        (onClick = noopVoid, id: string) => {
            onClick();
            if (!NO_CLOSE_ACTIONS_IDS.includes(id)) {
                onClose();
            }
        },
        [onClose]
    );

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_${id}`]]: !!id,
            })}
            data-qa-options={id}
        >
            {header && (
                <div className={styles.header}>
                    {header}
                    {headerComponent}
                </div>
            )}
            {items.map((item) => (
                <div
                    key={item.id}
                    className={classNames(styles.item, { [styles.item_disabled]: item?.disabled })}
                    onClick={() => onItemClick(item.onClick, item.id)}
                    data-qa-option={item.id}
                >
                    {item.component}
                    {item.icon && <div className={styles.icon}>{item.icon}</div>}
                    <div className={styles.text}>{item.text}</div>
                    {item.rightItem && <div className={styles.rightItem}>{item.rightItem}</div>}
                    {item?.active && (
                        <div className={styles.check}>
                            <CheckIcon height={20} width={20} fill="currentColor" />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
});

OptionsBlock.displayName = 'OptionsBlock';
