import type { AxiosResponse } from 'lib/axios';
import { R7EditorConsistencySupportAPICall, WopiEditorConsistencySupportAPICall } from 'reactApp/api/EditorsConsistencySupportAPICall';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';

/**
 * Удаляет параметры из пути
 * @example /Новый%20документ.docx?weblink=W7PE/Ew7agqNHD -> /Новый%20документ.docx
 */
const getPathFromUrl = (url: string): string => {
    return url.replace(/(\?weblink=(\w+)\/(\w+))$/, '');
};

const getData = (itemPath: string): { home: string } | { public: string } => {
    const path = getPathFromUrl(itemPath);
    if (IS_PUBLIC) {
        return { public: path };
    }
    return { home: path };
};

export const callWopiEditorsConsistencySupportAPICall = (itemPath: string): Promise<AxiosResponse<{ has_sessions: boolean }>> =>
    new WopiEditorConsistencySupportAPICall().makeRequest(getData(itemPath));

export const callR7EditorsConsistencySupportAPICall = (itemPath: string): Promise<AxiosResponse<{ has_sessions: boolean }>> =>
    new R7EditorConsistencySupportAPICall().makeRequest(getData(itemPath));
