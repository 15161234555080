import { downloadDocumentWithoutRedirect } from 'Cloud/Application/Editor/MyOffice/helpers/downloadDocument';
import { xray } from 'lib/xray';
import { useEffect, useRef, useState } from 'react';
import { ebookDesktopConfig, ebookTouchConfig } from 'reactApp/appHelpers/featuresHelpers';
import { type CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getOpeningPlace } from 'reactApp/ui/ViewerEbook/ViewerEbook.helpers';
import { downloadDocument } from 'reactApp/utils/downloadDocumentHelpers';

enum ERadars {
    tryOpen = 'ebook_try_open',
    tryRequest = 'ebook_try_req',
    requestOk = 'ebook_req_ok',
    requestError = 'ebook_req_err',
}

export enum EPlaces {
    cloud = 'cloud',
    attach = 'attach',
    public = 'public',
}

export enum ESources {
    desktop = 'desktop',
    touch = 'touch',
}

const getRadars = (source: string, place: string, ext: string) =>
    ({
        tryOpenRadar: () =>
            xray.send(`${ERadars.tryOpen}_${source}_${place}`, {
                i: ext,
            }),
        tryRequestRadar: () =>
            xray.send(`${ERadars.tryRequest}_${source}_${place}`, {
                i: ext,
            }),
        requestOkRadar: () =>
            xray.send(`${ERadars.requestOk}_${source}_${place}`, {
                i: ext,
            }),
        requestErrorRadar: (error: unknown) =>
            xray.send(`${ERadars.requestError}_${source}_${place}`, {
                rlog: 'cloud_open_ebook',
                rlog_message: {
                    ext,
                    error,
                    source,
                    place,
                },
            }),
    } as const);

export const useDownloadEbook = ({
    file,
    source,
    itemStorage,
}: {
    file: CloudFile;
    source: ESources;
    itemStorage?: EStorageType | null;
}): { content: ArrayBuffer | undefined; isError: boolean } => {
    const [content, setContent] = useState<ArrayBuffer>();
    const [isError, setIsError] = useState(false);
    const isTimeoutRequest = useRef<boolean>(false);

    const place = getOpeningPlace(itemStorage);

    const { tryOpenRadar, tryRequestRadar, requestOkRadar, requestErrorRadar } = getRadars(source, place, file.ext);

    useEffect(() => {
        tryOpenRadar();

        const exts = source === ESources.desktop ? ebookDesktopConfig?.exts : ebookTouchConfig?.exts;

        if (!exts || !exts?.includes(file.ext)) {
            setIsError(true);
            return;
        }

        const fetchTimeout = (): Promise<void> => {
            const timeout = source === ESources.desktop ? ebookDesktopConfig?.timeout : ebookTouchConfig?.timeout;
            if (!timeout) {
                return Promise.reject('not found config');
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    isTimeoutRequest.current = true;
                    reject('timeout');
                }, timeout * 1000);
            });
        };

        const fetchContent = async (): Promise<{ content?: ArrayBuffer | null; error?: unknown }> => {
            tryRequestRadar();

            /**
             * Есть 3 типа возможных тут аттача, и тут все ок работает только для 1 = attach
             *
             * Для двух остальных clod, cloud_stock и выключил просмотр епабов тут.
             * Проблема с загрузкой документа, надо разбираться
             * reactApp/ui/ReactViewer/ViewerEbook/withRenderIsStock.tsx
             */
            const url = place === EPlaces.attach ? file?.url?.view : file?.url?.get;

            if (!url) {
                return { error: 'no_url' };
            }

            /** уточнил тип до ArrayBuffer, но внутри он и везде как string */
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return place === EPlaces.attach
                ? await downloadDocumentWithoutRedirect(url)
                : await downloadDocument(url, file.storage === EStorageType.stock);
        };

        const fetchContentWithTimeout = async (): Promise<void> => {
            try {
                const fetchResult = await Promise.race([fetchTimeout(), fetchContent()]);
                const { content, error } = fetchResult || {};

                if (content) {
                    requestOkRadar();
                    setContent(content as ArrayBuffer);
                    return;
                }

                if (error) {
                    requestErrorRadar(error);
                }

                setIsError(true);
            } catch (error) {
                requestErrorRadar(error);
                setIsError(true);
            }
        };

        fetchContentWithTimeout();
    }, []);

    return { content, isError };
};
