import { groupBy } from 'ramda';
import type { State } from 'reactApp/modules/fileHistory/fileHistory.types';
import type { RootState } from 'reactApp/store';
import type { LoadingState } from 'reactApp/types/commonStates';
import type { VirtualListDivider } from 'reactApp/ui/VirtualList/VirtualList.types';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { createSelector } from 'reselect';

const getFileHistoryState = (state: RootState): State => state.fileHistory as State;

const getLoadingState = createSelector(
    getFileHistoryState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        hasError: state.hasError,
    })
);

const getCurrentItem = createSelector(getFileHistoryState, (state: State) => state.id);

const getEntry = createSelector(
    getFileHistoryState,
    (state, itemId) => itemId,
    (state: State, itemId: string) => {
        return state.list[itemId];
    }
);

const getIds = createSelector(
    getFileHistoryState,
    (state, itemId) => itemId,
    (state: State, itemId: string) => {
        const { id, list } = state;

        if (id !== itemId) {
            return [];
        }

        const byDate = groupBy((item: string) => {
            const date = list[item].date;

            return formatFileHistoryDate(date);
        });

        const ids = Object.keys(list);

        const groupedItems = byDate(ids);

        const newIdsList: (string | VirtualListDivider)[] = [];

        Object.keys(groupedItems).forEach((period) => {
            newIdsList.push({
                divider: true,
                text: period,
            });

            groupedItems[period].forEach((item) => newIdsList.push(item));
        });

        return newIdsList;
    }
);

export const FileHistorySelectors = {
    getLoadingState,
    getIds,
    getEntry,
    getCurrentItem,
};
