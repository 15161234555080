import { ReactComponent as AdvertisingIcon } from 'img/sass-landing/icons/advertising.svg';
import { ReactComponent as MoreIcon } from 'img/sass-landing/icons/more.svg';
import { ReactComponent as StarsIcon } from 'img/sass-landing/icons/stars.svg';
import React from 'react';
import type { IFeatureData } from 'reactApp/constants/products/features';
import { SASS_DOCUMENT_IDS } from 'reactApp/sections/SassLanding/SassLanding.data';

const baseQuotaProFeatures: IFeatureData[] = [
    {
        text: 'Нейросеть для бизнеса',
        textJsx: <>Нейросеть для бизнеса</>,
        icon: <StarsIcon width={28} height={28} />,
        key: 'stars',
    },
    {
        text: 'Никакой рекламы',
        textJsx: <>Никакой рекламы</>,
        icon: <AdvertisingIcon width={28} height={28} />,
        key: 'advertising',
    },
    {
        text: 'И другие возможности',
        textJsx: (
            <>
                И другие{'\u00A0'}
                <a
                    href={''}
                    onClick={(event: React.SyntheticEvent) => {
                        event.preventDefault();
                        const benefitElement = document.getElementById(`#${SASS_DOCUMENT_IDS.benefitTable}`);
                        benefitElement?.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }}
                >
                    возможности
                </a>
            </>
        ),
        icon: <MoreIcon width={28} height={28} />,
        key: 'benefits',
    },
];
const baseQuotaProSubscriptionsFeatures: IFeatureData[] = [
    {
        text: 'Сервисы Mail без рекламы',
        key: 'ad',
    },
    {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
        key: 'upload',
    },
    {
        text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
        key: 'sending',
    },
    {
        text: 'Нейросеть для бизнеса',
        key: 'aiForBusiness',
    },
    {
        text: 'Папки в заметках',
        key: 'foldersInNotes',
    },
    {
        text: 'Приоритетная поддержка',
        key: 'support',
    },
];

export const quotaProFeatures: Record<string, IFeatureData[]> = {
    quotaProBaseLanding: baseQuotaProFeatures,
    quotaProBaseSubscriptions: baseQuotaProSubscriptionsFeatures,
};
