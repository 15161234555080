// tempexp_SRCH-70119-full-file
import React, { type FC, type RefObject } from 'react';
import { Portal } from 'react-portal';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import { type SearchTooltipTypes, searchTooltipData } from './SearchTooltip.data';

interface Props {
    targetRef: RefObject<HTMLElement> | null;
    type: SearchTooltipTypes;
    onClose: () => void;
}

export const SearchTooltip: FC<Props> = ({ targetRef, type, onClose }) => {
    const data = searchTooltipData[type];
    return (
        <Portal>
            <FloatingTooltip
                target={targetRef}
                placement={ETooltipPlacement.bottomEnd}
                title={data.title}
                text={data.description}
                onClose={onClose}
                closeOnScroll
                qaId="tooltip-search"
            />
        </Portal>
    );
};

SearchTooltip.displayName = 'SearchTooltip';
