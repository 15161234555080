export enum AnalyticEventNames {
    /**
     * @description Посещение лендинга
     */
    QUOTA_LANDING_SHOWN = 'QUOTA_LANDING_SHOWN',
    /**
     * @description Посещение мобильного лендинга с тарифами
     */
    MOBILE_LANDING_SHOWN = 'MOBILE_LANDING_SHOWN',
    /**
     * @description Показ на экране блока тарифов
     */
    TARIFFS_SHOWN = 'TARIFFS_SHOWN',
    /**
     * @description Показ на экране блока тарифа (десктоп)
     */
    TARIFF_SHOWN = 'TARIFF_SHOWN',
    /**
     * @description Показ на экране блока тарифа (мобилка)
     */
    MOBILE_TARIFF_SHOWN = 'MOBILE_TARIFF_SHOWN',
    /**
     * @description  Клик по ссылке возможности на карточке тарифа
     */
    TARIFF_ADVANTAGES_CLICK = 'TARIFF_ADVANTAGES_CLICK',
    /**
     * @description Показ модального окна с возможностями тарифа
     */
    MODAL_TARIFF_ADVANTAGES_SHOW = 'MODAL_TARIFF_ADVANTAGES_SHOW',
    /**
     * @description Клик по конкрентному  тарифу
     */
    TARIFF_CLICK = 'TARIFF_CLICK',
    /**
     * @description Показа фастчекаут
     */
    FAST_CHECKOUT_SHOWN = 'FAST_CHECKOUT_SHOWN',
    /**
     * @description Клик по покупке тарифа в чекаут окне
     */
    TARIFF_BUY = 'TARIFF_BUY',
    /**
     * @description Клик по покупке тарифа в окне оплаты триала
     */
    TRIAL_TARIFF_BUY = 'TRIAL_TARIFF_BUY',

    /** Модалка "Файл слишком тяжёлый": открытие */
    BIG_FILE_DIALOG_SHOW = 'BIG_FILE_DIALOG_SHOW',
    /** Модалка "Файл слишком тяжёлый": открытие окна покупки подписки */
    BIG_FILE_DIALOG_PAYMENT_SHOW = 'BIG_FILE_DIALOG_PAYMENT_SHOW',
    /** Модалка "Файл слишком тяжёлый": покупка подписки */
    BIG_FILE_DIALOG_PAYMENT_BUY = 'BIG_FILE_DIALOG_PAYMENT_BUY',
    // Промо триала Mail Space в просмотрщике: показ
    MAIL_SPACE_TRIAL_PROMO_SHOW = 'MAIL_SPACE_TRIAL_PROMO_SHOW',
    // Промо триала Mail Space в просмотрщике: клик
    MAIL_SPACE_TRIAL_PROMO_CLICK = 'MAIL_SPACE_TRIAL_PROMO_CLICK',
    // Промо триала Mail Space в просмотрщике: закрытие
    MAIL_SPACE_TRIAL_PROMO_CLOSE = 'MAIL_SPACE_TRIAL_PROMO_CLOSE',
    /** Модалка почтовых аттачей в облаке: показ */
    MAIL_POPUP_SHOW = 'MAIL_POPUP_SHOW',
    /** Модалка почтовых аттачей в облаке: нажатие на кнопку */
    MAIL_POPUP_CLICK = 'MAIL_POPUP_CLICK',
    /** Модалка почтовых аттачей в облаке: показ оплаты */
    MAIL_POPUP_PAYMENT_SHOW = 'MAIL_POPUP_PAYMENT_SHOW',
    /** Модалка почтовых аттачей в облаке: успешная покупка подписки */
    MAIL_POPUP_PAYMENT_SUCCESS = 'MAIL_POPUP_PAYMENT_SUCCESS',
    /** Модалка почтовых аттачей в облаке: ошибка покупка подписки */
    MAIL_POPUP_PAYMENT_FAILURE = 'MAIL_POPUP_PAYMENT_FAILURE',

    /** Просмотршик файлов. Кнопка закрыть. Клик */
    VIEWER_CLOSE = 'VIEWER_CLOSE',

    /** Кнопка отключения рекламы: показ */
    DISABLE_BUTTON_SHOW = 'DISABLE_BUTTON_SHOW',
    /** Кнопка отключения рекламы: клик */
    DISABLE_BUTTON_CLICK = 'DISABLE_BUTTON_CLICK',
    /** Кнопка отключения рекламы: открытие окна оплаты */
    DISABLE_BUTTON_SHOW_CHECKOUT = 'DISABLE_BUTTON_SHOW_CHECKOUT',
    /**  Кнопка отключения рекламы: оплата */
    DISABLE_BUTTON_PAYMENT = 'DISABLE_BUTTON_PAYMENT',

    /** Промо триала в сайдбаре: показ */
    SIDEBAR_TRIAL_PROMO_SHOW = 'SIDEBAR_TRIAL_PROMO_SHOW',
    /** Промо триала в сайдбаре: закрытие */
    SIDEBAR_TRIAL_PROMO_CLOSE = 'SIDEBAR_TRIAL_PROMO_CLOSE',
    /** Промо триала в сайдбаре: клик */
    SIDEBAR_TRIAL_PROMO_CLICK = 'SIDEBAR_TRIAL_PROMO_CLICK',
    /** Промо триала в сайдбаре: показ */
    SIDEBAR_TRIAL_BANNER_SHOW = 'SIDEBAR_TRIAL_BANNER_SHOW',
    /** Промо триала в сайдбаре: клик */
    SIDEBAR_TRIAL_BANNER_CLICK = 'SIDEBAR_TRIAL_BANNER_CLICK',
    /** Тултип виджета квот: показ */
    QUOTA_TOOLTIP_SHOW = 'QUOTA_TOOLTIP_SHOW',
    /** Тултип виджета квот: закрытие */
    QUOTA_TOOLTIP_CLOSE = 'QUOTA_TOOLTIP_CLOSE',
    /** Кнопка виджета квот со скидкой: показ */
    QUOTA_BUTTON_SHOW = 'QUOTA_BUTTON_SHOW',
    /** Кнопка виджета квот со скидкой: нажатие */
    QUOTA_BUTTON_CLICK = 'QUOTA_BUTTON_CLICK',

    /** Показ фастчекаута (BUY iframe) */
    BUY_FAST_CHECKOUT_VIEW = 'BUY_FAST_CHECKOUT_VIEW',
    /** Нажал кнопку купить на странице фастчекаута (BUY iframe) */
    BUY_FAST_CHECKOUT_CLICK_PAY = 'BUY_FAST_CHECKOUT_CLICK_PAY',

    // tempexp_17127-start
    /** Окрытие модального окна (buy-disable-ads iframe) */
    BUY_DISABLE_ADS_MODAL_SHOWN = 'BUY_DISABLE_ADS_MODAL_SHOWN',
    /** Смена периода год / месяц (buy-disable-ads iframe) */
    BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE = 'BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE',
    /** Клик по тарифу (buy-disable-ads iframe) */
    BUY_DISABLE_ADS_MODAL_TARIFF_CLICK = 'BUY_DISABLE_ADS_MODAL_TARIFF_CLICK',
    // tempexp_17127-end

    /** Клик по кнопке шаринга папки (соответствует datalist_item_click_publish) */
    DATALIST_ITEM_CLICK_PUBLISH = 'DATALIST_ITEM_CLICK_PUBLISH',

    /**  Тултип CLOUDWEB-17237: Показ тултипа с "попробовать за 1 р" */
    NICE_LINK_TOOLTIP_SHOW = 'NICE_LINK_TOOLTIP_SHOW',
    /**  Тултип CLOUDWEB-17237: Клик на крестик */
    NICE_LINK_TOOLTIP_CLOSE = 'NICE_LINK_TOOLTIP_CLOSE',
    /**  Тултип CLOUDWEB-17237: Клик на "попробовать за 1 р" */
    NICE_LINK_TOOLTIP_CLICK = 'NICE_LINK_TOOLTIP_CLICK',
    /**  Тултип CLOUDWEB-17237: Показ тултипа "спасибо за интерес" */
    NICE_LINK_THANKS_TOOLTIP_SHOW = 'NICE_LINK_THANKS_TOOLTIP_SHOW',
    /**  Тултип CLOUDWEB-17237: Показ тултипа с "попробовать за 1 р" */
    NICE_LINK_THANKS_TOOLTIP_CLICK = 'NICE_LINK_THANKS_TOOLTIP_CLICK',
    // tempexp_17086-start
    /** Показа сплэша выбора дефолтного тарифа */
    WELCOME_DEFAULT_TARIFFS_SHOW = 'WELCOME_DEFAULT_TARIFFS_SHOW',
    /** Клик по бесплатному тарифу на сплэше выбоа дефолтного тарифа */
    WELCOME_DEFAULT_TARIFFS_CLICK_FREE = 'WELCOME_DEFAULT_TARIFFS_CLICK_FREE',
    // tempexp_17086-end

    /** Модалка оверквоты: показ */
    OVERQUOTA_POPUP_SHOW = 'OVERQUOTA_POPUP_SHOW',
    /** Модалка оверквоты: клик по тарифу */
    OVERQUOTA_POPUP_CLICK = 'OVERQUOTA_POPUP_CLICK',
    /** Показ блока квоты когда юзер в оверквоте */
    QUOTA_BLOCK_OVER_VIEW = 'QUOTA_BLOCK_OVER_VIEW',
    /** Клик по кнопке Увеличить место когда юзер в оверквоте */
    QUOTA_BLOCK_OVER_CLICK = 'QUOTA_BLOCK_OVER_CLICK',

    // tempexp_17125-start
    /** Показ попапа бесплатным оверквотерам при заходе */
    BLOCK_OVERQUOTA_VIEW = 'BLOCK_OVERQUOTA_VIEW',
    /** Клик по кнопке увеличить место в попапе для бесплатных оверквотеров */
    BLOCK_OVERQUOTA_CLICK = 'BLOCK_OVERQUOTA_CLICK',
    /** Показ попапа блокировки просмотра файлов для бесплатных оверквотеров */
    BLOCK_OVERQUOTA_FILES_VIEW = 'BLOCK_OVERQUOTA_FILES_VIEW',
    /** Клик по кнопке увеличить место в попапе блокировки просмотра файлов для бесплатных оверквотеров */
    BLOCK_OVERQUOTA_FILES_CLICK = 'BLOCK_OVERQUOTA_FILES_CLICK',
    // tempexp_17125-end

    // tempexp_17324-start
    MODAL_CHOOSE_TARIFF_SHOW = 'MODAL_CHOOSE_TARIFF_SHOW',
    MODAL_CHOOSE_TARIFF_CONTINUE = 'MODAL_CHOOSE_TARIFF_CONTINUE',
    // tempexp_17324-end

    // tempexp_17509-start
    /** Показ блока "Бонус: 2 месяца подписки" */
    LITRES_PROMO_SHOW = 'LITRES_PROMO_SHOW',
    /** Показ тултипа Литрес */
    LITRES_PROMO_TOOLTIP_SHOW = 'LITRES_PROMO_TOOLTIP_SHOW',
    /** Клик подробнее на тултипе Литрес */
    LITRES_PROMO_TOOLTIP_CLICK = 'LITRES_PROMO_TOOLTIP_CLICK',
    /** Клик подробнее на тултипе Литрес */
    LITRES_PROMO_TOOLTIP_CLOSE = 'LITRES_PROMO_TOOLTIP_CLOSE',
    /** Показ модального окна "Промокод Литрес уже у вас в почте" */
    LITRES_PROMO_SUCCESS_SHOW = 'LITRES_PROMO_SUCCESS_SHOW',
    /** Клик на "Проверить почту" модального окна "Промокод Литрес уже у вас в почте" */
    LITRES_PROMO_SUCCESS_CLICK_CHECK = 'LITRES_PROMO_SUCCESS_CLICK_CHECK',
    /** Клик на "Активирую позже" модального окна "Промокод Литрес уже у вас в почте" */
    LITRES_PROMO_SUCCESS_CLICK_LATER = 'LITRES_PROMO_SUCCESS_CLICK_LATER',
    /** Закрытие модального окна "Промокод Литрес уже у вас в почте" */
    LITRES_PROMO_SUCCESS_CLOSE = 'LITRES_PROMO_SUCCESS_CLOSE',
    // tempexp_17509-end

    // tempexp_17532-start
    /** Показ модального окна с промо Литрес в Облаке */
    LITRES_MODAL_CLOUD_SHOW = 'LITRES_MODAL_CLOUD_SHOW',
    /** Клик на "Выбрать тариф" модального окна с промо Литрес в Облаке */
    LITRES_MODAL_CLOUD_CLICK = 'LITRES_MODAL_CLOUD_CLICK',
    /** Клик на "Неинтересно" модального окна с промо Литрес в Облаке */
    LITRES_MODAL_CLOUD_CANCEL = 'LITRES_MODAL_CLOUD_CANCEL',
    /** Закрытие модального окна с промо Литрес в Облаке */
    LITRES_MODAL_CLOUD_CLOSE = 'LITRES_MODAL_CLOUD_CLOSE',
    // tempexp_17532-end

    /** Показ приветственного попапа с подпиской mailspace*/
    POPUP_BANNER_OFFER_SHOW = 'POPUP_OFFER_SHOW',
    /** Клик на CTA в приветственном попапе с подпиской mailspace*/
    POPUP_BANNER_OFFER_CLICK = 'POPUP_OFFER_CLICK',
    /** Закрытие приветственного попапа с подпиской mailspace*/
    POPUP_BANNER_OFFER_CLOSE = 'POPUP_OFFER_CLOSE',
    /** Покупка из приветственного попапа с подпиской mailspace*/
    POPUP_BANNER_OFFER_BUY = 'POPUP_OFFER_BUY',

    // tempexp_17324-start

    /** Увидел тултип "Выбирайте оформление для папок */
    TOOLTIP_BRAND_PAGE_SHOW = 'TOOLTIP_BRAND_PAGE_SHOW',
    /** Клик на "Попробовать" в тултипе "Выбирайте оформление для папок" */
    TOOLTIP_BRAND_PAGE_CLICK = 'TOOLTIP_BRAND_PAGE_CLICK',
    /** Закрытие тултипа "Выбирайте оформление для папок" */
    TOOLTIP_BRAND_PAGE_CLOSE = 'TOOLTIP_BRAND_PAGE_CLOSE',

    /** Увидели Три точки (Хомяк) */
    BREADCRUMBS_SHOW = 'BREADCRUMBS_SHOW',

    /** Увидели "Выбрать оформление" после нажатия на три точки */
    TOOLBAR_ITEM_SHOW_BRAND_PAGE = 'TOOLBAR_ITEM_SHOW_BRAND_PAGE',
    /** Кликнули "Выбрать оформление" после нажатия на три точки */
    TOOLBAR_ITEM_CLICK_BRAND_PAGE = 'TOOLBAR_ITEM_CLICK_BRAND_PAGE',
    /** Настройка совместого доступа - клик */
    BREADCRUMBS_PUBLISH_CLICK = 'BREADCRUMBS_PUBLISH_CLICK',

    /** Настройка совместого доступа. Увидели пункт Оформление для папки */
    SHARING_BRAND_PAGE_SHOW = 'SHARING_BRAND_PAGE_SHOW',
    /** Настройка совместого доступа. Кликнули "Выбрать" в пункте Оформление для папки */
    SHARING_BRAND_PAGE_CLICK = 'SHARING_BRAND_PAGE_CLICK',

    /** Настройка совместого доступа. Увидели тултип */
    SHARING_TOOLTIP_BRAND_PAGE_SHOW = 'SHARING_TOOLTIP_BRAND_PAGE_SHOW',
    /** Настройка совместого доступа. Закрыли тултип */
    SHARING_TOOLTIP_BRAND_PAGE_CLOSE = 'SHARING_TOOLTIP_BRAND_PAGE_CLOSE',

    /** Увидели Выбрать оформление */
    TOOLBAR_BRAND_PAGE_SHOW = 'TOOLBAR_BRAND_PAGE_SHOW',
    /** Нажали на Выбрать оформление */
    TOOLBAR_BRAND_PAGE_CLICK = 'TOOLBAR_BRAND_PAGE_CLICK',

    /** Попали на страницу брендирования */
    BRAND_PAGE_VIEW = 'BRAND_PAGE_VIEW',
    /** Попали на страницу брендирования */
    BRAND_PAGE_SAVE = 'BRAND_PAGE_SAVE',

    /** Видим модальное окно с тарифами Брендирования */
    BRAND_PAGE_TARIFF_PLAN_CHOOSE = 'BRAND_PAGE_TARIFF_PLAN_CHOOSE',
    /** Клик на тариф в модальном окне Брендирования */
    BRAND_PAGE_TARIFF_CLICK = 'BRAND_PAGE_TARIFF_CLICK',
    /** Нажимаем Свернуть на страницу брендирования */
    BRAND_PAGE_HIDE = 'BRAND_PAGE_HIDE',

    /** Видим модальное окно "Сохранить измененения?" */
    BRAND_PAGE_EXIT_SHOW = 'BRAND_PAGE_EXIT_SHOW',
    /** модальное окно "Сохранить измененения?" - Клик "Выбрать тариф" */
    BRAND_PAGE_EXIT_CHOOSE = 'BRAND_PAGE_EXIT_CHOOSE',
    /** модальное окно "Сохранить измененения?" - Клик "Не сохранять" */
    BRAND_PAGE_EXIT_TRUE = 'BRAND_PAGE_EXIT_TRUE',

    // tempexp_17324-end

    /** Показ приветственного баннера с увеличением квоты */
    WELCOME_TRIAL_BANNER_SHOW = 'WELCOME_TRIAL_SHOW',
    /** Клик на CTA в пприветственном баннере с увеличением квоты */
    WELCOME_TRIAL_BANNER_CLICK = 'WELCOME_TRIAL_CLICK',
    /** Покупка из приветственного баннера с увеличением квоты*/
    WELCOME_TRIAL_BANNER_BUY = 'WELCOME_TRIAL_BUY',
}
