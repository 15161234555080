import { setDownloadEbookClick } from 'reactApp/modules/ebook/ebook.module';
import { isEBook } from 'reactApp/modules/file/isEBook';
import { reDownloadController, ReDownloadEntry } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { selectOne } from 'reactApp/modules/selections/selections.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isDownloadByUrl } from 'reactApp/modules/userQuotaCleaner/helpers/isDownloadByUrl';
import type { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { store as reduxStore } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';

// Иначе падают страницы ошибок
const {
    // @ts-ignore
    download,
    // @ts-ignore
    downloadItem: downloadItemOld,
    // @ts-ignore
    downloadItems,
} = typeof window !== 'undefined' ? require('Cloud/Application/Download') : {};

export const downloadItem = ({ itemOrId, useDownloadUrl = false, storage = null, forceDownload = null, fromViewer = false }: any) => {
    const isQuotaCleaner = storage === EStorageType.quotaCleaner;
    const isDownloadUrl = useDownloadUrl || (isQuotaCleaner && isDownloadByUrl(itemOrId as QuotaCleanerItem));

    const isEbookItem = isEBook(itemOrId);

    if (isEbookItem) {
        sendXray(['ebook', 'download', fromViewer ? 'viewer' : 'list', itemOrId?.ext]);

        setTimeout(() => {
            reduxStore.dispatch(setDownloadEbookClick(true));
            reduxStore.dispatch(selectOne({ selectedIdx: itemOrId?.id, storage }));
        }, 1000);
    }

    /* tempexp_15344-next-line */
    reDownloadController.setFutureParams(ReDownloadEntry.AppHelpers, {
        itemOrId,
        useDownloadUrl,
        storage,
        forceDownload,
        fromViewer,
    });

    if (itemOrId.isFolder) {
        downloadItems(itemOrId.name, itemOrId, null);
    } else {
        download(itemOrId, forceDownload, isDownloadUrl, storage, fromViewer);
    }
};
export const downloadItemsZip = ({ items, name = 'archive', forceDownload = null }) => downloadItems(name, items, forceDownload);
export const downloadByUrl = (url, name, storage) => downloadItemOld(url, name, storage);
