import { getFeature } from 'Cloud/Application/FeaturesEs6';

export type DisableButtonTariffs = {
    title: string;
    tariffs: string;
    activeTab: string;
};

export const disableButtonTariffs: DisableButtonTariffs | undefined = getFeature('disable-button-tariffs');
export const disableButtonText: string | undefined = getFeature('disable-button-text');
