import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import { registerExperiment } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';

/** CLOUDWEB-17001, CLOUDWEB-17234: Варианты положения рекламаного сайдбара в просмотрщике аттачей */
export enum AttachesSidebarAB {
    /** Сайдбар 160 справа от просмотрщика */
    RightSmall = 1,
    /** Сайдбар 240 справа от просмотрщика */
    RightBig = 2,
    /** Сайдбар 160 слева от просмотрщика */
    LeftSmall = 3,
    /** Сайдбар 240 слева от просмотрщика */
    LeftBig = 4,
}

/** CLOUDWEB-17234: адаптивность кнопок хедера просмотрщика аттачей через JS */
export const attachesViewerHeaderJsAdaptivity = isFeature('ab-attach-viewer-header-js-adaptivity');

/** CLOUDWEB-17001, CLOUDWEB-17234: положение рекламаного сайдбара в просмотрщике аттачей */
export const attachesSidebarVersion = getFeature('ab-attach-sidebar') as AttachesSidebarAB;

/** CLOUDWEB-16781, CLOUDWEB-17234: АБ с заменой нижнего баннера в просмотрщике на почтовый сайдбар 160x800 */
export const isAttachesSidebar = isFeature('ab-attach-sidebar') && Object.values(AttachesSidebarAB).includes(attachesSidebarVersion);

/** CLOUDWEB-17234: показывать кнопку "Отключить рекламу" в просмотрщике аттачей */
export const isAttachesNoadsButtonEnabled = !!getFeature('ab-attach-noads-btn-enabled');

/** CLOUDWEB-17234: показ окна оплаты вместо новой вкладки при нажатии на кнопку "Отключить рекламу" */
export const isAttachesNoadsButtonPayment = !!getFeature('ab-attach-noads-btn-payment');

/** CLOUDWEB-17234: product id окна оплаты на кнопке "Отключить рекламу" */
export const attachesNoadsButtonProductId = getFeature('ab-attach-noads-btn-product-id');

/** CLOUDWEB-17234: название группы экспа */
const attachesSidebarGroupValue = getFeature('ab-attach-sidebar-group') as string | undefined;

registerExperiment(
    'attach-sidebar',
    attachesSidebarGroupValue,
    [
        AnalyticEventNames.VIEWER_CLOSE,
        AnalyticEventNames.DISABLE_BUTTON_SHOW,
        AnalyticEventNames.DISABLE_BUTTON_CLICK,
        AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT,
        AnalyticEventNames.DISABLE_BUTTON_PAYMENT,
    ],
    {
        sendXrayArgs: {
            [AnalyticEventNames.DISABLE_BUTTON_SHOW]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_CLICK]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT]: ({ id }) => ({
                iData: id,
            }),
            [AnalyticEventNames.DISABLE_BUTTON_PAYMENT]: ({ id }) => ({
                iData: id,
            }),
        },
    }
);
