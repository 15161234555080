import React, { type FC, useEffect } from 'react';

import { BaseConfirmDialog } from '../BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from '../BaseConfirmDialog/BaseConformationDialog.types';
import styles from './CreateCopyOfNonEditableFileModal.css';
import { ContentComponent } from './helper';
import { sendTechAnalytics, TechAnalyticsType } from './sendTechAnalytics';
import type { CreateCopyOfNonEditableFileModalProps } from './types';
import { useCreateCopyOfFile } from './useCreateCopyOfFile';

export const CreateCopyOfNonEditableFileModal: FC<CreateCopyOfNonEditableFileModalProps> = ({
    file,
    editingNoneEditableFormats,
    fromSharePopup = false,
    onClose,
}) => {
    const convertExtension = editingNoneEditableFormats.ext.toUpperCase();
    const convertedStorage = editingNoneEditableFormats.storage;
    const isSharedFile = !!file.weblink || fromSharePopup;

    const techAnalytics = sendTechAnalytics(file.ext, convertedStorage);

    const [loading, handleCreateCopyOfFile] = useCreateCopyOfFile({
        isSharedFile,
        file,
        editingNoneEditableFormats,
        fromSharePopup,
        onClose,
        techAnalytics,
    });

    useEffect(() => {
        techAnalytics(TechAnalyticsType.showPopup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BaseConfirmDialog
            onClose={onClose}
            renderHeader={() => (
                <div className={styles.header}>{isSharedFile ? 'Создайте копию для\u00A0редактирования' : 'Создайте копию документа'}</div>
            )}
            renderContent={() => (
                <ContentComponent
                    fromSharePopup={fromSharePopup}
                    isLoading={loading}
                    isSharedFile={!!file.weblink}
                    convertExtension={convertExtension}
                />
            )}
            buttons={[EButtonType.accept]}
            disableAcceptButton={loading}
            successButtonText="Создать копию"
            onSuccess={handleCreateCopyOfFile}
        />
    );
};
