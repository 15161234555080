import { Caption, Spacing, Text } from '@vkontakte/vkui';
import trialMonthCover from 'img/subscriptions/trial-month.jpg';
import trialTwoMonthsCover from 'img/subscriptions/trial-two-months.jpg';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cancelRenewTrialPeriod } from 'reactApp/appHelpers/featuresHelpers';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { getPeriodName } from 'reactApp/utils/Period';

import styles from './CancelAutoRenewTrialModal.css';
import type { CancelAutoRenewTrialModalProps } from './CancelAutoRenewTrialModal.types';

export const CancelAutoRenewTrialModal = memo<CancelAutoRenewTrialModalProps>(({ onClose, subscription }): ReactElement => {
    const dispatch = useDispatch();
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showTrialSubs);
    }, []);

    const content = useMemo(
        (): ReactElement => (
            <>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Сохраните {subscription.space.value} и все преимущества Mail Space на&nbsp;{getPeriodName(cancelRenewTrialPeriod)}{' '}
                    бесплатно
                </Text>
                <Spacing size={24} />
            </>
        ),
        [subscription]
    );

    const footerText = useMemo(() => {
        return (
            <>
                <Caption className={styles.caption}>После бесплатного периода подписка продлится автоматически</Caption>
                <Spacing size={12} />
            </>
        );
    }, []);

    const handleSaveSubscriptionTrial = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.trialCancelSubsSave);
        dispatch(BuySubscriptionActions.cancelAutorenewAddTrial({ subscriptionId: subscription.id, period: cancelRenewTrialPeriod }));
    }, [dispatch, subscription]);

    const handleCancelAutorenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.trialCancelSubsCancel);
        dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id }));
    }, [dispatch, subscription, sendAnalytics]);

    return (
        <WhatsNewDialog
            onClose={onClose}
            onClick={handleSaveSubscriptionTrial}
            onSecondary={handleCancelAutorenew}
            primaryButtonText={`Сохранить ${subscription.space.value}`}
            secondaryButtonText="Отключить"
            title={`${getPeriodName(cancelRenewTrialPeriod)} подписки — бесплатно`}
            content={content}
            imageUrl={cancelRenewTrialPeriod === '1m' ? trialMonthCover : trialTwoMonthsCover}
            footerText={footerText}
            footerTextBelowButtons
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

CancelAutoRenewTrialModal.displayName = 'CancelAutoRenewTrialModal';
