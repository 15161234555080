import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { RootState } from 'reactApp/store';
import type { Product, Tariff } from 'reactApp/types/Billing';

export const useTariff = (queryProductId?: string): { product?: Product; tariff?: Tariff } => {
    // Подбираем тариф, как для обычного юзера
    const productUsual = useSelector((state: RootState) => ProductsSelectors.getBuyFrameProduct(state, queryProductId));

    // Подбираем тариф для оверквотника
    const spaceInfo = useSelector(UserSelectors.getCloudSpace);
    const { isFull: isOverQuota } = spaceInfo;
    const overQuota = spaceInfo.over.original;
    const productOverquota = useSelector((state: RootState) =>
        ProductsSelectors.getProductAndTariffByQuota(state, overQuota, EProductPeriod.year, false)
    );

    const isFitsInUsual = !!productUsual && productUsual.product.space.original > overQuota;

    // Если оверквотник не помещается в тариф по умолчанию и мы нашли тариф, который подойдёт, то предлагаем его
    if (isOverQuota && !isFitsInUsual && productOverquota?.product && productOverquota?.tariff) {
        return productOverquota;
    }

    return productUsual || {};
};
