/* eslint-disable max-lines-per-function */
import { Icon16DropdownOutline, Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { useMergeRefs } from 'reactApp/hooks/useMergeRefs';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EActions } from 'reactApp/modules/storage/storage.types';
import { ChangePublicDesignTooltip } from 'reactApp/ui/ChangePublicDesignTooltip/ChangePublicDesignTooltip';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { RegisterCloudTooltip } from 'reactApp/ui/RegisterCloudTooltip/RegisterCloudTooltip';
import { type IToolbarItemProps, type TToolbarDropdownItem, EToolbarItemTooltip } from 'reactApp/ui/Toolbar/Toolbar.types';
import { findDropdownPosition } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem.utils';
import { noopVoid } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './ToolbarItem.css';

export const ToolbarItem = memo(
    forwardRef<HTMLDivElement, IToolbarItemProps>(
        (
            {
                className,
                id,
                promoId,
                text = '',
                hint = '',
                collapse = false,
                icon,
                list,
                withoutDropdownIcon,
                dropdownTheme,
                dropdownPosition,
                openDropdownByArrow = false,
                onClick = noopVoid,
                parentRef,
                mod,
                tooltipId,
                maxDropdownListHeight,
                isDropdownListOpen,
                setIsDropdownListOpen,
                renderDropdownListItem,
                color,
                disabled,
            }: IToolbarItemProps,
            ref
        ): ReactElement => {
            const isCreateOrUpload = id === EActions.upload || id === EActions.create;
            const isSort = id === 'sort';
            const rootRef = useRef<HTMLDivElement>(null);

            const storage = useSelector(getCurrentStorage);
            const { isInlineIntegration } = getStorage(storage);

            const [isOpen, setIsOpen] = useState<boolean>(false);

            const dispatch = useDispatch();
            const isAnyDialogVisible = useSelector(isDialogVisible);
            // tempexp_17340-next-line
            const changeDesignPromo = useSelector(PromoSelectors.getPromo(EPromoType.changePublicDesign));

            const isDropdownOpen = useMemo(() => isDropdownListOpen ?? isOpen, [isOpen, isDropdownListOpen]);
            const toggleIsDropdownOpen = useCallback(
                (majorIsDropdownOpen?: boolean) => {
                    if (setIsDropdownListOpen) {
                        const result = majorIsDropdownOpen ?? !isDropdownOpen;
                        setIsDropdownListOpen(result);
                        return;
                    }

                    const result = majorIsDropdownOpen ?? !isDropdownOpen;
                    setIsOpen(result);
                },
                [isDropdownListOpen, setIsDropdownListOpen, isOpen, setIsOpen]
            );

            const tooltip = useMemo(() => {
                if (tooltipId === EToolbarItemTooltip.publicCreateCloud) {
                    return <RegisterCloudTooltip targetRef={rootRef} closeOnScroll />;
                }
                // tempexp_17340-start
                if (!isAnyDialogVisible && tooltipId === EToolbarItemTooltip.changeDesign) {
                    return (
                        <ChangePublicDesignTooltip ref={rootRef} onClose={changeDesignPromo?.onClose} onShow={changeDesignPromo?.onShow} />
                    );
                }
                // tempexp_17340-end
                return null;
            }, [tooltipId, rootRef?.current, changeDesignPromo, isAnyDialogVisible]);

            const renderDropdownItem = useCallback(
                ({ text, active, icon, mod, hotkeyLabel, asc, withStatus }: TToolbarDropdownItem) => (
                    <DropdownItemAction
                        text={text}
                        active={active}
                        icon={icon}
                        mod={mod}
                        hotkey={hotkeyLabel}
                        asc={asc}
                        withStatus={withStatus}
                        theme={dropdownTheme}
                        isSort={isSort}
                    />
                ),
                [isSort, dropdownTheme]
            );

            const content = useMemo(
                () => (
                    <div className={styles.content}>
                        {icon && <div className={styles.icon}>{icon}</div>}
                        {text && !collapse && (
                            <div className={classNames(styles.text, { [styles.textInlineIntegration]: isInlineIntegration })}>{text}</div>
                        )}
                    </div>
                ),
                [collapse, icon, text]
            );

            const closeDropdown = useCallback(() => {
                toggleIsDropdownOpen(false);
            }, [toggleIsDropdownOpen]);

            const onItemClick = useCallback(
                (e) => {
                    if (list && !openDropdownByArrow) {
                        toggleIsDropdownOpen();
                    }

                    const onSuccess = (params) => {
                        if (params.source === 'search') {
                            const dwhData = {
                                eventCategory: ECategoryGa.toolbar_search,
                                action: params.action,
                                count_files: params.items.length,
                            };
                            const items = params.items.map(({ nameWithoutExt, id, pos, kind }) => ({
                                file_name: nameWithoutExt,
                                file_id: id,
                                pos,
                                type: kind,
                            }));
                            dispatch(dispatchNewSearchRadar({ dwhData, items }));
                        }
                    };

                    onClick(undefined, onSuccess, e);
                },
                [list, onClick, openDropdownByArrow, toggleIsDropdownOpen, dispatch]
            );

            const onArrowClick = useCallback(
                (event) => {
                    if (!openDropdownByArrow) {
                        return;
                    }

                    event.stopPropagation();
                    toggleIsDropdownOpen();
                },
                [toggleIsDropdownOpen, openDropdownByArrow]
            );

            return (
                <>
                    <div
                        className={classNames(className, styles.root, styles[`root_${id}`], {
                            [styles.root_active]: isDropdownOpen,
                            [styles.root_collapsed]: collapse || !text,
                            [styles.root_withDropdown]: list && !withoutDropdownIcon,
                            [styles[`root_${color?.toLowerCase()}`]]: !!color,
                            [styles.root_disabled]: !!disabled,
                            [styles[`root_${mod}`]]: !!mod,
                        })}
                        ref={useMergeRefs(rootRef, ref)}
                        onClick={onItemClick}
                        data-name={id}
                        data-promo-id={promoId}
                    >
                        {hint || collapse ? <Hint text={collapse && !text ? text : hint}>{content}</Hint> : content}
                        {list && (
                            <>
                                {!withoutDropdownIcon && (
                                    <div
                                        className={classNames(styles.dropdownIcon, { [styles.dropdownIcon_bordered]: openDropdownByArrow })}
                                        onClick={onArrowClick}
                                    >
                                        {isCreateOrUpload ? <Icon16DropdownOutline /> : <Icon20Dropdown />}
                                    </div>
                                )}
                                {isDropdownOpen && (
                                    <DropdownList
                                        list={list}
                                        renderItem={renderDropdownListItem || renderDropdownItem}
                                        close={closeDropdown}
                                        calcPosition={findDropdownPosition({
                                            ref: rootRef,
                                            parentRef,
                                            position: dropdownPosition,
                                        })}
                                        theme={IS_BIZ_USER && isSort ? undefined : dropdownTheme || DropdownTheme.small}
                                        parentRef={rootRef}
                                        gaId="toolbar"
                                        closeOnResize
                                        closeOnScroll
                                        font={DropdownFont.VKSansDisplay}
                                        animated
                                        maxDropdownListHeight={maxDropdownListHeight}
                                        isInlineIntegration={isInlineIntegration}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    {tooltip}
                </>
            );
        }
    )
);

ToolbarItem.displayName = 'NewToolbarItem';
