import api from 'Cloud/Application/api';
import { user } from 'Cloud/Application/User';
import { xray } from 'lib/xray';
import React, { type ReactElement, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserNewDeviceAPICall } from 'reactApp/api/profile/UserNewDeviceAPICall';
import { IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { DEVICE_ID_WEB } from 'reactApp/modules/profile/profile.contants';
import { agreeUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { DEFAULT_TARIFF_FORK_PROMO_ID } from 'reactApp/modules/promo/sagas/defaultTariffForkModal.saga';
import { type RootState, store as reduxStore } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { WelcomeDefaultTariffSplash } from 'reactApp/ui/WelcomeDefaultTariffSplash/WelcomeDefaultTariffSplash';
import { isFramed } from 'reactApp/utils/isFramed';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import styles from './Choice.css';

enum EPostMessageType {
    size = 'SIZE',
    close = 'CLOSE',
    success = 'SUCCESS',
    continueFree = 'CONTINUE_FREE',
    continuePaid = 'CONTINUE_PAID',
}
const CHOICE_FORK_KEY = 'choice-fork';

const sendFrameMessage = ({ type, ...data }) => {
    sendPostMessage({ key: CHOICE_FORK_KEY, type, ...data });
};

const { productId: queryProductId } = getQueryParams();

export const ChoiceIframe = memo((): ReactElement | null => {
    const dispatch = useDispatch();

    const { isLoading, isLoaded, hasError } = useSelector(ProductsSelectors.getLifeCycleState);

    const productFromQuery = useSelector((state: RootState) => ProductsSelectors.getAvailableProductById(state, queryProductId));
    const productFromConfig = useSelector((state: RootState) =>
        ProductsSelectors.getAvailableProductById(state, welcomeDefaultTariff?.tariff)
    );

    const product = productFromQuery || productFromConfig;

    useEffect(() => {
        xray.send('choice-frame_view_web');
        dispatch(initProducts());
    }, []);

    useEffect(() => {
        if (hasError || (isLoaded && !product)) {
            xray.send('choice-frame_error_web');
        }
    }, [hasError, isLoaded, product]);

    if (!isFramed() && !IS_DEV) {
        xray.send('choice-frame_error_not_frame');
        opener('/', true);
    }

    const content = useMemo(() => {
        if (!product) {
            return;
        }
        return (
            <WelcomeDefaultTariffSplash
                onContinue={() => {
                    new Promise<void>((resolve, reject) =>
                        api.user['agree-la']()
                            .done(() => {
                                user.setParam('newbie', false);
                                resolve(undefined);
                            })
                            .fail(reject)
                    )
                        .then(() => {
                            const defaultData = { did: DEVICE_ID_WEB };
                            return new UserNewDeviceAPICall().makeRequest({
                                ...defaultData,
                                name: DEVICE_ID_WEB,
                                plid: DEVICE_ID_WEB,
                            });
                        })
                        .then(() => {
                            reduxStore.dispatch(agreeUpdateLicenseRequest({ source: 'welcome_splash' }));
                            storeHelper.markAsShown(DEFAULT_TARIFF_FORK_PROMO_ID);
                            sendFrameMessage({ type: EPostMessageType.close });
                        })
                        .catch(() => {
                            // в любом случае надо закрыть развилку и не показывать ещё раз
                            storeHelper.markAsShown(DEFAULT_TARIFF_FORK_PROMO_ID);
                            sendFrameMessage({ type: EPostMessageType.close });
                        });
                }}
                onClose={() => {
                    const isNewbie = user.getParam('newbie');
                    // если пользователь isNewbie, то он ещё не принял лицензионное соглашение
                    // надо сначала принять, а потом закрывать, иначе запрос не дойдёт
                    if (!isNewbie) {
                        sendFrameMessage({ type: EPostMessageType.close });
                    }
                }}
                onSuccess={() => {
                    sendFrameMessage({ type: EPostMessageType.success });
                }}
                onContinueFree={() => {
                    sendFrameMessage({ type: EPostMessageType.continueFree });
                }}
                onContinuePaid={() => {
                    sendFrameMessage({ type: EPostMessageType.continuePaid });
                }}
                product={product}
            />
        );
    }, [product]);

    return (
        <Content wrapClass={styles.content} isLoading={isLoading} hasError={hasError || (isLoaded && !product)}>
            {content}
        </Content>
    );
});

ChoiceIframe.displayName = 'ChoiceIframe';
