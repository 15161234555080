import classNames from 'clsx';
import React, { type VFC } from 'react';
import type { IBaseSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';

import { CommonInfo } from '../CommonInfo/CommonInfo';
import styles from './BaseSubscription.css';

export const BaseSubscription: VFC<{ subscription: IBaseSubscription; isRebrandingQuotaLanding?: boolean }> = ({
    subscription: { space },
    isRebrandingQuotaLanding,
}) => (
    <>
        <CommonInfo space={space} isBase isRebrandingQuotaLanding={isRebrandingQuotaLanding} />
        <span
            className={classNames(styles.tariffManagement, { [styles.tariffManagement_rebrandingQuotaLanding]: isRebrandingQuotaLanding })}
        >
            Данная подписка не может быть изменена или отменена
        </span>
    </>
);
