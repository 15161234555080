/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import store from 'lib/store';
import React, {
    type MutableRefObject,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import { ENABLE_FULL_RESPONSIVE, NEW_PORTAL_HEADER, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { isStoriesInGallery } from 'reactApp/appHelpers/featuresHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { useIsTutoriaIntegration } from 'reactApp/hooks/useIsTutoriaIntegration';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFilteredFacesList } from 'reactApp/modules/faces/faces.selectors';
import { getFeatureFaces } from 'reactApp/modules/features/features.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isMobileGalleryViewMode } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage.helpers';
import {
    DATA_LIST_BREADCRUMBS_ID,
    DATA_LIST_EXTRABLOCKS_ID,
    DATA_LIST_GALLERY_TAB_MENU_ID,
    DATA_LIST_LAST_FILES_WIDGET_ID,
    DATA_LIST_STORIES_WIDGET_ID,
} from 'reactApp/ui/Datalist/DataList';
import { STORIES_WIDGET_LOCAL_STORAGE_KEY } from 'reactApp/ui/StoriesWidget/StoriesWidget.constants';
import { generateUuidV4 } from 'reactApp/utils/helpers';

import { LAST_FILES_WIDGET_LOCAL_STORAGE_KEY } from '../LastFilesWidget/LastFilesWidget';
import { COMPENSATE_SCROLL_PADDING, DIVIDER_HEIGHT, INCOMING_ITEM_HEIGHT_RESPONSIVE } from './VirtualList.constants';
import { listChildrenMap } from './VirtualList.data.new';
import { getActiveRowIndex, getIndexes, getListSizes, scrollIntoView } from './VirtualList.helpers.new';
import { useWindowResizer, useWindowScroller } from './VirtualList.hooks';
import styles from './VirtualList.new.css';
import { type IProps, type VirtualIndex, type VirtualListItem, EAligment } from './VirtualList.types';
import { VirtualListLoader } from './VirtualListLoader';

export const VIRTUAL_LIST_LOADER_ID = generateUuidV4();

let mountedLists = 0;

const VirtualListBase = forwardRef(function VirtualList(props: IProps, ref) {
    const {
        customScrollElement,
        itemHeight,
        itemWidth,
        rowHeight,
        list,
        renderItem,
        viewMode,
        dividerHeight,
        dividerSize,
        onItemsRendered,
        className = '',
        disableScroll = false,
    } = props;
    const [rootRef, containerWidth] = useWindowResizer();
    const [scrollerRef, outerRef] = useWindowScroller(customScrollElement);
    const innerRef = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const activeRef = useRef<HTMLDivElement | null>(null) as MutableRefObject<HTMLDivElement | null>;
    const aligment = useRef<EAligment>(EAligment.CENTER);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const currentStorage = useSelector(getCurrentStorage);
    const currentRouteId = useSelector(getCurrentRouteId);
    const IsFacesFeature = useSelector(getFeatureFaces);
    const faces = useSelector(getFilteredFacesList);
    const showFacesFilter = currentStorage === EStorageType.public && IsFacesFeature && faces.length;
    const { windowWidth } = useWindowSize();

    const [sizesMap, setSizesMap] = useState<Map<VirtualListItem, number>>(new Map());

    useEffect(() => {
        ++mountedLists;
        const storageTypes = [
            EStorageType.home,
            EStorageType.favorites,
            EStorageType.alldocuments,
            EStorageType.feed,
            EStorageType.sharedLinks,
            EStorageType.attaches,
            EStorageType.gallery,
        ];

        if (!storageTypes.includes(currentStorage)) {
            mountedLists = 0;
            return;
        }

        const inlineHidden = styles['inline-hidden'];
        document.body.classList.add(inlineHidden);

        return () => {
            --mountedLists;
            if (!mountedLists) {
                document.body.classList.remove(inlineHidden);
            }
        };
    }, []);

    const isTutoriaIntegration = useIsTutoriaIntegration();

    const { columnCount, lineHeight, width, margin, height } = getListSizes({
        containerWidth,
        itemHeight,
        itemWidth,
        rowHeight,
        viewMode,
        isPhone,
        isTutoriaIntegration,
    });

    const handleRef = useCallback((ref) => {
        activeRef.current = ref;
    }, []);

    const style = useMemo(
        () =>
            ({
                height: '100%',
                overflow: 'hidden',
                '--compensate-scroll-padding': `${COMPENSATE_SCROLL_PADDING}px`,
            } as const),
        []
    );

    const allItems = useMemo(() => {
        const listCopy = list.slice();
        const [last, rest] = [listCopy.pop(), listCopy];

        return [...rest, ...(props.hasMoreToLoad ? [VIRTUAL_LIST_LOADER_ID] : []), ...(last ? [last] : [])];
    }, [list, props.hasMoreToLoad]);

    const indexes = getIndexes(allItems, columnCount, viewMode);
    const itemActiveIndex = activeIndex !== -1 ? getActiveRowIndex({ index: activeIndex, viewMode, columnCount, indexes }) : -1;

    const extraBlockHeight = useRef<number>(0);

    const getItemSize = useCallback(
        (index: number) => {
            const isGrid =
                viewMode === EViewMode.thumbs ||
                isMobileGalleryViewMode(viewMode) ||
                viewMode === EViewMode.squares ||
                viewMode === EViewMode.squares180 ||
                viewMode === EViewMode.gallery;
            const gridIndex = indexes[index]?.start;

            const listItem = allItems[isGrid ? gridIndex : index];

            const dynamicSize = sizesMap.get(listItem);

            if (typeof dynamicSize === 'number') {
                return dynamicSize;
            }

            if (listItem === DATA_LIST_BREADCRUMBS_ID) {
                return showFacesFilter && !!PUBLIC_SHOVE ? 144 : 66;
            }

            if (listItem === VIRTUAL_LIST_LOADER_ID) {
                return 60;
            }

            if (listItem === DATA_LIST_LAST_FILES_WIDGET_ID) {
                return store.get(LAST_FILES_WIDGET_LOCAL_STORAGE_KEY) ? 244 : 50;
            }

            if (listItem === DATA_LIST_GALLERY_TAB_MENU_ID) {
                return 100;
            }

            if (listItem === DATA_LIST_STORIES_WIDGET_ID) {
                if (isStoriesInGallery) {
                    return 224;
                }
                return store.get(STORIES_WIDGET_LOCAL_STORAGE_KEY) ? 294 : 50;
            }

            if (listItem === DATA_LIST_EXTRABLOCKS_ID) {
                // TODO: нужно достать динамическую высоту блока рекламы
                return extraBlockHeight.current;
            }

            const isDivider = typeof listItem === 'object' && listItem.divider;

            if (isDivider) {
                return dividerHeight ? dividerHeight : DIVIDER_HEIGHT;
            }

            if (viewMode === EViewMode.list && windowWidth <= BREAKPOINT_SM && ENABLE_FULL_RESPONSIVE) {
                return INCOMING_ITEM_HEIGHT_RESPONSIVE;
            }

            return lineHeight;
        },
        [viewMode, indexes, allItems, sizesMap, windowWidth, lineHeight, showFacesFilter, dividerHeight]
    );

    const handleItemsRendered = useCallback(
        ({ visibleStartIndex, visibleStopIndex }: { visibleStartIndex: number; visibleStopIndex: number }) => {
            const start = indexes[visibleStartIndex]?.start;
            const stop = indexes[visibleStopIndex]?.end;

            onItemsRendered?.({ start, stop });
        },
        [indexes, onItemsRendered]
    );

    const getItemIndex = useCallback(
        (indexes: VirtualIndex[], item: VirtualListItem) => {
            return indexes.findIndex((virtualIndex) => {
                for (let index = virtualIndex.start; index <= virtualIndex.end; index++) {
                    const listItem = allItems[index];

                    if (listItem === item) {
                        return true;
                    }
                }

                return false;
            });
        },
        [allItems]
    );

    const itemData = {
        renderItem: (id: string, options: { index: number }) => {
            if (id === VIRTUAL_LIST_LOADER_ID) {
                return props.hasMoreToLoad && <VirtualListLoader loadOnScroll={props.loadOnScroll} isLoading={props.isLoading} />;
            }

            return renderItem(id, options, {
                onItemSizeChange: (size: number) => {
                    setSizesMap(sizesMap.set(id, size));

                    const blockIndex = getItemIndex(indexes, id);
                    scrollerRef.current?.resetAfterIndex(blockIndex, true);
                },
            });
        },
        list: allItems,
        itemActiveIndex,
        handleRef,
        width,
        margin,
        height,
        indexes,
        dividerSize,
        columnCount,
        isTutoriaIntegration,
    };

    useImperativeHandle(ref, () => ({
        scrollToItem: (index: number, aligmentOption = EAligment.CENTER) => {
            setActiveIndex(index);
            aligment.current = aligmentOption;
        },
        getColumnsCount: () => columnCount,
    }));

    /* Если список меньше размером, чем его контейнер, то отдаем оставшуюся высоту
     под блок рекламы/провокации загрузки
  */
    useEffect(() => {
        const extraBlockIndex = getItemIndex(indexes, DATA_LIST_EXTRABLOCKS_ID);

        const outerHeight = outerRef.current?.clientHeight || 0;
        const innerHeight = innerRef.current?.clientHeight || 0;

        const blockHeight = getItemSize(extraBlockIndex);
        const innerHeightWithoutBlock = innerHeight - blockHeight;

        if (outerHeight > innerHeightWithoutBlock) {
            extraBlockHeight.current = outerHeight - innerHeightWithoutBlock;
        } else {
            extraBlockHeight.current = 0;
        }

        if (extraBlockIndex !== -1) {
            // Пересчитываем размеры эл-в ниже блока рекламы/провокации загрузки
            scrollerRef.current?.resetAfterIndex(extraBlockIndex, true);
        }
    }, [getItemIndex, getItemSize, indexes, lineHeight, outerRef.current, innerRef.current, scrollerRef.current]);

    useEffect(() => {
        scrollerRef.current?.resetAfterIndex(0);
    }, [lineHeight, scrollerRef.current, list]);

    useEffect(() => {
        if (itemActiveIndex === -1) {
            return;
        }

        scrollerRef.current?.scrollToItem(itemActiveIndex, aligment.current);
        // Надо, чтобы VariableSizeList успел проскроллить и нарисовать элементы
        setTimeout(
            () =>
                aligment.current !== EAligment.SMART
                    ? activeRef.current?.scrollIntoView({ block: aligment.current })
                    : scrollIntoView(activeRef.current),
            100
        );
    }, [scrollerRef.current, columnCount, activeIndex]);

    useEffect(() => {
        if (currentStorage === EStorageType.home) {
            scrollerRef.current?.scrollTo(0);
        }
    }, [currentRouteId]);

    const isGrid = viewMode === EViewMode.thumbs || viewMode === EViewMode.squares || viewMode === EViewMode.squares180;

    return (
        <div
            ref={rootRef}
            className={classNames(className, {
                [styles.root]: true,
                [styles.root_grid]: isGrid,
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <List
                ref={scrollerRef}
                itemSize={getItemSize}
                itemCount={indexes.length}
                height={window.innerHeight}
                itemData={itemData}
                width={isTutoriaIntegration ? windowWidth : containerWidth}
                outerRef={outerRef}
                innerRef={innerRef}
                style={style}
                className={classNames(styles.list, { [styles.listDisableScroll]: disableScroll })}
                overscanCount={15}
                onItemsRendered={handleItemsRendered}
                estimatedItemSize={lineHeight}
            >
                {listChildrenMap[viewMode || EViewMode.thumbs]}
            </List>
        </div>
    );
});

export const VirtualList = memo(VirtualListBase);
