import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';

import styles from '../DataListItemThumb.css';

export const Publish = memo(({ isActive, onClick }: { isActive: boolean; onClick: (e: React.MouseEvent) => void }): ReactElement => {
    const handleOnPublish = useCallback(
        (e) => {
            onClick(e);
        },
        [onClick]
    );

    return (
        <div
            className={classNames({
                [styles.weblink]: true,
                [styles.weblink_active]: isActive,
            })}
        >
            <DataListControl type="publish" active={isActive} onClick={handleOnPublish} size={28} viewType={EViewMode.thumbs} />
        </div>
    );
});
Publish.displayName = 'Publish';
