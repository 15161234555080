import { Icon20Cancel } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import React, { type ReactElement, memo, useCallback, useEffect, useState } from 'react';

import styles from './TreePromo.css';

interface IProps {
    text: string;
    href: string;
    icon: ReactElement;
    onShow?: () => void;
    onClick?: () => void;
    onClose?: () => void;
}

export const TreePromo = memo<IProps>(({ text, href, icon, onShow, onClick, onClose }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        onShow?.();
    }, []);

    const handleClick = useCallback(() => {
        onClick?.();
        setShow(false);
    }, [onClick, setShow]);

    const handleClose = useCallback(() => {
        onClose?.();
        setShow(false);
    }, [onClose, setShow]);

    if (!show) {
        return null;
    }

    return (
        <div className={styles.root}>
            <a className={styles.link} href={href} target="_blank" onClick={handleClick} rel="noreferrer">
                {icon}
                <Text className={styles.text}>{text}</Text>
            </a>
            <div className={styles.close} onClick={handleClose}>
                <Icon20Cancel />
            </div>
        </div>
    );
});

TreePromo.displayName = 'TreePromo';
