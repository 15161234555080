import { createSelector } from '@reduxjs/toolkit';
import { sort } from 'ramda';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { CAMERA_UPLOADS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import type { FilesState, HomeFile, HomeFolder, HomeItem, MediaStatistics } from 'reactApp/modules/home/home.types';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSharedIds } from 'reactApp/modules/shared/shared.selectors';
import { intlStringCompare } from 'reactApp/modules/sort/sort.helpers';
import { isDomainFolder, isMountedFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import type { RootState } from 'reactApp/store';
import type { Kind } from 'reactApp/types/Tree';
import type { BreadcrumbsListItem } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

const getFilesState = (state: RootState): FilesState => state.home;
const getId = (state, id) => id;

export const getHomeList = createSelector(getFilesState, (state) => state.list);

export const getHomeItemById = createSelector(
    getFilesState,
    getId,
    (filesState, id): HomeFolder | HomeFile | undefined => filesState.list[id]
);

export const getHomeStatistics = createSelector(getFilesState, (filesState) => filesState.homeStatistics);
export const getCameraUploadsStatistics = createSelector(getFilesState, (filesState) => filesState.cameraUploadsStatistics);

export const getIntraDomainShare = createSelector(getFilesState, (filesState) => !!filesState.intraDomainShare);

export const getFolderListById = createSelector(
    getHomeItemById,
    (state: RootState) => state,
    (folder, state) => {
        if (folder?.isFolder && Array.isArray(folder.childs)) {
            return sort(
                (a, b) => (a && b ? intlStringCompare(a.name, b.name) : 0),
                folder.childs.map((child) => getHomeItemById(state, child))
            );
        }
        return [];
    }
);

export const getCurrentFolderHome = createSelector(
    getCurrentRouteId,
    (state) => state,
    (routeId, state): HomeFolder | undefined => {
        if (!routeId) {
            return;
        }

        const item = getHomeItemById(state, routeId);

        if (!item) {
            return;
        }

        if (item.isFolder) {
            return item;
        }

        return getHomeItemById(state, item.parent) as HomeFolder;
    }
);

export const getCurrentChildIds = createSelector(getCurrentFolderHome, (folder): string[] => {
    if (folder) {
        return folder.childs;
    }

    return [];
});

export const isReadOnly = createSelector(
    getFilesState,
    (state, id): string => id,
    (state, id): boolean => {
        let folder = state.list[id];
        while (folder && folder.isFolder && !folder.isReadOnly && folder.parent) {
            folder = state.list[folder.parent];
        }

        return folder?.isFolder ? Boolean(folder.isReadOnly) : false;
    }
);

export const isThisOrParentMounted = createSelector(getHomeItemById, getHomeList, (item, list) => {
    if (!item) {
        return false;
    }

    if (item.isMounted) {
        return true;
    }

    if (item.isFolder && (isMountedFolder(item) || isDomainFolder(item))) {
        return true;
    }

    let result = false;

    let parent = list[item.parent || ''] as HomeFolder;

    while (parent) {
        if (isMountedFolder(parent) || isDomainFolder(parent)) {
            result = true;
            break;
        }

        parent = list[parent.parent || ''] as HomeFolder;
    }

    return result;
});

export const isDefaultRootContent = createSelector(
    (state: RootState) => getFolderListById(state, '/'),
    (state: RootState) => getHomeItemById(state, '/'),
    (state: RootState) => getCurrentStorage(state),
    (list, folder, storage) => {
        if (storage !== EStorageType.home) {
            return false;
        }

        return list && list.length === 0 && (folder as HomeFolder)?.isLoaded;
    }
);

export const getMountedFolderQuotaFree = createSelector(
    (state: RootState) => state,
    (_state: RootState, folderId: string) => folderId,
    (state: RootState, folderId: string) => {
        const folder = getHomeItemById(state, folderId) as HomeFolder;

        return folder?.quota ?? 0;
    }
);

export const getCameraUploadsStatisticsPrev = createSelector(
    getSharedIds,
    (state) => state,
    (idxs, state) => {
        const list = getFolderListById(state, CAMERA_UPLOADS_FOLDER_ID) as HomeItem[];

        let photos = 0;
        let videos = 0;

        (Array.isArray(list) ? list : []).forEach((item) => {
            const fileItem = item as HomeFile;
            if (!fileItem) {
                return;
            }
            if (fileItem?.kind === 'image') {
                photos++;
            }
            if (fileItem?.kind === 'video') {
                videos++;
            }
        });

        return {
            photos,
            videos,
        } as MediaStatistics;
    }
);

export const getRootFolderList = createSelector(getHomeList, (home) => home[ROOT_FOLDER_ID]);

export const getDomainFoldersFilterActive = createSelector(getFilesState, (state) => state.domainFoldersFilterActive);

export const getHomeBreadcrumbs = createSelector(
    getCurrentFolderHome,
    (state: RootState) => state,
    (currentFolder, state): BreadcrumbsListItem[] => {
        if (!currentFolder) {
            return [];
        }

        const showDomainTitle = currentFolder.kind === 'domain-folder' || currentFolder.isMounted || getDomainFoldersFilterActive(state);

        const breadCrumbs: BreadcrumbsListItem[] = [
            {
                name: IS_BIZ_USER ? `${showDomainTitle ? 'Общие папки' : 'Личные папки'}` : 'Все файлы',
                id: ROOT_FOLDER_ID,
                kind: 'folder' as Kind,
                storage: EStorageType.home,
            },
        ];

        const pathParts = currentFolder.home.split('/').filter(Boolean);
        const isRoot = currentFolder.id === ROOT_FOLDER_ID;
        let isPrevShared = false;

        pathParts.reduce((fullPath, path) => {
            const currentPath = `${fullPath}/${path}`;
            // Для вложенных папок монтированной папки приходит isMounted, но kind - не mounted
            const isPrevMounted = currentFolder.isMounted && !isMountedFolder(currentFolder);

            breadCrumbs.push({
                name: renameCameraUploadFolder({ name: path }),
                id: currentPath,
                kind: currentFolder.kind,
                storage: EStorageType.home,
                weblink: currentFolder.weblink,
                showWeblink: !isRoot,
                showShare: !isRoot && !isPrevShared && !isPrevMounted && !isDomainFolder(currentFolder),
            });

            const item = getHomeItemById(state, currentPath);
            isPrevShared = isPrevShared || isSharedFolder(item);

            return currentPath;
        }, '');

        return breadCrumbs;
    }
);
