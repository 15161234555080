import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import type { IPaidFeatureProps } from 'reactApp/ui/FileHistory/FileHistory.types';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const MODAL_NAME = 'paid-feature-modal';

export const PaidFeatureDialog = memo<IPaidFeatureProps>(({ onClose, onOpenTariffs }): ReactElement => {
    const renderHeader = useCallback(() => <>История файла</>, []);

    const renderContent = useCallback(() => <>Возможность восстановления версий файла доступна только для платных тарифов</>, []);

    const handleSuccess = useCallback(() => {
        sendFilesHistoryGa('click', MODAL_NAME);
        sendPaymentGa({
            action: EPaymentGa.filesHistoryPaid,
            label: 'click',
        });

        onOpenTariffs();
    }, [onClose, onOpenTariffs]);

    const handleClose = useCallback(() => {
        sendFilesHistoryGa('close', MODAL_NAME);
        sendPaymentGa({
            action: EPaymentGa.filesHistoryPaid,
            label: 'close',
        });
        onClose();
    }, [onClose]);

    useEffect(() => {
        sendFilesHistoryGa('show', MODAL_NAME);
        sendPaymentGa({
            action: EPaymentGa.filesHistoryPaid,
            label: 'view',
        });
    }, []);

    return (
        <BaseConfirmDialog
            dataQAId="paid-feature"
            renderContent={renderContent}
            renderHeader={renderHeader}
            buttons={[EButtonType.accept, EButtonType.cancel]}
            successButtonText="Посмотреть тарифы"
            onSuccess={handleSuccess}
            onClose={handleClose}
        />
    );
});

PaidFeatureDialog.displayName = 'PaidFeatureDialog';
