import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

interface LeaveFamilyAPICallParams {
    error?: string;
    message?: string;
}

export interface LeaveFamilyQueryParams {
    family: string;
}

export class LeaveFamilyAPICall extends APICallV4<LeaveFamilyAPICallParams> {
    _method = '/family/leave';
    _type: Method = 'post';
}
