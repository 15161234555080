import { PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { CLOUD_SOURCE, PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { ADS_STORAGE } from 'reactApp/ui/AdvBanners/Adv.types';
import { getUtmString } from 'reactApp/utils/urlHelper';

export const getAdvDisableLink = ({ isAnonymous, storage }) => {
    if (PUBLIC_SHOVE) {
        return isAnonymous
            ? getQuotaPromoUrl({ query: PaymentUTM.advDisableLinkAnonym })
            : getQuotaPromoUrl({ query: PaymentUTM.advDisableLink });
    }

    const utm = getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_union_quota_landing',
        utm_campaign: `disable_ads_${ADS_STORAGE[storage] || ''}`,
    });

    return isAnonymous ? getQuotaPromoUrl({ query: PaymentUTM.advDisableLineLinkAnonym }) : getQuotaPromoUrl({ query: utm });
};
