import type { Method } from 'lib/axios';
import type { RequestActivitiesAPIPaylod } from 'reactApp/modules/activities/activities.types';

import { APICallV4 } from '../APICall';

export class ActivitiesV4ApiCall extends APICallV4 {
    _method = 'private/file/activities';
    _type: Method = 'get';
}

export const activitiesV4ApiCall = (arg: RequestActivitiesAPIPaylod) => new ActivitiesV4ApiCall().makeRequest(arg);
