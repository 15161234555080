import type { FaviconsInterface } from 'lib/getFaviconByExtension';
import posthtml, { type Node, type Result } from 'posthtml';
import insert from 'posthtml-insert';

const removeLink = (tree: Node): Node[] => {
    if (!Array.isArray(tree) || tree.length === 0) {
        return [tree];
    }

    return tree.match(
        ['link'].map((tag) => ({ tag })),
        (node): Node => {
            if (node?.attrs?.rel === 'icon' || node?.attrs?.rel === 'apple-touch-icon') {
                // @ts-expect-error
                node.tag = false;
                node.content = [];
            }
            return node;
        }
    );
};

export function replaceFaviconTags(favicons: FaviconsInterface): void {
    const head = document.querySelector('head');
    const headContent = head?.outerHTML;
    if (!headContent) {
        return;
    }

    const plugins = [
        removeLink,
        insert({
            selector: 'head',
            content: `
                <link rel="icon" href="${favicons.svg}" type="image/svg+xml" nonce crossorigin="anonymous">
                <link rel="icon" href="${favicons.ico}" sizes="any" nonce crossorigin="anonymous">
                <link rel="apple-touch-icon" href="${favicons.appleTouchIcon}" nonce crossorigin="anonymous">
            `,
        }),
    ];
    head.innerHTML = (posthtml(plugins).process(headContent, { sync: true }) as unknown as Result<{ html: string }>).html;
}
