import { Button } from '@vkontakte/vkui';
import OverquotaIcon from 'img/overquota/public_overquota.png';
import OverquotaDarkIcon from 'img/overquota/public_overquota_dark.png';
import React, { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isCurrentDarkTheme } from 'reactApp/modules/theme/theme.selectors';
import { EmptyComponent } from 'reactApp/ui/Empty/new/EmptyComponent';

import styles from './Empty.css';

const GoToButton: FC = () => {
    const handleGoTo = useCallback(() => open('/home', '_self'), []);

    return (
        <Button className={styles.button} mode="secondary" appearance="neutral" size="m" onClick={handleGoTo}>
            Перейти на главную
        </Button>
    );
};

export const EmptyPublicOverquota = memo(({ forceDark = false }: { forceDark?: boolean }) => {
    const isDark = useSelector(isCurrentDarkTheme) || forceDark;

    return (
        <EmptyComponent
            title={<div className={styles.title_overquota}>Посмотреть файлы пока не&nbsp;получится</div>}
            description={
                <div className={styles.description_overquota}>
                    Облако переполнено — обратитесь&nbsp;к&nbsp;владельцу пространства, чтобы получить&nbsp;доступ
                </div>
            }
            icon={isDark ? OverquotaDarkIcon : OverquotaIcon}
            BottomContent={GoToButton}
        />
    );
});

EmptyPublicOverquota.displayName = 'EmptyPublicOverquota';
