import type { Method } from 'lib/axios';
import { APICallV4 } from 'reactApp/api/APICall';

export interface DeleteTrashbinFilesList {
    'x-email': string;
    paths: string[];
}

export class DeleteFilesFromTrashbinAPICall extends APICallV4<{ body?: DeleteTrashbinFilesList }> {
    _method = 'trash/file/delete';
    _type: Method = 'post';
}
