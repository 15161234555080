import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { loadSharedAutoDeleteRequest } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.module';
import { getSharedAutoDeleteIds, getSharedAutoDeleteLoadingState } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { Empty } from 'reactApp/ui/Empty/Empty';
import { Footer } from 'reactApp/ui/Footer/Footer';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { sendGa } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedAutoDeletePage.css';

export const SharedAutoDeletePage = memo((): ReactElement => {
    const dispatch = useDispatch();

    const items = useSelector(getSharedAutoDeleteIds);
    const { isLoaded } = useSelector(getSharedAutoDeleteLoadingState);

    const showAutoDeletePromo = isLoaded && !items?.length;

    const handleItemClick = useCallback(({ id }) => {
        opener(`/home${encodeURIComponent(id)}`, !id);
    }, []);

    useEffect(() => {
        sendGa('autodelete-page', 'show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'autodelete' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'autodelete' });

        dispatch(loadSharedAutoDeleteRequest());
        dispatch(checkUpdateLicenseRequest());
    }, [dispatch]);

    if (showAutoDeletePromo) {
        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                })}
            >
                <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                    {isLoaded ? <BreadcrumbsContainer /> : <div className={styles.breadPlaceholder} />}
                    <SharedTabs />
                    <Empty.New storage={EStorageType.sharedAutodelete} />
                    <Footer storage={EStorageType.sharedAutodelete} isAbsolutePos={true} withMargins />
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                {isLoaded ? <BreadcrumbsContainer /> : <div className={styles.breadPlaceholder} />}
                <SharedTabs />
            </div>
            <DataList goTo={handleItemClick} storage={EStorageType.sharedAutodelete} selectableClassName={styles.datalistSelectable} />
        </div>
    );
});

SharedAutoDeletePage.displayName = 'SharedAutoDeletePage';
