import type { PromoMobileApp } from 'reactApp/modules/promo/promo.types';
import type { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { EAsidePromoModalTheme } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.types';
import { sendDwh } from 'reactApp/utils/ga';

export const showMobileAppRQ = ({
    promoData,
    type,
    url,
    from,
    isDarkPopup = false,
    popupTitle = 'Установите Облако на смартфон',
    popupText = 'Включите автозагрузку, и в смартфоне появится больше свободного места',
    bottom,
}: {
    promoData?: PromoMobileApp;
    type: MobileAppPromoType;
    url: string;
    from: string;
    isDarkPopup?: boolean;
    popupTitle?: string;
    popupText?: string;
    bottom?: number;
}) =>
    openAsidePromoModal({
        title: popupTitle,
        text: popupText,
        qrUrl: url,
        bottom,
        onShow: () => {
            promoData?.onShow();
            promoData?.onMarkShown(type);
            sendDwh({
                eventCategory: 'web_to_mobile',
                label: 'install_sidebar',
                dwhData: {
                    from,
                },
            });
        },
        theme: isDarkPopup ? EAsidePromoModalTheme.dark : undefined,
        onClose: () => promoData?.onClose(),
    });
