import config from 'Cloud/config';
import axios from 'lib/axios';
import { logger } from 'lib/logger';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { select } from 'redux-saga/effects';

export function* userEnable2fa() {
    if (!IS_ONPREMISE) {
        return;
    }

    /**
     * B2BCLOUD-1009:
     * Времпенный костыль для ТАРМ, через отдельный API проверяет нужно ли пользователю привязать номер телефона
     * для активации 2FA и если нужно, то редиректим на спецыальный url.
     *
     * fixme: Костыль делается как временное решение и будет выпилен после того как в Почте сделают нормальный флоу
     */
    try {
        const email = yield select(UserSelectors.getEmail);
        if (!email) {
            return;
        }

        const authDomain = config.get('AUTH_DOMAIN');
        const domainHost = config.get('DOMAIN_HOST');
        const pushAuthInfoUrl = `//auth.${domainHost}/api/v1/pushauth/info?login=${encodeURIComponent(email)}`;
        const {
            data: {
                body: { twostep_preserve, twostep },
            },
        } = yield axios.get(pushAuthInfoUrl);

        if (twostep_preserve === true && twostep === false) {
            window.location.assign(`${authDomain}user/2-step-auth/enable?returnUrl=${encodeURIComponent(window.location.href)}`);
        }
    } catch (err) {
        logger.error("Check user's 2fa failed with error:", err);
    }
}
