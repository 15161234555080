import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTrashbinSize, isLargeTrashbinInfoVisible } from 'reactApp/modules/trashbin/trashbin.selectors';
import styles from 'reactApp/ui/TreeComponent/TrashNodeLabel.css';

export const TrashNodeLabel = () => {
    const showLargeTrashbinInfo = useSelector(isLargeTrashbinInfoVisible);
    const trashbinSize = useSelector(getTrashbinSize);

    if (!showLargeTrashbinInfo) {
        return null;
    }

    return (
        <div className={styles.trashSize} data-qa-id="tree-node-label-trashbin-size-counter">
            Занято&nbsp;{bytesToNDigits(trashbinSize, 3).value}
        </div>
    );
};
