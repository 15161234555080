import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { getAttachUrl } from 'reactApp/modules/attaches/attaches.helpers';
import { isPublicAlbumTemporaryUrl } from 'reactApp/modules/public/publicAlbum.helper';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getEncodePath } from 'reactApp/utils/urlHelper';

import type { ContextMenuItem } from './ContextMenu';

const getId = (item, storage: EStorageType) => {
    const { isStock, isPublic } = getStorage(storage);

    const id = item.id?.replace('/', '');

    if (isPublic) {
        return `/${item.weblink}`;
    }

    if (isStock) {
        return item.id;
    }

    return encodeURIComponent(id);
};

export const getPathToNewTab = (item, storage, userEmail: string | null): string => {
    const {
        isHome,
        isSharedIncoming,
        isSearch,
        isSharedLinks,
        isSharedAutodelete,
        isFeed,
        isDocuments,
        isStart,
        isFavorites,
        isAttaches,
        isAlbums,
        isAllDocuments,
    } = getStorage(storage);

    const id = getId(item, storage);

    if (
        isHome ||
        isSharedIncoming ||
        isSearch ||
        isSharedLinks ||
        isSharedAutodelete ||
        isFeed ||
        isDocuments ||
        isAllDocuments ||
        isStart ||
        (isFavorites && item.ext && item.ext.toLowerCase() === 'pdf') ||
        (isFavorites && item.isFolder)
    ) {
        return `home${getEncodePath(item.home) || ''}`;
    }

    if (isAttaches) {
        return getAttachUrl(item.id, userEmail, true);
    }

    if (isAlbums) {
        return isAlbum(item) ? `${storage}/${id}` : `${storage}/${item.parent}/${id}`;
    }

    const isAlbumLocation = isPublicAlbumTemporaryUrl();

    if (isAlbumLocation) {
        return `album${id}`;
    }

    return `${storage}/${id}`;
};

export const makeFlatDividedList = (groups: ContextMenuItem[][]) =>
    groups.reduce((acc, group) => {
        const clonedGroup = group.slice();

        if (clonedGroup.length > 0) {
            const lastIndex = clonedGroup.length - 1;

            clonedGroup[lastIndex] = {
                ...clonedGroup[lastIndex],
                divider: true,
            };
        }

        acc.push(...clonedGroup);

        return acc;
    }, []);
