import { EditorID } from 'Cloud/Application/Editor/types';
import localState from 'lib/store';
import React, { type VFC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VIEWERS_CONFIG } from 'reactApp/appHelpers/configHelpers';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureMyOfficePromoRating, getFeatureMyOfficeViewerPromo } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { MyOfficePopup } from 'reactApp/ui/MyOfficePromo/Popup/MyOfficePopup';
import { ViewerSnackbar } from 'reactApp/ui/MyOfficePromo/ViewerSnackbar/ViewerSnackbar';
import opener from 'reactApp/utils/opener';
import { getAvailableEditorsFor } from 'server/helpers/editors/getEditors';

const RADAR_NAME = 'myoffice-viewer-promo';
const SESSION_STORAGE_VIEW_KEY = RADAR_NAME;

/**
 * Если с момента скрытия показа плашки прошло больше 30 дней, то показываем ее еще раз
 * Значение hideDays может быть взято из конфига
 * @param hideDays
 */
const isSnackbarAlreadyVisible = (hideDays = 30) => {
    const visibleTime = localState.get(SESSION_STORAGE_VIEW_KEY);
    if (!visibleTime) {
        return false;
    }
    const passedDays = (new Date().getTime() - parseInt(visibleTime)) / 1000 / 60 / 60 / 24;

    return passedDays < hideDays;
};

export const isVisiblePromo = (viewerApi, featureData) =>
    viewerApi?.id === EditorID.MYOFFICE && !!featureData && !isSnackbarAlreadyVisible(featureData?.hideDays);

interface IViewerSnackbarPromoContainerProps {
    file: CloudFile;
    isEmbedded;
}

export const ViewerSnackbarPromoContainer: VFC<IViewerSnackbarPromoContainerProps> = ({ file, isEmbedded }) => {
    const featureData = useSelector(getFeatureMyOfficeViewerPromo);
    const showPromoRating = useSelector(getFeatureMyOfficePromoRating);
    const storage = useSelector(getCurrentStorage);
    const { isAttaches } = getStorage(storage);
    const viewerApi = useMemo(
        () =>
            getAvailableEditorsFor({
                item: file,
                editors: VIEWERS_CONFIG,
                isEdit: false,
                storage,
                isWopiEnabled,
            })[0],
        [file, storage]
    );
    const [visible, setVisible] = useState(() => isVisiblePromo(viewerApi, featureData));

    return visible ? (
        <MyOfficePopup
            radarName={RADAR_NAME}
            offset={[-30, 5]}
            position="top center"
            mouseLeaveDelay={featureData?.mouseLeaveDelay}
            mouseEnterDelay={featureData?.mouseEnterDelay}
        >
            <ViewerSnackbar
                showPromoRating={showPromoRating}
                radarName={RADAR_NAME}
                fileExt={file?.ext}
                onClickAd={() => {
                    if (featureData?.adLink) {
                        opener(featureData.adLink);
                    }
                }}
                onClick={() => {
                    if (isAttaches && featureData?.attachLink) {
                        opener(featureData.attachLink);
                    } else if (featureData?.vkLink && isEmbedded) {
                        opener(featureData.vkLink);
                    } else if (featureData?.cloudLink) {
                        opener(featureData.cloudLink);
                    }
                }}
                onClickClose={() => {
                    setVisible(false);
                    localState.set(SESSION_STORAGE_VIEW_KEY, `${new Date().getTime()}`);
                }}
            />
        </MyOfficePopup>
    ) : null;
};
