import React, { memo } from 'react';
import type { IBaseSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { createGaSender } from 'reactApp/utils/ga';

import { SubscriptionItem } from '../SubscriptionListItem/SubscriptionListItem';

const sendGa = createGaSender('subs-sidebar');

type Props = {
    subscription: IBaseSubscription;
    onClick: () => void;
    isRebranding?: boolean;
    isRebrandingQuotaLanding?: boolean;
};

export const BaseSubscriptionItemComponent = memo<Props>(({ subscription, onClick, isRebranding = false, isRebrandingQuotaLanding }) => {
    const handleOnClick = () => {
        sendGa('click', 'subs-item', subscription.type);
        onClick();
    };

    return (
        <SubscriptionItem
            icon={subscription.icon}
            title={subscription.space.value}
            description="Основной объём Облака"
            onClick={handleOnClick}
            isRebranding={isRebranding}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        />
    );
});

BaseSubscriptionItemComponent.displayName = 'BaseSubscriptionItemComponent';
