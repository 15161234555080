import classNames from 'clsx';
import React, { type ReactElement, memo } from 'react';
import type { IProduct } from 'reactApp/modules/products/products.types';

import type { Options } from '../VkBuyModalWithSidebar.types';
import styles from './TrialPromoSidebar.css';
import { useContent } from './useContent';

interface TrialPromoSidebarProps {
    product: IProduct;
    options?: Options;
}

export const TrialPromoSidebar = memo(({ product, options }: TrialPromoSidebarProps): ReactElement => {
    const { hasTrial } = product ?? {};
    const { sidebarNewContent } = options ?? {};
    const { header, description, features, footer } = useContent(product, options);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_trial]: hasTrial && !sidebarNewContent,
                [styles.root_trial_updated]: hasTrial && sidebarNewContent,
            })}
        >
            <div className={styles.tariffInfo}>
                <div className={styles.header}>{header}</div>
                <div className={styles.text}>{description}</div>
                <ol className={styles.features}>
                    {features.map((feature, index) => (
                        <li key={index}>
                            <span className={styles.feature}>{feature}</span>
                        </li>
                    ))}
                </ol>
            </div>
            <div className={styles.paymentInfo}>{footer}</div>
        </div>
    );
});

TrialPromoSidebar.displayName = 'TrialPromoSidebar';
