import classNames from 'clsx';
import React, { type ReactElement, createRef, PureComponent } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { createGaSender } from 'reactApp/utils/ga';
import { noop, validateEmail } from 'reactApp/utils/helpers';
import { formatPhone, isValidPhone, processPhone } from 'reactApp/utils/phoneHelpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './BuyBusinessDialog.css';
import { requestBusinessTariff } from './BuyBusinessDialog.helpers';

type INPUT_TYPES = 'name' | 'login' | 'phone';
type ERRORS_TYPE = { [x in INPUT_TYPES]?: null | string } & { apiError?: string | null };

const ERROR_LIST = {
    emptyField: 'Заполните поле',
    badValue: 'Проверьте правильность заполнения полей, выделенных красным',
    apiError: 'Произошла ошибка',
    doubleError: 'Превышено количество попыток. Попробуйте позже',
};

const sendGa = createGaSender('buybusiness');

const validateName = (name): string => {
    if (name?.length <= 0) {
        return ERROR_LIST.emptyField;
    }

    return '';
};

const validateLogin = (login): string => {
    if (login?.length <= 0) {
        return ERROR_LIST.emptyField;
    }

    if (!validateEmail(login)) {
        return ERROR_LIST.badValue;
    }

    return '';
};

const validatePhone = (phone): string => {
    if (phone?.length <= 0) {
        return ERROR_LIST.emptyField;
    }

    if (!isValidPhone(processPhone(phone))) {
        return ERROR_LIST.badValue;
    }

    return '';
};

interface Props {
    onClose();
}

type State = {
    [x in INPUT_TYPES]?: null | string;
} & {
    errors: ERRORS_TYPE;
    apiResult: boolean;
};

export class BuyBusinessDialog extends PureComponent<Props, State> {
    private contentRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        onActionClick: () => Promise.resolve(''),
        onClose: noop,
        onShowDialog: noop,
        onHideDialog: noop,
        onSelect: () => Promise.resolve({ disableAction: false }),
        onMakeNewFolder: () => Promise.resolve(''),
        actionId: '',
        disableSelectDirectParentOfSelected: false,
        closeOnAction: false,
    };

    public state = {
        name: '',
        login: '',
        phone: '',
        errors: {} as ERRORS_TYPE,
        apiResult: false,
    };

    public componentDidMount() {
        scrollLock(this.contentRef.current);
        sendGa('show');
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.contentRef.current);
    }

    private handleActionClick = () => {
        const { name, login, phone } = this.state;

        const res = this.validateInput();

        if (!res) {
            return;
        }

        sendGa('send');

        const errors = this.state.errors;

        requestBusinessTariff({ name, login, phone })
            .then(({ data } = {}) => {
                if (data?.status === 'ok') {
                    sendGa('success', null, null, true);
                    this.setState({
                        errors: {},
                        apiResult: true,
                    });
                } else {
                    sendGa('fail');

                    this.setState({
                        errors: {
                            ...errors,
                            apiError: data?.errors?.form === 'double' ? ERROR_LIST.doubleError : ERROR_LIST.apiError,
                        },
                    });
                }
            })
            .catch(() => {
                sendGa('error');

                this.setState({
                    errors: {
                        ...errors,
                        apiError: ERROR_LIST.apiError,
                    },
                });
            });
    };

    private handleClose = () => {
        this.props.onClose();
    };

    private validateInput = (id?: string): boolean => {
        const { name, login, phone, errors } = this.state;

        const errorsNew = {
            ...errors,
            name: id !== 'name' && !!id ? errors.name : validateName(name),
            login: id !== 'login' && !!id ? errors.login : validateLogin(login),
            phone: id !== 'phone' && !!id ? errors.phone : validatePhone(phone),
        };

        this.setState({
            errors: errorsNew,
        });

        // true if no errors
        return !Object.values(errorsNew).join('');
    };

    private handleInputChange = (event) => {
        const { id, value } = event.target || {};
        if (id as INPUT_TYPES) {
            const newVal = {
                [id]: id === 'phone' ? formatPhone(value) : value,
            } as { [x in INPUT_TYPES]: undefined | null | string };
            this.setState(newVal);
        }
    };

    private handleInputKeyDown = (event) => {
        const { id } = event.target || {};
        const errors = this.state.errors;

        if (errors[id] || errors.apiError) {
            this.setState({
                errors: {
                    ...errors,
                    [id]: null,
                    apiError: null,
                },
            });
        }
    };

    private handleOnBlur = (event) => {
        this.validateInput(event.target?.id);
    };

    private handlePhoneFocus = () => {
        const { phone } = this.state;

        if (!phone) {
            this.setState({ phone: '+7' });
        }
    };

    private renderForm = (): ReactElement | null => {
        const { name, login, phone, errors } = this.state;

        return (
            <>
                <input
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    className={classNames({ [styles.input]: true, [styles.input_error]: !!errors.name })}
                    onBlur={this.handleOnBlur}
                    autoComplete="off"
                    placeholder="Ваше имя"
                    value={name}
                    id="name"
                />
                <input
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    onBlur={this.handleOnBlur}
                    className={classNames({ [styles.input]: true, [styles.input_error]: !!errors.login })}
                    autoComplete="off"
                    placeholder="e-mail"
                    value={login}
                    id="login"
                />
                <input
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    onBlur={this.handleOnBlur}
                    onFocus={this.handlePhoneFocus}
                    className={classNames({ [styles.input]: true, [styles.input_error]: !!errors.phone })}
                    autoComplete="off"
                    placeholder="Введите номер телефона"
                    value={phone}
                    id="phone"
                    type="tel"
                />
                <div className={styles.errorText}>{errors.name || errors.login || errors.phone || errors.apiError || ''}</div>
                <Button
                    className={styles.button}
                    theme="octavius"
                    size="big"
                    primary
                    onClick={this.handleActionClick}
                    fluid
                    data-name="confirm"
                >
                    {'Отправить заявку'}
                </Button>
            </>
        );
    };

    public render(): ReactElement | null {
        const { apiResult } = this.state;

        return (
            <Dialog
                open
                closeOnDimmerClick={false}
                onCancel={this.handleClose}
                disableScrollOnBody={false}
                id="buy-business-dialog"
                className={styles.root}
                size="small"
            >
                <Content isModal isLoading={false} hasError={false} scrolling>
                    <div className={styles.content} ref={this.contentRef}>
                        {!apiResult ? (
                            this.renderForm()
                        ) : (
                            <div className={styles.success}>
                                <div className={styles.title}>Спасибо</div>
                                <div className={styles.description}>
                                    Мы получили вашу заявку и обязательно свяжемся с&nbsp;вами в&nbsp;ближайшее время
                                </div>
                                <Button
                                    className={styles.button}
                                    theme="octavius"
                                    size="big"
                                    primary
                                    onClick={this.handleClose}
                                    fluid
                                    data-name="close"
                                >
                                    Понятно, спасибо
                                </Button>
                            </div>
                        )}
                    </div>
                </Content>
            </Dialog>
        );
    }
}
