import { type Partner, EPartner } from './subscriptions.types';

export const PARTNER_TITLE: Record<Partner, string> = {
    [EPartner.mail]: 'VK\u00A0Combo',
    [EPartner.rostelecom]: 'Ростелеком',
    [EPartner.tele2]: 't2',
    [EPartner.megafon]: 'Мегафон',
    [EPartner.sferum]: 'Сферум',
};

export const PARTNER_LIST = Object.keys(PARTNER_TITLE);
