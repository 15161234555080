/* eslint-disable sonarjs/no-identical-functions */
import type { PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { AddItemToFavoriteAPICall } from 'reactApp/api/albums/AddItemToFavoriteAPICall';
import { RemoveItemFromFavoritesAPICall } from 'reactApp/api/albums/RemoveItemFromFavoritesAPICall';
import { getFavoritesAddAPICall } from 'reactApp/api/FavoritesAddAPICall';
import { getFavoritesDeleteAPICall } from 'reactApp/api/FavoritesDeleteAPICall';
import { getCurrentAlbum } from 'reactApp/modules/albums/albums.selector';
import {
    addToFavoritesSuccess,
    modifyingStart,
    modifyingStop,
    removeFromFavoritesSuccess,
} from 'reactApp/modules/modifying/modifying.actions';
import { type IAddToFavoritesRequest, type IRemoveFromFavoritesRequest, EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import type { SearchFile } from 'reactApp/modules/search/search.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { all, put, select } from 'redux-saga/effects';

const removeFromFavoritesApiCall = ({ id }): any => getFavoritesDeleteAPICall().makeRequest({ path: id });
const addToFavoritesApiCall = ({ id }): any => getFavoritesAddAPICall().makeRequest({ path: id });
const addAlbumItemToFavorite = ({ id, albumId }): any => new AddItemToFavoriteAPICall().makeRequest({ element_id: id, album_id: albumId });
const removeItemFromFavorites = ({ id, albumId }): any =>
    new RemoveItemFromFavoritesAPICall().makeRequest({ element_id: id, album_id: albumId });

const UNKNOWN_ERROR = 'unknown error';

function addToFavorites(id) {
    return addToFavoritesApiCall({ id })
        .then((response) => ({ data: response.data }))
        .catch((error) => ({ error: error?.response?.body || error?.response?.error || UNKNOWN_ERROR }));
}

function removeFromFavorites(id) {
    return removeFromFavoritesApiCall({ id })
        .then((response) => ({ data: response.data }))
        .catch((error) => ({ error: error?.response?.body || error?.response?.error || UNKNOWN_ERROR }));
}

function addToAlbumFavorites(id, albumId) {
    return addAlbumItemToFavorite({ id, albumId })
        .then((response) => ({ data: response.data }))
        .catch((error) => ({ error: error?.response || UNKNOWN_ERROR }));
}

function removeFromAlbumFavorites(id, albumId) {
    return removeItemFromFavorites({ id, albumId })
        .then((response) => ({ data: response.data }))
        .catch((error) => ({ error: error?.response?.body || UNKNOWN_ERROR }));
}

export function* processAddToFavotitesItems(items, newIds) {
    const addedIds = items.reduce((result, item, index) => {
        if (!newIds[index]?.error) {
            result.push(item.id);
        }

        return result;
    }, []);

    if (addedIds.length !== items.length) {
        yield put(
            showSnackbarAction({
                closable: true,
                id: 'add-to-fav',
                text: 'Не удалось добавить в Избранное',
                type: SnackbarTypes.failure,
            })
        );
    }

    yield put(addToFavoritesSuccess({ ids: addedIds }));
}

export function* handleAddToFavoritesItems(action: PayloadAction<IAddToFavoritesRequest>) {
    const { items, from } = action.payload;
    const storage = yield select(getCurrentStorage);
    const currentAlbum = yield select(getCurrentAlbum);
    const albumId = currentAlbum?.id;
    const { query, xPageId, xReqId } = yield select(getSearchRequestParams);

    const files = items as SearchFile[];

    sendPaymentGa({ eventCategory: ECategoryGa.favorites, action: 'append', type_for: from, section: storage });

    if (storage === EStorageType.search) {
        const items = files.map(({ nameWithoutExt, id, pos, kind }) => ({
            file_name: nameWithoutExt,
            file_id: id,
            pos,
            type: kind,
        }));
        const searchParams = { req_id: xReqId, page_id: xPageId, search_phrase: query };
        let dwhData: any = {
            action: 'append',
            searchParams,
            items,
        };

        if (from === 'toolbar') {
            dwhData = { ...dwhData, eventCategory: ECategoryGa.toolbar_search, count_files: files.length };
        } else {
            dwhData = { ...dwhData, eventCategory: ECategoryGa.favoritesSearch };
        }
        newSendSearchRadarAnalytics({ ...dwhData });
    }

    yield put(modifyingStart());

    try {
        const newIds = yield all(
            items.map((item) => (storage === EStorageType.albums ? addToAlbumFavorites(item.id, albumId) : addToFavorites(item.id)))
        );

        yield processAddToFavotitesItems(items, newIds);

        yield put(modifyingStop({ reason: EModifyReason.favorites }));
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(modifyingStop({ reason: EModifyReason.favorites }));
    }
}

export function* processRemoveFromFavotitesItems(items, newIds) {
    const storage = yield select(getCurrentStorage);

    // eslint-disable-next-line sonarjs/no-identical-functions
    const removedIds = items.reduce((result, item, index) => {
        if (!newIds[index]?.error) {
            result.push(item.id);
        }

        return result;
    }, []);

    if (removedIds.length !== items.length) {
        yield put(
            showSnackbarAction({
                closable: true,
                id: 'remove-from-fav',
                text: 'Не удалось удалить из Избранного',
                type: SnackbarTypes.failure,
            })
        );
    }

    yield put(removeFromFavoritesSuccess({ ids: removedIds }));

    if (storage === EStorageType.favorites) {
        yield put(resetSelect());
    }
}

export function* handleRemoveFromFavoritesItems(action: PayloadAction<IRemoveFromFavoritesRequest>) {
    const { items } = action.payload;
    const currentAlbum = yield select(getCurrentAlbum);
    const storage = yield select(getCurrentStorage);
    const albumId = currentAlbum?.id;

    yield put(modifyingStart());

    try {
        const newIds = yield all(
            items.map((item) =>
                storage === EStorageType.albums ? removeFromAlbumFavorites(item.id, albumId) : removeFromFavorites(item.id)
            )
        );

        yield processRemoveFromFavotitesItems(items, newIds);

        yield put(modifyingStop({ reason: EModifyReason.favorites }));
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(modifyingStop({ reason: EModifyReason.favorites }));
    }
}
