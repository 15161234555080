import { groupBy } from 'ramda';
import {
    getCurrentUpload,
    isFileAutoFixError,
    isFileComplete,
    isFileCompleteWithError,
    isFileDeleting,
    isFileProgress,
    isFileWarning,
    isFolder,
    isShowErrorIcon,
    isShowInfoIcon,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { fileInFolder, folderCloudPath, folderName } from 'reactApp/modules/uploadList/uploadList.helpers';
import { removeEmptyFields } from 'reactApp/utils/objectHelpers';

import { type IInputFile, type INormalizedInputFile, type IUploadListState, EFileError, EFileStatus } from './uploadList.model';

const updateItemProgress = (item: IInputFile) => {
    if (!item || isFileComplete(item)) {
        return;
    }

    const commonSize = item.size ?? 0;
    const uploadedFolderSize = item.uploadedSize ?? 0;
    const leftDownloadSize = commonSize - uploadedFolderSize;
    const percentPast = leftDownloadSize / (commonSize / 100);
    const progress = Math.floor(100 - percentPast);

    item.progress = Number.isFinite(progress) ? progress : item.progress;

    if (!isFileProgress(item) && !isFileWarning(item)) {
        item.status = EFileStatus.PROGRESS;
    }

    if (!item.currentUpload) {
        item.currentUpload = true;
    }
};

const updateSingleFile = (state: IUploadListState, storedItem: IInputFile) => {
    const groupedByFolder = state.groupedFiles;

    if (isFileDeleting(storedItem)) {
        delete groupedByFolder[storedItem.descriptorId];
    } else {
        if (isFileProgress(storedItem) && getCurrentUpload(storedItem) === undefined) {
            storedItem.currentUpload = true;
        }

        groupedByFolder[storedItem.descriptorId] = {
            ...groupedByFolder[storedItem.descriptorId],
            ...removeEmptyFields(storedItem),
        };
    }
};

// проверяем данные файлов внутри папки
const updateFolderByFiles = (groupedByFolderItem: IInputFile, newItems?: IInputFile[]) => {
    const filesArr = newItems ?? Object.values(groupedByFolderItem.files || []);
    let hasHighlight = false;
    let doneElements = 0;
    let doneWithErrorsElements = 0;
    let hasWarningFile = newItems ? groupedByFolderItem.status === EFileStatus.WARNING : false;
    let hasErrorFile = newItems ? groupedByFolderItem.error === EFileError.ERROR_IN_FOLDER : false;
    let hasInfoFile = newItems ? groupedByFolderItem.error === EFileError.INFO_IN_FOLDER : false;
    let hasFileInProgress = newItems ? groupedByFolderItem.status === EFileStatus.WARNING : false;
    let sizeLoaded = 0; // считаем сколько байт загружено
    if (!newItems) {
        groupedByFolderItem.size = 0;
        groupedByFolderItem.uploadedSize = 0;
        groupedByFolderItem.highlight = false;
    }

    if (groupedByFolderItem.uploadedSize === undefined) {
        groupedByFolderItem.uploadedSize = 0;
    }

    // TODO: для больших папок > 1000 кешировать или считать в определенный момент
    filesArr.forEach((file) => {
        const currentFolderSize = groupedByFolderItem.size ?? 0;
        const fileSize = file.size && file.size > 0 ? file.size : 0;
        const fileLoaded = file.loaded && file.loaded > 0 ? file.loaded : 0;

        groupedByFolderItem.size = currentFolderSize + fileSize;

        if (file.highlight) {
            hasHighlight = true;
        }

        if (isFileComplete(file)) {
            doneElements++;
        }

        if (isFileCompleteWithError(file)) {
            doneWithErrorsElements++;
        }

        if (isFileWarning(file)) {
            hasWarningFile = true;
        }

        if (isShowErrorIcon(file)) {
            hasErrorFile = true;
        }

        if (isShowInfoIcon(file)) {
            hasInfoFile = true;
        }

        if (isFileProgress(file)) {
            hasFileInProgress = true;
        }

        sizeLoaded += fileLoaded;
    });
    groupedByFolderItem.highlight = groupedByFolderItem.highlight || hasHighlight;
    groupedByFolderItem.uploadedSize += sizeLoaded;

    if (hasWarningFile) {
        groupedByFolderItem.status = EFileStatus.WARNING;
    } else if (hasFileInProgress && groupedByFolderItem.status !== EFileStatus.CANCEL) {
        groupedByFolderItem.status = EFileStatus.PROGRESS;
    }

    if (hasErrorFile) {
        groupedByFolderItem.error = EFileError.ERROR_IN_FOLDER;
    } else if (hasInfoFile) {
        groupedByFolderItem.error = EFileError.INFO_IN_FOLDER;
    } else {
        groupedByFolderItem.error = undefined;
    }

    // в случае пустой папки doneElements === doneWithErrorsElements === 0, поэтому проверяем allFilesInFolder.length
    const allFilesInFolderDone = doneElements === filesArr.length;
    const allFilesInFolderError = filesArr.length && groupedByFolderItem.files?.length && doneWithErrorsElements === filesArr.length;

    if (!newItems) {
        const folderCloudPathNew = folderCloudPath(filesArr[0]?.cloudPath);
        if (folderCloudPathNew && groupedByFolderItem.folderCloudPath !== folderCloudPathNew) {
            groupedByFolderItem.folderCloudPath = folderCloudPathNew;
        }
    }

    if (allFilesInFolderError || allFilesInFolderDone) {
        let status = EFileStatus.NONE;

        if (allFilesInFolderError) {
            status = EFileStatus.ERROR;
        } else if (allFilesInFolderDone) {
            status = EFileStatus.DONE;
        }

        groupedByFolderItem.status = status;
        groupedByFolderItem.progress = 100;
        groupedByFolderItem.currentUpload = false;
    }

    updateItemProgress(groupedByFolderItem);
};

const updateFolderByFilesOptimized = (state: IUploadListState, rootFolderName, storedItems: IInputFile[], onlyNewFilesAdd = false) => {
    const groupedByFolder = state.groupedFiles;
    let groupedByFolderItem = groupedByFolder[rootFolderName];

    if (!groupedByFolderItem?.files) {
        const files: INormalizedInputFile = {};
        files[storedItems[0].descriptorId] = storedItems[0];

        groupedByFolder[rootFolderName] = {
            cloudPath: rootFolderName,
            descriptorId: rootFolderName,
            folderCloudPath: folderCloudPath(storedItems[0].cloudPath),
            localPath: rootFolderName,
            name: rootFolderName,
            extension: 'folder',
            thumb: '',
            uploadedSize: 0,
            progress: 0,
            currentUpload: true,
            uploadPacket: state.progress.uploadPacket,
            size: 0,
            files,
        };

        groupedByFolderItem = groupedByFolder[rootFolderName];
    }

    storedItems.forEach((storedItem) => {
        if (!groupedByFolderItem.files) {
            return;
        }
        if (isFileDeleting(storedItem)) {
            delete groupedByFolderItem.files[`${storedItem.descriptorId}`];
        } else {
            groupedByFolderItem.files[`${storedItem.descriptorId}`] = {
                ...removeEmptyFields(storedItem),
            };
        }
    });

    updateFolderByFiles(groupedByFolderItem, onlyNewFilesAdd ? storedItems : undefined);
};

const updateFolder = (state: IUploadListState, storedItem: IInputFile) => {
    const groupedByFolder = state.groupedFiles;
    const itemSize = storedItem.size && storedItem.size > 0 ? storedItem.size : 0;
    const folder = folderName(storedItem.localPath) || storedItem.localPath;
    const previousSize = storedItem.size ?? 0;

    if (isFileDeleting(storedItem)) {
        delete groupedByFolder[folder];
    } else {
        groupedByFolder[folder] = {
            ...groupedByFolder[folder],
            ...storedItem,
            size:
                storedItem.folderCloudPath !== groupedByFolder[folder]?.folderCloudPath || !previousSize
                    ? previousSize + itemSize
                    : previousSize,
        };

        if (!groupedByFolder[folder].folderCloudPath) {
            groupedByFolder[folder].folderCloudPath = storedItem.cloudPath;
        }

        updateItemProgress(groupedByFolder[folder]);

        const { files, hideError } = groupedByFolder[folder];

        if (files) {
            groupedByFolder[folder].files = Object.keys(files).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: {
                        ...files[key],
                        hideError,
                    },
                }),
                {}
            );
        }
    }
};

const needSkipFile = (state: IUploadListState, file: IInputFile, status: EFileStatus = EFileStatus.NONE) => {
    const needCancel = [EFileStatus.CANCEL, EFileStatus.SKIP, EFileStatus.ERROR].includes(status || EFileStatus.NONE);

    if (!file || !needCancel) {
        return;
    }

    if (!file.status) {
        file.status = EFileStatus.CANCEL;
    }

    if (!file.error) {
        file.error = EFileError.SKIPPED_FILE;
    }

    file.currentUpload = false;
};

export const findFullItem = (state: IUploadListState, payloadItem: IInputFile) => {
    const groupedByFolder = state.groupedFiles;
    const groupedFilesById = state.groupedFilesById;
    const payloadDescriptorId = payloadItem.descriptorId;
    const payloadLocalPath = payloadItem.localPath;
    let storedItem: IInputFile | undefined;

    if (!groupedByFolder) {
        return undefined;
    }

    const isSingleItem = groupedByFolder[payloadDescriptorId];

    if (isSingleItem) {
        storedItem = isSingleItem;
    }

    const isFolderItem = groupedByFolder[folderName(payloadLocalPath)];

    if (isFolder(payloadItem) && isFolderItem) {
        storedItem = isFolderItem;
    }

    if (!storedItem) {
        // поиск по descriptorId среди папок, если нет localPath
        storedItem = groupedFilesById[payloadDescriptorId];
    }

    if (!storedItem) {
        // ищем среди файлов в папках
        Object.values(groupedByFolder).forEach((folder) => {
            const folderFiles = folder.files;

            if (!folderFiles || storedItem) {
                return;
            }

            const item = folderFiles[payloadDescriptorId];

            if (item) {
                storedItem = item;
            } else {
                storedItem = groupedFilesById[payloadDescriptorId];
            }
        });
    }

    return storedItem;
};

export const setGroupedByFolderInputFiles = (state: IUploadListState, payload, onlyNewFilesAdd = false) => {
    const forUpdateFolders: IInputFile[] = [];

    payload.forEach((payloadItem) => {
        const fullItem = findFullItem(state, payloadItem);

        // не затираем ошибку, если это autoFix
        if (isFileAutoFixError(fullItem) && payloadItem?.error === '') {
            delete payloadItem.error;
        }

        const storedItem: IInputFile = {
            ...fullItem,
            ...payloadItem,
            uploadPacket: state.progress.uploadPacket,
        };

        if (isFolder(storedItem)) {
            updateFolder(state, storedItem);
        } else if (fileInFolder(storedItem?.localPath)) {
            forUpdateFolders.push(storedItem);
        } else {
            updateSingleFile(state, storedItem);
        }

        needSkipFile(state, storedItem, payloadItem.status);
    });

    const list = groupBy<IInputFile, string>((item) => folderName(item.localPath), forUpdateFolders);
    for (const rootFolderName of Object.keys(list)) {
        const group = list[rootFolderName];
        updateFolderByFilesOptimized(state, rootFolderName, group, onlyNewFilesAdd);
    }
};

export const applyPublishInfo = (state: IUploadListState, ids: string[], publish = false) => {
    const itemsByPath = Object.values(state.groupedFiles).reduce((result, item) => {
        if (!publish) {
            result[item.cloudPath] = item;
        }

        if (item.folderCloudPath && item.files) {
            result[item.folderCloudPath] = item;
        }

        return result;
    }, {});

    ids.forEach((id) => {
        const item = itemsByPath[id];

        if (!item) {
            return;
        }

        setGroupedByFolderInputFiles(state, [
            {
                descriptorId: item.descriptorId,
                cloudPath: item.id,
                publish,
            },
        ]);
    });
};
