import { clearItemFromLocalStorage, getItemFromLocalStorage, setItemFromLocalStorage } from 'reactApp/appHelpers/localstorageHelpers';
import { store } from 'reactApp/store';

export const BIZ_OVERQUOTE_MESSAGE_KEY = 'biz.overquote.message';

export const getOverquotaMessage = () => store.dispatch(getItemFromLocalStorage(BIZ_OVERQUOTE_MESSAGE_KEY));

export const setOverquotaMessage = (message: string | null) =>
    store.dispatch(setItemFromLocalStorage<typeof message>(BIZ_OVERQUOTE_MESSAGE_KEY, message));

export const clearOverquotaMessage = () => store.dispatch(clearItemFromLocalStorage(BIZ_OVERQUOTE_MESSAGE_KEY));

export const getMessageInfo = (usedPercent: number): string | null => {
    if (usedPercent > 100) {
        return 'Ваше хранилище переполнено. Удалите ненужные файлы или напишите администратору с просьбой увеличить размер хранилища';
    }

    if (usedPercent === 100) {
        return 'Ваше хранилище заполнено. Удалите ненужные файлы или напишите администратору с просьбой увеличить размер хранилища';
    }

    if (usedPercent >= 80) {
        return 'Ваше хранилище почти заполнено. Удалите ненужные файлы или напишите администратору с просьбой увеличить размер хранилища';
    }

    return null;
};
