import classNames from 'clsx';
import React, { type ReactElement } from 'react';

import styles from './User.css';
import { useWeblinkAccessRights } from './User.hooks';
import type { IProps } from './User.types';

export const WeblinkUser = React.memo(function WeblinkUser(props: IProps): ReactElement | null {
    const { user, isOwner = false, item, isPhone, isReadOnly } = props;

    const userBlock = useWeblinkAccessRights({ user, item, isPhone, isOwner, isReadOnly });

    if (!user) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_phone]: isPhone,
                [styles.root_withDropdown]: !isOwner,
            })}
            data-qa-email={user.email}
            data-qa-status={user.status}
        >
            <img src={user.avatarUrl} className={styles.avatar} />
            <div className={styles.userContainer}>
                <div className={styles.info}>
                    {user.name && <div className={styles.name}>{user.name}</div>}
                    <div className={styles.email}>{user.email}</div>
                </div>
                {userBlock}
            </div>
        </div>
    );
});
