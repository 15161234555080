import React, { type ClipboardEvent, type MouseEventHandler, memo, useCallback, useEffect, useRef, useState } from 'react';
import { KeyCodes } from 'reactApp/constants/KeyCodes';
import { execCommand, getClipboadText } from 'reactApp/utils/helpers';

import styles from './ContentEditable.css';

interface IProps {
    value?: string;
    onEdit?: (value: { value: string }) => Promise<any>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    selectAllOnFocus?: boolean;
}

export const ContentEditable = memo<IProps>(({ value = '', onEdit, onClick, selectAllOnFocus = false }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [lastValue, setLastValue] = useState<string | null>(null);

    const getText = () => ref.current?.textContent || '';

    useEffect(() => {
        ref.current?.focus();

        if (selectAllOnFocus && ref?.current) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(ref.current);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    }, []);

    const emitChange = useCallback(() => {
        const currentValue = getText();

        if (onEdit && currentValue !== lastValue) {
            onEdit({ value: currentValue })
                .then(() => {
                    setLastValue(value);
                })
                .catch(() => {
                    if (ref.current) {
                        ref.current.textContent = value;
                    }
                });
        }
    }, [getText, setLastValue, value, ref.current, lastValue]);

    const handleKeyDown = useCallback(
        (event) => {
            const keyCode = event.keyCode || event.which;

            if (keyCode === KeyCodes.DOM_VK_ENTER || keyCode === KeyCodes.DOM_VK_RETURN) {
                event.returnValue = false;
                if (event.preventDefault) {
                    event.preventDefault();
                    event.target?.blur();
                }
            }

            if (keyCode === KeyCodes.DOM_VK_ESCAPE) {
                event.returnValue = false;
                if (event.preventDefault && ref.current) {
                    event.preventDefault();
                    ref.current.textContent = value;
                    event.target.blur();
                }
            }
        },
        [ref.current, value]
    );

    const handlePaste = useCallback((event: ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const text = getClipboadText(event);
        if (!text) {
            return;
        }
        execCommand('insertText', text.replace(/[\r\n]+/gm, ''));
    }, []);

    return (
        <div
            className={styles.root}
            id="contenteditable"
            ref={ref}
            onBlur={emitChange}
            onPaste={handlePaste}
            contentEditable
            onKeyDown={handleKeyDown}
            onClick={onClick}
            suppressContentEditableWarning={true}
        >
            {value}
        </div>
    );
});

ContentEditable.displayName = 'ContentEditable';
