import { sort as rSort } from 'ramda';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import type { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import type { Sort } from 'reactApp/types/Tree';

export const simpleCompare = (a: number | string, b: number | string) => {
    if (a === b) {
        return 0;
    }
    return a < b ? -1 : 1;
};
export const intlStringCompare = typeof Intl !== 'undefined' ? new Intl.Collator(['en-u-kn-true']).compare : simpleCompare;

export const itemSortKey = 'itemsSort';

export const sortItems = (items: CloudItem[] | IArchiveItem[], sort: Sort) => {
    if (!items || !items.length) {
        return items;
    }

    const order = sort.order === ESortOder.asc ? 1 : -1;

    const compare = (itemA, itemB, sortType) => {
        if (sortType === ESortTypes.unmountedFirst && isMountedFolder(itemA) !== isMountedFolder(itemB)) {
            return !isMountedFolder(itemA) ? -1 : 1;
        } else if (itemA.isFolder !== itemB.isFolder) {
            return itemA.isFolder ? -1 : 1;
        }

        let propertyA = itemA.name || '';
        let propertyB = itemB.name || '';
        let compareProperties: typeof simpleCompare | typeof intlStringCompare = simpleCompare;

        let sortOrder = order;

        switch (sortType) {
            case ESortTypes.mtime:
                if (itemA.isFolder || itemB.isFolder) {
                    // у папок даты нет, их по имени сортируем
                    sortOrder = 1;

                    if (intlStringCompare) {
                        compareProperties = intlStringCompare;
                    } else {
                        propertyA = propertyA.toLowerCase();
                        propertyB = propertyB.toLowerCase();
                    }
                } else {
                    propertyA = itemA.mtime;
                    propertyB = itemB.mtime;
                }
                break;
            case ESortTypes.deletedAt: {
                if (itemA.deletedAt && itemB.deletedAt) {
                    propertyA = itemA.deletedAt;
                    propertyB = itemB.deletedAt;
                }
                break;
            }
            case ESortTypes.size: {
                propertyA = typeof itemA.size === 'number' ? itemA.size : itemA.size?.original;
                propertyB = typeof itemB.size === 'number' ? itemB.size : itemB.size?.original;
                break;
            }
            case ESortTypes.publicExpires: {
                if (itemA.weblinkExpires && itemB.weblinkExpires) {
                    propertyA = itemA.weblinkExpires;
                    propertyB = itemB.weblinkExpires;
                }
                break;
            }
            default:
                if (intlStringCompare) {
                    compareProperties = intlStringCompare;
                } else {
                    propertyA = propertyA.toLowerCase();
                    propertyB = propertyB.toLowerCase();
                }
                break;
        }

        if (propertyA === propertyB) {
            return 0;
        }

        return compareProperties(propertyA, propertyB) * sortOrder;
    };

    return rSort<CloudItem | IArchiveItem>((itemA, itemB) => {
        const res = compare(itemA, itemB, sort.type);

        return res !== 0 ? res : compare(itemA, itemB, ESortTypes.name);
    }, items);
};
