import React, { type FC, useEffect } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import {
    createDocumentByLinkDialogAnalytics,
    sendCreateDocumentByLinkDialogRadar,
} from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.analytics';
import { createDocumentByLinkFeature } from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.helpers';

import styles from './CreateDocumentByLinkDialog.css';

interface Props {
    onClose: () => void;
    onSuccess: () => void;
    isFromExternal?: string;
}

const QA_ID = 'create-document-by-link';
const content = () => (
    <div className={styles.text} data-qa-id={`${QA_ID}_content`}>
        {createDocumentByLinkFeature.content}
    </div>
);
const header = () => (
    <div className={styles.text} data-qa-id={`${QA_ID}_header`}>
        {createDocumentByLinkFeature.header}
    </div>
);

export const CreateDocumentByLinkDialog: FC<Props> = ({ onClose, onSuccess, isFromExternal: isFromExternalQueryParam }) => {
    useEffect(() => {
        sendCreateDocumentByLinkDialogRadar({
            i: createDocumentByLinkDialogAnalytics.LINK_OPEN,
            isLlmOpen: Boolean(isFromExternalQueryParam),
        });
    }, [isFromExternalQueryParam]);

    return (
        <BaseConfirmDialog
            dataQAId={QA_ID}
            renderContent={content}
            renderHeader={header}
            buttons={[EButtonType.accept, EButtonType.cancel]}
            successButtonText="Создать"
            cancelButtonText="Не создавать"
            onSuccess={onSuccess}
            onClose={onClose}
        />
    );
};
