import { useSelector } from 'react-redux';
import { hideDownloadItemSize, isEmptyFolder } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { attachesCloneLimit } from 'reactApp/appHelpers/featuresHelpers';
import { BREAKPOINT_XS } from 'reactApp/constants/breakpoints';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { getFilesWithSelectedFace } from 'reactApp/modules/faces/faces.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getCurrentTotalCount,
    getItemsTotalCount,
    getStorageCurrentFolder,
    getStorageSelectedItems,
} from 'reactApp/modules/storage/storage.selectors';
import { type CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getIsLetterGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import type { RootState } from 'reactApp/store';
import type { ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import {
    actionsOrder,
    actionsOrder_EbookReader,
    actionsOrder_PdfEdit,
    moreListOrder,
    TOOLBAR_CONFIG,
} from 'reactApp/ui/Toolbar/Toolbar.config';
import { getCloneItem, getDownloadItem, getSelectItem, toolbarDropdownItems, toolbarItems } from 'reactApp/ui/Toolbar/Toolbar.data';
import { type TToolbarItem, ToolbarContextEnum } from 'reactApp/ui/Toolbar/Toolbar.types';

const getPriority = (item) => (typeof item == 'number' ? item : undefined);

const checkAttachesCloneLimit = (storage: EStorageType, selectedCount: number) =>
    storage !== EStorageType.attaches || !attachesCloneLimit || selectedCount <= attachesCloneLimit;

export const useActions = ({
    storage,
    toolbarType,
    downloadButtonRef,
    isEbookReader,
}: {
    storage: EStorageType;
    toolbarType?: ToolbarContextEnum;
    downloadButtonRef?: React.RefObject<HTMLDivElement>;
    isEbookReader?: boolean;
}): TToolbarItem[] => {
    const settings = TOOLBAR_CONFIG[storage];

    const items: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const filesWithFaces = useSelector(getFilesWithSelectedFace);
    const quotaCleanerIsLetterGroup = useSelector((state: RootState) => getIsLetterGroup(state));
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

    const { isHome, isQuotaCleaner, isPublic, isStock } = getStorage(storage);
    const isRootHome = isHome && currentFolder?.id === ROOT_FOLDER_ID;
    const totalItemsCount = useSelector((state: RootState) => getCurrentTotalCount(state, undefined, undefined));
    const selectedCount = items.length;
    const allSelected = !!selectedCount && selectedCount === totalItemsCount;

    const itemsAllCount = useSelector(getItemsTotalCount);

    const availableActions = useActionsConfig();
    const { windowWidth } = useWindowSize();

    if (!settings || toolbarType === ToolbarContextEnum.pageContentContext) {
        return [];
    }

    const actionsOrderList = isEbookReader
        ? actionsOrder_EbookReader
        : storage === EStorageType.pdfEdit
        ? actionsOrder_PdfEdit
        : actionsOrder;

    const actions: TToolbarItem[] = actionsOrderList
        .filter((action) => availableActions[action] && Boolean(toolbarItems[action]) && Boolean(settings[action]))
        .map((action) => {
            let item: TToolbarItem = toolbarItems[action];

            if (action === EActions.download) {
                const isDownloadOff = isQuotaCleaner && quotaCleanerIsLetterGroup;
                if (isDownloadOff || (items?.length > 0 && hideDownloadItemSize(items)) || isEmptyFolder(currentFolder)) {
                    return null;
                }

                item = getDownloadItem(items, filesWithFaces, currentFolder as CloudItem, isRootHome, downloadButtonRef, isEbookReader);
            } else if (action === EActions.selectAll) {
                item = getSelectItem({ allSelected, itemsAllCount, itemsCount: selectedCount });
            } else if (action === EActions.clone) {
                if (!checkAttachesCloneLimit(storage, selectedCount)) {
                    return null;
                }
                item = getCloneItem(isPublic || isStock);
            }

            if (
                ENABLE_FULL_RESPONSIVE &&
                windowWidth <= BREAKPOINT_XS &&
                (action === EActions.remove || action === EActions.publish || action === EActions.share)
            ) {
                return null;
            }

            return {
                ...item,
                priority: getPriority(settings[action]),
            };
        })
        .filter((el) => !!el) as TToolbarItem[];

    const moreList: ListItem[] = moreListOrder
        .filter((action) => availableActions[action] && Boolean(toolbarDropdownItems[action]))
        .map((action) => toolbarDropdownItems[action]);

    if (moreList.length) {
        if (ENABLE_FULL_RESPONSIVE && windowWidth <= BREAKPOINT_XS) {
            if (availableActions[EActions.publish]) {
                moreList.push(toolbarItems.publish as ListItem);
            }

            if (availableActions[EActions.share]) {
                moreList.push(toolbarItems.share as ListItem);
            }

            if (availableActions[EActions.remove]) {
                moreList.push(toolbarItems.remove as ListItem);
            }
        }

        actions.push({
            ...toolbarItems[EActions.more],
            list: moreList,
        });
    }

    return actions;
};
