import { BReactComponent } from 'blocks-cloud/b-react-component';
import debounce from 'lodash.debounce';
import type React from 'react';
import { isPromise } from 'reactApp/utils/helpers';

const ModalDialogToolkit: Record<string, BReactComponent> = {};
const elements = {};

interface IParams extends Record<string, any> {
    onSuccess?: (data?: any) => any;
    onClose?: () => void;
}

export const renderModalDialog =
    (WrappedComponent: React.FunctionComponent<any> | React.ComponentClass<any>, name: string) => (params?: IParams) => {
        const { onSuccess, onClose, ...restProps } = params || {};
        const componentName = name || WrappedComponent.name || WrappedComponent.displayName || 'WrappedComponent';

        if (!ModalDialogToolkit[componentName]) {
            ModalDialogToolkit[componentName] = new BReactComponent({
                ReactComponent: WrappedComponent,
            });
        } else {
            return; // Do not try show the same dialog if it has been already open
        }

        if (!elements?.[componentName]) {
            elements[componentName] = document.createElement('div');
        }

        document.body.appendChild(elements[componentName]);

        const destroyDialog = () => {
            if (ModalDialogToolkit[componentName]) {
                ModalDialogToolkit[componentName].destroy();
                ModalDialogToolkit[componentName] = null;
            }
        };

        const onSuccessHandler = debounce(
            (data) => {
                onSuccessHandler.cancel();

                const res = onSuccess?.(data);
                return (isPromise(res) ? res : Promise.resolve(res)).then(destroyDialog);
            },
            250,
            { leading: true }
        );
        const onCloseHandler = debounce(() => {
            onCloseHandler.cancel();

            destroyDialog();
            onClose?.();
        }, 250);

        ModalDialogToolkit[componentName].renderTo(elements[componentName], {
            props: {
                open: true,
                onSuccess: onSuccessHandler,
                onClose: onCloseHandler,
                ...restProps,
            },
        });

        return {
            close: () => {
                onCloseHandler();
            },
            update: (props) => {
                if (ModalDialogToolkit[componentName]) {
                    ModalDialogToolkit[componentName].render({
                        props: {
                            ...restProps,
                            ...props.props,
                        },
                    });
                }
            },
        };
    };
