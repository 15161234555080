import { xray } from 'lib/xray';
import { isEBook } from 'reactApp/modules/file/isEBook';
import { type IPromoEpub, type IPromoSecondClick, EPromoType } from 'reactApp/modules/promo/promo.types';
import { EpubPromoType } from 'reactApp/modules/promo/sagas/epub.saga';
import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import { TipEpubContent } from 'reactApp/ui/Datalist/Item/TipEpubContent';
import { TipSecondClickContent } from 'reactApp/ui/Datalist/Item/TipSecondClickContent';
import { sendXray } from 'reactApp/utils/ga';

interface ITip {
    id: string;
    getTipContent: typeof TipSecondClickContent | typeof TipEpubContent;
    onHideClick: () => void;
    onShow: (id: string) => void;
}

function promoToTip(promo: IPromoSecondClick | IPromoEpub): ITip[] | null {
    return [
        {
            id: promo.promoId,
            getTipContent: promo.type === EPromoType.epubPromo ? TipEpubContent : TipSecondClickContent,
            onHideClick: () => promo.onClose(),
            onShow: (_) => {
                if (promo.type !== EPromoType.epubPromo) {
                    return;
                }
                promo.onShow();
                promo.onMarkShown(EpubPromoType.downloadList);
                sendXray(['datalist', 'ebook', 'tipdownload', 'show']);
            },
        },
    ];
}

export const getSelectedCount = (key: string, columnCount: number): number => {
    switch (key) {
        case 'ArrowDown':
            return columnCount;
        case 'ArrowUp':
            return -columnCount;
        case 'ArrowLeft':
            return -1;
        case 'ArrowRight':
            return 1;
        default:
            return 0;
    }
};

export const logHandler = ({ radars, radarName }: { radars?: string[]; radarName: string }): void => {
    if (radars) {
        for (const element of radars) {
            const r = element.split('=');

            xray.send('datalist', {
                i: {
                    [`${radarName}_${r[0]}`]: Number(r[1]),
                },
            });
        }
    } else {
        xray.send(`datalist_${radarName}`);
    }
};

interface IProps {
    selectedCount: number;
    isDragging: boolean;
    disableTips: boolean;
    promo?: IPromoSecondClick | IPromoEpub;
    selectedItems: (CloudItem | undefined)[];
}

export const getShowPromoTip = ({ selectedCount, isDragging, disableTips, promo, selectedItems }: IProps) => {
    if (!promo || isDragging || disableTips) {
        return null;
    }

    if (promo.type === EPromoType.secondClick && !selectedCount) {
        return null;
    }

    if (promo.type === EPromoType.epubPromo && (selectedItems?.length !== 1 || !selectedItems?.[0] || !isEBook(selectedItems?.[0]))) {
        return null;
    }

    return promoToTip(promo) || null;
};
