import { type MutableRefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBottomMarginAction } from 'reactApp/modules/snackbar/snackbar.actions';

/* При открытии диалога, чтобы снекбары его не перекрывали, а показывались выше открытого диалога.
refOrHeight - ссылка на див, выше которого надо показывать снекбар или сразу его высота.
heightFixPixel - доп. смещение вверх для снекбаров. */
export const useSnackBarBottomMargin = (refOrHeight: MutableRefObject<HTMLDivElement | null> | null | number, heightFixPixel = 0) => {
    const [height, setHeight] = useState<number>(0);
    const dispatch = useDispatch();

    if (!height || (typeof refOrHeight === 'number' && refOrHeight !== height)) {
        const newHeight = typeof refOrHeight === 'number' ? refOrHeight : refOrHeight?.current?.getBoundingClientRect()?.height ?? 0;

        if (newHeight !== height) {
            setHeight(newHeight);
        }
    }

    useEffect(() => {
        if (!height) {
            return;
        }

        dispatch(setBottomMarginAction(height + heightFixPixel));

        return () => {
            if (height) {
                // При закрытии диалога сбрасываем назад.
                dispatch(setBottomMarginAction(0));
            }
        };
    }, [height]);
};
