/* eslint-disable complexity */
import { Button as VKUIButton } from '@vkontakte/vkui';
import api from 'Cloud/Application/api';
import { user } from 'Cloud/Application/User';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DEV, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { agreeUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser, updateUser } from 'reactApp/modules/user/user.thunkActions';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';
import Content from 'reactApp/ui/Content/Content';
import { useCheckFreeSpaceAfterVisibilityChange } from 'reactApp/ui/MailPopup/hooks/useCheckFreeSpaceAfterVisibilityChange';
import { useContent } from 'reactApp/ui/MailPopup/hooks/useContent';
import { useTariffs } from 'reactApp/ui/MailPopup/hooks/useTariffs';
import { Payment } from 'reactApp/ui/MailPopup/Payment/Payment';
import { createGaSender } from 'reactApp/utils/ga';
import { isFramed } from 'reactApp/utils/isFramed';
import opener from 'reactApp/utils/opener';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import { useQueryParams } from './hooks/useQueryParams';
import {
    CloudStockAnalytics,
    E2GBPopupAction,
    sendCloudStockAnalytics,
    useSend2GBPopupAnalytics,
    useSend2GBPopupXray,
} from './MailPopup.analytics';
import styles from './MailPopup.css';

const POPUP_WIDTH = 491;
const POPUP_LA_WIDTH = 420;
// eslint-disable-next-line sonarjs/no-duplicate-string
const sendPopupGa = createGaSender('mail-popup');

enum EPostMessageType {
    load = 'LOAD_SUBMIT',
    size = 'SET_SIZE',
    cancelPayment = 'CANCEL_PAYMENT',
    submitLA = 'SUBMIT_LA',
    cancelLA = 'CANCEL_LA',
}

// eslint-disable-next-line max-lines-per-function
export const MailPopup = memo((): ReactElement | null => {
    const dispatch = useDispatch();

    const {
        maxUploadSize,
        totalUploadSize,
        isFromDnD,
        afterReload,
        isCloudStock,
        isAcceptanceLA,
        productId,
        attachSizeLimit,
        attachSizeLimitForceAb,
    } = useQueryParams();

    const [showPayment, setShowPayment] = useState<boolean>(!!afterReload);

    const [isLACheckboxSelected, setLACheckboxSelected] = useState<boolean>(false);

    // Отслеживаем показывась ли форма оплаты
    const showPaymentChanged = useRef(false);

    // если оплата прошла успешно, то рендерим лоадер в поп-апе
    const [isPaymentSuccess, setPaymentSuccess] = useState<boolean>(false);

    const rootRef = useRef<HTMLDivElement>(null);

    const { isLoading: isUserLoading, isLoaded: isUserLoaded } = useSelector(UserSelectors.getLifeCycleState);
    const { isLoading: isTariffsLoading, isLoaded: isTariffsLoaded } = useSelector(ProductsSelectors.getLifeCycleState);
    const { isLoading: isPaidInfoLoading, isLoaded: isPaidInfoLoaded } = useSelector(PaidInfoSelectors.getLoadingState);
    const isNewbie = useSelector(UserSelectors.isNewbie);
    const isFrozen = useSelector(UserSelectors.isFrozen);
    const isPaidUser = !!useSelector(UserSelectors.isPaidUser);
    const isUserFreeTb = useSelector(UserSelectors.isUserFreeTb);

    const isUserWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);
    const state = useSelector(UserSelectors.getUserData);

    const { space } = state;

    // Берём state.attachSizeLimit, а не state.uploadLimit, так как uploadLimit может
    // быть изменён фичей ab-upload-limit в другом экспе
    let uploadLimit = state.attachSizeLimit;

    // tempexp_17072-start
    // Отключаем логику с ограничением на платниках, чтобы убрать вероятность того, что им покажут окно подписки при повторном открытии
    if (attachSizeLimitForceAb && !isPaidUser) {
        // Чтобы случайно не увеличить реальный лимит, берём минимальный
        uploadLimit = Math.min(uploadLimit, attachSizeLimit);
    }
    // tempexp_17072-end

    const isEnoughSpace = totalUploadSize <= space.remaining.original;
    const canUpload = maxUploadSize <= uploadLimit && isEnoughSpace;

    const tariff = useTariffs({
        quota: totalUploadSize - space.remaining.original,
        isUserWithoutPayment,
        isUserFreeTb,
        isPaidUser,
        productId,
    });

    const { buttonText, description, title, buttonQaId, extraButtonQaId, extraButtonText } = useContent({
        isFrozen,
        tariff,
        canUpload,
        isFromDnD,
        isUserFreeTb: isUserFreeTb && maxUploadSize > uploadLimit,
        isEnoughSpace,
        showPayment,
        isCloudStock,
        isAcceptanceLA,
        attachSizeLimit,
    });

    const gaLabel = isFrozen ? 'frozen' : canUpload ? 'upload' : '';
    const send2GBPopupAnalytics = useSend2GBPopupAnalytics(tariff);
    const send2GbPopupXray = useSend2GBPopupXray(tariff);

    // если поп-ап открыт с afterReload и пользователь может загрузить файлы, то сразу отправляем LOAD_SUBMIT
    // кроме newbie, они должны принять сначала ЛА
    const uploadAfterReload = afterReload && canUpload && !isNewbie;

    const isReqInfoLoading = isTariffsLoading || isUserLoading || isPaidInfoLoading;

    if (!isFramed() && !IS_DEV) {
        opener('/', true);
    }

    const sendSize = useCallback(() => {
        if (rootRef.current) {
            const { height } = rootRef.current?.getBoundingClientRect();
            sendPostMessage({ type: EPostMessageType.size, width: isAcceptanceLA ? POPUP_LA_WIDTH : POPUP_WIDTH, height });
        }
    }, []);

    const onLAChecboxClick = useCallback(() => setLACheckboxSelected(!isLACheckboxSelected), [isLACheckboxSelected]);

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    useEffect(() => {
        if (isAcceptanceLA) {
            dispatch(
                sendAuthDwhRequest({
                    action: EAuthDwhAction.licenseOpen,
                    source: 'mail-popup',
                })
            );
        }
    }, []);

    useEffect(() => {
        if (isCloudStock) {
            sendCloudStockAnalytics(CloudStockAnalytics.SHOW);
        }

        return () => {
            // Отправляем радар, если пользователь не перешел на форму оплаты и закрыл модалку
            // Делаем это через ref, чтобы не менять массив зависимостей
            if (!showPaymentChanged) {
                sendCloudStockAnalytics(CloudStockAnalytics.CLOSE);
            }
        };
    }, []);

    useCheckFreeSpaceAfterVisibilityChange({
        isCloudStock,
        maxUploadSize,
        uploadLimit,
        totalUploadSize,
        canUploadCallback: () => sendPostMessage({ type: EPostMessageType.load }),
    });

    useEffect(() => {
        if (isUserLoaded && !isFrozen) {
            dispatch(initProducts());
            dispatch(loadPaidInfoRequest());

            // если пользователь newbie он должен сначала принять LA, поэтому по afterReload не показываем форму оплаты
            if (afterReload && isNewbie) {
                setShowPayment(false);
            }
        }
    }, [isUserLoaded, isFrozen, dispatch]);

    useEffect(() => {
        sendSize();
    }, [showPayment, isReqInfoLoading]);

    useEffect(() => {
        if (showPayment) {
            emitAnalyticEvent(AnalyticEventNames.MAIL_POPUP_PAYMENT_SHOW);
        }
    }, [showPayment]);

    useEffect(() => {
        if (isUserLoaded && isPaidInfoLoaded && isTariffsLoaded && uploadAfterReload) {
            sendPostMessage({ type: EPostMessageType.load });
            return;
        }

        if (isUserLoaded && ((isPaidInfoLoaded && isTariffsLoaded) || isFrozen)) {
            sendPopupGa('show', gaLabel);
            send2GBPopupAnalytics(E2GBPopupAction.SHOW);
            send2GbPopupXray(E2GBPopupAction.SHOW);
            emitAnalyticEvent(AnalyticEventNames.MAIL_POPUP_SHOW);
        }
    }, [isUserLoaded, isPaidInfoLoaded, isTariffsLoaded, isFrozen, gaLabel, canUpload, send2GBPopupAnalytics, send2GbPopupXray]);

    const handleButtonClick = useCallback(() => {
        sendPopupGa('click', gaLabel);

        if (isCloudStock) {
            sendCloudStockAnalytics(CloudStockAnalytics.SHOW);
        } else {
            send2GBPopupAnalytics(E2GBPopupAction.CLICK);
            send2GbPopupXray(E2GBPopupAction.CLICK);
            emitAnalyticEvent(AnalyticEventNames.MAIL_POPUP_CLICK);
        }

        if (isNewbie && !isAcceptanceLA) {
            api.user['agree-la']({ first_login: 'load_attach' });
            sendPostMessage({ type: EPostMessageType.submitLA });
        }

        if (isAcceptanceLA) {
            const source = 'load_attach';
            dispatch(
                sendAuthDwhRequest({
                    action: EAuthDwhAction.licenseAccept,
                    source: 'mail-popup',
                })
            );

            new Promise<void>((resolve, reject) =>
                api.user['agree-la']()
                    .done(() => {
                        user.setParam('newbie', false);
                        resolve(undefined);
                    })
                    .fail(reject)
            )
                .then(() => reduxStore.dispatch(agreeUpdateLicenseRequest({ source })))
                .then(
                    () =>
                        new Promise((resolve) => {
                            const checkInterval = setInterval(() => {
                                if (user.getParam('updateLicenseCompleted')) {
                                    clearInterval(checkInterval);
                                    resolve('success');
                                }
                            }, 100);
                        })
                )
                .then(() => sendPostMessage({ type: EPostMessageType.submitLA }));
        }

        if (isFrozen) {
            opener('/');
            dispatch(updateUser());
            return;
        } else if (canUpload) {
            sendPostMessage({ type: EPostMessageType.load });
            return;
        }

        setShowPayment(!showPayment);
        showPaymentChanged.current = true;
    }, [
        showPayment,
        isFrozen,
        canUpload,
        isNewbie,
        gaLabel,
        dispatch,
        isCloudStock,
        isAcceptanceLA,
        send2GBPopupAnalytics,
        send2GbPopupXray,
    ]);

    const handleExtraButtonClick = useCallback(() => {
        if (isAcceptanceLA) {
            sendPostMessage({ type: EPostMessageType.cancelLA });
            return;
        }
        sendCloudStockAnalytics(CloudStockAnalytics.CLEANER_OPEN);
        sendPostMessage({ type: EPostMessageType.cancelPayment });
        opener(getQuotaPromoUrl({ query: PaymentUTM.mailUploadPopup, hash: 'cleaner' }));
    }, [isAcceptanceLA]);

    const handleLAClick = useCallback(() => {
        opener('/LA');
    }, []);

    const handlePaymentCancel = useCallback(() => {
        sendCloudStockAnalytics(CloudStockAnalytics.CLOSE);
        sendPostMessage({ type: EPostMessageType.cancelPayment });
        setShowPayment(false);
    }, []);

    const handlePaymentSuccess = useCallback(() => {
        setPaymentSuccess(true);
        // Намеренно откладываем оптправку этого сообщения, чтобы
        // все метрики успели отослаться до того, как почта закроет попап
        setTimeout(() => sendPostMessage({ type: EPostMessageType.load }), 1000);

        // Отправляем радары сразу же, иначе iframe закроется и они не успеют отправиться
        send2GBPopupAnalytics(E2GBPopupAction.PAYMENT_SUCCESS, true);
        send2GbPopupXray(E2GBPopupAction.PAYMENT_SUCCESS, true);
        emitAnalyticEvent(AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS);
    }, [send2GBPopupAnalytics, send2GbPopupXray]);

    const handlePaymentFailure = useCallback(() => {
        send2GBPopupAnalytics(E2GBPopupAction.PAYMENT_FAILURE);
        send2GbPopupXray(E2GBPopupAction.PAYMENT_FAILURE);
        emitAnalyticEvent(AnalyticEventNames.MAIL_POPUP_PAYMENT_FAILURE);
    }, [send2GBPopupAnalytics, send2GbPopupXray]);

    const buttonBlock = useMemo(() => {
        return !extraButtonText ? (
            <Button primary theme="octavius" onClick={handleButtonClick} qaId={buttonQaId}>
                <div className={styles.buttonText}>{buttonText}</div>
            </Button>
        ) : (
            <>
                <VKUIButton
                    size="m"
                    mode="primary"
                    disabled={isAcceptanceLA && !isLACheckboxSelected}
                    onClick={handleButtonClick}
                    data-qa-id={buttonQaId}
                >
                    {buttonText}
                </VKUIButton>
                <div className={styles.separator} />
                <VKUIButton size="m" mode="secondary" appearance="neutral" onClick={handleExtraButtonClick} data-qa-id={extraButtonQaId}>
                    {extraButtonText}
                </VKUIButton>
            </>
        );
    }, [
        buttonQaId,
        buttonText,
        extraButtonQaId,
        extraButtonText,
        handleButtonClick,
        handleExtraButtonClick,
        isLACheckboxSelected,
        isAcceptanceLA,
    ]);

    return (
        <div ref={rootRef}>
            <Content
                wrapClass={classNames({ [styles.wrap]: true, [styles.wrapLA]: isAcceptanceLA })}
                isLoading={(isReqInfoLoading || uploadAfterReload || isPaymentSuccess) && !isFrozen}
            >
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_upload]: isCloudStock ? !isCloudStock : canUpload && !isFrozen && !isAcceptanceLA,
                        [styles.root_paid]: !canUpload && !isFrozen,
                        [styles.root_frozen]: isFrozen,
                    })}
                >
                    <div className={styles.title}>{title}</div>
                    <div className={styles.text}>{description}</div>
                    {isAcceptanceLA && (
                        <div className={styles.checkboxContainer} onClick={onLAChecboxClick}>
                            <Checkbox selected={isLACheckboxSelected} mod="blue" />
                            <div className={styles.textLA}>
                                Я соглашаюсь с использованием
                                <br />
                                <a
                                    className={styles.mailLA}
                                    href="https://help.mail.ru/legal/terms/cloud/recommendation"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    рекомендательных технологий
                                </a>{' '}
                                в Облаке
                                <br />и принимаю условия{' '}
                                <a
                                    className={styles.mailLA}
                                    href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Лицензионного
                                    <br />
                                    соглашения
                                </a>{' '}
                                и{' '}
                                <a
                                    className={styles.mailLA}
                                    href="https://help.mail.ru/legal/terms/cloud/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Политики конфиденциальности
                                </a>
                            </div>
                        </div>
                    )}
                    {tariff && !canUpload && showPayment && !isAcceptanceLA ? (
                        <Payment
                            product={tariff}
                            onResize={sendSize}
                            onCancel={handlePaymentCancel}
                            onSuccess={handlePaymentSuccess}
                            onError={handlePaymentFailure}
                        />
                    ) : (
                        <>
                            <div className={styles.button}>{buttonBlock}</div>
                            {isNewbie && !isAcceptanceLA && (
                                <div className={styles.la}>
                                    Нажав кнопку, вы принимаете{' '}
                                    <span onClick={handleLAClick} data-qa-id="la-link">
                                        лицензионное соглашение
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Content>
        </div>
    );
});

MailPopup.displayName = 'MailPopup';
