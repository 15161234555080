import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback } from 'react';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';

import styles from './ErrorRetry.css';

interface IProps {
    centered?: boolean;
    isPopup?: boolean;
}

export const ErrorRetryComponent = ({ centered, isPopup = false }: IProps): ReactElement | null => {
    const handleOnRetryClick = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.centered]: centered,
            })}
        >
            <div className={styles.text}>
                Ошибка сервера.
                <br />
                Наши специалисты уже устраняют проблему,
                <br />и совсем скоро всё заработает.
            </div>
            {!isPopup && (
                <ButtonLink onClick={handleOnRetryClick} primary>
                    Обновить страницу
                </ButtonLink>
            )}
        </div>
    );
};

export const ErrorRetry = memo(ErrorRetryComponent);
