import classNames from 'clsx';
import React, { memo } from 'react';

import styles from './SubscriptionListItem.css';

type Props = {
    icon: string;
    title: string;
    description: string;
    type?: string;
    onClick?: () => void;
    showWaiting?: boolean;
    isDisable?: boolean;
    isRebranding?: boolean;
    isRebrandingQuotaLanding?: boolean;
};

export const SubscriptionItem = memo<Props>(
    ({ icon, title, description, onClick, type = 'default', isDisable, showWaiting, isRebranding = false, isRebrandingQuotaLanding }) => (
        <div
            className={classNames(styles.root, {
                [styles[`root_${type}`]]: type,
                [styles.root_disable]: isDisable,
                [styles.root_rebranding]: isRebranding,
                [styles.root_rebrandingQuotaLanding]: isRebrandingQuotaLanding,
            })}
            onClick={onClick}
        >
            <div className={styles.content}>
                <p className={styles.title}>{title}</p>
                <p className={classNames(styles.description, { [styles.waiting]: showWaiting })}>{description}</p>
            </div>
            <img src={icon} className={styles.icon} width={28} height={28} />
        </div>
    )
);

SubscriptionItem.displayName = 'SubscriptionItem';
