import { useMemo } from 'react';
import type { AccessStatus } from 'reactApp/components/SharingNewBiz/SharingNew.types';

import { type IActionItem, EItemType } from './EmptyFolder.types';
import { useCoshare } from './hooks/useCoshare';
import { useCreate } from './hooks/useCreate';
import { useSelectFromCloud } from './hooks/useSelectFromCloud';
import { useUpload } from './hooks/useUpload';

type TEmptyFolderReturnType = Partial<Record<EItemType, (accessRights?: AccessStatus) => IActionItem>>;

export const useEmptyFolderContent = (id: string, publicUrl?: string, isAlbum?: boolean): TEmptyFolderReturnType => {
    const create = useCreate();
    const upload = useUpload(isAlbum);
    const coshare = useCoshare(id, publicUrl);
    const selectFromCloud = useSelectFromCloud(id);

    return useMemo(() => {
        if (isAlbum) {
            return {
                [EItemType.UPLOAD]: upload,
                [EItemType.SELECT_FROM_CLOUD]: selectFromCloud,
            };
        }

        return {
            [EItemType.UPLOAD]: upload,
            [EItemType.CREATE]: create,
            [EItemType.COSHARING]: coshare,
        };
    }, [isAlbum, upload, create, coshare, selectFromCloud]);
};
