import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { EditorID } from 'Cloud/Application/Editor/types';
import { ReactComponent as CloseIcon } from 'mrg-icons/src/mailru/actions/close_big.svg';
import React, { type FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import { getFeatureCoEditSharePopup } from 'reactApp/modules/features/features.selectors';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import type { RootState } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';
import { Popup } from 'semantic-ui-react';

import styles from './ShareButtonCoEditPopup.css';

const PROMO_ID = 'co-edit-share-popup';
const XRAY_NAME = PROMO_ID;

/**
 * Компонент контейнер над кнопкой ShareButton в редакторе моего офиса, которая
 * решает когда показать промо совместного редактирования
 */
export const ShareButtonCoEditPopup: FC = ({ children }) => {
    const dispatch = useDispatch();
    const triggerRef = useRef<HTMLElement>(null);
    const featureCoEditSharePopup = useSelector(getFeatureCoEditSharePopup);
    const editorState = useSelector(getEditorState);
    const wasNotShown = useSelector((state: RootState) => !UserStorageSelectors.get(state, PROMO_ID));
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        if (
            !openPopup &&
            wasNotShown &&
            featureCoEditSharePopup &&
            editorState.editorId === EditorID.MYOFFICE &&
            editorState.myOfficeType === MYOFFICE_VARIANTS.wopi &&
            editorState.isReady &&
            editorState.item?.weblinkAccessRights !== 'rw'
        ) {
            setOpenPopup(true);
        }
    }, [editorState, featureCoEditSharePopup, openPopup, wasNotShown]);

    useEffect(() => {
        if (!openPopup) {
            return;
        }

        triggerRef.current?.addEventListener(
            'click',
            () => {
                sendXray(XRAY_NAME, {
                    click: 1,
                });
            },
            { once: true }
        );

        sendXray(XRAY_NAME, {
            show: 1,
        });
        /** Сохраняем флаг показа в user хранилище в localstorage, чтобы больше не показывать */
        dispatch(UserStorageActions.set(PROMO_ID, '1'));
    }, [dispatch, openPopup]);

    return (
        <>
            {openPopup ? (
                <Popup
                    flowing
                    className={styles.componentStyles}
                    open={openPopup}
                    mouseLeaveDelay={200}
                    onClose={() => setOpenPopup(false)}
                    offset={[-5, 5]}
                    closeOnDocumentClick
                    position="bottom center"
                    content={
                        <div className={styles.content}>
                            <div
                                className={styles.contentClose}
                                onClick={() => {
                                    sendXray(XRAY_NAME, {
                                        close: 1,
                                    });
                                    setOpenPopup(false);
                                }}
                            >
                                <CloseIcon fill="#bebfc2" width={10} height={10} />
                            </div>
                            <div className={styles.contentHeader}>Совместное редактирование</div>
                            <div className={styles.contentText}>
                                Измените настройки на{'\u00A0'}совместное редактирование документа и{'\u00A0'}отправьте ссылку
                            </div>
                        </div>
                    }
                    trigger={
                        React.isValidElement(children)
                            ? React.cloneElement(children, {
                                  ref: triggerRef,
                              })
                            : children
                    }
                />
            ) : (
                children
            )}
        </>
    );
};
