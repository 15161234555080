import { createSelector } from '@reduxjs/toolkit';
import { sassTariffWhiteList } from 'reactApp/appHelpers/featuresHelpers/features/sassQuotaProLanding';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { getTariffProductsByPeriods } from 'reactApp/utils/Period';

const RECOMMENDED_ID = 'W1T';

export const getSassTariffsList = createSelector(
    [(state: RootState) => ProductsSelectors.getAvailableProductsByListIds(state, sassTariffWhiteList.tariffs)],
    (tariffByWhiteList) => {
        return tariffByWhiteList.reduce((acc: ITariffsByTab[], tariffItem) => {
            const products = getTariffProductsByPeriods(tariffItem.products);
            if (products.yearProduct || products.monthProduct) {
                const yearProduct = products.yearProduct;
                const monthProduct = products.monthProduct;
                const threeMonthProduct = products.threeMonthProduct;
                acc.push({
                    space: tariffItem.space,
                    tariffId: tariffItem.id,
                    isRecommended: tariffItem.id.includes(RECOMMENDED_ID),
                    products: {
                        yearProduct,
                        monthProduct,
                        threeMonthProduct,
                    },
                });
            }
            return acc;
        }, []);
    }
);

export const getSassQueryProduct = createSelector(
    (state: RootState, productFromQuery: string | undefined) => ProductsSelectors.getProductById(state, productFromQuery),
    (productFromQuery) => productFromQuery
);
