import React, { type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ActionPanel } from 'reactApp/components/ActionPanel/ActionPanel';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import type { EPageId } from 'reactApp/types/Page';
import { useDataContainer } from 'reactApp/ui/App/effect/useDataContainer';
import Content from 'reactApp/ui/Content/Content';
import { Header } from 'reactApp/ui/Header/Header';

interface IProps {
    isLoading: boolean;
    hasError: boolean;
    children: ((location) => ReactElement) | ReactElement | ReactElement[];
    pageId?: EPageId;
    hideHeader?: boolean;
    footer?: ReactElement | null;
    hasActionPanel?: boolean;
}

const reloadPage = (e) => {
    e.preventDefault();

    window.location.reload();
};

export const PageWrapper = ({ isLoading, hasError, children, pageId, hideHeader, footer = null, hasActionPanel = true }: IProps) => {
    const location = useLocation();
    const statusPage = useSelector(selectStatusPage);
    const { isPhone } = useDataContainer();

    return (
        <Content
            text="Произошла ошибка, не удалось загрузить страницу."
            retry={reloadPage}
            buttonText="Повторить загрузку"
            isLoading={isLoading}
            hasError={hasError}
            isPage
            qaId={pageId}
            wrapClass="vkui__root"
            layout={isPhone ? 'flex' : 'grid'}
        >
            {!hideHeader && <Header />}
            {typeof children === 'function' ? children(location) : children}
            {!isPhone && hasActionPanel && <ActionPanel />}
            {footer}
            {IS_PHONE_BROWSER && statusPage && <ErrorPage status={statusPage} />}
        </Content>
    );
};
