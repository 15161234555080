import { APICallV2Get, APICallV2Post } from 'reactApp/api/APICall';
import type { FeedApiResponse, SharedFolderApiResponse } from 'reactApp/modules/feed/feed.types';

export interface IDocumentsApiArgs {
    limit?: number;
    cursor?: string;
    exts?: string;
}

export class DocumentsApiCall extends APICallV2Post<{ body: FeedApiResponse }> {
    _method = 'feed';
}

export class SharedIncomingApiCall extends APICallV2Get<{ body: SharedFolderApiResponse }> {
    _method = 'folder/shared/incoming';
}
