import classNames from 'clsx';
import React from 'react';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getUploadItem } from 'reactApp/ui/Toolbar/Toolbar.data';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';

import { ReactComponent as UploadIcon } from '~img/cloud_upload.svg';

import styles from './B2BUploadButton.css';

interface IB2BUploadButton {
    storage: EStorageType;
    isActionPanelOpened: boolean;
}

const UploadButton = ({ text }) => (
    <div className={styles.button}>
        <div className={styles.icon}>
            <UploadIcon width={21} height={21} />
        </div>
        <div className={styles.text}>{text}</div>
    </div>
);

const B2BUploadButton = ({ storage, isActionPanelOpened = false }: IB2BUploadButton) => {
    const { id, text, list, onClick } = getUploadItem(storage);
    return (
        <div className={classNames(styles.root, { [styles.shifted_by_panel]: isActionPanelOpened })}>
            <ToolbarItem
                id={id}
                className={styles.toolbarItem}
                text={<UploadButton text={text} />}
                list={list}
                withoutDropdownIcon={true}
                onClick={onClick}
            />
        </div>
    );
};

export { B2BUploadButton };
