/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import localState from 'lib/store';
import { find, path, propEq, sortBy, values } from 'ramda';
import { IS_MOBILE_BROWSER, PROMO_TARIFFS, TARIFFS_LIST } from 'reactApp/appHelpers/configHelpers';
import {
    abEternalPromoYearTariffs,
    abMonthTariffsWithIncreasedPrice,
    abOverquotaPopupTariffs,
    desktopTariffMinSize,
    favorableTariffs as favorableTariffsIDs,
    horizontalTariffs,
    iosFavorableTariffs,
    mobileTariffsOrderByQuota,
    preferredTariffs,
    summerPromotion,
    threeMonthsPeriodTariffsAndroid,
    touchTariffMinSize,
} from 'reactApp/appHelpers/featuresHelpers';
import { litresUpsaleTariffs } from 'reactApp/appHelpers/featuresHelpers/features/litres';
import { upsaleWithTrial } from 'reactApp/appHelpers/featuresHelpers/features/upsaleWithTrial';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import {
    DEFAULT_PRIMARY_PRODUCT,
    filterAndSortTariffsByQuota,
    getComparatorByIdFromList,
    getTariffsByQuotaHelper,
    isNotSpecialProduct,
    isSummerPromoProductsAvailable,
    isTariffForExclude,
    PRICE_INCREASE_10_PERCEN_1M_TOUCH_REGEX,
    PRICE_INCREASE_10_PERCEN_1M_WEB_REGEX,
    PRICE_INCREASE_10_PERCEN_1Y_REGEX,
    PROMO_TARIFFS_REGEX,
    replaceProductInTariff,
    SINGLE_QUOTA_DISKO_REGEX,
    sortBySpace as sortProductsBySpace,
    SPACE_SIZES,
    upsertSummerPromoTariffs,
    YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX,
} from 'reactApp/modules/products/products.helpers';
import { type NormalizedTariffByProductIds, type YearAndMonthProductIds, EProductPeriod } from 'reactApp/modules/products/products.types';
import type { RootState } from 'reactApp/store';
import type { Product, Tariff } from 'reactApp/types/Billing';
import { findHigherTariff } from 'reactApp/utils/findTariff';
import { getTariffProductsByPeriods, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

import { cheapestProductIDs } from './products.const';

const { product_id: countdownTariffId, id: countdownId } = config.get('PROMO_COUNTDOWN') || {};

const getProductsState = (state: RootState) => state.products;

const getTariffs = createSelector(getProductsState, (productsState) => productsState.list);

const getLifeCycleState = createSelector(getProductsState, (productsState) => ({
    isLoaded: productsState.isLoaded,
    isLoading: productsState.isLoading,
    hasError: productsState.hasError,
}));

const isLoaded = createSelector(getLifeCycleState, (state) => state.isLoaded);

/**
 * Находит продукт по id.
 * @param id - id продукта
 * @warning не использовать для окон оплаты: селектор не проверяет возможность покупки тарифа,
 */
export const getProductById = createSelector(
    getTariffs,
    (state: RootState, id: string | undefined) => id,
    (tariffs, id) => {
        let product: Product | undefined;
        values(tariffs).forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const fProduct = find(propEq('id', id), item.products);
            if (fProduct) {
                product = fProduct;
            }
        });

        return product;
    }
);

const getTariffById = createSelector(
    getTariffs,
    (state, id) => id,
    (products, id) => products[id]
);

const sortBySpace = createSelector(getTariffs, (products) => sortProductsBySpace(products));

const getAvailableProducts = createSelector(sortBySpace, (products) =>
    products.reduce((acc, cur) => {
        const productsList = cur.products.filter(
            (product) =>
                product.available &&
                (product.isPromo || product.hasTrial || TARIFFS_LIST.includes(product.id) || isSummerPromoProductsAvailable(product.id))
        );

        if (productsList.length) {
            return [
                ...acc,
                {
                    ...cur,
                    products: productsList,
                },
            ];
        }
        return acc;
    }, [] as Tariff[])
);

// На декстопе не надо показывать тарифы меньше заданного в фиче значения (по дефолту - 128ГБ). cloudweb-13079.
const getAvailableProductsCertainSize = createSelector(getAvailableProducts, EnvironmentSelectors.isPhone, (products, isPhone) => {
    if (!isPhone) {
        // tempexp_16349-start
        const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
        return products.filter((product) => product?.space?.original >= minSizeDesktop);
        // tempexp_16349-end
    }

    return products;
});

/**
 * Возвращает доступный к покупке продукт по id
 * @param id - id продукта
 */
const getAvailableProductById = createSelector(getProductById, (product) => {
    if (product?.available) {
        return product;
    }
    return undefined;
});

const getPromoRegex = (state, promo) => PROMO_TARIFFS_REGEX[promo];

// tempexp_16338-start
const getMonthTariffsIcreasedPrice = createSelector(
    (state: RootState) => state,
    (state) => {
        return getAvailableProductsByListIds(state, abMonthTariffsWithIncreasedPrice || []);
    }
);
// tempexp_16338-end

// tempexp_16215-start
export const getEternalPromoYearTariffs = createSelector(
    (state: RootState) => state,
    EnvironmentSelectors.isPhone,
    (state, isPhone) => {
        const tariffs = getAvailableProductsByListIds(state, abEternalPromoYearTariffs);
        // tempexp_16349-start
        const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
        return isPhone ? tariffs : tariffs.filter((product) => product?.space?.original >= minSizeDesktop);
        // tempexp_16349-end
    }
);
// tempexp_16215-end

const getAvailableProductsByListIds = createSelector(
    sortBySpace,
    (state, listIds: string[]): string[] => listIds || [],
    (tariffs, listIds) => tariffs.filter((tariff) => tariff.products.some((product) => listIds?.includes(product.id)))
);

// tempexp_16373-start
const getFavorableTariffList = createSelector(
    (state: RootState) => state,
    getAvailableProductsCertainSize,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    (state, tariffs, monthTariffsIncreasedPrice) => {
        let favorableTariffs = [] as Tariff[];

        favorableTariffs = getAvailableProductsByListIds(state, favorableTariffsIDs);
        favorableTariffs.sort(getComparatorByIdFromList(favorableTariffsIDs));

        if (iosFavorableTariffs.tariffs.length) {
            favorableTariffs = getAvailableProductsByListIds(state, iosFavorableTariffs.tariffs);
            favorableTariffs.sort(getComparatorByIdFromList(iosFavorableTariffs.tariffs));
        }

        // tempexp-16938-start tempexp-17242-start
        if (horizontalTariffs.tariffs.length) {
            favorableTariffs = getAvailableProductsByListIds(state, horizontalTariffs.tariffs);
            favorableTariffs.sort(getComparatorByIdFromList(horizontalTariffs.tariffs));
        }
        // tempexp-16938-end tempexp-17242-end

        const usualTariffs = tariffs.filter((tariff) => !tariff.isProTariff && isNotSpecialProduct(tariff));

        return favorableTariffs.map((tariff) => {
            const usualTariff = getTariffsByQuotaHelper(usualTariffs, tariff.space.original)[0] as Tariff;
            // tempexp_16338-start
            const tariffWithIncreasedPrice = getTariffsByQuotaHelper(monthTariffsIncreasedPrice, tariff.space.original)[0] as Tariff;
            const tariffToAdd = tariffWithIncreasedPrice || usualTariff;
            // tempexp_16338-end

            // tempexp_16338-next-line
            const usualProduct = tariffToAdd?.products?.find((product) =>
                tariff.products.some((promoProduct) => promoProduct.period !== product.period)
            );

            if (!usualProduct) {
                return tariff;
            }

            return {
                ...tariff,
                products: [...tariff.products, usualProduct],
            };
        });
    }
);
// tempexp_16373-end

const getPromoTariffs = createSelector(
    EnvironmentSelectors.isPhone,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    getPromoRegex,
    getAvailableProductsCertainSize,
    (state, mergeWithUsualTariffs) => mergeWithUsualTariffs,
    // tempexp_16338-next-line tempexp_16215-next-line
    (isPhone, monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs, promoRegex, tariffs, mergeWithUsualTariffs) => {
        if (!promoRegex) {
            return [];
        }

        let promoTariffs = tariffs.filter((tariff) => promoRegex.test(tariff.id));

        // tempexp_16215-next-line
        if (abYearEternalPromoYearTariffs.length) {
            promoTariffs = abYearEternalPromoYearTariffs;
        }

        const minTariffSize = SPACE_SIZES[`_${isPhone ? touchTariffMinSize : desktopTariffMinSize}`];

        promoTariffs = promoTariffs.filter((tariff) => tariff?.space?.original >= minTariffSize);

        // tempexp_16510-start
        if (mobileTariffsOrderByQuota.length) {
            promoTariffs = filterAndSortTariffsByQuota(promoTariffs, mobileTariffsOrderByQuota);
        }
        // tempexp_16510-end

        if (!mergeWithUsualTariffs) {
            return promoTariffs;
        }

        const usualTariffs = tariffs.filter((tariff) => !tariff.isProTariff && isNotSpecialProduct(tariff));

        // eslint-disable-next-line sonarjs/no-identical-functions
        return promoTariffs.map((tariff) => {
            const usualTariff = getTariffsByQuotaHelper(usualTariffs, tariff.space.original)[0] as Tariff;
            // tempexp_16338-start
            const tariffWithIncreasedPrice = getTariffsByQuotaHelper(monthTariffsIncreasedPrice, tariff.space.original)[0] as Tariff;
            const tariffToAdd = tariffWithIncreasedPrice || usualTariff;
            // tempexp_16338-end

            // tempexp_16338-next-line
            const usualProduct = tariffToAdd?.products?.find((product) =>
                tariff.products.some((promoProduct) => promoProduct.period !== product.period)
            );

            if (!usualProduct) {
                return tariff;
            }

            return {
                ...tariff,
                products: [...tariff.products, usualProduct],
            };
        });
    }
);

const getPrimaryPromoTariff = createSelector(getPromoTariffs, (tariffs) => {
    const tariff = getTariffsByQuotaHelper(tariffs, DEFAULT_PRIMARY_PRODUCT)[0] as Tariff;

    const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);

    return { product: yearProduct || monthProduct, tariff };
});

const getPromoCodeTrialTariffs = createSelector(getAvailableProducts, (products) => {
    return products.filter((tariff) => tariff.isPromoCode && tariff.products.some((product) => product.hasTrial));
});

const getNonSpecialTariffs = createSelector(getAvailableProductsCertainSize, getTariffById, (products, additionalTariff) => {
    let tariffs: Tariff[] = [];

    if (additionalTariff) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isCountdownPromoTariff = additionalTariff.id === countdownTariffId;
        const email = config.get('user.email');
        const countdownEndDate = localState.get(`${email}|promo-countdown_${countdownId}`);

        tariffs.push({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...additionalTariff,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isPromoTariff: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isCountdownPromoTariff,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            countdownEndDate,
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tariffs = [...tariffs, ...products.filter((tariff) => isNotSpecialProduct(tariff) && !tariff.isSingleQuota)];
    const defaultProducts = products.filter(
        (t) => isNotSpecialProduct(t) && !t.products.some((p) => p.isProfessional) && t.products.some((p) => isMonthPeriod(p.period))
    );

    // FIXME Пофиксить ПОСЛЕ https://jira.vk.team/browse/CLOUDWEB-13535
    tariffs.forEach((tariff) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const yearCustomTariff = tariff.products.find((p) => p.id.match(YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!tariff.products.some((p) => p.isProfessional) && yearCustomTariff) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const defaultTariff = getTariffsByQuotaHelper(defaultProducts, tariff.space.original)[0];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!defaultTariff.products.some(({ id }) => id === yearCustomTariff.id)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultTariff.products.push(yearCustomTariff);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                tariffs = tariffs.filter(({ id }) => id !== tariff.id);
            }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!tariff.products.some((p) => p.isProfessional) && !yearCustomTariff) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const defaultTariff = getTariffsByQuotaHelper(defaultProducts, tariff.space.original)[0];
        if (defaultTariff) {
            const monthlyDefaultPrice = path(
                ['price'],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultTariff.products.find((p) => isMonthPeriod(p.period))
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tariff.products = tariff.products.map((p) => ({ ...p, monthlyDefaultPrice }));
        }
    });

    return tariffs;
});

const getSingleQuotaTariffs = createSelector(getAvailableProductsCertainSize, (tariffs) => {
    return tariffs.filter((tariff) => isNotSpecialProduct(tariff) && tariff.isSingleQuota);
});

// TODO После того как логика зафиксируется, возможно стоит написать юнит
const getNonSpecialSingleQuoteTariffs = createSelector(getAvailableProductsCertainSize, (tariffs: Tariff[]) => {
    const proTariffs = tariffs.filter((tariff) => tariff.isProTariff);
    const usualTariffs = tariffs.filter((tariff) => tariff.isSingleQuota && !tariff.isProTariff && isNotSpecialProduct(tariff));

    const result: Tariff[] = [];

    for (const tariff of [...proTariffs, ...usualTariffs]) {
        const tariffWithYearProduct = tariff.products.find((product) => isYearPeriod(product.period));

        // если в тарифе уже есть пара продуктов, один из которых на год, то сразу добавляем тариф в список
        if (tariff.products.length === 2 && tariffWithYearProduct) {
            result.push(tariff);
            continue;
        }

        if (tariff.products.length > 1 || !tariffWithYearProduct) {
            continue;
        }

        const isPro = tariff.id.match(SINGLE_QUOTA_DISKO_REGEX);
        const tariffsByQuota = getTariffsByQuotaHelper(isPro ? proTariffs : usualTariffs, tariff.space.original) as Tariff[];

        const yearNotMergedTariff = tariff.products[0];
        const monthNotMergedTariff = tariffsByQuota.find((tariff) => !isYearPeriod(tariff.products[0].period))?.products?.[0];

        if (monthNotMergedTariff) {
            result.push({
                ...tariff,
                products: [yearNotMergedTariff, monthNotMergedTariff],
            });
        }
    }

    return result;
});

const getNormalizedProductsBySpace = createSelector(
    getAvailableProductsByListIds,
    (state, listIds) => listIds,
    (tariffs, listIds): Record<number, { yearProduct: undefined | Product; monthProduct: undefined | Product }> => {
        const newTariffsList = tariffs.map((tariff) => ({
            ...tariff,
            products: tariff.products.filter((product) => listIds?.includes(product.id)),
        }));

        return newTariffsList.reduce((acc, tariff) => {
            const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff.products);

            const currentProducts = acc[tariff.space.original];

            return {
                ...acc,
                [tariff.space.original]: {
                    yearProduct: currentProducts?.yearProduct || yearProduct,
                    monthProduct: currentProducts?.monthProduct || monthProduct,
                },
            };
        }, {});
    }
);

const getCheapestProducts = createSelector(
    (state: RootState) => getNormalizedProductsBySpace(state, [cheapestProductIDs.month, cheapestProductIDs.year]),
    (products): ReturnType<typeof getNormalizedProductsBySpace>[0] => {
        const cheapestTariffQuota = '1024';
        const { monthProduct, yearProduct } = products[cheapestTariffQuota] ?? {};
        return {
            yearProduct: yearProduct ? { ...yearProduct, isCheapest: true } : undefined,
            monthProduct: monthProduct ? { ...monthProduct, isCheapest: true } : undefined,
        };
    }
);

const getTariffsForQuotaLanding = createSelector(
    getNonSpecialSingleQuoteTariffs,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    // tempexp_16338-next-line tempexp_16215-next-line
    (tariffs, monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs) => {
        // tempexp_16338-start
        let tariffList = tariffs.filter((tariff) => !tariff.isProTariff);
        // tempexp_16338-end

        // tempexp_16510-start
        if (mobileTariffsOrderByQuota.length) {
            tariffList = filterAndSortTariffsByQuota(tariffList, mobileTariffsOrderByQuota);
        }
        // tempexp_16510-end

        // tempexp_16338-start Заменяем месяные тарифы если есть такие в аб
        if (monthTariffsIncreasedPrice.length) {
            tariffList = replaceProductInTariff(tariffList, monthTariffsIncreasedPrice);
        }
        // tempexp_16338-end

        // tempexp_16215-next-line  Заменяем годовые тарфифы если есть такие в аб
        if (abYearEternalPromoYearTariffs.length) {
            tariffList = replaceProductInTariff(tariffList, abYearEternalPromoYearTariffs);
        }

        return tariffList;
    }
);

const getSummerPromoTariffs = createSelector(getAvailableProducts, (products) => {
    return products.filter((product) => isSummerPromoProductsAvailable(product.id));
});

const getTariffsForSubscriptionsPage = createSelector(
    getNonSpecialSingleQuoteTariffs,
    EnvironmentSelectors.isPhone,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    (state: RootState) => getPromoTariffs(state, 'marketingPromo', true), // Хак для летней акции, получаем тарифы из прошлой промки
    getSummerPromoTariffs,
    (tariffs, isPhone, monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs, blackFridayTariffs, summerPromoTarrifs) => {
        let tariffList = [...tariffs];
        // tempexp_16338-start
        if (isPhone) {
            tariffList = tariffList.filter((tariff) => !isTariffForExclude(tariff, SPACE_SIZES._32));
        }

        // Заменяем месяные тарифы если есть такие в аб
        if (monthTariffsIncreasedPrice.length) {
            tariffList = replaceProductInTariff(tariffList, monthTariffsIncreasedPrice);
        }
        // tempexp_16338-end

        // tempexp_16215-next-line Заменяем годовые тарфифы если есть такие в аб
        if (abYearEternalPromoYearTariffs.length) {
            tariffList = replaceProductInTariff(tariffList, abYearEternalPromoYearTariffs);
        }

        if (summerPromotion) {
            tariffList = upsertSummerPromoTariffs(blackFridayTariffs, summerPromoTarrifs, true);
        }

        const minTariffSize = SPACE_SIZES[`_${isPhone ? touchTariffMinSize : desktopTariffMinSize}`];

        return tariffList.filter((tariff) => tariff?.space?.original >= minTariffSize);
    }
);

const getTariffsActivatedByPromocode = createSelector(getAvailableProducts, (tariffs) => {
    if (!tariffs.length) {
        return [];
    }

    return tariffs.filter((tariff) => tariff.products.some((product) => product.isPromo && !product.hasTrial));
});

const getTariffsByQuota = createSelector(
    getNonSpecialTariffs,
    getNonSpecialSingleQuoteTariffs,
    (state, quota: number) => quota,
    (nonSpecialTariffs, singleQuotaNonSpecialTariffs, quota) =>
        getTariffsByQuotaHelper([...singleQuotaNonSpecialTariffs, ...nonSpecialTariffs], quota)
);

const getExpiredDeviceProduct = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, quota) => quota,
    (state, quota, period) => period,
    (tariffs, quota, period) => {
        const tariff = tariffs.filter(
            (tariff) => tariff.space.original === quota && tariff.products.some((product) => product.period === period && !product.hasDiskO)
        )?.[0];

        return tariff && tariff.products.find((product) => product.period === period);
    }
);

/**
 * В тарифах в продукты могут быть замерджены месячные или годовые, а может и нет
 * НО мы точно знаем, что в единой квоте селектор возвращает замердженные, по этому на всякий случай проверяем
 * есть ли выбранный тариф в единой квоте. Так как замердженный в приоритете, потому что у него есть и годовой и месячный продукт
 * */
const getTariffByProductId = createSelector(
    sortBySpace,
    getNonSpecialSingleQuoteTariffs,
    (state, id) => id,
    (tariffs, singleQuotaTariffs, id) => {
        const findByProductPredicate = (tariff: Tariff) => tariff.products.find((product) => product.id === id);

        return singleQuotaTariffs.find(findByProductPredicate) || tariffs.find(findByProductPredicate);
    }
);

const getPrimaryTariff = (state: RootState, primaryProduct = DEFAULT_PRIMARY_PRODUCT) => {
    if (!isNaN(primaryProduct * 1)) {
        const tariffs = getTariffsByQuota(state, primaryProduct);
        if (!tariffs?.length) {
            return;
        }

        if (tariffs[0].products?.length === 1 && tariffs.length > 1) {
            return {
                ...tariffs[0],
                products: [...tariffs[0].products, ...tariffs[1].products],
            };
        }
        return tariffs[0];
    }

    // Пришли из письма и явно передали ID
    const product = getTariffByProductId(state, primaryProduct);

    return product ? product : getTariffsByQuota(state, DEFAULT_PRIMARY_PRODUCT)[0];
};

const getPrimaryProduct = createSelector(
    getTariffsActivatedByPromocode,
    getNonSpecialTariffs,
    getPrimaryTariff,
    (state, productId?: string) => productId,
    (tariffsActivatedByPromoCodes, tariffs, primaryTariff, productId) => {
        if (!primaryTariff) {
            return;
        }

        let tariff = primaryTariff;

        if (tariffsActivatedByPromoCodes.length && !productId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const sortedBySpace = sortBy(path(['space', 'original']), tariffsActivatedByPromoCodes);
            tariff = sortedBySpace[sortedBySpace.length - 1];
        }

        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);
        const primaryProduct = productId && tariff?.products.find((product) => product?.id === productId);

        return { product: primaryProduct || yearProduct || monthProduct, tariff };
    }
);

const getMonthProductByQuota = createSelector(
    getSingleQuotaTariffs,
    (state, quota) => quota,
    (tariffs, quota) => {
        const monthTariffs = tariffs.filter((tariff) => tariff.products.some((product) => isMonthPeriod(product.period)));

        const tariff = findHigherTariff({ tariffs: monthTariffs, quota, period: null }) || monthTariffs.pop();

        return tariff && tariff.products.find((product) => isMonthPeriod(product.period));
    }
);

const getProductAndTariffByQuota = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, quota): number => quota,
    (state, quota, period): EProductPeriod => period,
    (state, quota, period, hasDisko): boolean => hasDisko,
    (tariffs, quota, period, hasDisko) => {
        const periodFunc = period === EProductPeriod.year ? isYearPeriod : isMonthPeriod;

        const tariffsByPeriod = tariffs.filter((tariff) =>
            tariff.products.some((product) => periodFunc(product.period) && product.hasDiskO === hasDisko)
        );

        const tariff = findHigherTariff({ tariffs: tariffsByPeriod, quota, period: null }) || tariffsByPeriod.pop();

        return { tariff, product: tariff && tariff.products.find((product) => periodFunc(product.period)) };
    }
);

const getYearAvailableTariffs = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, hasDisko): boolean => hasDisko,
    (tariffs, hasDisko) =>
        tariffs.filter((tariff) => tariff.products.some((product) => isYearPeriod(product.period) && product.hasDiskO === hasDisko))
);

const getQuotaDiscountTariff = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, PROMO_TARIFFS?.discountQuotaPopup),
    (state, quota) => quota,
    (tariffs, quota) => findHigherTariff({ tariffs, quota, period: null }) || tariffs.pop()
);

const getMailTrialByQuota = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, PROMO_TARIFFS?.trialsMailPopup),
    (state, quota) => quota,
    (tariffs, quota) => {
        const tariff = findHigherTariff({ tariffs, quota, period: null }) || tariffs.pop();

        return tariff && tariff.products.find((product) => isMonthPeriod(product.period));
    }
);

const isTrialAvailable = createSelector(
    (state, id) => getProductById(state, id),
    (state) => PaidInfoSelectors.isUserWithoutPayment(state),
    (product, isUserWithoutPayment) => product && isUserWithoutPayment
);

export const getBuyFrameProduct = createSelector(
    (state: RootState, productFromQuery: string | undefined) => getAvailableProductById(state, productFromQuery),
    (state: RootState) => getAvailableProductById(state, PROMO_TARIFFS?.buyFastchekoutFrame),
    (state: RootState) => getPrimaryProduct(state, undefined),
    getNonSpecialSingleQuoteTariffs,
    (productFromQuery, productFromConfig, primaryProduct, tariffs): { product: Product; tariff?: Tariff } | undefined => {
        // если в квери параметрах есть productId, берем его
        if (productFromQuery) {
            return { product: productFromQuery };
        }

        // иначе берем тариф из PROMO_TARIFFS и подмешиваем продукт к дефолтному тарифу из списка
        if (productFromConfig) {
            const tariff = getTariffsByQuotaHelper(tariffs, productFromConfig.space.original)[0] as Tariff;

            return {
                product: productFromConfig,
                tariff: {
                    ...tariff,
                    products: tariff.products.map((tariffProduct) =>
                        tariffProduct.period === productFromConfig.period ? productFromConfig : tariffProduct
                    ),
                },
            };
        }

        // если нет ни того ни другого, то берем праймари тариф на 128 гб
        return primaryProduct;
    }
);

// tempexp_16790-start
const overquotaPopupDefaultTariffs = createSelector(
    (state: RootState) => getNonSpecialSingleQuoteTariffs(state),
    (state: RootState) => getAvailableProductsCertainSize(state),
    getPromoRegex,
    (nonSpecialTariffs, tariffs, promoRegex) => {
        if (abOverquotaPopupTariffs === 'b') {
            return tariffs.filter((tariff) => promoRegex.test(tariff.id)).slice(0, 3);
        }

        if (abOverquotaPopupTariffs === 'c') {
            return nonSpecialTariffs
                .filter((tariff) => tariff.products.some((product) => isMonthPeriod(product.period) && product.hasDiskO === false))
                .slice(0, 3);
        }

        return nonSpecialTariffs
            .filter((tariff) => tariff.products.some((product) => isYearPeriod(product.period) && product.hasDiskO === false))
            .slice(0, 3);
    }
);

// tempexp-17081-start
const getPriceIncrease10PercentTariffs = createSelector(sortBySpace, (tariffs) => {
    const monthTariffs: Tariff[] = [];
    const yearTariffs: Tariff[] = [];
    tariffs.forEach((tariff) => {
        if (IS_MOBILE_BROWSER) {
            if (PRICE_INCREASE_10_PERCEN_1M_TOUCH_REGEX.test(tariff.id)) {
                monthTariffs.push(tariff);
            }
        } else if (PRICE_INCREASE_10_PERCEN_1M_WEB_REGEX.test(tariff.id)) {
            monthTariffs.push(tariff);
        }

        if (PRICE_INCREASE_10_PERCEN_1Y_REGEX.test(tariff.id)) {
            yearTariffs.push(tariff);
        }
    });

    return { monthTariffs, yearTariffs } as const;
});
// tempexp-17081-end

const overquotaPopupProTariff = createSelector(
    (state: RootState, quota: number): number => quota,
    (state, quota: number, promo: string) => getPromoRegex(state, promo),
    (state, quota: number, promo: string, isDiscount: boolean) => isDiscount,
    (state: RootState, quota: number) => getProductAndTariffByQuota(state, quota, EProductPeriod.year, true),
    (state: RootState) => getNonSpecialSingleQuoteTariffs(state),
    (state: RootState) => getAvailableProductsCertainSize(state),
    (state: RootState, quota: number) => getQuotaDiscountTariff(state, quota),
    (quota, promoRegex, isDiscount, productAndTariffByQuota, nonSpecialTariffs, tariffs, discountTariff) => {
        if (abOverquotaPopupTariffs === 'b') {
            const list = tariffs.filter((tariff) => promoRegex.test(tariff.id));
            return findHigherTariff({ tariffs: list, quota, period: null }) || list.pop();
        }

        if (abOverquotaPopupTariffs === 'c') {
            const list = nonSpecialTariffs.filter((tariff) =>
                tariff.products.some((product) => isMonthPeriod(product.period) && product.hasDiskO === false)
            );
            return findHigherTariff({ tariffs: list, quota, period: null }) || list.pop();
        }

        return isDiscount ? discountTariff : productAndTariffByQuota?.tariff;
    }
);

// tempexp_16790-end

const getPreferredTariffs = createSelector(
    (state: RootState) => state,
    (state: RootState) => getAvailableProductsByListIds(state, preferredTariffs),
    (state: RootState) => getNormalizedProductsBySpace(state, preferredTariffs),
    (state, tariffsList, list) => {
        const quotaListForSort: number[] = [];

        const newTariffsList = Object.values(list).map((products) => {
            const findByProductPredicate = (tariff: Tariff) => tariff.products.find((product) => product.id === products.yearProduct?.id);

            const tariff: Tariff = tariffsList.find(findByProductPredicate) as Tariff;

            return {
                ...tariff,
                products: [products.yearProduct, products.monthProduct],
            };
        });

        preferredTariffs.forEach((id) => {
            const products = Object.values(list).find((p) => p.yearProduct?.id === id || p.monthProduct?.id === id);

            const quota = (products?.yearProduct?.space?.original || 0) / SPACE_LIST.gb_1;

            if (quota && !quotaListForSort.includes(quota)) {
                quotaListForSort.push(quota);
            }
        });

        return filterAndSortTariffsByQuota(newTariffsList as Tariff[], quotaListForSort);
    }
);

// tempexp-17076-start
const getThreeMonthPeriodTariffs = createSelector(
    (state: RootState) => state,
    (state) => {
        let threeMonthTariffs: Tariff[] = [];
        if (threeMonthsPeriodTariffsAndroid?.tariffs?.length) {
            threeMonthTariffs = getAvailableProductsByListIds(state, threeMonthsPeriodTariffsAndroid.tariffs);
        }

        return threeMonthTariffs;
    }
);
// tempexp-17076-end

const getUpsaleForceTrialYearProductBySpace = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, upsaleWithTrial.tariffs),
    (state, space) => space,
    (upsaleForceTrialYearProducts, space) => {
        return upsaleForceTrialYearProducts.find((product) => product.space.space === space)?.products[0];
    }
);

// tempexp-17509-next-line
const getLitresUpsaleForceTrialYearProductBySpace = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, litresUpsaleTariffs),
    (state, space) => space,
    (upsaleForceTrialYearProducts, space) => {
        return upsaleForceTrialYearProducts.find((product) => product.space.space === space)?.products[0];
    }
);

const getNormalizedTariffsListByProductIds = createSelector(
    [
        (state: RootState, { year = [] }: YearAndMonthProductIds, _: string) => getAvailableProductsByListIds(state, year),
        (state: RootState, { year = [], month = [] }: YearAndMonthProductIds, _: string) =>
            getNormalizedProductsBySpace(state, [...year, ...month]),
        (_: RootState, __: YearAndMonthProductIds, tariffPostfix: string) => tariffPostfix,
    ],
    (products, normalizedProducts, tariffPostfix): NormalizedTariffByProductIds[] => {
        const spaceUnitMap = {
            ['ТБ']: 'TB',
            ['ГБ']: 'GB',
            ['МБ']: 'MB',
            ['КБ']: 'KB',
        };

        return products.map((p) => {
            const { monthProduct } = normalizedProducts[p.space.original];
            const { yearProduct } = getTariffProductsByPeriods(p.products);
            const tariffId = `W${p.space.space}${spaceUnitMap[p.space.units]}-${tariffPostfix}`;

            return {
                space: p.space,
                tariffId,
                products: { yearProduct, monthProduct },
            };
        });
    }
);

export const ProductsSelectors = {
    getTariffs,
    getLifeCycleState,
    isLoaded,
    getProductById,
    getAvailableProductById,
    getTariffById,
    sortBySpace,
    getAvailableProducts,
    getPromoTariffs,
    getQuotaDiscountTariff,
    getPromoCodeTrialTariffs,
    getNonSpecialSingleQuoteTariffs,
    getNonSpecialTariffs,
    getTariffsActivatedByPromocode,
    getTariffByProductId,
    getMonthProductByQuota,
    getMailTrialByQuota,
    isTrialAvailable,
    getPrimaryProduct,
    getExpiredDeviceProduct,
    getProductAndTariffByQuota,
    getYearAvailableTariffs,
    getTariffsForSubscriptionsPage,
    getTariffsForQuotaLanding,
    getPrimaryPromoTariff,
    getBuyFrameProduct,
    getNormalizedProductsBySpace,
    // tempexp_16373-next-line
    getFavorableTariffList,
    // tempexp_16790-start
    overquotaPopupDefaultTariffs,
    overquotaPopupProTariff,
    // tempexp_16790-end
    getCheapestProducts,
    getSummerPromoTariffs,
    getPreferredTariffs,
    // tempexp-17081-next-line
    getPriceIncrease10PercentTariffs,
    getThreeMonthPeriodTariffs,
    getAvailableProductsByListIds,
    // tempexp-17509-next-line
    getLitresUpsaleForceTrialYearProductBySpace,
    getUpsaleForceTrialYearProductBySpace,
    getNormalizedTariffsListByProductIds,
} as const;
