import { createReducer } from '@reduxjs/toolkit';

import {
    businessTemplatesCreateFileFromTemplateAction,
    businessTemplatesCreateFileFromTemplateDoneAction,
    businessTemplatesCreateFileFromTemplateErrorAction,
    businessTemplatesLoadedAction,
    businessTemplatesLoadErrorAction,
    businessTemplatesLoadingAction,
} from './businessTemplates.actions';
import type { BusinessTemplatesState } from './businessTemplates.types';

const initialState: BusinessTemplatesState = {
    listLoadingState: 'idle',
    categoriesList: [],
    categoriesById: {},
    copyLoadingState: 'idle',
};

export const businessTemplatesReducer = createReducer(initialState, {
    [businessTemplatesLoadingAction.type]: (state) => {
        state.listLoadingState = 'pending';
    },
    [businessTemplatesLoadedAction.type]: (state, action: ReturnType<typeof businessTemplatesLoadedAction>) => {
        const categories = action.payload;

        state.listLoadingState = 'resolved';

        for (const category of categories) {
            if (category.templates.length) {
                state.categoriesList.push(category);
                state.categoriesById[category.id] = category;
            }
        }
    },
    [businessTemplatesLoadErrorAction.type]: (state) => {
        state.listLoadingState = 'rejected';
    },
    [businessTemplatesCreateFileFromTemplateAction.type]: (state) => {
        state.copyLoadingState = 'pending';
    },
    [businessTemplatesCreateFileFromTemplateDoneAction.type]: (state) => {
        state.copyLoadingState = 'resolved';
    },
    [businessTemplatesCreateFileFromTemplateErrorAction.type]: (state) => {
        state.copyLoadingState = 'rejected';
    },
});
