import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestActivities, resetActivities } from 'reactApp/modules/activities/activities.module';
import { ActivitiesSelectors } from 'reactApp/modules/activities/activities.selector';
import type { RequestActivitiesPaylod } from 'reactApp/modules/activities/activities.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FileFolderChangesList } from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesList/FileFolderChangesList';
import {
    DEFAULT_FILTER,
    EVENTS_FILTERS,
    useFileFolderChangesHook,
} from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FilesFoldeChangesFilterHook';
import { LogLoader } from 'reactApp/ui/FilesFoldersLogViewer/FileFoldersVersionLog/LogLoader/Loader';
import { Stub } from 'reactApp/ui/FilesFoldersLogViewer/FileFoldersVersionLog/Stub/Stub';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';

import styles from './FileFolderChangesLog.css';

interface IProps {
    itemId: string;
    isFolder: boolean;
}

const MIN_ITEM_HEIGHT = 60;
const MIN_ITEMS_LOAD_LIMIT = 10;

const calculateItemsInContainerViewport = (element: Nullable<Element>) => {
    const height = element?.getBoundingClientRect().height || 0;
    return Math.ceil(height / MIN_ITEM_HEIGHT) + 5;
};

const childrenSelector = `:scope > div.${styles.list_item}`;
const lastChildSelector = `${childrenSelector}:last-child`;

export const FileFolderChangesLog = ({ itemId, isFolder }: IProps) => {
    const intersectionObserverRef = useRef<Nullable<IntersectionObserver>>(null);
    const [expectedItemsCount, setExpectedItemsCount] = useState(0);
    const isLoading = useSelector(ActivitiesSelectors.getIsLoading);
    const isError = useSelector(ActivitiesSelectors.getIsError);
    const containerRef = useRef<Nullable<HTMLDivElement>>(null);
    const dropdownRef = useRef<Nullable<HTMLDivElement>>(null);
    const items = useSelector(ActivitiesSelectors.activities);
    const email = useSelector(UserSelectors.getEmail) || '';
    const dispatch = useDispatch();

    const [queryParams, setQueryParams] = useState<RequestActivitiesPaylod>({ email, path: itemId, limit: MIN_ITEMS_LOAD_LIMIT });

    useEffect(() => {
        const { current } = containerRef;
        if (current) {
            const intersectionObserver = new IntersectionObserver(
                ([entry]: IntersectionObserverEntry[], observer) => {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        setQueryParams((state) => ({ ...state }));
                    }
                },
                {
                    root: current,
                    rootMargin: `${MIN_ITEM_HEIGHT * 5}px`,
                }
            );

            intersectionObserverRef.current = intersectionObserver;

            return () => intersectionObserver.disconnect();
        }
    }, []);

    const { filter, setFilter, filterItems, config } = useFileFolderChangesHook(isFolder, (id) => {
        setFilter(id);
        dispatch(resetActivities());
    });

    useEffect(() => {
        const container = containerRef.current;
        const children = container?.querySelectorAll(childrenSelector);
        const limit = Math.max(MIN_ITEMS_LOAD_LIMIT, calculateItemsInContainerViewport(container));
        let expected = (children && children.length + limit) || limit;

        if (queryParams.path !== itemId) {
            expected = limit;
            setQueryParams((state) => {
                state.path = itemId;
                state.limit = limit;
                return state;
            });
        }

        setExpectedItemsCount(expected);
        dispatch(requestActivities({ ...queryParams, path: itemId, limit, email, filter }));
    }, [itemId, queryParams, email, filter, dispatch]);

    useEffect(() => {
        const container = containerRef.current;

        if (!items.length || !container) {
            return;
        }

        const lastChild = container.querySelector(lastChildSelector);

        if (lastChild && items.length >= expectedItemsCount) {
            intersectionObserverRef.current?.observe(lastChild);
        }
    }, [items, expectedItemsCount]);

    useEffect(
        () => () => {
            dispatch(resetActivities());
            intersectionObserverRef.current?.disconnect();
        },
        [dispatch]
    );

    const reloadHandler = useCallback(() => {
        const limit = Math.max(MIN_ITEMS_LOAD_LIMIT, calculateItemsInContainerViewport(containerRef.current));
        dispatch(resetActivities());
        setQueryParams({ email, path: itemId, limit });
    }, [email, itemId, dispatch]);

    useEffect(() => {
        setFilter(DEFAULT_FILTER);
    }, [isFolder, setFilter]);

    return (
        <>
            <ToolbarItem
                className={styles.actions_filter}
                ref={dropdownRef}
                text={filterItems.find(({ id }) => id === filter)?.text || EVENTS_FILTERS[0].text}
                list={filterItems}
                parentRef={dropdownRef}
                {...config}
            />
            <div className={styles.root} ref={containerRef}>
                {isError ? (
                    <Stub className={styles.error_stub} caption={'Ошибка загрузки данных'} reloadHandler={reloadHandler} />
                ) : (
                    <>
                        <FileFolderChangesList items={items} currentUserEmail={email} isLoading={isLoading} />
                        {isLoading && <LogLoader className={styles.loader} />}
                    </>
                )}
            </div>
        </>
    );
};
