import { pickBy } from 'ramda';
import { IS_MBIZUSER, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams, getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { renderNewComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { sendSassTmrGoal } from 'reactApp/sections/SassLanding/SassTmr';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

const LANDING_NAME = 'biz_mailspace_pro';

export const sendSassGa = ({ action, label = '', eventCategory = '', ...restData }) => {
    const utms = getUtmObject();
    const state = reduxStore.getState();
    const { usedSize, freeSize } = UserQuotaSelectors.getSpace(state);
    const isAnonymous = UserSelectors.isAnonymous(state);
    const isPaidUser = UserSelectors.isPaidUser(state);
    const data = pickBy((val) => val !== undefined, restData) as Record<string, any>;

    const dwhData = {
        auth: isAnonymous ? 0 : 1,
        landing_name: LANDING_NAME,
        size_quota: usedSize,
        free_quota: freeSize,
        platform: IS_PHONE_BROWSER ? String(EnvironmentSelectors.getMobileType()).toLowerCase() : 'desktop',
        is_mailspace: isPaidUser ? 1 : 0,
        is_mailspace_pro: IS_MBIZUSER,
        action,
        ...data,
        ...utms,
    };

    sendDwh({ eventCategory, action, label, dwhData });
};

export const getAccentSliderCount = (): number => {
    const { accent_slider: accentSlider } = getQueryParams();
    return Number(accentSlider) ? Number(accentSlider) : 0;
};

export const sendGaAuth = (e, next = noop) => {
    sendSassGa({
        action: 'reg_button',
        eventCategory: 'click',
    });
    sendSassTmrGoal({ goal: 'click_reg_button' });
    next();
};
export const sendGaLogin = (e, next = noop) => {
    sendSassGa({
        action: 'enter_button',
        eventCategory: 'click',
    });
    sendSassTmrGoal({ goal: 'click_enter_button' });
    next();
};
export const sendGaAuthChange = (e, next = noop) => {
    sendSassGa({
        action: 'auth_change',
        eventCategory: 'click',
    });
    next();
};

const RADARS = [
    'show_sass_land',
    'slct_trff_click',
    'notes_ai_open',
    'tariff_load',
    'bnft_check_free',
    'trff_tab_month',
    'trff_tab_year',
    'card_buy_click',
    'ext_buy_click',
    'crd_buy_clck_anon',
    'ext_buy_clck_anon',
    'fschk_buy_click',
    'fschk_show',
    'fschk_scss',
] as const;

export const SASS_RADAR = RADARS.reduce((prev, curr) => {
    (prev[curr] as unknown) = curr;
    return prev;
}, {} as { [T in (typeof RADARS)[number]]: T });

const parsePeriod = (period: string) => period && period.match(/(^\d*)+(\D)$/);

export const getTariffTmrGoal = (period: string, space: number, units: string) => {
    const periodMatches = parsePeriod(period);
    const unit = units === 'ГБ' ? 'gb' : 'tb';
    const periodMap = {
        y: 'year',
        m: 'month',
    };
    const name = periodMatches?.[2];

    if (!name) {
        return '';
    }

    return `${space}${unit}_${periodMap[name]}`;
};

export const onShowFastCheckout = (tariffForDwh: string, finishPrice: number) => () => {
    sendSassGa({
        action: 'payment_form',
        eventCategory: 'show',
        tariff: tariffForDwh,
        amount: finishPrice,
    });
    sendXrayWithPlatform([SASS_RADAR.fschk_show]);
};

export const onSuccessFastCheckout =
    ({ productPeriod, space, units, tariffForDwh, finishPrice }) =>
    () => {
        sendSassGa({
            action: 'payment_form',
            eventCategory: 'click',
            tariff: tariffForDwh,
            amount: finishPrice,
        });
        sendXrayWithPlatform([SASS_RADAR.fschk_scss]);
        sendSassTmrGoal({ goal: `payment_form_succes_${getTariffTmrGoal(productPeriod, space, units)}` });
    };

export const onPayBtnFastCheckout =
    ({ productPeriod, space, units }) =>
    () => {
        sendXrayWithPlatform([SASS_RADAR.fschk_buy_click]);
        sendSassTmrGoal({ goal: `click_payment_form_${getTariffTmrGoal(productPeriod, space, units)}` });
    };

export const getUrlWithoutParam = (paramsName: string[]) => {
    // eslint-disable-next-line compat/compat
    const params = new URLSearchParams(window.location.search);
    paramsName.forEach((p) => params.delete(p));
    return params.toString().length ? `?${params.toString()}` : '';
};

export const showSassNoTariffDialog = () => {
    renderNewComfirmationDialog({
        dataQAId: 'no-tariff-dialog',
        renderHeader: () => (IS_PHONE_BROWSER ? ' Тариф недоступен' : 'Данный тариф недоступен'),
        renderContent: () => 'Попробуйте сменить аккаунт или выберите другой тариф',
        buttons: [EButtonType.accept],
        successButtonText: IS_PHONE_BROWSER ? 'Понятно' : 'Ок',
    });
};
