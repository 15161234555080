import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { LANDING_TITLE } from 'reactApp/sections/SassLanding/SassLanding.data';

export const isPromoQuotaProEnabled = getFeature('is_promo_quota_pro_enabled');

export const sassLandingVariant = getFeature('sass_landing_variant');

export const sassLandingHeader = getFeature('sass_landing_header') || {
    title: LANDING_TITLE,
};
export const sassLandingIsTrialEnabled = getFeature('sass_landing_is_trial_enabled');

export const sassTariffWhiteList = getFeature('sass_tariff_white_list');

export const sassLandingLinks = getFeature('sass_landing_links');

export const isSassThemeEnabled = getFeature('is_sass_theme_enabled') || false;
