export enum EDMRTypes {
    billing = 'billing',
    payment = 'payment',
}

export enum EDMRActions {
    payStart = 'payStart',
    payError = 'payError',
    payFail = 'payFail',
    pageLoad = 'pageLoad',
    formLoad = 'formLoad',
    resizeFrame = 'resizeFrame',
    refreshWindow = 'refreshWindow',
    closeWindow = 'closeWindow',
    paySuccess = 'paySuccess',
    payCancel = 'payCancel',
    paySubmit = 'paySubmit',
    putPixel = 'putPixel',
    threeDSPage = '3dsPage',
    threeDSFinish = '3dsFinish',
    availableMethods = 'availableMethods',
    removeAddedCard = 'removeAddedCard',
    hasBinds = 'hasBinds',
    typePaymentMethod = 'typePaymentMethod',
    payButtonClick = 'payButtonClick',
    payInvoice = 'payInvoice',
}
