import classNames from 'clsx';
import React, { memo, useCallback, useRef, useState } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';
import type { TPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './DatalistPic.css';

export const DatalistPic = memo((props: TPic) => {
    const iconType = getIconType({
        isFolder: props.isFolder,
        kind: props.isFolder ? props.kind : undefined,
        ext: props.isFolder ? undefined : props.ext.toLowerCase(),
        isVirus: props.isFolder ? false : props.isVirus,
        ignoreFolderType: props.isFolder ? props.ignoreFolderType : false,
    });

    const onLoaded = props.onLoaded ?? noopVoid;

    const startTime = useRef(Date.now());
    const [isLoadedThumb, setLoadedThumb] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);

    const showFileIcon = (!isLoading && !isLoadedThumb) || props.isFolder || !props.thumbUrl?.src;

    const handleThumbLoad = useCallback(() => {
        setLoadedThumb(true);
        setLoading(false);

        onLoaded();

        logHandler({
            radarName: `mobile_folder_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, [onLoaded]);

    const handleThumbError = useCallback(() => {
        setLoading(false);

        onLoaded();

        logHandler({
            radarName: `mobile_folder_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, [onLoaded]);

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.container_loaded]: !isLoading || props.isFolder || !props.thumbUrl?.src,
                [styles[`container_${props.viewMode}`]]: props.viewMode,
                [styles.border]: !props.isFolder,
            })}
        >
            {showFileIcon && props.author && (
                <div className={styles.icon}>
                    <Hint text={props.author.name || props.author.email}>
                        <Avatar size={48} sourceSize={90} email={props.author.email} name={props.author.name} />
                    </Hint>
                </div>
            )}
            {showFileIcon && !props.author && (
                <div className={styles.icon}>
                    <FileIcon type={iconType} size={props.viewMode === EViewMode.thumbs ? 'l' : 'm'} />
                </div>
            )}
            {!props.isFolder && props.thumbUrl?.src && (
                <ImageCancelable
                    className={classNames({ [styles.thumb]: true, [styles.thumb_loaded]: isLoadedThumb })}
                    draggable={false}
                    src={props.thumbUrl?.src}
                    alt=""
                    onError={handleThumbError}
                    onLoad={handleThumbLoad}
                />
            )}
        </div>
    );
});

DatalistPic.displayName = 'DatalistPic';
