import { toNDigits } from '@mail/cross-sizes-utils';
import { Icon20ExclamationMarkOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { type ReactElement, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { overquotaBlockDate } from 'reactApp/appHelpers/featuresHelpers/features/overquotaBlockDate';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getUserOverquotaState } from 'reactApp/modules/profile/profile.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './OverquotaModal.css';
import { OverquotaBannerCtrl, sendDwhOverquota } from './OverquotaModal.helpers';
import { type OverquotaModalProps, OverquotaModalMode } from './OverquotaModal.types';

const ACTION_NOT_ENOUGH_SPACE = 'not-enough-space';
const ACTION_STOP_FILES_OPEN = 'stop-files-open';

export const OverquotaModal = memo(function OverquotaPopup({ onClose, mode }: OverquotaModalProps): ReactElement | null {
    const storage = useSelector(getCurrentStorage);
    const { used, userTotalSpace } = useSelector(UserQuotaSelectors.getUserQuotaState);
    const overquota = useSelector(UserQuotaSelectors.getOverQuota);
    const { trashSize = 0, blockTime = undefined, state, clearTime } = useSelector(getUserOverquotaState);

    const clearTrash = trashSize > 0;

    const dispatch = useDispatch();

    useEffect(() => {
        sendDwhOverquota({ action: 'show', storage, place: 'overquota_started' });

        if (mode === OverquotaModalMode.enter) {
            emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_VIEW);
            sendPaymentGa({
                action: ACTION_NOT_ENOUGH_SPACE,
                label: 'view',
            });
        }

        if (mode === OverquotaModalMode.viewer) {
            emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_FILES_VIEW);
            sendPaymentGa({
                action: ACTION_STOP_FILES_OPEN,
                label: 'view',
                place: storage,
            });
        }
    }, []);

    const title = useMemo(() => {
        if (mode === OverquotaModalMode.viewer) {
            return 'Посмотреть файлы пока нельзя';
        }

        if (overquotaBlockDate && blockTime && state === 'started') {
            return (
                <>
                    <span>{formatFileHistoryDate(blockTime)}</span> доступ к файлам будет заблокирован
                </>
            );
        }

        if (overquotaBlockDate && clearTime && state === 'unblocked') {
            return (
                <>
                    <span>{formatFileHistoryDate(clearTime)}</span> ваши файлы будут удалены
                </>
            );
        }

        return 'Вашим файлам не хватает места';
    }, [mode, blockTime, overquotaBlockDate, state]);

    const content = useMemo(() => {
        if (mode === OverquotaModalMode.enter) {
            return (
                <>
                    Они занимают на <span>{overquota.value}</span> больше, чем у вас есть — некоторые функции недоступны. Увеличьте место
                    или&nbsp;удалите лишнее
                </>
            );
        }
        if (mode === OverquotaModalMode.viewer) {
            return (
                <>
                    Облако переполнено на <span>{overquota.value}</span> — увеличьте место или&nbsp;удалите лишнее, чтобы снова
                    просматривать файлы
                </>
            );
        }

        if (clearTrash) {
            return (
                <>
                    Увеличьте место или очистите корзину. Файлы&nbsp;в&nbsp;ней занимают&nbsp;
                    <span className={styles.quotaSize}>
                        {' '}
                        {
                            toNDigits({
                                bytes: trashSize,
                                maxDigitsCount: 3,
                            }).value
                        }
                    </span>
                    &nbsp;— этого хватит, чтобы сохранить доступ
                </>
            );
        }

        if (overquotaBlockDate && (blockTime || clearTime)) {
            return (
                <>
                    Пространство для хранения ваших файлов переполнено. Увеличьте&nbsp;его или&nbsp;очистите от&nbsp;лишнего.{' '}
                    <a href="https://help.mail.ru/legal/terms/cloud/LA" target="_blank" rel="noreferrer">
                        Подробнее
                    </a>
                </>
            );
        }

        return (
            <>
                Скоро они{' '}
                <a href="https://help.mail.ru/legal/terms/cloud/LA" target="_blank" rel="noreferrer">
                    будут удалены
                </a>
                . Увеличьте место, чтобы их&nbsp;сохранить, или&nbsp;очистите Облако от&nbsp;ненужных файлов
            </>
        );
    }, [mode, overquota, clearTrash, blockTime, overquotaBlockDate, trashSize]);

    const clearButtonText = useMemo(() => {
        if (clearTrash) {
            return 'Перейти в корзину';
        }

        if (overquotaBlockDate && blockTime) {
            return 'Очистить';
        }

        return 'Очистить Облако';
    }, [clearTrash, blockTime, overquotaBlockDate]);

    const onPrimaryHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, control: OverquotaBannerCtrl.add_space, place: 'overquota_started' });

        let search = PaymentUTM.overquotaSplashNew;

        if (mode === OverquotaModalMode.enter) {
            search = PaymentUTM.overquotaSlashEnterSpace;
            emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_CLICK);
            sendPaymentGa({
                action: ACTION_NOT_ENOUGH_SPACE,
                label: 'click',
            });
        }

        if (mode === OverquotaModalMode.viewer) {
            search = PaymentUTM.overquotaSlashViewerSpace;
            emitAnalyticEvent(AnalyticEventNames.BLOCK_OVERQUOTA_FILES_CLICK);
            sendPaymentGa({
                action: ACTION_STOP_FILES_OPEN,
                label: 'click',
                place: storage,
            });
        }

        dispatch(historyPush({ id: '/promo/quota', search: `?${search}` }));

        onClose?.();
    }, [onClose, mode, storage, dispatch]);

    const onSecondaryHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, control: OverquotaBannerCtrl.clear, place: 'overquota_started' });

        let search = '';

        if (mode === OverquotaModalMode.enter) {
            search = PaymentUTM.overquotaSlashEnterCleaner;
            sendPaymentGa({
                action: ACTION_NOT_ENOUGH_SPACE,
                label: 'cleaner',
            });
        }

        if (mode === OverquotaModalMode.viewer) {
            search = PaymentUTM.overquotaSlashViewerCleaner;
            sendPaymentGa({
                action: ACTION_STOP_FILES_OPEN,
                label: 'cleaner',
                place: storage,
            });
        }

        if (clearTrash) {
            dispatch(
                historyPush({
                    id: EStorageType.trashbin,
                    search: `?${search}`,
                })
            );
        } else {
            dispatch(
                historyPush({
                    id: '/promo/quota',
                    search: `?${search}`,
                    hash: 'cleaner',
                })
            );
        }
        onClose?.();
    }, [onClose, mode, storage, dispatch, clearTrash]);

    const onCloseHandler = useCallback(() => {
        sendDwhOverquota({ action: 'click', storage, control: OverquotaBannerCtrl.closed, place: 'overquota_started' });

        if (mode === OverquotaModalMode.enter) {
            sendPaymentGa({
                action: ACTION_NOT_ENOUGH_SPACE,
                label: 'close',
            });
        }

        if (mode === OverquotaModalMode.viewer) {
            sendPaymentGa({
                action: ACTION_STOP_FILES_OPEN,
                label: 'close',
                place: storage,
            });
        }

        onClose?.();
    }, [onClose, mode, storage]);

    if (!used || !userTotalSpace) {
        return null;
    }

    return (
        <Dialog
            open
            header={''}
            closeOnDimmerClick={false}
            id="overquota-alert"
            qaId="overquota-alert"
            onCancel={onCloseHandler}
            className={classNames(styles.root, {
                [styles.rebranding]: isRebranding,
            })}
            size="tiny"
        >
            <Content isModal>
                <div className={styles.content}>
                    <div className={styles.imageWrapper}>
                        <div className={styles.quota}>
                            <div className={styles.quotaIcon}>
                                <Icon20ExclamationMarkOutline width={26} height={26} />
                            </div>
                            <div className={styles.quotaTitle}>
                                Занято {used?.value} из {userTotalSpace?.value}
                            </div>
                            <div className={styles.quotaSeparator} />
                            <div className={styles.quotaText}>{isRebranding ? 'Хранилище переполнено' : 'Не хватает места'}</div>
                        </div>
                    </div>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.text}>{content}</div>
                    <div className={styles.footerWrapper}>
                        <Button
                            className={styles.button}
                            data-name="freeze-renewal"
                            theme="octavius"
                            sizeMode={ButtonSizeMode.small}
                            onClick={onPrimaryHandler}
                            error
                        >
                            Увеличить место
                        </Button>
                        <Button
                            className={styles.button}
                            onClick={onSecondaryHandler}
                            theme="octavius"
                            sizeMode={ButtonSizeMode.small}
                            data-name="secondary"
                        >
                            {clearButtonText}
                        </Button>
                    </div>
                </div>
            </Content>
        </Dialog>
    );
});

OverquotaModal.displayName = 'OverquotaModal';
