import { EditorID } from 'Cloud/Application/Editor/types';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import type { RootState } from 'reactApp/store';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

import { FileNameInput } from './components/FileNameInput/FileNameInput';
import styles from './HeaderFileName.css';

export interface Props {
    fileName: string;
    readOnly: boolean;
    onEdit: (value: { value: string }) => Promise<string | void>;
    onClick: () => void;
    qaId?: string;
}

export const MAX_FILENAME_LENGTH = 45;

export const HeaderFileName = memo<Props>(({ fileName, readOnly, onClick, onEdit, qaId }) => {
    const [isEdit, setIsEdit] = useState(false);
    const editorId = useSelector((state: RootState) => getEditorState(state).editorId);
    const isMyOffice = editorId === EditorID.MYOFFICE;

    return (
        <div className={styles.filenameBlock} data-qa-id={qaId}>
            {readOnly ? (
                <Hint text={fileName}>
                    <div className={styles.readOnly}>{truncateTextMiddle(fileName, MAX_FILENAME_LENGTH)}</div>
                </Hint>
            ) : (
                <Hint text={`${fileName?.length > MAX_FILENAME_LENGTH ? fileName : 'Переименовать (по двойному клику)'}`}>
                    <div className={styles.editable}>
                        <FileNameInput value={fileName} onEdit={onEdit} onClick={onClick} setIsEdit={setIsEdit} />
                    </div>
                </Hint>
            )}
            {!isEdit && isMyOffice && <div className={styles.myOfficeText}>МойОфис</div>}
        </div>
    );
});

HeaderFileName.displayName = 'HeaderFileName';
