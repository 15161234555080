import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { type SharingPublicThemeTariffIds, sharingPublicThemeTariffIds } from 'reactApp/appHelpers/featuresHelpers/features/themedPublic';
import { features } from 'reactApp/constants/products/features';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { TariffFeatureListItemType } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { useFeatureData } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import type { TariffByModalTab } from 'reactApp/ui/TariffsModal/TariffsModalContent.types';

const getTariffsList = createSelector(
    [
        (state: RootState, tariffIds: SharingPublicThemeTariffIds, _: TariffFeatureListItemType[]) =>
            ProductsSelectors.getNormalizedTariffsListByProductIds(state, tariffIds, 'sharong-public-theme'),
        (_: RootState, __: SharingPublicThemeTariffIds, features: TariffFeatureListItemType[]) => features,
    ],
    (tariffs, features): TariffByModalTab[] => {
        const RECOMMENDED_ID = 'W64G';
        return tariffs.map((t) => ({ ...t, isRecommended: t.tariffId.includes(RECOMMENDED_ID), features }));
    }
);

export function useSharingPublicThemeTariffs() {
    const [featureList] = useFeatureData(features.sharedPublicTheme);
    return {
        tariffIds: sharingPublicThemeTariffIds,
        tariffs: useSelector((state: RootState) => getTariffsList(state, sharingPublicThemeTariffIds, featureList)),
    };
}
