import type { CloudItem } from 'reactApp/modules/storage/storage.types';
import type { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';

export const popupNames = {
    CO_EDIT_ALERT: 'CO_EDIT_ALERT',
    SPACE_PROMO: 'SPACE_PROMO',
    WEB_PUSH_TOOLTIP: 'WEB_PUSH_TOOLTIP',
    DOWNLOAD_DLG: 'DOWNLOAD_DLG',
    VIRUS_DLG: 'VIRUS_DLG',
    NO_WARNING: 'NO_WARNING',
    DISABLED_FEATURE: 'DISABLED_FEATURE',
    RECOGNITION_POPUP: 'RECOGNITION_POPUP',
    FILE_HISTORY: 'FILE_HISTORY',
    SELECT_FOLDER_DIALOG: 'SELECT_FOLDER_DIALOG',
    SELECT_PATH_DIALOG: 'SELECT_PATH_DIALOG',
    REMOVE_DIALOG: 'REMOVE_DIALOG',
    RENAME_DIALOG: 'RENAME_DIALOG',
    ASK_NAME_DIALOG: 'ASK_NAME_DIALOG',
    CREATE_FOLDER: 'CREATE_FOLDER',
    CREATE_DOCUMENT: 'CREATE_DOCUMENT',
    UPDATE_CARD: 'UPDATE_CARD',
    TARIFF_BUY: 'TARIFF_BUY',
    CONFIRM_EMAIL_SCREEN: 'CONFIRM_EMAIL_SCREEN',
    CONFIRM_EMAIL_DIALOG: 'CONFIRM_EMAIL_DIALOG',
    CANCEL_MAIL_SUBSCRIPTION: 'CANCEL_MAIL_SUBSCRIPTION',
    VKBUY_MODAL: 'VKBUY_MODAL',
    BUY_DISABLE_ADS: 'BUY_DISABLE_ADS',
    MOUNT_DIALOG: 'MOUNT_DIALOG',
    UNMOUNT_DIALOG: 'UNMOUNT_DIALOG',
    REJECT_DIALOG: 'REJECT_DIALOG',
    SHARING_DIALOG: 'SHARING_DIALOG',
    SHARING_MODIFIED: 'SHARING_MODIFIED',
    OVERQUOTA_BANNER: 'OVERQUOTA_BANNER',
    OVERQUOTA_MODAL: 'OVERQUOTA_MODAL',
    OVERQUOTA_SPLASH: 'OVERQUOTA_SPLASH',
    DATALIST: 'DATALIST',
    DOWNLOAD_MOBILE: 'DOWNLOAD_MOBILE',
    MOBILE_OPTIONS: 'MOBILE_OPTIONS',
    MOBILE_FILTER_AUTHOR: 'MOBILE_FILTER_AUTHOR',
    MOBILE_FILTER_FACES: 'MOBILE_FILTER_FACES',
    MOBILE_VIEWER: 'MOBILE_VIEWER',
    AUTH_MODAL: 'AUTH_MODAL',
    MOBILE_VIRUS_DIALOG: 'MOBILE_VIRUS_DIALOG',
    MOBILE_FILE_INFO_MODAL: 'MOBILE_FILE_INFO_MODAL',
    MOBILE_SUSPICIOS_DIALOG: 'MOBILE_SUSPICIOS_DIALOG',
    MOBILE_GIFT_PROMOCODE_DIALOG: 'MOBILE_GIFT_PROMOCODE_DIALOG',
    MOBILE_SPLASH_SCREEN: 'MOBILE_SPLASH_SCREEN',
    MOBILE_LEFT_MENU: 'MOBILE_LEFT_MENU',
    SASS_MOBILE_MENU: 'SASS_MOBILE_MENU',
    SUSPICIOS_DIALOG: 'SUSPICIOS_DIALOG',
    SELECT_ALBUM: 'SELECT_ALBUM',
    DISABLE_ADS_PROMO: 'DISABLE_ADS_PROMO',
    SPECIAL_BANNER: 'SPECIAL_BANNER',
    WHATS_NEW_DIALOG: 'WHATS_NEW_DIALOG',
    CONFIRM_EMAIL_MOBILE: 'CONFIRM_EMAIL_MOBILE',
    EMERGENCY_SWA: 'EMERGENCY_SWA',
    BANNER_OFFER: 'BANNER_OFFER',
    BANNER_OFFER_REDESIGN: 'BANNER_OFFER_REDESIGN',
    SUBSCRIPTIONS_MOBILE: 'SUBSCRIPTIONS_MOBILE',
    SUBSCRIPTION_INFO_MOBILE: 'SUBSCRIPTION_INFO_MOBILE',
    SELECT_FROM_CLOUD_DIALOG: 'SELECT_FROM_CLOUD_DIALOG',
    SUBSCRIPTION_CANCEL_AUTO_RENEWAL_MOBILE: 'SUBSCRIPTION_CANCEL_AUTO_RENEWAL_MOBILE',
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_MOBILE: 'SUBSCRIPTION_CANCEL_SUBSCRIPTION_MOBILE',
    SUBSCRIPTION_CANCEL_WITH_CAPTCHA: 'SUBSCRIPTION_CANCEL_WITH_CAPTCHA',
    MUST_ATTACH_PHONE: 'MUST_ATTACH_PHONE',
    SUCCESS_ATTACH_PHONE: 'SUCCESS_ATTACH_PHONE',
    PAY_IN_BROWSER: 'PAY_IN_BROWSER',
    CLEAR_TRASHBIN: 'CLEAR_TRASHBIN',
    MOBILE_DOWNLOAD_APP: 'MOBILE_DOWNLOAD_APP',
    POLL_DIALOG: 'POLL_DIALOG',
    DELETE_CONFIRMATION_DIALOG: 'DELETE_CONFIRMATION_DIALOG',
    QUOTA_CLEANER_PAGE: 'QUOTA_CLEANER_PAGE',
    QUOTA_CLEANER_PAGE_MOBILE: 'QUOTA_CLEANER_PAGE_MOBILE',
    QUOTA_MOBILE_NAVIGATION: 'QUOTA_MOBILE_NAVIGATION',
    SASS_QUOTA_MOBILE_NAVIGATION: 'SASS_QUOTA_MOBILE_NAVIGATION',
    GENERIC_CONFRIMATION_DIALOG: 'GENERIC_CONFRIMATION_DIALOG',
    UPLOAD_BIG_FILE: 'UPLOAD_BIG_FILE',
    QUOTA_POPUP: 'QUOTA_POPUP',
    REMOVE_CARD: 'REMOVE_CARD',
    PRE_COPY_WEBLINK: 'PRE_COPY_WEBLINK',
    SUBSCRIPTION_MODAL: 'SUBSCRIPTION_MODAL',
    MOBILE_USER_CHANGE_MODAL: 'MOBILE_USER_CHANGE_MODAL',
    MOBILE_USER_LOGIN_MENU: 'MOBILE_USER_LOGIN_MENU',
    VIOLATED_FILENAME: 'VIOLATED_FILENAME',
    MOBILE_SEARCH_NOTIFICATION: 'MOBILE_SEARCH_NOTIFICATION',
    MOBILE_SEARCH_HISTORY_NOTIFICATION: 'MOBILE_SEARCH_HISTORY_NOTIFICATION',
    MOBILE_CLOUD_NEW_FEATURES: 'MOBILE_CLOUD_NEW_FEATURES',
    ASIDE_PROMO_MODAL: 'ASIDE_PROMO_MODAL',
    BUY_BUSINESS_DIALOG: 'BUY_BUSINESS_DIALOG',
    AUTH_DIALOG: 'AUTH_DIALOG',
    SMALL_WINDOW_WARNING_DIALOG: 'SMALL_WINDOW_WARNING_DIALOG',
    NOT_DOCUMENT_MODAL: 'NOT_DOCUMENT_MODAL',
    MOBILE_DISCOUNTS: 'MOBILE_DISCOUNTS',
    YEAR_FILTER_MODAL: 'YEAR_FILTER_MODAL',
    CREATE_COPY_OF_NONEDITABLEFILE: 'CREATE_COPY_OF_NONEDITABLEFILE',
    PROMOCODE_RESULT: 'PROMOCODE_RESULT',
    BUY_MODAL: 'BUY_MODAL',
    EBOOK_READER: 'EBOOK_READER',
    SLIDER_MODAL: 'SLIDER_MODAL',
    FAMILY_INVITE_MODAL: 'FAMILY_INVITE_MODAL',
    FAMILY_ACCEPT_INVITE: 'FAMILY_ACCEPT_INVITE',
    FAMILY_ERROR: 'FAMILY_ERROR',
    MOBILE_PUBLIC_BANNER: 'MOBILE_PUBLIC_BANNER',
    IMAGE_EDITOR: 'IMAGE_EDITOR',
    CONVERT_FB2_TO_EPUB: 'CONVERT_FB2_TO_EPUB',
    SAFE_FAKEDOOR: 'SAFE_FAKEDOOR',
    MOBILE_REQUIRED_SIGNUP: 'MOBILE_REQUIRED_SIGNUP',
    CREATE_NEW_DOCUMENT_BY_LINK: 'CREATE_NEW_DOCUMENT_BY_LINK',
    SUBSCRIPTION_CANCEL_TRIAL: 'SUBSCRIPTION_CANCEL_TRIAL',
    SUBSCRIPTION_CANCEL_TRIAL_MOBILE: 'SUBSCRIPTION_CANCEL_TRIAL_MOBILE',
    UPSALE_MONTH_TARIFF: 'UPSALE_MONTH_TARIFF',
    MOBILE_APP_QR: 'MOBILE_APP_QR',
    DELETE_PAGE_MODAL: 'DELETE_PAGE_MODAL',
    DELETE_PAGE_REJECTED_MODAL: 'DELETE_PAGE_REJECTED_MODAL',
    // tempexp_16481-start
    AUTOUPLOAD_PROMO_SUCCESS: 'AUTOUPLOAD_PROMO_SUCCESS',
    SUBSCRIPTION_CANCEL_AUTOUPLOAD: 'SUBSCRIPTION_CANCEL_AUTOUPLOAD',
    SUBSCRIPTION_CANCEL_10TB: 'SUBSCRIPTION_CANCEL_10TB',
    // tempexp_16481-end
    // tempexp_16800-next-line
    DETAILED_TARIFF_BENEFITS: 'DETAILED_TARIFF_BENEFITS',
    // tempexp_17086-next-line
    WELCOME_DEFAULT_TARIFF_SPLASH: 'WELCOME_DEFAULT_TARIFF_SPLASH',
    // tempexp_16598-next-line
    SUBSCRIPTION_CANCEL_NO_ADS: 'SUBSCRIPTION_CANCEL_NO_ADS',
    UPSALE_WITH_TRIAL: 'UPSALE_WITH_TRIAL',
    UNSAVED_CHANGES_MODAL: 'UNSAVED_CHANGES_MODAL',
    SHARING_PUBLIC_THEME_TARIFFS_MODAL: 'SHARING_PUBLIC_THEME_TARIFFS_MODAL',
    PAUSE_SUBSCRIPTION: 'PAUSE_SUBSCRIPTION',
    PAUSE_SUBSCRIPTION_SUCCESS: 'PAUSE_SUBSCRIPTION_SUCCESS',
    REBRANDING_PROMO_MODAL: 'REBRANDING_PROMO_MODAL',
    // tempexp_17439-start
    REFERRAL_PROGRAM_PROMO_MODAL: 'REFERRAL_PROGRAM_PROMO_MODAL',
    REFERRAL_PROGRAM_THANKS_MODAL: 'REFERRAL_PROGRAM_THANKS_MODAL',
    // tempexp_17439-end
    // tempexp_17509-next-line
    LITRES_SUCCESS_DIALOG: 'LITRES_SUCCESS_DIALOG',
    // tempexp_17532-next-line
    LITRES_MODAL: 'LITRES_MODAL',
} as const;

export interface PopupHelper {
    closeDialog(): void;
}

export interface PopupStateData {
    name: string;
    data: any;
}

export interface IVirusDialogRequest {
    items: CloudItem | CloudItem[];
    actionName?: ActionName;
    name?: string;
    doNotChangeRoute?: boolean;
}
