import { Icon24ErrorCircleOutline } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { createFamily } from 'reactApp/modules/family/family.actions';
import { buildInviteLink } from 'reactApp/modules/family/family.helpers';
import { getFamilyInviteLink, getFamilyLinkLoadingState } from 'reactApp/modules/family/family.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './FamilyInviteModal.css';
import type { FamilyInviteModalProps } from './FamilyInviteModal.types';

export const FamilyInviteModal = memo<FamilyInviteModalProps>(({ link, onClose = noopVoid, isRebrandingQuotaLanding }) => {
    const dispatch = useDispatch();
    const { isLoading, hasError } = useSelector(getFamilyLinkLoadingState);
    const familyLink = useSelector(getFamilyInviteLink);
    const storage = useSelector(getCurrentStorage);
    const isMobile = EnvironmentSelectors.isPhone();
    const scrollRef = useRef<HTMLDivElement>(null);

    const content = useMemo(() => {
        if (isLoading) {
            return (
                <div className={styles.loading}>
                    <div className={styles.loadingInput} />
                    <div className={styles.loadingButton} />
                </div>
            );
        }

        if (hasError) {
            return (
                <div className={classNames(styles.loading, styles.error)}>
                    <div className={styles.loadingInput}>
                        <Icon24ErrorCircleOutline />
                        <Text className={styles.errorText}>Произошла ошибка</Text>
                    </div>
                    <Button
                        className={styles.errorButton}
                        theme="vk"
                        sizeMode={ButtonSizeMode.middle}
                        onClick={() => {
                            dispatch(createFamily({ isRebrandingQuotaLanding }));
                            sendSplashScreenAnalytics({
                                action: ESplashScreenAction.click,
                                name: 'family_invite_link',
                                button_name: 'Повторить',
                                storage,
                            });
                        }}
                    >
                        Повторить
                    </Button>
                </div>
            );
        }

        const familyInviteLink = link || buildInviteLink(familyLink?.link);

        return (
            <div className={styles.copyLink}>
                <CopyWeblink
                    publicUrl={familyInviteLink}
                    type="new"
                    onCopy={() => {
                        sendSplashScreenAnalytics({
                            action: ESplashScreenAction.click,
                            name: 'family_invite_link',
                            button_name: 'Скопировать',
                            storage,
                        });
                    }}
                    isPhone={isMobile}
                    buttonMobile="Скопировать"
                />
            </div>
        );
    }, [isLoading, hasError, link, dispatch, familyLink?.link, storage, isMobile]);

    useEffect(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.show,
            name: 'family_invite_link',
            storage,
        });
    }, []);

    if (isMobile) {
        return (
            <MobileDialog
                id="family-invite-mobile"
                onClose={onClose}
                topmost
                closeOnDimmerClick
                scrollRef={scrollRef}
                mod="confirm"
                isRebrandingQuotaLanding={isRebrandingQuotaLanding}
            >
                <div
                    ref={scrollRef}
                    className={classNames(styles.rootMobile, {
                        [styles.rootRebrandingMobile]: isRebrandingQuotaLanding,
                    })}
                >
                    <Text className={styles.title}>Приглашение в подписку</Text>
                    <Spacing size={12} />
                    <Text className={styles.text}>Отправьте ссылку близким, с которыми хотите поделиться подпиской Mail Space.</Text>
                    <Spacing size={24} />
                    {content}
                </div>
            </MobileDialog>
        );
    }

    return (
        <Dialog
            open
            id="family-invite"
            onCancel={onClose}
            className={classNames(styles.root, {
                [styles.rootRebranding]: isRebrandingQuotaLanding,
            })}
            isRebrandingQuotaLanding={isRebrandingQuotaLanding}
        >
            <Content isModal>
                <Text className={styles.title}>Приглашение в подписку</Text>
                <Spacing size={12} />
                <Text className={styles.text}>Отправьте ссылку близким, с которыми хотите поделиться подпиской Mail Space.</Text>
                <Spacing size={24} />
                {content}
            </Content>
        </Dialog>
    );
});

FamilyInviteModal.displayName = 'FamilyInviteModal';
