import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { changeGalleryCategory } from 'reactApp/modules/gallery/gallery.module';
import { getGalleryCategories, getGalleryCategory } from 'reactApp/modules/gallery/gallery.selectors';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { sendGa } from 'reactApp/utils/ga';

import styles from './GalleryNewPage.css';

export interface GalleryPageTabMenuProps {
    isInDataList: boolean;
}

export const GalleryPageTabMenu = memo(function GalleryPageTabMenu({ isInDataList }: GalleryPageTabMenuProps) {
    const dispatch = useDispatch();

    const currentCategory = useSelector(getGalleryCategory);
    const categories = useSelector(getGalleryCategories);

    const handleMenuTabClick = useCallback(
        (category) => {
            sendGa('clicktab', 'category');

            dispatch(changeGalleryCategory({ category: GalleryCategoryType[category] }));
            dispatch(resetSelect());
        },
        [dispatch]
    );

    return (
        <div
            className={classNames({
                [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER,
                [styles.stories_home_page_header]: !isInDataList,
            })}
        >
            <BreadcrumbsContainer />
            <div className={styles.menu}>
                <PageTabMenu current={currentCategory} tabs={categories} onClick={handleMenuTabClick} changeHash />
            </div>
        </div>
    );
});
