import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { type DisableAdsTariffIds, tariffBuyWhenDisableAdsModalTariffIds } from 'reactApp/appHelpers/featuresHelpers';
import { features } from 'reactApp/constants/products/features';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { TariffFeatureListItemType } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { useFeatureData } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import type { TariffByModalTab } from 'reactApp/ui/TariffsModal/TariffsModalContent.types';

export const getTariffsList = createSelector(
    [
        (state: RootState, tariffIds: DisableAdsTariffIds, _: TariffFeatureListItemType[]) =>
            ProductsSelectors.getNormalizedTariffsListByProductIds(state, tariffIds, 'buy-disable-ads'),
        (_: RootState, __: DisableAdsTariffIds, features: TariffFeatureListItemType[]) => features,
    ],
    (tariffs, features): TariffByModalTab[] => {
        const RECOMMENDED_ID = 'W64G';
        return tariffs.map((t) => ({ ...t, isRecommended: t.tariffId.includes(RECOMMENDED_ID), features }));
    }
);

export function useDisableAdsTariffs(tariffsFromQuery?: string) {
    const [featureList] = useFeatureData(features.disableAds);
    const tariffIds = useMemo(() => {
        if (tariffsFromQuery && tariffBuyWhenDisableAdsModalTariffIds?.hasOwnProperty(tariffsFromQuery)) {
            return tariffBuyWhenDisableAdsModalTariffIds[tariffsFromQuery];
        }
        return { year: [], month: [] };
    }, [tariffsFromQuery, tariffBuyWhenDisableAdsModalTariffIds]);

    return { tariffIds, tariffs: useSelector((state: RootState) => getTariffsList(state, tariffIds, featureList)) };
}
