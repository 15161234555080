import React, { type ReactNode, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { welcomeTrialBannerTrialCtaText } from 'reactApp/appHelpers/featuresHelpers/features/welcomeTrialBanner';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import type { RootState } from 'reactApp/store';
import type { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord, getTypeOfPeriodName, isMonthPeriod, periodToDaysPeriod } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

interface TrialBlockContentProps {
    forQuota: boolean;
    infoOldStyle: boolean;
    productId: string;
    useMonthPrice: boolean;
}

interface TrialBlockContent {
    info: ReactNode;
    space: number;
    units: string;
    ctaText: string;
}

export const useContent = ({ forQuota, infoOldStyle, productId, useMonthPrice }: TrialBlockContentProps): TrialBlockContent | null => {
    const product: Product | undefined = useSelector((state: RootState) => getProductById(state, productId));

    if (!product) {
        return null;
    }

    const { space, units } = product.space;
    const { hasTrial, trialPeriod, discountPeriod, discountPrice, period, price } = product;

    let ctaText = `${formatPrice(getMonthProductPrice(product, true))} ₽ первый месяц`;
    if (hasTrial) {
        ctaText = welcomeTrialBannerTrialCtaText;
    }

    const infoTextArray: string[] = [];
    if (infoOldStyle) {
        infoTextArray.push(
            `Стоимость после ${getPeriodName(periodToDaysPeriod(trialPeriod || discountPeriod || ''))}`,
            `${formatPrice(hasTrial ? discountPrice : price)} ₽ в ${getTypeOfPeriodName(hasTrial ? discountPeriod ?? '' : period)}`
        );
    } else {
        const infoDiscount = `${getPeriodName(periodToDaysPeriod(trialPeriod || discountPeriod || ''))} ${
            hasTrial ? 'бесплатно' : ` за ${formatPrice(discountPrice || price)} ₽`
        }`;
        let infoPrice = `далее ${formatPrice(hasTrial ? discountPrice : price)} ₽ в ${getTypeOfPeriodName(
            hasTrial && discountPeriod ? discountPeriod : period
        )}`;

        if (useMonthPrice) {
            infoPrice = `далее ${formatPrice(getMonthProductPrice(product, hasTrial), undefined, true)} ₽ в месяц `;
            if (!isMonthPeriod(hasTrial && discountPeriod ? discountPeriod : period)) {
                infoPrice += `при покупке на ${getPeriodNameAsWord(hasTrial && discountPeriod ? discountPeriod : period, false, true)}`;
            }
        }

        infoTextArray.push(infoDiscount, infoPrice);
    }

    const info =
        !forQuota && infoOldStyle
            ? infoTextArray.map((part, index, { length }) => (
                  <Fragment key={index}>
                      {part}
                      {index < length - 1 && <br />}
                  </Fragment>
              ))
            : infoTextArray.join(', ');

    return {
        info,
        space,
        units,
        ctaText,
    };
};
