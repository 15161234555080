import { Icon24ErrorCircle } from '@vkontakte/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';

import { Informer } from '../Informer/Informer';
import { EInformerColor } from '../Informer/Informer.types';
import styles from './BizOverquotaInformer.css';
import { clearOverquotaMessage, getMessageInfo, getOverquotaMessage, setOverquotaMessage } from './BizOverquotaInformer.helpers';

export const BizOverquotaInformer = () => {
    const cloudSpace = useSelector(UserSelectors.getCloudSpace);
    const storedMessage = getOverquotaMessage();
    const message = getMessageInfo(cloudSpace.usedPercent);
    const [showInformer, setShowInformer] = useState<boolean>(message && IS_B2B_BIZ_USER && message !== storedMessage);

    const handleClose = useCallback(() => {
        setOverquotaMessage(message);
        setShowInformer(false);
    }, [message]);

    useEffect(() => {
        if ((message && storedMessage && message !== storedMessage) || !message) {
            clearOverquotaMessage();
        }

        setShowInformer(message && IS_B2B_BIZ_USER && message !== storedMessage);
    }, [message, storedMessage, cloudSpace.usedPercent]);

    if (!showInformer) {
        return null;
    }

    return (
        <Informer
            className={styles.root}
            color={EInformerColor.red}
            closable
            onClose={handleClose}
            icon={<Icon24ErrorCircle width={32} height={32} fill="var(--vkui--color_text_negative--hover)" />}
        >
            <div className={styles.title}>{message}</div>
        </Informer>
    );
};
