import { createSelector } from '@reduxjs/toolkit';
import { type EPromoType, type IPromoState, isNeedsPromo } from 'reactApp/modules/promo/promo.types';
import type { RootState } from 'reactApp/store';

const getPromoRoot = (state: RootState) => state.promo as IPromoState;

export const getPromoQueue = createSelector(getPromoRoot, (state) => [...state.queue]);
export const getCurrentPromo = createSelector(getPromoRoot, (state) => state.currentPromo);
export const getLastPromoShowTime = createSelector(getPromoRoot, (state) => state.lastPromoShownTimestamp);

export const getPromo = <T extends EPromoType>(promoType: T) =>
    createSelector(getPromoRoot, (state) => (isNeedsPromo(promoType, state.currentPromo) ? state.currentPromo : undefined));

export const pullPromo = <T extends EPromoType>(promoType: T) =>
    createSelector(getPromoRoot, getPromo(promoType), (state, getPromo) => {
        if (!state.currentPromo) {
            const find = state.queue.find((item) => item.type === promoType);
            return isNeedsPromo(promoType, find) ? find : getPromo;
        }

        return getPromo;
    });

export const PromoSelectors = {
    getPromoQueue,
    getCurrentPromo,
    getLastPromoShowTime,
    getPromo,
    pullPromo,
};
