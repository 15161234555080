import * as VKTBridge from '@instant-messengers/vk-teams-bridge';
import { parse } from 'qs';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

type VKTColorScheme = { colorsScheme: 'light' | 'dark' };

export const vkTeamsBridge = VKTBridge;

type VKTeamsOptions = { staticPage?: boolean };
type VKTeamsAppOptions = VKTeamsOptions & { isReady: boolean };

const VKTeamsMessages = {
    LoadingCompleted: 'LoadingCompleted',
    GetThemeSettings: 'GetThemeSettings',
} as const;

// TODO: См. sendVKTeamsMessageWithReliableAnswer
// const WAIT_RESPONSE_FROM_VKT_TIMEOUT = 1000;

function preventTransitionEffects(): () => void {
    document.body.classList.add('notransition');
    return () => {
        const _ = window.getComputedStyle(document.body).opacity;
        document.body.classList.remove('notransition');
    };
}

function sendVKTeamsMessageWithReliableAnswer<T>(message: keyof typeof VKTeamsMessages, args: Record<string, unknown>): Promise<T> {
    return Promise.race<T>([
        vkTeamsBridge.send(message, args),
        /**
         * TODO:
         * Вернуть после резолва таски https://jira.vk.team/browse/B2BCLOUD-1032
         * Вотчдог сейчас срабатывает раньше, чем ВКТ успевает отправить или
         * ответить нашему приложение, Решили пока ждать ответа максимально долго
         * или не загружать приложение
         */
        // new Promise((_resolve, reject) => setTimeout(() => reject(new Error('VKTeams not answering')), WAIT_RESPONSE_FROM_VKT_TIMEOUT)),
    ]);
}

export class VKTeamsClient {
    private static lastCall: Promise<unknown> = Promise.resolve();

    private constructor() {
        /* Decline manual creation  */
    }

    static runInsideVKTeams() {
        const urlSearchParams = parse(window.location.search, { ignoreQueryPrefix: true });
        return window?.cloudSettings?.params?.hasOwnProperty('ERROR_PAGE_STATUS') && urlSearchParams?.wv === '1';
    }

    static applyAppThemeSettings(options: VKTeamsOptions = {}) {
        if (options.staticPage && !VKTeamsClient.runInsideVKTeams()) {
            return VKTeamsClient;
        }

        VKTeamsClient.lastCall = VKTeamsClient.lastCall.then(() => {
            if (EnvironmentSelectors.isMobile()) {
                return Promise.resolve();
            }

            return sendVKTeamsMessageWithReliableAnswer<VKTColorScheme>(VKTeamsMessages.GetThemeSettings, {})
                .then(({ colorsScheme }) => {
                    if (colorsScheme === 'dark') {
                        const resumeTransitionEffects = preventTransitionEffects();
                        document.body.classList.add(VK_UI_DARK_THEME);
                        resumeTransitionEffects();
                    }
                })
                .catch((error: Error) => {
                    console.error(error.message);
                });
        });

        return VKTeamsClient;
    }

    /**
     * Так как страницы ошибок статические, мы не можем определить контекст запуска на сервере,
     * поэтому внедряем код непосредственно во время запуска скрипта, и аффекти лого в момент
     * когда готовы показать страницу ошибок в ВКТ.
     */
    static hideVKWorkspaceLogo(options: VKTeamsOptions = {}) {
        if (options.staticPage && !VKTeamsClient.runInsideVKTeams()) {
            return VKTeamsClient;
        }

        VKTeamsClient.lastCall = VKTeamsClient.lastCall.then(() => {
            document.body.classList.add('nologo');
        });

        return VKTeamsClient;
    }

    static notifyAppReady(options: VKTeamsAppOptions) {
        if (options.staticPage && !VKTeamsClient.runInsideVKTeams()) {
            return VKTeamsClient;
        }

        VKTeamsClient.lastCall = VKTeamsClient.lastCall.then(() => {
            delete (window as any).VKTClientLoading;
            return sendVKTeamsMessageWithReliableAnswer(VKTeamsMessages.LoadingCompleted, { ok: options.isReady })
                .catch((error: Error & { reason: string }) => {
                    console.error(error.reason || error.message);
                })
                .finally(() => {
                    window.ContentLoader?.setVKTClientLoaded?.();
                });
        });

        return VKTeamsClient;
    }
}
