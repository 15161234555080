import { useSelector } from 'react-redux';
import { TRIAL_OFFER_CONFIG } from 'reactApp/modules/home/home.constants';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { sortDiscountPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { Tariff } from 'reactApp/types/Billing';
import { formatDefaultDate } from 'reactApp/utils/formatDate';
import { getPeriodName } from 'reactApp/utils/Period';

import { DISCOUNTS } from '../Discounts/Components/DiscountCard/DiscountCard.constant';
import type { Discount, Promocode } from '../Discounts/Discounts.types';

type ComposeDiscountFn = (promocodes: Promocode[], promocodeTrialTariffs: Tariff[]) => Discount[];

const composeDiscount: ComposeDiscountFn = (promocodes, promocodeTrialTariffs) => {
    if (!promocodes?.length) {
        return [] as Discount[];
    }

    const promocodeTrialProducts = {};

    promocodeTrialTariffs.forEach((tariff) => {
        (tariff.products || []).forEach((product) => {
            promocodeTrialProducts[product.id] = product;
        });
    });

    return promocodes
        .filter(({ products }) => products.length)
        .map(({ expires, products }) => {
            const { price, space, period, id, isTrial, discountPrice } = products[0];

            if (isTrial) {
                const trialPeriod = promocodeTrialProducts[id]?.trialPeriod || period || '1m';

                return {
                    id,
                    type: DISCOUNTS.trial,
                    title: `${space.value} бесплатно на ${getPeriodName(trialPeriod, true, true)}`,
                    text: `Завершите получение бесплатных гигабайтов до ${formatDefaultDate(expires || 0)}`,
                };
            }

            const percentDiscount = discountPrice ? ` ${Math.floor(((price - discountPrice) / price) * 100)}%` : '';
            const discountPriceText = discountPrice ? `за ${discountPrice}₽ ` : '';
            const tariffPriceText = discountPrice ? `вместо ${price}₽` : '';

            return {
                id,
                type: DISCOUNTS.special,
                title: `${space.value} со скидкой${percentDiscount} на ${getPeriodName(period)}`,
                text: `Завершите покупку ${space.value} на год ${discountPriceText} ${tariffPriceText}`,
            };
        })
        .sort((a, b) => (a.type === DISCOUNTS.trial && b.type === DISCOUNTS.special ? 1 : -1));
};

const useShowTrial = (): boolean => {
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isOverQuotaUser = useSelector(UserSelectors.isOverQuotaUser);

    return !isPaidUser && !isBizUser && !isCorpUser && !isOverQuotaUser;
};

export const useDiscounts = () => {
    // Нельзя вывести тип, sortDiscountPromoBySpace написан на js
    const promocodes = useSelector(sortDiscountPromoBySpace) as Promocode[];
    const promocodeTrialTariffs = useSelector(ProductsSelectors.getPromoCodeTrialTariffs);
    const showTrial = useShowTrial();

    const discounts = [...composeDiscount(promocodes, promocodeTrialTariffs)];

    if (showTrial) {
        discounts.push({ ...TRIAL_OFFER_CONFIG, id: TRIAL_OFFER_CONFIG?.tariffId, type: DISCOUNTS.trial } as Discount);
    }

    return discounts.filter((s) => s?.text);
};
