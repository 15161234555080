import { parse } from 'qs';
import { getAlbumsPath } from 'reactApp/modules/albums/albums.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import type { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getEncodePath, getHomeFolderPath, getStorageAwareFolderPath } from 'reactApp/utils/urlHelper';

export const getRouterLinkUrl = (id: string, storage: EStorageType, href: string | null = null, search = '') => {
    const { isHome, isAlbums, isAttaches, isIntegration, isPublic, isStock, isInlineIntegration } = getStorage(storage);

    if (isHome) {
        return getEncodePath(getHomeFolderPath(id));
    }

    if (isIntegration || isInlineIntegration) {
        return getEncodePath(getStorageAwareFolderPath(storage, id)) + search;
    }

    if (isAttaches && search && href) {
        const { query } = parse(search, { ignoreQueryPrefix: true });
        return href === '/attaches' && query ? `${href}?query=${query}` : href;
    }

    if (isAlbums) {
        return getEncodePath(getAlbumsPath(id));
    }

    if (isPublic || isStock) {
        return getEncodePath(`/${storage}/${id}`);
    }

    return isAttaches ? href || id : getEncodePath(href || id);
};
