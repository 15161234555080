import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { advExpId, disableButtonModal, tariffBuyCustomFeatures } from 'reactApp/appHelpers/featuresHelpers';
import { disableButtonTariffs, disableButtonText } from 'reactApp/appHelpers/featuresHelpers/features/disableButtonTariffs';
import {
    autoreloadAfterSubscription,
    autoreloadAfterSubscriptionTimeout,
    noadsButtonPaymentTitle,
} from 'reactApp/appHelpers/featuresHelpers/features/noadsButton';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { openBuyCheckout, openBuyDisableAds } from 'reactApp/modules/payment/payment.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import type { Product } from 'reactApp/types/Billing';
import { getAdvDisableLink } from 'reactApp/ui/AdvBanners/AdvBanners.helpers';
import { useDisableAdsTitle } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTitle';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';

import { EButtonAction, sendDisableAdsAnalytics } from './DisableButton.analytics';
import { DisableButtonComponent } from './DisableButtonComponent';

let isBought = false;
let receiptWatched = false;
let boughtTimer: null | NodeJS.Timeout = null;

interface IProps {
    text?: string;
    className?: string;
    onClick?: () => void;
    id: string;
    theme?: 'light' | 'dark';
    /** Показывать окно оплаты вместо редиректа на /promo/quota */
    forceShowPayment?: boolean;
    /** Рекомендуемый к показу productrId */
    productId?: string;
}

// места где показываем кнопку https://jira.vk.team/browse/CLOUDWEB-12562
export const DisableButton = ({
    text = 'Отключить рекламу',
    className = '',
    onClick = noop,
    id,
    theme = 'light',
    forceShowPayment,
    productId,
}: IProps) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    // tempexp_13536-start
    const storage = useSelector(getCurrentStorage);
    const snackbarFeatureName = useSelector(getFeatureABDisableAdsSnackbar);
    const shouldShowButton = snackbarFeatureName && snackbarFeatureName !== 'b';
    const dispatch = useDispatch();

    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_SHOW, { id });

        if (shouldShowButton) {
            sendSplashscreenAnalytics({
                action: EPromoAction.SHOW,
                name: 'disable-adv-button',
                source: storage,
            });
        }

        sendDisableAdsAnalytics({
            action: EButtonAction.SHOW,
            place: id,
        });

        sendXray(`noads-button-show-${id}`, advExpId ? `${advExpId}` : null);
    }, []);

    let featuresList: string[] = [];

    const abVariant = disableButtonModal;

    if (abVariant && tariffBuyCustomFeatures[abVariant]) {
        featuresList = tariffBuyCustomFeatures[abVariant];
    }

    const title = useDisableAdsTitle(disableButtonTariffs?.title);

    const onBuyClick = (product?: Product) => {
        dispatch(
            openBuyCheckout({
                title: noadsButtonPaymentTitle,
                featuresList,
                onSuccess: () => {
                    emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_PAYMENT, { id });
                    sendDwh({ eventCategory: 'disable-button-payment' });

                    isBought = true;
                    boughtTimer = setTimeout(() => {
                        boughtTimer = null;

                        if (receiptWatched && autoreloadAfterSubscription) {
                            window.location.reload();
                        }
                    }, autoreloadAfterSubscriptionTimeout);
                },
                onShow: () => {
                    emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT, { id });
                    sendDwh({ eventCategory: 'disable-button-checkout' });
                },
                onClose: () => {
                    if (isBought) {
                        receiptWatched = true;

                        if (!boughtTimer && autoreloadAfterSubscription) {
                            window.location.reload();
                        }
                    }
                },
                paySource: 'cloud_disable_ads',
                productId: productId || product?.id,
                hideOtherTariffs: true,
            })
        );
    };

    const handleOnClick = useCallback(
        (e) => {
            if (disableButtonModal || forceShowPayment) {
                e.preventDefault();
                e.stopPropagation();
                sendDwh({ eventCategory: 'disable-button-click' });
                receiptWatched = false;
                onBuyClick();
            } else if (disableButtonTariffs?.tariffs) {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                    openBuyDisableAds({
                        title,
                        tariffsNumber: disableButtonTariffs.tariffs,
                        activeTab: disableButtonTariffs.activeTab,
                        isFrame: false,
                        onBuyClick,
                    })
                );
            }
            if (shouldShowButton) {
                sendSplashscreenAnalytics({
                    action: EPromoAction.CLICK,
                    name: 'disable-adv-button',
                    source: storage,
                    name_button: 'disable-adv',
                });
            }

            emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_CLICK, { id });
            sendDisableAdsAnalytics({
                action: EButtonAction.CLICK,
                place: id,
            });

            sendXray(`noads-button-click-${id}`, advExpId ? `${advExpId}` : null);

            onClick();
        },
        [dispatch, forceShowPayment, productId]
    );

    if (snackbarFeatureName === 'b') {
        return null;
    }
    // tempexp_13536-end

    const href = getAdvDisableLink({ isAnonymous, storage });
    if (disableButtonText) {
        text = disableButtonText;
    }

    return <DisableButtonComponent text={text} href={href} onClick={handleOnClick} className={className} theme={theme} />;
};
