import { requestPayment } from 'Cloud/Application/Payment';
import threeMonthTrialTbImage from 'img/aside-promo-modal/three-month-trial-tb-image.jpg';
import { IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { threeMonthTbPromoRebranding } from 'reactApp/appHelpers/featuresHelpers/features/three-month-tb-promo-rebranding';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID = 'three-month-trial-tb-promo-modal';
const PERIOD_IN_DAYS = 3;

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.threeMonthTrialTb));
    storeHelper.markAsShown(THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID);
    reduxStore.dispatch(removePromo(EPromoType.threeMonthTrialTb));
}

export function* initThreeMonthTrialTbPromoModal() {
    const storage = yield* select(getCurrentStorage);

    const periodPassed =
        storeHelper.getValue(THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID) &&
        storeHelper.isPassed(THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID, { daysCount: PERIOD_IN_DAYS });

    if (periodPassed) {
        storeHelper.clearStore(THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID);
    }

    const isCorrectUrl = storage === EStorageType.home || storage === EStorageType.public || storage === EStorageType.gallery;

    if (!IS_ACTION_USER || storeHelper.getValue(THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID) || !isCorrectUrl || !threeMonthTbPromoRebranding) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.threeMonthTrialTb,
            promoId: THREE_MONTH_TRIAL_TB_PROMO_MODAL_ID,
            onClick() {
                markPromoShown();
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.balloon,
                    name: '1tb_from_byte',
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                requestPayment({
                    tariff: 'W1TB_1y_rebranding_force_trial_3m_single_quota_cloud',
                    forceFastCheckout: true,
                    paySource: 'viewer_balloon_1tb_from_byte',
                });
            },
            onShow: () => {
                markPromoShown();
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.balloon,
                    name: '1tb_from_byte',
                });
            },
            onClose: () => {
                markPromoShown();
            },
        })
    );
}

export function* showThreeMonthTrialTbPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.threeMonthTrialTb));

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Терабайт от Байта на\u00A03\u00A0месяца',
            text: 'Много памяти для фото и\u00A0никакой рекламы с подпиской Mail Space',
            buttonText: 'Получить',
            imageUrl: threeMonthTrialTbImage,
            showAboveViewer: true,
            onShow,
            onClick,
            onClose,
        });
    }
}
