import {
    type SubscriptionRenewAddTrialQueryParams,
    SubscriptionRenewAddTrialAPICall,
} from 'reactApp/api/billing/subscription/SubscriptionRenewAddTrialApiCall';
import { closePauseSubscriptionDialog } from 'reactApp/components/PauseSubscriptionDialog/PauseSubscriptionDialog.helpers';
import { renderPauseSubscriptionSuccessDialog } from 'reactApp/components/PauseSubscriptionSuccessDialog/PauseSubscriptionSuccessDialog.helpers';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BuyNotifications } from 'reactApp/modules/buySubscription/buySubscription.types';
import { checkCaptcha } from 'reactApp/modules/captcha/captcha.saga';
import { showCardModal, updateCard } from 'reactApp/modules/creditCard/creditCard.saga';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getPausedSubscriptionStorageKey } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { getSubscriptionsById } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { renderCancelAutorenewMailSubsDialog } from 'reactApp/sections/QuotaLanding/CancelAutorenewDialog/CancelAutorenewDialog.helpers';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { store } from 'reactApp/store';
import { renderCancelSubscriptionTrial } from 'reactApp/ui/CancelAutoRenewTrialModal/CancelAutoRenewTrialModal.helpers';
import { renderCancelSubscriptionTrialMobile } from 'reactApp/ui/Mobile/CancelAutoRenewTrialModalMobile/CancelAutoRenewTrialModalMobile.helpers';
import { renderPollPopup } from 'reactApp/ui/PollPopup/PollPopup.helpers';
import { sendGa } from 'reactApp/utils/ga';
import { getPeriodName } from 'reactApp/utils/Period';
import { channel } from 'redux-saga';
import { call, cancel, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

// tempexp_16480-next-line
const subscriptionRenewAddTrialAPICall = (params: SubscriptionRenewAddTrialQueryParams) =>
    new SubscriptionRenewAddTrialAPICall().makeRequest(params);

function* openCancelAutorenewMailSubsDialog(action: ReturnType<typeof BuySubscriptionActions.openCancelAutorenewMailSubsDialog>) {
    const { subscription } = action.payload;

    const cancelChannel = channel();

    yield renderCancelAutorenewMailSubsDialog({
        onSuccess: () => cancelChannel.put(true),
        onClose: () => cancelChannel.close(),
        subscription,
    });

    yield take(cancelChannel);

    yield closePopupHelper(popupNames.CANCEL_MAIL_SUBSCRIPTION);

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(BuySubscriptionActions.cancelAutorenew(subscription.id));
        yield put(updateSubscriptionsRequest());
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось отключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.cancelAutoRenewFailure,
                closable: true,
            })
        );

        yield loggerSaga({ error });
    }
}

function* addAutoRenew(action: ReturnType<typeof BuySubscriptionActions.addAutorenewToSubscription>) {
    const { subscription } = action.payload;

    const { card } = yield select(CardSelectors.getCard);

    if (!card) {
        yield showCardModal({ isSilent: true });
        yield updateCard({
            showNotifications: true,
        });
    }

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(BuySubscriptionActions.addAutorenew(subscription.id));
        yield put(updateSubscriptionsRequest());
        yield put(
            showSnackbarAction({
                text: 'Автопродление включено',
                type: SnackbarTypes.success,
                id: BuyNotifications.autoRenewSuccess,
                closable: true,
            })
        );
        sendGa('billing', 'autorenew-cancel-success');
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось подключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.autoRenewFailure,
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }
}

function* handleCaptchaCheck(captchaId?: number, captchaValue?: string, sendAnalytics?: (action: ESubscriptionModalAction) => void) {
    if (captchaId) {
        yield call(checkCaptcha, { id: captchaId, value: captchaValue || '', sendAnalytics });

        yield closePopupHelper(popupNames.SUBSCRIPTION_CANCEL_WITH_CAPTCHA);
    }
}

function* cancelAutorenew(action: ReturnType<typeof BuySubscriptionActions.cancelAutorenewSubscription>) {
    const { subscriptionId, captchaId, captchaValue, sendAnalytics, isRebrandingQuotaLanding } = action.payload;
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    const subscription = yield select(getSubscriptionsById, subscriptionId);

    yield call(handleCaptchaCheck, captchaId, captchaValue, sendAnalytics);

    try {
        // tempexp_16480-next-line
        yield closePopupHelper(isPhone ? popupNames.SUBSCRIPTION_CANCEL_TRIAL_MOBILE : popupNames.SUBSCRIPTION_CANCEL_TRIAL);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield putResolve(yield call(BuySubscriptionActions.cancelAutorenew, subscriptionId));

        // закрываем окно с информацией о подписке для триалов,
        // тк после отмены автоплатежа такая подписка удаляется из списка подписок
        if (subscription?.isTrial && !subscription?.isPrepaid) {
            closePopupHelper(popupNames.SUBSCRIPTION_MODAL);
        }

        yield put(
            showSnackbarAction({
                text: 'Подписка отменена',
                type: SnackbarTypes.success,
                id: BuyNotifications.cancelAutoRenewSuccess,
                closable: true,
                buttonText: 'Восстановить',
                onButtonClick: () => {
                    sendAnalytics?.(ESubscriptionModalAction.cancelAutopaymentRestore);
                    store.dispatch(BuySubscriptionActions.addAutorenewToSubscription({ subscription }));
                },
            })
        );

        yield put(updateSubscriptionsRequest());
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось отключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.cancelAutoRenewFailure,
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }

    yield renderPollPopup({ isPhone, isRebrandingQuotaLanding });
}

// tempexp_16480-start
function* cancelRenewCheckCaptchaAndTrial(action: ReturnType<typeof BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial>) {
    const { subscriptionId, captchaId, captchaValue, sendAnalytics, period } = action.payload;
    const subscription = yield select(getSubscriptionsById, subscriptionId);
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    yield call(handleCaptchaCheck, captchaId, captchaValue, sendAnalytics);

    try {
        // Проверяем, что юзеру можно предложить триал. Если да - отрисовываем шаг с триалом.
        yield subscriptionRenewAddTrialAPICall({ sub_id: subscriptionId, interval: period });

        if (isPhone) {
            yield renderCancelSubscriptionTrialMobile({ subscription });
        } else {
            yield renderCancelSubscriptionTrial({ subscription });
        }
        yield cancel();
    } catch (error) {
        // Если нет - отключаем автопродление.
        yield put(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId }));
        yield loggerSaga({ error });
    }
}

function* checkPauseSubscription(action: ReturnType<typeof BuySubscriptionActions.checkPauseSubscription>) {
    const { subscriptionId } = action.payload;

    try {
        const { data } = yield subscriptionRenewAddTrialAPICall({ sub_id: subscriptionId });
        if (data?.result === 'success') {
            yield put(BuySubscriptionActions.setCanPauseSubscription({ canPauseSubscription: true }));
        } else {
            yield put(BuySubscriptionActions.setCanPauseSubscription({ canPauseSubscription: false }));
        }
    } catch (error) {
        yield loggerSaga({ error });
    }
}

function* pauseSubscription(action: ReturnType<typeof BuySubscriptionActions.pauseSubscription>) {
    const { subscriptionId, period } = action.payload;

    try {
        yield subscriptionRenewAddTrialAPICall({ sub_id: subscriptionId, interval: period, action: 'set' });
        yield put(updateSubscriptionsRequest());
        yield put(UserStorageActions.set(getPausedSubscriptionStorageKey(subscriptionId), true));

        yield renderPauseSubscriptionSuccessDialog({ subscriptionId });
        yield put(BuySubscriptionActions.setCanPauseSubscription({ canPauseSubscription: false }));
        yield closePauseSubscriptionDialog();
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось поставить подписку на паузу',
                type: SnackbarTypes.failure,
                id: BuyNotifications.pauseSubscriptionFailure,
                closable: true,
            })
        );

        yield loggerSaga({ error });
    }
}

function* addTrial(action: ReturnType<typeof BuySubscriptionActions.cancelAutorenewAddTrial>) {
    const { subscriptionId, period } = action.payload;
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    try {
        yield subscriptionRenewAddTrialAPICall({ sub_id: subscriptionId, interval: period, action: 'set' });
        yield closePopupHelper(isPhone ? popupNames.SUBSCRIPTION_CANCEL_TRIAL_MOBILE : popupNames.SUBSCRIPTION_CANCEL_TRIAL);

        yield put(
            showSnackbarAction({
                title: 'Бесплатный период активирован',
                text: `Следующее списание — через ${getPeriodName(period)}`,
                type: SnackbarTypes.success,
                id: BuyNotifications.cancelAutoRenewAddTrialSuccess,
                closable: true,
            })
        );

        yield put(updateSubscriptionsRequest());
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось активировать бесплатный период',
                type: SnackbarTypes.failure,
                id: BuyNotifications.cancelAutoRenewAddTrialFailure,
                closable: true,
            })
        );

        yield loggerSaga({ error });
    }
}
// tempexp_16480-end

export function* watchBuySubscription() {
    yield takeEvery(BuySubscriptionActions.openCancelAutorenewMailSubsDialog.toString(), openCancelAutorenewMailSubsDialog);
    yield takeEvery(BuySubscriptionActions.addAutorenewToSubscription.toString(), addAutoRenew);
    yield takeEvery(BuySubscriptionActions.cancelAutorenewSubscription.toString(), cancelAutorenew);
    // tempexp_16480-start
    yield takeEvery(BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial.toString(), cancelRenewCheckCaptchaAndTrial);
    yield takeEvery(BuySubscriptionActions.cancelAutorenewAddTrial.toString(), addTrial);
    // tempexp_16480-end
    yield takeEvery(BuySubscriptionActions.pauseSubscription.toString(), pauseSubscription);
    yield takeEvery(BuySubscriptionActions.checkPauseSubscription.toString(), checkPauseSubscription);
}
